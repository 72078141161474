export const SET_LOOKAHEAD_ACTIVATED_COLS = 'SET_LOOKAHEAD_ACTIVATED_COLS';
export const SET_LOOKAHEAD_RANGE_FILTERED = 'SET_LOOKAHEAD_RANGE_FILTERED';
export const SET_LOOKAHEAD_VIEWS = 'SET_LOOKAHEAD_VIEWS';
export const NOTIFY_LOOKAHEAD_UPDATE = 'NOTIFY_LOOKAHEAD_UPDATE';
export const NOTIFY_LOOKAHEAD_UPDATE_ORDER = 'NOTIFY_LOOKAHEAD_UPDATE_ORDER';
export const NOTIFY_LOOKAHEAD_UPDATE_FILTER = 'NOTIFY_LOOKAHEAD_UPDATE_FILTER';
export const NOTIFY_LOOKAHEAD_UPDATE_GROUP = 'NOTIFY_LOOKAHEAD_UPDATE_GROUP';
export const SET_FORCE_LOADING_SECTOR = 'SET_FORCE_LOADING_SECTOR';

export const lookaheadActions = {
  setActivatedColumns: (activatedColumns) => ({
    type: SET_LOOKAHEAD_ACTIVATED_COLS,
    payload: activatedColumns
  }),
  setIsRangeFiltered: (obj) => ({
    type: SET_LOOKAHEAD_RANGE_FILTERED,
    payload: { boolean: obj.boolean, dateRange: obj.range }
  }),
  setLookaheadViewsConfig: (configViews) => ({
    type: SET_LOOKAHEAD_VIEWS,
    payload: configViews
  }),
  notifyLookaheadUpdate: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateOrder: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_ORDER,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateFilter: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_FILTER,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateGroup: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_GROUP,
    payload: { boolean: true }
  }),
  setForceLoadingSector: (shouldForceLoad) => ({
    type: SET_FORCE_LOADING_SECTOR,
    payload: shouldForceLoad
  })
};
