import React from 'react';
import { InfoIcon } from '../../../icons';

export const COPY_EVERYTHING_KEY = 'COPY_EVERYTHING';
export const COPY_EVERYTHING_VALUE = 1;
export const COPY_SCHEDULE_AND_LOOKAHEAD_KEY =
  'COPY_SCHEDULE_AND_LOOKAHEAD_KEY';
export const COPY_SCHEDULE_AND_LOOKAHEAD_VALUE = 2;
export const CUSTOMIZE_KEY = 'CUSTOMIZE_KEY';
export const CUSTOMIZE_VALUE = 3;
export const SCHEDULE_KEY = 'SCHEDULE';
export const LOOKAHEAD_KEY = 'LOOKAHEAD';

export const custom_values = (t) => [
  {
    key: SCHEDULE_KEY,
    label: t('duplicate_stage.schedule_options.label'),
    withTooltip: true,
    contentTooltip: (
      <div>
        <InfoIcon color="#7DFF8A" />
      </div>
    ),
    textTooltip: t('duplicate_stage.schedule_options.tooltip_label'),
    options: [
      {
        key: 'progress',
        label: t('duplicate_stage.schedule_options.progress_label'),
        checked: false,
        disabled: false
      },
      {
        key: 'dependencies',
        label: t('duplicate_stage.schedule_options.dependencies_label'),
        checked: false,
        disabled: false
      },
      {
        key: 'resources',
        label: t('duplicate_stage.schedule_options.resources_label'),
        checked: false,
        disabled: false
      },
      {
        key: 'responsibles',
        label: t('duplicate_stage.schedule_options.responsibles_label'),
        checked: false,
        disabled: false
      },
      {
        key: 'companies',
        label: t('duplicate_stage.schedule_options.companies_label'),
        checked: false,
        disabled: false
      },
      {
        key: 'tags',
        label: t('duplicate_stage.schedule_options.tags_label'),
        checked: false,
        disabled: false
      },
      {
        key: 'costs',
        label: t('duplicate_stage.schedule_options.costs_label'),
        checked: false,
        disabled: false
      },
      {
        key: 'labor_hours',
        label: t('duplicate_stage.schedule_options.labour_hours_label'),
        checked: false,
        disabled: false
      },
      {
        key: 'baselines',
        label: t('duplicate_stage.schedule_options.baselines_label'),
        checked: false,
        disabled: false
      },
      {
        key: 'descriptions',
        label: t('duplicate_stage.schedule_options.descriptions_label'),
        checked: false,
        disabled: false
      }
    ]
  },
  {
    key: LOOKAHEAD_KEY,
    label: t('duplicate_stage.lookahead_options.label'),
    withTooltip: false,
    contentTooltip: null,
    textTooltip: null,
    options: [
      {
        key: 'tasks',
        label: t('duplicate_stage.lookahead_options.task_label'),
        checked: false,
        disabled: false
      },
      {
        key: 'roadblocks',
        label: t('duplicate_stage.lookahead_options.roadblocks_label'),
        checked: false,
        disabled: true
      }
    ]
  }
];

export const CUSTOM_DUPLICATION_SCHEDULE_OPTIONS = [
  'progress',
  'dependencies',
  'resources',
  'responsibles',
  'companies',
  'tags',
  'costs',
  'labor_hours',
  'baselines',
  'descriptions'
];

export const CUSTOM_DUPLICATION_LOOKAHEAD_OPTIONS = ['tasks', 'roadblocks'];
