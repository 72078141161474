import React from 'react';
import styled from 'styled-components';

function Navegacion({ setRouter, Router }) {
  return (
    <div>
      <br />
      <StyleNavegacion>
        <button
          className={
            'button-nav-template ' + (Router === 1 && 'bg-nav-template')
          }
          onClick={() => setRouter(1)}>
          Gantt
        </button>
        <button
          className={
            'button-nav-template ' + (Router === 2 && 'bg-nav-template')
          }
          onClick={() => setRouter(2)}>
          Etapas
        </button>
        <button
          className={
            'button-nav-template ' + (Router === 3 && 'bg-nav-template')
          }
          onClick={() => setRouter(3)}>
          Restricciones
        </button>
        <button
          className={
            'button-nav-template ' + (Router === 4 && 'bg-nav-template')
          }
          onClick={() => setRouter(4)}>
          CNCs
        </button>
        <button
          className={
            'button-nav-template ' + (Router === 5 && 'bg-nav-template')
          }
          onClick={() => setRouter(5)}>
          Etiquetas
        </button>
      </StyleNavegacion>
    </div>
  );
}

export default Navegacion;

const StyleNavegacion = styled.div`
  /* BUTTON NAV SETTINGS */
  .button-nav-template {
    display: inline-block;
    font-weight: 400;
    color: #7dc3f1;
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 13px;
    line-height: 1.5;
    border: 0px solid #000000;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
    background: white;
    width: 80px;
  }

  .bg-nav-template {
    color: white;
    background-color: #7dff8a;
  }
`;
