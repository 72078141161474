import React from 'react';
import { Popover } from 'antd';

const PopoverSystem = ({ children, theme = 'default', ...props }) => (
  <Popover
    {...props}
    overlayClassName={`popover-design-system theme--${theme}`}>
    {children}
  </Popover>
);

export default PopoverSystem;
