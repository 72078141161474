import * as icons from '../../icons';

export const GANTT_LEFT_TOOLBAR = [
  {
    icon: icons.FilterIcon,
    command: 'FILTER',
    tooltipI18nKey: 'filters_label.filters_label'
  },
  {
    icon: icons.CalendarIcon,
    command: 'DATE',
    tooltipI18nKey: 'master_plan.date'
  },
  {
    icon: icons.OrderIcon,
    command: 'ORDER',
    tooltipI18nKey: 'filters_label.order_label'
  },
  {
    icon: icons.ColumnsIcon,
    command: 'COLUMNS',
    tooltipI18nKey: 'filters_label.columns_label'
  }
];
