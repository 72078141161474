import moment from 'moment';

export const usePcrIndicatorProject = (project_s, t) => {
  let total = 0;
  let realized = 0;
  let ppc_exist = false;
  let retLabel = '';
  if (project_s) {
    const constraints = project_s.constraints;
    const actual_week = moment(new Date()).week();
    constraints.map((constraint) => {
      const commitmentDate = moment(constraint.commitmentDate);
      if (commitmentDate.week() == actual_week) {
        ppc_exist = true;
        if (constraint.schedules.length > 0) {
          constraint.schedules.map((sh) => {
            total++;
          });
        } else {
          total++;
        }
        if (constraint.status == 'released') {
          realized++;
        }
      }
    });
    if (total > 0) {
      retLabel = ((realized / total) * 100).toFixed(2) + '%';
    } else {
      if (ppc_exist) {
        retLabel = '0' + '%';
      } else {
        retLabel = 'No Data';
      }
    }
  }
  const ret = {
    realized,
    total,
    pcrLabel: retLabel,
    constraintCount: realized + '/' + total + ' ' + t('analictic.constraint')
  };
  return ret;
};

export const getPRR = (project_s, t) => {
  let total = 0;
  let realized = 0;
  let ppc_exist = false;
  let retLabel = '';
  if (project_s) {
    const constraints = project_s.constraints;
    const actual_week = moment(new Date()).week();
    constraints.map((constraint) => {
      const commitmentDate = moment(constraint.commitmentDate);
      if (commitmentDate.week() == actual_week) {
        ppc_exist = true;
        if (constraint.schedules.length > 0) {
          constraint.schedules.map((sh) => {
            total++;
          });
        } else {
          total++;
        }
        if (constraint.status == 'released') {
          realized++;
        }
      }
    });
    if (total > 0) {
      retLabel = ((realized / total) * 100).toFixed(2) + '%';
    } else {
      if (ppc_exist) {
        retLabel = '0' + '%';
      } else {
        retLabel = '-';
      }
    }
  }
  const ret = {
    realized,
    total,
    pcrLabel: retLabel,
    constraintCount: realized + '/' + total + ' ' + t('analictic.constraint')
  };
  return ret;
};
