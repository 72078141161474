import React from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import styles from './SummaryCol.module.scss';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Col, Popover } from 'antd';
import { ACTIONS } from '../../scroll.actions';

export const populateRows = (data, resourceData) => {
  const { partialValues } = data[0];
  const resourcesObject = {};

  resourceData.forEach((resource) => {
    const { r_id: id, r_name: name, unidad } = resource;
    const _name = name.toLowerCase().trim();
    const mixed_name = `${_name}_${unidad}`;
    // eslint-disable-next-line no-prototype-builtins
    if (!resourcesObject.hasOwnProperty(mixed_name)) {
      resourcesObject[mixed_name] = {
        name: name,
        unidad: unidad,
        ids: [id]
      };
    } else {
      resourcesObject[mixed_name].ids.push(id);
    }
  });
  const rows = [];
  for (const property in resourcesObject) {
    let arrayValues = [];
    partialValues.map((valores) => {
      let acumulado = 0;
      for (const pro in valores.weeks.data) {
        if (resourcesObject[property].ids.includes(parseInt(pro))) {
          acumulado += valores.weeks.data[pro];
        }
      }
      arrayValues = [...arrayValues, acumulado];
    });
    rows.push({
      name: resourcesObject[property].name,
      unidad: resourcesObject[property].unidad,
      values: arrayValues
    });
  }
  return rows;
};

export const GridSummaryCol = ({ rows, gridWidth, onScroll }) => {
  const rowCount = rows.length;
  const calculatedHeight = 25 * rowCount + 10;
  const cellsInfo =
    (cell) =>
    ({ rowIndex, style }) => {
      return (
        <div style={style}>
          <Col className={styles.gridContainer}>
            <div className={styles.cell__resource}>{cell[rowIndex].name}</div>
            <div className={styles.cell__unidad}>{cell[rowIndex].unidad}</div>
          </Col>
        </div>
      );
    };
  return (
    <div
      className={styles.divGridContainer}
      style={{ height: `${calculatedHeight}px` }}>
      <AutoSizer>
        {({ height, width }) => (
          <Grid
            className={styles.gridContent}
            columnCount={1}
            rowCount={rowCount}
            columnWidth={gridWidth}
            rowHeight={25}
            width={gridWidth}
            height={height}
            // onScroll={handleScroll}
          >
            {cellsInfo(rows)}
          </Grid>
        )}
      </AutoSizer>
    </div>
  );
};

export const GridSummaryColContent = ({
  rows,
  data,
  onScroll,
  summaryContentRef
}) => {
  const { partialValues } = data[0];
  const countColumns = partialValues.length;
  const rowCount = rows.length;

  const cells =
    (cell) =>
    ({ columnIndex, rowIndex, style }) => {
      return (
        <div style={style} className={`${styles.matrix__cell}`}>
          {cell[rowIndex].values[columnIndex] != undefined &&
            cell[rowIndex].values[columnIndex].toFixed(2)}
        </div>
      );
    };
  // const handleScroll = (scroll) => onScroll({ scrollFrom: ACTIONS.TOTAL_COL })(scroll)
  return (
    <div className={styles.matrix}>
      <AutoSizer>
        {({ height, width }) => (
          <Grid
            className={styles.matrix__content}
            ref={summaryContentRef}
            columnCount={countColumns}
            rowCount={rowCount}
            columnWidth={80}
            rowHeight={25}
            width={width}
            height={height}
            // onScroll={handleScroll}
          >
            {cells(rows)}
          </Grid>
        )}
      </AutoSizer>
    </div>
  );
};
