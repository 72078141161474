export function setupDynamicHubSpotStyles(path) {
  const styleSheet = document.createElement('style');
  document.head.appendChild(styleSheet);

  const shouldLimit = path.includes('/gantt') || path.includes('/lookahead');

  const updateStyles = () => {
    styleSheet.innerHTML = `
          #hubspot-messages-iframe-container.widget-align-left {
              left: -9px !important;
          }
          #hubspot-messages-iframe-container {
              min-width: ${shouldLimit ? '85px !important' : ''};
              width: ${shouldLimit ? '85px !important' : ''};
              height: ${shouldLimit ? '85px !important' : ''};
          }
          html.hs-messages-widget-open #hubspot-messages-iframe-container {
              width: ${shouldLimit ? '420px !important' : ''};
              height: 674px !important;
          }
      `;
  };

  updateStyles();

  const mutationObserver = new MutationObserver(updateStyles);
  mutationObserver.observe(document.body, {
    childList: true,
    subtree: true
  });

  return () => {
    mutationObserver.disconnect();
    document.head.removeChild(styleSheet);
  };
}
