import { base } from './base';
import axios from 'axios';

class GanttService {
  constructor() {
    this.http = axios.create({ baseURL: base.api });
  }

  setHeaders() {
    const loggedUser = sessionStorage.getItem('sessionToken');
    if (!loggedUser) {
      this.http = null;
      this.headers = null;
      return;
    }
    const headers = {
      'Content-Type': 'application/json',
      Authorization:
        'Basic cHJvcGxhbm5lcjpiWGx0WVhOMFpYSnJaWGx0ZVcxaGMzUmxjbXRsZVcxNWJXRnpkR1Z5YTJWNU1USXpORFUyTnpnNU1UQmhZbU5rWldabmFHbHE=',
      'WWW-Authenticate': 'Bearer ' + loggedUser
    };

    if (loggedUser) {
      headers['WWW-Authenticate'] = 'Bearer ' + loggedUser;
    }
    this.http.defaults.headers = headers;
  }

  async show(ganttId) {
    this.setHeaders();
    const res = await this.http.get('gantts/' + ganttId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async showBySector(sectorId) {
    this.setHeaders();
    if (!this.http || !sectorId) {
      console.warn('No session token available, request aborted');
      return false;
    }
    const res = await this.http
      .get('gantts/sector/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(ganttId) {
    this.setHeaders();
    const res = await this.http.delete('gantts/' + ganttId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async index() {
    this.setHeaders();
    const res = await this.http.get('gantts').catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async create(gantt) {
    this.setHeaders();
    const res = await this.http.post('gantts', gantt).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(gantt) {
    this.setHeaders();
    const res = await this.http
      .put('gantts/' + gantt.id, gantt)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const ganttService = new GanttService();
