/* eslint no-label-var: "off" */
import React, { useState, useEffect } from 'react';
/** Components */
import AppRouter from '../../router';

/**
 * High order components (HOC)
 */
import withoutWide from '../../hocs/withoutWide.hoc';

/** import elements from library Antd Framework */
import { Spin } from 'antd';

/** import icons from antd framework */
import { LoadingOutlined } from '@ant-design/icons';
import { useCheckUserAndLocation } from '../../hooks/useCheckUserAndLocation';

/** render */
export default function LayoutAuthWide(props) {
  useCheckUserAndLocation();
  const [FinishPage, setFinishPage] = useState(
    <Spin className="loader-layout-style" indicator={<LoadingOutlined />} />
  );
  useEffect(() => {
    const Final = withoutWide({ ...props })(AppRouter);
    setFinishPage(Final);
  }, []);
  return <div>{FinishPage}</div>;
}
