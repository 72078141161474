/** React components  */
import React, { useState } from 'react';
import RemoveIconWhite from '../../assets/img/gantt/X-white.png';
import { Tooltip } from 'antd';
import CustomDropdownSelector from '../CustomDropdownSelector';
import { useEffect } from 'react';
import {
  getCompanyTextColor,
  getTypeNotification,
  notifyMessageCustom
} from '../../utils';
import { MassiveHelmetComp } from '../GanttMassiveToolbar/icons';

export default function GanttMassiveCompany(props) {
  const gantt = window.to_use_react_gantt;
  const { tooltipI18nKey, t, selectedActivities } = props;
  const [state, setState] = useState({
    mainObject: {},
    options: props.subContracts,
    includeRemoveFooter: true,
    attrToExtractFromOption: 'id',
    attrToLabelFromOption: 'name'
  });
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setState({
      mainObject: {},
      options: props.subContracts,
      includeRemoveFooter: true,
      attrToExtractFromOption: 'id',
      attrToLabelFromOption: 'name'
    });
    const gantt = window?.to_use_react_gantt;
    if (gantt) {
      gantt.optimizedRender();
    }
  }, [props.subContracts]);

  const handleMouseEnter = (e) => {
    setIsHovered(true);
  };

  const handleMouseLeave = (e) => {
    setIsHovered(false);
  };

  const customMainClassName = 'massive-company-class';
  const isOnlyReadElement = false; // onlyRead || task.readonly || (subtradeRole && subtradeRole.isSubtradeRole)

  const renderSelectedOption = (selected) => {
    const object = selected;
    return (
      <div
        className="item-gantt item-custom-selected-sub"
        style={{ cursor: isOnlyReadElement ? 'not-allowed' : 'pointer' }}>
        <div
          className="subcontract-border cut-text-and-dot"
          style={{
            height: '100%',
            color: `${getCompanyTextColor(object?.color)}`,
            padding: 0,
            backgroundColor: object?.color
          }}>
          <div
            className="text-subcontract cut-text-and-dot"
            style={{
              alignContent: 'center',
              textAlign: 'center',
              position: 'relative',
              top: 1
            }}>
            {object?.name}
          </div>
        </div>
      </div>
    );
  };

  const renderSubToSelect = (option) => {
    const { object } = option;
    return (
      <div className="item-gantt">
        <div
          className="subcontract-border cut-text-and-dot"
          style={{
            height: '100%',
            color: `${getCompanyTextColor(object?.color)}`,
            padding: 0,
            backgroundColor: object?.color
          }}>
          <div
            className="text-subcontract cut-text-and-dot"
            style={{
              alignContent: 'center',
              textAlign: 'center',
              position: 'relative',
              top: 7
            }}>
            {object?.name}
          </div>
        </div>
      </div>
    );
  };

  const renderRemoveBtn = () => {
    return (
      <div className="remove-option-sub-custom">
        <div className="remove-sub-x">
          <img width="10" src={RemoveIconWhite} />
        </div>
        <div className="remove-sub-text">{t('remove_subcontract_label')}</div>
      </div>
    );
  };

  const onSelection = (payload) => {
    const newValue = payload.replace('-', '');
    if (selectedActivities.length) {
      const undoExtension = gantt.ext.undo;
      gantt.batchUpdate(function () {
        selectedActivities.forEach((act) => {
          undoExtension.saveState(act.id, 'task');
          const actGet = gantt.getTask(act.id);
          actGet.subcontractId = parseInt(newValue);
          gantt.updateTask(actGet.id);
        });
      });
      gantt.ext.keyboardNavigation.focus({
        type: 'taskCell',
        id: selectedActivities[0].id,
        column: 'subcontractId'
      });
      notifyMessageCustom({
        type: getTypeNotification(MassiveHelmetComp),
        description: t('massive_notif_company')
      });
    }
  };

  const callbackRemove = (_) => {
    if (selectedActivities.length) {
      selectedActivities.forEach((act) => {
        const actGet = gantt.getTask(act.id);
        actGet.subcontractId = null;
      });
      gantt.optimizedRender();
    }
  };

  return (
    <span className="gm-company">
      <CustomDropdownSelector
        customMainClassName={`custom-gantt-subcontract custom-sub-dropdown ${customMainClassName || ''}`}
        renderCustomPlaceholderElement={renderSelectedOption}
        renderCustomSelectionOption={renderSubToSelect}
        renderCustomRemoveOption={renderRemoveBtn}
        labelCreateBtn={
          <div className="create-sub-btn">
            + {t('user_config.new_subcontract')}
          </div>
        }
        {...state}
        callbackOnSelection={onSelection}
        callbackOnClickRemove={callbackRemove}
        callbackOnClickCreate={() => {
          gantt.refreshMassive = false;
          gantt.setVisibleFormSubcontract(true);
        }}
        onlyread={isOnlyReadElement}>
        <Tooltip placement="top" title={t(tooltipI18nKey)}>
          <span
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}>
            {props.icon && (
              <props.icon color={isHovered ? '#7DFF8A' : '#FFFFFF'} />
            )}
          </span>
        </Tooltip>
      </CustomDropdownSelector>
    </span>
  );
}
