import React, { useState, useEffect } from 'react';
import CustomDropdownSelector from '../../../CustomDropdownSelector';
import RemoveIcon from '../../../../assets/img/gantt/X.png';
import IconComponent from '../../../Projects/IconSvg';

import { RENDER_TAG } from '../constants';
import { withTranslation } from 'react-i18next';
import {
  findDeepGetTask,
  updateAsyncTaskGanttV2
} from '../../GanttVisualization.helper';
import { Tooltip } from 'antd';
import { getCompanyTextColor } from '../../../../utils';

const RenderTag = ({
  column,
  lastLevelActivities,
  activities,
  gantt,
  t,
  onlyRead,
  task,
  tags,
  callbackOnClickCreate,
  callbackOnClickRemove,
  callbackOnSelection,
  customMainClassName
}) => {
  const [state, setState] = useState(RENDER_TAG);
  const isOnlyReadElement = onlyRead || task.readonly;
  const taskFromLookahead =
    activities && gantt ? findDeepGetTask(activities, 'id', task.id) : null;

  useEffect(() => {
    setState({
      ...state,
      mainObject: task,
      options: tags
    });
  }, [task, tags]);

  const onSelection = (payload, dictionary) => {
    const newVal = [];
    payload.map((op) => {
      newVal.push(dictionary[op]);
    });
    setState({
      ...state,
      mainObject: {
        ...state.mainObject,
        tags: payload.map((id) => dictionary[id])
      }
    });
    callbackOnSelection && callbackOnSelection(payload, dictionary);
    taskFromLookahead &&
      updateAsyncTaskGanttV2(
        taskFromLookahead,
        column.name,
        newVal,
        gantt,
        true
      );
  };

  const callbackRemove = (_) => {
    setState({
      ...state,
      mainObject: {
        ...state.mainObject,
        tags: []
      }
    });
    callbackOnClickRemove && callbackOnClickRemove();
  };

  const renderSelectedOption = (_) => {
    return (
      <div>
        {task[column.name].map((tag, index) => {
          const { description, name } = tag;
          const textColor = getCompanyTextColor(description);
          return (
            <div key={index}>
              <div
                key={index}
                className="tag-proplanner"
                style={{
                  width: 70,
                  height: 17,
                  backgroundColor: description,
                  marginLeft: 16,
                  color: description
                }}></div>
              <div
                style={{
                  position: 'relative',
                  top: -11,
                  left: '19%',
                  display: 'inline-flex',
                  float: 'left'
                }}>
                <span
                  className="tag-dot-custom-field"
                  style={{
                    width: 5,
                    height: 5,
                    borderRadius: '50%',
                    backgroundColor: description,
                    border: `1px solid ${textColor}`,
                    position: 'relative',
                    zIndex: 1
                  }}></span>
                <span
                  title={name}
                  className="cut-text-and-dot tag-name-custom-field"
                  style={{
                    position: 'relative',
                    top: -13,
                    maxWidth: 60,
                    left: 4,
                    color: textColor,
                    zIndex: 1
                  }}>
                  {name}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  let isOdd = false;
  if (gantt?.oddColsConfig && task.type === 'activitytask') {
    isOdd = gantt.oddColsConfig.tags;
  }

  const renderTagToSelect = (option) => {
    const { object } = option;
    const { description, name } = object;
    const textColor = getCompanyTextColor(description);
    return (
      <div className="item-gantt">
        <div
          className="subcontract-border cut-text-and-dot"
          style={{
            height: '100%',
            color: `${getCompanyTextColor(description)}`,
            padding: 0,
            backgroundColor: description
          }}>
          <div
            className="text-subcontract cut-text-and-dot"
            style={{
              alignContent: 'center',
              textAlign: 'center',
              position: 'relative',
              top: 7,
              float: 'left',
              width: 140
            }}>
            <span style={{ marginLeft: 5 }}>
              <IconComponent
                viewBox="0 0 16 16"
                data={
                  'M0.409302 5.87389V1.23607C0.444715 0.527453 1.01572 0.00853333 1.64537 0H6.28318C7.02902 0.0463467 7.91953 0.33876 8.39779 0.878533L15.2626 8.51972C15.6938 9.06561 15.706 9.82104 15.2626 10.2768L9.88925 15.6501C9.3624 16.1267 8.57631 16.1065 8.13219 15.6501L1.28782 7.98849C0.788875 7.39925 0.416182 6.61145 0.409302 5.87389ZM2.42174 3.19744C2.45098 3.88691 2.99486 4.38449 3.60674 4.39265C4.2982 4.36177 4.79378 3.81064 4.80196 3.19744C4.77152 2.50567 4.21757 2.02047 3.60674 2.01244C2.91694 2.04125 2.42973 2.58795 2.42174 3.19744Z'
                }
                width={14}
                fill={textColor}
              />
              <Tooltip title={name} placement="leftBottom">
                <span style={{ marginLeft: 3 }}>{name}</span>
              </Tooltip>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderRemoveBtn = () => {
    return (
      <div className="remove-option-sub-custom">
        <div className="remove-sub-x">
          <img width="10" src={RemoveIcon} />
        </div>
        <div className="remove-sub-text">{t('remove_subcontract_label')}</div>
      </div>
    );
  };

  if (!task.isTask && gantt) return null;
  return (
    <div
      className={`${isOdd ? 'odd-col' : 'non-odd-col'} ${customMainClassName || ''}`}>
      <CustomDropdownSelector
        {...state}
        renderCustomSelectionOption={renderTagToSelect}
        customMainClassName={`custom-proplanner-tag ${customMainClassName || ''}`}
        renderCustomPlaceholderElement={renderSelectedOption}
        renderCustomRemoveOption={renderRemoveBtn}
        labelCreateBtn={<div className="create-sub-btn">+ {t('new_tag')}</div>}
        callbackOnSelection={onSelection}
        callbackOnClickCreate={callbackOnClickCreate}
        callbackOnClickRemove={callbackRemove}
        onlyread={isOnlyReadElement}
      />
    </div>
  );
};

export default withTranslation()(RenderTag);
