const CUSTOM_PREDECESSORS = 'custom_predecessors';
const CALENDAR_ID = 'calendar_id';

export const buildQuery = (showedFilters, mapElements) =>
  showedFilters.value
    .map((o, index) => {
      const fieldName = getFieldName(o, mapElements);
      const orderBy = o.order_by;

      return index === 0
        ? `firstBy(${fieldName}, { direction: '${orderBy}', ignoreCase: true})`
        : `.thenBy(${fieldName}, { direction: '${orderBy}', ignoreCase: true})`;
    })
    .join('');

const getFieldName = (filter, mapElements) => {
  const filterName = filter.name;
  let fieldName = `'${filterName}'`;

  if (filter.name === CUSTOM_PREDECESSORS) {
    filter.name = 'custom_predecessor';
  }

  if (
    filter.data_type.includes('array/') &&
    (filter.data_type.includes('/string') || filter.data_type.includes('/icon'))
  ) {
    const optionsMap = createOptionsMap(filter);
    mapElements[filterName] = optionsMap;

    if (filter.name === CALENDAR_ID) {
      fieldName = createStringFieldName(filter.name);
    } else {
      fieldName = createWeightFieldName(filter.name);
    }
  }

  return fieldName;
};

const createOptionsMap = (filter) => {
  const optionsMap = {};
  filter.from_values.forEach((option) => {
    optionsMap[option.value] =
      filter.name === CALENDAR_ID ? option.label : option.weigth;
  });
  return optionsMap;
};

const createFieldName = (filterName, isWeight) => `
  (v1, v2) => {
      let value_one = mapElements.${filterName}[v1.${filterName}] || ${isWeight ? '0' : "''"};
      let value_two = mapElements.${filterName}[v2.${filterName}] || ${isWeight ? '0' : "''"};
      return ${isWeight ? '(value_one - value_two)' : 'value_one.localeCompare(value_two)'};
  }
`;

const createStringFieldName = (filterName) =>
  createFieldName(filterName, false);
const createWeightFieldName = (filterName) => createFieldName(filterName, true);
