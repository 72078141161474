import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import { ToolbarGroup } from '../ToolbarGroup';
import { GANTT_LEFT_TOOLBAR } from './constants';
import styles from './GanttToolbar.module.scss';

export const testIds = {
  RENDER_GANTT_LEFT_TOOLBAR: 'RENDER_GANTT_LEFT_TOOLBAR'
};
const TestWrapped = ({ children }) => (
  <span data-testid={testIds.RENDER_GANTT_LEFT_TOOLBAR}>{children}</span>
);

export const GanttLeftToolbar = ({
  onCommandDispatched,
  counterFilters,
  t
}) => {
  const [items, setItems] = useState([]);
  useEffect(
    (_) => {
      const filterIdx = GANTT_LEFT_TOOLBAR.findIndex(
        (item) => item.command == 'FILTER'
      );
      const orderIdx = GANTT_LEFT_TOOLBAR.findIndex(
        (item) => item.command == 'ORDER'
      );

      GANTT_LEFT_TOOLBAR[filterIdx].counterSelected = counterFilters.filter;
      GANTT_LEFT_TOOLBAR[orderIdx].counterSelected = counterFilters.order;
      GANTT_LEFT_TOOLBAR[1].counterSelected = counterFilters.dates;

      setItems([GANTT_LEFT_TOOLBAR]);
    },
    [counterFilters]
  );

  useEffect(() => {
    let newItems = [];
    newItems = [GANTT_LEFT_TOOLBAR];
    /** update state only if there is change in the data */
    if (newItems.length !== items.length) {
      setItems(newItems);
    }
  }, []);

  return (
    <Row className="gantt-header-filters">
      <TestWrapped>
        <ToolbarGroup
          className={styles['gantt-left-toolbar']}
          items={items}
          onCommandDispatched={onCommandDispatched}
          t={t}
        />
      </TestWrapped>
    </Row>
  );
};

export default GanttLeftToolbar;
