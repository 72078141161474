export default function useAnalyticsPermissions() {
  const permissions = JSON.parse(sessionStorage.getItem('permissiontable'));
  if (!permissions) return {};
  const cnc = permissions.analytics.find(
    (per) => per.module == 'cnc'
  ).permission;
  const pcr = permissions.analytics.find(
    (per) => per.module == 'pcr'
  ).permission;
  const ppc = permissions.analytics.find(
    (per) => per.module == 'ppc'
  ).permission;
  const scurve = permissions.analytics.find(
    (per) => per.module == 'scurve'
  ).permission;
  const sectoradvancement = permissions.analytics.find(
    (per) => per.module == 'sectoradvancement'
  ).permission;
  const overdue = permissions.analytics.find(
    (per) => per.module == 'overdue'
  ).permission;
  return {
    cnc,
    pcr,
    ppc,
    scurve,
    sectoradvancement,
    overdue,
    product: 'analytics'
  };
}
