import Analytics from '../views/analytics';
import MasterPlanView from '../views/masterPlan';
import ImportGanttView from '../views/importGantt';
import LoginView from '../views/login';
import ForgotView from '../views/forgot';
import SignUpView from '../views/signup';
import SignUpConfirmationView from '../views/signupConfirmation';
import ChangePasswordView from '../views/changepassword';
import RegisterView from '../views/register';
import ConfirmationView from '../views/confirmation';
import CompanyView from '../views/company';
import ProjectConfirmationView from '../views/project/createConfirmation';
import ProjectsView from '../views/project/list';
import InviteView from '../views/inviteTeam';
import LookaheadMainView from '../views/lookahead';
import WeeklyPlanMainView from '../views/weeklyPlan';
import StageView from '../views/stage';
import GanttContainer from '../views/ganttContainer';
import SettingsUser from '../views/setting/SettingUser';
import SettingsProyect from '../views/setting/SettingProyect';
import SettingsPlugin from '../views/setting/SettingPlugin/SettingsPlugin';
import SettingsCompany from '../views/setting/SettingCompany';
import Report from '../views/report/weekAllReport';
import ReportLookAhead from '../views/report/lookAheadAllReport';
import ReportCommint from '../views/report/weekAllReportCommit';
import Productivity from '../views/productivity';
import ReportCommintClose from '../views/report/weekAllReportCommitClose';
import TutorialsView from '../views/tutorials';
import CcloudBanner from '../views/ccloud';
import TaktPlanningView from '../views/taktplanning';
import TaktAnalyticsView from '../views/analytics/general/taktAnalytics';
import Admin from '../views/adminoperation/';
import SelectCompanyView from '../views/selectCompany';
import TaktAnalyticsDashboard from '../views/taktAnalyticsDashboard/taktAnalyticsDashboard';
import { modulesInfo, MODULE_EXPORT_WEEKLYREPORT } from '../constants/modules';

export const routes = [
  modulesInfo[MODULE_EXPORT_WEEKLYREPORT],
  {
    path: '/masterplan/:sectorIdParam/:projectIdParam',
    component: MasterPlanView
  },
  {
    path: '/masterplan',
    component: MasterPlanView
  },
  {
    path: '/importgantt',
    component: ImportGanttView
  },
  {
    path: '/gantt/:sectorId',
    component: GanttContainer
  },
  {
    path: '/analytics',
    component: Analytics,
    exact: true
  },
  {
    path: '/report/week/all/:week/:planificationday/:year/:start/:end',
    component: Report
  },
  {
    path: '/reportlookahead/week/allNewTab/:week/:colorSchema/:numWeeks',
    component: ReportLookAhead
  },
  {
    path: '/reportlookahead/week/all/:week/:colorSchema/:numWeeks',
    component: ReportLookAhead
  },
  {
    path: '/reportcommit/week/all/:planificationday/:week/:projectId/:sectorId/:year',
    component: ReportCommint
  },
  {
    path: '/reportcommit/week/all/:planificationday/:week/:projectId/:sectorId/',
    component: ReportCommint
  },
  {
    path: '/reportcommitclose/week/all/:planificationday/:week/:projectId/:sectorId',
    component: ReportCommintClose
  },
  {
    path: '/productivity',
    component: Productivity
  },
  {
    path: '/login',
    component: LoginView
  },
  {
    path: '/forgot',
    component: ForgotView
  },
  {
    path: '/signup',
    component: SignUpView
  },
  {
    path: '/signupconfirmation/:token',
    component: SignUpConfirmationView
  },
  {
    path: '/changepassword/:token',
    component: ChangePasswordView
  },
  {
    path: '/register',
    component: RegisterView
  },
  {
    path: '/schedules/:token',
    component: StageView
  },
  {
    path: '/confirmation/:token',
    component: ConfirmationView
  },
  {
    path: '/company/:token',
    component: CompanyView
  },
  {
    path: '/confirmationproject/:token',
    component: ProjectConfirmationView
  },
  {
    path: '/selectCompany',
    component: SelectCompanyView
  },
  {
    exact: true,
    path: '/projects',
    component: ProjectsView
  },
  {
    path: '/lookahead/constraints/:projectIdParam/:sectorIdParam/:idsParam',
    component: LookaheadMainView
  },
  {
    path: '/lookahead/constraints/:projectIdParam/:sectorIdParam',
    component: LookaheadMainView
  },
  {
    path: '/lookahead/planification/:projectIdPlan/:sectorIdPlan',
    component: LookaheadMainView
  },
  {
    path: '/lookahead',
    component: LookaheadMainView
  },
  {
    path: '/weeklyplan/cncs/:projectIdCnc/:sectorIdCnc',
    component: WeeklyPlanMainView
  },
  {
    path: '/weeklyplan',
    component: WeeklyPlanMainView
  },
  {
    path: '/invite/:token',
    component: InviteView
  },
  {
    path: '/settings/company',
    component: SettingsCompany
  },
  {
    path: '/settings/project',
    component: SettingsProyect
  },
  {
    path: '/settings/user',
    component: SettingsUser
  },
  {
    exact: true,
    path: '/settings/plugins',
    component: SettingsPlugin
  },
  {
    path: '/taktanalytics',
    component: TaktAnalyticsView
  },
  {
    path: '/taktplanning',
    component: TaktPlanningView
  },
  {
    exact: true,
    path: '/tutorials',
    component: TutorialsView
  },
  {
    exact: true,
    path: '/ccloud_banner',
    component: CcloudBanner
  },
  {
    exact: true,
    path: '/admin/inactiveuser/ohf87eygv9erf3heh93fufcishsbveeuww8ewcn89',
    component: Admin
  },
  {
    path: '/extraDashboard',
    component: TaktAnalyticsDashboard
  }
];

const [exactRouteList, partialRouteList] = routes.reduce(
  ([exactRoutes, partialRoutes], { path, exact }) =>
    exact
      ? [[...exactRoutes, path], partialRoutes]
      : [exactRoutes, [...partialRoutes, path]],
  [[], []]
);

export { exactRouteList, partialRouteList };
