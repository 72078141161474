/**
 * This function handles scale timeline bug
 * @returns null when reference are not loaded
 */

let isScaleDateHeaderExecuting = false;
export const fixScaleDateHeader = (gantt) => () => {
  if (isScaleDateHeaderExecuting) return;

  isScaleDateHeaderExecuting = true;

  const executeFix = () => {
    if (!gantt.$ui) return;
    gantt.$ui.getView('timeline')._refreshScales();
    isScaleDateHeaderExecuting = false;
  };

  executeFix();
};
