import React, { useState, useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';
import { Spin, Icon } from 'antd';

export default function CurvePCRMini(props) {
  const { t } = props;
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  const [loading_filter, setLoading_filter] = useState(false);
  const constraints = props.data;
  const [options, setOptions] = useState({
    chart: {
      height: '250',
      type: 'area'
    }
  });
  const [series, setSeries] = useState([
    {
      name: 'Porcentaje',
      data: []
    }
  ]);

  useEffect(() => {
    setLoading_filter(false);
    let labels = [];
    let pcrs = [];
    let commitment_percentajes = [];

    if (props.dataConstraints) {
      labels = props.dataConstraints.labels;
      pcrs = props.dataConstraints.pcrs;
      commitment_percentajes = props.dataConstraints.commitment_percentajes;
      if (labels) {
        const option = {
          chart: {
            height: '250',
            sparkline: {
              enabled: true
            },
            type: 'area',
            colors: ['#7DFF8A'],
            toolbar: {
              show: false,
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: true,
                zoomout: true,
                pan: false,
                reset: false,
                customIcons: []
              }
            }
          },
          stroke: {
            width: [0, 2],
            curve: ['smooth', 'straight', 'stepline']
          },
          title: {
            text: ''
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [2]
          },
          labels: labels,
          xaxis: {
            type: 'datetime'
          },
          yaxis: [
            {
              show: false,
              title: {
                text: ''
              },
              max: 100
            },
            {
              opposite: true
            }
          ],
          colors: ['#7DFF8A'],
          tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return (
                '<div>' +
                `${t('lang') === 'en' ? series[seriesIndex][dataPointIndex] : series[seriesIndex][dataPointIndex].toString().replace('.', ',')}` +
                '%  <br>' +
                pcrs[dataPointIndex].realized +
                '/' +
                pcrs[dataPointIndex].total +
                ' ' +
                t('analictic.constraint') +
                '<br> ' +
                '</div>'
              );
            }
          },
          legend: {
            horizontalAlign: 'right'
          },
          grid: {
            show: false,
            trokeDashArray: 0,
            padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            }
          }
        };
        const series = [
          {
            name: 'Porcentaje',
            data: commitment_percentajes
          }
        ];

        setOptions(option);
        setSeries(series);
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (series[0]?.data.length) {
      setLoading_filter(true);
    }
  }, [series, options]);

  return (
    <div style={{ height: '100%' }}>
      <Chart
        options={options}
        series={series}
        type="area"
        width="100%"
        height="100%"
      />
    </div>
  );
}
