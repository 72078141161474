import React from 'react';
import styled from 'styled-components';

function BuildingHeight() {
  return (
    <div>
      <StyleHeight>
        <h1 style={{ color: '#64B7EF', height: '23.66px' }}>Building Height</h1>

        <div className="grid-cards-height">
          <div className="card-height">
            <div>
              <img
                src="https://img.interempresas.net/fotos/2244011.jpeg"
                alt=""
                width="100%"
                height="100px"
              />
            </div>
            <div className="grid-body">
              <div>
                <b className="title-card-height">
                  Obra Gruesa 3 subt + 15 Pisos
                </b>
                <p className="numbers">01.03.20</p>
              </div>
              <div>
                <img
                  className="img-card-height"
                  src="https://www.w3schools.com/w3images/avatar2.png"
                  alt=""
                />
              </div>
            </div>
            <p style={{ fontSize: '10px', textAlign: 'start', margin: '5px' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque condimentum lorem hendrerit congue ultricies.
              Habitant blandit habitant in a risus. Aliquet sed orci consequat
              id enim nec enim elementum morbi. A praesent
            </p>
          </div>
          <div className="card-height">
            <div>
              <img
                src="https://img.interempresas.net/fotos/2244011.jpeg"
                alt=""
                width="100%"
                height="100px"
              />
            </div>
            <div className="grid-body">
              <div>
                <b className="title-card-height">
                  Obra Gruesa 3 subt + 15 Pisos
                </b>
                <p className="numbers">01.03.20</p>
              </div>
              <div>
                <img
                  className="img-card-height"
                  src="https://www.w3schools.com/w3images/avatar2.png"
                  alt=""
                />
              </div>
            </div>
            <p style={{ fontSize: '10px', textAlign: 'start', margin: '5px' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque condimentum lorem hendrerit congue ultricies.
              Habitant blandit habitant in a risus. Aliquet sed orci consequat
              id enim nec enim elementum morbi. A praesent
            </p>
          </div>
          <div className="card-height">
            <div>
              <img
                src="https://img.interempresas.net/fotos/2244011.jpeg"
                alt=""
                width="100%"
                height="100px"
              />
            </div>
            <div className="grid-body">
              <div>
                <b className="title-card-height">
                  Obra Gruesa 3 subt + 15 Pisos
                </b>
                <p className="numbers">01.03.20</p>
              </div>
              <div>
                <img
                  className="img-card-height"
                  src="https://www.w3schools.com/w3images/avatar2.png"
                  alt=""
                />
              </div>
            </div>
            <p style={{ fontSize: '10px', textAlign: 'start', margin: '5px' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque condimentum lorem hendrerit congue ultricies.
              Habitant blandit habitant in a risus. Aliquet sed orci consequat
              id enim nec enim elementum morbi. A praesent
            </p>
          </div>

          <div className="card-height">
            <div>
              <img
                src="https://img.interempresas.net/fotos/2244011.jpeg"
                alt=""
                width="100%"
                height="100px"
              />
            </div>
            <div className="grid-body">
              <div>
                <b className="title-card-height">
                  Obra Gruesa 3 subt + 15 Pisos
                </b>
                <p className="numbers">01.03.20</p>
              </div>
              <div>
                <img
                  className="img-card-height"
                  src="https://www.w3schools.com/w3images/avatar2.png"
                  alt=""
                />
              </div>
            </div>
            <p style={{ fontSize: '10px', textAlign: 'start', margin: '5px' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque condimentum lorem hendrerit congue ultricies.
              Habitant blandit habitant in a risus. Aliquet sed orci consequat
              id enim nec enim elementum morbi. A praesent
            </p>
          </div>
        </div>
      </StyleHeight>
    </div>
  );
}

export default BuildingHeight;

const StyleHeight = styled.div`
  .grid-cards-height {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    margin: 5px;
  }
  .card-height {
    width: 90%;
    height: auto;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  }

  .grid-body {
    display: grid;
    grid-template-columns: 85% 15%;
  }
  @media (max-width: 1837px) {
    .title-card-height {
      font-size: 10px;
    }
    .img-card-height {
      height: 24px;
      width: 24px;
      border-radius: 50px;
    }
    .numbers {
      color: #121212;
      font-size: 10px;
    }
  }
  @media (min-width: 1838px) {
    .title-card-height {
      font-size: 15px;
    }
    .img-card-height {
      height: 28px;
      width: 28px;
      border-radius: 50px;
    }
    .numbers {
      color: #121212;
      font-size: 15px;
    }
  }
`;
