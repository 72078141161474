/** React components  */
import React, { useState, useEffect } from 'react';

import { Animated } from 'react-animated-css';

/** Import elements from library Antd */
import { Progress, Row, Col, Modal, Button, Upload, message } from 'antd';
import { CloseOutlined, CheckCircleOutlined } from '@ant-design/icons';

/** Redux */
import { useSelector } from 'react-redux';

import { openNotification } from '../../utils';
import logoMsProject from '../../assets/img/importer/msproject.png';
import logoOrPrimavera from '../../assets/img/importer/primavera.png';

import { withTranslation } from 'react-i18next';
/** impost sdk aws */
import AWS from 'aws-sdk';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../analytics/utils';
import { trackingEvent } from '../../analytics';
import { getImportableIconByFileExt } from '../../utils/importGanttUtils';
import {
  totangoEventTracking,
  totangoSetAccountAttributes
} from '../../analytics/implements/totango';
import { getSignedUser } from '../../utils/userUtils';
/** consts */
const colorBar = '#7DFF8A';

function ImportGanttUpload(props) {
  /** Project state from redux */
  const projectState = useSelector((state) => state.projectState);
  const lookaheadState = useSelector((state) => state.lookaheadState);
  const stateCompany = useSelector((state) => state.companyState);

  const [requestGlobal, setRequestGlobal] = useState(null);

  /** use props */
  const {
    t,
    setCurrenTab,
    currenTab,
    setVisibleImporter,
    setVisibleProcess,
    visibleImporter,
    setStateUpload,
    setFileInfo,
    fileInfo,
    startTimeUpload,
    setStartTimeUpload
  } = props;

  const headersSendForm = {
    Authorization:
      'Basic cHJvcGxhbm5lcjpiWGx0WVhOMFpYSnJaWGx0ZVcxaGMzUmxjbXRsZVcxNWJXRnpkR1Z5YTJWNU1USXpORFUyTnpnNU1UQmhZbU5rWldabmFHbHE=',
    enctype: 'multipart/form-data;',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Methods': 'POST,GET,OPTIONS,DELETE,PUT,HEAD,PATCH',
    'content-type': 'multipart/form-data'
  };

  // Bucket Configurations
  const bucketName = 'proplannerv2';
  const bucketRegion = 'us-east-2';
  const IdentityPoolId = 'us-east-2:550291a9-df58-45b4-a461-c8a5c504340e';

  AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IdentityPoolId
    })
  });

  const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: { Bucket: bucketName }
  });

  const s3upload = (fileToUp) => {
    const loggedUser = getSignedUser();
    const currentCompany = stateCompany.currentCompany;
    const project = projectState.allProjects.find(
      (p) => p.id == projectState.projectSelected
    );

    totangoSetAccountAttributes(
      loggedUser,
      projectState.projectSelected,
      currentCompany?.name,
      currentCompany?.id,
      project?.name,
      project?.stage,
      project?.country
    );

    totangoEventTracking(
      `p_${projectState.projectSelected}`,
      loggedUser,
      'Gantt Import',
      'Master Plan'
    );

    const projectName = projectState.allProjects.find(
      (pr) => pr.id === projectState.projectSelected
    ).name;
    const sectorName = projectState.allSectors.find(
      (e) => e.id == projectState.sectorSelected
    ).name;

    const ts = new Date().getTime();
    const file = fileToUp;
    const fileName = file.name;
    const filePath =
      `gantts/importer/${projectName}/${sectorName}/` + ts + '-' + fileName;
    setStartTimeUpload(() => new Date().getTime());
    setFileInfo({ name: fileName });
    const initialTime = new Date().getTime();
    setCurrenTab(2);
    const request = s3.putObject(
      {
        Key: filePath,
        Body: file,
        ACL: 'public-read'
      },
      (err, data) => {
        const s3url = s3.getSignedUrl('getObject', { Key: filePath });
        const s3urlsplit = s3url.split('?');
        setStateUpload({
          name: s3urlsplit[0],
          loading: false
        });
        if (err) {
          console.dir('error or manual abort');
        }
      }
    );
    setRequestGlobal(request);
    request.on('httpUploadProgress', (progress) => {
      console.dir('httpUploadProgress');
      console.dir(progress);
      const uploaded = parseInt((progress.loaded * 100) / progress.total);
      if (progress) {
        const timeElapsed = new Date().getTime() - new Date(initialTime);
        const uploadSpeed = progress.loaded / timeElapsed;
        const remaining = parseFloat(
          (progress.total - progress.loaded) / uploadSpeed / 1000
        ).toFixed(2);
        setTimeRemainingUpload(remaining); /** miliseconds */
        setPercentUpload(uploaded);
        if (uploaded === 100) {
          message.success(
            t('lang') === 'es'
              ? `${fileName} archivo subido con éxito`
              : `${fileName} file uploaded successfully`
          );
          setPercentUpload(0);
          setCurrenTab(3);
        }
      }
    });
  };

  /** hooks */
  const [percentUpload, setPercentUpload] = useState(0);
  const [timeRemainingUpload, setTimeRemainingUpload] = useState(0);

  const handleClose = () => {
    setVisibleImporter(false);
  };

  const prgUpload = {
    percent: percentUpload,
    strokeColor: {
      '0%': colorBar,
      '100%': colorBar
    },
    strokeWidth: 3,
    format: (percent) => `${parseFloat(percent.toFixed(2))}%`
  };

  const handleContinue = () => {
    setVisibleImporter(false);
    setVisibleProcess(true);
  };

  const buttonCancel = () => {
    setVisibleImporter(false);
    requestGlobal && requestGlobal.abort();
    setPercentUpload(0);
    setTimeRemainingUpload(0);
    setStateUpload({
      loading: false
    });
  };

  const trackingScheduleImportation = (
    format_selected,
    format_imported,
    event_result
  ) => {
    trackingEvent(
      'schedule_importation',
      {
        ...getBasicAmplitudEventProperties(),
        format_selected: format_selected,
        format_imported: format_imported,
        event_result: event_result
      },
      AMPLITUDE_SERVICE
    );
  };

  return (
    <Animated animationIn="fadeIn" animationInDuration={500} isVisible={true}>
      <Modal
        closable={false}
        className="frm-importer"
        title=""
        visible={visibleImporter}
        centered
        keyboard={false}
        maskClosable={false}
        onCancel={handleClose}
        width={640}
        footer={<div></div>}>
        <Row className="">
          <div className="title-frm">{t('import.gantt_import')}</div>
          <div className="hand-close" onClick={handleClose}>
            <CloseOutlined />
          </div>
        </Row>

        <div className={`body-modal ${currenTab === 1 ? null : 'hidden'}`}>
          <Row className="row-normal">
            <span className="text-title">{t('import.select_origin')}</span>
          </Row>
          <Row className="row-normal">
            <Upload
              accept=".mpp,.xer"
              listType="picture-card"
              showUploadList={false}
              beforeUpload={(file) => {
                const isValid =
                  file?.name.toLowerCase().includes('.mpp') ||
                  file?.name.toLowerCase().includes('.xer');
                if (!isValid) {
                  openNotification({
                    title: t('modules.masterplan'),
                    description: t('master_plan.warning_import_no_valid'),
                    type: 'warning'
                  });
                  return false;
                }
                try {
                  s3upload(file);
                  trackingScheduleImportation(
                    'Ms Project',
                    file?.name.toLowerCase().includes('.mpp')
                      ? '.mpp'
                      : file?.name.toLowerCase().includes('.xer')
                        ? '.xer'
                        : 'other',
                    'Successfull'
                  );
                } catch {
                  trackingScheduleImportation(
                    'Ms Project',
                    file?.name.toLowerCase().includes('.mpp')
                      ? '.mpp'
                      : file?.name.toLowerCase().includes('.xer')
                        ? '.xer'
                        : 'other',
                    'Failed'
                  );
                }
                return false;
              }}
              progress={prgUpload}
              headers={headersSendForm}>
              <Button className="btn-import">
                <div className="div-vertical">
                  <img className="img-upload" src={logoMsProject} />
                  Microsoft Project
                </div>
              </Button>
            </Upload>

            <Upload
              accept=".mpp,.xer"
              listType="picture-card"
              showUploadList={false}
              beforeUpload={(file) => {
                const isValid =
                  file?.name.toLowerCase().includes('.mpp') ||
                  file?.name.toLowerCase().includes('.xer');
                if (!isValid) {
                  openNotification({
                    title: t('modules.masterplan'),
                    description: t('master_plan.warning_import_no_valid'),
                    type: 'warning'
                  });
                  return false;
                }
                try {
                  s3upload(file);
                  trackingScheduleImportation(
                    'Primavera P6',
                    file?.name.toLowerCase().includes('.mpp')
                      ? '.mpp'
                      : file?.name.toLowerCase().includes('.xer')
                        ? '.xer'
                        : 'other',
                    'Successfull'
                  );
                } catch {
                  trackingScheduleImportation(
                    'Primavera P6',
                    file?.name.toLowerCase().includes('.mpp')
                      ? '.mpp'
                      : file?.name.toLowerCase().includes('.xer')
                        ? '.xer'
                        : 'other',
                    'Failed'
                  );
                }
                return false;
              }}
              progress={prgUpload}
              headers={headersSendForm}>
              <Button className="btn-import">
                <div className="div-vertical">
                  <img className="img-upload" src={logoOrPrimavera} />
                  Oracle Primavera
                </div>
              </Button>
            </Upload>
          </Row>
        </div>

        <div className={`body-modal ${currenTab === 2 ? null : 'hidden'}`}>
          <Row className="row-normal">
            <span className="text-title">{t('import.loading_gantt')}</span>
            <div className="text-remaining">
              {t('import.left_five_min')} {timeRemainingUpload}s
            </div>
            <div className="text-filename">
              <img
                className="img-upload"
                src={getImportableIconByFileExt(fileInfo?.name)}
              />
              {fileInfo?.name}
            </div>
            <div className="div-progress">
              <Progress {...prgUpload} />
            </div>
            <Button className="btn-cancel" onClick={buttonCancel}>
              {t('import.cancel_load')}
            </Button>
          </Row>
        </div>

        <div className={`body-modal ${currenTab === 3 ? null : 'hidden'}`}>
          <Row className="row-normal">
            <div className="text-success">{t('import.load_complete')}</div>
            <div className="div-circle">
              <CheckCircleOutlined className="circle-ok" />
            </div>
            <div>
              <Button className="btn-continue" onClick={() => handleContinue()}>
                {t('import.continue_load')}
              </Button>
            </div>
          </Row>
        </div>
      </Modal>
    </Animated>
  );
}

export default withTranslation()(ImportGanttUpload);
