import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react';
import bars from './bars.json';
import styles from './style.module.scss';

const LOADER_ID = styles.loader;

function getLoader() {
  return document.getElementById(LOADER_ID);
}

function setLoaderStyle(property, value) {
  const loader = getLoader();
  if (!loader) {
    return;
  }
  loader.style[property] = value;
}

function setLoaderDisplay(display) {
  setLoaderStyle('display', display);
}

function setLoaderBackground(color) {
  setLoaderStyle('background', color);
}

function isLoaderDisplayed() {
  const loader = getLoader();
  if (!loader) {
    return false;
  }
  return loader.style.display === 'flex';
}

function Loader() {
  const history = useHistory();

  useEffect(() => {
    window.loader = {
      hide: () => {
        setLoaderDisplay('none');
      },
      show: () => {
        setLoaderDisplay('flex');
      },
      setBackground: (color) => {
        setLoaderBackground(color);
      }
    };
  }, []);

  useEffect(() => {
    history.listen(() => {
      if (isLoaderDisplayed()) {
        setLoaderDisplay('none');
      }
    });
  }, [history]);

  return (
    <div id={styles.loader} className={styles.loader}>
      <Lottie
        animationData={bars}
        loop={true}
        style={{ width: 400, height: 400 }}
      />
    </div>
  );
}

export default Loader;
