/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
import moment from 'moment';

class HistoricalActivityProgressService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(historicalActivityProgressId) {
    const res = await this.http
      .get('historicalActivityProgresss/' + historicalActivityProgressId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(historicalActivityProgressId) {
    const res = await this.http
      .delete('historicalActivityProgresss/' + historicalActivityProgressId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(historicalActivityProgress) {
    const copy = { ...historicalActivityProgress };
    copy.created = moment(new Date()).format('YYYY/MM/DD H:mm:ss');
    const res = await this.http
      .post('historicalActivityProgresss', copy)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(historicalActivityProgress) {
    const copy = { ...historicalActivityProgress };
    copy.created = moment(new Date()).format('YYYY/MM/DD H:mm');
    const res = await this.http
      .put('historicalActivityProgresss/' + historicalActivityProgress.id, copy)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async massiveCreate(allHistoricals) {
    const res = await this.http
      .post('historicalActivityProgresss/all', { historicals: allHistoricals })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showProgressReal(sector_id, star, end) {
    const res = await this.http
      .get(
        'historicalActivityProgresss/showprogress/' +
          sector_id +
          '/' +
          star +
          '/' +
          end
      )
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}
export const historicalActivityProgressService =
  new HistoricalActivityProgressService();
