import React, { useEffect, useState } from 'react';
import useWeeklyplan from './useWeeklyplan';
import { useSelector } from 'react-redux';
import { getTodayWeekWithPdayAndCurrentDay } from '../utils';
import {
  checkIfExistsWeeklyPlan,
  saveWeeklyPlan
} from '../views/weeklyPlan/weeklyPlan.helper';
import {
  calculateCommitmentPercentaje,
  calculateExpectedForWeek,
  checkMarkersByDates,
  initialStatus,
  updateTaskWeekly
} from '../utils/lookahead-common';
import moment from 'moment';
import { sectorService } from '../services';
/**
 *
 * @param {*} hasCustomHours object {startHour, endHour} will be setted to the start and end given
 */
export default function useCheckForwardWeekly(hasCustomHours = false) {
  const projectState = useSelector((state) => state.projectState);
  const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
  const [toReturn, setToReturn] = useState({
    shouldGoForward: false,
    weeklyRangeObject: null,
    range: {
      week: null
    }
  });

  const weeklyRangeObject = useWeeklyplan(hasCustomHours);

  useEffect(() => {
    const asyncF = async (_) => {
      if (!weeklyRangeObject || !currentSector) return;
      const { didCloseWeek, currentClosedWeek } = currentSector;
      // console.log('should define if is possible go forward one week')
      if (didCloseWeek) {
        const currentWeek = weeklyRangeObject.range.week;
        const maxDiffWeek = currentClosedWeek - currentWeek;
        const isYearChange = maxDiffWeek > 10;
        if (didCloseWeek && currentClosedWeek + 1 === currentWeek) {
          const currentSector = JSON.parse(
            sessionStorage.getItem('currentSector')
          );
          const finalData = { ...currentSector };
          finalData.currentClosedWeek = null;
          finalData.didCloseWeek = false;
          /** Data should be saved */
          await sectorService.update(finalData);
          sessionStorage.setItem('currentSector', JSON.stringify(finalData));
        } else if (currentClosedWeek >= currentWeek && !isYearChange) {
          const forwardOneWeek = moment().add('weeks', 1);
          const objectRange = getTodayWeekWithPdayAndCurrentDay(
            projectState,
            forwardOneWeek
          );
          const objectRangeData = objectRange[3];
          const project = projectState?.allProjects?.find(
            (pr) => pr.id === projectState.projectSelected
          );
          const planificationDay = project?.planification_day;
          if (!planificationDay) return;
          const { startMarker, finalEndMarker } = checkMarkersByDates(
            planificationDay,
            objectRangeData,
            hasCustomHours
          );
          setToReturn({
            shouldGoForward: true,
            actualWeeklyRangeObject: weeklyRangeObject,
            start_date: startMarker,
            end_date: finalEndMarker,
            range: objectRangeData,
            checkIfExistsWeeklyPlan,
            saveWeeklyPlan,
            calculateExpectedForWeek,
            updateTaskWeekly,
            initialStatus: initialStatus(startMarker, finalEndMarker),
            calculateCommitmentPercentaje
          });
        }
      }
    };
    asyncF();
  }, [weeklyRangeObject]);

  return toReturn;
}
