export const ACTIONS = {
  MATRIX: 'matrix',
  WORKER_LIST: 'listWorkers',
  WORKER_HOURS: 'listWorkerHours',
  HEADER: 'resourceHeader'
};

export default {
  [ACTIONS.MATRIX]: ({ header, list, listHours, scrollLeft, scrollTop }) => {
    if (header.current) {
      header.current.scrollTo({ scrollLeft: scrollLeft, scrollTop: 0 });
    }
    if (list.current) {
      list.current.scrollTo({ scrollTop: scrollTop, scrollLeft: 0 });
    }
    if (listHours.current) {
      listHours.current.scrollTo({ scrollTop: scrollTop, scrollLeft: 0 });
    }
  },
  [ACTIONS.WORKER_LIST]: ({ matrix, listHours, scrollTop }) => {
    if (matrix.current) {
      matrix.current.scrollTo({ scrollTop });
    }
    if (listHours.current) {
      listHours.current.scrollTo({ scrollTop: scrollTop, scrollLeft: 0 });
    }
  },
  [ACTIONS.WORKER_HOURS]: ({ matrix, list, scrollTop }) => {
    if (matrix.current) {
      matrix.current.scrollTo({ scrollTop });
    }
    if (list.current) {
      list.current.scrollTo({ scrollTop: scrollTop, scrollLeft: 0 });
    }
  },
  [ACTIONS.HEADER]: ({ matrix, scrollLeft }) => {
    if (matrix.current) {
      matrix.current.scrollTo({ scrollLeft });
    }
  }
};
