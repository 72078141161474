/**
 * This function adds time to the constraint_date according to the constraint_type
 * @param {*} task Task object to add hour
 * @param {*} calendar Task object calendar to adjust constraint date
 */
const addHoursToConstraintDate = (task, calendar, gantt) => {
  // According to the type of constraint, the time will be assigned to the constraint_date
  if (!task.constraint_type) return;
  let dir = 'future';
  if (['alap', 'mfo', 'fnlt', 'fnet'].includes(task.constraint_type))
    dir = 'past';
  const newConstraintDate = new Date(
    task.constraint_date?.getTime() + 24 * 60 * 60 * 1000
  );
  newConstraintDate.setHours(0);
  newConstraintDate.setMinutes(0);
  if (task.constraint_date) {
    task.constraint_date = calendar.getClosestWorkTime({
      date: dir === 'past' ? newConstraintDate : task.constraint_date,
      unit: gantt.config.duration_unit,
      dir
    });
  }
};

export { addHoursToConstraintDate };
