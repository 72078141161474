import React from 'react';
import { Button } from 'antd';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import './MainRouteHome.css';

export default function MainRouteHome(props) {
  const { height, width } = useWindowDimensions();
  return (
    <div style={{ alignContent: 'center', backgroundColor: 'white' }}>
      <div className="center-container title-style">{props.menu.title}</div>
      <div className="center-container sub-title">{props.menu.subTitle}</div>
      {props.menu.buttons.map((btn, index) => (
        <div
          className="center-container"
          style={{ marginTop: '1%' }}
          key={index}>
          <Button
            key={index}
            className="common-btn-style btnblock"
            type="primary"
            onClick={btn.onClick}>
            {btn.btnTitle}
          </Button>
        </div>
      ))}
    </div>
  );
}
