export const SET_CNC_ACTIVATED_COLS = 'SET_CNC_ACTIVATED_COLS';
export const NOTIFY_LOOKAHEAD_UPDATE_CNC = 'NOTIFY_LOOKAHEAD_UPDATE_CNC';
export const NOTIFY_LOOKAHEAD_UPDATE_ORDER_CNC =
  'NOTIFY_LOOKAHEAD_UPDATE_ORDER_CNC';
export const NOTIFY_LOOKAHEAD_UPDATE_FILTER_CNC =
  'NOTIFY_LOOKAHEAD_UPDATE_FILTER_CNC';
export const NOTIFY_LOOKAHEAD_UPDATE_GROUP_CNC =
  'NOTIFY_LOOKAHEAD_UPDATE_GROUP_CNC';

export const cncActions = {
  setActivatedColumns: (activatedColumns) => ({
    type: SET_CNC_ACTIVATED_COLS,
    payload: activatedColumns
  }),
  notifyLookaheadUpdate: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_CNC,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateOrder: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_ORDER_CNC,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateFilter: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_FILTER_CNC,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateGroup: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_GROUP_CNC,
    payload: { boolean: true }
  })
};
