import { reversPathTasks } from '../../../../utils';
import { splitTextHTML } from '../../../../utils/lookahead-common';
import { findDeepGetActivityOfTask } from '../../../../views/lookahead/planification/index.helper';
import { findDeepGetTask } from '../../GanttVisualization.helper';

const RenderRoute = ({ task, column, activities, gantt }) => {
  if (!task.isTask) return '';
  if (task.is_milestone) return '';

  /** get activity reference */
  const findAct = findDeepGetActivityOfTask(window.activities, 'id', task.id);
  let activityReference;
  if (findAct) activityReference = findAct;
  if (!activityReference) return '';

  /** get reference task (taskFromLookahead) from activities */
  const taskFromLookahead = findDeepGetTask(activities, 'id', task.id);
  if (!taskFromLookahead) return '';

  let isOdd = false;
  if (gantt?.oddColsConfig && task.type === 'activitytask') {
    isOdd = gantt.oddColsConfig[column.name];
  }
  return `<div class=${isOdd ? 'odd-col' : 'non-odd-col'}>
            <span>
                ${splitTextHTML(
                  reversPathTasks(
                    `${activityReference.activityRoute} > ${activityReference.name}`
                  ),
                  column,
                  task?.id
                )}
            </span>
        </div>`;
};

export default RenderRoute;
