import React from 'react';
import { Dropdown, Menu } from 'antd';
import { ArrowDropdownIcon } from '../../../icons';

const { SubMenu } = Menu;

const DEFAULT_HEIGHT = 24;
const DEFAULT_THEME = 'light';
const VERSION_V2 = 'v2';
const BLACK_COLOR = '#121212';
const WHITE_COLOR = '#FFFFFF';

const DropdownSystemV2 = ({
  theme = DEFAULT_THEME,
  items,
  version,
  label,
  height,
  isActive,
  icon = null,
  isError = false,
  ...props
}) => {
  const subMenuItem = (item) => (
    <SubMenu
      key={item.key}
      title={item.label}
      popupClassName={`dropdown-design-system-v2__submenu theme--${theme} ${version || ''}`}
      disabled={item.isDisabled}>
      {item.subitems.map((subitem) => (
        <Menu.Item
          key={subitem.key}
          className={`${subitem.isActive ? 'active' : ''} ${subitem.isDivider ? 'divider' : ''} ${subitem.isGroup ? 'group' : ''} ${subitem.icon ? 'icon' : ''}`}
          disabled={subitem.isDisabled}
          onClick={() =>
            !subitem.isDisabled &&
            !subitem.isDivider &&
            !subitem.isGroup &&
            subitem.onClick &&
            subitem.onClick()
          }>
          <div className="subitem__content">
            {subitem.isDivider ? (
              <div className="content__divider" />
            ) : (
              <>
                <div className="content__info">
                  {subitem.icon && (
                    <div className="content__icon">{subitem.icon}</div>
                  )}
                  <span>{subitem.label}</span>
                </div>
                {subitem.isGroup && <div className="content__group" />}
              </>
            )}
          </div>
        </Menu.Item>
      ))}
    </SubMenu>
  );

  const menuItem = (item) => (
    <Menu.Item
      key={item.key}
      className={`${item.isActive ? 'active' : ''} ${item.isDivider ? 'divider' : ''} ${item.isGroup ? 'group' : ''} ${item.icon ? 'icon' : ''}`}
      disabled={item.isDisabled}
      onClick={() =>
        !item.isDisabled &&
        !item.isDivider &&
        !item.isGroup &&
        item.onClick &&
        item.onClick()
      }>
      <div className="item__content">
        {item.isDivider ? (
          <div className="content__divider" />
        ) : (
          <>
            <div className="content__info">
              {item.icon && <div className="content__icon">{item.icon}</div>}
              <span>{item.label}</span>
            </div>
            {item.isGroup && <div className="content__group" />}
          </>
        )}
      </div>
    </Menu.Item>
  );

  const menu = (
    <Menu>
      {items.map((item) =>
        item.subitems.length > 0 ? subMenuItem(item) : menuItem(item)
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlayClassName={`dropdown-design-system-v2 theme--${theme} ${version || ''}`}
      overlay={menu}
      {...props}>
      <div
        className={`dropdown-design-system-v2__label theme--${theme} ${version || ''} ${isActive ? 'active' : ''} ${isError ? 'error' : ''}`}
        style={{ height: height || DEFAULT_HEIGHT }}>
        <div className="label__info">
          {icon && <div className="info__icon">{icon}</div>}
          <span>{label}</span>
        </div>
        <div className="label__icon">
          <ArrowDropdownIcon
            color={
              theme === DEFAULT_THEME
                ? BLACK_COLOR
                : version === VERSION_V2
                  ? BLACK_COLOR
                  : WHITE_COLOR
            }
          />
        </div>
      </div>
    </Dropdown>
  );
};

export default DropdownSystemV2;
