/* eslint-disable max-lines-per-function */
import React from 'react';
import InputSystem from '../../../DesignSystem/InputSystem';

import { ARROW_ICON } from '../constants';
import {
  Types,
  codeCountries,
  projectCurrency,
  projectSizesTypes
} from '../../../../utils';
import ProjectImageSection from './ProjectImageSection';
import DetailSection from './DetailSection';
import NumberFormat from 'react-number-format';

const ProjectDetails = ({
  t,
  state,
  setField,
  toggleMoreDetails,
  handleChange,
  handleImageChange
}) => (
  <div className="form__input">
    <label className="toggle" onClick={toggleMoreDetails}>
      {t('add_project_modal.more_details')}{' '}
      {ARROW_ICON(state.moreDetailsExpanded)}
    </label>
    {state.moreDetailsExpanded && (
      <div className="form__input--more-details">
        <ProjectImageSection
          t={t}
          state={state}
          setField={setField}
          handleImageChange={handleImageChange}
        />

        <div className="form__input--more-details__inputs">
          <div className="form__input--more-details__inputs__container">
            <div className="form__input_project_type_field">
              <DetailSection
                t={t}
                label={t('add_project_modal.project_type_field')}
                dropdownData={Types}
                stateField={state.projectType}
                updateField={(newValue) => setField('projectType', newValue)}
                keyProp="value"
                labelProp="trad"
                translationPrefix="settings.project_form"
              />
            </div>

            <div className="form__input_location_field">
              <DetailSection
                t={t}
                label={t('add_project_modal.location_field')}
                dropdownData={codeCountries}
                stateField={state.location}
                updateField={(newValue) => setField('location', newValue)}
                keyProp="code"
                labelProp="name"
              />
            </div>
          </div>

          <div className="form__input--more-details__inputs__container">
            <div className="form__input_currency_field">
              <DetailSection
                t={t}
                label={t('add_project_modal.currency_field')}
                dropdownData={projectCurrency(t)}
                stateField={state.currency}
                updateField={(newValue) => setField('currency', newValue)}
                keyProp="value"
                labelProp="value"
              />
            </div>

            <div className="form__input_budget_field">
              <label>{t('add_project_modal.budget_field')} </label>
              <NumberFormat
                name="budget"
                displayType={'input'}
                thousandSeparator={t('lang').includes('en') ? ',' : '.'}
                prefix={''}
                decimalSeparator={t('lang').includes('en') ? '.' : ','}
                decimalScale={2}
                allowNegative={false}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </div>
          </div>

          <div className="form__input--more-details__inputs__container">
            <div className="form__input_unit_field">
              <DetailSection
                t={t}
                label={t('add_project_modal.unit_field')}
                dropdownData={projectSizesTypes}
                stateField={state.unit}
                updateField={(newValue) => setField('unit', newValue)}
                keyProp="value"
                labelProp="value"
              />
            </div>

            <div className="form__input_size_field">
              <label>{t('add_project_modal.size_field')} </label>
              <NumberFormat
                name="size"
                displayType={'input'}
                thousandSeparator={t('lang').includes('en') ? ',' : '.'}
                prefix={''}
                decimalSeparator={t('lang').includes('en') ? '.' : ','}
                decimalScale={2}
                allowNegative={false}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

export default ProjectDetails;
