/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class WorkingDayService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(workingdayId) {
    const res = await this.http
      .get('workingdays/' + workingdayId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(workingdayId) {
    const res = await this.http
      .delete('workingdays/' + workingdayId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(workingday) {
    const res = await this.http
      .post('workingdays', workingday)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(workingday) {
    const res = await this.http
      .put('workingdays/' + workingday.id, workingday)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const workingdayService = new WorkingDayService();
