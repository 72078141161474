/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
import { base } from './base';
import axios from 'axios';

const url = 'https://customerapi.geovictoria.com/api/v1/';
const baseURL = base.apiTakt;
class GeoVictoriaService {
  constructor() {
    this.http = createHttpClient({ baseURL });
  }

  async getGeoData(data) {
    const res = await this.http
      .get(
        'integrations/geovictoria/get/' + data.projectState + '/' + data.month,
        data
      )
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getGeoUsersData(data) {
    const res = await this.http
      .get('integrations/geovictoria/getusers/' + data.projectState, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async createTaskGeo(data) {
    const res = await this.http
      .post('integrations/geovictoria/add/', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  /* async save (data) {
        const res = await this.http.post('gantt/importer_save', data).catch((error) => { console.log(error.response); return false })
        return res ? res.data : res
    } */
}

export const geoVictoriaService = new GeoVictoriaService();
