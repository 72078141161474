import { constraintStatus } from '../../../utils';

/** Import PNG for flag */
import flagGrey from '../../../assets/img/PR-Gray-50x50.png';
import flagBlue from '../../../assets/img/PR-Blue-50x50.png';
import flagYellow from '../../../assets/img/PR-Yellow-50x50.png';
import flagRed from '../../../assets/img/PR-Red-50x50.png';

/** Config object Table Constraints */

/** Array UI options for Priority */
export const arr_priority = [
  {
    icon: flagGrey,
    color: 'lightgrey',
    value: 'low',
    label: 'Baja',
    weigth: 3
  },
  {
    icon: flagBlue,
    color: 'darkturquoise',
    value: 'normal',
    label: 'Normal',
    weigth: 2
  },
  {
    icon: flagYellow,
    color: 'orange',
    value: 'high',
    label: 'Alta',
    weigth: 1
  },
  {
    icon: flagRed,
    color: 'red',
    value: 'urgent',
    label: 'Urgente',
    weigth: 0
  }
];

/** Metadata for table Constraints */
export const tableMetadata = [
  {
    name: 'name',
    label: 'Restricción',
    data_type: 'string',
    visible: true,
    ignore_as_column: true,
    span: 2,
    offset: 0,
    align: 'left',
    width: 400,
    from_values: constraintStatus,
    groupable: false,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true
  },
  {
    name: 'description',
    label: 'Descripción',
    data_type: 'string',
    visible: false,
    span: 2,
    offset: 0,
    align: 'left',
    width: 200,
    groupable: false,
    from_values: 'description',
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true
  },
  {
    name: 'constraintTypeId',
    label: 'Tipo',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 180,
    groupable: true,
    from_values: [],
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true,
    mode: 'single'
  },
  {
    name: 'responsables',
    label: 'Responsable',
    data_type: 'array/images',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    mode: 'multiple',
    from_values: 'toSelectResponsables',
    el_to_extract_from: 'email',
    el_to_label_from: ['name', 'lastname'],
    img_from: 'image',
    selectField: 'id',
    width: 140,
    ref: 'responsables',
    refId: 'userId',
    groupable: true,
    groupable_switch: ['A → Z', 'Z → A'],
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true
  },
  {
    name: 'status',
    label: 'Estado',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 140,
    from_values: constraintStatus,
    groupable: true,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true
  },
  {
    name: 'typeArea',
    label: 'Area',
    data_type: 'array/string',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 100,
    groupable: false,
    from_values: [],
    orderable: false,
    filterable: false,
    mode: 'single'
  },
  {
    name: 'reportusers',
    label: 'Reportar a',
    data_type: 'array/images',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    mode: 'multiple',
    from_values: 'toSelectResponsables',
    el_to_extract_from: 'email',
    img_from: 'image',
    selectField: 'id',
    width: 140,
    ref: 'reportusers',
    refId: 'report_user',
    filterable: true,
    el_to_label_from: ['name', 'lastname'],
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    groupable: true,
    groupable_switch: ['A → Z', 'Z → A']
  },
  {
    name: 'commitmentDate',
    label: 'Fecha de Compromiso',
    data_type: 'date',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 200,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true
  },
  {
    name: 'trending',
    label: 'Trending',
    data_type: 'number',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 200,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true
  },
  {
    name: 'priority',
    label: 'Prioridad',
    data_type: 'array/icon',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 140,
    from_values: arr_priority,
    groupable: true,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true
  },
  {
    name: 'tasks',
    label: 'Tareas',
    data_type: 'string',
    visible: true,
    el_to_extract_from: 'name',
    span: 3,
    offset: 0,
    align: 'center',
    mode: 'single',
    width: 140,
    filterable: true,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A']
  },
  {
    name: 'schedules',
    label: 'Reprogramaciones',
    data_type: 'string',
    visible: false,
    span: 3,
    offset: 0,
    align: 'center',
    mode: 'single',
    width: 140
  },
  {
    name: 'release_date',
    label: 'Fecha de Liberación',
    data_type: 'date',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 140,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true
  },
  {
    name: 'taskRoute',
    label: 'Ruta',
    data_type: 'string',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true,
    felixable: true
  },
  {
    name: 'document',
    label: 'Document',
    data_type: 'string',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: false,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: false
  }
];
