import React, { useState } from 'react';

import ActivityTasks from './components/ActivityTask';
import Timeline from './components/Timeline';
import Rfv from './components/rfv';

import styles from './Commitments.module.scss';
import { Tooltip } from 'antd';

const CommitmentActivity = (props) => {
  const {
    activity,
    activity: { tasks },
    calendars,
    isProgressColumns,
    planificationDay,
    resources,
    week,
    year,
    handleAddCnc,
    tasksAddedCnc,
    cncListRq,
    weekcommitment,
    t,
    permission,
    subtradeRole
  } = props;

  const [isChangeProgress, setIsChangeProgress] = useState(false);
  const [onlyRead, setOnlyRead] = useState(permission == 'V');
  return (
    <div className={styles.commitment__row}>
      <div className={styles.activity__container}>
        <div className={styles.activity}>
          {/* <Checkbox /> */}
          <span>{activity.correlative_id} | </span>
          {(activity.name + activity.activityRoute).length < 115 ? (
            <span>
              {activity.name}
              <span style={{ marginLeft: 10, fontWeight: 400 }}>
                {activity.activityRoute.split(' > ').reverse().join(' > ')}
              </span>
            </span>
          ) : activity.name.length > 115 ? (
            <Tooltip
              placement="top"
              title={
                activity.name +
                ' - ' +
                activity.activityRoute.split(' > ').reverse().join(' > ')
              }>
              <span>{activity.name.substring(0, 115) + '...'}</span>
            </Tooltip>
          ) : (
            <Tooltip
              placement="top"
              title={
                activity.name +
                ' - ' +
                activity.activityRoute.split(' > ').reverse().join(' > ')
              }>
              <span>
                {activity.name}
                <span style={{ marginLeft: 10, fontWeight: 400 }}>
                  {activity.activityRoute
                    .split(' > ')
                    .reverse()
                    .join(' > ')
                    .substring(0, 115 - activity.name.length) + '...'}
                </span>
              </span>
            </Tooltip>
          )}
        </div>
        <ActivityTasks
          subtradeRole={subtradeRole}
          resources={resources}
          isProgress={isProgressColumns.progress}
          tasks={tasks}
          isChangeProgress={isChangeProgress}
          setIsChangeProgress={setIsChangeProgress}
          activity={activity}
          onlyRead={onlyRead}
        />
      </div>
      <div className={styles.timeline__container}>
        <div className={styles.activity}></div>
        <Timeline
          subtradeRole={subtradeRole}
          tasks={tasks}
          activity={activity}
          week={week}
          year={year}
          calendars={calendars}
          planificationDay={planificationDay}
          onlyRead={onlyRead}
        />
      </div>
      <div className={styles.rfv__container}>
        <div className={styles.activity}></div>
        <Rfv
          subtradeRole={subtradeRole}
          tasks={tasks}
          handleAddCnc={handleAddCnc}
          tasksAddedCnc={tasksAddedCnc}
          cncListRq={cncListRq}
          weekcommitment={weekcommitment}
          isChangeProgress={isChangeProgress}
          t={t}
          onlyRead={onlyRead}
        />
      </div>
    </div>
  );
};

export default CommitmentActivity;
