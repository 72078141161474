const TASK_PROGRESS_COMPLETE = 100;
const TASK_PROGRESS_EMPTY = 0;

export const taskRowClass = (start, end, task) => {
  const baseClass = 'task-row';

  if (task.open === false || task.parent) {
    return baseClass;
  }

  if (task.progress === TASK_PROGRESS_EMPTY) {
    return `${baseClass} task-empty`;
  }

  if (task.progress === TASK_PROGRESS_COMPLETE) {
    return `${baseClass} task-completed`;
  }

  return `${baseClass} task-advanced`;
};
