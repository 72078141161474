import { roleTypes } from './role.constants';
import WeeklyCommitReport from '../views/report/WeeklyCommitReport';

export const MODULE_MASTERPLAN_SCHEDULE = 'masterplan-schedule';
export const MODULE_MASTERPLAN_RESOURCES = 'masterplan-resources';
export const MODULE_LOOKAHEAD_PLANNING = 'lookahead-planning';
export const MODULE_LOOKAHEAD_ROADBLOCKS = 'lookahead-roadblocks';
export const MODULE_WEEKLYPLAN_WEEKLYPLAN = 'weeklyplan-weeklyplan';
export const MODULE_WEEKLYPLAN_RFV = 'weeklyplan-rfv';
export const MODULE_ANALYTICS = 'analytics';
export const MODULE_SETTINGS_USER = 'settings-user';
export const MODULE_SETTINGS_PROJECT = 'settings-project';
export const MODULE_SETTINGS_ORGANIZATION = 'settings-organization';
export const MODULE_EXPORT_WEEKLYREPORT = 'export-weeklyreport';

export const modulesInfo = Object.freeze({
  [MODULE_MASTERPLAN_SCHEDULE]: {
    allowedRoles: {
      [roleTypes.SUPERADMIN]: true,
      [roleTypes.ADMIN]: true,
      [roleTypes.PROJECTLEADER]: true,
      [roleTypes.PLANNER]: true,
      [roleTypes.SUPERINTENDENT]: true,
      [roleTypes.SUBTRADE]: true,
      [roleTypes.CLIENT]: true,
      [roleTypes.MANAGER]: true
    }
  },
  [MODULE_MASTERPLAN_RESOURCES]: {
    allowedRoles: {
      [roleTypes.SUPERADMIN]: true,
      [roleTypes.ADMIN]: true,
      [roleTypes.PROJECTLEADER]: true,
      [roleTypes.PLANNER]: true,
      [roleTypes.SUPERINTENDENT]: true,
      [roleTypes.SUBTRADE]: true,
      [roleTypes.MANAGER]: true
    }
  },
  [MODULE_LOOKAHEAD_PLANNING]: {
    allowedRoles: {
      [roleTypes.SUPERADMIN]: true,
      [roleTypes.ADMIN]: true,
      [roleTypes.PROJECTLEADER]: true,
      [roleTypes.PLANNER]: true,
      [roleTypes.SUPERINTENDENT]: true,
      [roleTypes.SUBTRADE]: true,
      [roleTypes.LASTPLANNER]: true,
      [roleTypes.MANAGER]: true
    }
  },
  [MODULE_LOOKAHEAD_ROADBLOCKS]: {
    allowedRoles: {
      [roleTypes.SUPERADMIN]: true,
      [roleTypes.ADMIN]: true,
      [roleTypes.PROJECTLEADER]: true,
      [roleTypes.PLANNER]: true,
      [roleTypes.SUPERINTENDENT]: true,
      [roleTypes.SUBTRADE]: true,
      [roleTypes.LASTPLANNER]: true,
      [roleTypes.MANAGER]: true
    }
  },
  [MODULE_WEEKLYPLAN_WEEKLYPLAN]: {
    allowedRoles: {
      [roleTypes.SUPERADMIN]: true,
      [roleTypes.ADMIN]: true,
      [roleTypes.PROJECTLEADER]: true,
      [roleTypes.PLANNER]: true,
      [roleTypes.SUPERINTENDENT]: true,
      [roleTypes.SUBTRADE]: true,
      [roleTypes.LASTPLANNER]: true,
      [roleTypes.MANAGER]: true
    }
  },
  [MODULE_WEEKLYPLAN_RFV]: {
    allowedRoles: {
      [roleTypes.SUPERADMIN]: true,
      [roleTypes.ADMIN]: true,
      [roleTypes.PROJECTLEADER]: true,
      [roleTypes.PLANNER]: true,
      [roleTypes.SUPERINTENDENT]: true,
      [roleTypes.SUBTRADE]: true,
      [roleTypes.LASTPLANNER]: true,
      [roleTypes.MANAGER]: true
    }
  },
  [MODULE_ANALYTICS]: {
    allowedRoles: {
      [roleTypes.SUPERADMIN]: true,
      [roleTypes.ADMIN]: true,
      [roleTypes.PROJECTLEADER]: true,
      [roleTypes.PLANNER]: true,
      [roleTypes.SUPERINTENDENT]: true,
      [roleTypes.SUBTRADE]: true,
      [roleTypes.LASTPLANNER]: true,
      [roleTypes.MANAGER]: true
    }
  },
  [MODULE_SETTINGS_USER]: {
    allowedRoles: {
      [roleTypes.SUPERADMIN]: true,
      [roleTypes.ADMIN]: true,
      [roleTypes.PROJECTLEADER]: true,
      [roleTypes.PLANNER]: true,
      [roleTypes.SUPERINTENDENT]: true,
      [roleTypes.MANAGER]: true
    }
  },
  [MODULE_SETTINGS_PROJECT]: {
    allowedRoles: {
      [roleTypes.SUPERADMIN]: true,
      [roleTypes.ADMIN]: true,
      [roleTypes.PROJECTLEADER]: true,
      [roleTypes.MANAGER]: true
    }
  },
  [MODULE_SETTINGS_ORGANIZATION]: {
    allowedRoles: {
      [roleTypes.SUPERADMIN]: true,
      [roleTypes.ADMIN]: true,
      [roleTypes.MANAGER]: true
    }
  },
  [MODULE_EXPORT_WEEKLYREPORT]: {
    path: '/:organizationId/project/:projectId/schedule/:scheduleId/export/weekly',
    component: WeeklyCommitReport,
    allowedRoles: {
      [roleTypes.SUPERADMIN]: true,
      [roleTypes.ADMIN]: true,
      [roleTypes.PROJECTLEADER]: true,
      [roleTypes.PLANNER]: true,
      [roleTypes.SUPERINTENDENT]: true,
      [roleTypes.SUBTRADE]: true,
      [roleTypes.LASTPLANNER]: true,
      [roleTypes.MANAGER]: true
    }
  }
});

export const modulesInfoValues = Object.values(modulesInfo);
export const modulesInfoEntries = Object.entries(modulesInfo);
export const findModuleByPath = (path) =>
  modulesInfoEntries.find(([, { path: modulePath }]) => modulePath === path);
