/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class AnalyticResourceService {
  constructor() {
    this.http = createHttpClient();
  }

  async resourceMatrix(data) {
    const res = await this.http
      .post('analytics/matrixresourcesprogress', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const analyticResourceService = new AnalyticResourceService();
