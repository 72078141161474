import React, { Fragment } from 'react';

function SettingProyect({ Router, setRouter, t }) {
  return (
    <Fragment>
      <button
        className={
          'button-nav-settings ' + (Router === 1 && 'border-nav-settings')
        }
        onClick={() => setRouter(1)}>
        {t('settings.general')}
      </button>
      <button
        className={
          'button-nav-settings ' + (Router === 2 && 'border-nav-settings')
        }
        onClick={() => setRouter(2)}>
        {t('settings.users')}
      </button>
      <button
        className={
          'button-nav-settings ' + (Router === 3 && 'border-nav-settings')
        }
        onClick={() => setRouter(3)}>
        {t('settings.cats')}
      </button>
      <button
        className={
          'button-nav-settings ' + (Router === 4 && 'border-nav-settings')
        }
        onClick={() => setRouter(4)}>
        {t('settings.subcontracts')}
      </button>
      <button
        className={
          'button-nav-settings ' + (Router === 5 && 'border-nav-settings')
        }
        onClick={() => setRouter(5)}>
        {t('settings.reports')}
      </button>
    </Fragment>
  );
}
export default SettingProyect;
