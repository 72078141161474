import { updateGanttOnMoveOrResize } from './updateGanttOnMoveOrResize';
import { updateTaskOnResize } from './updateTaskOnResize';
import { setNewDatesWhileDrag } from './setNewDatesWhileDrag';

function onTaskDrag(id, mode, task, original) {
  /** Bug fix: This fix, resize drag behaviour */
  if (task.is_open_lightbox) {
    task.for_disable_milestone_duration = task.duration;
  }

  if (task.progress > 0 || draggedTaskIsNotChecked(id)) {
    task.start_date = original.start_date;
    task.end_date = original.end_date;
    task.duration = original.duration;
    return;
  }

  updateGanttOnMoveOrResize(task, mode);
  updateTaskOnResize(task, mode, original);
  setNewDatesWhileDrag(task, mode, original);
}

function draggedTaskIsNotChecked(taskId) {
  const gantt = window.to_use_react_gantt;

  if (gantt.getAllCheckedTasks.length > 0) {
    return !gantt.getAllCheckedTasks.includes(taskId);
  }
  return false;
}

export { onTaskDrag };
