import React, { useEffect, useState, useRef } from 'react';
import './index.css';
import { VariableSizeList as List } from 'react-window';
import { Checkbox, Row, Col, Popover, Tooltip } from 'antd';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import differenceBy from 'lodash/differenceBy';
import hideChildsIcon from '../../assets/img/takt/hidechilds.png';
import showChildsIcon from '../../assets/img/takt/showchilds.png';

import { splitText } from '../ActivitiesUnitsGrid/index.helper';

export default function UnitsCheckbox(props) {
  const { selectedActivities } = props;
  const $unitBox = useRef();
  const $unitParentBox = useRef();
  const {
    units,
    selectedUnits,
    selectedUnitsToDelete,
    onSelectedtoDeleteChange,
    onSelectedChange,
    relations,
    unitsFlush,
    t
  } = props;
  const { height, width } = useWindowDimensions();
  const [render, setRender] = useState(false);
  const userLang = navigator.language || navigator.userLanguage;

  const changeChildsVisibility = (activity) => {
    activity.hideChilds = !activity.hideChilds;
    setRender(!render);
  };

  const getOnlyActiveDisableActivities = () =>
    props.selectedActivities.filter(
      (activity) => activity.disable && activity.active
    );

  /**
   * This function renders an location in virtualized elements
   * @param {*} unit unit reference object
   * @param {*} padding left padding to tree structure
   */
  const renderSingleRow = (unit, padding) => {
    let disableCheckbox = false;
    const activeDisableActivities = getOnlyActiveDisableActivities();
    if (activeDisableActivities.length) {
      disableCheckbox = true;
      activeDisableActivities.forEach((activity) => {
        activity.productionUnits.forEach((productionUnit) => {
          if (unit.productionUnits) {
            const exist = unit.productionUnits.find(
              (parentUnit) => parentUnit.id == productionUnit.id
            );
            if (exist) disableCheckbox = false;
          }
        });
      });
    }

    return (
      <div
        ref={$unitParentBox}
        style={{ marginBottom: 10, paddingLeft: padding }}>
        <Col span={1} style={{ position: 'relative', top: 1 }} offset={1}>
          {unit.structureId ? (
            unit.productionUnits.length || unit.children.length ? (
              <img
                className="hide-style-pointer"
                style={{ cursor: 'pointer' }}
                src={unit.hideChilds ? showChildsIcon : hideChildsIcon}
                width={10}
                onClick={() => changeChildsVisibility(unit)}
              />
            ) : null
          ) : unit.children.length ? (
            <img
              className="hide-style-pointer"
              style={{ cursor: 'pointer' }}
              src={unit.hideChilds ? showChildsIcon : hideChildsIcon}
              width={10}
              onClick={() => changeChildsVisibility(unit)}
            />
          ) : null}
        </Col>
        <Col span={20} offset={1}>
          <Checkbox
            checked={unit.active}
            disabled={disableCheckbox}
            onChange={(e) => recursivelySelection(unit)}
          />
          <span style={{ fontSize: 15, color: '#7DFF8A', paddingLeft: 10 }}>
            {splitText(
              unit.name,
              $unitParentBox.current?.offsetWidth - 110 || 150
            )}{' '}
            {unit.structureId
              ? '(' + t('takt_assign.location') + ')'
              : '(' + t('takt_assign.structure') + ')'}
          </span>
        </Col>
      </div>
    );
  };

  const renderSingleItem =
    (data) =>
    ({ index, style }) => (
      <div style={{ ...style, width: '100%' }}>{data[index]}</div>
    );

  const renderVirtualizedList = (finalJsxArray) => (
    <List
      className="activities-checkbox-container-virtualized units"
      height={height * 0.65}
      itemCount={finalJsxArray.length}
      itemSize={(index) => height * 0.03}
      width={width * 0.22}>
      {renderSingleItem(finalJsxArray)}
    </List>
  );

  /**
   * This function creates a single virtualized unit checkbox with their text
   * @param {*} unit Unit reference memory object
   * @param {*} finalArray array tu push data
   * @param {*} padding left padding to create tree effect
   */
  const buildUnitsCheckbox = (unit, finalArray, padding) => {
    let disbaleCheckbox = false;
    const referenceOnSelected = selectedUnits.find((ac) => ac.id == unit.id);
    /** Consultar esta condicion con benja */
    const doesExistRelation = relations.find(
      (rel) => rel.productionunitId == unit.id
    );
    if (referenceOnSelected && doesExistRelation) {
      unit.disable = true;
    }

    const activeDisableActivities = getOnlyActiveDisableActivities();
    if (activeDisableActivities.length) {
      activeDisableActivities.forEach((activity) => {
        if (
          !activity.productionUnits.find(
            (unitActivivty) => unitActivivty.id == unit.id
          )
        ) {
          disbaleCheckbox = true;
        }
      });
    }

    finalArray.push(
      <Row style={{ marginBottom: 10, paddingLeft: padding }}>
        <Col
          span={1}
          style={{ position: 'relative', top: 1 }}
          offset={1}
          className="units-checkbox">
          <Checkbox
            checked={unit.active}
            disabled={disbaleCheckbox}
            onChange={(e) => recursivelySelection(unit)}
          />
        </Col>
        <Col offset={1}>
          <div ref={$unitBox}>
            <Col style={{ display: 'flex' }}>
              <Popover
                content={
                  <div>
                    <div
                      className="unitTotalActivities"
                      data-totalActivities={
                        userLang.includes('en')
                          ? unit.activities.length
                          : ` (${unit.activities.length} act)`
                      }>
                      {t('takt_assign.linkedTasks')}
                    </div>
                    {unit.activities.map((ac) => (
                      <div>
                        <Tooltip placement="topLeft" title={ac.activityRoute}>
                          {ac.name}
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                }
                placement="right"
                overlayClassName="show-units-activity-modal"
                trigger="click">
                <span
                  className={`units-activity ${unit.disable && 'assigned'}`}>
                  {splitText(
                    unit.name,
                    $unitBox.current?.offsetWidth - 30 || 150
                  )}
                </span>
              </Popover>
            </Col>
          </div>
        </Col>
      </Row>
    );
  };

  const recursiveBuild = (item, finalArray, padding) => {
    if (unitsFlush) {
      const flushRef = unitsFlush.find(
        (flush) => flush.id == item.id && flush.isStructure == item.isStructure
      );
      if (flushRef && !item.flushed) {
        item.hideChilds = flushRef.hideChilds;
        item.flushed = true;
      }
    }
    finalArray.push(renderSingleRow(item, padding));
    const children = item.children;
    const productionUnits = item.productionUnits;
    if (children.length && !item.hideChilds) {
      children.map((child) => {
        recursiveBuild(child, finalArray, padding + 10);
      });
    } else if (productionUnits && !item.hideChilds) {
      productionUnits
        .sort((a, b) => a.correlative_id - b.correlative_id)
        .map((child) => {
          buildUnitsCheckbox(child, finalArray, padding + 35);
        });
    }
  };

  const createTreeCheckbox = () => {
    const finalJsxArray = [];
    const padding = 0;

    units.map((un) => recursiveBuild(un, finalJsxArray, padding));

    return (
      <Row>
        <Col>
          <Row style={{ padding: 17 }}>
            <span style={{ color: '#7DFF8A', fontSize: 20 }}>
              {t('takt_assign.unitsTitle')}
            </span>
          </Row>
          <Row style={{ padding: 17, overflow: 'auto', height: height - 250 }}>
            {renderVirtualizedList(finalJsxArray)}
            <div style={{ visibility: 'hidden' }}>{render}</div>
          </Row>
        </Col>
      </Row>
    );
  };

  /**
   * This function handles with massive selection tasks
   * @param {*} selection Element to change selecton status by it active attribute
   * @param {*} flag This saves the parent selected status to match with their children
   * @param {*} toAdd Array with tasks to add to state array with massive selection
   * @param {*} toDelete Array with tasks to delete from state array with massive selection
   */
  const massiveSelectionHandler = (
    selection,
    flag = null,
    toAdd = [],
    toDelete = []
  ) => {
    /** Defines first flag value from calling this function without flag */
    if (flag == null) {
      flag = !selection.active;
      selection.active = !selection.active;
    }

    /** Matchs the values to dont change already setted correct value */
    if (flag != selection.active) {
      selection.active = !selection.active;
    }

    /** Defines if the current selection must be deleted or added to state array of massive selection */
    if (selection.isStructure) {
    } else if (selection.active && !selection.disable) {
      toAdd.push(selection);
    } else if (!selection.active && !selection.disable) {
      toDelete.push(selection);
    }
    let childs;

    /** Define array of children (activity or task) */
    if (selection.productionUnits) {
      if (selection.productionUnits.length) {
        childs = selection.productionUnits;
      } else {
        childs = selection.children;
      }
    } else {
      childs = selection.children;
    }
    /** Recursively repetition for children */
    if (childs) {
      if (childs.length) {
        childs.map((el) => {
          massiveSelectionHandler(el, flag, toAdd, toDelete);
        });
      }
    }
  };

  const massiveSelectionToDeleteHandler = (
    selection,
    flag = null,
    toAdd = [],
    toDelete = []
  ) => {
    /** Defines first flag value from calling this function without flag */
    if (flag == null) {
      flag = !selection.active;
      selection.active = !selection.active;
    }

    /** Matchs the values to dont change already setted correct value */
    if (flag != selection.active) {
      selection.active = !selection.active;
    }

    /** Defines if the current selection must be deleted or added to state array of massive selection */
    if (selection.isStructure) {
    } else if (selection.active && selection.disable) {
      selection.selectedByUser = true;
      toAdd.push(selection);
    } else if (!selection.active && selection.disable) {
      selection.selectedByUser = true;
      toDelete.push(selection);
    }

    let childs;

    /** Define array of children (activity or task) */
    if (selection.productionUnits) {
      if (selection.productionUnits.length) {
        childs = selection.productionUnits;
      } else {
        childs = selection.children;
      }
    } else {
      childs = selection.children;
    }
    /** Recursively repetition for children */
    if (childs) {
      if (childs.length) {
        childs.map((el) => {
          massiveSelectionToDeleteHandler(el, flag, toAdd, toDelete);
        });
      }
    }
  };

  /**
   * This function executes massiveSelectionHandler then sets the selection state to
   * handle massive actions like deleting, changing responsable, etc.
   * @param {*} selection Initial selection
   */
  const recursivelySelection = (selection) => {
    const sele = { ...selection };
    /** Temporal array to save added task */
    const toAdd = [];

    /** Temporal array to save deleted task */
    const toDelete = [];

    /** Execution of recursively selection */
    massiveSelectionHandler(selection, null, toAdd, toDelete);

    /** Setting state of massive actions */
    /* Take off elements of massive actions state */
    const newState = differenceBy(selectedUnits, toDelete, 'id');
    /** Then we check those elements that arent at the state and add them */
    toAdd.map((el) => {
      const doesExist = newState.find((single) => el.id == single.id);
      if (!doesExist && !el.disable) {
        /** Filter if it is a task (avoid adding activities) */
        newState.push(el);
      }
    });

    onSelectedChange(newState);

    const toAddDelete = [];
    const toDeleteDelete = [];
    massiveSelectionToDeleteHandler(sele, null, toAddDelete, toDeleteDelete);

    /** Setting state of massive actions */
    /* Take off elements of massive actions state */
    const newStateDelete = differenceBy(
      selectedUnitsToDelete,
      toDeleteDelete,
      'id'
    );
    /** Then we check those elements that arent at the state and add them */
    toAddDelete.map((el) => {
      const doesExist = newStateDelete.find((single) => el.id == single.id);
      if (!doesExist) {
        if (el.active && el.disable) {
          newStateDelete.push(el);
        }
      }
    });

    onSelectedtoDeleteChange(newStateDelete);
  };

  return createTreeCheckbox();
}
