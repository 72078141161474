/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class ConstraintScheduleService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(constraintScheduleId) {
    const res = await this.http
      .get('constraintschedules/' + constraintScheduleId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(constraintScheduleId) {
    const res = await this.http
      .delete('constraintschedules/' + constraintScheduleId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http
      .post('constraintschedules', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showByConstraintId(data) {
    const res = await this.http
      .post('constraintschedules/showByConstraintId', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('constraintschedules/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const constraintScheduleService = new ConstraintScheduleService();
