import React from 'react';
import { SvgIcon } from '../../../../../../utils';
import SuccessIconPath from '../../../../../../assets/img/icons/icon--success.svg';
import ErrorIconPath from '../../../../../../assets/img/icons/icon--error.svg';

export const ALERT_ICON_COLOR = '#F59D04';
export const SELECT_FULL_WIDTH = 555;
export const SELECT_BODY_WIDTH = 180;
export const SELECT_SIZE = 'small';
export const SELECT_SHORT_TEXT = true;
export const SELECT_MAX_LENGTH_TEXT = 18;
export const VALUE_MAX_WIDTH_TEXT = 180;
export const MODAL_THEME = 'dark';
export const MODAL_WIDTH = 968;
export const MAX_LIST_SELECTS = 6;
export const ACTIVE = 'active';
export const WHITE_COLOR = '#FFF';
export const DISABLED = 'disabled';
export const PROJECT_ARCHIVED = 'archived';
export const GREY_COLOR = '#B3B3B3';
export const PLACEMENT_TOP = 'top';
export const ERROR = 'error';
export const ERROR_COLOR = '#FA7676';
export const HTML_SPACE = '&nbsp;';

export const SUCCESS_ICON = (
  <SvgIcon
    path={SuccessIconPath}
    className="massive-notification-icon"
    color="#34AF00"
  />
);
export const ERROR_ICON = (
  <SvgIcon
    path={ErrorIconPath}
    className="massive-notification-icon"
    color="#E50101"
  />
);
