import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';

import { DRAWER_ORIGINS } from '../../constants/drawerOrigins';
import {
  getDrawerSelector,
  requestCloseDrawer
} from '../../redux/slices/hoveringStackSlice';
import styles from './HoveringPortal.module.scss';

export const TestIds = {
  HOVERING_PORTAL_DRAWER: 'hovering_portal_drawer'
};

export const HoveringPortal = ({ drawer, requestCloseDrawer }) => {
  const hoveringRootElId = '#hovering-root';
  const [rootEl, setRootEl] = useState();
  const {
    status: drawerStatus,
    component: DrawerComponent,
    origin: drawerOrigin = DRAWER_ORIGINS.RIGHT,
    params: drawerParams
  } = drawer;

  useEffect(() => {
    setRootEl(document.querySelector(hoveringRootElId));
  }, []);

  useEffect(() => {
    window[`${DrawerComponent ? 'add' : 'remove'}EventListener`](
      'click',
      handleClickOutsideDrawer
    );

    return () => window.removeEventListener('click', handleClickOutsideDrawer);
  }, [DrawerComponent]);

  const hasClickedGoToButton = (target) => {
    if (!target || !target.parentNode) return false;
    const parent = target.parentNode.tagName;
    const className = target.parentNode.classList;
    if (className.value.match(/goToTask/g) || parent === 'svg') return true;
    return false;
  };

  const handleClickOutsideDrawer = (e) => {
    /** check if is a popover subtrade && tag */

    const a0 = e.target.getElementsByClassName(
      'ant-popover container-image-custom custom-proplanner-tag gantt_tree_card subcontract-border cut-text-and-dot'
    );
    if (a0 && a0.length) return false;
    if (
      e.target &&
      e.target.className &&
      e.target.className.includes &&
      e.target.className.includes('custom-gantt-subcontract')
    ) {
      return false;
    }
    if (
      e.target &&
      e.target.className &&
      e.target.className.includes &&
      e.target.className.includes('custom-proplanner-tag')
    ) {
      return false;
    }

    if (hasClickedGoToButton(e.target)) return false;

    const clickOnActCardLink = e.target.className;

    if (clickOnActCardLink === 'gantt_tree_card') return false;

    if (typeof clickOnActCardLink === 'string') {
      if (
        clickOnActCardLink === 'dependencies-alert-modal__content' ||
        clickOnActCardLink === 'dependencies-alert-modal__title' ||
        clickOnActCardLink === 'dependencies-alert-modal__button-alert' ||
        clickOnActCardLink === 'dependencies-alert-modal__close' ||
        clickOnActCardLink?.includes('wrap-dependencies-alert-modal') ||
        e.target?.parentNode?.className?.includes(
          'dependencies-alert-modal__icon'
        ) ||
        e.target?.parentNode?.className?.includes(
          'dependencies-alert-modal__warning'
        ) ||
        e.target?.parentNode?.parentNode?.className?.includes(
          'dependencies-alert-modal'
        )
      )
        return false;
    }

    e.target.closest(
      `${hoveringRootElId}, .ant-tooltip, .ant-modal, .ToolbarItem_toolbar-item--button__1hJiU, .gantt-dropdown, .ant-popover, subcontract-border, gantt_tree_card`
    ) || requestCloseDrawer();
  };

  if (!rootEl) return null;

  const content = (
    <>
      {DrawerComponent ? (
        <div
          className={cn(
            styles['hovering-portal__drawer'],
            styles[`hovering-portal__drawer--${drawerOrigin}`]
          )}
          data-testid={TestIds.HOVERING_PORTAL_DRAWER}>
          <DrawerComponent
            drawerConfig={{ status: drawerStatus, origin: drawerOrigin }}
            params={drawerParams}
          />
        </div>
      ) : null}
    </>
  );

  return ReactDOM.createPortal(content, rootEl);
};

const mapStateToProps = (state) => ({
  drawer: getDrawerSelector(state)
});

const actionCreators = {
  requestCloseDrawer
};

export default connect(mapStateToProps, actionCreators)(HoveringPortal);
