/* eslint-disable prefer-const */

/** React components  */
import React, { useState } from 'react';

/** import common functions from utils */
import {
  openNotification,
  ReasonsArchive,
  setAllProjectsGlobal
} from '../../../utils';

import { socket } from '../../../services/socket.service';

/** import common functions from helper */
import { noSelectedReason } from '../../../views/project/project.helper';

/** import icons from antd framework */
import { ExclamationCircleOutlined } from '@ant-design/icons';

/** Import elements from library Antd */
import { Modal, Button, Select } from 'antd';

/** import Services */
import { projectService, companyService, userService } from '../../../services';

/** Redux */
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../../redux/slices/projectSlice';
import { withTranslation } from 'react-i18next';
import {
  getSessionTokenData,
  getCurrentCompany
} from '../../../utils/userUtils';
import { trackingEvent } from '../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import {
  HOME_CARD_VIEW,
  HOME_TIMELINE_VIEW
} from '../../../hooks/useSelectProject';

function FormDelete(props) {
  const { t, setFormDeleteVisible, formDeleteVisible, sourceTypeView } = props;
  const projectState = useSelector((state) => state.projectState);
  const [isLoading, setIsLoading] = useState(false); // Btn Loading

  const user = JSON.parse(localStorage.getItem('user'));
  const sessionTokenData = getSessionTokenData();
  /** state for archive/delete project */
  /** Structure:
        {
            reason: null,   //Handle reason select
            view: 'dialog'  //Options to view for delete/archive  ('dialog','archive', 'delete' )
        }
    */
  const [stateDeleteArchive, setStateDeleteArchive] = useState({
    view: 'dialog' /** Options to view for delete/archive  ('dialog','archive', 'delete' ) */
  });

  const gotoDialogDelete = (e) => {
    e.preventDefault();
    setStateDeleteArchive({
      view: 'dialog'
    });
  };

  /** services */
  async function updateProject(data) {
    const resp = await projectService.update(data);
    return resp;
  }

  async function getCompany(companyId) {
    const resp = await companyService.show(companyId);
    return resp ? resp.company : false;
  }

  /** Refresh projects list */
  const updateProjectsList = (arr, filterParam = 'stage') => {
    /** Redux event emitting function to set projects load from project actions */
    // dispatch(projectActions.setAllProjects(arr))
    setAllProjectsGlobal(arr, dispatch, projectActions, projectState);
  };

  /** this function reset elements after delete/archive process   */
  const resetElements = async () => {
    setIsLoading(false);

    dispatch(
      projectActions.setProps({
        ...projectState.props,
        modalDeleteVisible: false
      })
    );
    setFormDeleteVisible(false);
    const getProjects = await userService.projectsbyuserthrough(user.id);
    const projectsActive = getProjects.projects.filter(
      (e) => e.stage !== 'deleted'
    );

    updateProjectsList(projectsActive, projectState.props.filter);
    setStateDeleteArchive({
      view: 'dialog'
    });
  };

  /** redux */
  const dispatch = useDispatch();

  /** This function update stage of project ('archived','deleted') and save the reason (submit) */
  const archiveProject = async (action) => {
    const currentProject = projectState.props.currentProject;
    /** validate Select reason */
    if (
      stateDeleteArchive.reason === undefined ||
      stateDeleteArchive.reason === noSelectedReason
    ) {
      setStateDeleteArchive({
        ...stateDeleteArchive,
        reason: noSelectedReason
      });
      return false;
    }
    setIsLoading(true);

    /** notify socket */
    socket.emit('project_add', {
      companyId: sessionTokenData.companyId,
      project: currentProject
    });

    const data = {
      id: currentProject.id,
      name: currentProject.name,
      country: currentProject.country,
      companyId: currentProject.companyId,
      type: currentProject.type,
      stage: action,
      archivereason: stateDeleteArchive.reason
    };

    const company = getCurrentCompany();
    const commonEventData = {
      event_source:
        sourceTypeView === 'TIMELINE' ? HOME_TIMELINE_VIEW : HOME_CARD_VIEW,
      company_name: company.name,
      company_id: company.id
    };

    if (action === 'deleted') {
      const gantt = window.ganttScheduleOfSchedule;
      if (gantt) {
        gantt.modalData = {
          action: 'delete',
          projectId: currentProject.id
        };
      }
      trackingEvent(
        'project_deletion',
        {
          ...commonEventData,
          project_deleted_name: currentProject.name,
          project_deleted_id: currentProject.id,
          deletion_reason: stateDeleteArchive.reason
        },
        AMPLITUDE_SERVICE
      );
      dispatch(projectActions.setProject(null));
      dispatch(projectActions.setSector(null));
      sessionStorage.setItem('currentProject', null);
      sessionStorage.setItem('currentSector', null);
      dispatch(projectActions.setAllSectors([]));
    } else if (action === 'archived') {
      trackingEvent(
        'project_archiving',
        {
          ...commonEventData,
          project_archived_name: currentProject.name,
          project_archived_id: currentProject.id,
          archiving_reason: stateDeleteArchive.reason
        },
        AMPLITUDE_SERVICE
      );
    }

    const projectUpdated = await updateProject(data);
    if (projectUpdated.msj !== undefined) {
      openNotification({
        title: t('form.project'),
        description: t('form.project_edit'),
        type: 'success'
      });
    }
    resetElements();
  };

  /** This function render the view selected (archive, delete)  */
  const ShowForm = (view) => {
    setStateDeleteArchive({
      ...stateDeleteArchive,
      view: view
    });
  };

  /** constants */
  const { Option } = Select;

  /** render */
  return (
    <Modal
      visible={formDeleteVisible}
      centered
      onCancel={() => {
        dispatch(
          projectActions.setProps({
            ...projectState.props,
            modalDeleteVisible: false
          })
        );
        setFormDeleteVisible(false);
        setStateDeleteArchive({
          // ...stateDeleteArchive,
          view: 'dialog'
        });
      }}
      width={1000}
      footer={[]}>
      {stateDeleteArchive.view === 'dialog' ? (
        <div className="dialogDelete">
          <div className="wrapFormDelete">
            <div className="titleFormDelete">{t('project_delete.title')}</div>
            <ExclamationCircleOutlined className="infoFormDelete" />
            <p>
              {t('project_delete.wait')}
              <br />
              {t('project_delete.wait_1')}
            </p>
            <p>{t('project_delete.wait_2')}</p>
          </div>
          <div key="btnFooter" className="footerDelete">
            <Button
              key="back"
              type="primary"
              onClick={() => ShowForm('archive')}
              className="btnArchiveFormDelete">
              {t('project_delete.file')}
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={() => ShowForm('delete')}
              className="btnDeleteFormDelete">
              {t('project_delete.delete')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="dialogArchive">
          <div className="wrapFormDelete">
            <div className="titleFormDelete">{t('project_delete.ok')}</div>
            <Select
              placeholder={noSelectedReason}
              className="selectReason"
              value={stateDeleteArchive.reason}
              onChange={(e) =>
                setStateDeleteArchive({
                  ...stateDeleteArchive,
                  reason: e
                })
              }>
              {ReasonsArchive(t).map(({ value, label }, index) => (
                <Option key={index} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
            {stateDeleteArchive.reason === noSelectedReason ? (
              <label className="lblErrorElement">
                {t('project_delete.requerido')}
              </label>
            ) : (
              ''
            )}
          </div>
          <div key="btnFooter" className="footerDelete">
            {stateDeleteArchive.view === 'delete' ? (
              <Button
                key="delete"
                type="primary"
                onClick={() => archiveProject('deleted')}
                className="btnDeleteFormDelete"
                loading={isLoading}>
                {t('project_delete.delete')}
              </Button>
            ) : (
              <Button
                key="archive"
                type="primary"
                onClick={() => archiveProject('archived')}
                className="btnArchiveFormDelete"
                loading={isLoading}>
                {t('project_delete.file')}
              </Button>
            )}
            <a
              href="#/"
              onClick={gotoDialogDelete}
              className="refLink skipLink">
              Cancel
            </a>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default withTranslation()(FormDelete);
