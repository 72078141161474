import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Spin, Icon, Popover, Checkbox, Tooltip } from 'antd';
import { FixedSizeGrid as Grid } from 'react-window';
import { VariableSizeGrid } from 'react-window';
import './index.css';
import { Row, Col } from 'antd';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Header from 'antd/lib/calendar/Header';
import { VisualizationTree, GrouppableLevels } from './index.helper';
import {
  filterLastLvlActivities,
  filterUnits
} from '../../views/taktplanning/index.helper';
import treeIcon from '../../assets/img/gantt-view.png';
import groupIcon from '../../assets/img/groupicon.png';
import levelsTakt from '../../assets/img/takt/niveles-takt.svg';
import dropdownIcon from '../../assets/img/takt/dropdown-icon.svg';
import ganttVisualization from '../../assets/img/takt/ganttVisualization.svg';
import groupVisualization from '../../assets/img/takt/groupVisualization.svg';

/** Unit rendered element in grid */
import { GridCellUnitGroupped, GridCellUnitTree } from './modules/unitCell';

/** Location rendered element in grid */
import {
  GridCellLocationGroupped,
  GridCellLocationTree
} from './modules/locationCell';

/** Structure rendered element in grid  */
import {
  GridCellStructureGroupped,
  GridCellStructureTree
} from './modules/structureCell';

/** Fixed headers rendered cols (Structure, Location and Unit) */
import {
  HeaderUnitCol,
  HeaderLocationCol,
  HeaderStructureCol
} from './modules/fixedHeaders';

/** Fixed Activities col rendered row (Groupped and Tree) */
import {
  FixedColActivitiesRowGroupped,
  FixedColActivitiesRowTree
} from './modules/fixedActivitiesCol';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

export default function ActivitiesUnitsGrid(props) {
  const visualizationSelection = useRef(null);
  const {
    activities,
    assignUnassignAction,
    setAssignUnassignAction,
    units,
    selectedActivities,
    selectedUnits,
    executeAssignTakt,
    creatingTakt,
    deletingTakt,
    unAssignTakt,
    updateVisualization,
    visibilityState,
    t
  } = props;
  const [gridRows, setGridRows] = useState([]);
  const [visibilityLevels, setVisibilityLevels] = useState({
    structure: true,
    location: true,
    unit: false
  });
  const [treeActivitiesVisualization, setTreeActivitiesVisualization] =
    useState(true);

  const [gridCols, setGridCols] = useState([]);
  const [fixedColWidth, setFixedColWidth] = useState(150);
  const [grouppedRows, setGrouppedRows] = useState([]);
  const [locationArray, setLocationArray] = useState([]);
  const [structuresArray, setStructuresArray] = useState([]);
  const { height, width } = useWindowDimensions();
  const staticCol = useRef(null);
  const staticHeader = useRef(null);
  const staticLocationHeader = useRef(null);
  const staticStructureHeader = useRef(null);

  /** Height for fixed col activities and grid rows */
  const activitiesColHeight = 35;
  const gridHeaderHeight = 35;
  const gridHeight = height * 0.56;
  const gridWidth = width * 0.36;
  const gridColWidth = 100;

  useEffect(() => {
    if (staticLocationHeader.current) {
      staticLocationHeader.current.resetAfterColumnIndex(0);
    }

    if (staticStructureHeader.current) {
      staticStructureHeader.current.resetAfterColumnIndex(0);
    }
  }, [visibilityState]);

  /**
   * This function goes deeply on a tree data structure and transforms it to an lineal array in the same structure
   * @param {*} tree Array with tree format
   * @param {*} finalJsxArray empty array to push deeply data
   * @param {*} lvl 0 on start to deeply get level for elements
   */
  const treeToLinealArray = (tree, finalJsxArray, lvl) => {
    tree.map((element) => {
      const hasChilds = element.children;
      element.lvl = lvl;
      finalJsxArray.push(element);
      if (hasChilds) {
        if (hasChilds.length) {
          treeToLinealArray(hasChilds, finalJsxArray, lvl + 1);
        }
      }
    });
  };

  const getStructureWidth = (structure) => {
    let allLocationsWidth = 0;
    structure.locationsWithWidth.map((loc) => {
      allLocationsWidth += loc.width;
    });
    structure.widthForHeader = allLocationsWidth;
  };

  /**
   * This function gets units (all structure with locations) and transforms to only locatiosn with production unit
   * @param {*} units All locations with units array
   */
  const getLocationsWithUnits = (units) => {
    const linealArray = [];
    treeToLinealArray(units, linealArray, 0);
    const locationsWithUnits = linealArray.filter((location) => {
      if (!location.productionUnits) {
        return false;
      } else if (location.productionUnits.length) {
        return true;
      }
    });

    locationsWithUnits.map((location) => {
      getLocationWidth(location);
    });

    const structureIds = locationsWithUnits.map((loc) => loc.structureId);
    const structures = [];
    structureIds.map((s) => {
      const str = linealArray.find((l) => l.id == s && !l.structureId);
      const doesExist = structures.find((el) => el.id == str.id);
      if (!doesExist) {
        const mapping = [];
        linealArray.map((location) => {
          if (location.productionUnits) {
            if (
              location.productionUnits.length &&
              location.structureId == str.id
            ) {
              mapping.push({
                id: location.id,
                width: location.productionUnits.length * gridColWidth
              });
            }
          }
        });

        str.locationsWithWidth = mapping;
        structures.push(str);
      }
    });

    structures.map((structure) => {
      getStructureWidth(structure);
    });

    setStructuresArray(structures);
    setLocationArray(locationsWithUnits);
  };
  useEffect(() => {
    setFixedColWidth(width * 0.0965);
  }, [width]);

  /**
   * This effect loads in an lineal array the tree structure of locations, only getting which ones that has at last one unit production
   * Also creates the the flag to get last index of their unit at grid
   */
  useEffect(() => {
    /** Finally for last the second fixed header row, shows the units which at now is the minimum level  */
    const linealUnitsArray = [];
    let acumIndex = 0;
    locationArray.map((location) => {
      location.productionUnits.map((unit, index) => {
        acumIndex += 1;
        if (location.productionUnits.length - 1 == index) {
          location.lastIndex = acumIndex;
        }
        linealUnitsArray.push(unit);
      });
    });

    setGridCols(linealUnitsArray);
  }, [locationArray]);

  const groupActivitiesByName = (linealActivitiesArray) => {
    const grouppedActivitiesArray = [];
    const activitiesWithChild = linealActivitiesArray.filter(
      (ac) => !ac.has_childs
    );
    activitiesWithChild.map((ac) => {
      ac.name = ac.name.trim();
      const doesExistAtMap = grouppedActivitiesArray.find(
        (act) => act.name.trim() == ac.name
      );
      if (doesExistAtMap) {
        doesExistAtMap.activities.push(ac);
      } else {
        grouppedActivitiesArray.push({
          name: ac.name,
          activities: [ac]
        });
      }
    });
    return grouppedActivitiesArray;
  };

  /**
   * This effect creates basic structure data for this component work
   */
  useEffect(() => {
    /** Code below sets an lineal tree data structure to show at fixed first col of the grid */
    const linealActivitiesArray = [];

    treeToLinealArray(activities, linealActivitiesArray, 0);

    const grouppedActivities = groupActivitiesByName(linealActivitiesArray);
    setGrouppedRows(grouppedActivities);
    setGridRows(linealActivitiesArray);

    const activeUnits = selectedUnits.filter((unit) => unit.active);
    const activeActivities = selectedActivities.filter(
      (activity) => activity.active && !activity.has_childs
    );

    if (activeActivities.length > 0 && activeUnits.length > 0) {
      const activityExist = [];
      const existActivityInUnit = (activityExists) => (unit) => {
        if (unit.locationId) {
          unit.activities.forEach((activity) => {
            const findActivity = activeActivities.find(
              (activeActivity) => activeActivity.id == activity.id
            );
            findActivity && activityExists.push(findActivity);
          });
        }
      };

      activeUnits.forEach(existActivityInUnit(activityExist));
      const action = activityExist.length ? 'unassign' : 'assign';
      setAssignUnassignAction({
        disable: false,
        action: action,
        label:
          action === 'unassign'
            ? t('takt_assign.unassign')
            : t('takt_assign.assign')
      });
    } else {
      setAssignUnassignAction({
        disable: true,
        action: '',
        label: t('takt_assign.assign')
      });
    }

    /** Code below creates an array with locations that have units to show as a upper level o visualization for first fixed row header */
    getLocationsWithUnits(units);
  }, [selectedActivities, selectedUnits, activities, units]);

  /**
   * This function updates the scroll reference on all static virtualized elements, taking the grid movements
   */
  const onScroll = useCallback((all) => {
    const { scrollLeft, scrollTop, scrollUpdateWasRequested } = all;
    if (!scrollUpdateWasRequested) {
      staticCol.current.scrollTo({ scrollLeft: 0, scrollTop });
      if (staticHeader.current) {
        staticHeader.current.scrollTo({ scrollTop: 0, scrollLeft });
      }
      if (staticLocationHeader.current) {
        staticLocationHeader.current.scrollTo({ scrollTop: 0, scrollLeft });
      }
      if (staticStructureHeader.current) {
        staticStructureHeader.current.scrollTo({ scrollTop: 0, scrollLeft });
      }
    }
  });

  /**
   * This function calculates how width should have in the grid a location based on their units
   * @param {*} location location object
   */
  const getLocationWidth = (location) => {
    location.widthForHeader = location.productionUnits.length * gridColWidth;
  };

  const renderTableVirtualized = () => {
    if (visibilityState.unit) {
      return (
        <Col>
          <Grid
            onScroll={onScroll}
            className="Grid"
            columnCount={gridCols.length} /** Units (not locations!!) */
            columnWidth={gridColWidth}
            height={gridHeight + 7}
            rowCount={
              treeActivitiesVisualization
                ? gridRows.length
                : grouppedRows.length
            } /** Activities */
            rowHeight={activitiesColHeight}
            width={gridWidth + 7}>
            {treeActivitiesVisualization
              ? GridCellUnitTree(
                  gridRows,
                  gridCols,
                  selectedActivities,
                  selectedUnits,
                  locationArray
                )
              : GridCellUnitGroupped(
                  grouppedRows,
                  gridCols,
                  selectedActivities,
                  selectedUnits,
                  locationArray
                )}
          </Grid>
        </Col>
      );
    } else if (visibilityState.location) {
      return (
        <Col>
          <VariableSizeGrid
            onScroll={onScroll}
            className="Grid"
            columnCount={locationArray.length} /** Units (not locations!!) */
            columnWidth={(index) =>
              visibilityState.unit ? locationArray[index].widthForHeader : 100
            }
            height={gridHeight + 7}
            rowCount={
              treeActivitiesVisualization
                ? gridRows.length
                : grouppedRows.length
            } /** Activities */
            rowHeight={(index) => activitiesColHeight}
            width={gridWidth + 7}>
            {treeActivitiesVisualization
              ? GridCellLocationTree(
                  gridRows,
                  gridCols,
                  selectedActivities,
                  selectedUnits,
                  locationArray
                )
              : GridCellLocationGroupped(
                  grouppedRows,
                  gridCols,
                  selectedActivities,
                  selectedUnits,
                  locationArray
                )}
          </VariableSizeGrid>
        </Col>
      );
    } else if (visibilityState.structure) {
      return (
        <span>
          <VariableSizeGrid
            onScroll={onScroll}
            className="GridMatrix"
            columnCount={structuresArray.length} /** Units (not locations!!) */
            columnWidth={(index) =>
              visibilityLevels.unit
                ? structuresArray[index].widthForHeader
                : 100
            }
            height={gridHeight}
            rowCount={
              treeActivitiesVisualization
                ? gridRows.length
                : grouppedRows.filter((ac) => !ac.hide).length
            } /** Activities */
            rowHeight={(index) => activitiesColHeight}
            width={gridWidth}>
            {treeActivitiesVisualization
              ? GridCellStructureTree(
                  gridRows,
                  gridCols,
                  selectedActivities,
                  selectedUnits,
                  structuresArray
                )
              : GridCellStructureGroupped(
                  grouppedRows.filter((ac) => !ac.hide),
                  gridCols,
                  selectedActivities,
                  selectedUnits,
                  structuresArray,
                  locationArray
                )}
          </VariableSizeGrid>
        </span>
      );
    }
  };

  const changeTreeVisualization = () => {
    setTreeActivitiesVisualization(!treeActivitiesVisualization);
  };

  const handleAssignAction = () => {
    if (!assignUnassignAction.disable && assignUnassignAction.action !== '') {
      assignUnassignAction.action === 'assign'
        ? executeAssignTakt()
        : unAssignTakt();
    }
  };

  return (
    <Row>
      <Col>
        {/** Title and buttons header row */}
        <div className="container-takt-options-header">
          <span style={{ color: '#2C3421', fontSize: 20 }}>
            {t('takt_assign.matrixTitle')}
          </span>
          <span className="options-groups">
            {/* <-- Group visualization --> */}
            <Popover
              content={
                <VisualizationTree
                  t={t}
                  treeVisualization={treeActivitiesVisualization}
                  changeTreeVisualization={changeTreeVisualization}
                />
              }
              trigger="click"
              placement="bottom"
              overlayClassName="levels-dropdown-style">
              <div
                className={`levels-takt-btn ${!treeActivitiesVisualization && 'group'}`}>
                <img
                  src={
                    !treeActivitiesVisualization
                      ? ganttVisualization
                      : groupVisualization
                  }
                  width={12}
                />
                <span ref={visualizationSelection}>
                  {treeActivitiesVisualization
                    ? t('takt_assign.gantt')
                    : t('takt_assign.group')}
                </span>
                <img src={dropdownIcon} width={12} />
              </div>
            </Popover>
            {/* <-- Group filter --> */}
            <Popover
              content={
                <GrouppableLevels
                  t={t}
                  updateVisualization={updateVisualization}
                  setVisibilityLevels={setVisibilityLevels}
                  visibilityLevels={visibilityLevels}
                />
              }
              overlayClassName="levels-dropdown-style"
              trigger="click"
              placement="bottom">
              <div className="levels-takt-btn">
                <img src={levelsTakt} width={12} />
                {t('takt_assign.levels')}
                <img src={dropdownIcon} width={12} />
              </div>
            </Popover>
            <button
              className={`assign-takt-btn ${assignUnassignAction.action}`}
              onClick={handleAssignAction}
              disabled={assignUnassignAction.disable}>
              {creatingTakt || deletingTakt ? (
                <Spin
                  className="loader-spinner-lookahead-header"
                  indicator={antIcon}
                />
              ) : (
                assignUnassignAction.label
              )}
            </button>
          </span>
        </div>
        {/** Fixed headers, col and grid row */}
        <Row style={{ padding: 17 }} className="grid-container-style">
          <Col>
            {/** Fixed structure row  */}
            {visibilityState.structure ? (
              <Row>
                <Col span={5}></Col>
                <Col span={15}>
                  <VariableSizeGrid
                    ref={staticStructureHeader}
                    style={{ overflowX: 'hidden', overflowY: 'hidden' }}
                    className="GridStaticHeaderLocations structure"
                    columnCount={structuresArray.length}
                    columnWidth={(index) => {
                      if (structuresArray[index].widthForHeader) {
                        if (
                          (visibilityState.location && visibilityState.unit) ||
                          (!visibilityState.location && visibilityState.unit)
                        ) {
                          return structuresArray[index].widthForHeader;
                        } else if (
                          visibilityState.location &&
                          !visibilityState.unit
                        ) {
                          return (
                            structuresArray[index].locationsWithWidth.length *
                            100
                          );
                        } else if (
                          !visibilityState.location &&
                          !visibilityState.unit
                        ) {
                          return 100;
                        }
                      } else {
                        return 20;
                      }
                    }}
                    height={gridHeaderHeight}
                    rowCount={1}
                    rowHeight={(index) => gridHeaderHeight}
                    width={gridWidth}>
                    {HeaderStructureCol(structuresArray)}
                  </VariableSizeGrid>
                </Col>
              </Row>
            ) : null}

            {/** Fixed location row  */}
            {visibilityState.location ? (
              <Row>
                <Col span={5}></Col>
                <Col span={15}>
                  <VariableSizeGrid
                    ref={staticLocationHeader}
                    style={{ overflowX: 'hidden', overflowY: 'hidden' }}
                    className="GridStaticHeaderLocations location"
                    columnCount={locationArray.length}
                    columnWidth={(index) => {
                      if (locationArray[index].widthForHeader) {
                        return visibilityState.unit
                          ? locationArray[index].widthForHeader
                          : 100;
                      }
                      return 20;
                    }}
                    height={gridHeaderHeight}
                    rowCount={1}
                    rowHeight={(index) => gridHeaderHeight}
                    width={gridWidth}>
                    {HeaderLocationCol(
                      locationArray,
                      selectedActivities,
                      selectedUnits
                    )}
                  </VariableSizeGrid>
                </Col>
              </Row>
            ) : null}

            {/** Fixed units row */}
            {visibilityState.unit ? (
              <Row>
                <Col span={5}></Col>
                <Col span={15}>
                  <Grid
                    ref={staticHeader}
                    style={{ overflowX: 'hidden', overflowY: 'hidden' }}
                    className="GridStaticHeader"
                    columnCount={gridCols.length}
                    columnWidth={gridColWidth}
                    height={gridHeaderHeight}
                    rowCount={1}
                    rowHeight={gridHeaderHeight}
                    width={gridWidth}>
                    {HeaderUnitCol(
                      gridCols,
                      gridColWidth,
                      selectedActivities,
                      selectedUnits,
                      locationArray
                    )}
                  </Grid>
                </Col>
              </Row>
            ) : null}
            <Row style={{ display: 'flex' }}>
              {/** Fixed Col */}
              <Col
                span={5}
                className="GridStaticCol__container"
                style={{ height: gridHeight }}>
                <Grid
                  ref={staticCol}
                  style={{ overflowX: 'hidden', overflowY: 'hidden' }}
                  className="GridStaticCol"
                  columnCount={1}
                  columnWidth={fixedColWidth - 7}
                  height={gridHeight}
                  rowCount={
                    treeActivitiesVisualization
                      ? gridRows.length
                      : grouppedRows.length
                  }
                  rowHeight={activitiesColHeight}
                  width={fixedColWidth - 7}>
                  {treeActivitiesVisualization
                    ? FixedColActivitiesRowTree(
                        gridRows,
                        fixedColWidth,
                        selectedActivities,
                        selectedUnits
                      )
                    : FixedColActivitiesRowGroupped(
                        grouppedRows,
                        fixedColWidth,
                        selectedActivities,
                        selectedUnits
                      )}
                </Grid>
              </Col>
              {/** Table indeed */}
              {renderTableVirtualized()}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
