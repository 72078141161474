import { ADD, UPDATE } from '../actions/WidgetWeeklyPlanActions';

export const initialState = {
  widgetData: {
    sectorId: [],
    byHash: {}
  }
};

const reducers = {
  [ADD]: (state, payload) => {
    if (!state.widgetData) {
      state = initialState;
    }

    const newState = {
      widgetData: {
        sectorId: [...state.widgetData.sectorId, payload.sectorId],
        byHash: {
          ...state.widgetData.byHash,
          [payload.sectorId]: {
            sectorId: payload.sectorId,
            criteriaOptions: payload.criteriaOption,
            percentageOption: payload.percentageOption,
            resourceId: payload.resourceId
          }
        }
      }
    };

    return newState;
  },
  [UPDATE]: (state, payload) => {
    state.widgetData.byHash[payload.sectorId] = {
      ...state.widgetData.byHash[payload.id],
      ...payload
    };

    return { ...state };
  }
};

const widgetReducer = (state = initialState, action) => {
  if (!reducers[action.type]) return state;

  const newState = reducers[action.type](state, action.payload);
  return newState;
};

export default widgetReducer;
