function getLinkNameByCode(code) {
  const linkMap = {
    0: 'fs',
    1: 'ss',
    2: 'ff',
    3: 'sf'
  };
  return linkMap[code] || 'sf';
}

export { getLinkNameByCode };
