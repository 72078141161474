import React, { useEffect } from 'react';
import './index.css';
import './index.scss';
import CurvePpcMini from '../pcc_mini_chart';
import CurvePCRMini from '../pcr_mini_chart';
import { Spin, Icon } from 'antd';

/** Components */
import referenceanaliti3 from '../../../../assets/img/Group-1.png';

export default function Card(props) {
  /** props */
  const { t } = props;
  const chart = props.chart;
  const chartNew = props.chartNew;

  /** consts */
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  let image = '';
  if (props.position == 0) {
    image = <CurvePpcMini t={t} data={chart} />;
  } else if (props.position == 1) {
    image = chartNew ? (
      <CurvePCRMini t={t} data={chart} dataConstraints={chartNew} />
    ) : (
      <div className="widget-analitycs">
        <Spin className="loader-spinner-lookahead-table" indicator={antIcon} />
      </div>
    );
  } else {
    image = (
      <img
        className="img_project_card"
        src={referenceanaliti3}
        alt="Logo"></img>
    );
  }

  return (
    <div className="container-card">
      {props.position === 2 ? (
        props.title ? (
          <div className="text_container">
            <div className="text_container_title">{props.title}</div>
            <div className="text_container_data" style={{ width: 130 }}>
              {props.data}
            </div>
            <div className="text_container_description">
              {props ? props.subtitle : '34 tareas'}
            </div>
          </div>
        ) : (
          <div
            className="text_container"
            style={{
              display: 'flex',
              marginTop: 23,
              alignItems: 'center',
              justifyContent: 'center',
              width: 160
            }}>
            <div className="widget-analitycs">
              <Spin className="loader-spinner-analitycs" indicator={antIcon} />
            </div>
          </div>
        )
      ) : (
        <div className="text_container">
          <div className="text_container_title">{props.title}</div>
          <div className="text_container_data" style={{ width: 130 }}>
            {t('lang') === 'en'
              ? props.data
              : props.data.toString().replace('.', ',')}
          </div>
          <div className="text_container_description">
            {props ? props.subtitle : '34 tareas'}
          </div>
        </div>
      )}
      <div className="image_container_card">{image}</div>
      {/* <IconComponent data={analitic_negative_arrow} width={15} fill="#E50101" className="iconCompany" /> */}
    </div>
  );
}
