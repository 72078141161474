/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class BaseCalendarService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(baseCalendarId) {
    const res = await this.http
      .get('baseCalendars/' + baseCalendarId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showBySector(SectorId) {
    const res = await this.http
      .get('baseCalendars/sector/' + SectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(baseCalendarId) {
    const res = await this.http
      .delete('baseCalendars/' + baseCalendarId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('baseCalendars/', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('baseCalendars/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const baseCalendarService = new BaseCalendarService();
