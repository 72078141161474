import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import difference from 'lodash/difference';

// ==> Components
import { VariableSizeList } from 'react-window';
import { Checkbox, Col, Popover, Row } from 'antd';

// Context
import { ProductivityAssignCtx } from '../../Context/Productivty/Assign';

// => Assets
import styles from './index.module.css';
import { UserOutlined } from '@ant-design/icons';

// => Utilities
import { splitText } from '../ActivitiesUnitsGrid/index.helper';

const ListAsociations = ({ userLang, workers, t }) => (
  <div>
    <div
      className="assign-units-tooltip"
      data-totalworkers={
        userLang.includes('en')
          ? ` (${workers.length} Workers)`
          : workers.length
      }>
      {workers.length} {t('productivity.assign.linked')}
    </div>
    <ol className={styles.listAssosiationsList}>
      {workers.map((worker) => {
        const percentage = worker.factor * 100;
        const parsePercentage =
          percentage % 1 === 0 ? `${percentage}%` : `${percentage.toFixed(2)}%`;
        return (
          <li>
            <p className={styles.listAssosiationsItem}>
              <span>{splitText(worker.name, 101)}</span>
              <span>{parsePercentage}</span>
            </p>
          </li>
        );
      })}
    </ol>
  </div>
);

export const ActivityRow = (props) => {
  const { resource, padding, dateInit, t } = props;

  const $resourceBox = useRef(null);
  const userLang = navigator.language || navigator.userLanguage;

  const productivityAssign = useContext(ProductivityAssignCtx);

  // ==> State
  const [widthText, setWithText] = useState(240);
  const [workerAssign, setWorkerAssign] = useState(null);

  // ==> Hooks
  useEffect(() => filterWorkersByDate(), []);

  useEffect(() => {
    if ($resourceBox.current) {
      setWithText($resourceBox.current.offsetWidth);
    }
  }, [$resourceBox]);

  // ==> End hooks

  const selectionHandler = (
    selection,
    flag = null,
    toAdd = [],
    toDelete = []
  ) => {
    const childs = selection.children;

    if (flag == null) {
      flag = !selection.active;
      selection.active = !selection.active;
    }

    if (flag != selection.active) {
      selection.active = !selection.active;
    }

    if (selection.active && !selection.disable) {
      toAdd.push(selection);
    } else if (!selection.active && !selection.disable) {
      toDelete.push(selection);
    }

    if (childs) {
      if (childs.length) {
        childs.map((el) => {
          selectionHandler(el, flag, toAdd, toDelete);
        });
      }
    }
  };

  const checkedActivities = () => {
    productivityAssign.addResourceSelected = [resource];
  };

  const uncheckedActivities = (selection) => {
    const removeSelection = productivityAssign.resourceSelected.filter(
      (resource) => resource.id != selection.id
    );
    productivityAssign.toRemoveResourceSelection = removeSelection;
  };

  const handleActivitySelection = (evt) => {
    const checked = evt.target.checked;
    resource.active = checked;
    checked ? checkedActivities() : uncheckedActivities({ ...resource });
  };

  const filterWorkersByDate = () => {
    const date = dateInit.format('YYYY-MM-DD');
    const workers = resource.workersAssigment.filter((worker) =>
      worker.date.includes(date)
    );
    setWorkerAssign(workers.length ? workers : null);
  };

  return (
    <Row style={{ marginBottom: 10, paddingLeft: padding }}>
      <Col offset={1} span={23}>
        <div ref={$resourceBox} className={styles.resourceName}>
          <div>
            <Checkbox
              checked={resource.active}
              onChange={handleActivitySelection}
            />
            <span
              className={`${styles.itemContent} ${resource.has_childs && styles.mainItem}`}>
              {splitText(resource.name, widthText)}
            </span>
          </div>
          {workerAssign ? (
            <Popover
              content={
                <ListAsociations
                  userLang={userLang}
                  workers={workerAssign}
                  t={t}
                />
              }
              placement="right"
              overlayClassName="show-units-activity-modal"
              trigger="click">
              <UserOutlined />
            </Popover>
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

const RowItem =
  (components) =>
  ({ index: row, style }) => <div style={{ ...style }}>{components[row]}</div>;

const List = ({ className, element, tableHeight, tableWidth, itemSize }) => (
  <>
    <VariableSizeList
      className={className}
      height={tableHeight}
      itemCount={element.length}
      itemSize={(_) => itemSize}
      width={tableWidth}>
      {RowItem(element)}
    </VariableSizeList>
  </>
);

List.propTypes = {
  tableHeight: PropTypes.number.isRequired,
  tableWidth: PropTypes.number.isRequired,
  className: PropTypes.string,
  element: PropTypes.array.isRequired,
  itemSize: PropTypes.number.isRequired
};

export default List;
