import React from 'react';
import { connect } from 'react-redux';
import { activityUpdateResourceRequested } from '../../../../redux/slices/activitySlice';

import { EditableLabel } from '../../../EditableLabel/EditableLabel';
import { getActualQuantity } from '../../utils';
import styles from './ResourcesTab.module.scss';
import sharedStyles from './shared.module.scss';

export const ResourcesTab = ({
  cardData,
  activityUpdateResourceRequested,
  t
}) => {
  return (
    <div className={cardData.resources.length > 4 ? styles.tableFixHead : ''}>
      <table className={styles['resources-tab__table']}>
        <thead>
          <tr>
            <th className={styles['resources-tab__name-column']}>
              {t('activity_card.name')}
            </th>
            <th>{t('activity_card.type')}</th>
            <th className={sharedStyles['right-aligned-cell']}>
              {t('activity_card.quantity')}
            </th>
            <th className={sharedStyles['right-aligned-cell']}>
              {t('activity_card.actual')}
            </th>
          </tr>
        </thead>
        <tbody>
          {cardData.resources?.map((resource, i) => {
            return (
              <tr key={i}>
                <td>{resource.name}</td>
                <td>{t(`settings.project_categories.${resource.type}`)}</td>
                <td className={sharedStyles['right-aligned-cell']}>
                  <EditableLabel
                    inputConfig={{
                      type: 'number'
                    }}
                    initialValue={
                      resource?.originalResourceObject?.activitysectorresource
                        ?.quantity
                    }
                    renderer={(value) => `${value} ${resource.unit}`}
                    onChange={(quantity) =>
                      activityUpdateResourceRequested({
                        resource: {
                          ...resource.originalResourceObject,
                          total: quantity
                        }
                      })
                    }
                    className={styles['resources-tab__quantity-input']}
                  />
                </td>
                <td
                  className={
                    sharedStyles['right-aligned-cell']
                  }>{`${getActualQuantity(resource, cardData)} ${resource.unit}`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const actionCreators = {
  activityUpdateResourceRequested
};

export default connect(null, actionCreators)(ResourcesTab);
