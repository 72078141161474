import React, { useEffect, useState } from 'react';

import IconComponent from '../../Projects/IconSvg';
import { RENDER_TAG } from '../../GanttVisualization/dependencies/constants';
import CustomDropdownSelector from '../../CustomDropdownSelector/CustomDropdownSelector';
import RemoveIcon from '../../../assets/img/gantt/X.png';
import { getTagTextColor } from '../utils';
import { getCompanyTextColor } from '../../../utils';

export const TagDropdown = (props) => {
  /** get props */
  const {
    cardData,
    customMainClassName,
    t,
    activityCardUpdateLocally,
    activityUpdateRequested
  } = props;

  /** constansts */
  const gantt = window.to_use_react_gantt;

  /** states */
  const [state, setState] = useState(RENDER_TAG);

  /** effects */
  useEffect(() => {
    setState({
      ...state,
      mainObject: cardData,
      // options: cardData?.tags
      options: gantt?.toSelectTags
      // ?.map(el => {
      //     return {
      //         name: el.name,
      //         id: el.id,
      //         color: el.color
      //     }
      // })
    });
  }, [cardData]);

  /** validations */
  if (!cardData) return null;

  /** inside functions */

  /**
   * this function is executed when adding a new item
   */
  const callbackOnClickCreate = () => {
    gantt.toHardAssignTag = null;
    gantt.setVisibleFormTags(true);
  };

  /**
   * this function is executed when rendering the item of the entity
   * @param {*} selected item of the entity (in this case the assigned subcontract)
   * @returns
   */
  const renderSelectedOption = (selected) => {
    return (
      <div>
        {
          <ul>
            {cardData?.tags?.map((tag, i) => (
              <li
                key={i}
                style={{
                  backgroundColor: tag.color || tag.description,
                  color: getTagTextColor(tag.color || tag.description)
                }}>
                <span>{tag.name}</span>
              </li>
            ))}
          </ul>
          // );
          // })
        }
      </div>
    );
  };

  /**
   * this function renders all the options of the entity
   * @param {*} option item to be rendered
   * @returns array render
   */
  const renderTagToSelect = (option) => {
    const { object } = option;
    return (
      <div className="item-gantt">
        <div
          className="subcontract-border cut-text-and-dot"
          style={{
            height: '100%',
            color: `${getCompanyTextColor(object.description)}`,
            padding: 0,
            backgroundColor: object.description
          }}>
          <div
            className="text-subcontract cut-text-and-dot"
            style={{
              alignContent: 'center',
              textAlign: 'center',
              position: 'relative',
              top: 7,
              float: 'left'
            }}>
            <span style={{ marginLeft: 10 }}>
              <IconComponent
                viewBox="0 0 16 16"
                data={
                  'M0.409302 5.87389V1.23607C0.444715 0.527453 1.01572 0.00853333 1.64537 0H6.28318C7.02902 0.0463467 7.91953 0.33876 8.39779 0.878533L15.2626 8.51972C15.6938 9.06561 15.706 9.82104 15.2626 10.2768L9.88925 15.6501C9.3624 16.1267 8.57631 16.1065 8.13219 15.6501L1.28782 7.98849C0.788875 7.39925 0.416182 6.61145 0.409302 5.87389ZM2.42174 3.19744C2.45098 3.88691 2.99486 4.38449 3.60674 4.39265C4.2982 4.36177 4.79378 3.81064 4.80196 3.19744C4.77152 2.50567 4.21757 2.02047 3.60674 2.01244C2.91694 2.04125 2.42973 2.58795 2.42174 3.19744Z'
                }
                width={14}
                fill={object.description}
              />
              <span style={{ marginLeft: 10 }}>{object.name}</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  /**
   * this function renders the remove item button
   * @returns jsx to render
   */
  const renderRemoveBtn = () => {
    return (
      <div className="remove-option-sub-custom">
        <div className="remove-sub-x">
          <img width="10" src={RemoveIcon} />
        </div>
        <div className="remove-sub-text">{t('remove_subcontract_label')}</div>
      </div>
    );
  };

  /**
   * this function is a callback of the update saga call
   * @param {*} originalActivityObject activity to update
   */
  const onUpdated = (originalActivityObject) => {
    /** update gantt */
    const gantt = window.to_use_react_gantt;
    if (gantt.isTaskExists(originalActivityObject.unique_id)) {
      const taskGantt = gantt.getTask(originalActivityObject.unique_id);
      taskGantt.tags = originalActivityObject.tags;
      gantt.updateTask(taskGantt.id);
    }

    /** update reference */
    activityCardUpdateLocally({
      ...cardData,
      tags: originalActivityObject.tags
    });
  };

  /**
   * this function updates the subcontract of an activity
   * @param {*} entity activity to update
   * @param {*} subcontract subcontract to assign
   */
  const assignTagToActivity = (entity, tags = []) => {
    let newDataActivity;
    if (tags === []) {
      newDataActivity = {
        ...entity,
        tags: []
      };
    } else {
      newDataActivity = {
        ...entity,
        tags: tags
      };
    }
    onUpdated(newDataActivity);
  };

  /**
   * this function fires every time we select an item from the dropdown
   * @param {*} payload selected option information
   */

  const onSelection = (payload, dictionary) => {
    const newVal = [];
    payload.map((op) => {
      newVal.push(dictionary[op]);
    });
    setState({
      ...state,
      mainObject: {
        ...state.mainObject,
        tags: payload.map((id) => dictionary[id])
      }
    });
    /** update Tag */
    assignTagToActivity(cardData.originalActivityObject, newVal);
  };

  /**
   * this function fires every time we remove the selected item
   * @param {*} _
   */
  const callbackRemove = (_) => {
    setState({
      ...state,
      mainObject: {
        ...state.mainObject,
        tags: []
      }
    });
    /** set null to subcontract */
    assignTagToActivity(cardData.originalActivityObject, []);
  };

  /** render */
  return (
    <CustomDropdownSelector
      {...state}
      customMainClassName={`custom-proplanner-tag ${customMainClassName || ''}`}
      hideCreateBtn={true}
      renderCustomSelectionOption={renderTagToSelect}
      renderCustomPlaceholderElement={renderSelectedOption}
      renderCustomRemoveOption={renderRemoveBtn}
      callbackOnSelection={onSelection}
      callbackOnClickCreate={callbackOnClickCreate}
      callbackOnClickRemove={callbackRemove}
      closeOnclick={false}
      withTimeout={true}
    />
  );
};
