import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { activityCardUpdateTaskLocally } from '../../../../redux/slices/activitySlice';
import { taskUpdateProgressRequested } from '../../../../redux/slices/taskSlice';
import { AssigneesPicker } from '../../../AssigneesPicker';
import { EditableLabel } from '../../../EditableLabel/EditableLabel';
import {
  formatNumber,
  getChartColor,
  getFormattedRoadblocks,
  mapAssigneesToNewFormat
} from '../../utils';
import styles from './TasksTab.module.scss';
import sharedStyles from './shared.module.scss';
import { dynamicSort } from '../../../../utils';
import TooltipSystemV2 from '../../../../components/DesignSystem/TooltipSystemV2';

export const TasksTab = ({
  cardData,
  activityCardUpdateTaskLocally,
  taskUpdateProgressRequested,
  t,
  setCurrentCardData,
  currentTasks
}) => {
  const [tasks, setTasks] = useState(
    currentTasks.sort(dynamicSort('correlative_number', true))
  );

  useEffect(() => {
    setTasks(currentTasks.sort(dynamicSort('correlative_number', true)));
  }, [currentTasks]);

  const handleChangeResponsables = (id, responsables) => {
    const currentTask = tasks.find((current) => current.id === id);
    currentTask.assignees = mapAssigneesToNewFormat(responsables);

    const newAllTasks = tasks.filter((current) => current.id !== id);
    newAllTasks.push(currentTask);

    setTasks(newAllTasks.sort(dynamicSort('correlative_number', true)));
  };

  const renderEditableLabel = (task, enableEditing) => (
    <EditableLabel
      inputConfig={{
        type: 'number',
        min: 0,
        max: 100
      }}
      initialValue={task.completion}
      renderer={(value) => (
        <span
          style={{
            color: getChartColor(value),
            cursor: task.is_parent ? 'not-allowed' : 'initial'
          }}>
          {formatNumber(value)}%
        </span>
      )}
      onChange={(progress) => {
        const gantt = window.to_use_react_gantt;
        taskUpdateProgressRequested({
          task: task.originalTaskObject,
          activity: cardData.originalActivityObject,
          progress,
          cardData: cardData,
          setCurrentCardData: setCurrentCardData
        });
        gantt.update_progress(cardData.uniqueId);
      }}
      enableEditing={enableEditing}
      inputClassName={styles['tasks-tab__progress-input']}
    />
  );

  return (
    <div className={tasks.length > 4 ? styles.tableFixHead : ''}>
      <table className={styles['tasks-tab__table']}>
        <thead>
          <tr>
            <th>{t('activity_card.name')}</th>
            <th className={sharedStyles['centered-cell']}>
              {t('activity_card.roadblocks')}
            </th>
            <th>{t('activity_card.start')}</th>
            <th>{t('activity_card.duration')}</th>
            <th>{t('activity_card.end')}</th>
            <th className={sharedStyles['centered-cell']}>
              {t('activity_card.assignee')}
            </th>
            <th>{t('activity_card.progress')}</th>
          </tr>
        </thead>
        <tbody>
          {tasks?.map((task, i) => {
            return (
              <tr key={i}>
                <td>{task.title}</td>
                <td className={sharedStyles['centered-cell']}>
                  {getFormattedRoadblocks(task.roadblocks)}
                </td>
                <td>{task.startDate}</td>
                <td>
                  {task.duration} {`day${task.duration === 1 ? '' : 's'}`}
                </td>
                <td>{task.endDate}</td>
                <td className={sharedStyles['centered-cell']}>
                  {
                    <AssigneesPicker
                      selectedUsers={task.assignees}
                      entity={task.originalTaskObject}
                      onUpdated={(currentTask) =>
                        handleChangeResponsables(
                          currentTask.id,
                          currentTask.responsables
                        )
                      }
                    />
                  }
                </td>
                <td>
                  {task.is_parent
                    ? TooltipSystemV2({
                        title: t('activity_card_progress_tooltip'),
                        theme: 'dark',
                        placement: 'topLeft',
                        children: (
                          <div
                            style={{ display: 'inline-block', width: '100%' }}>
                            {renderEditableLabel(task, false)}
                          </div>
                        )
                      })
                    : renderEditableLabel(task, true)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const actionCreators = {
  activityCardUpdateTaskLocally,
  taskUpdateProgressRequested
};

export default connect(null, actionCreators)(TasksTab);
