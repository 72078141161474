import React, { useEffect, useState, useRef } from 'react';

import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import Range from 'react-range-progress';

import style from './style.module.css';

const ProgressBar = (props) => {
  const { value, setValue, massiveProgressHandler, t } = props;
  const [enableInput, setEnableInput] = useState(false);
  const [progress, setProgress] = useState(value);
  const input = useRef(null);

  useEffect(() => {
    if (input && enableInput) {
      input.current.select();
    }
  }, [input, enableInput]);

  const handleToggleInput = () => {
    setEnableInput(!enableInput);
  };

  const handleChange = (e) => {
    if (isNaN(e.target.value)) return;

    if (e.target.value > 100) {
      e.target.value = 100;
    }

    setProgress(e.target.value);
  };

  const handleSave = () => {
    enableInput && handleToggleInput();
    setValue(parseInt(progress));
  };

  return (
    <>
      <div style={{ textAlign: 'center', width: 111 }}>
        {t('progress_only_label')}
      </div>
      <div style={{ paddingLeft: 5 }}>
        <div style={{ position: 'relative' }}>
          {!enableInput && (
            <EditOutlined
              onClick={handleToggleInput}
              className={style.iconEditable}
            />
          )}
          {enableInput ? (
            <input
              ref={input}
              value={progress}
              onChange={handleChange}
              onBlur={handleSave}
              className={style.input}
            />
          ) : (
            <span className={style.progress} onDoubleClick={handleToggleInput}>
              {progress}%
            </span>
          )}
        </div>
        <span onMouseUp={handleSave}>
          <Range
            className="progress-massive-bar"
            style={{ top: -2 }}
            value={progress}
            fillColor={{
              r: 245,
              g: 157,
              b: 4,
              a: 1
            }}
            trackColor={{
              r: 245,
              g: 245,
              b: 245,
              a: 1
            }}
            height={7}
            width="58%"
            onChange={(value) => {
              setProgress(value);
            }}
          />
        </span>
      </div>
      <div className="progress-massive-btn" onClick={massiveProgressHandler}>
        {t('apply_label')}
      </div>
    </>
  );
};

export default ProgressBar;
