import React from 'react';
import { Tooltip, Row, Col, Checkbox } from 'antd';

// Icons as SVG
import ganttVisualization from '../../assets/img/takt/ganttVisualization.svg';
import groupVisualization from '../../assets/img/takt/groupVisualization.svg';
import { T } from 'antd/lib/upload/utils';

export const splitText = (text, width) => {
  if (typeof text === 'string') {
    let showedText = text;
    let tooltip;
    const colAdjustWidth = Math.trunc(width / 5);
    const textAdjustWidth = showedText.length * 2;

    if (textAdjustWidth > colAdjustWidth) {
      showedText = text.slice(0, Math.trunc(colAdjustWidth / 2)) + '...';
      tooltip = true;
    }
    return tooltip ? (
      <Tooltip placement="top" title={text}>
        {showedText}
      </Tooltip>
    ) : (
      showedText
    );
  }
  return text;
};

export const GrouppableLevels = (props) => (
  <>
    <Row>
      <Col>
        <Row>
          <div className="title-levels-takt">
            {props.t('takt_assign.groupLevel')}
          </div>
        </Row>
        <Row>
          <div className="line-block-levels"></div>
        </Row>
        <Row>
          <span style={{ marginRight: 10 }}>
            <Checkbox
              checked={props?.visibilityLevels.structure}
              onChange={(e) =>
                props?.setVisibilityLevels({
                  ...props?.visibilityLevels,
                  structure: !props?.visibilityLevels.structure
                })
              }
            />
          </span>
          <span>{props.t('takt_assign.structure')}</span>
        </Row>
        <Row>
          <span style={{ marginRight: 10 }}>
            <Checkbox
              checked={props.visibilityLevels.location}
              onChange={(e) =>
                props?.setVisibilityLevels({
                  ...props?.visibilityLevels,
                  location: !props?.visibilityLevels.location
                })
              }
            />
          </span>
          <span>{props.t('takt_assign.location')}</span>
        </Row>
        <Row>
          <span style={{ marginRight: 10 }}>
            <Checkbox
              checked={props.visibilityLevels.unit}
              onChange={(e) =>
                props.setVisibilityLevels({
                  ...props.visibilityLevels,
                  unit: !props.visibilityLevels.unit
                })
              }
            />
          </span>
          <span>{props.t('takt_assign.unit')}</span>
        </Row>
        <Row>
          <button
            className="assign-takt-btn apply-visualization-style"
            onClick={() => props.updateVisualization(props.visibilityLevels)}>
            {props.t('takt_assign.groupAction')}
          </button>
        </Row>
      </Col>
    </Row>
  </>
);

export const VisualizationTree = (props) => {
  const handleGroupVisualization = () => {
    props.treeVisualization && props.changeTreeVisualization();
  };

  const handleGanttVisualization = () => {
    !props.treeVisualization && props.changeTreeVisualization();
  };

  return (
    <>
      <Row onClick={handleGroupVisualization} style={{ cursor: 'pointer' }}>
        <img src={groupVisualization} width={10} style={{ marginRight: 10 }} />
        <span>{props.t('takt_assign.group')}</span>
      </Row>
      <Row onClick={handleGanttVisualization} style={{ cursor: 'pointer' }}>
        <img src={ganttVisualization} width={10} style={{ marginRight: 10 }} />
        <span>{props.t('takt_assign.gantt')}</span>
      </Row>
    </>
  );
};
