const updateCostAndHH = (activity) => {
  const gantt = window.to_use_react_gantt;

  try {
    if (activity.parent && activity.parent != '0') {
      if (!gantt.isTaskExists(activity.parent)) return;
      const parent = gantt.getTask(activity.parent);
      const childs = gantt.getChildren(parent.id);
      let hhWorkTime = 0;
      let cost = 0;
      let usedCost = 0;
      let realWork = 0;
      if (childs.length) {
        childs.forEach((id) => {
          if (!gantt.isTaskExists(id)) return;
          const task = gantt.getTask(id);
          cost += parseFloat(task.cost) || 0;
          usedCost += parseFloat(task.used_cost) || 0;
          hhWorkTime += task.hhWorkTime ? parseFloat(task.hhWorkTime) : 0;
          const aux = task.real_work
            ? parseFloat(task.real_work)
            : task.hhWorkTime
              ? (parseFloat(task.hhWorkTime) * task.progress) / 100
              : 0;
          realWork += aux;
        });
      }
      parent.cost = cost;
      parent.used_cost = usedCost;
      parent.hhWorkTime = hhWorkTime;
      parent.real_work = realWork;
      /** make the function recursive */
      updateCostAndHH(parent);
    }
  } catch (e) {
    console.log(e);
    console.log('Error');
  }
};

export { updateCostAndHH };
