import React, { useRef, useState } from 'react';
import * as Icons from '../../icons';

import { CloseOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import styles from './activityTree.module.scss';
import './ActivityTreePane.scss';

import Filters from './components/FiltersPane';
import ActivityTree from './components/ActivityTree';

const PARAGRAPH_ROWS_COUNT = 10;

const ActivityTreePane = (props) => {
  const {
    isReloadDataViews,
    actitySettings = false,
    activities = [],
    filterSettings = [],
    viewId = null,
    setActivityTreePaneSettings = {},
    lastLevelActivitiesIds,
    onCommandDispach = (_) => {},
    t = (_) => {},
    closePane = (_) => {}
  } = props;

  const { current: existTreePaneSettings } = useRef(!!actitySettings);

  const [chageDateRange, setChangeDateRange] = useState(false);
  const [firstDateFilter, setFirstDateFilter] = useState(false);
  const [isTreeRender, setIsTreeRender] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [activitySuggestion, setActivitySuggestion] = useState({});
  const [datesRange, setDateRange] = useState({});
  const [isShowedFilters, setIsShowedFilters] = useState(false);

  const callbackMoreFilters = (currentValue = isShowedFilters) => {
    setIsShowedFilters(currentValue);
  };

  const handleClosePane = (_) => {
    closePane(false);
  };

  return (
    <div className="activity-tree-pane__container">
      <div className="container__header">
        <div className="header__left">
          <Icons.FilterIcon />
          <span>{t('filters_label.lookahead_activities_label')}</span>
        </div>
        <div className="header__right">
          <CloseOutlined onClick={handleClosePane} />
        </div>
      </div>
      {isReloadDataViews ? (
        <>
          <div />
          <div>
            <Skeleton
              loading={isReloadDataViews}
              active
              paragraph={{ rows: PARAGRAPH_ROWS_COUNT }}
            />
          </div>
          <div />
        </>
      ) : (
        <>
          <div className="container__filters">
            <Filters
              onClickMoreFilters={callbackMoreFilters}
              activities={activities}
              actitySettings={actitySettings}
              chageDateRange={chageDateRange}
              datesRange={datesRange}
              setFilterApplied={setFilterApplied}
              filterApplied={filterApplied}
              isTreeRender={isTreeRender}
              setChangeDateRange={setChangeDateRange}
              setDateRange={setDateRange}
              setActivitySuggestion={setActivitySuggestion}
              filterSettings={filterSettings}
              setFirstDateFilter={setFirstDateFilter}
              firstDateFilter={firstDateFilter}
              t={t}
            />
          </div>
          <div className="container__tree">
            <ActivityTree
              isShowedFilters={isShowedFilters}
              activitySuggestion={activitySuggestion}
              actitySettings={actitySettings}
              existTreePaneSettings={existTreePaneSettings}
              activities={activities}
              filterApplied={filterApplied}
              chageDateRange={chageDateRange}
              filterSettings={filterSettings}
              setChangeDateRange={setChangeDateRange}
              setIsRender={setIsTreeRender}
              isRender={isTreeRender}
              datesRange={datesRange}
              viewId={viewId}
              firstDateFilter={firstDateFilter}
              onCommandDispach={onCommandDispach}
              lastLevelActivitiesIds={lastLevelActivitiesIds}
              setActivityTreePaneSettings={setActivityTreePaneSettings}
              setFirstDateFilter={setFirstDateFilter}
              t={t}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ActivityTreePane;
