const SubmittalStatusEnum = Object.freeze({
  DRAFT: 'Draft',
  OPEN: 'Open',
  CLOSED: 'Closed'
});

export const determineCurrentStatus = (
  status,
  startDatePlanned,
  approvalDatePlanned,
  actualApprovalDate,
  currentDate
) => {
  const isNotStarted = startDatePlanned > currentDate;
  const isInProgress = approvalDatePlanned > currentDate;
  const isDelayed =
    startDatePlanned <= currentDate || approvalDatePlanned <= currentDate;
  const isOK = actualApprovalDate <= approvalDatePlanned;
  const isExceeded = actualApprovalDate > approvalDatePlanned;

  const statusMapping = Object.freeze({
    [SubmittalStatusEnum.DRAFT]: [
      [isNotStarted, { status: SubmittalStatusEnum.DRAFT, color: '#747474' }],
      [isDelayed, { status: SubmittalStatusEnum.DRAFT, color: '#E50101' }]
    ],
    [SubmittalStatusEnum.OPEN]: [
      [isInProgress, { status: SubmittalStatusEnum.OPEN, color: '#498E98' }],
      [isDelayed, { status: SubmittalStatusEnum.OPEN, color: '#E50101' }]
    ],
    [SubmittalStatusEnum.CLOSED]: [
      [isOK, { status: SubmittalStatusEnum.CLOSED, color: '#2ECE3E' }],
      [isExceeded, { status: SubmittalStatusEnum.CLOSED, color: '#F26D0C' }]
    ]
  });

  const mapping = statusMapping[status];
  if (!mapping) {
    throw new Error(`Unknown submittal status: ${status}`);
  }

  for (const [condition, mappedStatus] of mapping) {
    if (condition) return mappedStatus;
  }

  return null;
};
