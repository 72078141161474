import React, { useContext, useEffect, useState, useRef } from 'react';
import { Row, Col, Modal, Radio } from 'antd';
import styles from './MatrixSettings.module.scss';

export const RenderSettings = ({ t, visible, handleOk, handleCancel }) => {
  const [shown, setShown] = useState();

  const onChange = (e) => {
    setShown(e.target.value);
  };

  return (
    <div>
      <Modal
        title={t('master_plan.config_label')}
        visible={visible}
        onOk={() => handleOk(shown)}
        onCancel={handleCancel}>
        <Row>
          <Col span={8}>{t('analitic.shown')}</Col>
          <Col span={12}>
            <Radio.Group onChange={onChange} defaultValue={'monthly'}>
              <Radio.Button value="weekly">{t('analitic.weekly')}</Radio.Button>
              <Radio.Button value="monthly">
                {t('analitic.monthly')}
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
