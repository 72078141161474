import React, { useContext, useEffect, useRef, useState } from 'react';
import difference from 'lodash/difference';
import { ACTIONS } from '../../scroll.actions';

// Components
import { FixedSizeGrid as Grid } from 'react-window';
import { Checkbox, Col, Popover } from 'antd';

// Context
import { ProductivityAssignCtx } from '../../../../Context/Productivty/Assign';

// Assets
import './index.css';
import style from './index.module.css';

// => Utilities
import { splitText } from '../../../ActivitiesUnitsGrid/index.helper';

export const CrewWorkersRow = (props) => {
  const { crew, crewWorkers = null, isCrew, hoursPerDay, gridWidth } = props;
  let millionFormat;
  let lastNumberRut;
  let formatRut;
  let crewRutFormat;

  let hoursToDays;

  if (!isCrew) {
    millionFormat = crew.rut.substr(0, crew.rut.length - 1);
    lastNumberRut = crew.rut.substr(crew.rut.length - 1, 1);
    formatRut = new Intl.NumberFormat().format(millionFormat);
    crewRutFormat = `${formatRut} - ${lastNumberRut}`;
    hoursToDays = crew.assist.hours
      ? `${(crew.assist.hours / hoursPerDay).toFixed(2)} d`
      : '1 d';
  }

  const productivityAssign = useContext(ProductivityAssignCtx);

  const disableCheckbox = !isCrew ? !crew.assist.worked : false;

  const $activityBox = useRef(null);

  const toogleCheckWorkers = () => {
    crew.active = !crew.active;

    if (crewWorkers) {
      crewWorkers.forEach((worker) => {
        if (worker.assist.worked) {
          worker.active = crew.active;
        }
      });
    }
  };

  const workerSelection = (isActive = false) => {
    toogleCheckWorkers();
    let workerSelected = [];

    if (isCrew) {
      workerSelected = crewWorkers.filter(
        (worker) => worker.active == isActive
      );
    }

    if (crew.crewId) {
      workerSelected = [...workerSelected, crew];
    }

    return workerSelected;
  };

  const checkedWorkers = (checked) => {
    const workersToAdd = workerSelection(checked);
    const diff = difference(
      workersToAdd,
      productivityAssign.workerSelected,
      'id'
    );
    productivityAssign.addWorkerSeledted = diff;
  };

  const uncheckedAWorkers = (checked) => {
    const workersToDelete = workerSelection(checked);
    const diff = difference(
      productivityAssign.workerSelected,
      workersToDelete,
      'id'
    );
    productivityAssign.toRemoveWorkerSelection = diff;
  };

  const handleWorkersSelection = (evt) => {
    const checked = evt.target.checked;
    checked ? checkedWorkers(checked) : uncheckedAWorkers(checked);
  };

  let splitTextSize = gridWidth > 400 ? gridWidth / 3 + 40 : gridWidth / 3;
  splitTextSize = isCrew ? splitTextSize : splitTextSize + 60;
  const cellWidth = isCrew
    ? `${(gridWidth / 3) * 2 + 5}px`
    : `${(gridWidth / 3) * 2}px`;

  return (
    <Col className={style.workerContiner}>
      <div
        style={{ width: cellWidth, paddingLeft: isCrew ? 5 : 20 }}
        className="workers"
        ref={$activityBox}
        className={`${isCrew && style.workerCrewWidth} ${style.workerName}`}>
        <Checkbox
          checked={crew.active}
          onChange={handleWorkersSelection}
          disabled={disableCheckbox}
        />
        <Popover
          placement="right"
          overlayClassName="show-units-activity-modal"
          trigger="click">
          <span
            className={`${style.crewName} ${isCrew && style.isCrew} ${disableCheckbox && style.disabled}`}>
            {splitText(crew.name, splitTextSize)}
          </span>
        </Popover>
      </div>
      <div
        style={{ width: `${gridWidth / 3}px` }}
        className={`${style.workerRut} ${disableCheckbox && style.disabled}`}>
        <Popover
          placement="right"
          overlayClassName="show-units-activity-modal"
          trigger="click">
          <span
            className={`${style.crewName} ${isCrew && style.isCrew} ${disableCheckbox && style.disabled}`}>
            {splitText(crew.position, 100)}
          </span>
        </Popover>
      </div>
      <div
        className={`${style.workerHour} ${disableCheckbox && style.disabled}`}
        style={{ width: `${gridWidth / 3}px` }}>
        {(!isCrew && !disableCheckbox && hoursToDays) || ' - '}
      </div>
    </Col>
  );
};

const Worker =
  (workers) =>
  ({ rowIndex, style }) => <div style={style}>{workers[rowIndex]}</div>;

const ListWorkers = (props) => {
  const {
    className,
    gridListRef,
    gridHeight,
    gridWidth,
    listContentRef,
    listWorkers,
    onScroll
  } = props;

  // ==> States
  const [rows, setRows] = useState(0);

  useEffect(() => {
    if (listWorkers) {
      setRows(listWorkers.length);
    }
  }, [listWorkers]);

  // ==> end hooks

  const handleScroll = (scroll) =>
    onScroll({ scrollFrom: ACTIONS.WORKER_LIST })(scroll);

  return (
    <div className={className} ref={gridListRef}>
      <Grid
        ref={listContentRef}
        className={style.listContainer}
        columnCount={1}
        columnWidth={gridWidth}
        height={gridHeight}
        rowCount={rows}
        rowHeight={25}
        width={gridWidth}
        onScroll={handleScroll}>
        {Worker(listWorkers)}
      </Grid>
    </div>
  );
};

export default React.memo(ListWorkers);
