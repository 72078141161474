import React, { useContext, useEffect, useRef, useState } from 'react';
import { executeNewToolbarCommand } from '../../components/LookaheadFilterHeader/index.interface';
import MenuSubmodules from '../../components/MenuSubmodules';
import { TimelineSettings } from '../../components/TimelineSettings/TimelineSettings';
import { useParams } from 'react-router-dom';
import { ViewsCtx } from '../../Context/Planification/ViewsContext';

import { isFeatureOn } from '../../utils/featureUtils';

/* {
	id: 'resources',
	btnTitle: t('tabs.lookahead.resources'),
	routeName: `${match.url}/resources`,
	onClick: (params) => {
		history.push(`${match.url}/resources`);
	},
	hide: lookaheadPermissions.resources == 'SA'
}, */

const LookaHeadSubmodulesMenu = (props) => {
  const {
    history = {},
    project = null,
    projectSelected = {},
    lookaheadPermissions = {},
    t
  } = props;

  const [message, setMessage] = useState('');
  const [Comp, setComp] = useState({ Comp: null, props: null });
  const {
    projectIdParam,
    sectorIdParam,
    idsParam,
    projectIdPlan,
    sectorIdPlan
  } = useParams();
  const { current: messageOptions } = useRef({
    DURATION: 'info_criteria_task_duration',
    COST: 'info_criteria_task_cost',
    HH: 'info_criteria_task_hh'
  });

  const {
    viewCtx: [view],
    ganttObject: [gantt]
  } = useContext(ViewsCtx);
  useEffect(() => {
    projectSelected &&
      setMessage(
        t(messageOptions[projectSelected?.task_creter?.toUpperCase()])
      );
  }, [projectSelected]);

  useEffect(
    (_) => {
      view && view.name == 'gantt' && isFeatureOn('lwp-15')
        ? setComp({
            Comp: TimelineSettings,
            props: {
              t,
              onCommandDispatched: onTimelineSettingsCommandDispatched,
              timelineObject: gantt,
              messageModule: t(
                messageOptions[projectSelected.task_creter.toUpperCase()]
              )
            }
          })
        : setComp({ Comp: null, props: null });
    },
    [view]
  );

  const onTimelineSettingsCommandDispatched = (command, value) => {
    if (command.includes('SET_DATE_FORMAT_')) {
      value = command.split('SET_DATE_FORMAT_')[1];
      command = 'DATE_FORMAT';
    }
    executeNewToolbarCommand(command, value);
  };

  const publishSubmodules = ({ history, match, t, toggleActiveButtons }) => {
    const buttons = !project?.project.geo_project_id
      ? [
          {
            id: 'plan',
            title: t('tabs.lookahead.plan'),
            routeName: `${match.url}/planification`,
            onClick: (_) => {
              toggleActiveButtons(1);
              if (
                projectIdParam ||
                sectorIdParam ||
                idsParam ||
                projectIdPlan ||
                sectorIdPlan
              ) {
                history.push('/lookahead/planification');
              } else {
                history.push(`${match.url}/planification`);
              }
            },
            hide: lookaheadPermissions?.plan == 'SA'
          },
          {
            id: 'constraints',
            title: t('tabs.lookahead.constraints'),
            routeName: `${match.url}/constraints`,
            onClick: (_) => {
              toggleActiveButtons(2);
              if (
                projectIdParam ||
                sectorIdParam ||
                idsParam ||
                projectIdPlan ||
                sectorIdPlan
              ) {
                history.push('/lookahead/constraints');
              } else {
                history.push(`${match.url}/constraints`);
              }
            },
            hide: lookaheadPermissions?.constraints == 'SA'
          }
        ]
      : [
          {
            id: 'plan',
            title: t('tabs.lookahead.plan'),
            routeName: `${match.url}/planification`,
            onClick: (_) => {
              toggleActiveButtons(1);
              if (
                projectIdParam ||
                sectorIdParam ||
                idsParam ||
                projectIdPlan ||
                sectorIdPlan
              ) {
                history.push('/lookahead/planification');
              } else {
                history.push(`${match.url}/planification`);
              }
            },
            hide: lookaheadPermissions?.plan == 'SA'
          },
          {
            id: 'constraints',
            title: t('tabs.lookahead.constraints'),
            routeName: `${match.url}/constraints`,
            onClick: (_) => {
              toggleActiveButtons(2);
              if (
                projectIdParam ||
                sectorIdParam ||
                idsParam ||
                projectIdPlan ||
                sectorIdPlan
              ) {
                history.push('/lookahead/constraints');
              } else {
                history.push(`${match.url}/constraints`);
              }
            },
            hide: lookaheadPermissions?.constraints == 'SA'
          }
        ](projectIdParam && sectorIdParam) &&
        buttons.map((el) => {
          el.id === 'constraints' && (el.className = 'lookahead-btn-style');
          return { ...el };
        })(projectIdPlan && sectorIdPlan) &&
        buttons.map((el) => {
          el.id === 'plan' && (el.className = 'lookahead-btn-style');
          return { ...el };
        });

    return buttons;
  };

  return (
    <MenuSubmodules
      publishSubmodules={publishSubmodules}
      history={history}
      messageModule={message}
      CustomRigthComp={Comp}
    />
  );
};

export default LookaHeadSubmodulesMenu;
