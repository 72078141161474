import React from 'react';
import closeIcon from '../../img/gantt/close-links-modal.png';
import linkLeftIcon from '../../img/gantt/link-modal-icon.png';
import arrowLink from '../../img/gantt/arrow-link-modal.png';
window.helperFunctions = {};

/**
 * This function close the pop up for double click
 */
export function endPopup() {
  modal = null;
  editLinkId = null;
}

/**
 * This function cancel edit link btn at pop up
 */
export function cancelEditLink() {
  endPopup();
}

/**
 * This function execute delete of a link by accessing it to the double click link at gantt chart
 * @param {*} gantt Instance where user is actually using the gantt lib (dhtmlx) which gives access to their API
 */
export function deleteLink(gantt) {
  gantt.deleteLink(editLinkId);
  endPopup();
}

/**
 * This function saves changes maded through pop up opened by double clicking a link at chart
 * @param {*} gantt Instance where user is actually using the gantt lib (dhtmlx) which gives access to their API
 */
export function saveLink(gantt) {
  const link = gantt.getLink(editLinkId);
  const lagValue = modal.querySelector('.lag-input').value;

  if (!isNaN(parseInt(lagValue, 10))) {
    link.lag = gantt.formatter.parse(lagValue);
  }
  // gantt.updateLink(link.id);
  gantt.deleteLink(link.id);
  gantt.addLink(link);
  if (gantt.autoSchedule) {
    /* gantt.autoSchedule(link.source); */
  }
  endPopup();
}

/**
 * This functions takes the activity type and transforms to an double chart nomenclature
 * @param {*} gantt Instance where user is actually using the gantt lib (dhtmlx) which gives access to their API
 * @param {*} link linki OBJECT which was double clicked through gantt chart
 * @returns An double chart nomenclature which may be (FS, FF, SS or SF)
 */
export function getLinkTypeTittle(gantt, link) {
  let linkTitle;
  switch (link.type) {
    case gantt.config.links.finish_to_start:
      linkTitle = 'FS';
      break;
    case gantt.config.links.finish_to_finish:
      linkTitle = 'FF';
      break;
    case gantt.config.links.start_to_start:
      linkTitle = 'SS';
      break;
    case gantt.config.links.start_to_finish:
      linkTitle = 'SF';
      break;
  }

  return linkTitle;
}

let modal;
let editLinkId;

/**
 * This function allows through the gantt instance and an id link, to a create a modal which shows link data and allows to edit it or deleting
 * @param {*} gantt Instance where user is actually using the gantt lib (dhtmlx) which gives access to their API
 * @returns False to avoid native behaviour of any event
 */
export const link_click_job = (gantt) => (id, e) => {
  // debugger
  editLinkId = id;
  const link = gantt.getLink(id);
  const linkTitle = getLinkTypeTittle(gantt, link);
  const activityFrom = gantt.getTask(link.source).text;
  const activityTo = gantt.getTask(link.target).text;

  modal = gantt.modalbox({
    title: `
            <div class="link-gantt-new-title">
                <span>
                    <img class="fit-left-img-link" width="16" src="${linkLeftIcon}" />
                </span>
                <span class="title-fit-modal-link">
                    Link
                </span>
                <span>
                    <img onclick="window.to_use_react_gantt.modalbox.hide(window.currentOpenModal)" class="fit-right-img-link" width="15" src="${closeIcon}" />
                </span>
            </div>
        `,
    text: `
            <div class="input-modal-link-new">
                <div class="middle-link-info">
                    <span>
                        ${activityFrom} 
                    </span>
                    <span>
                        <img class="arrow-link-description" width="16" src="${arrowLink}" />
                    </span>
                    <span>
                        ${linkTitle}
                    </span>
                    <span>
                        <img class="arrow-link-description" width="16" src="${arrowLink}" />
                    </span>
                    <span>
                        ${activityTo}
                    </span>
                </div>
    			<label>
                    <span style="font-size: 12px; padding-right: 16px; color: #FFFFFF;">
                        Lag
                    </span>
                    <input type='duration' class='lag-input' style="background: #3A4F56; border: 0px !important; color: white; border-radius: 5px; height: 32px; text-align: right;"/>
                </label>
			</div>
        `,
    buttons: [
      { label: 'Delete', css: 'link-delete-btn', value: 'delete' },
      { label: 'Cancel', css: 'link-cancel-btn', value: 'cancel' },
      { label: 'Save', css: 'link-save-btn', value: 'save' }
    ],
    width: '500px',
    type: 'popup-css-class-here',
    callback: function (result) {
      switch (result) {
        case 'save':
          saveLink(gantt);
          gantt.refreshData();
          break;
        case 'cancel':
          cancelEditLink();
          break;
        case 'delete':
          deleteLink(gantt);
          gantt.refreshData();
          break;
      }
    }
  });

  window.currentOpenModal = modal;

  const formattedValue = gantt.formatter.format(link.lag);

  if (formattedValue.includes('NaN')) {
    modal.querySelector('.lag-input').value = '0 days';
  } else {
    modal.querySelector('.lag-input').value = formattedValue;
  }

  // any custom logic here
  return false;
};
