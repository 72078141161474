import React, { Fragment, useEffect, useState } from 'react';
import { Table, Modal, Button, Col } from 'antd';
import './index.css';
import styled from 'styled-components';
import { sectorService } from '../../../../../services/sector.service';
import { useSelector, useDispatch } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import calendarIcon from '../icons/calendar.svg';
import sinGanttIcon from '../icons/sin-gantt.svg';
import { openNotification, compareValues } from '../../../../../utils';
import * as projectActions from '../../../../../redux/slices/projectSlice';
import moment from 'moment';
import { trackingEvent } from '../../../../../analytics';
import { getBasicAmplitudEventProperties } from '../../../../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../../../../analytics/constants';
import { getSignedUser } from '../../../../../utils/userUtils';
import { usersAccessOnlyToVisibleSchedules } from '../../../../../components/Toolbar/Toolbar';

/** Permissions */
const { role } = getSignedUser() || {};
const haveAccessOnlyVisibleSchedules =
  usersAccessOnlyToVisibleSchedules.includes(role);

function TableEtapa({ Sectors, setSectors, onlyRead, t }) {
  const dispatch = useDispatch();
  if (haveAccessOnlyVisibleSchedules) {
    Sectors = Sectors.filter((e) => e.visible === true);
  }
  // GET ID SECTOR FROM REDUX
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [text, setText] = useState('');

  useEffect(() => {
    dispatch(
      projectActions.setProps({
        ...stateProject.props,
        loadSectors: false
      })
    );
    const abortController = new AbortController();
    sectorService
      .getSectorsByProjectWithMasterActivity(projectSelectedId)
      .then(async (response) => {
        if (response.sectors) {
          /** select active sectors  */
          const filterSectors = response.sectors.filter(
            (e) =>
              e.projectId === stateProject.projectSelected && e.status === true
          );
          filterSectors.sort(compareValues('id'));
          filterSectors.sort(compareValues('order'));
          /** update state sectors */
          setSectors(filterSectors);
        }
      })
      .catch((err) => console.log(err));

    return function cleanup() {
      abortController.abort();
    };
  }, [projectSelectedId, reloadTable]);

  const handleSectors = (index, name, value, id) => {
    const copySectors = [...Sectors];
    const findIndex = copySectors.findIndex((el) => el.id === id);
    copySectors[findIndex] = {
      ...copySectors[findIndex],
      [name]: value
    };
    setSectors(copySectors);
  };

  const dispatchSectorUpdate = (val) => {
    dispatch(
      projectActions.setProps({
        ...stateProject.props,
        loadSectors: val
      })
    );
  };

  const updateSector = async (sectorUpdate) => {
    await sectorService
      .update(sectorUpdate)
      .then((response) => {
        if (response === false) {
          const alertError = {
            title: t('settings.project_form.sectors.title'),
            description: t('settings.error_default'),
            type: 'error'
          };
          openNotification(alertError);
        } else {
          dispatchSectorUpdate(true);
          const alertSucces = {
            title: t('settings.project_form.sectors.title'),
            description: t('settings.project_form.sectors.success'),
            type: 'success'
          };
          openNotification(alertSucces);
        }
      })
      .catch(() => {
        const alertSucces = {
          title: t('settings.project_form.sectors.title'),
          description: t('settings.error_default'),
          type: 'error'
        };
        openNotification(alertSucces);
      });
    dispatchSectorUpdate(false);
  };

  const handleOk = async () => {
    trackingEvent(
      'schedule_deletion',
      {
        ...getBasicAmplitudEventProperties(),
        stage_id: dataTable.id,
        stage_name: dataTable.name,
        event_source: 'Project settings'
      },
      AMPLITUDE_SERVICE
    );
    setIsModalVisible(false);
    await updateSector({ ...dataTable, status: false });
    setReloadTable(!reloadTable);
    setDataTable(null);
    setText('');
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setDataTable(null);
    setText('');
  };

  const columns = [
    {
      title: t('settings.project_form.sectors.name'),
      dataIndex: 'name',
      key: 'name',
      render: (name, data, index) => (
        <input
          disabled={onlyRead}
          type="text"
          name="name"
          value={name || ''}
          style={{
            width: '100%',
            fontSize: '11px',
            border: '0px',
            cursor: onlyRead ? 'not-allowed' : 'initial'
          }}
          title={name}
          onChange={(e) =>
            handleSectors(index, e.target.name, e.target.value, data.id)
          }
          onBlur={() => updateSector(data)}
        />
      )
    },
    {
      title: t('settings.project_form.sectors.prod'),
      dataIndex: 'productive',
      key: 'productive',
      render: (productive, data, index) => (
        <Fragment>
          {
            <button
              disabled={onlyRead}
              className={`btn-table-productividad ${
                productive ? 'bg-btn-productivo' : 'bg-no-productivo'
              }`}
              onClick={() =>
                handleSectors(index, 'productive', !productive, data.id)
              }
              onBlur={() => updateSector(data)}
              style={{ cursor: onlyRead ? 'not-allowed' : 'pointer' }}>
              {productive
                ? t('settings.project_form.sectors.prod_name')
                : t('settings.project_form.sectors.not_prod_name')}
            </button>
          }
        </Fragment>
      )
    },
    {
      title: t('settings.project_form.sectors.ini_end'),
      dataIndex: '',
      key: ['createdAt', 'updatedAt'],
      render: (data) => {
        const start_date =
          data.activities && data.activities[0] && data.activities[0].start_date
            ? moment.utc(data.activities[0].start_date).format('DD/MM/YYYY')
            : null;
        const end_date =
          data.activities && data.activities[0] && data.activities[0].end_date
            ? moment.utc(data.activities[0].end_date).format('DD/MM/YYYY')
            : null;
        return data.gantts && data.gantts.length ? (
          <>
            <div style={{ display: 'inline-block', margin: '3px' }}>
              {start_date ? (
                <>
                  <img
                    src={calendarIcon}
                    alt="calendar"
                    height="12px"
                    width="12px"
                  />
                  <span className="date-stage" style={{ marginLeft: '3px' }}>
                    {start_date}
                  </span>
                </>
              ) : null}
            </div>
            <div style={{ display: 'inline-block', margin: '3px' }}>
              {end_date ? (
                <>
                  <img
                    src={calendarIcon}
                    alt="calendar"
                    height="12px"
                    width="12px"
                  />
                  <span className="date-stage" style={{ marginLeft: '3px' }}>
                    {end_date}
                  </span>
                </>
              ) : null}
            </div>
          </>
        ) : (
          <StyleBoxGantt>
            <div style={{ display: 'inline-block' }}>
              <span style={{ fontSize: '10px' }}>
                {t('settings.project_form.sectors.no_gantt')}
              </span>
              <div className="box-orange-gantt">
                <img
                  style={{ marginLeft: '3px' }}
                  src={sinGanttIcon}
                  alt="no gantt"
                  height="12px"
                  width="12px"
                />
              </div>
            </div>
          </StyleBoxGantt>
        );
      }
    },
    {
      dataIndex: 'id',
      key: 'id',
      render: (id, data, index) => (
        <>
          {Sectors.length !== 1 && (
            <DeleteOutlined
              onClick={() => {
                setIsModalVisible(!onlyRead && !data.set_current);
                setDataTable(data);
              }}
              style={{
                cursor: onlyRead || data.set_current ? 'not-allowed' : 'pointer'
              }}
            />
          )}
          <Modal
            wrapClassName="activity-modification-style"
            title={t('settings.project_form.sectors.sure')}
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={[
              <Button
                onClick={() => {
                  if (text == t('settings.project_form.sectors.borrar')) {
                    handleOk();
                  }
                }}
                key="submit"
                style={{
                  background: '#7DFF8A',
                  color: '#121212',
                  borderColor: '#7DFF8A'
                }}>
                {t('settings.project_form.sectors.delete')}
              </Button>
            ]}>
            <Col span={24}>
              <div style={{ color: '#7DFF8A', textAlign: 'center' }}>
                {t('settings.project_form.sectors.deleting_sector')}
              </div>
              <div
                style={{
                  color: '#7DFF8A',
                  textAlign: 'center',
                  marginTop: 7,
                  marginBottom: 7
                }}>
                {t('settings.project_form.sectors.escribe')}{' '}
                <span style={{ color: '#7DFF8A' }}>
                  {t('settings.project_form.sectors.borrar')}
                </span>{' '}
                {t('settings.project_form.sectors.rest_sector')}
              </div>
              <div style={{ textAlign: 'center' }}>
                <span>
                  <input
                    style={{ width: '50%' }}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    className="structure-input"
                  />
                </span>
              </div>
            </Col>
          </Modal>
        </>
      )
    }
  ];
  return (
    <div>
      <StyledTableEtapaProyect>
        <Table
          columns={columns}
          dataSource={Sectors}
          ProjectsAll={Sectors}
          rowKey={(obj) => obj.id}
          className="head-table-settings"
          rowClassName="tr-class"
        />
      </StyledTableEtapaProyect>
    </div>
  );
}

export default TableEtapa;

const StyledTableEtapaProyect = styled.div`
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 0px 13px;
    overflow-wrap: break-word;
    font-size: 12px;
    color: gray;
  }
  @media (min-width: 1609px) and (max-width: 1790px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 0px 13px;
      overflow-wrap: break-word;
      font-size: 12px;
      color: gray;
    }
  }
`;

const StyleBoxGantt = styled.div`
  .box-orange-gantt {
    width: 18px;
    height: 18px;
    background: #7dff8a;
    display: inline-block;
    margin-left: 10px;
    border-radius: 5px;
  }
`;
