import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Card, Button, Empty, Select, Popconfirm, Icon } from 'antd';
import styled from 'styled-components';
import deleteIcon from '../../icons/delete.svg';
import {
  constraintTypeService,
  constraintService
} from '../../../../../../services';
import {
  openNotification,
  categoriesConstraintType,
  renderIconCat
} from '../../../../../../utils';
import { useSelector } from 'react-redux';
import Editable from '../../../../Editable/Editable';

function ConstraintsTypes({
  ContraintsTypesAll,
  setContraintsTypesAll,
  onlyRead,
  t
}) {
  const { Option } = Select;

  /** refs */
  const inputRef = useRef();

  /** hooks */
  const [idEdit, setIdEdit] = useState(false); /** handle load */
  const [allConstraintsProject, setAllConstraintsProject] = useState([]);

  // GET ID PROJECT FROM REDUX
  const stateProject = useSelector((state) => state.projectState);

  const projectSelectedId = stateProject.projectSelected;
  useEffect(() => {
    const abortController = new AbortController();
    constraintTypeService
      .showByProject(projectSelectedId)
      .then((res) => {
        setContraintsTypesAll(res.constrainttypes);
      })
      .catch((err) => console.log(err));
    return function cleanup() {
      abortController.abort();
    };
  }, [projectSelectedId]);

  useEffect(() => {
    getConstraintsAll();
  }, [projectSelectedId]);

  const getConstraintsAll = async () => {
    const allConstraints = [];
    const syncMap = stateProject.allSectors.map(async (el) => {
      const list = await constraintService.showBySector(el.id);
      allConstraints.push(...list.constraints);
    });
    await Promise.all(syncMap);
    setAllConstraintsProject(allConstraints);
  };

  useEffect(() => {
    if (idEdit !== false) {
      updateCat(ContraintsTypesAll[idEdit]);
    }
  }, [idEdit]);

  const updateCat = async (data) => {
    const resp = await constraintTypeService.update(data);
    setIdEdit(false);
  };

  const handleCatsSettings = (index, name, value) => {
    const copyCats = [...ContraintsTypesAll];
    copyCats[index] = {
      ...copyCats[index],
      [name]: value
    };
    setContraintsTypesAll(copyCats);
    setIdEdit(index);
  };

  const deleteConstraintType = (constraintId) => {
    const some = allConstraintsProject.some(
      (el) => el.constraintTypeId === constraintId
    );
    if (some) {
      const alertError = {
        title: t('settings.project_categories.constraints.title'),
        description: t('settings.project_categories.constraints.cannot_delete'),
        type: 'error'
      };
      openNotification(alertError);
      return false;
    }
    constraintTypeService
      .destroy(constraintId)
      .then((res) => {
        if (res === false) {
          const alertError = {
            title: t('settings.project_categories.constraints.title'),
            description: t('settings.project_categories.constraints.noauth'),
            type: 'error'
          };
          openNotification(alertError);
        } else {
          const newConstraintTypes = ContraintsTypesAll.filter(
            (type) => type.id !== constraintId
          );
          setContraintsTypesAll(newConstraintTypes);
          const alertSuccess = {
            title: t('settings.project_categories.constraints.title'),
            description: t('settings.project_categories.constraints.deleted'),
            type: 'success'
          };
          openNotification(alertSuccess);
        }
      })
      .catch((error) => {
        const alertError = {
          title: t('settings.project_categories.constraints.title'),
          description: t('settings.error_default'),
          type: 'error'
        };
        openNotification(alertError);
        console.log(error);
      });
  };

  return (
    <Fragment>
      <div className="position-btn-add-right constraint-right">
        <StyleButton>
          <Button disabled={onlyRead} className="btn-agregar-right" type="text">
            {t('settings.project_categories.constraints.template_add')}
          </Button>
          <Button disabled={onlyRead} className="btn-agregar-right" type="text">
            {t('settings.project_categories.constraints.template_save')}
          </Button>
        </StyleButton>
      </div>
      {/* CARTAS 1 */}
      <div className="scroll-table">
        {ContraintsTypesAll.length ? (
          ContraintsTypesAll.map((value, index) => (
            <Card
              bodyStyle={{ padding: '0px' }}
              className="card-category"
              key={value.id}>
              <div className="grid-cards-category">
                <div className="cat-name">
                  <Editable
                    text={value.name}
                    placeholder="Name"
                    childRef={inputRef}
                    type="input"
                    className="input-table-edit">
                    <input
                      disabled={onlyRead}
                      ref={inputRef}
                      type="text"
                      name="task"
                      className="input-inline"
                      placeholder={t(
                        'settings.project_categories.constraints.name_table'
                      )}
                      value={value.name}
                      onChange={(e) =>
                        handleCatsSettings(index, 'name', e.target.value)
                      }
                      autoComplete="off"
                    />
                  </Editable>
                </div>
                <div className="cat-icon">
                  <img
                    src={renderIconCat(
                      categoriesConstraintType.find(
                        (el) => el.value === value.type
                      ).icon
                    )}
                    alt=""
                  />
                </div>

                <div className="mt-category-txt cat-type">
                  <SelectionCargoStyle>
                    <Select
                      disabled={onlyRead}
                      value={value.type}
                      style={{
                        width: ' 100%',
                        fontSize: '12px'
                      }}
                      name="type"
                      onChange={(value) =>
                        handleCatsSettings(index, 'type', value)
                      }>
                      {categoriesConstraintType.map((cat) => (
                        <Option
                          name="position"
                          key={cat.value}
                          title={cat.label}
                          style={{ fontSize: '12px' }}>
                          {t(`settings.project_categories.${cat.trad}`)}
                        </Option>
                      ))}
                    </Select>
                  </SelectionCargoStyle>
                </div>
                <div className="grid-category-delete cat-actions">
                  <div className="mt-category-txt">
                    <Popconfirm
                      placement={'bottom'}
                      onConfirm={() => deleteConstraintType(value.id)}
                      title={t('are_sure_general')}
                      icon={
                        <Icon
                          type="question-circle-o"
                          style={{ color: 'red' }}
                        />
                      }>
                      {onlyRead ? null : (
                        <a>
                          <img src={deleteIcon} alt="" />
                        </a>
                      )}
                    </Popconfirm>
                  </div>
                </div>
              </div>
            </Card>
          ))
        ) : (
          <div style={{ marginTop: 38 }}>
            <Empty
              style={{ margin: 0 }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span>
                  {t('settings.project_categories.constraints.not_exists')}
                </span>
              }
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default ConstraintsTypes;

const StyleButton = styled.div`
  .ant-btn {
    border-color: transparent;
    visibility: hidden;
  }
`;

const SelectionCargoStyle = styled.div`
  .ant-select-selection {
    border-radius: 5px !important;
    width: 100px;
  }
  .ant-select-selection {
    background-color: white !important;
    color: #5f5f5f;
  }
  .anticon svg {
    color: transparent;
  }

  .ant-select-selection,
  ant-select-selection--single {
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    border-top: 0px;
  }

  .ant-select-selection:focus,
  ant-select-selection--single:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;
