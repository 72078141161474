import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { tagService } from '../../../../../../services/tag.service';
import { useSelector } from 'react-redux';
import { openNotification } from '../../../../../../utils';
import { CompactPicker } from 'react-color';

function Etiquetas(props) {
  /** props */
  const { tagsAll, setTagsAll, onlyRead, t } = props;

  /** redux */
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;
  const colorByDefault = '#121212';

  /** hooks */
  const [colorTag, setColorTag] = useState(colorByDefault);
  const templateTags = {
    name: '',
    description: colorTag,
    projectId: projectSelectedId
  };
  const [tagAdd, setTagAdd] = useState(templateTags);

  const changeTags = (name, value) => {
    setTagAdd({
      ...tagAdd,
      [name]: value
    });
  };

  const saveTags = () => {
    /** validate name */
    if (!tagAdd.name.length) {
      const alertError = {
        title: t('settings.project_categories.tags.title'),
        description: t('settings.project_categories.tags.required'),
        type: 'error'
      };
      openNotification(alertError);
      return false;
    }
    /** validate color */
    if (!tagAdd.name.length) {
      const alertError = {
        title: t('settings.project_categories.tags.title'),
        description: t('settings.project_categories.tags.select_color'),
        type: 'error'
      };
      openNotification(alertError);
      return false;
    }
    tagAdd.description = colorTag;
    tagService
      .create(tagAdd)
      .then((res) => {
        if (res === false) {
          const alertError = {
            title: t('settings.project_categories.tags.title'),
            description: t('settings.error_default'),
            type: 'error'
          };
          openNotification(alertError);
        } else {
          setTagsAll([...tagsAll, res]);
          setTagAdd(templateTags);
          const alertSuccess = {
            title: t('settings.project_categories.tags.title'),
            description: t('settings.project_categories.tags.success'),
            type: 'success'
          };
          openNotification(alertSuccess);
          setColorTag('#121212');
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChangeComplete = (color) => {
    setColorTag(color.hex);
  };

  return (
    <div className="wrapp-categories fix-tags">
      <h1 style={{ color: '#2C3421', marginTop: '10px' }}>
        {t('settings.project_categories.tags.title')}
      </h1>
      <p style={{ fontSize: '12px' }}>
        {t('settings.project_categories.tags.name')}
      </p>
      <Input
        disabled={onlyRead}
        name="name"
        type="text"
        placeholder={t('settings.project_categories.tags.name')}
        autoComplete="off"
        onChange={(e) => changeTags(e.target.name, e.target.value)}
        value={tagAdd.name}
        style={{
          fontSize: '12px',
          border: '1px solid #121212',
          borderRadius: '5px'
        }}
      />

      <div className="grid-icons-category">
        <div>
          <div
            style={{
              fontSize: '12px',
              marginTop: '10px',
              marginBottom: '10px'
            }}>
            {t('settings.project_categories.tags.select')}
          </div>
          <CompactPicker
            className="picker-wrapp"
            width={205}
            color={colorTag}
            onChangeComplete={handleChangeComplete}
          />
        </div>
        <div className="btns-cats-tags">
          <Button
            disabled={onlyRead}
            className="btn-tipo"
            type="text"
            style={{ marginTop: '67px' }}
            onClick={saveTags}>
            {t('settings.project_categories.tags.add')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Etiquetas;
