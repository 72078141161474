import React from 'react';
import moment from 'moment';

const StartCell = ({ date }) => {
  const language = navigator.language || navigator.userLanguage;
  const formatDate = language.includes('en') ? 'MM/DD/YY' : 'DD/MM/YY';

  const dateStr = moment(date).format(formatDate);
  return <div>{dateStr}</div>;
};

export default StartCell;
