import React from 'react';
import Checked from '../../../assets/img/checked-green.svg';

const CheckSystem = ({
  withLabel = false,
  label,
  checked = false,
  disabled = false,
  onClickFunction = () => {}
}) => {
  const handleClick = () => {
    onClickFunction(!checked);
  };

  return (
    <div className={'check-design-system'}>
      <div
        className={`check-design-system__input ${disabled ? 'disabled' : ''}`}
        onClick={() => !disabled && handleClick()}>
        {checked && <img src={Checked} alt="Checked" />}
      </div>
      {withLabel && label && (
        <label
          className={`check-design-system__label ${disabled ? 'disabled' : ''}`}
          onClick={() => !disabled && handleClick()}>
          {label}
        </label>
      )}
    </div>
  );
};

export default CheckSystem;
