import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import modalStyles from '../views/ganttContainer/gantt/modals.module.scss';
import { WarningIcon } from '../icons';
import colors from '../stylesheets/variables.scss';
import cloneDeep from 'lodash/cloneDeep';

export const checkForChanges = (gantt) => {
  const checkFn = (checkFunc) => gantt?.[checkFunc]?.() ?? 0;
  const hasChanges = (current, initial) => current - (initial || 0) > 0;

  const nonUpdatedTasksCounter = checkFn('checkNoUpdatedActivities');
  const nonSavedTasksCounter = checkFn('checkNoSavedActivities');
  const nonSavedDeletedTasksCounter = gantt?.deletedUnsavedActivities.length;

  const nonUpdatedLinksCounter = checkFn('checkNoUpdatedLinks');
  const nonSavedLinksCounter = checkFn('checkNoSavedLinks');
  const nonSavedDeletedLinksCounter = gantt && gantt.deletedUnsavedLinks.length;

  const haveChangesResult = {
    haveChanges:
      hasChanges(
        nonUpdatedTasksCounter.length,
        gantt?.initNonUpdatedTasksCounter
      ) ||
      hasChanges(
        nonSavedTasksCounter.length,
        gantt?.initNonSavedTasksCounter
      ) ||
      hasChanges(
        nonSavedDeletedTasksCounter,
        gantt?.initNonSavedDeletedTasksCounter
      ) ||
      hasChanges(
        nonUpdatedLinksCounter.length,
        gantt?.initNonUpdatedLinksCounter
      ) ||
      hasChanges(
        nonSavedLinksCounter.length,
        gantt?.initNonSavedLinksCounter
      ) ||
      hasChanges(
        nonSavedDeletedLinksCounter,
        gantt?.initNonSavedDeletedLinksCounter
      ),
    nonUpdatedTasksCounter,
    nonSavedTasksCounter,
    nonSavedDeletedTasksCounter
  };
  return haveChangesResult;
};

export default function useUnsavedElementsAlerter(t) {
  const [toReturn, setToReturn] = useState({
    jsx: null,
    callback: () => {}
  });
  const [modalMetadata, setModalMetadata] = useState({
    visible: false,
    onOkPayload: () => {
      console.log('On ok payload');
    },
    onCancelPayload: () => {
      console.log('On Cancel payload');
    },
    title: 'Modal',
    content: <div>Modal</div>,
    cancelText: 'Cancel',
    okText: 'Ok'
  });

  useEffect(() => {
    setToReturn({
      jsx: (
        <Modal
          wrapClassName={`activity-modification-style ${modalStyles['gantt-alert-modal']}`}
          cancelText={modalMetadata.cancelText}
          okText={modalMetadata.okText}
          title=" &nbsp;"
          centered
          width={380}
          visible={modalMetadata.visible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}>
          {modalMetadata.content}
        </Modal>
      ),
      callback: validateModifiedElements
    });
  }, [modalMetadata]);

  const validateModifiedElements = (callbackOnOkayClick = null) => {
    try {
      const gantt = window.to_use_react_gantt;
      const {
        haveChanges,
        nonUpdatedTasksCounter,
        nonSavedTasksCounter,
        nonSavedDeletedTasksCounter
      } = checkForChanges(gantt);
      if (haveChanges) {
        modalMetadata.title = t('modals.gantt.non_saved_activities.title');
        modalMetadata.okText = t('modals.gantt.non_saved_activities.leave');
        modalMetadata.cancelText = t(
          'modals.gantt.non_saved_activities.continue'
        );
        modalMetadata.content = (
          <div>
            <div className="icon-center">
              <WarningIcon color={colors.brandOrange40} />
            </div>
            <div className="body-center">
              {defineModalNonSavedMessage(
                nonSavedTasksCounter.length - gantt.initNonSavedTasksCounter,
                nonSavedDeletedTasksCounter -
                  gantt.initNonSavedDeletedTasksCounter,
                nonUpdatedTasksCounter.length - gantt.initNonUpdatedTasksCounter
              )}
            </div>
            <div className="body-center">
              {t('modals.gantt.non_saved_activities.are_sure_change_gantt')}
            </div>
          </div>
        );
        modalMetadata.visible = true;
        modalMetadata.onOkPayload = () => {
          // updateLocalState(item)
          callbackOnOkayClick && callbackOnOkayClick();
        };
        modalMetadata.onCancelPayload = () => {};
        updateModalRender();
        return true;
      }
    } catch (e) {
      // console.log(e)
    }
  };

  const defineModalNonSavedMessage = (
    createdTasks,
    deletedTasks,
    nonUpdatedTask,
    fixed = true
  ) => {
    let msg = '';

    if (createdTasks) {
      msg +=
        t('modals.gantt.non_saved_activities.are') +
        createdTasks +
        ' ' +
        (createdTasks > 1
          ? t('modals.gantt.non_saved_activities.activities')
          : t('modals.gantt.non_saved_activities.activity')) +
        t('non_saved_without_change_gantt');
    }

    if (deletedTasks) {
      msg +=
        t('modals.gantt.non_saved_activities.are') +
        deletedTasks +
        ' ' +
        (deletedTasks > 1
          ? t('modals.gantt.non_saved_activities.activities')
          : t('modals.gantt.non_saved_activities.activity')) +
        (deletedTasks > 1
          ? t('modals.gantt.non_saved_activities.deleted_plural')
          : t('modals.gantt.non_saved_activities.deleted')) +
        t('non_saved_change_gantt');
    }

    if (nonUpdatedTask) {
      msg +=
        t('modals.gantt.non_saved_activities.are') +
        nonUpdatedTask +
        ' ' +
        (nonUpdatedTask > 1
          ? t('modals.gantt.non_saved_activities.activities')
          : t('modals.gantt.non_saved_activities.activity')) +
        t('non_updated_change_gantt');
    }

    if (fixed) {
      msg = t('modals.gantt.non_saved_activities.fixed');
    }

    return msg;
  };

  function handleModalOk(e) {
    modalMetadata.visible = false;
    if (modalMetadata.onOkPayload) {
      modalMetadata.onOkPayload();
    }
    updateModalRender();
  }

  function handleModalCancel(e) {
    modalMetadata.visible = false;
    if (modalMetadata.onCancelPayload) {
      modalMetadata.onCancelPayload();
    }
    updateModalRender();
  }

  function updateModalRender() {
    setModalMetadata((prev) => {
      prev = cloneDeep(modalMetadata);
      return prev;
    });
  }

  return toReturn;
}
