import featureEnabledFlagsMap from '../App.featureflags';
/**
 * A very simple function that checks whether a given feature is ON (for now, features can be enabled by supplying specific params to the URL).
 * This function should be used for a limited time and must be removed as soon as we implement actual feature flags with a service like Optimizely.
 */
export function isFeatureOn(featureName) {
  if (featureEnabledFlagsMap[featureName]) return true;

  const params = new URLSearchParams(window.location.search);
  return params.get(featureName) === '1';
}
