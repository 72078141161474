/**
 * This function manipulates the DOM for inline start and constraint date
 * It allow put an label over original value label, and used formatted ProPlanner dates
 * @param {*} gantt DHTMLX gantt lib instance
 * @param {*} state State comming from inline editing events
 * @param {*} dom Object with container input div, and input dom element indeed {inputDom, divParent}
 */
export const customDateFormattedEditor = (gantt, state, dom) => {
  if (
    state.columnName == 'start_date' ||
    state.columnName == 'constraint_date'
  ) {
    const task = gantt.getTask(state.id);

    /** Pure JS: we create a div and assign it our new CSS class for label over date */
    const labelForDate = document.createElement('div');
    labelForDate.className = 'custom-date-for-inline-gantt';

    /** Pure JS: Then we create a text with the formatted date */
    const dateFormatText = document.createTextNode(
      gantt.date.date_to_str(gantt.hashMoment2Date[gantt.currentDateFormat])(
        task[state.columnName]
      )
    );
    labelForDate.appendChild(dateFormatText);
    dom.divParent.appendChild(labelForDate);

    /** Pure JS: We listen to input change  */
    dom.inputDom.addEventListener('change', (e) => {
      const dateObject = gantt.date.str_to_date('%Y/%m/%d')(e.target.value);
      const dateFormatText = document.createTextNode(
        gantt.date.date_to_str(gantt.hashMoment2Date[gantt.currentDateFormat])(
          dateObject
        )
      );
      labelForDate.innerHTML = '';
      labelForDate.appendChild(dateFormatText);
    });
  }
};

/** Editor for name */
export const textEditor = {
  type: 'text',
  map_to: 'text'
};

/** Editor for Description */
export const descriptionEditor = {
  type: 'text',
  map_to: 'description'
};

/** Editor for start date */
export const dateStartEditor = {
  type: 'date',
  map_to: 'start_date'
};

/** Editor for start date */
export const dateEndEditor = {
  type: 'date',
  map_to: 'end_date'
};

/** Editor 4 duration with days formatter */
export const durationEditor = (gantt) => ({
  type: 'duration',
  map_to: 'for_disable_milestone_duration',
  min: 0,
  max: 100,
  formatter: gantt.formatter,
  id: 'editorInputInline'
});

/** Editor 4 hh */
export const hhEditor = {
  type: 'number',
  map_to: 'hhWorkTime'
};

/** Editor 4 hh */
export const costEditor = {
  type: 'number',
  map_to: 'cost'
};

export const usedCostEditor = {
  type: 'number',
  map_to: 'used_cost'
};

/** Progress editor */
export const progressEditor = {
  type: 'number',
  map_to: 'progress',
  min: 0,
  max: 100
};

/** Constraint date editor */
export const constraintDateEditor = {
  type: 'date',
  map_to: 'constraint_date'
};

/** Ponderators number editor */
export const ponderatorNumberEditor = {
  type: 'number',
  map_to: 'ponderator',
  min: 0,
  max: 1
};

/** Priority editor with options from server gnat list */
export const priorityEditor = {
  type: 'select',
  map_to: 'priority',
  options: [
    { key: '1', label: 'Low' },
    { key: '2', label: 'Normal' },
    { key: '3', label: 'High' }
  ]
};

/** Constraint type editor */
export const constraintTypeEditor = (gantt) => ({
  type: 'select',
  map_to: 'constraint_type',
  options: [
    { key: 'asap', label: gantt.locale.labels.asap },
    { key: 'alap', label: gantt.locale.labels.alap },
    { key: 'snet', label: gantt.locale.labels.snet },
    { key: 'snlt', label: gantt.locale.labels.snlt },
    { key: 'fnet', label: gantt.locale.labels.fnet },
    { key: 'fnlt', label: gantt.locale.labels.fnlt },
    { key: 'mso', label: gantt.locale.labels.mso },
    { key: 'mfo', label: gantt.locale.labels.mfo }
  ]
});
