import React, { useCallback, useEffect } from 'react';

const SSOProcoreWebview = () => {
  const envConfigs = {
    sandbox: {
      urlIncludes: ['localhost', 'qa', 'dev', 'mobile'],
      agaveConfig: {
        sourceSystemEnvironment: 'sandbox',
        showProductionSourceSystems: false,
        showSandboxSourceSystems: true
      }
    },
    prod: {
      urlIncludes: [],
      agaveConfig: {
        sourceSystemEnvironment: 'prod',
        showProductionSourceSystems: true,
        showSandboxSourceSystems: false
      }
    }
  };

  const getAgaveConfig = useCallback(() => {
    const { agaveConfig } =
      Object.values(envConfigs).find(({ urlIncludes }) =>
        urlIncludes.some((urlPart) => window.location.href.includes(urlPart))
      ) || envConfigs.prod;

    return agaveConfig;
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.agaveapi.com/init.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const getAgaveLinkToken = () => {
    if (window && window.AgaveLink) {
      window.AgaveLink.openLink({
        linkToken: window.linkToken,
        sourceSystem: 'procore',
        ...getAgaveConfig(),
        sourceSystemOptions: {
          procore: { require_cross_company_access: false }
        },
        onSuccess: (publicToken) => {
          window.webkit.messageHandlers.successCallback.postMessage(
            publicToken
          );
        },
        onExit: (err) =>
          window.webkit.messageHandlers.errorCallback.postMessage(err)
      });
    } else {
      window.webkit.messageHandlers.errorCallback.postMessage(null);
    }
  };

  window.getAgaveLinkToken = getAgaveLinkToken;

  return <div className="sso-procore-webview" />;
};
export default SSOProcoreWebview;
