import React from 'react';
import ReactDOM from 'react-dom';
import { general_layout } from '../../../assets/js/gantt_layout/general-layout';
import { init_scroll_zoom_config } from '../../../assets/js/gantt_layout/scroll-zooming-conf';
import moment from 'moment';
import LazilyRender from 'react-lazily-render';

const $ = window.$;
/**
 * This function set basic option for DHTMLX gantt plugin.
 * As plugins, formatters, date format, autoscheduling and others
 * @param {*} gantt Gantt dhtmlx instance object
 * @param {*} sector Current sector running timeline view
 * @param {*} userPreferenceTableGantt Table layout comming from planification view
 * @param {*} general_layout Layout object configuration for Grid, resize and timeline elements
 * @param {*} subContracts Subs comming from planification view or any state that saves it to keep updated at gantt
 * @param {*} setVisibleFormSubcontract Setter function to update through the gantt API to React API subs data
 * @param {*} tableConfig table layout configuration comming from view
 */
export const buildOptions = (
  gantt,
  sector,
  userPreferenceTableGantt,
  subContracts,
  setVisibleFormSubcontract,
  tableConfig,
  t,
  copyPasteState
) => {
  gantt.currentSector = sector;

  gantt.dateFormatOptions = [
    'DD/MM/YY',
    'MM/DD/YY',
    'DD/MM/YYYY',
    'MM/DD/YYYY',
    'DD/MM/YY hh:mm',
    'MM/DD/YY hh:mm'
  ];
  gantt.hashMoment2Date = {
    'DD/MM/YY': '%d/%m/%y',
    'MM/DD/YY': '%m/%d/%y',
    'DD/MM/YYYY': '%d/%m/%Y',
    'MM/DD/YYYY': '%m/%d/%Y',
    'DD/MM/YY hh:mm': '%d/%m/%y %H:%i',
    'MM/DD/YY hh:mm': '%m/%d/%y %H:%i'
  };
  gantt.currentDateFormat =
    sector.dateFormat || (t('lang') === 'en' ? 'MM/DD/YY' : 'DD/MM/YY');
  gantt.stackDefaults = {
    ALL_TASKS: 'ALL_TASKS'
  };

  gantt.config.editor_types.date = {
    show: function (id, column, config, placeholder) {
      gantt.initDateFunctions && gantt.initDateFunctions();
      let minValue = null;
      let maxValue = null;

      if (typeof config.min === 'function') {
        minValue = config.min(id, column);
      } else {
        minValue = config.min;
      }

      if (typeof config.max === 'function') {
        maxValue = config.max(id, column);
      } else {
        maxValue = config.max;
      }

      const minAttr = minValue
        ? " min='" + gantt.dateToStr(minValue) + "' "
        : '';
      const maxAttr = maxValue
        ? " max='" + gantt.dateToStr(maxValue) + "' "
        : '';
      const html =
        "<div><input type='text' " +
        minAttr +
        maxAttr +
        " name='" +
        column.name +
        "' class='schedule-datepicker'></div>";
      placeholder.innerHTML = html;
      $('.schedule-datepicker').datepicker({
        format: {
          toDisplay: function (date, format, language) {
            if (
              !(
                gantt.currentDateFormat.includes('h') &&
                gantt.currentDateFormat.includes('m')
              )
            ) {
              date.setMinutes(60);
              date.setHours(23);
            }
            return gantt.dateToStr(date);
          },
          toValue: function (date, format, language) {
            const d = new Date(moment(date, gantt.currentDateFormat).toDate());
            return d;
          }
        },
        autoclose: true,
        todayBtn: 'linked',
        todayHighlight: true,
        weekStart: 1,
        keyboardNavigation: false
      });
    },
    hide: function () {
      $('.schedule-datepicker').datepicker('destroy');
    },
    set_value: function (value, id, column, node) {
      if (value && value.getFullYear) {
        node.querySelector('input').value = gantt.dateToStr(value);
      } else {
        node.querySelector('input').value = value;
      }
      $('.schedule-datepicker').datepicker('update');
      $('.schedule-datepicker').data('datepicker').show();
    },
    is_valid: function (value, id, column, node) {
      if (!value || isNaN(value.getTime())) {
        return false;
      }
      return true;
    },
    get_value: function (id, column, node) {
      let parsed;
      try {
        parsed = gantt.strToDate(node.querySelector('input').value || '');
      } catch (e) {
        parsed = null; // return null will cancel changes
      }

      return parsed;
    },
    is_changed: function (value, id, column, node) {
      const new_value = gantt.strToDate(node.querySelector('input').value);
      if (Number(new_value) != Number(value)) return true;
    },
    focus: function (node) {
      console.log('focus');
    }
  };

  gantt.plugins({
    drag_timeline: true,
    click_drag: true,
    auto_scheduling: true,
    marker: true,
    multiselect: true,
    keyboard_navigation: true
  });
  // gantt.addShortcut('shift + w', (e) => {
  //     gantt.changeWeek && gantt.changeWeek()
  // }, 'gantt')

  /** Configuration for multi task on drag selecting */
  gantt.config.click_drag = {
    callback: gantt.onDragCursorMultiTask,
    useKey: 'shiftKey'
  };
  gantt.visualizationColorActive = 'subcontract';

  gantt.config.multiselect = true;
  gantt.config.multiselect_one_level = false;

  /** Format to sector configuration */
  gantt.formatter = gantt.ext.formatters.durationFormatter({
    enter: 'day',
    store: 'hour',
    format: 'day',
    hoursPerDay: sector.hoursPerDay,
    hoursPerWeek: sector.hoursPerWeek,
    short: false
  });

  /** Format to handle dates */
  gantt.config.date_format = '%Y-%m-%d %H:%i';

  /** Allows drag n drop for cols header */
  gantt.config.reorder_grid_columns = true;

  /** Calculates duration in working hours and hides non-working time from the chart */
  gantt.config.work_time = true;
  gantt.config.correct_work_time = true;
  gantt.config.drag_links = false;
  /** Autoschedule turned on */
  gantt.config.auto_scheduling = true;
  gantt.config.auto_scheduling_initial = true;

  /** Allows freely drag n drop tasks */
  gantt.config.round_dnd_dates = false;
  gantt.config.time_step = 60; //* 24
  gantt.config.duration_unit = 'hour';
  gantt.config.disable_focus_on_create = true;
  if (userPreferenceTableGantt.ganttChart) {
    general_layout.cols[0].width = userPreferenceTableGantt.ganttChart;
  }

  /** Layout configuration */
  gantt.config.layout = general_layout;

  /** Memory leak 2 */
  /** DONT TOUCH THIS OPTION WITHOUT ASKING TO A TEACH LEAD OR SR DEV */
  gantt.config.external_render = {
    // checks the element is a React element
    isElement: (element) => React.isValidElement(element),
    // renders the React element into the DOM
    renderElement: (element, container, column, item) => {
      if (gantt.optimizeReactRender) {
        setTimeout(() => {
          ReactDOM.render(element, container);
        }, 350);
      } else {
        ReactDOM.render(element, container);
      }
    }
  };

  /** Task height */
  gantt.config.task_height = 20;
  gantt.templates.link_class = function (link) {
    return 'top-fix-style';
  };

  /** Height of the header labels of gantt */
  gantt.config.scale_height = 28;

  /** Height of table columns height */
  gantt.config.row_height = 34;

  /** Min width for grid table  */
  gantt.config.min_grid_column_width = 100;

  /** This prop allows to navigate through mouse at the gantt */
  gantt.config.drag_timeline = {
    ignore: '.gantt_task_line, .gantt_task_link',
    useKey: 'ctrlKey'
  };

  /** Configuration execution for mouse scrolling zoom */
  gantt.ext.zoom.init(init_scroll_zoom_config(gantt));
  gantt.config.autofit = true;
  /** Opens lightbox on creating a task */
  gantt.config.details_on_create = false;

  /** Optimization on the rendering */
  gantt.config.smart_rendering = true;
  gantt.config.static_background = false;
  gantt.config.show_task_cells = true;
  gantt.config.show_progress = true;
  // gantt.config.readonly = true;
  gantt.config.smart_scales = true;
  gantt.config.open_tree_initially = true;
  gantt.config.keyboard_navigation_cells = true;
  gantt.config.order_branch = true;
  gantt.config.order_branch_free = false;

  /** Disable the row to move progress at gantt chart */
  gantt.config.drag_progress = false;

  gantt.config.show_links = true;

  /** remove column id */
  gantt.config.columns = tableConfig.filter(
    (el) => el.name !== 'activity_ganttid'
  );

  /** Disable gantt chart scroll when click an activity or task */
  gantt.config.scroll_on_click = false;

  window.to_use_react_gantt_hard = gantt;
  gantt.subContracts = subContracts;
  gantt.setVisibleFormSubcontract = setVisibleFormSubcontract;
  // Redux integration
  const tasksToCopy = copyPasteState?.lookahead?.selectedTasks; // localStorage.getItem('tasksToCopy')
  if (tasksToCopy) {
    gantt.tasksToCopy = tasksToCopy;
  }
};
