export const SET_CONSTRAINT_FORM = 'SET_CONSTRAINT_FORM';
export const SET_CONSTRAINT_RESPONSABLES = 'SET_CONSTRAINT_RESPONSABLES';
export const SET_CONSTRAINT_LOOKAHEAD = 'SET_CONSTRAINT_LOOKAHEAD';
export const SET_CONSTRAINT_ACTIVATED_COLS = 'SET_CONSTRAINT_ACTIVATED_COLS';
export const SET_COLUMNS_SIZES = 'SET_COLUMNS_SIZES';
export const NOTIFY_LOOKAHEAD_UPDATE_CONSTRAINT =
  'NOTIFY_LOOKAHEAD_UPDATE_CONSTRAINT';
export const NOTIFY_LOOKAHEAD_UPDATE_ORDER_CONSTRAINT =
  'NOTIFY_LOOKAHEAD_UPDATE_ORDER_CONSTRAINT';
export const NOTIFY_LOOKAHEAD_UPDATE_FILTER_CONSTRAINT =
  'NOTIFY_LOOKAHEAD_UPDATE_FILTER_CONSTRAINT';
export const NOTIFY_LOOKAHEAD_UPDATE_GROUP_CONSTRAINT =
  'NOTIFY_LOOKAHEAD_UPDATE_GROUP_CONSTRAINT';

export const constraintActions = {
  setConstraintForm: (constraintForm) => ({
    type: SET_CONSTRAINT_FORM,
    payload: constraintForm
  }),
  setConstraintLookAhead: (constraintLookAhead) => ({
    type: SET_CONSTRAINT_LOOKAHEAD,
    payload: constraintLookAhead
  }),
  setConstraintResponsables: (constraintResponsables) => ({
    type: SET_CONSTRAINT_RESPONSABLES,
    payload: constraintResponsables
  }),
  setActivatedColumns: (activatedColumns) => ({
    type: SET_CONSTRAINT_ACTIVATED_COLS,
    payload: activatedColumns
  }),
  setColumnsSizes: (columnSizes) => ({
    type: SET_COLUMNS_SIZES,
    payload: columnSizes
  }),
  notifyLookaheadUpdate: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_CONSTRAINT,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateOrder: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_ORDER_CONSTRAINT,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateFilter: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_FILTER_CONSTRAINT,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateGroup: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_GROUP_CONSTRAINT,
    payload: { boolean: true }
  })
};
