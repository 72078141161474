import React from 'react';

/** import ReactComponents */
import { ReactComponent as MasterPlanIcon } from '../assets/img/modules/schedule.svg';
import { ReactComponent as LookaheadIcon } from '../assets/img/modules/new_lookahead.svg';
import { ReactComponent as WeeklyPlanIcon } from '../assets/img/modules/new_weeklyplan.svg';
import { ReactComponent as TaktPlanningIcon } from '../assets/img/modules/new_taktplanning.svg';
import { ReactComponent as ProductivityIcon } from '../assets/img/modules/productivity.svg';
import { ReactComponent as BudgetIcon } from '../assets/img/modules/budget.svg';
import { ReactComponent as DocumentIcon } from '../assets/img/modules/documents.svg';
import { ReactComponent as BimIcon } from '../assets/img/modules/bim-new.svg';
import { ReactComponent as AnalyticsIcon } from '../assets/img/modules/new_analytics.svg';
import { ReactComponent as ReportsIcon } from '../assets/img/modules/reports.svg';
import { ReactComponent as CcloudIcon } from '../assets/img/modules/logo_calidad_round.svg';
import { ReactComponent as TaktAnalyticsIcon } from '../assets/img/modules/takt_analytics.svg';

const iconTypes = {
  masterplan: MasterPlanIcon,
  lookahead: LookaheadIcon,
  weeklyplan: WeeklyPlanIcon,
  taktplanning: TaktPlanningIcon,
  productivity: ProductivityIcon,
  budget: BudgetIcon,
  documents: DocumentIcon,
  bim: BimIcon,
  analytics: AnalyticsIcon,
  reports: ReportsIcon,
  cloud: CcloudIcon,
  extraDashboard: TaktAnalyticsIcon
};

const IconComponent = ({ name, ...props }) => {
  const Icon = iconTypes[name];

  return (
    <Icon width={18} height={18}>
      <Icon name={iconTypes} />
    </Icon>
  );
};

export default IconComponent;
