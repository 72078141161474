import React, { useState, useEffect } from 'react';
import { Button, Icon } from 'antd';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import './MainRouteChild.css';

/**
 *
 * @param {*} props {  }
 */
export default function MainRouteChild(props) {
  const { height, width } = useWindowDimensions();
  const [buttons, setButtons] = useState(props.menu.buttons);
  const [selected, setSelected] = useState(false);
  const [btnSyle, setBtnStyle] = useState('common-btn-style');
  const handleClick = (e, btn, shouldGo = true) => {
    setSelected(true);
    setBtnStyle('common-btn-style common-disabled-btn-style');
    setButtons(
      buttons.map((btn) => {
        delete btn.className;
        return btn;
      })
    );
    btn.className = 'common-btn-style';
    if (shouldGo) btn.onClick();
  };

  useEffect(() => {
    const url = window.location.href;
    setButtons(
      buttons.map((btn) => {
        if (url.includes(btn.routeName)) {
          handleClick(null, btn, false);
        }
        return btn;
      })
    );
  }, []);

  return (
    <div>
      <div
        style={{ height: '20px', backgroundColor: 'white' }}
        onClick={() => {
          props.menu.goBack();
        }}>
        <Icon type="arrow-left" className="back-btn-navigator" />
      </div>
      <div style={{ alignContent: 'center', backgroundColor: 'white' }}>
        <div className="center-container childed-title-style">
          {props.menu.title}
        </div>
        <div className="center-container sub-title">{props.menu.subTitle}</div>
        <div className="center-container">
          {buttons.map((btn, index) => (
            <Button
              key={index}
              className={btn.className ? btn.className : btnSyle}
              type="primary"
              onClick={(e) => handleClick(e, btn)}>
              {btn.btnTitle}
            </Button>
          ))}
        </div>
        <div className="center-container">
          <hr className="bottom-separator" />
        </div>
      </div>
    </div>
  );
}
