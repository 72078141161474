import React, { useState, useEffect, useRef, useForceUpdate } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import filterIcon from '../../../../assets/img/takt/filter.png';
import moment from 'moment';
import {
  Row,
  Col,
  Modal,
  Checkbox,
  Button,
  Collapse,
  Radio,
  Dropdown
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { sectorResourcesService } from '../../../../services/sectorresource.service';
import { analyticServiceResourse } from '../../../../services/analytic_resource.service';
import './index.css';
import cloneDeep from 'lodash/cloneDeep';
import configurationIcon from '../../../../assets/img/takt/config.png';
/** Redux */
import { useDispatch } from 'react-redux';
import { curveResourceActions } from '../../../../redux/actions/curveResourceActions';
import { dynamicSort } from '../../../../utils';

import IconComponent from '../../../../components/Projects/IconSvg';
import { export_file } from '../../../../utils';

function ResourceCurve(props) {
  const t = props.t;
  const [Options, setOptions] = useState({});
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [firstLoadView, setFirstLoadView] = useState(true);
  const [resources, setResources] = useState([]);
  const [selectedResource, setSelectedResource] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const [curveData, setCurveData] = useState([]);
  const [holderChart, setHolderChart] = useState(true);
  const [seriePercetaje, setSeriePercetaje] = useState([]);
  const [serieMterial, setSerieMterial] = useState([]);
  const [seriesSwitch, setSeriesSwitch] = useState(false);
  const [groupVisibility, setGroupVisibility] = useState(false);
  const [loading_filter, setLoading_filter] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const dispatch = useDispatch();
  /** Project state from redux */
  const projectState = useSelector((state) => state.projectState);
  const [value, setValue] = useState({
    value: 0
  });
  const [userId, setUserId] = useState();

  const { Panel } = Collapse;

  const curveResourceState = useSelector((state) => state.curveResourceState);
  const allSectorsIds = projectState.allSectors.map((sector) => sector.id);
  const user_id = projectState.allProjects.find(
    (pr) => pr.id === projectState.projectSelected
  ).userproject.userId;

  useEffect(() => {
    if (firstLoad) {
      const projects = curveResourceState.projects;
      let project;
      if (projects) {
        project = projects.find(
          (p) => p.projecti_id == projectState.projectSelected
        );
      }
      if (project) {
        setSelectedSector(project.sectors);
        setSelectedResource(project.resources);
      } else {
        setFirstLoadView(false);
        const arrayResources = [];
        allSectorsIds.map(async (sector) => {
          const response = await sectorResourcesService.searchBySector(sector);
          if (response.sectorresource.length > 0) {
            arrayResources.push(response);
          }
        });
        setResources(arrayResources);
        setFirstLoad(false);
      }
    }
  }, []);
  useEffect(() => {
    if (firstLoad) {
      getResources();
    }
  }, [selectedResource]);

  const getResources = async () => {
    // await sectorResourcesService.searchBySector(sector)
    if (selectedResource.length > 0) {
      const response = await analyticServiceResourse.resource_curve({
        sector_id: selectedSector,
        date_of_filter: [],
        resource_id: selectedResource
      });
      setFirstLoadView(false);
      setCurveData(response);
      setConfirmLoading(true);
      setTimeout(() => {
        setVisible(false);
        setConfirmLoading(false);
      }, 500);

      const arrayResources = [];
      allSectorsIds.map(async (sector) => {
        const response = await sectorResourcesService.searchBySector(sector);
        if (response.sectorresource.length > 0) {
          arrayResources.push(response);
        }
      });

      setResources(arrayResources);
      setFirstLoad(false);
    }
  };

  /** Flag to know when component is on loading step */
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (value.value == 0) {
      Options.series = serieMterial;
    } else {
      Options.series = seriePercetaje;
    }
    setOptions(Options);
    setLoading_filter(true);
  }, [value]);

  useEffect(() => {
    getData();
  }, [curveData]);

  const getData = async () => {
    const response = curveData;
    if (response && response.options) {
      // let week = moment().week();

      const s_porsentaje = cloneDeep([...response.series]);
      const s_material = cloneDeep([...response.series]);

      s_porsentaje.map((s) => {
        let i = 0;
        s.data.map((data) => {
          if (s.name == 'real') {
            s.data[i] = (
              (((data / 100) * response.total_task_quantity) /
                response.total_activity_quantity) *
              100
            ).toFixed(2);
          } else {
            s.data[i] = data.toFixed(2);
          }
          i++;
        });
      });

      s_material.map((s) => {
        let i = 0;
        s.data.map((data) => {
          if (s.name == 'base' || s.name == 'followUp') {
            s.data[i] = (
              (data / 100) *
              response.total_activity_quantity
            ).toFixed(2);
          } else {
            s.data[i] = ((data / 100) * response.total_task_quantity).toFixed(
              2
            );
          }
          i++;
        });
      });

      setSeriePercetaje(s_porsentaje);
      setSerieMterial(s_material);

      const res = response;
      if (value.value == 0) {
        res.series = s_material;
      } else {
        res.series = s_porsentaje;
      }

      res.series = res.series.sort(
        (a, b) =>
          // ASC  -> a.length - b.length
          // DESC -> b.length - a.length
          b?.data?.length - a?.data?.length
      );

      const dynamic_color = [];
      res.series.map((serie) => {
        if (serie.name === 'followUp') {
          dynamic_color.push('#F59D04');
        }
        if (serie.name === 'lookahead') {
          dynamic_color.push('#606060');
        }
        if (serie.name === 'base') {
          dynamic_color.push('#2C3421');
        }
        if (serie.name === 'real') {
          dynamic_color.push('#34AF00');
        }
      });
      const actual = moment().endOf('isoweek').format('YYYY-MM-DD');
      const array_week = res.options.xaxis.full_date;
      const actual_position = array_week.indexOf(actual);
      const found_base = res.series[0].data.findIndex(
        (element) => element == 100
      );
      res.options.dataLabels = { enabled: false };
      res.options.tooltip = {
        shared: true
      };
      res.options.chart = { type: 'area', height: 500 };
      res.options.stroke = { show: true, width: 1.9 };
      res.options.colors = dynamic_color;
      res.options.toolbar = {
        show: true,
        offsetX: 0,
        offsetY: 0,
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category2',
            headerValue: 'value'
          }
        }
      };
      // res.options.colors = ['#4d3a96', '#4576b5']
      res.options.export = {
        show: true,
        offsetX: 0,
        offsetY: 0,
        csv: {
          filename: 'hernan',
          columnDelimiter: ',',
          headerCategory: 'category1',
          headerValue: 'value'
        }
      };
      res.options.markers = {
        size: 0,
        colors: undefined,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: 'circle',
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: undefined,
          sizeOffset: 3
        }
      };
      setOptions(res);
      setLoading(false);
      setLoading_filter(true);
      if (array_week.length > 0) {
        setHolderChart(false);
      }
    }
  };

  const handleOk = async () => {
    const response = await analyticServiceResourse.resource_curve({
      sector_id: selectedSector,
      date_of_filter: [],
      resource_id: selectedResource
    });
    setCurveData(response);
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 500);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const renderCollapse = () => {
    const sectorResource = generateArraySectorResources;
    if (resources[0]) {
      if (resources[0].sectorresource.length > 0) {
        const panels = sectorResource
          .sort(dynamicSort('sector_name'))
          .map((sr) => (
            <Panel header={sr.sector_name} key={sr.sector_id}>
              {sr.resources.map((r) => (
                <Row>
                  <Checkbox
                    defaultChecked={selectedResource.includes(r.value)}
                    value={[sr.sector_id, r.value]}
                    onChange={onChecked}>
                    {r.label}
                  </Checkbox>
                </Row>
              ))}
            </Panel>
          ));
        return panels;
      }
    }
    return null;
  };

  const generateArraySectorResources = resources.map((resource) => {
    let sectorResource = [];
    const sectorAndResource = [];
    if (resource.sectorresource.length > 0) {
      const sector = projectState.allSectors.filter(
        (sector) => sector.id == resource.sectorresource[0].sectorId
      )[0];
      // {label: 'asd', value: 1}
      const recursos = resource.sectorresource.map((recurso) => ({
        label: recurso.name,
        value: recurso.id
      }));
      const sectorAndResource = [];
      sectorResource = {
        sector_name: sector.name,
        sector_id: sector.id,
        resources: recursos
      };
    }
    return sectorResource;
  });

  const onChecked = (checkedValues) => {
    const selected_resource = selectedResource;
    const selected_sector = selectedSector;
    if (checkedValues.target.checked) {
      selected_resource.push(checkedValues.target.value[1]);
      selected_sector.push(checkedValues.target.value[0]);
    } else {
      const indexResource = selected_resource.indexOf(
        checkedValues.target.value[1]
      );
      const indexSector = selected_sector.indexOf(
        checkedValues.target.value[0]
      );
      if (indexResource > -1) {
        selected_resource.splice(indexResource, 1);
      }
      if (indexSector > -1) {
        selected_sector.splice(indexSector, 1);
      }
    }

    setSelectedSector(selected_sector);
    setSelectedResource(selected_resource);
    let projects = curveResourceState.projects;
    if (projects) {
      const project = projects.find(
        (p) => p.projecti_id == projectState.projectSelected
      );
      if (project) {
        project.sectors = selected_sector;
        project.resources = selected_resource;
      } else {
        projects.push({
          sectors: selected_sector,
          resources: selected_resource,
          projecti_id: projectState.projectSelected
        });
      }
    } else {
      projects = [];
      projects.push({
        sectors: selected_sector,
        resources: selected_resource,
        projecti_id: projectState.projectSelected
      });
    }

    dispatch(curveResourceActions.setProjects(projects));
  };
  const renderModalFilter = () => {
    return (
      <Modal
        title={t('resource_curve_model')}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}>
        <Collapse>{renderCollapse()}</Collapse>
      </Modal>
    );
    return null;
  };

  const renderInfoReport = () => {
    if (showInfoModal) {
      Modal.info({
        title: '',
        afterClose: setShowInfoModal(false),
        content: (
          <div>
            <p>{t('notification_message')}</p>
          </div>
        ),
        onOk() {}
      });
    }
  };

  const renderChart = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Chart
        options={Options.options}
        series={Options.series}
        type="area"
        width="100%"
        height="100%"
      />
    );
  };

  const onChangeRadios = (e) => {
    setValue({
      value: e.target.value
    });
    setLoading_filter(false);
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  };

  const handleGroupDropdown = () => {
    setGroupVisibility(!groupVisibility);
  };

  const resourcesReport = async () => {
    setShowInfoModal(true);
    const response = await analyticServiceResourse.resource_report({
      sector_id: allSectorsIds,
      user_id: user_id
    });
  };

  return (
    <div style={{ height: '100%' }}>
      <div
        className="content_place_holder"
        style={{ visibility: holderChart ? 'visible' : 'hidden' }}>
        {firstLoadView ? (
          <h2 className="content_place_holder_text">Loading</h2>
        ) : (
          <h2 className="content_place_holder_text">
            {t('resource_cuve_text_place_holder')}{' '}
            <img src={filterIcon} width={15} />
          </h2>
        )}
      </div>
      <Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 17,
            paddingBottom: 0
          }}>
          <span style={{ color: '#121212', fontSize: 20 }}>
            {t('resource_curve')}
          </span>

          <span
            style={{ position: 'relative', top: -2 }}
            className="matrix-activities-filter">
            <Dropdown
              overlay={
                <Radio.Group
                  onChange={onChangeRadios}
                  value={value.value}
                  defaultValue={value.value}
                  style={{
                    backgroundColor: 'white',
                    padding: '5px'
                  }}>
                  <Radio style={radioStyle} value={0}>
                    Material
                  </Radio>
                  <Radio style={radioStyle} value={1}>
                    %
                  </Radio>
                </Radio.Group>
              }
              trigger={['click']}
              visible={groupVisibility}
              onVisibleChange={handleGroupDropdown}>
              <div style={{ cursor: 'pointer', fontSize: '12px' }}>
                <img
                  src={configurationIcon}
                  width={15}
                  style={{ cursor: 'pointer', marginRight: 10 }}
                />
              </div>
            </Dropdown>
            <div
              style={{ cursor: 'pointer', marginRight: 10 }}
              onClick={() => {
                setVisible(true);
              }}>
              <div>
                <img src={filterIcon} width={15} />
              </div>
            </div>
            <div
              style={{
                cursor: 'pointer',
                fontSize: '12px',
                marginLeft: '-10px',
                top: '3px',
                position: 'relative'
              }}
              onClick={resourcesReport}>
              <IconComponent
                data={export_file}
                width={15}
                fill="#53C255"
                className="svg-icon-export-file"
              />
            </div>
          </span>
        </div>
      </Row>
      <Row style={{ height: '80%' }}>
        {loading_filter ? renderChart() : <div></div>}

        {renderModalFilter()}
        {renderInfoReport()}
      </Row>
    </div>
  );
}

export default ResourceCurve;
