function executeFixForConstraints(task) {
  /**
   * This function fix constraint wrong behaviour from dhtmlx lib
   * @param {*} task Task object to be corrected
   */

  if (task.start_date === task.last_start_date) {
    task.constraint_type = task.last_constraint;
    task.constraint_date = task.last_constraint_date;
  }
}

export { executeFixForConstraints };
