/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class AttachmentsService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(activityId) {
    const res = await this.http
      .get('attachments/' + activityId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showBySector(sectorId) {
    const res = await this.http
      .get(`sectors/${sectorId}/documents`)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(activityId) {
    const res = await this.http
      .delete('attachments/' + activityId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(activity) {
    const res = await this.http.post('attachments', activity).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(activity) {
    const res = await this.http
      .put('attachments/' + activity.id, activity)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}
export const attachmentsService = new AttachmentsService();
