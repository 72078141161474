import React, { useState } from 'react';

export const ViewsCtx = React.createContext({ viewCtx: [] });

const ViewsProvider = ({ children }) => {
  const viewCtx = useState(null);
  const ganttObject = useState(null);

  return (
    <ViewsCtx.Provider value={{ viewCtx, ganttObject }}>
      {children}
    </ViewsCtx.Provider>
  );
};

export default ViewsProvider;
