import React from 'react';
import Lottie from 'lottie-react';
import bars from './bars.json';
import styles from './style.module.scss';

function BaseLoader() {
  return (
    <div id={styles.baseloader} className={styles.baseloader}>
      <Lottie
        animationData={bars}
        loop={true}
        style={{ width: 400, height: 400 }}
      />
    </div>
  );
}

export default BaseLoader;
