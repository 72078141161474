import React from 'react';
import './index.css';
import './sector.scss';
import { Spin, Icon } from 'antd';
import { List, Progress } from 'antd';

export default function Sector(props) {
  const t = props.t;
  /** consts */
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  const data = props.sectors;
  /* if (props.sectors.length > 1){
        data = props.sectors.filter(elem => elem.productive == true)
    } */
  // const data = props.sectors;
  return data ? (
    data.length ? (
      renderSectors(t, data, props)
    ) : (
      <div className="widget-analitycs">
        <Spin className="loader-spinner-lookahead-table" indicator={antIcon} />
      </div>
    )
  ) : (
    <div className="widget-analitycs">
      <Spin className="loader-spinner-lookahead-table" indicator={antIcon} />
    </div>
  );
}

const renderSectors = (t, data, props) => (
  <div
    className="sector_list_container"
    style={{
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      width: '100%'
    }}>
    <List
      style={{
        height: '100%',
        width: '100%',
        overflow: 'auto'
      }}
      size="small"
      header={<div className="sector_title">{t('analitic.stage')}</div>}
      dataSource={data}
      renderItem={(item) => (
        <List.Item style={{ width: '100%' }}>
          {SectorItem(t, item, props.arrayActivityMaster)}
        </List.Item>
      )}
    />
  </div>
);

function SectorItem(t, sector, arrayActivityMaster) {
  let color = '#34AF00';

  const activity = arrayActivityMaster
    ? arrayActivityMaster.find((a) => a.sectorId == sector.id)
    : null;

  if (activity) {
    if (activity.progress < 30) {
      color = '#E50101';
    }
    if (activity.progress >= 30 && activity.progress < 70) {
      color = '#F59D04';
    }
    return (
      <div style={{ width: '100%' }}>
        <div className="title">{sector.name}</div>
        <div className="days">
          {activity
            ? t('lang') === 'en'
              ? activity.progress.toFixed(2)
              : activity.progress.toFixed(2).replace('.', ',')
            : t('lang') === 'es'
              ? 'No existe plan maestro'
              : 'There is no master plan'}
          %
        </div>
        <div className="progress">
          {activity
            ? t('lang') === 'en'
              ? activity.progress.toFixed(2)
              : activity.progress.toFixed(2).replace('.', ',')
            : '0'}
          %
        </div>
        <Progress
          percent={activity ? parseFloat(activity.progress.toFixed(2)) : 0}
          showInfo={false}
          strokeColor={color}
          strokeWidth={8}
          trailColor="#fff"
          width={100}
        />
      </div>
    );
  }
  return (
    <div style={{ width: '100%' }}>
      <div className="title">{sector.name}</div>
      <div className="days">
        {t('lang') === 'es'
          ? 'No existe plan maestro'
          : 'There is no master plan'}
      </div>
      <div className="progress">0%</div>
    </div>
  );
}
