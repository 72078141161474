import React, { useState, useEffect } from 'react';
import { Popover, Tooltip } from 'antd';
import { FixedSizeList as List } from 'react-window';
import {
  WHITE_COLOR,
  MAX_LENGTH_TITLE_DEPENDECIE,
  MAX_LENGTH_TITLE_DEPENDECIE_POPOVER
} from '../../constants';
import { orderTaskTree } from '../../utils';
import { ArrowDownIcon, AlertIcon } from '../../../../../../../icons';

export const DependenciesDropdownTask = ({
  value,
  tasks,
  parentsTask,
  currentIdDrawerTask,
  onClickSelectTask,
  t,
  predecessor
}) => {
  const [visible, setVisible] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const [taskArrow, setTaskArrow] = useState([]);

  const orderTasks = orderTaskTree(tasks);
  const tasksClosed = [];

  const getTaskToTree = (task, array) => {
    array.push(renderRow(task));
    if (tasksClosed.find((taskClosed) => taskClosed === task.id)) return;
    task.children.map((subtask) => getTaskToTree(subtask, array));
  };

  const contentTooltipDisabled = (idTask, textTask, currentTask) => (
    <div className="content-tooltip-disabled">
      <div className="content-tooltip-disabled__header">
        <span>
          {idTask} | {textTask}
        </span>
      </div>
      <div className="content-tooltip-disabled__footer">
        <div className="footer__icon">
          <AlertIcon color="#F6C29B" />
        </div>
        <span>
          {currentTask
            ? predecessor
              ? t('activity_card.predecessor_text_alert_tooltip')
              : t('activity_card.successor_text_alert_tooltip')
            : t('activity_card.parent_text_alert_tooltip')}
        </span>
      </div>
    </div>
  );

  const renderRow = (task) => {
    const isOpen = tasksClosed.find((taskClosed) => taskClosed === task.id);
    const disabledItem =
      task.id === currentIdDrawerTask ||
      parentsTask.find((parent) => parseInt(parent) === task.id);

    return (
      <div
        style={{ paddingLeft: task.$level * 15 + 10, paddingRight: 8 }}
        className={`menu__item ${disabledItem ? 'disabled' : ''} ${value?.id === task.correlative_id ? 'active' : ''}`}
        onClick={() =>
          !disabledItem ? handleSelectTask(task.correlative_id) : null
        }>
        <Tooltip
          title={
            disabledItem
              ? contentTooltipDisabled(
                  task.correlative_id,
                  task.text,
                  task.id === currentIdDrawerTask
                )
              : `${task.correlative_id} | ${task.text}`
          }
          placement={'right'}
          overlayClassName="overlay-tooltip-task-dropdown">
          <div className="tooltip-task-content">
            <div
              className={`content-icon ${isOpen ? 'rotate' : ''}`}
              onClick={(event) =>
                task.children.length > 0 && handleFilterTree(event, task.id)
              }>
              {task.children.length > 0 ? (
                <ArrowDownIcon color="#FFF" />
              ) : (
                <div className="icon-line" />
              )}
            </div>
            <span>
              {task.correlative_id} |{' '}
              {task.text?.length < MAX_LENGTH_TITLE_DEPENDECIE_POPOVER
                ? task.text
                : `${task.text?.substr(0, MAX_LENGTH_TITLE_DEPENDECIE_POPOVER)}...`}
            </span>
          </div>
        </Tooltip>
      </div>
    );
  };

  const renderRowList =
    (data) =>
    ({ index, style }) => <div style={style}>{data[index]}</div>;

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);

    if (!newVisible) {
      setTimeout(() => {
        orderTasksInit();
      }, 500);
    }
  };

  const handleSelectTask = (idTask) => onClickSelectTask(idTask);

  const handleFilterTree = (event, idTask) => {
    const idTaskExist = tasksClosed.findIndex((task) => task === idTask);
    idTaskExist > -1
      ? tasksClosed.splice(idTaskExist, 1)
      : tasksClosed.push(idTask);

    orderTasksInit();

    event.stopPropagation();
  };

  const orderTasksInit = () => {
    const currentTask = [];
    orderTasks.map((task) => getTaskToTree(task, currentTask));
    setTaskArrow(currentTask);
  };

  useEffect(() => {
    orderTasksInit();
  }, []);

  const content = (
    <div className="dropdown-popover-task">
      <div className="task__menu">
        <List
          width="100%"
          height={300}
          itemCount={taskArrow.length}
          itemSize={30}>
          {renderRowList(taskArrow)}
        </List>
      </div>
    </div>
  );

  return (
    <Popover
      visible={visible}
      onVisibleChange={handleVisibleChange}
      content={content}
      trigger="click"
      overlayClassName="overlay-task-dropdown-popover"
      zIndex={100}>
      <div
        className="popover-task-content"
        onMouseEnter={() => setShowArrow(true)}
        onMouseLeave={() => setShowArrow(false)}>
        <Tooltip
          title={value?.title}
          overlayClassName="overlay-tooltip-task-dropdown"
          zIndex={99}>
          <span>
            {value ? (
              value?.title?.length < MAX_LENGTH_TITLE_DEPENDECIE ? (
                value?.title
              ) : (
                `${value?.title?.substr(0, MAX_LENGTH_TITLE_DEPENDECIE)}...`
              )
            ) : (
              <p></p>
            )}
          </span>
          <div className={`content__arrow ${showArrow ? 'open' : ''}`}>
            <ArrowDownIcon color={WHITE_COLOR} />
          </div>
        </Tooltip>
      </div>
    </Popover>
  );
};

export default DependenciesDropdownTask;
