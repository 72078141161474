import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ProductivityAssignCtx } from '../../Context/Productivty/Assign';

// Components
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';

import { Assignmentworkerresources } from '../../services/assignmentworkerresources.service';

// Assets
import 'rc-calendar/assets/index.css';
import 'rc-time-picker/assets/index.css';
import styles from './index.module.css';
import { CalendarOutlined } from '@ant-design/icons';

const ProductityCalendar = (props) => {
  const { dateInit, defaultCalendarValue, setDateInit } = props;

  const productivityAssign = useContext(ProductivityAssignCtx);

  const calendarContainerRef = useRef();

  // ==> States
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(dateInit);
  const [assignedDates, setAssignedDates] = useState([]);

  useEffect(() => {
    getDates();
  }, []);

  const getDates = async () => {
    const { dates } = await Assignmentworkerresources.getDatesAssigned();
    setAssignedDates(dates);
  };

  const onChange = (value) => {
    productivityAssign.cleanSelection();
    setDate(value);
  };

  const onOpenChange = (open) => setOpen(open);

  const handleClick = () => setOpen(true);

  const getCalendarContainer = () => calendarContainerRef.current;

  const disabledDate = (current) => {
    if (!current) {
      return false;
    }

    const date = moment();
    date.hour(0);
    date.minute(0);
    date.second(0);

    return current.valueOf() > date.valueOf();
  };

  const dateRender = (current, _) => {
    const currentDate = current.format('YYYY/MM/DD');
    const existAssignments = assignedDates.find(({ date }) =>
      date.includes(currentDate)
    );

    return (
      <div
        className={`${existAssignments && styles.dateWithAssign} rc-calendar-date`}>
        {current.format('DD')}
      </div>
    );
  };

  const calendar = (
    <Calendar
      style={{ zIndex: 1001 }}
      dateInputPlaceholder="please input"
      defaultValue={defaultCalendarValue}
      focusablePanel={false}
      dateRender={dateRender}
      disabledDate={disabledDate}
    />
  );

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <DatePicker
          animation="slide-up"
          calendar={calendar}
          value={date}
          defaultValue={date}
          onChange={onChange}
          getCalendarContainer={getCalendarContainer}
          onOpenChange={onOpenChange}
          open={open}
          style={{ zIndex: 1001 }}>
          {({ value }) => {
            const dateFormat = value || dateInit;
            setDateInit(dateFormat);
            const month = dateFormat
              .format('MMMM')
              .replace(/^\w/, (firstLetter) => firstLetter.toUpperCase());
            const day = dateFormat
              .format('dddd')
              .replace(/^\w/, (firstLetter) => firstLetter.toUpperCase());
            return (
              <>
                <div className={styles.calendarDate}>
                  <p className={`${styles.orangeColor} ${styles.spaceRigth}`}>
                    {day} {dateFormat.format('DD')}
                  </p>
                  <p className={styles.blueColor}>
                    {month} {dateFormat.year()}
                  </p>
                  <CalendarOutlined
                    className={`${styles.calendarIcon} ${styles.blueColor}`}
                    onClick={handleClick}
                  />
                </div>
                <div ref={calendarContainerRef} />
              </>
            );
          }}
        </DatePicker>
      </div>
    </div>
  );
};

ProductityCalendar.propTypes = {
  dateInit: PropTypes.object,
  defaultCalendarValue: PropTypes.object
};

export default ProductityCalendar;
