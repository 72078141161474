/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
import * as Sentry from '@sentry/react';

class CompanyService {
  constructor() {
    this.http = createHttpClient();
  }

  setFreeTrialHeader(data) {
    if (data?.isFreeTrial) {
      const loggedUser = localStorage.getItem('authToken');
      if (loggedUser) {
        this.http.defaults.headers['WWW-Authenticate'] = `Bearer ${loggedUser}`;
      }
    }
  }

  async show(companyId) {
    try {
      const res = await this.http.get(`companys/${companyId}`);
      return res.data;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('companyService.show', {
          companyId,
          errorMessage: error.message,
          errorResponse: error.response
        });
        Sentry.captureException(error);
      });
      console.error(`Error fetching company ${companyId}:`, error);
      throw error;
    }
  }

  async destroy(companyId) {
    const res = await this.http
      .delete('companys/' + companyId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async index() {
    const res = await this.http.get('companys').catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async create(data) {
    try {
      this.setFreeTrialHeader(data);

      const res = await this.http.post('companys/', data);
      return res.data;
    } catch (error) {
      console.error('Error creating company:', error.response || error.message);
      return false;
    }
  }

  async update(data) {
    const res = await this.http
      .put('companys/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const companyService = new CompanyService();
