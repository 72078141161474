/**
This function is to save the original task data before drag and drop.
    @param {object} task Current status of the task in dra
    @param {object} mode Drag mode
    @param {object} original Value of the task before the drag, here we obtain the original values of the start_date and end_date.
    @returns {task.new_dates_drag} The new_dates_drag attribute is created in the task to be used in the afterTaskDrag to avoid losing the values of start_date, end_date, constraint_type and constraint_date.
*/

import moment from 'moment';

const setNewDatesWhileDrag = (task, mode, original) => {
  const gantt = window.to_use_react_gantt;
  const task_drag = {
    mode: mode,
    start_date: original.start_date,
    end_date: original.end_date,
    duration: original.duration,
    for_disable_milestone_duration: original.for_disable_milestone_duration,
    constraint_type: 'snet',
    constraint_type_old: original.constraint_type,
    constraint_date: original.constraint_date,
    baseline_points: original.baseline_points,
    task_original: original
  };
  task.constraint_type = 'asap';
  task.constraint_date = task.start_date;
  if (gantt.draggingSingleMilestone) {
    setNewDatesForMilestone(task, task_drag);
  }

  task.new_dates_drag = task_drag;
};

function setNewDatesForMilestone(task, task_drag) {
  const startDateCopy = moment(task.start_date).clone();
  startDateCopy
    .hours(task.calendarWorkingHours.start.hour)
    .minutes(task.calendarWorkingHours.start.minutes);

  task.start_date = startDateCopy.toDate();
  task.end_date = startDateCopy.toDate();

  task_drag.start_date = startDateCopy.toDate();
  task_drag.end_date = startDateCopy.toDate();
}

export { setNewDatesWhileDrag };
