import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sectorService, userService } from '../../../services';
import * as projectActions from '../../../redux/slices/projectSlice';
import ModalSystem from '../../DesignSystem/ModalSystem';
import SelectorSystem from '../../DesignSystem/SelectorSystem';
import TooltipSystem from '../../DesignSystem/TooltipSystem';
import CheckSystem from '../../DesignSystem/CheckSystem';
import { DuplicateStageIcon, InfoIcon } from '../../../icons';
import { dynamicSort, getTypeNotification } from '../../../utils';
import { Input } from 'antd';
import { notifyMessageCustom } from '../../../utils';
import {
  COPY_EVERYTHING_KEY,
  COPY_EVERYTHING_VALUE,
  CUSTOMIZE_KEY,
  CUSTOMIZE_VALUE,
  SCHEDULE_KEY,
  LOOKAHEAD_KEY,
  custom_values,
  CUSTOM_DUPLICATION_SCHEDULE_OPTIONS,
  CUSTOM_DUPLICATION_LOOKAHEAD_OPTIONS,
  COPY_SCHEDULE_AND_LOOKAHEAD_KEY,
  COPY_SCHEDULE_AND_LOOKAHEAD_VALUE
} from './constants';
import { DuplicateStageComp, EditPartyComp } from './icons';

const ROADBLOCKS_KEY = 'roadblocks';
const TASK_KEY = 'tasks';

const DuplicateStage = ({ show, setShow, t }) => {
  const dispatch = useDispatch();

  const inputCopyLabel = t('duplicate_stage.input_copy');

  const { projectState } = useSelector((state) => state);

  const [nameStage, setNameStage] = useState(
    `${projectState?.sectorSelectedRename?.name} - ${inputCopyLabel}`
  );
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [selectedProject, setSelectedProject] = useState(
    projectState.projectSelected
  );
  const [selectTypeDuplication, setSelectTypeDuplication] = useState(
    COPY_EVERYTHING_VALUE
  );
  const [scheduleOptions, setScheduleOptions] = useState([]);
  const [lookaheadOptions, setLookaheadOptions] = useState([]);

  useEffect(() => {
    setNameStage(
      `${projectState.sectorSelectedRename?.name} - ${inputCopyLabel}`
    );
  }, [projectState?.sectorSelectedRename]);

  useEffect(() => {
    setSelectedProject(projectState?.projectSelected);
  }, [projectState?.projectSelected]);

  const handleOnChange = (event) => {
    setNameStage(event.target.value);
  };

  const handleCloseModal = () => {
    setShow(false);
    setTimeout(() => {
      setSelectTypeDuplication(COPY_EVERYTHING_VALUE);
      cleanCustomOptions();
    }, 300);
  };

  const handleOnChangeTypeDuplication = (type) => {
    setSelectTypeDuplication(type);

    if (type === COPY_SCHEDULE_AND_LOOKAHEAD_VALUE) {
      cleanCustomOptions();
    }
  };

  const handleClickCheck = (value, keyCheck, type) => {
    if (type === SCHEDULE_KEY) {
      const currentScheduleOptions = [...scheduleOptions];
      const currentIndex = currentScheduleOptions.findIndex(
        (item) => item === keyCheck
      );

      if (value) {
        if (currentIndex > -1) return;
        currentScheduleOptions.push(keyCheck);
      } else {
        if (currentIndex === -1) return;
        currentScheduleOptions.splice(currentIndex, 1);
      }

      setScheduleOptions(currentScheduleOptions);
    }

    if (type === LOOKAHEAD_KEY) {
      const currentLookaheadOptions = [...lookaheadOptions];
      const currentIndex = currentLookaheadOptions.findIndex(
        (item) => item === keyCheck
      );

      if (keyCheck === TASK_KEY && !value) {
        setLookaheadOptions([]);
        return;
      }

      if (value) {
        if (currentIndex > -1) return;
        currentLookaheadOptions.push(keyCheck);
      } else {
        if (currentIndex === -1) return;
        currentLookaheadOptions.splice(currentIndex, 1);
      }

      setLookaheadOptions(currentLookaheadOptions);
    }
  };

  const handleDuplicateModal = async () => {
    if (disabledButton()) return;

    setIsLoadingButton(true);

    notifyMessageCustom({
      type: getTypeNotification(DuplicateStageComp, 'withTitleIcon'),
      title: t('notifications.duplicate_sector_processing'),
      description: t('notifications.duplicate_sector_processing_msg')
    });

    setShow(false);
    setIsLoadingButton(false);

    try {
      const currentUser = JSON.parse(localStorage.getItem('user'));

      if (selectTypeDuplication === COPY_EVERYTHING_VALUE) {
        const userLang = navigator.language || navigator.userLanguage;
        await sectorService.duplicateEverything({
          user_id: currentUser?.id,
          source_sector_id: projectState?.sectorSelectedRename?.id,
          target_project_id: selectedProject,
          target_sector_name: nameStage,
          lang: userLang.includes('es') ? 'es' : 'en'
        });
      }

      if (selectTypeDuplication === COPY_SCHEDULE_AND_LOOKAHEAD_VALUE) {
        await sectorService.duplicateSector({
          user_id: currentUser?.id,
          source_sector_id: projectState?.sectorSelectedRename?.id,
          target_project_id: selectedProject,
          target_sector_name: nameStage
        });
      }

      if (selectTypeDuplication === CUSTOMIZE_VALUE) {
        const customDuplicationSchedule = {};
        CUSTOM_DUPLICATION_SCHEDULE_OPTIONS.map(
          (option) =>
            (customDuplicationSchedule[option] = scheduleOptions.some(
              (opt) => opt === option
            ))
        );

        const customDuplicationLookahead = {};
        CUSTOM_DUPLICATION_LOOKAHEAD_OPTIONS.map(
          (option) =>
            (customDuplicationLookahead[option] = lookaheadOptions.some(
              (opt) => opt === option
            ))
        );

        await sectorService.duplicateSector({
          user_id: currentUser?.id,
          source_sector_id: projectState?.sectorSelectedRename?.id,
          target_project_id: selectedProject,
          target_sector_name: nameStage,
          custom_duplication_options: {
            schedule: customDuplicationSchedule,
            lookahead: customDuplicationLookahead
          }
        });
      }

      await callGetSectors();
      dispatch(projectActions.setSectorViewNotification(true));

      const description = t(
        'notifications.duplicate_sector_success_msg'
      ).replace('__str__', nameStage);
      notifyMessageCustom({
        type: getTypeNotification(EditPartyComp, 'withTitleIcon'),
        title: t('notifications.duplicate_sector_finished'),
        description
      });

      setSelectTypeDuplication(COPY_EVERYTHING_VALUE);
      cleanCustomOptions();

      const getProjects = await userService.projectsbyuserthrough(
        currentUser?.id
      );
      if (!getProjects) return;

      const projectsActive = getProjects.projects.filter(
        (project) => project.stage !== 'deleted'
      );
      const sortProjects = projectsActive.sort(dynamicSort('name'));
      dispatch(projectActions.setAllProjects(sortProjects));
    } catch (err) {
      console.log(err);
    }
  };

  const disabledButton = () =>
    nameStage === '' || nameStage?.trim() === '' || isLoadingButton;

  const disabledRoadBlocks = () =>
    !lookaheadOptions.some((option) => option === TASK_KEY);

  const cleanCustomOptions = () => {
    setScheduleOptions([]);
    setLookaheadOptions([]);
  };

  const optionSectorsDropdown = () =>
    projectState?.allProjects?.sort(dynamicSort('name')).map((project) => {
      if (
        (selectedProject &&
          project.id === selectedProject &&
          project.stage === 'archived') ||
        project.stage !== 'archived'
      ) {
        return {
          key: project?.id,
          value: project?.id,
          label: project?.name,
          show: true,
          active: selectedProject === project.id,
          onClick: () => setSelectedProject(project?.id),
          hoverAction: false,
          hoverActionClick: null,
          hoverTooltip: false,
          hoverTooltipText: null,
          showIcon: false,
          icon: null,
          iconPosition: null,
          popoverOptions: null
        };
      }
    });

  const optionsDuplicate = () => [
    {
      key: COPY_EVERYTHING_KEY,
      value: COPY_EVERYTHING_VALUE,
      label: t('duplicate_stage.option_duplicate_everything'),
      show: true,
      active: selectTypeDuplication === COPY_EVERYTHING_VALUE,
      onClick: () => handleOnChangeTypeDuplication(COPY_EVERYTHING_VALUE),
      hoverAction: true,
      hoverActionClick: null,
      hoverTooltip: true,
      hoverTooltipText: t('duplicate_stage.option_duplicate_everything_label'),
      showIcon: true,
      icon: null,
      iconPosition: null,
      popoverOptions: [],
      customRightIcon: <InfoIcon color={'#7D8671'} />
    },
    {
      key: COPY_SCHEDULE_AND_LOOKAHEAD_KEY,
      value: COPY_SCHEDULE_AND_LOOKAHEAD_VALUE,
      label: t('duplicate_stage.option_duplicate_schedule_and_lookahead'),
      show: true,
      active: selectTypeDuplication === COPY_SCHEDULE_AND_LOOKAHEAD_VALUE,
      onClick: () =>
        handleOnChangeTypeDuplication(COPY_SCHEDULE_AND_LOOKAHEAD_VALUE),
      hoverAction: false,
      hoverActionClick: null,
      hoverTooltip: false,
      hoverTooltipText: null,
      showIcon: false,
      icon: null,
      iconPosition: null,
      popoverOptions: null
    },
    {
      key: CUSTOMIZE_KEY,
      value: CUSTOMIZE_VALUE,
      label: t('duplicate_stage.option_duplicate_customize'),
      show: true,
      active: selectTypeDuplication === CUSTOMIZE_VALUE,
      onClick: () => handleOnChangeTypeDuplication(CUSTOMIZE_VALUE),
      hoverAction: false,
      hoverActionClick: null,
      hoverTooltip: false,
      hoverTooltipText: null,
      showIcon: false,
      icon: null,
      iconPosition: null,
      popoverOptions: null
    }
  ];

  const getSectorsByProject = async (idProject) => {
    const resp = await sectorService.getSectorsByProject(idProject);
    return resp ? resp.sectors : false;
  };

  const callGetSectors = async () => {
    const sectors = await getSectorsByProject(projectState.projectSelected);
    if (sectors) {
      const filterSectors = sectors.filter((e) => e.status === true);
      dispatch(projectActions.setAllSectors(filterSectors));
    }
  };

  const contentChildren = () => (
    <div className="duplicate-stage__content">
      <div className="content__form">
        <label className="form__label">
          {t('duplicate_stage.input_label')}
        </label>
        <Input type="text" value={nameStage} onChange={handleOnChange} />
        <div className="form__select">
          <div className="select__label">
            <label>
              {t('duplicate_stage.select_label')}{' '}
              <span>({t('duplicate_stage.select_hint')})</span>
            </label>
          </div>
          <div className="select__dropdown">
            <SelectorSystem
              width={200}
              shortText={true}
              maxLengthText={25}
              options={optionSectorsDropdown()}
            />
          </div>
        </div>
        <div className="form__select">
          <div className="select__label">
            <label>{t('duplicate_stage.input_type_duplicate_label')}</label>
            <span>{t('duplicate_stage.input_type_duplicate_hint')}</span>
          </div>
          <div className="select__dropdown">
            <SelectorSystem width={200} options={optionsDuplicate()} />
          </div>
        </div>
        {selectTypeDuplication === CUSTOMIZE_VALUE && (
          <div className="form__customize">
            {custom_values(t).map((type) => (
              <div key={type?.key} className="customize__type">
                <div className="type__title">
                  <h6>{type?.label}</h6>
                  {type?.withTooltip &&
                    TooltipSystem({
                      children: type?.contentTooltip,
                      placement: 'right',
                      title: type?.textTooltip
                    })}
                </div>
                <div className="type__options">
                  {type?.options.map((option) => (
                    <div key={option?.key}>
                      <CheckSystem
                        withLabel={true}
                        label={option?.label}
                        checked={
                          type?.key === SCHEDULE_KEY
                            ? scheduleOptions.some((op) => op === option?.key)
                            : type?.key === LOOKAHEAD_KEY
                              ? lookaheadOptions.some(
                                  (op) => op === option?.key
                                )
                              : false
                        }
                        disabled={
                          option?.key === ROADBLOCKS_KEY
                            ? disabledRoadBlocks()
                            : option?.disabled
                        }
                        onClickFunction={(value) =>
                          handleClickCheck(value, option?.key, type?.key)
                        }
                      />
                    </div>
                  ))}
                </div>
                {type?.key === SCHEDULE_KEY && <div className="type__line" />}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="content__buttons">
        <button className="buttons__cancel" onClick={() => handleCloseModal()}>
          {t('duplicate_stage.button_cancel_text')}
        </button>
        <button
          className="buttons__save"
          disabled={disabledButton()}
          onClick={() => handleDuplicateModal()}>
          {t('duplicate_stage.button_duplicate_text')}
        </button>
      </div>
    </div>
  );

  return ModalSystem({
    visible: show,
    title: t('duplicate_stage.title_modal'),
    theme: 'dark',
    setVisible: setShow,
    showIconTitle: true,
    iconTitle: <DuplicateStageIcon color="#7DFF8A" />,
    centered: true,
    width: 520,
    children: contentChildren(),
    onCancelFunction: () => handleCloseModal(),
    zIndex: 2000
  });
};

export default DuplicateStage;
