/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

import moment from 'moment';

class CalendarExceptionDayService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(calendarId) {
    const res = await this.http
      .get('calendarexceptiondays/' + calendarId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(calendarId) {
    const res = await this.http
      .delete('calendarexceptiondays/' + calendarId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const copy = { ...data };
    copy.from_date = moment(data.from_date).format('YYYY/MM/DD H:mm');
    copy.to_date = moment(data.to_date).format('YYYY/MM/DD H:mm');
    const res = await this.http
      .post('calendarexceptiondays/', copy)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('calendarexceptiondays/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const calendarExceptionDayService = new CalendarExceptionDayService();
