/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class CnctaskService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(constraintId) {
    const res = await this.http
      .get('cnctasks/' + constraintId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(constraintId) {
    const res = await this.http
      .delete('cnctasks/' + constraintId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('cnctasks', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('cnctasks/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const cncTaskService = new CnctaskService();
