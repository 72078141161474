import React from 'react';
import { splitText } from '../../../../views/taktplanning/assign/index.helper';

export const GridCellStructureTree =
  (
    activities,
    units,
    selectedActivities,
    selectedUnits,
    structureArray,
    locationArray
  ) =>
  ({ columnIndex, rowIndex, style }) => {
    const activity = activities[rowIndex];
    const structure = structureArray[columnIndex];

    const foundItemsSelected = (currentSelection) => (itemSelected) =>
      itemSelected.id === currentSelection.id && itemSelected.active;
    const foundActivitiesToUnassign = (currentSelection) => (itemSelected) =>
      itemSelected.id === currentSelection.id &&
      currentSelection.disable &&
      currentSelection.active;
    const isActivitySelected = selectedActivities.find(
      (currentActivity) => currentActivity.id == activity.id && activity.active
    );
    const activitiesWillUnassign = selectedActivities.find(
      (currentActivity) =>
        currentActivity.id == activity.id && activity.active && activity.disable
    );

    // Units groupping
    let isSomeUnitSelected = false;
    let unitWillCreate = 0;

    const creatingUnits = (location) => {
      location.productionUnits.forEach((unit) => {
        const isFoundUnitSelected = selectedUnits.find(
          foundItemsSelected(unit)
        );
        if (isFoundUnitSelected) {
          unitWillCreate++;
          isSomeUnitSelected = true;
        }
      });
    };

    structure.children.forEach(creatingUnits);

    /**
     * ===============
     *  Unassign units
     * ===============
     * */
    let isSomeUnitwillUnassign = false;
    let unitWillUnassign = 0;
    const unassigningUnits = (location) => {
      location.productionUnits.forEach((unit) => {
        const isFoundUnitSelected = selectedUnits.find(
          foundActivitiesToUnassign(unit)
        );
        const currentActivity = activity;
        const activytyUnitMatches =
          isFoundUnitSelected &&
          currentActivity &&
          isFoundUnitSelected.activities.find(
            (activity) => activity.id === currentActivity.id
          );
        if (isFoundUnitSelected && activytyUnitMatches) {
          unitWillUnassign++;
          isSomeUnitwillUnassign = true;
        }
      });
    };

    structure.children.forEach(unassigningUnits);

    /**
     * ==========================================
     *  Activities and units already had assigned
     * ==========================================
     * */

    let alreadyAssignedActivities = 0;
    const getAlreadyActivitiesAndUnitsSelected = (unitActivity) => {
      let isValid = false;
      structure.children.forEach((location) => {
        location.productionUnits.forEach((unitLocation) => {
          if (unitLocation.id == unitActivity.id) isValid = true;
        });
      });
      isValid && alreadyAssignedActivities++;
    };
    activity.productionUnits.forEach(getAlreadyActivitiesAndUnitsSelected);

    const blankText = activity.has_childs ? '' : '-';
    let content =
      isActivitySelected && isSomeUnitSelected && !activity.has_childs
        ? `${Number(unitWillCreate) && `${Number(unitWillCreate)} new /`}  ${alreadyAssignedActivities ? `${alreadyAssignedActivities} assigned` : ''}`
        : `${alreadyAssignedActivities || blankText}`;

    let className =
      isActivitySelected && isSomeUnitSelected && !activity.has_childs
        ? 'grid-element-with-units separate-secctions'
        : `grid-element-without-units separate-secctions ${alreadyAssignedActivities && !activity.has_childs ? 'grid-element-with-units' : ''}`;

    if (activitiesWillUnassign && isSomeUnitwillUnassign) {
      className = 'grid-element-unassign-units';
      content = `${unitWillUnassign} / ${alreadyAssignedActivities}`;
    }

    return (
      <div className={className} style={style}>
        {activity.has_childs ? '' : splitText(content, 8)}
      </div>
    );
  };

/**
 * This component is the rendered element between Y (activity) and X (location) which show the match of selected items
 * @param {*} activities all activities array
 * @param {*} units all locations array
 * @param {*} selectedActivities selected activities array
 * @param {*} selectedUnits selected units array
 * @param {*} locationArray locations with units array
 */
export const GridCellStructureGroupped =
  (
    activities,
    units,
    selectedActivities,
    selectedUnits,
    structureArray,
    locationArray
  ) =>
  ({ columnIndex, rowIndex, style }) => {
    const activitiesGroup = activities[rowIndex];
    const structure = structureArray[columnIndex];
    const foundItemsSelected = (currentSelection) => (itemSelected) =>
      itemSelected.id === currentSelection.id && !itemSelected.disable;
    const foundItemsWillAssign = (currentSelection) => (itemSelected) =>
      itemSelected.id === currentSelection.id && itemSelected.active;
    const foundActivitiesToUnassign = (currentSelection) => (itemSelected) =>
      itemSelected.id === currentSelection.id &&
      currentSelection.disable &&
      currentSelection.active;

    const customClass = 'separate-secctions';

    /*
     * ===========
     * Will assign units flow
     * ==========
     * */
    // Activities groupping
    let isActivitySelected = false;
    let activitiesWillCreate = 0;

    const creatingActivities = (activity) => {
      const activitiesSelection = foundItemsWillAssign(activity);
      const isFoundActivitySelected =
        selectedActivities.find(activitiesSelection);
      if (isFoundActivitySelected) {
        activitiesWillCreate++;
        isActivitySelected = true;
      }
    };

    activitiesGroup.activities.forEach(creatingActivities);

    // Units groupping
    let isSomeUnitSelected = false;
    let unitWillCreate = 0;
    const creatingUnits = (location) => {
      location.productionUnits.forEach((unit) => {
        const unitsSelection = foundItemsWillAssign(unit);
        const isFoundUnitSelected = selectedUnits.find(unitsSelection);
        if (isFoundUnitSelected) {
          unitWillCreate++;
          isSomeUnitSelected = true;
        }
      });
    };

    structure.children.forEach(creatingUnits);

    /**
     * ===============
     *  Unassign units
     * ===============
     * */
    let activitiesWillUnassign = false;
    let currentActivity = null;
    const unAssignActivities = (activity) => {
      const isFoundActivitySelected = selectedActivities.find(
        foundActivitiesToUnassign(activity)
      );
      if (isFoundActivitySelected) {
        activitiesWillUnassign = true;
        currentActivity = activity;
      }
    };

    activitiesGroup.activities.forEach(unAssignActivities);

    // Units unassigning
    let isSomeUnitwillUnassign = false;
    let unitWillUnassign = 0;

    const unassigningUnits = (location) => {
      location.productionUnits.forEach((unit) => {
        const isFoundUnitSelected = selectedUnits.find(
          foundActivitiesToUnassign(unit)
        );
        const activityUnitMatches =
          isFoundUnitSelected &&
          currentActivity &&
          isFoundUnitSelected.activities.find(
            (activity) => activity.id === currentActivity.id
          );
        if (isFoundUnitSelected && activityUnitMatches) {
          unitWillUnassign++;
          isSomeUnitwillUnassign = true;
        }
      });
    };

    structure.children.forEach(unassigningUnits);

    /**
     * ==========================================
     *  Activities and units already had assigned
     * ==========================================
     * */
    let alreadyAssignedActivities = 0;
    const getAlreadyActivitiesAndUnitsSelected = (activity) => {
      structure.children.forEach((location) => {
        location.productionUnits.forEach((unit) => {
          const unitsSelection = foundItemsSelected(activity);
          const isFoundUnitSelected = unit.activities.find(unitsSelection);
          isFoundUnitSelected && alreadyAssignedActivities++;
        });
      });
    };

    activitiesGroup.activities.forEach(getAlreadyActivitiesAndUnitsSelected);

    let className =
      isActivitySelected && isSomeUnitSelected
        ? `grid-element-with-units ${customClass}`
        : `grid-element-without-units ${customClass} ${alreadyAssignedActivities ? 'grid-element-with-units' : ''}`;

    let content =
      isActivitySelected && isSomeUnitSelected
        ? `${unitWillCreate * activitiesWillCreate} new / ${alreadyAssignedActivities} assigned`
        : `${alreadyAssignedActivities || '-'}`;

    // adding class for unassign
    if (activitiesWillUnassign && isSomeUnitwillUnassign) {
      className = 'grid-element-unassign-units';
      content = `${unitWillUnassign * activitiesWillUnassign} / ${alreadyAssignedActivities}`;
    }

    return (
      <div className={className} style={style}>
        {splitText(content, 8)}
      </div>
    );
  };
