// Action types
export const SUBMITTAL_CREATED = 'SUBMITTAL_CREATED';
export const SUBMITTAL_UPDATED = 'SUBMITTAL_UPDATED';
export const LOAD_SUBMITTALS = 'LOAD_SUBMITTALS';
export const SUBMITTAL_DELETED = 'SUBMITTAL_DELETED';
export const UPDATE_SUBMITTALS = 'UPDATE_SUBMITTALS';

/**
 * An object that contains action creators for handling submittals.
 */
export const submittalActions = {
  /**
   * Creates an action for submittal creation.
   *
   * @param {Object} data - The created submittal.
   * @return {Object} - The action.
   */
  submittalCreated: (data) => ({
    type: SUBMITTAL_CREATED,
    payload: data
  }),
  /**
   * Creates an action for submittal updates.
   *
   * @param {Object} data - The updated submittal.
   * @return {Object} - The action.
   */
  submittalUpdated: (data) => ({
    type: SUBMITTAL_UPDATED,
    payload: data
  }),
  /**
   * Creates an action for loading submittals.
   *
   * @param {Array} data - An array of submittals.
   * @return {Object} - The action.
   */
  loadSubmittals: (data) => ({
    type: LOAD_SUBMITTALS,
    payload: data
  }),
  /**
   * Creates an action for submittal deletion.
   *
   * @param {string|number} submittalId - The ID of the submittal to delete.
   * @return {Object} - The action.
   */
  submittalDeleted: (submittalId) => ({
    type: SUBMITTAL_DELETED,
    payload: submittalId
  }),
  /**
   * Creates an action for updating submittals.
   *
   * @param {Array} submittals - An array of updated submittals.
   * @return {Object} - The action.
   */
  updateSubmittals: (submittals) => ({
    type: UPDATE_SUBMITTALS,
    payload: submittals
  })
};
