import React from 'react';
import './cardSettingPlugin.scss';
import { withTranslation } from 'react-i18next';
import CardsGeneral from './CardsGeneral/CardsGeneral';

function CardSettingPlugin({ t }) {
  return (
    <div className="grid-settings-user-general-plugin">
      <div>
        <h1 className="projects-title">{t('settings.plugins_menu.title')}</h1>
        <CardsGeneral />
      </div>
    </div>
  );
}

export default withTranslation()(CardSettingPlugin);
