/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class BaseWorkingDayService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(baseworkingdayId) {
    const res = await this.http
      .get('baseworkingdays/' + baseworkingdayId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(baseworkingdayId) {
    const res = await this.http
      .delete('baseworkingdays/' + baseworkingdayId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(baseworkingday) {
    const res = await this.http
      .post('baseworkingdays', baseworkingday)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(baseworkingday) {
    const res = await this.http
      .put('baseworkingdays/' + baseworkingday.id, baseworkingday)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const baseworkingdayService = new BaseWorkingDayService();
