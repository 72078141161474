/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
import { base } from './base';

const baseURL = base.apiTakt;

class AnalyticResourceServiceFollow {
  constructor() {
    this.http = createHttpClient({ baseURL });
  }

  async resourceMatrix(data) {
    const res = await this.http
      .post('analytics/scurve_resource', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const analyticResourceServiceFollow =
  new AnalyticResourceServiceFollow();
