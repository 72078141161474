import React from 'react';
import Outbuild from '../../../assets/img/proplanner.svg';
import IconFrreTrial from '../../../assets/img/icon-email-free-trial.svg';
import './index.scss';

const SecondScreen = (props) => {
  const { t } = props;

  return (
    <div className="signup__second-screen">
      <div className="second-screen__logo">
        <img src={Outbuild} alt="Logo Outbuild" />
      </div>
      <div className="second-screen__body">
        <div className="body__title">
          <h6>{t('signup.case_a.third_screen_title')}</h6>
        </div>
        <div className="body__icon">
          <img src={IconFrreTrial} alt="Icon Free Trial" />
        </div>
        <div className="body__hint">
          <span>
            {t('signup.case_a.third_screen_hint_text')}{' '}
            <a href="mailto:success@outbuild.com">Success@outbuild.com</a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SecondScreen;
