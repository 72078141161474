const setOrderFilter = (showedFilters = [], newGantt) => {
  if (!showedFilters) return;
  if (showedFilters.length === 0) return;

  const gantt = newGantt;
  const mapElements = {};

  gantt.isDataOrdered = true;
  let query = '';
  showedFilters.map((o, index) => {
    let fieldName = "'" + o.name + "'";
    const orderBy = o.order_by;
    if (o.name == 'custom_predecessors') {
      o.name = 'custom_predecessor';
    }

    if (o.data_type.includes('array/')) {
      if (o.data_type.includes('/string') || o.data_type.includes('/icon')) {
        const optionsMap = {};
        o.from_values.map((option) => {
          optionsMap[option.value] = option.weigth;
        });
        mapElements[o.name] = optionsMap;
        fieldName = `
                    (v1, v2) => {
                        let weigth_one = mapElements.${o.name}[v1.${o.name}]
                        let weigth_two = mapElements.${o.name}[v2.${o.name}]
                        return (weigth_one || 0) - (weigth_two || 0)
                    }
                    `;
      }
    }

    if (index == 0) {
      query =
        query +
        `firstBy(${fieldName}, { direction: '${orderBy}', ignoreCase: true})`;
    } else {
      query =
        query +
        `.thenBy(${fieldName}, { direction: '${orderBy}', ignoreCase: true})`;
    }
  });
  gantt.sort(eval(query));
  gantt.toOrderByQuery = query;
  gantt.lastOrderByQuery = showedFilters;
};

export default setOrderFilter;
