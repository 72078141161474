import React, { useState } from 'react';
import { Tooltip } from 'antd';
import PopoverDropdown from './PopoverDropdown';
import { MoreFilledIcon } from '../../../../icons';

const SEPARATION_KEY = 'separation';
const TYPE_ITEM_DROPDOWN = 'item_dropdown';
const TYPE_ITEM_POPOVER = 'content__item';

const DropdownSelectorSystem = ({
  show,
  theme,
  selectorSize,
  options,
  shortText = false,
  maxLengthText = 100,
  setShowDropdown,
  setIsRunning,
  dropdownPosition,
  groupedInput = null
}) => {
  const [handleClosePopover, setHandleClosePopover] = useState(false);

  const handleClickFunction = (event, onClickFunction, type = null) => {
    if (onClickFunction) onClickFunction();
    event.stopPropagation();

    if (type === TYPE_ITEM_DROPDOWN) setShowDropdown(false);

    if (type === TYPE_ITEM_POPOVER) {
      setHandleClosePopover(true);
      setTimeout(() => {
        setShowDropdown(false);
        setHandleClosePopover(false);
      }, 500);
    }
  };

  const contentPopover = (options) => (
    <div
      className="popover__content"
      onClick={(event) => event.stopPropagation()}>
      {options.map(
        (option) =>
          option &&
          option.show && (
            <div
              key={option.key}
              className={`content__item ${option.disabled ? 'disabled' : ''}`}
              onClick={(event) =>
                !option.disabled &&
                option.onClick &&
                handleClickFunction(event, option.onClick, TYPE_ITEM_POPOVER)
              }>
              {option.key === SEPARATION_KEY ? (
                <div className="item__line" />
              ) : (
                <div className="item__option__informative">
                  <div className="item__option">
                    {option.showIcon && option.icon && (
                      <div
                        className={`option__icon position--${option.iconPosition || 'left'}`}>
                        {option.icon}
                      </div>
                    )}
                    <span>{option.label}</span>
                  </div>
                  {option.disabled &&
                    option.key === 'delete' &&
                    childrenPopover(
                      option.hoverActionClick,
                      option.hoverTooltip,
                      option.hoverTooltipText,
                      option.customRightIcon
                    )}
                </div>
              )}
            </div>
          )
      )}
    </div>
  );

  const childrenPopover = (
    hoverActionClick,
    hoverTooltip,
    hoverTooltipText,
    customRightIcon
  ) =>
    hoverTooltip ? (
      <Tooltip
        title={hoverTooltipText}
        className="item__dropdown"
        zIndex={2000}>
        <div onClick={(event) => handleClickFunction(event, hoverActionClick)}>
          {customRightIcon || <MoreFilledIcon color="#FFF" />}
        </div>
      </Tooltip>
    ) : (
      <div
        className="item__dropdown"
        onClick={(event) => handleClickFunction(event, hoverActionClick)}>
        <MoreFilledIcon color="#FFF" />
      </div>
    );

  const getTextLabel = (label) => {
    if (!label) return 'Default';
    if (shortText) {
      return label.length > maxLengthText
        ? `${label.substring(0, maxLengthText)}...`
        : label;
    }
    return label;
  };

  const groupOptions = (options) => {
    const groupSeparationItem = { key: 'separation', show: true };

    const currentGroupCurrent = options.filter(
      (option) => option && option.show && option.set_current
    );
    const currentGroupOthers = options.filter(
      (option) => !option?.show || !option.set_current
    );

    if (currentGroupOthers.length > 0) {
      currentGroupCurrent.unshift(groupSeparationItem);
      currentGroupOthers.unshift(groupSeparationItem);
    } else {
      currentGroupCurrent.unshift(groupSeparationItem);
    }

    return currentGroupCurrent.concat(currentGroupOthers);
  };

  const renderOptions = (options) => {
    if (!groupedInput) {
      return options.map(
        (option, numElement) =>
          option?.show && renderElement(option, numElement)
      );
    }

    const optionsLast = groupOptions(options);
    return optionsLast.map(
      (option, numElement) => option?.show && renderElement(option, numElement)
    );
  };

  const renderElement = (option, numElement = null) => {
    const keyElement = `renderElement-${numElement}`;
    if (option.key === SEPARATION_KEY) {
      return (
        <div key={keyElement} className="set__item">
          <span>{groupedInput?.separation[numElement / 2]}</span>
          <div className="line__item" />
        </div>
      );
    }
    return (
      <div
        key={keyElement}
        className={`dropdown__item ${option.active ? 'active' : ''} ${
          option?.disabled ? 'disabled' : ''
        }`}
        onClick={(event) =>
          !option?.disabled &&
          option.onClick &&
          handleClickFunction(event, option.onClick, TYPE_ITEM_DROPDOWN)
        }>
        <span>
          {option.showIcon && option.icon && (
            <div className={`span__icon position--${option.iconPosition}`}>
              {option.icon}
            </div>
          )}
          {getTextLabel(option.label)}
        </span>
        {option.hoverAction && option.popoverOptions && (
          <PopoverDropdown
            theme={theme}
            showDropdown={show}
            content={contentPopover(option.popoverOptions)}
            childrenContent={childrenPopover(
              option.hoverActionClick,
              option.hoverTooltip,
              option.hoverTooltipText,
              option.customRightIcon
            )}
            setIsRunning={setIsRunning}
            handleClosePopover={handleClosePopover}
          />
        )}
      </div>
    );
  };

  return (
    <div
      className={`container__dropdown ${
        show ? 'visible' : 'hidden'
      } theme--${theme} selector--size--${selectorSize}`}
      style={{
        minWidth: dropdownPosition?.width,
        top: dropdownPosition?.top + (selectorSize === 'small' ? 34 : 40) || 0,
        left: dropdownPosition?.left
      }}>
      {renderOptions(options)}
    </div>
  );
};

export default DropdownSelectorSystem;
