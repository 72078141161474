import CriticalPathHelpers from '../base/generic-calculations';
import calculateRestriction from './helpers/restrictionCalculation';
import { LinksConstraintCalculator } from './calculators/LinksConstraintCalculator';
import { CONSTRAINT_TYPES } from '../constants/index';

class ForwardPath extends CriticalPathHelpers {
  constructor(gantt) {
    super('forward');
    this.gantt = gantt;
    this.chainStartActivities = null; // Activities that start the chains
    this.calculations = new Map(); // Storage of the forward calculations
    this.mapOfRelationsWithActivitiesIds = new Map(); // Relation storage with the activities
    this.mapOfRelationsWithLinks = new Map(); // Relation between activities and links
    this.alapMap = new Map(); // Storage of Alap calculations
    this.activitiesPendingToBeCalculated = new Set(); // Storage of activities pending calculation
    this.activitiesLinkedToChainStart = new Set(); // Storage of activities linked to chain starts
    this.constraintsMap = new Map(); // Storage of constraints derived from calculations
    this.totalFloatSnltFnlt = new Map();
    this.totalFloatForMsoMfo = new Map();
    this.totalFloatForAsap = new Map();
    this.identifyInitialActivities();
    this.transformInitialActivitiesToObject();
  }

  async calculate() {
    try {
      await this.calculateChains();
      this.getStartAndFinishOfChainOrigin();
      this.calculateActivitiesDirectlyLinkedToChainStarts();
      this.initActivitiesPendingToBeCalculated();
      this.recursiveCalculateEfAndEs();
    } catch (error) {
      throw new Error(`FordwardPath - ${error.message}`);
    }
  }

  /**
   * Recursively calculates the earliest start (ES) and earliest finish (EF) dates for activities.
   *
   * This function processes activities that can be calculated based on their predecessors and updates
   * the remaining pending activities until all calculations are complete. It recursively calls itself
   * until there are no more pending activities to be calculated.
   *
   * @param {Set<string|number>} [activitiesPending=this.activitiesPendingToBeCalculated] - A set of activity IDs that are pending calculation.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activitiesPendingToBeCalculated is a Set containing activity IDs [1, 2, 3]
   * recursiveCalculateEfAndEs();
   * // This will recursively calculate ES and EF for all activities that can be calculated
   * // based on their predecessors and update the pending activities set until all are calculated.
   *
   * @throws {Error} If an error occurs during the process.
   */

  recursiveCalculateEfAndEs(
    activitiesPending = this.activitiesPendingToBeCalculated
  ) {
    if (activitiesPending.size === 0) return;

    let { activitiesThatCanBeCalculated, pendingActivities } =
      this.checkActivitiesThatNowCanBeCalculated(activitiesPending);

    this.calculateEsAndEf(activitiesThatCanBeCalculated);

    activitiesThatCanBeCalculated.forEach((activity) => {
      pendingActivities.delete(activity);
    });

    if (pendingActivities.size > 0) {
      this.recursiveCalculateEfAndEs(pendingActivities);
    }
  }

  /**
   * Calculates the earliest start (ES) and earliest finish (EF) dates for a list of activities.
   *
   * This function processes each activity in the `activitiesToCalculate` list, retrieves the necessary
   * information, and calculates the ES and EF dates based on the activity's progress and constraint type.
   * It handles various constraints and updates the calculations accordingly.
   * It also do calculation according to the activities links
   *
   * @param {Array<string|number>} activitiesToCalculate - A list of activity IDs for which to calculate ES and EF dates.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activitiesToCalculate is an array containing activity IDs [1, 2, 3]
   * calculateEsAndEf([1, 2, 3]);
   * // This will calculate the ES and EF dates for the activities and update the internal calculations map.
   *
   * @throws {Error} If an error occurs during the process.
   */

  calculateEsAndEf(activitiesToCalculate) {
    try {
      activitiesToCalculate.forEach((activity) => {
        let activityReference = this.gantt.getTask(activity);
        if (!activityReference) return;
        if (activityReference.type === 'project') {
          this.calculations.set(activity, {
            es: activityReference.start_date,
            ef: activityReference.end_date
          });
          return;
        }
        if (!activityReference) {
          throw new Error(`Error trying to get activity ${activity}`);
        }
        let linksWithPredecessors = activityReference.$target;
        linksWithPredecessors = this.cleanAllActivityParentsFromLinks(
          linksWithPredecessors
        );

        let constraintType =
          activityReference.constraint_type || CONSTRAINT_TYPES.ASAP;
        let constraintThatNeedToBeRecalculated = [
          CONSTRAINT_TYPES.SNET,
          CONSTRAINT_TYPES.SNLT,
          CONSTRAINT_TYPES.FNLT,
          CONSTRAINT_TYPES.FNET
        ];
        let recalculation = [
          CONSTRAINT_TYPES.ASAP,
          CONSTRAINT_TYPES.ALAP,
          CONSTRAINT_TYPES.SNET,
          CONSTRAINT_TYPES.FNET,
          CONSTRAINT_TYPES.SNLT,
          CONSTRAINT_TYPES.FNLT
        ];

        const activityCalendar = this.gantt.getCalendar(
          activityReference.calendar_id
        );
        if (!activityCalendar) {
          throw new Error(
            `Error trying to get calendar with id: ${activityReference.calendar_id}`
          );
        }
        const progress = Number(activityReference.progress);
        let es = null;
        let ef = null;
        let calculationsFromLinks = null;

        if (progress === 100) {
          this.setDatesForActivityWithFullProgress(activityReference);
          return;
        }

        if (progress > 0 && recalculation.includes(constraintType)) {
          ({ es, ef } =
            this.getDatesForActivityWithZeroProgress(activityReference));
          calculationsFromLinks = new LinksConstraintCalculator({
            links: linksWithPredecessors,
            activity: activityReference,
            calculatedActivitiesMap: this.calculations,
            calculatedAlapsMap: this.alapMap,
            constraint: constraintType,
            ganttInstance: this.gantt
          }).calculate();

          if (constraintType === CONSTRAINT_TYPES.ASAP) {
            this.totalFloatForAsap.set(activity, {
              es: calculationsFromLinks.es,
              ef: calculationsFromLinks.ef,
              text: activityReference.text
            });
          }
        } else {
          ({ es, ef } = new LinksConstraintCalculator({
            links: linksWithPredecessors,
            activity: activityReference,
            calculatedActivitiesMap: this.calculations,
            calculatedAlapsMap: this.alapMap,
            constraint: constraintType,
            ganttInstance: this.gantt
          }).calculate());
        }

        if (constraintType === CONSTRAINT_TYPES.ALAP) {
          this.alapMap.set(activity, {
            es: activityReference.start_date,
            ef: activityReference.end_date,
            text: activityReference.text
          });
        }

        let restriction = calculateRestriction(
          activityReference,
          constraintType,
          activityCalendar
        );

        if (constraintThatNeedToBeRecalculated.includes(constraintType)) {
          this.constraintsMap.set(activity, restriction);
          this.totalFloatSnltFnlt.set(activity, { es, ef });
          ({ es, ef } = this.recalculateInBaseConstraint(
            constraintType,
            restriction,
            { es, ef }
          ));
        }

        if (
          [CONSTRAINT_TYPES.MSO, CONSTRAINT_TYPES.MFO].includes(constraintType)
        ) {
          this.totalFloatForMsoMfo.set(activity, { es, ef });
          this.constraintsMap.set(activity, restriction);
          es = restriction.es;
          ef = restriction.ef;
        }

        this.calculations.set(activity, {
          es,
          ef
        });
      });
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
   * Calculates the earliest start (ES) and earliest finish (EF) dates for activities directly linked to chain starts.
   *
   * This function processes activities that are directly linked to the chain start activities by calculating their ES and EF dates.
   * It utilizes the `calculateEsAndEf` method, which is inherited from the superclass, to perform the calculations.
   * The activities to be processed are stored in the `activitiesLinkedToChainStart` property.
   *
   * @example
   * // Assuming activitiesLinkedToChainStart is an array containing activity IDs [1, 2, 3]
   * calculateActivitiesDirectlyLinkedToChainStarts();
   * // This will calculate the ES and EF dates for the activities directly linked to chain starts
   * // and update the internal calculations map.
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  calculateActivitiesDirectlyLinkedToChainStarts() {
    this.calculateEsAndEf(this.activitiesLinkedToChainStart);
  }
  /**
   * Initializes the set of activities pending to be calculated.
   *
   * This function initializes the `activitiesPendingToBeCalculated` property by creating a set
   * of all activity IDs that have entries in the `mapOfRelationsWithActivitiesIds` map. This set
   * represents all activities that are pending calculation.
   *
   * @example
   * // Assuming mapOfRelationsWithActivitiesIds is a Map with the structure:
   * // Map {
   * //   1: [2],
   * //   2: [3],
   * //   3: [4]
   * // }
   * initActivitiesPendingToBeCalculated();
   * // This will set activitiesPendingToBeCalculated to a Set containing [1, 2, 3]
   *
   * @throws {Error} If an error occurs during the initialization process.
   */
  initActivitiesPendingToBeCalculated() {
    const allActivities = this.gantt.getTaskByTime();
    const deleteChainStartActivitiesFromAllActivities = allActivities
      .filter((activity) => activity.type !== 'project')
      .filter((activity) => activity['$rendered_parent'] !== '0')
      .filter((activity) => !this.chainStartActivities.includes(activity.id))
      .filter(
        (activity) => !this.activitiesLinkedToChainStart.has(activity.id)
      );
    deleteChainStartActivitiesFromAllActivities.forEach((activity) => {
      this.activitiesPendingToBeCalculated.add(activity.id);
    });
  }
  /**
   * Deletes activities linked to chain starts from the pending queue.
   */
  deleteActivitiesLinkedToChainStartsFromPendingQueue() {
    this.activitiesLinkedToChainStart.forEach((element) => {
      this.activitiesPendingToBeCalculated.delete(element);
    });
  }

  /**
   * Gets the greatest date between a restriction and a link calculation.
   * @param {Object} restriction - The restriction object.
   * @param {Object} linkCalculation - The link calculation object.
   * @param {string} [comparisonType='greater'] - The type of comparison ('greater' or 'less').
   * @returns {Object} - The object with the greatest date.
   */
  getTheGreatesDateBetweenRestrictionAndEsEf(
    restriction,
    linkCalculation,
    comparisonType = 'greater'
  ) {
    if (comparisonType === 'greater') {
      return linkCalculation.ef > restriction.ef
        ? linkCalculation
        : restriction;
    }

    if (comparisonType === 'less') {
      return linkCalculation.ef < restriction.ef
        ? linkCalculation
        : restriction;
    }
  }

  /**
   * Set ES and EF dates for an activity with full progress.
   * @param {Object} activity - The activity object.
   */
  setDatesForActivityWithFullProgress(activity) {
    this.calculations.set(activity.id, {
      es: activity.start_date,
      ef: activity.end_date
    });
  }

  /**
   * Calculates ES and EF dates for an activity with progress greater than zero.
   * @param {Object} activity - The activity object.
   * @returns {Object} - The calculated ES and EF dates.
   */

  getDatesForActivityWithZeroProgress(activity) {
    return {
      es: activity.start_date,
      ef: activity.end_date
    };
  }

  /**
   * Recalculates ES and EF dates based on a constraint.
   * @param {string} constraintType - The type of constraint.
   * @param {Object} calculatedRestriction - The calculated restriction object.
   * @param {Object} calculatedEsEf - The calculated ES and EF object.
   * @returns {Object} - The recalculated ES and EF dates.
   */
  recalculateInBaseConstraint(
    constraintType,
    calculatedRestriction,
    calculatedEsEf
  ) {
    let comparisontype = [
      CONSTRAINT_TYPES.SNET,
      CONSTRAINT_TYPES.FNET
    ].includes(constraintType)
      ? 'greater'
      : 'less';
    let { es, ef } = this.getTheGreatesDateBetweenRestrictionAndEsEf(
      calculatedRestriction,
      calculatedEsEf,
      comparisontype
    );

    return { es, ef };
  }

  cleanAllActivityParentsFromLinks(links) {
    return links
      .map((link) => {
        const ganttActivityLink = this.gantt.getLink(link);
        if (!ganttActivityLink) {
          throw new Error(`Error trying to get link id: ${link}`);
        }
        let sourceId = ganttActivityLink.source;
        let targetId = ganttActivityLink.target;
        if (
          !this.gantt.getTask(sourceId) ||
          this.gantt.getTask(sourceId)?.type === 'project'
        )
          return;
        if (
          !this.gantt.getTask(targetId) ||
          this.gantt.getTask(targetId)?.type === 'project'
        )
          return;

        return link;
      })
      .filter(Boolean);
  }
}

export default ForwardPath;
