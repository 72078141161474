function initializeTask(activity, sector) {
  if (activity.new_task) {
    activity.start_date = new Date(activity.start_date_backup);
    activity.new_task = false;

    const currentSector = sector;
    activity.duration = parseInt(currentSector.hoursPerDay);
    activity.start_date.setHours(0);
    activity.start_date.setMinutes(0);
  }
}

export { initializeTask };
