import React from 'react';
import { Popover } from 'antd';

const PopoverSystemV2 = ({
  theme = 'light',
  itemReference,
  children,
  ...props
}) => (
  <Popover
    {...props}
    ref={itemReference}
    overlayClassName={`popover-design-system-v2 theme--${theme}`}>
    {children}
  </Popover>
);

export default PopoverSystemV2;
