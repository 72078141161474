import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import TooltipSystem from '../../../../DesignSystem/TooltipSystem';
import { determineCurrentStatus } from './utils';
import './SubmittalsTab.scss';
import { trackingEvent } from '../../../../../analytics';
import { getBasicAmplitudEventProperties } from '../../../../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../../../../analytics/constants';
import { base } from '../../../../../services/base.js';

const FORMAT_DEFAULT = 'MM/DD/AA';

const SubmittalsTab = ({ cardData, t, procoreBaseUrl }) => {
  const { submittals } = useSelector((state) => state.submittalState);

  const [currentSubmittals, setCurrentSubmittals] = useState([]);

  const currentDate = new Date();
  const gantt = window.to_use_react_gantt;
  const dateFormat = gantt?.currentDateFormat || FORMAT_DEFAULT;
  const currentUser = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const relatedSubmittals = Object.values(submittals).filter(
      (submittal) =>
        submittal.activityId === cardData.originalActivityObject?.proplannerId
    );
    setCurrentSubmittals(relatedSubmittals);

    trackingEvent(
      'visualize_activity_submittals',
      {
        ...getBasicAmplitudEventProperties(),
        activity_id: cardData?.id,
        activity_correlative_id:
          cardData?.originalActivityObject?.correlative_id,
        activity_name: cardData?.originalActivityObject?.name,
        num_submittals: relatedSubmittals.length
      },
      AMPLITUDE_SERVICE
    );
  }, [submittals, cardData]);

  const getTitle = (title) =>
    title.length > 25 ? `${title.substring(0, 25)}...` : title;

  const submittalUrl = (submittal) =>
    `${procoreBaseUrl || base.procoreUrl}/${submittal.procoreProjectExternalId}/project/submittal_logs/${submittal.submittalId}`;

  const onSubmittalClick = (submittal) => {
    trackingEvent(
      'click_submittal_id',
      {
        ...getBasicAmplitudEventProperties(),
        submittal_id: submittal?.submittalId,
        activity_id: cardData?.id,
        user_id: currentUser?.id
      },
      AMPLITUDE_SERVICE
    );
  };

  return (
    <div className="container__submittals">
      <div className="submittals">
        <div className="submittals__header">
          <div className="header__content header__number">
            # <div className="header__line" />
          </div>
          <div className="header__content header__rev">
            {t('activity_card.submittals_rev')} <div className="header__line" />
          </div>
          <div className="header__content header__title">
            {t('activity_card.submittal_title')}{' '}
            <div className="header__line" />
          </div>
          <div className="header__content header__type">
            {t('activity_card.submittal_type')} <div className="header__line" />
          </div>
          <div className="header__content header__status">
            {t('activity_card.submittal_status')}{' '}
            <div className="header__line" />
          </div>
          <div className="header__content header__submittals-start-date">
            {t('activity_card.submittal_start_date')}{' '}
            <div className="header__line" style={{ top: '15px' }} />
          </div>
          <div className="header__content header__submittals-start-date-planned">
            {t('activity_card.submittal_start_date_planned')}{' '}
            <div className="header__line" />
          </div>
          <div className="header__content header__submittals-start-date-actual">
            {t('activity_card.submittal_start_date_actual')}
          </div>
          <div className="header__content header__submittals-approval-date">
            {t('activity_card.submittal_approval_date')}{' '}
            <div className="header__line" style={{ top: '15px' }} />
          </div>
          <div className="header__content header__submittals-approval-date-planned">
            {t('activity_card.submittal_approval_date_planned')}{' '}
            <div className="header__line" />
          </div>
          <div className="header__content header__submittals-approval-date-actual">
            {t('activity_card.submittal_approval_date_actual')}
          </div>
          <div className="header__content header__lead-time">
            {t('activity_card.submittal_lead_time')}{' '}
            <div className="header__line" />
          </div>
          <div className="header__content header__required-on-site-date">
            {t('activity_card.submittal_required_on_site_date')}
          </div>
        </div>
        <div
          className="submittals__body"
          style={{
            overflowY: currentSubmittals.lenght > 5 ? 'scroll' : 'auto'
          }}>
          {currentSubmittals.length === 0 ? (
            <>
              {[...new Array(5)].fill(
                <div className="body__item">
                  {[...new Array(11)].fill(<div />)}
                </div>
              )}
            </>
          ) : (
            currentSubmittals.map((submittal, key) => {
              const currentRev = submittal.rev.split('-');
              const currentStatus = determineCurrentStatus(
                submittal.status,
                new Date(submittal.startDatePlanned),
                new Date(submittal.approvalDatePlanned),
                new Date(submittal.approvalDateActual),
                currentDate
              );
              return (
                <div key={key} className="body__item">
                  <div>
                    <a
                      href={submittalUrl(submittal)}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        onSubmittalClick(submittal);
                      }}>
                      {currentRev[0]}
                    </a>
                  </div>
                  <div>{currentRev[1]}</div>
                  <div style={{ justifyContent: 'left' }}>
                    {TooltipSystem({
                      children: getTitle(submittal.title),
                      title: submittal.title
                    })}
                  </div>
                  <div>{submittal.type}</div>
                  <div style={{ backgroundColor: currentStatus.color }}>
                    {currentStatus.status}
                  </div>
                  <div>
                    {submittal.startDatePlanned &&
                      moment(submittal.startDatePlanned).format(
                        dateFormat.split(' ')[0]
                      )}
                  </div>
                  <div>
                    {submittal.startDateActual &&
                      moment(submittal.startDateActual).format(
                        dateFormat.split(' ')[0]
                      )}
                  </div>
                  <div>
                    {submittal.approvalDatePlanned &&
                      moment(submittal.approvalDatePlanned).format(
                        dateFormat.split(' ')[0]
                      )}
                  </div>
                  <div>
                    {submittal.approvalDateActual &&
                      moment(submittal.approvalDateActual).format(
                        dateFormat.split(' ')[0]
                      )}
                  </div>
                  <div>{submittal.leadTime}</div>
                  <div>
                    {submittal.requiredOnSiteDate &&
                      moment(submittal.requiredOnSiteDate).format(
                        dateFormat.split(' ')[0]
                      )}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default SubmittalsTab;
