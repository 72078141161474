import {
  transformHourToDays,
  transformDaysToHours
} from '../../../views/ganttContainer/gantt/gantt.helper';
import { store } from '../../../redux/store';
import { earlyAccessCriticalPath } from '../../../utils/earlyAccessCriticalPath';

const SubmittalStatusEnum = Object.freeze({
  DRAFT: 'Draft',
  OPEN: 'Open',
  CLOSED: 'Closed'
});

/**
 * An enumeration that maps submittal statuses to their corresponding properties.
 * This object is frozen to make it immutable, ensuring that the mappings
 * cannot be accidentally modified elsewhere in the code.
 *
 * @readonly
 * @enum {object}
 */
export const SubmittalStatusProperties = Object.freeze({
  'Not Started': {
    description: 'When Planned Start Date > today.',
    strokeColor: '#747474',
    fillColor: '#747474'
  },
  'In Progress': {
    description: 'When Planned Approval Date > today.',
    strokeColor: '#498E98',
    fillColor: '#498E98'
  },
  Delayed: {
    description:
      'When Planned Start Date <= today (status == draft) or Planned Approval Date <= today (status == open/draft).',
    strokeColor: '#E50101',
    fillColor: '#E50101'
  },
  OK: {
    description: 'When status == Closed.',
    strokeColor: '#2ECE3E',
    fillColor: '#2ECE3E'
  },
  Exceeded: {
    description: 'When actual approval date > planned approval date.',
    strokeColor: '#F26D0C',
    fillColor: '#F26D0C'
  }
});

/**
 * This function prints status information as overdue/advancement delays at their baseline
 * @param {*} gantt Instance where user is actually using the gantt lib (dhtmlx) which gives access to their API
 * @returns return plain text with html that render an rigth section element for each activity at chart with size of baseline data
 */
const baseLineTask = (gantt, task) => {
  const visibleBaseline = task.baseline_points?.find((base) => {
    if (base.sectorbaselineversion) {
      if (base.sectorbaselineversion.visible) {
        return true;
      }
    }
  });
  if (visibleBaseline) {
    const last_baseline = visibleBaseline;
    const gantt_date = gantt.date;
    const startBaseline = last_baseline.start_date;
    const endBaseline = last_baseline.end_date;
    const baseTask = {
      start_date: gantt_date.parseDate(startBaseline, 'xml_date'),
      end_date: gantt_date.parseDate(endBaseline, 'xml_date')
    };
    /** Hour integration */
    baseTask.duration = transformDaysToHours(last_baseline.duration);
    return baseTask;
  }
};

export const right_side_gantt =
  (gantt, show_baseline) => (start, end, task) => {
    const class_is_milestone = task.type === 'milestone' ? 'is_milestone' : '';
    const submittals = store.getState().submittalState?.submittals;
    const hasSubmittals = Object.values(submittals || {}).some(
      (s) =>
        s.activityId === task.proplannerId &&
        s.status !== SubmittalStatusEnum.CLOSED
    );
    const submittalStyle = hasSubmittals
      ? 'display: flex; align-items: center; width: 22px;'
      : 'display: inline-block; width: 0; height: 0;';

    const nameSpan = `
        <span class="submittal-icon-space-${task.proplannerId}" style="${submittalStyle}"></span>
        <span class="right-side-title-gantt ${class_is_milestone}">
            ${task.text}
        </span>
    `;

    if (!task.baselineObject) {
      task.baselineObject = baseLineTask(gantt, task);
    }
    let extraData = '';
    if (task.baselineObject && show_baseline) {
      const gantt_date = gantt.date;
      const base_end = task.baselineObject.end_date;
      const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
      let calendar;
      if (!task.calendar_id) {
        calendar = gantt.getCalendar(gantt.defaultCalendar);
      } else {
        calendar = gantt.getCalendar(task.calendar_id);
      }
      if (task.end_date > base_end.getTime()) {
        // const overdue = Math.ceil(Math.abs((end.getTime() - base_end.getTime()) / (24 * 60 * 60 * 1000)));
        const overdueTask = {
          start_date: base_end,
          end_date: task.end_date,
          calendar_id: task.calendar_id
        };
        const durationHour = calendar.calculateDuration(overdueTask);
        const duration_partial = durationHour / currentSector.hoursPerDay;
        const overdue = transformHourToDays(
          duration_partial * currentSector.hoursPerDay
        );

        if (duration_partial != 0) {
          extraData = `
                    <span class="overdue-extra-text-gantt">
                        (+${overdue} ${durationHour > parseInt(currentSector.hoursPerDay) ? 'days' : 'day'})
                    </span>
                `;
        }
      } else if (base_end.getTime() > task.end_date) {
        // const advancement = Math.ceil(Math.abs((end.getTime() - base_end.getTime()) / (24 * 60 * 60 * 1000)));
        const advancementTask = {
          start_date: task.end_date,
          end_date: base_end,
          calendar_id: task.calendar_id
        };
        const durationHour = calendar.calculateDuration(advancementTask);
        const duration_partial = durationHour / currentSector.hoursPerDay;
        const advancement = transformHourToDays(
          duration_partial * currentSector.hoursPerDay
        );
        if (duration_partial != 0) {
          extraData = `
                    <span class="advancement-extra-text-gantt">
                        (-${advancement} ${durationHour > parseInt(currentSector.hoursPerDay) ? 'days' : 'day'})
                    </span>
                `;
        }
      }
    }

    const getContainerStyles = (hasSubmittals) => {
      let styles = 'display: flex; align-items: center; gap: 2px;';
      if (hasSubmittals)
        styles += 'top: -10.5px !important; left: 0.5rem !important;';
      return styles;
    };

    /** class for pdf. we need to differentiate the master activities */
    const isParent = task.type === 'project';
    const isParentAndHasSubmittals = isParent && hasSubmittals;

    const classIsParent = isParent ? 'is_parent' : '';
    const containerStyle = getContainerStyles(hasSubmittals);
    const stylesParentSubmittals = isParentAndHasSubmittals
      ? 'top: -15.5px !important;'
      : '';

    return `
     <div class="right-new-gantt ${isParentAndHasSubmittals ? '' : classIsParent}" style="${containerStyle} ${stylesParentSubmittals}">
         ${nameSpan}
         ${extraData}
     </div>
 `;
  };

/**
 * This function prints baseline UI element for each activity rendered at gantt chart
 * @param {*} gantt Instance where user is actually using the gantt lib (dhtmlx) which gives access to their API
 * @returns return plain text with html that render an bottom section element for each activity at chart
 */
export const baseline_task_layer = (gantt) => (task) => {
  if (task.baseline_points) {
    const visibleBaseline = task.baseline_points.find((base) => {
      if (base.sectorbaselineversion) {
        if (base.sectorbaselineversion.visible) {
          return true;
        }
      }
    });
    const last_baseline = visibleBaseline;
    if (last_baseline) {
      let html_element = false;
      const baseTask = baseLineTask(gantt, task);
      task.baselineObject = baseTask;

      const sizes = gantt.getTaskPosition(
        task,
        baseTask.start_date,
        baseTask.end_date
      );
      html_element = document.createElement('div');
      html_element.className = 'baseline';
      html_element.style.left = sizes.left + 'px';
      html_element.style.width = sizes.width + 'px';
      html_element.style.top = sizes.top + gantt.config.task_height + -5 + 'px';

      return html_element;
    }
  }
};

/**
 * Returns the SVG string representation of the submittal icon.
 *
 * @param {string} strokeColor - The stroke color of the SVG.
 * @param {string} fillColor - The fill color of the SVG.
 *
 * @returns {string} - SVG representation.
 */
export function getSubmittalIconAsString(strokeColor, fillColor) {
  return `
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="10.0208" stroke="${strokeColor}" stroke-width="1.625"/>
            <g clip-path="url(#clip0_2844_21979)">
                <path d="M12.2231 9.48843L12.6282 7.25232V5.8912L11.7208 5H9.93837L9.04695 5.8912V7.26028L9.45203 9.47221H6.34116V13.9606V14.4135V14.8664V16.6667H15.318V14.8664V14.4135V13.9606V9.48843H12.2231ZM14.4268 15.7755H7.23233V14.883V14.4268H14.4268V15.7755ZM7.23233 13.9659V10.3796H9.60541L9.92222 12.1788H11.7208L12.0376 10.3796H14.4107V13.9659H7.23233Z" fill="${fillColor}"/>
            </g>
            <defs>
                <clipPath id="clip0_2844_21979">
                    <rect width="11.6667" height="11.6667" fill="white" transform="translate(5 5)"/>
                </clipPath>
            </defs>
        </svg>
    `;
}

/**
 * Determines the current status of a submittal.
 *
 * @param {string} status The current status.
 * @param {Date} startDatePlanned The planned start date.
 * @param {Date} approvalDatePlanned The planned approval date.
 * @param {Date} actualApprovalDate The actual approval date.
 * @param {Date} currentDate The current date.
 * @returns {string} The determined status.
 * @throws Will throw an error if the status is unknown.
 */
const determineCurrentStatus = (
  status,
  startDatePlanned,
  approvalDatePlanned,
  actualApprovalDate,
  currentDate
) => {
  const isNotStarted = startDatePlanned > currentDate;
  const isInProgress = approvalDatePlanned > currentDate;
  const isDelayed =
    startDatePlanned <= currentDate || approvalDatePlanned <= currentDate;
  const isOK = actualApprovalDate <= approvalDatePlanned;
  const isExceeded = actualApprovalDate > approvalDatePlanned;

  const statusMapping = Object.freeze({
    [SubmittalStatusEnum.DRAFT]: [
      [isNotStarted, 'Not Started'],
      [isDelayed, 'Delayed']
    ],
    [SubmittalStatusEnum.OPEN]: [
      [isInProgress, 'In Progress'],
      [isDelayed, 'Delayed']
    ],
    [SubmittalStatusEnum.CLOSED]: [
      [isOK, 'OK'],
      [isExceeded, 'Exceeded']
    ]
  });

  const mapping = statusMapping[status];
  if (!mapping) {
    throw new Error(`Unknown submittal status: ${status}`);
  }

  for (const [condition, mappedStatus] of mapping) {
    if (condition) return mappedStatus;
  }

  return null;
};

/**
 * Get the highest priority status among a list of submittals.
 *
 * @param {Array} submittals An array of submittal objects.
 * @returns {string} The highest priority status among the submittals.
 */
const getHighestPriorityStatus = (submittals) => {
  const statusPriority = [
    'Delayed',
    'Not Started',
    'In Progress',
    'Exceeded',
    'OK'
  ];
  const currentDate = new Date();

  return submittals.reduce((finalStatus, submittal) => {
    const currentStatus = determineCurrentStatus(
      submittal.status,
      new Date(submittal.startDatePlanned),
      new Date(submittal.approvalDatePlanned),
      new Date(submittal.approvalDateActual),
      currentDate
    );

    const currentStatusIndex = statusPriority.indexOf(currentStatus);
    const finalStatusIndex = statusPriority.indexOf(finalStatus);

    return !finalStatus || currentStatusIndex < finalStatusIndex
      ? currentStatus
      : finalStatus;
  }, '');
};

/**
 * Creates a submittal icon task layer based on submittals linked to a Gantt chart task.
 *
 * @param {object} gantt - The Gantt chart object.
 * @param {object} params - An object containing submittals.
 * @returns {function} A function to generate a task layer with icons for linked submittals.
 */
export const submittal_icon_task_layer = (gantt) => (task) => {
  const submittals = store.getState().submittalState?.submittals;
  const ICON_WIDTH = '20px';

  const linkedSubmittals = Object.values(submittals).filter(
    (s) =>
      s.activityId === task.proplannerId &&
      s.status !== SubmittalStatusEnum.CLOSED
  );
  if (!linkedSubmittals.length) return;

  const highestPriorityStatus = getHighestPriorityStatus(linkedSubmittals);
  const { strokeColor, fillColor } =
    SubmittalStatusProperties[highestPriorityStatus];
  const iconSVG = getSubmittalIconAsString(strokeColor, fillColor);

  // Remove existing icons related to the task
  const previousIcons = document.querySelectorAll(
    `.submittal-icon.icon-${task.proplannerId}`
  );
  previousIcons.forEach((icon) => icon.remove());

  const iconElement = document.createElement('span');
  iconElement.className = `submittal-icon icon-${task.proplannerId}`;
  iconElement.innerHTML = iconSVG;
  iconElement.style.width = ICON_WIDTH;
  iconElement.style.zIndex = '1';

  const reservedIconSpace = document.querySelector(
    `.submittal-icon-space-${task.proplannerId}`
  );
  if (reservedIconSpace) {
    reservedIconSpace.innerHTML = iconElement.outerHTML;
    return null;
  }

  return null;
};

/**
 * This section prints an grey bar which shows the slack data
 * @param {*} gantt Instance where user is actually using the gantt lib (dhtmlx) which gives access to their API
 * @returns return plain text with html that render an rigth section element for each activity at chart
 */
export const adding_slack_to_task_layer = (gantt) => (task) => {
  const state = gantt.getState().drag_mode;

  if (state == 'resize' || state == 'move') {
    return null;
  }

  let html_element = false;
  let slack = 0;

  if (earlyAccessCriticalPath()) {
    slack = transformDaysToHours(task.freeSlack);
  } else {
    slack = gantt.getFreeSlack(task);
    task.freeSlack = slack;
  }

  if (!slack) {
    return null;
  }

  const startDateSlack = new Date(task.end_date.toDateString());
  startDateSlack.setDate(startDateSlack.getDate() + 1);
  const slackStart = startDateSlack;
  const slackEnd = gantt.calculateEndDate({
    start_date: slackStart,
    duration: slack
  });
  const sizes = gantt.getTaskPosition(task, slackStart, slackEnd);
  html_element = document.createElement('div');

  html_element.className = 'slack';
  html_element.style.left = sizes.left + 'px';
  html_element.style.top = sizes.top + 2 + 'px';
  html_element.style.width = sizes.width + 'px';
  html_element.style.height = sizes.height + -5 + 'px';

  return html_element;
};
