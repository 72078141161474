/**
 * Returns a user's permission for a given product and module
 *
 * @param {string} permissionPath - A string in "product.module" format
 * @return {string} - The permission string (V, AC, SA)
 */
export const getPermission = (() => {
  let permissions;
  let previousStringifiedPermissions = '';

  return (permissionPath) => {
    if (!/^[a-z\d-_]+\.[a-z\d-_]+$/i.test(permissionPath)) {
      return;
    }

    const [product, module] = permissionPath.split('.');
    const stringifiedPermissions = sessionStorage.getItem('permissiontable');

    if (stringifiedPermissions !== previousStringifiedPermissions) {
      previousStringifiedPermissions = stringifiedPermissions;
      permissions = JSON.parse(stringifiedPermissions);
    }

    return permissions?.[product]?.find((perm) => perm.module === module)
      ?.permission;
  };
})();
