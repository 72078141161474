import React, { useRef, useEffect, useState } from 'react';
import { Card, Upload, Avatar } from 'antd';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import ImgCrop from 'antd-img-crop';
import { useDispatch } from 'react-redux';
/** import base contant (setting urls)  */
import { base } from '../../../../services/base';
/** import common functions from utils */
import {
  openNotification,
  getBase64,
  headersSendForm
} from '../../../../utils';
import * as projectActions from '../../../../redux/slices/projectSlice';
import { withTranslation } from 'react-i18next';
import './index.css';
import './cardProfile.scss';

import useConfigurationPermissions from '../../../../hooks/useConfigurationPermissions';

const CardStyle = styled.div`
  .ant-card-body {
    padding: 0px !important;
    zoom: 1;
  }
`;

// Permissions
const userConfigPermissions = useConfigurationPermissions();

function CardProfile(props) {
  const { t } = props;
  const dispatch = useDispatch();
  const stateProject = useSelector((state) => state.projectState);
  const { InfoUser, setInfoUser, stateUpload, setStateUpload } = props;
  const history = useHistory();
  /** component button */
  const uploadButton = (
    <div>
      {stateUpload.loading ? (
        <LoadingOutlined />
      ) : InfoUser && InfoUser.image ? (
        <Avatar size={100} src={`${InfoUser.image}`} />
      ) : (
        <Avatar
          className="FrmAvatar avatar-user-settings"
          size={100}
          icon={<UserOutlined />}
        />
      )}
    </div>
  );

  /** this function is triggered when upload image */
  const onChangeUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setStateUpload({
        loading: true
      });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response
      getBase64(info.file.originFileObj, (imageUrl) =>
        setStateUpload({
          imageUrl,
          name: info.file.response.name,
          loading: false
        })
      );
      setInfoUser(() => ({
        ...InfoUser,
        image: info.file.response.name
      }));
      dispatch(
        projectActions.setProps({
          ...stateProject.props,
          changeAvatar: true
        })
      );
    }
  };

  const handleGoTo = (goTo) => {
    if (goTo.includes('/project')) {
      if (!stateProject.projectSelected) {
        const alertErrorMailExists = {
          title: t('settings.settings'),
          description: t('settings.project_no_selected'),
          type: 'error'
        };
        openNotification(alertErrorMailExists);
        return false;
      }
    }
    props.history.push(goTo);
  };

  return (
    <CardStyle>
      <div style={{ marginTop: '140px', marginLeft: '20px' }}>
        <Card
          className="card-shawdow"
          style={{
            width: 230,
            padding: '0px',
            height: '185px',
            borderBottom: '0px solid'
          }}>
          <div>
            <ImgCrop rotate shape="round" modalWidth={850}>
              <Upload
                action={`${base.api}users/upload`}
                listType="picture-card"
                showUploadList={false}
                headers={headersSendForm}
                onChange={onChangeUpload}>
                {stateUpload.imageUrl ? (
                  <Avatar size={100} src={stateUpload.imageUrl} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
          </div>
          <p align="center" className="txt-profile-size-user">
            <b>{InfoUser ? InfoUser.name + ' ' + InfoUser.lastname : ''}</b>
          </p>
          <p align="center" className="txt-profile-size-role">
            {InfoUser ? InfoUser.position : ''}
          </p>
        </Card>
        <Card
          className="card-shawdow"
          size="default"
          style={{
            width: 230,
            borderTop: '1px solid #d1d1d1',
            padding: '0 0 16px 0'
          }}>
          <div
            className={
              'txt-profile-size ' +
              (history.location.pathname === '/settings/user' &&
                'bg-active-txt')
            }>
            <div onClick={() => handleGoTo('/settings/user')}>
              {t('settings.menu_profile')}
            </div>
          </div>

          {userConfigPermissions.project != 'SA' ? (
            <div
              className={
                'txt-profile-size ' +
                (history.location.pathname === '/settings/project' &&
                  'bg-active-txt')
              }>
              <div onClick={() => handleGoTo('/settings/project')}>
                {t('settings.menu_project')}
              </div>
            </div>
          ) : null}

          {userConfigPermissions.company != 'SA' ? (
            <div
              className={
                'txt-profile-size ' +
                (history.location.pathname === '/settings/company' &&
                  'bg-active-txt')
              }>
              <div onClick={() => handleGoTo('/settings/company')}>
                {t('settings.menu_organization')}
              </div>
            </div>
          ) : null}

          {userConfigPermissions.integrations == 'AC' ? (
            <div
              className={
                'txt-profile-size ' +
                (history.location.pathname === '/settings/plugins' &&
                  'bg-active-txt')
              }>
              <div onClick={() => handleGoTo('/settings/plugins')}>
                {t('settings.plugins_menu.title')}
              </div>
            </div>
          ) : null}
        </Card>
      </div>
      <div className="setting-project-version">
        {base.version ? `Outbuild v ${base.version}` : ''}
      </div>
    </CardStyle>
  );
}

export default withTranslation()(CardProfile);
