import React, { useEffect, useState } from 'react';
import {
  List,
  Avatar,
  Button,
  Skeleton,
  Table,
  Tag,
  Space,
  Input,
  Row,
  Col,
  Tooltip
} from 'antd';
import './index.css';
/** services */
import { sectorResourcesService } from '../../../../services/sectorresource.service';
import { useHistory } from 'react-router-dom';
/** Redux implementation */
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

function AssingTable(props) {
  const { t } = props;
  const data_ = props.activity_selected;
  const activitiesResourceSelected = props.activitiesResourceSelected;
  const [data, setData] = useState([]);
  const [dato, setDato] = useState('');
  const history = useHistory();
  const projectState = useSelector((state) => state.projectState);

  useEffect(() => {
    const prepare_data = [];
    if (data_) {
      data_.map((d) => {
        const row = {
          id: d.proplanner_id,
          ruta: 'option/opntion/jhgdsgfksjkfgsdhdfghdsjkf/dfsfsfdsf/dsfdsfdsfs',
          name: d.title,
          unidad: d.unit,
          cantidad: d.cantidad,
          update: d.update,
          id_see: d.id
        };
        prepare_data.push(row);
      });
      setData(prepare_data);
    }
  }, [data_]);
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Ruta',
      dataIndex: 'ruta',
      key: 'ruta'
    },
    {
      title: 'Nombre Actividad',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Unidad',
      dataIndex: 'unidad',
      key: 'unidad'
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
      render: (text, render) => (
        <Input
          type="text"
          value={render.cantidad}
          placeholder={
            props.t('lang') === 'es' ? 'Ingrese Cantidad' : 'Enter Quantity'
          }
          onChange={(e) => changeInput(e, render, e.target.value)}
          bordered={false}
        />
      )
    }
  ];

  const changeInput = (e, render, value) => {
    const new_data = [];
    const postition = findWithAttr(data, 'id', render.id);

    data[postition].cantidad = value;

    setDato(value);
    setData(data);
  };

  const onsaveAssing = async () => {
    let validate = false;
    data.map((d) => {
      if (d.cantidad === '') {
        validate = true;
      }
    });
    if (validate) {
      alert(
        t('lang') === 'es'
          ? 'Debes agregar todos los totales'
          : 'You must add all the totals'
      );
    } else {
      const data_push = [];
      const data_push_update = [];
      data.map((d) => {
        const assign = {
          sectorresourceId: props.resource_id,
          activityId: d.id,
          quantity: d.cantidad,
          sectorId: projectState.sectorSelected
        };
        if (!d.update) {
          data_push.push(assign);
        } else {
          data_push_update.push(assign);
        }
      });
      const rest = await sectorResourcesService.assingActivitys(
        data_push,
        data_push_update
      );
      if (rest) {
        // history.push("/gantt/2/resources");
        window.location.reload();
      }
    }
  };

  function findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  function renderItem(item) {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <Row>
          <Col span={2} className="text-list-assing" style={{ width: '110px' }}>
            {item.id_see}
          </Col>
          {/* <Col span={6} className="text-list-assing"  style={{width:"160px"}} >
                            <Tooltip placement='top' title={item.ruta}>
                                {item.ruta}
                            </Tooltip>
                        </Col> */}
          <Col
            span={13}
            className="text-list-assing"
            style={{ marginLeft: '10px', width: '300px' }}>
            {item.name}
          </Col>
          <Col span={2} className="text-list-assing" style={{ width: '60px' }}>
            {item.unidad}
          </Col>
          <Col span={5} className="text-list-assing" style={{ width: '150px' }}>
            <Input
              className="input-list-assing"
              type="number"
              value={item.cantidad}
              placeholder={
                props.t('lang') === 'es' ? 'Ingrese Cantidad' : 'Enter Quantity'
              }
              onChange={(e) => changeInput(e, item, e.target.value)}
              bordered={false}
            />
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div
      className="sector_list_container_assig"
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '725px'
      }}>
      <List
        style={{ height: '400px', width: '750px', overflow: 'auto' }}
        header={
          <div style={{ height: '100%', width: '100%' }}>
            <Row>
              <Col span={2} style={{ width: '110px' }}>
                {t('master_plan_resources.id')}
              </Col>
              {/* <Col span={5} style={{width:"160px"}}>
                        Ruta
                        </Col> */}
              <Col span={13} style={{ width: '320px' }}>
                {t('master_plan_resources.name')}
              </Col>
              <Col span={3} style={{ width: '60px' }}>
                {t('master_plan_resources.unit')}
              </Col>
              <Col span={5} style={{ width: '150px' }}>
                {t('master_plan_resources.quantity')}
              </Col>
            </Row>
          </div>
        }
        dataSource={data}
        renderItem={(item) => (
          <List.Item className="row-list-assing">{renderItem(item)}</List.Item>
        )}
      />
      <span className="buttonn-assing-content">
        <Button
          className="button-resource"
          type="primary"
          onClick={onsaveAssing}>
          {t('master_plan_resources.modal_asssig')}
        </Button>
      </span>
    </div>
  );
}

export default withTranslation()(AssingTable);
