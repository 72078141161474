import React, { useEffect, useState } from 'react';
import { connect, shallowEqual } from 'react-redux';
import { isEqual } from 'lodash';

import { DRAWER_ORIGINS } from '../../constants/drawerOrigins';
import { SubRightIcon } from '../../icons';
import { ModificationRequestsDrawer } from '../ModificationRequestsDrawer';
import { openDrawer } from '../../redux/slices/hoveringStackSlice';
import {
  getVisualizationConfigSelector,
  getSelectedActivitiesSelector
} from '../../redux/slices/ganttSlice';
import { ToolbarGroup } from '../ToolbarGroup';
import { trackingEvent } from '../../analytics/index';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';
import { updateDefaultView } from '../../services/views/viewSync';

import {
  TOOLBAR_RIGHT_GROUP_ITEMS,
  GANTT_LEVELS_CONFIG,
  ZOOM_LEVELS_CONFIG,
  TOGGLE_LINKS_VISIBILITY_CONFIG,
  TOGGLE_SLACK_VISIBILITY_CONFIG,
  TOGGLE_NUMTASKS_VISIBILITY_CONFIG,
  TOGGLE_BASELINE_VISIBILITY_CONFIG,
  TOGGLE_TODAYLINE_VISIBILITY_CONFIG,
  COLOR_SCHEME_TYPE_SUBMENU_ITEMS,
  UNDO_REDO_CONFIG,
  INDENT_OUTDENT_CONFIG,
  LINKS_CONFIG,
  SETTINGS_CONFIG_GANTT,
  MR_CONFIG_OBJECT,
  EXPORT_CONFIG_OBJECT,
  SCROLL_CONFIG,
  BTN_SAVE_CONFIG,
  ROW_COLORS_SCHEME_TYPE,
  TOGGLE_SUBMITTALS_VISIBILITY_CONFIG
} from './constants';

import styles from './GanttToolbar.module.scss';
import EventEmitter from 'react-native-eventemitter';
export const GanttToolbar = ({
  gantt,
  visualizationConfig,
  selectedActivities,
  ganttLevels,
  zoomLevel,
  onCommandDispatched,
  openDrawer,
  t
}) => {
  const [items, setItems] = useState(TOOLBAR_RIGHT_GROUP_ITEMS);
  const {
    areLinksVisible,
    colorSchemeType,
    colorRowSchemeType,
    areSlackVisible,
    areNumtasksVisible,
    areBaselineVisible,
    isTodaylineVisible,
    areSubmittalsVisible
  } = visualizationConfig;
  const rerender = () => setItems([...TOOLBAR_RIGHT_GROUP_ITEMS]);
  const [renderForced, setRenderForced] = useState(true);
  const [massiveVisible, setMassiveVisible] = useState(false);

  const handleCommandDispatched = (command, value) => {
    if (command === 'SHOW_MODIFICATION_REQUESTS_DRAWER') {
      openDrawer({
        component: ModificationRequestsDrawer,
        origin: DRAWER_ORIGINS.RIGHT
      });
    } else {
      onCommandDispatched(command, value);
      if (command === 'IMPORT_AND_DOWNLOAD') {
        let currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
        let currentProject = JSON.parse(
          sessionStorage.getItem('currentProject')
        );
        let currentCompany = JSON.parse(sessionStorage.getItem('company'));
        trackingEvent(
          'import/donwload_button_selection',
          {
            company_name: currentCompany?.name,
            company_id: currentCompany?.id,
            project_name: currentProject?.name,
            project_id: currentProject?.id,
            stage_name: currentSector?.name,
            stage_id: currentSector?.id
          },
          AMPLITUDE_SERVICE
        );
      }
    }
  };

  const updateRender = () => {
    setRenderForced(false);
    setTimeout(() => {
      setRenderForced(true);
    }, 1);
  };

  useEffect(() => {
    GANTT_LEVELS_CONFIG.subitems = ganttLevels?.map((level) => ({
      text: `${t('level')} ${level.toString()}`,
      command: `SET_DISPLAY_LEVEL_${level}`,
      icon: SubRightIcon
    }));
  }, [ganttLevels]);

  useEffect(() => {
    TOGGLE_LINKS_VISIBILITY_CONFIG.checked = areLinksVisible;
    TOGGLE_SLACK_VISIBILITY_CONFIG.checked = areSlackVisible;
    TOGGLE_NUMTASKS_VISIBILITY_CONFIG.checked = areNumtasksVisible;
    TOGGLE_BASELINE_VISIBILITY_CONFIG.checked = areBaselineVisible;
    TOGGLE_TODAYLINE_VISIBILITY_CONFIG.checked = isTodaylineVisible;
    TOGGLE_SUBMITTALS_VISIBILITY_CONFIG.checked = areSubmittalsVisible;
    updateDefaultView();
  }, [
    areLinksVisible,
    areSlackVisible,
    areNumtasksVisible,
    areBaselineVisible,
    isTodaylineVisible,
    areSubmittalsVisible
  ]);

  useEffect(() => {
    COLOR_SCHEME_TYPE_SUBMENU_ITEMS.forEach((subitem) => {
      subitem.checked =
        subitem.command ===
        `CHANGE_COLOR_FOR_${colorSchemeType?.toUpperCase()}`;
    });
    updateRender();
    updateDefaultView();
  }, [colorSchemeType]);

  useEffect(() => {
    ROW_COLORS_SCHEME_TYPE.forEach((subitem) => {
      subitem.checked =
        subitem.command ===
        `CHANGE_COLOR_ROW_FOR_${colorRowSchemeType?.toUpperCase()}`;
    });
    updateRender();
    // This event is just TEMPORAL, due to only option to colorize row is WBS
    // as soon as other option is created this event must be replaced to let component
    // work lite it should (ToolbarGroup and ToolbarItem)
    if (!gantt) return;
    gantt.attachEvent('updateCheckedWBS', () => {
      const wbsOption = ROW_COLORS_SCHEME_TYPE.find(
        (type) => type.command === 'CHANGE_COLOR_ROW_FOR_WBS'
      );
      if (!wbsOption) return;
      wbsOption.checked = gantt.isActiveColorWBS;
      updateRender();
    });
  }, [colorRowSchemeType, gantt]);

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      const zoomLevel = data.value;
      if (!zoomLevel) {
        return;
      }
      ZOOM_LEVELS_CONFIG.defaultValue = zoomLevel;
      updateRender();
    };

    callback({
      value: zoomLevel
    });
    EventEmitter.on('changeScaleVisualization', callback);

    return () => {
      EventEmitter.removeListener('changeScaleVisualization', callback);
    };
  }, []);

  useEffect(() => {
    let newItems = [];
    newItems = [
      SCROLL_CONFIG,
      ZOOM_LEVELS_CONFIG,
      UNDO_REDO_CONFIG,
      INDENT_OUTDENT_CONFIG,
      LINKS_CONFIG,
      SETTINGS_CONFIG_GANTT,
      EXPORT_CONFIG_OBJECT,
      MR_CONFIG_OBJECT,
      BTN_SAVE_CONFIG
    ];
    /** update state only if there is change in the data */
    if (newItems.length !== items.length) {
      setItems(newItems);
    }
  }, [selectedActivities]);

  return (
    <div className={styles['gantt-toolbar']}>
      {renderForced ? (
        <ToolbarGroup
          items={items}
          onCommandDispatched={handleCommandDispatched}
          t={t}
          massiveVisible={massiveVisible}
          selectedActivities={selectedActivities}
          visualizationConfig={visualizationConfig}
        />
      ) : null}
    </div>
  );
};

const actionCreators = {
  openDrawer
};

const mapStateToProps = (state) => ({
  visualizationConfig: getVisualizationConfigSelector(state),
  selectedActivities: getSelectedActivitiesSelector(state)
});

function arePropsEqual(prevProps, nextProps) {
  const shallowEqualProps = shallowEqual(prevProps, nextProps);
  if (!shallowEqualProps) {
    const prevSelectedActivities = prevProps.selectedActivities;
    const nextSelectedActivities = nextProps.selectedActivities;
    const shouldReRender =
      prevProps.gantt === nextProps.gantt &&
      prevProps.visualizationConfig === nextProps.visualizationConfig &&
      prevProps.ganttLevels === nextProps.ganttLevels &&
      prevProps.zoomLevel === nextProps.zoomLevel &&
      prevProps.openDrawer === nextProps.openDrawer &&
      prevProps.t === nextProps.t &&
      (prevSelectedActivities === nextSelectedActivities ||
        (prevSelectedActivities.length === 0 &&
          nextSelectedActivities.length === 0)) &&
      deepEqualFunctions(
        prevProps.onCommandDispatched,
        nextProps.onCommandDispatched
      );

    return shouldReRender;
  }

  return true;
}

function deepEqualFunctions(prevFunc, nextFunc) {
  if (prevFunc !== nextFunc) {
    if (!isEqual(prevFunc.toString(), nextFunc.toString())) {
      return false;
    }
  }

  return true;
}

export default connect(
  mapStateToProps,
  actionCreators
)(React.memo(GanttToolbar, arePropsEqual));
