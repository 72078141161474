import React, { useState, memo, useEffect } from 'react';
import PopoverMultiselectSystem from '../../../../../DesignSystem/PopoverMultiselectSystem';
import { ArrowDropdownIcon } from '../../../../../../icons';
import Colors from '../../../../../../assets/styles/variables.scss';

const COUNT_ITEMS_SHOW_DISPLAY = 5;

const Projects = memo(
  ({ t, data, isValidRole, dataProjects, handleItemChange }) => {
    const { BLACK } = Colors;

    const [currentDataProjects, setCurrentDataProjects] =
      useState(dataProjects);
    const [currentProjectsChecked, setCurrentProjectsChecked] = useState([]);

    useEffect(() => {
      const projectsChecked = dataProjects.map((currentDataProjects) => {
        return {
          ...currentDataProjects,
          checked: data.projects.find(
            (project) => project.id === currentDataProjects.key
          )
        };
      });

      setCurrentDataProjects(projectsChecked);
      setCurrentProjectsChecked(
        projectsChecked.filter((project) => project.checked)
      );
    }, [dataProjects]);

    const handleCheckedItem = async (items) => {
      const item = getItemChange(currentProjectsChecked, items);
      if (!item) return;

      handleItemChange(item, data.id);
      setCurrentProjectsChecked(items);
    };

    const getItemChange = (previousItems, newItems) => {
      if (newItems.length > previousItems.length) {
        const addedItem = newItems.find(
          (item) => !previousItems.some((prevItem) => prevItem.key === item.key)
        );

        return { addItem: true, item: addedItem };
      }

      if (newItems.length < previousItems.length) {
        const removedItem = previousItems.find(
          (item) => !newItems.some((newItem) => newItem.key === item.key)
        );

        return { addItem: false, item: removedItem };
      }
    };

    const getLabel = () => (
      <span>
        {currentProjectsChecked.length === 0
          ? t(
              'settings.organization_settings.users_tab.table_projects_associated_no_projects'
            )
          : `${currentProjectsChecked.length} ${t('settings.organization_settings.users_tab.table_projects_associated_count_projects')}`}
      </span>
    );

    if (!isValidRole) {
      return <div className="projects-column">{getLabel()}</div>;
    }

    return (
      <PopoverMultiselectSystem
        countItemsShow={COUNT_ITEMS_SHOW_DISPLAY}
        trigger="click"
        placement="bottom"
        withSelectAll={false}
        items={currentDataProjects}
        onItemsCheckedFunction={(items) => handleCheckedItem(items)}>
        <div className="projects-column-popover">
          {getLabel()}
          <div className="projects-column-popover__icon">
            <ArrowDropdownIcon color={BLACK} />
          </div>
        </div>
      </PopoverMultiselectSystem>
    );
  }
);

export default Projects;
