import {
  findDeepGetTask,
  isGrouped,
  sanitizeString,
  updateAsyncTaskGanttV2
} from '../../GanttVisualization.helper';
import {
  splitTextDescriptionHTML,
  replacePlainTextToLinksPattern,
  replacePlainTextToLinksTemplate
} from '../../../../utils/lookahead-common';

const RenderDescription = ({
  task,
  column,
  activities,
  gantt,
  t,
  onlyRead
}) => {
  if (isGrouped()) {
    if (task.type === undefined) {
      return '';
    }
  }
  const isOnlyReadElement = onlyRead || task.readonly;

  /** Check if data is defined. Activity don't have this data */
  if (task.is_milestone) return '';
  if (!task.isTask) return '';
  if (!gantt) return '';

  /** get reference task (taskFromLookahead) from activities */
  const taskFromLookahead = findDeepGetTask(activities, 'id', task.id);
  if (!taskFromLookahead) return '';

  let isOdd = false;
  if (gantt?.oddColsConfig && task.type === 'activitytask') {
    isOdd = gantt.oddColsConfig.description;
  }

  const onSaveDescription = (value) => {
    const description = value;
    updateAsyncTaskGanttV2(taskFromLookahead, column.name, description, gantt);
  };

  task.onSaveDescription = onSaveDescription;

  return `<div class=${isOdd ? 'odd-col' : 'non-odd-col'}>
            <span class="vertical-center">
                ${
                  isOnlyReadElement
                    ? `<div>
                            ${splitTextDescriptionHTML(task, column, t)}
                        </div>`
                    : `<span>
                          ${
                            task[column.name]
                              ? sanitizeString(task[column.name]).replace(
                                  replacePlainTextToLinksPattern,
                                  replacePlainTextToLinksTemplate
                                )
                              : ''
                          }
                        </span>
                        <div style="cursor: pointer;">
                            ${splitTextDescriptionHTML(task, column, t)}
                        </div>`
                }
            </span>
        </div>`;
};

export default RenderDescription;
