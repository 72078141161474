/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
class TaskCommitmentService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(weekCommitmentId) {
    const res = await this.http
      .get('taskcommitments/' + weekCommitmentId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(weekCommitmentId) {
    const res = await this.http
      .delete('taskcommitments/' + weekCommitmentId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http
      .post('taskcommitments/', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('taskcommitments/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const taskCommitmentService = new TaskCommitmentService();
