import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { userService, authService } from '../../services';
import { STEPS } from './constants';
import ThirdScreenA from './caseA/thirdscreen';
import QuarterScreenA from './caseA/quarterscreen';
import ThirdScreenB from './caseB/thirdscreen';
import ExpiredScreen from './expiredscreen';
import { trackingEvent } from '../../analytics';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';

const SignUpConfirmationView = (props) => {
  const { token } = useParams();

  const [currentStep, setCurrentStep] = useState(0);
  const [dataForm, setDataForm] = useState();

  useEffect(() => {
    getUserByTokenFreeTrial();
  }, []);

  const getUserByTokenFreeTrial = async () => {
    try {
      const responseUser = await userService.getByTokenFreeTrial(token);
      if (responseUser === false) return props.history.push('/login');

      const {
        user: { id, email, flow_free_trial_closed, token_free_trial },
        company
      } = responseUser;
      if (flow_free_trial_closed) return props.history.push('/login');

      const responseToken = await authService.isTokenExpired({
        token: token_free_trial
      });

      if (
        responseToken.status === 404 &&
        responseToken.data.msj.name === 'TokenExpiredError'
      ) {
        trackingEvent(
          'expired_token',
          {
            email: email
          },
          AMPLITUDE_SERVICE
        );

        setCurrentStep(STEPS.EXPIRED_SCREEN);

        return;
      }

      if (responseToken.status === 200) {
        const { data } = responseToken;

        if (data.validationToken.isRejected) {
          trackingEvent(
            'expired_token',
            {
              email: email
            },
            AMPLITUDE_SERVICE
          );

          setCurrentStep(STEPS.EXPIRED_SCREEN);

          return;
        }

        setDataForm({
          user: {
            id: id,
            email: email
          },
          company: null
        });

        if (!company) {
          setCurrentStep(STEPS.THIRD_CASE_B_SCREEN);
          return;
        }

        setDataForm({
          user: {
            id: id,
            email: email
          },
          company: {
            id: company.id,
            name: company.name,
            image: company.image
          }
        });

        setCurrentStep(STEPS.THIRD_CASE_A_SCREEN);
      }
    } catch (err) {
      console.log(
        `Error in SignUpConfirmation - getUserByTokenFreeTrial(): ${err}`
      );
    }
  };

  const getCurrentStep = (step = 0) => {
    const steps = {
      [STEPS.THIRD_CASE_A_SCREEN]: (
        <ThirdScreenA
          {...props}
          setCurrentStep={setCurrentStep}
          dataForm={dataForm}
        />
      ),
      [STEPS.QUARTER_CASE_A_SCREEN]: (
        <QuarterScreenA {...props} userData={dataForm} />
      ),
      [STEPS.THIRD_CASE_B_SCREEN]: (
        <ThirdScreenB {...props} userData={dataForm} />
      ),
      [STEPS.EXPIRED_SCREEN]: <ExpiredScreen {...props} />
    };

    return steps[step];
  };

  return <>{getCurrentStep(currentStep)}</>;
};

export default withTranslation()(SignUpConfirmationView);
