/**
 * This function creates initial neccessary flags for the ProPlanner custom version of gantt dhtmlx
 * @param {*} gantt Instance where user is actually using the gantt lib (dhtmlx) which gives access to their API
 * @returns TRUE to keep normal gantt lifecycle
 */
export const initial_task_load_flow = (gantt) => (task) => {
  task.old_type = task.type;
  task.old_duration = task.duration;

  task.on_open_lightbox_type = task.type;
  task.for_disable_milestone_duration = task.duration;
  return true;
};
