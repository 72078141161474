import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Tooltip } from 'antd';
import cn from 'classnames';
import i18n from '../../../../../i18n';
import MaterialRealColumn from '../MaterialReal/MaterialReal';
import ProgressColumn from '../ProgressColumn';
import { formatMoney } from '../../../../../utils/lookahead-common';
import childArrow from '../../../../../assets/img/child-arrow.png';

import styles from './ActivityTask.module.css';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { getCompanyTextColor } from '../../../../../utils';

const ActivityTasks = (props) => {
  const {
    activity,
    tasks,
    isProgress,
    isChangeProgress,
    setIsChangeProgress,
    resources,
    onlyRead,
    subtradeRole
  } = props;

  const { width: windowWidth } = useWindowDimensions();

  const marginLeft = useRef(0);

  return (
    <>
      {tasks.map((task) => {
        task.children.length && (marginLeft.current += 8);
        return (
          <>
            <RecursiveTask
              onlyRead={onlyRead}
              key={task.id}
              activity={activity}
              task={task}
              isProgress={isProgress}
              isChangeProgress={isChangeProgress}
              setIsChangeProgress={setIsChangeProgress}
              resources={resources}
              windowWidth={windowWidth}
              subtradeRole={subtradeRole}
            />
            {task.children.length > 0 &&
              (marginLeft.current += 8) &&
              task.children.map((taskChild) => {
                marginLeft.current = 0;
                task.children.length && (marginLeft.current += 8);
                return (
                  <>
                    <RecursiveTask
                      onlyRead={onlyRead}
                      isChild
                      key={taskChild.id}
                      activity={activity}
                      marginLeft={marginLeft.current}
                      task={taskChild}
                      isProgress={isProgress}
                      isChangeProgress={isChangeProgress}
                      setIsChangeProgress={setIsChangeProgress}
                      resources={resources}
                      parentTask={task}
                      windowWidth={windowWidth}
                      subtradeRole={subtradeRole}
                    />
                    {taskChild.children.length > 0 &&
                      (marginLeft.current += 8) &&
                      taskChild.children.map((taskChild2) => {
                        return (
                          <>
                            <RecursiveTask
                              onlyRead={onlyRead}
                              isChild
                              key={taskChild2.id}
                              activity={activity}
                              marginLeft={marginLeft.current}
                              task={taskChild2}
                              isProgress={isProgress}
                              isChangeProgress={isChangeProgress}
                              setIsChangeProgress={setIsChangeProgress}
                              resources={resources}
                              parentTask={taskChild}
                              windowWidth={windowWidth}
                              subtradeRole={subtradeRole}
                            />
                            {taskChild2.children.length > 0 &&
                              (marginLeft.current += 8) &&
                              taskChild2.children.map((taskChild3) => {
                                return (
                                  <>
                                    <RecursiveTask
                                      onlyRead={onlyRead}
                                      isChild
                                      key={taskChild3.id}
                                      activity={activity}
                                      marginLeft={marginLeft.current}
                                      task={taskChild3}
                                      isProgress={isProgress}
                                      isChangeProgress={isChangeProgress}
                                      setIsChangeProgress={setIsChangeProgress}
                                      resources={resources}
                                      parentTask={taskChild2}
                                      windowWidth={windowWidth}
                                      subtradeRole={subtradeRole}
                                    />
                                    {taskChild3.children.length > 0 &&
                                      (marginLeft.current += 8) &&
                                      taskChild3.children.map((taskChild4) => {
                                        return (
                                          <>
                                            <RecursiveTask
                                              onlyRead={onlyRead}
                                              isChild
                                              key={taskChild4.id}
                                              activity={activity}
                                              marginLeft={marginLeft.current}
                                              task={taskChild4}
                                              isProgress={isProgress}
                                              isChangeProgress={
                                                isChangeProgress
                                              }
                                              setIsChangeProgress={
                                                setIsChangeProgress
                                              }
                                              resources={resources}
                                              parentTask={taskChild3}
                                              windowWidth={windowWidth}
                                              subtradeRole={subtradeRole}
                                            />
                                            {taskChild4.children.length > 0 &&
                                              (marginLeft.current += 8) &&
                                              taskChild4.children.map(
                                                (taskChild5) => {
                                                  return (
                                                    <>
                                                      <RecursiveTask
                                                        onlyRead={onlyRead}
                                                        isChild
                                                        key={taskChild5.id}
                                                        activity={activity}
                                                        marginLeft={
                                                          marginLeft.current
                                                        }
                                                        task={taskChild5}
                                                        isProgress={isProgress}
                                                        isChangeProgress={
                                                          isChangeProgress
                                                        }
                                                        setIsChangeProgress={
                                                          setIsChangeProgress
                                                        }
                                                        resources={resources}
                                                        parentTask={taskChild4}
                                                        windowWidth={
                                                          windowWidth
                                                        }
                                                        subtradeRole={
                                                          subtradeRole
                                                        }
                                                      />
                                                      {taskChild5.children
                                                        .length > 0 &&
                                                        (marginLeft.current += 8) &&
                                                        taskChild5.children.map(
                                                          (taskChild6) => {
                                                            return (
                                                              <>
                                                                <RecursiveTask
                                                                  onlyRead={
                                                                    onlyRead
                                                                  }
                                                                  isChild
                                                                  key={
                                                                    taskChild6.id
                                                                  }
                                                                  activity={
                                                                    activity
                                                                  }
                                                                  marginLeft={
                                                                    marginLeft.current
                                                                  }
                                                                  task={
                                                                    taskChild6
                                                                  }
                                                                  isProgress={
                                                                    isProgress
                                                                  }
                                                                  isChangeProgress={
                                                                    isChangeProgress
                                                                  }
                                                                  setIsChangeProgress={
                                                                    setIsChangeProgress
                                                                  }
                                                                  resources={
                                                                    resources
                                                                  }
                                                                  parentTask={
                                                                    taskChild5
                                                                  }
                                                                  windowWidth={
                                                                    windowWidth
                                                                  }
                                                                  subtradeRole={
                                                                    subtradeRole
                                                                  }
                                                                />
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                    </>
                                                  );
                                                }
                                              )}
                                          </>
                                        );
                                      })}
                                  </>
                                );
                              })}
                          </>
                        );
                      })}
                  </>
                );
              })}
          </>
        );
      })}
    </>
  );
};

const RecursiveTask = (props) => {
  const {
    activity,
    task,
    isChild,
    marginLeft = 0,
    windowWidth,
    isProgress,
    isChangeProgress,
    setIsChangeProgress,
    resources,
    parentTask = false,
    onlyRead
  } = props;

  const { current: maxUserShows } = useRef(3);

  if (task.taskcommitments.length <= 0) return null;

  const isFloat = (value) => value % 1 != 0;

  const splitText = (text = '', textWidth) => {
    let tooltip;
    let showedText = text;
    let colAdjustWidth;
    const textAdjustWidth = showedText ? showedText.length * 2 : 100;

    if (windowWidth >= 600 && windowWidth < 1100) {
      colAdjustWidth = Math.trunc(textWidth / 8);
    } else if (windowWidth >= 1100 && windowWidth <= 1500) {
      colAdjustWidth = Math.trunc(textWidth / 3.2);
    } else {
      colAdjustWidth = Math.trunc(textWidth / 3);
    }

    if (textAdjustWidth > colAdjustWidth) {
      showedText = text.slice(0, Math.trunc(colAdjustWidth / 2)) + '...';
      tooltip = true;
    }
    return tooltip ? (
      <Tooltip placement="top" title={text}>
        {showedText}
      </Tooltip>
    ) : (
      showedText
    );
  };

  return (
    <div
      className={cn(styles.row, {
        [styles['parent-task-color']]: task.children.length
      })}>
      <span className={styles['activity-name']}>
        {/* <div>
                    <Checkbox />
                </div> */}
        <em style={{ marginLeft }} className={styles.taskName}>
          {isChild && (
            <img
              src={childArrow}
              alt="arrow child"
              width={12}
              style={{ marginRight: 8 }}
            />
          )}
          {splitText(task.name, marginLeft ? 160 + marginLeft : 190)}
        </em>
      </span>
      <span>
        {task.responsables.map((responsable, idx) => (
          <>
            {idx < maxUserShows && (
              <div
                className={cn(styles.responsable, {
                  [styles['no-img']]: responsable.image
                })}>
                <Tooltip title={`${responsable.name} ${responsable.lastname}`}>
                  {responsable.image ? (
                    <img src={responsable.image} alt={`${responsable.name}`} />
                  ) : (
                    <div>
                      {responsable.name ? responsable.name[0] : ''}
                      {responsable.lastname ? responsable.lastname[0] : ''}
                    </div>
                  )}
                </Tooltip>
              </div>
            )}
          </>
        ))}
        {task.responsables.length > maxUserShows && (
          <em className={styles.total__users}>
            {' '}
            + {task.responsables.length - maxUserShows}
          </em>
        )}
      </span>
      <span
        className={cn({ [styles['border-subcontract']]: task.subcontract })}
        style={{
          background: `${task.subcontract?.color}`,
          color: `${getCompanyTextColor(task.subcontract?.color)}`,
          fontWeight: 400,
          borderBottom: 'border-right: .6px solid #EFEFEF'
        }}>
        {task.subcontract?.name ? splitText(task.subcontract?.name, 120) : null}
      </span>
      <span>
        {isProgress ? (
          <CommitmentColumn task={task} isFloat={isFloat} />
        ) : (
          <MaterialColumn
            task={task}
            resources={resources}
            splitText={splitText}
          />
        )}
      </span>
      <span>
        {/* <span className={cn({ [styles.column__commited]: !isProgress })}> */}
        {isProgress ? (
          task.taskcommitments[0].weekcommitment.closed ? (
            (() => {
              let number = task.taskcommitments[0].realized_percentaje;
              return Number.isInteger(number) ? number : number.toFixed(2);
            })()
          ) : (
            <ProgressColumn
              onlyRead={onlyRead}
              activity={activity}
              task={task}
              parentTask={parentTask}
              isChangeProgress={isChangeProgress}
              setIsChangeProgress={setIsChangeProgress}
            />
          )
        ) : (
          <MaterialRealColumn
            activity={activity}
            task={task}
            isFloat={isFloat}
            resources={resources}
            parentTask={parentTask}
          />
        )}
      </span>
      <span>
        {i18n.t('lang') === 'en'
          ? formatMoney(parseFloat(task.plan_endowment), '', 0, '.', ',')
          : formatMoney(parseFloat(task.plan_endowment), '', 0, ',', '.')}
      </span>
    </div>
  );
};

const CommitmentColumn = ({ task, isFloat }) => (
  <>
    {isFloat(task.taskcommitments[0].commitment_percentaje)
      ? `${task.taskcommitments[0].commitment_percentaje.toFixed(2)}%`
      : `${task.taskcommitments[0].commitment_percentaje}%`}
  </>
);

const MaterialColumn = ({ task, resources, splitText }) => (
  <>
    {task.materialId &&
      splitText(
        resources.find((resource) => resource.id == task.materialId)?.name,
        40
      )}
  </>
);

export default ActivityTasks;
