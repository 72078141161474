/* eslint-disable no-case-declarations */
import {
  SET_CALENDARS_BY_SECTOR,
  SET_CALENDAR_FORM,
  SELECT_CALENDAR
} from '../actions/calendarActions';

/**
 * Reducer function means a switch case to filter action data on the platform for general state
 * @param {*} state Real time updated through all components state (general state flux)
 * @param {*} action Action is an object with structyure { type: STRING, payload: FUNCTION }
 */
function calendarReducer(
  state = { calendarsBySector: [], calendarSelected: null, calendarForm: null },
  action
) {
  switch (action.type) {
    case SELECT_CALENDAR:
      return {
        ...state,
        calendarSelected: action.payload
      };
    case SET_CALENDARS_BY_SECTOR:
      return {
        ...state,
        calendarsBySector: action.payload
      };
    case SET_CALENDAR_FORM:
      return {
        ...state,
        calendarForm: action.payload
      };
    default:
      return state;
  }
}

export default calendarReducer;
