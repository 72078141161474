export const COPY_CONTENT = 'COPY_CONTENT';

export const copyPasteActions = {
  copyContent: (value, key) => {
    const masterplanTasks = key === 'masterplan' ? value : {};
    const lookaheadTasks = key === 'lookahead' ? value : {};

    return {
      type: COPY_CONTENT,
      payload: {
        masterplan: { selectedTasks: masterplanTasks },
        lookahead: { selectedTasks: lookaheadTasks }
      }
    };
  }
};
