import { useEffect, useRef } from 'react';
import _ from 'lodash';

/**
 * Custom React hook to get the previous value of a variable.
 *
 * @param {any} value - The current value of the variable.
 * @returns {any} The previous value of the variable.
 */
function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

/**
 * Custom React hook that runs an effect when the dependencies deeply change.
 *
 * @param {Function} callback - The callback function to run when the dependencies change.
 * @param {Array} dependencies - An array of dependencies to watch for changes.
 *
 * @example
 * useDeepCompareEffect(() => {
 *   console.log("Dependencies changed.");
 * }, [complexObject, nestedArray]);
 */
function useDeepCompareEffect(callback, dependencies) {
  const previousDependencies = usePrevious(dependencies);

  useEffect(() => {
    if (!_.isEqual(previousDependencies, dependencies)) {
      return callback();
    }
  }, [dependencies, previousDependencies, callback]);
}

export { useDeepCompareEffect };
