import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../../redux/slices/projectSlice';
import { sectorService, userService } from '../../../services';
import ModalSystem from '../../DesignSystem/ModalSystem';
import { EditStageIcon } from '../../../icons';
import { Input } from 'antd';
import { EditStageComp } from './icons';
import {
  getTypeNotification,
  notifyMessageCustom,
  dynamicSort
} from '../../../utils';

const RenameStage = ({ show, setShow, t }) => {
  const dispatch = useDispatch();

  const {
    projectState: { sectorSelectedRename, allSectors }
  } = useSelector((state) => state);

  const [nameStage, setNameStage] = useState(sectorSelectedRename?.name);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  useEffect(() => {
    setNameStage(sectorSelectedRename?.name);
  }, [sectorSelectedRename]);

  const handleOnChange = (event) => {
    setNameStage(event.target.value);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  const handleSaveModal = async () => {
    if (disabledButton()) return;

    setIsLoadingButton(true);

    try {
      await sectorService.updateFieldsBySectorId(sectorSelectedRename?.id, [
        {
          field: 'name',
          value: nameStage
        }
      ]);

      const newAllSectors = [...allSectors];
      const currentSectorId = newAllSectors.findIndex(
        (sector) => sector.id === sectorSelectedRename?.id
      );

      if (currentSectorId > -1) {
        newAllSectors[currentSectorId].name = nameStage;
        dispatch(projectActions.setAllSectors(newAllSectors));

        setShow(false);
        setIsLoadingButton(false);

        notifyMessageCustom({
          type: getTypeNotification(EditStageComp),
          description: t('notifications.stage_editing_success')
        });

        const currentUser = JSON.parse(localStorage.getItem('user'));
        const getProjects = await userService.projectsbyuserthrough(
          currentUser?.id
        );

        if (!getProjects) return;

        const projectsActive = getProjects.projects.filter(
          (project) => project.stage !== 'deleted'
        );
        const sortProjects = projectsActive.sort(dynamicSort('name'));
        dispatch(projectActions.setAllProjects(sortProjects));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const disabledButton = () =>
    nameStage === '' || nameStage?.trim() === '' || isLoadingButton;

  const contentChildren = () => (
    <div className="rename-stage__content">
      <div className="content__form">
        <label>{t('rename_stage.input_label')}</label>
        <Input type="text" value={nameStage} onChange={handleOnChange} />
      </div>
      <div className="content__buttons">
        <button className="buttons__cancel" onClick={() => handleCloseModal()}>
          {t('rename_stage.button_cancel_text')}
        </button>
        <button
          className="buttons__save"
          disabled={disabledButton()}
          onClick={() => handleSaveModal()}>
          {t('rename_stage.button_save_text')}
        </button>
      </div>
    </div>
  );

  return ModalSystem({
    visible: show,
    title: t('rename_stage.title_modal'),
    theme: 'dark',
    setVisible: setShow,
    showIconTitle: true,
    iconTitle: <EditStageIcon color="#7DFF8A" />,
    centered: true,
    width: 380,
    children: contentChildren(),
    zIndex: 2000
  });
};

export default RenameStage;
