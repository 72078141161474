import React, { useEffect, useState, useRef } from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { Radio, Checkbox } from 'antd';
import ModalSystem from '../DesignSystem/ModalSystem';
import userSettingsService from '../../services/userSettings.service';
import { getLocales } from './getLocales';
import styles from './styles.module.scss';
import { trackingEvent } from '../../analytics';
import { getBasicAmplitudEventProperties } from '../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';

const constraintName = {
  asap: 'As Soon As Possible',
  alap: 'As Late As Possible',
  snet: 'Start No Earlier Than',
  snlt: 'Start No Later Than',
  fnet: 'Finish No Earlier Than',
  fnlt: 'Finish No Later Than',
  mso: 'Must Start On',
  mfo: 'Must Finish On'
};

const FORMAT_DEFAULT = 'MM/DD/YY';

let activities_rescheduled_ids = [];
let numRescheduledActivities = 0;

const rescheduleType = (numRescheduledActivities) =>
  numRescheduledActivities === 1 ? 'Single reschedule' : 'Bulk reschedule';

const resetActivityToLastState = (reset) => {
  activities_rescheduled_ids = [];
  const gantt = window.to_use_react_gantt;
  gantt.batchUpdate(() => {
    window.gantt.lastMoveTaskData.forEach((task) => {
      if (reset) {
        gantt.updateTask(task.id, task.data);
      }
      activities_rescheduled_ids.push(task.data.proplannerId);
    });
    numRescheduledActivities = activities_rescheduled_ids.length;
    if (reset) {
      gantt.autoSchedule();
    }
    window.gantt.lastMoveTaskData = [];
  });
};

const ConstraintValidationModal = ({ t }) => {
  const [selectedOption, setSelectedOption] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [constraint, setConstraint] = useState({});
  const [texts, setTexts] = useState({});
  const [isMultipleDrag, setIsMultipleDrag] = useState(false);
  const saveConfiguration = useRef(false);

  useEffect(() => {
    setTexts(getLocales(t, constraint, isMultipleDrag));
  }, [isVisible]);

  useEffect(() => {
    const showConstraintValidationModal = (
      activity,
      isMultipleDrag = false
    ) => {
      if (!isVisible) {
        const dateFormat =
          window.to_use_react_gantt.currentDateFormat || FORMAT_DEFAULT;
        setConstraint({
          type: constraintName[activity.constraintType],
          date: moment(activity.constraintDate).format(dateFormat.split(' ')[0])
        });
        setIsMultipleDrag(isMultipleDrag);
        setVisible(true);
      }
    };

    window.showConstraintValidationModal = showConstraintValidationModal;
  }, []);

  const onSaveConfiguration = (e) => {
    saveConfiguration.current = e.target.checked;
  };

  const handleSaveConfiguration = async () => {
    if (saveConfiguration.current) {
      const response = await userSettingsService.saveConstraintValidation({
        constraintValidation: !selectedOption
      });
      if (response.status === 200) {
        localStorage.setItem('constraintValidation', !selectedOption);
      }
    }
  };

  const handleButton = (save) => {
    const buttonOption = save ? 'Accept' : 'Cancel';
    if (save) {
      handleSaveConfiguration();
      if (selectedOption) {
        resetActivityToLastState(false);

        trackingEvent(
          'constraint_modal_visualization',
          {
            ...getBasicAmplitudEventProperties(),
            button_option: buttonOption,
            rescheduling_performed: selectedOption,
            save_default_configuration: saveConfiguration.current,
            source: rescheduleType(numRescheduledActivities),
            activities_rescheduled_ids: activities_rescheduled_ids
          },
          AMPLITUDE_SERVICE
        );

        setVisible(false);
        return;
      }
    }

    closeModal(buttonOption);
  };

  const closeModal = (buttonOption) => {
    resetActivityToLastState(true);

    if (activities_rescheduled_ids.length) {
      trackingEvent(
        'constraint_modal_visualization',
        {
          ...getBasicAmplitudEventProperties(),
          button_option: buttonOption || 'Close',
          rescheduling_performed: selectedOption,
          save_default_configuration: saveConfiguration.current,
          source: rescheduleType(numRescheduledActivities),
          activities_rescheduled_ids: activities_rescheduled_ids
        },
        AMPLITUDE_SERVICE
      );
    }

    setVisible(false);
  };

  const onChangeRadioGroup = (e) => {
    setSelectedOption(e.target.value);
  };

  const contentChildren = () => (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h5>
            <Trans>{texts.title}</Trans>
          </h5>
        </div>
        <div className={styles.title}>
          <h5>{texts.subtitle}</h5>
        </div>
      </div>
      <div>
        <Radio.Group
          className={styles.optionsContainer}
          onChange={onChangeRadioGroup}
          value={selectedOption}>
          <div
            className={`${styles.option} ${
              selectedOption === false ? styles.checkedOption : ''
            }`}>
            <Radio value={false}>{texts.dontMove}</Radio>
          </div>

          <div
            style={{ overflowX: 'auto' }}
            className={`${styles.option} ${
              selectedOption === true ? styles.checkedOption : ''
            }`}>
            <Radio value={true}>
              <Trans>{texts.continue}</Trans>
            </Radio>
          </div>
        </Radio.Group>
      </div>

      <div className={styles.bottom}>
        <div className={styles.saveConfiguration}>
          <Checkbox onChange={onSaveConfiguration}>
            {texts.saveConfiguration}
          </Checkbox>
        </div>
        <div className={styles.buttons}>
          <button
            className="buttons__cancel"
            onClick={() => handleButton(false)}>
            {texts.cancel}
          </button>
          <button
            className={styles.acceptButton}
            onClick={() => handleButton(true)}
            disabled={selectedOption === null}>
            {texts.accept}
          </button>
        </div>
      </div>
    </div>
  );

  return ModalSystem({
    visible: isVisible,
    theme: 'dark',
    setVisible: closeModal,
    showTitle: false,
    centered: true,
    width: 640,
    children: contentChildren()
  });
};

export default ConstraintValidationModal;
