export default function useMasterplanPermissions() {
  const permissions = JSON.parse(sessionStorage.getItem('permissiontable'));
  if (!permissions) return {};
  const gantt = permissions.masterplan.find(
    (per) => per.module == 'gantt'
  ).permission;
  const resources = permissions.masterplan.find(
    (per) => per.module == 'resources'
  ).permission;
  return { gantt, resources, product: 'masterplan' };
}
