import { httpCodes } from '../constants/httpCodes';
import { ACCESS_GRANTED, NOT_FOUND } from '../constants/access';
import { createHttpClient } from '../utils/httpUtils';
import * as Sentry from '@sentry/react';

class AccessService {
  constructor() {
    this.http = createHttpClient();
  }

  async checkScheduleAccess(projectId, scheduleId) {
    const url = `/access/project/${projectId}/schedule/${scheduleId}`;
    const handlerByStatus = {
      [httpCodes.NOT_FOUND]: () => NOT_FOUND,
      [httpCodes.FORBIDDEN]: ({ reason }) => reason,
      [httpCodes.BAD_REQUEST]: () => NOT_FOUND
    };

    try {
      await this.http.get(url);
      return ACCESS_GRANTED;
    } catch (error) {
      if (!error.response) {
        Sentry.captureException(error);
        throw error;
      }

      return handlerByStatus[error.response.status](error.response.data);
    }
  }
}

export const accessService = new AccessService();
