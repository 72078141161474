import React from 'react';
import ProcoreIcon from '../../../assets/img/icons/icon--procore.svg';
import { LoadingOutlined } from '@ant-design/icons';

const SSOProcoreButton = ({
  t,
  isLoading = false,
  disabled = false,
  onClick
}) => (
  <div
    className={`sso-procore ${disabled ? 'disabled' : ''}`}
    onClick={() => onClick && !isLoading && !disabled && onClick()}>
    <div className="sso-procore__icon">
      <img src={ProcoreIcon} alt="Procore icon" />
    </div>
    <div className="sso-procore__label">
      {isLoading ? (
        <LoadingOutlined />
      ) : (
        <span>{t('login.button_sign_in_with_procore_text')}</span>
      )}
    </div>
  </div>
);

export default SSOProcoreButton;
