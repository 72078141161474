import { createSelector } from '@reduxjs/toolkit';

const defaultView = (state) => state.ganttState.views;
const isLoading = (state) => state.ganttState.loading;
const visualizationConfig = (state) => state.ganttState.visualizationConfig;

const getFilterOrder = createSelector(defaultView, (view) => view.order);

const getGeneralFilters = createSelector(defaultView, (view) => view.filters);

const getZoomLevel = createSelector(defaultView, (view) => view.zoom);

const getVisualization = createSelector(
  defaultView,
  (view) => view.visualization
);

const getDateFilter = createSelector(defaultView, (view) => view.range);

const getColumnsFilter = createSelector(defaultView, (view) => view.columns);

const getVisualizationFilter = createSelector(
  defaultView,
  (view) => view.visualization
);

const getColorsFilters = createSelector(defaultView, (view) => view.colors);

const getCollapsedFilter = createSelector(
  defaultView,
  (view) => view.collapsed
);

const getLoading = createSelector(isLoading, (loading) => loading);

const getVisualizationConfig = createSelector(
  visualizationConfig,
  (config) => config
);

export {
  getFilterOrder,
  getGeneralFilters,
  getZoomLevel,
  getVisualization,
  getDateFilter,
  getColumnsFilter,
  getVisualizationFilter,
  getColorsFilters,
  getCollapsedFilter,
  getLoading,
  defaultView,
  getVisualizationConfig
};
