/** React components  */
import React from 'react';

export const trakerServiceAppCues = (evento) => {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  if (currentUser) {
    window.Appcues.track(evento, {
      id: currentUser.id
    });
  }
};
