import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import scrollActions from './scroll.actions';

// Components
import HeaderResourcesGrid from './subComponents/HeaderResourcesGrid';
import ListWorkers, { CrewWorkersRow } from './subComponents/ListWorkers';
import WorkersProductivityGrid from './subComponents/WorkersProductivityGrid';
import TotalHoursGrid, { CrewWorkersHourRow } from './subComponents/TotalHours';

// Assets
import style from './index.module.css';

const ProductivityGrid = (props) => {
  const {
    crewWorkers,
    resources,
    dateInit,
    updateData,
    resouceStyle,
    loadingStyle,
    hoursPerDay,
    workersStyle,
    t
  } = props;

  const $gridList = useRef(null);
  const $gridListHours = useRef(null);
  const $gridMatrix = useRef(null);
  const $resourceHeader = useRef(null);
  // referens helps to move sceoll
  const $matrixContent = useRef(null);
  const $listContent = useRef();
  const $hoursContent = useRef();

  // ==> States
  const [crewWorkersRow, setCrewWorkersRow] = useState(null);
  const [crewWorkersHoursRow, setCrewWorkersHoursRow] = useState(null);
  const [workersListMatix, setWorkerListMatrix] = useState(null);
  const [updateInline, setUpdateInline] = useState(false);

  // grid sizes
  const [gridHeightList, setGridHeightList] = useState(0);
  const [gridWidthList, setGridWidthList] = useState(300);
  const [gridWidthMatrix, setGridWidthMatrix] = useState(0);
  const [gridWidthHours, setGridWidthHours] = useState(300);

  useEffect(() => {
    if (crewWorkers) {
      let workersListHoursElements = crewWorkers.reduce(
        (next, crew) => next.concat(buildCrewGroupsHour(crew)),
        []
      );

      if (updateInline) {
        workersListHoursElements = crewWorkers.reduce(
          (next, crew) => next.concat(buildCrewGroupsHour(crew)),
          []
        );
        setUpdateInline(false);
        setCrewWorkersHoursRow(workersListHoursElements);
      }
      const workersListElements = crewWorkers.reduce(
        (next, crew) => next.concat(buildCrewGroups(crew)),
        []
      );
      const workersListMatix = crewWorkers.reduce(
        (next, { crew, workers }) => next.concat([crew, ...workers]),
        []
      );

      setWorkerListMatrix(workersListMatix);
      setCrewWorkersHoursRow(workersListHoursElements);
      setCrewWorkersRow(workersListElements);
    } else {
      setWorkerListMatrix([]);
      setCrewWorkersHoursRow([]);
      setCrewWorkersRow([]);
    }
  }, [crewWorkers, updateInline]);

  useEffect(() => {
    const gridList = $gridList.current;
    const gridMatrix = $gridMatrix.current;
    const gridHours = $gridListHours.current;

    if (gridList) {
      setGridWidthList(gridList.clientWidth);
      setGridHeightList(gridList.clientHeight);
    }

    if (gridHours) {
      setGridWidthHours(gridHours.clientWidth + 5);
    }

    if (gridMatrix) {
      setGridWidthMatrix(gridMatrix.clientWidth);
    }
  }, [$gridList, $gridMatrix, $gridListHours]);

  // ==> ends hooks

  const buildCrewGroups = ({ crew, workers }) => {
    const crewElements = [];

    crewElements.push(
      <CrewWorkersRow
        crew={crew}
        crewWorkers={workers}
        isCrew={true}
        hoursPerDay={hoursPerDay}
        gridWidth={gridWidthList + 30}
        t={t}
      />
    );

    const crewWorkers = workers.map(buildCrewWorkers);

    return crewElements.concat(crewWorkers);
  };

  const buildCrewWorkers = (worker) => (
    <CrewWorkersRow
      crew={worker}
      isCrew={false}
      t={t}
      gridWidth={gridWidthList}
      hoursPerDay={hoursPerDay}
    />
  );

  const buildCrewGroupsHour = ({ crew, workers }) => {
    const crewElements = [];

    crewElements.push(
      <CrewWorkersHourRow
        moreProp="yes"
        crew={crew}
        isCrew={true}
        gridWidth={gridWidthHours}
        t={t}
      />
    );

    const crewWorkers = workers.map(buildCrewWorkersHour);

    return crewElements.concat(crewWorkers);
  };

  const buildCrewWorkersHour = (worker) => (
    <CrewWorkersHourRow
      crew={worker}
      isCrew={false}
      t={t}
      gridWidth={gridWidthHours}
      hoursPerDay={hoursPerDay}
    />
  );

  const onScroll = useCallback(({ scrollFrom }) => (scroll) => {
    const { scrollLeft, scrollTop, scrollUpdateWasRequested } = scroll;
    if (!scrollUpdateWasRequested) {
      scrollActions[scrollFrom]({
        header: $resourceHeader,
        list: $listContent,
        listHours: $hoursContent,
        matrix: $matrixContent,
        scrollLeft,
        scrollTop
      });
    }
  });

  if (crewWorkers && !crewWorkers.length) {
    return (
      <div className={style.noAssistant}>
        <p>
          {t('productivity.assign.noAssistant')} 👀,{' '}
          {t('productivity.assign.noAssistantSecondMessage')}
        </p>
      </div>
    );
  }

  return (
    <div className={style.gridContainer}>
      <div style={{ overflowX: 'hidden', borderLeft: '.5px solid #e3E3E3' }}>
        <div style={{ width: gridWidthList }} className={style.workersTitle}>
          <div
            style={{ width: `${(gridWidthList / 3) * 2}px` }}
            className={`${style.title} ${style.name}`}>
            {t('productivity.grid.header_name')}
          </div>
          <div
            className={`${style.title} ${style.rut}`}
            style={{ width: `${gridWidthList / 3}px` }}>
            {t('productivity.grid.header_rut')}
          </div>
          <div
            className={`${style.title} ${style.hour}`}
            style={{ width: `${gridWidthList / 3}px` }}>
            {t('productivity.grid.header_hour')}
          </div>
        </div>
      </div>
      <HeaderResourcesGrid
        className={`${style.gridWorkers} ${resouceStyle} ${!resources ? loadingStyle : ''}`}
        listResources={resources}
        gridWidth={gridWidthMatrix}
        gridListRef={$gridMatrix}
        resourceHeaderRef={$resourceHeader}
        onScroll={onScroll}
      />
      <div className={style.totalHourHeader}>
        <span>TOTAL</span>
      </div>
      <ListWorkers
        className={`${style.gridWorkers} ${workersStyle} ${!crewWorkers?.length ? loadingStyle : ''}`}
        listWorkers={crewWorkersRow}
        gridListRef={$gridList}
        listContentRef={$listContent}
        gridHeight={gridHeightList}
        gridWidth={gridWidthList}
        onScroll={onScroll}
      />
      <WorkersProductivityGrid
        className={`${style.gridWorkers} ${workersStyle} ${!crewWorkers?.length ? loadingStyle : ''}`}
        crewWorkers={crewWorkers}
        dateInit={dateInit}
        updateData={updateData}
        gridMatrixRef={$gridMatrix}
        matrixContentRef={$matrixContent}
        listWorkers={workersListMatix}
        listResources={resources}
        gridHeight={gridHeightList}
        gridWidth={gridWidthMatrix}
        gridListRef={$gridMatrix}
        updateInline={setUpdateInline}
        onScroll={onScroll}
      />
      <TotalHoursGrid
        className={`${style.gridWorkers} ${workersStyle} ${!crewWorkers?.length ? loadingStyle : ''}`}
        listWorkersHours={crewWorkersHoursRow}
        gridHoursRef={$gridListHours}
        hoursContentRef={$hoursContent}
        gridHeight={gridHeightList}
        gridWidth={gridWidthHours}
        onScroll={onScroll}
        hoursPerDay={hoursPerDay}
      />
    </div>
  );
};

export default withTranslation()(ProductivityGrid);
