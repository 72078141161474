/* eslint-disable no-eval */

import { base } from '../../../services/base';

/** React components  */
import React, { useState, useEffect, useRef } from 'react';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/** To animating views easely */
import { Animated } from 'react-animated-css';

import CustomPaginator from '../../../components/CustomPaginator';

/** Massive actions icons */
import deleteMassive from '../../../assets/img/massive/delete.png';

/** Build components from antd css framework */
import { Row, Col } from 'antd';
import { Spin, Icon, Empty } from 'antd';

import AnimatedSortable from '../../../components/AnimatedSortable';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
/** Services */
import { sectorResourcesService } from '../../../services/sectorresource.service';
import { activityService } from '../../../services/activity.service';
import { userService } from '../../../services/user.service';

/** Plain text CSS file (react pretty features) */
import './index.css';

/** Redux */
import { useSelector } from 'react-redux';

/** JSON with table distribution */
import { tableMetadata } from './table.layout';

/** Single draggable column component */
import SingleOrderCol from '../../../components/Resources/SingleOrderCol';

/** Single table with activity as header and tasks as childs */
import ResourceActivity from '../../../components/Resources/ResourceActivity';

import LookaheadOtherGroup from '../../../components/LookaheadOtherGroup';

/** Header with filters options component */
import FilterHeader from '../../../components/Resources/FilterHeader';

/** Function to clone objects on JS */
import cloneDeep from 'lodash/cloneDeep';
import differenceBy from 'lodash/differenceBy';

/** Function to keep states replacing specific elements */
import update from 'immutability-helper';

import {
  openNotification,
  resourceTypes,
  groupBy as groupByFn
} from '../../../utils';

import IconComponent from '../../../components/Projects/IconSvg';

/** import common functions from utils */
import { crane } from '../../../utils';

import { withTranslation } from 'react-i18next';

// import useWindowDimensions from '../../../hooks/useWindowDimensions';

/** RangePicker builded components to use datepicker from start to end in just one input :) */
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

/**
 * This view shows activities from last level.
 * Then user can through a filter change range date showed.
 * Then, user can add on eagger data model tasks, associating them with the activity ID as a super parent.
 * @param {*} props
 */
function PlanificationView(props) {
  const { t } = props;
  const [currentPage, setCurrentPage] = useState(0);

  /** Project state from redux */
  const projectState = useSelector((state) => state.projectState);

  /** Activities to handle table loading */
  const [activities, setActivities] = useState([]);
  const [resourcesAll, setResourcesAll] = useState([]);

  /** Array with users that belongs to the same sector that this master plan */
  const [toSelectResponsables, setToSelectResponsables] = useState([]);

  /** Flag to know when component is on loading step */
  const [isLoading, setLoading] = useState(true);

  const { permission } = props;

  /** Object to handle start and end dates to show last level activities from master plan  */
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  const [groupBy, setGroupBy] = useState({ criteria: 'activity', sort: 'asc' });

  /** Variable to view and his components to handle table manipulation by user */
  const [tableConfig, setTableConfig] = useState([]);

  const [massiveSelection, setMassiveSelection] = useState([]);

  const { height, width } = useWindowDimensions();

  const [scrollStates, setScrollStates] = useState({});

  const [massiveOnProcess, setMassiveOnProcess] = useState(false);

  const [resizing, setResizing] = useState(false);

  const [actvitys, setActvitys] = useState([]);

  /** Full width feature */
  const [totalTablesWidth, setTotalTablesWidth] = useState(0);
  let paginatorRef = useRef();

  const [tableUpdated, setTableUpdated] = useState(false);

  /** Effect to load translation to table declaration file */
  useEffect(() => {
    setTimeout(() => {
      const copyOfTableData = [...tableMetadata];
      copyOfTableData.map((column) => {
        if (
          column.data_type.includes('/icon') ||
          column.data_type.includes('/string')
        ) {
          column.label = t('tables.gantt_resources.' + column.name + '.label');
          column.from_values.map((option) => {
            option.label = t(
              'tables.gantt_resources.' +
                column.name +
                '.options.' +
                option.value
            );
          });
        } else {
          column.label = t('tables.gantt_resources.' + column.name);
        }
      });
      setTableConfig(copyOfTableData);
      setTableUpdated(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (!resizing) {
      let tablesWidth = 0;
      tableConfig.map((el) => {
        if (el.visible) {
          tablesWidth += el.width;
        }
      });
      if (tablesWidth < width) {
        tablesWidth = width - 200;
        const new_tableConfig = [];
        tableConfig.map((el) => {
          const tablerow = el;
          const w = width - 200;
          tablerow.width = el.proportion * w;
        });
      }

      setTotalTablesWidth(tablesWidth);
    }
  }, [tableConfig]);

  useEffect(() => {
    window.Appcues.page();
  });

  useEffect(() => {
    if (!resizing) {
      let tablesWidth = 0;
      tableConfig.map((el) => {
        if (el.visible) {
          tablesWidth += el.width;
        }
      });
      if (tablesWidth < width) {
        tablesWidth = width - 200;
        const new_tableConfig = [];
        tableConfig.map((el) => {
          const tablerow = el;
          const w = width - 200;
          tablerow.width = el.proportion * w;
        });
      }

      setTotalTablesWidth(tablesWidth);
    }
  }, [useWindowDimensions()]);

  useEffect(() => {
    if (paginatorRef.current) {
      paginatorRef.current.resetAfterColumnIndex(0);
    }
  }, [totalTablesWidth]);

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };
    EventEmitter.on('changeMainRoute', callback);
    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  /** This effect allows to load initial lookahead range, and reacts to change on sectorSelected from redux */
  useEffect(() => {
    if (tableConfig.length && tableUpdated) {
      /** Set default dates as today with 2 weeks forward */
      let start = new Date();
      let end = new Date();
      end.setDate(end.getDate() + 100);
      start = start.toISOString().split('T')[0].split('-').join('/');
      end = end.toISOString().split('T')[0].split('-').join('/');

      /** Then using set state hook load this vars, to let virtual dom load datepickers with correct dates */
      setDateRange((prev) => {
        prev.start = start;
        prev.end = end;
        return prev;
      });

      // window.history.replaceState({}, null, base.front + 'gantt/' + projectState.sectorSelected + '/resources')
      /** Loads activities with their tasks */
      getResources();
    }
  }, [projectState.sectorSelected, tableUpdated]);

  /**
   * This function get activities and responsables to use them at DOM
   */
  async function getResources() {
    /** set load to true */
    setLoading(true);
    setMassiveSelection([]);
    setCurrentPage(0);

    /** get resources */
    const resources = await sectorResourcesService.searchBySector(
      projectState.sectorSelected
    );
    const actvitys = await activityService.showBySector(
      projectState.sectorSelected
    );
    /** group resources by type */
    const resourcesGrouped = groupByFn(resources.sectorresource, 'type');

    /** get responsables */
    const responsables = await userService.getBySector(
      projectState.sectorSelected
    );

    /** If data were fetch succesfully, we pass this data to component states */
    let resourcesState = [];
    if (resources) {
      setLoading(false);
      /** generate arrays of resources from resourceTypes */
      resourcesState = [];
      resourceTypes.map((el) => {
        resourcesState.push({
          name: el.label,
          tasks: resourcesGrouped[el.value] || [],
          type: el.value
        });
      });

      resourcesState[0].headerJsx = (
        <div className="title-resources">
          <i className="fas fa-cubes"></i>
          <span>{t('master_plan_resources.material')}</span>
        </div>
      );
      resourcesState[1].headerJsx = (
        <div className="title-resources">
          <IconComponent data={crane} width={15} fill="#121212" />
          <span>{t('master_plan_resources.machinery')}</span>
        </div>
      );

      resourcesState[2].headerJsx = (
        <div className="title-resources">
          <i className="fas fa-user"></i>
          <span>{t('master_plan_resources.speciality')}</span>
        </div>
      );
      setResourcesAll(resourcesState);
      setToSelectResponsables(responsables.users);
      setActvitys(actvitys);
    }
    return resourcesState;
  }

  /**
   * This function updates the state of table metadata
   * @param {*} tMetadata Object with same attr than tableConfig to replace at view state
   */
  const updateTableMetadata = (tMetadata = tableConfig) => {
    setTableConfig((prev) => {
      prev = cloneDeep(tMetadata);
      return prev;
    });
  };

  /**
   * This functions update the state of activities at view
   */
  const updateRender = (ac = activities) => {
    const a = cloneDeep(ac);
    setActivities((prev) => {
      prev = a;
      return prev;
    });
  };

  /**
   * This function is used by datepickers to save new valeus to component state
   * @param {*} value Moment date object
   * @param {*} dateString String date
   */
  const changeDateState = async (value, dateString) => {
    if (dateString[0] != '' && dateString != '') {
      await setDateRange((prev) => {
        prev.start = dateString[0];
        prev.end = dateString[1];
        return prev;
      });
      await setGroupBy((prev) => {
        prev = { criteria: 'activity', sort: 'asc' };
        return prev;
      });
      getResources();
    } else {
      setActivities([]);
    }
  };

  /**
   * This function handles with resize of cols inside the table from dragging resize  from header titles
   * @param {*} width Updated width catched from the event
   * @param {*} colMetadata Single column metadata that were resized
   */
  const onDivResizeHandler = (width, colMetadata) => {
    if (colMetadata.doubleClicked) {
      colMetadata.width = width;
      colMetadata.doubleClicked = false;
      updateTableMetadata();
    }
  };

  /**
   * This function handles the click, and the unclick from user when the resize is catched
   * @param {*} col Single column metadata that is going to be resized
   * @param {*} val Boolean, true for start resizing, false for end resizing
   */
  const onDivResizeDoubleClicked = (col, val) => {
    col.doubleClicked = val;
    // setResizing(val)
    // updateTableMetadata()
  };

  /**
   * This function executes massiveSelectionHandler then sets the selection state to
   * handle massive actions like deleting, changing responsable, etc.
   * @param {*} selection Initial selection
   */
  const recursivelySelection = (selection) => {
    /** Temporal array to save added task */
    const toAdd = [];

    /** Temporal array to save deleted task */
    const toDelete = [];

    /** Execution of recursively selection */
    massiveSelectionHandler(selection, null, toAdd, toDelete);

    /** Setting state of massive actions */
    setMassiveSelection((prev) => {
      /* Take off elements of massive actions state */
      const newState = differenceBy(prev, toDelete, 'id');
      /** Then we check those elements that arent at the state and add them */
      toAdd.map((el) => {
        const doesExist = newState.find((single) => el.id == single.id);
        if (!doesExist) {
          newState.push(el);
        }
      });
      prev = cloneDeep(newState);
      return prev;
    });
  };

  /**
   * This function handles with massive selection tasks
   * @param {*} selection Element to change selecton status by it active attribute
   * @param {*} flag This saves the parent selected status to match with their children
   * @param {*} toAdd Array with tasks to add to state array with massive selection
   * @param {*} toDelete Array with tasks to delete from state array with massive selection
   */
  const massiveSelectionHandler = (
    selection,
    flag = null,
    toAdd = [],
    toDelete = []
  ) => {
    /** Define array of children (activity or task) */
    const childs = selection.tasks || selection.children || null;

    /** Defines first flag value from calling this function without flag */
    if (flag == null) {
      flag = !selection.active;
      selection.active = !selection.active;
    }

    /** Matchs the values to dont change already setted correct value */
    if (flag != selection.active) {
      selection.active = !selection.active;
    }

    /** Defines if the current selection must be deleted or added to state array of massive selection */
    if (selection.active) {
      toAdd.push(selection);
    } else if (!selection.active) {
      toDelete.push(selection);
    }

    /** Recursively repetition for children */
    if (childs && childs.length) {
      childs.map((el) => {
        massiveSelectionHandler(el, flag, toAdd, toDelete);
      });
    }
  };

  /**
   * This function handles the drag n drop movements for change cols disposition
   * @param {*} oldIndex Index from the array that is dragged
   * @param {*} newIndex Index from the element that the dragged el is going to be moved
   */
  const moveCard = async ({ oldIndex, newIndex }) => {
    const dragCard = tableConfig[oldIndex];

    const a = update(tableConfig, {
      $splice: [
        [oldIndex, 1],
        [newIndex, 0, dragCard]
      ]
    });
    updateTableMetadata(a);
  };

  /**
   * This function render the table with activities and their tasks
   */
  const renderTable = (activitiesData) => {
    if (isLoading) {
      return (
        <Spin className="loader-spinner-lookahead-table" indicator={antIcon} />
      );
    }
    if (activitiesData.length) {
      if (groupBy.criteria == 'activity') {
        const array = [renderGeneralHeader(), ...activitiesData];

        return (
          <CustomPaginator
            adjustWeigth={80}
            adjustHeight={130}
            totalTablesWidth={totalTablesWidth}
            massiveSelection={massiveSelection}
            current={currentPage}
            setCurrentPage={setCurrentPage}
            data={array}
            itemSize={height < 700 ? height * 0.55 : height * 0.68}
            renderItem={(item, key, virtualizeRef) => {
              if (key == 0) {
                paginatorRef = virtualizeRef;
                return item;
              } else if (!resizing) {
                return (
                  <ResourceActivity
                    permission={permission}
                    scrollStates={scrollStates}
                    setScrollStates={setScrollStates}
                    virtualizeRef={virtualizeRef}
                    massiveSelectionHandler={recursivelySelection}
                    setMassiveSelection={setMassiveSelection}
                    resizing={resizing}
                    tableMetadata={tableConfig}
                    key={key}
                    lastLvlActivity={item}
                    index={key}
                    toSelectResponsables={toSelectResponsables}
                    actvitys={actvitys}
                    headerJsx={item.headerJsx}
                  />
                );
              }
            }}
            perPage={1}
          />
        );
      }
    } else {
      return (
        <Empty
          style={{ marginTop: 200 }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>No hay recursos</span>}
        />
      );
    }
  };

  /**
   * This function render the header with titles for the printed tables, it allows to drag n drop the position
   */
  const renderGeneralHeader = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Row
        className="resource-fixed-tree-table-header"
        style={{ background: '#fff' }}>
        <AnimatedSortable
          useDragHandle
          items={tableConfig}
          onSortEnd={moveCard}
          axis="x"
          onDivResizeHandler={onDivResizeHandler}
          onDivResizeDoubleClicked={onDivResizeDoubleClicked}
        />
      </Row>
    );
  };

  /**
   * This function render the header with filtering options for printed tables
   */
  const renderFilterHeader = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Row>
        <Col>
          <FilterHeader
            groupBy={groupBy}
            data={activities}
            changeDateState={changeDateState}
            dateRange={dateRange}
            updateTableMetadata={updateTableMetadata}
            tableMetadata={tableConfig}
            toSelectResponsables={toSelectResponsables}
            updateRender={updateRender}
          />
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    if (massiveOnProcess) {
      setMassiveOnProcess(false);
    }
  }, [massiveOnProcess]);

  /**
   * On click function when pressing delete
   * @param {*} res resource to delete
   */
  const handleDelete = async (res) => {
    await sectorResourcesService.destroy(res.id);
  };

  /**
   * This functions shows a pretty alert to user
   * @param {*} data Object { title, message, type }
   */
  const notifyMessage = (data) => {
    const alertErrorMailExists = {
      title: data.title,
      description: data.message,
      type: data.type
    };
    openNotification(alertErrorMailExists);
  };

  /**
   * This function deletes tasks massively
   */
  const massiveDeleteHandler = async () => {
    /** Declare an async iteration */
    const asyncMap = massiveSelection.map(async (selection) => {
      const findByType = resourcesAll.find((el) => el.type === selection.type);
      const newChildrens = findByType.tasks.filter(
        (res) => res.id !== selection.id
      );
      findByType.tasks = newChildrens;
      await handleDelete(selection);
    });

    /** We await of async iteration */
    await Promise.all(asyncMap);

    /** Then update the render, notify of process, and clean the massive selection state */
    updateRender();
    notifyMessage({
      title: t('master_plan_resources.removed'),
      message: t('master_plan_resources.removed_resource_alert'),
      type: 'success'
    });
    setMassiveSelection([]);
  };

  /**
   * This function prints the header with actions to deal with massive selection
   */
  const renderMassiveActionHeader = () => (
    <Animated
      style={{ height: massiveSelection.length ? 30 : 0, overflow: 'hidden' }}
      animationIn="fadeIn"
      animationInDuration={250}
      animationOut="fadeOut"
      animationOutDuration={250}
      isVisible={massiveSelection.length}>
      <Row style={{ height: 30, backgroundColor: '#586666' }}>
        <Col span={12} offset={6} style={{ height: '100%' }}>
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            style={{ height: '100%' }}>
            {massiveOnProcess ? null : (
              <Col style={{ textAlign: 'center' }}>
                {/* Selection text indicator */}
                <span style={{ color: '#7DFF8A' }}>
                  {massiveSelection.length + ' '}
                </span>
                <span style={{ color: '#FFFFFF', marginRight: 10 }}>
                  selected resource{massiveSelection.length > 1 ? 's' : null}
                </span>
                {/* Massive delete */}
                <span
                  className="massive-icon-style"
                  onClick={massiveDeleteHandler}>
                  <img width={12} src={deleteMassive} />
                </span>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Animated>
  );

  /**
   * Render
   */
  return (
    <Animated animationIn="fadeIn" animationInDuration={500} isVisible={true}>
      <Row>
        {/* renderFilterHeader() */}
        <Col className="card-resource">{renderTable(resourcesAll)}</Col>
      </Row>
    </Animated>
  );
}

export default withTranslation()(PlanificationView);
