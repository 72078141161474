import React, { useState, useEffect } from 'react';
import { taskService } from '../../services/task.service';
import { Icon, Tooltip } from 'antd';
import IconComponent from '../../components/Projects/IconSvg';

/** import library for format numbers */
import NumberFormat from 'react-number-format';

/** PNG for task planification edit and save tasks */
import editIcon from '../../assets/img/EDT-DarkGrey-1080x1080.png';
import saveIcon from '../../assets/img/GRD-DarkGrey-1080x1080.png';
import { cardEditIcon } from '../../utils';
import { cardSaveIcon } from '../../utils';

const EditableInput = (props) => {
  const { onEdit, location, renderEditable, save, t } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(location.id);
  useEffect(() => {
    if (props.isEditing == location.id) {
      setValue(location.name);
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [props.isEditing]);
  const updateParentData = (replaceValue = true) => {
    save(value, location.id);
    /* if (onSave) {
            onSave(value)
        } else {
            if (replaceValue) {
                task[column.name] = value
            }
            setIsEditing(false)
            service(task)
        } */
    onEdit(null);
  };
  if (isEditing) {
    return (
      <span>
        {renderEditable(setValue, value, updateParentData)}
        <span
          onClick={updateParentData}
          style={{ marginLeft: 10, cursor: 'pointer' }}>
          <Tooltip placement="top" title={t('takt_units.save_label')}>
            <img className="save-input-icon" src={saveIcon} width={10} />
          </Tooltip>
        </span>
      </span>
    );
  }
  return (
    <span>
      {location.name}
      <span
        onClick={() => {
          onEdit(location.id);
          // setIsEditing(true)
        }}
        style={{ marginLeft: 10, cursor: 'pointer' }}>
        <Tooltip placement="top" title={t('takt_units.edit_label')}>
          <img className="editable-input-icon" src={editIcon} width={10} />
        </Tooltip>
      </span>
    </span>
  );
};
export default EditableInput;
