import axios from 'axios';
import { base } from '../services/base';

export const createHttpClient = (options = {}) => {
  const { headers: baseHeaders, ...extraOptions } = options;
  const headers = {
    'Content-Type': 'application/json',
    Authorization:
      'Basic cHJvcGxhbm5lcjpiWGx0WVhOMFpYSnJaWGx0ZVcxaGMzUmxjbXRsZVcxNWJXRnpkR1Z5YTJWNU1USXpORFUyTnpnNU1UQmhZbU5rWldabmFHbHE=',
    ...baseHeaders
  };

  const httpClient = axios.create({
    baseURL: base.api,
    headers,
    ...extraOptions
  });

  httpClient.interceptors.request.use((config) => {
    if (!config.headers['WWW-Authenticate']) {
      const sessionToken = sessionStorage.getItem('sessionToken');
      if (sessionToken) {
        config.headers['WWW-Authenticate'] = `Bearer ${sessionToken}`;
      }
    }
    return config;
  });

  return httpClient;
};
