import React, { useState, useEffect, memo } from 'react';

const Position = memo(({ defaultValue, onBlur, isValidRole }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const handleChange = ({ target }) => {
    const { value } = target;
    setValue(value);
  };

  const handleBlur = () => {
    onBlur(value);
  };

  if (!isValidRole)
    return <span className="position-column">{defaultValue}</span>;

  return (
    <input
      type="text"
      name="position"
      className="position-column-input"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      autoComplete="off"
    />
  );
});

export default Position;
