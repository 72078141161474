import { txtFieldState } from '../../utils';
import { userService, companyService } from '../../services';

// validation map form
export const stateTemplate = {
  cname: {
    ...txtFieldState,
    fieldName: 'cname',
    required: true,
    requiredTxt: 'Name is required',
    formatErrorTxt: ''
  },
  size: {
    ...txtFieldState,
    fieldName: 'size',
    required: true,
    requiredTxt: 'Number of projects is required',
    formatErrorTxt: ''
  },
  country: {
    ...txtFieldState,
    fieldName: 'country',
    required: true,
    requiredTxt: 'Country is required',
    formatErrorTxt: ''
  },
  allFieldsValid: false,
  isLoading: false
};

// services
export async function updateUser(data) {
  const resp = await userService.update(data);
  return resp;
}

export async function createCompany(data) {
  const resp = await companyService.create(data);
  return resp;
}

export async function getUser(token) {
  const resp = await userService.getbytoken(token);
  return resp;
}
