import { createHttpClient } from '../utils/httpUtils';
import { base } from './base';

const baseURL = base.apiJava;

class AnalyticService {
  constructor() {
    this.http = createHttpClient({ baseURL });
  }

  async s_curve(sector_id) {
    const res = await this.http
      .post('analytics/s_curve', sector_id)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async resource_curve(options) {
    const res = await this.http
      .post('analytics/resource_curve', options)
      .catch((error) => {
        console.error(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async velocity_takt(options) {
    const res = await this.http
      .post('analytics/velocity_takt', options)
      .catch((error) => {
        console.error(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async productivity_chart(options) {
    const res = await this.http
      .post('analytics/productivity_chart', options)
      .catch((error) => {
        console.error(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const analyticService = new AnalyticService();
