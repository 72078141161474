import React, { useState } from 'react';
import { Input, Button, Select } from 'antd';
import styled from 'styled-components';
import { constraintTypeService } from '../../../../../../services/constrainttype.service';
import {
  openNotification,
  categoriesConstraintType,
  compareValues
} from '../../../../../../utils';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

function ContraintsTypes({
  ContraintsTypesAll,
  setContraintsTypesAll,
  onlyRead,
  t
}) {
  const { Option } = Select;
  // GET ID PROJECT FROM REDUX
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;

  const templateCats = {
    name: '',
    description: '',
    type: undefined,
    projectId: projectSelectedId
  };

  const [contraintsTypes, setcontraintsTypes] = useState(templateCats);

  const changeContraints = (name, value) => {
    setcontraintsTypes({
      ...contraintsTypes,
      [name]: value
    });
  };

  const saveContraintsTypes = () => {
    /** validate */
    if (!contraintsTypes.name.length || contraintsTypes.type === undefined) {
      const alertError = {
        title: t('settings.project_categories.constraints.title'),
        description: t('settings.project_categories.constraints.required'),
        type: 'error'
      };
      openNotification(alertError);
      return false;
    }
    constraintTypeService
      .create(contraintsTypes)
      .then((res) => {
        if (res === false) {
          const alertError = {
            title: t('settings.project_categories.constraints.title'),
            description: t('settings.error_default'),
            type: 'error'
          };
          openNotification(alertError);
        } else {
          setContraintsTypesAll([...ContraintsTypesAll, res]);
          setcontraintsTypes(templateCats);
          const alertSuccess = {
            title: t('settings.project_categories.constraints.title'),
            description: t('settings.project_categories.constraints.success'),
            type: 'success'
          };
          openNotification(alertSuccess);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="wrapp-categories">
      <h1 style={{ color: '#2C3421' }}>
        {t('settings.project_categories.constraints.title')}
      </h1>
      <p style={{ fontSize: '12px' }}>
        {t('settings.project_categories.constraints.name')}
      </p>
      <Input
        disabled={onlyRead}
        name="name"
        type="text"
        placeholder={t('settings.project_categories.constraints.name')}
        autoComplete="off"
        onChange={(e) => changeContraints(e.target.name, e.target.value)}
        value={contraintsTypes.name}
        style={{
          fontSize: '12px',
          border: '1px solid #121212',
          borderRadius: '5px'
        }}
      />
      <p style={{ fontSize: '12px', marginTop: '10px' }}>
        {t('settings.project_categories.constraints.area')}
      </p>
      <SelectionStyle>
        <Select
          disabled={onlyRead}
          value={contraintsTypes.type}
          style={{
            width: ' 100%',
            fontSize: '12px',
            border: '1px solid #121212',
            borderRadius: '5px'
          }}
          placeholder={t('settings.project_categories.constraints.select')}
          name="type"
          onChange={(value) => changeContraints('type', value)}>
          {categoriesConstraintType
            .sort(compareValues(t('lang') === 'es' ? 'labelEs' : 'label'))
            .map((contraint) => (
              <Option
                style={{
                  fontSize: '12px'
                }}
                name="task_creter"
                key={contraint.value}>
                {t(`settings.project_categories.${contraint.trad}`)}
              </Option>
            ))}
        </Select>
      </SelectionStyle>
      <div className="btns-cats">
        <Button
          disabled={onlyRead}
          className="btn-tipo"
          style={{}}
          onClick={saveContraintsTypes}>
          {t('settings.project_categories.constraints.add')}
        </Button>
      </div>
    </div>
  );
}
export default withTranslation()(ContraintsTypes);

const SelectionStyle = styled.div`
  .ant-select-selection {
    border-radius: 5px !important;
    width: 100%;
  }
  .ant-select-selection {
    background-color: white !important;
    color: #5f5f5f;
  }
  .anticon svg {
    color: #7dff8a;
    float: right;
  }

  .ant-select-selection,
  ant-select-selection--single {
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    border-top: 0px;
  }

  .ant-select-selection:focus,
  ant-select-selection--single:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;
