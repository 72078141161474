import React from 'react';
import { splitText } from '../../../../views/taktplanning/assign/index.helper';

/**
 * This component is the rendered element between Y (activity) and X (unit) which show the match of selected items
 * @param {*} activities all activities in lineal tree structure
 * @param {*} units all units in lineal tree structure
 * @param {*} selectedActivities Selected activities at checkbox
 * @param {*} selectedUnits Selected units at checkbox
 * @param {*} locationArray locations with units array
 */
export const GridCellUnitGroupped =
  (activities, units, selectedActivities, selectedUnits, locationArray) =>
  ({ columnIndex, rowIndex, style }) => {
    const grouppedActivity = activities[rowIndex];
    const unit = units[columnIndex];

    const foundActivitiesToUnassign = (currentSelection) => (itemSelected) =>
      itemSelected.id === currentSelection.id &&
      currentSelection.disable &&
      currentSelection.active;

    let isSelectedActivity = false;
    let nToCreate = 0;
    for (let i = 0; i < grouppedActivity.activities.length; i++) {
      const ac = grouppedActivity.activities[i];
      const isActivityInSelectedArray = selectedActivities.find(
        (act) => act.id == ac.id && ac.active
      );
      if (isActivityInSelectedArray) {
        nToCreate += 1;
        isSelectedActivity = true;
      }
    }

    const isSelectedUnit = selectedUnits.find(
      (un) => un.id == unit.id && unit.active
    );
    let customClass = '';

    const location = locationArray.find((loc) => loc.id == unit.locationId);
    if (location) {
      const lastIndex = location.lastIndex;
      if (columnIndex == lastIndex - 1) {
        customClass += ' separate-secctions';
      }
    }

    let alreadyAssignedUnits = 0;

    let isAlreadyAssignActivity = false;
    for (let i = 0; i < grouppedActivity.activities.length; i++) {
      const ac = grouppedActivity.activities[i];
      const isActivityInSelectedArray = unit.activities.find(
        (act) => act.id == ac.id && ac.disable
      );
      isAlreadyAssignActivity = isActivityInSelectedArray;
      if (isActivityInSelectedArray) {
        alreadyAssignedUnits += 1;
        break;
      }
    }

    const alreadyAssignedCounter = alreadyAssignedUnits;

    /**
     * ===============
     *  Unassign units
     * ===============
     * */
    let activitiesWillUnassign = false;
    let isSomeUnitwillUnassign = false;
    const unAssignActivities = (activity) => {
      const isFoundActivitySelected = selectedActivities.find(
        foundActivitiesToUnassign(activity)
      );
      if (isFoundActivitySelected) {
        activitiesWillUnassign = true;
      }
    };

    grouppedActivity.activities.forEach(unAssignActivities);
    isSomeUnitwillUnassign = selectedUnits.find(
      foundActivitiesToUnassign(unit)
    );

    const activityUnitMatches =
      isSomeUnitwillUnassign &&
      isAlreadyAssignActivity &&
      isSomeUnitwillUnassign.activities.find(
        (activity) => activity.id === isAlreadyAssignActivity.id
      );

    let className =
      isSelectedActivity && isSelectedUnit
        ? `grid-element-with-units ${customClass}`
        : `grid-element-without-units ${customClass} ${alreadyAssignedCounter ? 'grid-element-with-units' : ''}`;

    let content =
      isSelectedActivity && isSelectedUnit
        ? `${nToCreate} new / ${alreadyAssignedCounter} assigned`
        : `${alreadyAssignedCounter || '-'}`;

    // adding class for unassign
    if (activitiesWillUnassign && activityUnitMatches) {
      className = 'grid-element-unassign-units';
      content = '1';
    }

    return (
      <div className={className} style={style}>
        {splitText(content, 8)}
      </div>
    );
  };

/**
 * This component is the rendered element between Y (activity) and X (unit) which show the match of selected items
 * @param {*} activities all activities in lineal tree structure
 * @param {*} units all units in lineal tree structure
 * @param {*} selectedActivities Selected activities at checkbox
 * @param {*} selectedUnits Selected units at checkbox
 */
export const GridCellUnitTree =
  (activities, units, selectedActivities, selectedUnits, locationArray) =>
  ({ columnIndex, rowIndex, style }) => {
    const activity = activities[rowIndex];
    const unit = units[columnIndex];

    const foundActivitiesToUnassign = (currentSelection) => (itemSelected) =>
      itemSelected.id === currentSelection.id &&
      currentSelection.disable &&
      currentSelection.active;

    const isSelectedActivity = selectedActivities.find(
      (act) => act.id == activity.id && activity.active
    );

    const isSelectedUnit = selectedUnits.find(
      (un) => un.id == unit.id && unit.active
    );
    let customClass = '';

    const location = locationArray.find((loc) => loc.id == unit.locationId);
    if (location) {
      const lastIndex = location.lastIndex;
      if (columnIndex == lastIndex - 1) {
        customClass += 'separate-secctions';
      }
    }

    let alreadyAssignedUnits = 0;

    const ac = activity;
    const isActivityInSelectedArray = unit.activities.find(
      (act) => act.id == ac.id && ac.disable
    );
    if (isActivityInSelectedArray) {
      alreadyAssignedUnits += 1;
    }

    const alreadyAssignedCounter = alreadyAssignedUnits;

    /**
     * ===============
     *  Unassign units
     * ===============
     * */
    let activitiesWillUnassign = false;
    let isSomeUnitwillUnassign = false;
    let unitWillUnassign = 0;

    isSomeUnitwillUnassign = selectedUnits.find(
      foundActivitiesToUnassign(unit)
    );
    activitiesWillUnassign = unit.activities.find(
      foundActivitiesToUnassign(activity)
    );
    if (activitiesWillUnassign) {
      unitWillUnassign++;
    }

    const blankText = activity.has_childs ? '' : '-';
    let className =
      isSelectedActivity && isSelectedUnit && !activity.has_childs
        ? `grid-element-with-units ${customClass}`
        : `grid-element-without-units ${customClass} ${alreadyAssignedCounter ? 'grid-element-with-units' : ''}`;

    let content =
      isSelectedActivity && isSelectedUnit && !activity.has_childs
        ? `1 ${alreadyAssignedCounter ? `${alreadyAssignedCounter} assigned` : ''}`
        : `${alreadyAssignedCounter || blankText}`;

    // adding class for unassign
    if (activitiesWillUnassign && isSomeUnitwillUnassign) {
      className = 'grid-element-unassign-units';
      content = '1';
    }

    return (
      <div className={className} style={style}>
        {content}
      </div>
    );
  };
