import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import VirtualDynamicTable from '../../../../VirtualDynamicTable';
import DependenciesFilterSwitch from './components/DependenciesFilterSwitch';
import {
  ITEM_COUNT,
  ITEM_SIZE,
  HEIGHT_ROW,
  DEFAULT_NUMBER_TYPE_TASK,
  DEFAULT_LAG,
  DEFAULT_TEXT_TYPE_TASK,
  DEFAULT_TEXT_LAG,
  ORANGE_IPSUM,
  EMPTY_ALERT_KEY,
  PREDECESSOR_ALERT_KEY,
  SUCCESSOR_ALERT_KEY,
  PARENT_ALERT_KEY,
  CIRCULAR_LINK_ALERT_KEY,
  LAG_FORMAT_ALERT_KEY,
  WHITE_COLOR
} from './constants';
import {
  getMessageAlert,
  templateDataPredecessors,
  templateDataSuccessors,
  getCodeFromNumberType
} from './utils';
import { WarningIcon, CloseIcon } from '../../../../../icons';
import styles from './DependenciesTab.module.scss';
import './index.css';
import { getBasicAmplitudEventProperties } from '../../../../../analytics/utils';
import { trackingEvent } from '../../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../../analytics/constants';

const DependenciesTab = ({ cardData, t }) => {
  const [allTasksGantt, setAllTaskGantt] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [textModal, setTextModal] = useState();
  const [predecessorsArray, setPredecessorsArray] = useState([]);
  const [successorsArray, setSuccessorsArray] = useState([]);

  const drawerUniqueIdTask = cardData?.uniqueId;
  const gantt = window.to_use_react_gantt;
  const parentsTask = [];

  const searchTask = async (id, drawerUniqueIdTask, predecessor, position) => {
    const oldTask = predecessor
      ? predecessorsArray[position]
      : successorsArray[position];

    if (!oldTask && (!id || id === '')) {
      return;
    }

    if (oldTask) {
      if (oldTask.id === parseInt(id)) return;
      await removeTask(oldTask.id, drawerUniqueIdTask, predecessor, position);
      if (!id || id === '') return;
    }

    const drawerTask = gantt.getTask(drawerUniqueIdTask);
    const currentTask = findTaskByCorrelativeId(parseInt(id));

    if (!currentTask) {
      setTextModal(getMessageAlert(EMPTY_ALERT_KEY, t));
      setShowModal(true);
      return false;
    }

    if (drawerTask.id === currentTask.id) {
      setTextModal(
        getMessageAlert(
          predecessor ? PREDECESSOR_ALERT_KEY : SUCCESSOR_ALERT_KEY,
          t
        )
      );
      setShowModal(true);
      return;
    }

    if (parseInt(drawerTask.parent) === currentTask.id) {
      setTextModal(getMessageAlert(PARENT_ALERT_KEY, t));
      setShowModal(true);
      return;
    }

    const currentLink = await createLink(
      currentTask,
      drawerUniqueIdTask,
      predecessor
    );

    if (currentLink) createTask(currentTask, predecessor, position);
  };

  const createLink = async (currentTask, drawerUniqueIdTask, predecessor) => {
    const newLink = {
      target: predecessor
        ? drawerUniqueIdTask.toString()
        : currentTask.id.toString(),
      source: predecessor
        ? currentTask.id.toString()
        : drawerUniqueIdTask.toString(),
      type: DEFAULT_NUMBER_TYPE_TASK,
      lag: DEFAULT_LAG,
      ganttId: cardData?.originalActivityObject.ganttId,
      sectorId: cardData?.originalActivityObject.sectorId
    };

    const isCircularLink = gantt.isCircularLink(newLink);
    if (isCircularLink) {
      setTextModal(getMessageAlert(CIRCULAR_LINK_ALERT_KEY, t));
      setShowModal(true);
      return;
    }

    try {
      const response = await gantt.addLink(newLink);

      if (!response) {
        setTextModal(getMessageAlert('', t));
        setShowModal(true);

        return false;
      }

      trackingEvent(
        'try_add_link',
        {
          getBasicAmplitudEventProperties,
          location: 'Activity Card - Dependencies Tab'
        },
        AMPLITUDE_SERVICE
      );

      return true;
    } catch (err) {
      console.log('Error gantt.addLink()', err);
    }
  };

  const createTask = (currentTask, predecessor, position) => {
    const newTask = {
      id: currentTask.correlative_id,
      title: currentTask.text,
      type: DEFAULT_TEXT_TYPE_TASK,
      lag: DEFAULT_TEXT_LAG
    };

    updateArrayTask(predecessor, position, newTask);
  };

  const removeTask = async (
    idTask,
    drawerUniqueIdTask,
    predecessor,
    position
  ) => {
    const links = gantt.getLinks();

    const targetTask = predecessor
      ? drawerUniqueIdTask
      : findTaskByCorrelativeId(parseInt(idTask))?.id;
    const sourceTask = predecessor
      ? findTaskByCorrelativeId(parseInt(idTask))?.id
      : drawerUniqueIdTask;

    if (!targetTask || !sourceTask) return;

    const link = links.find(
      (link) =>
        link.target.toString() === targetTask.toString() &&
        link.source.toString() === sourceTask.toString()
    );

    if (!link) return;

    try {
      await gantt.deleteLink(link.id);

      updateArrayTask(predecessor, position);
    } catch (err) {
      console.log('Error gantt.deleteLink()', err);
    }
  };

  const updateTask = async (
    idTask,
    drawerUniqueIdTask,
    typeNumber = null,
    lag = null,
    predecessor,
    position
  ) => {
    const links = gantt.getLinks();

    const targetTask = predecessor
      ? drawerUniqueIdTask
      : findTaskByCorrelativeId(parseInt(idTask))?.id;
    const sourceTask = predecessor
      ? findTaskByCorrelativeId(parseInt(idTask))?.id
      : drawerUniqueIdTask;

    if (!targetTask || !sourceTask) return;

    const link = links.find(
      (link) =>
        link.target.toString() === targetTask.toString() &&
        link.source.toString() === sourceTask.toString()
    );

    if (!link) return;

    try {
      const ganttLink = gantt.getLink(link.id);

      if (typeNumber) {
        ganttLink.type = typeNumber;
      }

      if (lag) {
        if (isNaN(parseInt(lag, 10))) {
          setTextModal(getMessageAlert(LAG_FORMAT_ALERT_KEY, t));
          setShowModal(true);
          return;
        }

        ganttLink.lag = gantt.formatter.parse(lag);
      }

      await gantt.updateLink(link.id);

      const currentTask = predecessor
        ? predecessorsArray[position]
        : successorsArray[position];

      if (!currentTask) return;

      const newTask = {
        id: currentTask.id,
        title: currentTask.title,
        type: typeNumber ? getCodeFromNumberType(typeNumber) : currentTask.type,
        lag: lag ? gantt.formatter.format(ganttLink.lag) : currentTask.lag
      };

      updateArrayTask(predecessor, position, newTask);
    } catch (err) {
      console.log('Error gantt.updateLink()', err);
    }
  };

  const removeAllTask = async (drawerUniqueIdTask, predecessor) => {
    const links = gantt.getLinks();

    links.map(async (link) => {
      if (
        (predecessor &&
          link.target.toString() === drawerUniqueIdTask.toString()) ||
        (!predecessor &&
          link.source.toString() === drawerUniqueIdTask.toString())
      ) {
        try {
          await gantt.deleteLink(link.id);
        } catch (err) {
          console.log('Error gantt.deleteLink()', err);
        }
      }
    });

    predecessor
      ? setPredecessorsArray([...Array(ITEM_COUNT)])
      : setSuccessorsArray([...Array(ITEM_COUNT)]);
  };

  function setNewDataIntoDependencies(dependencies, position, newTask) {
    let currentDependencies = [...dependencies];
    currentDependencies.splice(position, 1, newTask);
    currentDependencies = currentDependencies.map((currentDependency) => {
      if (!currentDependency) return;
      return {
        ...currentDependency,
        gantt_id: getGanttId(currentDependency.id)
      };
    });

    return currentDependencies;
  }

  const updateArrayTask = (predecessor, position, newTask = undefined) => {
    if (predecessor) {
      setPredecessorsArray(
        setNewDataIntoDependencies(predecessorsArray, position, newTask)
      );
      return;
    }

    if (!predecessor) {
      setSuccessorsArray(
        setNewDataIntoDependencies(successorsArray, position, newTask)
      );
    }
  };

  const findTaskByCorrelativeId = (id) => {
    const tasks = gantt.getTaskByTime();
    const task = tasks.find((task) => task.correlative_id === id);

    return task;
  };

  const getGanttId = (id) => {
    const task = findTaskByCorrelativeId(id);
    if (!task) return;

    return task.id;
  };

  const handleCloseModal = () => setShowModal(false);

  const getParentsRecursive = (task) => {
    const currentParent = gantt?.getParent(task);
    parentsTask.push(currentParent);

    if (currentParent) getParentsRecursive(currentParent);
  };

  getParentsRecursive(drawerUniqueIdTask);

  const setDependencyAttributes = (linkedActivities = []) => {
    if (linkedActivities.length === 0) return linkedActivities;

    const activitiesWithExtraParams = linkedActivities.map((activity) => {
      activity.lag = activity.lag.replace('d', ' days').replace('+', '');
      activity.gantt_id = getGanttId(activity.id);

      return activity;
    });

    return activitiesWithExtraParams;
  };

  useEffect(() => {
    let dependenciesPredecessorsArray = Object.values(
      cardData.dependencies?.predecessors
    );
    dependenciesPredecessorsArray = setDependencyAttributes(
      dependenciesPredecessorsArray
    );
    const currentDependeciesPredecessors = [];
    currentDependeciesPredecessors.unshift(...dependenciesPredecessorsArray);
    setPredecessorsArray(currentDependeciesPredecessors);

    let dependenciesSuccessorsArray = Object.values(
      cardData.dependencies?.successors
    );
    dependenciesSuccessorsArray = setDependencyAttributes(
      dependenciesSuccessorsArray
    );
    const currentDependeciesSuccessors = [];
    currentDependeciesSuccessors.unshift(...dependenciesSuccessorsArray);
    setSuccessorsArray(currentDependeciesSuccessors);
  }, [cardData.id]);

  useEffect(() => {
    const currentTaskGantt = [];
    gantt.eachTask((task) => {
      currentTaskGantt.push({
        id: task.id,
        text: task.text,
        $level: task.$level,
        $rendered_parent: parseInt(task.$rendered_parent),
        correlative_id: task.correlative_id
      });
    });
    setAllTaskGantt(currentTaskGantt);
  }, []);

  return (
    <>
      <div className={styles.tab__dependencies}>
        <div className={styles.dependencies__predecessors}>
          <div className={styles.dependencies__title}>
            <h6>{t('activity_card.predecessors')}</h6>
          </div>
          <VirtualDynamicTable
            templateData={templateDataPredecessors(
              drawerUniqueIdTask,
              searchTask,
              removeTask,
              updateTask,
              removeAllTask,
              allTasksGantt,
              parentsTask,
              predecessorsArray,
              t
            )}
            data={predecessorsArray}
            itemCount={ITEM_COUNT}
            itemSize={ITEM_SIZE}
            heightRow={HEIGHT_ROW}
          />
        </div>
        <div className={styles.dependencies__successors}>
          <div className={styles.dependencies__title}>
            <h6>{t('activity_card.successors')}</h6>
            <DependenciesFilterSwitch
              predecesors={predecessorsArray}
              successors={successorsArray}
              cardData={cardData}
              t={t}
            />
          </div>
          <VirtualDynamicTable
            templateData={templateDataSuccessors(
              drawerUniqueIdTask,
              searchTask,
              removeTask,
              updateTask,
              removeAllTask,
              allTasksGantt,
              parentsTask,
              successorsArray,
              t
            )}
            data={successorsArray}
            itemCount={ITEM_COUNT}
            itemSize={ITEM_SIZE}
            heightRow={HEIGHT_ROW}
          />
        </div>
      </div>
      <Modal
        visible={showModal}
        width={378}
        footer={null}
        centered
        wrapClassName="wrap-dependencies-alert-modal"
        className="dependencies-alert-modal"
        closeIcon={
          <div className="dependencies-alert-modal__close">
            <CloseIcon
              color={WHITE_COLOR}
              className="dependencies-alert-modal__icon"
            />
          </div>
        }
        onCancel={handleCloseModal}
        maskClosable={false}>
        <div className="dependencies-alert-modal__content">
          <WarningIcon
            color={ORANGE_IPSUM}
            className="dependencies-alert-modal__warning"
          />
          <h6 className="dependencies-alert-modal__title">{textModal}</h6>
          <button
            onClick={() => handleCloseModal()}
            className="dependencies-alert-modal__button-alert">
            {t('activity_card.text_button_modal_alert')}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DependenciesTab;
