import { CONSTRAINT_TYPES } from '../constants/index';
class TotalFloat {
  constructor(paramsForCalculation) {
    this.gantt = paramsForCalculation.gantt;
    this.activity = paramsForCalculation.activity;
    this.es = paramsForCalculation.es;
    this.ef = paramsForCalculation.ef;
    this.ls = paramsForCalculation.ls;
    this.lf = paramsForCalculation.lf;
    this.constrainstMap = paramsForCalculation.constraintsMap;
    this.totalFloatSnltFnlt = paramsForCalculation.totalFloatSnltFnlt;
    this.totalFloatForMsoMfo = paramsForCalculation.totalFloatForMsoMfo;
    this.minFromLinks = paramsForCalculation.minFromLinks;
  }

  getTotalFloat() {
    let { constraint_type } = this.activity;
    const progress = Number(this.activity.progress);

    if (progress === 100) {
      return 0;
    }

    if (!constraint_type) {
      constraint_type = CONSTRAINT_TYPES.ASAP;
    }
    if (constraint_type === CONSTRAINT_TYPES.ASAP || !constraint_type) {
      return progress === 0
        ? this.calculateAsap()
        : this.calculateAsapWithProgress();
    }
    if (constraint_type === CONSTRAINT_TYPES.ALAP) {
      return progress === 0
        ? this.calculateAlap()
        : this.calculateAlapWithProgress();
    }
    if (
      constraint_type === CONSTRAINT_TYPES.SNET ||
      constraint_type === CONSTRAINT_TYPES.FNET
    ) {
      return progress === 0
        ? this.calculateSnetFet()
        : this.calculateSnetFnetSnltWithProgress();
    }
    if (constraint_type === CONSTRAINT_TYPES.SNLT) {
      return progress === 0
        ? this.calculateSnltFnlt()
        : this.calculateSnetFnetSnltWithProgress();
    }
    if (constraint_type === CONSTRAINT_TYPES.FNLT) {
      return progress === 0
        ? this.calculateSnltFnlt()
        : this.calculateFnltWithProgress();
    }
    if (
      constraint_type === CONSTRAINT_TYPES.MSO ||
      constraint_type === CONSTRAINT_TYPES.MFO
    ) {
      return progress === 0
        ? this.calculateMsoMfo()
        : this.calculateMsoMfoWithProgress();
    }
  }

  calculateAsap() {
    const totalFloatStart = this.calculationWithCalendar(this.ls, this.es);
    const totalFloatFinish = this.calculationWithCalendar(this.lf, this.ef);
    return (
      this.getMinValue(totalFloatStart, totalFloatFinish) /
      this.gantt.config.hoursPerDay
    );
  }

  calculateAsapWithProgress() {
    let totalFloatFinish = this.calculationWithCalendar(this.lf, this.ef);

    if (this.minFromLinks) {
      const lfMin = this.calculationWithCalendar(this.minFromLinks.lf, this.ef);
      return (
        this.getMinValue(totalFloatFinish, lfMin) /
        this.gantt.config.hoursPerDay
      );
    }
    return totalFloatFinish / this.gantt.config.hoursPerDay;
  }

  calculateAlap() {
    const totalFloatStart = this.calculationWithCalendar(this.ls, this.es);
    const totalFloatFinish = this.calculationWithCalendar(this.lf, this.ef);
    const totalFloatDatesMin = this.getMinValue(
      totalFloatStart,
      totalFloatFinish
    );
    const totaltFloatRestriction = this.calculationWithCalendar(
      this.lf,
      this.activity.end_date
    );
    return (
      this.getMinValue(totalFloatDatesMin, totaltFloatRestriction) /
      this.gantt.config.hoursPerDay
    );
  }

  calculateAlapWithProgress() {
    return (
      this.calculationWithCalendar(this.lf, this.ef) /
      this.gantt.config.hoursPerDay
    );
  }

  calculateSnetFnetSnltWithProgress() {
    let totalFinish = this.calculationWithCalendar(this.lf, this.ef);

    if (this.minFromLinks) {
      let totalFloatMin = this.calculationWithCalendar(
        this.minFromLinks.lf,
        this.ef
      );

      return (
        this.getMinValue(totalFinish, totalFloatMin) /
        this.gantt.config.hoursPerDay
      );
    }
    return totalFinish / this.gantt.config.hoursPerDay;
  }

  calculateFnltWithProgress() {
    let totalStart = this.calculationWithCalendar(this.lf, this.ef);

    let totalFloatConstraint = this.calculationWithCalendar(
      this.activity.constraint_date,
      this.ef
    );

    if (this.minFromLinks) {
      let totalFloatLFmin = this.calculationWithCalendar(
        this.minFromLinks.lf,
        this.ef
      );
      return (
        this.getMinValue(totalStart, totalFloatConstraint, totalFloatLFmin) /
        this.gantt.config.hoursPerDay
      );
    }
    return (
      this.getMinValue(totalStart, totalFloatConstraint) /
      this.gantt.config.hoursPerDay
    );
  }

  calculateMsoMfoWithProgress() {
    let totalFloatFinish = this.calculationWithCalendar(this.lf, this.ef);
    return totalFloatFinish / this.gantt.config.hoursPerDay;
  }

  calculateSnetFet() {
    const totalFloatStart = this.calculationWithCalendar(this.ls, this.es);
    const totalFloatFinish = this.calculationWithCalendar(this.lf, this.ef);

    return (
      this.getMinValue(totalFloatStart, totalFloatFinish) /
      this.gantt.config.hoursPerDay
    );
  }

  calculateSnltFnlt() {
    const totalFloatStart = this.calculationWithCalendar(this.ls, this.es);
    const totalFloatFinish = this.calculationWithCalendar(this.lf, this.ef);
    const minTotalFloat = this.getMinValue(totalFloatStart, totalFloatFinish);
    if (!this.totalFloatSnltFnlt && !this.constrainstMap) {
      return (
        this.getMinValue(totalFloatStart, totalFloatFinish) /
        this.gantt.config.hoursPerDay
      );
    }

    let totalFloatRestriction = 0;

    if (this.activity.constraint_type === CONSTRAINT_TYPES.SNLT) {
      totalFloatRestriction = this.calculationWithCalendar(
        this.constrainstMap.es,
        this.totalFloatSnltFnlt.es
      );
    } else {
      totalFloatRestriction = this.calculationWithCalendar(
        this.constrainstMap.ef,
        this.totalFloatSnltFnlt.ef
      );
    }

    return (
      this.getMinValue(totalFloatRestriction, minTotalFloat) /
      this.gantt.config.hoursPerDay
    );
  }

  calculateMsoMfo() {
    const totalFloatStart = this.calculationWithCalendar(this.ls, this.es);
    const totalFloatFinish = this.calculationWithCalendar(this.lf, this.ef);
    const datesTotalFloat = this.getMinValue(totalFloatStart, totalFloatFinish);
    if (!this.totalFloatForMsoMfo && !this.constrainstMap) {
      return (
        this.getMinValue(totalFloatStart, totalFloatFinish) /
        this.gantt.config.hoursPerDay
      );
    }
    let totalFloatRestriction = 0;

    if (this.activity.constraint_type === CONSTRAINT_TYPES.MSO) {
      totalFloatRestriction = this.calculationWithCalendar(
        this.constrainstMap.es,
        this.totalFloatForMsoMfo.es
      );
    } else {
      totalFloatRestriction = this.calculationWithCalendar(
        this.constrainstMap.ef,
        this.totalFloatForMsoMfo.ef
      );
    }

    return (
      this.getMinValue(totalFloatRestriction, datesTotalFloat) /
      this.gantt.config.hoursPerDay
    );
  }

  getMinValue(...values) {
    return Math.min(...values);
  }

  calculationWithCalendar(dateOne, dateTwo) {
    let calendar = this.gantt.getCalendar(this.activity.calendar_id);
    let calcualteDate = calendar.calculateDuration({
      start_date: new Date(dateTwo),
      end_date: new Date(dateOne)
    });

    return calcualteDate;
  }
}

export default TotalFloat;
