function shiftIsValid(shift, index) {
  if (
    onlyOneShiftHasValue(shift, index) ||
    allShiftHasNovalue(shift, index) ||
    shiftStartAndEndDateHasNoValue(shift, index)
  ) {
    return false;
  }

  return true;
}

function onlyOneShiftHasValue(shift, index) {
  if (!shift) return false;

  return (
    (shift.shift_ini[index] === false && shift.shift_end[index] !== false) ||
    (shift.shift_ini[index] !== false && shift.shift_end[index] === false)
  );
}

function allShiftHasNovalue(shift, index) {
  if (!shift) return false;

  return (
    shift.shift_ini.every((elem) => elem === false) ||
    shift.shift_end.every((elem) => elem === false)
  );
}

function shiftStartAndEndDateHasNoValue(shift, index) {
  if (!shift) return false;

  return shift.shift_ini[index] === false && shift.shift_end[index] === false;
}

export default shiftIsValid;
