import { cncStatus } from '../../../utils';

/** Import PNG for flag */
import flagGrey from '../../../assets/img/PR-Gray-50x50.png';
import flagBlue from '../../../assets/img/PR-Blue-50x50.png';
import flagYellow from '../../../assets/img/PR-Yellow-50x50.png';
import flagRed from '../../../assets/img/PR-Red-50x50.png';

/** Config object Table Cncs */

/** Array UI options for Priority */
export const arr_priority = [
  {
    icon: flagGrey,
    color: 'lightgrey',
    value: 'low',
    label: 'Baja',
    weigth: 3
  },
  {
    icon: flagBlue,
    color: 'darkturquoise',
    value: 'normal',
    label: 'Normal',
    weigth: 2
  },
  {
    icon: flagYellow,
    color: 'orange',
    value: 'high',
    label: 'Alta',
    weigth: 1
  },
  {
    icon: flagRed,
    color: 'red',
    value: 'urgent',
    label: 'Urgente',
    weigth: 0
  }
];

/** Array UI options for Status */
export const arr_status = [
  {
    color: '#34af00',
    value: 'resolved',
    label: 'Resuelta',
    description: 'Release status means...',
    weigth: 2
  },
  {
    color: '#e50101',
    value: 'unsolved',
    label: 'No resuelta',
    description: 'Not Release status means...',
    weigth: 1
  },
  {
    color: '#F59d04',
    value: 'inprocess',
    label: 'En proceso',
    description: 'Expired status means...',
    weigth: 0
  }
];

/** Metadata for table Constraints   */
export const tableMetadata = [
  {
    name: 'name',
    label: 'Nombre',
    data_type: 'string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'left',
    width: 220,
    from_values: arr_status,
    groupable: false,
    ignore_as_column: true,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true
  },
  {
    name: 'cncTypeId',
    label: 'Tipo',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 180,
    mode: 'single',
    from_values: [],
    groupable: true,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true
  },
  {
    name: 'typeArea',
    label: 'Area',
    data_type: 'array/string',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 100,
    groupable: false,
    from_values: [],
    orderable: false,
    filterable: false,
    mode: 'single'
  },
  {
    name: 'create_date',
    label: 'Fecha de Creación',
    data_type: 'date',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    editable: false,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true
  },
  {
    name: 'user',
    label: 'Creada por',
    data_type: 'array/images',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    mode: 'multiple',
    from_values: 'toSelectResponsables',
    el_to_extract_from: 'email',
    el_to_label_from: ['name', 'lastname'],
    img_from: 'image',
    selectField: 'id',
    width: 100,
    ref: 'users',
    refSave: 'userId',
    editable: false,
    groupable: true,
    groupable_switch: ['A → Z', 'Z → A'],
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true
  },
  {
    name: 'priority',
    label: 'Prioridad',
    data_type: 'array/icon',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 140,
    from_values: arr_priority,
    groupable: true,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true
  },
  {
    name: 'week',
    label: 'Semana',
    data_type: 'number',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    mode: 'single',
    width: 140,
    disabled: true,
    from_values: 'week',
    groupable: true,
    groupable_switch: ['1 → 9', '9 → 1'],
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: true
  },
  {
    name: 'status',
    label: 'Estado',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    mode: 'single',
    width: 140,
    from_values: arr_status,
    arr_mapping: cncStatus,
    groupable: true,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true
  },
  {
    name: 'image',
    label: 'Foto',
    data_type: 'string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 220
  },
  {
    name: 'cnctasks',
    label: 'Tareas',
    data_type: 'string',
    visible: true,
    span: 3,
    offset: 0,
    align: 'center',
    mode: 'multiple',
    width: 100,
    orderable: false
  },
  {
    name: 'taskRoute',
    label: 'Ruta',
    data_type: 'string',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true
  }
];
