const REGEX_PASSWORD = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

export const isNotValidFormatEmail = (dataForm) =>
  dataForm.first_name === '' ||
  dataForm.last_name === '' ||
  !REGEX_PASSWORD.test(dataForm.password) ||
  !REGEX_PASSWORD.test(dataForm.password2) ||
  dataForm.password !== dataForm.password2 ||
  dataForm.company_name === '' ||
  dataForm.company_size === '';
