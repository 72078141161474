import React, { Fragment } from 'react';
import { Modal } from 'antd';
import './index.css';
import { HelpIcon } from '../../../icons';
import 'react-image-crop/dist/ReactCrop.css';
import helpGifIcon from '../../../assets/img/helpCropImage.gif';
import styled from 'styled-components';

function ModalHelpCrop({ Visible, setVisible, t }) {
  // Close modal
  const handleCancel = () => {
    setVisible(false);
  };

  const renderFooter = () => (
    <div className="footer-edit-image">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}></div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
        <ButtonEditImage>
          <button
            className="btn-edit-image"
            style={{ padding: '0 10px', width: 'auto', fontWeight: 500 }}
            onClick={handleCancel}>
            {t('settings.edit_image_form.close_text')}
          </button>
        </ButtonEditImage>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Modal
        className="crop_image_modal crop_help_modal"
        title={''}
        visible={Visible}
        onCancel={handleCancel}
        maskClosable={false}
        width={580}
        centered
        footer={renderFooter()}>
        <div className="grid-crop">
          <span className="column-title">
            <span style={{ float: 'left', marginRight: '10px' }}>
              <HelpIcon color="#fff" />
            </span>
            {t('settings.edit_image_form.text-help')}
          </span>
        </div>

        <div className="grid-crop">
          <div
            className="column-wrap-image-prev"
            style={{ margin: 'auto!important' }}>
            <img className="img-to-help" src={helpGifIcon} />
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

export default ModalHelpCrop;

const ButtonEditImage = styled.div`
  .btn-edit-image {
    margin-top: 40px;
    text-align: center;
    user-select: none;
    padding: 0.15rem 0.1rem;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
    color: white;
    width: 80px;
    font-size: 12px;
    height: 28px;
    border-radius: 5px;
    background-color: #7dff8a;
    transition: 0.3s;
    margin: 10px 5px;
    border: none;
    outline: none;
  }

  .btn-edit-image-left {
    margin-top: 40px;
    text-align: center;
    user-select: none;
    padding: 0.15rem 0.1rem;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
    color: white;
    width: 30px;
    font-size: 12px;
    height: 28px;
    background-color: #7dff8a;
    transition: 0.3s;
    margin: 10px 2px;
    border: none;
    outline: none;
  }

  .btn-edit-image:hover,
  .btn-edit-image-left:hover {
    transition: 0.3s;
    background-color: #ca7534;
  }
`;
