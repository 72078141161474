import React from 'react';

// Components
import WidgetOptions from './options';

import widgetHOC from './WidgetHOX';

// Assets
import styles from './index.module.css';

const Widget = (props) => {
  const {
    realWeek = '-',
    expected = '-',
    planning = '-',
    committed = '-',
    actualPPC = '-',
    resources = [],
    sectorId = null,
    t
  } = props;

  return (
    <div className={styles.container + ' widget_custom'}>
      <div className={styles.box}>
        <WidgetOptions
          styles={styles}
          resources={resources}
          sectorId={sectorId}
          t={t}
        />
        <div className={styles.realWeek}>
          <span>{t('weekly_plan_widget.realweek')}</span>
          <p>{realWeek}</p>
        </div>
        <div className={styles.expected}>
          <span>{t('weekly_plan_widget.expected')}</span>
          <p>{expected}</p>
        </div>
        <div className={styles.planning}>
          <span>{t('weekly_plan_widget.planned')}</span>
          <p>{planning}</p>
        </div>
        <div className={styles.committed}>
          <span>{t('weekly_plan_widget.commited')}</span>
          <p>{committed}</p>
        </div>
        <div className={styles.ppc}>
          <span>{t('weekly_plan_widget.ppc')}</span>
          <p>{actualPPC}</p>
        </div>
      </div>
    </div>
  );
};

export default widgetHOC(Widget);
