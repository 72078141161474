import React from 'react';

import { splitText } from '../../index.helper';

/**
 * This component is the rendered fixed header col
 * @param {*} data all units
 * @param {*} width width for this space
 */
export const HeaderUnitCol =
  (data, width, selectedActivities, selectedUnits, locationArray) =>
  ({ columnIndex, rowIndex, style }) => {
    const unit = data[columnIndex];
    const isUnitSelected = selectedUnits.find(
      (u) => u.id == unit.id && unit.active
    );
    const isUnitSelectedToUnassign = selectedUnits.find(
      (u) => u.id == unit.id && unit.active && unit.disable
    );
    // const parentLocation = locationArray.find(loc => loc.id == unit.locationId)
    // let customClass = ''

    let hasAtLeastOneAvailableActivity = false;
    let isSomeUnassignUnit = false;
    const onlyChilds = selectedActivities.filter((ac) => !ac.has_childs);
    for (let i = 0; i < onlyChilds.length; i++) {
      const ac = onlyChilds[i];
      if (!ac.disable) {
        hasAtLeastOneAvailableActivity = true;
        break;
      } else if (ac.active) {
        isSomeUnassignUnit = true;
        break;
      }
    }

    // if (parentLocation) {
    //     const lastIndex = parentLocation.lastIndex
    //     if (columnIndex == (lastIndex - 1)) {
    //         customClass += 'separate-secctions'
    //     }
    // }

    let className =
      isUnitSelected && hasAtLeastOneAvailableActivity
        ? 'fixed-unit-header-with-activities'
        : 'fixed-unit-header-without-activities';
    className =
      isUnitSelectedToUnassign && isSomeUnassignUnit
        ? 'fixed-unit-header-activities-unassign'
        : className;

    return (
      <div className={className} style={style}>
        <span>{splitText(unit.name, width)}</span>
      </div>
    );
  };

/**
 * This component is the rendered fixed header location col
 * @param {*} locationArray all location
 */
export const HeaderLocationCol =
  (locationArray, selectedActivities, selectedUnits) =>
  ({ columnIndex, rowIndex, style }) => {
    const location = locationArray[columnIndex];

    const selectedUnitsActive = selectedUnits.filter((unit) => unit.active);
    const selectedUnitsUnassign = selectedUnits.filter(
      (unit) => unit.active && unit.disable
    );
    const selectedActivitiesActive = selectedActivities.filter(
      (activity) => activity.active
    );
    const hasAtLeastOneAvailableActivity = selectedActivities.filter(
      (activity) => activity.active && !activity.has_childs
    );

    let isLocationActive = false;
    let isUnitSelectedToUnassign = false;

    const activityExist = [];
    const existActivityInUnit = (activityExists) => (unit) => {
      if (!unit.locationId) {
        return;
      }
      unit.activities.forEach((activity) => {
        selectedActivitiesActive.forEach((activityActive) => {
          const findActivity = activityActive.id == activity.id;
          findActivity && activityExists.push(findActivity);
        });
      });
    };

    selectedUnitsActive.forEach(existActivityInUnit(activityExist));

    selectedUnitsActive.forEach((unitSelected) => {
      if (unitSelected.locationId) {
        if (
          location.productionUnits.some((unit) => unit.id == unitSelected.id)
        ) {
          isLocationActive = true;
        }
      }
    });

    selectedUnitsUnassign.forEach((unitSelected) => {
      if (unitSelected.locationId) {
        if (
          location.productionUnits.some((unit) => unit.id == unitSelected.id)
        ) {
          isUnitSelectedToUnassign = true;
        }
      }
    });

    let className =
      isLocationActive &&
      selectedUnitsActive.length &&
      !activityExist.length &&
      hasAtLeastOneAvailableActivity
        ? 'fixed-unit-header-with-activities'
        : 'location-header-fix';
    className =
      isUnitSelectedToUnassign && activityExist.length
        ? 'fixed-unit-header-activities-unassign'
        : className;

    return (
      <div style={style}>
        <div className={className}>
          {splitText(location.name, location.widthForHeader || 100)}
        </div>
      </div>
    );
  };

export const HeaderStructureCol =
  (data) =>
  ({ columnIndex, rowIndex, style }) => {
    const structure = data[columnIndex];

    return (
      <div style={style}>
        <div className="location-header-fix-matrix matrix-structure activities">
          {splitText(structure.name, structure.widthForHeader || 100)}
        </div>
      </div>
    );
  };
