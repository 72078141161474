import React, { useState } from 'react';
import { Popover } from 'antd';
import { templateTypesTaskDropdown } from '../../utils';
import { WHITE_COLOR } from '../../constants';
import { ArrowDownIcon } from '../../../../../../../icons';

export const DependenciesDropdownTypes = ({ value, onClickSelectType }) => {
  const [visible, setVisible] = useState(false);
  const [showArrow, setShowArrow] = useState(false);

  const handleVisibleChange = (newVisible) => setVisible(newVisible);

  const handleSelectType = (typeNumber) => {
    onClickSelectType(typeNumber);
    handleVisibleChange(false);
  };

  const content = (
    <div className="dropdown-popover-types">
      <div className="types__menu">
        {templateTypesTaskDropdown.map((type) => (
          <div
            key={type.key}
            className={`menu__option ${value?.type?.toLowerCase() === type.key ? 'active' : ''}`}
            onClick={() => handleSelectType(type.number)}>
            <span>{type.labelDropdown}</span>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Popover
      visible={visible}
      onVisibleChange={handleVisibleChange}
      content={content}
      trigger="click"
      overlayClassName="overlay-type-dropdown-popover">
      <div
        className="popover-types-content"
        onMouseEnter={() => setShowArrow(true)}
        onMouseLeave={() => setShowArrow(false)}>
        <span>{value?.type}</span>
        <div className={`content__arrow ${showArrow ? 'open' : ''}`}>
          <ArrowDownIcon color={WHITE_COLOR} />
        </div>
      </div>
    </Popover>
  );
};

export default DependenciesDropdownTypes;
