export const RESOURCES_TYPES = {
  PROJECT_USERS: 'Project Users',
  PROJECT_VENDORS: 'Project Vendors',
  COMPANY_USERS: 'Company Users',
  COMPANY_VENDORS: 'Company Vendors',
  COMPANY_PROJECTS: 'Projects'
};

export const PROCORE_AUTO_IMPORT_ACTIONS = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate'
};

export const PROCORE_AUTO_IMPORT_LEVEL = {
  PROJECT: 'project',
  ORGANIZATION: 'organization'
};

export const ASSOCIATED_FIELDS = {
  PROJECT_USERS_FIELD: 'usersImportStatus',
  PROJECT_VENDORS_FIELD: 'companiesImportStatus',
  COMPANY_USERS_FIELD: 'usersImportStatus',
  COMPANY_VENDORS_FIELD: 'companiesImportStatus',
  COMPANY_PROJECTS_FIELD: 'projectsImportStatus'
};

export const RESOURCES_TYPES_TO_ASSOCIATED_FIELDS = {
  [RESOURCES_TYPES.PROJECT_USERS]: ASSOCIATED_FIELDS.PROJECT_USERS_FIELD,
  [RESOURCES_TYPES.PROJECT_VENDORS]: ASSOCIATED_FIELDS.PROJECT_VENDORS_FIELD,
  [RESOURCES_TYPES.COMPANY_USERS]: ASSOCIATED_FIELDS.COMPANY_USERS_FIELD,
  [RESOURCES_TYPES.COMPANY_VENDORS]: ASSOCIATED_FIELDS.COMPANY_VENDORS_FIELD,
  [RESOURCES_TYPES.COMPANY_PROJECTS]: ASSOCIATED_FIELDS.COMPANY_PROJECTS_FIELD
};
