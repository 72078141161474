/** Import PNG for flag */
import flagGrey from '../../../assets/img/PR-Gray-50x50.png';
import flagBlue from '../../../assets/img/PR-Blue-50x50.png';
import flagYellow from '../../../assets/img/PR-Yellow-50x50.png';
import flagRed from '../../../assets/img/PR-Red-50x50.png';

/** Table Titles */
export const tableMetadata = [
  {
    data_type: 'number',
    name: 'activity_ganttid',
    label: 'ID',
    visible: false,
    filterable: true,
    groupable: false,
    orderable: false,
    span: 3,
    offset: 0,
    align: 'left',
    width: 20,
    filterable: true,
    ignore_as_column: true,
    resize: false,
    tree: false
  },
  {
    data_type: 'string',
    name: 'activity',
    label: 'Actividad',
    visible: false,
    filterable: true,
    groupable: false,
    orderable: false,
    groupable_switch: ['1 → N', 'N → 1'],
    ignore_as_column: true,
    tree: false
  },
  {
    name: 'name',
    label: 'Tareas',
    data_type: 'string',
    visible: true,
    span: 3,
    offset: 0,
    align: 'left',
    ignore_as_column: true,
    width: 420,
    groupable: false,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true,
    showColumCommitment: true,
    isProgress: true,
    isProductivity: true
  },
  {
    name: 'description',
    label: 'Descripción',
    data_type: 'string',
    visible: false,
    span: 3,
    offset: 0,
    align: 'left',
    ignore_as_column: false,
    width: 200,
    groupable: false,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: false
  },
  {
    name: 'start_date',
    label: 'Fecha Inicio',
    data_type: 'date',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true
  },
  {
    name: 'duration',
    label: 'Duración',
    data_type: 'number',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 95,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: true
  },
  {
    name: 'end_date',
    label: 'Fecha Término',
    data_type: 'date',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true
  },

  {
    name: 'responsables',
    label: 'Responsable',
    data_type: 'array/images',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    mode: 'multiple',
    from_values: 'toSelectResponsables',
    el_to_extract_from: 'email',
    el_to_label_from: ['name', 'lastname'],
    img_from: 'image',
    width: 125,
    groupable: true,
    groupable_switch: ['A → Z', 'Z → A'],
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true,
    showColumCommitment: true,
    isProgress: true,
    isProductivity: true
  },
  {
    name: 'subcontractId',
    label: 'Subcontrato',
    data_type: 'array/string',
    visible: true,
    mode: 'single',
    span: 2,
    offset: 0,
    align: 'center',
    width: 180,
    groupable: true,
    from_values: [],
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true,
    showColumCommitment: true,
    isProgress: true,
    isProductivity: true
  },
  {
    name: 'expectedweek',
    label: 'Esperado Sem',
    data_type: 'number',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false
  },
  {
    name: 'commitment_percentaje',
    label: 'Compromiso',
    data_type: 'number',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false,
    showColumCommitment: true,
    isProgress: true,
    isProductivity: false
  },
  {
    name: 'lean_status',
    label: 'Estado Lean',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    mode: 'single',
    width: 125,
    from_values: [
      {
        color: '#E50101',
        value: 'Restricted',
        label: 'Restringida',
        description: 'LEAN debit status means...',
        weigth: 4
      },
      {
        color: '#586666',
        value: 'Debit',
        label: 'Debe',
        description: 'LEAN debit status means...',
        weigth: 3
      },
      {
        color: '#34AF00',
        value: 'Can',
        label: 'Puede',
        description: 'LEAN can status means...',
        weigth: 2
      },
      {
        color: '#2C3421',
        value: 'Will',
        label: 'Plan Semanal',
        description: 'LEAN debit status means...',
        weigth: 1
      },
      {
        color: '#F59D04',
        value: 'Committed',
        label: 'Comprometida',
        description: 'LEAN debit status means...',
        weigth: 0
      }
    ],
    groupable: true,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true
  },
  {
    name: 'materialId',
    identifier: 'material',
    label: 'Material',
    data_type: 'array/string',
    visible: false,
    span: 2,
    offset: 0,
    mode: 'single',
    align: 'center',
    width: 150,
    from_values: [],
    groupable: true,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true,
    showColumCommitment: true,
    isProgress: false,
    isProductivity: true
  },
  {
    name: 'total_quantity',
    label: 'Cantidad Total',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false
  },
  {
    name: 'actual_quantity',
    label: 'Cantidad Real',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false
  },
  {
    name: 'quantity_parcial_percentaje',
    label: 'Compromiso Par.',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false
  },
  {
    name: 'quantity_parcial',
    label: 'Material Sem.',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false
  },
  {
    name: 'progress',
    label: 'Avance',
    data_type: 'number',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 200,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false,
    showColumCommitment: true,
    isProgress: true,
    isProductivity: false
  },
  {
    name: 'column/combine',
    label: 'Mat.Com/real',
    data_type: 'string',
    visible: true,
    offset: 0,
    align: 'center',
    filterable: false,
    showColumCommitment: true,
    isProgress: false,
    isProductivity: true
  },
  {
    name: 'priority',
    label: 'Prioridad',
    data_type: 'array/icon',
    visible: false,
    span: 1,
    offset: 0,
    align: 'center',
    mode: 'single',
    width: 90,
    from_values: [
      {
        icon: flagGrey,
        color: 'lightgrey',
        value: 'Low',
        label: 'Baja',
        weigth: 3
      },
      {
        icon: flagBlue,
        color: 'darkturquoise',
        value: 'Normal',
        label: 'Normal',
        weigth: 2
      },
      {
        icon: flagYellow,
        color: 'orange',
        value: 'High',
        label: 'Alta',
        weigth: 1
      },
      {
        icon: flagRed,
        color: 'red',
        value: 'Urgent',
        label: 'Urgente',
        weigth: 0
      }
    ],
    groupable: true,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true
  },
  {
    name: 'status',
    label: 'Estado',
    ignore_as_column: true,
    mixed: true,
    mixed_from: ['Overdue', 'Advancement'],
    data_type: 'array/icon',
    visible: false,
    span: 1,
    offset: 0,
    align: 'center',
    mode: 'single',
    width: 95,
    from_values: [
      {
        icon: 'fas fa-clock',
        color: 'yellow',
        value: 'Advancement',
        label: 'Adelantada',
        weigth: 4
      },
      {
        icon: 'fas fa-clock',
        color: 'yellow',
        value: 'Overdue',
        label: 'Atrasada',
        weigth: 3
      },
      {
        icon: 'fas fa-clock',
        color: 'yellow',
        value: 'Doing',
        label: 'En desarrollo',
        weigth: 2
      },
      {
        icon: 'far fa-check-circle',
        color: 'green',
        value: 'Done',
        label: 'Finalizada',
        weigth: 1
      },
      {
        icon: 'fas fa-minus-circle',
        color: 'grey',
        value: 'Waiting',
        label: 'No Iniciada',
        weigth: 0
      }
    ],
    groupable: true,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true
  },
  {
    name: 'cost',
    label: 'Costo',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false
  },
  {
    name: 'expected',
    label: 'Esperado',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false
  },
  {
    name: 'expected_cost',
    label: 'Costo esperado',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false
  },
  {
    name: 'taskRoute',
    label: 'Ruta',
    data_type: 'string',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true
  },
  {
    name: 'plan_endowment',
    label: 'Dotación Plan',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    ignoreGantt: false,
    resize: true,
    filterable: false,
    showColumCommitment: true,
    isProgress: true,
    isProductivity: true
  }
];
