import React from 'react';
import { Input } from 'antd';
import DependenciesAlertPopover from './components/DependenciesAlertPopover';
import DependenciesDropdownTypes from './components/DependenciesDropdownTypes';
import DependenciesRemoveAllPopover from './components/DependenciesRemoveAllPopover';
import DependenciesDropdownTask from './components/DependenciesDropdownTask';
import DependenciesGoToTask from './components/DependenciesGoToTask';

export const getMessageAlert = (type, t) => {
  const defaultMessage = t('activity_card.generic_text_alert');

  const messages = {
    empty: t('activity_card.empty_text_alert'),
    predecessor: t('activity_card.predecessor_text_alert'),
    successor: t('activity_card.successor_text_alert'),
    parent: t('activity_card.parent_text_alert'),
    circularLink: t('activity_card.circular_link_text_alert'),
    lagFormat: t('activity_card.lag_format_text_alert')
  };

  return messages[type] || defaultMessage;
};

export const templateTypesTaskDropdown = [
  {
    key: 'fs',
    label: 'FS',
    labelDropdown: 'FS (Finish - Start)',
    number: '0'
  },
  {
    key: 'ss',
    label: 'SS',
    labelDropdown: 'SS (Start - Start)',
    number: '1'
  },
  {
    key: 'ff',
    label: 'FF',
    labelDropdown: 'FF (Finish - Finish)',
    number: '2'
  },
  {
    key: 'sf',
    label: 'SF',
    labelDropdown: 'SF (Start - Finish)',
    number: '3'
  }
];

export const getCodeFromNumberType = (numberType) => {
  const type = {
    0: 'fs',
    1: 'ss',
    2: 'ff',
    3: 'sf'
  };

  return type[numberType] || 'fs';
};

export const validateNumberInput = (event) =>
  (event.target.value = /^[0-9]+$/.test(event.target.value)
    ? event.target.value
    : '');

export const templateDataPredecessors = (
  drawerUniqueIdTask,
  searchTask,
  removeTask,
  updateTask,
  removeAllTask,
  allTasksGantt,
  parentsTask,
  linkedActivities,
  t
) => {
  const showRemoveAllTaskIcon = linkedActivities.some(
    (activity) => activity !== undefined
  );

  return {
    templateColumns: '1fr 3fr 2fr 2fr 1fr',
    headerHeight: 24,
    columns: [
      {
        key: 'id_task',
        label: t('activity_card.id'),
        justifyContentHeader: 'center',
        renderHeader: null,
        onClickHeader: null,
        justifyContentColumn: 'center',
        renderColumn: (value, index) => (
          <Input
            type="text"
            defaultValue={value?.id}
            onKeyUp={(event) => validateNumberInput(event)}
            onPressEnter={(event) => event.target.blur()}
            onBlur={(event) =>
              searchTask(event.target.value, drawerUniqueIdTask, true, index)
            }
          />
        )
      },
      {
        key: 'name_task',
        label: t('activity_card.name'),
        justifyContentHeader: 'center',
        renderHeader: null,
        onClickHeader: null,
        justifyContentColumn: 'left',
        renderColumn: (value, index) => (
          <DependenciesDropdownTask
            value={value}
            tasks={allTasksGantt}
            parentsTask={parentsTask}
            currentIdDrawerTask={drawerUniqueIdTask}
            onClickSelectTask={(taskId) =>
              searchTask(taskId, drawerUniqueIdTask, true, index)
            }
            t={t}
            predecessor={true}
          />
        )
      },
      {
        key: 'type_task',
        label: t('activity_card.type'),
        justifyContentHeader: 'center',
        renderHeader: null,
        onClickHeader: null,
        justifyContentColumn: 'center',
        renderColumn: (value, index) =>
          value?.type && (
            <DependenciesDropdownTypes
              value={value}
              onClickSelectType={(typeNumber) =>
                updateTask(
                  value?.id,
                  drawerUniqueIdTask,
                  typeNumber,
                  null,
                  true,
                  index
                )
              }
            />
          )
      },
      {
        key: 'lag_task',
        label: t('activity_card.lag'),
        justifyContentHeader: 'center',
        renderHeader: null,
        onClickHeader: null,
        justifyContentColumn: 'center',
        renderColumn: (value, index) =>
          value?.lag && (
            <Input
              type="text"
              style={{ width: '100%' }}
              defaultValue={value?.lag}
              onPressEnter={(event) =>
                updateTask(
                  value?.id,
                  drawerUniqueIdTask,
                  null,
                  event.target.value,
                  true,
                  index
                )
              }
            />
          )
      },
      {
        key: 'icon_task',
        label: '',
        justifyContentHeader: 'center',
        renderHeader: () =>
          showRemoveAllTaskIcon && (
            <DependenciesRemoveAllPopover
              onClickButtonDelete={() =>
                removeAllTask(drawerUniqueIdTask, true)
              }
              t={t}
            />
          ),
        onClickHeader: null,
        justifyContentColumn: 'center',
        renderColumn: (value, index) =>
          value && (
            <>
              <DependenciesGoToTask activity={value} t={t} />
              <DependenciesAlertPopover
                onClickButtonDelete={() =>
                  removeTask(value?.id, drawerUniqueIdTask, true, index)
                }
                t={t}
              />
            </>
          )
      }
    ]
  };
};

export const templateDataSuccessors = (
  drawerUniqueIdTask,
  searchTask,
  removeTask,
  updateTask,
  removeAllTask,
  allTasksGantt,
  parentsTask,
  showRemoveAllTaskIcon,
  t
) => ({
  templateColumns: '1fr 3fr 2fr 2fr 1fr',
  headerHeight: 24,
  columns: [
    {
      key: 'id_task',
      label: t('activity_card.id'),
      justifyContentHeader: 'center',
      renderHeader: null,
      onClickHeader: null,
      typeColumn: 'input',
      justifyContentColumn: 'center',
      renderColumn: (value, index) => (
        <Input
          type="text"
          defaultValue={value?.id}
          onKeyUp={(event) => validateNumberInput(event)}
          onPressEnter={(event) => event.target.blur()}
          onBlur={(event) =>
            searchTask(event.target.value, drawerUniqueIdTask, false, index)
          }
        />
      )
    },
    {
      key: 'name_task',
      label: t('activity_card.name'),
      justifyContentHeader: 'center',
      renderHeader: null,
      onClickHeader: null,
      typeColumn: 'string',
      justifyContentColumn: 'left',
      renderColumn: (value, index) => (
        <DependenciesDropdownTask
          value={value}
          tasks={allTasksGantt}
          parentsTask={parentsTask}
          currentIdDrawerTask={drawerUniqueIdTask}
          onClickSelectTask={(taskId) =>
            searchTask(taskId, drawerUniqueIdTask, false, index)
          }
          t={t}
          predecessor={false}
        />
      )
    },
    {
      key: 'type_task',
      label: t('activity_card.type'),
      justifyContentHeader: 'center',
      renderHeader: null,
      onClickHeader: null,
      typeColumn: 'dropdow',
      justifyContentColumn: 'center',
      renderColumn: (value, index) =>
        value?.type && (
          <DependenciesDropdownTypes
            value={value}
            onClickSelectType={(typeNumber) =>
              updateTask(
                value?.id,
                drawerUniqueIdTask,
                typeNumber,
                null,
                false,
                index
              )
            }
          />
        )
    },
    {
      key: 'lag_task',
      label: t('activity_card.lag'),
      justifyContentHeader: 'center',
      renderHeader: null,
      onClickHeader: null,
      typeColumn: 'number',
      justifyContentColumn: 'center',
      renderColumn: (value, index) =>
        value?.lag && (
          <Input
            type="text"
            style={{ width: '100%' }}
            defaultValue={value?.lag}
            onPressEnter={(event) =>
              updateTask(
                value?.id,
                drawerUniqueIdTask,
                null,
                event.target.value,
                false,
                index
              )
            }
          />
        )
    },
    {
      key: 'icon_task',
      label: '',
      justifyContentHeader: 'center',
      renderHeader: () =>
        showRemoveAllTaskIcon && (
          <DependenciesRemoveAllPopover
            onClickButtonDelete={() => removeAllTask(drawerUniqueIdTask, false)}
            t={t}
          />
        ),
      onClickHeader: null,
      typeColumn: 'icon',
      justifyContentColumn: 'center',
      renderColumn: (value, index) =>
        value && (
          <>
            <DependenciesGoToTask activity={value} t={t} />
            <DependenciesAlertPopover
              onClickButtonDelete={() =>
                removeTask(value?.id, drawerUniqueIdTask, false, index)
              }
              t={t}
            />
          </>
        )
    }
  ]
});

const NO_PARENTS = 0;
export const getLengthArray = (maxLength, arrayLength) =>
  arrayLength > maxLength ? maxLength : maxLength - arrayLength;

export const orderTaskTree = (tasks) => {
  const map = {};
  let node;
  const roots = [];
  let i;

  for (i = 0; i < tasks.length; i += 1) {
    map[tasks[i].id] = i;
    tasks[i].children = [];
  }

  for (i = 0; i < tasks.length; i += 1) {
    node = tasks[i];
    if (!node) continue;
    const { $rendered_parent } = node;
    if ($rendered_parent !== NO_PARENTS) {
      const attrToPushNode = tasks[map[$rendered_parent]];
      if (attrToPushNode && attrToPushNode.children) {
        attrToPushNode.children.push(node);
      }
    } else {
      roots.push(node);
    }
  }
  return roots;
};
