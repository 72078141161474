import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { destroyGantt, openForm } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { zoomLevels } from '../../GanttVisualization/constants';

const ganttType = 'scheduleOfSchedules';
const ganttName = 'ganttScheduleOfSchedule';

export const useMountGanttAfterFetchProjects = ({
  scheduleFactory,
  setFormDeleteVisible,
  setFormProjectVisible,
  onClickProject,
  setZoomLevel,
  zoomLevel,
  filteredSchedules,
  ganttRef,
  isLoading,
  processedProjects,
  permissions,
  filterText,
  schedules
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projectState = useSelector(({ projectState }) => projectState);
  useEffect(() => {
    if (!filteredSchedules.length || !ganttRef.current || isLoading) {
      destroyGantt(ganttName);
      return;
    }

    const analytics = {
      search_text: filterText,
      results_count: filteredSchedules.length
    };

    const handleDelete = openForm({
      handler: setFormDeleteVisible,
      handlerName: 'setFormDeleteVisible',
      permissions,
      projectState,
      dispatch
    });

    const handleEdit = openForm({
      handler: setFormProjectVisible,
      handlerName: 'setFormProjectVisible',
      permissions,
      projectState,
      dispatch
    });

    const ganttOptions = {
      t,
      handleDelete,
      handleEdit,
      processedProjects,
      onClickProject,
      analytics,
      setZoomLevel
    };

    const gantt =
      window[ganttName] || scheduleFactory.create(ganttType, ganttOptions);

    if (!window[ganttName]) {
      window[ganttName] = gantt;
      gantt.init(ganttRef.current);
    }

    const visibleProjects = filteredSchedules.filter(({ name }) => name).length;

    gantt.setProjectsCount(visibleProjects, schedules.total);
    gantt.changeScaleVisualization(zoomLevels.length - 1 - zoomLevel);
    gantt.clearAll();
    gantt.parse({ data: filteredSchedules });
    gantt.sort((a, b) => a.name.localeCompare(b.name));
    gantt.render();
    gantt.createMarker();
  }, [filteredSchedules, processedProjects, isLoading, filterText, zoomLevel]);
};
