/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class PasswordResetService {
  constructor() {
    this.http = createHttpClient();
  }

  async create(data) {
    const res = await this.http.post('passwordresets', data).catch((error) => {
      console.log(error.response);
      return error.response;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('passwordresets/' + data.token, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async show(data) {
    const res = await this.http.get('passwordresets/' + data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }
}

export const passwordResetService = new PasswordResetService();
