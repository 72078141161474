import { StyleSheet } from '@react-pdf/renderer';
export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    width: '1000px'
  },
  text: {
    fontSize: 20,
    padding: 2,
    backgroundColor: '#61C74F',
    fontFamily: 'Roboto',
    color: '#000'
  },
  section_text_header: {
    flexDirection: 'column',
    alignSelf: 'center'
  },
  title_head_week: {
    fontSize: 9,
    color: '#2C3421',
    marginTop: '2px'
  },
  subtitle_head_week: {
    fontSize: 6,
    marginTop: '5px'
  },
  image: {
    width: '6px',
    height: '6px'
  },
  section_title: {
    marginTop: '18px',
    marginLeft: '5px',
    width: '100px'
  },
  title: {
    fontSize: 9,
    color: '#000',
    fontFamily: 'Roboto',
    marginTop: '2px'
  },
  title_lean_state: {
    fontSize: 9,
    fontFamily: 'Roboto',
    color: '#2C3421',
    marginTop: '2px'
  },
  title_lean_state_com: {
    fontSize: 9,
    fontFamily: 'Roboto',
    color: '#F59D04',
    marginTop: '2px'
  },
  title_name: {
    fontSize: 9,
    fontFamily: 'Roboto',
    color: '#000',
    marginTop: '5px'
  },
  table: {
    // backgroundColor: "#61C74F",
    flexGrow: 1,
    padding: 5,
    marginTop: 10,
    float: 'left',
    flexDirection: 'column'
  },
  table: {
    // backgroundColor: "#61C74F",
    flexGrow: 1,
    padding: 5,
    marginTop: 10,
    float: 'left',
    flexDirection: 'column'
  },
  section_header: {
    flexDirection: 'row',
    marginLeft: '15px',
    marginRight: '15px',
    marginTop: '15px',
    justifyContent: 'space-between'
  },
  section_header_pdf: {
    flexDirection: 'row',
    borderBottom: '1pt solid #D1D1D1',
    marginLeft: '10px',
    marginRight: '10px'
  },
  table_header: {
    flexDirection: 'row',
    flex: 0.3,
    backgroundColor: '#ffffff'
  },
  table_header_days: {
    flex: 0.2,
    backgroundColor: '#ffffff'
  },
  table_header_texto: {
    flexDirection: 'row',
    flex: 0.97,
    backgroundColor: '#ffffff'
  },
  table_header_title_column_name: {
    fontSize: 6,
    fontFamily: 'Roboto',
    padding: '7px',
    color: '#121212',
    textAlign: 'left'
  },
  table_header_title_column: {
    fontSize: 6,
    fontFamily: 'Roboto',
    padding: '7px',
    color: '#121212',
    textAlign: 'center'
  },
  table_task_title_name_texto: {
    height: 'auto',
    width: '100%',
    fontSize: 7,
    fontFamily: 'Roboto',
    // marginLeft: "2px",
    fontStyle: 'normal',
    color: '#121212'
  },
  table_task_title_name: {
    height: 'auto',
    width: '100%',
    fontSize: 7,
    fontFamily: 'Roboto',
    // marginLeft: "2px",
    fontStyle: 'normal',
    textAlign: 'center'
  },

  table_task_title_name__subcontract: {
    height: 'auto',
    width: '100%',
    fontSize: 7,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    textAlign: 'center'
  },

  table_task_title_name_parent: {
    height: 'auto',
    fontSize: 7,
    fontFamily: 'Roboto',
    marginLeft: '2px',
    fontStyle: 'normal',
    fontWeight: '1200',
    color: '#000000'
  },
  table_heade_title_name_hour: {
    fontSize: 6,
    fontFamily: 'Roboto',
    padding: '0px',
    color: '#121212'
  },
  section_more: {
    fontSize: 6,
    fontFamily: 'Roboto',
    padding: '7px',
    color: '#121212'
  },
  section_activity: {
    backgroundColor: '#B3B3B3',
    marginLeft: '10px',
    marginRight: '10px'
  },
  section_activity_title_name: {
    color: '#121212',
    fontSize: 6,
    fontFamily: 'Roboto',
    padding: '5px'
  },
  section_task: {
    flexDirection: 'row',
    // backgroundColor: "#000000",
    marginLeft: '10px',
    marginRight: '10px',
    height: 'auto',
    boxSizing: 'border-box',
    borderBottom: '1pt solid #D1D1D1',
    borderRight: '1pt solid #D1D1D1'
    // backgroundColor: "#44014C"
    // border: "1px solid black"
  },
  section_task_parent: {
    flexDirection: 'row',
    // backgroundColor: "#000000",
    marginLeft: '10px',
    marginRight: '10px',
    height: 'auto',
    boxSizing: 'border-box',
    borderBottom: '1pt solid #D1D1D1',
    borderRight: '1pt solid #D1D1D1',
    backgroundColor: '#E1E1E1'
    // border: "1px solid black"
  },
  table_task_column_name: {
    height: 'auto',
    backgroundColor: '#ffffff',
    boxSizing: 'border-box',
    borderRight: '1pt solid #D1D1D1',
    borderLeft: '1pt solid #D1D1D1',
    paddingBottom: '2px',
    paddingTop: '2px',
    flexDirection: 'row',
    flex: 1

    // border: "1pt solid black",
  },
  table_task_texto_parent: {
    width: '107px',
    height: 'auto',
    backgroundColor: '#E1E1E1',
    boxSizing: 'border-box',
    borderRight: '1pt solid #D1D1D1',
    borderLeft: '1pt solid #D1D1D1',
    paddingBottom: '2px',
    paddingTop: '2px',
    flexDirection: 'row'

    // border: "1pt solid black",
  },
  table_task_real: {
    width: '30px',
    marginLeft: '5px',
    flexDirection: 'row',

    boxSizing: 'border-box',
    borderRight: '1pt solid #D1D1D1',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_real_parent: {
    width: '30px',
    marginLeft: '5px',
    flexDirection: 'row',
    backgroundColor: '#E1E1E1',
    boxSizing: 'border-box',
    borderRight: '1pt solid #D1D1D1',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_column: {
    flexDirection: 'row',
    flex: 0.3,
    borderRight: '1pt solid #D1D1D1',
    paddingBottom: '2px',
    paddingTop: '2px',
    justifyContent: 'center'
  },
  table_task_commitment_parent: {
    marginLeft: '5px',
    flexDirection: 'row',
    backgroundColor: '#E1E1E1',
    boxSizing: 'border-box',
    borderRight: '1pt solid #D1D1D1',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_resource: {
    width: '50px',
    marginLeft: '5px',
    flexDirection: 'row',

    boxSizing: 'border-box',
    borderRight: '1pt solid #D1D1D1',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_resource_parent: {
    width: '50px',
    marginLeft: '5px',
    flexDirection: 'row',
    backgroundColor: '#E1E1E1',
    boxSizing: 'border-box',
    borderRight: '1pt solid #D1D1D1',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_days: {
    flexDirection: 'row',
    flex: 0.2,
    boxSizing: 'border-box',
    borderRight: '1pt solid #D1D1D1',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_days_end: {
    width: '35.25px',
    marginLeft: '0px',
    flexDirection: 'row',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_days_parent: {
    width: '35.25px',
    marginLeft: '0px',
    flexDirection: 'row',
    // backgroundColor: "#121212",
    boxSizing: 'border-box',

    paddingBottom: '2px',
    paddingTop: '2px'
  },
  image_logo: {
    height: '25px',
    width: 'auto',
    objectFit: 'contain',
    alignSelf: 'center',
    marginRight: '10px'
  },
  pagination: {
    position: 'absolute',
    width: '100%',
    height: '50px',
    bottom: '0px'
    // backgroundColor: "red"
  },

  pagination_text: {
    position: 'absolute',
    bottom: '23px',
    left: '10px',
    // backgroundColor: "red",
    fontSize: 9,
    fontFamily: 'Roboto',
    padding: '7px',
    color: '#121212'
  },
  pagination_text_count: {
    position: 'absolute',
    bottom: '23px',
    right: '33px',
    // backgroundColor: "red",
    fontSize: 9,
    fontFamily: 'Roboto',
    padding: '7px',
    color: '#121212'
  }
});
