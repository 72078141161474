import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { propertiesService } from '../services/properties.service';
import { propertiesActions } from '../redux/actions/propertiesAction';

export const useProperties = () => {
  const dispatch = useDispatch();

  const {
    propertiesState: { properties }
  } = useSelector((state) => state);

  useEffect(() => {
    getProperties();
  }, []);

  const getProperties = async () => {
    const response = await propertiesService.index();

    if (response?.properties) {
      dispatch(propertiesActions.setProperties(response?.properties));
    }
  };

  return { properties };
};
