import React from 'react';
import ReactDOM from 'react-dom';
import { statusArray } from '../../../../utils/lookahead-common';

const RenderStatus = (props) => {
  /** props */
  const { gantt, task } = props;

  /** validations */
  if (!task?.isTask && gantt) return '';

  let isOdd = false;
  if (gantt.oddColsConfig && task?.type === 'activitytask') {
    isOdd = gantt.oddColsConfig.status;
  }
  if (!task?.isTask) return '';

  const defineIcon = (option) => {
    return `<img src=${option?.customIcon} width="15" />`;
  };

  /** define icon */
  const optionData = statusArray.filter((f) => f.value == task.status);

  /** define tooltip */
  var tooltipTitle = '';
  tooltipTitle = optionData[0]?.label;
  let classOdd = isOdd ? 'odd-col' : 'non-odd-col';

  return `<div class="${classOdd}">
        <span class="vertical-center" title="${tooltipTitle}">
            ${defineIcon(optionData[0])}
        </span>
    </div>`;
};

export default RenderStatus;
