import React, { useState, useRef, useEffect } from 'react';
import { ArrowDownIcon } from '../../../icons';
import DropdownSelectorSystem from './DropdownSelectorSystem';
import { useOutsideHideComponent, useWindowSize } from '../utils';

const SelectorSystem = ({
  width = '100%',
  size = 'default',
  theme = 'default',
  placeholder = 'Default',
  options = [],
  showArrow = true,
  shortText = false,
  maxLengthText = 100,
  disabled = false,
  isError = false,
  onClick = null,
  forceClose = false,
  forcePosition = false,
  groupedInput = null
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isRunning, setIsRunning] = useState(true);
  const [dropdownPosition, setDropdownPosition] = useState({});

  const wrapperRef = useRef(null);
  useOutsideHideComponent(wrapperRef, setShowDropdown, isRunning);

  useEffect(() => {
    const currentPosition = getPositionDropdown();
    setDropdownPosition(currentPosition);
  }, []);

  useEffect(() => {
    if (forceClose) {
      setShowDropdown(false);
    }
  }, [forceClose]);

  useEffect(() => {
    if (forcePosition) {
      const newPosition = getPositionDropdown();
      setDropdownPosition(newPosition);
    }
  }, [forcePosition]);

  const getPositionDropdown = () => {
    const currentPosition = {
      top: wrapperRef?.current?.getBoundingClientRect()?.top || 0,
      left: wrapperRef?.current?.getBoundingClientRect()?.left || 0,
      width: wrapperRef?.current?.getBoundingClientRect().width || 0
    };

    return currentPosition;
  };

  useWindowSize(getPositionDropdown, setDropdownPosition);

  const handleClickSelector = () => {
    if (onClick) onClick();
    setShowDropdown((prevState) => !prevState);
  };

  const getTextLabel = (label) => {
    if (!label) return 'Default';
    if (shortText)
      return label.length > maxLengthText
        ? `${label.substring(0, maxLengthText)}...`
        : label;

    return label;
  };

  return (
    <div
      ref={wrapperRef}
      className={`container__selector size--${size} theme--${theme} ${options.length > 0 ? 'pointer' : ''} ${disabled ? 'disabled' : ''} ${isError ? 'error' : ''}`}
      style={{ width }}
      onClick={() => options.length > 0 && !disabled && handleClickSelector()}>
      <label
        className={`${options.length > 0 ? 'pointer' : ''} ${disabled ? 'disabled' : ''}`}>
        {getTextLabel(
          options?.find((option) => option && option.active)?.label ||
            placeholder
        )}
      </label>
      {showArrow && (
        <div className={`selector__arrow ${showDropdown ? 'rotate' : ''}`}>
          <ArrowDownIcon color={isError ? '#FA7676' : '#FFF'} />
        </div>
      )}

      <DropdownSelectorSystem
        show={showDropdown}
        theme={theme}
        selectorSize={size}
        options={options}
        shortText={shortText}
        maxLengthText={maxLengthText}
        setShowDropdown={setShowDropdown}
        setIsRunning={setIsRunning}
        dropdownPosition={dropdownPosition}
        groupedInput={groupedInput}
      />
    </div>
  );
};

export default SelectorSystem;
