import React, { useState } from 'react';

export const ProductivityAssignCtx = React.createContext();

const ProductivityProvider = ({ children }) => {
  const [resourcesSelected, setResourcesSelected] = useState([]);
  const [workersSelected, setworkersSelected] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const assignment = {
    cleanSelection: () => {
      setRefresh(!refresh);
      setResourcesSelected([]);
      setworkersSelected([]);
    },
    get resourceSelected() {
      return resourcesSelected;
    },
    get workerSelected() {
      return workersSelected;
    },
    /**
     * @param {any} resources
     */
    set addResourceSelected(resources) {
      setResourcesSelected([...resourcesSelected, ...resources]);
    },
    /**
     * @param {any} workers
     */
    set addWorkerSeledted(workers) {
      setworkersSelected([...workersSelected, ...workers]);
    },
    /**
     * @param {any} resources
     */
    /**
     * @param {any} workers
     */
    set toRemoveWorkerSelection(workers) {
      setworkersSelected(workers);
    },
    /**
     * @param {any} resources
     */
    set toRemoveResourceSelection(resources) {
      setResourcesSelected(resources);
    }
  };

  return (
    <ProductivityAssignCtx.Provider value={assignment}>
      {children}
    </ProductivityAssignCtx.Provider>
  );
};

export default ProductivityProvider;
