import EventEmitter from 'react-native-eventemitter';
import { init_scroll_zoom_config } from '../../../../../assets/js/gantt_layout/scroll-zooming-conf';

const setScaleVisualizaTionFilter = (zoomLevel = {}, gantt) => {
  if (zoomHasNoConfiguration(zoomLevel)) return;
  const { type, code } = zoomLevel;
  EventEmitter.emit('changeScaleVisualization', { value: type });
  localStorage.setItem('scale', JSON.stringify(type));
  gantt.ext.zoom.init(init_scroll_zoom_config(gantt));
  gantt.ext.zoom.setLevel(code);
};

function zoomHasNoConfiguration(zoomLevel) {
  return !Object.keys(zoomLevel).length;
}

export default setScaleVisualizaTionFilter;
