const validateMoveTaskDragAndDrop = (task) => {
  const isMoveTaskDragAndDrop = Boolean(
    task?.new_dates_before_drag &&
      task.new_dates_before_drag?.start_date &&
      task.new_dates_before_drag?.end_date
  );
  return isMoveTaskDragAndDrop;
};

const validateChangeTaskForMassive = (task) => {
  const ischangeMassive = Boolean(task.checked || task.visibleChecked);
  return ischangeMassive;
};
/**
    This function is used to filter commands generated by bulk changes
    @param {object} commands This is the undoStack command
*/
const filterCommandsForMassive = (commands) => {
  let isFiltered = false;
  let isChangeDragAndDrop = false;
  const taskDragReplace = [];

  const validateIsLinkOrRemove = (command) => {
    const { value, entity, type } = command;
    const isLinkOrRemove = entity === 'link' || type === 'remove';
    return isLinkOrRemove;
  };

  const validateIsMoveOrMassive = (command) => {
    const { value } = command;
    const isMoveDragAndDrop = validateMoveTaskDragAndDrop(value);
    const ischangeMassive = validateChangeTaskForMassive(value);

    const isMoveOrMassive = !isMoveDragAndDrop && !ischangeMassive;
    return { isMoveOrMassive, isMoveDragAndDrop };
  };

  const validateIsDuplicateID = (taskId, tasks) => {
    let duplicateID = false;
    if (tasks.includes(taskId)) duplicateID = true;
    return duplicateID;
  };
  const commandsFiltered = commands.filter((command) => {
    const isLinkOrRemove = validateIsLinkOrRemove(command);
    if (isLinkOrRemove) return true;

    const { isMoveOrMassive, isMoveDragAndDrop } =
      validateIsMoveOrMassive(command);
    if (isMoveOrMassive) return false;

    if (isMoveDragAndDrop) isChangeDragAndDrop = true;
    isFiltered = true;
    const { value } = command;
    const taskId = value.id;

    const isNotDuplicateID = !validateIsDuplicateID(taskId, taskDragReplace);
    if (isNotDuplicateID) taskDragReplace.push(taskId);

    return isNotDuplicateID;
  });

  return {
    commandsFiltered,
    isFiltered,
    isChangeDragAndDrop
  };
};

const calculateDurationInteger = (gantt, duration) => {
  const hoursPerDay = gantt.config.hoursPerDay;
  const res = Math.ceil(duration / hoursPerDay);
  return res * hoursPerDay;
};

/**
    This function is used to update the duration and end date of tasks
    @param {object} commands This is the undoStack command
*/
const updateDurationAndEndDate = (gantt, oldValue, newValue) => {
  const isMassiveDuration = Boolean(newValue.old_massive_duration);
  if (isMassiveDuration) {
    oldValue.duration = newValue.old_massive_duration;
    oldValue.for_disable_milestone_duration = newValue.old_massive_duration;
    delete newValue.old_massive_duration;
  } else oldValue.duration = oldValue.for_disable_milestone_duration;
  newValue.duration = newValue.for_disable_milestone_duration;

  oldValue.duration = calculateDurationInteger(gantt, oldValue.duration);
  newValue.duration = calculateDurationInteger(gantt, newValue.duration);
  newValue.for_disable_milestone_duration = calculateDurationInteger(
    gantt,
    newValue.for_disable_milestone_duration
  );
  if (oldValue.duration > 0) {
    // do not update end_date time in milestone tasks
    const constraint_type = oldValue.constraint_type;
    const constraint_date = oldValue.constraint_date;
    oldValue.constraint_type = 'asap';
    oldValue.constraint_date = null;

    const calendar = gantt.getCalendar(oldValue.calendar_id);
    const new_date_end = calendar.calculateEndDate(
      oldValue.start_date,
      oldValue.duration
    );

    oldValue.end_date = new_date_end;

    oldValue.constraint_type = constraint_type;
    oldValue.constraint_date = constraint_date;
    if (oldValue.type === 'milestone') oldValue.type = 'task';
  }

  if (newValue.duration > 0) {
    // do not update end_date time in milestone tasks
    const calendar = gantt.getCalendar(newValue.calendar_id);
    const new_date_end = calendar.calculateEndDate(
      newValue.start_date,
      newValue.duration
    );
    newValue.end_date = new_date_end;
  }

  if (newValue.is_milestone) newValue.type = 'milestone';

  return { oldTaskValue: oldValue, newTaskValue: newValue };
};

/**
    This function is used to update tasks with basic changes.
    @param {object} commands This is the undoStack command
*/
const updateTaskCommands = (oldValue, newValue) => {
  oldValue.baseline_points = newValue.baseline_points;
  oldValue.baselineObject = newValue.baselineObject;

  if (newValue.undo_constraint_type) {
    oldValue.constraint_type = newValue.undo_constraint_type;
    oldValue.constraint_date = newValue.undo_constraint_date;
    oldValue.start_date = newValue.undo_start_date;
    oldValue.end_date = newValue.undo_end_date;
  }

  if (
    newValue?.new_dates_before_drag?.mode === 'resize' &&
    newValue.constraint_type === 'mfo'
  ) {
    newValue.start_date = oldValue.start_date;
    newValue.end_date = oldValue.end_date;
    newValue.duration = oldValue.duration;
    newValue.for_disable_milestone_duration =
      oldValue.for_disable_milestone_duration;
  }
  return { oldTaskValue: oldValue, newTaskValue: newValue };
};

/**
    This function is used to update the undoStack with the original task data before dragging and dropping.
    @param {object} undoElement This is the undoStack command, it saves the changes before and after the task.
    @returns {object} returns the updated command
*/

const updateCommandsForDragAndDrop = (commandsFilteredDrag, gantt) => {
  const updateCommands = commandsFilteredDrag.map((command) => {
    const { oldValue, value: newValue, entity, type } = command;

    // Tasks that have been modified with drag&drop have their initial values ​​updated
    if (type === 'update' && entity === 'task') {
      const isMoveDragAndDrop = validateMoveTaskDragAndDrop(newValue);
      if (isMoveDragAndDrop) {
        oldValue.start_date = newValue.new_dates_before_drag.start_date;
        oldValue.end_date = newValue.new_dates_before_drag.end_date;
        oldValue.duration = newValue.new_dates_before_drag.duration;
        oldValue.for_disable_milestone_duration =
          newValue.new_dates_before_drag.for_disable_milestone_duration;
        oldValue.constraint_type =
          newValue.new_dates_before_drag.constraint_type_old;
        oldValue.constraint_date =
          newValue.new_dates_before_drag.constraint_date;
        oldValue.baseline_points =
          newValue.new_dates_before_drag.baseline_points;
        oldValue.calendar_id =
          newValue.new_dates_before_drag.task_original.calendar_id;
        oldValue.status = newValue.new_dates_before_drag.task_original.status;
        oldValue.text = newValue.text;

        newValue.constraint_type =
          newValue.new_dates_before_drag.constraint_type;
        newValue.constraint_date = newValue.start_date;

        const calendar = gantt.getCalendar(newValue.calendar_id);
        const new_date_end = calendar.calculateEndDate(
          newValue.start_date,
          newValue.duration
        );
        newValue.end_date = new_date_end;
      }
    }
    return command;
  });
  return updateCommands;
};

/**
    This function is used to update the undoStack with the original task data before dragging and dropping.
    @param {object} undoElement This is the undoStack command, it saves the changes before and after the task.
    @returns {object} returns the updated command
*/

const handleCommandUpdateBulkAndDrag = (gantt, undoElement) => {
  const { commands, isFilteredDrag } = undoElement;
  let isCommandDrag = Boolean(isFilteredDrag);
  let updateCommandsDragAndDrop = false;

  // Delete commands created by autoSchedule when performing drag&drop
  let commandsFilteredDrag = commands;
  if (!isCommandDrag) {
    const { commandsFiltered, isFiltered, isChangeDragAndDrop } =
      filterCommandsForMassive(commands);
    isCommandDrag = isFiltered;
    if (isFiltered) commandsFilteredDrag = commandsFiltered;
    updateCommandsDragAndDrop = isChangeDragAndDrop;
  }

  // If the command has not performed a drag&drop, the editing and filter are canceled
  if (!isCommandDrag || !updateCommandsDragAndDrop)
    return { commands: commandsFilteredDrag, isFilteredDrag: true };
  const commandsUpdateFilteredDrag = updateCommandsForDragAndDrop(
    commandsFilteredDrag,
    gantt
  );
  return { commands: commandsUpdateFilteredDrag, isFilteredDrag: true };
};

/**
    This function is used to update the undoStack with the original task data.
    @param {object} undoElement This is the undoStack command, it saves the changes before and after the task.
    @returns {object} returns the updated command
*/

const allCommandUpdate = (gantt, undoElement) => {
  const { commands } = undoElement;
  const commandsUpdate = commands.map((command) => {
    const { entity, type } = command;
    let { oldValue, value: newValue } = command;

    if (newValue.value_start_add && oldValue.text === 'New task') {
      oldValue = newValue.value_start_add;
    }

    if (entity === 'task') {
      const { oldTaskValue, newTaskValue } = updateDurationAndEndDate(
        gantt,
        oldValue,
        newValue
      );
      oldValue = oldTaskValue;
      newValue = newTaskValue;
    }

    if (entity === 'task' && type === 'update') {
      const ismoveDrag =
        newValue?.new_dates_before_drag &&
        newValue.new_dates_before_drag?.mode !== 'resize';
      if (ismoveDrag) {
        return { ...command, oldValue, value: newValue };
      }

      const { oldTaskValue, newTaskValue } = updateTaskCommands(
        oldValue,
        newValue
      );
      oldValue = oldTaskValue;
      newValue = newTaskValue;
    }

    // Links with lag undefined are updated to lag equal to 0.
    if (entity === 'link' && type === 'update') {
      if (oldValue.lag === undefined || oldValue.lag === null) {
        oldValue.lag = 0;
      }
    }
    return { ...command, oldValue, value: newValue };
  });
  return { commands: commandsUpdate };
};

/**
    TThis function is used to join the two last elements of the undoStack
    @param {object} undoStack This is the undoStack command, it saves the changes before and after the task.
*/
const lastTwoUndoJoinElements = (gantt, undoStack) => {
  const undoStackLength = undoStack.length;
  if (undoStackLength < 2) return;

  const initUndoStack = undoStack.slice(0, undoStackLength - 2);
  const secondLastUndoCommand = undoStack[undoStackLength - 2].commands;
  const lastUndoCommand = undoStack[undoStackLength - 1].commands;
  const dir = Boolean(gantt.ext.undo.undoStackOldReverse);
  let newLastUndoCommand;

  if (dir) {
    newLastUndoCommand = {
      commands: lastUndoCommand.concat(secondLastUndoCommand)
    };
    delete gantt.ext.undo.undoStackOldReverse;
  } else {
    newLastUndoCommand = {
      commands: secondLastUndoCommand.concat(lastUndoCommand)
    };
  }

  initUndoStack.push(newLastUndoCommand);
  gantt.ext.proplannerCustomUndoMonitor._undo._undoStack = initUndoStack;
};

const modifyUndoStack = (gantt) => {
  if (
    !gantt.ext.undo.isRunUndoStack ||
    gantt.ext.undo.ignoreAutoSchedule ||
    gantt.ext.undo.undoRedoDisabled
  ) {
    gantt.ext.undo.ignoreAutoSchedule = false;
    return;
  }

  gantt.ext.undo.undoDisabled = false;
  gantt.ext.undo.undoRedoDisabled = false;
  const redoDisabledJoin = gantt.ext.undo.redoDisabledJoin;

  let undoStack = gantt.ext.undo.getUndoStack();
  let undoStackLength = undoStack.length;
  if (!redoDisabledJoin && undoStackLength > 0) {
    const undoStackOldAutoSchedule = gantt.ext.undo.undoStackOld;
    const isAutoScheduledModified = Boolean(undoStackOldAutoSchedule >= 0);
    // Join last change with autoSchedule update in undoStack
    if (
      isAutoScheduledModified &&
      undoStackOldAutoSchedule + 1 < undoStackLength
    ) {
      lastTwoUndoJoinElements(gantt, undoStack);
      undoStack = gantt.ext.undo.getUndoStack();
      undoStackLength = undoStack.length;
    }
    // Update last task command for tasks moved with drag and drop
    let lastUndoCommand = undoStack[undoStackLength - 1];
    if (isAutoScheduledModified || gantt.ext.undo.updateCommandRedo)
      lastUndoCommand = handleCommandUpdateBulkAndDrag(gantt, lastUndoCommand);
    // Update last command with general updates
    lastUndoCommand = allCommandUpdate(gantt, lastUndoCommand);
    undoStack[undoStackLength - 1] = lastUndoCommand;

    gantt.ext.proplannerCustomUndoMonitor._undo._undoStack = undoStack;
  }

  gantt.ext.undo.updateCommandRedo = false;
  gantt.ext.undo.isRunUndoStack = false;
};

export { modifyUndoStack };
