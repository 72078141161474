/* eslint-disable no-case-declarations */
import {
  SET_WEEKLYPLAN_ACTIVATED_COLS,
  NOTIFY_LOOKAHEAD_UPDATE_WEEKLYPLAN,
  NOTIFY_LOOKAHEAD_UPDATE_GROUP_WEEKLYPLAN,
  NOTIFY_LOOKAHEAD_UPDATE_FILTER_WEEKLYPLAN,
  NOTIFY_LOOKAHEAD_UPDATE_ORDER_WEEKLYPLAN
} from '../actions/weeklyplanActions';

/**
 * Reducer function means a switch case to filter action data on the platform for general state
 * @param {*} state Real time updated through all components state (general state flux)
 * @param {*} action Action is an object with structyure { type: STRING, payload: FUNCTION }
 */
function weeklyplanReducer(
  state = {
    activatedColumns: [],
    notifyChange: false,
    notifyChangeGroup: false,
    notifyChangeFilter: false,
    notifyChangeOrder: false
  },
  action
) {
  switch (action.type) {
    case SET_WEEKLYPLAN_ACTIVATED_COLS:
      return {
        ...state,
        activatedColumns: action.payload
      };
    case NOTIFY_LOOKAHEAD_UPDATE_WEEKLYPLAN:
      return {
        ...state,
        notifyChange: !state.notifyChange
      };
    case NOTIFY_LOOKAHEAD_UPDATE_GROUP_WEEKLYPLAN:
      return {
        ...state,
        notifyChangeGroup: !state.notifyChangeGroup
      };
    case NOTIFY_LOOKAHEAD_UPDATE_FILTER_WEEKLYPLAN:
      return {
        ...state,
        notifyChangeFilter: !state.notifyChangeFilter
      };
    case NOTIFY_LOOKAHEAD_UPDATE_ORDER_WEEKLYPLAN:
      return {
        ...state,
        notifyChangeOrder: !state.notifyChangeOrder
      };
    default:
      return state;
  }
}

export default weeklyplanReducer;
