import { cloneDeep } from 'lodash';
import { updateTaskTiming } from '../utils';

function setInitialHourDay(task) {
  const clonedStartDate = cloneDeep(task.start_date);
  clonedStartDate.setHours(0);
  clonedStartDate.setMinutes(0);
  task.start_date = clonedStartDate;
  updateTaskTiming({ task });
}

export { setInitialHourDay };
