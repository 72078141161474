import React from 'react';
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import ReactResizeDetector from 'react-resize-detector';
import { Resizable } from 're-resizable';
import { Col } from 'antd';
const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'solid 0px #ddd',
  background: '#f0f0f000'
};
const DragHandle = sortableHandle((props) => {
  const { value } = props;
  return (
    <div
      style={{
        cursor: 'move',
        color: '#121212',
        fontSize: '13px',
        lineHeight: '15px'
      }}>
      {value.label}
    </div>
  );
});
const SortableItem = SortableElement((props) => {
  const { value, sortIndex } = props;
  let w = 0;
  return (
    <Col
      key={sortIndex}
      span={value.span}
      offset={value.offset}
      style={{ textAlign: value.align, width: value.width }}>
      <ReactResizeDetector
        handleWidth
        onResize={(width, height) => {
          w = width;
        }}>
        <Resizable
          className="custom-resize-col-lookahead"
          maxWidth={1000}
          onMouseDown={() => props.onDivResizeDoubleClicked(value, true)}
          onResizeStop={() => props.onDivResizeHandler(w, value)}
          style={{ ...style, overflow: 'hidden' }}
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
          size={{
            width: value.width ? value.width : '100%',
            height: 18
          }}>
          <DragHandle value={value} />
        </Resizable>
      </ReactResizeDetector>
    </Col>
  );
});

export default SortableContainer((props) => (
  <Col>
    {props.items.map((value, index) => {
      if (value.visible) {
        return (
          <SortableItem
            {...props}
            key={`item-${index}`}
            index={index}
            sortIndex={index}
            value={value}
          />
        );
      }
    })}
  </Col>
));
