function refreshCriticalPathIsNeeded(
  criticalPathRefresh,
  validateCriticalNeed
) {
  const gantt = window.to_use_react_gantt;

  const columns_validate = gantt.getGridColumns();
  const isCriticalPathHighlightEnabled = gantt.config.highlight_critical_path;
  const isCriticalNeedValidColumnn = validateCriticalNeed(columns_validate);

  if (isCriticalPathHighlightEnabled || isCriticalNeedValidColumnn) {
    criticalPathRefresh();
  }
}

export { refreshCriticalPathIsNeeded };
