import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row } from 'antd';
import filterIcon from '../../assets/img/takt/filter.png';
import configurationIcon from '../../assets/img/takt/config.png';
import scrollActions from './scroll.actions';
import styles from './Matrix.module.scss';

/** Redux */
import { useSelector, useDispatch } from 'react-redux';

/** services */
import { sectorResourcesService } from '../../services/sectorresource.service';
import { analyticResourceServiceFollow } from '../../services/analyticresourcefollow.service';

import { analyticResourceService } from '../../services/analyticresource.service';

/** subcomponents */
import { ResourcesMatrix } from './subComponents/matrix/ResourcesMatrix';
import { GridSectorResources } from './subComponents/resources/Resources';
import { GrindSummaryRow } from './subComponents/summaryRow/SummaryRow';
import { GridHeader } from './subComponents/headerMatrix/ResourceHeader';
import { RenderSettings } from '../matrixResourceSettings/MatrixSettings';
import { RenderModalFilter } from '../SectorResourceFilter/ResourceFilter';
import {
  GridSummaryColContent,
  GridSummaryCol,
  populateRows
} from './subComponents/summaryCol/SummaryCol';

import moment from 'moment';

export const MatrixOfResources = ({ t }) => {
  // grid sizes
  const [contentWidth, setContentWidth] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const [sectorWidth, setSectorWidth] = useState(350);
  const [sectorHeight, setSectorHeight] = useState(300);
  const [showResourceFilter, setShowResourceFilter] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [resources, setResources] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [selectedResources, setSelectedResources] = useState([]);
  const [resourceData, setResourceData] = useState([]);
  const [data, setData] = useState([]);
  const [dates, setDates] = useState([]);
  const [shown, setShown] = useState('monthly');
  const [summaryColData, setSummaryColData] = useState([]);
  const [reRender, setRerender] = useState(false);
  const [selectSectorIds, setSelectSectorIds] = useState([]);
  const $gridContentValues = useRef(null);
  const $gridSectorResource = useRef(null);
  const $resourceHeader = useRef(null);
  const $listContent = useRef(null);
  const $matrixContent = useRef(null);
  const $totalContent = useRef(null);
  const $totalColContent = useRef(null);
  const [nowIndex, setNowIndex] = useState(0);

  const projectState = useSelector((state) => state.projectState);
  const projetId = projectState.projectSelected;
  const allSectorsIds = projectState.allSectors.map((sector) => sector.id);

  useEffect(() => {
    if (firstLoad) {
      getResources();
      setFirstLoad(false);
    }
  });
  useEffect(() => {
    const _resourcesData = [];
    const sector_tem_id = [];
    const copySelectedResources = [...selectedResources];
    resources.map((resource) => {
      const sector = projectState.allSectors.filter(
        (sector) => sector.id == resource.sectorresource[0].sectorId
      )[0];
      if (resource.sectorresource.length > 0) {
        resource.sectorresource.map((recurso) => {
          const resources = [];
          if (copySelectedResources.includes(recurso.id)) {
            const indexResource = copySelectedResources.indexOf(recurso.id);
            copySelectedResources.splice(indexResource, 1);
            resources.push({
              resourceName: recurso.name,
              resourceId: recurso.id,
              unidad: recurso.material_label
            });
            _resourcesData.push({
              sectorId: sector.id,
              sectorName: sector.name,
              resources: resources,
              r_id: recurso.id,
              r_name: recurso.name,
              unidad: recurso.material_label
            });
            sector_tem_id.push(sector.id);
          }
        });
      }
    });
    setSelectSectorIds(sector_tem_id);
    _resourcesData.sort((a, b) => {
      return a.r_id - b.r_id;
    });
    setResourceData(_resourcesData);
  }, [reRender]);

  const getResources = async () => {
    const arrayResources = [];
    allSectorsIds.map(async (sector) => {
      const response = await sectorResourcesService.searchBySector(sector);
      if (response.sectorresource.length > 0) {
        arrayResources.push(response);
      }
    });
    setResources(arrayResources);
  };
  const handleCancel = (e) => {
    setShowResourceFilter(false);
    setShowSettings(false);
  };
  const handleOk = (_selectedResources) => {
    _selectedResources.sort((a, b) => {
      return a - b;
    });
    setSelectedResources(_selectedResources);
    setShowResourceFilter(false);
    setRerender(!reRender);
  };

  const shownHandleOk = (_selectedShown) => {
    setShown(_selectedShown);
    setShowSettings(false);
  };

  useEffect(() => {
    if (resourceData.length > 0 && selectSectorIds.length > 0) {
      getData();
    }
  }, [resourceData, shown, selectSectorIds]);

  useEffect(() => {
    if (data[0] != undefined) {
      const { partialValues } = data[0];
      const dates = partialValues.map((value) => {
        if (shown == 'weekly') {
          return moment(value.weeks.dateWeek).format('DD MMM');
        } else {
          return moment(value.weeks.dateWeek).format('MMMM');
        }
      });
      setDates(dates);
      const _summaryColData = populateRows(data, resourceData);
      setSummaryColData(_summaryColData);
    }
  }, [data]);

  const gridContentRef = useCallback((node) => {
    if (node != null) {
      setContentWidth(node.clientWidth);
    }
  });

  const getData = async () => {
    const _data = await analyticResourceService.resourceMatrix({
      project_id: [projetId],
      resources: selectedResources,
      periodicity: shown
    });

    const _dataFollow = await analyticResourceServiceFollow.resourceMatrix({
      sectorId: selectSectorIds,
      resources: selectedResources,
      periodicity: shown
    });
    setNowIndex(_data[0].partialValues.length);

    const date_of_end_real =
      shown == 'weekly'
        ? _data[0].partialValues[_data[0].partialValues.length - 1].weeks
            .dateWeek
        : moment(
            _data[0].partialValues[_data[0].partialValues.length - 1].weeks
              .dateWeek
          )
            .endOf('month')
            .format('YYYY-MM-DD');
    _dataFollow.alldatesinterval.map((dt) => {
      console.log('MatrixOfResources map', dt.dt);
    });

    let init_add_validate = true;
    for (let i = 0; i < _dataFollow.alldatesinterval.length; i++) {
      const date =
        shown == 'weekly'
          ? moment(_dataFollow.alldatesinterval[i].dt)
              .add(6, 'day')
              .format('YYYY-MM-DD')
          : moment(_dataFollow.alldatesinterval[i].dt)
              .endOf('month')
              .format('YYYY-MM-DD');

      if (init_add_validate) {
        if (date === date_of_end_real) {
          init_add_validate = false;
        }
      } else {
        const array_of_resorces = _dataFollow.follow_up_matrix.filter((r) => {
          const date_of_resorce =
            shown == 'weekly'
              ? moment(r.date).add(6, 'day').format('YYYY-MM-DD')
              : moment(r.date).endOf('month').format('YYYY-MM-DD');
          return date_of_resorce === date;
        });
        if (array_of_resorces.length > 0) {
          const data = {};
          array_of_resorces.map((resource) => {
            data[resource.resource] = resource.total_quantity;
          });

          const weeks = {
            data: data,
            dateWeek: date
          };
          _data[0].partialValues.push({
            weeks: weeks
          });
        }
      }
    }
    setData(_data);
  };

  const onScroll = useCallback(({ scrollFrom }) => (scroll) => {
    const { scrollLeft, scrollTop, scrollUpdateWasRequested } = scroll;
    if (!scrollUpdateWasRequested) {
      scrollActions[scrollFrom]({
        header: $resourceHeader,
        list: $listContent,
        matrix: $matrixContent,
        total: $totalContent,
        total_col: $totalColContent,
        scrollLeft,
        scrollTop
      });
    }
  });

  const renderGrids = () => {
    if (resourceData.length > 0 && data.length > 0) {
      return (
        <div className={styles.gridContainer}>
          <div></div>
          <GridHeader
            className={styles.gridHeader}
            data={dates}
            gridWidth={contentWidth}
            resourceHeaderRef={$resourceHeader}
            onScroll={onScroll}
          />
          <div className={styles.gridContainer__total}>Total</div>
          <GridSectorResources
            gridSectorRef={$listContent}
            resourcesData={resourceData}
            sectorResourceRef={$gridSectorResource}
            gridWidth={sectorWidth}
            girdHeight={sectorHeight}
            onScroll={onScroll}
          />
          <ResourcesMatrix
            t={t}
            data={data}
            gridContentValue={gridContentRef}
            matrixContentRef={$matrixContent}
            gridWidth={contentWidth}
            gridHeight={contentHeight}
            resourcesIds={selectedResources}
            onScroll={onScroll}
            nowIndexValidate={nowIndex}
          />
          <GrindSummaryRow
            data={data}
            gridHeight={contentHeight}
            resourcesIds={selectedResources}
            totalGridRef={$totalContent}
            onScroll={onScroll}
            nowIndexValidate={nowIndex}
          />
          <GridSummaryCol
            rows={summaryColData}
            resourceData={resourceData}
            onScroll={onScroll}
            gridWidth={sectorWidth}
          />
          <GridSummaryColContent
            summaryContentRef={$totalColContent}
            rows={summaryColData}
            data={data}
            onScroll={onScroll}
            gridContentWidth={contentWidth}
          />
        </div>
      );
    } else {
      return null;
    }
  };
  return (
    <div>
      <Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 17,
            paddingBottom: 0
          }}>
          <span style={{ color: '#121212', fontSize: 20 }}>
            {t('analitic.timeline_resources')}
          </span>
          <span style={{ position: 'relative', top: -2 }}>
            <div
              style={{ cursor: 'pointer', fontSize: '12px' }}
              className={styles['matrix__filter-buttons']}
              onClick={() => {
                setShowSettings(true);
              }}>
              <img
                src={configurationIcon}
                width={15}
                style={{ cursor: 'pointer', marginRight: 10 }}
              />
            </div>
            <div
              style={{ cursor: 'pointer', marginRight: 10 }}
              className={styles['matrix__filter-buttons']}
              onClick={() => {
                setShowResourceFilter(true);
              }}>
              <div>
                <img src={filterIcon} width={15} />
              </div>
            </div>
          </span>
        </div>
      </Row>
      {resourceData ? renderGrids() : <div></div>}
      <div>
        <RenderModalFilter
          t={t}
          title={'resource_curve_model'}
          visible={showResourceFilter}
          resourcesArray={resources}
          handleCancel={handleCancel}
          handleOk={handleOk}
        />
      </div>
      <div>
        <RenderSettings
          t={t}
          visible={showSettings}
          handleCancel={handleCancel}
          handleOk={shownHandleOk}
        />
      </div>
    </div>
  );
};
