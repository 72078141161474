import React from 'react';
import ReactDOM from 'react-dom';
import { progressEditor } from '../../../../src/assets/js/field_config/editor-inline-gantt.js';
import {
  EditOutlined,
  UserAddOutlined,
  MinusOutlined,
  DownOutlined
} from '@ant-design/icons';
import IconComponent from '../../../components/Projects/IconSvg';
import {
  hasConstraint,
  hasUnfitProblem,
  formatMoneyV2,
  leanStatusArray,
  priorityArray,
  statusArray,
  formatMoney
} from '../../../utils/lookahead-common';
import { durationEditor } from '../../../assets/js/field_config/editor-inline-lookahead';

import {
  collapseExpandActivity,
  checkPonderatorIcon
} from '../../../components/GanttVisualization/GanttVisualization.helper';

import { helmet } from '../../../utils';
import i18n from '../../../../src/i18n';
import fakeAvatar from '../../../assets/img/fake_user.png';
import { templateNumber, findDeepGetActivityOfTask } from './index.helper';
import { isFeatureOn } from '../../../utils/featureUtils';

/** Table Titles */
export const tableMetadata = [
  {
    data_type: 'number',
    name: 'activity_ganttid',
    label: 'ID',
    visible: false,
    groupable: false,
    orderable: false,
    span: 3,
    offset: 0,
    align: 'left',
    width: 20,
    ignore_as_column: true,
    ignoreGantt: true,
    resize: false,
    tree: false
  },
  {
    data_type: 'string',
    name: 'activity',
    label: 'Actividad',
    visible: false,
    groupable: false,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true,
    ignore_as_column: true,
    ignoreGantt: true,
    tree: false
  },
  {
    name: 'checked',
    align: 'center',
    label: '',
    template: (activity) => {
      // comment to allow the render of the checkbox first activity
      /* if (window.groupBy.criteria == 'activity' || window.groupBy.criteria == 'activityId') {
                if (activity.correlative_id == 0 && activity.parent == 0) return
            } */
      const gantt = window.ganttVisualization;
      const lastLevelActivities = window.activities;
      if (activity.is_milestone || activity.readonly) return;
      // if (!activity.isTask && gantt.subtradeRole.subtrade) return
      // if (activity.isParentTask && gantt.subtradeRole.subtrade) return

      let activityRefId = activity.activityReference?.proplannerId || null;
      /** search reference activity */
      if (!activityRefId && activity.isTask) {
        const findAct = findDeepGetActivityOfTask(
          lastLevelActivities,
          'id',
          activity.id
        );
        activityRefId = findAct?.id;
      }

      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.checked;
      }
      let checked;
      if (activity.mustApplyVisibleChecked) {
        checked = activity.visibleChecked ? ' checked' : '';
      } else {
        checked = activity.checked ? ' checked' : '';
      }
      return `
           <div class="${isOdd ? 'odd-col' : 'non-odd-col'} checked-for-gantt ${
             checked ? 'checked-for-gantt-clicked' : ''
           }">
               <input class='gantt-checkbox-column' style="cursor: pointer; accent-color: #53C255;" onclick=window.ganttVisualization.updateDOMCheckbox(${
                 activity.id
               },${activityRefId},${false},${true}) type='checkbox' name='test' id='test' value='1' ${checked}/>
           </div>
           `;
    },
    width: 30,
    groupable: false,
    orderable: false,
    filterable: false,
    data_type: 'string',
    visible: true,
    ganttOnly: true,
    ignore_as_column: true
  },
  {
    name: 'name',
    label: 'Tareas',
    data_type: 'string',
    visible: true,
    span: 3,
    offset: 0,
    align: 'left',
    width: 250,
    groupable: false,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true,
    ignore_as_column: true,
    resize: true,
    tree: false,
    editable: false,
    editor: {
      type: 'text',
      map_to: 'name'
    },
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.name;
      }
      return `
                <div class="${!isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'actions',
    label: 'Acciones',
    data_type: 'string',
    visible: true,
    span: 3,
    offset: 0,
    align: 'left',
    width: 80,
    groupable: false,
    orderable: false,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: false,
    ignore_as_column: true,
    resize: false,
    tree: false,
    editable: false,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.name;
      }
      return `
                <div class="${!isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'roadblocks',
    label: 'Roadblocks',
    data_type: 'string',
    visible: true,
    span: 3,
    offset: 0,
    align: 'left',
    width: 80,
    groupable: false,
    orderable: false,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: false,
    ignore_as_column: false,
    resize: true,
    tree: false,
    editable: false,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.name;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'description',
    label: 'Descripción',
    data_type: 'string',
    visible: false,
    span: 3,
    offset: 0,
    align: 'left',
    ignore_as_column: false,
    width: 200,
    groupable: false,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: false,
    resize: true,
    editable: true,
    editor: {
      type: 'text',
      map_to: 'description'
    },
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.description;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'start_date',
    label: 'Inicio',
    data_type: 'date',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 97,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true,
    resize: true,
    editor: {
      type: 'date',
      map_to: 'start_date'
    },
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.start_date;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'duration',
    label: 'Duración',
    data_type: 'number',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 60,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: true,
    resize: true,
    editable: true,
    editor: durationEditor(),
    template: () => {
      /** this is defined in RenderReactCol */
    }
  },
  {
    name: 'end_date',
    label: 'Fin',
    data_type: 'date',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 97,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true,
    resize: true,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.end_date;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'priority',
    label: 'Prioridad',
    data_type: 'array/icon',
    visible: true,
    span: 1,
    offset: 0,
    align: 'center',
    mode: 'single',
    width: 90,
    from_values: priorityArray,
    groupable: true,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true,
    resize: true,
    template: (activity) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      let final = '';
      if (activity.priority) {
        final = `
                    <span>
                        <img src="${
                          priorityArray.find(
                            (pr) => pr.value == activity.priority
                          )?.icon
                        }" width="12" />
                    </span>
                `;
      }
      let isOdd = false;
      if (gantt.oddColsConfig && activity.type === 'activitytask') {
        isOdd = gantt.oddColsConfig.priority;
      }

      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                    ${final}
                </div>
            `;
    }
  },
  {
    name: 'progress',
    label: 'Avance',
    align: 'center',
    resize: true,
    width: '70',
    editor: progressEditor,
    template: (task, activity) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.progress;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    },
    hide: false,
    data_type: 'number',
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: true
  },
  {
    name: 'ponderator',
    label: 'Ponderador',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 110,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false,
    resize: true,
    template: (item) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      let isOdd = false;
      if (gantt.oddColsConfig && item.type === 'activitytask') {
        isOdd = gantt.oddColsConfig.ponderator;
      }
      if (item.ponderator === undefined || item.ponderator === 0) return;
      if (item.ponderator === null) return;
      return `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                ${
                  item.ponderator.toFixed
                    ? item.ponderator.toFixed(2)
                    : item.ponderator
                }
            </div>
            `;
    }
  },
  {
    name: 'status',
    label: 'Estado',
    ignore_as_column: false,
    mixed: false,
    mixed_from: ['Overdue', 'Advancement'],
    data_type: 'array/icon',
    visible: false,
    span: 1,
    offset: 0,
    align: 'center',
    mode: 'single',
    width: 95,
    from_values: statusArray,
    groupable: true,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true,
    resize: true,
    template: (item) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      let isOdd = false;
      if (gantt.oddColsConfig && item.type === 'activitytask') {
        isOdd = gantt.oddColsConfig.status;
      }
      if (!item.isTask) return;
      const defineIcon = (option) => <img src={option.customIcon} width={15} />;

      /** define icon */
      const optionData = statusArray.filter((f) => f.value == item.status);

      /** define tooltip */
      let tooltipTitle = '';
      tooltipTitle = optionData[0].label;

      const jsx = (
        <span className="vertical-center" title={tooltipTitle}>
          {defineIcon(optionData[0])}
        </span>
      );
      const temp = document.createElement('div');
      const html = ReactDOM.render(jsx, temp);
      if (html) {
        return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                    ${html.outerHTML}
                </div>`;
      }
    }
  },
  {
    name: 'lean_status',
    label: 'Estado Lean',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    mode: 'single',
    width: 125,
    from_values: leanStatusArray,
    groupable: true,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true,
    resize: true,
    template: (activity) => {
      let final = '';
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      let isOdd = false;
      if (gantt.oddColsConfig && activity.type === 'activitytask') {
        isOdd = gantt.oddColsConfig.lean_status;
      }
      if (activity.lean_status) {
        const leanObject = leanStatusArray.find(
          (lean) => lean.value == activity.lean_status
        );
        final = `
                <div class="popoverLeanStatus" style="background-color: ${leanObject.color}; color: white; height: 100%;">
                    <span class="vertical-center">
                        ${leanObject.label}
                    </span>
                </div>
                `;
      }

      return `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                ${final}
            </div>
            `;
    }
  },
  {
    name: 'cost',
    label: 'Costo',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    editor: {
      type: 'number',
      map_to: 'cost',
      max: 1000000
    },
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false,
    resize: true,
    template: (item) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      let isOdd = false;
      if (gantt.oddColsConfig && item.type === 'activitytask') {
        isOdd = gantt.oddColsConfig.cost;
      }

      let final = '';
      if (item.type != 'milestone') {
        final = templateNumber(item, 'cost', formatMoneyV2, EditOutlined);
      }

      return `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                ${final}
            </div>
            `;
    }
  },
  {
    name: 'expected',
    label: 'Esperado',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false,
    resize: true,
    template: (activity) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      let isOdd = false;
      if (gantt.oddColsConfig && activity.type === 'activitytask') {
        isOdd = gantt.oddColsConfig.expected;
      }
      let final = '';

      if (activity.expected) {
        if (i18n.t('lang') === 'en') {
          final =
            parseFloat(activity.expected).toFixed(
              Number.isInteger(parseFloat(activity.expected)) ? 0 : 2
            ) + '%';
        } else {
          final =
            parseFloat(activity.expected)
              .toFixed(Number.isInteger(parseFloat(activity.expected)) ? 0 : 2)
              .replace('.', ',') + '%';
        }
      }
      return `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                ${final}
            </div>
            `;
    }
  },
  {
    name: 'expected_cost',
    label: 'Costo esperado',
    data_type: 'number',
    visible: false,
    span: 2,
    calculated: true,
    calculatedCallback: () => {},
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false,
    resize: true,
    template: (item) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      let isOdd = false;
      if (gantt.oddColsConfig && item.type === 'activitytask') {
        isOdd = gantt.oddColsConfig.expected_cost;
      }
      if (!item.expected_cost) return;
      return `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                ${
                  i18n.t('lang') === 'en'
                    ? formatMoney(
                        parseFloat(item.expected_cost),
                        '$',
                        2,
                        '.',
                        ','
                      )
                    : formatMoney(
                        parseFloat(item.expected_cost),
                        '$',
                        2,
                        ',',
                        '.'
                      )
                }
            </div>
            `;
    }
  },
  /** New cols */
  {
    name: 'responsables',
    label: 'Responsable',
    data_type: 'array/images',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    mode: 'multiple',
    from_values: 'toSelectResponsables',
    el_to_extract_from: 'email',
    el_to_label_from: ['name', 'lastname'],
    img_from: 'image',
    width: 86,
    groupable: true,
    groupable_switch: ['A → Z', 'Z → A'],
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true,
    resize: true,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.responsables;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'subcontractId',
    label: 'Subcontrato',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    mode: 'single',
    width: 98,
    groupable: true,
    from_values: [],
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true,
    avoid_translate: true,
    resize: true,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.tags;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'tags',
    label: 'Tags',
    data_type: 'array/images',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    mode: 'multiple',
    from_values: 'toSelectTags',
    el_to_extract_from: 'id',
    el_to_label_from: ['name', 'description'],
    img_from: 'image',
    width: 125,
    groupable: false,
    groupable_switch: ['A → Z', 'Z → A'],
    orderable: false,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true,
    resize: true,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.tags;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'taskRoute',
    label: 'Ruta',
    data_type: 'string',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: false,
    resize: true,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.taskRoute;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'materialId',
    identifier: 'material',
    label: 'Material',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    mode: 'single',
    align: 'center',
    width: 125,
    from_values: [],
    groupable: false,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true,
    resize: true,
    ganttNewColumns: isFeatureOn('gantt-new-columns'),
    avoid_translate: true,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.materialId;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'specialtyId',
    label: 'Especialidad',
    identifier: 'rrhh',
    data_type: 'array/string',
    visible: false,
    span: 2,
    mode: 'single',
    offset: 0,
    align: 'center',
    width: 125,
    from_values: [],
    groupable: false,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true,
    resize: true,
    ganttNewColumns: isFeatureOn('gantt-new-columns'),
    avoid_translate: true,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.specialtyId;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'machineId',
    label: 'Maquinaria',
    identifier: 'machinery',
    data_type: 'array/string',
    visible: false,
    mode: 'single',
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    from_values: [],
    groupable: false,
    groupable_switch: ['1 → N', 'N → 1'],
    orderable: true,
    orderable_switch: ['1 → N', 'N → 1'],
    filterable: true,
    resize: true,
    ganttNewColumns: isFeatureOn('gantt-new-columns'),
    avoid_translate: true,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.machineId;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'total_quantity',
    label: 'Cantidad Total',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    editor: {
      type: 'number',
      map_to: 'total_quantity',
      max: 1000000
    },
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    resize: true,
    ganttNewColumns: isFeatureOn('gantt-new-columns'),
    filterable: false,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.total_quantity;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'actual_quantity',
    label: 'Cantidad Real',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    editor: {
      type: 'number',
      map_to: 'actual_quantity',
      max: 1000000
    },
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    resize: true,
    ganttNewColumns: isFeatureOn('gantt-new-columns'),
    filterable: false,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.actual_quantity;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'remaining_quantity',
    label: 'Cantidad Restante',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    resize: true,
    calculated: true,
    ganttNewColumns: isFeatureOn('gantt-new-columns'),
    filterable: false,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.remaining_quantity;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'plan_endowment',
    label: 'Dotación Plan',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 75,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    ignoreGantt: false,
    editor: {
      type: 'number',
      map_to: 'plan_endowment',
      max: 1000000
    },
    resize: true,
    ganttNewColumns: isFeatureOn('gantt-new-columns'),
    filterable: false,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.plan_endowment;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'real_endowment',
    label: 'Dotación Real',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    editor: {
      type: 'number',
      map_to: 'real_endowment',
      max: 1000000
    },
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    resize: true,
    ganttNewColumns: isFeatureOn('gantt-new-columns'),
    filterable: false,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.real_endowment;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'hhWorkTime',
    label: 'HH Total',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    editor: {
      type: 'number',
      map_to: 'hhWorkTime',
      max: 1000000
    },
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false,
    resize: true,
    template: (item) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      let isOdd = false;
      if (gantt.oddColsConfig && item.type === 'activitytask') {
        isOdd = gantt.oddColsConfig.hhWorkTime;
      }

      return `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                ${templateNumber(
                  item,
                  'hhWorkTime',
                  formatMoneyV2,
                  EditOutlined
                )}
            </div>
            `;
    }
  },
  {
    name: 'won_hh',
    label: 'HH Ganada',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    resize: true,
    ganttNewColumns: isFeatureOn('gantt-new-columns'),
    filterable: false,
    calculated: true,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.won_hh;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'spend_hh',
    label: 'HH Gastado',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    editor: {
      type: 'number',
      map_to: 'spend_hh',
      max: 1000000
    },
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    resize: true,
    ganttNewColumns: isFeatureOn('gantt-new-columns'),
    filterable: false,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.spend_hh;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'total_hm',
    label: 'HM Total',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    editor: {
      type: 'number',
      map_to: 'total_hm',
      max: 1000000
    },
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    resize: true,
    ganttNewColumns: isFeatureOn('gantt-new-columns'),
    filterable: false,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      if (task.type !== 'activitytask') return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.total_hm;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'spend_hm',
    label: 'HM Gastado',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    resize: true,
    ganttNewColumns: isFeatureOn('gantt-new-columns'),
    filterable: false,
    calculated: true,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      let isOdd = false;
      if (task.type !== 'activitytask') return;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.spend_hm;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'commitment_percentaje',
    label: 'Compromiso',
    data_type: 'number',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false,
    editor: {
      type: 'number',
      map_to: 'commitment_percentaje'
    },
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      let isOdd = false;
      if (task.type !== 'activitytask') return;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.commitment_percentaje;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  {
    name: 'quantity_parcial',
    label: 'Material Sem.',
    data_type: 'number',
    visible: false,
    span: 2,
    offset: 0,
    align: 'center',
    width: 125,
    groupable: false,
    orderable: true,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false,
    editor: {
      type: 'number',
      map_to: 'quantity_parcial',
      max: 1000000
    },
    hide: true,
    resize: true,
    template: (task) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      let isOdd = false;
      if (task.type !== 'activitytask') return;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.quantity_parcial;
      }
      return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
            `;
    }
  },
  /* {
        name: 'correlative_number',
        label: 'correlative number',
        data_type: 'number',
        visible: false,
        span: 2,
        offset: 0,
        align: 'center',
        width: 125,
        groupable: false,
        orderable: true,
        orderable_switch: ['1 → 9', '9 → 1'],
        filterable: false,
        hide: true,
        resize: true,
        template: (task) => {
            const gantt = window.ganttVisualization;
            if (!gantt) return;
            let isOdd = false;
            if (task.type !== 'activitytask') return;
            if (gantt.oddColsConfig) {
                isOdd = gantt.oddColsConfig.correlative_number;
            }
            return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                    ${task.correlative_number}
                </div>
            `;
        }
    }, */
  {
    name: 'unique_correlative_id',
    label: 'UID',
    align: 'center',
    width: '*',
    resize: false,
    hide: true,
    template: (activity) => {
      const gantt = window.ganttVisualization;
      if (!gantt) return;
      let isOdd = false;
      if (gantt.oddColsConfig) {
        isOdd = gantt.oddColsConfig.id;
      }
      if (!activity?.unique_correlative_id) return '';

      const label = activity.unique_correlative_id;
      return `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                ${label}
            </div>
            `;
    },
    data_type: 'number',
    groupable: false,
    orderable: false,
    orderable_switch: ['1 → 9', '9 → 1'],
    filterable: false
  }
];
