/* eslint-disable no-eval */
/** React stuff */
import React, { useState, useEffect, useRef } from 'react';

/** Components from ant design css framework */
import {
  Button,
  Modal,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Input,
  InputNumber,
  Progress,
  Popover,
  Icon,
  Empty,
  Popconfirm
} from 'antd';
import SelectSearch from 'react-select-search';

/** import icons from antd framework */
import {
  PlusOutlined,
  CloseCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons';

/** Messaging for user notification util */
import { openNotification, helmet } from '../../utils';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import { capitalize } from 'lodash';
import CustomCheckbox from '../CustomCheckbox';
import cloneDeep from 'lodash/cloneDeep';

import childArrow from '../../assets/img/child-arrow.png';

/** PNG to put on users without image */
import fakeAvatar from '../../assets/img/fake_user.png';

/** Date handler lib */
import moment from 'moment';

/** Styles on pure css */
import './index.css';
import { FixedSizeList as List } from 'react-window';

import { taskService } from '../../services';
import { activityService } from '../../services';
import { activityModificationService } from '../../services';
import { base } from '../../services/base';

import LazyElement from '../LazyElement';
import ProgressBar from '../ProgressBar';
import EditableInput from '../EditableInput';

import rollbackPonderatorIcon from '../../assets/img/ponderator-back.png';
import rollbackPonderatorInvertedIcon from '../../assets/img/ponderator-back-inverted.png';
import calendarModificationIconModal from '../../assets/img/activitymodification/calendar-modal.png';

import { transformHourToDays } from '../../views/ganttContainer/gantt/gantt.helper';
/** Redux implementation */
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { lookaheadActions } from '../../redux/actions/lookaheadResourceActions';
import { userActions } from '../../redux/actions/userActions';
/** SVG image */
import IconComponent from '../Projects/IconSvg';

/** PNG for task planification add and delete tasks */
import selectResponsablesIcon from '../../assets/img/select_responsables.png';

import {
  calculatePonderators,
  calculateProgress,
  getTasksToDelete,
  deleteTaskHandler,
  getEndDateByGantt,
  detectUnfitDate,
  transformDate,
  getTask,
  defineTitleModification,
  defineDateUnfit,
  hasConstraint,
  hasUnfitProblem,
  renderFriend,
  calculateExpected,
  calculateExpectedCost,
  transformDateWithHour,
  getRecursiveFromParentTask,
  splitText
} from '../../utils/lookahead-common';

import { socket } from '../../services/socket.service';
import { withTranslation } from 'react-i18next';
import ResourceActivity from '../Resources/ResourceActivity';

import { reversPathTasks } from '../../utils';
import { ResourcesList } from '../ResourcesList';
import {
  totangoEventTracking,
  totangoSetAccountAttributes
} from '../../analytics/implements/totango';
import { getSignedUser } from '../../utils/userUtils';

const { TextArea } = Input;

/**
 * This component prints an header with activity data, and then renders it's tree tasks, allowing user to interact each row data.
 */
function LookaheadActivityResource(props) {
  const virtualizeRef = useRef();
  const {
    permission,
    showDrawer,
    onClosecard,
    deleteSubcontract,
    t,
    updateAsyncTask,
    lastLevelActivities,
    updateAsyncActivity,
    setVisibleFormResource,
    setTypeResource,
    setActivityResource,
    setTaskSelected
  } = props;
  const [onlyRead, setOnlyRead] = useState(permission == 'V');
  const [popsVisibility, setPopsVisibility] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  const [editedInput, setEditedInput] = useState(null);
  const [resource, setResource] = useState([]);
  const [resourceMachine, setResourceMachine] = useState([]);
  const [resourceMaterial, setResourceMaterial] = useState([]);
  const [resourceRrhh, setResourceRrhh] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [resources, setResources] = useState(lastLevelActivities?.resource);
  const projectState = useSelector((state) => state.projectState);
  const stateCompany = useSelector((state) => state.companyState);
  let materailUnit = '';
  const dispatch = useDispatch();
  // onsole.log(projectState);
  // setResourceMachine(props.resources)
  const [modalModification, setModalModification] = useState({
    visible: false,
    title: '',
    description: '',
    data: { activity: {}, task: {} }
  });
  const { height, width } = useWindowDimensions();
  const { ganttAPI } = props;
  useEffect(() => {
    if (props.resources) {
      const mch = props.resources.filter((rs) => rs.type == 'machinery');
      const mtl = props.resources.filter((rs) => rs.type == 'material');
      const rrhh = props.resources.filter((rs) => rs.type == 'rrhh');
      setResourceMachine(mch);
      setResourceMaterial(mtl);
      setResourceRrhh(rrhh);
    }
  }, []);

  /** Fix of wrong ponderators */
  useEffect(() => {
    const activity = props.lastLvlActivity;
    if (activity.reCalculatePonderator) {
      activity.reCalculatePonderator = false;
      updateAsyncActivity(activity);
      const alreadyCalculatedParents = {};
      let activityAlreadyCalculated = false;
      activity.tasks.map((task) => {
        if (task.parent_id) {
          if (!alreadyCalculatedParents[task.parent]) {
            const parent = getTask(task.parent_id, null, activity);
            calculatePonderators(parent, null, updateAsyncTask, projectState);
            alreadyCalculatedParents[task.parent_id] = true;
          }
        } else if (!activityAlreadyCalculated) {
          calculatePonderators(activity, null, updateAsyncTask, projectState);
          activityAlreadyCalculated = true;
        }
      });
      setPopsVisibility({});
    }
  }, []);

  useEffect(() => {
    const id = props.lastLvlActivity.id;
    if (props.scrollStates[id]) {
      if (virtualizeRef.current) {
        virtualizeRef.current.scrollTo(props.scrollStates[id]);
      }
    }

    return () => {
      if (virtualizeRef.current) {
        props.scrollStates[id] = virtualizeRef.current.state.scrollOffset;
      }
    };
  }, [virtualizeRef]);

  // avance traker
  const progress_traker = () => {
    const loggedUser = getSignedUser();
    const currentCompany = stateCompany.currentCompany;
    const project = projectState.allProjects.find(
      (p) => p.id == projectState.projectSelected
    );

    totangoSetAccountAttributes(
      loggedUser,
      projectState.projectSelected,
      currentCompany?.name,
      currentCompany?.id,
      project?.name,
      project?.stage,
      project?.country
    );

    totangoEventTracking(
      `p_${projectState.projectSelected}`,
      loggedUser,
      'Progress Input',
      'Lookahead'
    );
  };

  /**
   * Clone deep of a new state to set and render view
   * @param {*} newState new state value (inmutability is broken)
   */
  const updateState = (newState = popsVisibility) => {
    setPopsVisibility((prev) => {
      prev = cloneDeep(newState);
      return prev;
    });
    dispatch(lookaheadActions.notifyLookaheadUpdate());
  };

  /**
   * This function deals with pop visibility hash map
   * @param {boolean} visible Value to set the pop
   * @param {*} id Unique state eval to use at virtual DOM to show popup or hide it (from antd component)
   */
  const handlePopVisibility = (visible, id) => {
    setPopsVisibility({
      ...popsVisibility,
      [id]: visible
    });
    dispatch(lookaheadActions.notifyLookaheadUpdate());
  };

  /**
   * This function opens a component to add constraints
   * @param {*} task task to add constraint
   */
  const openAddConstraint = (task) => {
    props.handleAddConstraint(task);
  };

  /**
   * This function receives a parent to check if a modal must be showed when he try to modify a auto ponderator
   * If user needs modify a ponderator, then it will be turned into custom ponderator system
   * @param {*} parent parent activity or task
   */
  const verifyCustomPonderator = (parent) => {
    if (!parent.hasCustomPonderator) {
      setShowModal(true);
      setSelectedParent(parent);
    }
  };

  /**
   * This function enable custom ponderator for an activity or task selected at component state
   */
  const disableAutoPonderator = () => {
    selectedParent.hasCustomPonderator = true;
    if (selectedParent.tasks) {
      updateAsyncActivity(selectedParent);
    } else {
      updateAsyncTask(selectedParent);
    }
    setShowModal(false);
  };

  /**
   * This functions shows a pretty alert to user
   * @param {*} data Object { title, message, type }
   */
  const notifyMessage = (data) => {
    const alertErrorMailExists = {
      title: data.title,
      description: data.message,
      type: data.type
    };
    openNotification(alertErrorMailExists);
  };

  /**
   * On click function when pressing delete
   * @param {*} task Top lvl task to start tree deleting
   * @param {*} activity Activity parent object
   * @param {*} parent If task is from another than first level must be specified the parent task to attack it children array
   */
  const handleDelete = async (task, activity, parent = null) => {
    const tasksToDelete = [];
    getTasksToDelete(task, tasksToDelete);
    const asyncMap = tasksToDelete.map(async (toDelete) => {
      await deleteTaskHandler(
        toDelete,
        activity,
        parent,
        updateAsyncTask,
        updateAsyncActivity
      );
      socket.emit('task_delete', {
        sector: projectState.sectorSelected,
        task: toDelete
      });
    });

    await Promise.all(asyncMap);
    notifyMessage({
      title: t('deleted_succesfully_title'),
      message: t('deleted_succesfully'),
      type: 'success'
    });
    props.virtualizeRef.current.resetAfterRowIndex(props.index);
    updateState();
    props.setMassiveSelection([]);
  };

  /**
   * This functions create a task and associate it to his activity parent
   * @param {*} activity Activity object to which we are going to add a new task
   */
  const handleAdd = (activity) => {
    createTask(null, 'New task', activity);
  };

  /**
   * This function adds a new sub task to a parent task
   * @param {*} task Task object, from the parent task to associate it
   * @param {*} activity Activity object, from the activity which must be associated the new task
   */
  const handleChildAdd = (task, activity) => {
    createTask(task, 'New sub task', activity);
  };

  /* useEffect(async () => {
        if (props.lastLvlActivity.tasks.length) {
            const s = await activityService.assignLookahead({
                id: props.lastLvlActivity.id,
                is_lookahead: true
            });
            props.lastLvlActivity.isOnLookahead = true;
            console.log('Res from turning lookahead activity: ', s);
        } else {
            const s = await activityService.assignLookahead({
                id: props.lastLvlActivity.id,
                is_lookahead: true
            });
            props.lastLvlActivity.isOnLookahead = true;
            console.log('Res from turning lookahead activity: ', s);
        }
    }, []) */

  /**
   * This function creates a new task
   * @param {*} parentId ID from the Task that has a lvl up as a parent
   * @param {*} name Name to show to the new task
   * @param {*} activity Activity superior parent object
   */
  const createTask = async (
    parentTask = null,
    name,
    activity,
    copyActivity = false
  ) => {
    const loggedUser = getSignedUser();
    const currentCompany = stateCompany.currentCompany;
    const project = projectState.allProjects.find(
      (p) => p.id == projectState.projectSelected
    );

    totangoSetAccountAttributes(
      loggedUser,
      projectState.projectSelected,
      currentCompany?.name,
      currentCompany?.id,
      project?.name,
      project?.stage,
      project?.country
    );

    totangoEventTracking(
      `p_${projectState.projectSelected}`,
      loggedUser,
      'Resources Task Creation',
      'Lookahead'
    );

    let inheritStartDate = parentTask
      ? parentTask.start_date
      : activity.start_date;

    inheritStartDate = transformDateWithHour(inheritStartDate);
    const toPush = {
      name: name,
      start_date: inheritStartDate,
      duration: 1,
      priority: 'Low',
      progress: 0,
      responsables: [],
      ponderator: 0,
      activityId: activity.id,
      isEditing: true,
      isNew: true,
      activity_ganttid: activity.correlative_id,
      parent_id: parentTask ? parentTask.id : parentTask,
      cost: 0,
      hhWorkTime: 0,
      status: 'Waiting',
      lean_status: 'Debit',
      children: [],
      constraints: [],
      hasCustomPonderator: false,
      active: false
    };

    if (copyActivity) {
      toPush.name = activity.name;
      toPush.progress = activity.progress;
      toPush.cost = activity.cost;
      toPush.duration = activity.duration;
      toPush.hhWorkTime = activity.hhWorkTime;
    }

    getEndDateByGantt(toPush, activity);

    const res = await taskService.create(toPush);

    if (res) {
      toPush.id = res.id;
      if (parentTask) {
        parentTask.children.push(toPush);
      } else {
        activity.tasks.push(toPush);
      }

      calculatePonderators(
        parentTask || activity,
        activity,
        updateAsyncTask,
        projectState
      );
      calculateProgress(
        toPush,
        parentTask || activity,
        activity,
        updateAsyncTask,
        updateAsyncActivity
      );

      if (activity.tasks.length != 0 && !activity.isOnLookahead) {
        const s = await activityService.assignLookahead({
          id: activity.id,
          is_lookahead: true
        });
        activity.isOnLookahead = true;
      }

      socket.emit('task_add', {
        sector: projectState.sectorSelected,
        task: toPush
      });
      props.virtualizeRef.current.resetAfterRowIndex(props.index);
      updateState();
    }
  };

  /**
   * This function allows user to hide childs for a task or activity
   * @param {*} parent Parent activity or task to hide their children (REQUIRED)
   */
  const hideChilds = (parent) => {
    if (parent.tasks) {
      props.virtualizeRef.current.resetAfterRowIndex(props.index);
    }
    parent.hide_childs = true;
    updateState();
  };

  /**
   * This function allows user to show childs for a task or activity
   * @param {*} parent Parent activity or task to show their children (REQUIRED)
   */
  const showChilds = (parent) => {
    if (parent.tasks) {
      props.virtualizeRef.current.resetAfterRowIndex(props.index);
    }
    parent.hide_childs = false;
    updateState();
  };

  const getRecursiveDurationCalendarForParent = (parent, calendar) => {
    const originalValue = JSON.stringify(parent.duration);
    const duration = ganttAPI.calculateDuration(
      parent.start_date,
      parent.end_date,
      calendar
    );
    if (duration) {
      const total = transformHourToDays(duration);
      if (JSON.stringify(total) != originalValue) {
        parent.duration = total;
        updateAsyncTask(parent);
        updateState();
      }
    }
  };

  const getRecursiveDurationForParent = (parent) => {
    const originalValue = JSON.stringify(parent.sumDuration);
    let total = 0;
    parent.children.map((el) => {
      total += el.duration;
    });
    parent.sumDuration = total;

    if (JSON.stringify(total) != originalValue) {
      updateState();
    }
  };

  /**
   * This function handles at virtual dom which icon should be shown (collapse or uncollapse)
   * @param {*} parent Parent activity or task to define what icon to show (REQUIRED)
   */
  const defineCollapseIcon = (parent) => {
    const children = parent.tasks || parent.children;
    if (children) {
      if (children.length) {
        if (parent.hide_childs) {
          return (
            <i
              onClick={() => showChilds(parent)}
              className="fa fa-angle-down show-childs-btn"
              aria-hidden="true"
            />
          );
        }
        return (
          <i
            onClick={() => hideChilds(parent)}
            className="fa fa-minus hide-childs-btn"
            aria-hidden="true"
          />
        );
      }
    }
  };

  /**
   * This function handles the request acitivity modification
   * @param {*} task task which has problems of unfit dates
   * @param {*} activity activity to request modification
   */
  const handleActivityModificationRequest = (task, activity) => {
    setModalModification({
      ...modalModification,
      visible: true,
      title: t('modals.lookahead.activity_modification_request.title'),
      data: {
        task,
        activity
      }
    });
  };

  const getStringFromMoment = (date) => {
    if (typeof date === 'string') {
      return date;
    }
    return moment(date).format('YYYY/MM/DD H:mm');
  };

  const recursiveGetStartForParent = (parent) => {
    const originalDate = new Date(getStringFromMoment(parent.start_date));

    let minStart;
    parent.children.map((el, index) => {
      const date = new Date(getStringFromMoment(el.start_date));
      if (!index) {
        minStart = date;
      } else {
        if (date.getTime() < minStart.getTime()) {
          minStart = date;
        }
      }
    });

    parent.start_date = minStart;
    if (originalDate.getTime() != minStart.getTime()) {
      taskService.update(parent);
      updateState();
    }
  };

  const recursiveGetEndForParent = (parent) => {
    const originalDate = new Date(getStringFromMoment(parent.end_date));

    let maxEnd;
    parent.children.map((el, index) => {
      const date = new Date(getStringFromMoment(el.end_date));
      if (!index) {
        maxEnd = date;
      } else {
        if (date.getTime() > maxEnd.getTime()) {
          maxEnd = date;
        }
      }
    });

    parent.end_date = maxEnd;
    if (originalDate.getTime() != maxEnd.getTime()) {
      taskService.update(parent);
      updateState();
    }
  };

  const renderResourcesList = (objectData) => (
    <ResourcesList
      setTaskSelected={setTaskSelected}
      setVisibleFormResource={setVisibleFormResource}
      task={objectData.task}
      setFilterText={setFilterText}
      filterText={filterText}
      activity={objectData.activity}
      setTypeResource={setTypeResource}
      updateAsyncTask={updateAsyncTask}
      uniqueId={objectData.uniqueId}
      handlePopVisibility={handlePopVisibility}
      popsVisibility={popsVisibility}
      setPopsVisibility={setPopsVisibility}
      setResources={setResources}
      resources={resources}
      column={objectData.column}
      lastLevelActivities={props.lastLevelActivities}
      setActivityResource={setActivityResource}
      t={t}
      showBtnCreate={true}
      resourceData={objectData.data}
      typeResource={objectData.type}
    />
  );

  /**
   * This function defines what should be showed inside each task column at table.
   * This by using a flag from task, isEditing, when is active renders something, and when is not active
   * renders another jsx
   * @param {*} column Metadata from the column that is going to be extracted from the task
   * @param {*} task Task object to extract the column data
   * @param {*} activity Parent activity for tasks from first level
   * @param {*} parentTask Parent task if it is not from first level
   */
  const defineTaskColumnContent = (
    column,
    task,
    activity,
    parentTask,
    index
  ) => {
    const released = task.constraints.filter(
      (constraint) => constraint.status == 'released'
    );
    const isTaskRestricted =
      task.constraints.length && task.constraints.length != released.length;

    let tagResource = '';
    let materialObject = null;
    let resourceMaterial_taks = null;
    let resourceRhh_taks = null;
    let resourceMachinery_taks = null;
    if (resources) {
      resourceMaterial_taks =
        resources && resources.filter((rs) => rs.type == 'material');
      resourceRhh_taks =
        resources && resources.filter((rs) => rs.type == 'rrhh');
      resourceMachinery_taks =
        resources && resources.filter((rs) => rs.type == 'machinery');
      materialObject = resourceMaterial_taks.filter(
        (el) => el.id == task.materialId
      );
      tagResource = materialObject[0] ? materialObject[0].material_label : null;
    }

    if (column.data_type == 'string') {
      if (column.name == 'name') {
        return (
          <span className="vertical-center">
            {onlyRead ? null : (
              <CustomCheckbox
                onClick={() => {
                  props.massiveSelectionHandler(task);
                  // updateState()
                }}
                active={task.active}
              />
            )}
            {task.parent_id ? (
              <img style={{ marginRight: 6 }} width={12} src={childArrow} />
            ) : null}

            {defineCollapseIcon(task)}
            <EditableInput
              t={t}
              disabled={onlyRead}
              service={updateAsyncTask}
              onEdit={setEditedInput}
              isEditing={editedInput}
              activity={props.lastLvlActivity}
              renderEditable={(
                column,
                index,
                task,
                value,
                setValue,
                updateParentData = null,
                handleEsc = null
              ) => (
                <Input
                  onKeyDown={handleEsc}
                  onPressEnter={updateParentData}
                  key={index}
                  id={column.name + task.id}
                  size="small"
                  value={value}
                  className="custom-input-planification"
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => setValue(e.target.value)}
                />
              )}
              updateState={updateState}
              index={index}
              column={column}
              task={task}
              showDrawer={showDrawer}
              onClosecard={onClosecard}
            />

            {onlyRead ? null : (
              <span>
                <span
                  onClick={() => handleChildAdd(task, activity)}
                  style={{ marginLeft: 5, cursor: 'pointer' }}>
                  <Tooltip placement="top" title={t('add_task_label')}>
                    <PlusOutlined className="lookahead-add-icon" />
                  </Tooltip>
                </span>
                <span style={{ cursor: 'pointer', marginLeft: 7 }}>
                  <Popconfirm
                    onConfirm={() => handleDelete(task, activity, parentTask)}
                    title={t('lookahead_resources.confir_delete')}
                    icon={
                      <Icon type="question-circle-o" style={{ color: 'red' }} />
                    }>
                    <Tooltip
                      placement="top"
                      title={t('lookahead_resources.delete')}>
                      <DeleteOutlined className="lookahead-delete-icon" />
                    </Tooltip>
                  </Popconfirm>
                </span>
              </span>
            )}

            <span style={{ float: 'right' }}>
              {checkPonderatorIcon(task)}
              {hasConstraint(task, openAddConstraint, onlyRead, t)}
            </span>
          </span>
        );
      }
      if (column.name == 'taskRoute') {
        return (
          <div
            className="vertical-center"
            style={{ display: 'flex', marginLeft: '1rem', paddingTop: '8px' }}>
            {splitText(
              reversPathTasks(`${activity.activityRoute} > ${activity.name}`),
              { ...column, view: 'resources' }
            )}
          </div>
        );
      }
    } else if (column.data_type == 'number') {
      let onChangeFunction;
      let onClick;
      let showElement = true;
      let disabled = false;
      if (column.name == 'progress') {
        task.expected = calculateExpected(task, ganttAPI, activity.calendarId);
        let progressColor = '#52c41a';
        if (task.progress.toFixed(2) == '100.00') {
          progressColor = '#34AF00';
          task.Advancement = false;
          task.Overdue = false;
        } else if (task.progress > task.expected) {
          progressColor = '#2C3421';
          task.Advancement = true;
          task.Overdue = false;
        } else if (task.progress < task.expected) {
          progressColor = '#E50101';
          task.Overdue = true;
          task.Advancement = false;
        } else {
          progressColor = '#52c41a';
          task.Overdue = false;
          task.Advancement = false;
        }
        /**
         * Note: This code below blocks the tasks with children to progress recursive feature
         */
        if (task.children.length || isTaskRestricted || onlyRead) {
          const percentVal = task[column.name].toFixed
            ? task[column.name].toFixed(2)
            : task[column.name];
          return (
            <span className="vertical-center">
              <Progress
                strokeColor={{ '0%': progressColor, '100%': progressColor }}
                className="progress-custom-props"
                percent={percentVal}
              />
            </span>
          );
        }
        return (
          <span className="vertical-center">
            <ProgressBar
              fillcolor={progressColor}
              calculateProgress={calculateProgress}
              progress_traker={progress_traker}
              calculatePonderators={calculatePonderators}
              projectState={projectState}
              updateAsyncActivity={updateAsyncActivity}
              updateAsyncTask={updateAsyncTask}
              updateRender={updateState}
              parent={parentTask || activity}
              task={task}
              activity={activity}
              column={column}
            />
          </span>
        );
      } else if (column.name == 'duration') {
        if (task.children.length) {
          /** Sumatorry of acum duration for ponderator */
          getRecursiveDurationForParent(task);
          getRecursiveDurationCalendarForParent(task, activity.calendar_id);
        }
        onChangeFunction = (e) => {
          /** Get do element to save it state on re render */
          task[column.name] = e;

          /** We give the lookahead task to gantt API to get duration and end_date */
          getEndDateByGantt(task, activity);

          /** Calculates ponderators feature */
          calculatePonderators(
            parentTask || activity,
            activity,
            updateAsyncTask,
            projectState
          );

          /** Apply changes */
          updateAsyncTask(task);
          // updateRenderSavingFocus(column.name + task.id)
        };
      } else if (column.name == 'total_hm') {
        if (task.children.length) {
          getRecursiveFromParentTask(
            task,
            'total_hm',
            updateState,
            updateAsyncTask
          );
        }
        onChangeFunction = (e) => {
          /** Get do element to save it state on re render */
          task[column.name] = e;

          /** Apply changes */
          updateAsyncTask(task);
        };
      } else if (column.name == 'spend_hm') {
        task.spend_hm = (task.progress / 100) * task.total_hm;
        return (
          <span className="vertical-center">{task.spend_hm.toFixed(2)}</span>
        );
      } else if (column.name == 'hhWorkTime') {
        if (task.children.length) {
          getRecursiveFromParentTask(
            task,
            'hhWorkTime',
            updateState,
            updateAsyncTask
          );
        }
        onChangeFunction = (e) => {
          /** Get do element to save it state on re render */
          task[column.name] = e;

          /** Calculates ponderators feature */
          calculatePonderators(
            parentTask || activity,
            activity,
            updateAsyncTask,
            projectState
          );

          /** Apply changes */
          updateAsyncTask(task);
        };
      } else if (column.name == 'total_quantity') {
        if (task.children.length) {
          getRecursiveFromParentTask(
            task,
            'total_quantity',
            () => {},
            updateAsyncTask
          );
        }
        onChangeFunction = (e) => {
          /** Get do element to save it state on re render */
          task[column.name] = e;

          /** Apply changes */
          updateAsyncTask(task);
        };
      } else if (column.name == 'remaining_quantity') {
        const remainQuant = task.total_quantity - task.actual_quantity;
        if (task.remaining_quantity != remainQuant) {
          task.remaining_quantity = remainQuant;
          updateAsyncTask(task);
        }
        return (
          <span className="vertical-center">
            {remainQuant.toFixed(2) + ' ' + materailUnit}{' '}
          </span>
        );
      } else if (column.name == 'actual_quantity') {
        const actualQuanti = (task.progress / 100) * task.total_quantity;
        if (task.actual_quantity != actualQuanti) {
          task.actual_quantity = actualQuanti;
          updateAsyncTask(task);
        }
        onChangeFunction = (e) => {
          /** Get do element to save it state on re render */
          let newProgress = 0;
          if (task.total_quantity) {
            newProgress = (100 * e) / task.total_quantity;
          }
          if (newProgress > 100) {
            task.progress = 100;
            task.actual_quantity = task.total_quantity;
          } else {
            task.progress = newProgress;
            task[column.name] = e;
          }
          /** Apply changes */
          updateAsyncTask(task);
          calculateProgress(
            null,
            parentTask || activity,
            activity,
            updateAsyncTask,
            updateAsyncActivity
          );
        };
      } else if (column.name == 'plan_endowment') {
        if (task.children.length) {
          getRecursiveFromParentTask(
            task,
            'plan_endowment',
            updateState,
            updateAsyncTask
          );
        }
        onChangeFunction = (e) => {
          /** Get do element to save it state on re render */
          task[column.name] = e;

          /** Apply changes */
          updateAsyncTask(task);
        };
      } else if (column.name == 'real_endowment') {
        if (task.children.length) {
          getRecursiveFromParentTask(
            task,
            'real_endowment',
            updateState,
            updateAsyncTask
          );
        }
        onChangeFunction = (e) => {
          /** Get do element to save it state on re render */
          task[column.name] = e;

          /** Apply changes */
          updateAsyncTask(task);
        };
      } else if (column.name == 'spend_hh') {
        if (task.children.length) {
          getRecursiveFromParentTask(
            task,
            'spend_hh',
            updateState,
            updateAsyncTask
          );
        }
        onChangeFunction = (e) => {
          /** Get do element to save it state on re render */
          task[column.name] = e;

          /** Apply changes */
          updateAsyncTask(task);
        };
      } else if (column.name == 'won_hh') {
        const wonHH = (task.progress / 100) * task.hhWorkTime;
        if (task.won_hh != wonHH) {
          task.won_hh = wonHH;
          updateAsyncTask(task);
        }

        return <span className="vertical-center">{wonHH.toFixed(2)}</span>;
      } else if (column.name == 'expected') {
        task.expected = calculateExpected(task, ganttAPI, activity.calendarId);
        return (
          <span className="vertical-center">{task.expected.toFixed(2)}%</span>
        );
      } else if (column.name == 'expected_cost') {
        task.expected_cost = calculateExpectedCost(
          task,
          ganttAPI,
          activity.calendarId
        );
        return (
          <span className="vertical-center">
            {task.expected_cost.toFixed(2)}%
          </span>
        );
      } else if (column.name == 'ponderator') {
        onClick = () => verifyCustomPonderator(parentTask || activity);
        disabled = !(parentTask || activity).hasCustomPonderator;
        onChangeFunction = (e) => {
          if (e) {
            const inputBackup = cloneDeep(task[column.name]);
            task[column.name] = e;

            const { showCustomPonderator, sumPonderators } = getPonderatorSum(
              parentTask || activity
            );

            if (sumPonderators >= 100.00545454545457) {
              task[column.name] = inputBackup;
            }

            /** Apply changes */
            updateAsyncTask(task);
            // updateRenderSavingFocus(column.name + task.id)
          }
        };
        if (task.progress != 0) {
          showElement = false;
        }
      } else {
        onChangeFunction = (e) => {
          task[column.name] = e;
          updateAsyncTask(task);
          // props.updateRender()
        };
      }

      if (onlyRead) {
        return (
          <span
            className="vertical-center"
            style={{ backgroundColor: 'transparent' }}>
            {Number.isInteger(task[column.name])
              ? task[column.name]
              : task[column.name]
                ? task[column.name].toFixed(2)
                : 0}
          </span>
        );
      }

      /** This case is to active custom ponderator */
      if (showElement && disabled) {
        return (
          <span
            className="vertical-center"
            style={{ cursor: 'pointer', backgroundColor: 'transparent' }}
            onClick={onClick}>
            <Tooltip
              title={t('lookahead_resources.custon_ponderator_mensaje')}
              key={index}>
              {Number.isInteger(task[column.name])
                ? task[column.name]
                : task[column.name].toFixed(2)}
            </Tooltip>
          </span>
        );
        /** This case is for change custom ponderator */
      } else if (task.children.length > 0 && column.name != 'ponderator') {
        return (
          <span className="vertical-center" style={{ paddingLeft: 19 }}>
            <InputNumber
              disabled
              className="custom-input-number-planification ant-input-number-focused disabled-fixed-input"
              defaultValue={task[column.name]}
            />
          </span>
        );
      } else if (showElement && !disabled) {
        return (
          <span className="vertical-center" style={{ paddingLeft: 19 }}>
            <EditableInput
              t={t}
              service={updateAsyncTask}
              onEdit={setEditedInput}
              isEditing={editedInput}
              key={index}
              onSave={onChangeFunction}
              isFloat={!Number.isInteger(task[column.name])}
              renderEditable={(
                column,
                index,
                task,
                value,
                setValue,
                updateParentData = null,
                handleEsc = null
              ) => (
                <InputNumber
                  onKeyDown={handleEsc}
                  onPressEnter={updateParentData}
                  className="custom-input-number-planification ant-input-number-focused"
                  disabled={disabled}
                  min={0}
                  name={column.name + task.id}
                  id={column.name + task.id}
                  precision={Number.isInteger(task[column.name]) ? 0 : 2}
                  step={0.1}
                  defaultValue={task[column.name]}
                  onChange={(e) => setValue(e)}
                  onFocus={(e) => e.target.select()}
                />
              )}
              updateState={updateState}
              index={index}
              column={column}
              task={task}
            />
            {[
              'total_quantity',
              'actual_quantity',
              'quantity_parcial',
              'remaining_quantity'
            ].includes(column.name)
              ? materailUnit
                ? ' ' + materailUnit
                : null
              : null}
          </span>
        );
        // actual_quantity

        /** Ponderator with progress */
      }
      return (
        <span className="vertical-center">
          <Tooltip title={t('has_automatic_tooltip_ponderator')} key={index}>
            {Number.isInteger(task[column.name])
              ? task[column.name]
              : task[column.name].toFixed(2)}
          </Tooltip>
        </span>
      );
    } else if (column.data_type == 'date') {
      /**
       * This code below deals with end date bug,
       * with gantt plugin end date were adding 1 day.
       * In customGanttPlugin at line 41, software substract 1 day, but at BD stills showing it at 24 hrs
       * Here even when data comes as example saturday 24 at 24 hrs,
       * is interpreted as sunday 25.
       * Whatever, here the text or object is fitted to correct date (JUST VISUALLY)
       */

      if (column.name == 'end_date') {
        if (task.children.length) {
          recursiveGetEndForParent(task);
        }
        let dateObject;
        if (task[column.name].split) {
          dateObject = new Date(task[column.name]);
        } else {
          dateObject = cloneDeep(task[column.name]);
        }

        // dateObject.setHours(0);
        return (
          <span className="vertical-center">
            {dateObject.toISOString().split('T')[0].split('-').join('/')}
            {hasUnfitProblem(
              task,
              activity,
              updateAsyncTask,
              handleActivityModificationRequest,
              onlyRead,
              t
            )}
          </span>
        );
      } else if (column.name == 'start_date') {
        if (task.children.length) {
          recursiveGetStartForParent(task);
        }
        let classDate = 'custom-date-picker-planification';
        if (task.active) {
          /** #12121210 */
          classDate += ' massive';
        }
        return (
          <span className="vertical-center-dates">
            <DatePicker
              disabled={task.children.length || onlyRead}
              allowClear={false}
              id={column.name + task.id}
              className={classDate}
              defaultValue={moment(task[column.name], 'YYYY/MM/DD')}
              format={'YYYY/MM/DD'}
              onChange={(date, dateString) => {
                task[column.name] = dateString;
                getEndDateByGantt(task, activity);

                /** Calculates ponderators feature */
                // calculatePonderators(parentTask || activity, activity, updateAsyncTask)
                /** Apply changes */
                updateAsyncTask(task);
                updateState();
              }}
            />
          </span>
        );
      }
    } else if (column.data_type == 'array/images') {
      const uniqueId = task.id + column.name;
      const friends = [];
      const selected = [];
      const dictionary = {};

      task[column.name].map((res) => {
        selected.push(res[column.el_to_extract_from]);
      });

      props[column.from_values].map((user) => {
        if (user.is_active) {
          friends.push({
            name:
              user[column.el_to_label_from[0]] +
              ' ' +
              user[column.el_to_label_from[1]],
            value: user[column.el_to_extract_from],
            photo: user[column.img_from] || fakeAvatar,
            object: user
          });
          dictionary[user[column.el_to_extract_from]] = user;
        }
      });

      if (onlyRead) {
        return (
          <div
            className="custom-multi-select-pop"
            style={{
              width: column.width ? column.width : '100%',
              height: '100%',
              marginTop: 0,
              border: 0
            }}>
            {task[column.name].length ? (
              task[column.name].map((responsable, index) => (
                <span className="vertical-center" key={index}>
                  <Tooltip
                    title={`${responsable.name} ${responsable.lastname}`}>
                    {responsable[column.img_from] ? (
                      <img
                        className="img-responsable-lookahead"
                        src={responsable[column.img_from]}
                      />
                    ) : (
                      <div className="img-responsable-lookahead no-img">
                        {responsable.name ? responsable.name[0] : ''}
                        {responsable.lastname ? responsable.lastname[0] : ''}
                      </div>
                    )}
                  </Tooltip>
                </span>
              ))
            ) : (
              <span className="vertical-center">
                <Tooltip
                  title={
                    onlyRead
                      ? t('non_allow_responsable_tooltip')
                      : t('select_responsable_tooltip')
                  }
                  style={onlyRead ? {} : {}}>
                  <img src={selectResponsablesIcon} width={15} />
                </Tooltip>
              </span>
            )}
          </div>
        );
      }

      return (
        <div
          className="custom-multi-select-pop"
          style={{
            width: column.width ? column.width : '100%',
            height: '100%',
            marginTop: 0,
            border: 0
          }}>
          <Popover
            overlayClassName="container-image-custom"
            className="popoverPriority"
            content={
              <SelectSearch
                className="select-search select-search--multiple"
                options={friends}
                value={selected}
                renderOption={renderFriend}
                onChange={(val) => {
                  task[column.name] = [];
                  val.map((op) => {
                    task[column.name].push(dictionary[op]);
                  });
                  updateAsyncTask(task);
                  updateState();
                }}
                multiple
                search
                placeholder="Buscar responsable"
              />
            }
            trigger="click"
            visible={popsVisibility[uniqueId]}
            onVisibleChange={(val) => {
              handlePopVisibility(val, uniqueId);
            }}>
            {task[column.name].length ? (
              task[column.name].map((responsable, index) => (
                <span className="vertical-center" key={index}>
                  <Tooltip
                    title={`${responsable.name} ${responsable.lastname}`}>
                    {responsable[column.img_from] ? (
                      <img
                        className="img-responsable-lookahead"
                        src={responsable[column.img_from]}
                      />
                    ) : (
                      <div className="img-responsable-lookahead no-img">
                        {responsable.name ? responsable.name[0] : ''}
                        {responsable.lastname ? responsable.lastname[0] : ''}
                      </div>
                    )}
                  </Tooltip>
                </span>
              ))
            ) : (
              <span className="vertical-center">
                <Tooltip title={t('select_responsable_tooltip')}>
                  <img src={selectResponsablesIcon} width={15} />
                </Tooltip>
              </span>
            )}
          </Popover>
        </div>
      );
    } else if (column.data_type == 'array/string') {
      if (column.name == 'machineId') {
        const uniqueId = task.id + column.name;
        const leanObject = resourceMachine.filter(
          (el) => el.id == task[column.name]
        );
        const background = 'white';

        if (onlyRead) {
          return (
            <div
              style={{
                backgroundColor: background,
                height: '100%'
              }}>
              <span className="vertical-center">
                {leanObject.length > 0
                  ? leanObject[0].name
                  : t('not_assigned_general')}
              </span>
            </div>
          );
        }
        return renderResourcesList({
          type: 'machinery',
          data: resourceMachinery_taks,
          task,
          activity,
          uniqueId,
          column
        });
      } else if (column.name == 'materialId') {
        const uniqueId = task.id + column.name;
        const leanObject = resourceMaterial.filter(
          (el) => el.id == task[column.name]
        );
        const background = 'white';

        if (onlyRead) {
          return (
            <span className="vertical-center">
              {leanObject.length > 0
                ? leanObject[0].name
                : t('not_assigned_general')}
            </span>
          );
        }
        return renderResourcesList({
          type: 'material',
          data: resourceMaterial_taks,
          task,
          activity,
          uniqueId,
          column
        });
      } else if (column.name == 'specialtyId') {
        const uniqueId = task.id + column.name;
        const leanObject = resourceRrhh.filter(
          (el) => el.id == task[column.name]
        );
        const background = 'white';

        if (onlyRead) {
          return (
            <div
              style={{
                backgroundColor: background,
                height: '100%'
              }}>
              <span className="vertical-center">
                {leanObject.length > 0
                  ? leanObject[0].name
                  : t('not_assigned_general')}
              </span>
            </div>
          );
        }
        return renderResourcesList({
          type: 'rrhh',
          data: resourceRhh_taks,
          task,
          activity,
          uniqueId,
          column
        });
      } else if (column.name === 'subcontractId') {
        const uniqueId = task.id + column.name;
        const statusObject =
          props.subContracts &&
          props.subContracts.find((e) => e.id === task[column.name]);
        const colorObject = statusObject ? statusObject.color : null;

        if (onlyRead) {
          return (
            <div style={{ height: '100%' }}>
              <span className="vertical-center">
                {statusObject ? (
                  <span
                    className="subcontract-border"
                    style={{
                      border: '1px solid ' + colorObject,
                      color: colorObject,
                      padding: 5,
                      borderRadius: 5
                    }}>
                    <IconComponent
                      style={{ marginRight: 6, position: 'relative', top: 2 }}
                      data={helmet}
                      width={15}
                      fill={colorObject}
                      className="icon-subcontract"
                    />
                    {statusObject.name}
                  </span>
                ) : (
                  <span className="">-</span>
                )}
              </span>
            </div>
          );
        }

        return (
          <div
            className="customPlanificationBlock priorityFlag subcontract-wrapper-resources"
            style={{ height: '100%', marginTop: 0, border: 0 }}>
            <Popover
              overlayClassName="popover-subcontract"
              className="popover-subcontract"
              placement="bottom"
              content={
                <div
                  className="subcontracts-options"
                  style={{ maxHeight: 600, overflow: 'auto' }}>
                  {props.subContracts.map((option, index) => (
                    <span
                      className="item"
                      key={index}
                      onClick={() => {
                        task[column.name] = option.id;
                        updateAsyncTask(task);
                        handlePopVisibility(false, uniqueId);
                        updateState();
                      }}>
                      <span style={{ marginLeft: 5 }}>
                        <span
                          className="subcontract-options-border"
                          style={{
                            border: '1px solid ' + option.color,
                            color: option.color
                          }}>
                          <IconComponent
                            data={helmet}
                            width={15}
                            fill={option.color}
                            className="icon-options-subcontract"
                          />
                          {capitalize(option.name)}
                        </span>
                      </span>
                    </span>
                  ))}
                  <span
                    className="add-subcontract-inline"
                    onClick={() => props.setVisibleFormSubcontract(true)}>
                    {t('create_subcontract_label')}
                  </span>
                </div>
              }
              trigger="click">
              <div style={{ height: '100%' }}>
                <span className="vertical-center">
                  {statusObject ? (
                    <span
                      className="subcontract-border"
                      style={{
                        border: '1px solid ' + colorObject,
                        color: colorObject,
                        padding: 5,
                        borderRadius: 5
                      }}>
                      <IconComponent
                        style={{ marginRight: 6, position: 'relative', top: 2 }}
                        data={helmet}
                        width={15}
                        fill={colorObject}
                        className="icon-subcontract"
                      />
                      {statusObject.name}
                    </span>
                  ) : (
                    <span className="">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  )}
                </span>
              </div>
            </Popover>
            {statusObject ? (
              <Tooltip title={t('weekly_remove_subcontract')}>
                <CloseCircleOutlined
                  className="delete-subcontract-resources"
                  onClick={() => deleteSubcontract(task, props.lastLvlActivity)}
                />
              </Tooltip>
            ) : null}
          </div>
        );
      } else if (column.name == 'lean_status') {
        if (isTaskRestricted) {
          task.restricted = true;
          return (
            <div
              style={{
                backgroundColor: '#E50101',
                color: 'white',
                height: '100%'
              }}>
              <span className="vertical-center">
                {t('lookahead_resources.restrcited')}
              </span>
            </div>
          );
        }
        const uniqueId = task.id + column.name;
        const leanObject = column.from_values.filter(
          (el) => el.value == task[column.name]
        );
        const background = leanObject[0].color;

        if (onlyRead) {
          return (
            <div
              style={{
                backgroundColor: background,
                color: 'white',
                height: '100%'
              }}>
              <span className="vertical-center">{leanObject[0].label}</span>
            </div>
          );
        }
        return (
          <div
            className="customPlanificationBlock priorityFlag"
            style={{ height: '100%', marginTop: 0, border: 0 }}>
            <Tooltip placement="bottom">
              <Popover
                overlayClassName="popoverPriority"
                className="popoverPriority"
                content={
                  <div className="priorityOptions">
                    {column.from_values.map((option, index) => {
                      if (option.value != 'Committed') {
                        return (
                          <span
                            className="item"
                            key={index}
                            onClick={() => {
                              task[column.name] = option.value;
                              updateAsyncTask(task);
                              handlePopVisibility(false, uniqueId);
                            }}>
                            <i
                              className="fas fa-circle"
                              style={{
                                fontSize: 11,
                                color: option.color,
                                position: 'relative',
                                top: -1
                              }}
                            />
                            <span style={{ marginLeft: 5 }}>
                              {capitalize(option.label)}
                            </span>
                          </span>
                        );
                      }
                    })}
                  </div>
                }
                trigger="click">
                <div
                  style={{
                    backgroundColor: background,
                    color: 'white',
                    height: '100%'
                  }}>
                  <span className="vertical-center">{leanObject[0].label}</span>
                </div>
              </Popover>
            </Tooltip>
          </div>
        );
      }
    } else if (column.data_type == 'array/icon') {
      const iconToShow = column.from_values.filter(
        (f) => f.value == task[column.name]
      );
      const uniqueId = task.id + column.name;
      const defineIcon = (option) => {
        if (column.name == 'priority') {
          return <img src={option.icon} width={12} />;
        }
        return (
          <i
            className={option.icon}
            style={{
              fontSize: 11,
              color: option.color,
              position: 'relative',
              top: -2
            }}
          />
        );
      };

      if (onlyRead) {
        return (
          <span className="vertical-center">{defineIcon(iconToShow[0])}</span>
        );
      }
      return (
        <div
          className="customStr priorityFlag custom-select-planification-position"
          style={{ height: '100%', marginTop: 0, border: 0 }}>
          <Tooltip title={capitalize(task[column.name])} placement="bottom">
            <Popover
              overlayClassName="popoverPriority"
              className="popoverPriority"
              content={
                <div className="priorityOptions">
                  {column.from_values.map((option, index) => (
                    <span
                      className="item"
                      key={index}
                      onClick={() => {
                        task[column.name] = option.value;
                        updateAsyncTask(task);
                        handlePopVisibility(false, uniqueId);
                      }}>
                      {defineIcon(option)}
                      <span style={{ marginLeft: 5 }}>
                        {capitalize(option.description)}
                      </span>
                    </span>
                  ))}
                </div>
              }
              trigger="click">
              <span className="vertical-center">
                {defineIcon(iconToShow[0])}
              </span>
            </Popover>
          </Tooltip>
        </div>
      );
    }
  };

  /**
   * This function renders the column container, and then goes to defineTaskColumnContent function
   * to define what render inside
   * @param {*} column Metadata from the column that is going to be extracted the task
   * @param {*} task Task object to extract the column data
   * @param {*} tabulation Integer to add as marginLeft to display tree structure
   * @param {*} index Index from the array iteration
   * @param {*} activity Parent activity for tasks from first level
   * @param {*} parentTask Parent task if it is not from first level
   */
  const renderTaskColumn = (
    column,
    task,
    tabulation,
    index,
    activity,
    parentTask,
    lvl
  ) => {
    let auxTabulation = tabulation;

    if (lvl >= 2) {
      const paddingMultiplierFix = 0.1 * (lvl + 1);
      auxTabulation = tabulation + tabulation * paddingMultiplierFix;
    }

    if (column.visible) {
      const leanObject_ = resourceMaterial.filter(
        (el) => el.id == task.materialId
      );
      if (leanObject_.length > 0) {
        // setMaterailUnit("leanObject_[0].material_label")
        materailUnit = leanObject_[0].material_label;
      } else {
        materailUnit = '';
      }
      return (
        <Col
          className={
            column.name == 'name'
              ? 'fit-name-superposition'
              : 'single-task-column-style'
          }
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            paddingLeft: column.name == 'name' ? auxTabulation : 0,
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineTaskColumnContent(column, task, activity, parentTask, index)}
        </Col>
      );
    }
  };

  const enableAutoPonderator = (parent) => {
    parent.hasCustomPonderator = false;
    calculatePonderators(parent, null, updateAsyncTask, projectState);
    if (parent.tasks) {
      updateAsyncActivity(parent);
    } else {
      updateAsyncTask(parent);
    }
    updateState();
  };

  /**
   * Recursive function to display at virtual dom the tree nested table format
   * @param {*} tasks Array of tasks
   * @param {*} finalArray Final array to display JSX at virtual dom
   * @param {*} activity Parent activity
   * @param {*} tabulation Padding left to display tree table format
   * @param {*} parentTask Instance of the parent task if it is not from first lvl
   */
  const renderChildTreeTasks = (
    tasks,
    finalArray,
    activity,
    tabulation,
    parentTask = null,
    lvl = 0
  ) => {
    tasks.map((task) => {
      // this were creating a memory leak !! calculatePonderators(parentTask || activity, null, updateAsyncTask, projectState)
      const childs = task.children;
      const hasChilds = childs.length != 0;
      const originalStatus = cloneDeep(task.status);
      if (task.progress == 0) {
        task.status = 'Waiting';
      } else if (task.progress > 0 && task.progress < 100) {
        task.status = 'Doing';
      } else {
        task.status = 'Done';
      }

      if (originalStatus != task.status) {
        updateAsyncTask(task);
      }

      if (!task.hide) {
        if (props.resizing) {
          finalArray.push(
            <Row key={task.id} className="fit-at-middle-row"></Row>
          );
        } else {
          finalArray.push(
            <Row
              style={{ backgroundColor: task.active ? '#12121210' : null }}
              key={task.id}
              className="fit-at-middle-row">
              {props.tableMetadata.map((eachColumn, index) =>
                renderTaskColumn(
                  eachColumn,
                  task,
                  tabulation || 0,
                  index,
                  activity,
                  parentTask,
                  lvl
                )
              )}
            </Row>
          );
        }
      }

      if (hasChilds && !task.hide_childs) {
        const newLevel = lvl + 1;
        renderChildTreeTasks(
          childs,
          finalArray,
          activity,
          tabulation + (task.hide ? 0 : 15),
          task,
          newLevel
        );
      }
    });
  };

  /** This function is used for show tasks quantity on virtual dom who renders them and resize height */
  const defineVirtualizedHeight = (taskCounter) => {
    const tempHeight = taskCounter * 40;
    const maxHeight = height < 700 ? height * 0.45 : height * 0.6;
    if (tempHeight < maxHeight) {
      return tempHeight;
    }
    return maxHeight;
  };

  const [renderedChildsCounter, setRenderedChildsCounter] = useState(0);
  useEffect(() => {
    props.virtualizeRef.current.resetAfterRowIndex(props.index);
  }, [renderedChildsCounter]);

  /* useEffect(() => {
        console.log('calculating ponderators')
        const alreadyCalculatedParents = {}
        let activityAlreadyCalculated = false

        props.lastLvlActivity.tasks.map(task => {
            if (task.parent_id) {
                if (!alreadyCalculatedParents[task.parent]) {
                    const parent = getTask(task.parent_id, null, props.lastLvlActivity)
                    calculatePonderators(parent, null, updateAsyncTask, projectState);
                    alreadyCalculatedParents[task.parent_id] = true
                }
            } else if (!activityAlreadyCalculated) {
                calculatePonderators(props.lastLvlActivity, null, updateAsyncTask, projectState);
                activityAlreadyCalculated = true
            }
        })
    }, [renderedChildsCounter]) */

  /**
   * This function is used by virtual dom to render tasks associated to activity
   * @param {*} activity Activity object to show his child tasks
   */
  const renderTasks = (activity) => {
    const childRended = [];
    renderChildTreeTasks(activity.tasks, childRended, activity, 15);

    activity.childRended = childRended.length;

    if (renderedChildsCounter != childRended.length) {
      setRenderedChildsCounter(childRended.length);
    }

    /**
     * To Optimize render of this child list an option is
     * https://react-window.now.sh/#/examples/list/variable-size
     * with childRended, we can create a virtual list to handle performance
     */

    if (activity.hide_childs) {
      return null;
    } else if (childRended.length) {
      return (
        <List
          ref={virtualizeRef}
          height={defineVirtualizedHeight(childRended.length)}
          itemCount={childRended.length}
          itemSize={40}
          width={'100%'}>
          {LazyElement(childRended, virtualizeRef)}
        </List>
      );
    }
    if (props.isFilterdData) {
      return props.setIndexToHide([...props.indexToHide, props.index]);
    }

    return (
      <div style={{ marginTop: 20 }}>
        <Empty
          style={{ margin: 0 }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>{t('lookahead_resources.enty_activity')}</span>}
        />
      </div>
    );
  };

  /**
   * This function defines what parent should has a custom ponderator sum
   * When defining that a parent needs it, this function creates the summatory value
   * @param {*} parent Parent to check custom ponderators sum
   * @returns {object} { boolean: Indicates if parent has custom ponderator active, number: Summatory of custom ponderators }
   */
  const getPonderatorSum = (parent) => {
    let sumPonderators = 0;
    let showCustomPonderator = false;
    const stringToExtract = parent.tasks ? 'tasks' : 'children';
    if (parent.hasCustomPonderator) {
      parent[stringToExtract].map((t) => {
        sumPonderators += t.ponderator;
      });
      showCustomPonderator = true;
    }
    return { showCustomPonderator, sumPonderators };
  };

  /**
   * This function creates a JSX with the summatory of childs custom ponderators for
   * a task or activity
   * @param {*} parent Parent task or activity to check childs custom ponderators sum
   */
  const checkPonderatorIcon = (parent) => {
    const { showCustomPonderator, sumPonderators } = getPonderatorSum(parent);

    if (showCustomPonderator) {
      let color = '#34af00';
      if (
        sumPonderators < 99.99545454545456 ||
        sumPonderators >= 100.00545454545457
      ) {
        color = 'red';
      }

      return (
        <span>
          <Tooltip title={t('has_manual_ponderator_childs')}>
            <span style={{ paddingRight: 5, color }}>
              {sumPonderators.toFixed
                ? sumPonderators.toFixed(2)
                : sumPonderators}
            </span>
          </Tooltip>

          {onlyRead ? null : (
            <Popconfirm
              onConfirm={() => enableAutoPonderator(parent)}
              title={t('are_sure_general')}
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}>
              <Tooltip placement="top" title={t('active_ponderator_automatic')}>
                <img
                  src={
                    parent.tasks
                      ? rollbackPonderatorIcon
                      : rollbackPonderatorInvertedIcon
                  }
                  className="rollback-ponderator-icon"
                />
              </Tooltip>
            </Popconfirm>
          )}
        </span>
      );
    }
  };

  /**
   * This function defines what content show inside each header with activity data
   * @param {*} column Metadata from the column that is going to be extracted the task
   * @param {*} activity Activity object to extract the column data
   */
  const defineActivityColumnContent = (column, activity) => {
    if (column.name == 'name') {
      return (
        <div>
          {activity.tasks.length && !onlyRead ? (
            <CustomCheckbox
              inverted
              onClick={() => {
                props.massiveSelectionHandler(activity);
                // updateState()
              }}
              active={activity.active}
            />
          ) : null}
          {defineCollapseIcon(activity)}
          <span>
            {activity.correlative_id} | {activity[column.name]}
          </span>
          <span style={{ marginLeft: 15 }}>
            {splitText(reversPathTasks(activity.activityRoute), {
              ...column,
              view: 'resources'
            })}
          </span>
        </div>
      );
    } else if (column.name == 'taskRoute') {
      return (
        <div style={{ display: 'flex', marginLeft: '1rem' }}>
          {splitText(
            reversPathTasks(`${activity.activityRoute} > ${activity.name}`),
            { ...column, view: 'resources' }
          )}
        </div>
      );
    } else if (column.data_type == 'string' || column.data_type == 'number') {
      const val = calculateExpected(activity, ganttAPI, activity.calendarId);
      let progressColor = '#52c41a';
      if (activity.progress.toFixed(2) == '100.00') {
        progressColor = '#34AF00';
      } else if (activity.progress >= val) {
        progressColor = '#2C3421';
      } else if (activity.progress < val) {
        progressColor = '#E50101';
      }
      if (column.name == 'progress') {
        const percentVal = activity[column.name].toFixed
          ? activity[column.name].toFixed(2)
          : activity[column.name];
        return (
          <Progress
            strokeColor={{ '0%': progressColor, '100%': progressColor }}
            className="progress-custom-props"
            percent={percentVal}
          />
        );
      } else if (column.name == 'ponderator') {
        return checkPonderatorIcon(activity);
      }

      return activity[column.name] ? (
        activity[column.name]
      ) : (
        <div className="transparent-for-activity-column-header">-</div>
      );
    } else if (column.data_type == 'date') {
      if (column.name == 'end_date') {
        return transformDateWithHour(activity[column.name])
          .split(' ')[0]
          .split('-')
          .join('/');
      }
      return activity[column.name] ? (
        activity[column.name].split(' ')[0].split('-').join('/')
      ) : (
        <div className="transparent-for-activity-column-header">-</div>
      );
    } else if (
      column.data_type == 'array/images' ||
      column.data_type == 'array/string' ||
      column.data_type == 'array/icon'
    ) {
      return activity[column.name] ? (
        activity[column.name].map((responsable, index) => (
          <Tooltip title={responsable.email} key={index}>
            <img
              src={responsable.avatar ? responsable.avatar : fakeAvatar}
              height={15}
              width={15}
              style={{ marginBottom: 3, marginRight: 2, borderRadius: 20 }}
            />
          </Tooltip>
        ))
      ) : (
        <div className="transparent-for-activity-column-header">-</div>
      );
    }
  };

  /**
   * This function renders each activity column, and then calls defineActivityColumnContent to define
   * what jsx els must be showed inside of this col
   * @param {*} column Metadata from the column that is going to be extracted the task
   * @param {*} activity Activity object to extract the column data
   * @param {*} index Index from array iteration
   */
  const renderActivityColumn = (column, activity, index) => {
    if (column.visible) {
      return (
        <Col
          className="single-column-header"
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineActivityColumnContent(column, activity)}
        </Col>
      );
    }
  };

  /**
   * This function render the header from the table, with the activity data
   */
  const renderActivityHeader = (lastLvlActivity) => (
    <Row className="custom-header-top-list">
      {props.tableMetadata.map((eachColumn, index) =>
        renderActivityColumn(eachColumn, lastLvlActivity, index)
      )}
    </Row>
  );

  const addCopyOfActivity = (activity) => {
    createTask(null, 'New task', activity, true);
  };

  /**
   * This function render Add Constraint button
   */
  const renderAddTask = () => {
    if (onlyRead) {
      return null;
    }

    let copyActivity = null;
    if (!props.lastLvlActivity.tasks.length) {
      copyActivity = (
        <span
          className="addTaskBtn"
          style={{ marginLeft: 35 }}
          onClick={() => addCopyOfActivity(props.lastLvlActivity)}>
          {t('lookahead_resources.add_repit')}
        </span>
      );
    }

    return (
      <div>
        <span
          className="addTaskBtn"
          onClick={() => handleAdd(props.lastLvlActivity)}>
          <PlusOutlined />
          {t('lookahead_resources.add')}
        </span>
        {copyActivity}
      </div>
    );
  };

  const createActivityModificationRequest = async (task, activity) => {
    const loggedUser = getSignedUser();
    const projectId = projectState.projectSelected;
    const sectorId = projectState.sectorSelected;
    const link = base.front + 'masterplan/' + sectorId + '/' + projectId;

    const activityModification = {
      type: task.unfitType,
      state: 'waiting',
      description: modalModification.description,
      activityId: activity.id,
      userRequestId: loggedUser.id,
      startOriginal: activity.start_date,
      endOriginal: activity.end_date,
      user: loggedUser,
      projectId,
      sectorId,
      link
    };

    if (task.unfitType == 'start') {
      activityModification.startNew = task.start_date;
      activityModification.endNew = activity.end_date;
    } else if (task.unfitType == 'end') {
      activityModification.startNew = activity.start_date;
      activityModification.endNew = task.end_date;
    } else if (task.unfitType == 'both') {
      activityModification.startNew = task.start_date;
      activityModification.endNew = task.end_date;
    }

    const res = await activityModificationService.create(activityModification);
    dispatch(userActions.setUserUpdate());
    if (res) {
      openNotification({
        type: 'success',
        title: t('success_request_notify')
      });
      setModalModification({
        ...modalModification,
        visible: false
      });
    }
  };

  /**
   * Render
   */
  return (
    <Row key={props.index}>
      <Col
        className="tableConstraints"
        style={{ marginTop: 10, marginBottom: 10 }}>
        {renderActivityHeader(props.lastLvlActivity)}
        {renderTasks(props.lastLvlActivity)}
      </Col>
      {renderAddTask()}

      {/* Modal for creating custom ponderator */}
      <Modal
        title={t('carefull_modal_title')}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="submit" type="primary" onClick={disableAutoPonderator}>
            {t('got_it_disable_auto_ponderator')}
          </Button>
        ]}>
        <p>
          {t('with_this_action_disable')}
          <b>{t('bold_ponderator_manual_label')}</b>
          {t('rest_of_disable_message')}
        </p>
      </Modal>

      {/* Modal for dealing with activity modification request */}
      <Modal
        wrapClassName="activity-modification-style"
        title={modalModification.title}
        visible={modalModification.visible}
        onCancel={() =>
          setModalModification({ ...modalModification, visible: false })
        }
        footer={[
          <Button
            onClick={() =>
              createActivityModificationRequest(
                modalModification.data.task,
                modalModification.data.activity
              )
            }
            key="submit"
            style={{
              background: '#7DFF8A',
              color: '#121212',
              borderColor: '#7DFF8A'
            }}>
            {t('modals.lookahead.activity_modification_request.send_request')}
          </Button>
        ]}>
        {modalModification.visible ? (
          <div>
            <div>
              <span className="name-activity-modification">
                {modalModification.data.activity.name}
              </span>
            </div>
            <div className="dates-container-activity-modification">
              <span className="calendar-activity-modification">
                <img src={calendarModificationIconModal} width={12} />
              </span>
              <span className="dates-activity-modification">
                {modalModification.data.activity.start_date.split(' ')[0]}
              </span>
              <span className="calendar-activity-modification">
                <img src={calendarModificationIconModal} width={12} />
              </span>
              <span className="dates-activity-modification">
                {modalModification.data.activity.end_date.split(' ')[0]}
              </span>
            </div>
            <div className="modification-title-activity-modification">
              {defineTitleModification(modalModification.data.task, t)}
            </div>
            {defineDateUnfit(
              modalModification.data.task,
              modalModification.data.activity,
              t
            )}
            <div className="commentary-container-activity-modification">
              {t('modals.lookahead.activity_modification_request.comment')}
            </div>
            <div>
              <TextArea
                onChange={({ target: { value } }) => {
                  modalModification.description = value;
                }}
                placeholder={t(
                  'modals.lookahead.activity_modification_request.description_placeholder'
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </div>
          </div>
        ) : null}
      </Modal>
    </Row>
  );
}

export default withTranslation()(LookaheadActivityResource);
