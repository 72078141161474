import React from 'react';
import { Tabs } from 'antd';

import styles from './TabPanel.module.scss';

export const TabPanel = ({ tabs, defaultActiveKey }) => {
  const handleTabClick = (key, e) => {
    const tab = tabs.find((tab) => tab.key === key) ?? {};
    tab.onClick && tab.onClick(e);
  };

  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      type="card"
      className={styles['tab-panel']}
      onTabClick={handleTabClick}>
      {tabs.map((tab, i) => (
        <Tabs.TabPane
          tab={tab.title}
          key={tab.key ?? i + 1}
          disabled={tab.disabled}>
          {tab.component}
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};
