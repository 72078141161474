import React, { useEffect, createRef, useState } from 'react';

/** To animating views easely */
import { Animated } from 'react-animated-css';

/** Build components from antd css framework */
import {
  Row,
  Col,
  Tooltip,
  Popover,
  DatePicker,
  Popconfirm,
  Icon,
  Select
} from 'antd';

import Range from 'react-range-progress';

import SelectSearch from 'react-select-search';
import {
  getTask,
  getEndDateByGantt,
  getTasksToDelete,
  deleteTaskHandler,
  calculatePonderators,
  getRecursiveFromParentTask,
  calculateProgress,
  calculateLeanStatus,
  launchInlineEditorEvent
} from '../../utils/lookahead-common';

import {
  avoidOptimizationTimelineBlinking,
  updateTasksSelected,
  massiveUnselected,
  updateActivityParent,
  updateAsyncActivityGantt,
  updateAsyncTaskGanttV2,
  updateIsParent,
  createTask,
  getStringFromMoment,
  findDeepGetTask,
  updateTaskMassive,
  updateParentDuration,
  getReferences,
  updateReferences,
  addToQueueToSave,
  evaluateCasoOfAllChildrenDeleted
} from '../GanttVisualization/GanttVisualization.helper';

/** import library for format numbers */
import NumberFormat from 'react-number-format';

import { durationMassiveSvg, materialMassiveSvg } from '../../utils/svgIcons';

import { compareValues, helmet } from '../../utils';

import { capitalize } from 'lodash';
import IconComponent from '../Projects/IconSvg/';

/** services */
import { taskService } from '../../services/task.service';
import { activityService } from '../../services/activity.service';

/** Massive actions icons */
import constraintMassive from '../../assets/img/massive/constraint.png';
import datesMassive from '../../assets/img/massive/dates.png';
import deleteMassive from '../../assets/img/massive/delete.png';
import priorityMassive from '../../assets/img/massive/priority.png';
import progressMassive from '../../assets/img/massive/progress.png';
import responsableMassive from '../../assets/img/massive/responsable.png';
import leanMassive from '../../assets/img/massive/EstadoLean.png';
import duplicateMassive from '../../assets/img/massive/duplicate.png';
import quitDismissIcon from '../../assets/img/gantt/quit-header-config.png';
import fakeAvatar from '../../assets/img/fake_user.png';
import {
  convertPPActivity2PluginTask,
  recursiveTransformForTasks
} from '../../views/lookahead/planification/index.helper';
import { assignCorrelativeNumbers } from '../GanttVisualization/GanttVisualization.helper';
import moment from 'moment';
/** Function to clone objects on JS */
import cloneDeep from 'lodash/cloneDeep';
import { TrashIcon } from '../../icons';
import { GREEN_COLOR } from '../ActivityCardDrawer/components/tabs/DependenciesTab/constants';

const { Option } = Select;
export const TestIds = {
  MASSIVE_TIMELINE_VIEW_CONTAINER: 'MASSIVE_TIMELINE_VIEW_CONTAINER'
};

const MassiveTimelineView = ({
  massiveSelection = [],
  massiveOnProcess = false,
  t = () => {},
  massiveConstraintHandler = () => {},
  activities = [],
  tableConfig = [],
  getActivityAndParentReference = () => {},
  toSelectResponsables = [],
  groupBy = {
    criteria: ''
  },
  updateAsyncTask = () => {},
  updateAsyncActivity = () => {},
  projectState = {},
  updateRender = () => {},
  updateGanttVisualizationState = () => {},
  notifyMessage = () => {},
  setMassiveSelection = () => {},
  subContracts = [],
  loadStoredGroupQuery = () => {},
  dateRange = {
    start: '',
    end: ''
  },
  tableMetadata = [],
  defaultView = {},
  getLookaheadInMassive = () => {},
  ganttAPI = {},
  sectorObject = {}
}) => {
  const [massiveResponsableState, setMassiveResponsableState] = useState({
    values: []
  });

  /** const */
  const gantt = window.ganttVisualization;
  const refInputMasssiveTotal = createRef();
  const userLang = navigator.language || navigator.userLanguage;

  /** hooks */
  const [massiveProgress, setMassiveProgress] = useState(0);
  const [toActivityDuplicate, setToActivityDuplicate] = useState({
    value: null
  });

  /** fuction's class */
  const ProgressBar = (props) => {
    const { value, setValue } = props;
    const [progress, setProgress] = useState(value);
    return (
      <div
        onMouseUp={() => {
          setValue(progress);
        }}
        style={{ paddingLeft: 5 }}>
        <span>{progress}%</span>
        <Range
          className="progress-massive-bar"
          style={{ top: -2 }}
          value={progress}
          fillColor={{
            r: 245,
            g: 157,
            b: 4,
            a: 1
          }}
          trackColor={{
            r: 245,
            g: 245,
            b: 245,
            a: 1
          }}
          height={7}
          width="58%"
          onChange={(value) => {
            setProgress(value);
          }}
        />
      </div>
    );
  };

  /**
   * This function builds a JSX to select responsables at inline edition
   */
  const renderFriend = (props, option, _snapshot, className) => {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10
    };

    let toShowName = `${option.object.name} ${option.object.lastname}`;

    if (toShowName) {
      if (toShowName.length > 17) {
        toShowName = toShowName.slice(0, 17) + '...';
      }
    }

    return (
      <button
        {...props}
        className={className}
        type="button"
        title={option.object.email}>
        <span>
          <img alt="" style={imgStyle} width={20} src={option.photo} />
          <span style={{ fontSize: 15 }}>{toShowName}</span>
        </span>
      </button>
    );
  };

  const renderMassiveResponsable = () => {
    const friends = [];
    const selected = [];
    const dictionary = {};

    massiveResponsableState.values.map((res) => {
      selected.push(res.email);
    });

    toSelectResponsables.map((user) => {
      if (user.is_active) {
        friends.push({
          name: user.name + ' ' + user.lastname + ' (' + user.email + ')',
          value: user.email,
          photo: user.image || fakeAvatar,
          object: user
        });
        dictionary[user.email] = user;
      }
    });

    return (
      <SelectSearch
        printOptions="always"
        className="select-search-massive select-search-massive--multiple"
        options={friends}
        value={selected}
        renderOption={renderFriend}
        onChange={(val) => {
          massiveResponsableState.values = [];
          val.map((op) => {
            massiveResponsableState.values.push(dictionary[op]);
          });
        }}
        multiple
        search
        placeholder={t('search_res_text')}
      />
    );
  };

  /** massive duration's functions */
  const getRecursiveDurationForParent = (parent, column, gantt) => {
    const originalValue = JSON.stringify(parent.sumDuration);
    let total = 0;
    parent.children.map((el) => {
      total += el.duration;
    });
    parent.sumDuration = total;
    if (JSON.stringify(total) != originalValue) {
      updateAsyncTaskGanttV2(parent, column.name, null, gantt);
    }
  };

  const updateAsyncParentDuration = (idTask, activityReference) => {
    const getReferenceTask = getTask(idTask, null, activityReference);
    const taskRef = getReferenceTask[0] || null;
    const column = { name: 'duration' };

    getEndDateByGantt(taskRef, activityReference);
    getRecursiveDurationForParent(taskRef, column, gantt);
    getRecursiveFromParentTask(
      taskRef,
      column.name,
      () => {},
      updateDataColumnArray
    );

    if (taskRef.parent_id) {
      updateAsyncParentDuration(taskRef.parent_id, activityReference);
    }
  };

  /** this function update end date & duration on save data */
  const updateDataColumnArray = (taskCallback, columnArray = ['progress']) => {
    const fieldsToUpdate = columnArray;
    const taskToUpdate = gantt.getTask(taskCallback.id);
    fieldsToUpdate.forEach((el) => {
      taskToUpdate[el] = taskCallback[el];
    });
    gantt.updateTask(taskToUpdate.id);
  };

  const massiveLeanHandle = (option) => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          const released = reference.constraints.filter(
            (constraint) => constraint.status == 'released'
          );
          const hasConstraints = reference.constraints.length;
          const hasAllReleased =
            reference.constraints.length == released.length;
          /** First case: Has constraints but are all of them released or Has not constraints */
          if (
            reference.lean_status !== 'Committed' &&
            ((hasConstraints && hasAllReleased) || !hasConstraints)
          ) {
            const childsHaveLeanStatusWill = reference.children.some(
              (el) => el.lean_status === 'Will'
            );
            if (!childsHaveLeanStatusWill) {
              reference.lean_status = option.value;
              updateAsyncTaskGanttV2(
                reference,
                'lean_status',
                option.value,
                gantt
              );
              calculateLeanStatus(
                reference,
                parentTask || activity,
                activity,
                updateAsyncTask,
                updateAsyncActivity
              );
            }
          }
        }
      });
    }
    gantt.render();
  };

  const massiveDurationHandle = () => {
    const value = refInputMasssiveTotal.current.state.value;
    const arrToUpdate = [];
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        /** get task from gantt */
        const taskGantt = gantt.getTask(selection.id);
        const { activityReference, taskFromLookahead, parentTask } =
          getReferences(taskGantt);

        /** commitment percentaje */
        gantt.updateCustomCommitmentPercentaje(taskGantt, false);

        /** calculate End date with new duration in Days */
        taskGantt.durationDays = value;
        taskGantt.duration = parseFloat(taskGantt.durationDays);
        getEndDateByGantt(taskGantt, activityReference);

        /** calculate new Duration with calendar */
        const durationToSave = ganttAPI.calculateDuration(
          taskGantt.start_date,
          taskGantt.end_date,
          taskGantt.calendar_id
        );

        /** update references with new duration and end date */
        updateReferences(taskGantt, taskFromLookahead, durationToSave);

        /** refresh view */
        gantt.updateTask(taskFromLookahead.id);
        launchInlineEditorEvent(gantt)(taskFromLookahead, 'duration', value);
        addToQueueToSave(arrToUpdate, taskFromLookahead);

        /** update parent */
        if (taskFromLookahead.parent_id) {
          updateParentDuration(
            taskFromLookahead.parent_id,
            activityReference,
            arrToUpdate,
            gantt,
            ganttAPI,
            projectState
          );
        }
      });
    }
    /** save data */
    updateTaskMassive(arrToUpdate);

    launchWeeklyLookaheadIntegrationUpdateValues();
    gantt.render();
  };

  /**
   * On click function when pressing delete
   * @param {*} task Top lvl task to start tree deleting (REQUIRED)
   * @param {*} activity Activity parent object (REQUIRED)
   * @param {*} parent If task is from another than first level must be specified the parent task to attack it children array
   */
  const handleDelete = async (
    task,
    activity,
    parent = null,
    parentTasksWithAllChildrenDeleted
  ) => {
    avoidOptimizationTimelineBlinking();
    const tasksToDelete = [];
    getTasksToDelete(task, tasksToDelete);
    const asyncMap = tasksToDelete.map(async (toDelete) => {
      const isAllTasksDeleted = parentTasksWithAllChildrenDeleted.some(
        (task) => task.parent_id === parent.id && task.parent_id !== null
      );
      await deleteTaskHandler(
        toDelete,
        activity,
        parent,
        updateAsyncTask,
        updateAsyncActivity,
        projectState,
        isAllTasksDeleted
      );
    });

    await Promise.all(asyncMap);
    gantt.detachMultiDragSelect && gantt.detachMultiDragSelect();
  };

  const quitDismissHandler = () => {
    avoidOptimizationTimelineBlinking();
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );
        activity.active = false;
        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.active = false;
        }
      });
      massiveUnselected(gantt, setMassiveSelection);
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            const reference = doesExistAtReference;
            reference.active = false;
            ac.active = false;
          }
        });
      });
      massiveUnselected(gantt, setMassiveSelection);
    }
    gantt.dragMultiActive = false;
    gantt.selectedTasksMultiDrag = [];
    gantt.multiSelectTroughCheckbox = false;
    gantt.detachMultiDragSelect && gantt.detachMultiDragSelect();
    gantt.render();
  };

  const massiveDuplicateHandler = async () => {
    const copy_massiveSelection = cloneDeep(massiveSelection);
    if (copy_massiveSelection.length > 0) {
      const duplicateactivity = activities.find(
        (a) => a.id == toActivityDuplicate.value
      );

      modificateTaskInitByActivity(
        duplicateactivity,
        copy_massiveSelection,
        sectorObject
      );
    }
    const res = await taskService.duplicateAll(
      copy_massiveSelection,
      projectState.sectorSelected,
      toActivityDuplicate.value
    );
    if (res) {
      let start = dateRange.start;
      let end = dateRange.end;
      if (defaultView.current.lookahead_init) {
        start = defaultView.current.lookahead_init || start;
        end = defaultView.current.lookahead_end || end;
      }
      const lastLevelActivities = await activityService.getLookaheadIds(
        getLookaheadInMassive
      );
      const data = lastLevelActivities.activities;
      const copiedActivity = data.find(
        (el) => el.id == toActivityDuplicate.value
      );
      let proplannerTask;
      const replace = activities.map((ac) => {
        if (ac.id == toActivityDuplicate.value) {
          ac = copiedActivity;
          proplannerTask = ac;
        }
        return ac;
      });
      updateRender(replace);

      const hashIdToUnique = {
        [proplannerTask.proplannerId]: proplannerTask.id
      };
      const mainParents = [];
      const final = [];
      const convertedActivityToGanttRef =
        convertPPActivity2PluginTask(proplannerTask);
      mainParents.push(convertedActivityToGanttRef);
      mainParents.map((mainLvlGanttTask) => {
        final.push(mainLvlGanttTask);
        recursiveTransformForTasks(mainLvlGanttTask, final, hashIdToUnique);
        delete mainLvlGanttTask.tasks;
      });
      const duplicateactivity = activities.find(
        (a) => a.id == convertedActivityToGanttRef.proplannerId
      );
      final.forEach((taskGanttRef) => {
        if (!gantt.isTaskExists(taskGanttRef.id)) {
          gantt.createTask(
            taskGanttRef,
            taskGanttRef.parent || convertedActivityToGanttRef.id
          );
          setTimeout(() => {
            // debugger
            const taskFromGantt = gantt.getTask(taskGanttRef.id);
            // console.log('taskFromGantt', taskFromGantt)
            taskFromGantt.start_date = gantt.date.parseDate(
              taskGanttRef.non_parsed_original_start_date,
              'xml_date'
            );
            taskFromGantt.end_date = gantt.date.parseDate(
              taskGanttRef.non_parsed_original_end_date,
              'xml_date'
            );
            taskFromGantt.duration =
              taskGanttRef.non_parsed_original_duration || 0;
            taskFromGantt.constraint_date = gantt.date.parseDate(
              taskGanttRef.non_parsed_original_constraint_date,
              'xml_date'
            );
            gantt.updateTask(taskGanttRef.id);
            //  updateAsyncTaskGanttV2(taskGanttRef, ['start_date', 'end_date', 'duration'], null, gantt)
          }, 2000);
        }
      });

      assignCorrelativeNumbers(final.filter((task) => task.type !== 'main'));
      gantt.render();
      notifyMessage({
        title: t('duplicate_succesfully_title'),
        message: t('duplicate_succesfully'),
        type: 'success'
      });
      // setMassiveSelection([])
    }
  };

  const modificateTaskInitByActivity = async (activity, tasks, sector) => {
    try {
      for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i];
        const activity_last = {
          ...activities.find((a) => a.id == task.activityId)
        };
        const activity_temp = { ...activity };
        const diferencia =
          ganttAPI.calculateDuration(
            moment(activity_last.start_date).format('YYYY/MM/DD HH:mm'),
            moment(task.start_date).format('YYYY/MM/DD HH:mm'),
            activity_temp.calendarId
          ) / sector.hoursPerDay;
        const new_date_init = ganttAPI.getEndByDuration(
          activity_temp.start_date,
          diferencia,
          task.id,
          activity_temp.calendarId,
          sector
        );
        const new_date = ganttAPI.getEndByDuration(
          new_date_init.end_date,
          task.duration,
          task.id,
          activity_temp.calendarId,
          sector
        );
        task.start_date = new_date.start_date;
        task.end_date = new_date.end_date;
        task.correlative_number = null;
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const renderSelectDuplicateTasks = () => {
    const avoidActivities = [];
    massiveSelection.map((singleTask) => {
      const activityId = singleTask.activityId;
      if (!avoidActivities.includes(activityId)) {
        avoidActivities.push(activityId);
      }
    });
    return (
      <Select
        placeholder={t('lookahead.activity')}
        style={{ width: 319 }}
        onChange={(e) => {
          toActivityDuplicate.value = e;
        }}>
        {activities.map((ac) => {
          if (!avoidActivities.includes(ac.id)) {
            return (
              <Option value={ac.id} key={ac.id}>
                <Tooltip
                  overlayStyle={{ width: 350, maxWidth: 350, minWidth: 350 }}
                  title={
                    <div style={{ textAlign: 'center' }}>
                      {ac.activityRoute}
                    </div>
                  }>
                  <div>{ac.name}</div>
                </Tooltip>
              </Option>
            );
          }
        })}
      </Select>
    );
  };

  /**
   * This function deletes tasks massively
   */
  const massiveDeleteHandler = async () => {
    massiveSelection.sort(compareValues('children'));
    const arrMass = [];

    /** Declare an async iteration */
    const asyncMap = massiveSelection.map(async (selection) => {
      const { activity, parentTask } = getActivityAndParentReference(
        selection.activityId,
        selection.parent_id
      );
      /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
      const doesExistAtReference = getTask(selection.id, null, activity);
      if (doesExistAtReference.length) {
        const taskRef = doesExistAtReference[0];
        if (taskRef) {
          if (taskRef && taskRef.id) {
            if (gantt.isTaskExists(taskRef.id)) {
              await gantt.deleteTask(taskRef.id);
            }
          }
          await handleDelete(
            taskRef,
            activity,
            parentTask,
            parentTasksWithAllChildrenDeleted
          );
          if (!activity.tasks.length) {
            activity.active = false;
          }
          if (
            parentTask &&
            parentTask?.children &&
            parentTask.children?.length === 0
          ) {
            try {
              updateIsParent(taskRef, parentTask, gantt);
            } catch (err) {
              console.log('error', err);
            }
          }
          if (parentTask?.id) {
            arrMass.push({
              parentTask,
              activity
            });
          }
        }
      }

      if (activity.tasks.length > 0) {
        assignCorrelativeNumbers(activity.tasks);
      }
    });

    const parentTasksWithAllChildrenDeleted = evaluateCasoOfAllChildrenDeleted(
      massiveSelection,
      window.activities
    );

    /** We await of async iteration */
    await Promise.all(asyncMap);
    setTimeout(() => {
      updateGanttVisualizationState();
    }, 500);
    const arr = [];
    const hash = {};
    arrMass.forEach(({ parentTask, activity }) => {
      if (parentTask && parentTask.id && !hash[parentTask.id]) {
        if (gantt.isTaskExists(parentTask.id)) {
          if (activity && parentTask.children.length) {
            updateParentDuration(
              parentTask.id,
              activity,
              arr,
              gantt,
              ganttAPI,
              projectState
            );
            hash[parentTask.id] = true;
          }
        }
      }
    });

    /** save data */
    await updateTaskMassive(arrMass);

    /** Then update the render, notify of process, and clean the massive selection state */
    notifyMessage({
      title: t('deleted_succesfully_title'),
      message: t('deleted_succesfully'),
      type: 'success'
    });
    setMassiveSelection([]);
    massiveUnselected(gantt, setMassiveSelection);
  };

  const updateDataProgress = (taskCallback) => {
    /** check status according progress */
    if (taskCallback.progress == 0) {
      taskCallback.status = 'Waiting';
    } else if (taskCallback.progress > 0 && taskCallback.progress < 100) {
      taskCallback.status = 'Doing';
    } else {
      taskCallback.status = 'Done';
    }
    updateAsyncTaskGanttV2(taskCallback, ['progress', 'status'], null, gantt);
  };

  /**
   * This function handle massive change of priority
   * @param {*} option Single object with selected priority
   */
  const massivePriorityHandle = async (option) => {
    let asyncMap;
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      asyncMap = massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          updateAsyncTaskGanttV2(reference, 'priority', option.value, gantt);
        }
      });
      /** Other group by logic massive update */
    } else {
      asyncMap = massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            const reference = doesExistAtReference;
            updateAsyncTaskGanttV2(reference, 'priority', option.value, gantt);
          }
        });
      });
      if (groupBy.criteria == 'priority') {
        loadStoredGroupQuery(activities);
      }
    }
    await Promise.all(asyncMap);
    updateTasksSelected(gantt);
  };

  /**
   * This function handles the massive start date change
   * @param {*} date date f rom datepicker at dom
   */
  const massiveStartHandle = async (date) => {
    let asyncMap;
    const arrToUpdate = [];
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      asyncMap = massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.start_date = date;
          getEndDateByGantt(reference, activity);
          addToQueueToSave(arrToUpdate, reference, [
            'start_date',
            'duration',
            'end_date'
          ]);
          const taskGantt = gantt.getTask(reference.id);
          taskGantt.start_date = reference.start_date;
          taskGantt.end_date = reference.end_date;
          gantt.updateTask(taskGantt.id);

          /** update parent */
          if (reference.parent_id) {
            updateParentDuration(
              reference.parent_id,
              activity,
              arrToUpdate,
              gantt,
              ganttAPI,
              projectState
            );
          }
        }
      });
    }
    await Promise.all(asyncMap);
    /** save data */
    updateTaskMassive(arrToUpdate);

    updateTasksSelected(gantt);
    launchWeeklyLookaheadIntegrationUpdateValues();
  };

  const launchWeeklyLookaheadIntegrationUpdateValues = () => {
    setTimeout(() => {
      gantt && gantt.refreshView && gantt.refreshView();
    }, 500);
  };

  /**
   * This function handles with massive progress change
   */
  const massiveProgressHandler = async () => {
    const asyncMap = massiveSelection.map(async (selection) => {
      const { activity, parentTask } = getActivityAndParentReference(
        selection.activityId,
        selection.parent_id
      );

      /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
      const doesExistAtReference = getTask(selection.id, null, activity);
      if (doesExistAtReference.length) {
        const reference = doesExistAtReference[0];
        if (!reference.children.length) {
          reference.progress = massiveProgress;
          if (massiveProgress == 0) {
            if (calculatePonderators) {
              calculatePonderators(
                parentTask || activity,
                activity,
                updateAsyncTask,
                projectState
              );
            }
          }
          updateDataProgress(reference);
          const updateAsyncActivityGanttRef = updateAsyncActivityGantt(
            gantt,
            reference,
            updateAsyncActivity,
            updateActivityParent,
            projectState.sectorSelected
          );
          await calculateProgress(
            reference,
            parentTask || activity,
            activity,
            updateAsyncTask,
            updateAsyncActivityGanttRef
          );
        }
      }
    });
    await Promise.all(asyncMap);
    gantt.render();
  };

  /**
   * This function handles change massive responsable change
   */
  const massiveResponsableHandler = async () => {
    let asyncMap;
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      asyncMap = massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          const newValue = [];
          massiveResponsableState.values.map((res) => {
            newValue.push(res);
          });
          updateAsyncTaskGanttV2(reference, 'responsables', newValue, gantt);
        }
      });
      /** Other group by logic massive update */
    } else {
      asyncMap = massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            const reference = doesExistAtReference;
            const newValue = [];
            massiveResponsableState.values.map((res) => {
              newValue.push(res);
            });
            updateAsyncTaskGanttV2(reference, 'responsables', newValue, gantt);
          }
        });
      });
      if (groupBy.criteria == 'responsables') {
        loadStoredGroupQuery(activities);
      }
    }
    await Promise.all(asyncMap);
    updateTasksSelected(gantt);
  };

  /**
   * This function handles change massive Subcontract change
   */
  const massiveSubcontractHandler = async (option) => {
    let asyncMap;
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      asyncMap = massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );
        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          updateAsyncTaskGanttV2(
            reference,
            'subcontractId',
            option.value,
            gantt
          );
        }
      });
      /** Other group by logic massive update */
    } else {
      asyncMap = massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            const reference = doesExistAtReference;
            updateAsyncTaskGanttV2(
              reference,
              'subcontractId',
              option.value,
              gantt
            );
          }
        });
      });
    }
    await Promise.all(asyncMap);
    updateTasksSelected(gantt);
  };

  return (
    <Animated
      className="massiveToolbar"
      style={{ height: massiveSelection.length ? 30 : 0, overflow: 'hidden' }}
      animationIn="fadeIn"
      animationInDuration={250}
      animationOut="fadeOut"
      animationOutDuration={250}
      isVisible={!!massiveSelection.length}>
      <Row
        data-testid={TestIds.MASSIVE_TIMELINE_VIEW_CONTAINER}
        className="lookahead-massive-actions">
        <Col span={12} offset={6} style={{ height: '100%' }}>
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            style={{ height: '100%' }}>
            {massiveOnProcess ? null : (
              <Col style={{ textAlign: 'center' }}>
                {/* Selection text indicator */}
                <span style={{ color: '#7DFF8A' }}>
                  {massiveSelection.length + ' '}
                </span>
                <span style={{ color: '#FFFFFF', marginRight: 23 }}>
                  {t('lookahead_resources.masive_task')}
                  {massiveSelection.length > 1 ? 's' : null}{' '}
                  {t('lookahead_resources.masive_selected')}
                  {massiveSelection.length > 1
                    ? userLang.includes('es')
                      ? 's'
                      : null
                    : null}
                </span>

                {/* Massive constraints */}
                <span
                  className="massive-icon-style"
                  onClick={massiveConstraintHandler}>
                  <Tooltip placement="top" title={t('constraints_label_only')}>
                    <img width={12} src={constraintMassive} />
                  </Tooltip>
                </span>

                {/* Massive Progress */}
                {groupBy.criteria == 'activity' ||
                groupBy.criteria == 'activityId' ? (
                  <span className="massive-icon-style">
                    <Popover
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          <div style={{ textAlign: 'center', width: 111 }}>
                            {t('progress_only_label')}
                          </div>
                          <ProgressBar
                            value={massiveProgress}
                            setValue={setMassiveProgress}
                          />
                          <div
                            className="progress-massive-btn"
                            onClick={massiveProgressHandler}>
                            {t('apply_label')}
                          </div>
                        </span>
                      }
                      trigger="click">
                      <Tooltip placement="top" title={t('progress_only_label')}>
                        <img width={12} src={progressMassive} />
                      </Tooltip>
                    </Popover>
                  </span>
                ) : null}

                {/* Massive Duration */}
                {groupBy.criteria == 'activity' ||
                groupBy.criteria == 'activityId' ? (
                  <span className="massive-icon-style">
                    <Popover
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          <div style={{ textAlign: 'center', width: 111 }}>
                            {t('tables.lookahead.plan.duration')}
                          </div>
                          <div className="div-number-progress-massive">
                            <NumberFormat
                              ref={refInputMasssiveTotal}
                              className="only-number-progress-massive"
                              defaultValue={0}
                              autoComplete="off"
                              displayType={'input'}
                              style={{ textAlign: 'center' }}
                              autoFocus
                              onFocus={(e) => e.target.select()}
                            />
                          </div>
                          <div
                            className="progress-massive-btn"
                            onClick={massiveDurationHandle}>
                            {t('apply_label')}
                          </div>
                        </span>
                      }
                      trigger="click">
                      <Tooltip
                        placement="top"
                        title={t('tables.lookahead.plan.duration')}>
                        <span>
                          <IconComponent
                            data={durationMassiveSvg}
                            width={14}
                            fill="#fff"
                            className="icon-massive-duration"
                          />
                        </span>
                      </Tooltip>
                    </Popover>
                  </span>
                ) : null}

                {/* Massive Responsable */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {renderMassiveResponsable()}
                        <div
                          className="progress-massive-btn"
                          onClick={massiveResponsableHandler}>
                          {t('apply_label')}
                        </div>
                      </span>
                    }
                    trigger="click">
                    <Tooltip
                      placement="top"
                      title={t('responsable_label_only')}>
                      <img width={12} src={responsableMassive} />
                    </Tooltip>
                  </Popover>
                </span>

                {/* Subcontract */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {tableConfig
                          .find((col) => col.name == 'subcontractId')
                          ?.from_values.map((option, index) => {
                            const sub = subContracts?.find(
                              (sub) => sub.id === option.value
                            );
                            return (
                              <div
                                style={{ cursor: 'pointer' }}
                                key={index}
                                onClick={() =>
                                  massiveSubcontractHandler(option)
                                }>
                                <IconComponent
                                  data={helmet}
                                  width={15}
                                  fill={'white'}
                                  className="icon-options-subcontract"
                                />
                                <span style={{ marginLeft: 5 }}>
                                  {sub
                                    ? capitalize(sub.name)
                                    : capitalize(option.label)}
                                </span>
                              </div>
                            );
                          })}
                      </span>
                    }
                    trigger="click">
                    <Tooltip
                      placement="top"
                      title={t('subcontract_only_label')}>
                      <span>
                        <IconComponent
                          data={helmet}
                          width={15}
                          fill={'white'}
                          className="icon-options-subcontract"
                        />
                      </span>
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive LEAN */}
                {groupBy.criteria == 'activity' ||
                groupBy.criteria == 'activityId' ? (
                  <span className="massive-icon-style">
                    <Popover
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          {tableMetadata
                            .find((col) => col.name == 'lean_status')
                            .from_values.map((option, index) => {
                              if (
                                option.value == 'Committed' ||
                                option.value == 'Restricted'
                              )
                                return null;
                              return (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  key={index}
                                  onClick={() => massiveLeanHandle(option)}>
                                  <i
                                    className="fas fa-circle border-icon"
                                    style={{
                                      fontSize: 11,
                                      color: option.color,
                                      position: 'relative',
                                      top: -1
                                    }}
                                  />
                                  <span style={{ marginLeft: 5 }}>
                                    {capitalize(option.label)}
                                  </span>
                                </div>
                              );
                            })}
                        </span>
                      }
                      trigger="click">
                      <Tooltip placement="top" title={t('lean_status_only')}>
                        <img width={12} src={leanMassive} />
                      </Tooltip>
                    </Popover>
                  </span>
                ) : null}

                {/* Massive start date */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        <DatePicker
                          allowClear={false}
                          className="custom-date-picker-planification"
                          style={{ left: -4 }}
                          format={'YYYY/MM/DD'}
                          placeholder={t('select_date_label_only')}
                          onChange={(_date, dateString) =>
                            massiveStartHandle(dateString)
                          }
                        />
                      </span>
                    }
                    trigger="click">
                    <Tooltip placement="top" title={t('start_only_label')}>
                      <img width={12} src={datesMassive} />
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive Priority */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {tableMetadata
                          ?.find((col) => col.name == 'priority')
                          ?.from_values?.map((option, index) => {
                            return (
                              <div
                                style={{ cursor: 'pointer' }}
                                key={index}
                                onClick={() => massivePriorityHandle(option)}>
                                <img
                                  src={option.icon}
                                  width={11}
                                  style={{ position: 'relative', top: 0 }}
                                />
                                <span style={{ marginLeft: 5 }}>
                                  {capitalize(option.label)}
                                </span>
                              </div>
                            );
                          })}
                      </span>
                    }
                    trigger="click">
                    <Tooltip placement="top" title={t('priority_label_only')}>
                      <img width={12} src={priorityMassive} />
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive duplicate */}
                {groupBy.criteria == 'activity' ||
                groupBy.criteria == 'activityId' ? (
                  <span className="massive-icon-style">
                    <Popover
                      overlayStyle={{ width: 350 }}
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          <div
                            style={{
                              textAlign: 'center',
                              width: 'auto',
                              paddingBottom: 5
                            }}>
                            {t('duplicate_label')}
                          </div>
                          {renderSelectDuplicateTasks()}
                          <div
                            className="progress-massive-btn"
                            style={{ marginTop: 8 }}
                            onClick={massiveDuplicateHandler}>
                            {t('apply_label')}
                          </div>
                        </span>
                      }
                      trigger="click">
                      <Tooltip placement="top" title={t('duplicate_label')}>
                        <img width={12} src={duplicateMassive} />
                      </Tooltip>
                    </Popover>
                  </span>
                ) : null}

                {/* Massive delete */}
                {groupBy.criteria == 'activity' ||
                groupBy.criteria == 'activityId' ? (
                  <span className="massive-icon-style">
                    <Popconfirm
                      placement={'bottom'}
                      onConfirm={massiveDeleteHandler}
                      title={t('are_sure_general')}
                      icon={
                        <Icon
                          type="question-circle-o"
                          style={{ color: 'red' }}
                        />
                      }>
                      <Tooltip
                        placement="top"
                        title={t('lookahead_resources.masive_delete')}>
                        <span>
                          <TrashIcon color={GREEN_COLOR} />
                        </span>
                      </Tooltip>
                    </Popconfirm>
                  </span>
                ) : null}

                {/* Quit Dismiss */}
                <Tooltip
                  placement="top"
                  title={t('lookahead_resources.masive_cancel')}>
                  <span
                    className="massive-icon-style"
                    onClick={quitDismissHandler}>
                    <img
                      width={12}
                      src={quitDismissIcon}
                      style={{ position: 'relative', top: -1 }}
                    />
                  </span>
                </Tooltip>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Animated>
  );
};

export default MassiveTimelineView;
