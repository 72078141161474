import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './styles.scss';
import { HOME_TIMELINE_VIEW } from '../../../../hooks/useSelectProject';
import TooltipSystemV2 from '../../../DesignSystem/TooltipSystemV2';
import TooltipWrapper from '../../../TooltipWrapper';

const handleProjectClick = (gantt, project) => () => {
  if (!gantt.processedProjects[project.id]) {
    return;
  }

  gantt.onClickProject(
    gantt.processedProjects[project.id],
    HOME_TIMELINE_VIEW,
    gantt.analytics
  );
};

const shouldShowTooltip = ({ scrollWidth, clientWidth }) => {
  return scrollWidth > clientWidth;
};

const ProjectCell = ({ gantt, project }) => {
  const projectNameRef = useRef(null);
  const scheduleNameRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState({
    project: false,
    schedule: false
  });

  const options = [
    {
      key: 'project',
      ref: projectNameRef,
      className: 'project-name',
      text: project.name
    },
    {
      key: 'schedule',
      ref: scheduleNameRef,
      className: 'main-schedule',
      onClick: handleProjectClick(gantt, project),
      text: project.scheduleName
    }
  ];

  useLayoutEffect(() => {
    if (!projectNameRef.current || !scheduleNameRef.current) {
      return;
    }

    setShowTooltip({
      project: shouldShowTooltip(projectNameRef.current),
      schedule: shouldShowTooltip(scheduleNameRef.current)
    });
  }, [project]);

  return (
    <div className="project-cell">
      {options.map(({ key, text, ...props }) => (
        <TooltipWrapper key={key} title={text} shouldWrap={showTooltip[key]}>
          <span key={key} {...props}>
            {text}
          </span>
        </TooltipWrapper>
      ))}
    </div>
  );
};

export default ProjectCell;
