import React from 'react';
import { Layout } from 'antd';
import { Animated } from 'react-animated-css';
import registerViewImg from '../assets/img/imgRegister.jpg';

const { Sider, Content } = Layout;

const withoutSidenav = (props) => (WrappedComponent) => () => (
  <Animated
    animationIn="slideInLeft"
    animationInDuration={750}
    isVisible={true}
    style={{ height: '100%' }}>
    <Layout id="layoutWide">
      <Sider>
        <img
          style={{ height: '100%', width: '100%' }}
          src={registerViewImg}
          alt=""
        />
      </Sider>
      <Layout>
        <Content>
          <WrappedComponent />
        </Content>
      </Layout>
    </Layout>
  </Animated>
);

export default withoutSidenav;
