import { createHttpClient } from '../utils/httpUtils';

class DefaultView {
  constructor() {
    this.http = createHttpClient();
  }

  async getDefaultView(sectorId) {
    const res = await this.http.get('view/' + sectorId).catch((error) => {
      console.log(error.response);
      return error.response;
    });

    if (res.status === 401) {
      return [];
    }
    return res ? res.data : res;
  }

  async updateDefaultView(companyId, defaultView) {
    const res = await this.http
      .post('view/' + companyId, defaultView)
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return res ? res.data : res;
  }
}

export const defaultViewService = new DefaultView();
