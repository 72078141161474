import React, { useState, useEffect } from 'react';
import { Table, Avatar, Progress, Dropdown, Row, Col, Select } from 'antd';
import fakeAvatar from '../../../../assets/img/fake_user.png';
import './index.css';
import moment from 'moment';
import IconComponent from '../../../../components//Projects/IconSvg';
import { formatMoney } from '../../../../utils/lookahead-common';
import { filter } from '../../../../utils';

export default function PcrForUser(props) {
  const t = props.t;
  const users_props = props.users;
  let total_pcr_weeks = props.total_pcr_weeksF;
  const { Column } = Table;
  const constraintsArrayUser = props.constraintsArrayUser;
  const [data, setData] = useState([]);
  const [filterVisibility, setFilterVisibility] = useState(false);
  const [sectorFilter, setSectorFilter] = useState([]);
  const [users, setusers] = useState([]);
  const planificationDay = props.planificationDay;

  useEffect(() => {
    initPrc();
  }, [constraintsArrayUser]);

  async function initPrc() {
    const users_rest = users_props;
    setusers(users_rest);
    if (total_pcr_weeks.length) {
      total_pcr_weeks = total_pcr_weeks.sort((a, b) => {
        if (b.year == a.year) return a.week - b.week;
        return a.year - b.year;
      });
    }
    const push_check_sector = [];
    push_check_sector.push(
      <Option key={0} value={['todo', 'todo']}>
        {t('lang') == 'es' ? 'Todo' : 'All'}
      </Option>
    );

    let prevWeeK = 0;
    let prevYear = 0;
    total_pcr_weeks.length &&
      total_pcr_weeks.map((c) => {
        if (prevWeeK != c.week || prevYear != c.year) {
          push_check_sector.push(
            <Option key={c.id} value={[c.week, c.year]}>
              {moment()
                .locale(t('lang'))
                .year(c.year.toString())
                .week(c.week)
                .startOf('isoweek')
                .add(planificationDay + 5, 'days')
                .format('DD MMM YY')}
            </Option>
          );
        }
        prevWeeK = c.week;
        prevYear = c.year;
      });

    setSectorFilter(push_check_sector);
    getUsers(users_rest);
  }

  const handleFilterDropdown = (val) => {
    setFilterVisibility(val);
  };

  function handleChangeWeek(value) {
    getUsers(users, value[0], value[1]);
  }

  async function getUsers(users, week = 'todo', year) {
    const pre_data = [];
    Object.keys(constraintsArrayUser).forEach((key, index) => {
      const user_pro = users.find((u) => u.id == key);
      if (user_pro) {
        let total = 0;
        let realized = 0;
        let diferent_count = 0;
        let reproramin = 0;
        let diferente_total = 0;
        let arrayfilter = [];
        if (week != 'todo') {
          arrayfilter = constraintsArrayUser[key].filter(
            (c) => c.week == week && c.year == year
          );
        } else {
          arrayfilter = constraintsArrayUser[key];
        }

        arrayfilter.map((c) => {
          if (c.status == 'released') {
            if (c.release_date) {
              const commitmentDateFormat = moment.utc(
                c.commitmentDate,
                'YYYY-MM-DD'
              );
              const releaseDateFormat = moment.utc(
                c.release_date,
                'YYYY-MM-DD'
              );
              if (
                commitmentDateFormat.isSameOrAfter(releaseDateFormat, 'day')
              ) {
                // if (releaseDateFormat.isSameOrAfter(commitmentDateFormat, 'day')) {
                realized++;
              }
            }
          }
          total++;
          if (c.reprogramin) {
            reproramin++;
          } else {
            const diferente = moment.duration(
              moment(c.commitmentDate).diff(moment(c.createdAt))
            );
            diferente_total = diferente_total + diferente.asDays();
            diferent_count++;
          }
        });
        let commitment_percentaje = 0;
        if (total > 0) {
          commitment_percentaje = ((realized / total) * 100).toFixed(0);
        }
        const dato = {
          user: user_pro,
          ppc: commitment_percentaje,
          reprograming: reproramin,
          realizes: realized + '/' + total,
          total: total,
          diferent: (diferente_total / diferent_count).toFixed(2)
        };
        pre_data.push(dato);
      }
    });
    setData(pre_data);
  }
  const { Option } = Select;

  return (
    <div className="contenctPcrForUser">
      <br />
      <h1 className="user_h1">{t('analitic.team_constraints')}</h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: 30,
          marginBottom: 32,
          marginTop: -40
        }}>
        <Row className="lookahead-filter-header filer_user">
          <Col span={10}>
            <Col style={{ height: '100%', textAlign: 'center' }} span={3}>
              <Dropdown
                overlay={
                  <div className="sector_check_filter">
                    <Select
                      style={{ width: '150px' }}
                      onChange={handleChangeWeek}
                      placeholder={t('analitic.select_week')}>
                      {sectorFilter}
                    </Select>
                    ,
                    <br />
                    <br />
                  </div>
                }
                trigger={['click']}
                visible={filterVisibility}
                onVisibleChange={handleFilterDropdown}>
                <div
                  className="filter_buton"
                  style={{ cursor: 'pointer', fontSize: '12px' }}>
                  <IconComponent
                    data={filter}
                    fill="#53C255"
                    className="svg-icon-filter"
                  />
                </div>
              </Dropdown>
            </Col>
          </Col>
        </Row>
      </div>
      <div className="user_hr"></div>
      <Table
        className="tablescroll"
        dataSource={data}
        scroll={{ y: 400 }}
        style={{ width: '100%', height: '400' }}
        pagination={false}>
        <Column
          title={t('master_plan.responsible')}
          dataIndex="user"
          key="user"
          width="50%"
          className="resposable-title"
          render={(user) => (
            <div>
              <Avatar
                className="avantar"
                shape="circle"
                src={user.image ? user.image : fakeAvatar}
                size={40}
              />
              <div className="content-title">
                <p className="user-title">{user.name + ' ' + user.lastname}</p>
                <p
                  className="user-position-sub"
                  style={{
                    position: 'relative'
                  }}>
                  {user.position}
                </p>
              </div>
            </div>
          )}
        />
        <Column
          title={t('lang') === 'es' ? 'PCR' : 'PRR'}
          dataIndex="ppc"
          className="other-title"
          key="ppc"
          render={(ppc) => (
            <div>
              <Progress
                type="circle"
                percent={ppc}
                width={40}
                strokeColor={ppc >= 50 ? '#2C3421' : 'red'}
                className="porgressbar"
                trailColor="#fff"
              />
            </div>
          )}
        />
        <Column
          title={t('lookahead_resources.constraints')}
          dataIndex="realizes"
          key="realizes"
          className="other-title"
          render={(realizes) => <p className="user-position">{realizes}</p>}
        />
        <Column
          title={t('analitic.reprogramed')}
          dataIndex="reprograming"
          key="reprograming"
          className="other-title"
          render={(reprograming) => (
            <div>
              <p className="user-position">{reprograming}</p>
            </div>
          )}
        />
        <Column
          title={t('analitic.anticipation_days')}
          dataIndex="diferent"
          key="diferent"
          className="other-title"
          render={(diferent) => (
            <div>
              <p className="user-position">
                {isNaN(diferent)
                  ? 0
                  : t('lang') === 'en'
                    ? formatMoney(parseFloat(diferent), '', 2, '.', ',')
                    : formatMoney(parseFloat(diferent), '', 2, ',', '.')}
              </p>
            </div>
          )}
        />
      </Table>
    </div>
  );
}
