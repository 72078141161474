/** React components  */
import React, { useState } from 'react';

import { Tooltip, Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import CustomDropdownSelector from '../CustomDropdownSelector';
import { useEffect } from 'react';
import { getTypeNotification, notifyMessageCustom } from '../../utils';
import { MassiveAvatarComp } from '../GanttMassiveToolbar/icons';

export default function GanttMassiveResponsable(props) {
  const gantt = window.to_use_react_gantt;
  const { tooltipI18nKey, t, selectedActivities } = props;
  const defaultState = {
    mainObject: {},
    options: props.toSelectResponsables.filter((user) => user.is_active),
    attrToExtractFromOption: 'id',
    attrToLabelFromOption: 'name'
  };
  const [state, setState] = useState(defaultState);
  const [isHovered, setIsHovered] = useState(false);
  const [usersSelected, setUsersSelected] = useState([]);
  const [dictionary, setDictionary] = useState([]);

  useEffect(() => {
    setState(defaultState);
    const gantt = window?.to_use_react_gantt;
    if (gantt) {
      gantt.optimizedRender();
    }
  }, [props.toSelectResponsables]);

  useEffect(() => {
    /** unMount component */
    return () => {
      setUsersSelected([]);
    };
  }, []);

  const handleMouseEnter = (e) => {
    setIsHovered(true);
  };

  const handleMouseLeave = (e) => {
    setIsHovered(false);
  };

  const customMainClassName = 'massive-responsible-class';
  const isOnlyReadElement = false; // onlyRead || task.readonly || (subtradeRole && subtradeRole.isSubtradeRole)
  const renderSelectedOption = (selected) => {};

  const onSelection = async (payload, dictionary) => {
    setUsersSelected(payload);
    if (dictionary && !dictionary.length) setDictionary(dictionary);
  };

  const applyChanges = async () => {
    if (selectedActivities.length) {
      const undoExtension = gantt.ext.undo;
      gantt.batchUpdate(function () {
        selectedActivities.forEach((act) => {
          const actGet = gantt.getTask(act.id);
          undoExtension.saveState(act.id, 'task');
          const usersSelectedObj = usersSelected.map((userId) => {
            return dictionary[userId];
          });
          if (actGet.responsables) {
            actGet.responsables = usersSelectedObj;
          }
          gantt.updateTask(actGet.id);
        });
      });
      gantt.ext.keyboardNavigation.focus({
        type: 'taskCell',
        id: selectedActivities[0].id,
        column: 'responsables'
      });
      let description = t('massive_notif_responsible');
      if (usersSelected.length === 0) {
        description = t('massive_notif_responsible_clear');
      }
      notifyMessageCustom({
        type: getTypeNotification(MassiveAvatarComp),
        description
      });
    }
  };

  const renderResponsibleToSelect = (option) => {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10,
      position: 'relative',
      left: 4,
      top: 1
    };

    let toShowName =
      option.object.name || option.object.lastname
        ? `${option.object?.name} ${option.object?.lastname}`
        : `${option.object.email}`;
    if (toShowName) {
      if (toShowName.length > 25) {
        toShowName = toShowName.slice(0, 25) + '...';
      }
    }

    return (
      <span style={{ display: 'flex' }}>
        {option?.object?.image ? (
          <img
            className="img-massive-responsable"
            alt=""
            style={imgStyle}
            width="16"
            height="16"
            src={option.object.image}
          />
        ) : (
          <Avatar
            className="avatar-dropdown-massive"
            size={19}
            icon={<UserOutlined />}
          />
        )}
        <div
          className="cut-text-and-dot"
          style={{ fontSize: 12, width: '100%' }}
          title={toShowName}>
          {toShowName}
        </div>
      </span>
    );
  };

  const callbackRemove = (_) => {};

  const renderCustomFooter = () => {
    return <Button>{t('apply_label')}</Button>;
  };

  return (
    <span className="gm-responsible">
      <CustomDropdownSelector
        {...state}
        hideCreateBtn={true}
        customMainClassName={customMainClassName || ''}
        renderCustomSelectionOption={renderResponsibleToSelect}
        renderCustomPlaceholderElement={renderSelectedOption}
        renderCustomFooter={renderCustomFooter}
        callbackOnSelection={onSelection}
        callbackOnClickCreate={() => {}}
        callbackOnClickRemove={callbackRemove}
        callbackOnClickFooter={applyChanges}
        onlyread={isOnlyReadElement}
        closeOnclick={false}
        multiple
        calbackOnVisibleChange={() => setUsersSelected([])}>
        <Tooltip placement="top" title={t(tooltipI18nKey)}>
          <span
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}>
            {props.icon && (
              <props.icon color={isHovered ? '#7DFF8A' : '#FFFFFF'} />
            )}
          </span>
        </Tooltip>
      </CustomDropdownSelector>
    </span>
  );
}
