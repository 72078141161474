import React, { memo } from 'react';
import TruncatedTextSystem from '../../../../../DesignSystem/TruncatedTextSystem';

const Name = memo(({ name, width }) => {
  return (
    <TruncatedTextSystem
      name={name}
      maxWidth={width > 1600 ? 130 : 70}
      placement={'topLeft'}>
      <span className="name-column">{name}</span>
    </TruncatedTextSystem>
  );
});

export default Name;
