import React, { Fragment, useState, useEffect, useRef } from 'react';
import {
  Modal,
  Icon,
  Button,
  Select,
  Spin,
  Row,
  Col,
  Input,
  Upload
} from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import './index.css';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { ChromePicker } from 'react-color';

import { trackingEvent } from '../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';

// services
import { projectService } from '../../../services/project.service';
import { subContractService } from '../../../services/subcontract.service';

import { base } from '../../../services/base';
import helmetIcon from '../../../assets/img/gantt/helmet-new.png';
import closeIcon from '../../../assets/img/gantt/close-links-modal.png';
import placeholderSub from '../../../assets/img/gantt/placeholder-subcontrract.png';

/** import library for antd upload crop */
import ImgCrop from 'antd-img-crop';

import {
  openNotification,
  checkAllFieldsValid,
  reduceFormValues,
  headersSendForm,
  getBase64,
  ObjectLogoSvg
} from '../../../utils';

/** PNG to put on users without image */
import defaultImg from '../../../assets/img/defaulSubcontract.png';

import styled from 'styled-components';

const { confirm } = Modal;

function ModalAddSubContract({
  Visible,
  setVisible,
  setReloadTableUsers,
  ReloadTableUsers,
  modCompany = false,
  t,
  setSubcontractsReload = null,
  setIsReloadSubcontracts = null,
  newSubcontractFunction = null,
  setDataSubcontractsExternal = null
}) {
  const location = useLocation();
  // GET ID PROJECT FROM REDUX
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;
  const formRef = useRef(null);

  // GET CURRENT COMPANY FOR REDUX
  const stateCompany = useSelector((state) => state.companyState);
  const currentCompany = stateCompany.currentCompany;

  const { Option } = Select;

  const txtFieldState = {
    value: '',
    valid: true,
    typeMismatch: false,
    errMsg: ''
  };

  const createSubContract = async (data) => {
    const resp = await subContractService.create(data);
    return resp;
  };

  // validation map form
  const stateTemplate = {
    namesub: {
      ...txtFieldState,
      fieldName: 'namesub',
      required: true,
      requiredTxt: t('confirmation.name_req'),
      formatErrorTxt: ''
    },
    rutsub: {
      ...txtFieldState,
      fieldName: 'rutsub',
      required: false,
      requiredTxt: 'El RUT es requerido',
      formatErrorTxt: ''
    },
    emailsub: {
      ...txtFieldState,
      fieldName: 'emailsub',
      required: false,
      requiredTxt: 'El email es requerido',
      formatErrorTxt: t('register.user_error_format')
    },
    typesub: {
      ...txtFieldState,
      fieldName: 'typesub',
      required: false,
      requiredTxt: 'El tipo es requerido',
      formatErrorTxt: ''
    },
    allFieldsValid: false,
    isLoading: false
  };

  const templateTags = {
    displayColorPicker: false,
    color: {
      r: '35',
      g: '108',
      b: '242',
      a: '1'
    },
    hex: '#236CF2'
  };

  const [stateColor, setStateColor] = useState(templateTags); // state
  const [state, setState] = useState(stateTemplate); // state
  const [subcontracts, setSubcontracts] = useState([]); // state

  const resetForm = () => {
    setState(stateTemplate);
    setVisible(false);
  };

  const asignProjectSubcontract = async (data) => {
    const resp = await projectService.asignProjectSubContract(data);
    return resp;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!formRef.current) return;
    const form = formRef.current;
    const formValues = reduceFormValues(form.elements, state);
    const allFieldsValid = checkAllFieldsValid(formValues);

    const existName = subcontracts.some(
      (el) =>
        el.name.toLowerCase().trim() ===
        formValues.namesub.value.toLowerCase().trim()
    );

    if (existName) {
      const warningSubContractName = {
        title: t('settings.subcontracts'),
        description: t('settings.project_subcontracts.warning_name'),
        type: 'warning'
      };
      openNotification(warningSubContractName);
      return false;
    }
    setLoading(true);
    setState(() => ({ ...state, ...formValues, allFieldsValid }));

    if (allFieldsValid) {
      setState({
        ...state,
        isLoading: true
      });
      setReloadTableUsers(false);
      const data = {
        name: state.namesub.value,
        rut: state.rutsub.value,
        email: state.emailsub.value,
        type: state.typesub.value,
        companyId: currentCompany.id,
        color: stateColor.hex
      };
      /** save image uploaded */
      data.image = state.stateUpload ? state.stateUpload.name : null;

      const resp = await createSubContract(data);
      if (resp) {
        // assign users to project
        if (!modCompany) {
          if (resp.id) {
            await asignProjectSubcontract({
              subcontractId: resp.id,
              projectId: projectSelectedId
            });
          }
        }
        const succesSubContract = {
          title: t('settings.subcontracts'),
          description: t('success_subtrade'),
          type: 'success'
        };
        const eventSources = [
          { path: '/settings/project', source: 'Project Settings' },
          { path: '/settings/company', source: 'Company Settings' },
          { path: '/lookahead', source: 'Lookahead' }
        ];
        trackingEvent(
          'company_creation',
          {
            ...getBasicAmplitudEventProperties(),
            event_source: eventSources.find((src) =>
              location?.pathname?.includes(src.path)
            )?.source,
            company_created_name: data.name,
            company_created_id: resp.id
          },
          AMPLITUDE_SERVICE
        );
        if (setSubcontractsReload) {
          setSubcontractsReload(true);
        }
        openNotification(succesSubContract);

        if (typeof setDataSubcontractsExternal === 'function') {
          setDataSubcontractsExternal(true);
        } else {
          setReloadTableUsers(true);
        }

        if (typeof setIsReloadSubcontracts === 'function') {
          setIsReloadSubcontracts(true);
        }
        // setReloadTableUsers(!ReloadTableUsers)

        if (newSubcontractFunction) {
          newSubcontractFunction(resp);
        }
      }
      resetForm();
      setState({
        ...state,
        isLoading: false
      });
    } else {
      const { emailsub, namesub, rutsub, typesub } = formValues;
      const succesSubContract = {
        title: t('settings.subcontracts'),
        description: namesub.requiredTxt,
        type: 'error'
      };
      if (!namesub.valid) {
        succesSubContract.description = namesub.requiredTxt;
      }
      if (emailsub.value && !emailsub.valid) {
        succesSubContract.description = emailsub.requiredTxt;
        if (emailsub.typeMismatch) {
          succesSubContract.description = emailsub.formatErrorTxt;
        }
      }

      openNotification(succesSubContract);
    }
    setLoading(false);
  };

  // Close modal
  const handleCancel = () => {
    setVisible(false);
  };
  // Save emails
  const handleOk = () => {
    setVisible(false);
  };

  /** this function is triggered when upload image */
  const onChangeUpload = (info) => {
    if (info.file.status === 'uploading') {
      setState({
        ...state,
        stateUpload: { loading: true }
      });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response
      getBase64(info.file.originFileObj, (imageUrl) =>
        setState({
          ...state,
          stateUpload: {
            imageUrl,
            name: info.file.response.name,
            loading: false
          }
        })
      );
    }
  };

  const [ProjectSelected, setProjectSelected] = useState({});

  /** component Logo by default */
  const ProjectIcon = (props) => <Icon component={ObjectLogoSvg} {...props} />;

  /** component upload button  */
  const uploadButton = (
    <div>
      {state.stateUpload && state.stateUpload.loading ? (
        <LoadingOutlined />
      ) : (
        <ProjectIcon className="FrmCompany" />
      )}
    </div>
  );

  // GET PROJECT GENERAL SELECTED BY USER
  useEffect(() => {
    if (Visible) {
      setState(stateTemplate);
      const abortController = new AbortController();
      projectService
        .show(projectSelectedId)
        .then((response) => {
          if (response.project === undefined) {
            setProjectSelected({});
          } else {
            setProjectSelected(response.project);
          }
        })
        .catch(() => setProjectSelected({}));
      return function cleanup() {
        abortController.abort();
      };
    }
  }, [Visible]);

  useEffect(() => {
    if (Visible) getAllSubconttracts();
  }, [Visible]);

  const getAllSubconttracts = async () => {
    const idCurrentCompany = stateCompany.currentCompany?.id;
    const response = await subContractService.getByCompany(idCurrentCompany);
    if (response) {
      setSubcontracts(response.subcontracts);
    }
  };

  // messages
  const [Loading, setLoading] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const handleClick = () => {
    const newState = {
      ...stateColor,
      displayColorPicker: !stateColor.displayColorPicker
    };
    setStateColor(newState);
  };

  const handleChange = (color) => {
    const newState = {
      ...stateColor,
      color: color.rgb,
      hex: color.hex
    };
    setStateColor(newState);
  };

  const handleClose = () => {
    const newState = {
      ...stateColor,
      displayColorPicker: false
    };
    setStateColor(newState);
  };

  return (
    <Fragment>
      <Modal
        className="frmModalAdd custom-tag-modal"
        visible={Visible}
        closable={false}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        maskClosable={false}
        width={520}
        footer={
          Loading ? (
            <div style={{ textAlign: 'center' }}>
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <CenterButtons>
              <div className="center-btn-modal-company right-add-tag-center">
                <button
                  className="btn-cancel-tag"
                  onClick={(e) => handleCancel()}>
                  {t('user_config.cancel_btn')}
                </button>
                <button className="btn-add-tag" onClick={(e) => onSubmit(e)}>
                  {t('user_config.add_btn')}
                </button>
              </div>
            </CenterButtons>
          )
        }>
        <div className="wrapp-sub-contract">
          <div className="title-frm-add">
            <span>
              <img width={16} src={helmetIcon} />
            </span>
            <span style={{ marginLeft: 10, fontSize: 16 }}>
              {t('user_config.new_subcontract')}
            </span>
            <div className="title-close" onClick={() => setVisible(false)}>
              <img width={14} src={closeIcon} />
            </div>
          </div>
          <div>
            <form
              ref={formRef}
              id="frm-subcontracts"
              className="frmWide"
              onSubmit={onSubmit}
              noValidate>
              <Row>
                <Col>
                  <Row>
                    <Col span={18}>
                      <Row>
                        <div style={{ textAlign: 'left', marginLeft: 18 }}>
                          <span
                            style={{
                              color: 'white',
                              fontSize: 12,
                              marginRight: 12,
                              display: 'inline-block',
                              width: 92
                            }}>
                            {t('user_config.subcontract_name')}
                          </span>
                          <Input
                            style={{
                              maxWidth: 230
                            }}
                            autoComplete="off"
                            type="text"
                            name="namesub"
                            className="input-add-subcontract"
                            placeholder={''}
                            value={state.namesub.value}
                            onChange={(e) => {
                              /** update state with value selected */
                              setState({
                                ...state,
                                namesub: {
                                  ...state.namesub,
                                  value: e.target.value,
                                  valid: true /** clear validation */
                                }
                              });
                            }}
                            required
                          />
                        </div>
                      </Row>
                      <Row>
                        <div style={{ textAlign: 'left', marginLeft: 18 }}>
                          <span>
                            <span
                              style={{
                                color: 'white',
                                fontSize: 12,
                                marginRight: 12,
                                display: 'inline-block',
                                width: 92
                              }}>
                              {t('user_config.subcontract_id')}
                            </span>
                            <Input
                              style={{
                                maxWidth: 118
                              }}
                              autoComplete="off"
                              type="text"
                              name="rutsub"
                              // maxLength={9}
                              className="input-add-subcontract"
                              placeholder={''}
                              value={state.rutsub.value}
                              onChange={(e) => {
                                setState({
                                  ...state,
                                  rutsub: {
                                    ...state.rutsub,
                                    value: e.target.value,
                                    valid: true
                                  }
                                });
                              }}
                            />
                          </span>
                          <span style={{ marginLeft: 17 }}>
                            <span
                              style={{
                                color: 'white',
                                fontSize: 12,
                                marginLeft: 10,
                                marginRight: 10
                              }}>
                              {t('color')}
                            </span>
                            <div
                              style={{
                                padding: '3px',
                                background: '#3A4F56',
                                borderRadius: '4px',
                                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                                display: 'inline-block',
                                cursor: 'pointer',
                                position: 'relative',
                                top: 4,
                                left: 3
                              }}
                              onClick={() => handleClick()}>
                              <div
                                style={{
                                  width: '36px',
                                  height: '14px',
                                  borderRadius: '2px',
                                  background: `${stateColor.hex}`
                                }}
                              />
                            </div>
                            {stateColor && stateColor.displayColorPicker ? (
                              <div
                                style={{
                                  position: 'absolute',
                                  zIndex: '2',
                                  left: 313
                                }}>
                                <div
                                  style={{
                                    position: 'fixed',
                                    top: '0px',
                                    right: '0px',
                                    bottom: '0px',
                                    left: '0px'
                                  }}
                                  onClick={() => handleClose()}
                                />
                                <ChromePicker
                                  className="picker-wrapp"
                                  color={stateColor.color}
                                  onChange={(color) => handleChange(color)}
                                />
                              </div>
                            ) : null}
                          </span>
                        </div>
                      </Row>
                    </Col>
                    <Col span={6} style={{ position: 'relative', left: -27 }}>
                      {/* .custom-tag-modal                                            */}
                      <ImgCrop
                        rotate
                        shape="rect"
                        aspect={5 / 3}
                        modalWidth={850}>
                        <Upload
                          className="upload-subcontract"
                          action={`${base.api}subcontracts/upload`}
                          listType="picture-card"
                          showUploadList={false}
                          headers={headersSendForm}
                          onChange={onChangeUpload}>
                          {state.stateUpload && state.stateUpload.imageUrl ? (
                            <img
                              width={60}
                              className="img-logo"
                              src={state.stateUpload.imageUrl}
                              alt="logo project"
                            />
                          ) : (
                            <div
                              className="img-subcontract"
                              style={{ position: 'relative', top: -15 }}>
                              <img width={60} src={placeholderSub} alt="" />
                            </div>
                          )}
                        </Upload>
                      </ImgCrop>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={24}
                      style={{ textAlign: 'left', marginLeft: 18 }}>
                      <span
                        style={{
                          color: 'white',
                          fontSize: 12,
                          display: 'inline-block',
                          width: 92,
                          marginRight: 12
                        }}>
                        {t('user_config.subcontract_email')}
                      </span>
                      <Input
                        style={{
                          maxWidth: 118
                        }}
                        autoComplete="off"
                        type="email"
                        name="emailsub"
                        className="input-add-subcontract"
                        placeholder={''}
                        value={state.emailsub.value}
                        onChange={(e) => {
                          /** update state with value selected */
                          setState({
                            ...state,
                            emailsub: {
                              ...state.emailsub,
                              value: e.target.value
                              // valid: true /** clear validation */
                            }
                          });
                        }}
                      />
                      <span
                        style={{
                          color: 'white',
                          fontSize: 12,
                          marginLeft: 26,
                          marginRight: 10,
                          display: 'inline-block',
                          width: 28
                        }}>
                        {t('user_config.buscontract_type')}
                      </span>
                      <Input
                        style={{
                          width: 182,
                          marginLeft: 8
                        }}
                        autoComplete="off"
                        type="text"
                        name="typesub"
                        className="input-add-subcontract"
                        placeholder={''}
                        value={state.typesub.value}
                        onChange={(e) => {
                          /** update state with value selected */
                          setState({
                            ...state,
                            typesub: {
                              ...state.typesub,
                              value: e.target.value,
                              valid: true /** clear validation */
                            }
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

export default ModalAddSubContract;

const CenterButtons = styled.div`
  .center-btn-modal-company {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const ButtonSubcontractStyle = styled.div`
  .btn-continue-user-company {
    margin-top: 40px;
    text-align: center;
    user-select: none;
    padding: 0.15rem 0.1rem;
    line-height: 1.5;
    display: inline-block;
    border: 0px;
    cursor: pointer;
    color: #121212;
    width: 80px;
    font-size: 12px;
    height: 28px;
    border-radius: 5px;
    background-color: #7dff8a;
    transition: 0.3s;
    margin: 10px;
    border: none;
    outline: none;
  }
`;
