import { authService, userCompanyService } from '../../../services';

/**
 * Retrieves the list of companies associated with the current user.
 * @returns {Promise<Array<Object>>} A Promise that resolves with an array of company objects.
 */
export const getCompaniesByUser = async () => {
  try {
    const user = localStorage.getItem('user');
    if (!user) return [];

    const { id } = JSON.parse(user);
    if (!id) return [];

    const companies = await userCompanyService.getCompaniesByUser(id);
    return companies;
  } catch (error) {
    return [];
  }
};

/**
 * Retrieves the current company from session storage.
 * @returns {<Object>} A function that returns the current company object or with an empty object if the retrieval fails.
 */
export const getCompaniesLocal = () => {
  try {
    const currentCompany = JSON.parse(sessionStorage.getItem('company'));
    return currentCompany;
  } catch (err) {
    return {};
  }
};

/**
 * Retrieves the session token for the specified company and redirects the user home.
 * @param {number} companyId - The ID of the company.
 * @returns {Promise<void>} A Promise that resolves once the session token is retrieved and the user is redirected home.
 */
export const getSessionToken = (companyId, history) => {
  const asyncGetSessionToken = async () => {
    const { id: userId } = JSON.parse(localStorage.getItem('user'));
    const dataSessionToken = {
      userId,
      companyId
    };

    const session = await authService.createSession(dataSessionToken);
    const { user, sessionToken, permissions, company } = session.data;

    if (!sessionToken) return;

    localStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('sessionToken', JSON.stringify(sessionToken));
    sessionStorage.setItem('permissiontable', JSON.stringify(permissions));
    sessionStorage.setItem('company', JSON.stringify(company));

    const PROJECTS_PATH = '/projects';
    history.push(PROJECTS_PATH, { forceRefresh: true });
    if (window.location.href.includes(PROJECTS_PATH))
      window.location = PROJECTS_PATH;
  };

  asyncGetSessionToken();
};
