/* eslint-disable prefer-const */

/** React components  */
import React, { useState, useEffect, Fragment } from 'react';

/** Import elements from library Antd */
import { Upload, Icon, Modal, Row, Col, Select, Input, Button } from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons';

/** import common functions from helper */
import {
  stateTemplate,
  asignProjectSubcontract
} from '../../../views/project/project.helper';
import {
  defaultConstraintTypes,
  defaultCncTypes,
  defaultSubContracts
} from '../../Projects/FormProjectsDummy/dummyData';
import { socket } from '../../../services/socket.service';

/** import common functions from utils */
import {
  checkAllFieldsValid,
  reduceFormValues,
  userType,
  setAllProjectsGlobal,
  codeCountries,
  Types,
  generateFormObjectSelect,
  openNotification,
  projectCurrency,
  projectSizesTypes,
  compareValues,
  ListElement,
  getBase64,
  headersSendForm,
  ObjectLogoSvg
} from '../../../utils';

/** import Services */
import {
  sectorService,
  projectService,
  companyService,
  userService,
  constraintTypeService,
  cncTypeService,
  subContractService
} from '../../../services';

/** import library for format numbers */
import NumberFormat from 'react-number-format';

/** import base contant (setting urls)  */
import { base } from '../../../services/base';

/** Drag and Drop */
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

/** import library for antd upload crop */
import ImgCrop from 'antd-img-crop';

import ErrorValidationLabel from '../../Login/ValidationLabel';

/** Redux */
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../../redux/slices/projectSlice';
import { companyActions } from '../../../redux/actions/companyActions';
import { withTranslation } from 'react-i18next';
import { removeCurrencySeparators } from '../../../utils/currency';
import { trackingEvent } from '../../../analytics';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getSessionTokenData } from '../../../utils/userUtils';

/** constants */
const { Option } = Select;
function FormProjects(props) {
  const { t, setFormProjectVisible, formProjectVisible } = props;
  const SUPERADMIN = userType(t).find((el) => el.value === 'superadmin');
  /** redux */
  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.projectState);

  /** GET ID COMPANY CURRENT FROM REDUX */
  const stateCompany = useSelector((state) => state.companyState);
  const idCurrentCompany = stateCompany.currentCompany?.id;

  /** hooks */
  const [noSector, setNoSector] = useState(false);
  const [sectorsProject, setSectorsProject] = useState([]);
  const [sectorsOriginal, setSectorsOriginal] = useState([]);
  const [sectorName, setSectorName] = useState(null);
  const [projectId, setProjectId] = useState({});
  const [state, setState] = useState(stateTemplate); // validation
  const [filter] = useState('stage'); // handle filter option ()
  const [stateUpload, setStateUpload] = useState({ loading: false });

  const [projectPname, setProjectPname] = useState(''); // handle Pname
  const [projectType, setProjectType] = useState(); // handle Project Type
  const [projectSizeType, setProjectSizeType] = useState('ft2'); // hook for select timezone
  const [projectSize, setProjectSize] = useState(); // hook for select timezone
  const [projectPcurrency, setProjectPcurrency] = useState('USD'); // hook for select Country
  const [projectBudget, setProjectBudget] = useState(); // hook for select Country
  const [projectPcountry, setProjectPcountry] = useState('US'); // handle Project Country
  const [isLoading, setIsLoading] = useState(false); // Btn Loading
  const navigatorLang = navigator.language || navigator.userLanguage;
  const [currentProject, setCurrentProject] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [text, setText] = useState({
    text: ''
  });

  useEffect(() => {
    resetModalAddProject();
    if (projectState.props.currentProjectId) {
      ShowEditProject(projectState.props.currentProjectId);
    }
  }, [projectState]);

  /**
   * This function open dialog for edit project
   * @param {*} projectId Id of the project to edit
   */
  const ShowEditProject = async (projectId) => {
    const project = await getProject(projectId);
    setCurrentProject(project);
    setProjectPname(project.name);
    setProjectType(project.type);
    setProjectPcountry(project.country);
    setProjectSizeType(project.sizetype);
    setProjectSize(project.size);
    setProjectBudget(project.budget);
    setProjectPcurrency(project.currency);
    setProjectId(project.id);
    /** load avatar for current project */
    if (project.image) {
      setStateUpload({
        ...stateUpload,
        imageUrl: `${project.image}`
      });
    }

    const sectorsFiltered = project.sectors.filter((e) => e.status !== false);
    if (sectorsFiltered) {
      sectorsFiltered.sort(compareValues('order'));
    }
    setSectorsProject(sectorsFiltered);
    setSectorsOriginal(sectorsFiltered);
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const sessionTokenData = getSessionTokenData();

  /** Refresh projects list */
  const updateProjectsList = (arr, filterParam = 'stage') => {
    /** Redux event emitting function to set projects load from project actions */
    // dispatch(projectActions.setAllProjects(arr))
    setAllProjectsGlobal(arr, dispatch, projectActions, projectState);
  };

  const [selectedData, setSelectedData] =
    useState(null); /** state for select data item */

  /** Logic Drag Schedules */
  /**
   *  This function create a Element of a List Sortable
   */
  const SortableItem = SortableElement((props) => {
    const value = props.value;

    const index = props.i;
    return (
      <Fragment>
        <ListElement>
          <Row className="listElement" key={value.id}>
            <Col span={24}>
              <div className="element">{value.name}</div>
            </Col>
          </Row>
        </ListElement>
        <DeleteOutlined
          className={`${value.set_current ? 'deleteElement-not-allowed' : 'deleteElement-pointer'} deleteElement`}
          onClick={() => {
            setIsModalVisible(!value.set_current);
            setSelectedData({
              value: value,
              index: index
            });
          }}
        />
      </Fragment>
    );
  });

  const renderModalConfirmDelete = () => (
    <Modal
      wrapClassName="activity-modification-style"
      title={t('settings.project_form.sectors.sure')}
      visible={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false);
        text.text = '';
      }}
      footer={[
        <Button
          onClick={(e) => {
            e.preventDefault();
            if (sectorsProject.length == 1) {
              setNoSector(true);
              setIsModalVisible(false);
              return;
            }
            setNoSector(false);

            if (text.text === t('settings.project_form.sectors.borrar')) {
              deleteSector(selectedData.value, selectedData.index);
              setIsModalVisible(false);
              text.text = '';
            }
          }}
          key="submit"
          style={{
            background: '#7DFF8A',
            color: '#121212',
            borderColor: '#7DFF8A'
          }}>
          {t('settings.project_form.sectors.delete')}
        </Button>
      ]}>
      <Col span={24}>
        <div style={{ color: '#2C3421', textAlign: 'center' }}>
          {t('settings.project_form.sectors.deleting_sector')}
        </div>
        <div
          style={{
            color: '#2C3421',
            textAlign: 'center',
            marginTop: 7,
            marginBottom: 7
          }}>
          {t('settings.project_form.sectors.escribe')}{' '}
          <span style={{ color: '#7DFF8A' }}>
            {t('settings.project_form.sectors.borrar')}
          </span>{' '}
          {t('settings.project_form.sectors.rest_sector')}
        </div>
        <div style={{ textAlign: 'center' }}>
          <span>
            <input
              style={{ width: '50%' }}
              defaultValue={text.text}
              type="text"
              onChange={(e) => {
                text.text = e.target.value;
              }}
              className="structure-input"
            />
          </span>
        </div>
      </Col>
    </Modal>
  );

  /**
   *  This function create a List Sortable
   */
  const SortableList = SortableContainer(({ items }) => (
    <div>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          i={index}
          value={value}
        />
      ))}
      {renderModalConfirmDelete()}
    </div>
  ));

  /**
   *  This function handle Sort End Event
   */
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSectorsProject(arrayMove(sectorsProject, oldIndex, newIndex));
  };

  // sectors
  const deleteSector = (e, i) => {
    const filteredItems =
      e.id !== undefined
        ? sectorsProject.filter((el) => el.id !== e.id)
        : sectorsProject.filter((e, index) => index !== i);
    setSectorsProject(filteredItems);
  };

  const addSector = () => {
    if (sectorName) {
      const tmp = sectorsProject;
      tmp.push({ name: sectorName });
      setSectorsProject(tmp);
      setSectorName(null);
      setNoSector(false);
    } else {
      setSectorName('');
    }
  };

  /** Services for projects */
  async function getProject(id) {
    const resp = await projectService.show(id);
    return resp.project;
  }

  async function createProject(data) {
    const resp = await projectService.create(data);
    return resp;
  }

  async function asignProjectUser(data) {
    const resp = await projectService.asignProjectUser(data);
    return resp;
  }

  async function updateProject(data) {
    const resp = await projectService.update(data);
    return resp;
  }

  async function getCompany(companyId) {
    const resp = await companyService.show(companyId);
    return resp ? resp.company : false;
  }

  /** Services for Sectors */
  async function createSector(data) {
    const resp = await sectorService.create(data);
    return resp;
  }

  async function updateSector(data) {
    const resp = await sectorService.update(data);
    return resp;
  }

  const resetModalAddProject = () => {
    setStateUpload({ loading: false });
    setProjectPname();
    setProjectType();
    setProjectPcountry('US');
    setProjectSizeType('ft2');
    setProjectSize('');
    setSectorName();
    setProjectBudget('');
    setProjectPcurrency('USD');
    setSectorsProject([]);
    setNoSector(false);
    setState(stateTemplate);
  };

  // validation project
  const { pname, ptype, psizetype, psize, country, pbudget } = state;

  const renderPnameValidationError = pname.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={
        pname.typeMismatch ? pname.formatErrorTxt : t('confirmation.name_req')
      }
    />
  );

  const renderPtypeValidationError = ptype.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={t('confirmation.type_req')}
    />
  );

  const renderPsizetypeValidationError = psizetype.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={t('confirmation.typesize_req')}
    />
  );

  const renderPsizeValidationError = psize.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={t('confirmation.size_req')}
    />
  );

  const renderPbudgetValidationError = pbudget.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left ta-lblErrorFixNumber fixLblErrorSplitCol"
      txtLbl={t('confirmation.budget_req')}
    />
  );

  const renderCountryValidationError = country.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={
        country.typeMismatch
          ? country.formatErrorTxt
          : t('confirmation.country_req')
      }
    />
  );

  const ProjectIcon = (props) => <Icon component={ObjectLogoSvg} {...props} />;
  const uploadButton = (
    <div>
      {stateUpload.loading ? (
        <LoadingOutlined />
      ) : (
        <ProjectIcon className="FrmCompany" />
      )}
    </div>
  );

  /** this function is triggered when upload image */
  const onChangeUpload = (info) => {
    if (info.file.status === 'uploading') {
      setStateUpload({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response
      getBase64(info.file.originFileObj, (imageUrl) =>
        setStateUpload({
          imageUrl,
          name: info.file.response.name,
          loading: false
        })
      );
    }
  };

  // logic
  // Submit create Project
  const onSubmitCreateProject = async (e) => {
    const gantt = window.ganttScheduleOfSchedule;
    if (gantt) {
      gantt.modalData = {
        action: 'edit',
        value: projectPname,
        projectId: currentProject.id
      };
    }

    e.preventDefault();
    const isEdit = Boolean(projectState.props.currentProjectId);
    const form = e.target;
    const formValues = reduceFormValues(form.elements, state);

    // add selects to validation
    formValues.ptype = generateFormObjectSelect('ptype', 'Type', projectType);
    formValues.country = generateFormObjectSelect(
      'country',
      'Country',
      projectPcountry
    );
    formValues.currency = generateFormObjectSelect(
      'currency',
      'Currency',
      projectPcurrency
    );
    formValues.psizetype = generateFormObjectSelect(
      'psizetype',
      'Type Size',
      projectSizeType
    );

    const allFieldsValid = checkAllFieldsValid(formValues);
    setState({ ...formValues, allFieldsValid });
    if (!sectorsProject.length) {
      setNoSector(true);
      return;
    }
    setNoSector(false);

    if (allFieldsValid && user.id) {
      setIsLoading(true);

      const isEnglish = navigatorLang.includes('en');
      const budget = removeCurrencySeparators(
        isEnglish,
        formValues.pbudget.value
      );
      const size = removeCurrencySeparators(isEnglish, formValues.psize.value);

      const companyId = sessionTokenData.companyId;
      let data = {
        name: formValues.pname.value,
        country: formValues.country.value,
        companyId: companyId,
        type: formValues.ptype.value,
        currency: formValues.currency.value,
        budget: budget,
        sizetype: formValues.psizetype.value,
        size: size,
        stage: 'started',
        planification_day: 1 /** monday by default */,
        image: stateUpload.name
          ? stateUpload.name
          : null /** save image uploaded */
      };
      const alert = {
        title: t('form.project'),
        description: isEdit ? t('form.project_edit') : t('form.project_add'),
        type: 'success'
      };
      if (!isEdit) {
        // new project
        const projectNew = await createProject(data);
        if (projectNew.id) {
          /** add constraint types */
          const asyncMapDefaultConstraintTypes = defaultConstraintTypes(
            props.t
          ).map(async (constraintType) => {
            await constraintTypeService.create({
              ...constraintType,
              projectId: projectNew.id,
              description: '' /** is required */
            });
          });
          await Promise.all(asyncMapDefaultConstraintTypes);
          /** add cnc types */
          const asyncMapDefaultCncTypes = defaultCncTypes(props.t).map(
            async (cncType) => {
              await cncTypeService.create({
                ...cncType,
                projectId: projectNew.id,
                description: '' /** is required */
              });
            }
          );
          await Promise.all(asyncMapDefaultCncTypes);
          // add default subcontract
          const response =
            await subContractService.getByCompany(idCurrentCompany);
          let allSubcontracts;
          if (response) {
            allSubcontracts = response.subcontracts;
          }
          defaultSubContracts.map(async (sc) => {
            const existName = allSubcontracts.filter(
              (el) =>
                el.type === 'General Contractor' &&
                el.name.toLowerCase().trim() ===
                  stateCompany.currentCompany.name.toLowerCase().trim()
            );
            if (!existName.length) {
              const subcontract = await subContractService.create({
                ...sc,
                companyId: idCurrentCompany,
                name: stateCompany.currentCompany.name,
                image: stateCompany.currentCompany.image
              });
              /** assign */
              if (subcontract.id) {
                await asignProjectSubcontract({
                  subcontractId: subcontract.id,
                  projectId: projectNew.id
                });
              }
            } else {
              await asignProjectSubcontract({
                subcontractId: existName[0].id,
                projectId: projectNew.id
              });
            }
          });
          // assign to user
          await asignProjectUser({
            userId: user.id,
            projectId: projectNew.id,
            isCreator: true
          });
          const asyncMap = sectorsProject.map(async (sn, index) => {
            const findEl = sectorsOriginal.find((e) => sn.id === e.id);
            if (!findEl) {
              data = {
                name: sn.name,
                projectId: projectNew.id,
                set_current: false,
                visible: false,
                order: index
              };
              await createSector(data);
            }
          });
          await Promise.all(asyncMap);
          /** users */
          /** get users of company */

          const usersCompany = await userService.getByCompany(idCurrentCompany);
          // assign users to project
          if (usersCompany.users) {
            usersCompany.users.map(async (us) => {
              if (us.role === SUPERADMIN.value && user.id !== us.id) {
                await asignProjectUser({
                  userId: us.id,
                  projectId: projectNew.id,
                  isCreator: false
                });
              }
            });
          }
          /** notify socket */
          /*   socket.emit('project_add', {
                        companyId: user.companyId,
                        project: projectNew
                    }); */
        }
      } else {
        // edit project
        data.stage = currentProject.stage;
        data.id = currentProject.id;
        await updateProject(data);

        sectorsOriginal.map(async (so, index) => {
          const findEl = sectorsProject.find((e) => so.id === e.id);
          if (!findEl) {
            // delete sector
            trackingEvent(
              'schedule_deletion',
              {
                ...getBasicAmplitudEventProperties(),
                stage_id: so.id,
                stage_name: so.name,
                event_source: 'Project Form'
              },
              AMPLITUDE_SERVICE
            );
            if (so.id) {
              await updateSector({
                id: so.id,
                name: so.name,
                projectId: so.projectId,
                status: false
              });
            }
          } else {
            const elemOrder = sectorsProject.findIndex((e) => e.id === so.id);
            if (so.id !== undefined) {
              // update order
              await updateSector({
                id: so.id,
                name: so.name,
                projectId: so.projectId,
                order: elemOrder,
                status: true
              });
            }
          }
        });
        sectorsProject.map(async (sn, index) => {
          const findEl = sectorsOriginal.find((e) => sn.id === e.id);
          if (findEl !== undefined && !findEl.id) {
            data = {
              name: sn.name,
              projectId: projectId,
              order: index
            };
            await createSector(data);
          }
        });
      }

      let status; // waiting aws
      while (data.image && status !== 200) {
        const response = await fetch(data.image);
        status = response.status;
      }
      const company = await getCompany(companyId);
      if (company) {
        dispatch(companyActions.setCurrentCompany(company));
      }
      const getProjects = await userService.projectsbyuserthrough(user.id);
      const projectsActive = getProjects.projects.filter(
        (e) => e.stage !== 'deleted'
      );
      updateProjectsList(projectsActive, filter);
      setFormProjectVisible(false);
      setIsLoading(false);
      // refresh list projects
      setCurrentProject({});
      setStateUpload({ loading: false });
      setState(stateTemplate);
      await setTimeout(() => {
        resetModalAddProject();
        dispatch(
          projectActions.setProps({
            ...projectState.props,
            currentProjectId: null,
            openFormProject: false
          })
        );
        openNotification(alert);
      }, 300);
    }
  };

  /** render */
  return (
    <Modal
      title=""
      visible={formProjectVisible}
      centered
      onCancel={() => {
        resetModalAddProject();
        dispatch(
          projectActions.setProps({
            ...projectState.props,
            currentProjectId: null,
            openFormProject: false
          })
        );
        setFormProjectVisible(false);
      }}
      width={1200}
      footer={[]}>
      <div className="titleWideProject">
        {currentProject.id
          ? t('project_create.title_edict')
          : t('project_create.title_create')}
      </div>
      <div className="divSection formMiddle">
        <Fragment>
          <form
            className="frmWide"
            onSubmit={onSubmitCreateProject}
            noValidate
            id="frmAddProject">
            <Row>
              <Col span={24}>
                <ImgCrop rotate shape="rect" aspect={5 / 3} modalWidth={850}>
                  <Upload
                    action={`${base.api}projects/upload`}
                    listType="picture-card"
                    showUploadList={false}
                    headers={headersSendForm}
                    onChange={onChangeUpload}>
                    {stateUpload.imageUrl ? (
                      <img
                        className="imgLogoProject"
                        src={stateUpload.imageUrl}
                        alt="project"
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </ImgCrop>
              </Col>
            </Row>

            <Row className="mt-10">
              <Col span={11}>
                <Input
                  className="w100"
                  type="text"
                  name="pname"
                  placeholder={t('project_create.project_name')}
                  value={projectPname}
                  onChange={(e) => setProjectPname(e.target.value)}
                  required
                />
                {renderPnameValidationError}
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Select
                  type="select"
                  name="ptype"
                  placeholder={t('project_create.type')}
                  value={projectType}
                  onChange={(e) => setProjectType(e)}>
                  {Types.map(({ value, label, trad }) => (
                    <Option key={value} value={value}>
                      {t(`settings.project_form.${trad}`)}
                    </Option>
                  ))}
                </Select>
                {renderPtypeValidationError}
              </Col>
            </Row>

            <Row className="mt-15">
              <Col span={11}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  name="country"
                  placeholder={t('project_create.country')}
                  value={projectPcountry}
                  onChange={(e) => setProjectPcountry(e)}>
                  {codeCountries.map(({ code, name }, index) => (
                    <Option key={code} value={code}>
                      {name}
                    </Option>
                  ))}
                </Select>
                {renderCountryValidationError}
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Select
                  className="w30 fixCur fixSelectCur"
                  placeholder="USS"
                  name="currency"
                  value={projectPcurrency}
                  onChange={(e) => setProjectPcurrency(e)}
                  dropdownClassName="currencySelect">
                  {projectCurrency(t).map(({ value, label }, index) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>

                <NumberFormat
                  id="numberformatBudget"
                  name="pbudget"
                  className="fixOnlyNumber"
                  placeholder={t('project_create.pbudget')}
                  displayType={'input'}
                  thousandSeparator={navigatorLang.includes('en') ? ',' : '.'}
                  prefix={''}
                  decimalSeparator={navigatorLang.includes('en') ? '.' : ','}
                  value={projectBudget}
                  allowNegative={false}
                  onChange={(e) => setProjectBudget(e.target.value)}
                  required
                />
              </Col>
            </Row>

            <Row className="mt-15">
              <Col span={11}>
                <Select
                  type="select"
                  className=""
                  name="psizetype"
                  placeholder={t('project_create.psizetype')}
                  value={projectSizeType || 'ft2'}
                  onChange={(e) => setProjectSizeType(e)}>
                  {projectSizesTypes.map(({ value, label }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <NumberFormat
                  id="numberformatProjectSize"
                  name="psize"
                  className="fixOnlyNumber"
                  placeholder={t('project_create.psize')}
                  displayType={'input'}
                  thousandSeparator={navigatorLang.includes('en') ? ',' : '.'}
                  prefix={''}
                  decimalSeparator={navigatorLang.includes('en') ? '.' : ','}
                  decimalScale={2}
                  value={projectSize}
                  allowNegative={false}
                  onChange={(e) => setProjectSize(e.target.value)}
                  required
                />
              </Col>
            </Row>
            <br />
            <br />

            {/* Stages Form */}
            <h2 style={{ textAlign: 'center' }}>
              {t('project_create.stages')}
            </h2>
            <Row className="">
              <Col span={24}>
                <div>
                  <Input
                    className="w50 inline"
                    type="text"
                    name="sname"
                    autoComplete="off"
                    placeholder={t('project_create.stages_name')}
                    value={sectorName}
                    onChange={(e) => setSectorName(e.target.value)}
                  />
                  <PlusOutlined
                    onClick={addSector}
                    className={noSector ? 'addElement2' : 'addElement'}
                  />
                  {sectorName !== null && sectorName === '' ? (
                    <label className="lblErrorElement">
                      {t('project_create.requerido')}
                    </label>
                  ) : (
                    ''
                  )}
                </div>
              </Col>
            </Row>

            <SortableList items={sectorsProject} onSortEnd={onSortEnd} />
            {noSector && <label className="lblerror">{t('onestage')}</label>}
            <Button htmlType="submit" loading={isLoading}>
              {projectState.props.currentProjectId
                ? t('project_create.save_project')
                : t('project_create.add_name')}
            </Button>
          </form>
        </Fragment>
      </div>
    </Modal>
  );
}

export default withTranslation()(FormProjects);
