function updateGanttOnMoveOrResize(task, mode) {
  const gantt = window.to_use_react_gantt;
  const state = gantt.getState();
  const minDate = state.min_date;
  const maxDate = state.max_date;
  let showDate;
  let repaint = false;
  if (mode == 'resize' || mode == 'move') {
    const scaleStep =
      gantt.date.add(new Date(), state.scale_step, state.scale_unit) -
      new Date();

    if (Math.abs(task.start_date - minDate) < scaleStep) {
      showDate = task.start_date;
      repaint = true;
    } else if (Math.abs(task.end_date - maxDate) < scaleStep) {
      showDate = task.end_date;
      repaint = true;
    }

    if (repaint) {
      gantt.optimizedRender();
      gantt.showDate(showDate);
    }
  }
}

export { updateGanttOnMoveOrResize };
