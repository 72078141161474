import { useEffect } from 'react';
import { HOME_CARD_VIEW } from '../../../hooks/useSelectProject';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { TYPE_VIEW_CARD } from '../../../components/Projects/Filters/FiltersHeader/constants';
import { filterByName } from '../../../utils/projectUtils';

export const useSendAnalyticsOnFilterByName = (
  analyticsService,
  setFilterResultsCount,
  projectsGrouped,
  keyGrouped,
  filterText,
  typeView
) => {
  useEffect(() => {
    const projectsLoaded = projectsGrouped && keyGrouped.length > 0;
    const shouldSendAnalytics = projectsLoaded && typeView === TYPE_VIEW_CARD;

    if (!shouldSendAnalytics) {
      return;
    }

    const filterFn = filterByName(filterText);
    const results = keyGrouped.reduce(
      (res, { value }) => res + projectsGrouped[value].filter(filterFn).length,
      0
    );

    setFilterResultsCount(results);

    if (results > 0) {
      return;
    }

    analyticsService(
      'search_box_no_results',
      {
        search_text: filterText,
        event_source: HOME_CARD_VIEW
      },
      AMPLITUDE_SERVICE
    );
  }, [filterText, keyGrouped, projectsGrouped, typeView]);
};
