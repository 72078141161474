import React from 'react';
import { Image } from '@react-pdf/renderer';
import child_arrow from '../../../assets/img/child-arrow.png';

export const formatText = (text, maxLength) => {
  if (!maxLength) {
    return text || '';
  }
  if (text.length > maxLength) {
    return `${text.substr(0, maxLength - 3)} ...`;
  }

  return text || '';
};

export const arrowParentTask = (parent) => (
  <Image
    style={{
      width: '6px',
      height: '6px',
      marginLeft: isNaN(parent) ? '0px' : parent + 'px',
      display: parent == 0 || isNaN(parent) ? 'none' : ''
    }}
    source={child_arrow}
  />
);
