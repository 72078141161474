import { createHttpClient } from '../utils/httpUtils';

class AssignmentWorkerResources {
  constructor() {
    this.http = createHttpClient();
    this.url = 'assignmentworkerresources';
  }

  async show() {
    const res = await this.http.get(this.url).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async destroy(resourceAssignmentId) {
    const res = await this.http
      .delete(`${this.url}/${resourceAssignmentId}}`)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async bulkDelete(data) {
    const res = await this.http
      .delete(`${this.url}/bulk`, { data })
      .catch((error) => false);
    return res ? res.data : res;
  }

  async create(data) {
    const assignData = { data: data };
    const res = await this.http.post(this.url, assignData).catch((error) => {
      console.log(error.response);
      return error.response;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put(`${this.url}/${data.id}}`, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updateFactor({ sectorresourceId, assistworkerId, data }) {
    const res = await this.http
      .put(`${this.url}/${sectorresourceId}/${assistworkerId}/factor`, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getDatesAssigned() {
    const res = await this.http.get(`${this.url}/dates`).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }
}
export const Assignmentworkerresources = new AssignmentWorkerResources();
