import React, { useRef, useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './Rfv.module.css';
import { categoriesCncType, renderIconCat } from '../../../../../utils';
import { Tooltip } from 'antd';

const Rfv = (props) => {
  const {
    tasks,
    handleAddCnc,
    tasksAddedCnc,
    cncListRq,
    isChangeProgress,
    weekcommitment,
    t,
    subtradeRole
  } = props;
  return (
    <>
      {tasks.map((task) => (
        <>
          <RecursiveTask
            subtradeRole={subtradeRole}
            task={task}
            key={task.id}
            handleAddCnc={handleAddCnc}
            tasksAddedCnc={tasksAddedCnc}
            cncListRq={cncListRq}
            isChangeProgress={isChangeProgress}
            weekcommitment={weekcommitment}
            t={t}
          />
          {task.children.length > 0 &&
            task.children.map((taskChild) => (
              <>
                <RecursiveTask
                  subtradeRole={subtradeRole}
                  task={taskChild}
                  key={taskChild.id}
                  cncListRq={cncListRq}
                  handleAddCnc={handleAddCnc}
                  tasksAddedCnc={tasksAddedCnc}
                  isChangeProgress={isChangeProgress}
                  weekcommitment={weekcommitment}
                  t={t}
                />
                {taskChild.children.length > 0 &&
                  taskChild.children.map((taskChild1) => (
                    <>
                      <RecursiveTask
                        subtradeRole={subtradeRole}
                        task={taskChild1}
                        key={taskChild1.id}
                        cncListRq={cncListRq}
                        handleAddCnc={handleAddCnc}
                        tasksAddedCnc={tasksAddedCnc}
                        isChangeProgress={isChangeProgress}
                        weekcommitment={weekcommitment}
                        t={t}
                      />
                      {taskChild1.children.length > 0 &&
                        taskChild1.children.map((taskChild2) => (
                          <>
                            <RecursiveTask
                              subtradeRole={subtradeRole}
                              task={taskChild2}
                              key={taskChild2.id}
                              cncListRq={cncListRq}
                              handleAddCnc={handleAddCnc}
                              tasksAddedCnc={tasksAddedCnc}
                              isChangeProgress={isChangeProgress}
                              weekcommitment={weekcommitment}
                              t={t}
                            />
                            {taskChild2.children.length > 0 &&
                              taskChild2.children.map((taskChild3) => (
                                <>
                                  <RecursiveTask
                                    subtradeRole={subtradeRole}
                                    task={taskChild3}
                                    key={taskChild3.id}
                                    cncListRq={cncListRq}
                                    handleAddCnc={handleAddCnc}
                                    tasksAddedCnc={tasksAddedCnc}
                                    isChangeProgress={isChangeProgress}
                                    weekcommitment={weekcommitment}
                                    t={t}
                                  />
                                  {taskChild3.children.length > 0 &&
                                    taskChild3.children.map((taskChild4) => (
                                      <>
                                        <RecursiveTask
                                          subtradeRole={subtradeRole}
                                          task={taskChild4}
                                          key={taskChild4.id}
                                          cncListRq={cncListRq}
                                          handleAddCnc={handleAddCnc}
                                          tasksAddedCnc={tasksAddedCnc}
                                          isChangeProgress={isChangeProgress}
                                          weekcommitment={weekcommitment}
                                          t={t}
                                        />
                                        {taskChild4.children.length > 0 &&
                                          taskChild4.children.map(
                                            (taskChild5) => (
                                              <RecursiveTask
                                                subtradeRole={subtradeRole}
                                                task={taskChild5}
                                                key={taskChild5.id}
                                                cncListRq={cncListRq}
                                                handleAddCnc={handleAddCnc}
                                                tasksAddedCnc={tasksAddedCnc}
                                                isChangeProgress={
                                                  isChangeProgress
                                                }
                                                weekcommitment={weekcommitment}
                                                t={t}
                                              />
                                            )
                                          )}
                                      </>
                                    ))}
                                </>
                              ))}
                          </>
                        ))}
                    </>
                  ))}
              </>
            ))}
        </>
      ))}
    </>
  );
};

const RecursiveTask = (props) => {
  const {
    task,
    handleAddCnc,
    tasksAddedCnc,
    cncListRq,
    isChangeProgress,
    weekcommitment,
    t,
    subtradeRole
  } = props;
  const [findCnc, setFindCnc] = useState(false);
  const [findCncIcon, setFindCncIcon] = useState(false);
  const [conditionCompletedShow, setConditionCompletedShow] = useState(false);
  const [conditionRfvShow, setConditionRfvShow] = useState(false);
  const [showCNCBtn, setShowCNCBtn] = useState(true);

  useEffect(() => {
    if (subtradeRole && !subtradeRole.isSubtradeRole) {
      setShowCNCBtn(true);
    } else if (
      subtradeRole &&
      subtradeRole.isSubtradeRole &&
      subtradeRole.subtrade
    ) {
      const shouldShowBtn = subtradeRole.subtrade.id === task.subcontractId;
      setShowCNCBtn(shouldShowBtn);
    } else if (
      subtradeRole &&
      subtradeRole.isSubtradeRole &&
      !subtradeRole.subtrade
    ) {
      setShowCNCBtn(false);
    }
  }, [subtradeRole]);

  /** check if task was added to cnc */
  const { current: findTaskAddCnc } = useRef(
    !!tasksAddedCnc.find(
      (el) =>
        el.taskId === task.id &&
        parseInt(el.week) === parseInt(weekcommitment.week)
    )
  );

  const findTask = (_) => {
    if (cncListRq && cncListRq.data && cncListRq.data.cncs) {
      cncListRq.data.cncs.forEach((el) => {
        const findTask = el.cnctasks.find((t) => t.taskId === task.id);
        if (findTask && parseInt(el.week) === parseInt(weekcommitment.week)) {
          setFindCncIcon(
            categoriesCncType.find((cnc) => cnc.value == el.typeArea)
          );
          setFindCnc(el);
        }
      });
    }
  };

  const calculateConditions = (_) => {
    if (!task.taskcommitments.length) return;

    const taskCommitment = task.taskcommitments[0];

    const progressToCompare = task.taskcommitments[0].realized_percentaje; /// task.progress
    setConditionCompletedShow(
      weekcommitment.closed
        ? progressToCompare >= taskCommitment.commitment_percentaje
        : task.progress >= taskCommitment.commitment_percentaje
    );
    setConditionRfvShow(
      weekcommitment.closed &&
        !findTaskAddCnc &&
        taskCommitment.is_last_level &&
        progressToCompare < taskCommitment.commitment_percentaje
    );
  };

  useEffect(() => {
    calculateConditions();
    findTask();
  }, [isChangeProgress]);

  if (task.taskcommitments.length <= 0) return null;

  const openAddCnc = (task) => handleAddCnc(task);

  return (
    <div
      className={cn(styles.row, {
        [styles['parent-task-color']]: task.children.length
      })}
      key={task.id}>
      <span>
        {conditionCompletedShow ? (
          <i
            className="far fa-check-circle"
            style={{
              fontSize: 11,
              color: 'green',
              position: 'relative',
              top: -2
            }}
          />
        ) : (
          <i
            className="fas fa-minus-circle"
            style={{
              fontSize: 11,
              color: 'red',
              position: 'relative',
              top: -2
            }}
          />
        )}
      </span>
      <span
        className={styles.cnc}
        style={{ color: findCnc ? '#121212' : '#747474' }}>
        {findCnc ? (
          <>
            {findCncIcon.icon && (
              <Tooltip
                title={t(`settings.project_categories.${findCncIcon.trad}`)}>
                <img
                  src={renderIconCat(findCncIcon.icon)}
                  className={styles.cnc__icon}
                />
              </Tooltip>
            )}
            {findCnc.name}
          </>
        ) : (
          conditionRfvShow &&
          showCNCBtn && (
            <span
              onClick={() => openAddCnc(task)}
              style={{ cursor: 'pointer' }}>
              {t('weekcommitment.add_rfv')}
            </span>
          )
        )}
      </span>
    </div>
  );
};

export default Rfv;
