import React from 'react';

export const usePpcIndicatorProject = (project_s, getTodayWeekWithPday) => {
  let total = 0;
  let realized = 0;
  let ppc_exist = false;
  let retLabel = '';
  if (project_s) {
    const weekcommitments = project_s.weekcommitments.filter(
      (weekcomm) => weekcomm.closed === true
    );
    let todayWeek = getTodayWeekWithPday(null, project_s.projects);
    todayWeek = todayWeek - 1;
    weekcommitments.map((weekcommitment) => {
      if (weekcommitment.week == todayWeek) {
        ppc_exist = true;
        total = total + weekcommitment.commitment_tasks;
        realized = realized + weekcommitment.realized_tasks;
      }
    });
    const commitment_percentaje = (realized / total) * 100;
    if (total > 0) {
      retLabel = commitment_percentaje.toFixed(2) + '%';
    } else {
      if (ppc_exist) {
        retLabel = '0%';
      } else {
        retLabel = 'No Data';
      }
    }
  }
  const ret = {
    realized,
    total,
    ppcLabel: retLabel
  };
  return ret;
};

export const getPPC = (project_s, getTodayWeekWithPday) => {
  let total = 0;
  let realized = 0;
  let ppc_exist = false;
  let retLabel = '';
  if (project_s) {
    const weekcommitments = project_s.weekcommitments.filter(
      (weekcomm) => weekcomm.closed === true
    );
    let todayWeek = getTodayWeekWithPday(null, project_s.projects);
    todayWeek = todayWeek - 1;
    weekcommitments.map((weekcommitment) => {
      if (weekcommitment.week == todayWeek) {
        ppc_exist = true;
        total = total + weekcommitment.commitment_tasks;
        realized = realized + weekcommitment.realized_tasks;
      }
    });
    const commitment_percentaje = (realized / total) * 100;
    if (total > 0) {
      retLabel = commitment_percentaje.toFixed(2) + '%';
    } else {
      if (ppc_exist) {
        retLabel = '0%';
      } else {
        retLabel = '-';
      }
    }
  }
  const ret = {
    realized,
    total,
    ppcLabel: retLabel
  };
  return ret;
};
