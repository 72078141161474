/** React components  */
import React, { useState, useEffect } from 'react';

/** Redux */
import { useSelector, useDispatch } from 'react-redux';
import { timelineActions } from '../../redux/actions/lookaheadTimelineActions';
import { withTranslation } from 'react-i18next';
/** Import elements from library Antd */
import { Row, Col, Select, Radio, Menu, Dropdown } from 'antd';
import arrowSelectImg from '../../assets/img/groupping-arrow.png';
import './index.css';
const { Option } = Select;

/**
 * This component allows users to filter Projects through a dinamic filter opts
 * @param {*} props Props must receive a { options, currentFilter, currentOrder, changeFilter, changeOrder }
 */
function LookaheadGroup(props) {
  const { t, updateDefaultViewFilters, visualizationOp } = props;
  /** redux */
  const lookaheadState = useSelector((state) => state[props.lookaheadState]);
  const dispatch = useDispatch();

  useEffect(() => {
    reGroupData();
  }, [lookaheadState.notifyChangeGroup]);

  const [orderToGroup, setOrderToGroup] = useState({
    value: props.groupBy.sort
  });
  const { defaultOrderOptions } = props;
  const [parameterToGroup, setParameterToGroup] = useState({
    value: props.groupBy.criteria,
    label: defaultOrderOptions.find((el) => el.name == props.groupBy.criteria)
      ? defaultOrderOptions.find((el) => el.name == props.groupBy.criteria)
          .label
      : ''
  });

  useEffect(() => {
    if (parameterToGroup.label !== '') return;

    const groupLabel = props.options.filter(
      (op) => op.groupable && op.name === props.groupBy.criteria
    );
    setParameterToGroup({
      value: props.groupBy.criteria,
      label: groupLabel[0].label
    });
  }, []);

  const reGroupData = () => {
    dispatch(timelineActions.updateParsedValue(false));
    const group = {
      criteria: parameterToGroup.value,
      sort: orderToGroup.value,
      filter: true
    };
    if (updateDefaultViewFilters) {
      updateDefaultViewFilters('group_query', JSON.stringify(group));
    }
    props.modifyGroupBy(group, props.data);
  };

  const updateRender = () => {
    setParameterToGroup({
      ...parameterToGroup
    });

    setOrderToGroup({
      ...orderToGroup
    });
  };

  /** logic component */
  const setChangeFilter = async (e) => {
    parameterToGroup.value = e.name;
    parameterToGroup.label = e.label;
    updateRender();
    reGroupData();
  };

  const setChangeOrder = async (e) => {
    orderToGroup.value = e.target.value;
    updateRender();
    reGroupData();
  };

  const setSelectedGroup = (op) => {
    setChangeFilter(op);
  };

  const renderOptionsToOrder = () => (
    <Menu>
      {defaultOrderOptions.map((opt, index) => (
        <Menu.Item key={index}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              setSelectedGroup({ name: opt.name, label: opt.label })
            }>
            {opt.label}
          </a>
        </Menu.Item>
      ))}
      {props.options.map((op, index) => {
        /** HARDCODED: To avoid freezing browser, when using takt visualization will be disabled the group by subs */
        if (
          op.name == 'subcontractId' &&
          visualizationOp &&
          visualizationOp.name == 'takt'
        ) {
          return;
        }
        if (
          op.name != 'activityId' &&
          visualizationOp &&
          visualizationOp.name == 'gantt'
        ) {
          return;
        }
        if (op.groupable) {
          return (
            <Menu.Item key={index}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setSelectedGroup(op)}>
                {op.label}
              </a>
            </Menu.Item>
          );
        }
      })}
    </Menu>
  );

  const renderOrderToGroup = () => {
    let insideSwitchContent;
    if (parameterToGroup.value) {
      const filter = props.options.find(
        (op) => op.name == parameterToGroup.value
      );
      if (!filter) {
        /** This use case is for default groupping options */
        const defaultOpt = defaultOrderOptions.find(
          (el) => el.name == parameterToGroup.value
        );
        if (defaultOpt.switch) {
          insideSwitchContent = defaultOpt.switch;
        } else {
          return null;
        }
      } else if (filter) {
        insideSwitchContent = filter.groupable_switch;
      }

      return (
        <Radio.Group
          defaultValue={orderToGroup.value}
          buttonStyle="solid"
          size="small"
          onChange={(e) => setChangeOrder(e)}
          className="button-switch-groupping">
          <Radio.Button value="asc" className="btnSwitch width-half">
            {insideSwitchContent[0] == 'Primero → Último'
              ? '1 → N'
              : insideSwitchContent[0]}
          </Radio.Button>
          <Radio.Button value="desc" className="btnSwitch width-half">
            {insideSwitchContent[1] == 'Último → Primero'
              ? 'N → 1'
              : insideSwitchContent[1]}
          </Radio.Button>
        </Radio.Group>
      );
    }
  };
  /** render */
  return (
    <div className="filter-main-container" style={{ width: 450 }}>
      <Row className="full-width-style">
        <Col className="filterProject">
          <Row className="single-filter-row">
            <Col span={7}>
              <Dropdown overlay={renderOptionsToOrder} trigger={['click']}>
                <div>
                  <a
                    onClick={(e) => e.preventDefault()}
                    className="dropdown-groupping">
                    {parameterToGroup.label}
                  </a>
                  <img src={arrowSelectImg} className="arrow-img-groupping" />
                </div>
              </Dropdown>
            </Col>
            <Col span={1} offset={1} className="labelOrder">
              <span>{t('from')}</span>
            </Col>
            <Col span={14} offset={1} className="order-lookahead">
              {renderOrderToGroup()}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
export default withTranslation()(LookaheadGroup);
