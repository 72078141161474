import { addTimeToConstraintDate } from './addTimeToConstraintDate';
import { checkLastEditedColumn } from './checkLastEditedColumn';

function updateConstraintDate(activity, calendarObject) {
  if (!activity.constraint_type_old) {
    return;
  }

  if (
    !checkLastEditedColumn([
      'duration',
      'end_date',
      'start_date',
      'calendar_id',
      'custom_predecessors',
      'custom_successors',
      'constraint_date',
      'constraint_type'
    ])
  ) {
    return;
  }

  const {
    constraint_type_old,
    constraint_type,
    constraint_date_old,
    constraint_date,
    end_date,
    start_date
  } = activity;

  /** ctid#03 ctid#04 */
  if (activity.type === 'project' && activity.constraint_type === 'snet') {
    activity.constraint_type = 'asap';
    return;
  }

  function checkStartNo() {
    /** ctid#21 ctid#22 ctid#23 */
    if (['mfo', 'fnlt', 'fnet'].includes(constraint_type)) {
      if (constraint_type === 'fnet') {
        activity.constraint_date = constraint_date_old;
        addTimeToConstraintDate({
          activity,
          start: false,
          field: 'constraint_date',
          calendarObject
        });
        return;
      }

      if (constraint_date_old) {
        activity.end_date = constraint_date;
      }

      addTimeToConstraintDate({ activity, calendarObject });
      return;
    }

    /** ctid#25 ctid#26 */
    if (['fnlt', 'mfo'].includes(constraint_type)) {
      if (constraint_date_old) {
        activity.constraint_date = constraint_date_old;
      }

      addTimeToConstraintDate({
        activity,
        start: false,
        field: 'constraint_date',
        calendarObject
      });
      activity.end_date = activity.constraint_date;
    }
  }

  function checkFinishNotThan() {
    /** ctid#29 ctid#30 ctid#31 */
    if (['mso', 'snlt', 'snet'].includes(constraint_type)) {
      if (constraint_date_old) {
        activity.constraint_date = constraint_date_old;
      }

      addTimeToConstraintDate({ activity, start: true, calendarObject });
      return;
    }

    /** ctid#32 ctid#33 */
    if (['mso', 'snlt'].includes(constraint_type)) {
      addTimeToConstraintDate({ activity, start: true, calendarObject });
      return;
    }

    /** ctid#34 */
    if (constraint_type === 'snet') {
      if (constraint_date_old) {
        activity.constraint_date = constraint_date_old;
      }

      addTimeToConstraintDate({ activity, start: true, calendarObject });
      activity.start_date = constraint_date_old;
    }
  }

  function checkAsSoonAs() {
    /** ctid#06 ctid#07 */
    if (['mfo', 'fnlt'].includes(activity.constraint_type)) {
      activity.constraint_date = end_date;
      return;
    }

    /** ctid#37 */
    if (activity.constraint_type === 'fnet') {
      activity.constraint_date = end_date;
      addTimeToConstraintDate({ activity, calendarObject });
      return;
    }

    /** ctid#05 ctid#09 */
    if (['mso', 'snlt'].includes(activity.constraint_type)) {
      activity.constraint_date = start_date;
    }
  }

  function checkMustStartOn() {
    /** ctid#17 */
    if (constraint_type === 'fnlt') {
      addTimeToConstraintDate({
        activity,
        start: false,
        field: 'constraint_date',
        calendarObject
      });
      activity.end_date = constraint_date;
      return;
    }

    /** ctid#19 */
    if (constraint_type === 'mfo') {
      addTimeToConstraintDate({ activity, calendarObject });
      activity.end_date = constraint_date;
    }
  }

  if (constraint_type_old === 'snlt' && constraint_type === 'snet') {
    checkStartNo();
    return;
  }

  if (constraint_type_old === 'fnlt' && constraint_type === 'fnet') {
    checkFinishNotThan();
    return;
  }

  if (constraint_type_old === 'asap') {
    checkAsSoonAs();
    return;
  }

  if (constraint_type_old === 'mso') {
    checkMustStartOn();
  }
}

export { updateConstraintDate };
