function checkBugResize(activity) {
  /**
   * Flag to fix: Sometimes on drag resize the autoschedule Gantt Component
   * were deleting on count -1 to duration
   */
  if (activity.bug_duration_from_resize_drag) {
    activity.bug_duration_from_resize_drag = false;
    if (activity.inline_duration_value) {
      activity.duration = activity.inline_duration_value;
      delete activity.inline_duration_value;
    }
    activity.duration_milestone_bugged = activity.duration;
    activity.lightbox_duration = activity.duration_milestone_bugged;
  }
}

export { checkBugResize };
