const finalObj = {};

export const checkboxMenu = [
  {
    title: 'Resource and Cubication',
    checkboxsArray: [
      {
        name: 'overwrite_resources',
        val: false,
        title: 'Overwrite Resources'
      }
    ]
  },
  {
    title: 'Costs',
    checkboxsArray: [
      {
        name: 'overwrite_base_cost',
        val: false,
        title: 'Overwrite Base Cost'
      }
    ]
  },
  {
    title: 'Activities',
    checkboxsArray: [
      {
        name: 'overwrite_baseline',
        val: false,
        title: 'Overwrite baseline'
      },
      {
        name: 'overwrite_progress',
        val: false,
        title: 'Overwrite Progresses'
      },
      {
        name: 'overwrite_work_calendar',
        val: false,
        title: 'Overwrite Work Calendar'
      },
      {
        name: 'overwrite_follow_dates',
        val: false,
        title: 'Overwrite Follow-Up Dates'
      },
      {
        name: 'overwrite_links',
        val: false,
        title: 'Overwrite Activities Relation (gantt links)'
      }
    ]
  },
  {
    title: 'Others',
    checkboxsArray: [
      {
        name: 'use_structures_as_categories',
        val: false,
        title: 'Use project structure as categories'
      }
    ]
  }
];

checkboxMenu.map((check) =>
  check.checkboxsArray.map((singleCheck) => {
    finalObj[singleCheck.name] = singleCheck.val;
  })
);

export const checkBoxInitialState = finalObj;
