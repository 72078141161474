import { ScheduleOfSchedulesBuilder } from './ScheduleOfSchedulesBuilder';

const scheduleBuilders = {
  scheduleOfSchedules: ScheduleOfSchedulesBuilder
};

export const ScheduleFactory = {
  create(type, options) {
    const builder = scheduleBuilders[type];
    builder.create(options);
    builder.addColumns(options);
    builder.addPlugins(options);
    builder.addCustomFunctions(options);
    builder.configure(options);
    builder.addTemplates(options);
    builder.configureEvents(options);
    return builder.get();
  }
};
