import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { socket } from '../../../services/socket.service';
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../../redux/slices/projectSlice';
import FormProjects from '../../../components/Projects/FormProjects';
import FormDelete from '../../../components/Projects/FormDelete';
import { projectService, userService } from '../../../services';
import { Animated } from 'react-animated-css';
import { groupBy, dynamicSort, setAllProjectsGlobal } from '../../../utils';
import { filterProjects } from '../project.helper';
import '../project.css';
import { useTranslation, withTranslation } from 'react-i18next';
import FormProjectsDummy from '../../../components/Projects/FormProjectsDummy/FormProjectsDummy';
import { trackingEvent } from '../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import AddProject from '../../../components/Projects/AddProjectModal';
import ScheduleOfSchedules from '../../../components/ScheduleOfSchedules';
import Filters from '../../../components/Projects/Filters/FiltersHeader';
import { useSelectProject } from '../../../hooks/useSelectProject';
import { getSessionTokenData, getSignedUser } from '../../../utils/userUtils';
import {
  isFilteredProjectListEmpty,
  isProjectListEmpty
} from '../../../utils/projectUtils';
import EmptyFilteredProjects from '../EmptyFilteredProjects/EmptyFilteredProjects';
import EmptyHome from '../EmptyHome/EmptyHome';
import notificationSystemV2 from '../../../components/DesignSystem/NotificationSystemV2/NotificationSystemV2';
import {
  TYPE_VIEW_CARD,
  TYPE_VIEW_LOADING,
  TYPE_VIEW_TIMELINE
} from '../../../components/Projects/Filters/FiltersHeader/constants';
import { useShowNotificationOnUnauthorized } from '../hooks/useShowNotificationOnUnauthorized';
import { useSendAnalyticsOnFilterByName } from '../hooks/useSendAnalyticsOnFilterByName';
import ProjectCardsList from '../../../components/ProjectCardsList';

function ProjectsView(props) {
  const { onClickProject } = useSelectProject();

  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.projectState);
  const [typeView, setTypeView] = useState(TYPE_VIEW_LOADING);
  const { t, ready } = useTranslation();
  const [filter, setFilter] = useState('stage');
  const [projectsGrouped, setProjectsGrouped] = useState(null);
  const [groupedByKey, setGroupedByKey] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [formProjectVisible, setFormProjectVisible] = useState(false);
  const [formProjectVisibleDummy, setFormProjectVisibleDummy] = useState(false);
  const [formDeleteVisible, setFormDeleteVisible] = useState(false);
  const [processedProjects, setProcessedProjects] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [filterResultsCount, setFilterResultsCount] = useState(0);
  const [filterCompany, setFilterCompany] = useState();

  const sessionTokenData = getSessionTokenData();
  const [zoomLevel, setZoomLevel] = useState(4);
  const user = getSignedUser();

  useShowNotificationOnUnauthorized(notificationSystemV2);
  useSendAnalyticsOnFilterByName(
    trackingEvent,
    setFilterResultsCount,
    projectsGrouped,
    groupedByKey,
    filterText,
    typeView
  );

  useEffect(() => {
    window.Appcues.page();
  });

  useEffect(() => {
    if (projectState.props.openFormProject) {
      setFormProjectVisible(true);
    }
  }, [projectState.props.openFormProject]);

  useEffect(() => {
    if (projectState.props.isDummy) {
      setFormProjectVisibleDummy(true);
    }
  }, [projectState.props.isDummy]);

  useEffect(() => {
    const event = `project_add_${sessionTokenData?.companyId}`;
    socket.on(event, getProjectsLoad);
    return () => socket.off(event);
  }, []);

  useEffect(() => {
    if (user) {
      userService.getUserProjectsView(user.id).then((projectsView) => {
        setTypeView(projectsView);
      });
    }
  }, []);

  const getProjectsLoad = async () => {
    if (!user) return;
    const getProjects = await userService.projectsbyuserthrough(user.id);
    const { projects } = getProjects;
    if (!projects) return;
    const projectsActive = projects.filter((e) => e.stage !== 'deleted');
    updateProjectsList(projectsActive);
    setLoadingProjects(false);

    trackingEvent(
      'home_visualization',
      {
        project_count: projectsActive?.length || 0
      },
      AMPLITUDE_SERVICE
    );
  };

  const getCurrentSectorId = (sectors) => {
    for (const sector of sectors) {
      if (sector.set_current === true) {
        return sector.id;
      }
    }
    return null;
  };

  useEffect(() => {
    /** filter array of  projects */
    const searchProjectFilter = filterProjects(projectState);
    const getFilter = projectState.props?.filter || 'stage';
    setFilter(getFilter);

    /** group projects from state */
    const grouped = groupBy(
      searchProjectFilter || projectState.allProjects,
      'stage'
    );

    /** create array for sort by key (value is the group name) */
    const groupedSorted = Object.keys(grouped).map((el) => ({ value: el }));

    /** sort by key desc */
    let order = ''; /** normal asc */
    /** get order from state  */
    if (projectState.props.order === 'a-z') {
      order = '';
    } else {
      order = '-';
    }

    /** sort by value (order : 1, asc, -1 desc ) */
    groupedSorted.sort(dynamicSort(`${order}value`));

    /** update state */
    setGroupedByKey(groupedSorted);

    if (JSON.stringify(projectsGrouped) !== JSON.stringify(grouped)) {
      setProjectsGrouped(grouped);
    }
  }, [projectState]);

  useEffect(() => {
    const currentSectorId = getCurrentSectorId(projectState.allSectors);
    dispatch(projectActions.setSector(currentSectorId));
  }, [projectState.allSectors]);

  const getUserLight = async (userId) => {
    const projectListLight = await projectService.ProjectsByUser(userId);
    const listLength = projectListLight?.projects?.length;
    if (!listLength) {
      setLoadingProjects(false);
    } else {
      const localList = projectState?.allProjects;
      if (localList.length !== listLength) {
        /** regenerate cache with fetch data */
        getProjectsLoad();
      } else {
        const lastUpdatedList = projectState?.props?.timeStampAllProjects;
        const diffBetweenDates =
          Number(new Date() - new Date(lastUpdatedList)) / (1000 * 60);
        if (diffBetweenDates < 3) {
          /** diff in minutes > 3 minutes (get data of cache, state redux) */
          // check socket
          setLoadingProjects(false);
        } else {
          /** regenerate cache with fetch data */
          getProjectsLoad();
        }
      }
    }
  };

  useEffect(() => {
    user && getUserLight(user.id);
  }, []);

  useEffect(() => {
    const loadProjectsData = async () => {
      if (isProjectListEmpty(projectsGrouped) || typeView !== 'TIMELINE') {
        return;
      }

      const archived = projectsGrouped.archived || [];
      const started = projectsGrouped.started || [];
      const allProjects = [...started, ...archived];
      const projectIdsMap = {};
      allProjects.forEach((project) => {
        projectIdsMap[project.id] = project;
      });
      if (Object.keys(projectIdsMap).length === 0) return;
      setProcessedProjects(projectIdsMap);
    };
    loadProjectsData();
  }, [projectsGrouped, typeView]);

  /** Refresh projects list */
  const updateProjectsList = (arr) => {
    /** Redux event emitting function to set projects load from project actions */
    setAllProjectsGlobal(arr, dispatch, projectActions, projectState);
  };

  // redirects
  const signed = localStorage.getItem('signed');
  if (!signed) {
    return <Redirect to="/login" />;
  }

  const handleCloseModal = () => {
    dispatch(
      projectActions.setProps({
        ...projectState.props,
        openAddProject: false
      })
    );
  };

  if (typeView === TYPE_VIEW_LOADING) {
    return null;
  }

  const noLoadedProjectsExist =
    isProjectListEmpty(projectsGrouped) &&
    filterText === '' &&
    !loadingProjects;

  const noLoadedProjectMatchFilter =
    isFilteredProjectListEmpty(projectsGrouped, filterText) && !loadingProjects;

  const shouldRenderProjectsComponent =
    !noLoadedProjectsExist && !noLoadedProjectMatchFilter;

  return (
    <Animated
      animationIn="fadeIn"
      animationInDuration={500}
      isVisible={true}
      className="animationProjects">
      <div className="wrappView">
        <Filters
          typeView={typeView}
          setTypeView={setTypeView}
          filterText={filterText}
          setFilterText={setFilterText}
          zoomLevel={zoomLevel}
          filterCompany={filterCompany}
          setFilterCompany={setFilterCompany}
        />

        {noLoadedProjectsExist && <EmptyHome />}

        {noLoadedProjectMatchFilter && <EmptyFilteredProjects />}

        {shouldRenderProjectsComponent && typeView === TYPE_VIEW_TIMELINE && (
          <ScheduleOfSchedules
            processedProjects={processedProjects}
            setFormProjectVisible={setFormProjectVisible}
            formProjectVisible={formProjectVisible}
            setFormDeleteVisible={setFormDeleteVisible}
            formDeleteVisible={formDeleteVisible}
            filterText={filterText}
            setZoomLevel={setZoomLevel}
            zoomLevel={zoomLevel}
            filterCompany={filterCompany}
            showNotification={notificationSystemV2}
          />
        )}

        {shouldRenderProjectsComponent && typeView === TYPE_VIEW_CARD && (
          <ProjectCardsList
            isLoadingProjects={loadingProjects}
            projectsGroupedByStages={projectsGrouped}
            projectStages={groupedByKey}
            filterText={filterText}
            filter={filter}
            projectState={projectState}
            onClickProject={onClickProject}
            setFormProjectVisible={setFormProjectVisible}
            formProjectVisible={formProjectVisible}
            setFormDeleteVisible={setFormDeleteVisible}
            formDeleteVisible={formDeleteVisible}
            filterResultsCount={filterResultsCount}
          />
        )}
      </div>

      <FormProjects
        setFormProjectVisible={setFormProjectVisible}
        formProjectVisible={formProjectVisible}
      />
      <AddProject
        t={t}
        visible={projectState.props.openAddProject}
        setVisible={handleCloseModal}
      />

      <FormProjectsDummy
        setFormProjectVisibleDummy={setFormProjectVisibleDummy}
        formProjectVisibleDummy={formProjectVisibleDummy}
        isDummy={true}
      />

      <FormDelete
        setFormDeleteVisible={setFormDeleteVisible}
        formDeleteVisible={formDeleteVisible}
        sourceTypeView={typeView}
      />
    </Animated>
  );
}

export default withTranslation()(ProjectsView);
