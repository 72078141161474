/* eslint-disable prefer-const */
/** React components  */
import React from 'react';

/** import elements from library Antd Framework */
import { Row, Col } from 'antd';

/** import icons from antd framework */
import { HomeFilled } from '@ant-design/icons';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import './RouteToolbar.css';

/** render */
export default function RouteToolbar(props) {
  const { width } = useWindowDimensions();
  let rightSideStyle = {
    paddingRight: '12px',
    paddingLeft: '12px'
  };
  if (width > 1600) {
    rightSideStyle.left = Math.round(width * 0.015) + 'px';
  } else if (width < 1300) {
    rightSideStyle.left = '-' + Math.round(width * 0.07) + 'px';
    rightSideStyle.paddingRight = '0px';
    rightSideStyle.paddingLeft = '0px';
  }

  return (
    <div className="route-toolbar-container">
      <div className="gutter-example">
        <Row gutter={24}>
          <Col className="gutter-row" span={7}>
            <label className="title-route">{props.route.title}</label>
            <label className="breadcrumb-route">
              <HomeFilled className="route-logo" />
              {` / ${props.route.breadcrumb}`}
            </label>
          </Col>
          <Col className="gutter-row" span={6}></Col>
          <Col className="gutter-row" span={8}></Col>
          <Col className="gutter-row" style={rightSideStyle} span={3}>
            {/** Here must be the second part of toolbar */}
          </Col>
        </Row>
      </div>
    </div>
  );
}
