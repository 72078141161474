import * as Sentry from '@sentry/react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSessionStorage,
  getCurrentUser,
  getSessionToken,
  getSessionTokenData,
  initializeUserStorage,
  setCurrentProject,
  setCurrentSector
} from '../utils/userUtils';
import { useEffect, useState } from 'react';
import { userService } from '../services';
import {
  filterActiveProjects,
  findItemById,
  findProjectActiveSchedulesByProjectId
} from '../utils/projectUtils';
import { compareValues, setAllProjectsGlobal } from '../utils';
import {
  setSector,
  setAllSectors,
  setAllProjects,
  setProject,
  setProps
} from '../redux/slices/projectSlice';
import { companyActions } from '../redux/actions/companyActions';
import { accessService } from '../services/access.service';
import {
  NO_ORGANIZATION_ACCESS,
  NOT_FOUND,
  LOADING_ACCESS,
  NO_PROJECT_ACCESS,
  PROJECT_ACCESS_ERROR_MSG,
  SCHEDULE_ACCESS_ERROR_MSG,
  NO_SCHEDULE_ACCESS,
  NO_MODULE_ACCESS,
  ACCESS_DENIED_EVENT
} from '../constants/access';
import SessionCreationError from '../entities/error/SessionCreationError';
import { findModuleByPath } from '../constants/modules';
import { trackingEvent } from '../analytics';
import { AMPLITUDE_SERVICE } from '../analytics/constants';

const configureUserProjects = (projectId, params, dispatch) => {
  const { projects, projectState } = params;
  const actions = { setAllProjects, setProps };
  const active = filterActiveProjects(projects);
  const project = findItemById(active, projectId);

  if (!project) {
    throw new Error(PROJECT_ACCESS_ERROR_MSG);
  }

  setAllProjectsGlobal(active, dispatch, actions, projectState);
  dispatch(setProject(projectId));
  setCurrentProject(project);
};

const configureUserSchedules = (scheduleId, params, dispatch) => {
  const { projectId, projects } = params;
  const schedules = findProjectActiveSchedulesByProjectId(projects, projectId);
  const schedule = findItemById(schedules, scheduleId);

  if (!schedule) {
    throw new Error(SCHEDULE_ACCESS_ERROR_MSG);
  }

  dispatch(setAllSectors(schedules.sort(compareValues('order'))));
  dispatch(setSector(scheduleId));
  setCurrentSector(schedule);
};

const configureUserOrganization = async (userId, organizationId, dispatch) => {
  const tokenData = await getSessionToken(userId, organizationId);
  initializeUserStorage(tokenData);
  dispatch(companyActions.setCurrentCompany(tokenData.company));
};

const checkAccessStatus = async (projectId, scheduleId, route) => {
  const { role } = getSessionTokenData();
  const [moduleName, { allowedRoles }] = findModuleByPath(route);

  const tracking = {
    user_role: role,
    required_roles: Object.keys(allowedRoles),
    module_name: moduleName
  };

  if (!allowedRoles[role]) {
    return {
      status: NO_MODULE_ACCESS,
      tracking
    };
  }

  const status = await accessService.checkScheduleAccess(projectId, scheduleId);
  return { status, tracking };
};

const initializeSession = async (userId, params, setters) => {
  const { organizationId, projectId, route, scheduleId, projectState } = params;
  const { dispatch, setStatus } = setters;
  const baseTracking = {
    organization_id: organizationId,
    project_id: projectId,
    schedule_id: scheduleId,
    url: window.location.href
  };

  try {
    await configureUserOrganization(userId, organizationId, dispatch);
    const { status, tracking } = await checkAccessStatus(
      projectId,
      scheduleId,
      route
    );

    if (status === NOT_FOUND) {
      setStatus(NOT_FOUND);
      return;
    }

    trackingEvent(
      ACCESS_DENIED_EVENT,
      { ...tracking, ...baseTracking },
      AMPLITUDE_SERVICE
    );

    if (status === NO_PROJECT_ACCESS) {
      window.location.href = '/projects?error=unauthorized';
      return;
    }

    const { projects } = await userService.projectsbyuserthrough(userId);

    configureUserProjects(projectId, { projects, projectState }, dispatch);

    if (status !== NO_SCHEDULE_ACCESS) {
      configureUserSchedules(scheduleId, { projectId, projects }, dispatch);
    }

    setStatus(status);
  } catch (err) {
    console.log(err);

    if (err instanceof SessionCreationError) {
      setStatus(NO_ORGANIZATION_ACCESS);
      clearSessionStorage();
      trackingEvent(
        ACCESS_DENIED_EVENT,
        {
          reason: NO_ORGANIZATION_ACCESS,
          ...baseTracking
        },
        AMPLITUDE_SERVICE
      );
      return;
    }

    setStatus(NOT_FOUND);
    Sentry.captureException(err);
  }
};

export const useInitializeSession = () => {
  const [status, setStatus] = useState(LOADING_ACCESS);
  const {
    organizationId: rawOrganizationId,
    projectId: rawProjectId,
    scheduleId: rawScheduleId
  } = useParams();
  const { path } = useRouteMatch();
  const projectState = useSelector((state) => state.projectState);
  const dispatch = useDispatch();

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (!currentUser) {
      window.location.href = '/login';
      return;
    }

    const params = {
      route: path,
      organizationId: parseInt(rawOrganizationId, 10),
      projectId: parseInt(rawProjectId, 10),
      scheduleId: parseInt(rawScheduleId, 10),
      projectState
    };
    const setters = { dispatch, setStatus };

    initializeSession(currentUser.id, params, setters);
  }, []);

  return { status };
};
