import React from 'react';

import { splitText } from '../../../ActivitiesUnitsGrid/index.helper';
export const FixedColActivitiesRowTree =
  (data, width, selectedActivities, selectedUnits) =>
  ({ columnIndex, rowIndex, style }) => {
    const activity = data[rowIndex];
    const customStyle = { paddingLeft: activity.lvl * 15 };
    if (!activity.lvl) customStyle.paddingLeft = 5;

    let addTopClass = '';
    if (!rowIndex) {
      addTopClass = ' rounded-top-fixed';
    }

    const isActivitySelected = selectedActivities.find(
      (act) => act.id == activity.id && !activity.disable
    );

    let hasAtLeastOneAvailableUnit = false;
    const onlyUnits = selectedUnits.filter((u) => !u.structureId);
    for (let i = 0; i < onlyUnits.length; i++) {
      const ac = onlyUnits[i];
      if (!ac.disable) {
        hasAtLeastOneAvailableUnit = true;
        break;
      }
    }

    if (
      isActivitySelected &&
      hasAtLeastOneAvailableUnit &&
      !activity.has_childs
    ) {
      return (
        <div className="fixed-col-activity-with-units-matrix" style={style}>
          <div
            className={'col-fixed-row-matrix' + addTopClass}
            style={customStyle}>
            {splitText(activity.name, width - customStyle.paddingLeft)}
          </div>
        </div>
      );
    }
    return (
      <div className="fixed-col-activity-without-units" style={style}>
        <div
          className={'col-fixed-row-matrix' + addTopClass}
          style={customStyle}>
          {splitText(activity.name, width - customStyle.paddingLeft)}
        </div>
      </div>
    );
  };

/**
 * This component is the rendered fixed col row, actually activities col (to easy understand)
 * @param {*} data all activities in lineal tree structure
 * @param {*} width width for this col
 */
export const FixedColActivitiesRowGroupped =
  (data, width, selectedActivities, selectedUnits) =>
  ({ columnIndex, rowIndex, style }) => {
    const grouppedActivity = data[rowIndex];

    let isSelectedActivity = false;

    for (let i = 0; i < grouppedActivity.activities.length; i++) {
      const ac = grouppedActivity.activities[i];
      const isActivityInSelectedArray = selectedActivities.find(
        (act) => act.id == ac.id && !ac.disable
      );
      if (isActivityInSelectedArray) {
        isSelectedActivity = true;
        break;
      }
    }

    let addTopClass = '';
    if (!rowIndex) {
      addTopClass = ' rounded-top-fixed';
    }

    let hasAtLeastOneAvailableUnit = false;
    const onlyUnits = selectedUnits.filter((u) => !u.structureId);
    for (let i = 0; i < onlyUnits.length; i++) {
      const ac = onlyUnits[i];
      if (!ac.disable) {
        hasAtLeastOneAvailableUnit = true;
        break;
      }
    }

    if (isSelectedActivity && hasAtLeastOneAvailableUnit) {
      return (
        <div className="fixed-col-activity-with-units-matrix" style={style}>
          <div
            style={{ textAlign: 'center' }}
            className={'col-fixed-row-matrix' + addTopClass}>
            {splitText(grouppedActivity.name, width)}
          </div>
        </div>
      );
    }
    return (
      <div className="fixed-col-activity-without-units" style={style}>
        <div className={'col-fixed-row-matrix' + addTopClass}>
          {splitText(grouppedActivity.name, width)}
        </div>
      </div>
    );
  };
