const getRandomLink = (gantt) => {
  const links = gantt.getLinks();
  if (!links) return;
  const randomLink = links[0];
  if (!randomLink) return;
  return {
    id: randomLink.id,
    link: randomLink
  };
};

export const initFunctions = (gantt) => {
  gantt.resetLinkState = (id, link) => {
    return;
    // const { id, link } = getRandomLink(gantt);
    gantt.callbackPlaceholderTask(id, link);
    gantt._preventCircularLink(id, link);
    gantt._preventDescendantLink(id, link);
    gantt.slackHandler();
    gantt.resetCache();
    gantt.criticalPathHandler();
  };

  gantt.resetLinkOnDeleteTask = () => {
    const { id, link } = getRandomLink(gantt);
    gantt.callbackPlaceholderTask(id, link);
    gantt._preventCircularLink(id, link);
    gantt._preventDescendantLink(id, link);
    gantt.slackHandler();
    gantt.resetCache();
    gantt.criticalPathHandler();
  };

  const filterDuplicatedLinks = (array) => {
    if (!array) return;
    const filtered = new Set(array);
    if (!filtered) return;

    return Array.from(filtered);
  };

  gantt.resetLinksStateWithTimeout = (isGanttStarted = true) => {
    setTimeout(() => {
      const taskALAP = gantt.getTaskBy((t) => t.constraint_type == 'alap');

      const linkALAP = taskALAP.reduce(
        (acc, t) => [...acc, ...t.$source, ...t.$target],
        []
      );

      const filteredDuplicated = filterDuplicatedLinks(linkALAP);
      if (!filteredDuplicated) return;
      filteredDuplicated.forEach((linkID) => {
        if (!gantt.isLinkExists(linkID)) return;
        const linkObject = gantt.getLink(linkID);
        gantt.resetLinkState(linkObject.id, linkObject);
      });

      if (linkALAP.length > 0 && !gantt.isPasting) {
        const initLink = linkALAP[0];
        if (!isGanttStarted) gantt.clearStacks = true;
        gantt._autoScheduleAfterLinkChange(initLink.id, initLink);
      }
    }, 1000);
  };
};
