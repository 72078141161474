export const ADD = 'SET_CRITERIA';
export const UPDATE = 'UPDATE_CRITERIA';

export const WidgetActions = {
  add: (value) => ({
    type: ADD,
    payload: value
  }),
  update: (value) => ({
    type: UPDATE,
    payload: value
  })
};
