import { PARTIAL_PERMISSION_PER_MODULE } from '../constants/permissions';
import { getSessionTokenData } from './userUtils';
import { roleTypes } from '../enums/Role.enum';

export const commandPermission = (command) => {
  const sessionTokenData = getSessionTokenData();
  if (!sessionTokenData) {
    return true;
  }
  const userRole = getRoleKey(sessionTokenData?.role);
  const schedulePermissions = PARTIAL_PERMISSION_PER_MODULE.MASTERPLAN.GANTT;

  if (!schedulePermissions[command]) {
    return true;
  }
  return schedulePermissions[command][userRole];
};

export const getRoleKey = (value) => {
  return Object.keys(roleTypes).find((key) => roleTypes[key] === value);
};
