import { useEffect } from 'react';

export function useOutsideHideComponent(ref, setShow, isRunning = true) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (isRunning && ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setShow, isRunning]);
}

export function useWindowSize(getPositionDropdown, setDropdownPosition) {
  useEffect(() => {
    function updateSize() {
      const currentPosition = getPositionDropdown();

      if (
        currentPosition.top !== 0 &&
        currentPosition.left !== 0 &&
        currentPosition.width !== 0
      ) {
        setDropdownPosition(currentPosition);
      }
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);
}
