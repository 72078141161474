/* eslint no-label-var: "off" */
import React, { useState, useEffect } from 'react';

/** Components */
import AppRouter from '../../router';

/**
 * High order components (HOC)
 */
import withToolbar from '../../hocs/withToolbar.hoc';
import withCompanyInfo from '../../hocs/withCompanyInfo.hoc';
import withFilters from '../../hocs/withFilters.hoc';

import '../layout.css';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useCheckUserAndLocation } from '../../hooks/useCheckUserAndLocation';

export default function Layout(props) {
  useCheckUserAndLocation();
  const [FinishPage, setFinishPage] = useState(
    <Spin className="loader-layout-style" indicator={<LoadingOutlined />} />
  );

  useEffect(() => {
    const position = { fixed: true };
    const filters = withFilters({ ...props })(AppRouter);
    const filtersAndCompanyInfo = withCompanyInfo({ ...props })(filters);
    const Final = withToolbar({ ...props, position })(filtersAndCompanyInfo);
    setFinishPage(<Final />);
  }, []);

  return <div>{FinishPage}</div>;
}
