/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
import { base } from './base';

const baseURL = base.apiTakt;

class StructureService {
  constructor() {
    this.http = createHttpClient({ baseURL });
  }

  async show(structureId) {
    const res = await this.http
      .get('structures/' + structureId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(structureId) {
    const res = await this.http
      .delete('structures/' + structureId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('structures', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('structures/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showBySector(sectorId) {
    const res = await this.http
      .post('structures/bysector', { sectorId })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const structureService = new StructureService();
