import React, { useState, useEffect } from 'react';
import { Animated } from 'react-animated-css';
import { Button, Row, Col, Popover, Modal, Checkbox, Tooltip } from 'antd';
import { structureService } from '../../../services/structure.service';
import { locationService } from '../../../services/location.service';
import { productionunitService } from '../../../services/productionunit.service';
import { Spin, Icon, Input } from 'antd';
import moment from 'moment';
import { notifyMessage } from '../../../utils/lookahead-common';

import { useSelector, useDispatch } from 'react-redux';
import OtroIcon from '../../../assets/img/takt/Otro.svg';

import useWindowDimensions from '../../../hooks/useWindowDimensions';

import './index.css';
import { withTranslation } from 'react-i18next';
import optionIcon from '../../../assets/img/takt/options.png';
import duplicateIcon from '../../../assets/img/takt/duplicate.png';
import deleteIcon from '../../../assets/img/takt/delete.png';
import infoIcon from '../../../assets/img/takt/info.png';
import typeDefaultIcon from '../../../assets/img/takt/type-default.png';
import showChildsIcon from '../../../assets/img/takt/showchilds.png';
import hideChildsIcon from '../../../assets/img/takt/hidechilds.png';
import massiveActionIcon from '../../../assets/img/takt/massiveaction.png';
import deleteActionIcon from '../../../assets/img/takt/deleteaction.png';
import addChildActionIcon from '../../../assets/img/takt/addchild.png';
import editProductionUnitIcon from '../../../assets/img/takt/editpu.png';
import { prototypeStructure, fakeStructures } from './index.helper';
import EditableInput from '../../../components/EditableTatkLocal/index';
import { getSignedUser } from '../../../utils/userUtils';
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const colorsPerLevel = ['#E0E0E0', '#F8F8F8', '#FFFFFF'];

function UnitsView(props) {
  const [loaded, setLoaded] = useState(false);
  const projectState = useSelector((state) => state.projectState);
  const { height, width } = useWindowDimensions();
  const [selectedStructure, setSelectedStructure] = useState(false);
  const [allStructures, setAllStructures] = useState([]);
  const [creationModal, setCreationModal] = useState({
    visible: false,
    data: null,
    step: 0
  });
  const [selectedLocation, setSelectedLocation] = useState(false);
  const [massiveStructureModal, setMassiveStructureModal] = useState({
    visible: false,
    data: null
  });
  const [massiveUnitModal, setMassiveUnitModal] = useState({
    visible: false,
    data: null
  });
  const [editUnitState, setEditUnitState] = useState({
    visible: false,
    name: ''
  });
  const [locationRange, setLocationRange] = useState({
    start: null,
    end: null
  });
  const [structureRange, setStructureRange] = useState({
    start: null,
    end: null
  });
  const [unitsRange, setUnitsRange] = useState({ start: null, end: null });
  const [nPerLocation, setnPerLocation] = useState(null);
  const { t } = props;
  const [editedInput, setEditedInput] = useState(null);

  const [warningDelete, setWarningDelete] = useState({
    visible: false,
    onOk: () => {},
    text: '',
    data: []
  });

  useEffect(() => {
    getStructures();
  }, [projectState.sectorSelected]);

  useEffect(() => {
    window.Appcues.page();
  });

  /********************/
  /** Function SECTION */
  /********************/

  /**
   * This function gets initial data for view, it is followed by effect which updates sector data when user change it at toolbar component
   */
  const getStructures = async () => {
    setSelectedStructure(false);
    setSelectedLocation(false);
    const currentSector = projectState.allSectors.find(
      (e) => e.id == projectState.sectorSelected
    );
    const structures = await structureService.showBySector(currentSector.id);
    setAllStructures(structures.structure);
    setLoaded(true);
  };

  /**
   * This function allows to select a type of structure when using modal to create structures
   * @param {*} prototype Prototype of structure to use auto generation data
   */
  const selectTypeStructure = (prototype = false) => {
    if (prototype) {
      setCreationModal({
        ...creationModal,
        data: { type: prototype },
        step: -1,
        prototypeAutoData: {
          dataLocationInput: '',
          dataProductionUnitInput: '',
          locationEnd: '',
          locationInit: '',
          nPerLocation: '',
          name: ''
        }
      });
    } else {
      setCreationModal({
        ...creationModal,
        step: 1,
        prototypeAutoData: {
          dataLocationInput: '',
          dataProductionUnitInput: '',
          locationEnd: '',
          locationInit: '',
          nPerLocation: '',
          name: ''
        }
      });
    }
  };

  /**
   * This function execute the form of auto creation structure data
   */
  const autoCreationStructure = async () => {
    const prototypeData = creationModal.prototypeAutoData;
    const currentSector = projectState.allSectors.find(
      (e) => e.id == projectState.sectorSelected
    );
    const currentProject = projectState.allProjects.find(
      (e) => e.id == projectState.projectSelected
    );
    const currentUser = getSignedUser();

    /** Location data */
    const locationName = prototypeData.dataLocationInput;
    let locationStart = parseInt(prototypeData.locationInit);
    let locationEnd = parseInt(prototypeData.locationEnd);

    locationStart = locationStart < 0 ? locationStart * -1 : locationStart;
    locationEnd = locationEnd < 0 ? locationEnd * -1 : locationEnd;

    /** Units data */
    const unitName = prototypeData.dataProductionUnitInput;
    const nUnitsPerLocation = parseInt(prototypeData.nPerLocation);

    if (
      locationStart <= 0 ||
      locationEnd <= 0 ||
      isNaN(locationEnd) ||
      isNaN(locationStart)
    ) {
      notifyMessage({
        title: 'Configuración de rangos incorrecta',
        message: 'Debes configurar rangos coherentes.',
        type: 'warning'
      });
      return;
    }

    if (
      prototypeData.name != '' &&
      locationName != '' &&
      unitName != '' &&
      nUnitsPerLocation != '' &&
      Object.keys(prototypeData).length
    ) {
      if (locationStart > locationEnd) {
        notifyMessage({
          title:
            'no puede el numero de ubicacion inicial ser mayor que el final',
          type: 'warning'
        });
        return;
      }
      if (locationStart == locationEnd) {
        notifyMessage({
          title: 'no pueden ser iguales inicio/fin',
          type: 'warning'
        });
        return;
      }

      if (locationStart < 0 || locationEnd < 0 || nUnitsPerLocation < 0) {
        notifyMessage({ title: 'valores numericos erroneos', type: 'warning' });
        return;
      }

      setCreationModal({ ...creationModal, loading: true });

      /**
       * First is necessary to create structure
       */
      const newStructure = {
        name: prototypeData.name,
        sector: currentSector,
        project: currentProject,
        user: currentUser,
        creation_date: moment().format('YYYY/MM/DD'),
        sectorId: currentSector.id,
        projectId: currentProject.id,
        creatorId: currentUser.id
      };

      const res = await structureService.create({
        name: newStructure.name,
        sectorId: newStructure.sector.id,
        projectId: newStructure.project.id,
        creatorId: newStructure.user.id,
        creation_date: newStructure.creation_date
      });

      if (res) {
        newStructure.id = res.id;
        newStructure.locations = [];
        /**
         * Then itreating from start to end, we create locations for the created structure
         */
        const createdLocations = [];
        for (let a = locationStart; a <= locationEnd; a++) {
          const newLocation = {
            structureId: newStructure.id,
            name: locationName + ' ' + a,
            correlative_id: a,
            level: 0,
            has_childs: false,
            locationParentId: null,
            children: [],
            productionUnits: []
          };
          const res = await locationService.create(newLocation);
          if (res) {
            newLocation.id = res.id;
            const createdUnits = [];
            /**
             * Once we register each location must create all units per location
             */
            const unit_post = [];
            for (let b = 0; b < nUnitsPerLocation; b++) {
              const newUnit = {
                name: (creationModal.data.type.id = 1
                  ? unitName +
                    ' ' +
                    (newLocation.correlative_id * 100 + (b + 1))
                  : unitName + ' ' + (b + 1)),
                locationId: newLocation.id
              };
              unit_post.push(newUnit);
            }
            const data = {
              production_units: unit_post
            };
            const resUnits = await productionunitService.createAll(data);
            resUnits.map((restUnit) => {
              createdUnits.push(restUnit);
            });
            /* if (resUnit) {
                                newUnit.id = resUnit.id
                                createdUnits.push(newUnit)
                        } */
            newLocation.productionUnits = [...createdUnits];
            createdLocations.push(newLocation);
          }
        }
        newStructure.locations = [...createdLocations];
        setAllStructures([...allStructures, newStructure]);
        setCreationModal({ visible: false, data: null, step: 0 });
        setLocationRange({ start: null, end: null });
        setStructureRange({ start: null, end: null });
        setUnitsRange({ start: null, end: null });
        setnPerLocation(null);
      }
    } else {
      notifyMessage({ title: 'Ingrese datos', type: 'warning' });
    }
  };

  /**
   * This async function fetchs, the creation of a new structure (only for  non auto creations)
   */
  const createStructure = async () => {
    setCreationModal({ ...creationModal, loading: true });
    const prototypeData = creationModal.prototypeAutoData;
    const currentSector = projectState.allSectors.find(
      (e) => e.id == projectState.sectorSelected
    );
    const currentProject = projectState.allProjects.find(
      (e) => e.id == projectState.projectSelected
    );
    const currentUser = getSignedUser();

    const newStructure = {
      name: prototypeData.name,
      sector: currentSector,
      project: currentProject,
      user: currentUser,
      creation_date: moment().format('YYYY/MM/DD'),
      locations: [],
      sectorId: currentSector.id,
      projectId: currentProject.id,
      creatorId: currentUser.id
    };
    const res = await structureService.create({
      name: newStructure.name,
      sectorId: newStructure.sector.id,
      projectId: newStructure.project.id,
      creatorId: newStructure.user.id,
      creation_date: newStructure.creation_date
    });

    if (res) {
      newStructure.id = res.id;
      setAllStructures([...allStructures, newStructure]);
      setCreationModal({ visible: false, data: null, step: 0 });
    }
  };

  /**
   * This function allows user to select an structure and allow locations view col
   * @param {*} structure This is the selected structure from array
   */
  const selectStructure = (structure) => {
    setSelectedStructure(structure);
    setSelectedLocation(false);
  };

  const recursiveDuplicateTree = async (parent, structure) => {
    /** Locations */
    const newLocations = [];
    for (let a = 0; a < parent.children.length; a++) {
      const location = parent.children[a];
      const newLocation = {
        ...location,
        structureId: structure.id,
        locationParentId: parent.id
      };
      delete newLocation.id;
      const resLocation = await locationService.create(newLocation);
      if (resLocation) {
        newLocation.id = resLocation.id;

        /** Check if there is a tree structure to deal with it */
        if (newLocation.children.length) {
          recursiveDuplicateTree(newLocation, structure);
        }
        newLocations.push(newLocation);

        /** Once we have created the location, we copy original units */
        newLocation.productionUnits = await createPus(newLocation);
      }
    }
    parent.children = newLocations;
  };

  const createPus = async (newLocation) => {
    const newPus = [];
    for (let b = 0; b < newLocation.productionUnits.length; b++) {
      const pu = newLocation.productionUnits[b];
      const newPu = {
        ...pu,
        locationId: newLocation.id
      };
      delete newPu.id;
      const resPu = await productionunitService.create(newPu);
      if (resPu) {
        newPu.id = resPu.id;
        newPus.push(newPu);
      }
    }
    return newPus;
  };

  /**
   * ON DEV
   * This function allows users to duplicate an structure (this means all data structure, which are structure, locations and their tree structure and locations whit their production units)
   * @param {*} structure Structure object to duplicate
   */
  const duplicateStructure = async (structure) => {
    /** First we duplicate the data for structurem, setting new creation date and original old id */
    const currentUser = getSignedUser();
    const newStructure = {
      ...structure,
      locations: [],
      creation_date: moment().format('YYYY/MM/DD'),
      creatorId: currentUser.id,
      user: currentUser
    };
    delete newStructure.id;
    let count_copy = 0;
    allStructures.map((strs) => {
      if (strs.name.includes(newStructure.name)) {
        count_copy++;
      }
    });
    /* if(newStructure.name.includes("Copia")){

        }else{

        } */
    newStructure.name = newStructure.name + ' Copia ' + count_copy;
    const resStructure = await structureService.create(newStructure);

    if (resStructure) {
      newStructure.id = resStructure.id;

      /** Then we map locations of original structure to duplicate them */
      const newLocations = [];
      for (let a = 0; a < structure.locations.length; a++) {
        const location = structure.locations[a];
        const newLocation = {
          ...location,
          structureId: newStructure.id
        };
        delete newLocation.id;
        const resLocation = await locationService.create(newLocation);
        if (resLocation) {
          newLocation.id = resLocation.id;

          /** Check if there is a tree structure to deal with it */
          if (newLocation.children.length) {
            recursiveDuplicateTree(newLocation, newStructure);
          }
          newLocations.push(newLocation);

          /** Once we have created the location, we copy original units */
          newLocation.productionUnits = await createPus(newLocation);
        }
      }

      newStructure.locations = newLocations;
      setAllStructures([...allStructures, newStructure]);
    }
  };

  /**
   * This functions allows to user to delete structures, this must update state to jsx show render
   * @param {*} structure Structure object to delete
   */
  const deleteStructure = async (structure) => {
    const res = await structureService.destroy(structure.id);
    if (res) {
      if (selectedStructure.id == structure.id) {
        setSelectedLocation(false);
        setSelectedStructure(false);
      }

      const toUpdateState = allStructures.filter((s) => s.id != structure.id);
      setAllStructures([...toUpdateState]);
    }
  };

  /**
   * This function allows to create tree data structure, which detects when a locaiton has a parent, and order it to allow jsx print it as a tree.
   * @param {*} parent If location has a parent should be give through this
   */
  const createLocation = async (parent = false) => {
    if (!selectedStructure.locations) {
      selectedStructure.locations = [];
    }

    let newLocation;
    let toPush;

    if (parent) {
      if (parent == selectedLocation) {
        setSelectedLocation(null);
      }

      parent.has_childs = true;
      await locationService.update(parent);

      parent.showChilds = true;
      newLocation = {
        structureId: selectedStructure.id,
        name: t('takt_units.location') + ' ' + (parent.children.length + 1),
        correlative_id: parent.children.length,
        level: parent ? parent.level + 1 : 0,
        has_childs: false,
        locationParentId: parent.id,
        children: [],
        productionUnits: [],
        correlative_id: parent.children.length + 1
      };
      toPush = parent.children;
    } else {
      newLocation = {
        structureId: selectedStructure.id,
        name:
          t('takt_units.location') +
          ' ' +
          (selectedStructure.locations.length + 1),
        correlative_id: selectedStructure.locations.length,
        level: parent ? parent.level + 1 : 0,
        has_childs: false,
        locationParentId: null,
        children: [],
        productionUnits: [],
        correlative_id: selectedStructure.locations.length + 1
      };
      toPush = selectedStructure.locations;
    }

    const res = await locationService.create({
      name: newLocation.name,
      level: newLocation.level,
      has_childs: newLocation.has_childs,
      locationParentId: newLocation.locationParentId,
      structureId: newLocation.structureId,
      correlative_id: newLocation.correlative_id
    });

    if (res) {
      newLocation.id = res.id;
      toPush.push(newLocation);
    }

    setSelectedStructure({ ...selectedStructure });
  };

  /**
   * This function only trigger the opening of modal to create massively locations
   * @param {*} parent Parent location object if it is triggered through the tree data structure
   */
  const openCreateMassively = (parent = false) => {
    setMassiveStructureModal({
      ...massiveStructureModal,
      visible: true,
      data: { name: '', start: '', end: '' },
      parent: parent
    });
  };

  /**
   * Same as last function but for production units
   */
  const openCreateMassivelyUnit = () => {
    setMassiveUnitModal({
      ...massiveUnitModal,
      visible: true,
      data: { name: '', start: '', end: '' }
    });
  };

  /**
   * This function allows users, to hide/show childs of a specific location
   * @param {*} hide true or false if you want to hide
   * @param {*} location Location object which is desired to show or hide their childs
   */
  const changeChildVisibility = (hide, location) => {
    location.showChilds = !hide;
    setSelectedStructure({ ...selectedStructure });
  };

  /**
   * This function allows users to create their production units
   */
  const createProductionUnit = async () => {
    const newProductionUnit = {
      // name: 'Production ' +  (( (selectedLocation.correlative_id+1) * 100)+ selectedLocation.productionUnits.length),
      name: 'Production ' + (selectedLocation.productionUnits.length + 1),
      locationId: selectedLocation.id,
      correlative_id: selectedLocation.productionUnits.length
    };

    const res = await productionunitService.create(newProductionUnit);

    if (res) {
      newProductionUnit.id = res.id;
      selectedLocation.productionUnits.push(newProductionUnit);
    }

    setAllStructures([...allStructures]);
  };

  /**
   * This is submit function for creation of massive production units
   */
  const massiveUnitCreation = async () => {
    let parsedStart = parseInt(massiveUnitModal.data.start);
    let parsedEnd = parseInt(massiveUnitModal.data.end);

    parsedStart = parsedStart < 0 ? parsedStart * -1 : parsedStart;
    parsedEnd = parsedEnd < 0 ? parsedEnd * -1 : parsedEnd;

    if (
      parsedStart <= 0 ||
      parsedEnd <= 0 ||
      isNaN(parsedStart) ||
      isNaN(parsedEnd)
    ) {
      notifyMessage({
        title: 'Configuración de rangos incorrecta',
        message: 'Debes configurar rangos coherentes.',
        type: 'warning'
      });
      return;
    }

    if (massiveUnitModal.data.name != '') {
      if (massiveUnitModal.data.start > massiveUnitModal.data.end) {
        notifyMessage({
          title: 'El inicio no puede ser mayor al termino',
          type: 'warning'
        });
        return;
      }
      if (massiveUnitModal.data.start == massiveUnitModal.data.end) {
        notifyMessage({
          title: 'El inico y el termino no pueden ser iguales',
          type: 'warning'
        });
        return;
      }

      setMassiveUnitModal({ ...massiveUnitModal, loading: true });

      const unit_post = [];
      const createdUnits = [];
      for (
        let a = massiveUnitModal.data.start;
        a <= massiveUnitModal.data.end;
        a++
      ) {
        const newUnit = {
          name: massiveUnitModal.data.name + '  ' + a,
          locationId: selectedLocation.id
        };
        unit_post.push(newUnit);
      }

      const data = {
        production_units: unit_post
      };

      const resUnits = await productionunitService.createAll(data);

      resUnits.map((restUnit) => {
        createdUnits.push(restUnit);
        selectedLocation.productionUnits.push(restUnit);
      });

      // const orderUnitsCB = (current, next) => {
      //    const fisrt = parseInt(current.name.split(/\w+\s/)[1])
      //    const second = parseInt(next.name.split(/\w+\s/)[1])
      //    return fisrt < second ? -1 : 0
      // }

      const orderUnitsCB = (current, next) => current.id - next.id;
      selectedLocation.productionUnits.sort(orderUnitsCB);

      setMassiveUnitModal({ visible: false });
      setLocationRange({ start: null, end: null });
      setStructureRange({ start: null, end: null });
      setUnitsRange({ start: null, end: null });
      setnPerLocation(null);
    }
  };

  /**
   * This is submit function for creation of massive locations
   */
  const massiveStructureCreation = async () => {
    let parsedStart = parseInt(massiveStructureModal.data.start);
    let parsedEnd = parseInt(massiveStructureModal.data.end);

    if (
      massiveStructureModal.parent &&
      massiveStructureModal.parent.productionUnits.length
    ) {
      return notifyMessage({
        title: 'No se puede crear las ubicaciones',
        message: 'No puedes crear ubicaciones por que ya tienes unidades',
        type: 'warning'
      });
    }

    parsedStart = parsedStart < 0 ? parsedStart * -1 : parsedStart;
    parsedEnd = parsedEnd < 0 ? parsedEnd * -1 : parsedEnd;
    if (
      parsedStart <= 0 ||
      parsedEnd <= 0 ||
      isNaN(parsedStart) ||
      isNaN(parsedEnd)
    ) {
      notifyMessage({
        title: 'Configuración de rangos incorrecta',
        message: 'Debes configurar rangos coherentes.',
        type: 'warning'
      });
      return;
    }

    if (massiveStructureModal.data.name != '') {
      if (massiveStructureModal.data.start > massiveStructureModal.data.end) {
        notifyMessage({
          title: 'El inicio no puede ser mayor al termino',
          type: 'warning'
        });
        return;
      }
      if (massiveStructureModal.data.start == massiveStructureModal.data.end) {
        notifyMessage({
          title: 'El inico y el termino no pueden ser iguales',
          type: 'warning'
        });
        return;
      }

      setMassiveStructureModal({ ...massiveStructureModal, loading: true });

      /* const createdLocations = []
                const newLocation = {
                    structureId: selectedStructure.id,
                    name: massiveStructureModal.data.name + ' ' + a,
                    correlative_id: a,
                    level: massiveStructureModal.parent ? massiveStructureModal.parent.level + 1 : 0,
                    has_childs: false,
                    locationParentId: massiveStructureModal.parent ? massiveStructureModal.parent.id : null,
                    children: [],
                    productionUnits: [],
                    //correlative_id: parent.children.length+1
                }
                local_rest.push(local_rest)
                const res = await locationService.create(newLocation)
                if (res) {
                    newLocation.id = res.id
                    createdLocations.push(newLocation)
                }
            } */

      const createdLocations = [];
      const local_rest = [];
      for (
        let a = massiveStructureModal.data.start;
        a <= massiveStructureModal.data.end;
        a++
      ) {
        const newLocation = {
          structureId: selectedStructure.id,
          name: massiveStructureModal.data.name + ' ' + a,
          correlative_id: a,
          level: massiveStructureModal.parent
            ? massiveStructureModal.parent.level + 1
            : 0,
          has_childs: false,
          locationParentId: massiveStructureModal.parent
            ? massiveStructureModal.parent.id
            : null,
          children: [],
          productionUnits: []
          // correlative_id: parent.children.length+1
        };
        local_rest.push(newLocation);
      }

      const data = {
        locations: local_rest
      };

      const res = await locationService.createAll(data);

      res.map((l) => {
        l.children = [];
        l.productionUnits = [];
        createdLocations.push(l);
      });

      if (massiveStructureModal.parent) {
        massiveStructureModal.parent.children = [
          ...massiveStructureModal.parent.children,
          ...createdLocations
        ];
        if (
          massiveStructureModal.parent.children.length &&
          !massiveStructureModal.parent.has_childs
        ) {
          const s = await locationService.update({
            ...massiveStructureModal.parent,
            has_childs: true
          });
          if (s) {
            massiveStructureModal.parent.has_childs = true;
          }
        }
      } else {
        selectedStructure.locations = [
          ...selectedStructure.locations,
          ...createdLocations
        ];
      }

      setSelectedStructure({ ...selectedStructure });
      setMassiveStructureModal({ visible: false });
      setLocationRange({ start: null, end: null });
      setStructureRange({ start: null, end: null });
      setUnitsRange({ start: null, end: null });
      setnPerLocation(null);
    } else {
      notifyMessage({ title: 'Ingrese los datos', type: 'warning' });
    }
  };

  const deleteLocation = async (toDelete, structure, parent) => {
    const res = await locationService.destroy(toDelete.id);
    if (res) {
      if (parent) {
        parent.children = parent.children.filter(
          (loc) => loc.id != toDelete.id
        );
        if (!parent.children.length && parent.has_childs) {
          const resUpdate = await locationService.update({
            ...parent,
            has_childs: false
          });
          if (resUpdate) {
            parent.has_childs = false;
          }
        }
      } else {
        const structure_actual = allStructures.find(
          (stre) => stre.id == structure.id
        );
        structure_actual.locations = structure_actual.locations.filter(
          (loc) => loc.id != toDelete.id
        );
        setSelectedStructure(structure_actual);
      }

      if (selectedLocation.id == toDelete.id) {
        setSelectedLocation(false);
      }

      setAllStructures([...allStructures]);

      if (toDelete.has_childs && toDelete.showChilds) {
        toDelete.children.map((child) => {
          // recursiveLocalForUpdate(location,id,value)
          destroy_recursive(child);
        });
      }
    }
  };

  const destroy_recursive = async (location) => {
    const res = await locationService.destroy(location.id);
    if (location.has_childs && location.showChilds) {
      location.children.map((child) => {
        destroy_recursive(child);
      });
    }
  };

  const onChangeFunctionLocale = async (value, id) => {
    selectedStructure.locations.map((location) => {
      recursiveLocalForUpdate(location, id, value);
    });
  };

  const onChangeFunctionProductUnid = async (value, id) => {
    const productionUnit = selectedLocation.productionUnits.find(
      (p) => p.id == id
    );
    productionUnit.name = value;
    productionunitService.update(productionUnit);
  };

  const recursiveLocalForUpdate = (location, id, value) => {
    if (location.id == id) {
      location.name = value;
      locationService.update(location);
    } else {
      if (location.has_childs && location.showChilds) {
        location.children.map((child) => {
          // recursiveLocalForUpdate(location,id,value)
          recursiveLocalForUpdate(child, id, value);
        });
      }
    }
  };

  const checkRelationsOfLocationUnits = (location, hasRelations) => {
    if (location.productionUnits.length) {
      location.productionUnits.map((pu) => {
        if (pu.tasks?.length) {
          hasRelations.value = true;
        }
      });
    } else {
      if (location.children.length) {
        return location.children.map((child) =>
          checkRelationsOfLocationUnits(child, hasRelations)
        );
      }
    }
  };

  const checkRecursiveLocation = (location) => {
    const hasRelations = { value: false };
    checkRelationsOfLocationUnits(location, hasRelations);
    return hasRelations.value;
  };

  const checkRecursiveStructure = (structure) => {
    const hasRelations = { value: false };
    structure.locations.map((loc) => {
      checkRelationsOfLocationUnits(loc, hasRelations);
    });
    return hasRelations.value;
  };

  /********************/
  /** RENDER SECTION */
  /********************/

  /**
   * Recursive function which allows to create a jsx whit tree structure
   * @param {*} finalArray Memory array ref whit final jsx array with tree structure
   * @param {*} location single location which must be iterated
   */
  const recursivePush = (finalArray, location, parent = false) => {
    let color;
    if (location.level < 2) {
      color = colorsPerLevel[location.level];
    } else {
      color = colorsPerLevel[2];
    }

    if (selectedLocation) {
      if (selectedLocation.id == location.id) {
        color = '#2C342166';
      }
    }

    const openLocationUnits = () => {
      if (!location.has_childs) {
        setSelectedLocation(location);
      }
    };

    finalArray.push(
      <Row
        className="tree-location-element"
        style={{ backgroundColor: color, paddingLeft: location.level * 6 }}>
        <Col span={2} style={{ textAlign: 'center' }}>
          {location.has_childs && location.showChilds ? (
            <div
              onClick={() => changeChildVisibility(true, location)}
              style={{ cursor: 'pointer' }}>
              <img src={hideChildsIcon} width={10} />
            </div>
          ) : null}
          {location.has_childs && !location.showChilds ? (
            <div
              onClick={() => changeChildVisibility(false, location)}
              style={{ cursor: 'pointer' }}>
              <img src={showChildsIcon} width={10} />
            </div>
          ) : null}
        </Col>
        <Col
          span={12}
          onClick={openLocationUnits}
          style={{ cursor: location.has_childs ? '' : 'pointer' }}>
          <EditableInput
            t={t}
            location={location}
            onEdit={setEditedInput}
            isEditing={editedInput}
            save={onChangeFunctionLocale}
            renderEditable={(setValue, value, updateParentData) => (
              <Input
                // onKeyDown={handleEsc}
                onPressEnter={updateParentData}
                key={location.id}
                id={location.id}
                size="small"
                value={value}
                className="custom-input-planification"
                onFocus={(e) => e.target.select()}
                onChange={(e) => setValue(e.target.value)}
              />
            )}
          />
        </Col>
        <Col
          span={6}
          onClick={openLocationUnits}
          style={{
            color: '#7DFF8A',
            cursor: location.has_childs ? '' : 'pointer'
          }}>
          {location.productionUnits.length > 0 &&
            location.productionUnits.length +
              ' ' +
              (location.productionUnits.length === 1
                ? t('takt_units.unit_label')
                : t('takt_units.units_label'))}
        </Col>
        <Col span={4}>
          <span style={{ float: 'right' }}>
            <span
              className="action-for-location"
              onClick={() => openCreateMassively(location)}>
              {!location.productionUnits.length && (
                <Tooltip
                  placement="top"
                  title={t('takt_units.massive_creation_label')}>
                  <img src={massiveActionIcon} width={12} />
                </Tooltip>
              )}
            </span>
            <span
              className="action-for-location"
              onClick={() => {
                if (checkRecursiveLocation(location)) {
                  setWarningDelete({
                    ...warningDelete,
                    onOk: deleteLocation,
                    data: [location, selectedStructure, parent],
                    visible: true
                  });
                } else {
                  deleteLocation(location, selectedStructure, parent);
                }
              }}>
              <Tooltip placement="top" title={t('takt_units.delete')}>
                <img src={deleteActionIcon} width={12} />
              </Tooltip>
            </span>
            {!location.productionUnits.length ? (
              <span
                className="action-for-location"
                onClick={() => createLocation(location)}>
                <Tooltip placement="top" title={t('takt_units.add_label')}>
                  <img src={addChildActionIcon} width={12} />
                </Tooltip>
              </span>
            ) : null}
          </span>
        </Col>
      </Row>
    );

    if (location.has_childs && location.showChilds) {
      location.children.sort((a, b) => a.id - b.id);
      location.children.map((child) => {
        recursivePush(finalArray, child, location);
      });
    }
  };

  /**
   * This function trigger the tree generation trhough recursive push function
   */
  const renderTreeLocations = () => {
    const jsxArray = [];

    if (selectedStructure.locations) {
      selectedStructure.locations.sort((a, b) => a.id - b.id);

      selectedStructure.locations.map((location) => {
        recursivePush(jsxArray, location);
      });
      return jsxArray;
    }

    return null;
  };

  const deleteProductionUnit = async (unit, location) => {
    const res = await productionunitService.destroy(unit.id);
    if (res) {
      location.productionUnits = location.productionUnits.filter(
        (pu) => pu.id != unit.id
      );
      setAllStructures([...allStructures]);
    }
  };

  /**
   * This function is responsable of printing for user the production units
   */
  const renderProductionUnits = () => {
    selectedLocation.productionUnits.sort((a, b) => a.id - b.id);
    return selectedLocation.productionUnits.map((pu) => (
      <Row className="production-unit-container">
        <Col span={2}></Col>
        <Col span={17}>
          <EditableInput
            t={t}
            location={pu}
            onEdit={setEditedInput}
            isEditing={editedInput}
            save={onChangeFunctionProductUnid}
            renderEditable={(setValue, value, updateParentData) => (
              <Input
                // onKeyDown={handleEsc}
                onPressEnter={updateParentData}
                key={pu.id}
                id={pu.id}
                size="small"
                value={value}
                className="custom-input-planification"
                onFocus={(e) => e.target.select()}
                onChange={(e) => setValue(e.target.value)}
              />
            )}
          />
        </Col>
        <Col span={4}>
          <span style={{ float: 'right' }}>
            <span
              className="action-for-location"
              onClick={() => {
                if (pu.tasks?.length) {
                  setWarningDelete({
                    ...warningDelete,
                    onOk: deleteProductionUnit,
                    data: [pu, selectedLocation],
                    visible: true
                  });
                } else {
                  deleteProductionUnit(pu, selectedLocation);
                }
              }}>
              <img src={deleteActionIcon} width={12} />
            </span>
          </span>
        </Col>
      </Row>
    ));
  };

  /**
   * This function is general render, when component is already loaded
   */
  const renderLoadedContent = () => (
    <Row>
      <Col>
        <Row>
          <Col span={6} offset={1}>
            <div className="structure-title">
              {t('takt_units.assign_structures')}
            </div>
          </Col>
          <Col span={1}>
            <div
              className="btn-create-structure"
              onClick={() =>
                setCreationModal({ visible: true, data: {}, step: 0 })
              }>
              {t('takt_units.create_label')} +
            </div>
          </Col>
        </Row>
        <Row>
          {/** Structure col */}
          <Col
            span={8}
            style={{
              marginTop: 25,
              overflow: 'auto',
              maxHeight: height - 202,
              paddingRight: 20
            }}>
            {allStructures
              .sort((a, b) => a.id - b.id)
              .map((structure) => {
                const background =
                  selectedStructure.id == structure.id ? '#2C342166' : 'white';
                return (
                  <Row
                    style={{ backgroundColor: background }}
                    className="single-structure-card"
                    key={structure.id}>
                    <Col
                      style={{ padding: 10 }}
                      onClick={() => selectStructure(structure)}>
                      <Row>
                        <Col span={10} className="structure-name">
                          {structure.name}
                        </Col>
                        <Col
                          span={12}
                          offset={2}
                          style={{ textAlign: 'right' }}
                          className="structure-creator">
                          {t('takt_units.created_by_label')}{' '}
                          {structure.user.name}
                        </Col>
                      </Row>
                      <Row className="structure-route">
                        {structure.project.name}, {structure.sector.name}
                      </Row>
                      <Row className="structure-date">
                        {t('takt_units.created_at')} {structure.creation_date}
                      </Row>
                    </Col>
                    <Popover
                      placement="right"
                      content={
                        <Row style={{ marginRight: 13 }}>
                          <Col>
                            <Row
                              style={{ cursor: 'pointer' }}
                              onClick={() => duplicateStructure(structure)}>
                              <Col span={10}>
                                <img src={duplicateIcon} width={13} />
                              </Col>
                              <Col span={5} style={{ paddingTop: 2 }}>
                                {t('takt_units.duplicate')}
                              </Col>
                            </Row>
                            <Row
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                if (checkRecursiveStructure(structure)) {
                                  setWarningDelete({
                                    ...warningDelete,
                                    onOk: deleteStructure,
                                    data: [structure],
                                    visible: true
                                  });
                                } else {
                                  deleteStructure(structure);
                                }
                              }}>
                              <Col span={10}>
                                <img src={deleteIcon} width={13} />
                              </Col>
                              <Col span={5} style={{ paddingTop: 2 }}>
                                {t('takt_units.delete')}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      }
                      trigger="click">
                      <span className="structure-options">
                        <img src={optionIcon} width={2} />
                      </span>
                    </Popover>
                  </Row>
                );
              })}
          </Col>

          {/** Location col */}
          {selectedStructure ? (
            <Col
              span={8}
              style={{
                overflow: 'auto',
                maxHeight: height - 176,
                paddingLeft: 23,
                paddingRight: 20
              }}>
              <Row className="location-title">
                {t('takt_units.locations_label')}
              </Row>
              <Row>
                <Col offset={14} span={4}>
                  <div
                    onClick={() => createLocation()}
                    style={{
                      fontSize: 12,
                      color: '#7DFF8A',
                      cursor: 'pointer',
                      textAlign: 'right'
                    }}>
                    + {t('takt_units.add_label')}
                  </div>
                </Col>
                <Col span={6}>
                  <div
                    onClick={() => openCreateMassively()}
                    style={{
                      fontSize: 12,
                      color: '#7DFF8A',
                      cursor: 'pointer',
                      textAlign: 'right'
                    }}>
                    + {t('takt_units.massive_creation_label')}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>{renderTreeLocations()}</Col>
              </Row>
            </Col>
          ) : null}

          {/** Production Unit Col */}
          {selectedLocation ? (
            <Col
              span={8}
              style={{
                overflow: 'auto',
                maxHeight: height - 176,
                paddingLeft: 23,
                paddingRight: 20
              }}>
              <Row className="location-title">
                {t('takt_units.production_units_label')}
              </Row>
              <Row>{selectedLocation.name}</Row>
              <Row>
                <Col offset={14} span={4}>
                  <div
                    onClick={() => createProductionUnit()}
                    style={{
                      fontSize: 12,
                      color: '#7DFF8A',
                      cursor: 'pointer',
                      textAlign: 'right'
                    }}>
                    + {t('takt_units.add_label')}
                  </div>
                </Col>
                <Col span={6}>
                  <div
                    onClick={openCreateMassivelyUnit}
                    style={{
                      fontSize: 12,
                      color: '#7DFF8A',
                      cursor: 'pointer',
                      textAlign: 'right'
                    }}>
                    + {t('takt_units.massive_creation_label')}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>{renderProductionUnits()}</Col>
              </Row>
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  );

  /**
   * This function render the body content for creating structure modal
   */
  const renderModalCreationBody = () => {
    /** Type selection */
    if (creationModal.loading) {
      return (
        <Spin className="loader-spinner-lookahead-header" indicator={antIcon} />
      );
    }

    if (creationModal.step == 0) {
      return (
        <Row>
          <Col>
            <Row>
              <Col className="type-structure-title">
                {t('takt_units.select_type_title')}
              </Col>
            </Row>
            {prototypeStructure.map((prototype) => (
              <Row
                className="type-selector-container"
                onClick={() =>
                  selectTypeStructure(prototype.hasauto ? prototype : false)
                }>
                <Col offset={1} span={3}>
                  <img src={prototype.icon} width={18} />
                </Col>
                <Col span={10} className="type-name">
                  {t('takt_units.' + prototype.translate_label)}
                </Col>
              </Row>
            ))}
            <Row
              className="type-selector-container"
              onClick={() => selectTypeStructure()}>
              <Col offset={1} span={3}>
                <img src={OtroIcon} width={18} />
              </Col>
              <Col span={10} className="type-name">
                {t('takt_units.other')}
              </Col>
            </Row>
          </Col>
        </Row>
      );
    } else if (creationModal.step == 1) {
      return (
        <Row>
          <Col>
            <Row>
              <Col>
                <input
                  placeholder={t('takt_units.name_structure')}
                  defaultValue={
                    creationModal.prototypeAutoData.name
                      ? creationModal.prototypeAutoData.name
                      : ''
                  }
                  onChange={(e) => {
                    creationModal.prototypeAutoData.name = e.target.value;
                  }}
                  className="structure-input"
                />
              </Col>
            </Row>
            <Row style={{ textAlign: 'center' }}>
              <Button
                onClick={() => createStructure()}
                style={{
                  background: '#7DFF8A',
                  color: '#121212',
                  borderColor: '#7DFF8A',
                  width: 107
                }}>
                {t('takt_units.create_label')}
              </Button>
            </Row>
          </Col>
        </Row>
      );
      /** -1 is when a type is selected */
    } else if (creationModal.step == -1) {
      return (
        <Row>
          <Col>
            <Row>
              <Col className="description-type-prototype">
                {t('takt_units.description_massive')}{' '}
                {t('takt_units.' + creationModal.data.type.translate_label)}
              </Col>
            </Row>
            <Row style={{ paddingLeft: 19 }}>
              <Col offset={6} span={5}>
                <Button
                  onClick={() =>
                    setCreationModal({ ...creationModal, step: 1 })
                  }
                  style={{
                    background: '#7DFF8A',
                    color: '#121212',
                    borderColor: '#7DFF8A',
                    width: 104
                  }}>
                  {t('takt_units.manual')}
                </Button>
              </Col>
              <Col offset={1} span={5}>
                <Button
                  onClick={() =>
                    setCreationModal({ ...creationModal, step: -2 })
                  }
                  style={{
                    background: '#7DFF8A',
                    color: '#121212',
                    borderColor: '#7DFF8A'
                  }}>
                  {t('takt_units.auto')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      );
      /** -2 for automatic creation selection */
    } else if (creationModal.step == -2) {
      return (
        <Row>
          <Col>
            <Row style={{ height: 50 }}>
              <Col offset={1} span={1} style={{ position: 'relative', top: 7 }}>
                <img src={creationModal.data.type.icon} width={18} />
              </Col>
              <Col
                span={10}
                className="type-name"
                style={{ fontSize: 20, marginLeft: 10, color: '#2C3421' }}>
                {t('takt_units.' + creationModal.data.type.translate_label)}

                <Popover
                  placement="right"
                  content={
                    <div style={{ maxWidth: 150 }}>
                      {creationModal.data.type.description}
                    </div>
                  }
                  trigger="hover">
                  <span style={{ marginLeft: 10 }}>
                    <img src={infoIcon} width={10} />
                  </span>
                </Popover>
              </Col>
            </Row>
            <Row>
              <Col>
                <input
                  placeholder={t('takt_units.name_structure')}
                  defaultValue={
                    creationModal.prototypeAutoData.name
                      ? creationModal.prototypeAutoData.name
                      : ''
                  }
                  onChange={(e) => {
                    creationModal.prototypeAutoData.name = e.target.value;
                  }}
                  className="structure-input"
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <input
                  placeholder={t(
                    'takt_units.' + creationModal.data.type.location_label
                  )}
                  defaultValue={
                    creationModal.prototypeAutoData.dataLocationInput
                      ? creationModal.prototypeAutoData.dataLocationInput
                      : ''
                  }
                  onChange={(e) => {
                    creationModal.prototypeAutoData.dataLocationInput =
                      e.target.value;
                  }}
                  className="structure-input"
                />
              </Col>
              <Col offset={1} span={5}>
                <input
                  type="number"
                  min="1"
                  placeholder={t('takt_units.n_start')}
                  value={locationRange.start}
                  onChange={(e) => {
                    const init =
                      parseInt(e.target.value) < 0
                        ? parseInt(e.target.value) * -1
                        : parseInt(e.target.value);
                    if (e.target.value === '-') return;

                    creationModal.prototypeAutoData.locationInit =
                      init == 0 ? 1 : init;
                    setLocationRange({
                      ...locationRange,
                      start: init == 0 ? 1 : init
                    });
                  }}
                  className="structure-input"
                />
              </Col>
              <Col offset={1} span={5}>
                <input
                  type="number"
                  min="1"
                  placeholder={t('takt_units.n_end')}
                  value={locationRange.end}
                  onChange={(e) => {
                    const end =
                      parseInt(e.target.value) < 0
                        ? parseInt(e.target.value) * -1
                        : parseInt(e.target.value);
                    if (e.target.value === '-') return;

                    creationModal.prototypeAutoData.locationEnd =
                      end == 0 ? 1 : end * -1;
                    setLocationRange({
                      ...locationRange,
                      end: end == 0 ? 1 : end
                    });
                  }}
                  className="structure-input"
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <input
                  placeholder={t(
                    'takt_units.' +
                      creationModal.data.type.production_unit_label_translate
                  )}
                  defaultValue={
                    creationModal.prototypeAutoData.dataProductionUnitInput
                      ? creationModal.prototypeAutoData.dataProductionUnitInput
                      : ''
                  }
                  onChange={(e) => {
                    creationModal.prototypeAutoData.dataProductionUnitInput =
                      e.target.value;
                  }}
                  className="structure-input"
                />
              </Col>
              <Col offset={1} span={11}>
                <input
                  type="number"
                  min="1"
                  placeholder={t(
                    'takt_units.' +
                      creationModal.data.type.location_label_translate
                  )}
                  value={nPerLocation}
                  onChange={(e) => {
                    const perLocation =
                      parseInt(e.target.value) < 0
                        ? parseInt(e.target.value) * -1
                        : parseInt(e.target.value);
                    if (e.target.value === '-') return;
                    creationModal.prototypeAutoData.nPerLocation =
                      perLocation == 0 ? 1 : perLocation;
                    setnPerLocation(perLocation == 0 ? 1 : perLocation);
                  }}
                  className="structure-input"
                />
              </Col>
            </Row>
            <Row style={{ textAlign: 'center' }}>
              <Button
                onClick={() => autoCreationStructure()}
                style={{
                  background: '#7DFF8A',
                  color: '#121212',
                  borderColor: '#7DFF8A',
                  width: 107
                }}>
                {t('takt_units.create_label')}
              </Button>
            </Row>
          </Col>
        </Row>
      );
    }
  };

  /**
   * This function render the body content for creating locations massively modal
   */
  const renderMassiveLocationCreationBody = () => {
    if (massiveStructureModal.loading) {
      return (
        <Spin className="loader-spinner-lookahead-header" indicator={antIcon} />
      );
    }

    if (massiveStructureModal.data) {
      return (
        <div className="modal-creation-structure">
          <Row>
            <Col span={14}>
              <input
                placeholder={t('takt_units.location_name')}
                defaultValue={
                  massiveStructureModal.data.name
                    ? massiveStructureModal.data.name
                    : ''
                }
                onChange={(e) => {
                  massiveStructureModal.data.name = e.target.value;
                }}
                className="structure-input"
              />
            </Col>
            <Col span={4} offset={1}>
              <input
                type="number"
                min="1"
                placeholder={t('takt_units.init')}
                value={structureRange.start}
                onChange={(e) => {
                  const start =
                    parseInt(e.target.value) < 0
                      ? parseInt(e.target.value) * -1
                      : parseInt(e.target.value);
                  if (e.target.value === '-') return;

                  massiveStructureModal.data.start = start == 0 ? 1 : start;
                  setStructureRange({
                    ...structureRange,
                    start: start == 0 ? 1 : start
                  });
                }}
                className="structure-input"
              />
            </Col>
            <Col span={4} offset={1}>
              <input
                placeholder={t('takt_units.finish')}
                type="number"
                min="1"
                value={structureRange.end}
                onChange={(e) => {
                  const end =
                    parseInt(e.target.value) < 0
                      ? parseInt(e.target.value) * -1
                      : parseInt(e.target.value);
                  if (e.target.value === '-') return;

                  massiveStructureModal.data.end = end == 0 ? 1 : end;
                  setStructureRange({
                    ...structureRange,
                    end: end == 0 ? 1 : end
                  });
                }}
                className="structure-input"
              />
            </Col>
          </Row>
        </div>
      );
    }
  };

  /**
   * This function render the body content for creating production units massively modal
   */
  const renderMassiveUnitCreationBody = () => {
    if (massiveUnitModal.loading) {
      return (
        <Spin className="loader-spinner-lookahead-header" indicator={antIcon} />
      );
    }

    if (massiveUnitModal.data) {
      return (
        <div className="modal-creation-structure">
          <Row>
            <Col span={14}>
              <input
                placeholder={t('takt_units.unit_name')}
                defaultValue={
                  massiveUnitModal.data.name ? massiveUnitModal.data.name : ''
                }
                onChange={(e) => {
                  massiveUnitModal.data.name = e.target.value;
                }}
                className="structure-input"
              />
            </Col>
            <Col span={4} offset={1}>
              <input
                type="number"
                min="1"
                placeholder={t('takt_units.init')}
                value={unitsRange.start}
                onChange={(e) => {
                  const start =
                    parseInt(e.target.value) < 0
                      ? parseInt(e.target.value) * -1
                      : parseInt(e.target.value);
                  if (e.target.value === '-') return;

                  massiveUnitModal.data.start = start == 0 ? 1 : start;
                  setUnitsRange({
                    ...unitsRange,
                    start: start == 0 ? 1 : start
                  });
                }}
                className="structure-input"
              />
            </Col>
            <Col span={4} offset={1}>
              <input
                placeholder={t('takt_units.finish')}
                min="1"
                type="number"
                value={unitsRange.end}
                onChange={(e) => {
                  const end =
                    parseInt(e.target.value) < 0
                      ? parseInt(e.target.value) * -1
                      : parseInt(e.target.value);
                  if (e.target.value === '-') return;

                  massiveUnitModal.data.end = end == 0 ? 1 : end;
                  setUnitsRange({ ...unitsRange, end: end == 0 ? 1 : end });
                }}
                className="structure-input"
              />
            </Col>
          </Row>
        </div>
      );
    }
  };

  const updateUnit = async () => {
    const res = await productionunitService.update({
      ...editUnitState.object,
      name: editUnitState.name
    });
    if (res) {
      editUnitState.object.name = editUnitState.name;
      setEditUnitState({ visible: false });
    }
  };

  return (
    <Animated
      animationIn="fadeIn"
      animationInDuration={500}
      isVisible={true}
      style={{
        height: height - 129,
        overflow: 'hidden',
        maxHeight: height - 129
      }}>
      {loaded ? (
        renderLoadedContent()
      ) : (
        <Spin className="loader-spinner-lookahead-header" indicator={antIcon} />
      )}

      {/** Creation structure modal */}
      <Modal
        wrapClassName="activity-modification-style"
        title={t('takt_units.modal_title')}
        visible={creationModal.visible}
        onCancel={() => setCreationModal({ ...creationModal, visible: false })}
        footer={[]}>
        <div className="modal-creation-structure">
          {renderModalCreationBody()}
        </div>
      </Modal>

      {/** Massive location modal */}
      <Modal
        wrapClassName="activity-modification-style"
        title={t('takt_units.locations_label')}
        visible={massiveStructureModal.visible}
        onCancel={() =>
          setMassiveStructureModal({ ...massiveStructureModal, visible: false })
        }
        footer={[
          <Button
            onClick={() => massiveStructureCreation()}
            key="submit"
            style={{
              background: '#7DFF8A',
              color: '#121212',
              borderColor: '#7DFF8A'
            }}>
            {t('takt_units.create_label')}
          </Button>
        ]}>
        {renderMassiveLocationCreationBody()}
      </Modal>

      {/** Massive unit modal */}
      <Modal
        wrapClassName="activity-modification-style"
        title={t('takt_units.production_units_label')}
        visible={massiveUnitModal.visible}
        onCancel={() =>
          setMassiveUnitModal({ ...massiveUnitModal, visible: false })
        }
        footer={[
          <Button
            onClick={() => massiveUnitCreation()}
            key="submit"
            style={{
              background: '#7DFF8A',
              color: '#121212',
              borderColor: '#7DFF8A'
            }}>
            {t('takt_units.create_label')}
          </Button>
        ]}>
        {renderMassiveUnitCreationBody()}
      </Modal>

      {/** edit unit modal */}
      <Modal
        wrapClassName="activity-modification-style"
        title={'Editar unidad'}
        visible={editUnitState.visible}
        onCancel={() => setEditUnitState({ ...editUnitState, visible: false })}
        footer={[
          <Button
            onClick={() => updateUnit()}
            key="submit"
            style={{
              background: '#7DFF8A',
              color: '#121212',
              borderColor: '#7DFF8A'
            }}>
            Actualizar
          </Button>
        ]}>
        <Col span={24}>
          <input
            placeholder="Nombre unidad"
            defaultValue={editUnitState.name ? editUnitState.name : ''}
            onChange={(e) => {
              editUnitState.name = e.target.value;
            }}
            className="structure-input"
          />
        </Col>
      </Modal>

      {/** delete warning modal */}
      <Modal
        wrapClassName="activity-modification-style"
        title={t('takt_units.sure')}
        visible={warningDelete.visible}
        onCancel={() =>
          setWarningDelete({
            visible: false,
            onOk: () => {},
            text: '',
            data: []
          })
        }
        footer={[
          <Button
            onClick={() => {
              if (warningDelete.text == t('takt_units.borrar')) {
                warningDelete.onOk(...warningDelete.data);
                setWarningDelete({
                  visible: false,
                  onOk: () => {},
                  text: '',
                  data: []
                });
              }
            }}
            key="submit"
            style={{
              background: '#7DFF8A',
              color: '#121212',
              borderColor: '#7DFF8A'
            }}>
            {t('takt_units.delete')}
          </Button>
        ]}>
        <Col span={24}>
          <div style={{ color: '#2C3421', textAlign: 'center' }}>
            {t('takt_units.deleting')} <u>{t('takt_units.assigned')}</u>
          </div>
          <div
            style={{
              color: '#2C3421',
              textAlign: 'center',
              marginTop: 7,
              marginBottom: 7
            }}>
            {t('takt_units.escribe')}{' '}
            <span style={{ color: '#7DFF8A' }}>{t('takt_units.borrar')}</span>{' '}
            {t('takt_units.rest')}
          </div>
          <div style={{ textAlign: 'center' }}>
            <span>
              <input
                style={{ width: '50%' }}
                placeholder={t('takt_units.borrar')}
                value={warningDelete.text}
                onChange={(e) =>
                  setWarningDelete({ ...warningDelete, text: e.target.value })
                }
                className="structure-input"
              />
            </span>
          </div>
        </Col>
      </Modal>
    </Animated>
  );
}

export default withTranslation()(UnitsView);
