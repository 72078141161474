/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class ScheduleUpdatesService {
  constructor() {
    this.http = createHttpClient();
  }

  async showBySector(sectorId) {
    const res = await this.http
      .get('scheduleupdates/sector/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async addScheduleUpdate(scheduleUpdate) {
    const res = await this.http
      .post('scheduleupdates/', scheduleUpdate)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const scheduleUpdatesService = new ScheduleUpdatesService();
