export const addSign = (number, type) => {
  const baseVariance = 0;
  const typeMapper = {
    'on schedule': '',
    overdue: '+',
    advancement: '-'
  };

  if (number === baseVariance) {
    return `${number}`;
  }

  return `${typeMapper[type]}${number}`;
};
