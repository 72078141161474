import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { ScheduleTimeIcon } from '../../../icons';
import Colors from '../../../assets/styles/variables.scss';

const DEFAULT_THEME = 'light';
const DEFAULT_SIZE = 'default';

const TableSystem = ({
  theme = DEFAULT_THEME,
  size = DEFAULT_SIZE,
  isNoMatchingRecords = false,
  ...props
}) => {
  const { t } = useTranslation();

  const { DARK_GRAY, CLEAR_BED_ROCK } = Colors;

  return (
    <Table
      {...props}
      className={`table-design-system theme--${theme} size--${size}`}
      locale={{
        emptyText: (
          <>
            <div>
              <ScheduleTimeIcon
                color={theme === DEFAULT_THEME ? DARK_GRAY : CLEAR_BED_ROCK}
              />
            </div>
            <span>
              {!isNoMatchingRecords
                ? t('tableSystem.empty_state_text')
                : t('tableSystem.no_matching_records_text')}
            </span>
          </>
        )
      }}
    />
  );
};

export default TableSystem;
