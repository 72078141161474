import React, { useState, useEffect } from 'react';
import { SectorResourcesService } from '../../../../services/sectorresource.service';
import { Icon, Tooltip } from 'antd';

/** PNG for task planification edit and save tasks */
import editIcon from '../../../../assets/img/EDT-DarkGrey-1080x1080.png';
import saveIcon from '../../../../assets/img/GRD-DarkGrey-1080x1080.png';

import { withTranslation } from 'react-i18next';

const EditableInput = (props) => {
  const { t } = props;
  const {
    service,
    column,
    task,
    index,
    updateState,
    renderEditable,
    isFloat,
    onSave,
    onEdit,
    symbol,
    disabled = false
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(task[column.name]);

  const updateParentData = (replaceValue = true) => {
    if (onSave) {
      onSave(value);
    } else {
      if (replaceValue) {
        task[column.name] = value;
      }
      setIsEditing(false);
      service.update(task);
    }
    onEdit(null);
  };

  const handleEsc = (e) => {
    if (e.keyCode === 27) {
      updateParentData(false);
    }
  };

  useEffect(() => {
    const focusedItem = column.name + task.id;
    const domElement = document.getElementById(focusedItem);
    if (domElement) {
      domElement.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (props.isEditing == column.name + task.id) {
      setIsEditing(true);
    }
  }, [props.isEditing]);

  if (isEditing) {
    return (
      <span>
        {renderEditable(
          column,
          index,
          task,
          value,
          setValue,
          updateParentData,
          handleEsc
        )}
        {symbol || null}
        <span
          onClick={updateParentData}
          style={{ marginLeft: 10, cursor: 'pointer' }}>
          <Tooltip placement="top" title={t('master_plan_resources.save')}>
            <img className="save-input-icon" src={saveIcon} width={10} />
          </Tooltip>
        </span>
      </span>
    );
  }
  return (
    <span>
      {task[column.name]
        ? isFloat
          ? task[column.name].toFixed(2)
          : task[column.name]
        : 0}
      {symbol || null}
      {disabled ? null : (
        <span
          onClick={() => {
            onEdit(column.name + task.id);
            setIsEditing(true);
          }}
          style={{ marginLeft: 10, cursor: 'pointer' }}>
          <Tooltip placement="top" title={t('master_plan_resources.edict')}>
            <img className="editable-input-icon" src={editIcon} width={10} />
          </Tooltip>
        </span>
      )}
    </span>
  );
};

export default withTranslation()(EditableInput);
