import { constraintStatus } from '../../../utils';

/** Import PNG for flag */
import flagGrey from '../../../assets/img/PR-Gray-50x50.png';
import flagBlue from '../../../assets/img/PR-Blue-50x50.png';
import flagYellow from '../../../assets/img/PR-Yellow-50x50.png';
import flagRed from '../../../assets/img/PR-Red-50x50.png';

// export const constraintStatus = [
//     { value: 'released', label: 'Liberada', visible: true },
//     { value: 'notreleased', label: 'No Liberada', visible: false },
//     { value: 'expired', label: 'Vencida', visible: false },
//     { value: 'notcompromises', label: 'Sin compromiso', visible: false }
// ];

/** Metadata for table Constraints */
export const tableMetadata = [
  {
    name: 'Name',
    label: 'name',
    data_type: 'string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'left',
    width: 200,
    from_values: constraintStatus,
    groupable: false,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true
  },
  /* {
        name: 'proplanner',
        label: 'dfd',
        data_type: 'string',
        visible: true,
        span: 2,
        offset: 0,
        align: 'left',
        width: 150,
        from_values: constraintStatus,
        groupable: false,
        orderable: true,
        orderable_switch: ['A → Z', 'Z → A'],
        filterable: true
    }, */
  {
    name: 'GroupDescription',
    label: 'subcontratos',
    data_type: 'string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'left',
    width: 150,
    from_values: constraintStatus,
    groupable: false,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true
  },
  {
    name: 'PositionDescription',
    label: 'cargo',
    data_type: 'string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'left',
    width: 150,
    from_values: constraintStatus,
    groupable: false,
    orderable: true,
    orderable_switch: ['A → Z', 'Z → A'],
    filterable: true
  },
  {
    name: '1',
    label: '1',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '2',
    label: '2',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '3',
    label: '3',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '4',
    label: '4',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '5',
    label: '5',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '6',
    label: '6',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '7',
    label: '7',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '8',
    label: '8',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '9',
    label: '9',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '10',
    label: '10',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '11',
    label: '11',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '12',
    label: '12',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '13',
    label: '13',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '14',
    label: '14',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '15',
    label: '15',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '16',
    label: '16',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '17',
    label: '17',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '18',
    label: '18',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '19',
    label: '19',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '20',
    label: '20',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '21',
    label: '21',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '22',
    label: '22',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '23',
    label: '23',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '24',
    label: '24',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '25',
    label: '25',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '26',
    label: '26',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '27',
    label: '27',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '28',
    label: '28',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '29',
    label: '29',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '30',
    label: '30',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  },
  {
    name: '31',
    label: '31',
    data_type: 'array/string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'center',
    width: 40,
    groupable: true,
    from_values: [],
    groupable: false,
    orderable: false,
    filterable: false
  }
];
