/* eslint-disable prefer-const */

/** React components  */
import React, { useState, useEffect, Fragment } from 'react';

/** Import elements from library Antd */
import { Upload, Icon, Modal, Row, Col, Select, Input, Button } from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons';

/** import common functions from helper */
import {
  asignConstraintTask,
  asignProjectSubcontract,
  asignProjectUser,
  buildViews,
  createBaseCalendarFromCalendar,
  createCalendar,
  createConstraint,
  createExceptionDay,
  createProject,
  createSector,
  createTask,
  getBodyForNewBaseline,
  getCompany,
  getDatesIni,
  getProject,
  stateTemplate,
  stateTemplateDummy,
  updateProject,
  updateSector
} from '../../../views/project/project.helper';

import moment from 'moment';

/** import common functions from utils */
import {
  checkAllFieldsValid,
  reduceFormValues,
  userType,
  setAllProjectsGlobal,
  codeCountries,
  Types,
  generateFormObjectSelect,
  openNotification,
  projectCurrency,
  projectSizesTypes,
  compareValues,
  ListElement,
  getBase64,
  headersSendForm,
  ObjectLogoSvg,
  makeSlug,
  getTodayWeekWithPday,
  getTodayWeekWithPdayAndCurrentDay
} from '../../../utils';

/** import Services */
import {
  activityService,
  activityRelationService,
  userService,
  constraintTypeService,
  cncTypeService,
  tagService,
  subContractService,
  ganttService,
  workingdayService,
  calendarService,
  taskService,
  weekCommitmentService,
  taskCommitmentService,
  sectorBaselineVersionService,
  sectorBaselinePointService
} from '../../../services';

/** import base contant (setting urls)  */
import { base } from '../../../services/base';

/** Drag and Drop */
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

/** import library for antd upload crop  */
import ImgCrop from 'antd-img-crop';

import ErrorValidationLabel from '../../Login/ValidationLabel';

/** Redux */
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../../redux/slices/projectSlice';
import { companyActions } from '../../../redux/actions/companyActions';
import { withTranslation } from 'react-i18next';
import {
  dummyProject,
  dummySectors,
  dummyConstraintTypes,
  dummyCncTypes,
  dummyTags,
  dummySubContracts,
  dummyUsers,
  defaultCncTypes,
  defaultConstraintTypes,
  dummyExceptions,
  dummyActivitys,
  dummyActivityRelations,
  dummyCalendar,
  dummyTasks,
  dummyConstraints,
  dummyWeekCommitment,
  dummyCncs
} from './dummyData';
import {
  getEndDateByGantt,
  transformDateWithHour
} from '../../../utils/lookahead-common';
import { ganttAPI } from '../../../utils/customGanttPlugin';
import { historicalActivityProgressService } from '../../../services/historicalactivityprogress.service';
import { createCnc } from '../../../views/weeklyPlan/weeklyPlan.helper';
import { getSessionTokenData } from '../../../utils/userUtils';

/** constants */
const { Option } = Select;

function FormProjectsDummy(props) {
  const {
    t,
    setFormProjectVisibleDummy,
    formProjectVisibleDummy,
    isDummy = true
  } = props;
  const SUPERADMIN = userType(t).find((el) => el.value === 'superadmin');
  /** redux */
  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.projectState);

  /** GET ID COMPANY CURRENT FROM REDUX */
  const stateCompany = useSelector((state) => state.companyState);
  const currentCompany = stateCompany.currentCompany;
  const idCurrentCompany = stateCompany.currentCompany?.id;

  /** hooks */
  const [noSector, setNoSector] = useState(false);
  const [sectorsProject, setSectorsProject] = useState([]);
  const [sectorsOriginal, setSectorsOriginal] = useState([]);
  const [sectorName, setSectorName] = useState(null);
  const [projectId, setProjectId] = useState({});
  const [state, setState] = useState(stateTemplateDummy); // validation
  const [filter] = useState('stage'); // handle filter option ()
  const [stateUpload, setStateUpload] = useState({ loading: false });

  const [projectPname, setProjectPname] = useState(''); // handle Pname
  const [projectType, setProjectType] = useState(); // handle Project Type
  const [projectSizeType, setProjectSizeType] = useState(); // hook for select timezone
  const [projectSize, setProjectSize] = useState(); // hook for select timezone
  const [projectPcurrency, setProjectPcurrency] = useState('USD'); // hook for select Country
  const [projectBudget, setProjectBudget] = useState(); // hook for select Country
  const [projectPcountry, setProjectPcountry] = useState('CL'); // handle Project Country
  const [isLoading, setIsLoading] = useState(false); // Btn Loading

  const [currentProject, setCurrentProject] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentGantt, setCurrentGantt] = useState();
  const [text, setText] = useState({
    text: ''
  });

  useEffect(() => {
    resetModalAddProject();
    if (projectState.props.currentProjectId) {
      ShowEditProject(projectState.props.currentProjectId);
    }
  }, [projectState]);

  /** load data */
  useEffect(() => {
    loadGantt();
  }, []);

  const getGantt = async () => {
    const gannt = await ganttService.showBySector(projectState.sectorSelected);
    return gannt;
  };

  const loadGantt = async () => {
    const gantt = await getGantt();
    if (gantt) {
      if (gantt?.gantt?.id) {
        setCurrentGantt(gantt.gantt.id);
      }
    }
  };

  /**
   * This function open dialog for edit project
   * @param {*} projectId Id of the project to edit
   */
  const ShowEditProject = async (projectId) => {
    const project = await getProject(projectId);
    setCurrentProject(project);
    setProjectPname(project.name);
    setProjectType(project.type);
    setProjectPcountry(project.country);
    setProjectSizeType(project.sizetype);
    setProjectSize(project.size);
    setProjectBudget(project.budget);
    setProjectPcurrency(project.currency);
    setProjectId(project.id);

    /** load avatar for current project */
    if (project.image) {
      setStateUpload({
        ...stateUpload,
        imageUrl: `${project.image}`
      });
    }

    const sectorsFiltered = project.sectors.filter((e) => e.status !== false);
    if (sectorsFiltered) {
      sectorsFiltered.sort(compareValues('order'));
    }
    setSectorsProject(sectorsFiltered);
    setSectorsOriginal(sectorsFiltered);
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const sessionTokenData = getSessionTokenData();

  /** Refresh projects list */
  const updateProjectsList = (arr, filterParam = 'stage') => {
    /** Redux event emitting function to set projects load from project actions */
    // dispatch(projectActions.setAllProjects(arr))
    setAllProjectsGlobal(arr, dispatch, projectActions, projectState);
  };

  const [selectedData, setSelectedData] =
    useState(null); /** state for select data item */

  /** Logic Drag Stages */
  /**
   *  This function create a Element of a List Sortable
   */
  const SortableItem = SortableElement((props) => {
    const value = props.value;
    const index = props.i;
    return (
      <Fragment>
        <ListElement>
          <Row className="listElement" key={value.id}>
            <Col span={24}>
              <div className="element">{value.name}</div>
            </Col>
          </Row>
        </ListElement>
        <DeleteOutlined
          className="deleteElement"
          onClick={() => {
            setIsModalVisible(true);
            setSelectedData({
              value: value,
              index: index
            });
          }}
        />
      </Fragment>
    );
  });

  const renderModalConfirmDelete = () => (
    <Modal
      wrapClassName="activity-modification-style"
      title={t('settings.project_form.sectors.sure')}
      visible={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false);
        text.text = '';
      }}
      footer={[
        <Button
          onClick={(e) => {
            e.preventDefault();
            if (sectorsProject.length == 1) {
              setNoSector(true);
              setIsModalVisible(false);
              return;
            }
            setNoSector(false);

            if (text.text === t('settings.project_form.sectors.borrar')) {
              deleteSector(selectedData.value, selectedData.index);
              setIsModalVisible(false);
              text.text = '';
            }
          }}
          key="submit"
          style={{
            background: '#7DFF8A',
            color: '#121212',
            borderColor: '#7DFF8A'
          }}>
          {t('settings.project_form.sectors.delete')}
        </Button>
      ]}>
      <Col span={24}>
        <div style={{ color: '#2C3421', textAlign: 'center' }}>
          {t('settings.project_form.sectors.deleting_sector')}
        </div>
        <div
          style={{
            color: '#2C3421',
            textAlign: 'center',
            marginTop: 7,
            marginBottom: 7
          }}>
          {t('settings.project_form.sectors.escribe')}{' '}
          <span style={{ color: '#7DFF8A' }}>
            {t('settings.project_form.sectors.borrar')}
          </span>{' '}
          {t('settings.project_form.sectors.rest_sector')}
        </div>
        <div style={{ textAlign: 'center' }}>
          <span>
            <input
              style={{ width: '50%' }}
              defaultValue={text.text}
              type="text"
              onChange={(e) => {
                text.text = e.target.value;
              }}
              className="structure-input"
            />
          </span>
        </div>
      </Col>
    </Modal>
  );

  // sectors
  const deleteSector = (e, i) => {
    const filteredItems =
      e.id !== undefined
        ? sectorsProject.filter((el) => el.id !== e.id)
        : sectorsProject.filter((e, index) => index !== i);
    setSectorsProject(filteredItems);
  };

  /** Services for projects */
  const resetModalAddProject = () => {
    setStateUpload({ loading: false });
    setProjectPname();
    setProjectType();
    setProjectPcountry();
    setProjectSizeType();
    setProjectSize('');
    setSectorName();
    setProjectBudget('');
    setProjectPcurrency('USD');
    setSectorsProject([]);
    setNoSector(false);
    setState(stateTemplate);
  };

  // validation project
  const { pname, country } = state;

  const renderPnameValidationError = pname.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={
        pname.typeMismatch ? pname.formatErrorTxt : t('confirmation.name_req')
      }
    />
  );

  const renderCountryValidationError = country.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={
        country.typeMismatch
          ? country.formatErrorTxt
          : t('confirmation.country_req')
      }
    />
  );

  const ProjectIcon = (props) => <Icon component={ObjectLogoSvg} {...props} />;
  const uploadButton = (
    <div>
      {stateUpload.loading ? (
        <LoadingOutlined />
      ) : (
        <ProjectIcon className="FrmCompany" />
      )}
    </div>
  );

  /** this function is triggered when upload image */
  const onChangeUpload = (info) => {
    if (info.file.status === 'uploading') {
      setStateUpload({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response
      getBase64(info.file.originFileObj, (imageUrl) =>
        setStateUpload({
          imageUrl,
          name: info.file.response.name,
          loading: false
        })
      );
    }
  };

  const updateHistorical = async (activityId, progress) => {
    const newActivityHistorical = {
      activityId: activityId,
      progress: progress
    };
    await historicalActivityProgressService.create(newActivityHistorical);
  };

  const getWeek = (week_current, week) =>
    moment()
      .day(1)
      .week(parseInt(week_current[0]) + (parseInt(week) - 3));

  // logic
  // Submit create Project
  const onSubmitCreateProject = async (e) => {
    e.preventDefault();

    const isEdit = Boolean(projectState.props.currentProjectId);
    const form = e.target;
    const formValues = reduceFormValues(form.elements, state);

    // add selects to validation
    formValues.country = generateFormObjectSelect(
      'country',
      'Country',
      projectPcountry
    );

    const allFieldsValid = checkAllFieldsValid(formValues);
    setState({ ...formValues, allFieldsValid });

    if (allFieldsValid && user.id) {
      setIsLoading(true);
      const companyId = sessionTokenData.companyId;
      let data = {
        companyId: companyId,
        image: stateUpload.name
          ? stateUpload.name
          : null /** save image uploaded */
      };
      if (isDummy) {
        data = {
          ...data,
          ...dummyProject,
          name: formValues.pname.value,
          country: formValues.country.value
        };
      }
      const alert = {
        title: t('form.project'),
        description: isEdit ? t('form.project_edit') : t('form.project_add'),
        type: 'success'
      };
      if (!isEdit) {
        // new project
        const projectNew = await createProject(data);
        if (projectNew.id) {
          /** add constraint types */
          const asyncMapDefaultConstraintTypes = defaultConstraintTypes(
            props.t
          ).map(async (constraintType) => {
            await constraintTypeService.create({
              ...constraintType,
              projectId: projectNew.id,
              description: '' /** is required */
            });
          });
          await Promise.all(asyncMapDefaultConstraintTypes);
          /** add cnc types */
          const asyncMapDefaultCncTypes = defaultCncTypes(props.t).map(
            async (cncType) => {
              await cncTypeService.create({
                ...cncType,
                projectId: projectNew.id,
                description: '' /** is required */
              });
            }
          );
          await Promise.all(asyncMapDefaultCncTypes);
          // assign to user
          await asignProjectUser({
            userId: user.id,
            projectId: projectNew.id,
            isCreator: true
          });
          let sectorsArr = sectorsProject;
          let realUsers;
          let realTags = [];
          let realTasks = [];
          let realTypeConstraints = [];
          let realTypeCncs = [];
          let realSubcontracts = [];
          let realActivitys;
          let realProjectId = projectNew.id;
          if (isDummy) {
            sectorsArr = dummySectors;
            /** add constraint types */
            const asyncMapConstraintTypes = dummyConstraintTypes.map(
              async (constraintType) => {
                const newType = await constraintTypeService.create({
                  ...constraintType,
                  projectId: projectNew.id,
                  description: '' /** is required */
                });
                // constraintType
                realTypeConstraints.push({
                  ...newType,
                  idStr: constraintType.idStr
                });
              }
            );
            await Promise.all(asyncMapConstraintTypes);

            /** add cnc types */
            const asyncMapCcncTypes = dummyCncTypes.map(async (cncType) => {
              const newTypeCnc = await cncTypeService.create({
                ...cncType,
                projectId: projectNew.id,
                description: '' /** is required */
              });
              realTypeCncs.push({
                ...newTypeCnc,
                idStr: cncType.idStr
              });
            });
            await Promise.all(asyncMapCcncTypes);

            /** add tags */
            const asyncMapTag = dummyTags.map(async (tag) => {
              const tagCreate = await tagService.create({
                ...tag,
                projectId: projectNew.id
              });
              realTags.push({
                ...tag,
                id: tagCreate.id
              });
            });
            await Promise.all(asyncMapTag);

            /** add subcontract */
            /** det all subcontracts */
            const response =
              await subContractService.getByCompany(idCurrentCompany);
            let allSubcontracts;
            if (response) {
              allSubcontracts = response.subcontracts;
            }

            dummySubContracts.map(async (sc) => {
              /** validate name */
              const existName = allSubcontracts.filter(
                (el) =>
                  el.name.toLowerCase().trim() === sc.name.toLowerCase().trim()
              );
              if (!existName.length) {
                const subcontract = await subContractService.create({
                  ...sc,
                  companyId: idCurrentCompany
                });
                /** assign */
                if (subcontract.id) {
                  realSubcontracts.push({
                    ...sc,
                    id: realSubcontracts.id
                  });
                  await asignProjectSubcontract({
                    subcontractId: subcontract.id,
                    projectId: projectNew.id
                  });
                }
              } else {
                // realSubcontracts = allSubcontracts
                realSubcontracts.push({
                  ...sc,
                  id: existName[0].id
                });
                /** assign */
                await asignProjectSubcontract({
                  subcontractId: existName[0].id,
                  projectId: projectNew.id
                });
              }
            });

            /** add users */
            let userStorage = JSON.parse(localStorage.getItem('user'));
            const userLang = navigator.language || navigator.userLanguage;
            const rowsUsers = dummyUsers.map((el) => {
              const newEmail = el.email.replace(
                '@',
                '-' + makeSlug(currentCompany.name, true) + '@'
              );
              return {
                ...el,
                email: newEmail,
                companyId: idCurrentCompany,
                projectId: projectNew.id
              };
            });

            const dataUsers = {
              sender_user: {
                name: userStorage.name,
                image: userStorage.image,
                name_company: currentCompany.name,
                image_company: currentCompany.image,
                name_project: projectNew.name
              },
              link: base.front + 'confirmation/',
              users: rowsUsers,
              lang: userLang.includes('es') ? 'es' : 'en'
            };

            const usersCompany =
              await userService.getByCompany(idCurrentCompany);
            const inviteResponse = await userService.inviteUsers(dataUsers);
            if (inviteResponse.users) {
              realUsers = inviteResponse.users;
              inviteResponse.users.map(async (user) => {
                /** assign user to project */
                await asignProjectUser({
                  userId: user.id,
                  projectId: projectNew.id,
                  isCreator: false
                });
              });
            } else {
              if (usersCompany.users) {
                realUsers = usersCompany.users;
                rowsUsers.map(async (user) => {
                  const findUser = usersCompany.users.find(
                    (el) => el.email === user.email
                  );
                  if (findUser) {
                    await asignProjectUser({
                      userId: findUser.id,
                      projectId: projectNew.id,
                      isCreator: false
                    });
                  }
                });
              }
            }
          }

          let lastSector;
          const asyncMap = sectorsArr.map(async (sn, index) => {
            const findEl = sectorsOriginal.find((e) => sn.id === e.id);
            if (!findEl) {
              data = {
                name: sn.name,
                projectId: projectNew.id,
                order: index
              };
              if (isDummy) {
                data = {
                  ...sn,
                  projectId: projectNew.id
                };
              }
              const tmpLastSector = await createSector(data);
              if (sn.order === 0) {
                lastSector = tmpLastSector;
                const copy_sector = { ...lastSector };
                copy_sector.cncs = [];
                copy_sector.constraints = [];
                copy_sector.weekcommitments = [];
                localStorage.setItem(
                  'currentSector',
                  JSON.stringify(copy_sector)
                );
              }
            }
          });
          await Promise.all(asyncMap);

          if (isDummy) {
            // lastSector
            /** create gantt */
            const ganttRes = await ganttService.create({
              activities: 0,
              activities_relation_size: 0,
              is_impoorter: false,
              sectorId: lastSector.id
            });

            /** calendars */
            const dataCalendar = {
              ...dummyCalendar,
              sectorId: lastSector.id,
              ganttId: ganttRes.id,
              userId: user.id,
              unique_id: new Date().getTime()
            };

            const calendarCreated = await createCalendar(dataCalendar);
            if (calendarCreated.id) {
              const workingDay = {
                shift_string:
                  'false,06:00,06:00,06:00,06:00,06:00,false-false,14:00,14:00,14:00,14:00,14:00,false',
                correlative_id: 0,
                calendarId: calendarCreated.id
              };
              await workingdayService.create(workingDay);

              /** exceptions */
              dummyExceptions.forEach(async (exc) => {
                const data = {
                  ...exc,
                  calendarId: calendarCreated.id
                };
                await createExceptionDay(data);
              });
            }

            /** loada calendars to gantt plugin */
            const calendars = await calendarService.showBySector(lastSector.id);
            ganttAPI.loadCalendars(calendars.calendar);

            /** dates initial */
            const week_current = getTodayWeekWithPdayAndCurrentDay(
              null,
              moment(new Date(), 'YYYY-MM-DD'),
              dummyProject.planification_day
            );
            const week_arr = [];
            let range43 = Array.from(Array(44).keys());
            range43.forEach((el) => {
              week_arr[el] = getWeek(week_current, el);
            });
            const dates_ini = getDatesIni(week_arr);

            /** get real project's users */
            dummyActivitys.map((el) => {
              el.start_date = dates_ini[el.start_date] || el.start_date;

              /** calculate end date */
              const res = ganttAPI.getEndByDuration(
                el.start_date,
                el.duration,
                el.id,
                calendarCreated.id
              );

              // el.duration = res.duration
              el.end_date = res.end_date;

              const dateStr = el.start_date.toString().split(' ').shift();
              let timeStr = '06:00';
              if (el.name === 'Construction Start') {
                timeStr = '13:00';
              }
              el.start_date = dateStr + ' ' + timeStr;

              /** assing users */
              if (realUsers) {
                const arr_new_users = [];
                el.responsables.forEach((resp) => {
                  /** find in real users */
                  const wordFindInEmailArr = resp.email.split('@');
                  if (wordFindInEmailArr) {
                    const find = realUsers.find((realUs) =>
                      realUs.email.includes(wordFindInEmailArr[0])
                    );
                    if (find) {
                      arr_new_users.push(find);
                    }
                  }
                });
                el.responsables = arr_new_users;
              }

              /** assing tags */
              if (realTags) {
                const arr_new_tags = [];
                el.tags.forEach((resp) => {
                  /** find in real tags */
                  const wordFindInArr = resp.idStr;
                  if (wordFindInArr) {
                    const find = realTags.find(
                      (real) => real.idStr === wordFindInArr
                    );
                    if (find) {
                      arr_new_tags.push(find);
                    }
                  }
                });
                el.tags = arr_new_tags;
              }

              /** assing subcontracts */
              if (realSubcontracts) {
                let arr_new_sc;
                const wordFind = el.subcontract;
                if (wordFind) {
                  const find = realSubcontracts.find(
                    (real) => real.idStr === wordFind
                  );
                  if (find) {
                    arr_new_sc = find;
                  }
                }
                if (arr_new_sc?.id) {
                  el.subcontractId = arr_new_sc.id;
                }
              }

              /** assign calendar */
              el.calendarId = calendarCreated.id;

              /** return el modified */
              return el;
            });
            /** create activitys */
            const actList = dummyActivitys.map((el) => ({
              ...el,
              sectorId: lastSector.id,
              ganttId: ganttRes.id
            }));
            realActivitys = await activityService.createAll(
              actList,
              lastSector.id
            );

            /** create links */
            const actRel = dummyActivityRelations.map((el) => ({
              ...el,
              sectorId: lastSector.id,
              ganttId: ganttRes.id
            }));
            activityRelationService.createAll(actRel);

            /** create baseline */
            /** Creates a new baseline version object */
            const newBaselineVersion = {
              create_date: moment().format('YYYY/MM/DD H:mm'),
              name: 'Updated Baseline',
              description: 'Demo BL',
              creatorId: user.id,
              creator: user,
              sectorId: lastSector.id,
              visible: true,
              active: true,
              accumulatedDuration: 3333,
              saveOption: 1
            };
            const resVersion =
              await sectorBaselineVersionService.create(newBaselineVersion);
            if (resVersion) {
              const hashTable = await createBaseCalendarFromCalendar(
                resVersion,
                calendars.calendar,
                undefined
              );
              const bodyForNewBaselinePoints = getBodyForNewBaseline(
                hashTable,
                resVersion,
                realActivitys
              );
              const resPoints =
                await sectorBaselinePointService.createAllBaselines(
                  bodyForNewBaselinePoints
                );
            }

            /** create tasks */
            const asyncMapTasks = dummyTasks.map(async (task) => {
              const findActivity = realActivitys.find(
                (el) => el.correlative_id === task.activityCorrelativeId
              );
              if (findActivity) {
                /** replace start date */
                task.start_date = dates_ini[task.start_date];
                const taskCreated = await createTask(null, task, findActivity);
                realTasks.push({
                  ...task,
                  id: taskCreated.id,
                  taskbd: taskCreated
                });
              }
            });
            await Promise.all(asyncMapTasks);

            /** create historical progress */
            const findActivity_act2 = realActivitys.find(
              (el) => el.correlative_id === 2
            );
            if (findActivity_act2) {
              await updateHistorical(findActivity_act2.id, 25);
              await updateHistorical(findActivity_act2.id, 45);
              await updateHistorical(findActivity_act2.id, 50);
            }

            const findActivity_act3 = realActivitys.find(
              (el) => el.correlative_id === 3
            );
            if (findActivity_act3) {
              await updateHistorical(findActivity_act3.id, 20);
              await updateHistorical(findActivity_act3.id, 40);
              await updateHistorical(findActivity_act3.id, 60);
              await updateHistorical(findActivity_act3.id, 80);
            }

            const findActivity_act4 = realActivitys.find(
              (el) => el.correlative_id === 4
            );
            if (findActivity_act4) {
              await updateHistorical(findActivity_act4.id, 18.75);
              await updateHistorical(findActivity_act4.id, 27);
            }

            /** create view */
            buildViews(realProjectId, lastSector.id);

            /** create constraints */
            dummyConstraints.map(async (objConstraint) => {
              const findTypeConstraint = realTypeConstraints.find(
                (el) => el.idStr === objConstraint.type
              );

              let emailResponsable, nameResponsable;
              const wordFindInEmailArr = objConstraint.user.split('@');
              if (wordFindInEmailArr) {
                const find = realUsers.find((realUs) =>
                  realUs.email.includes(wordFindInEmailArr[0])
                );
                if (find) {
                  objConstraint.responsable = find.id;
                  emailResponsable = find.email;
                  nameResponsable = find.name + ' ' + find.lastname;
                }
              }

              if (findTypeConstraint) {
                const userLang = navigator.language || navigator.userLanguage;

                const data = {
                  name: objConstraint.name,
                  priority: objConstraint.priority,
                  status: objConstraint.status,
                  constraintTypeId: findTypeConstraint.id,
                  userId: objConstraint.responsable,
                  commitmentDate: dates_ini[objConstraint.commitmentDate],
                  sectorId: lastSector.id,
                  identify_user: user.id,
                  email: emailResponsable,
                  link:
                    base.front +
                    'lookahead/constraints/' +
                    projectNew.id +
                    '/' +
                    lastSector.id,
                  projectName: dummyProject.name,
                  sectorName: lastSector.name,
                  userName: nameResponsable,
                  lang: userLang.includes('es') ? 'es' : 'en',
                  release_date: null
                };
                if (objConstraint.status) {
                  data.release_date = dates_ini[objConstraint.commitmentDate];
                }
                data.idsendmail = 0;
                const constraintCreated = await createConstraint(data);

                if (constraintCreated) {
                  if (objConstraint.tasks) {
                    const syncMap = objConstraint.tasks.map(async (task) => {
                      const findTask = realTasks.find(
                        (el) => el.idStr === task
                      );
                      if (findTask) {
                        await asignConstraintTask({
                          constraintId: constraintCreated.id,
                          taskId: findTask.id
                        });
                      }
                    });
                    await Promise.all(syncMap);
                  }
                }
              }
            });

            let realWeekCommitments = [];

            /** create weekcommitment */
            const asyncWeekCommitments = dummyWeekCommitment.map(
              async (weekCommitment) => {
                let week_number;
                let week_start;
                let week_end;
                if (weekCommitment.week === 'week_1') {
                  const week_current_1 = getTodayWeekWithPdayAndCurrentDay(
                    null,
                    moment(new Date(), 'YYYY-MM-DD').subtract(14, 'days'),
                    dummyProject.planification_day
                  );
                  week_number = week_current_1[0];
                  week_start = week_current_1[2].start;
                  week_end = week_current_1[2].end;
                }
                if (weekCommitment.week === 'week_2') {
                  const week_current_2 = getTodayWeekWithPdayAndCurrentDay(
                    null,
                    moment(new Date(), 'YYYY-MM-DD').subtract(7, 'days'),
                    dummyProject.planification_day
                  );
                  week_number = week_current_2[0];
                  week_start = week_current_2[2].start;
                  week_end = week_current_2[2].end;
                }
                if (weekCommitment.week === 'week_3') {
                  week_number = week_current[0];
                  week_start = week_current[2].start;
                  week_end = week_current[2].end;
                }
                const dataWeeklyPlan = {
                  ...weekCommitment,
                  week: week_number,
                  start_date: moment(week_start),
                  end_date: moment(week_end),
                  sectorId: lastSector.id,
                  closed: true
                };
                const newWeeklyPlan =
                  await weekCommitmentService.create(dataWeeklyPlan);
                if (newWeeklyPlan.id) {
                  const tmpTaskCommitment = [];
                  const syncMap = weekCommitment.tasks.map(async (task) => {
                    const findTask = realTasks.find(
                      (el) => el.idStr === task.idStr
                    );
                    if (findTask) {
                      const dataTaskCommitment = {
                        commitment_percentaje: task.commitment,
                        realized_percentaje: task.realized,
                        userId: user.id,
                        weekcommitmentId: newWeeklyPlan.id,
                        current_progress_task: 0,
                        current_commitment_partial: 0,
                        taskId: findTask.id,
                        is_last_level: true,
                        // start_date: findTask.start_date,
                        start_date: week_start,
                        end_date: week_end,
                        duration: findTask.duration,
                        total_quantity: findTask.total_quantity,
                        plan_endowment: findTask.plan_endowment,
                        subcontractId: findTask.subcontractId,
                        materialId: findTask.materialId,
                        name: findTask.name
                      };
                      const newTaskCommitment =
                        await taskCommitmentService.create(dataTaskCommitment);

                      tmpTaskCommitment.push({
                        ...task,
                        id: newTaskCommitment.id,
                        taskId: findTask.id
                      });

                      /** update task only current */
                      if (weekCommitment.week === 'week_3') {
                        const taskUpdate = findTask.taskbd;
                        taskUpdate.responsables = [];
                        taskUpdate.lean_status = 'Committed';
                        /** exception for first review */
                        if (taskUpdate.name !== 'First Review') {
                          // eslint-disable-next-line dot-notation
                          taskUpdate.start_date = dates_ini['week_3_2'];
                        }
                        const findActivity = realActivitys.find(
                          (el) =>
                            el.correlative_id === findTask.activityCorrelativeId
                        );
                        getEndDateByGantt(taskUpdate, findActivity);
                        await taskService.update(taskUpdate);
                      }
                    }
                  });
                  await Promise.all(syncMap);

                  realWeekCommitments.push({
                    ...weekCommitment,
                    weekid: newWeeklyPlan.id,
                    tasks: tmpTaskCommitment
                  });
                }
              }
            );
            await Promise.all(asyncWeekCommitments);

            /** create cnc */
            dummyCncs.map((cnc) => {
              const cncType = realTypeCncs.find((el) => el.idStr === cnc.type);

              let week_save, year_save;
              if (cnc.week === 'week_1') {
                const week_current_1 = getTodayWeekWithPdayAndCurrentDay(
                  null,
                  moment(new Date(), 'YYYY-MM-DD').subtract(14, 'days'),
                  dummyProject.planification_day
                );
                week_save = week_current_1[0];
                year_save = week_current_1[1];
              }
              if (cnc.week === 'week_2') {
                const week_current_2 = getTodayWeekWithPdayAndCurrentDay(
                  null,
                  moment(new Date(), 'YYYY-MM-DD').subtract(7, 'days'),
                  dummyProject.planification_day
                );
                week_save = week_current_2[0];
                year_save = week_current_2[1];
              }
              if (cnc.week === 'week_3') {
                const week_current_3 = getTodayWeekWithPdayAndCurrentDay(
                  null,
                  moment(new Date(), 'YYYY-MM-DD'),
                  dummyProject.planification_day
                );
                week_save = week_current_3[0];
                year_save = week_current_3[1];
              }

              const dataCnc = {
                name: cnc.name,
                priority: cnc.priority,
                week: week_save,
                year: year_save,
                status: cnc.status,
                create_date: moment(),
                cncTypeId: cncType.id,
                userId: user.id,
                sectorId: lastSector.id,
                image: null
              };

              /** build tasks for cnc */
              const arrTasks = [];
              cnc.tasks.map((cnctask) => {
                const findWeek = realWeekCommitments.find(
                  (el) => el.week === cnc.week
                );
                const findTaskCommitment = findWeek.tasks.filter(
                  (el) => el.realized < el.commitment
                );
                if (findTaskCommitment.length) {
                  findTaskCommitment.map((tc) => {
                    const dataTask = {
                      taskId: tc.taskId,
                      commitment_percentaje: 100,
                      realized_percentaje: 90,
                      taskcommitmentid: tc.id,
                      weekcommitmentid: findWeek.weekid
                    };
                    arrTasks.push(dataTask);
                  });
                }
              });
              const data = {
                cnc: dataCnc,
                tasks: arrTasks
              };
              createCnc(data);
            });
          }

          /** users */
          /** get users of company */

          const usersCompany = await userService.getByCompany(idCurrentCompany);
          // assign users to project
          if (usersCompany.users) {
            usersCompany.users.map(async (us) => {
              if (us.role === SUPERADMIN.value && user.id !== us.id) {
                await asignProjectUser({
                  userId: us.id,
                  projectId: projectNew.id,
                  isCreator: false
                });
              }
            });
          }
        }
      } else {
        // edit project
        data.stage = currentProject.stage;
        data.id = currentProject.id;
        await updateProject(data);

        sectorsOriginal.map(async (so, index) => {
          const findEl = sectorsProject.find((e) => so.id === e.id);
          if (!findEl) {
            // delete sector
            if (so.id) {
              await updateSector({
                id: so.id,
                name: so.name,
                projectId: so.projectId,
                status: false
              });
            }
          } else {
            const elemOrder = sectorsProject.findIndex((e) => e.id === so.id);
            if (so.id !== undefined) {
              // update order
              await updateSector({
                id: so.id,
                name: so.name,
                projectId: so.projectId,
                order: elemOrder,
                status: true
              });
            }
          }
        });
        sectorsProject.map(async (sn, index) => {
          const findEl = sectorsOriginal.find((e) => sn.id === e.id);
          if (findEl !== undefined && !findEl.id) {
            data = {
              name: sn.name,
              projectId: projectId,
              order: index
            };
            await createSector(data);
          }
        });
      }

      let status; // waiting aws
      while (data.image && status !== 200) {
        const response = await fetch(data.image);
        status = response.status;
      }
      const company = await getCompany(companyId);
      if (company) {
        dispatch(companyActions.setCurrentCompany(company));
      }
      const getProjects = await userService.projectsbyuserthrough(user.id);
      const projectsActive = getProjects.projects.filter(
        (e) => e.stage !== 'deleted'
      );
      updateProjectsList(projectsActive, filter);
      setFormProjectVisibleDummy(false);
      setIsLoading(false);
      // refresh list projects
      setCurrentProject({});
      setStateUpload({ loading: false });
      setState(stateTemplate);
      await setTimeout(() => {
        resetModalAddProject();
        dispatch(
          projectActions.setProps({
            ...projectState.props,
            currentProjectId: null,
            openFormProject: false,
            isDummy: false
          })
        );
        openNotification(alert);
      }, 300);
    }
  };

  /** render */
  return (
    <Modal
      title=""
      visible={formProjectVisibleDummy}
      centered
      onCancel={() => {
        resetModalAddProject();
        dispatch(
          projectActions.setProps({
            ...projectState.props,
            currentProjectId: null,
            openFormProject: false,
            isDummy: false
          })
        );
        setFormProjectVisibleDummy(false);
      }}
      width={1200}
      footer={[]}>
      <div className="titleWideProject">
        {currentProject.id
          ? t('project_create.title_edict')
          : t('project_create.title_create')}
      </div>
      <div className="divSection formMiddle">
        <Fragment>
          <form
            className="frmWide"
            onSubmit={onSubmitCreateProject}
            noValidate
            id="frmAddProject">
            <Row>
              <Col span={24}>
                <ImgCrop rotate shape="rect" aspect={5 / 3} modalWidth={850}>
                  <Upload
                    action={`${base.api}projects/upload`}
                    listType="picture-card"
                    showUploadList={false}
                    headers={headersSendForm}
                    onChange={onChangeUpload}>
                    {stateUpload.imageUrl ? (
                      <img
                        className="imgLogoProject"
                        src={stateUpload.imageUrl}
                        alt="project"
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </ImgCrop>
              </Col>
            </Row>

            <Row className="mt-10">
              <Col span={11}>
                <Input
                  className="w100"
                  type="text"
                  name="pname"
                  placeholder={t('project_create.project_name')}
                  value={projectPname}
                  onChange={(e) => setProjectPname(e.target.value)}
                  required
                />
                {renderPnameValidationError}
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  name="country"
                  placeholder={t('project_create.country')}
                  value={projectPcountry}
                  onChange={(e) => setProjectPcountry(e)}>
                  {codeCountries.map(({ code, name }, index) => (
                    <Option key={code} value={code}>
                      {name}
                    </Option>
                  ))}
                </Select>
                {renderCountryValidationError}
              </Col>
            </Row>

            <br />

            <Button htmlType="submit" loading={isLoading}>
              {projectState.props.currentProjectId
                ? t('project_create.save_project')
                : t('project_create.add_name')}
            </Button>
          </form>
        </Fragment>
      </div>
    </Modal>
  );
}

export default withTranslation()(FormProjectsDummy);
