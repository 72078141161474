import React, { Fragment } from 'react';
import CompanyInfo from '../components/CompanyInfo';
import './hoc.css';

const withCompanyInfo = (props) => (WrappedComponent) =>
  function withSidenav() {
    return (
      <Fragment>
        <CompanyInfo />
        <WrappedComponent />
      </Fragment>
    );
  };
export default withCompanyInfo;
