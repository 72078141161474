/* eslint-disable no-eval */
/** React stuff */
import React, { useState, useEffect, useRef } from 'react';

/** React-query */
import { useQueryClient, useMutation } from 'react-query';

/** Components from ant design css framework */
import {
  Menu,
  Dropdown,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Input,
  Popover,
  Icon,
  Empty,
  Popconfirm
} from 'antd';
import SelectSearch from 'react-select-search';

/** import icons from antd framework */
import { PlusOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';

/** Messaging for user notification util */
import {
  openNotification,
  categoriesCncType,
  renderIconCat,
  reversPathTasks
} from '../../../../utils';
import { splitText } from '../../../../utils/lookahead-common';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

import { useDispatch } from 'react-redux';
import { cncActions } from '../../../../redux/actions/cncActions';

import { capitalize } from 'lodash';
import CustomCheckbox from '../../../CustomCheckbox';
import cloneDeep from 'lodash/cloneDeep';

import childArrow from '../../../../assets/img/child-arrow.png';

/** PNG to put on users without image */
import fakeAvatar from '../../../../assets/img/fake_user.png';

/** Date handler lib */
import moment from 'moment';

/** Styles on pure css */
import './index.css';
import { FixedSizeList as List } from 'react-window';

/** Services */
import { deleteCnc } from '../../../../views/weeklyPlan/weeklyPlan.helper';
import { activityService } from '../../../../services';

import LazyElement from '../../../LazyElement';
import EditableInput from '../../../EditableInput';

/** PNG for task planification add and delete tasks */
import deleteIcon from '../../../../assets/img/DLT-DarkGrey-1080x1080.png';
import { getScheduleDateFormat } from '../../../../utils/getScheduleDateFormat';

/**
 * This component prints an header with activity data, and then renders it's tree tasks, allowing user to interact each row data.
 */
export default function CncActivity(props) {
  /** consts */
  const queryClient = useQueryClient();

  /** refs */
  const virtualizeRef = useRef();

  /** props */
  const { permission, t, projectState } = props;
  const [onlyRead, setOnlyRead] = useState(permission == 'V');
  /** hooks */
  const [popsVisibility, setPopsVisibility] = useState({});
  const [editedInput, setEditedInput] = useState(null);
  const { height, width } = useWindowDimensions();
  const { updateAsyncCnc } = props;
  const dispatch = useDispatch();

  const formatDate = getScheduleDateFormat(projectState);

  /** Querys Mutators */
  /**
   * This function delete object from table & update cache
   * @param {*} data Object {} Object data to pass on to mutation side effects
   */
  const mutationDelete = useMutation(
    async ({ cncId, activity }) => await deleteCnc({ cncId }),
    {
      onMutate: (variables) => {
        const oldItems = variables.activity;
        const newData = oldItems.tasks.filter(
          (item) => item.id !== variables.cncId
        );
        variables.activity.tasks = newData;

        /** delete from dataNoGroup */
        props.setDataNoGroup([
          {
            name: t('weekly_plan_cnc.title'),
            tasks: newData
          }
        ]);
        queryClient.setQueryData('cncListRq', {
          cncs: newData
        });

        notifyMessage({
          title: t('deleted_constraint_label'),
          message: t('succesfully_removed_cnc'),
          type: 'success'
        });
        props.virtualizeRef.current.resetAfterRowIndex(props.index);
        updateState();
        props.setMassiveSelection([]);
        return () => {};
      },
      onSettled: () => {
        queryClient.removeQueries(['cncListRq', projectState.sectorSelected]);
      }
    }
  );

  useEffect(() => {
    const id = props.lastLvlActivity.id;
    if (props.scrollStates[id]) {
      if (virtualizeRef.current) {
        virtualizeRef.current.scrollTo(props.scrollStates[id]);
      }
    }

    return () => {
      if (virtualizeRef.current) {
        props.scrollStates[id] = virtualizeRef.current.state.scrollOffset;
      }
    };
  }, [virtualizeRef]);

  /**
   * Clone deep of a new state to set and render view
   * @param {*} newState new state value (inmutability is broken)
   */
  const updateState = (newState = popsVisibility) => {
    setPopsVisibility((prev) => {
      prev = cloneDeep(newState);
      return prev;
    });
    dispatch(cncActions.notifyLookaheadUpdate());
  };

  /**
   * This function deals with pop visibility hash map
   * @param {boolean} visible Value to set the pop
   * @param {*} id Unique state eval to use at virtual DOM to show popup or hide it (from antd component)
   */
  const handlePopVisibility = (visible, id) => {
    setPopsVisibility({
      ...popsVisibility,
      [id]: visible
    });
    dispatch(cncActions.notifyLookaheadUpdate());
  };

  /**
   * This function builds a JSX to select responsables at inline edition
   */
  const renderFriend = (props, option, snapshot, className) => {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10
    };

    let toShowName = option.object.name;

    if (toShowName) {
      if (toShowName.length > 15) {
        toShowName = toShowName.slice(0, 15) + '...';
      }
    }

    return (
      <button
        {...props}
        className={className}
        type="button"
        title={option.object.email}>
        <span>
          <img
            alt=""
            style={imgStyle}
            width="32"
            height="32"
            src={option.photo}
          />
          <span style={{ fontSize: 15 }}>{toShowName}</span>
        </span>
      </button>
    );
  };

  /**
   * This functions shows a pretty alert to user
   * @param {*} data Object { title, message, type }
   */
  const notifyMessage = (data) => {
    const alertErrorMailExists = {
      title: data.title,
      description: data.message,
      type: data.type
    };
    openNotification(alertErrorMailExists);
  };

  /**
   * On click function when pressing delete
   * @param {*} task Top lvl task to start tree deleting
   * @param {*} activity Activity parent object
   */
  const handleDelete = async (cnc, activity) => {
    /** call mutation function for work on cache */
    mutationDelete.mutate({
      cncId: cnc.id,
      activity
    });
  };

  /**
   * This function allows user to hide childs for a task or activity
   * @param {*} parent Parent activity or task to hide their children (REQUIRED)
   */
  const hideChilds = (parent) => {
    if (parent.tasks)
      props.virtualizeRef.current.resetAfterRowIndex(props.index);
    parent.hide_childs = true;
    updateState();
  };

  /**
   * This function allows user to show childs for a task or activity
   * @param {*} parent Parent activity or task to show their children (REQUIRED)
   */
  const showChilds = (parent) => {
    if (parent.tasks)
      props.virtualizeRef.current.resetAfterRowIndex(props.index);
    parent.hide_childs = false;
    updateState();
  };

  /**
   * This function handles at virtual dom which icon should be shown (collapse or uncollapse)
   * @param {*} parent Parent activity or task to define what icon to show (REQUIRED)
   */
  const defineCollapseIcon = (parent) => {
    const children = parent.tasks || parent.children;
    if (children) {
      if (children.length) {
        if (parent.hide_childs) {
          return (
            <i
              onClick={() => showChilds(parent)}
              className="fa fa-angle-down show-childs-btn"
              aria-hidden="true"
            />
          );
        }
        return (
          <i
            onClick={() => hideChilds(parent)}
            className="fa fa-minus hide-childs-btn"
            aria-hidden="true"
          />
        );
      }
    }
  };

  /** replace last ocurrency */
  const replaceLast = (x, y, z) => {
    const a = x.split('');
    a[x.lastIndexOf(y)] = z;
    return a.join('');
  };

  /**
   * This function defines what should be showed inside each task column at table.
   * This by using a flag from task, isEditing, when is active renders something, and when is not active
   * renders another jsx
   * @param {*} column Metadata from the column that is going to be extracted from the task
   * @param {*} task Task object to extract the column data
   * @param {*} activity Parent activity for tasks from first level
   * @param {*} parentTask Parent task if it is not from first level
   */
  const defineTaskColumnContent = (
    column,
    task,
    activity,
    parentTask,
    index
  ) => {
    if (column.data_type === 'string') {
      if (column.name === 'cnctasks') {
        const cnc = task;
        /** calculate real total */
        const weekTask = props.allWeekCommitments.find(
          (el) => el.week === cnc.week && el.year === cnc.year
        );

        let totalTasks = 0;
        if (weekTask) {
          cnc.cnctasks.map((cncTask) => {
            const count = weekTask.taskcommitments.filter(
              (el) => el.taskId === cncTask.taskId
            );
            totalTasks += parseInt(count.length);
          });
        }
        let ret = <span className="vertical-center">-</span>;
        if (totalTasks > 1) {
          const menu = (
            <Menu>
              {cnc[column.name].map((ct, index) => {
                const count = weekTask.taskcommitments.filter(
                  (el) => el.taskId === ct.taskId
                );
                const taskName = props.taskList.find((t) => t.id === ct.taskId);
                let strTask = t('non_exist_task');
                let routeTooltip = 'No Route';
                if (taskName && taskName.name) {
                  strTask =
                    count.length > 1
                      ? `${taskName.name} (${count.length})`
                      : taskName.name;
                  const findRoute = taskName.activity;
                  routeTooltip = findRoute
                    ? findRoute.activityRoute + ' > ' + findRoute.name
                    : 'No Route';
                }
                return (
                  <Menu.Item key={index}>
                    {/* {strTask} */}
                    <Tooltip title={routeTooltip}>{strTask}</Tooltip>
                  </Menu.Item>
                );
              })}
            </Menu>
          );
          ret = (
            <span className="vertical-center">
              <Dropdown
                overlay={menu}
                trigger={['click']}
                className="associated-task">
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}>
                  {totalTasks} Tasks <DownOutlined />
                </a>
              </Dropdown>
            </span>
          );
        } else {
          if (totalTasks > 0) {
            ret = cnc[column.name].map((ct, index) => {
              const taskName = props.taskList.find((t) => t.id === ct.taskId);
              let strTask = t('non_exist_task');
              let routeTooltip = 'No Route';
              if (taskName && taskName.name) {
                strTask = taskName.name;
                const findRoute = taskName.activity;
                routeTooltip = findRoute
                  ? findRoute.activityRoute + ' > ' + findRoute.name
                  : 'No Route';
              }

              /** calc width dinamic */
              let showedText = strTask;
              let tooltip;
              const colAdjustWidth = Math.trunc(column.width / 4);
              const textAdjustWidth = showedText.length * 2;
              if (textAdjustWidth > colAdjustWidth) {
                showedText =
                  strTask.slice(0, Math.trunc(colAdjustWidth / 2)) + '...';
                tooltip = true;
              }
              const textDrawer = showedText;
              return tooltip ? (
                <Tooltip placement="top" title={routeTooltip} key={index}>
                  <span className="vertical-center" key={index}>
                    {textDrawer}
                  </span>
                </Tooltip>
              ) : (
                <Tooltip placement="top" title={routeTooltip} key={index}>
                  <span className="vertical-center" key={index}>
                    {textDrawer}
                  </span>
                </Tooltip>
              );
            });
          }
        }
        return ret;
      } else if (column.name === 'image') {
        let ret = <span className="vertical-center">-</span>;
        if (task[column.name]) {
          const th1 = replaceLast(task[column.name], '.', '-th1.');
          ret = (
            <span className="vertical-center">
              <img
                src={th1}
                className="img-modal-row"
                width="14"
                onClick={() => {
                  if (!onlyRead) {
                    props.setImgModal(task[column.name]);
                    props.setVisibleImg(true);
                  }
                }}
              />
            </span>
          );
        }
        return ret;
      } else if (column.name === 'name') {
        let option = props.tableMetadata.filter((el) => el.name == 'status');
        option = option[0];
        let background = option.from_values.filter(
          (el) => el.value == task.status
        );
        background = background[0];
        return (
          <span className="vertical-center">
            {onlyRead ? null : (
              <CustomCheckbox
                onClick={() => {
                  props.massiveSelectionHandler(task);
                  // updateState()
                }}
                active={task.active}
              />
            )}
            {task.parent_id ? (
              <img style={{ marginRight: 6 }} width={12} src={childArrow} />
            ) : null}
            <div
              className="lineStatus lookaheadLineStatus"
              style={{ background: background.color, marginRight: 6 }}>
              &nbsp;
            </div>
            <EditableInput
              t={t}
              disabled={onlyRead}
              service={updateAsyncCnc}
              onEdit={setEditedInput}
              isEditing={editedInput}
              renderEditable={(
                column,
                index,
                task,
                value,
                setValue,
                updateParentData = null,
                handleEsc = null
              ) => (
                <Input
                  onKeyDown={handleEsc}
                  onPressEnter={updateParentData}
                  key={index}
                  id={column.name + task.id}
                  size="small"
                  value={value}
                  className="custom-input-planification"
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => setValue(e.target.value)}
                />
              )}
              updateState={updateState}
              index={index}
              column={column}
              task={task}
            />
            {onlyRead ? null : (
              <span style={{ cursor: 'pointer', marginLeft: 5 }}>
                <Popconfirm
                  onConfirm={() => handleDelete(task, activity, parentTask)}
                  title={t('are_sure_general')}
                  icon={
                    <Icon type="question-circle-o" style={{ color: 'red' }} />
                  }>
                  <Tooltip placement="top" title={t('delete_task_label')}>
                    <DeleteOutlined className="lookahead-delete-icon" />
                  </Tooltip>
                </Popconfirm>
              </span>
            )}
          </span>
        );
      } else if (column.name === 'tasks') {
        const cncItem = task;
        let ret = <span className="vertical-center">-</span>;
        if (cncItem.tasks.length > 1) {
          ret = cncItem.tasks.map((ct, index) => (
            <span class="vertical-center" key={index}>
              {ct.name}
            </span>
          ));
          const menu = (
            <Menu>
              {cncItem.tasks.map((ct, index) => (
                <Menu.Item key={index}>{ct.name}</Menu.Item>
              ))}
            </Menu>
          );
          ret = (
            <span className="vertical-center">
              <Dropdown
                overlay={menu}
                trigger={['click']}
                className="associated-task">
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}>
                  {cncItem.tasks.length} {t('task_only_label')} <DownOutlined />
                </a>
              </Dropdown>
            </span>
          );
        } else {
          if (cncItem.tasks.length > 0) {
            ret = cncItem.tasks.map((ct, index) => (
              <span className="vertical-center" key={index}>
                {ct.name}
              </span>
            ));
          }
        }
        return ret;
      } else if (column.name === 'path') {
        return (
          <div
            className="vertical-center"
            style={{ display: 'flex', marginLeft: '1rem', paddingTop: '8px' }}>
            {splitText(
              reversPathTasks(`${activity.activityRoute} > ${activity.name}`),
              column
            )}
          </div>
        );
      }
    } else if (column.data_type == 'number') {
      return (
        <span className="vertical-center">
          {Number.isInteger(task[column.name])
            ? task[column.name]
            : task[column.name].toFixed(2)}
        </span>
      );
    } else if (column.data_type == 'date') {
      if (column.name == 'create_date') {
        if (column.editable !== 'undefined' && column.editable === false) {
          return (
            <span className="vertical-center">
              {moment(task[column.name]).format(formatDate)}
            </span>
          );
        }
        let classDate = 'custom-date-picker-planification';
        if (task.active) {
          classDate += ' massive';
        }
        return (
          <span className="vertical-center">
            <DatePicker
              disabled={onlyRead}
              allowClear={false}
              id={column.name + task.id}
              className={classDate}
              defaultValue={moment(task[column.name], 'YYYY/MM/DD')}
              format={'YYYY/MM/DD'}
              onChange={(date, dateString) => {
                task[column.name] = dateString;
                /** Apply changes */
                updateAsyncCnc(task);
                updateState();
              }}
            />
          </span>
        );
      }
    } else if (column.data_type == 'array/images') {
      if (column.editable !== 'undefined' && column.editable === false) {
        const responsable = task[column.ref][0];
        return responsable ? (
          <span className="vertical-center">
            <Tooltip title={responsable.name + ' ' + responsable.lastname}>
              {responsable.image ? (
                <img
                  className="img-responsable-lookahead"
                  src={responsable.image}
                />
              ) : (
                <div className="img-responsable-lookahead no-img">
                  {responsable.name ? responsable.name[0] : ''}
                  {responsable.lastname ? responsable.lastname[0] : ''}
                </div>
              )}
            </Tooltip>
          </span>
        ) : (
          <span className="vertical-center">-</span>
        );
      }

      const uniqueId = task.id + column.name;
      const friends = [];
      const selected = [];

      task[column.ref].map((res) => {
        selected.push(res[column.el_to_extract_from]);
      });

      props[column.from_values].map((user) => {
        if (user.is_active) {
          friends.push({
            name: user[column.el_to_extract_from],
            value: user[column.el_to_extract_from],
            photo: user[column.img_from] || fakeAvatar,
            object: user
          });
        }
      });

      if (onlyRead) {
        return (
          <div>
            {task[column.ref].length ? (
              task[column.ref].map((responsable, index) =>
                responsable ? (
                  <span className="vertical-center" key={index}>
                    {responsable[column.img_from] ? (
                      <img
                        className="img-responsable-lookahead"
                        src={responsable[column.img_from]}
                      />
                    ) : (
                      <div className="img-responsable-lookahead no-img">
                        {responsable.name ? responsable.name[0] : ''}
                        {responsable.lastname ? responsable.lastname[0] : ''}
                      </div>
                    )}
                  </span>
                ) : (
                  <span className="vertical-center">-</span>
                )
              )
            ) : (
              <span className="vertical-center">
                {t('select_responsable_tooltip')}
              </span>
            )}
          </div>
        );
      }

      return (
        <div
          className="custom-multi-select-pop"
          style={{
            width: column.width ? column.width : '100%',
            height: '100%',
            marginTop: 0,
            border: 0
          }}>
          <Popover
            overlayClassName="container-image-custom"
            className="popoverPriority"
            content={
              <SelectSearch
                className="select-search select-search--multiple"
                options={friends}
                value={selected}
                renderOption={renderFriend}
                onChange={(val) => {
                  const findUser = props[column.from_values].find(
                    (us) => us.email === val
                  );
                  task.users = [findUser];
                  task[column.refSave] = findUser.id;
                  updateAsyncCnc(task);
                  updateState();
                }}
                search
                placeholder={t('search_res_text')}
              />
            }
            trigger="click">
            {task[column.ref].length ? (
              task[column.ref].map((responsable, index) =>
                responsable ? (
                  <span className="vertical-center" key={index}>
                    <Tooltip
                      title={responsable.name + ' ' + responsable.lastname}>
                      {responsable[column.img_from] ? (
                        <img
                          className="img-responsable-lookahead"
                          src={responsable[column.img_from]}
                        />
                      ) : (
                        <div className="img-responsable-lookahead no-img">
                          {responsable.name ? responsable.name[0] : ''}
                          {responsable.lastname ? responsable.lastname[0] : ''}
                        </div>
                      )}
                    </Tooltip>
                  </span>
                ) : (
                  <span className="vertical-center">-</span>
                )
              )
            ) : (
              <span className="vertical-center">
                {t('select_responsable_tooltip')}
              </span>
            )}
          </Popover>
        </div>
      );
    } else if (column.data_type == 'array/string') {
      if (column.name === 'cncTypeId') {
        const uniqueId = task.id + column.name;
        const statusObject =
          props.cncTypes &&
          props.cncTypes.find((e) => e.id === task[column.name]);
        const findItemTable =
          statusObject &&
          categoriesCncType.find((el) => el.value === statusObject.type);

        if (onlyRead) {
          return (
            <div style={{ height: '100%' }}>
              <span className="vertical-center">
                <Tooltip
                  title={t(
                    `settings.project_categories.${findItemTable.trad}`
                  )}>
                  <img
                    className="cat-icon"
                    src={renderIconCat(findItemTable.icon)}
                    alt=""
                  />
                </Tooltip>
                {statusObject.name}
              </span>
            </div>
          );
        }

        /** calc width dinamic */
        let showedText = statusObject.name;
        let tooltip;
        const colAdjustWidth = Math.trunc(column.width / 4);
        const textAdjustWidth = showedText.length * 2;
        if (textAdjustWidth > colAdjustWidth) {
          showedText =
            statusObject.name.slice(0, Math.trunc(colAdjustWidth / 2)) + '...';
          tooltip = true;
        }
        const textDrawer = showedText;
        const drawTextFinal = tooltip ? (
          <Tooltip placement="top" title={statusObject.name}>
            {textDrawer}
          </Tooltip>
        ) : (
          textDrawer
        );
        return (
          statusObject && (
            <div
              className="customPlanificationBlock priorityFlag"
              style={{ height: '100%', marginTop: 0, border: 0 }}>
              <Tooltip placement="top">
                <Popover
                  overlayClassName="popoverPriority"
                  className="popoverPriority"
                  placement="bottom"
                  content={
                    <div className="priorityOptions">
                      {props.cncTypes.map((option, index) => {
                        const findItem = categoriesCncType.find(
                          (el) => el.value === option.type
                        );
                        return (
                          <span
                            className="item"
                            key={index}
                            onClick={() => {
                              task[column.name] = option.id;
                              // task[column.name] = props.cncTypes.find(type => type.id === option.id)
                              updateAsyncCnc(task);
                              handlePopVisibility(false, uniqueId);
                              updateState();
                            }}>
                            <img
                              className="cat-icon"
                              src={renderIconCat(findItem.icon)}
                              alt=""
                            />
                            <span style={{ marginLeft: 5 }}>
                              {capitalize(option.name)}
                            </span>
                          </span>
                        );
                      })}
                    </div>
                  }
                  trigger="click">
                  <div style={{ height: '100%' }}>
                    <span className="vertical-center">
                      <Tooltip
                        title={t(
                          `settings.project_categories.${findItemTable.trad}`
                        )}>
                        <img
                          className="cat-icon"
                          src={renderIconCat(findItemTable.icon)}
                          alt=""
                        />
                      </Tooltip>
                      {drawTextFinal}
                    </span>
                  </div>
                </Popover>
              </Tooltip>
            </div>
          )
        );
      } else if (column.name === 'typeArea') {
        const statusObject =
          props.cncTypes && props.cncTypes.find((e) => e.id === task.cncTypeId);
        return (
          <div style={{ height: '100%' }}>
            <span className="vertical-center">
              {t(`settings.project_categories.${statusObject.type}`)}
            </span>
          </div>
        );
      } else if (column.name === 'status') {
        const uniqueId = task.id + column.name;
        const statusObject = column.from_values.filter(
          (el) => el.value == task[column.name]
        );
        const background = statusObject[0].color;

        if (onlyRead) {
          return (
            <div
              style={{
                backgroundColor: background,
                color: 'white',
                height: '100%'
              }}>
              <span className="vertical-center">{statusObject[0].label}</span>
            </div>
          );
        }

        return (
          <div
            className="customPlanificationBlock priorityFlag"
            style={{ height: '100%', marginTop: 0, border: 0 }}>
            <Tooltip placement="top">
              <Popover
                placement="bottom"
                overlayClassName="popoverPriority"
                className="popoverPriority"
                content={
                  <div className="priorityOptions">
                    {column.from_values.map((option, index) => (
                      <span
                        className="item"
                        key={index}
                        onClick={() => {
                          task[column.name] = option.value;
                          updateAsyncCnc(task);
                          handlePopVisibility(false, uniqueId);
                        }}>
                        <i
                          className="fas fa-circle"
                          style={{
                            fontSize: 11,
                            color: option.color,
                            position: 'relative',
                            top: -1
                          }}
                        />
                        <span style={{ marginLeft: 5 }}>{option.label}</span>
                      </span>
                    ))}
                  </div>
                }
                trigger="click">
                <div
                  style={{
                    backgroundColor: background,
                    color: 'white',
                    height: '100%'
                  }}>
                  <span className="vertical-center">
                    {statusObject[0].label}
                  </span>
                </div>
              </Popover>
            </Tooltip>
          </div>
        );
      }
      return <span className="vertical-center">-</span>;
    } else if (column.data_type == 'array/icon') {
      const iconToShow = column.from_values.filter(
        (f) => f.value == task[column.name]
      );
      const uniqueId = task.id + column.name;
      const defineIcon = (option) => {
        if (column.name == 'priority') {
          return <img src={option.icon} width={12} />;
        }
        return (
          <i
            className={option.icon}
            style={{
              fontSize: 11,
              color: option.color,
              position: 'relative',
              top: -2
            }}
          />
        );
      };

      if (onlyRead) {
        return (
          <div
            className="customStr priorityFlag custom-select-planification-position"
            style={{ height: '100%', marginTop: 0, border: 0 }}>
            <Tooltip title={capitalize(task[column.name])} placement="top">
              <span className="vertical-center">
                {defineIcon(iconToShow[0])}
              </span>
            </Tooltip>
          </div>
        );
      }

      return (
        <Popover
          overlayClassName="popoverPriority"
          className="popoverPriority"
          placement="bottom"
          content={
            <div className="priorityOptions">
              {column.from_values.map((option, index) => (
                <span
                  className="item"
                  key={index}
                  onClick={() => {
                    task[column.name] = option.value;
                    updateAsyncCnc(task);
                    handlePopVisibility(false, uniqueId);
                  }}>
                  {defineIcon(option)}
                  <span style={{ marginLeft: 5 }}>
                    {capitalize(option.label)}
                  </span>
                </span>
              ))}
            </div>
          }
          trigger="click">
          <div
            className="customStr priorityFlag custom-select-planification-position"
            style={{ height: '100%', marginTop: 0, border: 0 }}>
            <Tooltip title={capitalize(task[column.name])} placement="top">
              <span className="vertical-center">
                {defineIcon(iconToShow[0])}
              </span>
            </Tooltip>
          </div>
        </Popover>
      );
    } else {
      return <div className="">--</div>;
    }
  };

  /**
   * This function renders the column container, and then goes to defineTaskColumnContent function
   * to define what render inside
   * @param {*} column Metadata from the column that is going to be extracted the task
   * @param {*} task Task object to extract the column data
   * @param {*} tabulation Integer to add as marginLeft to display tree structure
   * @param {*} index Index from the array iteration
   * @param {*} activity Parent activity for tasks from first level
   * @param {*} parentTask Parent task if it is not from first level
   */
  const renderTaskColumn = (
    column,
    task,
    tabulation,
    index,
    activity,
    parentTask,
    lvl
  ) => {
    let auxTabulation = tabulation;

    if (lvl >= 2) {
      const paddingMultiplierFix = 0.1 * (lvl + 1);
      auxTabulation = tabulation + tabulation * paddingMultiplierFix;
    }

    if (column.visible) {
      return (
        <Col
          className={
            column.name == 'name'
              ? 'fit-name-superposition'
              : 'single-task-column-style'
          }
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            paddingLeft: column.name == 'name' ? auxTabulation : 0,
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineTaskColumnContent(column, task, activity, parentTask, index)}
        </Col>
      );
    }
  };

  /**
   * Recursive function to display at virtual dom the tree nested table format
   * @param {*} tasks Array of tasks
   * @param {*} finalArray Final array to display JSX at virtual dom
   * @param {*} activity Parent activity
   * @param {*} tabulation Padding left to display tree table format
   * @param {*} parentTask Instance of the parent task if it is not from first lvl
   */
  const renderChildTreeTasks = (
    tasks,
    finalArray,
    activity,
    tabulation,
    parentTask = null,
    lvl = 0
  ) => {
    tasks.map((task) => {
      const childs = task.children;
      const hasChilds = childs && childs.length != 0;

      if (!task.hide) {
        if (props.resizing) {
          finalArray.push(
            <Row key={task.id} className="fit-at-middle-row"></Row>
          );
        } else {
          finalArray.push(
            <Row
              style={{ backgroundColor: task.active ? '#12121210' : null }}
              key={task.id}
              className="fit-at-middle-row">
              {props.tableMetadata.map((eachColumn, index) =>
                renderTaskColumn(
                  eachColumn,
                  task,
                  tabulation || 0,
                  index,
                  activity,
                  parentTask,
                  lvl
                )
              )}
            </Row>
          );
        }
      }

      if (hasChilds && !task.hide_childs) {
        const newLevel = lvl + 1;
        renderChildTreeTasks(
          childs,
          finalArray,
          activity,
          tabulation + (task.hide ? 0 : 15),
          task,
          newLevel
        );
      }
    });
  };

  /** This function is used for show tasks quantity on virtual dom who renders them and resize height */
  const defineVirtualizedHeight = (taskCounter) => {
    const tempHeight = taskCounter * 40;
    const maxHeight = height < 700 ? height * 0.45 : height * 0.6;
    if (tempHeight < maxHeight) {
      return tempHeight;
    }
    return maxHeight;
  };

  /**
   * This function is used by virtual dom to render tasks associated to activity
   * @param {*} activity Activity object to show his child tasks
   */
  const renderTasks = (activity) => {
    const childRended = [];
    renderChildTreeTasks(activity.tasks, childRended, activity, 15);

    activity.childRended = childRended.length;

    if (props.virtualizeRef.current) {
      props.virtualizeRef.current.resetAfterRowIndex(props.index);
    }

    /**
     * To Optimize render of this child list an option is
     * https://react-window.now.sh/#/examples/list/variable-size
     * with childRended, we can create a virtual list to handle performance
     */

    if (activity.hide_childs) {
      return null;
    } else if (childRended.length) {
      return (
        <List
          ref={virtualizeRef}
          height={defineVirtualizedHeight(childRended.length)}
          itemCount={childRended.length}
          itemSize={40}
          width={'100%'}>
          {LazyElement(childRended, virtualizeRef)}
        </List>
      );
    }
    return (
      <div style={{ marginTop: 20 }}>
        <Empty
          style={{ margin: 0 }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>{t('add_cnc_label')}</span>}
        />
      </div>
    );
  };

  /**
   * This function defines what content show inside each header with activity data
   * @param {*} column Metadata from the column that is going to be extracted the task
   * @param {*} activity Activity object to extract the column data
   */
  const defineActivityColumnContent = (column, activity) => {
    if (column.name == 'name') {
      return (
        <div>
          {activity.tasks.length && !onlyRead ? (
            <CustomCheckbox
              inverted
              onClick={() => {
                props.massiveSelectionHandler(activity);
                // updateState()
              }}
              active={activity.active}
            />
          ) : null}
          {defineCollapseIcon(activity)}
          <span>{activity[column.name]}</span>
        </div>
      );
    }
    return <div className="transparent-for-activity-column-header">-</div>;
  };

  /**
   * This function renders each activity column, and then calls defineActivityColumnContent to define
   * what jsx els must be showed inside of this col
   * @param {*} column Metadata from the column that is going to be extracted the task
   * @param {*} activity Activity object to extract the column data
   * @param {*} index Index from array iteration
   */
  const renderActivityColumn = (column, activity, index) => {
    if (column.visible) {
      return (
        <Col
          className="single-column-header"
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineActivityColumnContent(column, activity)}
        </Col>
      );
    }
  };

  /**
   * This function render the header from the table, with the activity data
   */
  const renderActivityHeader = () => (
    <Row className="custom-header-top-list">
      {props.tableMetadata.map((eachColumn, index) =>
        renderActivityColumn(eachColumn, props.lastLvlActivity, index)
      )}
    </Row>
  );

  /**
   * This function render Add button
   */
  const renderAddItem = () => {
    if (onlyRead) {
      return null;
    }
    return (
      <Row>
        <Col span={6}>
          <div className="addTaskBtn" onClick={props.handleAddItem}>
            <PlusOutlined />
            {t('adding_cnc_label')}
          </div>
        </Col>
      </Row>
    );
  };

  /**
   * Render
   */
  return (
    <Row key={props.index}>
      <Col
        className="tableConstraints"
        style={{ marginTop: 10, marginBottom: 10 }}>
        {renderActivityHeader()}
        {renderTasks(props.lastLvlActivity)}
      </Col>
      {renderAddItem()}
    </Row>
  );
}
