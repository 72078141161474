import { logger } from '@sentry/utils';
import moment from 'moment';
import React, { useRef, useEffect, useState } from 'react';
import { ganttAPI } from '../../../../../utils/customGanttPlugin';

import styles from './ManpowerInfo.module.scss';

const Manpower = (props) => {
  const { activities, calendars, planificationDay, week, year, t } = props;

  const task = useRef([]);
  const tasksCompleted = useRef(0);

  let { current: dayOfManPower } = useRef({
    day0: 0,
    day1: 0,
    day2: 0,
    day3: 0,
    day4: 0,
    day5: 0,
    day6: 0
  });

  const planificationDate = planificationDay == 0 ? -1 : planificationDay - 1;
  const loadCalendars = (calendars) => ganttAPI.loadCalendars(calendars);

  const [totalTask, setTotalTask] = useState(0);
  const [completed, setTasksCompleted] = useState(0);
  const [totalManpowerPerDay, setTotalManpowerPerDay] = useState(dayOfManPower);

  useEffect(() => {
    calendars.length && loadCalendars(calendars);
  }, [calendars]);

  useEffect(() => {
    cleanData();

    activities.length && getAllTasks(task.current, 0);
    setTotalTask(task.current.length);

    task.current.forEach((task) => {
      calculateConditions(task);
      calculateTotalManpowerPerDay(task);
    });
    setTasksCompleted(tasksCompleted.current);
    setTotalManpowerPerDay({ ...totalManpowerPerDay, ...dayOfManPower });
  }, [activities]);

  const cleanData = (_) => {
    const cleanTotal = {
      day0: 0,
      day1: 0,
      day2: 0,
      day3: 0,
      day4: 0,
      day5: 0,
      day6: 0
    };

    task.current = [];
    dayOfManPower = cleanTotal;
    tasksCompleted.current = 0;
    setTotalManpowerPerDay(cleanTotal);
  };

  const getAllTasks = (activityTask, idx) => {
    if (activities.length <= idx) {
      return;
    }

    const tasks = activities[idx].tasks;

    const task = tasks.filter((task) => {
      task.activity = activities[idx];
      return task.taskcommitments.length;
    });

    activityTask.push(...task);

    getTaskChilds(tasks, activityTask);
    getAllTasks(activityTask, idx + 1);
  };

  const calculateTotalManpowerPerDay = (task) => {
    sumTotalManpower(task, task.activity, 0);
    sumTotalManpower(task, task.activity, 1);
    sumTotalManpower(task, task.activity, 2);
    sumTotalManpower(task, task.activity, 3);
    sumTotalManpower(task, task.activity, 4);
    sumTotalManpower(task, task.activity, 5);
    sumTotalManpower(task, task.activity, 6);
  };

  const sumTotalManpower = (task, activity, day) => {
    const isValid = validateDay(task.taskcommitments[0], activity, day);

    isValid && (dayOfManPower[`day${day}`] += task.plan_endowment);
  };

  const getTaskChilds = (tasks, activityTask) => {
    if (tasks.length) {
      tasks.forEach((task) =>
        task.children?.forEach((child) => {
          child?.children?.length && getTaskChilds(child);
          child?.taskCommitment?.length && activityTask.push(child);
        })
      );
    }
  };

  const calculateConditions = (task) => {
    const taskCommitment = task.taskcommitments[0];

    const progressToCompare = taskCommitment.weekcommitment.closed
      ? taskCommitment.realized_percentaje
      : task.progress;

    if (progressToCompare >= taskCommitment.commitment_percentaje) {
      tasksCompleted.current += 1;
    }
  };

  const validateDay = (task, activity, day) => {
    const week_state = year == 2022 ? props.week - 1 : props.week;
    const calendar = ganttAPI.getTaskCalendar(activity.calendarId);
    if (!calendar) return false;

    const validate_date = moment()
      .lang('es')
      .year(year)
      .week(week_state)
      .startOf('isoweek')
      .add('days', planificationDate)
      .set('hour', '9');
    const copyOfDate = new Date(
      validate_date.add('days', day).format('YYYY/MM/DD H:mm')
    );
    const validate_day = validate_date.isBetween(
      moment(task.start_date).subtract(1, 'd'),
      moment(task.end_date).add(1, 'd'),
      'days',
      false
    );

    if (!validate_day) {
      return false;
    }

    // Funcion mal utilizada deberia ser {date, unit: 'day'}
    return calendar.isWorkTime({ date: copyOfDate, unit: 'day' });
  };

  return (
    <div className={styles.manpower}>
      <div>
        <span className={styles.manpower_title}>
          {t('weekcommitment.total-manpowers')}
        </span>
      </div>
      <div className={styles.days__container}>
        <span className={styles.days}> {totalManpowerPerDay.day0 || '-'} </span>
        <span className={styles.days}> {totalManpowerPerDay.day1 || '-'} </span>
        <span className={styles.days}> {totalManpowerPerDay.day2 || '-'} </span>
        <span className={styles.days}> {totalManpowerPerDay.day3 || '-'} </span>
        <span className={styles.days}> {totalManpowerPerDay.day4 || '-'} </span>
        <span className={styles.days}> {totalManpowerPerDay.day5 || '-'} </span>
        <span className={styles.days}> {totalManpowerPerDay.day6 || '-'} </span>
      </div>
      <div className={styles.task_completed}>
        <span>
          {completed}/{totalTask}
        </span>
        <span></span>
      </div>
    </div>
  );
};

export default Manpower;
