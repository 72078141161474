import { earlyAccessCriticalPath } from '../../../../utils/earlyAccessCriticalPath';

function validateCriticalNeed(array) {
  const columnsSet = new Set(
    columnsWhoNeedToReloadCritical().map((column) => column.name)
  );
  return array.some((property) => columnsSet.has(property.name));
}

function columnsWhoNeedToReloadCritical() {
  const columns = [
    { name: 'totalSlack' },
    { name: 'freeSlack' },
    { name: 'is_critical' }
  ];

  if (earlyAccessCriticalPath()) {
    columns.push(
      { name: 'early_start' },
      { name: 'late_start' },
      { name: 'early_finish' },
      { name: 'late_finish' }
    );
  }
  return columns;
}

export { validateCriticalNeed };
