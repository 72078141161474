import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from './routes';

export default function AppRouter() {
  return (
    <div>
      <Switch>
        {routes.map((data) => (
          <Route key={data.path} {...data} />
        ))}
      </Switch>
    </div>
  );
}
