/* eslint-disable no-eval */
/** React stuff */
import React, { useState, useEffect, useRef } from 'react';

/** Components from ant design css framework */
import {
  Button,
  Modal,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Input,
  InputNumber,
  Progress,
  Popover,
  Icon,
  Empty
} from 'antd';
import { CloseCircleOutlined, UserOutlined } from '@ant-design/icons';
import SelectSearch from 'react-select-search';

/** import library for format numbers */
import NumberFormat from 'react-number-format';

/** Messaging for user notification util */
import {
  openNotification,
  helmet,
  handshake,
  reversPathTasks,
  getActivitiesforWeeklyPlan
} from '../../../../utils';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
/** Redux implementation */
import { useSelector } from 'react-redux';
import { userActions } from '../../../../redux/actions/userActions';

/** import textarea component auto grow */
import TextareaAutosize from 'react-autosize-textarea';

import { useDispatch } from 'react-redux';
import { capitalize } from 'lodash';
import { weeklyplanActions } from '../../../../redux/actions/weeklyplanActions';
import cloneDeep from 'lodash/cloneDeep';

import calendarModificationIconModal from '../../../../assets/img/activitymodification/calendar-modal.png';
import CustomCheckbox from '../../../CustomCheckbox';

/** PNG to put on users without image */
import fakeAvatar from '../../../../assets/img/fake_user.png';
import selectResponsablesIcon from '../../../../assets/img/select_responsables.png';

/** Date handler lib */
import moment from 'moment';

import { FixedSizeList as List } from 'react-window';

import { activityService } from '../../../../services/activity.service';
import {
  activityModificationService,
  notificationService
} from '../../../../services';
import { base } from '../../../../services/base';

import LazyElement from '../../../LazyElement';
import ProgressBar from '../../../ProgressBar';
import EditableInput from '../../../EditableInput';

/** import IconComponent for SVG imagen */
import IconComponent from '../../../Projects/IconSvg';
import { transformHourToDays } from '../../../../views/ganttContainer/gantt/gantt.helper';

import {
  calculatePonderators,
  calculateProgress,
  calculateExpectedForWeekByRangeLast,
  getEndDateByGantt,
  transformDateWithHour,
  defineTitleModification,
  defineDateUnfit,
  hasConstraint,
  hasUnfitProblem,
  renderFriend,
  calculateExpected,
  calculateExpectedForWeek,
  calculateExpectedCost,
  getRecursiveFromParentTask,
  splitText,
  splitTextDescription,
  formatMoney
} from '../../../../utils/lookahead-common';
import { ResourcesList } from '../../../ResourcesList';
import useSubtradeUser from '../../../../hooks/useSubtradeUser';
import { validateMutationFromSub } from '../../../LookaheadActivity/LookaheadActivity.helper';
import MoneySymbolString from '../../../MoneySymbolString';
import {
  totangoEventTracking,
  totangoSetAccountAttributes
} from '../../../../analytics/implements/totango';
import { getSignedUser } from '../../../../utils/userUtils';
const { TextArea } = Input;
/**
 * This component prints an header with activity data, and then renders it's tree tasks, allowing user to interact each row data.
 */
export default function WeeklyPlanActivity(props) {
  /** use props */
  const {
    lastLevelActivities,
    haveWeeklyPlan,
    dateRange,
    setVisibleFormSubcontract,
    setTypeResource,
    setVisibleFormResource,
    updateAsyncTask,
    updateAsyncActivity,
    belongsToRange,
    volatileTaskData,
    t,
    showDrawer,
    onClosecard,
    permission,
    deleteSubcontract,
    setActivityResource,
    ganttAPI,
    setTaskSelected,
    isFilterdData,
    calculateLeanStatus,
    handleAddCnc,
    subtradeRole
  } = props;

  const virtualizeRef = useRef();
  const dispatch = useDispatch();
  const navigatorLang = navigator.language || navigator.userLanguage;
  const [onlyRead, _setOnlyRead] = useState(permission == 'V');
  /** hooks */
  const projectState = useSelector((state) => state.projectState);
  const stateCompany = useSelector((state) => state.companyState);
  const [popsVisibility, setPopsVisibility] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  const [editedInput, setEditedInput] = useState(null);
  const { height, width } = useWindowDimensions();
  const [resources, setResources] = useState(lastLevelActivities?.resource);
  const [modalModification, setModalModification] = useState({
    visible: false,
    title: '',
    description: '',
    data: { activity: {}, task: {} }
  });
  const [filterText, setFilterText] = useState('');
  const sectorDateFormat = projectState.allSectors.find(
    (e) => e.id == projectState.sectorSelected
  );
  const formatDate =
    sectorDateFormat && sectorDateFormat.dateFormat
      ? sectorDateFormat.dateFormat
      : null;

  /** symbol currency by default  */
  const currency_symbol = MoneySymbolString() + ' ';

  useEffect(() => {
    const id = props.lastLvlActivity.id;
    if (props.scrollStates[id]) {
      if (virtualizeRef.current) {
        virtualizeRef.current.scrollTo(props.scrollStates[id]);
      }
    }

    return () => {
      if (virtualizeRef.current) {
        props.scrollStates[id] = virtualizeRef.current.state.scrollOffset;
      }
    };
  }, [virtualizeRef]);

  /**
   * This function calculates the commitment percentage for all tree recursively
   * @param {*} task Element which one was modified the commitment percentage
   * @param {*} parent Parent to start calculation of commitment percentage
   * @param {*} haveWeeklyPlanData Item containing weekly plan tasks
   */
  const calculateCommitment = async (
    task,
    parent,
    haveWeeklyPlanData = null,
    origen
  ) => {
    const childs = parent.tasks || parent.children;
    let percentaje = 0;
    /** Accum commit Parent = (Accum commit * ponderador) + sum hide task (progress * weight) */
    let percentaje_commitment = 0;
    childs &&
      childs.map((t) => {
        const toExtractFrom = t.editValues || t;
        let mult = toExtractFrom.commitment_percentaje;
        if (haveWeeklyPlanData) {
          const findWeekly = haveWeeklyPlanData.taskcommitments.find(
            (el) => el.taskId === t.id
          );
          mult = findWeekly?.commitment_percentaje;
        }
        if (toExtractFrom.showWeeklyPlan) {
          percentaje_commitment +=
            (toExtractFrom.ponderator / 100) * (mult || 0);
        } else {
          percentaje_commitment +=
            (toExtractFrom.ponderator / 100) * (toExtractFrom.progress || 0);
        }
        percentaje += (toExtractFrom.ponderator / 100) * (mult || 0);
      });

    const toExtractFromParent = parent.editValues || parent;
    if (isNaN(toExtractFromParent)) {
      toExtractFromParent.commitment_percentaje = percentaje_commitment;
    }
    if (parent.tasks) {
      parent.commitmentPercentaje = percentaje_commitment;
      parent.expectedweekAct = percentaje;
    }
    props.updateCommitment(task, task.commitment_percentaje);

    const newParentId = parent.parent_id || parent.activityId;
    if (newParentId) {
      const newParent = getTask(newParentId);
      if (newParent.length) {
        calculateCommitment(parent, newParent[0], haveWeeklyPlan, 'recursivo');
      }
    }
  };

  const getStringFromMoment = (date) => {
    if (typeof date == 'string') {
      return date;
    } else {
      return moment(date).format('YYYY/MM/DD H:mm');
    }
  };

  const recursiveGetStartForParent = (parent) => {
    const originalDate = new Date(getStringFromMoment(parent.start_date));

    let minStart;
    parent.children.map((el, index) => {
      const date = new Date(getStringFromMoment(el.start_date));
      if (!index) {
        minStart = date;
      } else {
        if (date.getTime() < minStart.getTime()) {
          minStart = date;
        }
      }
    });

    parent.start_date = minStart;
    if (originalDate.getTime() != minStart.getTime()) {
      updateAsyncTask(parent);
      updateState();
    }
  };

  const recursiveGetEndForParent = (parent) => {
    const originalDate = new Date(getStringFromMoment(parent.end_date));

    let maxEnd;
    parent.children.map((el, index) => {
      const date = new Date(getStringFromMoment(el.end_date));
      if (!index) {
        maxEnd = date;
      } else {
        if (date.getTime() > maxEnd.getTime()) {
          maxEnd = date;
        }
      }
    });

    parent.end_date = maxEnd;
    if (originalDate.getTime() != maxEnd.getTime()) {
      updateAsyncTask(parent);
      updateState();
    }
  };

  // avance traker
  const progress_traker = () => {
    const loggedUser = getSignedUser();
    const currentCompany = stateCompany.currentCompany;
    const project = projectState.allProjects.find(
      (p) => p.id == projectState.projectSelected
    );

    totangoSetAccountAttributes(
      loggedUser,
      projectState.projectSelected,
      currentCompany?.name,
      currentCompany?.id,
      project?.name,
      project?.stage,
      project?.country
    );

    totangoEventTracking(
      `p_${projectState.projectSelected}`,
      loggedUser,
      'Progress Input',
      'Lookahead'
    );
  };

  /**
   * This function handles the request acitivity modification
   * @param {*} task task which has problems of unfit dates
   * @param {*} activity activity to request modification
   */
  const handleActivityModificationRequest = (task, activity) => {
    setModalModification({
      ...modalModification,
      visible: true,
      title: t('modals.lookahead.activity_modification_request.title'),
      data: {
        task,
        activity
      }
    });
  };

  /**
   * This function search recursively inside of a array with elements with ID attr and children elements
   * @param {*} id id to find
   * @param {*} array array with children elements, and ID attr at his elements
   * @param {*} res Array to push result
   */
  const recursiveSearch = (id, array, res) => {
    array.map((el) => {
      if (el.id == id) {
        res.push(el);
      } else {
        recursiveSearch(id, el.children, res);
      }
    });
  };

  /**
   * This function search a task at state data by it ID
   * @param {*} task_id id of task to find the object
   * @param {*} taskParent parent to iterate inside it the search
   */
  const getTask = (task_id, taskParent = null) => {
    let res;
    if (task_id == props.lastLvlActivity.id) {
      res = [props.lastLvlActivity];
    } else if (!taskParent) {
      res = props.lastLvlActivity.tasks.filter((el) => el.id == task_id);
    }

    if (!res.length) {
      if (!taskParent) {
        taskParent = props.lastLvlActivity.tasks;
      } else {
        taskParent = taskParent.children;
      }
      const a = [];

      recursiveSearch(task_id, taskParent, a);
      return a;
    }

    return res;
  };

  /**
   * Clone deep of a new state to set and render view
   * @param {*} newState new state value (inmutability is broken)
   */
  const updateState = (newState = popsVisibility) => {
    setPopsVisibility((prev) => {
      prev = cloneDeep(newState);
      return prev;
    });
    dispatch(weeklyplanActions.notifyLookaheadUpdate());
  };

  /**
   * This function deals with pop visibility hash map
   * @param {boolean} visible Value to set the pop
   * @param {*} id Unique state eval to use at virtual DOM to show popup or hide it (from antd component)
   */
  const handlePopVisibility = (visible, id) => {
    popsVisibility[id] = visible;
    updateState();
  };

  /**
   * This  function opens a component to add constraints
   * @param {*} task task to add constraint
   */
  const openAddConstraint = (task) => {
    props.handleAddConstraint(task);
  };

  const openAddCnc = (task) => {
    props.handleAddCnc(task);
  };

  /**
   * This function receives a parent to check if a modal must be showed when he try to modify a auto ponderator
   * If user needs modify a ponderator, then it will be turned into custom ponderator system
   * @param {*} parent parent activity or task
   */
  const verifyCustomPonderator = (parent) => {
    if (!parent.hasCustomPonderator) {
      setShowModal(true);
      setSelectedParent(parent);
    }
  };

  /**
   * This function enable custom ponderator for an activity or task selected at component state
   */
  const disableAutoPonderator = () => {
    selectedParent.hasCustomPonderator = true;
    if (selectedParent.tasks) {
      activityService.update(selectedParent);
    } else {
      updateAsyncTask(selectedParent);
    }
    setShowModal(false);
  };

  const renderResourcesList = (objectData) => {
    return (
      <ResourcesList
        setTaskSelected={setTaskSelected}
        setVisibleFormResource={setVisibleFormResource}
        task={objectData.task}
        setFilterText={setFilterText}
        filterText={filterText}
        activity={objectData.activity}
        setTypeResource={setTypeResource}
        updateAsyncTask={updateAsyncTask}
        uniqueId={objectData.uniqueId}
        handlePopVisibility={handlePopVisibility}
        popsVisibility={popsVisibility}
        setPopsVisibility={setPopsVisibility}
        setResources={setResources}
        resources={resources}
        column={objectData.column}
        lastLevelActivities={props.lastLevelActivities}
        setActivityResource={setActivityResource}
        t={t}
        showBtnCreate={true}
        resourceData={objectData.data}
        typeResource={objectData.type}
      />
    );
  };

  /**
   * This functions shows a pretty alert to user
   * @param {*} data Object { title, message, type }
   */
  const notifyMessage = (data) => {
    const alertErrorMailExists = {
      title: data.title,
      description: data.message,
      type: data.type
    };
    openNotification(alertErrorMailExists);
  };

  const hideChilds = (parent) => {
    parent.hide_childs = true;
    updateState();
  };

  const showChilds = (parent) => {
    parent.hide_childs = false;
    updateState();
  };

  const getRecursiveDurationCalendarForParent = (parent, calendar) => {
    const originalValue = JSON.stringify(parent.duration);
    const duration = ganttAPI.calculateDuration(
      parent.start_date,
      parent.end_date,
      calendar
    );
    if (duration) {
      const total = transformHourToDays(duration);
      if (JSON.stringify(total) != originalValue) {
        parent.duration = total;
        updateAsyncTask(parent);
        updateState();
      }
    }
  };

  const getRecursiveDurationForParent = (parent) => {
    const originalValue = JSON.stringify(parent.sumDuration);
    let total = 0;
    parent.children.map((el) => {
      total += el.duration;
    });
    parent.sumDuration = total;

    if (JSON.stringify(total) != originalValue) {
      updateState();
    }
  };

  const defineCollapseIcon = (parent) => {
    const children = parent.tasks || parent.children;
    if (children) {
      if (children.length) {
        if (parent.hide_childs) {
          return (
            <i
              onClick={() => showChilds(parent)}
              className="fa fa-angle-down show-childs-btn"
              aria-hidden="true"
            />
          );
        } else {
          return (
            <i
              onClick={() => hideChilds(parent)}
              className="fa fa-minus hide-childs-btn"
              aria-hidden="true"
            />
          );
        }
      }
    }
  };

  /**
   * this function notifies every time the progress of a task is updated.
   * The accumulated commitment is updated.
   * @param {*} task Updated Task object
   */
  const updateTask = (task) => {
    let newCommit;
    if (task.total_quantity) {
      newCommit =
        (100 * task.quantity_parcial) / task.total_quantity + task.progress;
    } else {
      if (parseFloat(task.quantity_parcial_percentaje) > 0) {
        newCommit = task.quantity_parcial_percentaje + task.progress;
      } else {
        newCommit = task.progress;
      }
    }
    if (parseFloat(newCommit) > 100) {
      newCommit = 100;
      task.quantity_parcial = task.remaining_quantity;
    }
    task.commitment_percentaje = newCommit;

    /** bidirection real quantity */
    const actualQuanti = (task.progress / 100) * task.total_quantity;
    task.actual_quantity = actualQuanti;
  };

  const defineRestrictedTask = (task, released) => {
    const restricted =
      task.constraints.length && task.constraints.length != released.length;
    const copyOfLeanStatus = JSON.parse(JSON.stringify(task.lean_status));

    if (restricted && task.lean_status != 'Restricted') {
      task.status_before_restricted = task.lean_status;
      task.lean_status = 'Restricted';
    } else if (!restricted && task.lean_status == 'Restricted') {
      task.lean_status = 'Can';
    }

    if (copyOfLeanStatus != task.lean_status) {
      updateAsyncTask(task);
    }
    return restricted;
  };

  /**
   * This function defines what should be showed inside each task column at table.
   * This by using a flag from task, isEditing, when is active renders something, and when is not active
   * renders another jsx
   * @param {*} column Metadata from the column that is going to be extracted from the task
   * @param {*} task Task object to extract the column data
   * @param {*} activity Parent activity for tasks from first level
   * @param {*} parentTask Parent task if it is not from first level
   */
  const defineTaskColumnContent = (
    column,
    task,
    activity,
    parentTask,
    index
  ) => {
    const isOnlyReadElement =
      onlyRead ||
      (task.editablePermissionACP && !task.editablePermissionACP.editable);
    const released = task.constraints.filter(
      (constraint) => constraint.status == 'released'
    );
    const isTaskRestricted = defineRestrictedTask(task, released);
    let tagResource = '';
    let materialObject = null;
    let resourceMaterial_taks = null;
    // task.week_expected = 0
    // calculateExpectedRecurcive(task, parentTask || activity, null,activity)
    if (resources) {
      resourceMaterial_taks =
        resources &&
        resources.filter(function (rs) {
          return rs.type == 'material';
        });
      materialObject = resourceMaterial_taks.filter(
        (el) => el.id == task.materialId
      );
      tagResource = materialObject[0] ? materialObject[0].material_label : null;
    }
    if (column.data_type == 'string') {
      // calculateExpectedRecurcive(task, parentTask || activity, null,activity)
      if (column.name === 'description') {
        return (
          <span className="vertical-center">
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Popover
              placement="bottom"
              overlayClassName="container-image-custom"
              className="popoverPriority"
              content={
                <span>
                  <TextareaAutosize
                    className="text-area-tasks"
                    defaultValue={task[column.name]}
                    onChange={(e) => {
                      task[column.name] = e.target.value;
                      updateAsyncTask(task);
                    }}
                  />
                  <div
                    className="progress-massive-btn btn-apply-desc"
                    onClick={() => updateState()}>
                    {t('save_editable_tooltip')}
                  </div>
                </span>
              }
              trigger="click">
              {splitTextDescription(task, column, t)}
            </Popover>
          </span>
        );
      } else if (column.name == 'name') {
        let option = props.tableMetadata.filter(
          (el) => el.name == 'lean_status'
        );
        option = option[0];
        let background = option.from_values.filter(
          (el) => el.value == task.lean_status
        );
        background = background[0];

        return (
          <span
            className="vertical-center"
            onDoubleClick={() =>
              !isOnlyReadElement && showDrawer(task, props.lastLvlActivity)
            }>
            {isOnlyReadElement ? null : (
              <CustomCheckbox
                onClick={() => {
                  props.massiveSelectionHandler(task);
                  // updateState()
                }}
                active={task.active}
              />
            )}
            {task.parent_id ? (
              <i className="fas fa-level-up-alt arrow-child-tasks" />
            ) : null}
            <div
              className="lineStatus lookaheadLineStatus"
              style={{ background: background.color }}>
              &nbsp;
            </div>
            {defineCollapseIcon(task)}
            <EditableInput
              t={t}
              activity={props.lastLvlActivity}
              disabled={isOnlyReadElement}
              service={updateAsyncTask}
              onEdit={setEditedInput}
              isEditing={editedInput}
              renderEditable={(
                column,
                index,
                task,
                value,
                setValue,
                updateParentData = null,
                handleEsc = null
              ) => {
                return (
                  <Input
                    onKeyDown={handleEsc}
                    onPressEnter={updateParentData}
                    key={index}
                    id={column.name + task.id}
                    size="small"
                    value={value}
                    className="custom-input-planification"
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => setValue(e.target.value)}
                  />
                );
              }}
              updateState={updateState}
              index={index}
              column={column}
              task={task}
              // showDrawer={showDrawer}
              onClosecard={onClosecard}
            />
            <span style={{ float: 'right' }}>
              {hasConstraint(task, openAddConstraint, isOnlyReadElement, t)}
            </span>
          </span>
        );
      } else if (column.name == 'taskRoute') {
        return (
          <div
            className="vertical-center"
            style={{ display: 'flex', marginLeft: '1rem', paddingTop: '8px' }}>
            {splitText(
              reversPathTasks(`${activity.activityRoute} > ${activity.name}`),
              { ...column, view: 'weecklyplan' }
            )}
          </div>
        );
      }
    } else if (column.data_type == 'number') {
      let onChangeFunction;
      let onClick;
      let showElement = true;
      let disabled = false;
      const showTooltipOnDisabled = true;
      let symbol;
      const expWeek = calculateExpectedForWeek(
        task,
        ganttAPI,
        activity.calendarId,
        null,
        props.dateRange
      );

      task.expectedweek = expWeek;
      if (column.name == 'progress') {
        task.expected = calculateExpected(task, ganttAPI, activity.calendarId);
        let progressColor = '#52c41a';
        if (task.progress.toFixed(2) == '100.00') {
          progressColor = '#34AF00';
          task.Advancement = false;
          task.Overdue = false;
        } else if (task.progress > task.expected) {
          progressColor = '#2C3421';
          task.Advancement = true;
          task.Overdue = false;
        } else if (task.progress < task.expected) {
          progressColor = '#E50101';
          task.Overdue = true;
          task.Advancement = false;
        } else {
          progressColor = '#52c41a';
          task.Overdue = false;
          task.Advancement = false;
        }
        /**
         * Note: This code below blocks the tasks with children to progress recursive feature
         */
        let commitment_percentaje = task.commitment_percentaje;
        let compromiseIsOk = '';
        const isTaskNew = ['Committed'].includes(task.lean_status);
        if (haveWeeklyPlan && isTaskNew) {
          const find = haveWeeklyPlan.taskcommitments.find(
            (e) => e.taskId === task.id
          );
          if (find && !isNaN(find.commitment_percentaje)) {
            commitment_percentaje = find.commitment_percentaje;
          }
          compromiseIsOk =
            task.progress >= commitment_percentaje ? (
              <IconComponent
                data={handshake}
                width={12}
                fill="#34AF00"
                className="icon-hand-shake"
              />
            ) : (
              <IconComponent
                data={handshake}
                width={12}
                fill="#e50101"
                className="icon-hand-shake"
              />
            );
        }
        if (
          task.children.length /* || isTaskRestricted || */ ||
          isOnlyReadElement
        ) {
          const percentVal = task[column.name].toFixed
            ? task[column.name].toFixed(2)
            : task[column.name];
          return (
            <span className="vertical-center">
              <Progress
                strokeColor={{ '0%': progressColor, '100%': progressColor }}
                className="progress-custom-props"
                percent={parseFloat(percentVal)}
              />
              {haveWeeklyPlan && task.progress >= commitment_percentaje ? (
                <IconComponent
                  data={handshake}
                  width={12}
                  fill="#34AF00"
                  className="icon-hand-shake hand-progress-bar"
                />
              ) : (
                ''
              )}
            </span>
          );
        } else {
          return (
            <span className="vertical-center">
              <ProgressBar
                fillcolor={progressColor}
                calculateProgress={calculateProgress}
                calculatePonderators={calculatePonderators}
                progress_traker={progress_traker}
                projectState={projectState}
                updateAsyncActivity={updateAsyncActivity}
                updateAsyncTask={updateAsyncTask}
                updateRender={updateState}
                updateTask={updateTask}
                parent={parentTask || activity}
                task={task}
                activity={activity}
                column={column}
              />
              {compromiseIsOk}
            </span>
          );
        }
      } else if (column.name == 'quantity_parcial') {
        /** update remaining quantity  */
        const remainQuant = task.total_quantity - task.actual_quantity;
        if (task.remaining_quantity != remainQuant) {
          task.remaining_quantity = remainQuant;
          updateAsyncTask(task);
        }

        /**
         * Note: This code below blocks the tasks with children to progress recursive feature
         */
        let quantity_parcial = 0;
        if (haveWeeklyPlan) {
          const find = haveWeeklyPlan.taskcommitments.find(
            (e) => e.taskId === task.id
          );
          if (find && !isNaN(find.commitment_percentaje)) {
            quantity_parcial = find.current_commitment_partial;
          }
          return quantity_parcial ? (
            <span className="vertical-center">
              {parseFloat(quantity_parcial).toFixed(2)}{' '}
              {tagResource ? tagResource.material_label : null}
            </span>
          ) : (
            <span className="vertical-center">-</span>
          );
        } else {
          if (!isNaN(task.commitment_percentaje)) {
            quantity_parcial =
              ((task.commitment_percentaje - task.progress) *
                task.total_quantity) /
              100;
            if (task.commitment_percentaje < task.progress) {
              quantity_parcial = 0;
            }
          }
        }
        task.current_commitment_partial =
          parseFloat(quantity_parcial).toFixed(2);

        if (task.commitment_percentaje < task.progress) {
          let newCompromise = task.progress;
          if (task.total_quantity) {
            newCompromise =
              task.progress + (quantity_parcial * 100) / task.total_quantity;
          }
          task.commitment_percentaje = newCompromise;
          quantity_parcial = 0;
        }

        /** Bug fix: 843 */
        task.quantity_parcial = parseFloat(quantity_parcial).toFixed(2);
        volatileTaskData[task.id] = {
          ...volatileTaskData[task.id],
          quantity_parcial: task.quantity_parcial
        };

        onChangeFunction = (e) => {
          const quantity_parcial = e;
          if (
            parseFloat(quantity_parcial) > parseFloat(task.remaining_quantity)
          ) {
            notifyMessage({
              title: t('not_valid_committed_title'),
              message: t('not_upper_remaining'),
              type: 'error'
            });
            return false;
          }
          let newCompromise = task.progress;
          if (task.total_quantity) {
            newCompromise =
              task.progress + (quantity_parcial * 100) / task.total_quantity;
          }

          /** Get do element to save it state on re render */
          task.commitment_percentaje = newCompromise;
          task.quantity_parcial = quantity_parcial;
        };
      } else if (column.name == 'cost') {
        if (task.children.length) {
          getRecursiveFromParentTask(
            task,
            'cost',
            updateState,
            updateAsyncTask
          );
        }

        onChangeFunction = (e) => {
          /** Get do element to save it state on re render */
          task[column.name] = e;

          /** Calculates ponderators feature */
          calculatePonderators(
            parentTask || activity,
            activity,
            updateAsyncTask,
            projectState
          );

          /** Apply changes */
          updateAsyncTask(task);
        };
      } else if (column.name == 'duration') {
        if (task.children.length) {
          /** Sumatorry of acum duration for ponderator */
          getRecursiveDurationForParent(task);
          getRecursiveDurationCalendarForParent(task, activity.calendar_id);
        }
        onChangeFunction = (e) => {
          /** Get do element to save it state on re render */
          task[column.name] = e;

          /** HEre is losing render binding    */

          /** We give the lookahead task to gantt API to get duration and end_date */
          getEndDateByGantt(task);

          /** Calculates ponderators feature */
          calculatePonderators(
            parentTask || activity,
            activity,
            updateAsyncTask,
            projectState
          );

          /** Apply changes */
          updateAsyncTask(task);
        };
      } else if (column.name == 'expected') {
        task.expected = calculateExpected(task, ganttAPI, activity.calendarId);
        return (
          <span className="vertical-center">
            {t('lang') === 'en'
              ? task.expected.toFixed(2) + '%'
              : task.expected.toFixed(2).replace('.', ',') + '%'}
          </span>
        );
      } else if (column.name == 'expectedweek') {
        return (
          <span className="vertical-center">
            {t('lang') === 'en'
              ? expWeek.toFixed(2) + '%'
              : expWeek.toFixed(2).replace('.', ',') + '%'}
          </span>
        );
      } else if (column.name == 'expected_cost') {
        task.expected_cost = calculateExpectedCost(
          task,
          ganttAPI,
          activity.calendarId
        );
        return (
          <span className="vertical-center">
            <NumberFormat
              value={task.expected_cost}
              decimalScale={2}
              thousandSeparator={navigatorLang.includes('en') ? ',' : '.'}
              decimalSeparator={navigatorLang.includes('en') ? '.' : ','}
              displayType={'text'}
              prefix={
                ['expected_cost'].includes(column.name) ? currency_symbol : null
              }
            />
          </span>
        );
      } else if (column.name == 'ponderator') {
        onClick = () => verifyCustomPonderator(parentTask || activity);
        disabled = !(parentTask || activity).hasCustomPonderator;
        onChangeFunction = (e) => {
          if (e) {
            const inputBackup = cloneDeep(task[column.name]);
            task[column.name] = e;

            const { showCustomPonderator, sumPonderators } = getPonderatorSum(
              parentTask || activity
            );

            if (sumPonderators >= 100.00545454545457) {
              task[column.name] = inputBackup;
            }

            /** Apply changes */
            updateAsyncTask(task);
          }
        };
        if (task.progress != 0) {
          showElement = false;
        }
      } else if (column.name == 'commitment_percentaje') {
        if (parseFloat(task[column.name]) > 100) {
          task[column.name] = 100;
        }
        symbol = '%';
        onChangeFunction = async (e) => {
          /** validate commitment < progress */
          if (parseFloat(e) <= parseFloat(task.progress)) {
            notifyMessage({
              title: t('not_valid_committed_title'),
              message: t('not_upper_committed'),
              type: 'error'
            });
          } else if (parseFloat(e) > 100) {
            notifyMessage({
              title: t('not_valid_committed_title'),
              message: t('not_upper_committed_max'),
              type: 'error'
            });
          } else {
            task[column.name] = e;
            calculateCommitment(
              task,
              parentTask || activity,
              null,
              'column activity'
            );
          }
        };
        if (haveWeeklyPlan) {
          if (activity.recalcCommitment) {
            calculateCommitment(
              task,
              parentTask || activity,
              haveWeeklyPlan,
              'column activity haveweek'
            );
          }

          const find = haveWeeklyPlan.taskcommitments.find(
            (e) => e.taskId === task.id
          );
          if (find && !isNaN(find.commitment_percentaje)) {
            const valueColumn = find.commitment_percentaje || 0;
            let percentVal = valueColumn.toFixed
              ? valueColumn.toFixed(2)
              : valueColumn;
            /** Bug fix: 843 */
            volatileTaskData[task.id] = {
              ...volatileTaskData[task.id],
              commitment_percentaje: find.commitment_percentaje
            };
            if (parseFloat(percentVal) > 100) {
              percentVal = 100;
            }
            return (
              <span className="vertical-center">
                {t('lang') === 'en'
                  ? parseFloat(percentVal) + '%'
                  : parseFloat(percentVal).toFixed(2).replace('.', ',') + '%'}
              </span>
            );
          }
        }
        if (task.children.length) {
          const valueColumn = task[column.name] || 0;
          const percentVal = valueColumn.toFixed
            ? valueColumn.toFixed(2)
            : valueColumn;

          /** Bug fix: 843 */
          volatileTaskData[task.id] = {
            ...volatileTaskData[task.id],
            commitment_percentaje: percentVal
          };
          return (
            <span className="vertical-center">
              {t('lang') === 'en'
                ? parseFloat(percentVal).toFixed(2) + '%'
                : parseFloat(percentVal).toFixed(2).replace('.', ',') + '%'}
            </span>
          );
        }
        calculateCommitment(
          task,
          parentTask || activity,
          haveWeeklyPlan,
          'valor undefined'
        );
      } else if (column.name == 'expected') {
        task.expected = calculateExpected(task, ganttAPI, activity.calendarId);
        return (
          <span className="vertical-center">
            {t('lang') === 'en'
              ? task.expected.toFixed(2) + '%'
              : task.expected.toFixed(2).replace('.', ',') + '%'}
          </span>
        );
      } else if (column.name == 'total_quantity') {
        if (task.children.length) {
          getRecursiveFromParentTask(
            task,
            'total_quantity',
            updateState,
            updateAsyncTask
          );
        }
        onChangeFunction = (e) => {
          /** Get do element to save it state on re render */
          task[column.name] = e;
          const actualQuanti = (task.progress / 100) * task.total_quantity;
          task.actual_quantity = actualQuanti;
          /** Apply changes */
          updateAsyncTask(task);
        };
      } else if (column.name == 'actual_quantity') {
        onChangeFunction = (e) => {
          /** Get do element to save it state on re render */
          let newProgress = 0;
          if (task.total_quantity) {
            newProgress = (100 * e) / task.total_quantity;
          }
          if (newProgress > 100) {
            task.progress = 100;
            task.actual_quantity = task.total_quantity;
          } else {
            task.progress = newProgress;
            task[column.name] = e;
          }
          /** Apply changes */
          updateAsyncTask(task);
          calculateProgress(
            null,
            parentTask || activity,
            activity,
            updateAsyncTask,
            updateAsyncActivity
          );
        };
      } else if (column.name == 'quantity_parcial_percentaje') {
        if (haveWeeklyPlan) {
          const find = haveWeeklyPlan.taskcommitments.find(
            (e) => e.taskId === task.id
          );
          if (find && !isNaN(find.commitment_percentaje)) {
            let valueColumn = find.commitment_percentaje || 0;
            valueColumn -= find.current_progress_task;
            const percentVal = valueColumn.toFixed
              ? valueColumn.toFixed(2)
              : valueColumn;
            task.quantity_parcial_percentaje = percentVal;
            return (
              <span className="vertical-center">
                {t('lang') === 'en'
                  ? parseFloat(percentVal).toFixed(2) + '%'
                  : parseFloat(percentVal).toFixed(2).replace('.', ',') + '%'}
              </span>
            );
          }
        }

        if (task.commitment_percentaje < task.progress) {
          let quantity_parcial = 0;
          let newCompromise = task.progress;
          if (task.total_quantity) {
            newCompromise =
              task.progress + (quantity_parcial * 100) / task.total_quantity;
          }
          task.commitment_percentaje = newCompromise;

          quantity_parcial = 0;
        }

        const quantity_parcial_percentaje = parseFloat(
          parseFloat(task.commitment_percentaje || 0) -
            parseFloat(task.progress)
        );
        task.quantity_parcial_percentaje = quantity_parcial_percentaje;
        return (
          <span className="vertical-center">
            {t('lang') === 'en'
              ? parseFloat(quantity_parcial_percentaje).toFixed(2) + '%'
              : parseFloat(quantity_parcial_percentaje)
                  .toFixed(2)
                  .replace('.', ',') + '%'}
          </span>
        );
      } else {
        onChangeFunction = (e) => {
          task[column.name] = e;
          updateAsyncTask(task);
        };
      }

      if (isOnlyReadElement) {
        if (!task[column.name].toFixed) {
          return <div> - </div>;
        }

        return (
          <span
            className="vertical-center"
            style={{ backgroundColor: 'transparent' }}>
            {t('lang') === 'en'
              ? formatMoney(parseFloat(task[column.name]), '', 2, '.', ',')
              : formatMoney(parseFloat(task[column.name]), '', 2, ',', '.')}
          </span>
        );
      }

      /** This case is to active custom ponderator */
      if (showElement && disabled) {
        return (
          <span
            className="vertical-center"
            style={{ cursor: 'pointer', backgroundColor: '#d8d8d8' }}
            onClick={onClick}>
            {showTooltipOnDisabled ? (
              <Tooltip
                title="This task has auto ponderator (click to apply custom ponderator)"
                key={index}>
                {Number.isInteger(task[column.name])
                  ? task[column.name]
                  : task[column.name].toFixed(2)}
              </Tooltip>
            ) : (
              <>
                {Number.isInteger(task[column.name])
                  ? task[column.name]
                  : task[column.name].toFixed(2)}
              </>
            )}
          </span>
        );
        /** This case is for change custom ponderator */
      } else if (task.children.length > 0 && column.name != 'ponderator') {
        return (
          <span className="vertical-center">
            {['actual_quantity', 'quantity_parcial'].includes(column.name) ? (
              <NumberFormat
                value={task[column.name]}
                thousandSeparator={t('ĺang') === 'en' ? ',' : '.'}
                decimalSeparator={t('ĺang') === 'en' ? '.' : ','}
                displayType={'text'}
                prefix={
                  ['cost', 'expected_cost'].includes(column.name)
                    ? currency_symbol
                    : null
                }
              />
            ) : (
              <NumberFormat
                value={task[column.name]}
                decimalScale={2}
                thousandSeparator={t('ĺang') === 'en' ? ',' : '.'}
                decimalSeparator={t('ĺang') === 'en' ? '.' : ','}
                displayType={'text'}
                prefix={
                  ['cost', 'expected_cost'].includes(column.name)
                    ? currency_symbol
                    : null
                }
              />
            )}
          </span>
        );
      } else if (showElement && !disabled) {
        return (
          <span
            className={`vertical-center
                            ${
                              task.commitment_percentaje === task.progress &&
                              column.name === 'commitment_percentaje' &&
                              task.progress !== 100
                                ? 'editable-input-danger'
                                : null
                            }`}
            style={{ paddingLeft: 19 }}>
            <EditableInput
              t={t}
              service={updateAsyncTask}
              onEdit={setEditedInput}
              isEditing={editedInput}
              key={index}
              onSave={onChangeFunction}
              isFloat={true}
              renderEditable={(
                column,
                index,
                task,
                value,
                setValue,
                updateParentData = null,
                handleEsc = null
              ) => (
                <>
                  <InputNumber
                    onKeyDown={handleEsc}
                    onPressEnter={updateParentData}
                    className="custom-input-number-planification ant-input-number-focused"
                    disabled={disabled}
                    min={0}
                    name={column.name + task.id}
                    id={column.name + task.id}
                    precision={column.name === 'plan_endowment' ? 0 : 2}
                    step={0.1}
                    defaultValue={task[column.name]}
                    onChange={(e) => setValue(e)}
                    onFocus={(e) => e.target.select()}
                  />
                </>
              )}
              updateState={updateState}
              index={index}
              column={column}
              task={task}
              round={false}
              symbol_suffix={
                ['commitment_percentaje'].includes(column.name) ? '%' : null
              }
              symbol={
                ['cost', 'expected_cost'].includes(column.name)
                  ? currency_symbol
                  : null
              }
            />
            {['total_quantity', 'actual_quantity', 'quantity_parcial'].includes(
              column.name
            )
              ? tagResource
                ? ' ' + tagResource
                : null
              : null}
          </span>
        );

        /** Ponderator with progress */
      } else {
        return (
          <span className="vertical-center">
            <Tooltip
              title="This ponderator has progress, cannot be moved)"
              key={index}>
              {Number.isInteger(task[column.name])
                ? task[column.name]
                : task[column.name].toFixed(2)}
            </Tooltip>
          </span>
        );
      }
    } else if (column.data_type == 'date') {
      if (task.children.length) {
        recursiveGetEndForParent(task);
      }

      if (column.name == 'end_date') {
        const disabled = haveWeeklyPlan !== false;
        let dateObject;
        if (task[column.name].split) {
          dateObject = new Date(task[column.name]);
        } else {
          dateObject = cloneDeep(task[column.name]);
        }

        return (
          <span className="vertical-center">
            {moment(dateObject.toString()).format(formatDate)}
            {hasUnfitProblem(
              task,
              activity,
              updateAsyncTask,
              handleActivityModificationRequest,
              isOnlyReadElement,
              t
            )}
          </span>
        );
      } else if (column.name == 'start_date') {
        if (task.children.length) {
          recursiveGetStartForParent(task);
        }

        const disabled = false;
        let dateObject;
        if (task[column.name].split) {
          dateObject = new Date(task[column.name]);
        } else {
          dateObject = cloneDeep(task[column.name]);
        }
        dateObject.setHours(0);
        return disabled ? (
          <span className="vertical-center">
            {dateObject.toISOString().split('T')[0].split('-').join('/')}
          </span>
        ) : (
          <span className="vertical-center-dates">
            <DatePicker
              disabled={task.children.length || isOnlyReadElement}
              id={column.name + task.id}
              allowClear={false}
              className="custom-date-picker-planification"
              defaultValue={moment(task[column.name])}
              format={formatDate}
              onChange={(date, dateString) => {
                task[column.name] = moment(dateString, formatDate).format(
                  'YYYY/MM/DD'
                );
                getEndDateByGantt(task);
                /** Apply changes */
                updateAsyncTask(task);
                updateState();
                const belongs = belongsToRange(
                  moment(dateString, 'YYYY/MM/DD'),
                  [{ ...dateRange }]
                );
                if (!belongs) {
                  const newData = getActivitiesforWeeklyPlan(
                    props.activities,
                    dateRange
                  );
                  props.updateRender(newData);
                }
              }}
            />
          </span>
        );
      }
    } else if (column.data_type == 'array/images') {
      const uniqueId = task.id + column.name;
      const friends = [];
      const selected = [];
      const dictionary = {};

      task[column.name].map((res) => {
        selected.push(res[column.el_to_extract_from]);
      });

      props[column.from_values].map((user) => {
        if (user.is_active) {
          friends.push({
            name:
              user[column.el_to_label_from[0]] +
              ' ' +
              user[column.el_to_label_from[1]],
            value: user[column.el_to_extract_from],
            photo: user[column.img_from] || fakeAvatar,
            object: user
          });
          dictionary[user[column.el_to_extract_from]] = user;
        }
      });

      if (isOnlyReadElement) {
        return (
          <div
            className="custom-multi-select-pop"
            style={{
              width: column.width ? column.width : '100%',
              height: '100%',
              marginTop: 0,
              border: 0
            }}>
            {task[column.name].length ? (
              task[column.name].map((responsable, index) => {
                return (
                  <span className="vertical-center" key={index}>
                    <Tooltip
                      title={`${responsable.name} ${responsable.lastname}`}>
                      {responsable[column.img_from] ? (
                        <img
                          className="img-responsable-lookahead"
                          src={responsable[column.img_from]}
                        />
                      ) : (
                        <div className="img-responsable-lookahead no-img">
                          {responsable.name ? responsable.name[0] : ''}
                          {responsable.lastname ? responsable.lastname[0] : ''}
                        </div>
                      )}
                    </Tooltip>
                  </span>
                );
              })
            ) : (
              <span className="vertical-center">
                <Tooltip
                  title={
                    isOnlyReadElement
                      ? t('non_allow_responsable_tooltip')
                      : t('select_responsable_tooltip')
                  }
                  style={isOnlyReadElement ? {} : {}}>
                  <img src={selectResponsablesIcon} width={15} />
                </Tooltip>
              </span>
            )}
          </div>
        );
      }
      return (
        <div
          className="custom-multi-select-pop"
          style={{
            width: column.width ? column.width : '100%',
            height: '100%',
            marginTop: 0,
            border: 0
          }}>
          <Popover
            overlayClassName="container-image-custom"
            className="popoverPriority"
            content={
              <SelectSearch
                className="select-search select-search--multiple"
                options={friends}
                value={selected}
                renderOption={renderFriend}
                onChange={async (val) => {
                  task[column.name] = [];
                  val.map((op) => {
                    task[column.name].push(dictionary[op]);
                  });
                  const user = getSignedUser();
                  updateAsyncTask(task);
                  task.sectorId = projectState.sectorSelected;
                  for (let i = 0; i < task.responsables.length; i++) {
                    await notificationService.createConstraint({
                      user,
                      constraint: task,
                      module: 'tasks',
                      type_notification: 'assign_responsible',
                      userId: task.responsables[i].id
                    });
                  }
                  dispatch(userActions.setUserUpdate());
                  updateState();
                }}
                multiple
                search
                placeholder={t('search_responsable_placeholder')}
              />
            }
            trigger="click">
            {task[column.name].length ? (
              task[column.name].map((responsable, index) => {
                return (
                  <span className="vertical-center" key={index}>
                    <Tooltip
                      title={`${responsable.name} ${responsable.lastname}`}>
                      {responsable[column.img_from] ? (
                        <img
                          className="img-responsable-lookahead"
                          src={responsable[column.img_from]}
                        />
                      ) : (
                        <div className="img-responsable-lookahead no-img">
                          {responsable.name ? responsable.name[0] : ''}
                          {responsable.lastname ? responsable.lastname[0] : ''}
                        </div>
                      )}
                    </Tooltip>
                  </span>
                );
              })
            ) : (
              <span className="vertical-center">
                <Tooltip title={t('select_responsable_tooltip')}>
                  <img src={selectResponsablesIcon} width={15} />
                </Tooltip>
              </span>
            )}
          </Popover>
        </div>
      );
    } else if (column.data_type == 'array/string') {
      if (column.name === 'subcontractId') {
        const uniqueId = task.id + column.name;
        const statusObject =
          props.subContracts &&
          props.subContracts.find((e) => e.id === task[column.name]);
        const colorObject = statusObject ? statusObject.color : null;

        if (
          isOnlyReadElement ||
          (subtradeRole && subtradeRole.isSubtradeRole)
        ) {
          return (
            <div style={{ height: '100%' }}>
              <span className="vertical-center">
                {statusObject ? (
                  <span
                    className="subcontract-border"
                    style={{
                      border: '1px solid ' + colorObject,
                      color: colorObject,
                      padding: 5,
                      borderRadius: 5
                    }}>
                    <IconComponent
                      style={{ marginRight: 6, position: 'relative', top: 2 }}
                      data={helmet}
                      width={15}
                      fill={colorObject}
                      className="icon-subcontract"
                    />
                    {statusObject.name}
                  </span>
                ) : (
                  <span className="">-</span>
                )}
              </span>
            </div>
          );
        }

        return (
          <div
            className="customPlanificationBlock priorityFlag subcontract-wrapper"
            style={{ height: '100%', marginTop: 0, border: 0 }}>
            <Popover
              overlayClassName="popover-subcontract"
              className="popover-subcontract"
              placement="bottom"
              content={
                <div
                  className="subcontracts-options"
                  style={{ maxHeight: 600, overflow: 'auto' }}>
                  {props.subContracts.map((option, index) => {
                    return (
                      <span
                        className="item"
                        key={index}
                        onClick={() => {
                          task[column.name] = option.id;

                          if (
                            subtradeRole.subtrade &&
                            JSON.stringify(option.id) !==
                              JSON.stringify(subtradeRole.subtrade.id)
                          ) {
                            validateMutationFromSub(subtradeRole, task);
                          }

                          updateAsyncTask(task);
                          handlePopVisibility(false, uniqueId);
                          updateState();
                        }}>
                        <span style={{ marginLeft: 5 }}>
                          <span
                            className="subcontract-options-border"
                            style={{
                              border: '1px solid ' + option.color,
                              color: option.color
                            }}>
                            <IconComponent
                              data={helmet}
                              width={15}
                              fill={option.color}
                              className="icon-options-subcontract"
                            />
                            {capitalize(option.name)}
                          </span>
                        </span>
                      </span>
                    );
                  })}
                  <span
                    className="add-subcontract-inline"
                    onClick={() => setVisibleFormSubcontract(true)}>
                    {t('create_subcontract_label')}
                  </span>
                </div>
              }
              trigger="click">
              <div style={{ height: '100%' }}>
                <span className="vertical-center">
                  {statusObject ? (
                    <span
                      className="subcontract-border"
                      style={{
                        border: '1px solid ' + colorObject,
                        color: colorObject
                      }}>
                      <IconComponent
                        data={helmet}
                        width={15}
                        fill={colorObject}
                        className="icon-subcontract"
                      />
                      {statusObject.name}
                    </span>
                  ) : (
                    <span className="">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  )}
                </span>
              </div>
            </Popover>
            {statusObject ? (
              <Tooltip title={t('weekly_remove_subcontract')}>
                <CloseCircleOutlined
                  className="delete-subcontract"
                  onClick={() => {
                    validateMutationFromSub(subtradeRole, task);
                    deleteSubcontract(task, props.lastLvlActivity);
                  }}
                />
              </Tooltip>
            ) : null}
          </div>
        );
      } else if (column.name === 'lean_status') {
        if (isTaskRestricted) {
          task.restricted = true;
          return (
            <div
              style={{
                backgroundColor: '#E50101',
                color: 'white',
                height: '100%'
              }}>
              <span className="vertical-center">{t('restricted_label')}</span>
            </div>
          );
        } else {
          const uniqueId = task.id + column.name;
          const leanObject = column.from_values.filter(
            (el) => el.value == task[column.name]
          );
          const background = leanObject[0].color;
          const disabled = haveWeeklyPlan !== false;
          const content = (
            <div
              style={{
                backgroundColor: background,
                color: 'white',
                height: '100%'
              }}>
              <span className="vertical-center">{leanObject[0].label}</span>
            </div>
          );

          if (isOnlyReadElement) {
            return (
              <div
                style={{
                  backgroundColor: background,
                  color: 'white',
                  height: '100%'
                }}>
                <span className="vertical-center">{leanObject[0].label}</span>
              </div>
            );
          }
          const isTaskNew = ['Committed'].includes(task.lean_status);
          return (
            <div
              className="customPlanificationBlock priorityFlag fix-status-weeklyplan "
              style={{ height: '100%', marginTop: 0, border: 0 }}>
              {disabled && isTaskNew ? (
                content
              ) : (
                <Popover
                  placement="bottom"
                  overlayClassName="popoverPriority"
                  className="popoverPriority"
                  content={
                    <div className="priorityOptions">
                      {column.from_values.map((option, index) => {
                        if (task.lean_status === 'Committed') return null;
                        if (
                          option.value == 'Committed' ||
                          option.value == 'Restricted'
                        )
                          return null;
                        return (
                          <span
                            className="item"
                            key={index}
                            onClick={() => {
                              /** check if childs have lean_status = 'Will' */
                              const childsHaveLeanStatusWill =
                                task.children.some(
                                  (el) => el.lean_status === 'Will'
                                );
                              if (!childsHaveLeanStatusWill) {
                                // updateAsyncTask(task)
                                task[column.name] = option.value;
                                updateAsyncTask(task);
                                calculateLeanStatus(
                                  task,
                                  parentTask || activity,
                                  activity,
                                  updateAsyncTask,
                                  updateAsyncActivity
                                );
                                handlePopVisibility(false, uniqueId);
                                const excludeStatusLean = [
                                  'Will',
                                  'Committed'
                                ].includes(option.value);
                                if (!excludeStatusLean) {
                                  const newData = getActivitiesforWeeklyPlan(
                                    props.activities,
                                    dateRange
                                  );
                                  /** calculate tree lean_status */
                                  props.updateRender(newData);
                                }
                              } else {
                                notifyMessage({
                                  title: t('modules.weeklyplan'),
                                  message: t('have_childs_will'),
                                  type: 'warning'
                                });
                              }
                              handlePopVisibility(false, uniqueId);
                            }}>
                            <i
                              className="fas fa-circle"
                              style={{
                                fontSize: 11,
                                color: option.color,
                                position: 'relative',
                                top: -1
                              }}
                            />
                            <span style={{ marginLeft: 5 }}>
                              {capitalize(option.label)}
                            </span>
                          </span>
                        );
                      })}
                    </div>
                  }
                  trigger="click">
                  {content}
                </Popover>
              )}
            </div>
          );
        }
      } else if (column.name == 'materialId') {
        const uniqueId = task.id + column.name;
        const background = 'white';
        if (isOnlyReadElement) {
          return (
            <span className="vertical-center">
              {materialObject.length > 0
                ? materialObject[0].name
                : t('not_assigned_general')}
            </span>
          );
        }
        return renderResourcesList({
          type: 'material',
          data: resourceMaterial_taks,
          task,
          activity,
          uniqueId,
          column
        });
      }
    } else if (column.data_type == 'array/icon') {
      const iconToShow = column.from_values.filter(
        (f) => f.value == task[column.name]
      );
      const uniqueId = task.id + column.name;
      var titleStatus = '';
      const defineIcon = (option) => {
        if (column.name == 'priority') {
          return <img src={option.icon} width={12} />;
        } else {
          titleStatus = option.value;
          return (
            <i
              className={option.icon}
              style={{
                fontSize: 11,
                color: option.color,
                position: 'relative',
                top: -2
              }}
            />
          );
        }
      };
      var tooltipTitle = '';

      if (column.name === 'priority') {
        const tooltipDescription = column.from_values.filter(
          (option) => option.value === task[column.name]
        );
        tooltipTitle = tooltipDescription[0].description;
      }

      if (isOnlyReadElement) {
        return (
          <span className="vertical-center">{defineIcon(iconToShow[0])}</span>
        );
      }
      return (
        <div
          className="customStr priorityFlag custom-select-planification-position"
          style={{ height: '100%', marginTop: 0, border: 0 }}>
          <Tooltip title={capitalize(tooltipTitle)} placement="top">
            <Popover
              placement="bottom"
              overlayClassName="popoverPriority"
              className="popoverPriority"
              content={
                <div className="priorityOptions">
                  {column.from_values.map((option, index) => {
                    return (
                      <span
                        className="item"
                        key={index}
                        onClick={() => {
                          task[column.name] = option.value;
                          updateAsyncTask(task);
                          handlePopVisibility(false, uniqueId);
                        }}>
                        {defineIcon(option)}
                        {titleStatus}
                        <span style={{ marginLeft: 5 }}>
                          {capitalize(option.label)}
                        </span>
                      </span>
                    );
                  })}
                </div>
              }
              trigger="click">
              <span className="vertical-center">
                {defineIcon(iconToShow[0])}
              </span>
            </Popover>
          </Tooltip>
        </div>
      );
    }
  };

  /**
   * This function renders the column container, and then goes to defineTaskColumnContent function
   * to define what render inside
   * @param {*} column Metadata from the column that is going to be extracted the task
   * @param {*} task Task object to extract the column data
   * @param {*} tabulation Integer to add as marginLeft to display tree structure
   * @param {*} index Index from the array iteration
   * @param {*} activity Parent activity for tasks from first level
   * @param {*} parentTask Parent task if it is not from first level
   */
  const renderTaskColumn = (
    column,
    task,
    tabulation,
    index,
    activity,
    parentTask,
    lvl
  ) => {
    let auxTabulation = tabulation;

    if (lvl >= 2) {
      const paddingMultiplierFix = 0.1 * (lvl + 1);
      auxTabulation = tabulation + tabulation * paddingMultiplierFix;
    }

    if (column.visible) {
      return (
        <Col
          className={
            column.name == 'name'
              ? 'fit-name-superposition'
              : 'single-task-column-style'
          }
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            paddingLeft: column.name == 'name' ? auxTabulation : 0,
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineTaskColumnContent(column, task, activity, parentTask, index)}
        </Col>
      );
    }
  };

  /**
   * Recursive function to display at virtual dom the tree nested table format
   * @param {*} tasks Array of tasks
   * @param {*} finalArray Final array to display JSX at virtual dom
   * @param {*} activity Parent activity
   * @param {*} tabulation Padding left to display tree table format
   * @param {*} parentTask Instance of the parent task if it is not from first lvl
   */
  const renderChildTreeTasks = (
    tasks,
    finalArray,
    activity,
    tabulation,
    parentTask = null,
    lvl = 0
  ) => {
    tasks.map((task) => {
      const childs = task.children;
      const hasChilds = childs.length != 0;
      const originalStatus = cloneDeep(task.status);
      if (task.progress == 0) {
        task.status = 'Waiting';
      } else if (task.progress > 0 && task.progress < 100) {
        task.status = 'Doing';
      } else {
        task.status = 'Done';
      }

      if (originalStatus != task.status) {
        updateAsyncTask(task);
      }

      if (!task.hide && task.showWeeklyPlan) {
        if (props.resizing) {
          finalArray.push(
            <Row key={task.id} className="fit-at-middle-row"></Row>
          );
        } else {
          finalArray.push(
            <Row key={task.id} className="fit-at-middle-row">
              {props.tableMetadata.map((eachColumn, index) => {
                return renderTaskColumn(
                  eachColumn,
                  task,
                  tabulation || 0,
                  index,
                  activity,
                  parentTask,
                  lvl
                );
              })}
            </Row>
          );
        }
      }

      if (hasChilds && !task.hide_childs) {
        const newLevel = lvl + 1;
        renderChildTreeTasks(
          childs,
          finalArray,
          activity,
          tabulation + (task.hide ? 0 : 15),
          task,
          newLevel
        );
      }
    });
  };

  /** This function is used for show tasks quantity on virtual dom who renders them and resize height */
  const defineVirtualizedHeight = (taskCounter) => {
    const tempHeight = taskCounter * 40;
    const maxHeight = height < 700 ? height * 0.45 : height * 0.6;
    if (tempHeight < maxHeight) {
      return tempHeight;
    }
    return maxHeight;
  };

  const [renderedChildsCounter, setRenderedChildsCounter] = useState(0);
  useEffect(() => {
    props.virtualizeRef.current.resetAfterRowIndex(props.index);
  }, [renderedChildsCounter]);

  /**
   * This function is used by virtual dom to render tasks associated to activity
   * @param {*} activity Activity object to show his child tasks
   */
  const renderTasks = (activity) => {
    const childRended = [];
    renderChildTreeTasks(activity.tasks, childRended, activity, 15);

    activity.childRended = childRended.length;

    if (renderedChildsCounter != childRended.length) {
      setRenderedChildsCounter(childRended.length);
    }

    /**
     * To Optimize render of this child list an option is
     * https://react-window.now.sh/#/examples/list/variable-size
     * with childRended, we can create a virtual list to handle performance
     */

    if (activity.hide_childs) {
      return null;
    } else if (childRended.length) {
      return (
        <List
          ref={virtualizeRef}
          height={defineVirtualizedHeight(childRended.length)}
          itemCount={childRended.length}
          itemSize={40}
          width={'100%'}>
          {LazyElement(childRended, virtualizeRef)}
        </List>
      );
    } else {
      if (isFilterdData) {
        return null;
      }

      return (
        <div style={{ marginTop: 20 }}>
          <Empty
            style={{ margin: 0 }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span></span>}
          />
        </div>
      );
    }
  };

  /**
   * This function defines what parent should has a custom ponderator sum
   * When defining that a parent needs it, this function creates the summatory value
   * @param {*} parent Parent to check custom ponderators sum
   * @returns {object} { boolean: Indicates if parent has custom ponderator active, number: Summatory of custom ponderators }
   */
  const getPonderatorSum = (parent) => {
    let sumPonderators = 0;
    let showCustomPonderator = false;
    const stringToExtract = parent.tasks ? 'tasks' : 'children';
    if (parent.hasCustomPonderator) {
      parent[stringToExtract].map((t) => {
        sumPonderators += t.ponderator;
      });
      showCustomPonderator = true;
    }
    return { showCustomPonderator, sumPonderators };
  };

  /**
   * This function creates a JSX with the summatory of childs custom ponderators for
   * a task or activity
   * @param {*} parent Parent task or activity to check childs custom ponderators sum
   */
  const checkPonderatorIcon = (parent) => {
    const { showCustomPonderator, sumPonderators } = getPonderatorSum(parent);

    if (showCustomPonderator) {
      let color = '#34af00';
      if (
        sumPonderators < 99.99545454545456 ||
        sumPonderators >= 100.00545454545457
      ) {
        color = 'red';
      }

      return (
        <Tooltip title="Child tasks has custom ponderators">
          <span style={{ paddingRight: 5, color }}>
            ΣP:{' '}
            {sumPonderators.toFixed
              ? sumPonderators.toFixed(2)
              : sumPonderators}
          </span>
        </Tooltip>
      );
    }
  };

  /**
   * This function defines what content show inside each header with activity data
   * @param {*} column Metadata from the column that is going to be extracted the task
   * @param {*} activity Activity object to extract the column data
   */
  const defineActivityColumnContent = (column, activity) => {
    if (column.name == 'name') {
      return (
        <div>
          {activity.tasks.length && !onlyRead ? (
            <CustomCheckbox
              inverted
              onClick={() => {
                props.massiveSelectionHandler(activity);
                // updateState()
              }}
              active={activity.active}
            />
          ) : null}
          {defineCollapseIcon(activity)}
          <span>
            {activity.correlative_id} | {activity[column.name]}
          </span>
          <span style={{ marginLeft: 15 }}>
            {splitText(reversPathTasks(activity.activityRoute), column)}
          </span>
        </div>
      );
    } else if (column.name == 'taskRoute') {
      return (
        <div
          className="vertical-center"
          style={{ display: 'flex', marginLeft: '1rem', paddingTop: '8px' }}>
          {splitText(
            reversPathTasks(`${activity.activityRoute} > ${activity.name}`),
            { ...column, view: 'weecklyplan' }
          )}
        </div>
      );
    } else if (column.data_type == 'string' || column.data_type == 'number') {
      const val = calculateExpected(activity, ganttAPI, activity.calendarId);
      let progressColor = '#52c41a';
      if (activity.progress.toFixed(2) == '100.00') {
        progressColor = '#34AF00';
      } else if (activity.progress >= val) {
        progressColor = '#2C3421';
      } else if (activity.progress < val) {
        progressColor = '#E50101';
      }
      if (column.name == 'progress') {
        const percentVal = activity[column.name].toFixed
          ? activity[column.name].toFixed(2)
          : activity[column.name];
        return (
          <Progress
            strokeColor={{ '0%': progressColor, '100%': progressColor }}
            className="progress-custom-props"
            percent={percentVal}
            format={
              percentVal == 100
                ? false
                : (percent) =>
                    t('lang') === 'en'
                      ? percent + '%'
                      : `${percent}`.replace('.', ',') + '%'
            }
          />
        );
      } /* else if (column.name == 'ponderator') {
                return checkPonderatorIcon(activity);
            } */
      if (column.name == 'commitment_percentaje') {
        return activity.commitmentPercentaje
          ? t('lang') === 'en'
            ? activity.commitmentPercentaje.toFixed(2) + '%'
            : activity.commitmentPercentaje.toFixed(2).replace('.', ',') + '%'
          : t('lang') === 'en'
            ? '0.00'
            : '0,00';
      }
      // if (column.name == 'expectedweek') {
      //     return (activity.expectedweekAct ? activity.expectedweekAct.toFixed(2) : '0.00') + '%';
      // }
      if (column.name == 'cost') {
        return (
          <div>
            {' '}
            {t('lang') === 'en'
              ? formatMoney(activity[column.name], '$', 2, '.', ',')
              : formatMoney(activity[column.name], '$', 2, ',', '.')}
          </div>
        );
      }
      if (column.name == 'duration') {
        return (
          <div>
            {' '}
            {t('lang') === 'en'
              ? formatMoney(activity[column.name], '', 2, '.', ',')
              : formatMoney(activity[column.name], '', 2, ',', '.')}
          </div>
        );
      }
      return activity[column.name] ? (
        activity[column.name]
      ) : (
        <div className="transparent-for-activity-column-header">-</div>
      );
    } else if (column.data_type == 'date') {
      return activity[column.name] ? (
        moment(activity[column.name].toString()).format(formatDate)
      ) : (
        <div className="transparent-for-activity-column-header">-</div>
      );
    } else if (
      column.data_type == 'array/images' ||
      column.data_type == 'array/string' ||
      column.data_type == 'array/icon'
    ) {
      return activity[column.name] && activity[column.name] instanceof Array ? (
        activity[column.name].map((responsable, index) => {
          return (
            <Tooltip title={responsable.email} key={index}>
              <img
                src={responsable.avatar ? responsable.avatar : fakeAvatar}
                height={15}
                width={15}
                style={{ marginBottom: 3, marginRight: 2, borderRadius: 20 }}
              />
            </Tooltip>
          );
        })
      ) : (
        <div className="transparent-for-activity-column-header">-</div>
      );
    }
  };

  /**
   * This function renders each activity column, and then calls defineActivityColumnContent to define
   * what jsx els must be showed inside of this col
   * @param {*} column Metadata from the column that is going to be extracted the task
   * @param {*} activity Activity object to extract the column data
   * @param {*} index Index from array iteration
   */
  const renderActivityColumn = (column, activity, index) => {
    if (column.visible) {
      return (
        <Col
          className="single-column-header"
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineActivityColumnContent(column, activity)}
        </Col>
      );
    }
  };

  /**
   * This function render the header from the table, with the activity data
   */
  const renderActivityHeader = () => {
    return (
      <Row className="custom-header-top-list">
        {props.tableMetadata.map((eachColumn, index) => {
          return renderActivityColumn(eachColumn, props.lastLvlActivity, index);
        })}
      </Row>
    );
  };

  const createActivityModificationRequest = async (task, activity) => {
    const loggedUser = getSignedUser();
    const projectId = projectState.projectSelected;
    const sectorId = projectState.sectorSelected;
    const link = base.front + 'masterplan/' + sectorId + '/' + projectId;

    const activityModification = {
      type: task.unfitType,
      state: 'waiting',
      description: modalModification.description,
      activityId: activity.id,
      userRequestId: loggedUser.id,
      startOriginal: activity.start_date,
      endOriginal: activity.end_date,
      user: loggedUser,
      projectId,
      sectorId,
      link
    };

    if (task.unfitType == 'start') {
      activityModification.startNew = task.start_date;
      activityModification.endNew = activity.end_date;
    } else if (task.unfitType == 'end') {
      activityModification.startNew = activity.start_date;
      activityModification.endNew = task.end_date;
    } else if (task.unfitType == 'both') {
      activityModification.startNew = task.start_date;
      activityModification.endNew = task.end_date;
    }

    const res = await activityModificationService.create(activityModification);
    dispatch(userActions.setUserUpdate());
    if (res) {
      openNotification({
        type: 'success',
        title: t('success_request_notify')
      });
      setModalModification({
        ...modalModification,
        visible: false
      });
    }
  };

  /**
   * Render
   */

  if (!formatDate) return <div></div>;

  return props.lastLvlActivity.showWeeklyPlan ? (
    <Row key={props.index}>
      <Col
        className="tableConstraints"
        style={{ marginTop: 10, marginBottom: 10 }}>
        {renderActivityHeader(props.lastLvlActivity)}
        {renderTasks(props.lastLvlActivity)}
      </Col>

      {/* Modal for creating custom ponderator */}
      <Modal
        title={t('carefull_modal_title')}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="submit" type="primary" onClick={disableAutoPonderator}>
            {t('got_it_disable_auto_ponderator')}
          </Button>
        ]}>
        <p>
          {t('with_this_action_disable')}
          <b>{t('bold_ponderator_manual_label')}</b>
          {t('rest_of_disable_message')}
        </p>
      </Modal>

      {/* Modal for dealing with activity modification request */}
      <Modal
        wrapClassName="activity-modification-style"
        title={modalModification.title}
        visible={modalModification.visible}
        onCancel={() =>
          setModalModification({ ...modalModification, visible: false })
        }
        footer={[
          <Button
            onClick={() =>
              createActivityModificationRequest(
                modalModification.data.task,
                modalModification.data.activity
              )
            }
            key="submit"
            style={{
              background: '#7DFF8A',
              color: '#121212',
              borderColor: '#7DFF8A'
            }}>
            {t('modals.lookahead.activity_modification_request.send_request')}
          </Button>
        ]}>
        {modalModification.visible ? (
          <div>
            <div>
              <span className="name-activity-modification">
                {modalModification.data.activity.name}
              </span>
            </div>
            <div className="dates-container-activity-modification">
              <span className="calendar-activity-modification">
                <img src={calendarModificationIconModal} width={12} />
              </span>
              <span className="dates-activity-modification">
                {modalModification.data.activity.start_date.split(' ')[0]}
              </span>
              <span className="calendar-activity-modification">
                <img src={calendarModificationIconModal} width={12} />
              </span>
              <span className="dates-activity-modification">
                {modalModification.data.activity.end_date.split(' ')[0]}
              </span>
            </div>
            <div className="modification-title-activity-modification">
              {defineTitleModification(modalModification.data.task, t)}
            </div>
            {defineDateUnfit(
              modalModification.data.task,
              modalModification.data.activity,
              t
            )}
            <div className="commentary-container-activity-modification">
              {t('modals.lookahead.activity_modification_request.comment')}
            </div>
            <div>
              <TextArea
                onChange={({ target: { value } }) => {
                  modalModification.description = value;
                }}
                placeholder={t(
                  'modals.lookahead.activity_modification_request.description_placeholder'
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </div>
          </div>
        ) : null}
      </Modal>
    </Row>
  ) : null;
}
