import React from 'react';

import { splitText } from '../../index.helper';

export const FixedColActivitiesRowTree =
  (data, width, selectedActivities, selectedUnits) =>
  ({ columnIndex, rowIndex, style }) => {
    const activity = data[rowIndex];
    const customStyle = { paddingLeft: activity.lvl * 5 };
    if (!activity.lvl) customStyle.paddingLeft = 2;
    if (activity.has_childs) customStyle.color = '#1890FF';

    let addTopClass = '';
    if (!rowIndex) {
      addTopClass = ' rounded-top-fixed';
    }

    const isActivitySelected = selectedActivities.find(
      (act) => act.id == activity.id && !activity.disable
    );
    const isActivitySelecteToUnassign = selectedActivities.find(
      (act) => act.id == activity.id && activity.active && activity.disable
    );

    let hasAtLeastOneAvailableUnit = false;
    let isSomeUnassignUnit;
    const onlyUnits = selectedUnits.filter((u) => !u.structureId);
    for (let i = 0; i < onlyUnits.length; i++) {
      const ac = onlyUnits[i];
      if (!ac.disable) {
        hasAtLeastOneAvailableUnit = true;
        break;
      } else if (ac.active) {
        isSomeUnassignUnit = true;
        break;
      }
    }

    let className =
      isActivitySelected && hasAtLeastOneAvailableUnit && !activity.has_childs
        ? 'fixed-col-activity-with-units'
        : 'fixed-col-activity-without-units';
    className =
      isActivitySelecteToUnassign && isSomeUnassignUnit
        ? 'fixed-col-activity-unassign-units'
        : className;

    return (
      <div className={className} style={style}>
        <div className={'col-fixed-row' + addTopClass} style={customStyle}>
          {splitText(activity.name, width - customStyle.paddingLeft)}
        </div>
      </div>
    );
  };

/**
 * This component is the rendered fixed col row, actually activities col (to easy understand)
 * @param {*} data all activities in lineal tree structure
 * @param {*} width width for this col
 */
export const FixedColActivitiesRowGroupped =
  (data, width, selectedActivities, selectedUnits) =>
  ({ columnIndex, rowIndex, style }) => {
    const grouppedActivity = data[rowIndex];

    let isSelectedActivity = false;
    let isActivitySelectedToUnassign = false;

    for (let i = 0; i < grouppedActivity.activities.length; i++) {
      const ac = grouppedActivity.activities[i];
      const isActivityInSelectedArray = selectedActivities.find(
        (act) => act.id == ac.id && !ac.disable
      );
      const isActivitySelecteToUnassignArray = selectedActivities.find(
        (act) => act.id == ac.id && ac.active && ac.disable
      );
      if (isActivityInSelectedArray) {
        isSelectedActivity = true;
        break;
      }
      if (isActivitySelecteToUnassignArray) {
        isActivitySelectedToUnassign = true;
        break;
      }
    }

    let addTopClass = '';
    if (!rowIndex) {
      addTopClass = ' rounded-top-fixed';
    }

    let hasAtLeastOneAvailableUnit = false;
    let isSomeUnassignUnit = false;
    const onlyUnits = selectedUnits.filter((u) => !u.structureId);
    for (let i = 0; i < onlyUnits.length; i++) {
      const ac = onlyUnits[i];
      if (!ac.disable) {
        hasAtLeastOneAvailableUnit = true;
        break;
      } else if (ac.active) {
        isSomeUnassignUnit = true;
        break;
      }
    }

    let className =
      isSelectedActivity && hasAtLeastOneAvailableUnit
        ? 'fixed-col-activity-with-units'
        : 'fixed-col-activity-without-units';
    className =
      isActivitySelectedToUnassign && isSomeUnassignUnit
        ? 'fixed-col-activity-unassign-units'
        : className;

    return (
      <div className={className} style={style}>
        <div className={'col-fixed-row' + addTopClass}>
          {splitText(grouppedActivity.name, width)}
        </div>
      </div>
    );
  };
