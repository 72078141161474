import React, { useState, useEffect, useRef } from 'react';
import AddIcon from '../../assets/img/gantt/assignee-new.png';
import RemoveIcon from '../../assets/img/gantt/X.png';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import SelectSearch from 'react-select-search';

import { Popover, Tooltip } from 'antd';

import { getCompanyTextColor } from '../../utils';
import { ArrowDownIcon } from '../../icons';

/** test id */
export const testIds = {
  RENDER_CUSTOM_DROPDOWN_CONTAINER: 'RENDER_CUSTOM_DROPDOWN_CONTAINER'
};
const TestWrapped = ({ children }) => (
  <span data-testid={testIds.RENDER_CUSTOM_DROPDOWN_CONTAINER}>{children}</span>
);

const CustomDropdownSelector = ({
  mainObject,
  columnName,
  width,
  multiple,
  renderCustomPlaceholderEmpty,
  renderCustomPlaceholderElement,
  renderCustomSelectionOption,
  renderCustomCreateNewOption,
  renderCustomRemoveOption,
  renderCustomFooter,
  callbackOnSelection,
  callbackOnClickCreate,
  callbackOnClickRemove = () => {},
  callbackOnClickFooter = () => {},
  labelCreateBtn,
  labelRemoveBtn,
  labelSearchbar,
  t,
  options,
  attrToExtractFromOption,
  attrToLabelFromOption,
  includeRemoveFooter = false,
  hideCreateBtn = false,
  customMainClassName = '',
  selectOptions = false,
  clearSelection = false,
  callbackOnSubcontractRowIdx = null,
  onlyread = false,
  closeOnclick = true,
  withTimeout = false,
  children,
  calbackOnVisibleChange = null
}) => {
  const [currentValue, setCurrentValue] = useState(null);
  const [selected, setSelected] = useState(null);
  const [dictionary, setDictionary] = useState({});
  const [toSelectOptions, setToSelectOptions] = useState(null);
  const [selectionOption, setSelectionOption] = useState(null);
  const popRef = useRef();

  useEffect(() => {
    clearSelection && setSelectionOption(null);
  }, [clearSelection]);

  /** Defining the current val from extracting attr from object */
  useEffect(() => {
    if (!mainObject[columnName]) {
      setCurrentValue(null);
    } else if (multiple) {
      setCurrentValue(mainObject[columnName]);
    } else {
      setCurrentValue(mainObject[columnName] + '-');
    }
  }, [mainObject, columnName]);

  /** Options for selector build effect */
  useEffect(() => {
    const opts = [];
    const dictionary = {};

    if (!options) return;

    options &&
      options instanceof Array &&
      options.forEach((op) => {
        if (op) {
          const value = op[attrToExtractFromOption] + '-';
          const attributeToSearch = Array.isArray(attrToLabelFromOption)
            ? attrToLabelFromOption.map((attr) => op[attr]).join(' ')
            : op[attrToLabelFromOption];

          opts.push({
            label: attributeToSearch,
            name: attributeToSearch,
            value: value,
            object: op
          });
          dictionary[value] = op;
        }
      });
    setDictionary(dictionary);
    setToSelectOptions(opts);
  }, [options, selected]);

  /** Selected needed array to selectsearch component build effect */
  useEffect(() => {
    let selected;

    if (multiple) {
      selected = [];
      currentValue &&
        currentValue instanceof Array &&
        currentValue.forEach((el) => {
          selected.push(el[attrToExtractFromOption] + '-');
        });
    } else {
      selected = currentValue;
    }
    selected && setSelected(selected);
  }, [currentValue]);

  const onSelectionChange = (val) => {
    callbackOnSelection && callbackOnSelection(val, dictionary);
    selectOptions && setSelectionOption(dictionary[val]);
    callbackOnSubcontractRowIdx && callbackOnSubcontractRowIdx(dictionary[val]);
    if (closeOnclick) closeDropdown();
  };

  const renderSelectionOption = (props, option, snapshot, className) => {
    if (option.name === 'NONE_TAG_EXIST') {
      return <div className="none-tag-exist"></div>;
    }

    return (
      <button {...props} className={className} type="button">
        <span
          className="custom-dropdown-option-wrapper"
          style={{ display: 'inline-flex' }}>
          {renderCustomSelectionOption ? (
            renderCustomSelectionOption(option)
          ) : (
            <div className="child-tag">{option.label}</div>
          )}
        </span>
      </button>
    );
  };

  const closeDropdown = (_) => {
    if (popRef) {
      if (withTimeout) {
        setTimeout(() => {
          popRef.current.tooltip.setState({ visible: false });
        }, 1000);
      } else {
        popRef.current.tooltip.setState({ visible: false });
      }
    }
  };

  const renderCreateSelectorOption = (_) => {
    if (!hideCreateBtn) {
      const onClick = () => {
        if (closeOnclick) closeDropdown();
        callbackOnClickCreate && callbackOnClickCreate();
      };

      if (renderCustomCreateNewOption) {
        return <div onClick={onClick}>{renderCustomCreateNewOption()}</div>;
      }

      return (
        <div
          className="add-subcontract-inline custom-create-tag-to-select-checkbox"
          onClick={onClick}>
          {labelCreateBtn || t('create_subcontract_label')}
        </div>
      );
    }
  };

  const renderRemoveSelectorOption = (_) => {
    if (includeRemoveFooter) {
      const onClick = () => {
        if (closeOnclick) closeDropdown();
        callbackOnClickRemove && callbackOnClickRemove();
        callbackOnClickRemove && setSelectionOption(null);
      };

      if (renderCustomRemoveOption) {
        return <div onClick={onClick}>{renderCustomRemoveOption()}</div>;
      }

      return (
        <div
          style={{
            alignContent: 'center',
            textAlign: 'center',
            position: 'relative',
            top: 1,
            height: 15,
            width: 78
          }}
          className="add-subcontract-inline"
          onClick={onClick}>
          <span
            style={{
              position: 'relative',
              left: -5,
              top: -2
            }}>
            <img width="10" src={RemoveIcon} />
          </span>
          <span
            style={{
              color: '#121212',
              fontSize: 12,
              position: 'relative',
              left: -5
            }}>
            {labelRemoveBtn || t('remove_subcontract_label')}
          </span>
        </div>
      );
    }
  };

  const renderSelectionHeader = (name) => {
    switch (name) {
      case 'RENDER_CREATE_BTN':
        return renderCreateSelectorOption();
      case 'RENDER_REMOVE_BTN':
        return renderRemoveSelectorOption();
    }
  };

  const renderSinglePlaceholder = (_) => {
    const fromOption = toSelectOptions?.find((op) => op.value === currentValue);
    const fromObject = fromOption?.object;
    if (renderCustomPlaceholderElement)
      return renderCustomPlaceholderElement(fromObject);
    return <div>{fromObject && fromObject[attrToLabelFromOption]}</div>;
  };

  const renderMultiplePlaceholder = (_) => {
    if (renderCustomPlaceholderElement) return renderCustomPlaceholderElement();
    return currentValue?.length
      ? currentValue.map((el, index) => {
          return <div key={index}>{el[attrToLabelFromOption]}</div>;
        })
      : null;
  };

  const renderSelectDropdown = (_) => {
    let options = [
      {
        type: 'group',
        name: 'RENDER_CREATE_BTN',
        items: toSelectOptions?.length
          ? toSelectOptions
          : [
              {
                name: 'NONE_TAG_EXIST',
                value: 'NONE_TAG_EXIST'
              }
            ]
      },
      {
        type: 'group',
        name: 'RENDER_REMOVE_BTN',
        items: [
          {
            name: 'NONE_TAG_EXIST',
            value: 'NONE_TAG_EXIST'
          }
        ]
      }
    ];

    if (!includeRemoveFooter && hideCreateBtn) {
      options = toSelectOptions?.length
        ? toSelectOptions
        : [
            {
              name: 'NONE_TAG_EXIST',
              value: 'NONE_TAG_EXIST'
            }
          ];
    }

    let onClickFooter = () => {};
    if (renderCustomFooter) {
      onClickFooter = () => {
        callbackOnClickFooter && callbackOnClickFooter();
        closeDropdown();
      };
    }

    return (
      <>
        <SelectSearch
          printOptions="always"
          renderGroupHeader={renderSelectionHeader}
          className="select-search select-search--multiple"
          options={options}
          value={selected}
          renderOption={renderSelectionOption}
          onChange={onSelectionChange}
          multiple={multiple}
          search
          placeholder={labelSearchbar || t('filters_label.search_bar_label')}
        />
        {renderCustomFooter ? (
          <div onClick={onClickFooter} className="footer-custom-dropdown">
            {renderCustomFooter()}
          </div>
        ) : null}
      </>
    );
  };

  /** Creates trigger dropdown button placeholder */
  const renderPlaceholderBtn = (_) => {
    /** Don't assignation, so adds plus icon to add */
    if (
      currentValue === false ||
      currentValue === null ||
      currentValue === undefined ||
      (currentValue && multiple && !currentValue.length)
    ) {
      if (renderCustomPlaceholderEmpty) return renderCustomPlaceholderEmpty();
      return (
        <div
          className="opacity-hover-style"
          style={{ width: '100%', height: '100%' }}>
          <span className="add-responsable-new">
            <img width="10" src={AddIcon} />
          </span>
        </div>
      );
    }

    /** If there is assignation, and it is multiple, goes for multiple placeholder function */
    if (multiple) {
      return renderMultiplePlaceholder();
    } else {
      return renderSinglePlaceholder();
    }
  };

  const renderSelectionOptionPlaceholder = (_) => {
    const { color } = selectionOption || {};
    return (
      <div
        style={{
          height: 32,
          padding: '5px 5px',
          position: 'relative',
          top: '6px',
          display: 'flex',
          borderRadius: 5,
          color: color ? `${getCompanyTextColor(color)}` : '#D1D1D1',
          fontWeight: 600,
          backgroundColor: color || '#3E482D',
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginLeft: '-3px'
        }}>
        {selectionOption &&
          selectionOption.name &&
          selectionOption.name.length < 13 &&
          selectionOption.name}
        {selectionOption &&
          selectionOption.name &&
          selectionOption.name.length >= 13 && (
            <Tooltip title={selectionOption.name} placement="bottom">
              {selectionOption.name.substring(0, 13) + '...'}
            </Tooltip>
          )}
        <span style={{ position: 'absolute', right: 10 }} className="ccc4">
          <ArrowDownIcon color={selectionOption?.color || '#D1D1D1'} />
        </span>
      </div>
    );
  };

  const renderPopOverContainer = (_) => {
    if (onlyread) {
      return (
        <div
          className={
            'container-image-custom custom-proplanner-tag custom-dropdown-selector ' +
            customMainClassName
          }
          style={{ cursor: 'not-allowed' }}>
          {selectOptions
            ? renderSelectionOptionPlaceholder()
            : renderPlaceholderBtn()}
        </div>
      );
    }

    let haveFooterClass = '';
    if (renderCustomFooter) {
      haveFooterClass = ' have-footer';
    }

    return (
      <Popover
        onVisibleChange={() => {
          if (calbackOnVisibleChange) {
            calbackOnVisibleChange();
            setSelected([]);
          }
        }}
        ref={popRef}
        placement="bottom"
        overlayClassName={
          'container-image-custom custom-proplanner-tag custom-dropdown-selector ' +
          customMainClassName +
          haveFooterClass
        }
        className={
          'popoverPriority custom-tags-proplanner ' + customMainClassName
        }
        content={renderSelectDropdown()}
        trigger="click">
        {children ||
          (selectOptions
            ? renderSelectionOptionPlaceholder()
            : renderPlaceholderBtn())}
      </Popover>
    );
  };

  return (
    <div
      className="custom-multi-select-pop react-dhtmlx custom-dropdown-selector"
      style={{
        width: width || '100%',
        height: '100%',
        marginTop: 0,
        cursor: onlyread ? 'not-allowed' : ''
      }}>
      <TestWrapped>{renderPopOverContainer()}</TestWrapped>
    </div>
  );
};

/** Props definition */
CustomDropdownSelector.propTypes = {
  mainObject: PropTypes.object.isRequired,
  columnName: PropTypes.string,
  width: PropTypes.number,
  multiple: PropTypes.bool.isRequired,
  renderCustomPlaceholderEmpty: PropTypes.func,
  renderCustomPlaceholderElement: PropTypes.func,
  renderCustomSelectionOption: PropTypes.func,
  renderCustomCreateNewOption: PropTypes.func,
  renderCustomRemoveOption: PropTypes.func,
  renderCustomFooter: PropTypes.func,
  callbackOnSelection: PropTypes.func.isRequired,
  callbackOnClickCreate: PropTypes.func,
  callbackOnClickRemove: PropTypes.func,
  callbackOnClickFooter: PropTypes.func,
  options: PropTypes.array.isRequired,
  attrToExtractFromOption: PropTypes.string.isRequired,
  attrToLabelFromOption: PropTypes.string.isRequired,
  includeRemoveFooter: PropTypes.bool,
  labelCreateBtn: PropTypes.string,
  labelRemoveBtn: PropTypes.string,
  labelSearchbar: PropTypes.string,
  hideCreateBtn: PropTypes.bool,
  customMainClassName: PropTypes.string,
  onlyread: PropTypes.bool,
  closeOnclick: PropTypes.bool,
  withTimeout: PropTypes.bool
};

export default withTranslation()(CustomDropdownSelector);
