import { txtFieldState } from '../../utils';
import {
  userService,
  projectService,
  baseworkingdayService,
  taskService,
  constraintService,
  calendarExceptionDayService,
  sectorService,
  calendarService,
  companyService
} from '../../services';
import moment from 'moment';
import {
  codeCountries,
  Types,
  Stages,
  getDynamicAttributesToCreateTask
} from '../../utils';
import { viewService } from '../../services/views.service';
import { viewType } from '../lookahead/planification/index.helper';
import { transformHourToDays } from '../ganttContainer/gantt/gantt.helper';
import { baseCalendarService } from '../../services/basecalendar.service';
import { BaseCalendarExceptionDaysService } from '../../services/basecalendarexceptiondays.service';
import {
  getEndDateByGantt,
  transformDateWithHour
} from '../../utils/lookahead-common';
import { historicalActivityProgressService } from '../../services/historicalactivityprogress.service';

export const stateTemplateDummy = {
  pname: {
    ...txtFieldState,
    fieldName: 'pname',
    required: true,
    requiredTxt: 'Name is required',
    formatErrorTxt: ''
  },
  country: {
    ...txtFieldState,
    fieldName: 'country',
    required: true,
    requiredTxt: 'Country is required',
    formatErrorTxt: '',
    value: 'CL'
  },
  allFieldsValid: false,
  isLoading: false,
  stateUpload: { loading: false }
};

export const stateTemplate = {
  pname: {
    ...txtFieldState,
    fieldName: 'pname',
    required: true,
    requiredTxt: 'Name is required',
    formatErrorTxt: ''
  },
  ptype: {
    ...txtFieldState,
    fieldName: 'ptype',
    required: true,
    requiredTxt: 'Type is required',
    formatErrorTxt: '',
    value: undefined
  },
  psize: {
    ...txtFieldState,
    fieldName: 'psize',
    required: true,
    requiredTxt: 'Size is required',
    formatErrorTxt: ''
  },
  psizetype: {
    ...txtFieldState,
    fieldName: 'psizetype',
    required: true,
    requiredTxt: 'Size Type is required',
    formatErrorTxt: '',
    value: 'ft2'
  },
  country: {
    ...txtFieldState,
    fieldName: 'country',
    required: true,
    requiredTxt: 'Country is required',
    formatErrorTxt: '',
    value: 'US'
  },
  pcurrency: {
    ...txtFieldState,
    fieldName: 'pcurrency',
    required: true,
    requiredTxt: 'Currency is required',
    formatErrorTxt: '',
    value: 'USD'
  },
  pbudget: {
    ...txtFieldState,
    fieldName: 'pbudget',
    required: true,
    requiredTxt: 'Budget is required',
    formatErrorTxt: ''
  },
  sname: {
    ...txtFieldState,
    fieldName: 'sname',
    required: false,
    requiredTxt: 'Sector name is required',
    formatErrorTxt: ''
  },
  allFieldsValid: false,
  isLoading: false,
  stateUpload: { loading: false }
};

export const stateTemplateSector = {
  sname: {
    ...txtFieldState,
    fieldName: 'sname',
    required: true,
    requiredTxt: 'Sector es requerido',
    formatErrorTxt: ''
  },
  sdescription: {
    ...txtFieldState,
    fieldName: 'sdescription',
    required: false,
    requiredTxt: 'Descripcion es requerido',
    formatErrorTxt: ''
  },
  allFieldsValidSector: false
};

export const getProject = async (id) => {
  const resp = await projectService.show(id);
  return resp.project;
};

export const createProject = async (data) => {
  const resp = await projectService.create(data);
  return resp;
};

export const asignProjectUser = async (data) => {
  const resp = await projectService.asignProjectUser(data);
  return resp;
};

export const getUser = async (token) => {
  const resp = await userService.getbytoken(token);
  return resp;
};

export const getLabel = (t) => (key, filter) => {
  let find;
  switch (filter) {
    case 'stage': {
      const arrStage = Stages(t).find((e) => e.value === key);
      find = arrStage ? arrStage.label : '';
      break;
    }
    case 'country': {
      const arrCountry = codeCountries.find((e) => e.code === key);
      find = arrCountry ? arrCountry.name : '';
      break;
    }
    case 'type': {
      const arrType = Types.find((e) => e.value === key);
      find = arrType ? arrType.label : '';
    }
  }
  return find || '';
};

/** Constant for Select Reason state initial  */
export const noSelectedReason = 'Your Reason';

/** Constant for no projects  */
export const mesaggeNoProjectsFound = 'No projects found';

/** logic for filter */
export const filterProjects = (projectState) => {
  const projectsOrigin = projectState.allProjects;

  /** filter */
  const searchProject = [];
  let tmpSearch = projectsOrigin;
  let searchProjectFilter;
  const filters = projectState.props.showedFilters;
  if (filters && filters.value.length) {
    filters.value.map((el, i) => {
      const search = el.filter_by;
      if (el.type === 'text') {
        if (search) {
          tmpSearch = projectsOrigin.filter((e) => {
            let ev;
            if (el.name === 'correlative_id') {
              ev = el.equals
                ? e.id === parseInt(search)
                : e.id !== parseInt(search);
            } else if (el.name === 'title') {
              ev = el.equals
                ? e.name.includes(search)
                : !e.name.includes(search);
            }
            return ev;
          });
        }
        searchProject.push(tmpSearch);
      }
    });

    /** And Or Logic */
    let resp = [];
    if (filters.andOr === 'or') {
      /** union projects cumulative */
      searchProject.map((e) => {
        if (resp.length === 0) {
          resp = e;
        } else {
          const union = [...resp, ...e];
          const set = new Set();
          resp = union.filter((item) => {
            if (!set.has(item.id)) {
              set.add(item.id);
              return true;
            }
            return false;
          }, set);
        }
      });
    } else {
      /** intersection projects cumulative */
      searchProject.map((e, index) => {
        if (index === 0) {
          resp = e;
        } else {
          resp = resp.filter((n) => e.some((n2) => n.id === n2.id));
        }
      });
    }
    searchProjectFilter = resp;
  } else {
    searchProjectFilter = projectsOrigin;
  }
  return searchProjectFilter;
};

export const buildViews = async (projectId, sectorId) => {
  const views = await viewService.showByProyect(projectId, sectorId);

  let dateInit = new Date();
  let dateEnd = new Date();
  dateEnd.setDate(dateInit.getDate() + 30);
  dateInit = dateInit.toISOString().split('T')[0].split('-').join('/');
  dateEnd = dateEnd.toISOString().split('T')[0].split('-').join('/');

  if (!views.length) {
    let viewsDefault = {
      project: projectId,
      sector: sectorId,
      view: {
        name: viewType.find((view) => view.name == 'table').name,
        icon: viewType.find((view) => view.name == 'table').icon,
        label: viewType.find((view) => view.name == 'table').label,
        module: 'Lookahead_plan', // ENUM
        filter_query: {},
        order_query: {},
        group_query: '{"criteria": "activityId", "sort": "asc"}',
        columns_json: {},
        lookahead_init: dateInit,
        lookahead_end: dateEnd,
        lookahead_range: '1 week',
        gantt_date_init: dateInit,
        gantt_date_end: dateEnd,
        default: true,
        beta: true
      }
    };
    const newView = await viewService.create({
      projectId: projectId,
      sectorId: sectorId,
      order: 1,
      ...viewsDefault.view
    });

    viewsDefault = {
      project: newView.project,
      sector: newView.sector,
      order: newView.order,
      view: {
        ...newView.view
      }
    };
  }
};

export const cloneActivityToBasePoint = (
  originalActivity,
  arrayToPush,
  calendarToBaseCalendarHash
) => {
  /** Hour integration */
  arrayToPush.push({
    ...originalActivity,
    hh_work: originalActivity.hhWorkTime,
    cost: originalActivity.cost,
    duration: transformHourToDays(originalActivity.duration),
    baseCalendarId: calendarToBaseCalendarHash[originalActivity.calendar_id],
    sumOfDurationRecursively: originalActivity.sumOfDurationRecursively,
    hoursPerDay: 8,
    hoursPerWeek: 40
  });
};

export const getBodyForNewBaseline = (
  calendarToBaseCalendarHash,
  newBaselineVersion,
  activities
) => {
  // const activities = gantt.serialize().data
  const toAssignBaselineActivities = [];
  // If in future versions of new baseline creation, just addapt this object to keep those required values
  const colToHardClone = {
    hh_work: 'hhWorkTime',
    cost: 'cost'
  };

  /** All activities will be replaced with a follow up baseline point */
  activities.forEach((activity) => {
    if (activity.id) {
      activity.proplannerId = activity.id;
      cloneActivityToBasePoint(
        activity,
        toAssignBaselineActivities,
        calendarToBaseCalendarHash
      );
    }
  });
  /** Activities with old baselinepoint active will keep it */

  const bodyToSaveBaseline = {
    version_id: newBaselineVersion.id,
    activities: toAssignBaselineActivities
  };

  return bodyToSaveBaseline;
};

export const createBaseCalendarFromCalendar = async (
  newBaselineVersion,
  calendars,
  lastActiveBaselineVersion
) => {
  const hashTable = {};
  const copyOfCalendar = calendars.filter((cal) => typeof cal.id !== 'string');
  const asyncMap = copyOfCalendar.map(async (calendar) => {
    /** We create a new base calendar object */
    const newBaseCalendar = {
      ...calendar,
      sectorBaselineVersionId: newBaselineVersion.id,
      is_default: calendar.is_default || false
    };
    const res = await baseCalendarService.create(newBaseCalendar);
    if (res) {
      /** We takes calendar shifts and clone it into a base working day (for baselines) */
      const asyncBaseWorkingDays = calendar.shifts.map(async (shift) => {
        /** We create a new base working day object */
        const newBaseWorkingday = {
          shift_string: shift.shift_string,
          correlative_id: shift.correlative_id,
          basecalendarId: res.id
        };
        await baseworkingdayService.create(newBaseWorkingday);
      });
      await Promise.all(asyncBaseWorkingDays);

      /** we map calendar ID to base calendar ID */
      hashTable[calendar.id] = res.id;

      /** Then async clone calendar exceptions for base calendar exceptions */
      calendar.exceptiondays.map(async (exception) => {
        const newBaseCalendarExceptionDay = {
          ...exception,
          baseCalendarId: res.id
        };
        newBaseCalendarExceptionDay.every_type = exception.every_type;
        const res2 = await BaseCalendarExceptionDaysService.create(
          newBaseCalendarExceptionDay
        );
        if (res2) {
          /** N workingdays integration */
          const asyncMap = exception.shifts.map(async (shift) => {
            const newBaseWorkingday = {
              shift_string: shift.shift_string,
              correlative_id: shift.correlative_id,
              basecalendarexceptiondayId: res2.id
            };
            await baseworkingdayService.create(newBaseWorkingday);
          });
          await Promise.all(asyncMap);
        }
      });
    }
  });
  await Promise.all(asyncMap);
  return hashTable;
};

export const createTask = async (parentTask = null, task, activity) => {
  let inheritStartDate = task.start_date;
  inheritStartDate = transformDateWithHour(inheritStartDate);

  const toAddDynamicAttributes =
    (await getDynamicAttributesToCreateTask()) || {};
  let toPush = {
    name: task.name,
    start_date: inheritStartDate,
    duration: task.duration,
    priority: 'Low',
    progress: task.progress || 0,
    responsables: [],
    ponderator: task.ponderator,
    activityId: activity.id,
    isEditing: true,
    isNew: true,
    activity_ganttid: activity.correlative_id,
    parent_id: parentTask ? parentTask.id : parentTask,
    cost: 0,
    hhWorkTime: 0,
    status: 'Waiting',
    lean_status: 'Debit',
    children: [],
    constraints: [],
    hasCustomPonderator: false,
    active: false
  };

  toPush = {
    ...toPush,
    ...toAddDynamicAttributes
  };
  getEndDateByGantt(toPush, activity);
  const taskCreated = await taskService.create(toPush);
  return taskCreated;
};

export const createUser = async (data) => {
  const resp = await userService.create(data);
  return resp;
};

export const asignConstraintTask = async (data) => {
  const resp = await constraintService.asignConstraintTask(data);
  return resp;
};

export const createConstraint = async (data) => {
  const constraintCreated = await constraintService.create(data);
  return constraintCreated;
};

export const createExceptionDay = async (data) => {
  const excCreated = await calendarExceptionDayService.create(data);
  return excCreated;
};

export const asignProjectSubcontract = async (data) => {
  const resp = await projectService.asignProjectSubContract(data);
  return resp;
};

export const createSector = async (data) => {
  const resp = await sectorService.create(data);
  return resp;
};

export const createCalendar = async (data) => {
  const calendarCreated = await calendarService.create(data);
  return calendarCreated;
};

export const updateProject = async (data) => {
  const resp = await projectService.update(data);
  return resp;
};

export const getCompany = async (companyId) => {
  const resp = await companyService.show(companyId);
  return resp ? resp.company : false;
};

export const updateSector = async (data) => {
  const resp = await sectorService.update(data);
  return resp;
};

export const updateHistorical = async (activityId, progress) => {
  const newActivityHistorical = {
    activityId: activityId,
    progress: progress
  };
  await historicalActivityProgressService.create(newActivityHistorical);
};

export const getWeek = (week_current, week) =>
  moment()
    .day(1)
    .week(parseInt(week_current[0]) + (parseInt(week) - 3));

export const getDatesIni = (week_arr) => {
  const dates_ini = {
    week_1_1: moment(week_arr[1])
      .clone()
      .add(1 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_1_3: moment(week_arr[1])
      .clone()
      .add(3 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_2_1: moment(week_arr[2])
      .clone()
      .add(1 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_2_2: moment(week_arr[2])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_2_5: moment(week_arr[2])
      .clone()
      .add(5 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_3_1: moment(week_arr[3])
      .clone()
      .add(1 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_3_2: moment(week_arr[3])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_3_3: moment(week_arr[3])
      .clone()
      .add(3 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_3_4: moment(week_arr[3])
      .clone()
      .add(4 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_3_5: moment(week_arr[3])
      .clone()
      .add(5 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_4_1: moment(week_arr[4])
      .clone()
      .add(1 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_4_2: moment(week_arr[4])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_4_3: moment(week_arr[4])
      .clone()
      .add(3 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_4_4: moment(week_arr[4])
      .clone()
      .add(4 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_4_5: moment(week_arr[4])
      .clone()
      .add(5 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_5_2: moment(week_arr[5])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_5_5: moment(week_arr[5])
      .clone()
      .add(5 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_6_4: moment(week_arr[6])
      .clone()
      .add(4 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_6_5: moment(week_arr[6])
      .clone()
      .add(5 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_7_1: moment(week_arr[7])
      .clone()
      .add(1 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_7_2: moment(week_arr[7])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_7_4: moment(week_arr[7])
      .clone()
      .add(4 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_8_2: moment(week_arr[8])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_8_3: moment(week_arr[8])
      .clone()
      .add(3 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_9_5: moment(week_arr[9])
      .clone()
      .add(5 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_11_4: moment(week_arr[11])
      .clone()
      .add(4 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_14_2: moment(week_arr[14])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_15_2: moment(week_arr[15])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_17_2: moment(week_arr[17])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_19_2: moment(week_arr[19])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_21_2: moment(week_arr[21])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_23_2: moment(week_arr[23])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_24_2: moment(week_arr[24])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_27_2: moment(week_arr[27])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_29_1: moment(week_arr[29])
      .clone()
      .add(1 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_29_2: moment(week_arr[29])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_31_2: moment(week_arr[31])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_33_2: moment(week_arr[33])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_37_2: moment(week_arr[37])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_39_2: moment(week_arr[39])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_40_2: moment(week_arr[40])
      .clone()
      .add(2 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD'),
    week_43_1: moment(week_arr[43])
      .clone()
      .add(1 - 1, 'days')
      .startOf('day')
      .format('YYYY/MM/DD')
  };
  return dates_ini;
};

export const calculateHeightSchedule = () => {
  const HEIGHT_BORDER = 1;

  const getHeightElement = (id) => {
    return document.getElementById(id)?.offsetHeight || 0;
  };

  const toolbar = getHeightElement('toolbar_container');
  const toolbarCompany = getHeightElement('toolbar_company');
  const toolbarFiltersHeader = getHeightElement('toolbar_filters_header');

  const heightSchedule =
    toolbar + toolbarCompany + toolbarFiltersHeader + HEIGHT_BORDER;
  return heightSchedule;
};

export const getHeightSchedule = () => {
  return `calc(100vh - ${calculateHeightSchedule()}px)`;
};
