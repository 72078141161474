import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'antd';
import { userService } from '../../../services/user.service';
import EventEmitter from 'react-native-eventemitter';
import '../index.css';
import { openNotification } from '../../../utils';
import useConfigurationPermissions from '../../../hooks/useConfigurationPermissions';
import { withTranslation } from 'react-i18next';

// Navegaciones
import SettingUser from '../../setting/Navbars/SettingUser';
import CardProfile from '../../../components/Settings/settingsProyect/CardProfile';

// Cards config User
import CardSettingUser from '../../../components/Settings/settingsUser/User';
import { getSignedUser } from '../../../utils/userUtils';
function SettingsUser(props) {
  const { t } = props;
  const stateProject = useSelector((state) => state.projectState);

  const permission = useConfigurationPermissions();
  useEffect(() => {
    const callback = (data) => {
      if (!stateProject.projectSelected) {
        const alertErrorMailExists = {
          title: t('settings.settings'),
          description: t('settings.project_no_selected'),
          type: 'error'
        };
        openNotification(alertErrorMailExists);
      } else {
        data.route(props.history);
      }
    };
    EventEmitter.on('changeMainRoute', callback);
    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, [stateProject.projectSelected]);

  useEffect(() => {
    window.Appcues.page();
  });

  const [InfoUser, setInfoUser] = useState({});
  const [stateUpload, setStateUpload] = useState({ loading: false });
  const userStorage = getSignedUser();
  // get data from  user
  useEffect(() => {
    const abortController = new AbortController();
    if (userStorage.id !== null) {
      userService
        .show(userStorage.id)
        .then((response) => {
          setInfoUser(response.user);
        })
        .catch((err) => console.log(err));
    }
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  const renderWithPermission = () => {
    return (
      <div className="background-image">
        <div className="grid-settings">
          <CardProfile
            permissions={permission}
            {...props}
            InfoUser={InfoUser}
            setInfoUser={setInfoUser}
            stateUpload={stateUpload}
            setStateUpload={setStateUpload}
          />
          <div
            style={{
              marginTop: '140px',
              marginLeft: '135px',
              height: '100%'
            }}>
            <Card className="card-shawdow">
              <SettingUser t={t} />
              <CardSettingUser
                {...props}
                InfoUser={InfoUser}
                setInfoUser={setInfoUser}
                stateUpload={stateUpload}
                setStateUpload={setStateUpload}
              />
            </Card>
          </div>
        </div>
      </div>
    );
  };

  /**
   * Render
   */
  return renderWithPermission();
}

export default withTranslation()(SettingsUser);
