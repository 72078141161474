import React from 'react';
import TooltipSystemV2 from '../DesignSystem/TooltipSystemV2';

const TooltipWrapper = ({ shouldWrap, title, placement, children }) => {
  if (!shouldWrap) {
    return children;
  }

  return (
    <TooltipSystemV2 title={title} placement={placement}>
      {children}
    </TooltipSystemV2>
  );
};

export default TooltipWrapper;
