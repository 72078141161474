import React, { memo } from 'react';
import TruncatedTextSystem from '../../../../../DesignSystem/TruncatedTextSystem';

const Email = memo(({ email, width }) => {
  return (
    <TruncatedTextSystem
      name={email}
      maxWidth={width > 1600 ? 150 : 80}
      placement={'topLeft'}>
      <span className="email-column">{email}</span>
    </TruncatedTextSystem>
  );
});

export default Email;
