const CONSTRAINT_TYPES = Object.freeze({
  MFO: 'mfo',
  MSO: 'mso',
  FNLT: 'fnlt',
  FNET: 'fnet',
  SNET: 'snet',
  SNLT: 'snlt',
  ALAP: 'alap',
  ASAP: 'asap'
});

export { CONSTRAINT_TYPES };
