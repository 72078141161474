/** React stuff */
import React, { useImperativeHandle, useRef } from 'react';

/** Draggable stuff */
import { DragSource, DropTarget } from 'react-dnd';

/** Data typing */
import { ItemTypes } from './ItemTypes';

/** Component to handle resize events on dom elements */
import ReactResizeDetector from 'react-resize-detector';
import { Resizable } from 're-resizable';

/** Column component from grid of ant design css framework */
import { Col } from 'antd';

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'solid 0px #ddd',
  background: '#f0f0f000'
};

/** Single custom component to be draggable */
const Card = React.forwardRef(
  /** Elements that we want to extract from props */
  (
    {
      index,
      metadata,
      onDivResizeHandler,
      onDivResizeDoubleClicked,
      isDragging,
      connectDragSource,
      connectDropTarget
    },
    ref
  ) => {
    const elementRef = useRef(null);
    let w = 0;
    connectDragSource(elementRef);
    connectDropTarget(elementRef);
    const opacity = isDragging ? 0 : 1;
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current
    }));

    return (
      <Col
        key={index}
        span={metadata.span}
        offset={metadata.offset}
        style={{ textAlign: metadata.align, width: metadata.width }}>
        <ReactResizeDetector
          handleWidth
          onResize={(width, height) => {
            w = width;
          }}>
          <Resizable
            className="custom-resize-col-lookahead"
            maxWidth={1000}
            onMouseDown={() => onDivResizeDoubleClicked(metadata, true)}
            onResizeStop={() => onDivResizeHandler(w, metadata)}
            style={{ ...style, overflow: 'hidden' }}
            enable={{
              top: false,
              right: true,
              bottom: false,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false
            }}
            size={{
              width: metadata.width ? metadata.width : '100%',
              height: 18
            }}>
            <div
              ref={elementRef}
              style={{
                cursor: 'move',
                color: '#121212',
                fontSize: '13px',
                lineHeight: '15px'
              }}>
              {metadata.label}
            </div>
          </Resizable>
        </ReactResizeDetector>
      </Col>
    );
  }
);

/**
 * HOC to let dom elements being draggable
 */
export default DropTarget(
  ItemTypes.LOOKAHEAD_ORDER_COL,
  {
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    }
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget()
  })
)(
  DragSource(
    ItemTypes.LOOKAHEAD_ORDER_COL,
    {
      beginDrag: (props) => {
        props.metadata.isDragging = true;
        return {
          id: props.id,
          index: props.index
        };
      },
      endDrag(props, monitor, component) {
        props.metadata.isDragging = false;
        props.setResizing(false);
      },
      canDrag: (props, monitor) => true
      /* if (props.metadata.name != 'name' && props.metadata.name != 'add') {
                    return true
                } else {
                    return false
                } */
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    })
  )(Card)
);
