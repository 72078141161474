/* eslint-disable prefer-const */

/** React components  */
import React from 'react';

/** Redux implementation */
import { useSelector, useDispatch } from 'react-redux';
import { constraintActions } from '../../../../../redux/actions/constraintActions';

/** Import elements from library Antd */
import { Calendar, Tooltip } from 'antd';

/** import library for handle dates */
import moment from 'moment';

/** import components */
import HeaderCalendar from '../HeaderCalendar';

const formatDate = 'DD/MM/YYYY';

export default function ConstraintCalendar(props) {
  /** redux */
  const calendarState = useSelector((state) => state.calendarState);
  const constraintState = useSelector((state) => state.constraintState);
  const dispatch = useDispatch();

  const { setShowPopoverCalendar } = props;

  /** get range dates of exceptions */
  const rangesDates =
    calendarState.calendarSelected &&
    calendarState.calendarSelected.exceptions &&
    calendarState.calendarSelected.exceptions.map((el) => ({
      name: el.name,
      ini: moment(el.from_date).format(formatDate),
      end: moment(el.to_date).format(formatDate)
    }));

  /** search date in range */
  const belongsToRange = (currentDate, rangesDates) => {
    if (!rangesDates) {
      return [false, []];
    }
    let belongs = rangesDates.some((e) => {
      let startDate = moment(e.ini, formatDate);
      let endDate = moment(e.end, formatDate);
      return currentDate.isBetween(startDate, endDate, 'days', true);
    });

    let find = rangesDates.find((e) => {
      let startDate = moment(e.ini, formatDate);
      let endDate = moment(e.end, formatDate);
      return currentDate.isBetween(startDate, endDate, 'days', true);
    });
    /** belongs: found in range, find: match item */
    return [belongs, find];
  };

  /** this function draws days according to the date ranges */
  function onFullRender(date, currentView) {
    /** get data from date */
    const day = date.format('DD');
    const month = date.format('MM');

    /** current Month in view */
    const currentMonth = currentView.format('MM');

    /** handle style select date */
    let style;

    /** search date into range */
    let [fillCell, find] = belongsToRange(date, rangesDates);

    style = { background: fillCell ? '#ECECEC' : '#FFF' };

    /** differentiate day from other month */
    if (month !== currentMonth) {
      style.color = 'gray';
    }
    return (
      <Tooltip title={find ? find.name : ''}>
        <div className="constraint-item-calendar" /* style={style} */>
          {day}
        </div>
      </Tooltip>
    );
  }

  /** heade calendar custom */
  const header = (props) => <HeaderCalendar {...props} />;

  const handleSelect = (selectDate) => {
    dispatch(
      constraintActions.setConstraintForm({
        ...constraintState.constraintForm,
        calendarDate: selectDate
      })
    );
    setShowPopoverCalendar(false);
  };

  /** get min of array of objects (date_end) */
  const getEarlyEnd = (tasks, field = 'end_date') => {
    const min = tasks.reduce(
      (prev, curr) => (prev[field] < curr[field] ? prev : curr),
      moment()
    );
    return min[field];
  };

  /** this function evaluates dates to disable according taskList */
  const disabledDate = (current) => {
    /** get Early End date from taskList */
    const earlyEnd = getEarlyEnd(constraintState.constraintForm.taskList);

    /** build min & max dates for range */
    const minDate = moment().add(-1, 'days');
    const maxDate = moment(earlyEnd).add(+1, 'days');

    /** set Ranges ( case of not select Tasks ) */
    const retRange = current <= minDate || current > maxDate;
    const retNotTasksSelected = current <= minDate;

    /** disable dates according to range */
    return earlyEnd ? retRange : retNotTasksSelected;
  };

  /** render */
  return (
    <div className="constraint-calendar">
      <Calendar
        fullscreen={false}
        disabledDate={disabledDate}
        onSelect={handleSelect}
        dateFullCellRender={onFullRender}
        headerRender={header}
      />
    </div>
  );
}
