import { editing_flow } from '../../gantt_lifecycle/editing-flow';
import { updateCostAndHH } from '../utils';
import { checkLastEditedColumn } from './checkLastEditedColumn';

function updateParentDuration(activity, id, projectState) {
  const gantt = window.to_use_react_gantt;
  if (!gantt.loadedSuccesfully) {
    return;
  }

  if (
    !checkLastEditedColumn([
      'progress',
      'hhWorkTime',
      'duration',
      'cost',
      'used_cost',
      'real_cost'
    ])
  ) {
    return;
  }

  /**
   * This code section improves the performance by filtering those activities which values has changed.
   */
  const {
    progress,
    old_progress_registered,
    hhWorkTime,
    old_hhWorkTime_registered,
    duration,
    old_duration_registered,
    real_cost,
    cost,
    old_cost_registered,
    used_cost,
    old_used_cost_registered,
    old_real_cost_registered
  } = activity;
  const didChangeProgress = progress != old_progress_registered;
  const didChangeHH = hhWorkTime != old_hhWorkTime_registered;
  const didChangeDuration = duration != old_duration_registered;
  const didChangeCost = cost != old_cost_registered;
  const didChangeCostUsed = used_cost != old_used_cost_registered;
  const didChangeCostReal = real_cost != old_real_cost_registered;
  if (
    !gantt.is_task_moved &&
    (didChangeProgress ||
      didChangeHH ||
      didChangeCost ||
      didChangeCostUsed ||
      didChangeDuration ||
      didChangeCostReal)
  ) {
    activity.old_duration_registered = duration;
    activity.old_progress_registered = progress;
    activity.old_hhWorkTime_registered = hhWorkTime;
    activity.old_cost_registered = cost;
    activity.old_used_cost_registered = used_cost;
    activity.old_real_cost_registered = real_cost;
    editing_flow(gantt)(
      id,
      activity,
      () => gantt.updatePonderators,
      updateCostAndHH
    );
  }
}

export { updateParentDuration };
