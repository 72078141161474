export const leanStatusArray = [
  {
    color: '#E50101',
    value: 'Restricted',
    label: 'Restringida',
    description: 'LEAN debit status means...',
    weigth: 4
  },
  {
    color: '#C8C6C6',
    value: 'Debit',
    label: 'Debe',
    description: 'LEAN debit status means...',
    weigth: 3
  },
  {
    color: '#34AF00',
    value: 'Can',
    label: 'Puede',
    description: 'LEAN can status means...',
    weigth: 2
  },
  {
    color: '#25a8ba',
    value: 'Will',
    label: 'Plan Semanal',
    description: 'LEAN debit status means...',
    weigth: 1
  },
  {
    color: '#F59D04',
    value: 'Committed',
    label: 'Comprometida',
    description: 'LEAN debit status means...',
    weigth: 0
  }
];
