import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import modalStyles from '../views/ganttContainer/gantt/modals.module.scss';
import { WarningIcon } from '../icons';
import colors from '../stylesheets/variables.scss';
import cloneDeep from 'lodash/cloneDeep';

export default function useModalBasic(t, content, sufix = '') {
  const {
    message_i18,
    message,
    question_i18,
    title_i18,
    text_cancel_i18,
    text_ok_i18,
    hideCancelButton = false,
    hideOkButton = false,
    hideWarningIcon = false,
    hideCloseButton = false,
    footerAlign = 'center',
    customWidth = 380,
    linkRef,
    messageRef
  } = content;

  const sufixStrJsx = `jsxModal${sufix}`;
  const sufixStrCallback = `callbackModal${sufix}`;

  const [toReturn, setToReturn] = useState({
    sufixStrJsx: null,
    sufixStrCallback: () => {}
  });
  const [modalMetadata, setModalMetadata] = useState({
    visible: false,
    onOkPayload: () => {
      console.log('On ok payload');
    },
    onCancelPayload: () => {
      console.log('On Cancel payload');
    },
    title: 'Modal',
    content: <div>Modal</div>,
    cancelText: 'Cancel',
    okText: 'Ok'
  });

  const displayButtonsCss = {};
  if (hideCancelButton && !hideOkButton) {
    displayButtonsCss.margin = 'auto';
    displayButtonsCss.display = 'inline';
  }

  let stylesModal = `activity-modification-style ${modalStyles['gantt-alert-modal']}`;
  if (footerAlign === 'right') {
    stylesModal += `${stylesModal} ${modalStyles['footer-right']}`;
  }

  if (hideCloseButton) {
    stylesModal += `${stylesModal} ${modalStyles['without-close']}`;
  }

  useEffect(() => {
    const buttonCancelCss = {
      ...displayButtonsCss
    };

    const buttonOkCss = {
      ...displayButtonsCss
    };

    if (hideCancelButton) {
      buttonCancelCss.display = 'none';
    }

    if (hideOkButton) {
      buttonOkCss.display = 'none';
    }

    const propsModal = {
      wrapClassName: stylesModal,
      cancelText: modalMetadata.cancelText,
      okText: modalMetadata.okText,
      cancelButtonProps: { style: buttonCancelCss },
      okButtonProps: { style: buttonOkCss },
      centered: true,
      width: customWidth,
      visible: modalMetadata.visible,
      onOk: handleModalOk,
      onCancel: handleModalCancel
    };

    const dataReturn = title_i18
      ? {
          [sufixStrJsx]: (
            <Modal {...propsModal} title={modalMetadata.title}>
              {modalMetadata.content}
            </Modal>
          )
        }
      : {
          [sufixStrJsx]: (
            <Modal {...propsModal} title=" &nbsp;">
              {modalMetadata.content}
            </Modal>
          )
        };
    dataReturn[sufixStrCallback] = callbackFn;
    setToReturn(dataReturn);
  }, [modalMetadata]);

  /**
   * This function is executed by calling the second parameter of the custom hook, with callback notation.
   * @param {*} callbackOnOkayClick function that is executed when clicking on ok
   * @param {*} extraMsg message that is created in the callback, and that consists of 3 elements, to be able to style the number of activities
   * @returns true
   */
  const callbackFn = (callbackOnOkayClick = null, extraMsg = null) => {
    let str1, str2, str3;

    const messajeWithRef = (
      <a href={linkRef} target="_blank" className={'message-ref'}>
        {defineMessage(messageRef)}
      </a>
    );

    if (extraMsg && extraMsg.length) {
      str1 = extraMsg[0] || null;
      str2 = extraMsg[1] || null;
      str3 = extraMsg[2] || null;
    }
    try {
      modalMetadata.title = title_i18 ? t(title_i18) : ' &nbsp;';
      modalMetadata.okText = text_ok_i18 ? t(text_ok_i18) : '';
      modalMetadata.cancelText = text_cancel_i18 ? t(text_cancel_i18) : '';
      modalMetadata.content = (
        <div>
          {hideWarningIcon ? null : (
            <div className="icon-center">
              <WarningIcon color={colors.brandOrange40} />
            </div>
          )}
          {extraMsg && extraMsg.length ? (
            <div className="body-center">
              {str1 || null}
              {str2 ? (
                isNaN(parseInt(str2)) ? (
                  str2
                ) : (
                  <span className="number-msg">{str2}</span>
                )
              ) : null}
              {str3 || null}
            </div>
          ) : (
            <div className="body-center">
              {message_i18 ? defineMessage(message_i18) : message || ''}
              {messajeWithRef}
            </div>
          )}
          <div className="body-center">
            {question_i18 ? defineMessage(question_i18) : null}
          </div>
        </div>
      );
      modalMetadata.visible = true;
      modalMetadata.onOkPayload = () => {
        callbackOnOkayClick && callbackOnOkayClick();
      };
      modalMetadata.onCancelPayload = () => {};
      updateModalRender();
      return true;
    } catch (e) {}
  };

  const defineMessage = (message_i18) => {
    let msg = '';
    if (message_i18) {
      msg = t(message_i18);
    }
    return msg;
  };

  function handleModalOk(e) {
    modalMetadata.visible = false;
    if (modalMetadata.onOkPayload) {
      modalMetadata.onOkPayload();
    }
    updateModalRender();
  }

  function handleModalCancel(e) {
    modalMetadata.visible = false;
    if (modalMetadata.onCancelPayload) {
      modalMetadata.onCancelPayload();
    }
    updateModalRender();
  }

  function updateModalRender() {
    setModalMetadata((prev) => {
      prev = cloneDeep(modalMetadata);
      return prev;
    });
  }

  return toReturn;
}
