import React, { useState, useEffect } from 'react';
import CustomDropdownSelector from '../../../CustomDropdownSelector';
import { RENDER_MATERIAL } from '../constants';
import { withTranslation } from 'react-i18next';
import {
  findDeepGetTask,
  updateAsyncTaskGanttV2
} from '../../GanttVisualization.helper';

import materialIcon from '../../../../assets/img/newlookahead/resources/material.png';
import RemoveIcon from '../../../../assets/img/gantt/X.png';

const RenderMaterial = ({
  column,
  lastLevelActivities,
  activities,
  gantt,
  t,
  onlyRead,
  task,
  callbackOnClickCreate,
  callbackOnClickRemove,
  options,
  updateMemoryRefMaterial
}) => {
  const isOnlyReadElement = onlyRead || task.readonly;
  const [state, setState] = useState(RENDER_MATERIAL);
  const [resources, setResources] = useState([]);
  const [resourceMaterial, setResourceMaterial] = useState([]);
  const taskFromLookahead = findDeepGetTask(activities, 'id', task.id);

  // Update unit when a new material is created
  updateMemoryRefMaterial();

  useEffect(() => {
    options && setResources(options);
  }, [options]);

  useEffect(() => {
    if (resources) {
      setResourceMaterial(resources);
    }
  }, [resources]);

  useEffect(() => {
    setState({
      ...state,
      mainObject: task,
      options: resourceMaterial.map((r) => r.plain)
    });
  }, [task, resourceMaterial]);

  const callbackOnSelection = (payload) => {
    setState({
      ...state,
      mainObject: {
        ...state.mainObject,
        materialId: payload.split('-')[0]
      }
    });
    updateAsyncTaskGanttV2(
      taskFromLookahead,
      column.name,
      payload.split('-')[0],
      gantt
    );
  };

  const callbackRemove = (_) => {
    setState({
      ...state,
      mainObject: {
        ...state.mainObject,
        materialId: null
      }
    });
    callbackOnClickRemove && callbackOnClickRemove();
  };

  const renderMaterialToSelect = (option) => {
    const { object } = option;
    return (
      <div style={{ paddingLeft: 10 }}>
        <span>
          <img src={materialIcon} width={16} />
        </span>
        <span style={{ marginLeft: 10 }}>{option.name}</span>
      </div>
    );
  };

  const renderRemoveBtn = () => {
    return (
      <div className="remove-option-sub-custom">
        <div className="remove-sub-x">
          <img width="10" src={RemoveIcon} />
        </div>
        <div className="remove-sub-text">{t('remove_subcontract_label')}</div>
      </div>
    );
  };

  let isOdd = false;
  if (gantt?.oddColsConfig && task.type === 'activitytask') {
    isOdd = gantt.oddColsConfig.materialId;
  }

  if (!task.isTask) return null;
  return (
    <div className={isOdd ? 'odd-col' : 'non-odd-col'}>
      <CustomDropdownSelector
        {...state}
        customMainClassName="custom-material-gantt"
        renderCustomSelectionOption={renderMaterialToSelect}
        renderCustomRemoveOption={renderRemoveBtn}
        labelCreateBtn={
          <div className="create-sub-btn">+ {t('new_material_dropdown')}</div>
        }
        callbackOnSelection={callbackOnSelection}
        callbackOnClickCreate={callbackOnClickCreate}
        callbackOnClickRemove={callbackRemove}
        onlyread={isOnlyReadElement}
      />
    </div>
  );
};

export default withTranslation()(RenderMaterial);
