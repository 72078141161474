import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Row } from 'antd';
import { openDrawer } from '../../redux/slices/hoveringStackSlice';
import {
  getVisualizationConfigSelector,
  getSelectedActivitiesSelector
} from '../../redux/slices/ganttSlice';
import { ToolbarGroup } from '../ToolbarGroup';
import {
  MASSIVE_BTN_UNCHECK_CONFIG,
  MASSIVE_SETTINGS_CONFIG,
  CONSTRAINT_TYPES_SUBMENU_ITEMS
} from './constants';
import styles from './GanttMassiveToolbar.module.scss';
import { isFeatureOn } from '../../utils/featureUtils';
import { FEATURE_FLAGS } from '../../constants/featureFlags';
import { enableForMassiveSelect } from '../../views/ganttContainer/gantt/gantt.helper';
export const GanttMassiveToolbar = (props) => {
  const {
    selectedActivities,
    onCommandDispatched,
    t,
    constraintTypes,
    setConstraintTypes,
    ganttObject
  } = props;
  const [items, setItems] = useState([]);

  const handleCommandDispatched = (command, value) =>
    onCommandDispatched(command, value);

  useEffect(() => {
    let newItems = [];

    if (selectedActivities.length) {
      /** massive is active */
      newItems = [MASSIVE_BTN_UNCHECK_CONFIG, MASSIVE_SETTINGS_CONFIG];
    }
    /** update state only if there is change in the data */
    if (newItems.length !== items.length) {
      setItems(newItems);
    }
  }, [selectedActivities, constraintTypes]);

  /** case white screen */
  if (
    !ganttObject.toSelectResponsables &&
    !ganttObject.toSelectTags &&
    !ganttObject.subContracts
  )
    return null;

  if (!selectedActivities.length) return null;
  const isBulkActionsBarFeatureEnabled =
    isFeatureOn(FEATURE_FLAGS.ENABLE_BULK_ACTIONS_BAR) &&
    enableForMassiveSelect();
  return isBulkActionsBarFeatureEnabled ? (
    <Row className="gantt-header-filters gantt-massive-actions">
      <div className={styles['gantt-toolbar']}>
        <ToolbarGroup
          items={items}
          onCommandDispatched={handleCommandDispatched}
          t={t}
          selectedActivities={selectedActivities}
          type="massive-toolbar"
          ganttObject={ganttObject}
          toSelectResponsables={props.toSelectResponsables}
          toSelectTags={props.toSelectTags}
          subContracts={props.subContracts}
        />
      </div>
    </Row>
  ) : null;
};

const actionCreators = {
  openDrawer
};

const mapStateToProps = (state) => ({
  visualizationConfig: getVisualizationConfigSelector(state),
  selectedActivities: getSelectedActivitiesSelector(state)
});

export default connect(mapStateToProps, actionCreators)(GanttMassiveToolbar);
