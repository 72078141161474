import { useEffect } from 'react';
import EventEmitter from 'react-native-eventemitter';

const changeMainRouteEvent = 'changeMainRoute';

export const useNavigateFromNavbar = (history) => {
  useEffect(() => {
    const navigate = ({ route }) => route(history);
    EventEmitter.on(changeMainRouteEvent, navigate);

    return () => EventEmitter.removeListener(changeMainRouteEvent, navigate);
  }, []);
};
