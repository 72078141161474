export const ITEM_COUNT = 30;
export const ITEM_SIZE = 28;
export const HEIGHT_ROW = 140;
export const MAX_LENGTH_TITLE_DEPENDECIE = 15;
export const MAX_LENGTH_TITLE_DEPENDECIE_POPOVER = 15;
export const DEFAULT_NUMBER_TYPE_TASK = '0';
export const DEFAULT_LAG = 0;
export const DEFAULT_TEXT_TYPE_TASK = 'fs';
export const DEFAULT_TEXT_LAG = '0 days';
export const ORANGE_IPSUM = '#7DFF8A';
export const WHITE_COLOR = '#FFF';
export const GREEN_COLOR = '#53C255';
export const GREY_COLOR = '#121212';
export const EMPTY_ALERT_KEY = 'empty';
export const PREDECESSOR_ALERT_KEY = 'predecessor';
export const SUCCESSOR_ALERT_KEY = 'successor';
export const PARENT_ALERT_KEY = 'parent';
export const CIRCULAR_LINK_ALERT_KEY = 'circularLink';
export const LAG_FORMAT_ALERT_KEY = 'lagFormat';
