/* eslin -disable no-eval */
/** React stuff */
import React, { useState, useEffect, useRef } from 'react';

/** Components from ant design css framework */
import {
  Button,
  Modal,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Input,
  InputNumber,
  Progress,
  Popover,
  Icon,
  Empty,
  Tree
} from 'antd';

import { CloseOutlined, LeftOutlined } from '@ant-design/icons';

/** import icons from antd framework */
import { PlusOutlined } from '@ant-design/icons';

/** Messaging for user notification util */
import { openNotification } from '../../../utils';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

import cloneDeep from 'lodash/cloneDeep';

import CustomCheckbox from '../../CustomCheckbox';
import childArrow from '../../../assets/img/child-arrow.png';

/** Redux */
import { useSelector } from 'react-redux';

/** Styles on pure css */
import './index.css';
import { FixedSizeList as List } from 'react-window';

/** services */
import { sectorResourcesService } from '../../../services/sectorresource.service';

import LazyElement from '../../LazyElement';
import EditableInput from './EditableInput';

/** PNG for task planification edit and save tasks */
import deleteIcon from '../../../assets/img/DLT-DarkGrey-1080x1080.png';
import AssingTable from './listassing';
import { withTranslation } from 'react-i18next';
/**
 * This component prints an header with activity data, and then renders it's tree tasks, allowing user to interact each row data.
 */
const { Search } = Input;
function ResourceActivity(props) {
  /** Project state from redux */
  const { t } = props;
  const { permission } = props;
  const [onlyRead, setOnlyRead] = useState(permission == 'V');
  const [expandedKeys, setExpandedKeys] = useState(['0-0-0']);
  const [checkedKeys, setCheckedKeys] = useState(['0-0-0']);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const projectState = useSelector((state) => state.projectState);
  const virtualizeRef = useRef();
  const [popsVisibility, setPopsVisibility] = useState({});
  const [editedInput, setEditedInput] = useState(null);
  const { height, width } = useWindowDimensions();
  const [popsVisibilityModal, setPopsVisibilityModal] = useState(false);
  const [modelResource, setModelResource] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [arraytreeData, setArrayTreeData] = useState([]);
  const [activitySelected, setActivitySelected] = useState([]);
  const actvitys_props = props.actvitys;
  const [activitiesResourceSelected, setActivitiesResourceSelected] = useState(
    []
  );

  useEffect(() => {
    const id = props.lastLvlActivity.id;
    if (props.scrollStates[id]) {
      if (virtualizeRef.current) {
        virtualizeRef.current.scrollTo(props.scrollStates[id]);
      }
    }

    return () => {
      if (virtualizeRef.current) {
        props.scrollStates[id] = virtualizeRef.current.state.scrollOffset;
      }
    };
  }, [virtualizeRef]);

  /**
   * Clone deep of a new state to set and render view
   * @param {*} newState new state value (inmutability is broken)
   */
  const updateState = (newState = popsVisibility) => {
    setPopsVisibility((prev) => {
      prev = cloneDeep(newState);
      return prev;
    });
  };

  /**
   * This functions shows a pretty alert to user
   * @param {*} data Object { title, message, type }
   */
  const notifyMessage = (data) => {
    const alertErrorMailExists = {
      title: data.title,
      description: data.message,
      type: data.type
    };
    openNotification(alertErrorMailExists);
  };

  /**
   * On click function when pressing delete
   * @param {*} task Top lvl task to start tree deleting
   * @param {*} activity Activity parent object
   * @param {*} parent If task is from another than first level must be specified the parent task to attack it children array
   */
  const handleDelete = async (task, activity, parent = null) => {
    const deleted = await sectorResourcesService.destroy(task.id);
    if (deleted.msj) {
      notifyMessage({
        title: t('master_plan_resources.removed'),
        message: t('master_plan_resources.removed_resource_alert'),
        type: 'success'
      });
      props.virtualizeRef.current.resetAfterRowIndex(props.index);
      const newStateTasks = activity.tasks.filter((el) => el.id !== task.id);
      /** update elements */
      activity.tasks = newStateTasks;
      updateState();
    }
  };

  /**
   * This functions create a task and associate it to his activity parent
   * @param {*} resource Resource object to which we are going to add a new task
   */
  const handleAdd = (resource) => {
    createResource('New Resource', resource);
  };

  /**
   * This function creates a new resource
   * @param {*} name Name to show to the new task
   * @param {*} activity Activity superior parent object
   */
  const createResource = async (name, activity) => {
    const toPush = {
      name: name,
      type: activity.type,
      material_label: 'Un',
      total: 0,
      used: 0,
      sectorresources: [],
      sectorId: projectState.sectorSelected
    };
    const res = await sectorResourcesService.create(toPush);
    if (res) {
      toPush.id = res.id;
      activity.tasks.push(toPush);
      props.virtualizeRef.current.resetAfterRowIndex(props.index);
      updateState();
    }
  };

  const hideChilds = (parent) => {
    parent.hide_childs = true;
    updateState();
  };

  const showChilds = (parent) => {
    parent.hide_childs = false;
    updateState();
  };

  const defineCollapseIcon = (parent) => {
    const children = parent.tasks || parent.children;
    if (children) {
      if (children.length) {
        if (parent.hide_childs) {
          return (
            <i
              onClick={() => showChilds(parent)}
              className="fa fa-angle-down show-childs-btn"
              aria-hidden="true"
            />
          );
        }
        return (
          <i
            onClick={() => hideChilds(parent)}
            className="fa fa-minus hide-childs-btn"
            aria-hidden="true"
          />
        );
      }
    }
  };

  /**
   * This function defines what should be showed inside each task column at table.
   * This by using a flag from task, isEditing, when is active renders something, and when is not active
   * renders another jsx
   * @param {*} column Metadata from the column that is going to be extracted from the task
   * @param {*} task Task object to extract the column data
   * @param {*} activity Parent activity for tasks from first level
   * @param {*} parentTask Parent task if it is not from first level
   */
  const defineTaskColumnContent = (
    column,
    task,
    activity,
    parentTask,
    index
  ) => {
    if (column.data_type == 'string') {
      if (column.name == 'name') {
        let option = props.tableMetadata.filter(
          (el) => el.name == 'lean_status'
        );
        option = option[0];
        return (
          <span className="vertical-center">
            {onlyRead ? null : (
              <CustomCheckbox
                onClick={() => {
                  props.massiveSelectionHandler(task);
                  // updateState()
                }}
                active={task.active}
              />
            )}
            {task.parent_id ? (
              <img style={{ marginRight: 6 }} width={12} src={childArrow} />
            ) : null}
            {defineCollapseIcon(task)}
            <EditableInput
              disabled={onlyRead}
              service={sectorResourcesService}
              onEdit={setEditedInput}
              isEditing={editedInput}
              renderEditable={(
                column,
                index,
                task,
                value,
                setValue,
                updateParentData = null,
                handleEsc = null
              ) => (
                <Input
                  onKeyDown={handleEsc}
                  onPressEnter={updateParentData}
                  key={index}
                  id={column.name + task.id}
                  size="small"
                  value={value}
                  className="custom-input-planification"
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => setValue(e.target.value)}
                />
              )}
              updateState={updateState}
              index={index}
              column={column}
              task={task}
            />
            {onlyRead ? null : (
              <span
                onClick={() => handleDelete(task, activity, parentTask)}
                style={{ cursor: 'pointer', marginLeft: 5 }}>
                <Tooltip placement="top" title={t('delete_task_label')}>
                  <img
                    className="delete-input-icon"
                    src={deleteIcon}
                    width={10}
                  />
                </Tooltip>
              </span>
            )}
          </span>
        );
      } else if (column.name == 'material_label') {
        return (
          <span className="vertical-center">
            <EditableInput
              disabled={onlyRead}
              service={sectorResourcesService}
              onEdit={setEditedInput}
              isEditing={editedInput}
              renderEditable={(
                column,
                index,
                task,
                value,
                setValue,
                updateParentData = null,
                handleEsc = null
              ) => (
                <Input
                  onKeyDown={handleEsc}
                  onPressEnter={updateParentData}
                  key={index}
                  id={column.name + task.id}
                  size="small"
                  value={value}
                  className="custom-input-planification"
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => setValue(e.target.value)}
                />
              )}
              updateState={updateState}
              index={index}
              column={column}
              task={task}
            />
          </span>
        );
      } else if (column.name == 'assignar') {
        return (
          <span className="vertical-center">
            <Button
              disabled={onlyRead}
              className="gantt-main-container-btn-style button-resource"
              type="primary"
              onClick={(e) => handleClick(e)}></Button>
          </span>
        );
      } else if (column.name == 'assignartask') {
        return (
          <span className="vertical-center">
            {task.sectorresources ? task.sectorresources.length : '0'}
          </span>
        );
      }
    } else if (column.data_type == 'number') {
      if (column.name == 'total') {
        let total = 0;
        if (task.sectorresources) {
          task.sectorresources.map((activy) => {
            total = total + activy.activitysectorresource.quantity;
          });
        }
        return (
          <span className="vertical-center">
            {Number.isInteger(total) ? total : total.toFixed(2)}
          </span>
        );
      }
      return (
        <span className="vertical-center">
          {Number.isInteger(task[column.name])
            ? task[column.name]
            : task[column.name].toFixed(2)}
        </span>
      );
    } else if (column.data_type == 'button') {
      return (
        <span>
          <Button
            disabled={onlyRead}
            className="button-resource"
            type="primary"
            onClick={(e) => handleClick(e, task)}>
            {t('assign_activities')}
          </Button>
        </span>
      );
    }
  };

  const handleClick = (e, task) => {
    setActivitySelected([]);
    setActivitiesResourceSelected(task.sectorresources);
    setModelResource(task);
    const actvitys = [];
    actvitys_props.activity.map((actvity_prop) => {
      const ap = {
        id: parseInt(actvity_prop.unique_id),
        proplanner_id: actvity_prop.id,
        parentId: parseInt(actvity_prop.parent_id),
        title: actvity_prop.name,
        level: actvity_prop.parent_id,
        key: '0-0',
        nivel: 0,
        position: 0,
        has_childs: actvity_prop.has_childs,
        cantidad: '',
        update: false,
        unit: task.material_label,
        children: null,
        correlative_id: actvity_prop.correlative_id
      };
      actvitys.push(ap);
    });
    actvitys.sort(compare);
    // const validate = await list_to_tree(actvitys);
    list_to_tree(actvitys, task);
  };

  function compare(a, b) {
    if (a.correlative_id < b.correlative_id) {
      return -1;
    }
    if (a.correlative_id > b.correlative_id) {
      return 1;
    }
    return 0;
  }

  async function list_to_tree(list, task) {
    const push_array = [];
    const push_checkout_key = [];
    const push_expance_key = [];
    const selectd_array = [];
    const map = {};
    let node;
    const roots = [];
    let i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentId !== 0) {
        // if you have dangling branches check that map[node.parentId] exists
        node.key = '0-0-' + map[node.parentId] + '-' + i;
        push_expance_key.push(node.key);
        const selected = task.sectorresources.find(
          (o) => o.id === node.proplanner_id
        );
        node.nivel = map[node.parentId];
        if (selected) {
          // var resourcequetity = selected.activitysectorresource.find(o => o.sectorresourceId === task.id);
          node.cantidad = selected.activitysectorresource.quantity;
          node.update = true;
          push_checkout_key.push(node.key);
          selectd_array.push(node);
        }
        push_array.push(node);
        list[map[node.parentId]].children.push(node);
        // list[map[node.parentId]].children.sort( );
      } else {
        roots.push(node);
      }
    }
    setActivitySelected(selectd_array);
    setArrayTreeData(push_array);
    setTreeData(roots);
    setCheckedKeys(push_checkout_key);
    setExpandedKeys(push_expance_key);
    setSelectedKeys(push_checkout_key);
    setAutoExpandParent(true);
    setPopsVisibilityModal(true);
    // setPopsVisibilityModal(true)
    return true;
  }

  const handleClicModalClose = () => {
    setPopsVisibilityModal(false);
  };

  /**
   * This function renders the column container, and then goes to defineTaskColumnContent function
   * to define what render inside
   * @param {*} column Metadata from the column that is going to be extracted the task
   * @param {*} task Task object to extract the column data
   * @param {*} tabulation Integer to add as marginLeft to display tree structure
   * @param {*} index Index from the array iteration
   * @param {*} activity Parent activity for tasks from first level
   * @param {*} parentTask Parent task if it is not from first level
   */
  const renderTaskColumn = (
    column,
    task,
    tabulation,
    index,
    activity,
    parentTask,
    lvl
  ) => {
    const auxTabulation = tabulation;

    /* if (lvl >= 2) {
            const paddingMultiplierFix = 0.1 * (lvl + 1)
            auxTabulation = tabulation + (tabulation * paddingMultiplierFix)
        } */

    if (true) {
      return (
        <Col
          className="single-task-column-style"
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            paddingLeft: column.name == 'name' ? auxTabulation : 0,
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineTaskColumnContent(column, task, activity, parentTask, index)}
        </Col>
      );
    }
  };

  /**
   * Recursive function to display at virtual dom the tree nested table format
   * @param {*} tasks Array of tasks
   * @param {*} finalArray Final array to display JSX at virtual dom
   * @param {*} activity Parent activity
   * @param {*} tabulation Padding left to display tree table format
   * @param {*} parentTask Instance of the parent task if it is not from first lvl
   */
  const renderChildTreeTasks = (
    tasks,
    finalArray,
    activity,
    tabulation,
    parentTask = null,
    lvl = 0
  ) => {
    tasks &&
      tasks.map((task) => {
        const childs = task.children;
        const hasChilds = childs && childs.length != 0;

        if (!task.hide) {
          if (props.resizing) {
            finalArray.push(
              <Row key={task.id} className="fit-at-middle-row"></Row>
            );
          } else {
            finalArray.push(
              <Row
                style={{ backgroundColor: task.active ? '#12121210' : null }}
                key={task.id}
                className="fit-at-middle-row">
                {props.tableMetadata.map((eachColumn, index) =>
                  renderTaskColumn(
                    eachColumn,
                    task,
                    tabulation || 0,
                    index,
                    activity,
                    parentTask,
                    lvl
                  )
                )}
              </Row>
            );
          }
        }

        if (hasChilds && !task.hide_childs) {
          const newLevel = lvl + 1;
          renderChildTreeTasks(
            childs,
            finalArray,
            activity,
            tabulation + (task.hide ? 0 : 15),
            task,
            newLevel
          );
        }
      });
  };

  /** This function is used for show tasks quantity on virtual dom who renders them and resize height */
  const defineVirtualizedHeight = (taskCounter) => {
    const tempHeight = taskCounter * 40;
    const maxHeight = height < 700 ? height * 0.45 : height * 0.6;
    if (tempHeight < maxHeight) {
      return tempHeight;
    }
    return maxHeight;
  };

  /**
   * This function is used by virtual dom to render tasks associated to activity
   * @param {*} activity Activity object to show his child tasks
   */
  const renderTasks = (activity) => {
    const childRended = [];
    renderChildTreeTasks(activity.tasks, childRended, activity);
    activity.childRended = childRended.length;
    props.virtualizeRef.current.resetAfterRowIndex(props.index);
    /**
     * To Optimize render of this child list an option is
     * https://react-window.now.sh/#/examples/list/variable-size
     * with childRended, we can create a virtual list to handle performance
     */

    if (activity.hide_childs) {
      return null;
    } else if (childRended.length) {
      return (
        <List
          ref={virtualizeRef}
          height={defineVirtualizedHeight(childRended.length)}
          itemCount={childRended.length}
          itemSize={40}
          width={'100%'}>
          {LazyElement(childRended, virtualizeRef)}
        </List>
      );
    }
    return (
      <div style={{ marginTop: 20 }}>
        <Empty
          className="empty"
          style={{ margin: 0 }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <span>{t('master_plan_resources.place_holder_table')}</span>
          }
        />
      </div>
    );
  };

  /**
   * This function defines what content show inside each header with activity data
   * @param {*} column Metadata from the column that is going to be extracted the task
   * @param {*} activity Activity object to extract the column data
   */
  const defineActivityColumnContent = (column, activity) => {
    if (column.name == 'name') {
      return (
        <div>
          {defineCollapseIcon(activity)}
          <span>{activity[column.name]}</span>
        </div>
      );
    } else if (
      column.data_type == 'string' ||
      column.data_type == 'number' ||
      column.data_type == 'button'
    ) {
      return activity[column.name] ? (
        activity[column.name]
      ) : (
        <div className="transparent-for-activity-column-header">-</div>
      );
    }
  };

  /**
   * This function renders each activity column, and then calls defineActivityColumnContent to define
   * what jsx els must be showed inside of this col
   * @param {*} column Metadata from the column that is going to be extracted the task
   * @param {*} activity Activity object to extract the column data
   * @param {*} index Index from array iteration
   */
  const renderActivityColumn = (column, activity, index) => {
    if (true) {
      return (
        <Col
          className="single-column-header"
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineActivityColumnContent(column, activity)}
        </Col>
      );
    }
  };

  /**
   * This function render the header from the table, with the activity data
   */
  const renderActivityHeader = () => (
    <Row className="custom-header-top-list">
      {props.tableMetadata.map((eachColumn, index) =>
        renderActivityColumn(eachColumn, props.lastLvlActivity, index)
      )}
    </Row>
  );

  /**
   * This function render Add Constraint button
   */
  const renderAddTask = () => {
    if (onlyRead) {
      return null;
    }

    return (
      <div
        className="resource-add-btn"
        onClick={() => handleAdd(props.lastLvlActivity)}>
        <PlusOutlined />
        {t('master_plan_resources.add_resource')}
      </div>
    );
  };

  const onExpand = (expandedKeys) => {
    // or, you can remove all expanded children keys.

    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeys) => {
    const selectd_array = [];
    checkedKeys.map((checkedKey) => {
      const selected = arraytreeData.find((o) => o.key === checkedKey);
      if (selected) {
        if (!selected.has_childs) {
          selectd_array.push(selected);
        }
      }
    });
    setCheckedKeys(checkedKeys);
    setActivitySelected(selectd_array);
  };

  const onSelect = (selectedKeys, info) => {
    setSelectedKeys(selectedKeys);
  };

  const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  };

  const onChange = (e) => {
    const { value } = e.target;

    const expandedKeys = arraytreeData
      .map((item) => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, treeData);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(true);
  };

  /**
   * Render
   */
  return (
    <Row className="divReourse" closable={false} key={props.index}>
      <Col>{props.headerJsx}</Col>
      <Col
        className="tableConstraints"
        style={{ marginTop: 10, marginBottom: 10 }}>
        {renderTasks(props.lastLvlActivity)}
      </Col>
      {renderAddTask()}
      <Modal
        className="frm-constraint"
        visible={popsVisibilityModal}
        bodyStyle={{ height: '750px', overflow: 'auto' }}
        width={1200}
        // onOk={this.handleOk}
        onCancel={handleClicModalClose}
        footer={
          /** submit button in footer */

          <div className="constraint-footer-btn" key="btns"></div>
        }>
        <div className="title-frm-add">
          {t('master_plan_resources.model_title')}
        </div>
        <h2 className="current-resource-modal">
          {t('master_plan_resources.model_title')}{' '}
          <strong className="current-value-resource-modal">
            {modelResource ? modelResource.name : null}
          </strong>
        </h2>
        <Row className="frm">
          <Col span={9} className="treeResourceModal">
            <Search
              style={{ marginBottom: 8 }}
              className="input-search-resource"
              placeholder="Search"
              onChange={onChange}
            />
            {
              <Tree
                checkable
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                onSelect={onSelect}
                selectedKeys={selectedKeys}
                treeData={treeData}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%'
                }}
                className="tree-scroll"
              />
            }
          </Col>
          <Col span={1} className="blue-line"></Col>
          <Col span={14} className="resumResourceModal">
            <AssingTable
              activity_selected={activitySelected}
              unit_id={modelResource ? modelResource.id : ''}
              activitiesResourceSelected={activitiesResourceSelected}
              resource_id={modelResource ? modelResource.id : ''}
            />
          </Col>
        </Row>
      </Modal>
    </Row>
  );
}

export default withTranslation()(ResourceActivity);
