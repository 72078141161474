import notificationSystemV2 from '../../../../../../components/DesignSystem/NotificationSystemV2/NotificationSystemV2';
import * as Sentry from '@sentry/react';
import { trackingEventNetworkBlocking } from './trackingEvent';
import { MESSAGE_FIT_ONE_PAGE } from '../constants';

const INFO_TYPE = 'info';
const SUCCESS_TYPE = 'success';
const ERROR_TYPE = 'error';

/**
 * Shows a network blocking alert and tracks the event.
 *
 * @param {Object} t - Translation function.
 * @param {boolean} isSchedule - Indicates if the alert is related to the schedule.
 */
export const showNetworkBlockAlert = ({ t, isSchedule }) => {
  try {
    const message = t('export_pdf_modal.network_blocking_alert');
    notificationSystemV2({
      key: 'network-blocking-alert',
      type: ERROR_TYPE,
      message
    });

    trackingEventNetworkBlocking({ t, isSchedule });
  } catch (error) {
    Sentry.captureException(error);
  }
};
/**
 * Shows a notification based on the export result and tracks the event.
 *
 * @param {Object} exportResult - The result of the PDF export.
 * @param {Function} t - Translation function.
 * @returns {string} The event result type.
 */
export const showExportPDFAlert = ({ exportResult, t }) => {
  const TOAST_KEY = 'exportable-sent-by-mail';

  const toastType = () => {
    if (!exportResult) {
      const message = t('export_lookahead_pdf_gantt.notification.error');
      const eventResult = 'lookahead exportable failed';
      return { message, type: ERROR_TYPE, eventResult };
    }

    const { url, message: messageResult } = exportResult;

    if (url) {
      const message = t('export_lookahead_pdf_gantt.notification.success');
      const eventResult = 'lookahead exportable generated';
      return { message, type: SUCCESS_TYPE, eventResult };
    }

    if (messageResult === MESSAGE_FIT_ONE_PAGE) {
      const eventResult = 'lookahead exportable generated';
      return { eventResult };
    }

    const message = t('export_lookahead_pdf_gantt.notification.send_mail');
    const eventResult = 'lookahead exportable sent';
    return { message, type: INFO_TYPE, eventResult };
  };

  const { message, type, eventResult } = toastType();

  type &&
    notificationSystemV2({
      key: TOAST_KEY,
      type,
      message
    });

  return eventResult;
};
