import * as Sentry from '@sentry/react';
import { base } from '../services/base';
import { Integrations } from '@sentry/tracing';
import { BreadcrumbLevels } from '../constants/breadcrumbTypes.constants';

export const start = (history) => {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  Sentry.init({
    dsn: 'https://6c707f47ee1d4264a9fbbde632e7fac7@o1357571.ingest.sentry.io/6735181',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Integrations.BrowserTracing(),
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.replayIntegration(),
      Sentry.replayCanvasIntegration()
    ],
    tracesSampleRate: 1.0,
    environment: base.returnEnviroment,
    attachStacktrace: true,
    release: base.version ? base.version : 'not production'
  });
  if (currentUser) {
    Sentry.setUser({ email: currentUser.email });
  }
};

export const log = (category, message) => {
  Sentry.addBreadcrumb({
    category: category,
    message: message,
    level: 'info'
  });
};

export const addBreadcrumbUtil = (
  category,
  message,
  level = 'info',
  data = {}
) => {
  Sentry.addBreadcrumb({
    category,
    message,
    level,
    data
  });
};

export const sentryLogger = {
  info: (message, context = {}) => {
    addBreadcrumbUtil(
      context.entityName,
      message,
      BreadcrumbLevels.INFO,
      context
    );
  },

  warn: (message, context = {}) => {
    addBreadcrumbUtil(
      context.entityName,
      message,
      BreadcrumbLevels.WARNING,
      context
    );
  },

  error: (message, context = {}) => {
    Sentry.withScope((scope) => {
      scope.setTag('entityName', context.entityName);
      scope.setExtras(context);
      Sentry.captureException(context.error || new Error(message));
    });
  }
};
