/* eslint-disable prefer-const */

/** React components  */
import React, { useEffect, useState } from 'react';

/** Import elements from library Antd */
import { Row, Input, Col, Button, Modal } from 'antd';
import { CloseOutlined, LeftOutlined } from '@ant-design/icons';

/** import common functions from utils */
import { openNotification } from '../../../utils';

/** Redux implementation */
import { useSelector, useDispatch } from 'react-redux';
import { calendarActions } from '../../../redux/actions/calendarActions';

/** Services */
import {
  calendarExceptionDayService,
  calendarService,
  ganttService,
  workingdayService
} from '../../../services';

import cloneDeep from 'lodash/cloneDeep';

/** import components */
import FormDays from '../../../components/Calendar/CalendarForm/FormDays';
import FormExceptionsDays from '../../../components/Calendar/CalendarForm/FormExceptionsDays';
import { withTranslation } from 'react-i18next';

import { trackingEvent } from '../../../analytics/index';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';

import shiftIsValid from '../utils/shiftIsValid';

function CalendarForm(props) {
  /** traduction */
  const { t } = props;
  /** use props */
  const { visibleForm, setVisibleForm, formatShift, add1Hour, calendarsList } =
    props;

  /** Redux */
  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.projectState);
  const calendarState = useSelector((state) => state.calendarState);
  const nameCalendar = calendarState.calendarForm
    ? calendarState.calendarForm.name
    : null;

  /** consts */
  const alertCalendarEditSuccess = {
    /** notification exit */ title: t('calendars_form.title'),
    description: t('calendars_form.add'),
    type: 'success'
  };
  const alertCalendarNothingDay = {
    /** notification error some day */ title: t('calendars_form.title'),
    description: t('calendars_form.select_day'),
    type: 'error'
  };
  const alertCalendarNothingWorkingDay = {
    title: t('lang') === 'es' ? 'Ninguna jornada creada' : 'No shift created',
    description:
      t('lang') === 'es'
        ? 'Al menos debes tener una jornada laboral'
        : 'You must have at least one working day',
    type: 'error'
  };
  const alertCalendarAddError = {
    /** notification error */ title: t('calendars_form.title'),
    description: t('calendars_form.review_config'),
    type: 'error'
  };
  const alertCalendarExceptionError = {
    /** notification error exceptions */ title: t('calendars_form.title'),
    description: t('calendars_form.review_excep'),
    type: 'error'
  };

  const loggedUser = JSON.parse(localStorage.getItem('user'));

  /** object by default for Calendar Form */
  const templatecalendar = {
    name: null,
    days: cloneDeep([...Array(7).keys()].map((i) => false)),
    shiftStart_ini: cloneDeep([...Array(7).keys()].map((i) => false)),
    shiftEnd_ini: cloneDeep([...Array(7).keys()].map((i) => false)),
    shiftStart_end: cloneDeep([...Array(7).keys()].map((i) => false)),
    shiftEnd_end: cloneDeep([...Array(7).keys()].map((i) => false)),
    exceptions: []
  };

  /** Hooks */
  const [currenTab, setCurrenTab] = useState(1); /** handle tabs (1, 2, 3) */
  const [currentGantt, setCurrentGantt] = useState();
  const [currentCalendar, setCurrentCalendar] =
    useState(templatecalendar); /** handle calendar state */
  const [loading, setLoading] = useState(false); /** handle load */

  /** load data */
  useEffect(() => {
    loadGantt();
  }, []);

  const getGantt = async () => {
    const gannt = await ganttService.showBySector(projectState.sectorSelected);
    return gannt;
  };

  const loadGantt = async () => {
    const gantt = await getGantt();
    if (gantt) {
      setCurrentGantt(gantt.gantt.id);
    }
  };

  /** services */
  const createCalendar = async (data) => {
    const calendarCreated = await calendarService.create(data);
    return calendarCreated;
  };

  const createExceptionDay = async (data) => {
    const excCreated = await calendarExceptionDayService.create(data);
    return excCreated;
  };

  const getCalendars = async () => {
    const calendars = await calendarService.showBySector(
      projectState.sectorSelected
    );
    return calendars;
  };

  /** Component Logic */
  const resetStateCalendar = () => {
    setCurrentCalendar(templatecalendar); /** set state calendar to default */
    setCurrenTab(1); /** set tab to step 1 */
    setVisibleForm(false); /** close modal */
  };

  /** handle tabs / submit data */
  const handleContinue = async () => {
    /** submit data */
    const start_ini = calendarState.calendarForm.shiftStart_ini
      ? formatShift(calendarState.calendarForm.shiftStart_ini)
      : null;
    const start_end = calendarState.calendarForm.shiftStart_end
      ? formatShift(calendarState.calendarForm.shiftStart_end)
      : null;
    const end_ini = calendarState.calendarForm.shiftEnd_ini
      ? formatShift(calendarState.calendarForm.shiftEnd_ini)
      : null;
    const end_end = calendarState.calendarForm.shiftEnd_end
      ? formatShift(calendarState.calendarForm.shiftEnd_end)
      : null;

    const is_default = !calendarsList.length;
    const working_days = calendarState.calendarForm.days
      .map((eld) => eld | 0)
      .join();

    if (currenTab !== 3) {
      /** step */
      /** validate init */
      if (!calendarState.calendarForm.name) {
        dispatch(
          calendarActions.setCalendarForm({
            ...calendarState.calendarForm,
            name: ''
          })
        );
        return false;
      }
      if (currenTab === 2) {
        /** validate all days false */
        const nothingDaySelect = calendarState.calendarForm.days.every(
          (el) => el === false
        );
        if (nothingDaySelect) {
          openNotification(alertCalendarNothingDay);
          return false;
        }

        const nothingWorkingDays = calendarState.calendarForm.workingDays;
        if (!nothingWorkingDays || !nothingWorkingDays.length) {
          openNotification(alertCalendarNothingWorkingDay);
          return false;
        }

        /** validate hours */
        let validate = true;
        calendarState.calendarForm.days.map((el, index) => {
          if (el) {
            calendarState.calendarForm.workingDays.map((shift, i) => {
              if (!shiftIsValid(shift, index)) {
                validate = false;
              }
            });
          }
        });
        if (!validate) {
          openNotification(alertCalendarAddError);
          return false;
        }
      }
      if (currenTab === 1) {
        trackingEvent(
          'set_calendar_name',
          {
            ...getBasicAmplitudEventProperties(),
            event_source: 'calendar_creation'
          },
          AMPLITUDE_SERVICE
        );
      }
      setCurrenTab(currenTab + 1);
    } else {
      /** validate shift of exceptions
            const someShiftNotDefined = calendarState.calendarForm.exceptions.some(el =>
                el.workable && isNaN(el.hourend)
            )
            if (someShiftNotDefined) {
                openNotification(alertCalendarExceptionError)
                return false
            } */

      setLoading(true);
      const data = {
        name: calendarState.calendarForm.name,
        unique_id: new Date().getTime(),
        is_default: is_default,
        working_days: working_days,
        shift_start: `${start_ini}-${start_end}` /** This is how every shift (working day) must look */,
        shift_end: `${end_ini}-${end_end}`,
        sectorId: projectState.sectorSelected,
        ganttId: currentGantt,
        userId: loggedUser.id
      };

      const calendarCreated = await createCalendar(data);
      if (calendarCreated.id) {
        /** update state calendar */
        const resp = await getCalendars();
        if (resp) {
          /** filter by sector */
          const calendarsActive = resp.calendar.filter(
            (e) => e.sectorId === projectState.sectorSelected
          );
          dispatch(calendarActions.setCalendarsbySector(calendarsActive));
        }

        const toAssignCalendarId = calendarCreated.id;
        const asyncMapWorkingDaySave =
          calendarState.calendarForm.workingDays.map(async (shift) => {
            const formattedIni = formatShift(shift.shift_ini);
            const formattedEnd = formatShift(shift.shift_end);
            const newWorkingDay = {
              shift_string: `${formattedIni}-${formattedEnd}`,
              correlative_id: shift.correlative_id,
              calendarId: toAssignCalendarId
            };

            await workingdayService.create(newWorkingDay);
          });

        await Promise.all(asyncMapWorkingDaySave);

        if (calendarState.calendarForm.exceptions) {
          await saveExceptions(calendarCreated);
        }
        openNotification(alertCalendarEditSuccess);
        setLoading(false);
        resetStateCalendar();
      }

      trackingEvent(
        'finish_calendar_creation',
        {
          ...getBasicAmplitudEventProperties()
        },
        AMPLITUDE_SERVICE
      );
    }
  };

  const saveExceptions = async (calendarSelected) => {
    const asyncExceptions = calendarState.calendarForm.exceptions.map(
      async (exc) => {
        const start_ini =
          exc.hourini === 'Start' ? null : formatShift(exc.hourini);
        const start_end =
          exc.hourend === 'End' ? null : formatShift(exc.hourend);
        const data = {
          name: exc.name,
          unique_id: new Date().getTime(),
          to_date: exc.to_date,
          from_date: exc.from_date,
          calendarId: calendarSelected.id,
          workable: exc.workable,
          iterable: exc.iterable,
          shift_start: `${start_ini}-${start_end}`
        };

        if (exc.iterable) {
          data.every = exc.every;
          data.shift_start = `${isNaN(exc.hourini) ? null : formatShift(exc.hourini)}-${isNaN(exc.hourend) ? null : formatShift(exc.hourend)}`;
        }

        const resException = await createExceptionDay(data);
        if (resException) {
          const asyncExceptionWorkingday = exc.workingDays.map(
            async (shift) => {
              const toAssignExceptionId = resException.id;
              const formattedIni = formatShift(shift.shift_ini);
              const formattedEnd = formatShift(shift.shift_end);
              const newWorkingDay = {
                shift_string: `${formattedIni}-${formattedEnd}`,
                correlative_id: shift.correlative_id,
                calendarexceptiondayId: toAssignExceptionId
              };

              await workingdayService.create(newWorkingDay);
            }
          );

          await Promise.all(asyncExceptionWorkingday);
        }
      }
    );

    await Promise.all(asyncExceptions);
  };

  const handleClose = () => {
    trackingEvent(
      'cancel_calendar_creation',
      {
        ...getBasicAmplitudEventProperties()
      },
      AMPLITUDE_SERVICE
    );
    setCurrenTab(1);
    setVisibleForm(false);
    resetStateCalendar();
    dispatch(calendarActions.setCalendarForm(null));
  };

  const handleBack = () => {
    setCurrenTab(currenTab - 1);
  };

  /** render */
  return (
    <Modal
      closable={false}
      className="frmCalendar"
      title=""
      visible={visibleForm}
      centered
      keyboard={false}
      maskClosable={false}
      onCancel={handleClose}
      width={1200}
      bodyStyle={{ height: '55vh', overflow: 'auto' }}
      footer={
        /** submit button in footer */
        <div className="footerBtns" key="btns">
          <Button
            loading={loading}
            onClick={handleContinue}
            className="btnSubmit">
            {currenTab != 3
              ? t('calendars_form.continue')
              : t('calendars_form.save')}
          </Button>
        </div>
      }>
      <div className="title-frm-add">
        <div
          onClick={handleBack}
          className={currenTab <= 1 ? 'hidden' : 'back-arrow'}>
          <LeftOutlined />
        </div>
        {t('calendars_form.create')}
        <div className="title-close" onClick={handleClose}>
          <CloseOutlined />
        </div>
      </div>

      <Row className="frm">
        <Col span={24} className="frmCol">
          <div className={currenTab === 1 ? null : 'hidden'}>
            <Input
              className="txt-name"
              type="text"
              name="cname"
              placeholder={t('calendars_form.name')}
              autoComplete="off"
              required
              value={
                calendarState.calendarForm && calendarState.calendarForm.name
              }
              onChange={(e) =>
                dispatch(
                  calendarActions.setCalendarForm({
                    ...calendarState.calendarForm,
                    name: e.target.value
                  })
                )
              }
            />
            {
              /** validate name */
              nameCalendar !== null && nameCalendar === '' ? (
                <label className="lblError">
                  {t('calendars_form.req_name')}
                </label>
              ) : null
            }
          </div>

          {/* Render Days Schedule */}
          {visibleForm ? (
            <div className={currenTab === 2 ? null : 'hidden'}>
              <FormDays
                currenTab={currenTab}
                setCurrenTab={setCurrenTab}
                currentCalendar={currentCalendar}
                setCurrentCalendar={setCurrentCalendar}
                add1Hour={add1Hour}
                t={t}
              />
            </div>
          ) : null}

          {/* Render Exceptions */}
          {visibleForm ? (
            <div className={currenTab === 3 ? null : 'hidden'}>
              <FormExceptionsDays
                currenTab={currenTab}
                setCurrenTab={setCurrenTab}
                currentCalendar={currentCalendar}
                setCurrentCalendar={setCurrentCalendar}
                add1Hour={add1Hour}
                t={t}
              />
            </div>
          ) : null}
        </Col>
      </Row>
    </Modal>
  );
}

export default withTranslation()(CalendarForm);
