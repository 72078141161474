import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './Accordion.module.scss';

export const Accordion = ({
  items = [],
  itemKeyProperty,
  renderItemHeader,
  renderItemContent,
  className,
  itemClassName
}) => {
  const [selectedItemKey, setSelectedItemKey] = useState();
  const handleItemHeaderClick = (key) => {
    setSelectedItemKey(key === selectedItemKey ? null : key);
  };

  useEffect(() => {
    setSelectedItemKey(
      itemKeyProperty && items?.length ? items[0]?.[itemKeyProperty] : 0
    );
  }, [items]);

  return (
    <div className={cn(styles['accordion'], className)}>
      {items.map((item, i) => {
        const key = itemKeyProperty ? item[itemKeyProperty] : i;
        const isExpanded = selectedItemKey === key;

        return (
          <div
            className={cn(
              styles['accordion__item'],
              {
                [styles['accordion__item--expanded']]: isExpanded
              },
              itemClassName
            )}
            key={key}>
            <div
              onClick={() => handleItemHeaderClick(key)}
              className={styles['accordion__item-header']}>
              {renderItemHeader(item, isExpanded)}
            </div>
            <div className={styles['accordion__item-content']}>
              {renderItemContent(item, isExpanded)}
            </div>
          </div>
        );
      })}
    </div>
  );
};
