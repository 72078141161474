import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import * as Icons from '../../../icons';
import EventEmitter from 'react-native-eventemitter';
import { VariableSizeList as List } from 'react-window';
import { Empty, Skeleton } from 'antd';
import { useDispatch } from 'react-redux';
import { timelineActions } from '../../../redux/actions/lookaheadTimelineActions';
import styles from './activityTree.module.scss';
import { treePaneService } from '../../../services/activityTreeSettings.service';
import { splitTextActivityTree } from '../../../utils/lookahead-common';
import CheckboxSystem from '../../DesignSystem/CheckboxSystem/CheckboxSystem';

const MAX_WIDTH = 250;
export const TestIds = {
  ACTIVITY_TREE_CONTAINER: 'ACTIVITY_TREE_CONTAINER'
};

const ActivityTree = (props) => {
  const {
    activitySuggestion = {},
    firstDateFilter = {},
    setFirstDateFilter = () => {},
    viewId = null,
    existTreePaneSettings = false,
    filterApplied = false,
    filterSettings = [],
    actitySettings = {},
    datesRange = {},
    activities = [],
    chageDateRange = () => {},
    setIsRender = (_) => {},
    setActivityTreePaneSettings = (_) => {},
    setChangeDateRange = (_) => {},
    t = (_) => {},
    isShowedFilters = false
  } = props;

  const activityContainer = useRef();
  const activityTree = useRef([]);
  const { current: maxSelection } = useRef(1000);
  const activitySelected = useRef([]);
  const dispatch = useDispatch();

  const [isCollapseAll, setIsCollapseAll] = useState(true);
  const [loadingTree, setLoadingTree] = useState(true);
  const [activityShown, setActivityShown] = useState([]);
  const [activitiesRendered, setActivitiesRendered] = useState([]);
  const [activitySelectedTotal, setActivitySelectedTotal] = useState([]);

  useEffect(() => {
    if (loadingTree) {
      return;
    }

    activitySelected.current = activitySelected.current
      .filter(
        (selection, idx) =>
          activitySelected.current.findIndex(
            (activity) => activity.id == selection.id
          ) == idx
      )
      .map((activitySelected) => activitySelected);
    setActivitySelectedTotal(
      activitySelected.current.filter((activity) => !activity.children.length)
        .length
    );
  }, [loadingTree]);

  useEffect(() => {
    activityTree.current = [];
    buildActivitiesTree(activityTree.current);
  }, [activitySuggestion]);

  useEffect(() => {
    if (!activities.length) {
      setLoadingTree(false);
      return;
    }

    buildActivitiesTree(activityTree.current);

    setLoadingTree(false);
  }, [activities]);

  useEffect(() => {
    activityTree.current = [];
    chageDateRange && setChangeDateRange(false);
    buildActivitiesTree(activityTree.current);
  }, [chageDateRange, firstDateFilter]);

  useEffect(() => {
    if (loadingTree || chageDateRange) {
      return;
    }

    activityTree.current = [];
    buildActivitiesTree(activityTree.current);
  }, [activitySelectedTotal, activityShown, filterApplied]);

  const buildActivitiesTree = (activitiesTree) => {
    activities.forEach((activity) =>
      recursiveActivityBuild(activity, activitiesTree)
    );
    setActivitiesRendered(activityTree.current);
    setIsRender(true);
    activitySelected.current = activitySelected.current.filter(
      (item, index, inputArray) => inputArray.indexOf(item) == index
    );
    setActivitySelectedTotal(
      activitySelected.current.filter((activity) => !activity.children.length)
        .length
    );
  };

  const recursiveCollapse = (activities) => {
    activities.forEach((activity) => {
      if (activity.children.length) {
        activity.hideChilds = !activity.hideChilds;

        activity.hideChilds &&
          setActivityShown([
            ...activityShown,
            {
              correlative_id: activity.correlative_id,
              hideChilds: activity.hideChilds
            }
          ]);

        !activity.hideChilds &&
          setActivityShown(
            activityShown.filter((selection) => selection.hideChilds)
          );
        activity.hideChilds = isCollapseAll;
        recursiveCollapse(activity.children);
      }
    });
  };

  const renderSingleRow = (activity, marginLeft) => {
    return (
      <div style={{ marginLeft }} className={styles.checkbox__activity}>
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
          <CheckboxSystem
            checked={activity.active}
            onChange={(_) => handleChangeSelection(activity)}
          />
          <span className={cn(styles['font-bold'], styles.correlative_id)}>
            {activity.correlative_id}
          </span>
          <span className={styles['task-pipe']}>|</span>
          <span
            className={cn(styles['font-activity'], {
              [styles['font-bold']]: activity.has_childs
            })}>
            {splitTextActivityTree(activity.name, {
              name: 'activityNameOnPanel',
              width: MAX_WIDTH,
              correlative_id: activity.correlative_id
            })}
          </span>
        </div>
        {activity.children.length > 0 && (
          <span
            onClick={(_) => handleToogleActivityChilds(activity)}
            className={cn(styles['cursor-pointer'], {
              [styles['rotate-icon']]: activity.hideChilds
            })}>
            <Icons.ChevronDownIcon color="#121212" />
          </span>
        )}
      </div>
    );
  };

  const recursiveActivityBuild = (activity, activityTree, marginLeft = 0) => {
    activity.hide && (activity.active = false);
    !activity.hide && activityTree.push(renderSingleRow(activity, marginLeft));

    if (loadingTree) {
      const selections =
        actitySettings?.activitieIds?.find((selection) => {
          if (isNaN(selection)) return selection.id == activity.id;
          else return selection == activity.id;
        }) || false;

      if (selections && !activity.hide) {
        activity.active = selections;
        activitySelected.current.push(activity);
      } else {
        activity.active = false;
      }
    }

    if (!loadingTree && activity.hide) {
      const idx = activitySelected.current.findIndex(
        (selection) => selection.id == activity.id
      );
      if (idx > -1) activitySelected.current.splice(idx, 1);
    }

    if (activity.children.length && !activity.hideChilds) {
      const sortActivities = (a, b) => a.correlative_id - b.correlative_id;
      activity.children
        .sort(sortActivities)
        .map((child) =>
          recursiveActivityBuild(child, activityTree, marginLeft + 15)
        );
    }
  };

  const createOrUpdateTreeSettings = async (settings) => {
    if (existTreePaneSettings) {
      await treePaneService.update(viewId, settings);
      return;
    }

    await treePaneService.create(settings);
  };

  const handleSubmitFilters = () => {
    dispatch(timelineActions.updateParsedValue(false));
    setFirstDateFilter(false);
    const settings = {
      activitieIds: activitySelected.current.map((activity) => activity.id),
      filters: {
        values: [
          ...filterSettings.map((setting) => ({
            name: setting.name,
            filter_by: setting.filter_by,
            type: setting.type
          }))
        ]
      },
      start: datesRange.start,
      end: datesRange.end,
      range: datesRange.weeks || 'free',
      viewId
    };

    viewId && createOrUpdateTreeSettings(settings);
    setActivityTreePaneSettings(settings);

    setTimeout(() => {
      EventEmitter.emit('HIDE_PLANIFICATION');
    }, 200);
  };

  const handleToogleActivityChilds = (activity = 'collaps All') => {
    if (activity == 'collaps All') {
      setIsCollapseAll(!isCollapseAll);
      return recursiveCollapse(activities);
    }

    activity.hideChilds = !activity.hideChilds;

    activity.hideChilds &&
      setActivityShown([
        ...activityShown,
        {
          correlative_id: activity.correlative_id,
          hideChilds: activity.hideChilds
        }
      ]);

    !activity.hideChilds &&
      setActivityShown(
        activityShown.filter((selection) => selection.hideChilds)
      );
  };

  const selection = (activity) => {
    activitySelected.current.find((selection) => selection.id == activity.id)
      ? activitySelected.current.splice(
          activitySelected.current.findIndex(
            (selextion) => selection.id == activity.id
          ),
          1
        )
      : activitySelected.current.push(activity);
  };

  const handleChangeSelection = (activity, isParent, parentStatus) => {
    if (!isParent) {
      isParent = activity.has_childs;
      parentStatus = !activity.active;
    }

    activity.active = isParent ? parentStatus : !activity.active;

    if (activity.active && !activity.hide) {
      selection(activity);
    }

    if (activity.active && !activity.hide) {
      activitySelected.current = [...activitySelected.current, activity];
    }

    if (!activity.active && !activity.hide) {
      activitySelected.current = activitySelected.current.filter(
        (selection) => selection.id != activity.id
      );
    }

    activity.children.forEach((child) =>
      handleChangeSelection(child, isParent, parentStatus)
    );

    setActivitySelectedTotal(
      activitySelected.current.filter((activity) => !activity.children.length)
        .length
    );
  };

  return (
    <div
      data-testid={TestIds.ACTIVITY_TREE_CONTAINER}
      className={styles.container}
      ref={activityContainer}>
      {loadingTree && (
        <Skeleton
          loading={loadingTree}
          active
          size="small"
          paragraph={{ rows: 8 }}
        />
      )}
      {!loadingTree && !activitiesRendered.length && (
        <Empty
          style={{ marginTop: 50 }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <span>{t('activity_tree_panel.no_find_activities')}</span>
          }
        />
      )}
      {!loadingTree && activitiesRendered.length ? (
        <>
          <div className={styles['activities-selected']}>
            <span
              className={cn(styles['showing-tasks'], {
                [styles['higger-task-selected']]:
                  activitySelectedTotal >= maxSelection ||
                  activitySelectedTotal == 0
              })}>
              {activitySelectedTotal == 0
                ? t('activity_tree_panel.selection_activity')
                : `${t('activity_tree_panel.showing')} ${activitySelectedTotal}/${maxSelection}`}
            </span>
            <span
              className={cn(styles.collapse, styles['cursor-pointer'])}
              onClick={(_) => handleToogleActivityChilds()}>
              <Icons.CollapseIcon
                className={cn(styles['collapse-icon'], {
                  [styles['expand-icon']]: !isCollapseAll
                })}
              />
              {isCollapseAll
                ? t('activity_tree_panel.collapse_all')
                : t('activity_tree_panel.expand_all')}
            </span>
          </div>
          <div
            className={cn(styles.tasks, {
              [styles['active-filters']]: isShowedFilters
            })}
            ref={activityContainer}>
            <Activities
              activities={activitiesRendered}
              height={activityContainer?.current?.offsetHeight || 400}
            />
          </div>
        </>
      ) : null}
      {!loadingTree && activitiesRendered.length ? (
        <button
          className={cn(styles.btn, {
            [styles['btn-disable']]:
              activitySelectedTotal >= maxSelection ||
              activitySelectedTotal == 0
          })}
          onClick={handleSubmitFilters}
          disabled={
            activitySelectedTotal >= maxSelection || activitySelectedTotal == 0
          }
          name={' Apply filter'}
          title={' Apply filter'}>
          Apply filter
        </button>
      ) : null}
    </div>
  );
};

const renderSingleActivity =
  (data) =>
  ({ index, style }) => (
    <div style={{ ...style, width: '100%' }}>{data[index]}</div>
  );

const Activities = ({ activities = [], height }) => {
  return (
    <List
      className="activity-tree-virtualized-list"
      height={height}
      itemCount={activities.length}
      itemSize={(_) => 30}>
      {renderSingleActivity(activities)}
    </List>
  );
};

export default ActivityTree;
