import { useEffect, useState } from 'react';
import PopoverSystem from '../../../PopoverSystem';

const PopoverDropdown = ({
  theme,
  showDropdown,
  content,
  childrenContent,
  setIsRunning,
  handleClosePopover
}) => {
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    if (handleClosePopover) {
      handleChangeVisiblePopover(false);
    }
  }, [handleClosePopover]);

  const handleChangeVisiblePopover = (visible) => {
    setShowPopover(visible);
    setIsRunning(!visible);
  };

  return PopoverSystem({
    visible: showPopover,
    onVisibleChange: handleChangeVisiblePopover,
    placement: 'right',
    theme: theme,
    zIndex: 1003,
    trigger: 'click',
    content: content,
    children: childrenContent
  });
};

export default PopoverDropdown;
