import React, { memo } from 'react';
import { UserCircleSingleIcon } from '../../../../../../icons';
import Colors from '../../../../../../assets/styles/variables.scss';

const Avatar = memo(({ data }) => {
  const { BLACK } = Colors;

  const getAvatarUser = ({ name, lastname, avatar }) => {
    if (avatar) {
      return (
        <img
          src={avatar}
          alt={`Avatar - ${name} ${lastname}`}
          className="avatar-image-column"
        />
      );
    }

    if (name && lastname) {
      return (
        <div className="avatar-name-column">
          {name.charAt(0)}
          {lastname.charAt(0)}
        </div>
      );
    }

    return (
      <div className="avatar-icon-column">
        <UserCircleSingleIcon color={BLACK} />
      </div>
    );
  };

  return <div className="avatar-column">{getAvatarUser(data)}</div>;
});

export default Avatar;
