import React from 'react';
import styled from 'styled-components';
import NavCategories from './NavCategories';
import BuildingHeight from './BuildingHeight';

function Gantt() {
  return (
    <StyleGantt>
      <div className="grid-categories-settings">
        <NavCategories />
        <BuildingHeight />
      </div>
    </StyleGantt>
  );
}

export default Gantt;

const StyleGantt = styled.div`
  .grid-categories-settings {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-gap: 20px;
  }
`;
