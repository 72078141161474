import React, { useEffect } from 'react';
import EventEmitter from 'react-native-eventemitter';
import General from './general';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getAnalitycsByProjectBack } from '../../views/weeklyPlan/weeklyPlan.helper';
import useAnalyticsPermissions from '../../hooks/useAnalyticsPermissions';

/** Build components from antd css framework  */
import { Spin, Icon } from 'antd';

export default function AnalyticsView(props) {
  /** consts */
  const permissions = useAnalyticsPermissions();
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  /** redux */
  const projectState = useSelector((state) => state.projectState);
  const projectSelected = projectState.allProjects.find(
    (e) => e.id == projectState.projectSelected
  );

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);
  useEffect(() => {
    window.Appcues.page();
  });

  /** Query */
  const analitycsRq = useQuery(['analitycsRqBack', projectSelected.id], () =>
    getAnalitycsByProjectBack(projectSelected.id)
  );

  /** render */
  return analitycsRq.data?.data ? (
    <div style={{ background: '#FFFFFF' }}>
      <General permissions={permissions} analitycsRq={analitycsRq} />
    </div>
  ) : (
    <div className="loader-spinner-analytics">
      <Spin indicator={antIcon} className="spinner-load" />
    </div>
  );
}
