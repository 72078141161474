/**
 * This functions correct a wrong behaviour at dhtmlx gantt lib, which allows to
 * transform an activity to milestone and also an milestone to be an activity
 * @param {*} gantt Instance where user is actually using the gantt lib (dhtmlx) which gives access to their API
 * @param {*} item An edited activity at rendered data in chart, which could be getted through any event of gantt dhtmlx component
 * @returns None return indeed, just an void method
 */
export const type_and_duration_feature_filter = (gantt, item) => {
  /**
   * VAR ITEM.ON_OPEN_LIGHTBOX_TYPE: Represents a flag for knowing if user did access to data by open
   * modal lightbox and not other sources as inline GRID editing or movement. Also  it works to know
   * if since the modal did open the type of the element has changed.
   *
   */

  const itemHasCalendar = item.calendar_id;
  const calendarId = itemHasCalendar ? item.calendar_id : gantt.defaultCalendar;
  const calendar = gantt.getCalendar(calendarId);

  function updateForDisableMilestoneDuration() {
    item.for_disable_milestone_duration = item.duration;
  }

  function updateDuration() {
    item.duration = item.for_disable_milestone_duration;
  }

  function updateEndDate() {
    item.end_date = calendar.calculateEndDate({
      start_date: item.start_date,
      duration: item.duration
    });
  }

  if (item.on_open_lightbox_type !== item.type && item.is_open_lightbox) {
    /** With the method API from Gantt DHTMLX, we can from a start date, and duration of work, get the
     * end date for the correct display of Gantt ProPlanner module.
     */
    updateEndDate();

    /**
     * ITEM.for_disable_milestone_duration: The original duration field is being manipulated
     * for Gantt library embedded.
     *
     * By this reason a copy of that original VAR from the TASK model object, is saved here. It will be
     * Showed at the GRID element.
     */
    updateForDisableMilestoneDuration();

    /**
     * If they are different, old one, must be updated. If it change again... who knows...
     */
    item.on_open_lightbox_type = item.type;

    /**
     * If for_disable_milestone_duration (copy of duration field to show in GRID table)
     * is 0, and it isnt a milestone, there is a error. WE CATCH IT
     *
     */
  } else if (
    item.for_disable_milestone_duration == 0 &&
    item.type !== 'milestone'
  ) {
    // send_warning_message(gantt)(`Changing item id ${item.id} to milestone...`)
    /**
     * The original duration field is updated with the var for_disable_milestone_duration
     * (which is just showed at GRID table in the duration field) to 0.
     */

    updateDuration();
    /**
     * The end date of this task is re caculated with calculateEndDate.
     */
    updateEndDate();

    /**
     * By hardcoding we assing correct value to type original field
     */
    item.type = 'milestone';

    /**
     * If for_disable_milestone_duration isnt 0, and it is with milestone field
     * it is not correct, we CATCH IT.
     */
  } else if (
    item.for_disable_milestone_duration != 0 &&
    item.type == 'milestone'
  ) {
    // send_warning_message(gantt)(`Rolling back item id ${item.id} to old type...`)
    if (!item.duration) return;
    /**
     * original duration field is updated with the label we use at GRID table
     */
    updateDuration();

    /**
     * We rollback to the last type saved to transform milestone (This use case will be
     * activated just when the user modify the duration with inline GRID table editor)
     */
    // item.type = item.old_type;
    /** BUG FIX 938 */
    item.type = 'task';

    /**
     * Re calculate of end date with the duration catched from the for_disable_milestone_duration
     */

    updateEndDate();
    /**
     * is_open_lightbox this property sets true when the modal lightbox is getting open (check the
     * event onBeforeLightbox from Gantt DHTMLX lib). This use case, catch when editing comes from in
     * line GRID table
     */
  } else if (!item.is_open_lightbox && item.is_inline) {
    item.is_inline = false;
    /**
     * Duration is updated to the val setted by the user using inline editor
     */
    updateDuration();

    /**
     * With the new duration, the end date is re calculated
     */
    if (item.constraint_type != 'fnlt' && item.constraint_type != 'fnet') {
      updateEndDate();
    }

    /**
     * is_open_lightbox will be true just when user tries to edit the task data by modal lightbox
     */
  } else if (item.is_open_lightbox) {
    updateForDisableMilestoneDuration();
  }

  /**
   * We turn to false this val, to use it with the same logic again and again...
   */
  item.is_open_lightbox = false;
};

/**
 * This function allows get recursively calculation of any
 * customn logic as Cost or HH custom implementation (check code at gantt-general-config file)
 * @param {*} gantt Instance where user is actually using the gantt lib (dhtmlx) which gives access to their API
 * @param {*} item An edited activity at rendered data in chart, which could be getted through any event of gantt dhtmlx component
 * @param {*} ponderatorsCalc Custom implementetion of ponderator calculation which works recursively from a deep of activity
 * @param {*} costCalc Custom implementation of cost, same as ponderator custom implementation, works recursively
 */

function activityHasParent(activity) {
  return activity.parent != 0;
}

export const check_progress = async (
  gantt,
  activity,
  ponderatorsCalc = null,
  costCalc = null
) => {
  if (activityHasParent(activity)) {
    try {
      const parentTask = gantt.getTask(activity.parent);
      const childs = gantt.getChildren(parentTask.id);
      let totProgress = 0;
      let tempTask;

      /**
       * With MAP function we iterate the CHILDS array, and calculate the final progress
       * by using an acumulator totProgress
       */
      childs.map((child) => {
        tempTask = gantt.getTask(child);
        if (tempTask.ponderator) {
          totProgress +=
            parseFloat(tempTask.progress) * (tempTask.ponderator / 100);
        }
      });

      /*
       * With toFixed function we cut the decimal value of the total progress to 2 decimals.
       * Then the parent task is updated
       */
      parentTask.progress = totProgress.toFixed(2);
      parentTask.for_disable_milestone_duration = parentTask.duration;

      if (costCalc) {
        costCalc(activity);
      }

      if (parentTask.parent != 0) {
        check_progress(gantt, parentTask, ponderatorsCalc, costCalc);
      }
    } catch (e) {
      console.log(e);
    }
  }
};

/**
 * This function allows to check progress recursively from a activity
 * @param {*} gantt Instance where user is actually using the gantt lib (dhtmlx) which gives access to their API
 * @returns None return just a void method
 */
export const editing_flow =
  (gantt) =>
  (id, item, ponderatorsCalc = null, costCalc = null) => {
    const aux_gantt = gantt;
    /**
     * This section code calculates the progress of the PROJECT parent task, by moving some progress of
     * it's childs.
     */
    if (
      item.parent !== 0 &&
      !aux_gantt.is_task_moved &&
      !aux_gantt.is_autoscheduling
    ) {
      try {
        check_progress(gantt, item, ponderatorsCalc, costCalc);
      } catch (e) {
        console.log(e);
      }
    }
  };
