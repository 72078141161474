/**
 * This function executes configuration for scrolling zoom at gantt chart
 * @param {*} gantt Instance where user is actually using the gantt lib (dhtmlx) which gives access to their API
 * @returns Return an object with levels, trigger, width config and others (check doc)
 */

export function init_scroll_zoom_config(gantt) {
  const navigatorLang = navigator.language || navigator.userLanguage;
  return {
    levels: [
      {
        name: 'Year-Year',
        min_column_width: 50,
        scales: [
          {
            unit: 'year',
            step: 3,
            format: function (date) {
              const anho = date.getYear() + 1900;
              return anho + ' - ' + (anho + 2);
            }
          },
          {
            unit: 'year',
            step: 1,
            format: '%Y'
          }
        ]
      },
      {
        name: 'Year-Quarter',
        min_column_width: 50,
        scales: [
          {
            unit: 'year',
            format: '%Y',
            step: 1
          },
          {
            unit: 'quarter',
            step: 1,
            format: function (date) {
              const quarter = Math.floor((date.getMonth() + 3) / 3);
              return 'Q' + quarter;
            }
          }
        ]
      },
      {
        name: 'Year-Month',
        min_column_width: 50,
        scales: [
          {
            unit: 'year',
            format: '%Y',
            step: 1
          },
          {
            unit: 'month',
            step: 1,
            css: function (date) {
              let defaultCalendar;
              if (gantt.defaultCalendar) {
                defaultCalendar = gantt.getCalendar(gantt.defaultCalendar);
              }
              if (defaultCalendar) {
                const copyOfDate = new Date(date);
                let isWorkable = false;
                while (true) {
                  // Funcion mal utilizada deberia ser {date, unit: 'day'}
                  if (
                    defaultCalendar.isWorkTime({
                      date: copyOfDate,
                      unit: 'day'
                    })
                  ) {
                    isWorkable = true;
                    break;
                  } else {
                    copyOfDate.setDate(copyOfDate.getDate() + 1);
                    if (copyOfDate.getDate() == 1) break;
                  }
                }

                if (!isWorkable) return "week-end personalized-weekend-class'";
              }
            },
            format: function (date) {
              const dateToStr = gantt.date.date_to_str('%M')(date);
              const monthEsp = {
                Jan: 'Ene',
                Feb: 'Feb',
                Mar: 'Mar',
                Apr: 'Abr',
                May: 'May',
                Jun: 'Jun',
                Jul: 'Jul',
                Aug: 'Ago',
                Sep: 'Set',
                Oct: 'Oct',
                Nov: 'Nov',
                Dec: 'Dic'
              };
              if (navigatorLang.includes('es')) return monthEsp[dateToStr];
              return dateToStr;
            }
          }
        ]
      },
      {
        name: 'Month-Week',
        min_column_width: 80,
        scales: [
          {
            unit: 'month',
            format: '%M %Y',
            step: 1
          },
          {
            unit: 'week',
            step: 1,
            css: function (date) {
              let defaultCalendar;
              if (gantt.defaultCalendar) {
                defaultCalendar = gantt.getCalendar(gantt.defaultCalendar);
              }
              if (defaultCalendar) {
                const copyOfDate = new Date(date);
                const endDate = gantt.date.add(date, 6, 'day');
                let isWorkable = false;
                while (true) {
                  // Funcion mal utilizada deberia ser {date, unit: 'day'}
                  if (
                    defaultCalendar.isWorkTime({
                      date: copyOfDate,
                      unit: 'day'
                    })
                  ) {
                    isWorkable = true;
                    break;
                  } else {
                    copyOfDate.setDate(copyOfDate.getDate() + 1);
                    if (copyOfDate > endDate) break;
                  }
                }

                if (!isWorkable) return "week-end personalized-weekend-class'";
              }
            },
            format: function (date) {
              const dateToStr = gantt.date.date_to_str('%d');
              const endDate = gantt.date.add(date, 6, 'day');
              const weekNum = gantt.date.date_to_str('%W')(date);
              return navigatorLang.includes('en')
                ? 'WK' +
                    (Number(weekNum) + 1) +
                    ', ' +
                    dateToStr(date) +
                    ' - ' +
                    dateToStr(endDate)
                : 'S' +
                    (Number(weekNum) + 1) +
                    ', ' +
                    dateToStr(date) +
                    ' - ' +
                    dateToStr(endDate);
            }
          }
        ]
      },
      {
        name: 'Month-Days',
        min_column_width: 30, // 25
        scales: [
          {
            unit: 'month',
            format: '%M %Y',
            step: 1
          },
          {
            unit: 'day',
            // format: '%D %d',
            step: 1,
            css: function (date) {
              let defaultCalendar;
              if (gantt.defaultCalendar) {
                defaultCalendar = gantt.getCalendar(gantt.defaultCalendar);
              }
              if (defaultCalendar) {
                // Funcion mal utilizada deberia ser {date, unit: 'day'}
                if (!defaultCalendar.isWorkTime({ date, unit: 'day' })) {
                  return "week-end personalized-weekend-class'";
                }
              }
            },
            format: function (date) {
              const dateToStr = gantt.date.date_to_str('%D %d')(date);
              const daysEsp = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
              if (navigatorLang.includes('es'))
                return daysEsp[date.getDay()] + String(dateToStr).split(' ')[1];
              return (
                String(dateToStr).split(' ')[0][0] +
                String(dateToStr).split(' ')[1]
              );
            }
          }
        ]
      }
      /*
            {
                name: 'Days-Hours-Minutes',
                min_column_width: 30,
                scales:
                [
                    {
                        unit: 'day',
                        format: '%d %M %Y',
                        step: 1
                    },
                    {
                        unit: 'hour',
                        format: '%HH',
                        step: 1,
                        css: function (date) {
                            let defaultCalendar
                            if (gantt.defaultCalendar) {
                                defaultCalendar = gantt.getCalendar(gantt.defaultCalendar)
                            }
                            if (defaultCalendar) {
                                // Funcion mal utilizada deberia ser {date, unit: 'hour'}
                                if (!defaultCalendar.isWorkTime({ date, unit: 'hour' })) {
                                    return "week-end personalized-weekend-class'";
                                }
                            }
                        }
                    }
                ]
            }
             {
                name: 'Days-Hours-Range',
                min_column_width: 80,
                scales:
                [
                    {
                        unit: 'day',
                        format: '%d %M',
                        step: 1
                    },
                    {
                        unit: 'hour',
                        format: hourRangeFormat(12),
                        step: 12
                    }
                ]
            },
            {
                name: 'Days-Hours-Minutes',
                min_column_width: 20,
                scales:
                [
                    {
                        unit: 'day',
                        format: '%d %M',
                        step: 1
                    },
                    {
                        unit: 'hour',
                        format: '%H',
                        step: 1,
                        css: function (date) {
                            if (!gantt.isWorkTime(date)) {
                                return "week-end personalized-weekend-class'";
                            }
                        }
                    }
                ]
            },
            {
                name: 'Days-Hours-Range',
                min_column_width: 43,
                scales:
                [
                    {
                        unit: 'day',
                        format: '%d %M',
                        step: 1
                    },
                    {
                        unit: 'hour',
                        format: hourRangeFormat(6),
                        step: 6,
                        css: function (date) {
                            if (!gantt.isWorkTime(date)) {
                                return "week-end personalized-weekend-class'";
                            }
                        }
                    }
                ]
            } */
    ],
    maxColumnWidth: 150,
    element: function () {
      return gantt.$root.querySelector('.gantt_task');
    }
  };
}
