/** React components  */
import React from 'react';

/** import elements from library Antd Framework */
import { Button, Alert } from 'antd';

export default function TokenNoValid(props) {
  return (
    <div className="divErrorDivSection">
      <a href="/">
        <div
          className="imgDegrade"
          style={{ width: 120, height: 120, backgroundSize: 120 }}>
          &nbsp;
        </div>
      </a>
      <Alert
        message="Invalid Token"
        type="error"
        style={{ width: '60%', margin: 'auto' }}
      />
      <Button onClick={() => props.history.push('/register')}>Register</Button>
    </div>
  );
}
