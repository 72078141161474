import React from 'react';
import { Card } from 'antd';
import styled from 'styled-components';

function Stage() {
  const CncTypes = [1, 2, 3, 4, 5, 6, 7];
  return (
    <div>
      <StyleTablesStages>
        <br />
        <h1 style={{ color: '#64B7EF', height: '23.66px' }}>Types of Stages</h1>
        <div className="flex-etapa-txt">
          <p style={{ color: '#7DFF8A' }}>Schedules of High-rise Building</p>
          <div className="box-etapa-stage"></div>
          <p>15</p>
          <div className="box-etapa-stage"></div>
          <p>3</p>
        </div>
        <div className="scroll-stage">
          {CncTypes.map((index) => (
            <Card
              bodyStyle={{ padding: '0px' }}
              className="ant-card-stages"
              key={index}>
              <div className="grid-cards-stages">
                <div>
                  <p
                    style={{
                      marginLeft: '20px',
                      marginTop: '10px',
                      fontSize: '13px'
                    }}>
                    Obra Gruesa
                  </p>
                </div>

                <div className="flex-productive-btn">
                  <button class="btn-productive-stage  btn-productive-stage-noactive">
                    Productive
                  </button>
                </div>
              </div>
            </Card>
          ))}
        </div>

        <br />
        <h1 style={{ color: '#64B7EF', height: '23.66px' }}>
          Types of Schedules
        </h1>
        <div className="flex-etapa-txt">
          <p style={{ color: '#7DFF8A' }}>Schedules of High-rise Building</p>
          <div className="box-etapa-stage"></div>
          <p>15</p>
          <div className="box-etapa-stage"></div>
          <p>3</p>
        </div>
        <div className="scroll-stage">
          {CncTypes.map((index) => (
            <Card
              bodyStyle={{ padding: '0px' }}
              className="ant-card-stages"
              key={index}>
              <div className="grid-cards-stages">
                <div>
                  <p
                    style={{
                      marginLeft: '20px',
                      marginTop: '10px',
                      fontSize: '13px'
                    }}>
                    Obra Gruesa
                  </p>
                </div>

                <div className="flex-productive-btn">
                  <button class="btn-productive-stage  btn-productive-stage-active">
                    Productive
                  </button>
                </div>
              </div>
            </Card>
          ))}
        </div>

        <br />
        <h1 style={{ color: '#64B7EF', height: '23.66px' }}>
          Types of Schedules
        </h1>
        <div className="flex-etapa-txt">
          <p style={{ color: '#7DFF8A' }}>Schedules of High-rise Building</p>
          <div className="box-etapa-stage"></div>
          <p>15</p>
          <div className="box-etapa-stage"></div>
          <p>3</p>
        </div>
        <div className="scroll-stage">
          {CncTypes.map((index) => (
            <Card
              bodyStyle={{ padding: '0px' }}
              className="ant-card-stages"
              key={index}>
              <div className="grid-cards-stages">
                <div>
                  <p
                    style={{
                      marginLeft: '20px',
                      marginTop: '10px',
                      fontSize: '13px'
                    }}>
                    Obra Gruesa
                  </p>
                </div>

                <div className="flex-productive-btn">
                  <button class="btn-productive-stage  btn-productive-stage-active">
                    Productive
                  </button>
                </div>
              </div>
            </Card>
          ))}
        </div>
        <br />
        <br />
        <br />
      </StyleTablesStages>
    </div>
  );
}

export default Stage;

const StyleTablesStages = styled.div`
  .flex-etapa-txt {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .box-etapa-stage {
    width: 16px;
    height: 16px;
    background: #a70a0a;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 2px;
  }

  /* STYLES TABLE */
  .grid-cards-stages {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .ant-card-stages {
    -webkit-box-shadow: 0px 1px 5px -2px rgba(0, 0, 0, 0.75) !important;
    -moz-box-shadow: 0px 1px 5px -2px rgba(0, 0, 0, 0.75) !important;
    box-shadow: 0px 1px 5px -2px rgba(0, 0, 0, 0.75) !important;
    border-radius: 10px !important;
  }
  .scroll-stage {
    overflow: scroll;
    height: 133px;
    width: 100%;
    overflow-x: hidden;
  }

  .scroll-stage::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .scroll-stage::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .scroll-stage::-webkit-scrollbar-thumb {
    background: #d6d0d0;
  }

  /* Handle on hover */
  .scroll-stage::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .flex-productive-btn {
    display: flex;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  /* BUTTON SIGNUP */
  .btn-productive-stage {
    display: inline-block;
    font-weight: 400;
    color: #ffffff;
    height: 100%;
    width: 200px;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 10px;
    line-height: 1.5;
    border: 0px solid #000000;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
    margin: 0px;
  }
  .btn-productive-stage-active {
    background-color: #2c3421;
  }
  .btn-productive-stage-noactive {
    background-color: #586666;
  }
`;
