/* eslint-disable prefer-const */
/** React stuff */
import { Button, Card, Modal } from 'antd';
import React, { useState, useEffect } from 'react';

/**  import library for animations effects */
import { Animated } from 'react-animated-css';

import { withTranslation } from 'react-i18next';

/** Redux */
import { useDispatch, useSelector } from 'react-redux';
import { constraintService } from '../../../../../services';
import SelectActivities from '../../ConstraintForm/SelectActivities';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const IndividualTaskSelection = (props) => {
  /** props */
  const {
    credentials,
    constraint,
    noTitle,
    newDocument,
    t,
    defaultTasks,
    updateRender
  } = props;

  const [documentList, setDocumentList] = useState([]);
  const [selectedTask, setSelectedTask] =
    useState(defaultTasks); /** handle select task */
  const [valSelect, setValSelect] = useState(null); /** handle select task */
  const [visibility, setVisibility] = useState(props.visibility);

  /** redux */

  useEffect(() => {
    setValSelect({ selected: defaultTasks.map((item) => `task-${item.id}`) });
  }, []);

  const update = async () => {
    const tasks = await constraintService.bulkAsignConstraintTask(
      constraint.id,
      {
        tasks: selectedTask.map((item) => parseInt(item.replace('task-', '')))
      }
    );

    if (tasks) {
      constraint.tasks = tasks.tasks;
      setVisibility(false);
      updateRender();
    }
  };

  /** render */
  return (
    <div>
      <p onClick={() => setVisibility(true)}>
        <a
          onClick={() => {
            setVisibility(true);
          }}>
          {props.children}
        </a>
      </p>
      <Modal
        title="Tasks"
        visible={visibility}
        onOk={() => {
          update();
        }}
        onCancel={() => {
          setVisibility(false);
        }}>
        <Card>
          {valSelect && (
            <SelectActivities
              noDispatch
              visibleForm={visibility}
              t={t}
              setSelectedTask={setSelectedTask}
              valSelect={valSelect}
              setValSelect={setValSelect}
            />
          )}
        </Card>
      </Modal>
    </div>
  );
};

export default IndividualTaskSelection;
