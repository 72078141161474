import { Modal } from 'antd';
import React, { useEffect } from 'react';
import WarningLowerEnv from '../components/WarningLowerEnv';

const environments = {
  localhost: 'Local',
  'v2.proplanner': 'Beta',
  'front-beta-v3.proplanner': 'Beta v3',
  'staging.proplanner.app': 'Staging',
  'demo.proplanner.app': 'Demo'
};

const isOnProjects = () => window.location.href.includes('projects');

export const useWarningForLowerEnvs = () => {
  useEffect(() => {
    const urlList = Object.keys(environments);
    const urlEnv = urlList.find((url) => window.location.href.includes(url));

    if (!urlEnv || !isOnProjects()) {
      return;
    }

    Modal.warning({ content: <WarningLowerEnv env={environments[urlEnv]} /> });
  }, []);
};
