/* eslint-disable quote-props */
import React from 'react';

import { splitText } from '../../../ActivitiesUnitsGrid/index.helper';
const monthString = {
  '01': {
    es: 'Enero',
    en: 'January'
  },
  '02': {
    es: 'Febrero',
    en: 'February'
  },
  '03': {
    es: 'Marzo',
    en: 'March'
  },
  '04': {
    es: 'Abril',
    en: 'April'
  },
  '05': {
    es: 'Mayo',
    en: 'May'
  },
  '06': {
    es: 'Junio',
    en: 'June'
  },
  '07': {
    es: 'Julio',
    en: 'July'
  },
  '08': {
    es: 'Agosto',
    en: 'August'
  },
  '09': {
    es: 'Septiembre',
    en: 'September'
  },
  10: {
    es: 'Octubre',
    en: 'October'
  },
  11: {
    es: 'Noviembre',
    en: 'November'
  },
  12: {
    es: 'Diciembre',
    en: 'December'
  }
};

/**
 * This component is the rendered element between Y (activity) and X (unit) which show the match of selected items
 * @param {*} days all activities in lineal tree structure
 * @param {*} width all units in lineal tree structure
 */
export const FixedDayHeaderColumn =
  (days, width, viewFontSize = 10) =>
  ({ columnIndex, rowIndex, style }) => {
    const currentDate = days[columnIndex];
    const color = currentDate.workable ? 'white' : '#8080804a';

    return (
      <div
        className={'grid-element-without-units-matrix'}
        style={{
          ...style,
          paddingTop: 0,
          fontSize: viewFontSize,
          backgroundColor: color
        }}>
        {splitText(currentDate.value.split('/')[2], width)}
      </div>
    );
  };

/**
 * This component is the rendered element between Y (activity) and X (unit) which show the match of selected items
 * @param {*} months all activities in lineal tree structure
 * @param {*} width all units in lineal tree structure
 */
export const FixedMonthHeaderColumn =
  (months, width, viewFontSize = 10) =>
  ({ columnIndex, rowIndex, style }) => {
    const currentDate = months[columnIndex];
    let string = monthString[currentDate.value];
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang.includes('es')) {
      string = string.es;
    } else {
      string = string.en;
    }

    return (
      <div
        className={'grid-element-without-units-matrix'}
        style={{ ...style, paddingTop: 0, fontSize: viewFontSize }}>
        {splitText(string, width)}
      </div>
    );
  };

/**
 * This component is the rendered element between Y (activity) and X (unit) which show the match of selected items
 * @param {*} years all activities in lineal tree structure
 * @param {*} width all units in lineal tree structure
 */
export const FixedYearHeaderColumn =
  (years, width, viewFontSize = 10) =>
  ({ columnIndex, rowIndex, style }) => {
    const currentDate = years[columnIndex];
    return (
      <div
        className={'grid-element-without-units-matrix'}
        style={{ ...style, paddingTop: 0, fontSize: viewFontSize }}>
        {splitText(currentDate.value, width)}
      </div>
    );
  };
