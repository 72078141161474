import React, { useState, useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getTodayWeekWithPdayAndCurrentDay } from '../../../../utils';
import { message } from 'antd';
import { formatMoney } from '../../../../utils/lookahead-common';

function CurveS(props) {
  const [Options, setOptions] = useState({});
  const { t } = props;
  const response = JSON.parse(JSON.stringify(props.dataCurve));
  /** Flag to know when component is on loading step */
  const [isLoading, setLoading] = useState(true);
  // dias de planificacion va de 0 a 6
  // 0 = domingo

  /** redux */
  const projectState = useSelector((state) => state.projectState);
  const projectSelected = projectState.allProjects.find(
    (e) => e.id == projectState.projectSelected
  );
  const planification_day = projectSelected.planification_day;
  const creter = projectSelected?.activity_creter;

  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    getData();
    if (!firstLoad) {
      message.success(t('filters_applied'), 4);
    }
  }, [props.dataCurve]);

  const getData = async () => {
    if (response && response.options) {
      const res = { ...response };
      const dates = res.options.xaxis.full_date;

      const filterDates = props.filterDates;
      let start = moment();
      let end = moment();
      const filtered_base = [];
      const filtered_follow = [];
      const filtered_real = [];
      const filtered_categories = [];
      if (filterDates.length != 0) {
        const start_data = getTodayWeekWithPdayAndCurrentDay(
          null,
          moment(filterDates[0]),
          planification_day
        );
        const end_data = getTodayWeekWithPdayAndCurrentDay(
          null,
          moment(filterDates[1]),
          planification_day
        );
        start = moment(start_data[2].end);
        end = moment(end_data[2].end);

        const start_string = start.format('YYYY-MM-DD');
        const end_string = end.format('YYYY-MM-DD');

        const index_start = dates.indexOf(start_string);
        const index_end = dates.indexOf(end_string);
        if (start_string != '' && end_string != '') {
          res.series[0].data.map((point, index) => {
            if (index >= index_start && index <= index_end) {
              filtered_base.push(point);
            }
          });
          res.series[1].data.map((point, index) => {
            if (index >= index_start && index <= index_end) {
              filtered_follow.push(point);
            }
          });
          res.series[2].data.map((point, index) => {
            if (index >= index_start && index <= index_end) {
              filtered_real.push(point);
            }
          });
          res.options.xaxis.categories.map((point, index) => {
            if (index >= index_start && index <= index_end) {
              filtered_categories.push(point);
            }
          });
          res.series[0].data = filtered_base;
          res.series[1].data = filtered_follow;
          res.series[2].data = filtered_real;
          res.options.xaxis.categories = filtered_categories;
        }
      }

      const totalBase = res.total_activity_quantity;
      const totalFollow = res.total_task_quantity;

      let state_base = true;
      const new_base = [];
      res.series[0].data.map((d) => {
        let boolD = 0;
        if (state_base) {
          new_base.push(d);
          boolD = d;
        }
        if (boolD >= 100) {
          state_base = false;
        }
      });

      const new_real = [];
      res.series[2].data.map((d) => {
        new_real.push(d);
      });
      if (t('lang') === 'en') res.series[2].name = 'actual';
      let state_follow = true;
      const new_follow = [];
      res.series[1].data.map((d) => {
        let boolD = 0;
        if (state_follow) {
          new_follow.push(d);
          boolD = d;
        }
        if (boolD >= 100) {
          state_follow = false;
        }
      });
      if (t('lang') === 'en') res.series[1].name = 'projected';
      res.series[0].data = new_base;
      res.series[2].data = new_real;
      res.series[1].data = new_follow;

      if (creter == 'duration') {
        if (res.series[1].data?.length > 0) {
          if (res.series[1].data[res.series[1].data.length - 1] >= 99.98) {
            res.series[1].data[res.series[1].data.length - 1] = 100;
          }
        }
        if (res.series[0].data?.length > 0) {
          if (res.series[0].data[res.series[0].data.length - 1] >= 99.98) {
            res.series[0].data[res.series[0].data.length - 1] = 100;
          }
        }
        if (res.series[2].data?.length > 0) {
          if (res.series[2].data[res.series[2].data.length - 1] >= 99.98) {
            res.series[2].data[res.series[2].data.length - 1] = 100;
          }
        }
      }

      res.options.dataLabels = {
        enabled: false
      };
      res.options.tooltip = {
        custom: function ({ series, dataPointIndex, w }) {
          let base, follow, real;
          if (typeof series[0][dataPointIndex] !== 'undefined') {
            base =
              t('lang') === 'en'
                ? parseFloat(series[0][dataPointIndex]).toFixed(2) + '%'
                : parseFloat(series[0][dataPointIndex])
                    .toFixed(2)
                    .replace('.', ',') + '%';
          }
          if (typeof series[1][dataPointIndex] !== 'undefined') {
            follow =
              t('lang') === 'en'
                ? parseFloat(series[1][dataPointIndex]).toFixed(2) + '%'
                : parseFloat(series[1][dataPointIndex])
                    .toFixed(2)
                    .replace('.', ',') + '%';
          }
          if (typeof series[2][dataPointIndex] !== 'undefined') {
            real =
              t('lang') === 'en'
                ? parseFloat(series[2][dataPointIndex]).toFixed(2) + '%'
                : parseFloat(series[2][dataPointIndex])
                    .toFixed(2)
                    .replace('.', ',') + '%';
          }
          return `<div>
                                <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${w.globals.categoryLabels[dataPointIndex]}</div>
                                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: ${typeof series[0][dataPointIndex] === 'undefined' ? 'none' : 'flex'};">
                                    <span class="apexcharts-tooltip-marker" style="background-color: rgb(48, 159, 233);"></span>
                                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                        <div class="apexcharts-tooltip-y-group">
                                            <span class="apexcharts-tooltip-text-y-label">base: </span>
                                            <span class="apexcharts-tooltip-text-y-value">${base}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 2; display: ${typeof series[1][dataPointIndex] === 'undefined' ? 'none' : 'flex'};">
                                    <span class="apexcharts-tooltip-marker" style="background-color: rgb(245, 157, 4);"></span>
                                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                        <div class="apexcharts-tooltip-y-group">
                                            <span class="apexcharts-tooltip-text-y-label">projected: </span>
                                            <span class="apexcharts-tooltip-text-y-value">${follow}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 3; display: ${typeof series[2][dataPointIndex] === 'undefined' ? 'none' : 'flex'};">
                                    <span class="apexcharts-tooltip-marker" style="background-color: rgb(52, 175, 0);"></span>
                                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                        <div class="apexcharts-tooltip-y-group">
                                            <span class="apexcharts-tooltip-text-y-label">real: </span>
                                            <span class="apexcharts-tooltip-text-y-value">${real}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>`;
        }
      };
      res.options.chart = { type: 'area', height: 500 };
      res.options.stroke = { show: true, width: 1.9 };
      res.options.colors = ['#2C3421', '#F59D04', '#34AF00'];
      res.options.markers = {
        size: 0,
        colors: undefined,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: 'circle',
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: undefined,
          sizeOffset: 3
        }
      };
      res.options.yaxis = {
        labels: {
          formatter: function (value) {
            return t('lang') === 'en'
              ? parseFloat(value).toFixed(2) + '%'
              : parseFloat(value).toFixed(2).replace('.', ',') + '%';
          }
        },
        max: 100
      };
      setOptions(res);
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const renderChart = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Chart
        options={Options.options}
        series={Options.series}
        type="area"
        width="100%"
        height="100%"
      />
    );
  };
  return <div style={{ height: '100%' }}>{renderChart()}</div>;
}

export default CurveS;
