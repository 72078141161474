/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class SectorBaseLineVersionService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(sectorBaselineVersionId) {
    const res = await this.http
      .get('sectorbaselineversions/' + sectorBaselineVersionId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showBySectorActive(sectorId) {
    const res = await this.http
      .get('sectorbaselineversions/sector/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(sectorBaselineVersionId) {
    const res = await this.http
      .delete('sectorbaselineversions/' + sectorBaselineVersionId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(sectorBaselineVersion) {
    const res = await this.http
      .post('sectorbaselineversions', sectorBaselineVersion)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(sectorBaselineVersion) {
    const res = await this.http
      .put(
        'sectorbaselineversions/' + sectorBaselineVersion.id,
        sectorBaselineVersion
      )
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showProgressReal(sector_id, star, end) {
    const res = await this.http
      .get('sectorbaselineversions/showprogress/' + sector_id)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}
export const sectorBaselineVersionService = new SectorBaseLineVersionService();
