/* eslint-disable quote-props */
import moment from 'moment';
import { createHttpClient } from '../utils/httpUtils';
class ActivityModificationService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(activityModificationId) {
    const res = await this.http
      .get('activityModifications/' + activityModificationId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(activityModificationId) {
    const res = await this.http
      .delete('activityModifications/' + activityModificationId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const copy = { ...data };
    copy.startOriginal = moment(data.startOriginal).format('YYYY/MM/DD H:mm');
    copy.endOriginal = moment(data.endOriginal).format('YYYY/MM/DD H:mm');
    copy.startNew = moment(data.startNew).format('YYYY/MM/DD H:mm');
    copy.endNew = moment(data.endNew).format('YYYY/MM/DD H:mm');
    const res = await this.http
      .post('activityModifications', copy)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(data) {
    const copy = { ...data };
    copy.startOriginal = moment(data.startOriginal).format('YYYY/MM/DD H:mm');
    copy.endOriginal = moment(data.endOriginal).format('YYYY/MM/DD H:mm');
    copy.startNew = moment(data.startNew).format('YYYY/MM/DD H:mm');
    copy.endNew = moment(data.endNew).format('YYYY/MM/DD H:mm');
    const res = await this.http
      .put('activityModifications/' + data.id, copy)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const activityModificationService = new ActivityModificationService();
