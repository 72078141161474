import moment from 'moment';
import { ganttAPI } from '../../../../utils/customGanttPlugin';

function modifyLagCustom(task) {
  const gantt = window.to_use_react_gantt;
  let new_lag = 0;

  let link;
  let source_task;
  task.$target.forEach((l) => {
    link = gantt.getLink(l);
    if (!gantt.isTaskExists(link.source)) return;
    source_task = gantt.getTask(link.source);
    // *************************************
    // validar el tipo de link
    let l_source;
    let l_target;
    switch (link.type) {
      case '0':
        l_source = moment(source_task.end_date).format();
        l_target = moment(task.start_date).format();
        break;
      case '1':
        l_source = moment(source_task.start_date).format();
        l_target = moment(task.start_date).format();
        break;
      case '2':
        l_source = moment(source_task.end_date).format();
        l_target = moment(task.end_date).format();
        break;
      case '3':
        l_source = moment(source_task.start_date).format();
        l_target = moment(task.end_date).format();
        break;
      default:
        l_source = moment(source_task.end_date).format();
        l_target = moment(task.start_date).format();
        break;
    }
    // fin validar tipo de link

    const durationWithGanttApi = ganttAPI.calculateDuration(
      l_source,
      l_target,
      task.calendar_id,
      gantt
    );

    new_lag = durationWithGanttApi; // calculateDuration(l_source, l_target, gantt_cal, task.calendar_id);
    if (source_task.constraint_type == 'alap') new_lag = 0;
    link.lag = new_lag;
    gantt.updateLink(link.id);
  });
}

export { modifyLagCustom };
