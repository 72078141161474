import React, { useState, useEffect } from 'react';
import { Animated } from 'react-animated-css';
import { Row, Col } from 'antd';
import { Spin, Icon } from 'antd';
import { useSelector } from 'react-redux';

import useWindowDimensions from '../../../hooks/useWindowDimensions';

import ActivitiesCheckbox from '../../../components/ActivitiesCheckbox';
import UnitsCheckbox from '../../../components/UnitsCheckbox';
import ActivitiesUnitsGrid from '../../../components/ActivitiesUnitsGrid';

import './index.css';
import { Toast } from './index.helper';
import { withTranslation } from 'react-i18next';
import { structureService } from '../../../services/structure.service';
import { calendarService, sectorService } from '../../../services';

import Loader from '../../../components/Loadign';

import { filterLastLvlActivities, filterUnits } from '../index.helper';
import { getDynamicAttributesToCreateTask } from '../../../utils';
import {
  getEndDateByGantt,
  transformDateWithHour
} from '../../../utils/lookahead-common';
import { ganttAPI } from '../../../utils/customGanttPlugin';
import { productionunitService } from '../../../services/productionunit.service';

import moment from 'moment';
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

function AssignView(props) {
  const { height } = useWindowDimensions();
  const [loaded, setLoaded] = useState(false);
  const [assignUnassignLoaded, setAssignUnassignLoaded] = useState(false);
  const [activities, setActivities] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [selectedActivitiesToDelete, setSelectedActivitiesToDelete] = useState(
    []
  );
  const [selectedUnitsToDelete, setSelectedUnitsToDelete] = useState([]);
  const [units, setUnits] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const { t } = props;
  const projectState = useSelector((state) => state.projectState);
  const stateCompany = useSelector((state) => state.companyState);
  const [calendars, setCalendars] = useState([]);
  const [creatingTakt, setCreatingTakt] = useState(false);
  const [deletingTakt, setDeletingTakt] = useState(false);
  const [relations, setRelations] = useState([]);
  const [activitiesFlush, setActivitiesFlush] = useState(null);
  const [unitsFlush, setUnitsFlush] = useState(null);

  const [assignUnassignAction, setAssignUnassignAction] = useState({
    disable: true,
    action: '',
    label: t('takt_assign.assign')
  });
  const [visibilityState, setVisibilityState] = useState({
    structure: true,
    location: true,
    unit: false
  });

  useEffect(() => {
    updateStates();
    Toast.init();
  }, [projectState.sectorSelected]);

  useEffect(() => {
    window.Appcues.page();
  });

  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;

    if (!assignUnassignAction.disable) {
      if (
        assignUnassignAction.action !== '' &&
        assignUnassignAction.action === 'assign'
      ) {
        createAssignTaktRelation(false) // prevent make the request
          .then(({ units, activities }) =>
            Toast.show(
              userLang.includes('en')
                ? `${t('takt_assign.tooltipAssigned.first')} <span class="count-tasks">${units.length}</span> ${t('takt_assign.tooltipAssigned.second')}<br> <span class="count-tasks">${activities.length}</span> ${t('takt_assign.tooltipAssigned.third')} ${t('takt_assign.tooltipAssigned.fourth')}`
                : `${t('takt_assign.tooltipAssigned.first')} <span class="count-tasks">${units.length}</span> ${t('takt_assign.tooltipAssigned.second')}<br> ${t('takt_assign.tooltipAssigned.third')} <span class="count-tasks">${activities.length}</span> ${t('takt_assign.tooltipAssigned.fourth')}`,
              'assigned',
              2000
            )
          );
      } else {
        const { unitSelected, activitiesToUnassign } =
          removeAssignTaktRelation();
        Toast.show(
          userLang.includes('en')
            ? `${t('takt_assign.tooltipUnassigned.first')} <span class="count-tasks">${unitSelected.length}</span> ${t('takt_assign.tooltipUnassigned.second')}<br><span class="count-tasks">${activitiesToUnassign.length}</span> ${t('takt_assign.tooltipUnassigned.third')} ${t('takt_assign.tooltipUnassigned.fourth')}`
            : `${t('takt_assign.tooltipUnassigned.first')} <span class="count-tasks">${unitSelected.length}</span> ${t('takt_assign.tooltipUnassigned.second')}<br>${t('takt_assign.tooltipUnassigned.third')} <span class="count-tasks">${activitiesToUnassign.length}</span> ${t('takt_assign.tooltipUnassigned.fourth')}`,
          'unassigned',
          2000
        );
      }
    }
  }, [assignUnassignAction]);

  const getActivities = async () => {
    const sectorResActivity = await sectorService.createActivitiesTree(
      projectState.sectorSelected
    );
    return sectorResActivity.sector;
  };

  const getStructures = async () => {
    const currentSector = projectState.allSectors.find(
      (e) => e.id == projectState.sectorSelected
    );
    const structures = await structureService.showBySector(currentSector.id);
    return structures.structure;
  };

  const getRelations = async (ac, relations) => {
    if (ac.productionUnits.length) {
      ac.productionUnits.map((pu) => {
        const productionunitactivity = pu.productionunitactivity;
        const doesExist = relations.find(
          (rel) => rel.activityId == ac.id && rel.productionunitId == pu.id
        );
        if (!doesExist) {
          relations.push(productionunitactivity);
        }
      });
    }
    if (ac.children.length) {
      ac.children.map((child) => {
        getRelations(child, relations);
      });
    }
  };

  /**
   * This function goes deeply on a tree data structure and transforms it to an lineal array in the same structure
   * @param {*} tree Array with tree format
   * @param {*} finalJsxArray empty array to push deeply data
   * @param {*} lvl 0 on start to deeply get level for elements
   */
  const treeToLinealArray = (
    tree,
    finalJsxArray,
    lvl,
    setDefaultHideChilds = false
  ) => {
    tree.map((element) => {
      const hasChilds = element.children;
      element.lvl = lvl;
      if (setDefaultHideChilds && lvl > 2) {
        element.hideChilds = true;
      }
      finalJsxArray.push(element);
      if (hasChilds) {
        if (hasChilds.length) {
          treeToLinealArray(
            hasChilds,
            finalJsxArray,
            lvl + 1,
            setDefaultHideChilds
          );
        }
      }
    });
  };

  /**
   * This function gets units (all structure with locations) and transforms to only locatiosn with production unit
   * @param {*} units All locations with units array
   */
  const getLocationsWithUnits = (units) => {
    const linealArray = [];
    treeToLinealArray(units, linealArray, 0);
    const locationsWithUnits = linealArray.filter((location) => {
      if (!location.productionUnits) {
        return false;
      } else if (location.productionUnits.length) {
        return true;
      }
    });
    return locationsWithUnits;
    // setLocationArray(locationsWithUnits)
  };

  /**
   * This function updates the states and performs various operations based on the provided parameters.
   * @param {boolean} [flushData=false] - Determines whether to flush the data or not.
   * @returns {Promise<void>} - A Promise that resolves once the states are updated.
   */
  const updateStates = async (flushData = false) => {
    const sectorInfo = (await getActivities()) ?? [];
    const structures = await getStructures();

    const relations = [];
    const alreadySelectedActivities = [];
    let oldLinealActivitiesArray;
    let oldLinealUnitsArray;
    if (flushData) {
      const oldActivities = JSON.parse(JSON.stringify(activities));
      oldLinealActivitiesArray = [];
      treeToLinealArray(oldActivities, oldLinealActivitiesArray, 0);

      const oldUnits = JSON.parse(JSON.stringify(units));
      oldLinealUnitsArray = [];
      treeToLinealArray(oldUnits, oldLinealUnitsArray, 0);
    }

    const linealActivitiesArray = [];

    if (sectorInfo.length) {
      sectorInfo.forEach((activity) =>
        activity.children.forEach((a) => getRelations(a, relations))
      );

      setRelations(relations);
      treeToLinealArray(sectorInfo, linealActivitiesArray, 0, true);

      relations.map((rel) => {
        const activity = linealActivitiesArray.find(
          (sectorInfo) => sectorInfo.id === rel.activityId
        );
        const doesAlreadyAdded = alreadySelectedActivities.find(
          (a) => a.id === activity.id
        );
        if (!doesAlreadyAdded) {
          activity.disable = true;
          alreadySelectedActivities.push(activity);
        }
      });
    }

    const alreadySelectedUnits = [];
    setSelectedActivities(alreadySelectedActivities);
    setSelectedActivitiesToDelete(alreadySelectedActivities);

    if (structures) {
      const structuresFormated = structures.map((structure) => {
        structure.children = JSON.parse(JSON.stringify(structure.locations));
        structure.isStructure = true;
        delete structure.locations;
        return structure;
      });

      if (structuresFormated) {
        relations.map((rel) => {
          const locationArray = getLocationsWithUnits(structuresFormated);
          let unit;
          for (let i = 0; i < locationArray.length; i++) {
            const doesExist = locationArray[i].productionUnits.find(
              (pu) => pu.id == rel.productionunitId
            );
            if (doesExist) {
              unit = doesExist;
              break;
            }
          }

          if (unit) {
            const doesAlreadyAdded = alreadySelectedUnits.find(
              (a) => a.id == unit.id
            );
            if (!doesAlreadyAdded) {
              unit.disable = true;
              alreadySelectedUnits.push(unit);
            }
          }
        });
      }

      setSelectedUnits(alreadySelectedUnits);
      setSelectedUnitsToDelete(alreadySelectedUnits);

      if (flushData) {
        setActivitiesFlush(oldLinealActivitiesArray);
        setUnitsFlush(oldLinealUnitsArray);
      }

      setActivities(sectorInfo);

      setUnits(structuresFormated.length ? structuresFormated : []);
    }
    setLoaded(true);
    setAssignUnassignLoaded(false);
  };

  /**
   * This function acts when a change is made at checkbox
   * @param {*} selectedItem Selected item from activities state
   * @param {*} add true or false if an element will be added or deleted
   */
  const onActivitiesCheckboxChange = (activitiesSelected) => {
    setSelectedActivities([...activitiesSelected]);
  };

  const onActivitiesToDeleteCheckboxChange = (activitiesSelected) => {
    setSelectedActivitiesToDelete([...activitiesSelected]);
  };

  /**
   * This function acts when a change is made at checkbox
   * @param {*} selectedItem Selected item from activities state
   * @param {*} add true or false if an element will be added or deleted
   */
  const onUnitsCheckboxChange = (unitsSelected) => {
    setSelectedUnits([...unitsSelected]);
  };

  const onUnitsToDeleteCheckboxChange = (unitsSelected) => {
    setSelectedUnitsToDelete([...unitsSelected]);
  };

  /**
   * This function creates a new task
   * @param {*} parentId ID from the Task that has a lvl up as a parent
   * @param {*} name Name to show to the new task
   * @param {*} activity Activity superior parent object
   */
  const createTask = async (
    name,
    activity,
    dur = null,
    start = null,
    totalElements
  ) => {
    const currentCompany = stateCompany.currentCompany;

    const toAddDynamicAttributes =
      (await getDynamicAttributesToCreateTask()) || {};
    let acumOldPonderator = 0;
    activity.tasks.map((task) => {
      acumOldPonderator += task.ponderator;
    });
    const ponderator = (100 - acumOldPonderator) / totalElements;

    let inheritStartDate = activity.start_date;
    inheritStartDate = transformDateWithHour(inheritStartDate);
    let toPush = {
      name: name,
      start_date: start || inheritStartDate,
      duration: dur || 1,
      priority: 'Low',
      progress: 0,
      responsables: [],
      ponderator: ponderator,
      activityId: activity.id,
      isEditing: true,
      isNew: true,
      parent_id: null,
      cost: 0,
      hhWorkTime: 0,
      status: 'Waiting',
      lean_status: 'Debit',
      children: [],
      constraints: [],
      hasCustomPonderator: false,
      active: false
    };

    toPush = {
      ...toPush,
      ...toAddDynamicAttributes
    };

    getEndDateByGantt(toPush, activity);
    return toPush;
  };

  useEffect(() => {
    getCalendars();
  }, [projectState.sectorSelected]);

  const getCalendars = async () => {
    const calendars = await calendarService.showBySector(
      projectState.sectorSelected
    );
    setCalendars(calendars.calendar);
  };

  useEffect(() => {
    if (calendars.length) {
      loadCalendars(calendars);
    }
  }, [calendars]);

  const loadCalendars = (calendars) => {
    /** This method load calendars to Gantt API and also creates a custom version to use custom calculate duration */
    ganttAPI.loadCalendars(calendars);
  };

  const createAssignTaktRelation = async (isCreateActivity = true) => {
    const activityExist = [];
    let lastLevelActivities = filterLastLvlActivities(selectedActivities);

    lastLevelActivities = lastLevelActivities.filter((a) => !a.disable);

    const existActivityInUnit = (activityExists) => (unit) =>
      unit.activities.forEach((activity) => {
        const findActivity = lastLevelActivities.includes(activity);
        findActivity && activityExists.push(findActivity);
      });

    const orderdByCorrelativeID = filterUnits(selectedUnits)
      .filter((unit) => unit.active)
      .sort((a, b) => a.correlative_id - b.correlative_id);
    let unitsOfProduction = filterUnits(orderdByCorrelativeID)
      .filter((unit) => unit.active)
      .sort((a, b) => a.id - b.id);

    unitsOfProduction.forEach(existActivityInUnit(activityExist));
    unitsOfProduction = !activityExist.length ? unitsOfProduction : [];
    const tasks = [];
    for (let i = 0; i < lastLevelActivities.length; i++) {
      const activity = lastLevelActivities[i];
      const activityDuration = activity.duration;
      if (unitsOfProduction.length) {
        const durationPerTask = activityDuration / unitsOfProduction.length;
        for (let j = 0; j < unitsOfProduction.length; j++) {
          let durationToCalculateStart =
            (j * activityDuration) / unitsOfProduction.length;
          if (durationToCalculateStart % 1 == 0) {
            durationToCalculateStart += 1;
          }
          try {
            durationToCalculateStart = Math.ceil(durationToCalculateStart);
          } catch {
            durationToCalculateStart = 1;
          }
          const calculatedStartDate = ganttAPI.getEndByDuration(
            moment(activity.start_date).format('YYYY/MM/DD'),
            durationToCalculateStart,
            undefined,
            activity.calendarId
          ).end_date;
          const formatedCalculatedStartDate =
            moment(calculatedStartDate).format('YYYY/MM/DD');
          const unit = unitsOfProduction[j];
          const newTask = isCreateActivity
            ? await createTask(
                unit.name,
                activity,
                Math.ceil(durationPerTask),
                formatedCalculatedStartDate,
                unitsOfProduction.length
              )
            : { end_date: Date.now() };
          newTask.coordinates = { a: i, u: j };
          tasks.push(newTask);
        }
      }
    }
    tasks.map((t) => {
      t.start_date = moment(t.start_date).format('YYYY/MM/DD H:mm');
      t.end_date = moment(t.end_date).format('YYYY/MM/DD H:mm');
    });
    return { tasks, activities: lastLevelActivities, units: unitsOfProduction };
  };

  const removeAssignTaktRelation = () => {
    const activities = filterLastLvlActivities(selectedActivitiesToDelete);
    const activitiesToUnassign = activities.filter((a) => a.active);
    const units = filterUnits(selectedUnitsToDelete);
    const unitSelected = units.filter((unit) => unit.active);
    const relationsToDelete = [];

    const removeRelations = (activity) => (acc, unit) => {
      acc.push({ activityId: activity.id, productionunitId: unit.id });
      return acc;
    };

    activitiesToUnassign.forEach((activity) => {
      unitSelected.reduce(removeRelations(activity), relationsToDelete);
    });

    return { relationsToDelete, unitSelected, activitiesToUnassign };
  };

  const executeAssignTakt = async () => {
    setCreatingTakt(true);
    setAssignUnassignLoaded(true);

    const { activities, units, tasks } = await createAssignTaktRelation();

    const bodyRequest = {
      selectedActivities: activities,
      selectedUnits: units,
      tasksToCreate: tasks
    };

    const res = await productionunitService.assignTaktPlanning(bodyRequest);

    if (res) {
      updateStates(true);
      setCreatingTakt(false);
      await productionunitService.updateBases({
        sectorId: projectState.sectorSelected
      });
    }
  };

  const updateVisualization = (visibilityState) =>
    setVisibilityState({ ...visibilityState });

  const unAssignTakt = async () => {
    setAssignUnassignLoaded(true);
    setDeletingTakt(true);
    const { relationsToDelete } = removeAssignTaktRelation();
    const res = await productionunitService.unAssignTakt({ relationsToDelete });
    if (res) {
      updateStates(true);
      setDeletingTakt(false);
    }
  };

  const renderLoadedContent = () => (
    <>
      <Row>
        <Col>
          <Row style={{ padding: 17 }}>
            <span className="assign-subtitle">{t('takt_assign.subtitle')}</span>
            <br />
            {activities.length === 0 && (
              <span className="assign-subtitle-optional">
                {t('takt_assign.without_baseline_alert')}
              </span>
            )}
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col span={6}>
              <ActivitiesCheckbox
                activitiesFlush={activitiesFlush}
                relations={relations}
                activities={activities}
                selectedActivities={selectedActivities}
                selectedActivitiesToDelete={selectedActivitiesToDelete}
                selectedUnits={selectedUnits}
                onSelectedtoDeleteChange={onActivitiesToDeleteCheckboxChange}
                t={t}
                onSelectedChange={onActivitiesCheckboxChange}
              />
            </Col>
            <Col span={6}>
              <UnitsCheckbox
                unitsFlush={unitsFlush}
                relations={relations}
                units={units}
                selectedActivities={selectedActivities}
                selectedUnits={selectedUnits}
                selectedUnitsToDelete={selectedUnitsToDelete}
                onSelectedChange={onUnitsCheckboxChange}
                t={t}
                onSelectedtoDeleteChange={onUnitsToDeleteCheckboxChange}
              />
            </Col>
            <Col span={12}>
              <ActivitiesUnitsGrid
                assignUnassignAction={assignUnassignAction}
                setAssignUnassignAction={setAssignUnassignAction}
                visibilityState={visibilityState}
                updateVisualization={updateVisualization}
                creatingTakt={creatingTakt}
                deletingTakt={deletingTakt}
                unAssignTakt={unAssignTakt}
                executeAssignTakt={executeAssignTakt}
                activities={activities}
                units={units}
                selectedActivities={selectedActivities}
                t={t}
                selectedUnits={selectedUnits}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {assignUnassignLoaded && <Loader />}
    </>
  );

  return (
    <Animated
      animationIn="fadeIn"
      animationInDuration={500}
      isVisible={true}
      style={{ height: height - 130 }}>
      {loaded ? (
        renderLoadedContent()
      ) : (
        <Spin className="loader-spinner-lookahead-header" indicator={antIcon} />
      )}
    </Animated>
  );
}

export default withTranslation()(AssignView);
