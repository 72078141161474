import React, { useEffect, useRef, useState } from 'react';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';
import { DatePicker } from 'antd';
import AutoComplete from '../../AutoComplete';
import MoreFilters from './MoreFilters';
import styles from './filters.module.scss';
import { LOOKAHEAD_RANGE } from './constants';
import { useDispatch } from 'react-redux';

import { timelineActions } from '../../../redux/actions/lookaheadTimelineActions';
import { ToolbarGroup } from '../../ToolbarGroup';
import moment from 'moment';
import { isFeatureOn } from '../../../utils/featureUtils';

const { RangePicker } = DatePicker;

export const TestIds = {
  FILTERS_PANE_CONTAINER: 'FILTERS_PANE_CONTAINER'
};
const Filters = (props) => {
  const {
    chageDateRange = () => {},
    filterApplied = false,
    firstDateFilter = false,
    isTreeRender = false,
    activities = [],
    filterSettings = [],
    actitySettings = {
      range: null,
      start: null,
      end: null
    },
    datesRange = {},
    setFirstDateFilter = () => {},
    setDateRange = (_) => {},
    setFilterApplied = (_) => {},
    setChangeDateRange = (_) => {},
    setActivitySuggestion = (_) => {},
    t = (_) => {},
    onClickMoreFilters = (_) => {}
  } = props;

  //const { current: items } = useRef(LOOKAHEAD_RANGE(t))

  const [addFilter, setAddFilter] = useState(false);
  const [commandRange, setCommand] = useState({
    command: '4_WEEKS',
    clicked: false
  });
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [linearActivities, setLinearActivities] = useState([]);
  const [items, setItems] = useState(LOOKAHEAD_RANGE(t));

  const dispatch = useDispatch();

  useEffect(() => {
    actitySettings?.range &&
      setCommand({
        command: actitySettings.range
          .replace(' ', '_')
          .toUpperCase()
          .includes('FREE')
          ? `${actitySettings.range.replace(' ', '_').toUpperCase()}_RANGE`
          : `${actitySettings.range.replace(' ', '_').toUpperCase()}_WEEKS`,
        clicked: false
      });
  }, []);

  useEffect(() => {
    if (!actitySettings) {
      presetDateChange(4, true);
      return;
    }

    const range = actitySettings?.range?.split(' ')[0].toUpperCase() || 'FREE';

    if (range.includes('CLEAR')) {
      return;
    }

    range == 'FREE'
      ? middleDatesChange(
          null,
          [actitySettings.start, actitySettings.end],
          true
        )
      : presetDateChange(parseInt(range), true);
  }, [activities, chageDateRange]);

  useEffect(() => {
    setLinearActivities(handleLinearActivity());
  }, [activities, isTreeRender]);

  useEffect(() => {
    setShowDatePicker(
      commandRange.command === 'FREE_RANGE' && commandRange.clicked
    );

    let currentItems = [...items];
    let currentSubItems = [...currentItems[0].subitemsSettings];
    currentSubItems = currentSubItems.map((subItem) => ({
      ...subItem,
      checked: commandRange.command === subItem.command
    }));

    currentItems[0].subitemsSettings = currentSubItems;
    setItems(currentItems);
  }, [commandRange]);

  useEffect(() => {
    /** THIS CODE WAS COMMENTED DUE TO A INFINITE LOOP */
    // filterActivities()
  }, [addFilter, activities, isTreeRender]);

  const handleLinearActivity = (_) => {
    const linearActivities = [];
    activities.forEach((activity) =>
      recursiveActivities(activity, linearActivities)
    );
    linearActivities.forEach((activity) => delete activity.children);
    return linearActivities;
  };

  const recursiveActivities = (activity, linearActivities) => {
    linearActivities.push({
      id: activity.id,
      name: activity.name,
      value: activity.name
    });
    activity.children.forEach((child) =>
      recursiveActivities(child, linearActivities)
    );
  };

  const handleCommandDispatched = (command) => {
    !command.includes('FREE') &&
      presetDateChange(parseInt(command.split('_')[0]));

    setCommand({ command, clicked: true });
  };

  const presetDateChange = (weeksAgo, filterOnRender = false) => {
    if (isNaN(weeksAgo)) {
      setDateRange({
        start: null,
        end: null,
        weeks: 'clear'
      });

      setFirstDateFilter(filterOnRender);
      filterActivitiesByDate(null, null, 'clear');
      return;
    }

    let start = new Date();
    let end = new Date();
    start = start.toISOString().split('T')[0].split('-').join('/');

    const weeks = {
      1: { days: 7, week: '1' },
      2: { days: 14, week: '2' },
      3: { days: 21, week: '3' },
      4: { days: 28, week: '4' },
      5: { days: 35, week: '5' },
      6: { days: 42, week: '6' },
      7: { days: 49, week: '7' },
      8: { days: 56, week: '8' }
    };

    end.setDate(end.getDate() + weeks[weeksAgo]?.days || weeks.THIS.days);
    end = end.toISOString().split('T')[0].split('-').join('/');

    setDateRange({
      start: start,
      end: end,
      weeks: weeks[weeksAgo]?.week || '1'
    });

    setFirstDateFilter(filterOnRender);
    filterActivitiesByDate(start, end, weeks[weeksAgo]?.week || '1');
  };

  /**
   * Evaluates whether an activity should be hidden or visible in the activity tree based on
   * its start and end dates, completion status, and the selected date range filter.
   *
   * @param {Object} activity - The activity object to evaluate. Must include start_date, end_date, and progress properties.
   * @param {String} dateStart - The start date of the filter range, formatted as 'YYYY-MM-DD'. If undefined, no start date filtering is applied.
   * @param {String} dateEnd - The end date of the filter range, formatted as 'YYYY-MM-DD'. If undefined, no end date filtering is applied.
   * @param {Boolean} validate_child - A flag indicating whether child activities need to be recursively validated. It's set to false if the current activity should not be hidden.
   * @param {String} range - Specifies the filtering mode. Can be 'clear', 'free', or a specific week range (e.g., '2 weeks').
   *                         'clear' mode shows all activities, 'free' mode applies the date range filter, and specific week ranges include incomplete activities before the start date.
   *
   * @returns {Boolean} The updated hide status of the activity. If true, the activity should be hidden in the activity tree; otherwise, it should be visible.
   *
   * @link https://pp-docs-internal.proplanner.app/docs/Proplanner%20Web/Lookahead/ActivityTreePanel/filterActivitiesByDate
   */
  const dateRangeCondition = (
    activity,
    dateStart,
    dateEnd,
    validate_child,
    range
  ) => {
    const start = moment(dateStart).format('YYYY-MM-DD');
    const end = moment(dateEnd).format('YYYY-MM-DD');
    const startDate = moment(activity.start_date).format('YYYY-MM-DD');
    const endDate = moment(activity.end_date).format('YYYY-MM-DD');

    const isIncomplete = activity.progress < 100;

    if (range === 'clear') {
      activity.hide = false;
      if (activity.hide === false) {
        validate_child = false;
      }

      return activity.hide;
    } else if (range === 'free') {
      activity.hide = !(
        (startDate >= start && endDate <= end) ||
        (startDate >= start && startDate <= end) ||
        (startDate <= start && endDate >= start)
      );
      if (activity.hide === false) {
        validate_child = false;
      }

      return activity.hide;
    }

    if (!dateStart && !dateEnd) {
      activity.hide = false;
      return;
    }

    activity.hide = !(
      (startDate >= start && endDate <= end) ||
      (startDate >= start && startDate <= end) ||
      (startDate <= start && endDate >= start) ||
      (range !== 'free' && isIncomplete && endDate < start)
    );

    if (activity.hide === false) {
      validate_child = false;
    }

    return activity.hide;
  };

  const applyFilters = (activity) => {
    dispatch(timelineActions.updateParsedValue(false));
    const filtersType = {
      status: (currentActivity, filterBy) => {},
      toSelectResponsibles: (currentActivity, filterBy) =>
        (currentActivity.hideresponsables = filterBy.length
          ? !currentActivity.responsables.filter((responsable) =>
              filterBy.includes(responsable.email)
            ).length
          : false),
      subtrade: (currentActivity, filterBy) =>
        (currentActivity.hideBySubtrade = filterBy.length
          ? !filterBy.includes(currentActivity.subcontractId)
          : false),
      tags: (currentActivity, filterBy) =>
        (currentActivity.hideTags = filterBy.length
          ? !currentActivity.tags.filter((tag) => filterBy.includes(tag.name))
              .length
          : false)
    };

    filterSettings.forEach((filter) => {
      filtersType[filter.name](activity, filter.filter_by);
    });

    setTimeout(() => {
      EventEmitter.emit('HIDE_PLANIFICATION');
    }, 1000);
  };

  const filterActivities = () => {
    const recursiveTask = (activity) => {
      applyFilters(activity);
      activity.hide =
        activity.hideresponsables ||
        activity.hideBySubtrade ||
        activity.hideTags;
      activity.children &&
        activity.children.map((child) => recursiveTask(child));
    };

    activities.forEach((activity) => recursiveTask(activity));
    setFilterApplied(!filterApplied);
  };

  const filterActivitiesById = (activitySelection) => {
    const recursiveTask = (activity) => {
      activity.hide = activitySelection && activity.id != activitySelection.id;
      activity.children &&
        activity.children.map((child) => recursiveTask(child));
    };

    activities.forEach((activity) => recursiveTask(activity));

    setActivitySuggestion(activitySelection);
  };

  const filterActivitiesByDate = (start, end, range) => {
    const recursiveTask = (activity) => {
      let evaluate_child = true;

      if (activity.children) {
        activity.children.forEach((child) => recursiveTask(child));
      }

      if (activity.type !== 'project') {
        if (!dateRangeCondition(activity, start, end, evaluate_child, range)) {
          evaluate_child = false;
        }
      } else {
        evaluate_child = activity.children.every((child) => child.hide);
      }

      activity.hide = evaluate_child;
    };

    if (actitySettings) {
      actitySettings.start = start;
      actitySettings.end = end;
      actitySettings.range = range;
    }

    activities.forEach((activity) => recursiveTask(activity));

    if (!firstDateFilter) {
      setChangeDateRange(true);
    }
  };

  const middleDatesChange = (_, dates, filterOnRender = false) => {
    if (dates[0] == '' && dates[1] == '') {
      return setShowDatePicker(false);
    }

    setDateRange({
      start: dates[0],
      end: dates[1],
      weeks: null
    });

    setFirstDateFilter(filterOnRender);
    filterActivitiesByDate(dates[0], dates[1], 'free');
  };

  return (
    <div
      className={styles.filters}
      data-testid={TestIds.FILTERS_PANE_CONTAINER}>
      <div className={styles['search-box']}>
        <AutoComplete
          suggestions={linearActivities}
          setValue={filterActivitiesById}
          showIconSearch
          placeholder={t('activity_tree_panel.autocomplete-placeholder')}
        />
        <ToolbarGroup
          items={items}
          onCommandDispatched={handleCommandDispatched}
          t={t}
        />
        {showDatePicker && (
          <RangePicker
            className={styles['range-picker']}
            onChange={middleDatesChange}
            defaultValue={
              datesRange.start && datesRange.end
                ? [
                    moment(datesRange.start, 'YYYY/MM/DD'),
                    moment(datesRange.end, 'YYYY/MM/DD')
                  ]
                : [
                    moment(new Date(), 'YYYY/MM/DD'),
                    moment(new Date(), 'YYYY/MM/DD')
                  ]
            }
          />
        )}
      </div>
      {isFeatureOn('enable-LWP-100') && (
        <MoreFilters
          onClickMoreFilters={onClickMoreFilters}
          addFilter={addFilter}
          setAddFilter={setAddFilter}
          filterSettings={filterSettings}
          t={t}
        />
      )}
    </div>
  );
};

export default Filters;
