import React from 'react';
import './index.css';

const Loader = ({ extraClass = '', text = false }) => (
  <div className={'loaded-container ' + extraClass}>
    <div className="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    {text ? <div>{text}</div> : null}
  </div>
);

export default Loader;
