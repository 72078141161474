const tracker = require('totango-tracker')('SP-28971-01');
const hostName = window.location.hostname;

export const totangoUserIdentify = (user) => {
  if (
    !user?.email?.includes('super@') &&
    hostName.includes('app.outbuild.com')
  ) {
    tracker.setUserAttributes(
      user.email,
      {
        Email: user.email,
        position: user.position,
        name: `${user.name} ${user.lastname}`,
        'Full Name': `${user.name} ${user.lastname}`
      },
      (err) => {
        if (err) {
          console.log('totangoUserIdentify', err);
        }
      }
    );
  }
};

export const totangoSetAccountAttributes = (
  user,
  projectId,
  companyName,
  companyId,
  projectName,
  stage,
  country
) => {
  if (
    !user?.email?.includes('super@') &&
    hostName.includes('app.outbuild.com')
  ) {
    tracker.setAccountAttributes(
      'p_' + projectId,
      {
        'Company Name': companyName,
        parent_id: companyId,
        'Account Type': 'PROJECT',
        name: projectName,
        'ProPlanner Status': stage,
        country: country
      },
      (err) => {
        if (err) {
          console.log('totangoSetAccountAttributes', err);
        }
      }
    );
  }
};

export const totangoEventTracking = (accountId, user, activity, module) => {
  if (
    !user?.email?.includes('super@') &&
    hostName.includes('app.outbuild.com')
  ) {
    tracker.trackActivity(accountId, user?.email, activity, module, (err) => {
      if (err) {
        console.log(
          'totangoEventTracking',
          err,
          accountId,
          user.email,
          activity,
          module
        );
      }
    });
  }
};
