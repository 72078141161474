import React, { useState, useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { getTodayWeekWithPdayAndCurrentDay } from '../../../../utils';

function DaysBehindSchedule({ dataCurve, filterDates }) {
  const [isLoading, setLoading] = useState(true);
  const [Options, setOptions] = useState({});
  let series;
  const userLang = navigator.language || navigator.userLanguage;

  /** redux */
  const projectState = useSelector((state) => state.projectState);
  let projectSelected = [];
  let planification_day = null;

  if (typeof projectState !== 'undefined') {
    if (projectState.allProjects !== null) {
      projectSelected = projectState.allProjects.find(
        (e) => e.id == projectState.projectSelected
      );
      planification_day = projectSelected.planification_day;
    }
  }

  useEffect(() => {
    if (typeof planification_day !== 'undefined') {
      if (planification_day !== null) {
        curve();
      }
    }
  }, [dataCurve]);

  const curve = async () => {
    let title;
    let point;
    if (userLang.includes('es')) {
      title = 'Desfase actual';
      point = 'Días';
    } else {
      title = 'Actual Gap';
      point = 'Days';
    }
    const data = dataCurve;
    if (data.options) {
      const dates = dataCurve.options.xaxis.full_date;
      series = [
        {
          data: [],
          name: point
        }
      ];
      dates.forEach((element, index) => {
        const rt2 = data.raw_data[2].real[index];
        const t2 = element;
        let s1;
        const base_slice = data.raw_data[0].base;
        let b2 = 0;
        const b1 = base_slice.find((base, indice) => {
          if (base <= rt2 && base_slice[indice + 1] >= rt2) {
            b2 = base_slice[indice + 1];
            s1 = dates[indice];
          }
          return base <= rt2 && base_slice[indice + 1] >= rt2;
        });
        const t1 = moment(s1).add(
          (7 * ((rt2 - b1) / (b2 - b1))).toFixed(0),
          'days'
        );
        let t0 = t1.diff(t2, 'days');
        if (isNaN(t0)) {
          t0 = 0;
        }
        if (data.raw_data[2].real[index] >= 0) {
          series[0].data.push({ x: element, y: t0.toFixed(2) });
        }
      });
      const filtered_serie = [];
      let start = moment();
      let end = moment();
      if (filterDates.length != 0) {
        const start_data = getTodayWeekWithPdayAndCurrentDay(
          null,
          moment(filterDates[0]),
          planification_day
        );
        const end_data = getTodayWeekWithPdayAndCurrentDay(
          null,
          moment(filterDates[1]),
          planification_day
        );
        start = moment(start_data[2].end);
        end = moment(end_data[2].end);
        const start_string = start.format('YYYY-MM-DD');
        const end_string = end.format('YYYY-MM-DD');

        const index_start = dates.indexOf(start_string);
        const index_end = dates.indexOf(end_string);

        if (start_string != '' && end_string != '') {
          series[0].data.map((point, index) => {
            if (index >= index_start && index <= index_end) {
              filtered_serie.push(point);
            }
          });
          series[0].data = filtered_serie;
        }
      }

      const options = {
        series: series,
        options: {
          chart: {
            height: 500,
            type: 'line'
          },
          dataLabels: { enabled: false },
          stroke: {
            show: true,
            width: 1.9
          },
          markers: {
            size: [6, 0]
          },
          tooltip: {
            shared: false,
            intersect: true
          },
          legend: {
            show: false
          },
          xaxis: {
            type: 'date'
          },
          title: {
            text: title
          },
          annotations: {
            yaxis: [
              {
                y: 0,
                width: '100%',
                height: '25px',
                strokeDashArray: 0,
                borderColor: '#00E396',
                label: {
                  borderColor: '#00E396',
                  text: '',
                  style: {
                    color: '#fff',
                    background: '#00E396',
                    fontSize: '14px'
                  }
                }
              }
            ]
          }
        }
      };
      setOptions(options);
      setLoading(false);
    }
  };
  const renderChart = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Chart
        options={Options.options}
        series={Options.series}
        width="100%"
        height="100%"
      />
    );
  };
  return <div style={{ height: '100%' }}>{renderChart()}</div>;
}

export default DaysBehindSchedule;
