import React, { useState, createRef } from 'react';

import SelectSearch from 'react-select-search';
import {
  Row,
  Col,
  Icon,
  Popover,
  DatePicker,
  Popconfirm,
  Tooltip,
  Select
} from 'antd';
import { MassiveTotalMaterial } from '../MassiveTotalMaterial';
import ProgressBar from '../ProgressBarMassive';
import IconComponent from '../Projects/IconSvg';
import { durationMassiveSvg, materialMassiveSvg } from '../../utils/svgIcons';
import { helmet } from '../../utils';
import { lookaheadActions } from '../../redux/actions/lookaheadActions';
import { userActions } from '../../redux/actions/userActions';
import NumberFormat from 'react-number-format';
import cloneDeep from 'lodash/cloneDeep';
import { Animated } from 'react-animated-css';
import { capitalize } from 'lodash';
import moment from 'moment';

/** Massive actions icons */
import constraintMassive from '../../assets/img/massive/constraint.png';
import datesMassive from '../../assets/img/massive/dates.png';
import deleteMassive from '../../assets/img/massive/delete.png';
import priorityMassive from '../../assets/img/massive/priority.png';
import progressMassive from '../../assets/img/massive/progress.png';
import responsableMassive from '../../assets/img/massive/responsable.png';
import leanMassive from '../../assets/img/massive/EstadoLean.png';
import duplicateMassive from '../../assets/img/massive/duplicate.png';
import quitDismissIcon from '../../assets/img/gantt/quit-header-config.png';
import fakeAvatar from '../../assets/img/fake_user.png';
import {
  calculateProgress,
  getTask,
  getEndDateByGantt,
  calculatePonderators,
  notifyMessage,
  getTasksToDelete,
  deleteTaskHandler,
  calculateLeanStatus
} from '../../utils/lookahead-common';
import {
  activityService,
  notificationService,
  taskService
} from '../../services';
import { geoVictoriaService } from '../../services/geovictoria_api.service';
import { getSignedUser } from '../../utils/userUtils';

const { Option } = Select;

const LookaheadMassiveHeader = (props) => {
  const {
    massiveSelection,
    massiveOnProcess,
    dispatch,
    t,
    getActivityAndParentReference,
    groupBy,
    updateAsyncTask,
    updateAsyncActivity,
    activities,
    handlePopVisibility,
    projectState,
    tableConfig,
    tableMetadata,
    dateRange,
    massiveConstraintHandler,
    updateRender,
    setMassiveSelection,
    lastLevelActivitiesCard,
    toSelectResponsables,
    geo_users,
    ganttAPI,
    sectorObject,
    getLookaheadInMassive
  } = props;
  const [massiveProgress, setMassiveProgress] = useState(0);
  const [toActivityDuplicate, setToActivityDuplicate] = useState({
    value: null
  });
  const [massiveResponsableState, setMassiveResponsableState] = useState({
    values: []
  });
  const [toActivityGeoVictoria, setToActivityGeoVictoria] = useState({
    value: null
  });

  const refInputMasssiveTotal = createRef();

  /**
   * On click function when pressing delete
   * @param {*} task Top lvl task to start tree deleting (REQUIRED)
   * @param {*} activity Activity parent object (REQUIRED)
   * @param {*} parent If task is from another than first level must be specified the parent task to attack it children array
   */
  const handleDelete = async (task, activity, parent = null) => {
    const tasksToDelete = [];
    getTasksToDelete(task, tasksToDelete);
    const asyncMap = tasksToDelete.map(async (toDelete) => {
      await deleteTaskHandler(
        toDelete,
        activity,
        parent,
        updateAsyncTask,
        updateAsyncActivity,
        projectState
      );
    });

    await Promise.all(asyncMap);
  };

  /**
   * This function handles with massive progress change
   */
  const massiveProgressHandler = async () => {
    const asyncMap = massiveSelection.map(async (selection) => {
      const { activity, parentTask } = getActivityAndParentReference(
        selection.activityId,
        selection.parent_id
      );

      /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
      const doesExistAtReference = getTask(selection.id, null, activity);
      if (doesExistAtReference.length) {
        const reference = doesExistAtReference[0];
        /** VALIDATION: Task with children cant be setted the progress */
        if (!reference.children.length) {
          reference.progress = massiveProgress;
          if (massiveProgress == 0) {
            if (calculatePonderators) {
              calculatePonderators(
                parentTask || activity,
                activity,
                updateAsyncTask,
                projectState
              );
            }
          }
          // taskService.update(reference)
          updateAsyncTask(reference);
          await calculateProgress(
            reference,
            parentTask || activity,
            activity,
            updateAsyncTask,
            updateAsyncActivity
          );
        }
      }
    });

    await Promise.all(asyncMap);
    handlePopVisibility(false, 'progressMassive');
  };

  /**
   * This function handle massive change of priority
   * @param {*} option Single object with selected priority
   */
  const massivePriorityHandle = (option) => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.priority = option.value;
          // taskService.update(reference)
          updateAsyncTask(reference);
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            let reference = doesExistAtReference;
            reference.priority = option.value;
            reference = cloneDeep(reference);
            delete reference.activityObject;
            updateAsyncTask(reference);
          }
        });
      });
    }
    dispatch(lookaheadActions.notifyLookaheadUpdateGroup());
  };

  /**
   * This function deletes tasks massively
   */
  const massiveDeleteHandler = async () => {
    /** Declare an async iteration */
    const asyncMap = massiveSelection.map(async (selection) => {
      const { activity, parentTask } = getActivityAndParentReference(
        selection.activityId,
        selection.parent_id
      );

      /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
      const doesExistAtReference = getTask(selection.id, null, activity);
      if (doesExistAtReference.length) {
        await handleDelete(doesExistAtReference[0], activity, parentTask);
        if (!activity.tasks.length) {
          activity.active = false;
        }
      }
    });

    /** We await of async iteration */
    await Promise.all(asyncMap);

    /** Then update the render, notify of process, and clean the massive selection state */
    updateRender();
    notifyMessage({
      title: t('deleted_succesfully_title'),
      message: t('deleted_succesfully'),
      type: 'success'
    });
    setMassiveSelection([]);
  };

  /**
   * This function create masive task in geo victoria
   */
  const massiveGeoVictoriaHandler = async () => {
    const tasks_list = [];
    const asyncMap = massiveSelection.map(async (selection) => {
      const { activity, parentTask } = getActivityAndParentReference(
        selection.activityId,
        selection.parent_id
      );
      const doesExistAtReference = getTask(selection.id, null, activity);
      /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
      if (doesExistAtReference.length) {
        const tasks = {
          task: activity.activityRoute + ' > ' + doesExistAtReference[0].name
        };
        tasks_list.push(tasks);
      }
      const data = {
        tasks: tasks_list,
        projectId: projectState.projectSelected,
        user: toActivityGeoVictoria.value
      };
      await geoVictoriaService.createTaskGeo(data);
      toActivityGeoVictoria.value = '';
    });
    await Promise.all(asyncMap);
    updateRender();
    notifyMessage({
      title: t('geo_victoria_tasks_create'),
      message: '',
      type: 'success'
    });
    setMassiveSelection([]);
  };
  /**
   * This function handles the massive start date change
   * @param {*} date date f rom datepicker at dom
   */
  const massiveStartHandle = (date) => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.start_date = date;
          getEndDateByGantt(reference, activity);
          updateAsyncTask(reference);
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            let reference = doesExistAtReference;
            reference.start_date = date;
            getEndDateByGantt(reference, reference.activityObject);
            reference = cloneDeep(reference);
            delete reference.activityObject;
            updateAsyncTask(reference);
          }
        });
      });
    }
    dispatch(lookaheadActions.notifyLookaheadUpdateGroup());
  };

  const massiveDurationHandle = (date) => {
    const value = refInputMasssiveTotal.current.state.value;
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];

          reference.duration = Math.abs(Number(value));
          /** We give the lookahead task to gantt API to get duration and end_date */
          getEndDateByGantt(reference, activity);
          /** Calculates ponderators feature */
          calculatePonderators(
            parentTask || activity,
            activity,
            updateAsyncTask,
            projectState
          );
          /** Apply changes */
          updateAsyncTask(reference);
        }
      });
    }
    dispatch(lookaheadActions.notifyLookaheadUpdateGroup());
    handlePopVisibility(false, 'priorityMassive');
  };

  /**
   * This function handles change massive responsable change
   */
  const massiveResponsableHandler = () => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.responsables = [];
          massiveResponsableState.values.map((res) => {
            reference.responsables.push(res);
          });
          updateAsyncTask(reference);
          const user = getSignedUser();
          reference.sectorId = projectState.sectorSelected;
          for (let i = 0; i < reference.responsables.length; i++) {
            notificationService.createConstraint({
              user,
              constraint: reference,
              module: 'tasks',
              type_notification: 'assign_responsible',
              userId: reference.responsables[i].id
            });
          }
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map(async (ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            let reference = doesExistAtReference;
            reference.responsables = [];
            massiveResponsableState.values.map((res) => {
              reference.responsables.push(res);
            });
            reference = cloneDeep(reference);
            delete reference.activityObject;
            updateAsyncTask(reference);
            const user = getSignedUser();
            reference.sectorId = projectState.sectorSelected;
            for (let i = 0; i < reference.responsables.length; i++) {
              await notificationService.createConstraint({
                user,
                constraint: reference,
                module: 'tasks',
                type_notification: 'assign_responsible',
                userId: reference.responsables[i].id
              });
            }
          }
        });
      });
    }
    dispatch(lookaheadActions.notifyLookaheadUpdateGroup());
    dispatch(userActions.setUserUpdate());
  };

  const massiveLeanHandle = (option) => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          const released = reference.constraints.filter(
            (constraint) => constraint.status == 'released'
          );
          const hasConstraints = reference.constraints.length;
          const hasAllReleased =
            reference.constraints.length == released.length;
          /** First case: Has constraints but are all of them released or Has not constraints */
          if (
            reference.lean_status !== 'Committed' &&
            ((hasConstraints && hasAllReleased) || !hasConstraints)
          ) {
            const childsHaveLeanStatusWill = reference.children.some(
              (el) => el.lean_status === 'Will'
            );
            if (!childsHaveLeanStatusWill) {
              reference.lean_status = option.value;
              updateAsyncTask(reference);
              calculateLeanStatus(
                reference,
                parentTask || activity,
                activity,
                updateAsyncTask,
                updateAsyncActivity
              );
            }
          }
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            let reference = doesExistAtReference;
            const released = reference.constraints.filter(
              (constraint) => constraint.status == 'released'
            );
            const hasConstraints = reference.constraints.length;
            const hasAllReleased =
              reference.constraints.length == released.length;
            /** First case: Has constraints but are all of them released or Has not constraints */
            // if (reference.lean_status === 'Committed' && ((hasConstraints && hasAllReleased) || !hasConstraints)) {
            //     reference.lean_status = option.value
            //     reference = cloneDeep(reference)
            //     delete reference.activityObject
            //     updateAsyncTask(reference)
            // }
            if (
              reference.lean_status !== 'Committed' &&
              ((hasConstraints && hasAllReleased) || !hasConstraints)
            ) {
              reference.lean_status = option.value;
              reference = cloneDeep(reference);
              delete reference.activityObject;
              updateAsyncTask(reference);
            }
          }
        });
      });
    }
    dispatch(lookaheadActions.notifyLookaheadUpdateGroup());
  };

  const massiveDuplicateHandler = async () => {
    const copy_massiveSelection = cloneDeep(massiveSelection);
    if (copy_massiveSelection.length > 0) {
      const duplicateactivity = activities.find(
        (a) => a.id == toActivityDuplicate.value
      );

      modificateTaskInitByActivity(
        duplicateactivity,
        copy_massiveSelection,
        sectorObject
      );
    }
    const res = await taskService.duplicateAll(
      copy_massiveSelection,
      projectState.sectorSelected,
      toActivityDuplicate.value
    );
    if (res) {
      const lastLevelActivities = await activityService.getLookaheadIds(
        getLookaheadInMassive
      );
      const data = lastLevelActivities.activities;
      const copiedActivity = data.find(
        (el) => el.id == toActivityDuplicate.value
      );

      const replace = activities.map((ac) => {
        if (ac.id == toActivityDuplicate.value) {
          ac = copiedActivity;
        }
        return ac;
      });

      updateRender(replace);
      notifyMessage({
        title: t('duplicate_succesfully_title'),
        message: t('duplicate_succesfully'),
        type: 'success'
      });
      //setMassiveSelection([])
    }
  };

  const modificateTaskInitByActivity = async (activity, tasks, sector) => {
    try {
      for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i];
        const activity_last = activities.find((a) => a.id == task.activityId);
        const diferencia =
          ganttAPI.calculateDuration(
            moment(activity_last.start_date).format('YYYY/MM/DD HH:mm'),
            moment(task.start_date).format('YYYY/MM/DD HH:mm'),
            activity.calendarId
          ) / sector.hoursPerDay;
        const new_date_init = ganttAPI.getEndByDuration(
          activity.start_date,
          diferencia,
          task.id,
          activity.calendarId,
          sector
        );
        const new_date = ganttAPI.getEndByDuration(
          new_date_init.end_date,
          task.duration,
          task.id,
          activity.calendarId,
          sector
        );
        task.start_date = new_date.start_date;
        task.end_date = new_date.end_date;
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const massiveSubcontractHandler = (option) => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.subcontractId = option.value;
          // taskService.update(reference)
          updateAsyncTask(reference);
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            let reference = doesExistAtReference;
            reference.subcontractId = option.value;
            reference = cloneDeep(reference);
            delete reference.activityObject;
            updateAsyncTask(reference);
          }
        });
      });
    }
    dispatch(lookaheadActions.notifyLookaheadUpdateGroup());
  };

  const quitDismissHandler = () => {
    setMassiveResponsableState({ values: [] });
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );
        activity.active = false;
        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.active = false;
          // taskService.update(reference)
          // updateAsyncTask(reference)
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            const reference = doesExistAtReference;
            reference.active = false;
            ac.active = false;
            // delete reference.activityObject
            // updateAsyncTask(reference)
          }
        });
      });
    }

    setMassiveSelection([]);
    handlePopVisibility(false, 'priorityMassive');
  };

  const massiveMaterialHandler = (option) => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.materialId = option.id;
          updateAsyncTask(reference);
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            let reference = doesExistAtReference;
            reference.materialId = option.id;
            reference = cloneDeep(reference);
            delete reference.activityObject;
            updateAsyncTask(reference);
          }
        });
      });
    }

    handlePopVisibility(false, 'materialMassive');
    dispatch(lookaheadActions.notifyLookaheadUpdateGroup());
  };

  const renderSelectUserGeo = () => {
    return (
      <Select
        placeholder="Usuario"
        style={{ width: 319 }}
        onChange={(e) => {
          toActivityGeoVictoria.value = e;
        }}>
        {geo_users.listusers.map((us) => {
          if (us.Identifier) {
            return (
              <Option value={us.Identifier}>
                <Tooltip
                  overlayStyle={{ width: 350, maxWidth: 350, minWidth: 350 }}
                  title={
                    <div style={{ textAlign: 'center' }}>{us.Identifier}</div>
                  }>
                  <div>{us.Name + ' ' + us.LastName}</div>
                </Tooltip>
              </Option>
            );
          }
        })}
      </Select>
    );
  };

  const renderMassiveResponsable = () => {
    const friends = [];
    const selected = [];
    const dictionary = {};

    massiveResponsableState.values.map((res) => {
      selected.push(res.email);
    });

    toSelectResponsables.map((user) => {
      if (user.is_active) {
        friends.push({
          name: user.name + ' ' + user.lastname + ' (' + user.email + ')',
          value: user.email,
          photo: user.image || fakeAvatar,
          object: user
        });
        dictionary[user.email] = user;
      }
    });

    return (
      <SelectSearch
        printOptions="always"
        className="select-search-massive select-search-massive--multiple"
        options={friends}
        value={selected}
        renderOption={renderFriend}
        onChange={(val) => {
          massiveResponsableState.values = [];
          val.map((op) => {
            massiveResponsableState.values.push(dictionary[op]);
          });
        }}
        multiple
        search
        placeholder={t('search_res_text')}
      />
    );
  };

  const renderSelectDuplicateTasks = () => {
    const avoidActivities = [];
    massiveSelection.map((singleTask) => {
      const activityId = singleTask.activityId;
      if (!avoidActivities.includes(activityId)) {
        avoidActivities.push(activityId);
      }
    });
    return (
      <Select
        placeholder={t('lookahead.activity')}
        style={{ width: 319 }}
        onChange={(e) => {
          toActivityDuplicate.value = e;
        }}>
        {activities.map((ac) => {
          if (!avoidActivities?.includes(ac?.id)) {
            return (
              <Option value={ac.id} key={ac.id}>
                <Tooltip
                  overlayStyle={{ width: 350, maxWidth: 350, minWidth: 350 }}
                  title={
                    <div style={{ textAlign: 'center' }}>
                      {ac.activityRoute}
                    </div>
                  }>
                  <div>{ac.name}</div>
                </Tooltip>
              </Option>
            );
          }
        })}
      </Select>
    );
  };
  /**
   * This function builds a JSX to select responsables at inline edition
   */
  const renderFriend = (props, option, _snapshot, className) => {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10
    };

    let toShowName = `${option.object.name} ${option.object.lastname}`;

    if (toShowName) {
      if (toShowName.length > 17) {
        toShowName = toShowName.slice(0, 17) + '...';
      }
    }

    return (
      <button
        {...props}
        className={className}
        type="button"
        title={option.object.email}>
        <span>
          <img alt="" style={imgStyle} width={20} src={option.photo} />
          <span style={{ fontSize: 15 }}>{toShowName}</span>
        </span>
      </button>
    );
  };

  return (
    <Animated
      style={{ height: massiveSelection.length ? 30 : 0, overflow: 'hidden' }}
      animationIn="fadeIn"
      animationInDuration={250}
      animationOut="fadeOut"
      animationOutDuration={250}
      isVisible={!!massiveSelection.length}>
      <Row style={{ height: 30, backgroundColor: '#586666' }}>
        <Col span={12} offset={6} style={{ height: '100%' }}>
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            style={{ height: '100%' }}>
            {massiveOnProcess ? null : (
              <Col style={{ textAlign: 'center' }}>
                {/* Selection text indicator */}
                <span style={{ color: '#7DFF8A' }}>
                  {massiveSelection.length + ' '}
                </span>
                <span style={{ color: '#FFFFFF', marginRight: 23 }}>
                  {t('lookahead_resources.masive_task')}
                  {massiveSelection.length > 1 ? 's' : null}{' '}
                  {t('lookahead_resources.masive_selected')}
                  {massiveSelection.length > 1 ? 's' : null}
                </span>

                {/* Massive constraints */}
                <span
                  className="massive-icon-style"
                  onClick={massiveConstraintHandler}>
                  <Tooltip placement="top" title={t('constraints_label_only')}>
                    <img width={12} src={constraintMassive} />
                  </Tooltip>
                </span>

                {/* Massive Progress */}
                {groupBy.criteria == 'activity' ||
                groupBy.criteria == 'activityId' ? (
                  <span className="massive-icon-style">
                    <Popover
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          <ProgressBar
                            value={massiveProgress}
                            setValue={setMassiveProgress}
                            massiveProgressHandler={massiveProgressHandler}
                            t={t}
                          />
                        </span>
                      }
                      trigger="click">
                      <Tooltip placement="top" title={t('progress_only_label')}>
                        <img width={12} src={progressMassive} />
                      </Tooltip>
                    </Popover>
                  </span>
                ) : null}

                {/* Massive Duration */}
                {groupBy.criteria == 'activity' ||
                groupBy.criteria == 'activityId' ? (
                  <span className="massive-icon-style">
                    <Popover
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          <div style={{ textAlign: 'center', width: 111 }}>
                            {t('tables.lookahead.plan.duration')}
                          </div>
                          <div className="div-number-progress-massive">
                            <NumberFormat
                              ref={refInputMasssiveTotal}
                              className="only-number-progress-massive"
                              defaultValue={0}
                              autoComplete="off"
                              displayType={'input'}
                              style={{ textAlign: 'center' }}
                              autoFocus
                              onFocus={(e) => e.target.select()}
                            />
                          </div>
                          <div
                            className="progress-massive-btn"
                            onClick={massiveDurationHandle}>
                            {t('apply_label')}
                          </div>
                        </span>
                      }
                      trigger="click">
                      <Tooltip
                        placement="top"
                        title={t('tables.lookahead.plan.duration')}>
                        <span>
                          <IconComponent
                            data={durationMassiveSvg}
                            width={14}
                            fill="#fff"
                            className="icon-massive-duration"
                          />
                        </span>
                      </Tooltip>
                    </Popover>
                  </span>
                ) : null}

                {/* Massive Responsable */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {renderMassiveResponsable()}
                        <div
                          className="progress-massive-btn"
                          onClick={massiveResponsableHandler}>
                          {t('apply_label')}
                        </div>
                      </span>
                    }
                    trigger="click">
                    <Tooltip
                      placement="top"
                      title={t('responsable_label_only')}>
                      <img width={12} src={responsableMassive} />
                    </Tooltip>
                  </Popover>
                </span>

                {/* Subcontract */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {tableConfig
                          .find((col) => col.name == 'subcontractId')
                          ?.from_values.map((option, index) => {
                            return (
                              <div
                                style={{ cursor: 'pointer' }}
                                key={index}
                                onClick={() =>
                                  massiveSubcontractHandler(option)
                                }>
                                <IconComponent
                                  data={helmet}
                                  width={15}
                                  fill={'white'}
                                  className="icon-options-subcontract"
                                />
                                <span style={{ marginLeft: 5 }}>
                                  {capitalize(option.label)}
                                </span>
                              </div>
                            );
                          })}
                      </span>
                    }
                    trigger="click">
                    <Tooltip
                      placement="top"
                      title={t('subcontract_only_label')}>
                      <span>
                        <IconComponent
                          data={helmet}
                          width={15}
                          fill={'white'}
                          className="icon-options-subcontract"
                        />
                      </span>
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive LEAN */}
                {groupBy.criteria == 'activity' ||
                groupBy.criteria == 'activityId' ? (
                  <span className="massive-icon-style">
                    <Popover
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          {tableMetadata
                            .find((col) => col.name == 'lean_status')
                            .from_values.map((option, index) => {
                              if (
                                option.value == 'Committed' ||
                                option.value == 'Restricted'
                              )
                                return null;
                              return (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  key={index}
                                  onClick={() => massiveLeanHandle(option)}>
                                  <i
                                    className="fas fa-circle border-icon"
                                    style={{
                                      fontSize: 11,
                                      color: option.color,
                                      position: 'relative',
                                      top: -1
                                    }}
                                  />
                                  <span style={{ marginLeft: 5 }}>
                                    {capitalize(option.label)}
                                  </span>
                                </div>
                              );
                            })}
                        </span>
                      }
                      trigger="click">
                      <Tooltip placement="top" title={t('lean_status_only')}>
                        <img width={12} src={leanMassive} />
                      </Tooltip>
                    </Popover>
                  </span>
                ) : null}

                {/* Massive start date */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        <DatePicker
                          allowClear={false}
                          className="custom-date-picker-planification"
                          style={{ left: -4 }}
                          format={'YYYY/MM/DD'}
                          placeholder={t('select_date_label_only')}
                          onChange={(_date, dateString) =>
                            massiveStartHandle(dateString)
                          }
                        />
                      </span>
                    }
                    trigger="click">
                    <Tooltip placement="top" title={t('start_only_label')}>
                      <img width={12} src={datesMassive} />
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive Priority */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {tableMetadata
                          .find((col) => col.name == 'priority')
                          .from_values.map((option, index) => {
                            return (
                              <div
                                style={{ cursor: 'pointer' }}
                                key={index}
                                onClick={() => massivePriorityHandle(option)}>
                                <img
                                  src={option.icon}
                                  width={11}
                                  style={{ position: 'relative', top: 0 }}
                                />
                                <span style={{ marginLeft: 5 }}>
                                  {capitalize(option.label)}
                                </span>
                              </div>
                            );
                          })}
                      </span>
                    }
                    trigger="click">
                    <Tooltip placement="top" title={t('priority_label_only')}>
                      <img width={12} src={priorityMassive} />
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive duplicate */}
                {groupBy.criteria == 'activity' ||
                groupBy.criteria == 'activityId' ? (
                  <span className="massive-icon-style">
                    <Popover
                      overlayStyle={{ width: 350 }}
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          <div
                            style={{
                              textAlign: 'center',
                              width: 'auto',
                              paddingBottom: 5
                            }}>
                            {t('duplicate_label')}
                          </div>
                          {renderSelectDuplicateTasks()}
                          <div
                            className="progress-massive-btn"
                            style={{ marginTop: 8 }}
                            onClick={massiveDuplicateHandler}>
                            {t('apply_label')}
                          </div>
                        </span>
                      }
                      trigger="click">
                      <Tooltip placement="top" title={t('duplicate_label')}>
                        <img width={12} src={duplicateMassive} />
                      </Tooltip>
                    </Popover>
                  </span>
                ) : null}

                {/* Massive Geo Victoria Task Create */}
                {/* {
                                                geo_project_id != null
                                                    ? groupBy.criteria == 'activity' || groupBy.criteria == 'activityId' ? (
                                                        <span className="massive-icon-style">
                                                            <Popover
                                                                overlayStyle={{ width: 350 }}
                                                                overlayClassName="massive-selection-pop"
                                                                placement="bottom"
                                                                content={
                                                                    (
                                                                        <span className="progress-massive-style">
                                                                            <div style={{ textAlign: 'center', width: 'auto', paddingBottom: 5 }}>
                                                                                {t('select_geo_user')}
                                                                            </div>
                                                                            { renderSelectUserGeo() }
                                                                            <div className="progress-massive-btn" style={{ marginTop: 8 }} onClick={massiveGeoVictoriaHandler}>
                                                                                {t('apply_label')}
                                                                            </div>
                                                                        </span>
                                                                    )
                                                                }
                                                                trigger="click">
                                                                <Tooltip placement='top' title={t('duplicate_label')}>
                                                                    <img width={12} src={gv}/>
                                                                </Tooltip>
                                                            </Popover>
                                                        </span>

                                                    ) : null
                                                    : null
                                            } */}
                {/* <span className="massive-icon-style">
                                                            <Popconfirm
                                                                placement={'bottom'}
                                                                onConfirm={massiveGeoVictoriaHandler}
                                                                title={t('are_sure_general')}
                                                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}>
                                                                <Tooltip placement='top' title="Geo Victoria">
                                                                    <img width={12} src={gv} />
                                                                </Tooltip>
                                                            </Popconfirm>

                                                        </span> */}
                {/* Massive material */}
                <MassiveTotalMaterial
                  t={t}
                  massiveSelection={massiveSelection}
                  activities={activities}
                  getTask={getTask}
                  getActivityAndParentReference={getActivityAndParentReference}
                  updateAsyncTask={updateAsyncTask}
                  handlePopVisibility={handlePopVisibility}
                  groupBy={groupBy}
                />
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {lastLevelActivitiesCard?.resource?.length
                          ? lastLevelActivitiesCard.resource
                              .filter((el) => el.type === 'material')
                              .map((option, index) => {
                                return (
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    key={index}
                                    onClick={() =>
                                      massiveMaterialHandler(option)
                                    }>
                                    <span className="material-icon-res">
                                      <IconComponent
                                        data={materialMassiveSvg}
                                        width={15}
                                        fill="#fff"
                                      />
                                    </span>
                                    <span style={{ marginLeft: 5 }}>
                                      {capitalize(option.name)}
                                    </span>
                                  </div>
                                );
                              })
                          : null}
                      </span>
                    }
                    trigger="click">
                    <Tooltip
                      placement="top"
                      title={t('lookahead_resources.masive_material')}>
                      <span>
                        <IconComponent
                          data={materialMassiveSvg}
                          width={14}
                          fill="#fff"
                          className="icon-massive-material"
                        />
                      </span>
                    </Tooltip>
                  </Popover>
                </span>
                {/* Massive delete */}

                {groupBy.criteria == 'activity' ||
                groupBy.criteria == 'activityId' ? (
                  <span className="massive-icon-style">
                    <Popconfirm
                      placement={'bottom'}
                      onConfirm={massiveDeleteHandler}
                      title={t('are_sure_general')}
                      icon={
                        <Icon
                          type="question-circle-o"
                          style={{ color: 'red' }}
                        />
                      }>
                      <Tooltip
                        placement="top"
                        title={t('lookahead_resources.masive_delete')}>
                        <img width={12} src={deleteMassive} />
                      </Tooltip>
                    </Popconfirm>
                  </span>
                ) : null}

                {/* Quit Dismiss */}
                <Tooltip
                  placement="top"
                  title={t('lookahead_resources.masive_cancel')}>
                  <span
                    className="massive-icon-style"
                    onClick={quitDismissHandler}>
                    <img
                      width={12}
                      src={quitDismissIcon}
                      style={{ position: 'relative', top: -1 }}
                    />
                  </span>
                </Tooltip>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Animated>
  );
};

export default LookaheadMassiveHeader;
