import React from 'react';
import { Tooltip } from 'antd';

const TooltipSystem = ({ children, theme = 'default', ...props }) => (
  <Tooltip
    {...props}
    overlayClassName={`tooltip-design-system theme--${theme}`}>
    {children}
  </Tooltip>
);

export default TooltipSystem;
