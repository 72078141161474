import React, { useState } from 'react';
import cn from 'classnames';

import {
  ChevronDownIcon,
  CircleCancelIcon,
  CircleCheckIcon,
  EyeIcon
} from '../../../icons';
import { Accordion } from '../../Accordion';
import { getIconByStatus, getModificationDetails } from '../utils';
import colors from '../../../stylesheets/variables.scss';
import styles from './RequestsAccordion.module.scss';
import { useEffect } from 'react';
import { Tooltip } from 'antd';

export const RequestsAccordion = ({
  requests,
  onApproveRequest,
  onRejectRequest,
  onPreviewRequest,
  className,
  t
}) => {
  const [requestBeingPreviewed, setRequestBeingPreviewed] = useState();

  const handlePreviewRequest = (request) => {
    const isEnabling = requestBeingPreviewed !== request.id;
    setRequestBeingPreviewed(isEnabling ? request.id : undefined);
    onPreviewRequest(request, isEnabling);
  };

  const handleRejectRequest = (request) => {
    setRequestBeingPreviewed(undefined);
    onRejectRequest(request);
  };

  const handleApproveRequest = (request) => {
    setRequestBeingPreviewed(undefined);
    onApproveRequest(request);
  };

  useEffect(() => {
    setRequestBeingPreviewed(undefined);
  }, [requests]);

  const AuthorContent = (request) => {
    if (!request.authorName && !request.authorLastname) {
      return request.authorEmail;
    } else {
      const authorFullName = `${request.authorName || ''} ${request.authorLastname || ''}`;
      return authorFullName.trim();
    }
  };

  return (
    <Accordion
      items={requests}
      itemKeyProperty="id"
      className={cn(styles['requests-accordion'], className)}
      itemClassName={styles['requests-accordion__item']}
      renderItemHeader={(request, isExpanded) => {
        const { icon: StatusIcon, color: statusIconColor } = getIconByStatus(
          request.status
        );

        return (
          <div className={styles['requests-accordion__header']}>
            <div className={styles['requests-accordion__header-top-row']}>
              <strong className={styles['requests-accordion__id']}>
                {request.activityCorrelativeId}
              </strong>
              <strong className={styles['requests-accordion__title']}>
                {request.title}
              </strong>
              <StatusIcon
                color={statusIconColor}
                className={styles['requests-accordion__status-icon']}
              />
            </div>
            <div className={styles['requests-accordion__header-bottom-row']}>
              <Tooltip title={<AuthorContent {...request} />}>
                {request.authorThumbUrl ? (
                  <img
                    alt=""
                    className={styles['requests-accordion__author-thumb']}
                    src={request.authorThumbUrl}
                  />
                ) : (
                  <div className={styles['requests-accordion__author-no-img']}>
                    {request.authorName ? request.authorName[0] : ''}
                    {request.authorLastname ? request.authorLastname[0] : ''}
                  </div>
                )}
              </Tooltip>
              <span className={styles['requests-accordion__date']}>
                {t('date')}: {request.date}
              </span>
              <span className={styles['requests-accordion__elapsed-time']}>
                ({request.elapsedTime})
              </span>
              <ChevronDownIcon
                color={colors.white}
                className={cn(styles['requests-accordion__chevron-icon'], {
                  [styles['requests-accordion__chevron-icon--inverted']]:
                    isExpanded
                })}
              />
            </div>
          </div>
        );
      }}
      renderItemContent={(request) => {
        const modificationDetails = getModificationDetails(request);
        const isPending = request.status === 'WAITING';

        return (
          <div className={styles['requests-accordion__content']}>
            <div className={styles['requests-accordion__content-top-row']}>
              {modificationDetails.map(
                ({ i18nKey, originalValue, newValue }) => (
                  <div
                    className={styles['requests-accordion__modifications']}
                    key={i18nKey}>
                    <span
                      className={
                        styles['requests-accordion__modification-name']
                      }>
                      {t(i18nKey)}
                    </span>
                    <span
                      className={styles['requests-accordion__original-value']}>
                      {originalValue}
                    </span>
                    <span className={styles['requests-accordion__new-value']}>
                      {newValue}
                    </span>
                  </div>
                )
              )}
            </div>
            <div className={styles['requests-accordion__content-bottom-row']}>
              <div className={styles['requests-accordion__description']}>
                <textarea
                  className={styles['requests-accordion__description-textarea']}
                  value={request.description}
                  disabled
                />
              </div>
              <div className={styles['requests-accordion__buttons']}>
                <button
                  disabled={
                    !isPending ||
                    (requestBeingPreviewed &&
                      requestBeingPreviewed !== request.id)
                  }
                  onClick={() => handlePreviewRequest(request)}
                  className={cn(styles['requests-accordion__preview-button'], {
                    [styles['requests-accordion__preview-button--activated']]:
                      requestBeingPreviewed === request.id
                  })}>
                  <EyeIcon color={colors.gray80} />
                  {t('modification_requests_drawer.preview')}
                </button>
                <button
                  disabled={!isPending}
                  onClick={() => handleRejectRequest(request)}
                  className={styles['requests-accordion__reject-button']}>
                  <CircleCancelIcon color={colors.gray80} />
                  {t('modification_requests_drawer.reject')}
                </button>
                <button
                  disabled={!isPending}
                  onClick={() => handleApproveRequest(request)}
                  className={styles['requests-accordion__approve-button']}>
                  <CircleCheckIcon color={colors.gray80} />
                  {t('modification_requests_drawer.approve')}
                </button>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};
