import { AMPLITUDE_SERVICE } from '../../analytics/constants';
import { getSessionTokenData } from '../../utils/userUtils';
import { setProps } from '../../redux/slices/projectSlice';

export const destroyGantt = (ganttName) => {
  const gantt = window[ganttName];
  if (!gantt) {
    return;
  }

  gantt.removeEvents();
  gantt.destructor();
  window[ganttName] = undefined;
};

export const trackFilterEvent = (
  tracking,
  companyId,
  filteredProjectsNumber
) => {
  const userData = getSessionTokenData();
  if (!companyId || !userData) {
    return;
  }

  tracking.trackingEvent(
    'home_timeline_filter_application',
    {
      filterType: 'Company',
      company_id: companyId,
      projects_filtered: filteredProjectsNumber,
      user_role: userData.role
    },
    AMPLITUDE_SERVICE
  );
};

export const openForm =
  ({ permissions, handler, handlerName, dispatch, projectState }) =>
  (e, project) => {
    if (permissions.project !== 'AC') {
      return;
    }

    if (e) {
      e.stopPropagation();
    }

    const props = {
      setFormDeleteVisible: {
        ...projectState.props,
        modalDeleteVisible: true,
        currentProject: project
      },
      setFormProjectVisible: {
        ...projectState.props,
        currentProjectId: project.id,
        openFormProject: true
      }
    };

    handler(true);
    dispatch(setProps(props[handlerName]));
  };
