/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
class PluginService {
  constructor() {
    this.http = createHttpClient();
  }

  async index() {
    const res = await this.http.get('plugins').catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async show(pluginId) {
    const res = await this.http.get('plugins/' + pluginId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }
}
export const pluginService = new PluginService();
