import React, { useState } from 'react';

import { splitText } from '../../../ActivitiesUnitsGrid/index.helper';
import '../../index.css';
import reTaktIcon from '../../../../assets/img/reprogramtakt.png';
import { Popover, Tooltip } from 'antd';
/**
 * This component is the rendered fixed col row, actually activities col (to easy understand)
 * @param {*} data all activities in lineal tree structure
 * @param {*} width width for this col
 */
export const FixedColActivitiesRowGroupped =
  (data, width, viewFontSize = 10, height) =>
  ({ columnIndex, rowIndex, style }) => {
    const grouppedActivity = data[rowIndex];

    let extraHeight = 0;
    if (grouppedActivity.maxHeight > height) {
      extraHeight = 300;
    }

    return (
      <div className="fixed-col-activity-with-units-matrix" style={style}>
        <div
          style={{ textAlign: 'center', fontSize: viewFontSize }}
          className={'col-fixed-row-matrix retakt-btn'}>
          <span className="activities-view-takt">
            {splitText(grouppedActivity.name, width + extraHeight)}
          </span>
        </div>
      </div>
    );
  };
