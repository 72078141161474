import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useLayoutEffect
} from 'react';
import { FixedColActivitiesRowGroupped } from './modules/fixedActivitiesColumn';
import { GridCell } from './modules/cells';
import { Row, Col, Spin, Icon, Modal, Button } from 'antd';

import { FixedSizeGrid as Grid } from 'react-window';
import { VariableSizeGrid } from 'react-window';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {
  FixedDayHeaderColumn,
  FixedMonthHeaderColumn,
  FixedYearHeaderColumn
} from './modules/fixedDatesColumn';

import moment from 'moment';
import { colors } from './index.helper';
import TaktPreviewVisualization from '../TaktPreviewVisualization';
import { transformHourToDays } from '../../views/ganttContainer/gantt/gantt.helper';

import cloneDeep from 'lodash/cloneDeep';
import { rescheduleTaktService } from '../../services/rescheduletakt.service';
import { notifyMessage } from '../../utils/lookahead-common';
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

export default function TaktVisualization(props) {
  const {
    onDoubleClickTask,
    data,
    taktRelations,
    groupByActivity,
    ganttAPI,
    scrollTaktPosition,
    setScrollTaktPosition,
    t,
    updateRender
  } = props;
  const [loading, setLoading] = useState(true);
  const [loadingEnty, setLoadingEnty] = useState(true);
  const [loadingEntyText, setLoadingEntyText] = useState(false);

  const staticCol = useRef(null);
  const tableRef = useRef(null);
  const fixedDayHeaderRef = useRef(null);
  const fixedMonthHeaderRef = useRef(null);
  const fixedYearHeaderRef = useRef(null);
  const headerCol = useRef(null);

  const [yearAxis, setYearsAxis] = useState([]);
  const [monthAxis, setMonthAxis] = useState([]);

  /** Set of groupped activities */
  const [grouppedRows, setGrouppedRows] = useState([]);

  /** All activities tasks that belongs to takt planning */
  const [tasks, setTasks] = useState([]);

  /** Dates in X axis sorted from task dates */
  const [gridCols, setGridCols] = useState([]);

  const [locationColorsHash, setLocationColorsHash] = useState([]);

  const [fixedColWidth, setFixedColWidth] = useState(150);
  const [gridHeight, setGridHeight] = useState(0);

  const { height, width } = useWindowDimensions();

  const originalWidth = width > 1700 ? width * 0.85 : width * 0.83;
  const [gridWidth, setGridWidth] = useState(originalWidth);
  const [autoWidthTableContainer, setAutoWidthTableContainer] =
    useState(originalWidth);

  /** Width of each col rendered in the grid */
  const gridColWidth = originalWidth;

  /** Headers (Unit, Location and Structure height) */
  const gridHeaderHeight = 20;

  /** Height for fixed col activities and grid rows */
  const activitiesColHeight = 35;

  /** Header dates singel col width, and single grid col width */
  const defaultHeaderWidth = width * 0.04;

  /** Font size for whole view */
  const viewFontSize = 10;

  const [allReSchedule, setAllReSchedule] = useState([]);

  const getReSchedule = async () => {
    const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
    const res = await rescheduleTaktService.getRescheduleBySector(
      currentSector.id
    );
  };

  useEffect(() => {
    getReSchedule();
  }, []);

  useEffect(() => {
    if (fixedDayHeaderRef.current) {
      fixedDayHeaderRef.current.scrollTo({
        scrollTop: 0,
        scrollLeft: scrollTaktPosition.left
      });
    }
    if (fixedMonthHeaderRef.current) {
      fixedMonthHeaderRef.current.scrollTo({
        scrollTop: 0,
        scrollLeft: scrollTaktPosition.left
      });
    }
    if (fixedYearHeaderRef.current) {
      fixedYearHeaderRef.current.scrollTo({
        scrollTop: 0,
        scrollLeft: scrollTaktPosition.left
      });
    }

    if (staticCol.current) {
      staticCol.current.scrollTo({
        scrollTop: scrollTaktPosition.top,
        scrollLeft: 0
      });
    }

    if (tableRef.current) {
      tableRef.current.scrollTo({
        scrollTop: scrollTaktPosition.top,
        scrollLeft: scrollTaktPosition.left
      });
    }
  }, [tableRef, loading]);

  useEffect(() => {
    if (tableRef.current) {
      setScrollTaktPosition({
        top: tableRef.current.state.scrollTop,
        left: tableRef.current.state.scrollLeft
      });
    }
  }, [data]);

  useEffect(() => {
    setGridHeight(height - 300);
  }, [height]);

  useEffect(() => {
    setGridWidth(width > 1700 ? width * 0.86 : width * 0.83);
    setFixedColWidth(width * 0.08);
  }, [width]);

  useEffect(() => {
    headerCol.current &&
      setAutoWidthTableContainer(() => {
        const divider = width >= 3744 ? 2 : 3;
        return headerCol.current.clientWidth - fixedColWidth / divider;
      });
  }, [headerCol.current?.clientWidth]);

  useEffect(() => {
    if (gridHeight) {
      if (tableRef.current && tableRef.current.resetAfterColumnIndex) {
        tableRef.current.resetAfterColumnIndex(0);
        tableRef.current.resetAfterRowIndex(0);
      }
    }
  }, [gridHeight]);

  useEffect(() => {
    if (staticCol.current) {
      if (staticCol.current.resetAfterColumnIndex) {
        staticCol.current.resetAfterColumnIndex(0);
      }

      if (staticCol.current.resetAfterRowIndex) {
        staticCol.current.resetAfterRowIndex(0);
      }
    }
  }, [fixedColWidth]);

  useEffect(() => {
    setLoading(true);
    const linealActivitiesArray = [];
    treeToLinealArray(data, linealActivitiesArray, 0);

    if (groupByActivity) {
      const grouppedActivities = groupActivitiesByName(linealActivitiesArray);
      setGrouppedRows(grouppedActivities);
    } else {
      setGrouppedRows(linealActivitiesArray);
    }
  }, [data]);

  /** Once activities has been groupped, is necessary to find fitted tasks through each one by taking takt relation */
  useEffect(() => {
    if (grouppedRows.length) {
      const tasksFromTakt = [];
      if (groupByActivity) {
        /** Map all groupped activities to check their activities array attribute */
        grouppedRows.map((activity) => {
          if (activity.activities) {
            /** Then if does exist, map every single activity */
            activity.activities.map((singleAc) => {
              if (singleAc.tasks) {
                /** From this map, we get each single task */
                singleAc.tasks.map((task) => {
                  /** And verify if this task is in some relation at takt relations taht belongs to current sector */
                  const doesExistAtTaktRelations = taktRelations.find(
                    (relation) => relation.taskId == task.id
                  );
                  if (doesExistAtTaktRelations) {
                    setLoadingEnty(false);
                    /** Finally if it does, the object reference is saved to task object and pushed to the final array */
                    task.productionUnitRelation = doesExistAtTaktRelations;
                    task.calendarId = singleAc.calendarId;
                    task.activityReference = singleAc;
                    tasksFromTakt.push(task);
                  }
                });
              }
            });
          }
        });
      } else {
        grouppedRows.map((groupped) => {
          const singleAc = groupped;
          if (singleAc.tasks) {
            /** From this map, we get each single task */
            singleAc.tasks.map((task) => {
              /** And verify if this task is in some relation at takt relations taht belongs to current sector */
              const doesExistAtTaktRelations = taktRelations.find(
                (relation) => relation.taskId == task.id
              );

              if (doesExistAtTaktRelations) {
                setLoadingEnty(false);
                /** Finally if it does, the object reference is saved to task object and pushed to the final array */
                task.productionUnitRelation = doesExistAtTaktRelations;
                task.calendarId = singleAc.calendarId;
                task.activityReference = singleAc;
                tasksFromTakt.push(task);
              }
            });
          }
        });
      }
      setTasks(tasksFromTakt);
      if (loadingEnty) {
        setLoadingEntyText(true);
      }
      //
    }
  }, [grouppedRows]);

  /**
   * This functions creates from a sorted dates array an iteration without jumps between dates to final date from sorted array
   * @param {*} sortedArray Sorted array from early to lastest
   */
  const createDaysArrayFromSortedDates = (sortedArray) => {
    let allDatesFilled = [];
    const initialDate = sortedArray[0];
    const finalDate = moment(sortedArray[sortedArray.length - 1]);

    let currentDate = initialDate;
    while (true) {
      allDatesFilled.push(currentDate);
      const instanceMoment = moment(currentDate);
      allDatesFilled.push(instanceMoment.format('YYYY/MM/DD'));
      instanceMoment.add(1, 'days');
      currentDate = instanceMoment.format('YYYY/MM/DD');

      if (instanceMoment > finalDate) {
        break;
      }
    }

    allDatesFilled = removeDups(allDatesFilled);
    return allDatesFilled;
  };

  /** Once tasks are defined, there is time to create X axis with dates */
  useEffect(() => {
    if (tasks.length) {
      /** Create two vars for dates from tasks and location array with locations */
      let allDates = [];
      const locationHash = [];

      /** Then mapping tasks */
      tasks.map((task) => {
        /** Skipping those ones that dont fit in filters */
        if (!task.hide) {
          if (typeof task.start_date !== 'string') {
            task.start_date = moment(task.start_date).format('YYYY/MM/DD H:mm');
          }

          if (typeof task.end_date !== 'string') {
            task.end_date = moment(task.end_date).format('YYYY/MM/DD H:mm');
          }

          allDates.push(task.start_date /** .split(' ')[0] */);
          allDates.push(task.end_date /** .split(' ')[0] */);

          /** And mapping it location, and checking if it is already in the array */
          const locationId =
            task.productionUnitRelation.productionunit.locationId;
          const doesExist = locationHash.find((el) => el.id == locationId);
          if (!doesExist) {
            locationHash.push({
              id: locationId,
              color: ''
            });
          }
        }
      });
      //

      /** Once we get the locations array, we map it, and assign hardcoded color */
      locationHash.map((el, index) => {
        if (colors[index]) {
          el.color = colors[index];
        }
      });

      /** Set state with locations array and their objects with color */
      setLocationColorsHash(locationHash);

      /** Probably there are some tasks with same tasks, that's why we remove duplicates from the all dates array */
      allDates = removeDups(allDates);

      /** We sort the array */
      const sortedArray = allDates.sort(
        (a, b) => moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD')
      );

      const allDaysArray = createDaysArrayFromSortedDates(sortedArray);

      /** Then we need to check those elements that are workable, and which not */
      let workableDates = [];
      allDaysArray
        .filter((d) => d != 'undefined')
        .map((date) => {
          if (date) {
            if (ganttAPI.isWorkable(date)) {
              workableDates.push({
                value: date.split(' ')[0],
                workable: true
              });
            } else {
              workableDates.push({
                value: date.split(' ')[0],
                workable: false
              });
            }
          }
        });

      workableDates = removeDuplicates(workableDates, 'value');
      setGridCols(workableDates);
    }
  }, [tasks]);

  /** Once gridcols (dates array) is setted, we must create month and year array to static headers */
  useEffect(() => {
    /** months */
    const auxMonthAxis = [];
    let insidenceCounter = 0;
    let lastMonth;
    let lastYear;

    gridCols.map((date, index) => {
      const month = date.value.split('/')[1];
      const year = date.value.split('/')[0];
      if (!index) lastMonth = month;
      if (!index) lastYear = year;

      if (month != lastMonth) {
        const newMonth = {
          value: lastMonth,
          incidences: insidenceCounter,
          year: lastYear
        };

        auxMonthAxis.push(newMonth);

        insidenceCounter = 1;
        lastMonth = month;
      } else if (month == lastMonth) {
        insidenceCounter += 1;
        lastMonth = month;
        if (index == gridCols.length - 1) {
          const newMonth = {
            value: lastMonth,
            incidences: insidenceCounter,
            year: lastYear
          };
          auxMonthAxis.push(newMonth);
        }
      }
      lastYear = year;
    });

    auxMonthAxis.map((month) => {
      const commonWidth =
        gridColWidth / gridCols.length > defaultHeaderWidth
          ? gridColWidth / gridCols.length
          : defaultHeaderWidth;
      month.width = month.incidences * commonWidth;
    });

    setMonthAxis(auxMonthAxis);
  }, [gridCols]);

  useEffect(() => {
    const auxYearAxis = [];
    let lastYearRef;
    let totalWidthForYear = 0;
    monthAxis.map((month, index) => {
      const currentYear = month.year;
      if (!index) lastYearRef = currentYear;

      if (currentYear != lastYearRef) {
        const newYear = {
          value: lastYearRef,
          width: totalWidthForYear
        };

        auxYearAxis.push(newYear);

        totalWidthForYear = month.width;
        lastYearRef = currentYear;
      } else if (currentYear == lastYearRef) {
        totalWidthForYear += month.width;
        lastYearRef = currentYear;
        if (index == monthAxis.length - 1) {
          const newYear = {
            value: lastYearRef,
            width: totalWidthForYear
          };
          auxYearAxis.push(newYear);
        }
      }
    });

    setYearsAxis(auxYearAxis);
  }, [monthAxis]);

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      if (staticCol.current) {
        grouppedRows.map((a, b) => {
          staticCol.current.resetAfterRowIndex(b);
        });
      }
    }, 350);
    window.ASD = tableRef;
  }, [gridCols]);

  const removeDups = (array) => {
    const unique = {};
    array.forEach((i) => {
      if (!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  };

  function removeDuplicates(array, key) {
    const lookup = {};
    array.forEach((element) => {
      lookup[element[key]] = element;
    });
    return Object.keys(lookup).map((key) => lookup[key]);
  }

  const groupActivitiesByName = (linealActivitiesArray) => {
    const grouppedActivitiesArray = [];
    const activitiesWithChild = linealActivitiesArray.filter(
      (ac) => !ac.has_childs
    );
    activitiesWithChild.map((ac) => {
      ac.name = ac.name.trim();
      const doesExistAtMap = grouppedActivitiesArray.find(
        (act) => act.name.trim() == ac.name
      );
      if (ac.tasks.length) {
        if (doesExistAtMap) {
          doesExistAtMap.activities.push(ac);
        } else {
          grouppedActivitiesArray.push({
            name: ac.name,
            activities: [ac]
          });
        }
      }
    });
    return grouppedActivitiesArray;
  };

  /**
   * This function goes deeply on a tree data structure and transforms it to an lineal array in the same structure
   * @param {*} tree Array with tree format
   * @param {*} finalJsxArray empty array to push deeply data
   * @param {*} lvl 0 on start to deeply get level for elements
   */
  const treeToLinealArray = (tree, finalJsxArray, lvl) => {
    tree.map((element) => {
      const hasChilds = element.children;
      element.lvl = lvl;
      finalJsxArray.push(element);
      if (hasChilds) {
        if (hasChilds.length) {
          treeToLinealArray(hasChilds, finalJsxArray, lvl + 1);
        }
      }
    });
  };

  /**
   * This function updates the scroll reference on all static virtualized elements, taking the grid movements
   */
  const onScroll = useCallback((all) => {
    const { scrollLeft, scrollTop, scrollUpdateWasRequested } = all;
    if (!scrollUpdateWasRequested) {
      staticCol.current.scrollTo({ scrollLeft: 0, scrollTop });
      if (fixedDayHeaderRef.current) {
        fixedDayHeaderRef.current.scrollTo({ scrollTop: 0, scrollLeft });
      }
      if (fixedMonthHeaderRef.current) {
        fixedMonthHeaderRef.current.scrollTo({ scrollTop: 0, scrollLeft });
      }
      if (fixedYearHeaderRef.current) {
        fixedYearHeaderRef.current.scrollTo({ scrollTop: 0, scrollLeft });
      }
    }
  });

  /** This method defines row height through defining whihc tasks of all tasks universe fits in this col parameter */
  const defineRowHeight = (grouppedActivity, index) => {
    grouppedActivity.Xaxis = [];

    let maxNumberOfTaskPerRow = 0;

    for (let i = 0; i < gridCols.length; i++) {
      const date = gridCols[i].value;

      /** We must find tasks that belongs to this date range */

      let tasksFromThisActivityGroup = [];

      if (groupByActivity) {
        if (!grouppedActivity.activities) return <div></div>;
        grouppedActivity.activities.map((singleAc) => {
          const tasksForThisSingleAc = tasks.filter(
            (task) => task.activityId == singleAc.id
          );
          tasksFromThisActivityGroup = [
            ...tasksFromThisActivityGroup,
            ...tasksForThisSingleAc
          ];
        });
      } else {
        tasksFromThisActivityGroup = grouppedActivity.tasks;
      }

      /** Finally this are the tasks that fit in this date */
      if (!tasksFromThisActivityGroup) return <div></div>;
      const fitTasks = tasksFromThisActivityGroup.filter((task) => {
        const startMoment = moment(task.start_date.split(' ')[0]);
        const endMoment = moment(task.end_date.split(' ')[0]);
        const dateMoment = moment(date);
        if (dateMoment >= startMoment && dateMoment <= endMoment) {
          return true;
        }
      });
      grouppedActivity.Xaxis.push(fitTasks);
      if (fitTasks.length > maxNumberOfTaskPerRow) {
        maxNumberOfTaskPerRow = fitTasks.length;
      }
    }
    grouppedActivity.maxHeight = maxNumberOfTaskPerRow * activitiesColHeight;
    grouppedActivity.maxNumberOfTaskPerRow = maxNumberOfTaskPerRow;
    if (staticCol.current) {
      staticCol.current.resetAfterRowIndex(index);
    }

    return maxNumberOfTaskPerRow * activitiesColHeight || activitiesColHeight;
  };

  const renderTableVirtualized = () => (
    <VariableSizeGrid
      ref={tableRef}
      onScroll={onScroll}
      className="GridMatrix"
      columnCount={gridCols.length} /** Units (not locations!!) */
      columnWidth={() =>
        gridColWidth / gridCols.length > defaultHeaderWidth
          ? gridColWidth / gridCols.length
          : defaultHeaderWidth
      }
      height={gridHeight}
      rowCount={grouppedRows.filter((ac) => !ac.hide).length} /** Activities */
      rowHeight={(index) => defineRowHeight(grouppedRows[index], index)}
      width={autoWidthTableContainer}>
      {GridCell(
        grouppedRows.filter((ac) => !ac.hide),
        locationColorsHash,
        groupByActivity,
        onDoubleClickTask,
        viewFontSize,
        gridCols,
        ganttAPI
      )}
    </VariableSizeGrid>
  );

  const goToTaktPlanning = () => {
    props.history.push('/taktplanning/units');
  };

  const [modalPreview, setModalPreview] = useState({
    visible: false,
    data: [],
    nPerWeek: 0,
    dateFromReSchedule: ''
  });

  const reSchedule = (activity, nPerWeek, dateFromReSchedule) => {
    const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
    const copyOf = cloneDeep(activity);
    copyOf.activities.map((singleAc, index) => {
      const tasks = singleAc.tasks.filter((t) => {
        const instancedStart = new Date(
          moment(t.start_date).format('YYYY/MM/DD')
        );
        const instancedFromReSchedule = new Date(
          dateFromReSchedule.format('YYYY/MM/DD')
        );
        if (instancedStart.getTime() >= instancedFromReSchedule.getTime()) {
          return true;
        }
        return false;
      });

      /** Then we need to know number of weeks */
      const durationPerUnitInHour = Math.trunc(
        currentSector.hoursPerWeek / nPerWeek
      );

      let lastEnd;
      let lessDayAcum = 0;
      let weekHoursCounter = 0;
      let assignedUnitCounter = 0;
      tasks.map((t) => {
        let newEnd;
        if (!lastEnd) {
          newEnd = ganttAPI.getEndByDuration(
            t.start_date,
            transformHourToDays(durationPerUnitInHour),
            t.id,
            singleAc.calendarId
          );
        } else {
          newEnd = ganttAPI.getEndByDuration(
            lastEnd,
            transformHourToDays(durationPerUnitInHour),
            t.id,
            singleAc.calendarId
          );
          t.start_date = moment(lastEnd).format('YYYY/MM/DD H:mm');
        }

        t.duration = transformHourToDays(newEnd.duration);
        t.end_date = moment(newEnd.end_date).format('YYYY/MM/DD H:mm');
        weekHoursCounter += durationPerUnitInHour;
        lessDayAcum += durationPerUnitInHour;
        assignedUnitCounter++;

        if (
          assignedUnitCounter == parseInt(nPerWeek) &&
          weekHoursCounter < parseInt(currentSector.hoursPerWeek)
        ) {
          const newHoursFit =
            parseInt(currentSector.hoursPerWeek - weekHoursCounter) /
            parseInt(currentSector.hoursPerDay);
          lastEnd = moment(newEnd.end_date)
            .add(newHoursFit * 24 + parseInt(currentSector.hoursPerDay), 'h')
            .format('YYYY/MM/DD H:mm');
          weekHoursCounter = 0;
          assignedUnitCounter = 0;
        } else if (
          durationPerUnitInHour >= currentSector.hoursPerDay &&
          weekHoursCounter >= currentSector.hoursPerWeek
        ) {
          lastEnd = moment(newEnd.end_date)
            .add(currentSector.hoursPerDay, 'h')
            .format('YYYY/MM/DD H:mm');
          weekHoursCounter = 0;
        } else if (
          durationPerUnitInHour < currentSector.hoursPerDay &&
          lessDayAcum >= currentSector.hoursPerDay
        ) {
          lastEnd = moment(newEnd.end_date)
            .add(currentSector.hoursPerDay, 'h')
            .format('YYYY/MM/DD H:mm');
          lessDayAcum = 0;
        } else if (
          lessDayAcum >= currentSector.hoursPerDay &&
          weekHoursCounter > currentSector.hoursPerWeek
        ) {
          lastEnd = moment(newEnd.end_date)
            .add(currentSector.hoursPerDay, 'h')
            .format('YYYY/MM/DD H:mm');
        } else if (durationPerUnitInHour == currentSector.hoursPerDay) {
          lastEnd = moment(newEnd.end_date)
            .add(currentSector.hoursPerDay, 'h')
            .format('YYYY/MM/DD H:mm');
        } else {
          lastEnd = t.end_date;
        }
      });
    });
    setModalPreview({
      ...modalPreview,
      visible: true,
      data: copyOf.activities,
      nPerWeek: nPerWeek,
      dateFromReSchedule: dateFromReSchedule.format('YYYY/MM/DD')
    });
  };

  const saveReSchedule = async () => {
    const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
    const currentUser = JSON.parse(localStorage.getItem('user'));
    let tasks = [];
    modalPreview.data.map((ac) => {
      tasks = [...tasks, ...ac.tasks];
    });
    tasks.map((t) => {
      delete t.activityReference;
      delete t.productionUnitRelation;
    });

    const reSchedule = {
      tasks,
      user_id: currentUser.id,
      apply_date: new Date(),
      nPerWeek: modalPreview.nPerWeek,
      sectorId: currentSector.id
    };
    const res = await rescheduleTaktService.saveReSchedule(reSchedule);

    if (res) {
      notifyMessage({
        title: t('reScheduleTakt.success'),
        message: t('reScheduleTakt.success_message'),
        type: 'success'
      });
      setModalPreview({
        visible: false,
        data: [],
        nPerWeek: 0,
        sectorId: currentSector.id
      });

      const activities = cloneDeep(data);
      modalPreview.data.map((newAc) => {
        const acOriginalRef = activities.find((ac) => ac.id == newAc.id);
        if (acOriginalRef) {
          acOriginalRef.tasks = newAc.tasks;
        }
      });
      updateRender(activities);

      // setLoading(true)
      // setTimeout(() => {
      //     window.location.reload(false);
      // }, 500)
    }
  };

  const renderModalPreviewSchedule = () => (
    <Modal
      wrapClassName="activity-modification-style"
      title={t('reScheduleTakt.previewText')}
      width={width * 0.8}
      visible={modalPreview.visible}
      onCancel={() => setModalPreview({ ...modalPreview, visible: false })}
      footer={[
        <Button
          onClick={() => saveReSchedule()}
          key="submit"
          style={{
            background: '#7DFF8A',
            color: '#121212',
            borderColor: '#7DFF8A'
          }}>
          {t('reScheduleTakt.save')}
        </Button>
      ]}>
      {modalPreview.visible ? (
        <TaktPreviewVisualization
          data={modalPreview.data}
          dateFromReSchedule={modalPreview.dateFromReSchedule}
          taktRelations={taktRelations}
          groupByActivity={groupByActivity}
          ganttAPI={ganttAPI}
          t={t}
        />
      ) : (
        <Spin className="loader-spinner-lookahead-table" indicator={antIcon} />
      )}
    </Modal>
  );

  const renderTaktVisualizaton = () => (
    <Row>
      <Col>
        <Row style={{ padding: 17 }}>
          <Col>
            {/** Fixed year header row */}
            <div
              style={{ marginLeft: fixedColWidth }}
              id="colHeader"
              ref={headerCol}>
              <VariableSizeGrid
                ref={fixedYearHeaderRef}
                style={{ overflowX: 'hidden', overflowY: 'hidden' }}
                className="GridStaticHeaderMatrix"
                columnCount={yearAxis.length}
                columnWidth={(index) => yearAxis[index].width}
                height={gridHeaderHeight}
                rowCount={1}
                rowHeight={(index) => gridHeaderHeight}
                width={autoWidthTableContainer}>
                {FixedYearHeaderColumn(
                  yearAxis,
                  gridColWidth / gridCols.length > defaultHeaderWidth
                    ? gridColWidth / gridCols.length
                    : defaultHeaderWidth,
                  viewFontSize
                )}
              </VariableSizeGrid>
            </div>

            {/** Fixed month header row */}
            <Row style={{ marginLeft: fixedColWidth }}>
              <span>
                <VariableSizeGrid
                  ref={fixedMonthHeaderRef}
                  style={{ overflowX: 'hidden', overflowY: 'hidden' }}
                  className="GridStaticHeaderMatrix"
                  columnCount={monthAxis.length}
                  columnWidth={(index) => monthAxis[index].width}
                  height={gridHeaderHeight}
                  rowCount={1}
                  rowHeight={(index) => gridHeaderHeight}
                  width={autoWidthTableContainer}>
                  {FixedMonthHeaderColumn(
                    monthAxis,
                    gridColWidth / gridCols.length > defaultHeaderWidth
                      ? gridColWidth / gridCols.length
                      : defaultHeaderWidth,
                    viewFontSize
                  )}
                </VariableSizeGrid>
              </span>
            </Row>

            {/** Fixed dates header row */}
            <Row style={{ marginLeft: fixedColWidth }}>
              <Grid
                ref={fixedDayHeaderRef}
                style={{ overflowX: 'hidden', overflowY: 'hidden' }}
                className="GridStaticHeaderMatrix"
                columnCount={gridCols.length}
                columnWidth={
                  gridColWidth / gridCols.length > defaultHeaderWidth
                    ? gridColWidth / gridCols.length
                    : defaultHeaderWidth
                }
                height={gridHeaderHeight}
                rowCount={1}
                rowHeight={gridHeaderHeight}
                width={autoWidthTableContainer}>
                {FixedDayHeaderColumn(
                  gridCols,
                  gridColWidth / gridCols.length > defaultHeaderWidth
                    ? gridColWidth / gridCols.length
                    : defaultHeaderWidth,
                  viewFontSize
                )}
              </Grid>
            </Row>

            {/** Fixed col and table */}
            <Row id="tableContainer">
              {/** Fixed Col activities */}
              <VariableSizeGrid
                ref={staticCol}
                style={{ overflowX: 'hidden', overflowY: 'hidden' }}
                className="GridStaticColMatrix"
                columnCount={1}
                columnWidth={() => fixedColWidth}
                height={gridHeight}
                rowCount={grouppedRows.filter((ac) => !ac.hide).length}
                rowHeight={(index) =>
                  grouppedRows[index].maxHeight || activitiesColHeight
                }
                width={fixedColWidth}>
                {FixedColActivitiesRowGroupped(
                  grouppedRows.filter((ac) => !ac.hide),
                  fixedColWidth,
                  viewFontSize,
                  activitiesColHeight,
                  reSchedule,
                  t
                )}
              </VariableSizeGrid>
              {/** Table */}
              {renderTableVirtualized()}
            </Row>
          </Col>
        </Row>
      </Col>
      {renderModalPreviewSchedule()}
    </Row>
  );

  const render = () => {
    if (!loading) {
      if (!loadingEnty) {
        return renderTaktVisualizaton();
      }
      return (
        <Row
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '100px'
          }}>
          {loadingEntyText ? (
            <h1
              style={{
                width: '100%',
                textAlign: 'center'
              }}>
              {t('takt_text_holder')}{' '}
              <strong
                style={{ color: '#1890ff', cursor: 'pointer' }}
                onClick={() => {
                  const itemMenuLookahead = document.querySelector(
                    '#menu>li:nth-child(2)'
                  );
                  const itemMenuTakt = document.querySelector(
                    '#menu>li:nth-child(4)'
                  );
                  itemMenuLookahead.classList.remove('ant-menu-item-selected');
                  itemMenuTakt.classList.add('ant-menu-item-selected');
                  goToTaktPlanning();
                }}>
                {t('takt_text_holder_link')}
              </strong>{' '}
            </h1>
          ) : (
            <Spin
              className="loader-spinner-lookahead-table"
              indicator={antIcon}
            />
          )}
        </Row>
      );
    }
    return (
      <Spin className="loader-spinner-lookahead-table" indicator={antIcon} />
    );
  };

  return render();
}
