import React, { useEffect } from 'react';
import EventEmitter from 'react-native-eventemitter';
import WeekReportCommitClosed from '../../../components/Report/WeeReportCommitClose';
import { useParams } from 'react-router-dom';

export default function WeekReportCommitClose(props) {
  const { week, projectId, sectorId, planificationday } = useParams();
  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);
  // <General/>
  return (
    <div style={{ background: '#FFFFFF' }}>
      <WeekReportCommitClosed
        week={week}
        sectorId={sectorId}
        projectId={projectId}
        planificationday={planificationday == 0 ? -1 : planificationday - 1}
      />
    </div>
  );
}
