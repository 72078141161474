import React, { useState, useEffect } from 'react';
import EventEmitter from 'react-native-eventemitter';
import { Redirect, useParams } from 'react-router-dom';

import withRouteToolbar from '../../hocs/withRouteToolbar.hoc';
import MainRouteHome from '../../components/MainRouteHome';
import * as projectActions from '../../redux/slices/projectSlice';
import { Animated } from 'react-animated-css';
import useMasterplanPermissions from '../../hooks/useMasterplanPermissions';
import { withTranslation } from 'react-i18next';
/** View icon */
import masterPlanIcon from '../../assets/img/modules/planning.svg';
import { ganttService } from '../../services';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Icon, message } from 'antd';
import {
  totangoEventTracking,
  totangoSetAccountAttributes
} from '../../analytics/implements/totango';
import { trackingEvent } from '../../analytics/index';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';
import { getSignedUser } from '../../utils/userUtils';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

function MasterPlanView(props) {
  const { t } = props;
  const projectState = useSelector((state) => state.projectState);
  const dispatch = useDispatch();
  const [ganttObject, setGanttObject] = useState({
    activities: 0,
    activities_relation_size: 0,
    is_impoorter: false,
    sectorId: projectState.sectorSelected
  });
  const [loading, setLoading] = useState(true);
  const masterplanPermissions = useMasterplanPermissions();
  const stateCompany = useSelector((state) => state.companyState);

  useEffect(() => {
    const loggedUser = getSignedUser();
    const currentCompany = stateCompany.currentCompany;
    const project = projectState.allProjects.find(
      (p) => p.id == projectState.projectSelected
    );

    totangoSetAccountAttributes(
      loggedUser,
      projectState.projectSelected,
      currentCompany?.name,
      currentCompany?.id,
      project?.name,
      project?.stage,
      project?.country
    );

    totangoEventTracking(
      `p_${projectState.projectSelected}`,
      loggedUser,
      'Project Init',
      'General'
    );
  }, []);

  const menuMetaData = {
    title: t('master_plan.welcome_master'),
    subTitle: t('master_plan.start_master'),
    buttons: [
      {
        btnTitle: t('master_plan.import_gantt'),
        onClick: (params) => {
          const currentSector = JSON.parse(
            sessionStorage.getItem('currentSector')
          );
          const currentProject = JSON.parse(
            sessionStorage.getItem('currentProject')
          );
          const currentCompany = JSON.parse(sessionStorage.getItem('company'));

          trackingEvent(
            'start_schedule_creation',
            {
              event_source: 'import_schedule',
              company_name: currentCompany?.name,
              company_id: currentCompany?.id,
              project_name: currentProject?.name,
              project_id: currentProject?.id,
              stage_name: currentSector?.name,
              stage_id: currentSector?.id
            },
            AMPLITUDE_SERVICE
          );

          props.history.push('/importgantt');
        }
      },
      /* {
                btnTitle: 'Start from a Template',
                onClick: (params) => {
                    props.history.push('/creategantt/template')
                }
            }, */
      {
        btnTitle: t('master_plan.initial_start'),
        onClick: async (params) => {
          const currentSector = JSON.parse(
            sessionStorage.getItem('currentSector')
          );
          const currentProject = JSON.parse(
            sessionStorage.getItem('currentProject')
          );
          const currentCompany = JSON.parse(sessionStorage.getItem('company'));

          trackingEvent(
            'start_schedule_creation',
            {
              event_source: 'from_scratch',
              company_name: currentCompany?.name,
              company_id: currentCompany?.id,
              project_name: currentProject?.name,
              project_id: currentProject?.id,
              stage_name: currentSector?.name,
              stage_id: currentSector?.id
            },
            AMPLITUDE_SERVICE
          );

          const ganttRes = await ganttService.create(ganttObject);
          props.history.push({
            pathname: '/gantt/' + ganttRes.sectorId + '/ganttchart',
            state: {}
          });
        }
      }
    ]
  };

  const FinishPage = withRouteToolbar({
    ...props,
    route: {
      icon: masterPlanIcon,
      title: t('master_plan.master_plan'),
      breadcrumb: t('master_plan.master_plan')
    },
    menu: menuMetaData
  })(MainRouteHome);

  /** Similar to did mount */
  useEffect(() => {
    checkGanttCreated();
  }, [projectState.sectorSelected]);

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  const checkGanttCreated = async () => {
    const res = await ganttService.index();
    if (res) {
      if (res.gantts) {
        const filtered = res.gantts.filter(
          (gantt) => gantt.sectorId == projectState.sectorSelected
        );

        if (!projectState.sectorSelected) {
          props.history.goBack();
        } else if (filtered.length != 0) {
          props.history.push({
            pathname: '/gantt/' + projectState.sectorSelected + '/ganttchart',
            state: {}
          });
        } else {
          setLoading(false);
        }
      }
    }
  };

  // redirects
  const signed = localStorage.getItem('signed');
  if (!signed) {
    return <Redirect to="/" />;
  }

  const renderFinishPage = () => {
    if (masterplanPermissions.gantt == 'AC') {
      return <FinishPage />;
    }
    return <div>{t('settings.not_permissions')}</div>;
  };

  return (
    <Animated animationIn="fadeIn" animationInDuration={500} isVisible={true}>
      {loading ? (
        <Spin className="loader-gantt-style" indicator={antIcon} />
      ) : (
        renderFinishPage()
      )}
    </Animated>
  );
}

export default withTranslation()(MasterPlanView);
