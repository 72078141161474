import React from 'react';
import { SvgIcon } from '../../../utils';
import PlusIconPath from '../../../assets/img/icons/icon--plus-project.svg';
import ArrowDownIconPath from '../../../assets/img/icons/icon--arrow-down-3.svg';
import photoIconPath from '../../../assets/img/icons/icon--photo.svg';
import cameraIconPath from '../../../assets/img/icons/icon--camera.svg';
import informationCirclePath from '../../../assets/img/icons/icon--information-circle.svg';
import deleteIconPath from '../../../assets/img/icons/icon--delete.svg';

export const ALERT_ICON_COLOR = '#F59D04';
export const MODAL_THEME = 'dark';
export const VALUE_MAX_HEIGHT = 438;
export const MODAL_WIDTH = 680;
export const MAX_LIST_SELECTS = 6;
export const ACTIVE = 'active';
export const DISABLED = 'disabled';
export const WHITE_COLOR = '#FFF';
export const GREY_COLOR = '#B3B3B3';
export const PLACEMENT_TOP = 'top';
export const ERROR = 'error';
export const ERROR_COLOR = '#FA7676';
export const INFO_ICON = (
  <SvgIcon path={informationCirclePath} color="#7D8671" />
);
export const PHOTO_DEFAULT_ICON = (
  <SvgIcon path={photoIconPath} color="#252B1D" />
);
export const CAMERA_ICON = <SvgIcon path={cameraIconPath} color="#121212" />;
export const PLUS_ICON = <SvgIcon path={PlusIconPath} color="#7DFF8A" />;
export const DELETE_ICON = <SvgIcon path={deleteIconPath} color="#FFF" />;

export const ARROW_ICON = (rotate) => (
  <SvgIcon
    path={ArrowDownIconPath}
    color="#7DFF8A"
    className={`${rotate ? 'rotate' : ''}`}
  />
);
