const setVisualizationAndColorsFilter = (
  visualization = {},
  defaultColorsFilter = {},
  gantt
) => {
  if (defaultColorsFilter.wbs) {
    gantt.enableColorsWBS();
    gantt.callEvent('updateCheckedWBS');
  }

  setGlobalVisualizationFlags(visualization, gantt);
  executeVisualizationFilters(gantt);

  if (defaultColorsFilter.bar) {
    gantt.changeVisualizationOption(defaultColorsFilter.bar);
  }
};

function setGlobalVisualizationFlags(visualization, gantt) {
  gantt.config.show_links = !visualization.areLinksVisible;
  gantt.config.highlight_critical_path = !visualization.isCriticalPathVisible;
  gantt.config.show_slack = !visualization.areSlackVisible;
  gantt.config.show_baseline = !visualization.areBaselineVisible;
  gantt.config.show_numtasks = !visualization.areNumtasksVisible;
  gantt.config.show_todayline = !visualization.isTodaylineVisible;
  gantt.config.show_submittal_icon = !visualization.areSubmittalsVisible;
}

function executeVisualizationFilters(gantt) {
  gantt.changeSubmittalsVisibility(false);
  gantt.changeLinksVisibility(false);
  gantt.changeSlackVisibility(false);
  gantt.changeTodaylineVisibility(false);
  gantt.changeBaselineVisibility(false);
  gantt.changeNumtasksVisibility(false);
}

export default setVisualizationAndColorsFilter;
