import React, { useEffect, useRef, useState } from 'react';
import { calculateProgress } from '../../../../../utils/lookahead-common';

import EditableInput from '../../../../EditableInput';
import { InputNumber, Tooltip } from 'antd';

import { activityService, taskService } from '../../../../../services';
import { historicalActivityProgressService } from '../../../../../services/historicalactivityprogress.service';
import useWeeklyplanPermissions from '../..../../../../../../hooks/useWeeklyplanPermissions';
import { useTranslation } from 'react-i18next';

import styles from './MaterialReal.module.scss';

/** test consts */
export const testIds = {
  RENDER_MATERIALREAL_CONTAINER: 'RENDER_MATERIALREAL_CONTAINER'
};
const TestWrapped = ({ children }) => (
  <span data-testid={testIds.RENDER_MATERIALREAL_CONTAINER}>{children}</span>
);

const MaterialRealColumn = (props) => {
  const {
    activity,
    task,
    isFloat = () => {},
    parentTask,
    resources = []
  } = props;

  const { t } = useTranslation();
  const readOnly = useWeeklyplanPermissions().plan === 'V';
  const [isEditing, setIsEditing] = useState(false);
  const [realMaterial, setRealMaterial] = useState(
    (task?.progress / 100) * task?.total_quantity || 0
  );

  const { current: currentRealMaterial } = useRef(realMaterial);
  const { current: materiaCommited } = useRef(
    task?.taskcommitments.length > 0
      ? task.taskcommitments[0].total_quantity
        ? ((task.taskcommitments[0].commitment_percentaje -
            task.taskcommitments[0].current_progress_task) /
            100) *
          task.taskcommitments[0].total_quantity
        : '-'
      : '0'
  );

  useEffect(() => {
    if (task?.actual_quantity && task.actual_quantity != realMaterial) {
      task.actual_quantity = realMaterial;
      updateAsyncTask(task);
    }
  }, []);

  const updateAsyncTask = async (task) => {
    const taskClone = { ...task };
    delete taskClone.activityObject;
    delete taskClone.taskcommitments;
    delete taskClone.activity;

    await taskService.update(taskClone);
  };

  const updateAsyncActivity = async (task) => {
    await activityService.update(activity);
    await activityService.updateTreeProgress(activity.id);
    const newActivityHistorical = {
      activityId: activity.id,
      progress: activity.progress
    };

    historicalActivityProgressService.create(newActivityHistorical);
  };

  const handleSave = (value) => {
    let newProgress = 0;
    task.total_quantity && (newProgress = 100 * (value / task.total_quantity));

    if (newProgress > 100) {
      task.progress = 100;
      task.actual_quantity = task.total_quantity;
    } else {
      task.progress = newProgress;
      task.actual_quantity = value;
    }

    updateAsyncTask(task);
    calculateProgress(
      null,
      parentTask || activity,
      activity,
      updateAsyncTask,
      updateAsyncActivity
    );
  };

  return (
    <TestWrapped>
      <Tooltip
        title={
          readOnly ? (
            <div style={{ textAlign: 'center' }}>
              {t('not_permissions_actions')}
            </div>
          ) : null
        }>
        <span className={styles.commited}>
          {isFloat(materiaCommited) && materiaCommited != '-'
            ? materiaCommited.toFixed(2)
            : materiaCommited}{' '}
          /{' '}
        </span>
        <EditableInput
          t={(_) => {}}
          onEdit={setIsEditing}
          isEditing={isEditing}
          isFloat={isFloat(realMaterial)}
          index={1}
          column={{ name: 'actual_quantity' }}
          onSave={handleSave}
          round={false}
          symbol_suffix={
            resources.find((resource) => resource.id == task.materialId)
              ?.material_label
          }
          task={task}
          disabled={readOnly}
          renderEditable={(
            column,
            index,
            task,
            value,
            setValue,
            updateParentData = null,
            handleEsc = null
          ) => (
            <InputNumber
              onKeyDown={handleEsc}
              onPressEnter={updateParentData}
              isFloat={isFloat(realMaterial)}
              className="custom-input-number-planification ant-input-number-focuseda"
              min={0}
              name="real_material"
              precision={isFloat(realMaterial) ? 2 : 0}
              defaultValue={realMaterial}
              onChange={(e) => setValue(e)}
              onFocus={(e) => e.target.focus()}
            />
          )}
        />
      </Tooltip>
    </TestWrapped>
  );
};

export default MaterialRealColumn;
