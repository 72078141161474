import React, { createRef } from 'react';
import { Popover, Tooltip } from 'antd';
/** import library for format numbers */
import NumberFormat from 'react-number-format';
import IconComponent from '../Projects/IconSvg';
import { totalMaterialSvg } from '../../utils/svgIcons';

export const MassiveTotalMaterial = (props) => {
  const {
    t,
    massiveSelection,
    activities,
    getTask,
    getActivityAndParentReference,
    updateAsyncTask,
    handlePopVisibility,
    groupBy
  } = props;

  const refInputMasssiveTotal = createRef();

  /** on change massive total naterial */
  const massiveTotalMaterialHandle = async () => {
    const value = refInputMasssiveTotal.current.state.value;
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        activities.map(async (ac) => {
          const { activity, parentTask } = getActivityAndParentReference(
            selection.activityId,
            selection.parent_id
          );
          const doesExistAtReference = getTask(selection.id, null, activity);
          if (doesExistAtReference) {
            const reference = doesExistAtReference[0];
            if (!reference.children.length) {
              reference.total_quantity = Math.abs(value);
              updateAsyncTask(reference);
            }
          }
        });
      });
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            const reference = doesExistAtReference;
            reference.total_quantity = Math.abs(value);
            updateAsyncTask(reference);
          }
        });
      });
    }
    handlePopVisibility(false, 'totalMassive');
  };

  return (
    <span className="massive-icon-style">
      <Popover
        overlayClassName="massive-selection-pop"
        placement="bottom"
        content={
          <span className="progress-massive-style">
            <div style={{ textAlign: 'center', width: 111 }}>
              {t('tables.lookahead.resources.total_quantity')}
            </div>
            <div className="div-number-progress-massive">
              <NumberFormat
                ref={refInputMasssiveTotal}
                className="only-number-progress-massive"
                style={{ textAlign: 'center' }}
                defaultValue={0}
                autoComplete="off"
                displayType={'input'}
                autoFocus
                onFocus={(e) => e.target.select()}
              />
            </div>
            <div
              className="progress-massive-btn"
              onClick={massiveTotalMaterialHandle}>
              {t('apply_label')}
            </div>
          </span>
        }
        trigger="click">
        <Tooltip
          placement="top"
          title={t('tables.weeklyplan.plan.total_quantity')}>
          <span>
            <IconComponent
              data={totalMaterialSvg}
              fill="#fff"
              className="icon-totlamaterial-massive"
              style={{ position: 'relative', top: 4 }}
            />
          </span>
        </Tooltip>
      </Popover>
    </span>
  );
};
