import moment from 'moment';

import * as icons from '../../icons';
import { getElapsedTimeLabel } from '../../utils/dateUtils';
import colors from '../../stylesheets/variables.scss';
import i18n from '../../i18n';

/**
 * Returns an object with an icon component and color for a given status supplied as argument
 *
 * @param {string} status - The status whose icon details should be returned
 * @return {{ icon: Component, color: string }} - An object containing info about the icon for the supplied status
 */
export const getIconByStatus = (status) =>
  ({
    WAITING: {
      icon: icons.CircleMinusIcon,
      color: '#F59D04'
    },
    APPROVED: {
      icon: icons.CircleCheckIcon,
      color: colors.green
    },
    REJECTED: {
      icon: icons.CircleCancelIcon,
      color: colors.red
    }
  })[status?.toUpperCase()] ?? {};

/**
 * Returns info about the modifications along with i18n keys to be used in the UI
 *
 * @param {{
 *  originalStartDate,
 *  newStartDate,
 *  originalDuration,
 *  newDuration,
 *  originalEndDate,
 *  newEndDate
 * }} request - An object containing info about the performed modification
 * @return {Array} - An array with objects containing info about the performed modifications and a matching i18nKey to be used in the UI
 */
export const getModificationDetails = (request) => [
  {
    i18nKey: 'tables.gantt.start_date',
    originalValue: request.originalStartDateFormatted,
    newValue: request.newStartDateFormatted
  },
  {
    i18nKey: 'tables.gantt.duration',
    originalValue: request.originalDuration,
    newValue: request.newDuration
  },
  {
    i18nKey: 'tables.gantt.end_date',
    originalValue: request.originalEndDateFormatted,
    newValue: request.newEndDateFormatted
  }
];

/**
 * Gets the number of request by status
 *
 * @param {string} status - The status to count requests by
 * @return {Object} - An object with statuses and their respective quantities
 */
export const getQuantitiesByStatus = (requests) => {
  const getQuantity = (status) =>
    requests.filter((req) => req.status === status).length;

  const quantities = {
    WAITING: getQuantity('WAITING'),
    APPROVED: getQuantity('APPROVED'),
    REJECTED: getQuantity('REJECTED')
  };

  return {
    ...quantities,
    ALL: Object.values(quantities).reduce((a, b) => a + b, 0)
  };
};

/**
 * Maps a modification request object to a friendlier format
 *
 * @param {Object} request - The original request object
 * @return {Object} - The request mapped to the new format
 */
export const mapRequestToNewFormat = (request, dateFormat, selectedSector) => {
  const createdAt = new Date(request.createdAt);
  const activityId = Number(request.activity?.unique_id);

  return {
    activityId,
    id: request.id,
    activityCorrelativeId: request.activity?.correlative_id,
    status: request.state?.toUpperCase(),
    title: request.activity?.name,
    description: request.description,
    authorThumbUrl: request.userRequest?.image,
    authorEmail: request.userRequest?.email,
    authorName: request.userRequest?.name,
    authorLastname: request.userRequest?.lastname,
    elapsedTime: getElapsedTimeLabel(createdAt),
    date: moment(createdAt).format(dateFormat),
    originalStartDate: request.startOriginal,
    originalStartDateFormatted: formatActivityDate(
      request.startOriginal,
      dateFormat
    ),
    newStartDate: request.startNew,
    newStartDateFormatted: formatActivityDate(request.startNew, dateFormat),
    originalDuration: getDateDiffLabel(
      { start: request.startOriginal, end: request.endOriginal },
      activityId,
      selectedSector
    ),
    newDuration: getDateDiffLabel(
      mapDatesToSagaFormat(request.startNew, request.endNew, 'Y/M/D H:m:s'),
      activityId,
      selectedSector
    ),
    originalEndDate: request.endOriginal,
    originalEndDateFormatted: formatActivityDate(
      request.endOriginal,
      dateFormat
    ),
    newEndDate: request.endNew,
    newEndDateFormatted: formatActivityDate(request.endNew, dateFormat)
  };
};

/**
 * Maps the start and end dates to the format expected by the MR saga
 *
 * @param {string} startDate - The original start date
 * @param {string} endDate - The original end date
 * @param {string} dateFormat - The original date format
 * @return {Object} - Both dates mapped to the format expected by the MR saga
 */
export const mapDatesToSagaFormat = (startDate, endDate, dateFormat) => ({
  start: startDate,
  end: endDate
});

function getDateDiffLabel(dates, activityId, selectedSector) {
  if (!activityId || !selectedSector) return '';

  const parseDate = (date) => ganttInstance.date.parseDate(date, 'xml_date');

  const ganttInstance = getGanttInstance();
  let activity = ganttInstance.getTask(activityId);
  if (!activity) return;
  const calendar = ganttInstance.getCalendar(
    activity.calendar_id || ganttInstance.defaultCalendar
  );

  activity = Object.assign({}, activity, {
    constraint_type: 'asap',
    start_date: parseDate(dates.start),
    end_date: parseDate(dates.end)
  });

  const durationUnit = ganttInstance.config.duration_unit;
  const duration = calendar.calculateDuration(activity);
  const days = Number(
    (durationUnit === 'hour'
      ? duration / selectedSector.hoursPerDay
      : duration
    ).toFixed(2)
  );

  return `${days} ${days === 1 ? 'day' : 'days'}`;
}

const getGanttInstance = () => window.to_use_react_gantt;

const activityDateTimeFormat = 'YYYY/MM/DD H:mm:ss';

const hoursTargetFormat = (targetFormat) => {
  if (/hh:mm/i.test(targetFormat)) {
    return targetFormat.replace(/hh:mm/i, 'HH:mm');
  }

  return targetFormat;
};

function formatActivityDate(date, targetFormat) {
  return moment(date, activityDateTimeFormat).format(
    hoursTargetFormat(targetFormat)
  );
}
