import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { setAutoFreeze } from 'immer';

import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({}) ?? compose;
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));
const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga);

/**
 * ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌
 *
 * DISCLAIMER:
 * As part of the effort to make it simpler to work with Redux on ProPlanner and to
 * protect our state from unwanted mutations, the Gantt reducer (and its actions) was transformed
 * into a redux-toolkit slice. Unfortunately, there are many places where the Redux state is
 * being directly mutated in our codebase. This is wrong. The Redux-way of doing so is
 * via actions + reducers. As RTK uses Immer under the hood and its default behavior is to
 * freeze the state in order to protect it, there were many errors after this change.
 * In order to fix some of these errors, significant changes should be performed.
 * So, I'm disabling Immer's auto freeze here as a temporary solution.
 * The right solution is to refactor all places mutating the state and reenable this option.
 *
 * ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌
 */
setAutoFreeze(false);

export { store };
