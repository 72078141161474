import React, { useState } from 'react';
import * as Icons from '../../icons';

import { CloseOutlined } from '@ant-design/icons';
import styles from './autocomplete.module.scss';
import SuggestionList from './compnents/SuggestionsList';

const AutoComplete = (props) => {
  const {
    suggestions = [],
    showIconSearch = true,
    placeholder = '',
    setValue = (_) => {}
  } = props;

  const [activeSuggestion, setActiveSuggestion] = useState('');
  const [filteredSuggestions, setFitlerSuggestion] = useState([]);
  const [showSuggestions, setShowSuggestion] = useState(false);
  const [userInput, setUserInput] = useState('');

  const handleChange = (event) => {
    const userInput = event.target.value;

    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.value.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    userInput == '' && setValue(null);

    setFitlerSuggestion(filteredSuggestions);
    setShowSuggestion(true);
    setUserInput(userInput);
  };

  const handleClearSearch = (_) => {
    setUserInput('');
    setActiveSuggestion('');
    setValue(null);
  };

  const handleClick = (event) => {
    setFitlerSuggestion(filteredSuggestions);
    setShowSuggestion(false);
    setValue(
      suggestions.find(
        (suggestion) => suggestion.value == event.target.innerText
      )
    );
    setActiveSuggestion(
      suggestions.find(
        (suggestion) => suggestion.value == event.target.innerText
      )
    );
    setUserInput(event.target.innerText);
  };

  const handleKeyUp = (event) => {
    const keyCode = {
      13: (_) => {
        if (filteredSuggestions.length == 1) {
          setShowSuggestion(false);
          setUserInput(filteredSuggestions[0].value);
          setValue(filteredSuggestions[0]);
          setActiveSuggestion(filteredSuggestions[0]);
        }
      }
    };

    keyCode[event.keyCode] && keyCode[event.keyCode]();
  };

  return (
    <div className={styles.container}>
      <span className={styles['input-container']}>
        <input
          type="search"
          onChange={handleChange}
          onKeyDown={handleKeyUp}
          placeholder={placeholder}
          value={userInput}
        />
        {userInput == '' && showIconSearch && (
          <Icons.SearchIcon
            className={styles['icon-position']}
            color="#121212"
          />
        )}
        {userInput != '' && (
          <CloseOutlined
            className={styles['icon-position']}
            color="#121212"
            onClick={handleClearSearch}
          />
        )}
      </span>
      {showSuggestions && userInput && (
        <SuggestionList
          activeSuggestion={activeSuggestion}
          filteredSuggestions={filteredSuggestions}
          onClick={handleClick}
        />
      )}
    </div>
  );
};

export default AutoComplete;
