import { call, put, takeLatest } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';

import {
  calculatePonderators,
  calculateProgress,
  getTaskstoModifyProgress,
  nestTasks
} from '../../utils/lookahead-common';
import { taskService } from '../../services';
import {
  taskUpdateRequested,
  taskUpdateSucceeded,
  taskUpdateFailed,
  taskUpdateProgressRequested
} from '../slices/taskSlice';

export function* taskWatcherSaga() {
  yield takeLatest(taskUpdateRequested.type, taskUpdateRequestedWorkerSaga);
  yield takeLatest(
    taskUpdateProgressRequested.type,
    taskUpdateProgressRequestedWorkerSaga
  );
}

export function* taskUpdateRequestedWorkerSaga({
  payload: { task, onUpdated }
} = {}) {
  try {
    yield call(updateTask, task);
    if (onUpdated) yield call(onUpdated, task);

    yield put({ type: taskUpdateSucceeded.type });
  } catch (e) {
    yield put({ type: taskUpdateFailed.type });
  }
}

export function* taskUpdateProgressRequestedWorkerSaga({
  payload: { task, activity, progress, cardData, setCurrentCardData }
} = {}) {
  const serializedData = window.to_use_react_gantt.serialize().data;
  const serializedActivity = serializedData.find(
    (act) => act.proplannerId == activity.proplannerId
  );

  const activityCopy = yield call(cloneDeep, activity);
  activityCopy.start_date = serializedActivity.start_date;
  activityCopy.end_date = serializedActivity.end_date;
  activityCopy.constraint_date = serializedActivity.constraint_date;
  const taskCopy = yield call(cloneDeep, task);
  taskCopy.progress = progress;
  activityCopy.tasks.splice(
    activityCopy.tasks.findIndex((t) => t.id === task.id),
    1,
    taskCopy
  );
  activityCopy.id = activityCopy.proplannerId;
  activityCopy.tasks = nestTasks(activityCopy.tasks);

  if (progress === 0) {
    yield call(calculatePonderators, activityCopy);
  }

  yield call(calculateProgress, task, activityCopy, activityCopy);

  /** get task from gantt */
  const taskGantt = window.to_use_react_gantt.getTask(activity.unique_id);

  /** update row in gantt */
  taskGantt.progress = activityCopy.progress;

  /** update pogress task in card data object  */
  const tasksToModifyInActivity = getTaskstoModifyProgress(
    cardData.originalActivityObject.tasks,
    progress,
    task,
    'activity'
  );
  // const tasksToModifyInArrayTasks = getTaskstoModifyProgress(cardData.tasks, progress, task, 'task')

  /** Update General Object */
  setCurrentCardData({
    ...cardData,
    completion: activityCopy.progress,
    originalActivityObject: {
      ...cardData.originalActivityObject,
      tasks: tasksToModifyInActivity
    }
    // tasks: tasksToModifyInArrayTasks
  });

  /** update gantt */
  window.to_use_react_gantt.updateTask(taskGantt.id);
  window.to_use_react_gantt.render();
  window.to_use_react_gantt.updateActCard();

  yield call(updateTask, taskCopy);
}

export function* updateTask(task) {
  const taskCopy = yield call(cloneDeep, task);
  delete taskCopy.activityObject;
  delete taskCopy.activityReference;
  yield call([taskService, taskService.update], taskCopy);
}
