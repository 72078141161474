import { createSlice } from '@reduxjs/toolkit';

export const nonSerializableSliceKey = 'nonSerializableSlice';

/**
 * ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌
 *
 * DISCLAIMER:
 * There is a significant refactoring going on... And, sometimes, it's incredibly challenging
 * to make new code and legacy code properly work with each other.
 * During this refactoring, the application shared state is being progressively extracted to Redux slices
 * and side-effects are being moved to Redux sagas. However, many existing pieces of logic are
 * defined inside components and are tightly coupled to them, being quite hard to extract.
 * In order to make these pieces of logic available from outside the components they're defined in,
 * this slice was created. Its purpose is to store non-serializable items. In most cases, these
 * items will be function references.
 *
 * There is one issue, though: Non-serializable items shouldn't be stored in a Redux store.
 * By doing so, we compromise time-travel debugging and rehydration won't work properly.
 * The former problem is hard to tackle, but the latter is easily solved by disabling persisting
 * for this slice.
 * https://redux.js.org/faq/organizing-state#can-i-put-functions-promises-or-other-non-serializable-items-in-my-store-state
 *
 * THIS SLICE SHOULD BE TEMPORARY!! As soon as functions stored here are reimplemented in Redux sagas,
 * we're free to get rid of this code.
 *
 * ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌ ❌
 */

const initialState = {
  saveMasterplan: () => {}
};

const { actions, reducer } = createSlice({
  name: nonSerializableSliceKey,
  initialState,
  reducers: {
    setSaveMasterplanFunction(state, { payload }) {
      state.saveMasterplan = payload;
    }
  }
});

export function getSaveMasterplanSelector(state) {
  return state[nonSerializableSliceKey].saveMasterplan;
}

export const nonSerializableReducer = reducer;
export const { setSaveMasterplanFunction } = actions;
