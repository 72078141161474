import { validateEmail } from '../../utils';
import { projectService } from '../../services';
export const checkAllSelectsValid = (arrInvite, msgInvited) => {
  // build list of guests
  const guests = [];
  let elemTmp = {
    messageForInvitated: msgInvited
  };

  // validation all fields of form
  let allFieldsValid = true;
  arrInvite.map((x) => {
    if (x.email && x.email !== '') {
      elemTmp.email = x.email;
    }
    if (elemTmp.email && x.type) {
      elemTmp.role = x.type;
      guests.push(elemTmp);
      elemTmp = { messageForInvitated: msgInvited };
    }
    if (
      (x.email && !validateEmail(x.email)) ||
      (x.email && !x.type) ||
      (!x.email && x.type)
    ) {
      allFieldsValid = false;
    }
  });
  if (!guests.length) {
    allFieldsValid = false;
  }
  return { allFieldsValid, guests };
};

// Pre hooks
const n = 5; // number of guests by default
export const guestsTemplate = [...Array(n)].map((el, index) => ({
  ...el,
  id: index
}));

export async function asignProjectUser(data) {
  const resp = await projectService.asignProjectUser(data);
  return resp;
}
