import { all } from 'redux-saga/effects';

import { activityWatcherSaga } from './activitySaga';
import { modificationRequestsWatcherSaga } from './modificationRequestSaga';
import { taskWatcherSaga } from './taskSaga';

export default function* rootSaga() {
  yield all([
    activityWatcherSaga(),
    modificationRequestsWatcherSaga(),
    taskWatcherSaga()
  ]);
}
