import React from 'react';
import { DatePicker } from 'antd';

const DatePickerSystem = ({ theme = 'light', isError = false, ...props }) => (
  <DatePicker
    {...props}
    className={`datepicker-input-design-system theme--${theme} ${isError ? 'error' : ''}`}
    dropdownClassName={`datepicker-design-system theme--${theme}`}
  />
);

export default DatePickerSystem;
