export const SET_WEEKLYPLAN_ACTIVATED_COLS = 'SET_WEEKLYPLAN_ACTIVATED_COLS';
export const NOTIFY_LOOKAHEAD_UPDATE_WEEKLYPLAN =
  'NOTIFY_LOOKAHEAD_UPDATE_WEEKLYPLAN';
export const NOTIFY_LOOKAHEAD_UPDATE_ORDER_WEEKLYPLAN =
  'NOTIFY_LOOKAHEAD_UPDATE_ORDER_WEEKLYPLAN';
export const NOTIFY_LOOKAHEAD_UPDATE_FILTER_WEEKLYPLAN =
  'NOTIFY_LOOKAHEAD_UPDATE_FILTER_WEEKLYPLAN';
export const NOTIFY_LOOKAHEAD_UPDATE_GROUP_WEEKLYPLAN =
  'NOTIFY_LOOKAHEAD_UPDATE_GROUP_WEEKLYPLAN';

export const weeklyplanActions = {
  setActivatedColumns: (activatedColumns) => ({
    type: SET_WEEKLYPLAN_ACTIVATED_COLS,
    payload: activatedColumns
  }),
  notifyLookaheadUpdate: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_WEEKLYPLAN,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateOrder: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_ORDER_WEEKLYPLAN,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateFilter: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_FILTER_WEEKLYPLAN,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateGroup: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_GROUP_WEEKLYPLAN,
    payload: { boolean: true }
  })
};
