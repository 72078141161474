import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { sectorService } from '../services';
import moment from 'moment';
import { getTodayWeekWithPdayAndCurrentDay } from '../utils';
import {
  checkIfExistsWeeklyPlan,
  saveWeeklyPlan
} from '../views/weeklyPlan/weeklyPlan.helper';
import {
  calculateCommitmentPercentaje,
  calculateExpectedForWeek,
  checkMarkersByDates,
  initialStatus,
  updateTaskWeekly
} from '../utils/lookahead-common';

const DAYS_PER_WEEK = 7;

/**
 * Simple hook to have start and end of a weekly plan from a project (connected with redux to project and sector)
 * @param {*} hasCustomHours object {startHour, endHour} will be setted to the start and end given
 * @param {*} currentDate days representation of a week
 * @param {*} daysPerWeek days representation of a week
 * @returns {start, end, duration} Object which represent a task along the weekly range
 */
const useWeeklyplan = (
  hasCustomHours = false,
  currentDate = moment(),
  daysPerWeek = DAYS_PER_WEEK
) => {
  const [planificationDay, setPlanificationDay] = useState('');
  const [res, setRes] = useState(null);
  const [currentSector, setCurrentSector] = useState(null);
  const projectState = useSelector((state) => state.projectState);

  /** get Range according currentDate */
  const objectRange = getTodayWeekWithPdayAndCurrentDay(
    projectState,
    currentDate
  );
  const objectRangeData = objectRange[3];

  const getSectorData = async (_) => {
    const updatedSector = await sectorService.showByLooaheadWeekly(
      projectState.sectorSelected
    );
    setCurrentSector(updatedSector.sector);
  };

  useEffect(() => {
    const project = projectState?.allProjects?.find(
      (pr) => pr.id === projectState.projectSelected
    );
    if (project) {
      setPlanificationDay(project.planification_day);
    }
  }, [projectState.projectSelected]);

  useEffect(() => {
    getSectorData();
  }, [planificationDay]);

  useEffect(() => {
    if (!currentSector) return;

    const { startMarker, finalEndMarker } = checkMarkersByDates(
      planificationDay,
      objectRangeData,
      hasCustomHours
    );

    setRes({
      start_date: startMarker,
      end_date: finalEndMarker,
      range: objectRangeData,
      checkIfExistsWeeklyPlan,
      saveWeeklyPlan,
      calculateExpectedForWeek,
      updateTaskWeekly,
      initialStatus: initialStatus(startMarker, finalEndMarker),
      calculateCommitmentPercentaje
    });
  }, [currentSector]);

  return res;
};

export default useWeeklyplan;
