import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import * as Icons from '../../../icons';

import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import styles from './morefilters.module.scss';

const MoreFilters = (props) => {
  const {
    addFilter,
    filterSettings,
    setAddFilter,
    t,
    onClickMoreFilters = (_) => {}
  } = props;

  const { current: filterType } = useRef({
    image: null,
    icon: null,
    string: null
  });

  const [responsibles, setResponsibles] = useState([]);
  const [status, setStatus] = useState([]);
  const [subtrade, setSubtrade] = useState([]);
  const [tags, setTags] = useState([]);
  const [responsiblesChecked, setResponsiblesChecked] = useState([]);
  const [statusChecked, setStatusChecked] = useState([]);
  const [subtradeCheked, setSubtradeCkecked] = useState([]);
  const [tagsChecked, setTagsChecked] = useState([]);
  const [hideFilters, setHideFilters] = useState(false);

  useEffect(() => {
    onClickMoreFilters(hideFilters);
  }, [hideFilters]);

  useEffect(() => {
    buildFilters();

    filterSettings.forEach((filter) => {
      filterType[filter.type](filter);
    });
  }, []);

  const imageFilter = (filter) => {
    const selectedOption = filter.values.filter((data) => {
      if (filter.filter_by.includes(data.email)) {
        return {
          name: filter.name,
          label: data.email,
          value: data.email
        };
      }
    });

    if (selectedOption.length) {
      setResponsiblesChecked(
        selectedOption.map((option) => ({
          ...option,
          id: option.email,
          value: option.email,
          label: option.email
        }))
      );
    }

    const responsibles = filter.values
      .filter((user) => user.is_active)
      .map((user) => ({
        label: `${user.name} ${user.lastname}`,
        value: user.email
      }));

    setResponsibles(responsibles);
  };

  const iconFilter = (filter) => {
    const selectedOption = filter.values.filter((data) => {
      if (filter.filter_by.includes(data.value)) {
        return {
          name: filter.name,
          label: data.label,
          value: data.value
        };
      }
    });

    if (selectedOption.length) {
      filter.name == 'status' &&
        setStatusChecked(selectedOption.map((option) => ({ ...option })));
      filter.name == 'subtrade' &&
        setSubtradeCkecked(
          selectedOption.map((option) => ({
            ...option,
            id: option.value,
            value: option.label,
            label: option.label
          }))
        );
    }

    filter.name == 'status' && setStatus(filter.values);
    filter.name == 'subtrade' &&
      setSubtrade(
        filter.values.map((value) => {
          return { name: value.name, label: value.name, value: value.name };
        })
      );
  };

  const stringFilter = (filter) => {
    const selectedOption = filter.values.filter((data) => {
      if (filter.filter_by.includes(data.name)) {
        return {
          name: filter.name,
          label: data.name,
          value: data.id
        };
      }
    });

    if (selectedOption.length) {
      filter.name == 'tags' &&
        setTagsChecked(
          selectedOption.map((option) => ({
            name: option.name,
            label: option.name,
            value: option.name
          }))
        );
    }

    filter.name == 'tags' &&
      setTags(
        filter.values.map((value) => ({
          name: value.name,
          label: value.name,
          value: value.name
        }))
      );
  };

  const buildFilters = () => {
    filterType.image = imageFilter;
    filterType.icon = iconFilter;
    filterType.string = stringFilter;
  };

  const setCheckedValues = (values, filterType) => {
    filterSettings.forEach(
      (filter) =>
        filter.name == filterType &&
        (filter.filter_by = values.map((value) => value.id || value.value))
    );

    filterType == 'status' && setStatusChecked(values);
    filterType == 'toSelectResponsibles' && setResponsiblesChecked(values);
    filterType == 'subtrade' && setSubtradeCkecked(values);
    filterType == 'tags' && setTagsChecked(values);

    setAddFilter(!addFilter);
  };

  const handleChange = (values, filterType) => {
    filterType == 'status' && setCheckedValues(values, 'status');
    filterType == 'toSelectResponsibles' &&
      setCheckedValues(values, 'toSelectResponsibles');
    filterType == 'subtrade' && setCheckedValues(values, 'subtrade');
    filterType == 'tags' && setCheckedValues(values, 'tags');
  };

  const toggleHide = (_) => setHideFilters(!hideFilters);

  return (
    <div>
      {!hideFilters && (
        <span onClick={toggleHide} className={styles.filterText}>
          <span
            className={cn(
              styles['filter-icon'],
              styles['filter-icon--reverse']
            )}>
            <Icons.MoreFiltersIcon />
          </span>
          {t('activity_tree_panel.more-filters')}
        </span>
      )}
      {hideFilters && (
        <span onClick={toggleHide} className={styles.filterText}>
          <span className={styles['filter-icon']}>
            <Icons.MoreFiltersIcon />
          </span>
          {t('activity_tree_panel.hide-filters')}
        </span>
      )}

      {hideFilters && (
        <div className={styles['filter-type']}>
          {/* <div className={styles['filter-multi-check']}>
                        <ReactMultiSelectCheckboxes 
                            options={status}
                            value={statusChecked}
                            onChange={event => handleChange(event, 'status')}
                            className={styles['filter-list-item']}
                            placeholder={t('master_plan.search')}
                            getDropdownButtonLabel={_ => (
                                <div className={styles['filter-label']}>
                                    <span className={styles.text}>Status</span>
                                    <Icons.ChevronDownIcon color='#121212' />
                                </div>
                            )}
                        />
                    </div> */}
          <div className={styles['filter-multi-check']}>
            <ReactMultiSelectCheckboxes
              options={responsibles}
              value={responsiblesChecked}
              onChange={(event) => handleChange(event, 'toSelectResponsibles')}
              className={styles['filter-list-item']}
              placeholder={t('master_plan.search')}
              getDropdownButtonLabel={(_) => (
                <div className={styles['filter-label']}>
                  <span className={styles.text}>
                    {' '}
                    {t('activity_tree_panel.responsables')}
                  </span>
                  <Icons.ChevronDownIcon color="#121212" />
                </div>
              )}
            />
          </div>
          <div className={styles['filter-multi-check']}>
            <ReactMultiSelectCheckboxes
              options={subtrade}
              value={subtradeCheked}
              onChange={(event) => handleChange(event, 'subtrade')}
              className={styles['filter-list-item']}
              placeholder={t('master_plan.search')}
              getDropdownButtonLabel={(_) => (
                <div className={styles['filter-label']}>
                  <span className={styles.text}>
                    {' '}
                    {t('activity_tree_panel.subtrade')}
                  </span>
                  <Icons.ChevronDownIcon color="#121212" />
                </div>
              )}
            />
          </div>
          <div className={styles['filter-multi-check']}>
            <ReactMultiSelectCheckboxes
              options={tags}
              value={tagsChecked}
              onChange={(event) => handleChange(event, 'tags')}
              className={styles['filter-list-item']}
              placeholder={t('master_plan.search')}
              getDropdownButtonLabel={(_) => (
                <div className={styles['filter-label']}>
                  <span className={styles.text}>
                    {' '}
                    {t('activity_tree_panel.tags')}
                  </span>
                  <Icons.ChevronDownIcon color="#121212" />
                </div>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MoreFilters;
