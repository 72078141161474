/**
 * Checks if a task's associated calendar has a 24-hour work interval.
 *
 * @param {Object} task - The task object containing calendar details.
 * @param {string} task.calendar_id - The ID of the task's associated calendar.
 *
 * @returns {boolean} Returns true if the calendar has a 24-hour interval, otherwise returns false.
 */
export function has24HourInterval(task) {
  const gantt = window?.to_use_react_gantt;
  if (!gantt) return false;
  const calendar = gantt.getCalendar(task.calendar_id);
  if (!calendar) return false;
  const worktimes = Object.values(calendar._worktime.dates);

  // validate worktimes and if is and array
  if (!worktimes || !Array.isArray(worktimes)) return false;

  return worktimes.some((worktime) => {
    if (!worktime || !Array.isArray(worktime)) return false;

    return worktime.some((interval) => interval === '00:00-24:00');
  });
}
