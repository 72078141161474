import React from 'react';
import colors from '../../../stylesheets/variables.scss';

/** massive icons */
import editStagePath from '../../../assets/img/icons/icon--edit-stage.svg';
import { SvgIcon } from '../../../utils';

export const EditStageComp = (
  <SvgIcon
    path={editStagePath}
    className="massive-notification-icon"
    color={colors.brandOrange40}
  />
);
