import React, { useEffect, useState } from 'react';
import { Table, Select, Checkbox, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { userService } from '../../../../services/user.service';
import { StyleTableUsersProyect } from './style';
import { openNotification } from '../../../../utils';
import useConfigurationPermissions from '../../../../hooks/useConfigurationPermissions';
import userIcon from '../icons/user.svg';

function CardReports(props) {
  const { t } = props;
  /** hooks */
  const [Visible, setVisible] = useState(false);
  const [ReloadTableUsers, setReloadTableUsers] = useState(false);
  const [UsersProject, setUsersProject] = useState([]);
  const [showProjects, setshowProjects] = useState(false);
  const permissions = useConfigurationPermissions();
  const [onlyRead, setOnlyRead] = useState(permissions.project == 'V');
  const [search, setSearch] = useState('');
  const [copy, setCopy] = useState(null);
  /** consts */
  const { Option } = Select;

  /** redux */
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;
  const stateCompany = useSelector((state) => state.companyState);

  // GET PROJECT GENERAL SELECTED BY USER
  useEffect(() => {
    const abortController = new AbortController();
    userService
      .getByProjectThrough(projectSelectedId)
      .then((response) => {
        setUsersProject(response.users);
        setshowProjects(!showProjects);
      })
      .catch((error) => console.error(error));
    return function cleanup() {
      abortController.abort();
    };
  }, [ReloadTableUsers, Visible, projectSelectedId]);

  useEffect(() => {
    filter();
  }, [UsersProject, search]);

  const handleUserSettings = (index, name, value, userId) => {
    const copyUsers = [...UsersProject];
    const findIndex = copyUsers.findIndex((el) => el.id === userId);
    copyUsers[findIndex] = {
      ...copyUsers[findIndex],
      [name]: value
    };
    setUsersProject(copyUsers);
  };

  const updateUserTable = async (user) => {
    await userService
      .update(user)
      .then((response) => {})
      .catch(() => {
        const alertError = {
          title: t('settings.settings'),
          description: t('settings.error_default'),
          type: 'error'
        };
        openNotification(alertError);
      });
  };

  const handleChangeCheckbox = async (data, type, e) => {
    const dataReport = {
      project_id: projectSelectedId,
      user_id: data.id,
      type: type,
      value: e.target.checked
    };
    await userService.updateProjectReport(dataReport);
    setReloadTableUsers(!ReloadTableUsers);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'image',
      key: 'image',
      width: 50,
      render: (image) =>
        image ? (
          <img
            src={image}
            className="rounded-img-user"
            alt=""
            height="20px"
            width="20px"
          />
        ) : (
          <img
            src={userIcon}
            className="rounded-img-user"
            alt=""
            height="20px"
            width="20px"
          />
        )
    },
    {
      title: t('settings.project_users.name'),
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.name || '';
        b = b.name || '';
        return b.localeCompare(a);
      },
      render: (name, data, index) => (
        <input
          disabled={onlyRead}
          className="inp-table"
          placeholder={'-'}
          autoComplete="off"
          type="text"
          name="name"
          value={name}
          style={{ width: '90%', fontSize: '12px', border: '0px' }}
          title={name}
          onChange={(e) =>
            handleUserSettings(index, e.target.name, e.target.value, data.id)
          }
          onBlur={() => updateUserTable(data)}
        />
      )
    },
    {
      title: t('settings.project_users.lastname'),
      dataIndex: 'lastname',
      key: 'lastname',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.lastname || '';
        b = b.lastname || '';
        return b.localeCompare(a);
      },
      render: (lastname, data, index) => (
        <input
          disabled={onlyRead}
          className="inp-table"
          placeholder={'-'}
          autoComplete="off"
          type="text"
          name="lastname"
          value={lastname}
          style={{ width: '90%', fontSize: '12px', border: '0px' }}
          title={lastname}
          onChange={(e) =>
            handleUserSettings(index, e.target.name, e.target.value, data.id)
          }
          onBlur={() => updateUserTable(data)}
        />
      )
    },
    {
      title: t('settings.project_users.email'),
      dataIndex: 'email',
      key: 'email',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.email || '';
        b = b.email || '';
        return b.localeCompare(a);
      },
      render: (email, data, index) => (
        <input
          disabled={onlyRead}
          className="inp-table"
          placeholder={'-'}
          autoComplete="off"
          type="text"
          name="email"
          defaultValue={email}
          style={{ width: '100%', fontSize: '12px', border: '0px' }}
          title={email}
        />
      )
    },
    {
      title: t('settings.reports_form.taskcommitments'),
      dataIndex: 'report_tasks',
      key: 'report_tasks',
      render: (report_tasks, data, index) => (
        <div className="weekly-ckeckbox">
          <Checkbox
            checked={data.userproject.report_tasks}
            onChange={(e) => handleChangeCheckbox(data, 'report_tasks', e)}
            style={{ cursor: onlyRead ? 'not-allowed' : 'pointer' }}
            disabled={onlyRead}
          />
        </div>
      )
    },
    {
      title: t('settings.reports_form.weeklyclosing'),
      dataIndex: 'report_closing',
      key: 'report_closing',
      render: (report_closing, data, index) => (
        <div className="weekly-ckeckbox">
          <Checkbox
            checked={data.userproject.report_closing}
            onChange={(e) => handleChangeCheckbox(data, 'report_closing', e)}
            style={{ cursor: onlyRead ? 'not-allowed' : 'pointer' }}
            disabled={onlyRead}
          />
        </div>
      )
    }
    /* {
            title: <div className="title-center"><Tooltip placement='top' title={t('modules.next_release')}>{t('settings.reports_form.weeklyanalytics')}</Tooltip></div>,
            dataIndex: 'report_analytics',
            key: 'report_analytics',
            render: (report_analytics, data, index) => (
                <div className="weekly-ckeckbox">
                    <Checkbox
                        disabled
                        checked={data.userproject.report_analytics}
                        onChange={ e => handleChangeCheckbox(data, 'report_analytics', e)}
                    />
                </div>
            )
        } */
  ];

  const filter = () => {
    if (!search) {
      setCopy(UsersProject);
      return;
    }
    const filteredData = UsersProject.filter(
      (entry) =>
        (entry.name &&
          entry.name.toLowerCase().includes(search.toLowerCase())) ||
        (entry.lastname &&
          entry.lastname.toLowerCase().includes(search.toLowerCase())) ||
        (entry.email &&
          entry.email.toLowerCase().includes(search.toLowerCase()))
    );
    setCopy(filteredData);
  };

  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  return (
    <StyleTableUsersProyect>
      <div>
        <div className="grid-options-users"></div>
        <br />
        <div style={{ display: 'flex', margin: 2 }}>
          <input
            className="inp-table"
            placeholder={t('settings.project_subcontracts.search') + ' ...'}
            autoComplete="off"
            type="text"
            name="search"
            value={search || ''}
            style={{
              width: '180px',
              fontSize: '14px',
              border: '1px solid',
              borderColor: '#2C3421',
              margin: 2,
              paddingLeft: 10
            }}
            onChange={handleChangeSearch}
          />
        </div>
        <Table
          className="table-users-project"
          columns={columns}
          dataSource={copy}
          pagination={{ pageSize: 15 }}
          rowKey={(obj) => obj.id}
        />
      </div>
    </StyleTableUsersProyect>
  );
}

export default CardReports;
