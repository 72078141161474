import React, { useRef, useEffect, useState } from 'react';
/** Components from ant design css framework */
import {
  Menu,
  Dropdown,
  Button,
  Modal,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Input,
  InputNumber,
  Progress,
  Popover,
  Icon,
  Empty,
  Popconfirm
} from 'antd';
import { FileTextOutlined } from '@ant-design/icons';

/** import icons from antd framework */
import { ExclamationCircleOutlined } from '@ant-design/icons';

import CustomCheckbox from '../../../CustomCheckbox';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { List as AntdList } from 'antd';

/** PNG to put on users without image */
import fakeAvatar from '../../../../assets/img/fake_user.png';

import { FixedSizeList as List } from 'react-window';

import EditableInput from '../../../EditableInput';

import childArrow from '../../../../assets/img/child-arrow.png';
import cloneDeep from 'lodash/cloneDeep';
import externalLinkIcon from '../../../../assets/img/procore-variant.png';

import { reversPathTasks } from '../../../../utils';
/** Date handler lib */
import moment from 'moment';

/** Redux implementation */
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { renderFriend } from '../../../../utils/lookahead-common';
import { socket } from '../../../../services/socket.service';
import {
  openNotification,
  categoriesConstraintType,
  renderIconCat,
  handshake
} from '../../../../utils';
import SelectSearch from 'react-select-search';
import IconComponent from '../../../Projects/IconSvg';
import { userActions } from '../../../../redux/actions/userActions';
/** Services */
import {
  constraintService,
  constraintScheduleService,
  notificationService
} from '../../../../services';
import deleteIcon from '../../../../assets/img/DLT-DarkGrey-1080x1080.png';
import selectResponsablesIcon from '../../../../assets/img/select_responsables.png';
import { clone } from '@babel/types';
import { capitalize } from 'lodash';
import {
  PlusOutlined,
  SaveOutlined,
  LikeOutlined,
  DownOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import IndividualTaskSelection from '../ConstraintActivity/IndividualTaskSelection';
import IndividualAttachment from '../ConstraintActivity/IndividualAttachment';
import { attachmentsService } from '../../../../services/attachment.service';
import { calculateTrendingForConstraint } from '../../../../views/lookahead/constraints/functions/setTrending';
import { trackingEvent } from '../../../../analytics';
import { getBasicAmplitudEventProperties } from '../../../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import { getSignedUser } from '../../../../utils/userUtils';

const { TextArea } = Input;
const LazyElement =
  (data) =>
  ({ index, style }) => (
    <div style={style} className="single-row-bottom-bordered">
      {data[index]}
    </div>
  );
const { confirm } = Modal;

export default function LookaheadOtherGroup(props) {
  const { permission, t } = props;
  const [onlyRead, setOnlyRead] = useState(permission == 'V');
  const {
    groupParent,
    tableMetadata,
    updateAsyncConstraint,
    constraintActions,
    lastLevelActivities,
    modifyGroupBy
  } = props;
  const [editedInput, setEditedInput] = useState(null);
  const projectState = useSelector((state) => state.projectState);
  const [popsVisibility, setPopsVisibility] = useState({});
  const { height, width } = useWindowDimensions();
  const dispatch = useDispatch();
  const virtualizeRef = useRef();
  const [resourceMachine, setResourceMachine] = useState([]);
  const [resourceMaterial, setResourceMaterial] = useState([]);
  const [resourceRrhh, setResourceRrhh] = useState([]);
  const { attachments, setAttachments } = props;

  const sectorDateFormat = projectState.allSectors.find(
    (e) => e.id == projectState.sectorSelected
  );
  const formatDate =
    sectorDateFormat && sectorDateFormat.dateFormat
      ? sectorDateFormat.dateFormat
      : null;
  useEffect(() => {
    if (props.resources) {
      const mch = props.resources.filter((rs) => rs.type == 'machinery');
      const mtl = props.resources.filter((rs) => rs.type == 'material');
      const rrhh = props.resources.filter((rs) => rs.type == 'rrhh');
      setResourceMachine(mch);
      setResourceMaterial(mtl);
      setResourceRrhh(rrhh);
    }
  }, []);

  useEffect(() => {
    const id = props.groupParent.value;
    if (props.scrollStates[id]) {
      if (virtualizeRef.current) {
        virtualizeRef.current.scrollTo(props.scrollStates[id]);
      }
    }

    return () => {
      if (virtualizeRef.current) {
        props.scrollStates[id] = virtualizeRef.current.state.scrollOffset;
      }
    };
  }, [virtualizeRef]);

  const deleteDocument = async (id) => {
    const deleted = await attachmentsService.destroy(id);
    if (deleted) {
      notifyMessage({
        title: 'File removed',
        message: t('deleted_constraint_message_label'),
        type: 'success'
      });
      // eslint-disable-next-line prefer-const
      let aux = attachments.filter((item) => item.id != id);
      setAttachments(aux);
      return deleted;
    }
  };

  const newDocument = async (document) => {
    // eslint-disable-next-line prefer-const
    let aux = await attachments.concat(document);
    setAttachments(aux);
  };

  /**
   * This functions shows a pretty alert to user
   * @param {*} data Object { title, message, type }
   */
  const notifyMessage = (data) => {
    const alertErrorMailExists = {
      title: data.title,
      description: data.message,
      type: data.type
    };
    openNotification(alertErrorMailExists);
  };

  /**
   * This function allows user to hide childs for a task or activity
   * @param {*} parent Parent activity or task to hide their children (REQUIRED)
   */
  const hideChilds = (parent) => {
    if (parent.tasks)
      props.virtualizeRef.current.resetAfterRowIndex(props.index);
    parent.hide_childs = true;
    setTimeout(() => {
      props.updateRender(props.data);
    }, 500);
    updateState(false);
  };

  /**
   * This function allows user to show childs for a task or activity
   * @param {*} parent Parent activity or task to show their children (REQUIRED)
   */
  const showChilds = (parent) => {
    if (parent.tasks)
      props.virtualizeRef.current.resetAfterRowIndex(props.index);
    parent.hide_childs = false;
    setTimeout(() => {
      props.updateRender(props.data);
    }, 500);
    updateState(false);
  };

  const splitText = (text, column) => {
    if (typeof text === 'string') {
      let showedText = text;
      let tooltip;
      const colAdjustWidth = Math.trunc(column.width / 6);
      const textAdjustWidth = showedText.length * 2;
      if (textAdjustWidth > colAdjustWidth) {
        showedText = text.slice(0, Math.trunc(colAdjustWidth / 2)) + '...';
        tooltip = true;
      }
      return tooltip ? (
        <Tooltip placement="top" title={text}>
          {showedText}
        </Tooltip>
      ) : (
        showedText
      );
    }
    return text;
  };

  /**
   * This function handles at virtual dom which icon should be shown (collapse or uncollapse)
   * @param {*} parent Parent activity or task to define what icon to show (REQUIRED)
   */
  const defineCollapseIcon = (parent) => {
    const children = parent.tasks || parent.children;
    if (children) {
      if (children.length) {
        if (parent.hide_childs) {
          return (
            <i
              onClick={() => showChilds(parent)}
              className="fa fa-angle-down show-childs-btn"
              aria-hidden="true"
            />
          );
        }
        return (
          <i
            onClick={() => hideChilds(parent)}
            className="fa fa-minus hide-childs-btn"
            aria-hidden="true"
          />
        );
      }
    }
  };

  const defineParentColumnContent = (column, parent) => {
    if (column.name == 'name') {
      if (parent.name || parent.label) {
        return (
          <div>
            {parent.tasks.length && !onlyRead ? (
              <CustomCheckbox
                inverted
                onClick={() => {
                  props.massiveSelectionHandler(parent);
                  // updateState()
                }}
                active={parent.active}
              />
            ) : null}
            {defineCollapseIcon(parent)}
            <span style={{ marginLeft: 5 }}>{parent.name || parent.label}</span>
          </div>
        );
      }
      return parent.label;
    }
  };

  const renderParentColumn = (column, parent, index) => {
    if (column.visible) {
      return (
        <Col
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineParentColumnContent(column, parent)}
        </Col>
      );
    }
  };

  const renderGroupParentHeader = (parent = groupParent) => (
    <Row className="custom-header-top-list">
      {tableMetadata.map((eachColumn, index) =>
        renderParentColumn(eachColumn, parent, index)
      )}
    </Row>
  );

  /**
   * This function opens a component to add constraints
   * @param {*} task task to add constraint
   */
  const openAddConstraint = (task) => {
    props.handleAddConstraint(task);
  };
  /**
   * Clone deep of a new state to set and render view
   * @param {*} newState new state value (inmutability is broken)
   */
  const updateState = (notifyLookahead = true) => {
    setPopsVisibility((prev) => {
      prev = cloneDeep(popsVisibility);
      return prev;
    });
    if (notifyLookahead) {
      dispatch(constraintActions.notifyLookaheadUpdate());
    }
  };

  /**
   * This function deals with pop visibility hash map
   * @param {boolean} visible Value to set the pop
   * @param {*} id Unique state eval to use at virtual DOM to show popup or hide it (from antd component)
   */
  const handlePopVisibility = (visible, id) => {
    setPopsVisibility({
      ...popsVisibility,
      [id]: visible
    });
    dispatch(constraintActions.notifyLookaheadUpdate());
  };

  const filterBufferSaveAndUpdateState = (task) => {
    const copyTask = cloneDeep(task);
    delete copyTask.activityObject;
    updateAsyncConstraint(copyTask);
    updateState();
  };

  const createSchedule = async (dateString, constraintId) => {
    const data = {
      deadline: dateString,
      fail_comment: '',
      constraintId
    };
    return constraintScheduleService.create(data);
  };

  const commitmentDateChange = async (task, column, dateString, dateComm) => {
    task[column.name] = dateString;
    let status = task.status;
    if (moment(dateString) < moment()) {
      status = 'expired';
    } else {
      status = 'notreleased';
    }
    task.status = status;
    /** chek if is first schedule */
    if (!task.schedules.length) {
      const datStr = moment.utc(dateComm).startOf('day').add(12, 'hours');
      const addSch = await createSchedule(datStr, task.id);
      task.schedules.push(addSch);
    }
    /** Apply changes */
    const addSch = await createSchedule(dateString, task.id);
    task.schedules.push(addSch);
    calculateTrendingForConstraint(task);
    updateAsyncConstraint(task);
    updateState();
    props.setCountSchedule(parseInt(props.countSchedule) + 1);
  };

  /** this function shown modal dialog to confirm action (rescheduling) */
  const showConfirm = (task, column, dateString, dateComm) => {
    confirm({
      title: t('lookahead_constraints.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('lookahead_constraints.warning_schedule'),
      onOk() {
        commitmentDateChange(task, column, dateString, dateComm);
      },
      onCancel() {
        updateState();
      }
    });
  };

  /**
   * On click function when pressing delete
   * @param {*} task Top lvl task to start tree deleting
   * @param {*} activity Activity parent object
   * @param {*} parent If task is from another than first level must be specified the parent task to attack it children array
   */
  const handleDelete = async (task, activity, parent = null) => {
    await constraintService.destroy(task.id);
    const newConstraints = activity.tasks.filter(
      (constraint) => constraint.id !== task.id
    );
    activity.tasks = newConstraints;

    notifyMessage({
      title: t('deleted_constraint_label'),
      message: t('deleted_constraint_message_label'),
      type: 'success'
    });
    props.virtualizeRef.current.resetAfterRowIndex(props.index);
    updateState();
    props.setMassiveSelection([]);
  };

  const notification = async (constraint, prev = false, report = false) => {
    const user = getSignedUser();
    if (report) {
      constraint.report_user &&
        (await notificationService.createConstraint({
          user,
          constraint,
          module: 'constraints',
          type_notification: 'assign_report'
        }));
    } else {
      prev !== constraint.userId &&
        (await notificationService.createConstraint({
          user,
          constraint,
          module: 'constraints',
          type_notification: 'assign_responsible'
        }));
    }
    if (prev && prev !== constraint.userId) {
      // eslint-disable-next-line prefer-const
      let copy = { ...constraint, userId: prev };
      await notificationService.createConstraint({
        user,
        constraint: copy,
        module: 'constraints',
        type_notification: 'unassign_responsible'
      });
    }
    dispatch(userActions.setUserUpdate());
  };

  /**
   * This function defines what should be showed inside each task column at table.
   * This by using a flag from task, isEditing, when is active renders something, and when is not active
   * renders another jsx
   * @param {*} column Metadata from the column that is going to be extracted from the task
   * @param {*} task Task object to extract the column data
   * @param {*} activity Parent activity for tasks from first level
   * @param {*} parentTask Parent task if it is not from first level
   */
  const defineTaskColumnContent = (column, task, parent, index) => {
    if (column.data_type === 'string') {
      if (column.name == 'name') {
        const option = props.tableMetadata.find((el) => el.name == 'status');
        let background = option.from_values.filter(
          (el) => el.value == task.status
        );
        background = background[0];
        return (
          <span className="vertical-center">
            {onlyRead ? null : (
              <CustomCheckbox
                onClick={() => {
                  props.massiveSelectionHandler(task);
                  // updateState()
                }}
                active={task.active}
              />
            )}
            {task.parent_id ? (
              <img style={{ marginRight: 6 }} width={12} src={childArrow} />
            ) : null}
            <div
              className="lineStatus lookaheadLineStatus"
              style={{ background: background.color, marginRight: 6 }}>
              &nbsp;
            </div>
            <EditableInput
              t={t}
              disabled={onlyRead}
              service={updateAsyncConstraint}
              onEdit={setEditedInput}
              isEditing={editedInput}
              renderEditable={(
                column,
                index,
                task,
                value,
                setValue,
                updateParentData = null,
                handleEsc = null
              ) => (
                <Input
                  onKeyDown={handleEsc}
                  onPressEnter={updateParentData}
                  key={index}
                  id={column.name + task.id}
                  size="small"
                  value={value}
                  className="custom-input-planification"
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => setValue(e.target.value)}
                />
              )}
              updateState={updateState}
              index={index}
              column={column}
              task={task}
              constraint={true}
            />
            {onlyRead ? null : (
              <span style={{ cursor: 'pointer', marginLeft: 5 }}>
                <Popconfirm
                  onConfirm={() => handleDelete(task, parent, parent)}
                  title={t('are_sure_general')}
                  icon={
                    <Icon type="question-circle-o" style={{ color: 'red' }} />
                  }>
                  <Tooltip placement="top" title={t('delete_task_label')}>
                    <DeleteOutlined className="lookahead-delete-icon" />
                  </Tooltip>
                </Popconfirm>
              </span>
            )}
            {task.link && task.link.includes('procore') && (
              <span style={{ cursor: 'pointer', marginLeft: 15 }}>
                <Tooltip placement="top" title={t('procore_link_label')}>
                  <a
                    href={task.link}
                    target="_blank"
                    onClick={(e) => {
                      const url = task.link;
                      const rfiId = url.substring(url.lastIndexOf('/') + 1);
                      trackingEvent(
                        'visualize_rfi',
                        {
                          ...getBasicAmplitudEventProperties(),
                          roadblock_linked_id: task.id,
                          roadblock_linked_name: task.name,
                          rfi_id: rfiId
                        },
                        AMPLITUDE_SERVICE
                      );
                    }}>
                    <img src={externalLinkIcon} width={15} height={15} />
                  </a>
                </Tooltip>
              </span>
            )}
          </span>
        );
      } else if (column.name === 'description') {
        return (
          <Tooltip placement="bottom" title={task.description}>
            <div className="vertical-center">
              <span style={{ width: '10px' }} className="constraint-desc">
                {task.description}
              </span>
            </div>
          </Tooltip>
        );
      } else if (column.name === 'tasks') {
        const constraint = task;
        const constraintTasks = constraint.tasks.filter((ct) => ct !== null);

        let ret = (
          <span
            className="constraint-activity-tasks"
            style={{
              margin: 'auto',
              cursor: onlyRead ? 'not-allowed' : 'pointer'
            }}>
            {onlyRead ? (
              <div
                style={{ cursor: 'not-allowed !important', color: '#1890ff' }}>
                {t('weekly_plan_cnc.select_tasks')}
              </div>
            ) : (
              <IndividualTaskSelection
                noDispatch
                constraint={constraint}
                defaultTasks={constraintTasks}
                {...props}>
                {t('weekly_plan_cnc.select_tasks')}
              </IndividualTaskSelection>
            )}
          </span>
        );
        if (constraintTasks.length === 0) return ret;
        let dropdownContent;
        if (constraintTasks.length === 1) {
          const firstConstraint = constraintTasks[0];

          const findRoute = lastLevelActivities.activities.find(
            (el) => el.id === firstConstraint.activityId
          );
          const routeTooltip = findRoute
            ? findRoute.activityRoute + ' > ' + findRoute.name
            : 'No Route';

          dropdownContent = (
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}>
              <Tooltip title={routeTooltip}>{firstConstraint.name}</Tooltip>
              <DownOutlined />
            </a>
          );
        }
        if (constraintTasks.length > 1) {
          dropdownContent = (
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}>
              {constraintTasks.length}{' '}
              {constraintTasks.length > 1
                ? t('tasks_only_label')
                : t('task_only_label')}{' '}
              <DownOutlined />
            </a>
          );
        }

        ret = constraintTasks.map((ct, index) => (
          <span class="vertical-center" key={index}>
            {ct.name}
          </span>
        ));

        const menu = (
          <Menu>
            {constraintTasks.map((ct, index) => {
              const findRoute = lastLevelActivities.activities.find(
                (el) => el.id === ct.activityId
              );
              const routeTooltip = findRoute
                ? findRoute.activityRoute + ' > ' + findRoute.name
                : 'No Route';
              return (
                <Tooltip title={routeTooltip}>
                  <IndividualTaskSelection
                    constraint={constraint}
                    defaultTasks={constraintTasks}
                    {...props}>
                    {ct.name}
                  </IndividualTaskSelection>
                </Tooltip>
              );
            })}
          </Menu>
        );
        ret = (
          <span
            className="constraint-activity-tasks"
            style={{ margin: 'auto' }}>
            <Dropdown
              overlay={menu}
              trigger={['click']}
              className="associated-task">
              {dropdownContent}
            </Dropdown>
          </span>
        );
        return ret;
      } else if (column.name === 'document') {
        return <div></div>;
        /* const att = attachments.filter((el) => el.modelId == task.id)
                let ret = (
                    <a href={'#'} className="vertical-center">Ver documentos</a>
                )

                    ret = att.map((ct, index) => {
                        return (
                            <span class="vertical-center" key={index}>{ct.filename}</span>
                        )
                    })
                    const menu = (
                        <AntdList header={<div>Files</div>} bordered>
                            {
                                att.map((ct, index) => {
                                    return (
                                            <AntdList.Item>
                                                <a target="_blank" className="ant-dropdown-link"  href={ct.url}>
                                                <FileTextOutlined /> {ct.filename}
                                                </a>
                                                <span onClick={()=>deleteDocument(ct.id)} style={{color:'red'}}>X</span>
                                            </AntdList.Item>
                                    )
                                })
                            }
                            {
                                att.length == 0 && <AntdList.Item>
                                    No files
                                </AntdList.Item>
                            }
                        </AntdList>
                    )

                    ret = (
                        <span className="vertical-center">
                            <IndividualAttachment newDocument={(doc)=>newDocument(doc) } documentsQtt={att.length} constraint={task} visibility={false} noTitle description='Attach more' {...props} >
                                {menu}
                            </IndividualAttachment>
                        </span>
                    )

                return ret */
      } else if (column.name === 'schedules') {
        const constraint = task;
        let ret = <span className="vertical-center">-</span>;
        const menu = (
          <Menu>
            {constraint.schedules.map((ct, index) => (
              <Menu.Item key={index}>
                {moment.utc(ct.deadline).format(formatDate)}
              </Menu.Item>
            ))}
          </Menu>
        );
        ret = (
          <span className="vertical-center">
            {constraint.schedules.length <= 1 ? (
              t('without_reschedule_label')
            ) : (
              <Dropdown
                overlay={menu}
                trigger={['click']}
                className="associated-task">
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}>
                  {parseInt(constraint.schedules.length - 1)}{' '}
                  {parseInt(constraint.schedules.length - 1) > 1
                    ? t('reschedules_only_label')
                    : t('reschedule_only_label')}{' '}
                  <DownOutlined />
                </a>
              </Dropdown>
            )}
          </span>
        );
        return ret;
      }
    } else if (column.data_type == 'number') {
      if (column.name === 'trending') {
        let textColor;
        if (task.trending < 0) textColor = 'red';
        else if (task.trending > 0) textColor = 'blue';
        else if (task.trending === 0) textColor = 'grey';
        return (
          <span
            className="vertical-center"
            style={{
              color: textColor
            }}>
            {Number.isInteger(task[column.name])
              ? task[column.name]
              : task[column.name].toFixed(2)}{' '}
            d
          </span>
        );
      }
      return (
        <span className="vertical-center">
          {Number.isInteger(task[column.name])
            ? task[column.name]
            : task[column.name].toFixed(2)}
        </span>
      );
    } else if (column.data_type == 'date') {
      if (column.name == 'release_date') {
        let classDate = 'custom-date-picker-planification';
        if (task.active) {
          classDate += ' massive';
        }
        const retInfo = (
          <span className="vertical-center">
            {task[column.name] && task.status !== 'notreleased'
              ? moment(task[column.name]).format(formatDate)
              : '-'}
          </span>
        );
        let ret = retInfo;
        if (task.releaseuser) {
          const userMsg = `${task.releaseuser.name} ${task.releaseuser.lastname} (${task.releaseuser.email})`;
          const msg =
            task[column.name] && task.status !== 'notreleased'
              ? `${t('released_by_only_label')}${userMsg}`
              : null;
          ret = <Tooltip title={msg}>{retInfo}</Tooltip>;
        }
        return ret;
      } else if (column.name == 'deadline') {
        let classDate =
          'custom-date-picker-planification custom-date-picker-roadblock';
        if (task.active) {
          /** #12121210 */
          classDate += ' massive';
        }
        return (
          <span className="vertical-center-dates">
            <DatePicker
              disabled={onlyRead}
              allowClear={false}
              id={column.name + task.id}
              className={classDate}
              defaultValue={moment(task[column.name])}
              format={formatDate}
              onChange={(date, dateString) => {
                task[column.name] = moment(dateString, formatDate).format(
                  'MM-DD-YY'
                );
                /** Apply changes */
                updateAsyncConstraint(task);
                updateState();
              }}
            />
          </span>
        );
      } else if (column.name == 'commitmentDate') {
        let classDate =
          'custom-date-picker-planification custom-date-picker-roadblock';
        if (task.active) {
          classDate += ' massive';
        }
        return task[column.name] ? (
          <span className="vertical-center-dates">
            <DatePicker
              disabled={onlyRead}
              allowClear={false}
              id={column.name + task.id}
              className={classDate}
              defaultValue={moment(task[column.name])}
              format={formatDate}
              onChange={async (date, dateString) => {
                const dateComm = cloneDeep(task.commitmentDate);
                showConfirm(
                  task,
                  column,
                  moment(dateString, formatDate).format('MM-DD-YY'),
                  dateComm
                );
              }}
            />
          </span>
        ) : (
          <span className="vertical-center">
            <Popover
              overlayClassName="popoverConstraint"
              content={
                <div>
                  <DatePicker
                    disabled={onlyRead}
                    className="dateConstraint"
                    allowClear
                    format={formatDate}
                    onChange={async (e, dateString) => {
                      let status = task.status;
                      if (e.format(formatDate) < moment().format(formatDate)) {
                        status = 'expired';
                      } else {
                        status = 'notreleased';
                      }
                      task.status = status;
                      task[column.name] = e;
                      const addSch = await createSchedule(dateString, task.id);
                      task.schedules.push(addSch);
                      updateAsyncConstraint(task);
                      updateState();
                    }}
                  />
                </div>
              }
              title={t('change_committed_only')}
              trigger="click">
              <Button className="constraints-commitment-btn" size="small">
                <div>
                  <IconComponent
                    data={handshake}
                    width={12}
                    fill="#FFFFFF"
                    className="icon-hand-shake"
                  />
                  <span className="constraint-btn-span">
                    {t('committed_text_general')}
                  </span>
                </div>
              </Button>
            </Popover>
          </span>
        );
      }
    } else if (column.data_type == 'array/images') {
      if (column.ref === 'responsables') {
        const uniqueId = task.id + column.name;
        const friends = [];
        const selected = [];
        task[column.ref].map((res) => {
          if (res) {
            selected.push(res[column.el_to_extract_from]);
          }
        });
        props[column.from_values].map((user) => {
          if (user.is_active) {
            friends.push({
              name: user[column.el_to_extract_from],
              value: user[column.el_to_extract_from],
              photo: user[column.img_from] || fakeAvatar,
              object: user
            });
          }
        });
        if (onlyRead) {
          return (
            <div
              className="custom-multi-select-pop"
              style={{
                width: column.width ? column.width : '100%',
                height: '100%',
                marginTop: 0,
                border: 0
              }}>
              {task[column.ref].length ? (
                task[column.ref].map((responsable, index) => {
                  if (responsable) {
                    return (
                      <span className="vertical-center" key={index}>
                        <Tooltip title={responsable.name}>
                          {responsable[column.img_from] ? (
                            <img
                              className="img-responsable-lookahead"
                              src={responsable[column.img_from]}
                            />
                          ) : (
                            <div className="img-responsable-lookahead no-img">
                              {responsable.name ? responsable.name[0] : ''}
                              {responsable.lastname
                                ? responsable.lastname[0]
                                : ''}
                            </div>
                          )}
                        </Tooltip>
                      </span>
                    );
                  }
                })
              ) : (
                <span className="vertical-center">
                  <Tooltip
                    title={
                      t('lang') === 'es'
                        ? 'Seleccione Responsable'
                        : 'Select Responsible'
                    }>
                    <img src={selectResponsablesIcon} width={15} />
                  </Tooltip>
                </span>
              )}
            </div>
          );
        }

        return (
          <div
            className="custom-multi-select-pop"
            style={{
              width: column.width ? column.width : '100%',
              height: '100%',
              marginTop: 0,
              border: 0
            }}>
            <Popover
              overlayClassName="container-image-custom"
              className="popoverPriority"
              content={
                <SelectSearch
                  printOptions="always"
                  className="select-search select-search--multiple"
                  options={friends}
                  value={selected}
                  renderOption={renderFriend}
                  onChange={(val) => {
                    if (task.status == 'draft') {
                      task.status = 'notreleased';
                    }
                    const prevRes = task.responsables[0]?.id || false;
                    const findUser = props[column.from_values].find(
                      (us) => us.email === val
                    );
                    task.users = [findUser];
                    task.responsables = [findUser];
                    task[column.refId] = findUser.id;
                    updateAsyncConstraint(task);
                    notification(task, prevRes);
                    notification(task, false, true);
                    modifyGroupBy();
                    updateState();
                    handlePopVisibility(false, uniqueId);
                  }}
                  search
                  placeholder="Search users (email)"
                />
              }
              trigger="click">
              {task[column.ref].length ? (
                task[column.ref].map((responsable, index) => {
                  if (responsable) {
                    return (
                      <span className="vertical-center" key={index}>
                        <Tooltip title={responsable.name}>
                          {responsable[column.img_from] ? (
                            <img
                              className="img-responsable-lookahead"
                              src={responsable[column.img_from]}
                            />
                          ) : (
                            <div className="img-responsable-lookahead no-img">
                              {responsable.name ? responsable.name[0] : ''}
                              {responsable.lastname
                                ? responsable.lastname[0]
                                : ''}
                            </div>
                          )}
                        </Tooltip>
                      </span>
                    );
                  }
                })
              ) : (
                <span className="vertical-center">
                  <Tooltip
                    title={
                      t('lang') === 'es'
                        ? 'Seleccione Responsable'
                        : 'Select Responsible'
                    }>
                    <img src={selectResponsablesIcon} width={15} />
                  </Tooltip>
                </span>
              )}
            </Popover>
          </div>
        );
      } else if (column.name === 'reportusers') {
        const uniqueId = task.id + column.name;
        const friends = [];
        const selected = [];
        if (!task[column.ref]) {
          return 'no asignado';
        }
        task[column.ref].map((res) => {
          if (res) {
            selected.push(res[column.el_to_extract_from]);
          }
        });
        props[column.from_values].map((user) => {
          if (user.is_active) {
            friends.push({
              name: user[column.el_to_extract_from],
              value: user[column.el_to_extract_from],
              photo: user[column.img_from] || fakeAvatar,
              object: user
            });
          }
        });
        if (onlyRead) {
          return (
            <div
              className="custom-multi-select-pop"
              style={{
                width: column.width ? column.width : '100%',
                height: '100%',
                marginTop: 0,
                border: 0
              }}>
              {task[column.ref].length ? (
                task[column.ref].map((responsable, index) => {
                  if (responsable) {
                    return (
                      <span className="vertical-center" key={index}>
                        <Tooltip
                          title={`${responsable.name} ${responsable.lastname}`}>
                          {responsable[column.img_from] ? (
                            <img
                              className="img-responsable-lookahead"
                              src={responsable[column.img_from]}
                            />
                          ) : (
                            <div className="img-responsable-lookahead no-img">
                              {responsable.name ? responsable.name[0] : ''}
                              {responsable.lastname
                                ? responsable.lastname[0]
                                : ''}
                            </div>
                          )}
                        </Tooltip>
                      </span>
                    );
                  }
                })
              ) : (
                <span className="vertical-center">
                  <Tooltip title={t('select_responsable_tooltip')}>
                    <img src={selectResponsablesIcon} width={15} />
                  </Tooltip>
                </span>
              )}
            </div>
          );
        }

        return (
          <div
            className="custom-multi-select-pop"
            style={{
              width: column.width ? column.width : '100%',
              height: '100%',
              marginTop: 0,
              border: 0
            }}>
            <Popover
              overlayClassName="container-image-custom"
              className="popoverPriority"
              content={
                <SelectSearch
                  printOptions="always"
                  className="select-search select-search--multiple"
                  options={friends}
                  closeOnSelect={true}
                  value={selected}
                  renderOption={renderFriend}
                  onChange={(val) => {
                    const findUser = props[column.from_values].find(
                      (us) => us.email === val
                    );
                    if (
                      task[column.name][0] &&
                      task[column.name][0].email === val
                    ) {
                      task.reportusers = [];
                      task[column.refId] = null;
                    } else {
                      task.reportusers = [findUser];
                      task[column.refId] = findUser.id;
                    }

                    updateState();
                    handlePopVisibility(false, uniqueId);
                    notification(task, false, true);
                    filterBufferSaveAndUpdateState(task);
                  }}
                  search
                  placeholder="Search users (email)"
                />
              }
              trigger="click">
              {task[column.ref].length ? (
                task[column.ref].map((responsable, index) => {
                  if (responsable) {
                    return (
                      <span className="vertical-center" key={index}>
                        <Tooltip
                          title={`${responsable.name} ${responsable.lastname}`}>
                          {responsable[column.img_from] ? (
                            <img
                              className="img-responsable-lookahead"
                              src={responsable[column.img_from]}
                            />
                          ) : (
                            <div className="img-responsable-lookahead no-img">
                              {responsable.name ? responsable.name[0] : ''}
                              {responsable.lastname
                                ? responsable.lastname[0]
                                : ''}
                            </div>
                          )}
                        </Tooltip>
                      </span>
                    );
                  }
                })
              ) : (
                <span className="vertical-center">
                  <Tooltip title={t('select_responsable_tooltip')}>
                    <img src={selectResponsablesIcon} width={15} />
                  </Tooltip>
                </span>
              )}
            </Popover>
          </div>
        );
      }
    } else if (column.data_type == 'array/string') {
      if (column.name === 'constraintTypeId') {
        const uniqueId = task.id + column.name;
        const statusObject =
          props.constraintTypes &&
          props.constraintTypes.find((e) => e.id === task[column.name]);
        const findItemTable =
          statusObject &&
          categoriesConstraintType.find((el) => el.value === statusObject.type);

        if (onlyRead) {
          return (
            <div style={{ height: '100%' }}>
              <span className="vertical-center">
                <Tooltip
                  title={t(
                    `settings.project_categories.${findItemTable.trad}`
                  )}>
                  <img
                    className="cat-icon"
                    src={renderIconCat(findItemTable.icon)}
                    alt=""
                  />
                </Tooltip>
                {statusObject.name}
              </span>
            </div>
          );
        }

        return (
          statusObject && (
            <div
              className="customPlanificationBlock priorityFlag"
              style={{
                height: '100%',
                marginTop: 0,
                border: 0,
                marginLeft: 15,
                textAlign: 'start'
              }}>
              <Tooltip placement="top">
                <Popover
                  overlayClassName="popoverPriority"
                  className="popoverPriority"
                  placement="bottom"
                  content={
                    <div className="priorityOptions">
                      {props.constraintTypes.map((option, index) => {
                        const findItem = categoriesConstraintType.find(
                          (el) => el.value === option.type
                        );
                        return (
                          <span
                            className="item"
                            key={index}
                            onClick={() => {
                              task[column.name] = option.id;
                              updateAsyncConstraint(task);
                              handlePopVisibility(false, uniqueId);
                              // updateState()
                            }}>
                            <Tooltip title={findItem.label}>
                              <img
                                className="cat-icon"
                                src={renderIconCat(findItem.icon)}
                                alt=""
                              />
                            </Tooltip>
                            <span style={{ marginLeft: 5 }}>
                              {capitalize(option.name)}
                            </span>
                          </span>
                        );
                      })}
                    </div>
                  }
                  trigger="click">
                  <div style={{ height: '100%' }}>
                    <span className="vertical-center">
                      <Tooltip
                        title={t(
                          `settings.project_categories.${findItemTable.trad}`
                        )}>
                        <img
                          className="cat-icon"
                          src={renderIconCat(findItemTable.icon)}
                          alt=""
                        />
                      </Tooltip>
                      {statusObject.name}
                    </span>
                  </div>
                </Popover>
              </Tooltip>
            </div>
          )
        );
      } else if (column.name === 'typeArea') {
        const statusObject =
          props.constraintTypes &&
          props.constraintTypes.find((e) => e.id === task.constraintTypeId);
        return (
          <div style={{ height: '100%' }}>
            <span className="vertical-center">
              {t(`settings.project_categories.${statusObject.type}`)}
            </span>
          </div>
        );
      } else if (column.name === 'status') {
        const uniqueId = task.id + column.name;
        const statusObject = column.from_values.filter(
          (el) => el.value == task[column.name]
        );
        const background = statusObject[0].color;

        if (onlyRead) {
          return (
            <div
              style={{
                backgroundColor: background,
                color: 'white',
                height: '100%'
              }}>
              <span className="vertical-center">{statusObject[0].label}</span>
            </div>
          );
        }

        return (
          <div
            className="customPlanificationBlock priorityFlag"
            style={{ height: '100%', marginTop: 0, border: 0 }}>
            <Tooltip placement="top">
              <Popover
                placement="bottom"
                overlayClassName="popoverPriority"
                className="popoverPriority"
                content={
                  <div className="priorityOptions">
                    {column.from_values
                      .filter((el) => el.visible)
                      .map((option, index) => (
                        <span
                          className="item"
                          key={index}
                          onClick={() => {
                            const user = JSON.parse(
                              localStorage.getItem('user')
                            );
                            task[column.name] = option.value;
                            if (option.value === 'released') {
                              task.release_date = moment();
                              task.release_user = user.id;
                            }
                            if (option.value === 'notreleased') {
                              task.release_date = null;
                              task.release_user = null;
                            }
                            calculateTrendingForConstraint(task);
                            updateAsyncConstraint(task);
                            handlePopVisibility(false, uniqueId);
                          }}>
                          <i
                            className="fas fa-circle"
                            style={{
                              fontSize: 11,
                              color: option.color,
                              position: 'relative',
                              top: -1
                            }}
                          />
                          <span style={{ marginLeft: 5 }}>{option.label}</span>
                        </span>
                      ))}
                  </div>
                }
                trigger="click">
                <div
                  style={{
                    backgroundColor: background,
                    color: 'white',
                    height: '100%'
                  }}>
                  <span className="vertical-center">
                    {statusObject[0].label}
                  </span>
                </div>
              </Popover>
            </Tooltip>
          </div>
        );
      }
      return <span className="vertical-center">-</span>;
    } else if (column.data_type == 'array/icon') {
      const iconToShow = column.from_values.filter(
        (f) => f.value == task[column.name]
      );
      const uniqueId = task.id + column.name;
      const defineIcon = (option) => {
        if (column.name == 'priority') {
          return <img src={option.icon} width={12} />;
        }
        return (
          <i
            className={option.icon}
            style={{
              fontSize: 11,
              color: option.color,
              position: 'relative',
              top: -2
            }}
          />
        );
      };
      let tooltipTitle = '';

      if (column.name === 'priority') {
        const tooltipDescription = column.from_values.filter(
          (option) => option.value === task[column.name]
        );
        tooltipTitle = tooltipDescription[0].label;
      }

      if (onlyRead) {
        return (
          <div
            className="customStr priorityFlag custom-select-planification-position"
            style={{ height: '100%', marginTop: 0, border: 0 }}>
            <Tooltip title={capitalize(tooltipTitle)} placement="top">
              <span className="vertical-center">
                {defineIcon(iconToShow[0])}
              </span>
            </Tooltip>
          </div>
        );
      }

      return (
        <Popover
          overlayClassName="popoverPriority"
          className="popoverPriority"
          placement="bottom"
          content={
            <div className="priorityOptions">
              {column.from_values.map((option, index) => (
                <span
                  className="item"
                  key={index}
                  onClick={() => {
                    task[column.name] = option.value;
                    updateAsyncConstraint(task);
                    handlePopVisibility(false, uniqueId);
                  }}>
                  {defineIcon(option)}
                  <span style={{ marginLeft: 5 }}>
                    {capitalize(option.label)}
                  </span>
                </span>
              ))}
            </div>
          }
          trigger="click">
          <div
            className="customStr priorityFlag custom-select-planification-position"
            style={{ height: '100%', marginTop: 0, border: 0 }}>
            <Tooltip title={capitalize(tooltipTitle)} placement="top">
              <span className="vertical-center">
                {defineIcon(iconToShow[0])}
              </span>
            </Tooltip>
          </div>
        </Popover>
      );
    } else {
      return <div className="">--</div>;
    }
  };

  const renderTaskColumn = (column, task, index, parent) => {
    if (column.visible) {
      return (
        <Col
          className={
            column.name == 'name'
              ? 'fit-name-superposition'
              : 'single-task-column-style'
          }
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            paddingLeft: column.name == 'name' ? 15 : 0,
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineTaskColumnContent(column, task, parent, index)}
        </Col>
      );
    }
  };

  const renderChildTreeTasks = (finalArray, parent) => {
    parent.tasks.map((task) => {
      if (!task.hide) {
        finalArray.push(
          <Row
            style={{ backgroundColor: task.active ? '#12121210' : null }}
            key={task.id}
            className="fit-at-middle-row">
            {tableMetadata.map((eachColumn, index) =>
              renderTaskColumn(eachColumn, task, index, parent)
            )}
          </Row>
        );
      }
    });
  };

  /** This function is used for show tasks quantity on virtual dom who renders them and resize height */
  const defineVirtualizedHeight = (taskCounter) => {
    const tempHeight = taskCounter * 40;
    const maxHeight = height < 700 ? height * 0.45 : height * 0.6;
    if (tempHeight < maxHeight) {
      return tempHeight;
    }
    return maxHeight;
  };

  const renderTasks = (parent = groupParent) => {
    const childRended = [];
    renderChildTreeTasks(childRended, parent);

    parent.childRended = childRended.length;

    props.virtualizeRef.current.resetAfterRowIndex(props.index);
    /**
     * To Optimize render of this child list an option is
     * https://react-window.now.sh/#/examples/list/variable-size
     * with childRended, we can create a virtual list to handle performance
     */
    if (parent.hide_childs) {
      return null;
    } else if (childRended.length) {
      return (
        <List
          ref={virtualizeRef}
          height={defineVirtualizedHeight(childRended.length)}
          itemCount={childRended.length}
          itemSize={40}
          width={'100%'}>
          {LazyElement(childRended)}
        </List>
      );
    }
    return (
      <div style={{ marginTop: 20 }}>
        <Empty
          style={{ margin: 0 }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span></span>}
        />
      </div>
    );
  };

  /**
   * Render
   */

  if (!formatDate) return <div></div>;
  return (
    <Row key={props.index}>
      <Col
        className="tableConstraints"
        style={{ marginTop: 10, marginBottom: 40 }}>
        {renderGroupParentHeader(groupParent)}
        {renderTasks(groupParent)}
      </Col>
    </Row>
  );
}
