/** format hour */
export const formatShift = (shift) => {
  let ret = shift;
  if (typeof shift === 'number' || shift instanceof Number) {
    ret = parseInt(shift) < 10 ? `0${shift}:00` : `${shift}:00`;
  } else {
    ret = shift.map((e) => {
      let retShift = e;
      if (!isNaN(parseInt(e))) {
        /** if is integer */
        retShift = parseInt(e) < 10 ? `0${e}:00` : `${e}:00`;
      }
      return retShift;
    });
  }
  return ret;
};

/** add 1 hour to existing schedule */
export const add1Hour = (hour, addH = 0) => {
  const maxHour = 23;
  const ret = hour === maxHour ? maxHour : parseInt(hour) + addH;
  return ret;
};
