import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import TimeAgo from 'javascript-time-ago';

export const getCurrentLanguage = () => i18n?.languages?.[0];

export const defaultLanguage = 'en';
export const supportedLanguages = [
  'en',
  'es',
  'fr',
  'pt',
  'ja',
  'tr',
  'id',
  'zh',
  'zh-CN',
  'zh-TW'
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .on('initialized', async () => {
    const timeAgoLocale = await import(
      `javascript-time-ago/locale/${getCurrentLanguage()}`
    );
    TimeAgo.addDefaultLocale(timeAgoLocale);
  })
  .init({
    whitelist: supportedLanguages,
    nonExplicitWhitelist: true,
    load: 'languageOnly',
    fallbackLng: {
      default: [defaultLanguage],
      ...Object.fromEntries(
        supportedLanguages
          .filter((lang) => lang !== defaultLanguage)
          .map((lang) => [lang, [lang]])
      )
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
