import styled from 'styled-components';

export const StyleTableUsersProyect = styled.div`
  .rounded-img-user {
    border-radius: 0px;
  }
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-right: 0px;
    border-top: 0px;
    border-left: 0px;
  }

  .ant-select-selection {
    background-color: #2c3421 !important;
    color: white;
  }
  .ant-input-group-addon {
    border-right: 0px;
    border-top: 0px;
    border-left: 0px;
  }

  .ant-input-group-addon {
    border: 0px solid transparent !important;
    background-color: white !important;
  }

  .grid-options-users {
    display: grid;
    grid-template-columns: 70% 30%;
  }

  .ant-table-tbody > tr {
    box-shadow: none !important;
    border-radius: 10px;
  }

  .ant-upload {
    height: 35px !important;
  }

  .btn-table-users {
    text-align: center;
    user-select: none;
    padding: 0.33rem 0.3rem;
    font-size: 10px;
    line-height: 1.5;
    display: block;
    border: 0px solid #000000;
    cursor: pointer;
    color: white;
    width: 80px;
  }

  .bg-btn-activo {
    background-color: #34af00;
  }
  .bg-btn-desactivo {
    background-color: #586666;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 2px 13px !important;
    overflow-wrap: break-word !important;
    font-size: 11.5px !important;
  }

  .ant-select-selection {
    width: 90px;
    height: 27px !important;
  }

  .btn-table-add {
    text-align: center;
    user-select: none;
    padding: 0.15rem 0.1rem;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
    color: white;
    width: 152px;
    border-radius: 5px;
    border: none;
    outline: none;
  }

  .bg-btn-add {
    background-color: #7dff8a;
    color: #121212;
    margin: 0 5px;
  }

  .ant-input-group .ant-input {
    height: 25px !important;
  }

  @media (min-width: 1478px) {
    .grid-options-users {
      display: grid;
      grid-template-columns: 70% 30%;
    }
  }

  @media (min-width: 1647px) {
    .grid-options-users {
      display: grid;
      grid-template-columns: 70% 30%;
    }
  }
  @media (min-width: 2043px) {
    .grid-options-users {
      display: grid;
      grid-template-columns: 70% 30%;
    }
  }
  .rounded-img-user:hover {
    transform: scale(2.5);
  }
`;

export const SelectionStyle = styled.div`
  .ant-select-selection {
    border-radius: 5px !important;
  }
  .ant-select-selection:hover {
    background-color: white !important;
    color: #2c3421;
  }
  .anticon svg {
    color: #2c3421;
  }
`;
