import { DatePicker, Popover } from 'antd';
import moment from 'moment';
import React from 'react';

const { RangePicker } = DatePicker;

export const LookaheadDateRangePicker = (props) => {
  const {
    isPlanification = false,
    visible = false,
    LookaheadFilterHeaderStyles = {},
    dateRangePickerOptions = [],
    middleDatesChange = () => {},
    presetDateChange = () => {},
    dateRange = { current: null },
    visualizationOp = {},
    t = () => {}
  } = props;

  if (isPlanification && !visible) return null;

  return (
    <div className="lookahead-range-picker">
      {dateRangePickerOptions.map((item, index) => {
        if (item == 'free') {
          return (
            <div key={index}>
              <Popover
                content={
                  <div style={{ zIndex: 3, position: 'relative' }}>
                    <RangePicker
                      onChange={middleDatesChange}
                      defaultValue={[
                        moment(dateRange.current.start, 'YYYY/MM/DD'),
                        moment(dateRange.current.end, 'YYYY/MM/DD')
                      ]}
                    />
                  </div>
                }
                placement="right"
                trigger="click">
                <div
                  className={`${LookaheadFilterHeaderStyles.dateRangeOptions} ${visualizationOp?.lookahead_range.includes(item) ? LookaheadFilterHeaderStyles.dateRangeSelection : ''}`}>
                  {t('filters_label.lookahead_free')}
                </div>
              </Popover>
            </div>
          );
        }

        if (item == '1') {
          return (
            <div
              key={index}
              onClick={() => presetDateChange(item)}
              className={`${LookaheadFilterHeaderStyles.dateRangeOptions} ${visualizationOp?.lookahead_range.includes(item) ? LookaheadFilterHeaderStyles.dateRangeSelection : ''}`}>
              {t('filters_label.lookahead_this_week')}
            </div>
          );
        }

        return (
          <div
            key={index}
            onClick={() => presetDateChange(item)}
            className={`${LookaheadFilterHeaderStyles.dateRangeOptions} ${visualizationOp?.lookahead_range.includes(item) ? LookaheadFilterHeaderStyles.dateRangeSelection : ''}`}>
            {item} {t('filters_label.lookahead_plural_week')}
          </div>
        );
      })}
    </div>
  );
};
