export const ACTIONS = {
  DATA_TO_ASSIGN: 'to_assign',
  ASSIGN_ACTION: 'assign_action',
  DISABLE_BTN: 'disable_button'
};

export const ACTIONS_REDUCERS = {
  [ACTIONS.DATA_TO_ASSIGN]: (state, action) => ({
    ...state,
    toAssign: action.payload
  }),
  [ACTIONS.ASSIGN_ACTION]: (state, action) => ({
    ...state,
    assignAction: action.payload
  }),
  [ACTIONS.DISABLE_BTN]: (state, action) => ({
    ...state,
    disableBtn: action.payload
  })
};

export const reducer = (state, action) => {
  const actionsReducers = ACTIONS_REDUCERS[action.type];
  return actionsReducers ? actionsReducers(state, action) : state;
};

export const initState = {
  disableBtn: true,
  assignAction: true,
  toAssign: null
};
