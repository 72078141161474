/** React stuff */
import React, { useState, useEffect } from 'react';

/** Components from ant design css framework */
import { Row, Col, Select, Icon, DatePicker, Dropdown, Input } from 'antd';

/** Date handler lib */
import moment from 'moment';

/** Component that handle filtering on lookahead data */
import LookaheadFilter from '../../LookaheadFilter';

/** Component that handle ordering on lookahead data */
import LookaheadOrder from '../../LookaheadOrder';

/** Component that handle grouping on lookahead data */
import LookaheadGroup from '../../LookaheadGroup';

/** Component for multiselect elements on checkbox display */
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

/** import common functions from utils */
import { group, date, filter, order, columns, grid } from '../../../utils';

/** import IconComponent to SVG images */
import IconComponent from '../../Projects/IconSvg';

/** Redux */
import { useSelector, useDispatch } from 'react-redux';
import { resourceActions } from '../../../redux/actions/resourceActions';

/** To clone objects on JS */
import cloneDeep from 'lodash/cloneDeep';

/** Styles on pure css */
import './index.css';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;
/**
 * This component handles the filtering options at lookahead view
 * @param {*} props { changeDateState, dateRange, updateTableMetadata, tableMetadata, width }
 */
export default function FilterHeader(props) {
  const [toSearchOnPage, setToSearchOnPage] = useState('');
  const [counterSearch, setCounterSearch] = useState({
    current: null,
    total: null
  });

  /** Saves the hidden of dropdown for dates */
  const [datesVisibility, setDatesVisibility] = useState(false);

  /** Saves the hidden of dropdown for filters */
  const [filterVisibility, setFilterVisibility] = useState(false);

  /** Saves the hidden of dropdown for order */
  const [orderVisibility, setOrderVisibility] = useState(false);

  /** Saves the hidden of dropdown for grouping */
  const [groupVisibility, setGroupVisibility] = useState(false);

  /** Array with elements to show at hide/show cols */
  const [children, setChildren] = useState([]);

  /** Array with selected options at hide/show cols */
  const [selectedOption, setSelectedOption] = useState([]);

  /** lookahead state from redux */
  const resourceState = useSelector((state) => state.resourceState);

  /** function to emit events with actions at redux */
  const dispatch = useDispatch();

  /** This effect loads the elements for hide/show cols */
  useEffect(() => {
    /** This function build the option for multi select format { label, value } */
    for (let i = 0; i < props.tableMetadata.length; i++) {
      const name = props.tableMetadata[i].name;
      if (name != 'add' && name != 'name') {
        children.push({
          label: props.tableMetadata[i].label,
          value: props.tableMetadata[i].name
        });
      }
    }

    /** This section code deal when redux has a state, turning on those ones, and disabling which not */
    if (resourceState.activatedColumns.length) {
      const activated = resourceState.activatedColumns.map((op) => {
        const col = props.tableMetadata.filter((c) => c.name == op);
        if (col.length != 0) {
          return { label: col[0].label, value: col[0].name };
        }
      });
      const all = [];

      props.tableMetadata.map((el) => {
        if (el.name != 'name') {
          all.push(el.name);
        }
      });

      /** difference between arrays (all/active) to disable */
      const colsToDisable = all.filter(
        (x) => !resourceState.activatedColumns.includes(x)
      );
      const colsToEnable = all.filter((x) =>
        resourceState.activatedColumns.includes(x)
      );

      updateVisibilityCols(colsToEnable, colsToDisable);
      setSelectedOption(activated);

      /** This code deals with no redux state, and use default config from table.layout.js */
    } else {
      const names = [];
      const activated = props.tableMetadata.map((col) => {
        if (col.visible) {
          names.push(col.name);
          return { label: col.label, value: col.name };
        }
      });

      dispatch(resourceActions.setActivatedColumns(names));
      setSelectedOption(activated);
    }
  }, []);

  const updateVisibilityCols = (toEnable, toDisable) => {
    const tableData = cloneDeep(props.tableMetadata);
    toEnable.map((columnName) => {
      const column = tableData.filter((c) => c.name == columnName);
      column[0].visible = true;
    });
    toDisable.map((columnName) => {
      const column = tableData.filter((c) => c.name == columnName);
      column[0].visible = false;
    });
    props.updateTableMetadata(tableData);
  };

  /**
   * This function changes the visibility for an array of cols
   * @param {*} cols Array with cols to change visible status
   * @param {*} enabled Boolean, true for show col, and false for hide col
   */
  const changeColsVisibility = (cols, enabled) => {
    const tableData = cloneDeep(props.tableMetadata);
    cols.map((columnName) => {
      const column = tableData.filter((c) => c.name == columnName);
      column[0].visible = enabled;
    });
    props.updateTableMetadata(tableData);
  };

  /**
   * This function deals with the change of multi select.
   * Deals with  deleting, and clicking news cols.
   * @param {*} value Array of values for multi select of Ant Design Framework
   */
  function handleChange(value) {
    setSelectedOption(JSON.parse(JSON.stringify(value)));
    value = value.map((v) => v.value);

    /** Then by mapping array, can active the columns indeed and render methods will take effect visually */
    dispatch(resourceActions.setActivatedColumns(value));
    changeColsVisibility(value, true);

    /** This if statement allows to check if there is some fields to disable */
    if (value.length < resourceState.activatedColumns.length) {
      const colsToDisable = resourceState.activatedColumns.filter(
        (x) => !value.includes(x)
      );
      changeColsVisibility(colsToDisable, false);
    }
  }

  /**
   * This functions changes the visibility of dropdown for dates
   * @param {*} val Boolean, true to show dropdown, and false to hide dropdown
   */
  const handleDatesDropdown = (val) => {
    setDatesVisibility(val);
  };

  /**
   * This function hides the date dropdown selector before executing the query to bring activities
   * @param {*} a start_date
   * @param {*} b end_date
   */
  const middleDatesChange = (a, b) => {
    setDatesVisibility(false);
    props.changeDateState(a, b);
  };

  /**
   * This functions changes the visibility of dropdown for filters
   * @param {*} val Boolean, true to show dropdown, and false to hide dropdown
   */
  const handleFilterDropdown = (val) => {
    setFilterVisibility(val);
  };

  /**
   * This functions changes the visibility of dropdown for ordering
   * @param {*} val Boolean, true to show dropdown, and false to hide dropdown
   */
  const handleOrderDropdown = (val) => {
    setOrderVisibility(val);
  };

  /**
   * This functions changes the visibility of dropdown for grouping
   * @param {*} val Boolean, true to show dropdown, and false to hide dropdown
   */
  const handleGroupDropdown = (val) => {
    setGroupVisibility(val);
  };

  const onSearchChange = (search) => {
    if (toSearchOnPage != search) {
      window.finder.findTerm(search);
    } else {
      window.finder.nextResult();
    }
    const newCounterState = {
      current: window.finder.currentResult,
      total: window.finder.resultsCount
    };

    setCounterSearch(newCounterState);
    setToSearchOnPage(search);
  };

  const getCounterForSearch = () => {
    if (counterSearch.current && counterSearch.total) {
      return (
        <span className="counter-search-on-page">
          {counterSearch.current}/{counterSearch.total}
        </span>
      );
    }
    return null;
  };

  /** Render */
  return (
    <Row className="resources-filter-header">
      <Col span={3}>
        <Select
          placeholder="Select View"
          className="resources-view-planification"
          dropdownClassName="resources-view-dropdown-planification"
          value="card"
          onChange={(e) => console.log('Event: ', e)}>
          <Option value="card">
            <IconComponent
              data={grid}
              fill="#7DFF8A"
              className="svg-icon-grid-view"
            />
            Vista de Tabla
          </Option>
        </Select>
      </Col>
      <Col
        style={{ height: '100%', textAlign: 'center' }}
        span={2}
        title="Lookahead Range">
        <Dropdown
          overlay={
            <div>
              <RangePicker
                onChange={middleDatesChange}
                defaultValue={[
                  moment(props.dateRange.start, 'YYYY/MM/DD'),
                  moment(props.dateRange.end, 'YYYY/MM/DD')
                ]}
              />
            </div>
          }
          trigger={['click']}
          visible={datesVisibility}
          onVisibleChange={handleDatesDropdown}>
          <div style={{ cursor: 'pointer', fontSize: '12px' }}>
            <IconComponent
              data={date}
              fill="#53C255"
              className="svg-icon-date"
            />
            Fecha
          </div>
        </Dropdown>
      </Col>
      <Col style={{ height: '100%', textAlign: 'center' }} span={2}>
        <Dropdown
          overlay={
            <LookaheadFilter
              updateRender={props.updateRender}
              options={props.tableMetadata}
              data={props.data}
              toSelectResponsables={props.toSelectResponsables}
            />
          }
          trigger={['click']}
          visible={filterVisibility}
          onVisibleChange={handleFilterDropdown}>
          <div style={{ cursor: 'pointer', fontSize: '12px' }}>
            <IconComponent
              data={filter}
              fill="#53C255"
              className="svg-icon-date"
            />
            Filtros
          </div>
        </Dropdown>
      </Col>
      <Col style={{ height: '100%', textAlign: 'center' }} span={2}>
        <Dropdown
          overlay={
            <LookaheadOrder
              updateRender={props.updateRender}
              options={props.tableMetadata}
              data={props.data}
            />
          }
          trigger={['click']}
          visible={orderVisibility}
          onVisibleChange={handleOrderDropdown}>
          <div style={{ cursor: 'pointer', fontSize: '12px' }}>
            <IconComponent
              data={order}
              fill="#53C255"
              className="svg-icon-order"
            />
            Orden
          </div>
        </Dropdown>
      </Col>
      <Col style={{ height: '100%', textAlign: 'center' }} span={2}>
        <Dropdown
          overlay={
            <LookaheadGroup
              groupBy={props.groupBy}
              modifyGroupBy={props.modifyGroupBy}
              options={props.tableMetadata}
              data={props.data}
            />
          }
          trigger={['click']}
          visible={groupVisibility}
          onVisibleChange={handleGroupDropdown}>
          <div style={{ cursor: 'pointer', fontSize: '12px' }}>
            <IconComponent
              data={group}
              fill="#53C255"
              className="svg-icon-group"
            />
            Agrupar
          </div>
        </Dropdown>
      </Col>
      <Col style={{ height: '100%', textAlign: 'center' }} span={2}>
        <ReactMultiSelectCheckboxes
          getDropdownButtonLabel={() => (
            <div
              style={{ color: '#121212', fontSize: '12px' }}
              onClick={() => {
                setTimeout(() => {
                  const a = document.getElementsByClassName(
                    'css-1pcexqc-container'
                  );
                  a[0].parentNode.style.marginTop = '0px';
                }, 20);
              }}>
              <IconComponent
                data={columns}
                fill="#53C255"
                className="svg-icon-columns"
              />
              Columnas
            </div>
          )}
          placeholderButtonLabel="Columns"
          value={selectedOption}
          onChange={handleChange}
          options={children}
        />
      </Col>

      <div className="resources-search-on-page-bar">
        <Search
          prefix={getCounterForSearch()}
          placeholder="Buscar"
          onSearch={(value) => onSearchChange(value)}
        />
      </div>
    </Row>
  );
}
