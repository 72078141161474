import { useReducer } from 'react';

const ACTIONS = {
  SET_FIELD: 'SET_FIELD',
  RESET: 'RESET',
  SET_LOADING: 'SET_LOADING',
  TOGGLE_MORE_DETAILS: 'TOGGLE_MORE_DETAILS'
};

export const initialState = {
  projectName: '',
  projectType: 'commercial',
  location: 'US',
  currency: 'USD',
  budget: '0',
  unit: 'ft2',
  size: '0',
  projectImage: false,
  projectImageURL: '',
  isLoading: false,
  isExistChanges: false,
  moreDetailsExpanded: false
};

function formReducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_FIELD:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
        isExistChanges: true
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case ACTIONS.RESET:
      return initialState;
    case ACTIONS.TOGGLE_MORE_DETAILS:
      return {
        ...state,
        moreDetailsExpanded: !state.moreDetailsExpanded
      };
    default:
      return state;
  }
}

export const useFormProject = (initialState) => {
  const [state, dispatch] = useReducer(formReducer, initialState);

  const setField = (name, value) => {
    dispatch({
      type: ACTIONS.SET_FIELD,
      payload: { name, value }
    });
  };

  const setLoading = (isLoading) => {
    dispatch({
      type: ACTIONS.SET_LOADING,
      payload: { isLoading }
    });
  };

  const resetForm = () => {
    dispatch({ type: ACTIONS.RESET });
  };

  const toggleMoreDetails = () => {
    dispatch({ type: ACTIONS.TOGGLE_MORE_DETAILS });
  };

  return { state, setField, setLoading, resetForm, toggleMoreDetails };
};
