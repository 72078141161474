/** Table Titles */
export const tableMetadata = [
  {
    name: 'name',
    label: 'Nombre del Recurso',
    data_type: 'string',
    visible: true,
    span: 2,
    offset: 0,
    align: 'left',
    width: 350,
    proportion: 0.4
  },
  {
    name: 'material_label',
    label: 'Unidad',
    data_type: 'string',
    visible: true,
    span: 1,
    offset: 0,
    align: 'center',
    width: 100,
    proportion: 0.1
  },
  {
    name: 'total',
    label: 'Total',
    data_type: 'number',
    visible: true,
    span: 1,
    offset: 0,
    align: 'center',
    width: 100,
    proportion: 0.1
  },
  {
    name: 'used',
    label: 'Utilizado',
    data_type: 'number',
    visible: true,
    span: 1,
    offset: 0,
    align: 'center',
    width: 100,
    proportion: 0.1
  },
  {
    name: 'assignartask',
    label: 'Actividades Asignadas',
    data_type: 'string',
    visible: true,
    span: 1,
    offset: 0,
    align: 'center',
    width: 100,
    proportion: 0.1
  },
  {
    name: 'assignar',
    label: 'Asignar',
    data_type: 'button',
    visible: true,
    span: 1,
    offset: 0,
    align: 'center',
    width: 250,
    proportion: 0.2
  }
];
