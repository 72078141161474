/**
 * Function to find the set with the highest Early Start (ES).
 * @param {Array<Object>} arrayCalculatedLinks - Array of objects containing earlyFinish (EF) and earlyStart (ES).
 * @returns {Object} - The set with the highest Early Start (ES).
 */

function getMaxEarlyStartSet(arrayCalculatedLinks) {
  return arrayCalculatedLinks.reduce((maxObj, currentObj) =>
    currentObj.ef > maxObj.ef ? currentObj : maxObj
  );
}

export { getMaxEarlyStartSet };
