import { requestRender } from '../events/utils';
import { submittal_icon_task_layer } from '../gantt_styles_config/task-gantt-style';

/**
 * Toggles the visibility of submittals in the Gantt chart and updates the configuration.
 *
 * @param {Object} gantt - The Gantt chart object.
 * @param {Function} trackEventBasicProps - Function to track basic events.
 * @param {Function} dispatchGanttVisualizationConfigChange - Function to dispatch changes to the Gantt visualization configuration.
 *
 * @example
 * changeSubmittalsVisibility(ganttInstance, trackEvent, dispatchConfigChange);
 */
export const changeSubmittalsVisibility = (
  gantt,
  trackEventBasicProps,
  dispatchGanttVisualizationConfigChange
) => {
  const config = gantt.config;
  config.show_submittal_icon = !config.show_submittal_icon;

  if (config.show_submittal_icon) {
    gantt.idTaskLayerSubmittal = gantt.addTaskLayer(
      submittal_icon_task_layer(gantt)
    );
    gantt.iconSubmittalsArray.push(gantt.idTaskLayerSubmittal);
    trackEventBasicProps('show_submittals_icon');
  } else {
    gantt.iconSubmittalsArray.forEach((id) => {
      gantt.removeTaskLayer(id);
    });
    gantt.iconSubmittalsArray = [];
    trackEventBasicProps('hide_submittals_icon');
  }

  requestRender();
  dispatchGanttVisualizationConfigChange();
};
