import React from 'react';
import { Tooltip } from 'antd';

export const splitText = (text, width) => {
  if (typeof text === 'string') {
    let showedText = text;
    let tooltip;
    const colAdjustWidth = Math.trunc(width / 5);
    const textAdjustWidth = showedText.length * 2;

    if (textAdjustWidth > colAdjustWidth) {
      showedText = text.slice(0, Math.trunc(colAdjustWidth / 2)) + '...';
      tooltip = true;
    }
    return tooltip ? (
      <Tooltip placement="top" title={text}>
        {showedText}
      </Tooltip>
    ) : (
      showedText
    );
  }
  return text;
};

export const groupActivitiesByName = (linealActivitiesArray) => {
  const grouppedActivitiesArray = [];
  const activitiesWithChild = linealActivitiesArray.filter(
    (ac) => !ac.has_childs
  );
  activitiesWithChild.map((ac) => {
    ac.name = ac.name.trim();
    const doesExistAtMap = grouppedActivitiesArray.find(
      (act) => act.name.trim() == ac.name
    );
    if (ac.productionUnits.length) {
      if (doesExistAtMap) {
        doesExistAtMap.activities.push(ac);
      } else {
        grouppedActivitiesArray.push({
          name: ac.name,
          activities: [ac]
        });
      }
    }
  });
  return grouppedActivitiesArray;
};
