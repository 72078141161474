import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../redux/slices/projectSlice';

// Components Styles
import { Dropdown, notification, Tooltip } from 'antd';

// Assets
import styles from './index.module.css';
import { SmileOutlined } from '@ant-design/icons';
import configIconHeader from '../../assets/img/gantt/config-icon-header.png';
import { projectService } from '../../services';

const Form = ({ handleSubmit, hoursPerDay }) => (
  <div className="config-header-container">
    <form onSubmit={handleSubmit} className={styles.formHours}>
      <label>Horas de trabajo por dia</label>
      <input
        type="number"
        name="hoursPerDay"
        min="4"
        max="20"
        defaultValue={hoursPerDay}
        placeholder="Horas de trabajo"
        className="structure-input"
      />
    </form>
  </div>
);

const SettingsHours = (props) => {
  const { hoursPerDay, t } = props;
  const [hoursConfigVisibility, setHoursConfigVisibility] = useState(false);

  const projectState = useSelector((state) => state.projectState);
  const dispatch = useDispatch();

  const handleHoursConfigDropdown = (show) => {
    setHoursConfigVisibility(show);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const input = e.target.querySelector('input');
      await projectService.updateHoursPerDay({
        id: projectState.projectSelected,
        hoursPerDay: input.value
      });
      notification.open({
        message: 'Hora de trabajo actualizada',
        description: `Ahora son ${input.value} horas por dia`,
        icon: <SmileOutlined style={{ color: '#108ee9' }} />
      });

      const allProjects = projectState.allProjects.map((project) => {
        if (project.id == projectState.projectSelected) {
          return { ...project, hoursPerDay: input.value };
        }
        return { ...project };
      });

      dispatch(projectActions.setAllProjects(allProjects));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.settingsHours}>
      <Dropdown
        overlay={<Form handleSubmit={handleSubmit} hoursPerDay={hoursPerDay} />}
        trigger={['click']}
        visible={hoursConfigVisibility}
        onVisibleChange={handleHoursConfigDropdown}>
        <Tooltip title={t('master_plan.config_label')}>
          <div className={styles.tooltipImg}>
            <img src={configIconHeader} className="config-header-icon"></img>
          </div>
        </Tooltip>
      </Dropdown>
    </div>
  );
};

export default SettingsHours;
