import React, { useState, useEffect } from 'react';
import LayoutLoginOutbuild from '../../components/LayoutLoginOutbuild';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import CompanyOptions from './companyOptions';
import { useDispatch } from 'react-redux';
import { stateActions } from '../../redux/actions/stateActions';
import { useHistory } from 'react-router-dom';
import './index.scss';
import {
  getCompaniesByUser,
  getSessionToken
} from './companyOptions/companyOptions.helpers';

function SelectCompanyView(props) {
  const { t } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const getCompany = async () => {
      const MIN_COMPANY = 1;
      const response = await getCompaniesByUser();
      if (response.length === MIN_COMPANY) {
        await getSessionToken(response[0].companyId, history);
      } else setVisible(true);
    };
    getCompany();
  }, []);

  const signed = localStorage.getItem('signed');
  if (!signed) {
    return <Redirect to="/login" />;
  }

  const sessionToken = sessionStorage.getItem('sessionToken');
  if (sessionToken) {
    return <Redirect to="/projects" />;
  }

  // redirects
  const logout = (e) => {
    const wishAction = (_) => {
      e.preventDefault();
      dispatch(stateActions.clearAllStoreData());
      localStorage.clear();
      sessionStorage.clear();
      history.push('/login');
    };
    wishAction();
  };

  const renderSelectCompanyView = (
    <div>
      <LayoutLoginOutbuild title={t('selectOrganization.title')}>
        <div className="SelectCompanyView__container">
          <div className="SelectCompanyView__description">
            {t('selectOrganization.description')}
          </div>
          <CompanyOptions dark={false} />
          <div className="SelectCompanyView__logout">
            <a
              onClick={(e) => {
                logout(e);
              }}>
              {t('selectOrganization.logout')}
            </a>
          </div>
        </div>
      </LayoutLoginOutbuild>
    </div>
  );

  return visible ? renderSelectCompanyView : null;
}

export default withTranslation()(SelectCompanyView);
