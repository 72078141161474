function updateTaskOnResize(task, mode, original) {
  if (mode == 'resize') {
    task.modeDrag = 'resize';
    if (
      Number(task.start_date) > Number(original.start_date) ||
      Number(task.start_date) < Number(original.start_date)
    ) {
      if (/* task.is_lookahead || */ task.progress > 0) {
        task.start_date = original.start_date;
        task.end_date = original.end_date;
        task.duration = original.duration;
      }
    }
  }
}

export { updateTaskOnResize };
