const setCollapsedFilter = (collapsed = [], gantt) => {
  gantt.batchUpdate(() => {
    if (!collapsed.length) return;
    collapsed.forEach((activityId) => {
      const activitiy = gantt.getTask(activityId);
      if (!activitiy) return;
      activitiy.$open = false;
    });
  });
};

export default setCollapsedFilter;
