import { combineReducers } from 'redux';

import companyState from './companyReducer';
import userState from './userReducer';
import calendarState from './calendarReducer';
import lookaheadState from './lookaheadReducer';
import constraintState from './constraintReducer';
import weeklyplanState from './weeklyplanReducer';
import widgetReducer from './weeklyPlanWidget';
import resourceState from './resourseReducer';
import cncState from './cncReducer';
import lookaheadResourceState from './lookaheadResourceReducer';
import assistanceState from './assistanceReducer';
import routerState from './routerReducer';
import curveResourceState from './curveResourceReducer';
import productivityChartState from './productivityChartReducer';
import timelineState from './lookaheadTimelineReducer';
import { activityReducer, activitySliceKey } from '../slices/activitySlice';
import { ganttReducer, ganttSliceKey } from '../slices/ganttSlice';
import {
  hoveringStackReducer,
  hoveringStackSliceKey
} from '../slices/hoveringStackSlice';
import {
  nonSerializableReducer,
  nonSerializableSliceKey
} from '../slices/nonSerializableSlice';
import { projectReducer, projectSliceKey } from '../slices/projectSlice';
import { selectCompanyReducer, companySliceKey } from '../slices/CompanySlice';
import { taskReducer, taskSliceKey } from '../slices/taskSlice';
import copyPasteState from './copyPasteReducer';
import submittalState from './submittalsReducer';
import propertiesState from './propertiesReducer';
import companySettingsState from './companySettingsReducer';

const originalReducers = {
  companyState,
  userState,
  calendarState,
  lookaheadState,
  lookaheadResourceState,
  constraintState,
  weeklyplanState,
  widgetReducer,
  resourceState,
  cncState,
  assistanceState,
  routerState,
  curveResourceState,
  productivityChartState,
  timelineState,
  copyPasteState,
  submittalState,
  propertiesState,
  companySettingsState,
  [activitySliceKey]: activityReducer,
  [ganttSliceKey]: ganttReducer,
  [hoveringStackSliceKey]: hoveringStackReducer,
  [nonSerializableSliceKey]: nonSerializableReducer,
  [projectSliceKey]: projectReducer,
  [companySliceKey]: selectCompanyReducer,
  [taskSliceKey]: taskReducer
};

const appReducer = combineReducers(originalReducers);

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_ALL_REDUCERS_DATA') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
