import { Tooltip } from 'antd';
import React from 'react';

export const Toast = {
  init() {
    this.hideTimeout = null;
    this.toast =
      document.querySelector('.toast') || document.createElement('div');
    this.toast.className = 'toast';
    document.body.appendChild(this.toast);
  },
  show(message, state, timeount) {
    clearTimeout(this.hideTimeout);
    if (!this.toast.hasChildNodes()) {
      this.toastMessage = document.createElement('p');
      this.toastMessage.className = 'toast__message';
    }

    this.toastMessage.innerHTML = message;
    this.toast.className = 'toast toast--visible';
    this.toast.appendChild(this.toastMessage);

    state && this.toast.classList.add(`toast--${state}`);

    this.hideTimeout = setTimeout((_) => {
      this.toast.classList.remove('toast--visible');
      this.toast.classList.remove(`toast--${state}`);
    }, timeount);
  },
  destroy() {
    this.toast.remove();
  }
};

export const splitText = (text, columnWidth) => {
  if (typeof text === 'string') {
    let showedText = text;
    let tooltip;

    if (text.length > columnWidth) {
      showedText = text.slice(0, Math.trunc(columnWidth)) + '...';
      tooltip = true;
    }
    return tooltip ? (
      <Tooltip placement="top" title={text}>
        {showedText}
      </Tooltip>
    ) : (
      showedText
    );
  }
  return text;
};
