/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
import { base } from './base';
import axios from 'axios';

const baseURL =
  base.backendExportable || 'https://www.backendservice.link/api/';

class ActivityPdfService {
  constructor() {
    this.http = createHttpClient({
      baseURL,
      maxContentLength: Infinity,
      maxBodyLength: Infinity
    });
  }

  async exportToPDF(data) {
    const res = await this.http
      .post('activitys/exportpdf', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async checkBackendAccess() {
    try {
      await this.http.get('auth/healthCheck');
      return true;
    } catch (error) {
      return false;
    }
  }
}

export const activityPdfService = new ActivityPdfService();
