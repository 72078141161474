import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../../redux/slices/projectSlice';
import { sectorService, userService } from '../../../services';
import { WarningIcon } from '../../../icons';
import ModalSystem from '../../DesignSystem/ModalSystem';
import { SetCurrentStageComp } from './icons';
import {
  getTypeNotification,
  notifyMessageCustom,
  dynamicSort
} from '../../../utils';
import { trackingEvent } from '../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';
import * as Sentry from '@sentry/react';

const SetAsCurrentStage = ({ show, setShow, t }) => {
  const dispatch = useDispatch();
  const { projectState } = useSelector((state) => state);
  const {
    projectState: { sectorSelectedRename, allSectors }
  } = useSelector((state) => state);

  const handleCloseModal = () => {
    setShow(false);
  };

  const getSectorsByProject = useCallback(async (idProject) => {
    const resp = await sectorService.getSectorsByProject(idProject);
    return resp ? resp.sectors : false;
  }, []);

  const callGetSectors = useCallback(async () => {
    const sectors = await getSectorsByProject(projectState.projectSelected);
    if (sectors) {
      let filterSectors = sectors.filter((e) => e.status === true);
      dispatch(projectActions.setAllSectors(filterSectors));
    }
  }, [projectState.projectSelected]);

  useEffect(() => {
    callGetSectors();
  }, [callGetSectors]);

  const updateSectorFieldsBySectorId = async (sectorId, value) => {
    const fields = [{ field: 'set_current', value: value }];
    await sectorService.updateFieldsBySectorId(sectorId, fields);
  };

  const updateCurrentSchedule = async (selectedSectorId, currentSectorId) => {
    await Promise.all([
      updateSectorFieldsBySectorId(selectedSectorId, true),
      updateSectorFieldsBySectorId(currentSectorId, false)
    ]);
  };

  const refreshProjects = async (userId) => {
    if (!userId) return;
    const getProjects = await userService.projectsbyuserthrough(userId);
    if (!getProjects) return;

    const projectsActive = getProjects.projects.filter(
      (project) => project.stage !== 'deleted'
    );
    const sortProjects = projectsActive.sort(dynamicSort('name'));
    dispatch(projectActions.setAllProjects(sortProjects));
  };

  const handleSetAsCurrentStage = async () => {
    try {
      const newAllSectors = [...allSectors];
      const currentSectorId = newAllSectors.findIndex(
        (sector) => sector.id === sectorSelectedRename?.id
      );
      const currentSetSchedule = projectState.allSectors.filter(
        (sector) => sector?.set_current
      );

      await updateCurrentSchedule(
        sectorSelectedRename?.id,
        currentSetSchedule[0]?.id
      );

      if (currentSectorId > -1) {
        dispatch(projectActions.setAllSectors(newAllSectors));

        setShow(false);
        await callGetSectors();

        trackingEvent(
          'set_current_schedule',
          {
            ...getBasicAmplitudEventProperties(),
            schedule_set_as_current: sectorSelectedRename.name
          },
          AMPLITUDE_SERVICE
        );

        notifyMessageCustom({
          type: getTypeNotification(SetCurrentStageComp, 'withTitleIcon'),
          description: t(
            'notifications.set_current_schedule_success_msg'
          ).replace('__str__', sectorSelectedRename.name)
        });

        let currentUser = JSON.parse(localStorage.getItem('user'));
        await refreshProjects(currentUser?.id);
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };

  const contentChildren = () => (
    <div className="set-current__content">
      <div className="content__form">
        <div className="form__icon">
          <WarningIcon color="#7DFF8A" />
        </div>
        <div className="form__title">
          <h5> {t('set_as_current.description')}</h5>
        </div>
      </div>
      <div className="content__buttons">
        <button className="buttons__cancel" onClick={() => handleCloseModal()}>
          {t('set_as_current.button_cancel_text')}
        </button>
        <button
          className="buttons__confirm"
          onClick={() => handleSetAsCurrentStage()}>
          {t('set_as_current.button_confirm_text')}
        </button>
      </div>
    </div>
  );

  return ModalSystem({
    visible: show,
    theme: 'dark',
    setVisible: setShow,
    showTitle: false,
    centered: true,
    width: 400,
    children: contentChildren(),
    zIndex: 2000
  });
};

export default SetAsCurrentStage;
