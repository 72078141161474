import React from 'react';
import MoreOptionsPopover from './MoreOptionsPopover';
import './styles.css';
import { getSessionTokenData } from '../../../../utils/userUtils';

const ActionsCell = ({ project }) => {
  const sessionTokenData = getSessionTokenData();
  const availableRoles = ['superadmin', 'admin', 'projectleader'];

  if (!sessionTokenData || !availableRoles.includes(sessionTokenData.role)) {
    return null;
  }

  return (
    <div className="actions-cell react-dhtmlx">
      <MoreOptionsPopover project={project} />
    </div>
  );
};

export default ActionsCell;
