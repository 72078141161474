import { hexToCSSFilter } from 'hex-to-css-filter';

/**
 * Returns a string with the CSS filters to dye an icon in the color supplied as argument
 *
 * @param {string} color The desired color
 * @returns {string} A string with the CSS filters to dye an icon
 */
export function getColorFilters(color) {
  if (!color) return '';
  return hexToCSSFilter(color).filter.replace(';', '');
}
