import React from 'react';
import { Radio } from 'antd';

const RadioSystem = ({ theme = 'light', size = 'medium', ...props }) => (
  <Radio.Group
    className={`radio-design-system theme--${theme} size--${size}`}
    {...props}></Radio.Group>
);

export default RadioSystem;
