import { useEffect } from 'react';
import { filterByName } from '../../../utils/projectUtils';
import { HOME_TIMELINE_VIEW } from '../../../hooks/useSelectProject';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';

export const useFilterGanttSchedules = ({
  gantt,
  tracking,
  setFilteredSchedules,
  filterText,
  isLoading,
  schedules
}) => {
  useEffect(() => {
    if (isLoading) {
      return;
    }

    const filteredSchedules = schedules.projects.filter(
      filterByName(filterText)
    );

    if (gantt) {
      gantt.analytics = {
        search_text: filterText,
        results_count: filteredSchedules.length
      };
    }

    if (!filteredSchedules.length) {
      tracking.trackingEvent(
        'search_box_no_results',
        {
          search_text: filterText,
          event_source: HOME_TIMELINE_VIEW
        },
        AMPLITUDE_SERVICE
      );
    }

    setFilteredSchedules(filteredSchedules);
  }, [filterText, isLoading, schedules]);
};
