import { Popover } from 'antd';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { splitText } from '../../ActivitiesUnitsGrid/index.helper';

import { WidgetActions } from '../../../redux/actions/WidgetWeeklyPlanActions';
import { initialState } from '../../../redux/reducers/weeklyPlanWidget';

// Assets
import dropDown from '../../../assets/img/weeklyplanWidget/dropDown.svg';
import arrowUp from '../../../assets/img/weeklyplanWidget/arrowUp.svg';
import './index.css';
import useOutsideAlerter from '../../../hooks/useOutsideAlerter';

const Icon = ({ className }) => (
  <>
    <div className={className}></div>
    <div className={className}></div>
    <div className={className}></div>
  </>
);

const PercentageOption = ({ handlePercentageOption, resources, styles, t }) => (
  <div>
    <p
      className={`${styles.cursorPointer} ${styles.list}`}
      onClick={() => handlePercentageOption('percentage')}>
      {t('weekly_plan_widget.percentage')}
    </p>
    <hr className={styles.hr} />
    <p
      className={`${styles.subtitle} ${styles.cursorPointer} ${styles.popoverContent}`}>
      {t('weekly_plan_widget.resources')}
    </p>
    {resources.map((resource) => (
      <p
        key={resource.id}
        className={`${styles.cursorPointer} ${styles.list}`}
        onClick={() => handlePercentageOption(resource.name, resource.id)}>
        {splitText(resource.name, 120)}
      </p>
    ))}
  </div>
);

const Criteria = ({ handleCriteriaOption, t, styles }) => {
  return (
    <div>
      <p className={`${styles.subtitle} ${styles.popoverContent}`}>
        {t('weekly_plan_widget.criteria')}
      </p>
      <hr className={styles.hr} />
      <p
        onClick={() => handleCriteriaOption('base')}
        className={`${styles.cursorPointer} ${styles.list}`}>
        {t('weekly_plan_widget.base')}
      </p>
      <p
        onClick={() => handleCriteriaOption('following')}
        className={`${styles.cursorPointer} ${styles.list}`}>
        {t('weekly_plan_widget.following')}
      </p>
    </div>
  );
};

const Options = (props) => {
  const {
    resources,
    t,
    styles,
    hideOptions,
    sectorId,
    percentageOption,
    setPercentageOption,
    criteriaOption,
    setCriteriaOption,
    widgetData,
    resurce_ops
  } = props;
  const [visible, setVisible] = useState(false);
  const [visiblePersentaje, setVisiblePersentaje] = useState(false);
  const popovercriterioRef = useRef(null);
  const popoverpercentageRef = useRef(null);
  //useOutsideAlerter(popoverpercentageRef, () => handleHoverChangeOutside());
  //useOutsideAlerter(popovercriterioRef, () => setVisiblePersentaje(false));

  const handleHoverChangeOutside = () => {
    setVisible(false);
    setVisiblePersentaje(false);
  };

  // Redux hooks
  const dispatch = useDispatch();
  const text = useRef({
    percentage: t('weekly_plan_widget.percentage'),
    base: t('weekly_plan_widget.base'),
    following: t('weekly_plan_widget.following')
  });

  const existSectorId = (id) => {
    const foundSector = widgetData.sectorId.find(
      (widgetSectorId) => widgetSectorId == id
    );
    return !foundSector ? WidgetActions.add : WidgetActions.update;
  };

  const handlePercentageOption = (option, resourceId) => {
    setPercentageOption(option);
    const dispatchAction = existSectorId(sectorId);
    const payload = {
      sectorId: sectorId,
      criteriaOptions: criteriaOption,
      percentageOption: option
    };

    if (option != 'percentage') payload.resourceId = resourceId;

    dispatch(dispatchAction(payload));
    setVisiblePersentaje(false);
  };

  const handleCriteriaOption = (criteria) => {
    setCriteriaOption(criteria);
    const dispatchAction = existSectorId(sectorId);
    const payload = {
      sectorId: sectorId,
      criteriaOptions: criteria,
      percentageOption: percentageOption
    };

    if (percentageOption != 'percentage') payload.resourceId = resurce_ops;

    dispatch(dispatchAction(payload));
    setVisible(false);
  };

  const handleHoverChange = (visible) => {
    setVisible(true);
  };

  const handleHoverChangePersentaje = (visible) => {
    setVisiblePersentaje(true);
  };

  return (
    <>
      <Popover
        placement="bottom"
        className={styles.cursorPointer}
        trigger="click"
        visible={visiblePersentaje}
        onVisibleChange={handleHoverChangePersentaje}
        content={
          <span ref={popoverpercentageRef}>
            <PercentageOption
              resources={resources}
              handlePercentageOption={handlePercentageOption}
              styles={styles}
              t={t}
            />
          </span>
        }>
        <div className={`${styles.selection} ${styles.selectionLeft}`}>
          <span>
            {text.current[percentageOption] || splitText(percentageOption, 120)}
          </span>
          <img src={dropDown} width={8} />
        </div>
      </Popover>
      <Popover
        placement="bottom"
        className={styles.cursorPointer}
        visible={visible}
        trigger="click"
        onVisibleChange={handleHoverChange}
        content={
          <span ref={popovercriterioRef}>
            <Criteria
              styles={styles}
              handleCriteriaOption={handleCriteriaOption}
              t={t}
            />
          </span>
        }>
        <div className={`${styles.selection} ${styles.selectionRigth}`}>
          <span>{text.current[criteriaOption]}</span>
          <img src={dropDown} width={8} alt="" srcset="" />
        </div>
      </Popover>
      <span onClick={hideOptions} className={styles.hideOptionsBtn}>
        <img src={arrowUp} />
      </span>
    </>
  );
};

const WidgetOptions = ({ resources, styles, t, sectorId }) => {
  const { widgetData } = useSelector((state) => {
    if (!state.widgetReducer?.widgetData) {
      return initialState;
    }
    return state.widgetReducer;
  });

  const percentage =
    widgetData.byHash[sectorId]?.percentageOption ?? 'percentage';
  const criteria = widgetData.byHash[sectorId]?.criteriaOptions ?? 'base';
  const resurce_ops = widgetData.byHash[sectorId]?.resourceId;

  // Local state ==>
  const [showOptions, setShowOptions] = useState(false);
  const [percentageOption, setPercentageOption] = useState('');
  const [criteriaOption, setCriteriaOption] = useState('');

  const handleToggleOptions = () => setShowOptions(!showOptions);

  const handleShownOption = () => {
    if (!showOptions) {
      handleToggleOptions();
    }
  };

  return (
    <div
      className={`${styles.options} ${showOptions ? styles.showOptions : styles.hideOptions}`}
      onClick={handleShownOption}>
      {showOptions ? (
        <Options
          resources={resources}
          hideOptions={handleToggleOptions}
          sectorId={sectorId}
          t={t}
          styles={styles}
          percentageOption={percentage}
          criteriaOption={criteria}
          resurce_ops={resurce_ops}
          setCriteriaOption={setCriteriaOption}
          setPercentageOption={setPercentageOption}
          widgetData={widgetData}
        />
      ) : (
        <Icon className={styles.ellipse} />
      )}
    </div>
  );
};

export default WidgetOptions;
