import React, { useState, useEffect } from 'react';
import { Upload, message, Button, Icon, Row, Col } from 'antd';
import { reactRouterV4Instrumentation } from '@sentry/react';
import { subContractService, userService } from '../services';
import { getUserAndSub } from '../utils';

export default function useSubtradeUser(props) {
  const [returnObject, setReturnObject] = useState({
    user: null,
    subtrade: null,
    isSubtradeRole: null
  });

  const loadSubToState = async (userFromLocalstorage) => {
    const res = await getUserAndSub(userFromLocalstorage);
    if (!res) return;
    setReturnObject({
      user: res.user,
      subtrade: res.subtrade,
      isSubtradeRole: res.user.role === 'subtrade'
    });
  };

  useEffect(() => {
    const currentUserString = localStorage.getItem('user');
    if (!currentUserString) return;
    const currentUserObject = JSON.parse(currentUserString);
    if (!currentUserObject) return;
    loadSubToState(currentUserObject);
  }, []);

  return returnObject;
}
