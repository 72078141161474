import React from 'react';
import ReactDOM from 'react-dom';
import i18n from '../../i18n';
import './PopconfirmHTML.css';
import deleteIcon from '../../assets/img/newlookahead/name/delete.png';

export const TestIds = {
  TOOLBAR_CONTAINER: 'POPCONFIRM_CONTAINER'
};

/**
 * This component is to display a tootlip in the lookahead.
 * @param {*} container Container is the HTML object that will display the tooltip with onmouseover
 * @param {*} task_id Task_id is the id of the task assigned to the tooltip
 * @param {*} gantt Gantt is the instance of the gantt that is being displayed on the screen, by default it will be ganttVisualization
 * @param {*} gantt_string Gantt_string is the name of the instance of the gantt.
 * @param {*} name Name is the name that the tooltip will have in its id
 * @param {*} onclick Onclick is the function in string that will be executed when the ok button is clicked.
 */
export default function PopconfirmHTML({
  container = '<div></div>',
  task_id = 0,
  gantt = window.ganttVisualization,
  gantt_string = 'window.ganttVisualization',
  name = '',
  onclick = ''
}) {
  const idPopConfirm = 'PopConfirmHTML-' + task_id + '-' + name;
  if (!gantt.Popconfirm_create) {
    gantt.Popconfirm_create = (id, click) => {
      const idPopConfirm = 'PopConfirmHTML-' + id + '-' + name;
      const popConfirmDiv = document.getElementById(idPopConfirm);
      const popPosition = popConfirmDiv.getBoundingClientRect();

      const content = `<div class="PopConfirmHTMLElement" onclick="${gantt_string}.Popconfirm_create(${id}, '${click}')">
                                    ${container}
                            </div>
                            <span class="PopConfirmHTMLContent" style="top: ${popPosition.top}px; left:${popPosition.left}px;">
                                <div class="PopConfirmHTMLContent__title">
                                    <img src=${deleteIcon} style="width: 18px; height:18px;color:red" />
                                    <div class="ant-popover-message-title"  style="padding: 0 0 0 8px">${i18n.t('lookahead_resources.confir_delete')}</div>
                                </div>
                                <div class="PopConfirmHTMLContent__buttons">
                                    <button type="button" class="ant-btn ant-btn-sm"
                                        onclick="${gantt_string}.Popconfirm_delete()">${i18n.t('cancel')}</button>
                                    <button type="button" class="ant-btn ant-btn-primary ant-btn-sm" onclick="${click}">${i18n.t('ok_text')}</button>
                                </div>
                            </span>`;

      popConfirmDiv.innerHTML = content;

      document.addEventListener('mouseup', (event) => {
        const popConfirmTextDiv = document.getElementsByClassName(
          'PopConfirmHTMLContent'
        );
        const obj = popConfirmTextDiv[0];
        if (popConfirmTextDiv.length > 0) {
          if (!obj.contains(event.target)) gantt.Popconfirm_delete();
          else {
          }
        }
      });
    };
  }

  if (!gantt.Popconfirm_delete) {
    gantt.Popconfirm_delete = () => {
      const popConfirmTextDiv = document.getElementsByClassName(
        'PopConfirmHTMLContent'
      );
      for (let i = 0; i < popConfirmTextDiv.length; i++)
        popConfirmTextDiv[i].remove();
      document.removeEventListener('click', () => {});
    };
  }

  return `
        <div class="PopConfirmHTML" id="${idPopConfirm}">
            <div class="PopConfirmHTMLElement" onclick="${gantt_string}.Popconfirm_create(${task_id}, '${onclick}')">
                ${container}
            </div>
        </div>`;
}
