import React, { useState, useEffect } from 'react';
import { ACTIONS } from '../../scroll.actions';

// Components
import { FixedSizeGrid as Grid } from 'react-window';

// Assets
import style from './index.module.css';

export const CrewWorkersHourRow = (props) => {
  const { crew, isCrew, hoursPerDay } = props;

  let hoursToDays = '-';

  if (!isCrew) {
    if (crew.resourcesAssigned.length) {
      let resourcesFactor = crew.resourcesAssigned.reduce((acc, resource) => {
        acc += resource.factor;
        return acc;
      }, 0);

      resourcesFactor = resourcesFactor > 1 ? 1 : resourcesFactor;
      const hours = crew.assist.hours || hoursPerDay;
      hoursToDays = (hours * resourcesFactor) / hoursPerDay;
      hoursToDays = hoursToDays == 1 ? hoursToDays : hoursToDays.toFixed(2);
      hoursToDays = `${hoursToDays} d`;
    }
  }

  return (
    <div className={style.hourstotal}>
      {!isCrew ? hoursToDays || ' - ' : ' '}
    </div>
  );
};

const WorkerHours =
  (data) =>
  ({ rowIndex, style }) => (
    <div className={style.cells} style={style}>
      {data[rowIndex]}
    </div>
  );

const TotalHoursGrid = (props) => {
  const {
    className,
    gridHoursRef,
    gridHeight,
    gridWidth,
    hoursContentRef,
    listWorkersHours,
    onScroll
  } = props;

  // ==> States
  const [rows, setRows] = useState(0);

  useEffect(() => {
    if (listWorkersHours) {
      setRows(listWorkersHours.length);
    }
  }, [listWorkersHours]);

  // ==> end hooks

  const handleScroll = (scroll) =>
    onScroll({ scrollFrom: ACTIONS.WORKER_HOURS })(scroll);

  return (
    <div className={className} ref={gridHoursRef}>
      <Grid
        ref={hoursContentRef}
        columnCount={1}
        columnWidth={gridWidth}
        height={gridHeight}
        rowCount={rows}
        rowHeight={25}
        width={gridWidth}
        onScroll={handleScroll}>
        {WorkerHours(listWorkersHours)}
      </Grid>
    </div>
  );
};

export default React.memo(TotalHoursGrid);
