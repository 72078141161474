import { type_and_duration_feature_filter } from '../../gantt_lifecycle/editing-flow';
import { checkLastEditedColumn } from './checkLastEditedColumn';

function updateMilestoneData(new_item) {
  const gantt = window.to_use_react_gantt;
  if (gantt.is_autoscheduling || gantt.is_task_moved) {
    return;
  }

  if (
    !checkLastEditedColumn([
      'duration',
      'end_date',
      'start_date',
      'calendar_id',
      'custom_predecessors',
      'custom_successors'
    ])
  ) {
    return;
  }

  type_and_duration_feature_filter(gantt, new_item);
}

export { updateMilestoneData };
