import { StyleSheet } from '@react-pdf/renderer';
export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    width: '1000px'
  },
  content_activities_tasks: {
    // borderTop: '1pt solid #EBEBEB',
    marginLeft: '20px',
    marginRight: '20px'
  },
  text: {
    fontSize: 20,
    padding: 2,
    backgroundColor: '#61C74F',
    fontFamily: 'Roboto',
    color: '#000'
  },
  section: {
    marginTop: '18px',
    marginLeft: '5px'
  },
  title_head_week: {
    marginTop: '18px',
    fontSize: 12,
    marginLeft: '450px',
    color: '#2C3421'
  },
  subtitle_head_week: {
    fontSize: 10,
    marginLeft: '450px'
  },
  image: {
    width: '6px',
    height: '6px',
    marginLeft: '15px'
  },
  section_title: {
    marginTop: '18px',
    marginLeft: '5px',
    width: '100px'
  },
  title: {
    fontWeight: 500,
    fontSize: 14,
    color: '#121212',
    fontFamily: 'Roboto'
  },
  title_lean_state: {
    fontSize: 9,
    fontFamily: 'Roboto',
    color: '#2C3421',
    marginTop: '2px'
  },
  title_lean_state_com: {
    fontSize: 9,
    fontFamily: 'Roboto',
    color: '#F59D04',
    marginTop: '2px'
  },
  title_name: {
    fontSize: 10,
    minHeight: '12px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#A7A7A7',
    lineHeight: '12px',
    marginTop: '9px',
    marginBottom: '9px',
    paddingBottom: '0px'
  },
  answer_name: {
    fontSize: 10,
    minHeight: '12px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#121212',
    lineHeight: '12px'
  },
  table: {
    flexGrow: 1,
    float: 'left',
    flexDirection: 'column'
  },
  section_header: {
    flexDirection: 'row',
    marginRight: '20px',
    marginLeft: '20px',
    marginTop: '20px',
    marginBottom: '0px',
    border: '1pt solid #EBEBEB',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    justifyContent: 'space-between'
  },
  section_footer: {
    flexDirection: 'row',
    marginRight: '20px',
    marginLeft: '20px',
    marginTop: '0px',
    // marginBottom: '20px',
    borderLeft: '1pt solid #EBEBEB',
    borderRight: '1pt solid #EBEBEB',
    borderBottom: '1pt solid #EBEBEB',
    justifyContent: 'space-between'
  },
  section_container_header: {
    paddingTop: '5px',
    paddingBottom: '5px',
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '5px',
    border: '1pt solid #FAFAFA'
  },
  section_header_table: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #EBEBEB'
  },
  sub_section_columns_header_table: {
    display: 'flex',
    flex: 1.15,
    flexDirection: 'row'
  },
  sub_section_days_header_table: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row'
  },
  table_header: {
    borderRight: '1pt solid #EBEBEB'
  },
  table_header_days: {
    width: '18.1px',
    height: '12px !important',
    marginLeft: '1px',
    borderRight: '1pt solid #EBEBEB'
  },
  table_header_days_first: {
    width: '10px',
    marginLeft: '10px',
    backgroundColor: '#ffffff'
  },
  table_heade_title_name: {
    fontSize: 10,
    fontFamily: 'Roboto',
    color: '#121212',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '12px'
  },
  table_heade_title_name_date: {
    fontSize: 8,
    fontFamily: 'Roboto',
    textAlign: 'center',
    color: '#121212',
    marginTop: '1px',
    fontStyle: 'normal',
    minHeight: '12px',
    fontWeight: 500,
    lineHeight: '12px'
  },
  table_task_title_name_texto: {
    height: 'auto',
    width: '100%',
    fontSize: 8,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: '#121212'
  },
  table_task_title_name: {
    height: 'auto',
    width: '100%',
    fontSize: 10,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: '#121212',
    textAlign: 'center',
    margin: 'auto'
  },
  table_task_title_day: {
    height: 'auto',
    width: '100%',
    fontSize: 8,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    textAlign: 'center',
    margin: 'auto'
  },
  table_task__subcontract_box: {
    width: '10px',
    height: '10px',
    position: 'absolute',
    left: '5px'
  },
  table_task_title_name_parent: {
    height: 'auto',
    fontSize: 7,
    fontFamily: 'Roboto',
    marginLeft: '2px',
    fontStyle: 'normal',
    fontWeight: '1200',
    color: '#000000'
  },
  table_heade_title_name_hour: {
    fontSize: 6,
    fontFamily: 'Roboto',
    padding: '0px',
    color: '#121212',
    textAlign: 'center'
  },
  section_more: {
    fontSize: 6,
    fontFamily: 'Roboto',
    padding: '7px',
    color: '#121212'
  },
  section_columns_activity: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1pt solid #EBEBEB',
    borderLeft: '1pt solid #EBEBEB',
    // borderRight: '1pt solid #EBEBEB',
    display: 'flex',
    flexDirection: 'row'
  },
  section_row_activity: {
    display: 'flex',
    flexDirection: 'row',
    height: '18px'
  },
  section_timeline_activity: {
    display: 'flex',
    flexDirection: 'row'
  },
  section_timeline_activity_per_day: {
    width: '19.11px',
    marginLeft: '0px',
    flexDirection: 'row',
    boxSizing: 'border-box',
    backgroundColor: '#F5F5F5',
    borderBottom: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  section_activity_title_name: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 8,
    fontFamily: 'Roboto',
    padding: '4px',
    paddingLeft: '10px',
    position: 'absolute'
  },
  section_task_table: {
    display: 'flex',
    flexDirection: 'row'
  },
  sub_section_columns_task_table: {
    flex: 1.15,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#CCA7C1',
    boxSizing: 'border-box',
    borderBottom: '1pt solid #EBEBEB',
    borderRight: '1pt solid #EBEBEB'
  },
  sub_section_days_task_table: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row'
  },
  section_task_parent: {
    marginLeft: '10px',
    marginRight: '10px',
    height: 'auto',
    boxSizing: 'border-box',
    borderBottom: '1pt solid #EBEBEB',
    borderRight: '1pt solid #EBEBEB',
    backgroundColor: '#EBEBEB'
  },
  table_task_with_icon: {
    flexDirection: 'row',
    borderLeft: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_column: {
    borderLeft: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_texto_parent: {
    width: '107px',
    height: 'auto',
    backgroundColor: '#F5F5F5',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    borderLeft: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px',
    flexDirection: 'row'
  },
  table_task_priority: {
    width: '40px',
    marginLeft: '5px',
    flexDirection: 'row',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_priority_parent: {
    width: '40px',
    marginLeft: '5px',
    flexDirection: 'row',
    backgroundColor: '#E1E1E1',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_real: {
    width: '30px',
    marginLeft: '5px',
    flexDirection: 'row',

    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_real_parent: {
    width: '30px',
    marginLeft: '5px',
    flexDirection: 'row',
    backgroundColor: '#E1E1E1',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  open_roadblocks: {
    fontSize: 8,
    fontFamily: 'Roboto',
    textAlign: 'center',
    color: '#E50101',
    margin: 'auto',
    width: '15px'
  },
  removed_roadblocks: {
    fontSize: 8,
    fontFamily: 'Roboto',
    textAlign: 'center',
    color: '#121212',
    margin: 'auto',
    width: '15px'
  },
  table_task_subcontract_parent: {
    width: '55px',
    marginLeft: '5px',
    flexDirection: 'row',
    backgroundColor: '#E1E1E1',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_commitment: {
    width: '40px',
    marginLeft: '5px',
    flexDirection: 'row',

    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_commitment_parent: {
    width: '40px',
    marginLeft: '5px',
    flexDirection: 'row',
    backgroundColor: '#E1E1E1',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_resource: {
    width: '50px',
    marginLeft: '5px',
    flexDirection: 'row',

    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_resource_parent: {
    width: '50px',
    marginLeft: '5px',
    flexDirection: 'row',
    backgroundColor: '#E1E1E1',
    boxSizing: 'border-box',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_days: {
    width: '19.11px',
    marginLeft: '0px',
    flexDirection: 'row',
    boxSizing: 'border-box',
    borderBottom: '1pt solid #EBEBEB',
    borderRight: '1pt solid #EBEBEB',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_days_parent: {
    width: '35.25px',
    marginLeft: '0px',
    flexDirection: 'row',
    boxSizing: 'border-box',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  table_task_days_end: {
    width: '35.25px',
    marginLeft: '0px',
    flexDirection: 'row',
    paddingBottom: '2px',
    paddingTop: '2px'
  },
  image_logo: {
    height: '17px',
    width: 'auto',
    marginLeft: '14px',
    alignSelf: 'center'
  },
  image_logo_proplanner: {
    height: '17px',
    width: 'auto',
    marginTop: '10px',
    marginBottom: '8px',
    marginRight: '14px',
    alignItems: 'center'
  },
  image_icon_roadblocks: {
    height: '7px',
    width: 'auto',
    margin: 'auto',
    // marginLeft: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pagination: {
    position: 'absolute',
    width: '100%',
    height: '50px',
    bottom: '0px'
  },

  pagination_text: {
    position: 'absolute',
    bottom: '23px',
    left: '10px',
    // backgroundColor: "red",
    fontSize: 9,
    fontFamily: 'Roboto',
    padding: '7px',
    color: '#121212'
  },
  pagination_text_count: {
    position: 'absolute',
    bottom: '23px',
    right: '33px',
    // backgroundColor: "red",
    fontSize: 9,
    fontFamily: 'Roboto',
    padding: '7px',
    color: '#121212'
  }
});
