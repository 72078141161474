import React, { Fragment, useState } from 'react';
import { Modal, Input, Form } from 'antd';
import styled from 'styled-components';
import { userService } from '../../../../services/user.service';
import { openNotification } from '../../../../utils';
import { withTranslation } from 'react-i18next';

const PlaceHolder = styled.div`
  .color-placeholder-password::placeholder {
    color: #d1d1d1 !important;
  }
  div {
    margin: 10px;
  }
`;
const CenterInputs = styled.div`
  .centerInputs {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
function ModalPassword({ Visible, setVisible, InfoUserProp, setInfoUser, t }) {
  const [RepetirContraseña, setRepetirContraseña] = useState('');
  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  // get new value from user
  const changeInput = (name, value) => {
    setInfoUser({
      ...InfoUserProp,
      [name]: value
    });
  };

  const updatePassword = async () => {
    if (InfoUserProp.password.trim() === '' || RepetirContraseña === '') {
      const alertError = {
        title: t('settings.change_password.title'),
        description: t('settings.change_password.all_fields'),
        type: 'error'
      };
      openNotification(alertError);
    } else {
      if (InfoUserProp.password === RepetirContraseña) {
        const response = await userService.update(InfoUserProp);
        if (response.user) {
          const alertSuccess = {
            title: t('settings.change_password.title'),
            description: t('settings.change_password.success'),
            type: 'success'
          };
          openNotification(alertSuccess);
          setVisible(false);
        }
      } else {
        const alertError = {
          title: t('settings.change_password.title'),
          description: t('settings.change_password.mismatch'),
          type: 'error'
        };
        openNotification(alertError);
      }
    }
  };
  return (
    <Fragment>
      <Modal
        visible={Visible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        footer={
          <div style={{ textAlign: 'center', width: '100%' }}>
            <button
              className="btn-handle-cambiar"
              onClick={() => updatePassword()}>
              <b>{t('settings.change_password.title')}</b>
            </button>
          </div>
        }>
        <h3 style={{ color: '#7DFF8A' }}>
          <b>{t('settings.change_password.title')}</b>
        </h3>
        <br />
        <CenterInputs>
          <div className="centerInputs">
            <PlaceHolder>
              <div>
                <Input
                  name="password"
                  type="password"
                  className="color-placeholder-password"
                  style={{ border: '1px solid #7DFF8A', width: '180px' }}
                  placeholder={t('settings.change_password.new_password')}
                  onChange={(e) => changeInput('password', e.target.value)}
                />
              </div>
              <div>
                <Input
                  type="password"
                  className="color-placeholder-password"
                  style={{ border: '1px solid #7DFF8A', width: '180px' }}
                  placeholder={t('settings.change_password.repeat_password')}
                  onChange={(e) => setRepetirContraseña(e.target.value)}
                />
              </div>
            </PlaceHolder>
          </div>
        </CenterInputs>
      </Modal>
    </Fragment>
  );
}

export default withTranslation()(ModalPassword);
