import React from 'react';
import VarianceCell from '../../../components/VarianceCell';

export default (gantt, { t }) => ({
  name: 'variance',
  label: t('scheduleofschedule.columns.variance'),
  align: 'center',
  width: 70,
  onrender: (item) => <VarianceCell variance={item.variance} />
});
