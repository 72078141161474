import { Colors } from '../../../../constants/colors.constants';

/**
 * This function set the color by the sub selected for a task
 * @param {*} task Task object to be checked sub color
 * @param {*} render Boolean that defines if gantt must render
 * @returns Null if task object is milestone
 */
function defineColorBySubcontract(task, render = false, gantt = false) {
  if (!gantt) {
    gantt = window.to_use_react_gantt;
  }

  gantt.config.highlight_critical_path = false;
  if (task.type === 'milestone') return;

  const color = getBarColorWithProgressByCompany(task.subcontractId, gantt);
  task.progressSolidColor = color;
  task.color = getBarColorWithoutProgressByCompany(color);

  if (render) gantt.optimizedRender();
}

function getBarColorWithProgressByCompany(subId, gantt) {
  if (!subId) return Colors.GANTT_BAR_GRAY_WITH_PROGRESS;

  const subcontractObject = gantt.subContracts.find((el) => el.id === subId);
  return subcontractObject
    ? subcontractObject.color
    : Colors.GANTT_BAR_GRAY_WITH_PROGRESS;
}

function getBarColorWithoutProgressByCompany(color) {
  const COLOR_DEFAULT = Colors.GANTT_BAR_GRAY_WITH_PROGRESS;
  return color !== COLOR_DEFAULT
    ? color + '4d'
    : Colors.GANTT_BAR_GRAY_WITHOUT_PROGRESS;
}

export { defineColorBySubcontract };
