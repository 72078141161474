import { trackingEvent } from '../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';
import { isCircularLinkExtended } from '../../../utils/circularDependencyDetection';

/**
 * This function report to amplitude that a circular link was founded
 * @param {object} link Link object to send amplitude event
 */
const reportLinkCircular = (link) => {
  trackingEvent(
    'circular_link_alert_visualization',
    {
      ...getBasicAmplitudEventProperties(),
      link_id: JSON.stringify(link),
      type: 'circular_automatic_deleted',
      tag: 'new logic'
    },
    AMPLITUDE_SERVICE
  );
};

/**
 * This function alert to amplitude and to the user that a circular link was detected
 * @param {object} link Link object to send amplitude event
 * @param {function} modalCircular Function to execute the modal to show that a circular link
 */
const alertCircularLink = (link, modalCircular) => {
  reportLinkCircular(link);
  modalCircular();
};

/**
 * This function check if a link creates a circular connection in the loaded gantt
 * @param {object} link Link object to send amplitude event
 * @param {object} gantt instnace of DHTMLX object
 * @param {function} modalCircular Function to execute the modal to show that a circular link
 * @returns {boolean}
 */
export const checkCircularLinks = (link, gantt, modalCircular) => {
  if (isCircularLinkExtended(link, gantt)) {
    alertCircularLink(link, modalCircular);
    return true;
  }
  return false;
};
