import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
/** Here must be writted the adaptares functions
 *
 * Plugin only needs
 * for Tasks = {
 *  text
 *  id
 *  parent
 *  description
 *  progress
 *  constraint_type
 *  constraint_date
 *  start_date
 *  sectorId
 *  ganttId
 *  proplannerId
 *  type
 *  non_parsed_original_start_date
 *  non_parsed_original_end_date
 *  non_parsed_original_constraint_date
 *  should_correct_start_date
 *  correlative_id
 *  is_lookahead
 *  should_be_showed
 *  baseline_points
 *  hhWorkTime
 *  cost
 *  used_cost
 *  real_cost
 *  real_work
 *  activityModifications
 *  calendar_id
 *  ponderator
 *  tasks
 * }
 *
 * *******************************************************
 *
 * and Links = {
 *  id
 *  source
 *  target
 *  type
 *  ganttId
 *  sectorId
 *  proplannerId
 * }
 */

import { transformDaysToHours } from '../../ganttContainer/gantt/gantt.helper';
import tableVisualizationIcon from '../../../assets/img/visualization-table.png';
import ganttVisualizationIcon from '../../../assets/img/gantt-view.png';
import taktVisualizationIcon from '../../../assets/img/takt/takticon.png';
import { isValidDateObject } from '../../../utils/dateUtils';
import { isFeatureOn } from '../../../utils/featureUtils';
/**
 * This function should create first lvl of gantt tasks taking activities container with their tasks
 * This ones, will not have links option
 * @param {*} pp_activity ProPlanner Activity which have n tasks related
 */
export const convertPPActivity2PluginTask = (activity) => {
  activity.isTask = false;
  const ganttTask = {
    name: activity.name,
    text: activity.name,
    id: parseInt(activity.unique_id),
    parent: activity.parent_id,
    description: activity.description,
    progress: activity.progress || 0,
    start_date: new Date(activity.start_date),
    sectorId: activity.sectorId,
    ganttId: activity.ganttId,
    type: activity.type != 'milestone' ? 'main' : 'milestone',
    non_parsed_original_start_date: activity.start_date,
    non_parsed_original_end_date: activity.end_date,
    non_parsed_original_constraint_date: activity.constraint_date,
    should_correct_start_date: true,
    correlative_id: activity.correlative_id,
    unique_correlative_id: activity.unique_correlative_id,
    is_lookahead: activity.isOnLookahead,
    should_be_showed: true,
    baseline_points: activity.baseline_points,
    hhWorkTime: activity.hhWorkTime,
    cost: activity.cost,
    used_cost: activity.used_cost || 0,
    real_cost: activity.real_cost || 0,
    real_work: activity.real_work || 0,
    activityModifications: activity.activitymodifications,
    calendar_id: activity.calendarId,
    ponderator: activity.ponderator,
    tasks: activity.tasks,
    status: activity.status,
    hide: activity.hide,
    activityRoute: activity.activityRoute,
    activityRouteIds: activity.activityRouteIds,
    proplannerId: activity.id,
    commitment_percentaje: activity.commitment_percentaje || 0,
    tags: activity.tags,
    subcontractId: activity.subcontractId,
    responsables: activity.responsables,
    correlative_number: activity.correlative_number
  };

  if (isFeatureOn('enable_lwp_18')) {
    delete ganttTask.parent;
  }

  if (ganttTask.type == 'milestone') {
    ganttTask.color = '#9E00A2';
    ganttTask.is_milestone = true;
  } else {
    ganttTask.color = '#9b3939';
  }

  if (!ganttTask.parent) {
    delete ganttTask.parent;
  }

  if (activity.tasks) {
    ganttTask.hasTasks = activity.tasks.length
      ? activity.tasks.map((task) => task.id)
      : false;
  }

  /** Hour integration: From DB duration value comes in Days, but at gantt component the value must be interpreted as days */
  const hoursDuration = transformDaysToHours(activity.duration);
  ganttTask.sumOfDurationRecursively = transformDaysToHours(
    activity.sumOfDurationRecursively
  );
  ganttTask.duration = ganttTask.type == 'milestone' ? 0 : hoursDuration;
  ganttTask.duration_milestone_bugged =
    ganttTask.type == 'milestone' ? 0 : hoursDuration;
  ganttTask.for_disable_milestone_duration =
    ganttTask.type == 'milestone' ? 0 : hoursDuration;
  ganttTask.non_parsed_original_duration =
    ganttTask.type == 'milestone' ? 0 : hoursDuration;

  if (!isValidDateObject(ganttTask.start_date)) {
    delete ganttTask.start_date;
  }

  return ganttTask;
};

export const convertPluginTask2PPTask = (
  plugin_task,
  default_calendar = null,
  parent_id
) => {
  let PPTask = cloneDeep(plugin_task);
  PPTask = {
    ...PPTask,
    name: plugin_task.name,
    id: parseInt(plugin_task.id),
    children: plugin_task.children || [],
    parent: parent_id,
    description: plugin_task.description,
    progress: plugin_task.progress || 0,
    start_date: moment(plugin_task.start_date).format('YYYY/MM/DD'),
    end_date: moment(plugin_task.end_date).format('YYYY/MM/DD'),
    sectorId: plugin_task.sectorId,
    ganttId: plugin_task.ganttId,
    type: 'activitytask',
    correlative_id: plugin_task.correlative_id,
    is_parent: plugin_task.children && plugin_task.children.length,
    hhWorkTime: plugin_task.hhWorkTime,
    cost: plugin_task.cost,
    used_cost: plugin_task.used_cost || 0,
    real_cost: plugin_task.real_cost || 0,
    real_work: plugin_task.real_work || 0,
    calendar_id: plugin_task.calendarId || default_calendar,
    ponderator: plugin_task.ponderator,
    lean_status: plugin_task.lean_status,
    subcontractId: plugin_task.subcontractId,
    status: plugin_task.status,
    responsables: plugin_task.responsables || [],
    priority: plugin_task.priority,
    hide: plugin_task.hide,
    isTask: true,
    constraints: plugin_task.constraints,
    Overdue: plugin_task.Overdue,
    Advancement: plugin_task.Advancement,
    unfitType: plugin_task.unfitType,
    taskRoute: plugin_task.taskRoute,
    checked: plugin_task.active,
    materialId: plugin_task.materialId,
    machineId: plugin_task.machineId,
    specialtyId: plugin_task.specialtyId,
    tags: plugin_task.tags,
    spend_hm: plugin_task.spend_hm,
    total_hm: plugin_task.total_hm,
    won_hh: plugin_task.won_hh,
    spend_hh: plugin_task.spend_hh,
    real_endowment: plugin_task.real_endowment,
    plan_endowment: plugin_task.plan_endowment,
    remaining_quantity: plugin_task.remaining_quantity,
    actual_quantity: plugin_task.actual_quantity,
    total_quantity: plugin_task.total_quantity,
    /** New cols integration lookahead and weekly plan */
    commitment_percentaje: plugin_task.commitment_percentaje || 0,
    quantity_parcial_percentaje: plugin_task.quantity_parcial_percentaje,
    quantity_parcial: plugin_task.quantity_parcial,
    expectedweek: plugin_task.expectedweek,
    hasCustomCommitmentPercentaje: plugin_task.customCommitmentPercentaje,
    status_before_restricted: plugin_task.status_before_restricted,
    inside_weekly_other_lean: plugin_task.inside_weekly_other_lean,
    correlative_number: plugin_task.correlative_number
  };
  return PPTask;
};
/**
 * This function should create child gantt tasks, which one were created with the function convertPPActivity2PluginTask.
 * This ones will be able to link whit their thanks to the new table, TaskRelation
 * @param {*} pp_activity_task ProPlanner task of an activity
 */
export const convertPPTask2PluginTask = (
  pp_activity_task,
  hashIdToUnique = null,
  mainLvlGanttTask = null
) => {
  let parent = null;
  if (pp_activity_task && pp_activity_task.parentIdForGantt) {
    parent = pp_activity_task.parentIdForGantt;
  } else if (pp_activity_task && pp_activity_task.parent_id) {
    parent = pp_activity_task.parent_id;
  } else if (
    pp_activity_task &&
    pp_activity_task.activityId &&
    hashIdToUnique
  ) {
    parent = hashIdToUnique[pp_activity_task.activityId];
  }

  pp_activity_task.isTask = true;

  const ganttTask = {
    name: pp_activity_task.name,
    text: pp_activity_task.name,
    id: parseInt(pp_activity_task.id),
    parent: parent,
    description: pp_activity_task.description,
    progress: pp_activity_task.progress || 0,
    start_date: new Date(pp_activity_task.start_date),
    sectorId: pp_activity_task.sectorId,
    ganttId: pp_activity_task.ganttId,
    type: 'activitytask',
    non_parsed_original_start_date: pp_activity_task.start_date,
    non_parsed_original_end_date: pp_activity_task.end_date,
    non_parsed_original_constraint_date: pp_activity_task.constraint_date,
    should_correct_start_date: true,
    correlative_id: pp_activity_task.correlative_id,
    unique_correlative_id: pp_activity_task.unique_correlative_id,
    is_lookahead: pp_activity_task.isOnLookahead,
    is_parent: pp_activity_task.children && pp_activity_task.children.length,
    should_be_showed: true,
    baseline_points: pp_activity_task.baseline_points,
    hhWorkTime: pp_activity_task.hhWorkTime,
    cost: pp_activity_task.cost,
    used_cost: pp_activity_task.used_cost || 0,
    real_cost: pp_activity_task.real_cost || 0,
    real_work: pp_activity_task.real_work || 0,
    activityModifications: pp_activity_task.activitymodifications,
    calendar_id: pp_activity_task.calendarId || mainLvlGanttTask.calendar_id,
    ponderator: pp_activity_task.ponderator,
    lean_status: pp_activity_task.lean_status,
    subcontractId: pp_activity_task.subcontractId,
    status: pp_activity_task.status,
    responsables: pp_activity_task.responsables,
    priority: pp_activity_task.priority,
    hide: pp_activity_task.hide,
    isTask: true,
    constraints: pp_activity_task.constraints,
    Overdue: pp_activity_task.Overdue,
    Advancement: pp_activity_task.Advancement,
    activityReference: mainLvlGanttTask,
    unfitType: pp_activity_task.unfitType,
    taskRoute: pp_activity_task.taskRoute,
    checked: pp_activity_task.active,
    materialId: pp_activity_task.materialId,
    machineId: pp_activity_task.machineId,
    specialtyId: pp_activity_task.specialtyId,
    tags: pp_activity_task.tags,
    spend_hm: pp_activity_task.spend_hm,
    total_hm: pp_activity_task.total_hm,
    won_hh: pp_activity_task.won_hh,
    spend_hh: pp_activity_task.spend_hh,
    real_endowment: pp_activity_task.real_endowment,
    plan_endowment: pp_activity_task.plan_endowment,
    remaining_quantity: pp_activity_task.remaining_quantity,
    actual_quantity: pp_activity_task.actual_quantity,
    total_quantity: pp_activity_task.total_quantity,
    /** New cols integration lookahead and weekly plan */
    commitment_percentaje: pp_activity_task.commitment_percentaje || 0,
    quantity_parcial_percentaje: pp_activity_task.quantity_parcial_percentaje,
    quantity_parcial: pp_activity_task.quantity_parcial,
    expectedweek: pp_activity_task.expectedweek,
    hasCustomCommitmentPercentaje: pp_activity_task.customCommitmentPercentaje,
    status_before_restricted: pp_activity_task.status_before_restricted,
    inside_weekly_other_lean: pp_activity_task.inside_weekly_other_lean,
    correlative_number: pp_activity_task.correlative_number
  };

  if (pp_activity_task.editablePermissionACP) {
    ganttTask.editable = pp_activity_task.editablePermissionACP.editable;
    ganttTask.readonly = !ganttTask.editable;
  }

  if (pp_activity_task.parent_id) {
    ganttTask.isChildTask = true;
  }

  if (
    ganttTask.constraints &&
    ganttTask.constraints.length &&
    ganttTask.constraints.filter(
      (constraint) => constraint.status === 'released'
    ).length === ganttTask.constraints.length &&
    ganttTask.status_before_restricted
  ) {
    ganttTask.restricted = false;
    ganttTask.is_restricted = false;
    ganttTask.lean_status =
      ganttTask.status_before_restricted === 'Committed'
        ? 'Debit'
        : ganttTask.status_before_restricted;
  }

  /** Hour integration: From DB duration value comes in Days, but at gantt component the value must be interpreted as days */
  const hoursDuration = transformDaysToHours(pp_activity_task.duration);
  ganttTask.sumOfDurationRecursively = transformDaysToHours(
    pp_activity_task.sumOfDurationRecursively
  );
  ganttTask.duration = hoursDuration;
  ganttTask.duration_milestone_bugged = hoursDuration;
  ganttTask.for_disable_milestone_duration = hoursDuration;
  ganttTask.non_parsed_original_duration = hoursDuration;
  return ganttTask;
};
/**
 * This function should create links for gantt plugin
 * @param {*} pp_task_relation ProPlanner Task Relation created at the new table, TaskRelation
 */
export const convertPPTaskRelationToPluginLink = (pp_task_relation) => {};

const recursiveGet = (task, finalArray, mainLvlGanttTask) => {
  finalArray.push(convertPPTask2PluginTask(task, null, mainLvlGanttTask));
  if (task.children.length) {
    task.children.map((child) => {
      recursiveGet(child, finalArray, mainLvlGanttTask);
    });
  }
};

/**
 * This functions go deeply populating an final array to load to gantt plugin
 * @param {*} mainLvlGanttTask Activity transformed to gantt task which is main custom type of task
 * @param {*} finalArray Array final to load at gantt plugin
 */
export const recursiveTransformForTasks = (
  mainLvlGanttTask,
  finalArray,
  hashIdToUnique
) => {
  const activitiesTasks = mainLvlGanttTask.tasks;
  activitiesTasks.map((activityTask) => {
    finalArray.push(
      convertPPTask2PluginTask(activityTask, hashIdToUnique, mainLvlGanttTask)
    );
    if (activityTask.children) {
      if (activityTask.children.length) {
        activityTask.children.map((child) => {
          recursiveGet(child, finalArray, mainLvlGanttTask);
        });
      }
    }
  });
};

export const viewType = [
  {
    icon: tableVisualizationIcon,
    name: 'table',
    label: 'Vista Tabla',
    beta: false,
    module: 'Lookahead_plan'
  },
  {
    icon: ganttVisualizationIcon,
    name: 'gantt',
    label: 'Vista Timeline',
    beta: true,
    module: 'Masterplan_gantt'
  },
  {
    name: 'takt',
    label: 'Vista Takt',
    icon: taktVisualizationIcon,
    beta: false,
    module: 'Lookahead_plan'
  }
];

/** template function for numeric data */
export const templateNumber = (
  item,
  column_name,
  formatMoneyV2,
  EditOutlined,
  symbol_number = '$',
  decimals = 0,
  symbol_decimal = ','
) => {
  const symbol_to_render = symbol_number ? `${symbol_number} ` : '';
  const val_to_render = formatMoneyV2(
    item[column_name] || 0,
    symbol_to_render,
    decimals,
    symbol_decimal
  );
  if (!item.isTask) {
    return val_to_render;
  }
  const jsx = (
    <span>
      <EditOutlined className="editable-input-icon" />
    </span>
  );
  const temp = document.createElement('div');
  const html = ReactDOM.render(jsx, temp);
  if (html) {
    const finalHtml = `
        <span>
            <span>
                ${val_to_render}
                ${item.is_parent ? '' : html.outerHTML}
            </span>
        </span>
        `;
    return finalHtml;
  }
};

/** get task's Activity */
export const findDeepGetActivityOfTask = (data, fieldToFind, value) =>
  data.find((e) => {
    if (e[fieldToFind] && e[fieldToFind] == value) return true;

    const treeData = e.tasks || e.children;
    if (treeData) {
      return findDeepGetActivityOfTask(treeData, fieldToFind, value);
    }
  });

/**
 * This function returns an array with the values of a specific key from columns filtered or ordered in a current view.
 *
 * @param {object} currentView - An object representing the current view in lookahead.
 * @param {string} property - A string indicating the property to retrieve the columns from. It can be "filter_query" or "order_query".
 * @param {string} keyName - A string representing the key name of the property to obtain the values from.
 *
 * @returns {Array} An array containing the values of the specified key from the columns in the current view.
 */
export function obtainKeyValuesOfArray(currentView, property, keyName) {
  const arrayColumns = currentView.view[property].value ?? [];
  const valueColumnApplied = [];
  for (let i = 0; i < arrayColumns.length; i++) {
    const objet = arrayColumns[i];
    const valueColumn = objet[keyName];
    valueColumnApplied.push(valueColumn);
  }
  return valueColumnApplied;
}
