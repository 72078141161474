import React, { useState, useEffect } from 'react';
import ModalSystem from '../../components/DesignSystem/ModalSystem';
import { withTranslation } from 'react-i18next';
import EventEmitter from 'react-native-eventemitter';

import './ModalMessage.scss';
export const ModalMessage = (params) => {
  const { t, isShow, setIsShow } = params;
  const subtitle = t('export_pdf_modal.message_fit_one_page');

  const handleClose = () => {
    setIsShow(false);
  };

  const contentChildren = () => (
    <div className="modal-message">
      <div className="modal-message__content">
        <div className="modal-message__content--title">
          <h5>{subtitle}</h5>
        </div>
      </div>
      <div className="modal-message__buttons">
        <button
          className="modal-message__buttons--cancel"
          onClick={() => handleClose(false)}>
          {t('export_pdf_modal.ok_text')}
        </button>
      </div>
    </div>
  );

  return ModalSystem({
    visible: isShow,
    theme: 'dark',
    setVisible: handleClose,
    showTitle: false,
    centered: true,
    width: 520,
    closable: true,
    children: contentChildren()
  });
};

export default withTranslation()(ModalMessage);
