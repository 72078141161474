import React from 'react';
import ProPlanner from '../../assets/img/proplanner.svg';
import './index.scss';

export default function LayoutLoginOutbuild({ children, ...props }) {
  const { title } = props;
  return (
    <div>
      <div className="layoutLogin__container">
        <div className="layoutLogin__logo">
          <img src={ProPlanner} alt="Logo" />
        </div>
        {title && (
          <div className="layoutLogin__title">
            <h2>{title}</h2>
          </div>
        )}
        <div>{children}</div>
      </div>
    </div>
  );
}
