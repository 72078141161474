import { buildTemplates } from './gantt.templates';
import { buildOptions } from './gantt.options';
import { buildUtils } from './gantt.utils';
import { buildEvents } from './gantt.events';

export const ganttConfig = (
  gantt,
  ganttContainer,
  projectState = null,
  dispatch = () => {},
  t,
  sector,
  tableConfig,
  onDoubleClickTask,
  markersDates,
  userPreferenceTableGantt,
  activities,
  updateGanttVisualizationState,
  subContracts,
  setVisibleFormSubcontract,
  setMassiveSelectionGannt,
  groupBy,
  updateTaskDrag,
  {
    resizeColumnsTimeLine = (_) => {},
    saveColumnOrder = (_) => {},
    colorSchema = 'subcontract'
  },
  setWeeklyModalData,
  setMassiveSelection,
  copyPasteState = {}
) => {
  let events = null;
  const mountGanttConfig = (objGantt) => {
    /** Dont run any process just defines them */
    buildUtils(
      objGantt,
      setMassiveSelectionGannt,
      setWeeklyModalData,
      dispatch,
      projectState,
      t,
      setMassiveSelection
    );

    /** Set global DHTMLX options config */
    buildOptions(
      objGantt,
      sector,
      userPreferenceTableGantt,
      subContracts,
      setVisibleFormSubcontract,
      tableConfig,
      t,
      copyPasteState
    );

    /** Run UI elements from DHTMLX lib */
    buildTemplates(objGantt);

    /** Build events from DHTMLX lib */
    events = buildEvents(
      objGantt,
      userPreferenceTableGantt,
      dispatch,
      markersDates,
      updateTaskDrag,
      t,
      saveColumnOrder,
      resizeColumnsTimeLine,
      colorSchema,
      projectState
    );
  };
  window.mountGanttConfig = mountGanttConfig;

  try {
    mountGanttConfig(gantt);
    /** Through viewchild we have the virtual dom reference, so we can initialize the plugin there */
    gantt.init(ganttContainer);
  } catch (e) {
    console.log(e);
    /** Through viewchild we have the virtual dom reference, so we can initialize the plugin there */
    gantt.init(ganttContainer);
  }

  /** Events to detach at kanban component or any who use this */
  return events;
};
