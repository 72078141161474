import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Modal, Icon, Select, Spin, Row, Col, Input, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './index.css';
import { useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import IconComponent from '../Projects/IconSvg';
/** import component for validation */
import ErrorValidationLabel from '../Login/ValidationLabel';
/** import common functions from utils */
import { crane } from '../../utils';

// services
import { projectService } from '../../services/project.service';
import { sectorResourcesService } from '../../services';

import {
  openNotification,
  checkAllFieldsValid,
  reduceFormValues,
  ObjectLogoSvg
} from '../../utils';

import styled from 'styled-components';
import { materialMassiveSvg } from '../../utils/svgIcons';

function ModalAddResources(props) {
  /** use props */
  const {
    resourceAdded,
    typeResource,
    Visible,
    setVisible,
    setReloadTableUsers,
    assignResourceOnCreate,
    t
  } = props;
  // GET ID PROJECT FROM REDUX
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;
  const formRef = useRef(null);

  let iconResource;
  switch (typeResource) {
    case 'material':
      iconResource = (
        <span className="material-icon-frm">
          <IconComponent data={materialMassiveSvg} width={50} fill="#000" />
        </span>
      );
      break;
    case 'rrhh':
      iconResource = <i className="fas fa-user fa-3x"></i>;
      break;
    case 'machinery':
      iconResource = (
        <span className="machinery-icon">
          <IconComponent data={crane} width={35} fill="#000" />
        </span>
      );
      break;

    default:
      iconResource = (
        <span className="material-icon-frm">
          <IconComponent data={materialMassiveSvg} width={50} fill="#000" />
        </span>
      );
      break;
  }

  const txtFieldState = {
    value: '',
    valid: true,
    typeMismatch: false,
    errMsg: ''
  };

  // validation map form
  const stateTemplate = {
    namesub: {
      ...txtFieldState,
      fieldName: 'namesub',
      required: true,
      requiredTxt: t('resources_form.req_name'),
      formatErrorTxt: ''
    },
    unsub: {
      ...txtFieldState,
      fieldName: 'unsub',
      required: true,
      requiredTxt: t('resources_form.req_un'),
      formatErrorTxt: ''
    },
    allFieldsValid: false,
    isLoading: false
  };

  const [state, setState] = useState(stateTemplate); // state

  const resetForm = () => {
    setState(stateTemplate);
    setVisible(false);
  };

  /**
   * This function creates a new resource
   * @param {*} name Name to show to the new task
   * @param {*} activity Activity superior parent object
   */
  const createResource = async (dataRes) => {
    const toPush = {
      name: dataRes.namesub.value,
      type: typeResource,
      material_label: dataRes.unsub.value,
      total: 0,
      used: 0,
      sectorId: stateProject.sectorSelected
    };
    const res = await sectorResourcesService.create(toPush);
    /** only material */
    if (res.id && props.activityResource) {
      await assignResourceToActivity(res.id, props.activityResource.id);
    }
    return res;
  };

  const assignResourceToActivity = async (resource_id) => {
    const assign = {
      sectorresourceId: resource_id,
      activityId:
        props.activityResource.proplannerId || props.activityResource.id,
      quantity: 0,
      sectorId: stateProject.sectorSelected
    };
    const data_push = [];
    data_push.push(assign);
    await sectorResourcesService.assingActivitys(data_push, []);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!formRef.current) return;
    const form = formRef.current;
    const formValues = reduceFormValues(form.elements, state);
    const allFieldsValid = checkAllFieldsValid(formValues);
    setLoadingSave(true);
    setState({ ...state, ...formValues, allFieldsValid });
    // setState(() => {
    //     return { ...state, ...formValues, allFieldsValid }
    // })
    if (allFieldsValid) {
      setState({
        ...state,
        isLoading: true
      });
      setReloadTableUsers(false);
      const res = await createResource(state);
      if (res) {
        const succesSubContract = {
          title: t('master_plan.resources'),
          description: t('success_resource'),
          type: 'success'
        };
        openNotification(succesSubContract);
        setReloadTableUsers(true);
        setLoading(true);
        if (assignResourceOnCreate) resourceAdded(res);
      }
      const newLastLevelActivities = props.lastLevelActivities;
      newLastLevelActivities.resource.push(res);
      props.setLastLevelActivities(newLastLevelActivities);
      resetForm();
      setState({
        ...state,
        isLoading: false
      });
    } else {
      const { namesub, unsub } = formValues;
      const errorResource = {
        title: t('master_plan.resources'),
        description: namesub.requiredTxt,
        type: 'error'
      };
      if (!namesub.valid) {
        errorResource.description = namesub.requiredTxt;
      } else {
        if (!unsub.valid) {
          errorResource.description = unsub.requiredTxt;
        }
      }
      openNotification(errorResource);
    }
    setLoadingSave(false);
  };

  // Close modal
  const handleCancel = () => {
    setVisible(false);
  };
  // Save emails
  const handleOk = () => {
    setVisible(false);
  };

  const [ProjectSelected, setProjectSelected] = useState({});

  // GET PROJECT GENERAL SELECTED BY USER
  useEffect(() => {
    if (Visible) {
      setState(stateTemplate);
      const abortController = new AbortController();
      projectService
        .show(projectSelectedId)
        .then((response) => {
          if (response.project === undefined) {
            setProjectSelected({});
          } else {
            setProjectSelected(response.project);
          }
        })
        .catch(() => setProjectSelected({}));
      return function cleanup() {
        abortController.abort();
      };
    }
  }, [Visible]);

  // messages
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const renderTitle = () => {
    let title = '';
    switch (typeResource) {
      case 'material':
        title = t('title_material');
        break;
      case 'rrhh':
        title = t('title_rrhh');
        break;
      case 'machinery':
        title = t('title_machinery');
        break;
      default:
        title = t('title_material');
        break;
    }
    return <span>{title}</span>;
  };

  const renderPlaceholder = () => {
    let placeholder = '';
    switch (typeResource) {
      case 'material':
        placeholder = t('placeholder_material');
        break;
      case 'rrhh':
        placeholder = t('placeholder_rrhh');
        break;
      case 'machinery':
        placeholder = t('placeholder_machinery');
        break;
      default:
        placeholder = t('placeholder_material');
        break;
    }
    return placeholder;
  };

  return (
    <Fragment>
      <Modal
        className="frmModalAdd"
        visible={Visible}
        closable={false}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        maskClosable={false}
        width={450}
        footer={
          <CenterButtons>
            <div className="center-btn-modal-company">
              <ButtonSubcontractStyle>
                <Button
                  loading={loadingSave}
                  className="btn-continue-user-company"
                  onClick={(e) => onSubmit(e)}>
                  {t('user_config.add_btn')}
                </Button>
              </ButtonSubcontractStyle>
              <div className="skip-link" onClick={() => resetForm()}>
                {t('user_config.cancel_btn')}
              </div>
            </div>
          </CenterButtons>
        }>
        <div className="wrapp-sub-contract">
          <div className="title-frm-add">
            {renderTitle()}
            <div className="title-close" onClick={() => setVisible(false)}>
              <CloseOutlined />
            </div>
          </div>
          <div>
            <form
              ref={formRef}
              id="frm-resources"
              className="frmWide frm-resources"
              onSubmit={onSubmit}
              noValidate>
              <Row className="row-form">
                <Col span={6}>
                  <div className="icon-resources">{iconResource}</div>
                </Col>
                <Col span={18}>
                  <Row style={{ display: 'flex' }}>
                    <Col span={18} className="col-frm">
                      <span className="label-input-up">
                        {t('resources_form.name')}
                      </span>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="namesub"
                        autoFocus
                        maxLength={30}
                        className="input-add"
                        placeholder={renderPlaceholder()}
                        value={state.namesub.value}
                        onChange={(e) => {
                          setState({
                            ...state,
                            namesub: {
                              ...state.namesub,
                              value: e.target.value,
                              valid: true
                            }
                          });
                        }}
                        required
                      />
                    </Col>
                    <Col span={18} className="col-frm">
                      <span className="label-input-up">Un</span>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="unsub"
                        maxLength={3}
                        className="input-add input-add-unit"
                        placeholder={'Un'}
                        value={state.unsub.value}
                        onChange={(e) => {
                          setState({
                            ...state,
                            unsub: {
                              ...state.unsub,
                              value: e.target.value,
                              valid: true
                            }
                          });
                        }}
                        required
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

export default ModalAddResources;

const CenterButtons = styled.div`
  .center-btn-modal-company {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const ButtonSubcontractStyle = styled.div`
  .btn-continue-user-company {
    margin-top: 40px;
    text-align: center;
    user-select: none;
    padding: 0.15rem 0.1rem;
    line-height: 1.5;
    display: inline-block;
    border: 0px;
    cursor: pointer;
    color: #121212;
    width: 80px;
    font-size: 12px;
    height: 28px;
    border-radius: 5px;
    background-color: #7dff8a;
    transition: 0.3s;
    margin: 10px;
    border: none;
    outline: none;
  }
`;
