import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

const errorKey = 'scheduleofschedule.errors.load_companies';
export const useCompaniesList = (
  subcontractService,
  showNotification,
  organizationId
) => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    subcontractService
      .getByCompany(organizationId)
      .then(({ subcontracts }) => {
        setCompanies(
          subcontracts
            .filter(({ status }) => status)
            .toSorted((a, b) => a.name.localeCompare(b.name))
            .map(({ id, name, color }) => ({
              id,
              key: id,
              value: id,
              checked: false,
              name,
              color
            }))
        );
      })
      .catch((error) => {
        Sentry.captureException(error);
        showNotification({ message: t(errorKey), type: 'error' });
      });
  }, []);

  return { companies };
};
