/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class NotificationService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(companyId) {
    const res = await this.http
      .get('notifications/' + companyId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(companyId) {
    const res = await this.http
      .delete('notifications/' + companyId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('notifications/', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async createConstraint(data) {
    const res = await this.http
      .post('notifications/constraints', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('notifications/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getByUser(userId, page = 0) {
    if (!this.http || !userId) {
      console.warn('No session token available, request aborted');
      return false;
    }
    const data = { userId: userId, page: page };
    const res = await this.http
      .post('notifications/byuser', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async readAll(userId) {
    const data = { userId };
    const res = await this.http
      .post('notifications/readall', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const notificationService = new NotificationService();
