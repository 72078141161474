import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import FirstScreen from './firstscreen';
import SecondScreen from './secondscreen';
import { STEPS, DEFAULT_DATA_FORM } from './constants';
import { trackingEvent } from '../../analytics';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';

const SignUpView = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [dataForm, setDataForm] = useState(DEFAULT_DATA_FORM);

  useEffect(() => {
    trackingEvent('signup_viewed', null, AMPLITUDE_SERVICE);
  }, []);

  const signed = localStorage.getItem('signed');
  if (signed) {
    return <Redirect to="/projects" />;
  }

  const getCurrentStep = (step = 1) => {
    const steps = {
      [STEPS.FIRST_SCREEN]: (
        <FirstScreen
          {...props}
          dataForm={dataForm}
          setDataForm={setDataForm}
          setCurrentStep={setCurrentStep}
        />
      ),
      [STEPS.SECOND_SCREEN]: <SecondScreen {...props} />
    };

    return steps[step];
  };

  return <>{getCurrentStep(currentStep)}</>;
};

export default withTranslation()(SignUpView);
