import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userService } from '../../../services/user.service';
import { Card } from 'antd';
import EventEmitter from 'react-native-eventemitter';
import { openNotification } from '../../../utils';
import '../index.css';
import useConfigurationPermissions from '../../../hooks/useConfigurationPermissions';

// Navegaciones
import SettingProyect from '../../setting/Navbars/SettingProyect';
import CardProfile from '../../../components/Settings/settingsProyect/CardProfile';
// Cards config proyect
import CardGeneral from '../../../components/Settings/settingsProyect/CardGeneral';
import CardUsers from '../../../components/Settings/settingsProyect/CardUsers';
import CardCategoria from '../../../components/Settings/settingsProyect/CardCategoria';
import CardSubContracts from '../../../components/Settings/settingsProyect/CardSubContracts';
import CardReports from '../../../components/Settings/settingsProyect/CardReports';
import { withTranslation } from 'react-i18next';
import { getSignedUser } from '../../../utils/userUtils';

function SettingsProyect(props) {
  const { t } = props;
  const [Router, setRouter] = useState(1);
  const [InfoUser, setInfoUser] = useState({});
  const [stateUpload, setStateUpload] = useState({ loading: false });
  const userStorage = getSignedUser();
  const stateProject = useSelector((state) => state.projectState);

  const permission = useConfigurationPermissions();
  useEffect(() => {
    const callback = (data) => {
      if (!stateProject.projectSelected) {
        const alertErrorMailExists = {
          title: t('settings.settings'),
          description: t('settings.project_no_selected'),
          type: 'error'
        };
        openNotification(alertErrorMailExists);
      } else {
        data.route(props.history);
      }
    };
    EventEmitter.on('changeMainRoute', callback);
    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, [stateProject.projectSelected]);
  useEffect(() => {
    window.Appcues.page();
  });

  useEffect(() => {
    const abortController = new AbortController();
    if (userStorage.id !== null) {
      userService
        .show(userStorage.id)
        .then((response) => {
          setInfoUser(response.user);
        })
        .catch((err) => console.log(err));
    }
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  const renderWithPermission = () => {
    if (permission.project == 'AC' || permission.project == 'V') {
      return (
        <div className="background-image">
          <div className="grid-settings">
            <CardProfile
              permissions={permission}
              {...props}
              InfoUser={InfoUser}
              setInfoUser={setInfoUser}
              stateUpload={stateUpload}
              setStateUpload={setStateUpload}
            />
            <div
              style={{
                marginTop: '140px',
                marginLeft: '135px',
                height: '100%'
              }}>
              <Card className="card-shawdow">
                <SettingProyect Router={Router} setRouter={setRouter} t={t} />
                {Router === 1 && <CardGeneral t={t} />}
                {Router === 2 && <CardUsers t={t} />}
                {Router === 3 && <CardCategoria t={t} />}
                {Router === 4 && <CardSubContracts t={t} />}
                {Router === 5 && <CardReports t={t} />}
              </Card>
            </div>
          </div>
        </div>
      );
    }
    return <div>{t('settings.not_permissions')}</div>;
  };

  /**
   * Render
   */
  return renderWithPermission();
}

export default withTranslation()(SettingsProyect);
