/**
    This function returns the valid date between the date_start and the constraint_date of a milestone.
        @param {object} task Task object.
        @param {string} successorStart - Predecessor's next date to generate the plan.
        @returns {boolean} Returns the valid date.
    */
const constraintValidationsForDrag = (task, successorStart) => {
  if (!task || !successorStart) return new Date();
  const CONSTRAINT_TYPES = {
    MFO: 'mfo',
    MSO: 'mso',
    FNLT: 'fnlt',
    FNET: 'fnet',
    SNET: 'snet',
    SNLT: 'snlt'
  };
  const { constraint_type, constraint_date } = task;

  const constraintHandlers = {
    [CONSTRAINT_TYPES.MSO]: () => constraint_date,
    [CONSTRAINT_TYPES.SNLT]: () =>
      constraint_date < successorStart ? constraint_date : successorStart,
    [CONSTRAINT_TYPES.SNET]: () =>
      constraint_date > successorStart ? constraint_date : successorStart,
    [CONSTRAINT_TYPES.MFO]: () => constraint_date,
    [CONSTRAINT_TYPES.FNLT]: () =>
      constraint_date < successorStart ? constraint_date : successorStart,
    [CONSTRAINT_TYPES.FNET]: () =>
      constraint_date > successorStart ? constraint_date : successorStart
  };

  const handler = constraintHandlers[constraint_type];
  if (handler) return new Date(handler());
  return successorStart;
};
export { constraintValidationsForDrag };
