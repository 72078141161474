export const RENDER_MATERIAL = {
  columnName: 'materialId',
  width: '100%',
  multiple: false,
  callbackOnSelection: (payload) => {},
  callbackOnClickCreate: (payload) => {},
  callbackOnRemove: (payload) => {},
  mainObject: {
    materialId: null
  },
  attrToExtractFromOption: 'id',
  attrToLabelFromOption: 'name',
  options: [
    {
      name: 'Material 1',
      id: 1
    },
    {
      name: 'Material 2',
      id: 2
    },
    {
      name: 'Material 3',
      id: 3
    }
  ],
  includeRemoveFooter: true
};

export const RENDER_MACHINERY = {
  columnName: 'machineId',
  width: '100%',
  multiple: false,
  callbackOnSelection: (payload) => {},
  callbackOnClickCreate: (payload) => {},
  callbackOnRemove: (payload) => {},
  mainObject: {
    machineId: null
  },
  attrToExtractFromOption: 'id',
  attrToLabelFromOption: 'name',
  options: [
    {
      name: 'Maquina 1',
      id: 1
    },
    {
      name: 'Maquina 2',
      id: 2
    },
    {
      name: 'Maquina 3',
      id: 3
    }
  ],
  includeRemoveFooter: true
};

export const RENDER_SPECIALTY = {
  columnName: 'specialtyId',
  width: '100%',
  multiple: false,
  callbackOnSelection: (payload) => {},
  callbackOnClickCreate: (payload) => {},
  callbackOnRemove: (payload) => {},
  mainObject: {
    specialtyId: null
  },
  attrToExtractFromOption: 'id',
  attrToLabelFromOption: 'name',
  options: [
    {
      name: 'Especialidad 1',
      id: 1
    },
    {
      name: 'Especialidad 2',
      id: 2
    },
    {
      name: 'Especialidad 3',
      id: 3
    }
  ],
  includeRemoveFooter: true
};

export const RENDER_SUB = {
  columnName: 'subcontractId',
  width: '100%',
  multiple: false,
  callbackOnSelection: (payload) => {},
  callbackOnClickCreate: (payload) => {},
  callbackOnRemove: (payload) => {},
  mainObject: {
    subcontractId: null
  },
  attrToExtractFromOption: 'id',
  attrToLabelFromOption: 'name',
  options: [
    {
      name: 'Sub 1',
      id: 1
    },
    {
      name: 'Sub 2',
      id: 2
    },
    {
      name: 'Sub 3',
      id: 3
    }
  ],
  includeRemoveFooter: true
};

export const RENDER_TAG = {
  columnName: 'tags',
  width: '100%',
  multiple: true,
  callbackOnSelection: (payload) => {},
  callbackOnClickCreate: (payload) => {},
  callbackOnRemove: (payload) => {},
  mainObject: {
    tags: [
      {
        name: 'Tag 1',
        id: 1
      },
      {
        name: 'Tag 2',
        id: 2
      }
    ]
  },
  attrToExtractFromOption: 'id',
  attrToLabelFromOption: 'name',
  options: [
    {
      name: 'Tag 1',
      id: 1
    },
    {
      name: 'Tag 2',
      id: 2
    },
    {
      name: 'Tag 3',
      id: 3
    }
  ],
  includeRemoveFooter: true
};

export const RENDER_RESPONSIBLE = {
  columnName: 'responsables',
  width: '100%',
  multiple: true,
  callbackOnSelection: (payload) => {},
  callbackOnClickCreate: (payload) => {},
  callbackOnRemove: (payload) => {},
  mainObject: {
    responsables: [
      {
        name: 'Responsible 1',
        id: 1
      }
    ]
  },
  attrToExtractFromOption: 'id',
  attrToLabelFromOption: ['name', 'lastname'],
  options: [
    {
      name: 'Responsible 1',
      id: 1
    },
    {
      name: 'Responsible 2',
      id: 2
    },
    {
      name: 'Responsible 3',
      id: 3
    }
  ],
  includeRemoveFooter: false,
  hideCreateBtn: true
};
