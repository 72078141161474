import React, { useState } from 'react';

import { splitText } from '../../../ActivitiesUnitsGrid/index.helper';
import '../../index.css';
import reTaktIcon from '../../../../assets/img/reprogramtakt.png';
import { Popover, Tooltip, DatePicker } from 'antd';
import moment from 'moment';
import { notifyMessage } from '../../../../utils/lookahead-common';
let nPerWeek = 0;
let dateFromReSchedule = moment(new Date());
let ref;
/**
 * This component is the rendered fixed col row, actually activities col (to easy understand)
 * @param {*} data all activities in lineal tree structure
 * @param {*} width width for this col
 */
export const FixedColActivitiesRowGroupped =
  (data, width, viewFontSize = 10, height, reSchedule = () => {}, t) =>
  ({ columnIndex, rowIndex, style }) => {
    const grouppedActivity = data[rowIndex];

    let extraHeight = 0;
    if (grouppedActivity.maxHeight > height) {
      extraHeight = 300;
    }

    const reSchedulePreview = () => {
      if (nPerWeek > 0 && nPerWeek != '' && nPerWeek != '0') {
        reSchedule(grouppedActivity, nPerWeek, dateFromReSchedule);
        nPerWeek = 0;
      } else {
        notifyMessage({
          title: t('reScheduleTakt.unit_per_week_error'),
          type: 'warning'
        });
      }
    };

    const renderPopContent = () => (
      <div style={{ width: 350 }}>
        <div className="header-retakt" style={{ fontSize: 15 }}>
          <span style={{ color: '#2C3421' }}>
            {' '}
            {t('reScheduleTakt.are_programming')}{' '}
          </span>
          <span style={{ color: '#7DFF8A' }}>{grouppedActivity.name}</span>
        </div>
        <div style={{ color: '#2C3421', textAlign: 'center', fontSize: 15 }}>
          {t('reScheduleTakt.rythm')}
        </div>
        <div style={{ textAlign: 'center', marginBottom: 30 }}>
          <input
            min={0}
            style={{ border: 0, fontSize: 15, width: 50 }}
            defaultValue={0}
            type="number"
            onChange={(e) => {
              nPerWeek = e.target.value;
            }}
          />
        </div>
        <div style={{ color: '#2C3421', textAlign: 'center', fontSize: 15 }}>
          {t('reScheduleTakt.time_reschedule')}
        </div>
        <div style={{ textAlign: 'center', marginBottom: 30 }}>
          <DatePicker
            allowClear={false}
            defaultValue={dateFromReSchedule}
            format={'YYYY/MM/DD'}
            onChange={(date, dateString) => {
              dateFromReSchedule = date;
            }}
          />
        </div>
        <div
          style={{ color: '#7DFF8A', textAlign: 'right', cursor: 'pointer' }}
          onClick={reSchedulePreview}>
          {t('reScheduleTakt.previewText')}{' '}
          <span style={{ fontSize: 15 }}> {'>'}</span>
        </div>
      </div>
    );

    return (
      <div className="fixed-col-activity-with-units-matrix" style={style}>
        <div
          style={{ textAlign: 'center', fontSize: viewFontSize }}
          className={'col-fixed-row-matrix retakt-btn'}>
          <span className="activities-view-takt">
            {splitText(grouppedActivity.name, width + extraHeight)}
            <span style={{ float: 'right', position: 'relative', left: -5 }}>
              <Popover
                overlayClassName="main-retakt-container"
                content={renderPopContent()}
                trigger="click"
                placement="right">
                <Tooltip title={t('reScheduleTakt.reschedule_tooltip')}>
                  <img
                    src={reTaktIcon}
                    width={10}
                    style={{ cursor: 'pointer' }}
                    className="icon-retakt"
                  />
                </Tooltip>
              </Popover>
            </span>
          </span>
        </div>
      </div>
    );
  };
