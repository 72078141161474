/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
import * as Sentry from '@sentry/react';

class PermissionService {
  constructor() {
    this.http = createHttpClient();
  }

  async getPermissionsByUsers() {
    if (!this.http) {
      console.warn('No session token available, request aborted');
      return false;
    }
    const res = await this.http
      .post('permissions/getPermissionsByUsers')
      .catch((error) => {
        Sentry.captureException(error);
        return false;
      });
    return res ? res.data : res;
  }
}
export const permissionService = new PermissionService();
