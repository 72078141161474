import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { TextInput } from '../TextInput';
import styles from './EditableLabel.module.scss';

export const EditableLabel = ({
  initialValue,
  renderer,
  onChange,
  inputConfig,
  className,
  inputClassName,
  enableEditing = true
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (newValue) => {
    setValue(newValue);
    setIsEditable(false);
    onChange && onChange(newValue);
  };

  const handleCancel = () => {
    setIsEditable(false);
  };

  const handleDisplayModeClick = (e) => {
    e.stopPropagation();

    if (!enableEditing) return;
    setIsEditable(true);
  };

  return (
    <span className={className} onClick={handleDisplayModeClick}>
      {isEditable ? (
        <TextInput
          inputConfig={inputConfig}
          className={cn(inputClassName, styles['editable-cell__text-input'])}
          onChange={handleChange}
          onCancel={handleCancel}
          autoFocus={true}
          initialValue={value}
        />
      ) : renderer ? (
        renderer(value)
      ) : (
        value
      )}
    </span>
  );
};
