/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
import { base } from './base';

const baseURL = base.apiAnalytics;

class ProjectService {
  constructor() {
    this.http = createHttpClient({ baseURL });
  }

  setFreeTrialHeader(data) {
    if (data?.isFreeTrial) {
      const loggedUser = localStorage.getItem('authToken');
      if (loggedUser) {
        this.http.defaults.headers['WWW-Authenticate'] = `Bearer ${loggedUser}`;
      }
    }
  }

  async show(projectId) {
    if (!projectId) {
      console.warn('No session token available, request aborted');
      return false;
    }
    const res = await this.http.get('projects/' + projectId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async closecommitbysector(sectorId, week, year) {
    const res = await this.http
      .get('projects/closecommitbysector/' + sectorId + '/' + week + '/' + year)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(projectId) {
    const res = await this.http
      .delete('projects/' + projectId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('projects', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('projects/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updateHoursPerDay(data) {
    const res = await this.http
      .put(`projects/${data.id}/hoursperday`, { hoursPerDay: data.hoursPerDay })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async asignProjectUser(data) {
    const res = await this.http
      .post('projects/assign/user', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async asignProjectSubContract(data) {
    const res = await this.http
      .post('projects/assign/subcontract', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async deallocateUserOfProject(data) {
    const res = await this.http
      .post('projects/deallocate/user', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res;
  }

  async deallocateSubcontracOfProject(data) {
    const res = await this.http
      .post('projects/deallocate/subcontract', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async ProjectsByUser(userId) {
    const res = await this.http
      .get('projects/byuser/' + userId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showAnalitic(projectId, ourRequest = null) {
    let res;
    if (ourRequest) {
      res = await this.http
        .get('projects/analitic/' + projectId, {
          cancelToken: ourRequest?.token || null
        })
        .catch((error) => {
          console.log(error.response);
          return false;
        });
    } else {
      res = await this.http
        .get('projects/analitic/' + projectId)
        .catch((error) => {
          console.log(error.response);
          return false;
        });
    }
    // return res ? res.data : res
    return res;
  }

  async showAnaliticByFilter(data) {
    const res = await this.http
      .post('projects/analitic_filter', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async duplicateProject(data) {
    try {
      this.setFreeTrialHeader(data);
      const res = await this.http.post('projects/duplicate', data);
      return res.data;
    } catch (error) {
      console.error(
        'Error duplicating project:',
        error.response || error.message
      );
      return false;
    }
  }

  async assignProjectFreeTrialSuperAdmin(data) {
    try {
      this.setFreeTrialHeader(data);
      const res = await this.http.post(
        'projects/assignfreetrial/superadmin',
        data
      );
      return res.data;
    } catch (error) {
      console.error(
        'Error assigning project to super admin:',
        error.response || error.message
      );
      return false;
    }
  }

  async getScheduleOfScheduleProjects(subcontractId) {
    const params = new URLSearchParams(subcontractId && { subcontractId });
    const separator = params.size > 0 ? '?' : '';
    const url = `projects/overview${separator}${params.toString()}`;
    return this.http.get(url);
  }
}

export const projectService = new ProjectService();
