/** React components  */
import React, { useState, useEffect, Fragment } from 'react';
import { Redirect } from 'react-router-dom';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';
/** Services */
import { passwordResetService } from '../../services';

/** import elements from library Antd Framework */
import { Button, Input } from 'antd';

/** import component for validation */
import ErrorValidationLabel from '../../components/Login/ValidationLabel';

/** import common functions from utils */
import {
  checkAllFieldsValid,
  reduceFormValues,
  txtFieldState,
  openNotification,
  MsgErrorDB
} from '../../utils';
import { base } from '../../services/base';
import { withTranslation } from 'react-i18next';

import { Trans } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import ProPlanner from '../../assets/img/proplanner.svg';
import RecoverPassword from '../../assets/img/recover-password.svg';
import NotificationSystem from '../../components/DesignSystem/NotificationSystem';

import { trackingEvent } from '../../analytics';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';

import './index.scss';
import LayoutLoginOutbuild from '../../components/LayoutLoginOutbuild';

/** state for component */
/** Structure:
    {
        email,
        allFieldsValid: false, // handle all field form is valid
        isLoading: false, // handle loading
        switchViewForm: false // handle view form
    }
*/
// state Object
const stateTemplate = {
  email: {
    ...txtFieldState,
    fieldName: 'email',
    required: true,
    requiredTxt: 'login.user_required',
    formatErrorTxt: 'login.user_error_format'
  },
  allFieldsValid: false,
  isLoading: false,
  switchViewForm: false
};

const EMAIL_TYPE = 'email';
const ERROR_TYPE = 'error';
const REGEX_EMAIL =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

function ForgotView(props) {
  /** hooks */
  const [state, setState] = useState(stateTemplate); // validation
  const [emailForm, setEmailForm] = useState('');
  const [emailIncorrectFormat, setEmailIncorrectFormat] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [successSendEmail, setSuccessSendEmail] = useState(false);

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  const { t } = props;

  /** redirects */
  const signed = localStorage.getItem('signed');
  if (signed) {
    return <Redirect to="/masterplan" />;
  }

  /** services */
  async function createResetToken(data) {
    const res = await passwordResetService.create(data);
    return res;
  }

  /** navigation history */
  const goToLogin = (e) => {
    e.preventDefault();
    props.history.push('login');
  };
  const goToRegister = (e) => {
    e.preventDefault();
    props.history.push('/register');
  };

  const handleInputChange = (event) => {
    setEmailForm(event.target.value);
  };

  const handleOnBlur = () => {
    setEmailIncorrectFormat(!REGEX_EMAIL.test(emailForm));
  };

  const isValidForm = () => REGEX_EMAIL.test(emailForm);

  /** logic component */
  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoadingButton(true);

    if (isValidForm && !isLoadingButton) {
      try {
        const data = {
          email: emailForm.toLowerCase(),
          link: base.front + 'changepassword/',
          lang: t('lang')
        };

        const response = await createResetToken(data);

        trackingEvent(
          'recovery_link_sending',
          {
            email_given: emailForm,
            event_result: !response
              ? 'Login issues (Server Down)'
              : response?.err
                ? 'Unsent mail'
                : 'Mail sent'
          },
          AMPLITUDE_SERVICE
        );

        setShowErrorNotification(!response);
        setSuccessSendEmail(Boolean(response));
        setIsLoadingButton(false);
      } catch (err) {
        trackingEvent(
          'recovery_link_sending',
          {
            email_given: emailForm,
            event_result: 'Login issues (Server Down)'
          },
          AMPLITUDE_SERVICE
        );

        setShowErrorNotification(true);
        setIsLoadingButton(false);
      }
    }
    /* e.preventDefault();
        const form = e.target;
        const formValues = reduceFormValues(form.elements, state);
        const allFieldsValid = checkAllFieldsValid(formValues);
        setState({ ...state, ...formValues, allFieldsValid });
        const userLang = navigator.language || navigator.userLanguage;
        if (allFieldsValid) {
            setState({
                ...state,
                isLoading: true
            })
            const data = {
                email: state.email.value.toLowerCase(),
                link: base.front + 'changepassword/',
                lang: userLang.includes('es') ? 'es' : 'en'
            }
            const resp = await createResetToken(data)
            setState({
                ...state,
                isLoading: false
            })
            if (resp.err && resp.err.msj) {
                const msgErrorDB = MsgErrorDB(resp.err.type);
                const msgError = t(`register.${msgErrorDB}`)
                const alertErrorMailExists = {
                    title: t('forgot.forgot'),
                    description: msgError,
                    type: 'error'
                }
                openNotification(alertErrorMailExists)
            } else {
                const alertErrorMailExists = {
                    title: t('forgot.forgot'),
                    description: t('forgot.ticket_send'),
                    type: 'success'
                }
                openNotification(alertErrorMailExists)
                setState({
                    ...state,
                    switchViewForm: true
                })
            }
        } */
  };

  const containerForgotPassword = () => (
    <>
      <div className="forgot__title">
        <h3>{t('password_email.title_forgot_password')}</h3>
      </div>
      <div className="forgot__notification">
        {showErrorNotification && (
          <NotificationSystem
            type={ERROR_TYPE}
            label={t('password_email.notification_error_text')}
          />
        )}
      </div>
      <div className="forgot__form">
        <form onSubmit={onSubmit}>
          <div className="form__input">
            <label>{t('password_email.label_email')}</label>
            <Input
              value={emailForm}
              onChange={handleInputChange}
              onBlur={() => handleOnBlur()}
              className={emailIncorrectFormat ? ERROR_TYPE : ''}
              type={EMAIL_TYPE}
              name={EMAIL_TYPE}
              autoComplete="off"
            />
            {emailIncorrectFormat && (
              <small>{t('password_email.hint_email')}</small>
            )}
          </div>
          <div className="form__input">
            <button type="submit" disabled={!isValidForm()}>
              {isLoadingButton ? (
                <LoadingOutlined />
              ) : (
                t('password_email.button_send_text')
              )}
            </button>
          </div>
          <div className="form__login">
            <a href="javascript:void(0);" onClick={goToLogin}>
              {t('password_email.button_login_text')}
            </a>
          </div>
        </form>
      </div>
    </>
  );

  const containerSuccessSendEmail = () => (
    <>
      <div className="forgot__title">
        <h3>{t('password_email.title_forgot_password_send')}</h3>
      </div>
      <div className="forgot__content">
        <img src={RecoverPassword} alt="Icon Forgot" />
        <span>
          <Trans
            i18nKey="password_email.message_forgot_password_send"
            values={{ email: emailForm }}
            components={{ bold: <b /> }}
          />
        </span>
      </div>
      <div className="forgot__login">
        <a href="javascript:void(0);" onClick={goToLogin}>
          {t('password_email.button_login_text')}
        </a>
      </div>
    </>
  );

  /** validation */
  const { email } = state;
  const renderEmailValidationError = email.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      txtLbl={
        email.typeMismatch ? t(email.formatErrorTxt) : t(email.requiredTxt)
      }
    />
  );

  /** render */

  return (
    <LayoutLoginOutbuild>
      {successSendEmail
        ? containerSuccessSendEmail()
        : containerForgotPassword()}
    </LayoutLoginOutbuild>
  );
  return (
    <div className="divSection frm-forgot">
      {state.switchViewForm ? (
        <Fragment>
          <div className="imgDegrade">&nbsp;</div>
          <h1 className="headerMain">{t('password_email.complete_title')}</h1>
          <h3 style={{ textAlign: 'center' }}>
            {t('password_email.complete_title_1')}
          </h3>
          <h3 style={{ textAlign: 'center' }}>
            {t('password_email.complete_title_2')}
          </h3>
          <h3 style={{ textAlign: 'center' }}>
            {t('password_email.complete_title_3')}
          </h3>
        </Fragment>
      ) : (
        <Fragment>
          <div className="imgDegrade">&nbsp;</div>
          <h1 className="headerMain">{t('password_email.forgot_password')}</h1>
          <form className="frmWide" onSubmit={onSubmit} noValidate>
            <Input
              className="centerTxt"
              type="email"
              name="email"
              placeholder={t('password_email.enter_mail')}
              autoComplete="off"
              onChange={(e) => {
                /** update state with value selected */
                setState({
                  ...state,
                  email: {
                    ...state.email,
                    value: e.target.value,
                    valid: true /** clear validation */
                  }
                });
              }}
              required
            />
            {renderEmailValidationError}

            <Button htmlType="submit" loading={state.isLoading}>
              {t('password_email.send_mail')}
            </Button>

            {/* <span className="labelFrm">{t('password_email.sign_up')}&nbsp;<a href="#/" onClick={goToRegister} className="refLink">{t('password_email.sign_up_1')}</a></span> */}
            <span className="labelFrm">
              {t('password_email.already_account')}&nbsp;
              <a href="#/" onClick={goToLogin} className="refLink">
                {t('password_email.already_account_1')}
              </a>
            </span>
          </form>
        </Fragment>
      )}
    </div>
  );
}

export default withTranslation()(ForgotView);
