import { useEffect } from 'react';

export const useLogout = () => {
  const handleLogout = (e) => {
    const { key, oldValue, newValue } = e;
    if (key || oldValue || newValue) return;

    sessionStorage.clear();
    window.location.href = '/login';
  };

  useEffect(() => {
    window.addEventListener('storage', handleLogout);
    return () => window.removeEventListener('storage', handleLogout);
  }, []);
};
