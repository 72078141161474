import React, { useState, useEffect, useRef } from 'react';
import TooltipSystemV2 from '../TooltipSystemV2';

const TruncatedTextSystem = ({
  name,
  placement,
  maxWidth,
  children,
  color
}) => {
  const divRef = useRef(null);

  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (divRef.current) {
      setIsTruncated(divRef.current.scrollWidth > divRef.current.clientWidth);
    }
  }, [children]);

  return (
    <div
      ref={divRef}
      className="truncated-text-system"
      style={{ maxWidth, color }}>
      {isTruncated
        ? TooltipSystemV2({
            title: name,
            placement: placement || 'top',
            children: children
          })
        : children}
    </div>
  );
};

export default TruncatedTextSystem;
