import React from 'react';
import { Layout } from 'antd';
import { Animated } from 'react-animated-css';
import loginViewImg from '../assets/img/imgLogin.jpg';
import './hoc.css';

const { Sider, Content } = Layout;

const withoutSidenavReverse = (props) => (WrappedComponent) => {
  return () => (
    <div className="without-sidenav-reverse">
      <WrappedComponent />
    </div>
  );

  return () => (
    <Animated
      animationIn="slideInRight"
      animationInDuration={750}
      isVisible={true}
      style={{ height: '100%' }}>
      <Layout id="layoutWide">
        <Layout>
          <Content>
            <WrappedComponent />
          </Content>
        </Layout>
        <Sider>
          <img
            style={{ height: '100%', width: '100%' }}
            src={loginViewImg}
            alt=""
          />
        </Sider>
      </Layout>
    </Animated>
  );
};

export default withoutSidenavReverse;
