import { activityPdfService } from '../../../../../services';
import { checkBackendAccess } from '../../../../../utils/checkBackendAccess';
import {
  getCurrentProject,
  getCurrentSector,
  getSignedUser
} from '../../../../../utils/userUtils';
import { ALL_CONTENT } from './constants';
import { getPageDimensions, langExportPDF } from './helpers/configPages';
import {
  getGanttToExport,
  getZoomLevel,
  setupGanttAfterPDFGeneration,
  setupGanttBeforePDFGeneration
} from './helpers/updateGantt';
import {
  showExportPDFAlert,
  showNetworkBlockAlert
} from './helpers/notifyUser';
import {
  createArrayOfFilesPDF,
  getExportPDFName,
  handleEventResultExportPDF
} from './helpers/exportPDF';
import {
  trackingEventExportPDFResult,
  trackingEventUnknown
} from './helpers/trackingEvent';
import * as Sentry from '@sentry/react';

/**
 * Generates and exports the Gantt chart as a PDF file.
 *
 * @param {Object} gantt - The Gantt chart instance to be exported.
 * @param {Function} t - Translation function for localization.
 * @param {Object} config - Configuration settings for the PDF export.
 * @param {string} config.formatToExport - The format of the export (e.g., all content, visible content).
 * @param {boolean} isSchedule - Determines if the export is for a schedule or lookahead.
 *
 * @returns {Promise<Object>} A promise that resolves with the response from the PDF export service.
 */
const createPDFExport = async ({ gantt, t, config, isSchedule, zoomLevel }) => {
  const lang = langExportPDF();

  const { formatToExport } = config;

  setupGanttBeforePDFGeneration({ gantt, isSchedule, formatToExport });
  const pageDimensions = getPageDimensions({ gantt, formatToExport });

  const arrayOfFiles = createArrayOfFilesPDF({
    gantt,
    t,
    lang,
    pageDimensions,
    isLookahead: !isSchedule,
    config
  });

  const currentUser = getSignedUser();
  const jsonData = JSON.stringify(arrayOfFiles);

  const { id: projectId, name: projectName } = getCurrentProject();
  const { id: sectorId, name: sectorName } = getCurrentSector();

  const exportName = getExportPDFName({
    gantt,
    isSchedule,
    projectName,
    sectorName,
    formatToExport
  });

  const exportPDFData = {
    data: jsonData,
    name: exportName,
    current_user: currentUser,
    lang,
    project_id: projectId,
    project: projectName,
    sector_id: sectorId,
    sector: sectorName,
    is_schedule: isSchedule
  };

  const response = await activityPdfService.exportToPDF(exportPDFData);

  setupGanttAfterPDFGeneration({
    gantt,
    isSchedule,
    resetZoomLevel: zoomLevel
  });

  return response;
};

/**
 * Initiates the process to export the Gantt chart as a PDF file.
 *
 * @param {boolean} [isSchedule=true] - Indicates if the export is for a schedule.
 * @param {Function} t - Translation function for localization.
 * @param {Object} [config={}] - Configuration settings for the export.
 * @param {Date} [config.dateSelected=new Date()] - The selected date for the export.
 * @param {boolean} [config.isTrimParent=false] - Whether to trim the parent task in the export.
 * @param {boolean} [config.isFitOnePage=false] - Whether to fit the export to a single page.
 * @param {string} [config.formatToExport=ALL_CONTENT] - The format of the export (e.g., all content, visible content).
 *
 * @returns {Promise<void>} A promise that resolves when the PDF export process is complete.
 */
export const exportPDFGantt = async ({
  isSchedule = true,
  t,
  handleCloseModal = (_) => {},
  openFitToOnePageModal = (_) => {},
  config = {}
}) => {
  try {
    const gantt = getGanttToExport(isSchedule);

    if (!gantt) {
      return;
    }

    const statusServer = await checkBackendAccess(activityPdfService);
    if (!statusServer) {
      showNetworkBlockAlert({ t, isSchedule });
      return;
    }

    const zoomLevel = getZoomLevel(gantt);

    const {
      dateSelected = new Date(),
      isTrimParent = false,
      isFitOnePage = false,
      formatToExport = ALL_CONTENT
    } = config;

    const now = new Date();
    const isShowTodayLinePDF = gantt.config.show_todayline;

    const configPage = {
      isFitOnePage,
      isTrimParent,
      newDate: now,
      newDateToday: now,
      formatToExport,
      isShowTodayLinePDF,
      dateSelected
    };

    const exportResult = await createPDFExport({
      gantt,
      t,
      config: configPage,
      isSchedule,
      zoomLevel
    });

    const eventResult = showExportPDFAlert({ t, exportResult });

    handleEventResultExportPDF({ exportResult, gantt, openFitToOnePageModal });
    trackingEventExportPDFResult({
      gantt,
      isSchedule,
      zoomLevel,
      configPage,
      eventResult
    });
  } catch (error) {
    trackingEventUnknown({ t, isSchedule });
    Sentry.captureException(error);
  } finally {
    handleCloseModal();
  }
};
