/** React components  */
import React from 'react';

/** Import elements from library Antd */
import { Select } from 'antd';

import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import { ArrowLeftIcon, ArrowRigthIcon } from '../../../../../icons';

export default function HeaderCalendar(props) {
  /** use props */
  const { value, onChange } = props;

  /** logic component */
  const start = 0;
  const end = 12;
  const monthOptions = [];

  const current = value.clone();
  const localeData = value.localeData();
  const months = [];

  // build months
  for (let i = 0; i < 12; i++) {
    current.month(i);
    months.push(localeData.monthsShort(current));
  }

  // build select monts
  for (let index = start; index < end; index++) {
    monthOptions.push(
      <Select.Option className="month-item" key={`${index}`}>
        {months[index]}
      </Select.Option>
    );
  }

  // get current data in view
  const month = value.month();

  // navigate months
  const handleChangeMonth = (value, to, onChange) => {
    const newValue = value.clone();
    newValue.month(to);
    onChange(newValue);
  };

  const old = false;

  // render
  return old ? (
    <div className="constraint-header-custom">
      {/* <div className="constraint-wrap-header-calendar"> */}
      <div
        className="navigation-links first-month"
        onClick={() => handleChangeMonth(value, 0, onChange)}>
        <div className="constraint-label-calendar">
          {value.format('MMM, D YYYY')}
        </div>
        {/* <LeftOutlined className="constraint-calendar-leftoutlined-navigation-links" />
                    <LeftOutlined className="constraint-calendar-leftoutlined-navigation-links" /> */}
      </div>

      <LeftOutlined className="constraint-calendar-navigation-links" />

      <RightOutlined
        className="constraint-calendar-navigation-links"
        onClick={() =>
          handleChangeMonth(value, parseInt(month, 10) + 1, onChange)
        }
      />
      <div
        className="constraint-calendar-navigation-links last-month"
        onClick={() => handleChangeMonth(value, 11, onChange)}>
        {/* <RightOutlined/>
                    <RightOutlined/> */}
      </div>

      {/* </div> */}
    </div>
  ) : (
    <div className="header-calendar">
      <div
        className="calendar__left-arrow"
        onClick={() =>
          handleChangeMonth(value, parseInt(month, 10) - 1, onChange)
        }>
        <ArrowLeftIcon color="#7DFF8A" />
      </div>
      <div className="calendar__date">
        <span>{value.format('MMMM, DD YYYY')}</span>
      </div>
      <div
        className="calendar__right-arrow"
        onClick={() =>
          handleChangeMonth(value, parseInt(month, 10) + 1, onChange)
        }>
        <ArrowRigthIcon color="#7DFF8A" />
      </div>
    </div>
  );
}
