import React from 'react';
import cn from 'classnames';

import styles from './suggestionlist.module.scss';

const SuggestionList = (props) => {
  const { activeSuggestion, filteredSuggestions = [], onClick } = props;

  return (
    <div className={styles.container}>
      <ul className={styles['list-container']}>
        {filteredSuggestions.map((suggestion) => (
          <li
            key={suggestion}
            onClick={onClick}
            className={cn(styles.suggestion, {
              [styles['active-suggestion']]:
                suggestion.value === activeSuggestion.value
            })}>
            {suggestion.value}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SuggestionList;
