import React from 'react';
import './CompanyImage.scss';

export default function CompanyImage(image, name, size = 30, border = false) {
  if (!name) return;
  const defaultStyle = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '50%'
  };

  const imageDefault = () => {
    const firstChar = name.charAt(0);

    const renderImage = (
      <div className={`CompanyImage__default`} style={defaultStyle}>
        {firstChar}
      </div>
    );
    return renderImage;
  };

  const imageCompany = () => {
    return (
      <img
        className={`CompanyImage__img ${border ? 'CompanyImage__border' : ''}`}
        src={image}
        alt={name}
        style={defaultStyle}
      />
    );
  };
  return <>{image ? imageCompany() : imageDefault()}</>;
}
