/** React components  */
import React, { useState, useEffect, Fragment } from 'react';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/** Services */
import { userService, authService } from '../../services';

/**  import library for animations effects */
import { Animated } from 'react-animated-css';

/** import elements from library Antd */
import { Spin, Avatar, Input, Row, Col, Button, Select, Upload } from 'antd';
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';

/** import hook for handle params */
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/** import components */
import ErrorValidationLabel from '../../components/Login/ValidationLabel';
import TokenNoValid from '../../components/Login/tokenNoValid';

/** import common functions from utils */
import {
  checkAllFieldsValid,
  reduceFormValues,
  txtFieldState,
  positions,
  generateFormObjectSelect,
  getBase64,
  headersSendForm
} from '../../utils';

/** import base contant (setting urls)  */
import { base } from '../../services/base';

/** import library for antd upload crop */
import ImgCrop from 'antd-img-crop';
import { redirectSelectCompany } from '../login/login.helpers';

const { Option } = Select;

/** state for component */
/** Structure:
    {
        fname,
        lname,
        password,
        email,
        position,
        repassword,
        allFieldsValid: false,
        isLoading: false, // handle loading button
        loadingForm: true, // show loading until fetch is ok
        tokenValid: false, // handle resp if token is valid
        currentUser: {}, // handle user according to token
        stateUpload: { loading: false } //handle upload image
    }
*/
const stateTemplate = {
  fname: {
    ...txtFieldState,
    fieldName: 'fname',
    required: true,
    requiredTxt: 'Name is required',
    formatErrorTxt: ''
  },
  lname: {
    ...txtFieldState,
    fieldName: 'lname',
    required: true,
    requiredTxt: 'Last name is required',
    formatErrorTxt: ''
  },
  password: {
    ...txtFieldState,
    fieldName: 'password',
    required: true,
    requiredTxt: 'Password is not valid',
    formatErrorTxt: "Passwords don't match"
  },
  email: {
    ...txtFieldState,
    fieldName: 'email',
    required: false,
    requiredTxt: '',
    formatErrorTxt: ''
  },
  position: {
    ...txtFieldState,
    fieldName: 'position',
    required: false,
    requiredTxt: '',
    formatErrorTxt: ''
  },
  repassword: {
    ...txtFieldState,
    fieldName: 'repassword',
    required: true,
    requiredTxt: 'Password is not valid',
    formatErrorTxt: "Passwords don't match"
  },
  allFieldsValid: false,
  isLoading: false,
  loadingForm: true,
  tokenValid: false,
  currentUser: {},
  stateUpload: { loading: false }
};

export default function ConfirmationView(props) {
  // hooks
  const { token } = useParams(); // handle params
  const [state, setState] = useState(stateTemplate); // state
  const [noMatch, setNoMatch] = useState(false);
  const { t } = useTranslation();
  const userLang = navigator.language || navigator.userLanguage;

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };
    const msg =
      userLang.indexOf('es') !== -1
        ? 'Crea tu ' + '<span id="spanActive">' + 'Perfil' + '</span>'
        : 'Create your ' + '<span id="spanActive">' + 'Profile' + '</span>';
    document.getElementById('titleHeader').innerHTML = msg;
    EventEmitter.on('changeMainRoute', callback);
    loadUser();

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  const loadUser = async () => {
    const user = await getUser(token);
    if (user !== false) {
      user.email = user.email.replace(/\s+/g, '');
      if (!user.position) {
        /** profile first time */
        setState({
          ...state,
          loadingForm: false,
          tokenValid: true,
          currentUser: user
        });
      } else {
        /** if user profile exist.  */
        setState({
          // update position from current user
          ...state,
          loadingForm: false,
          tokenValid: true,
          currentUser: user,
          position: {
            ...state.position,
            value: user.position
          }
        });
      }
    } else {
      /** user not exists  */
      setState({
        ...state,
        loadingForm: false,
        tokenValid: false
      });
    }
  };

  // TODO: Preguntar como se comporta estas pantallas (confirmation, company, add project, invite) cuando se esta logueado o no
  // !signed => next
  // signed => verify token ; TODO: add field(status) to passwordresets table
  //          token is valid  => next
  //          token !valid    => redirect to / or masterplan

  // services
  async function updateUser(data) {
    await userService.update(data);
  }

  async function getUser(token) {
    const resp = await userService.getbytoken(token);
    return resp;
  }

  /** Logic component */
  const onSubmit = async (e) => {
    e.preventDefault();
    if (state.password.value !== state.repassword.value) {
      setNoMatch(true);
      return;
    }
    setNoMatch(false);

    const form = e.target;
    const formValues = reduceFormValues(form.elements, state);
    // add selects to validation
    formValues.position = generateFormObjectSelect(
      'position',
      'Position',
      state.position.value
    );
    const allFieldsValid = checkAllFieldsValid(formValues);
    setState({ ...state, ...formValues, allFieldsValid });

    if (allFieldsValid) {
      setState({
        ...state,
        isLoading: true
      });
      if (state.currentUser) {
        // TODO: firstName / lastName merge field? Role by default?
        const email = state.currentUser.email.toLowerCase().replace(/\s+/g, '');
        const data = {
          name: state.fname.value,
          lastname: state.lname.value,
          email: state.currentUser.email.replace(/\s+/g, ''),
          id: state.currentUser.id,
          is_active: true,
          role: email.includes('super@')
            ? 'superadmin'
            : state.currentUser.role || 'admin',
          password: state.password.value,
          position: state.position.value,
          companyId: state.currentUser.companyId || null
        };
        /** save image uploaded */
        data.image = state.stateUpload.name ? state.stateUpload.name : null;
        await updateUser(data);
        /** call auth */
        const dataAuth = {
          user: state.currentUser.email,
          password: state.password.value
        };
        const resp = await authService.index(dataAuth);

        return redirectSelectCompany(resp);
      }
    }
  };

  /** this function is triggered when upload image */
  const onChangeUpload = (info) => {
    if (info.file.status === 'uploading') {
      setState({
        ...state,
        stateUpload: {
          loading: true
        }
      });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response
      getBase64(info.file.originFileObj, (imageUrl) =>
        setState({
          ...state,
          stateUpload: {
            imageUrl,
            name: info.file.response.name,
            loading: false
          }
        })
      );
    }
  };

  /** component button */
  const uploadButton = (
    <div>
      {state.stateUpload.loading ? (
        <LoadingOutlined />
      ) : state.currentUser.image ? (
        <Avatar size={140} src={`${state.currentUser.image}`} />
      ) : (
        <Avatar className="FrmAvatar" size={140} icon={<UserOutlined />} />
      )}
    </div>
  );

  // validation
  const { fname, lname, position } = state;

  const renderFnameValidationError = fname.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={
        fname.typeMismatch
          ? fname.formatErrorTxt
          : t('confirmation.first_name_req')
      }
    />
  );

  const renderLnameValidationError = lname.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={
        lname.typeMismatch
          ? lname.formatErrorTxt
          : t('confirmation.last_name_req')
      }
    />
  );

  const renderPositionValidationError = position.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={
        position.typeMismatch
          ? position.formatErrorTxt
          : t('confirmation.position_req')
      }
    />
  );

  // render
  return (
    <Animated
      className="animatedWide"
      animationIn="fadeIn"
      animationInDuration={750}
      isVisible={true}>
      <div className="divWide">
        <div className="divSection">
          {state.loadingForm ? (
            <Spin spinning={true} />
          ) : state.tokenValid ? (
            <Fragment>
              <form className="frmWide" onSubmit={onSubmit} noValidate>
                <Row>
                  <Col span={24}>
                    <ImgCrop rotate shape="round" modalWidth={850}>
                      <Upload
                        action={`${base.api}users/upload`}
                        listType="picture-card"
                        showUploadList={false}
                        headers={headersSendForm}
                        onChange={onChangeUpload}>
                        {state.stateUpload.imageUrl ? (
                          <Avatar size={140} src={state.stateUpload.imageUrl} />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </ImgCrop>
                  </Col>
                </Row>

                <Row className="mt-20">
                  <Col span={11}>
                    <Input
                      className="w100"
                      type="text"
                      name="fname"
                      placeholder={t('confirmation.first_name')}
                      autoComplete="off"
                      defaultValue={
                        state.currentUser.name
                      } /** split until change model */
                      onChange={(e) => {
                        /** update state with value selected */
                        setState({
                          ...state,
                          fname: {
                            ...state.fname,
                            value: e.target.value,
                            valid: true /** clear validation */
                          }
                        });
                      }}
                      required
                    />
                    {renderFnameValidationError}
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Input
                      className="w100"
                      type="text"
                      name="lname"
                      placeholder={t('confirmation.last_name')}
                      autoComplete="off"
                      defaultValue={
                        state.currentUser.lastname
                      } /** split until change model */
                      onChange={(e) => {
                        /** update state with value selected */
                        setState({
                          ...state,
                          lname: {
                            ...state.lname,
                            value: e.target.value,
                            valid: true /** clear validation */
                          }
                        });
                      }}
                      required
                    />
                    {renderLnameValidationError}
                  </Col>
                </Row>

                <Row className="mt-20">
                  <Col span={11}>
                    <Input
                      className="w100"
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                      value={state.currentUser.email}
                      autoComplete="off"
                    />
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Input
                      className="w100"
                      type="text"
                      name="position"
                      placeholder={t('confirmation.position')}
                      autoComplete="off"
                      defaultValue={state.currentUser.position}
                      onChange={(e) => {
                        /** update state with value selected */
                        setState({
                          ...state,
                          position: {
                            ...state.position,
                            value: e.target.value,
                            valid: true
                          }
                        });
                      }}
                      required
                    />
                    {renderPositionValidationError}
                  </Col>
                </Row>

                <Row className="mt-20">
                  <Col span={11}>
                    <Input
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      className="w100"
                      type="password"
                      name="password"
                      placeholder={t('confirmation.create')}
                      onChange={(e) => {
                        /** update state with value selected */
                        setState({
                          ...state,
                          password: {
                            ...state.password,
                            value: e.target.value
                          },
                          repassword: {
                            ...state.password,
                            valid: true /** clear validation */
                          }
                        });
                      }}
                      required
                    />
                    <label
                      className={
                        state.repassword.valid
                          ? 'lblInfoElement'
                          : ' lblInfoElement lblerrorfrm'
                      }>
                      {t('confirmation.pass_req')}
                    </label>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Input
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      className="w100"
                      type="password"
                      name="repassword"
                      placeholder={t('confirmation.retype')}
                      onChange={(e) => {
                        /** update state with value selected */
                        setState({
                          ...state,
                          repassword: {
                            ...state.repassword,
                            value: e.target.value,
                            valid: true /** clear validation */
                          }
                        });
                      }}
                      required
                    />
                  </Col>
                  {noMatch && (
                    <label
                      className="lblInfoElement lblerrorfrm"
                      style={{ left: 55 }}>
                      {t('confirmation.no_match')}
                    </label>
                  )}
                </Row>
                <Button htmlType="submit" loading={state.isLoading}>
                  {t('confirmation.continue')}
                </Button>
              </form>
            </Fragment>
          ) : (
            <TokenNoValid />
          )}
        </div>
      </div>
    </Animated>
  );
}
