/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
import { base } from './base';
import axios from 'axios';

const baseURL = base.apiJava;
class ImporterService {
  constructor() {
    this.http = createHttpClient({ baseURL });
  }

  async validate(data) {
    const res = await this.http
      .post('gantt/importer_validar_data', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async save(data) {
    const res = await this.http
      .post('gantt/importer_save', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const importerService = new ImporterService();
