/**
 *
 * @param {Object} service - The service to use for checking backend access.
 * @param {Function} service.checkBackendAccess - The function to call to check backend access.
 * @returns {Object} {boolean} Indicates the connection status with the backend
 */
export const checkBackendAccess = async (service) => {
  const statusBackend = await service.checkBackendAccess();
  return statusBackend;
};
