import { setInitialHourDay } from './setInitialHourDay';
import { setFinalHourDay } from './setFinalHourDay';

/**
 * Sets the initial hour for all tasks, including milestones, to the start of the working day.
 * For non-milestone tasks, also sets the end hour to the end of the working day.
 *
 * @param {Object} task - The task object to set the hours for.
 */
function configureTaskHours(task) {
  const gantt = window.to_use_react_gantt;

  if (task.type !== 'milestone') {
    gantt.setInitialHourDay && setInitialHourDay(task);
    gantt.setFinalHourDay && setFinalHourDay(task);
  }
}

export { configureTaskHours };
