import React, { useState, useEffect, useRef } from 'react';
/** Build components from antd css framework */
import { Spin, Icon } from 'antd';
import Chart from 'react-apexcharts';

export default function CurvePCR(props) {
  /** props */
  const constraint = props.dataConstraints;
  const t = props.t;

  /** consts  */
  const noData = {
    text: 'No data',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: '14px',
      fontFamily: undefined
    }
  };
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  const [series, setSeries] = useState([
    {
      name: 'Porcentaje',
      type: 'column',
      data: null
    }
  ]);

  /** hooks */
  const [options, setOptions] = useState({
    chart: {
      height: 250,
      colors: ['#F44336']
    },
    stroke: {
      width: [0, 2],
      curve: ['smooth', 'straight', 'stepline']
    },
    title: {
      text: ''
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1]
    },
    labels: [],
    xaxis: {
      type: 'datetime'
    },
    yaxis: [
      {
        title: {
          text: ''
        }
      },
      {
        opposite: true,
        title: {
          text: 'PCR'
        }
      }
    ],
    colors: ['#155D77', '#7DFF8A'],
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return <p>series[seriesIndex][dataPointIndex]</p>;
      }
    }
  });

  useEffect(() => {
    let labels = [];
    let pcrs = [];
    let commitment_percentajes = [];
    if (props.dataConstraints) {
      labels = props.dataConstraints.labels;
      pcrs = props.dataConstraints.pcrs;
      commitment_percentajes = props.dataConstraints.commitment_percentajes;
    }

    if (props.dataConstraints) {
      const option = {
        chart: {
          height: 250,
          colors: ['#7DFF8A'],
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: false,
              customIcons: []
            }
          }
        },
        stroke: {
          width: [0, 2],
          curve: ['smooth', 'straight', 'stepline']
        },
        title: {
          text: t('analitic.pcr'),
          style: {
            fontSize: '13px',
            fontWeight: '500',
            fontFamily: `
              Roboto,
              -apple-system,
              BlinkMacSystemFont,
              'Segoe UI',
              Oxygen,
              Ubuntu,
              Cantarell,
              'Fira Sans',
              'Droid Sans',
              'Helvetica Neue',
              sans-serif !important
            `
          }
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [2]
        },
        labels: labels,
        xaxis: {
          type: 'category'
        },
        yaxis: [
          {
            title: {
              text: ''
            },
            max: 100,
            labels: {
              formatter: (val) =>
                t('lang') === 'en'
                  ? val + '%'
                  : val.toString().replace('.', ',') + '%'
            }
          },
          {
            opposite: true,
            title: {
              text: ''
            },
            labels: {
              formatter: (val) =>
                t('lang') === 'en' ? val : val.toString().replace('.', ',')
            }
          }
        ],
        colors: ['#7DFF8A', '#7DFF8A'],
        tooltip: {
          shared: true,
          intersect: false,
          enabled: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              '<div>' +
              `${t('lang') === 'en' ? series[seriesIndex][dataPointIndex] : series[seriesIndex][dataPointIndex].toString().replace('.', ',')}` +
              '%  <br>' +
              pcrs[dataPointIndex].realized +
              '/' +
              pcrs[dataPointIndex].total +
              ' ' +
              t('analictic.constraint') +
              '<br> ' +
              pcrs[dataPointIndex].reproramin +
              ' ' +
              t('analictic.repro') +
              '<br>' +
              pcrs[dataPointIndex].week +
              '<br>' +
              pcrs[dataPointIndex].year +
              '</div>'
            );
          }
        }
      };
      const seriesUp = [
        {
          name: 'Porcentaje',
          type: 'column',
          data: commitment_percentajes
        }
      ];
      // setOptions(option)
      setOptions(
        commitment_percentajes.length
          ? option
          : {
              ...option,
              noData
            }
      );
      setSeries(seriesUp);
    }
  }, [constraint]);

  return (
    <div style={{ height: '100%' }}>
      {series[0].data !== null || series[0].data?.length ? (
        <Chart
          options={options}
          series={series}
          type="bar"
          width="100%"
          height="100%"
        />
      ) : (
        <div className="widget-analitycs">
          <Spin
            className="loader-spinner-lookahead-table"
            indicator={antIcon}
          />
        </div>
      )}
    </div>
  );
}
