import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const DEFAULT_TYPE = 'primary';
const DEFAULT_SIZE = 'default';

const ButtonSystem = ({
  children,
  type = DEFAULT_TYPE,
  size = DEFAULT_SIZE,
  loading = false,
  icon = null,
  ...props
}) => {
  return (
    <button
      className={`button-design-system type--${type} size--${size}`}
      {...props}>
      {!loading ? (
        <>
          {icon && icon} {children}
        </>
      ) : (
        <LoadingOutlined />
      )}
    </button>
  );
};

export default ButtonSystem;
