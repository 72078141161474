import React, { useEffect, useState, useRef } from 'react';
import './index.css';
import { VariableSizeList as List } from 'react-window';
import { Checkbox, Row, Col, Popover } from 'antd';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import differenceBy from 'lodash/differenceBy';
import hideChildsIcon from '../../assets/img/takt/hidechilds.png';
import showChildsIcon from '../../assets/img/takt/showchilds.png';
import { splitText } from '../ActivitiesUnitsGrid/index.helper';

export default function ActivitiesCheckbox(props) {
  const $activityBox = useRef();
  const {
    activities,
    selectedActivities,
    onSelectedChange,
    relations,
    selectedActivitiesToDelete,
    onSelectedtoDeleteChange,
    activitiesFlush,
    t
  } = props;
  const userLang = navigator.language || navigator.userLanguage;
  const [disableActivityCheckbox, setDisableActivityCheckbox] = useState(null);
  const [render, setRender] = useState(false);
  const { height, width } = useWindowDimensions();
  useEffect(() => {
    if (!disableActivityCheckbox) {
      const activeActivities = selectedActivities.filter(
        (activity) => activity.active
      );
      const activityReference = activeActivities.shift();
      activityReference &&
        setDisableActivityCheckbox({
          ...activityReference,
          disable: activityReference.disable || false
        });
      return;
    }

    const activeActivities = selectedActivities.filter(
      (activity) => activity.active
    );
    !activeActivities.length && setDisableActivityCheckbox(null);
  }, [selectedActivities]);

  const getOnlyActiveUnits = () =>
    props.selectedUnits.filter((unit) => unit.active && unit.locationId);

  const recursiveBuild = (item, finalArray, padding) => {
    if (activitiesFlush) {
      const flushRef = activitiesFlush.find((flush) => flush.id == item.id);
      if (flushRef && !item.flushed) {
        item.hideChilds = flushRef.hideChilds;
        item.flushed = true;
      }
    }

    finalArray.push(renderSingleRow(item, padding));
    const children = item.children;
    if (children.length && !item.hideChilds) {
      children
        .sort((a, b) => a.correlative_id - b.correlative_id)
        .map((child) => {
          recursiveBuild(child, finalArray, padding + 5);
        });
    }
  };

  const renderSingleActivity =
    (data) =>
    ({ index, style }) => (
      <div style={{ ...style, width: '100%' }}>{data[index]}</div>
    );

  const renderVirtualizedList = (finalJsxArray) => (
    <List
      className="activities-checkbox-container-virtualized"
      height={height * 0.65}
      itemCount={finalJsxArray.length}
      itemSize={(index) => height * 0.03}
      width={width * 0.22}>
      {renderSingleActivity(finalJsxArray)}
    </List>
  );

  const createTreeCheckbox = () => {
    const finalJsxArray = [];
    const padding = 2;

    activities.map((ac) => recursiveBuild(ac, finalJsxArray, padding));

    return (
      <Row>
        <Col>
          <Row style={{ padding: 17 }}>
            <span style={{ color: '#2C3421', fontSize: 20 }}>
              {t('takt_assign.activitiesTitle')}
            </span>
          </Row>
          <Row style={{ padding: 17, overflow: 'auto', height: height - 250 }}>
            {renderVirtualizedList(finalJsxArray)}
            <div style={{ visibility: 'hidden' }}>{render}</div>
          </Row>
        </Col>
      </Row>
    );
  };

  const changeChildsVisibility = (activity) => {
    activity.hideChilds = !activity.hideChilds;
    setRender(!render);
  };

  /**
   * This is a single JSX render for virtualization, which represents a checkbox and a text
   * @param {*} activity activity object from pointer memory
   * @param {*} padding padding to create tree structure
   */
  const renderSingleRow = (activity, padding) => {
    let disableCheckbox = false;
    const referenceOnSelected = selectedActivities.find(
      (ac) => ac.id == activity.id
    );
    /** Consultar esta condicion con benja */
    const doesExistRelation = relations.find(
      (rel) => rel.activityId == activity.id
    );
    if (referenceOnSelected && doesExistRelation) {
      activity.disable = true;
    }

    if (disableActivityCheckbox && !activity.has_childs) {
      const activityDisable = Boolean(activity.disable);
      disableCheckbox = !(activityDisable == disableActivityCheckbox.disable);
    }

    const activeUnits = getOnlyActiveUnits();
    if (activeUnits.length) {
      if (activity.has_childs) {
        activity.children.forEach((childActivity) =>
          childActivity.productionUnits.forEach((unitActivivty) => {
            if (activeUnits.find((unit) => unit.id != unitActivivty.id)) {
              disableCheckbox = true;
            }
          })
        );
      } else if (!activity.has_childs && activity.disable) {
        disableCheckbox = true;
        activeUnits.forEach((unit) => {
          const unitIsLinkedToActivity = activity.productionUnits.find(
            (unitActivivty) => unitActivivty.id === unit.id
          );
          if (unitIsLinkedToActivity) {
            disableCheckbox = false;
          }
        });
      }
    }

    return (
      <Row style={{ marginBottom: 10, paddingLeft: padding }}>
        <Col span={1} style={{ position: 'relative', top: 1 }} offset={1}>
          {activity.children.length ? (
            <img
              className="hide-style-pointer"
              style={{ cursor: 'pointer' }}
              src={activity.hideChilds ? showChildsIcon : hideChildsIcon}
              width={10}
              onClick={() => changeChildsVisibility(activity)}
            />
          ) : null}
        </Col>
        <Col offset={1} span={20}>
          <div ref={$activityBox}>
            <Checkbox
              disabled={disableCheckbox}
              checked={activity.active}
              onChange={(e) => recursivelySelection(activity)}
            />
            <Popover
              content={
                <div>
                  <div
                    className="assign-units-tooltip"
                    data-totalUnits={
                      userLang.includes('en')
                        ? activity.productionUnits.length
                        : ` (${activity.productionUnits.length} un)`
                    }>
                    {t('takt_assign.linkedUnits')}
                  </div>
                  {activity.productionUnits
                    .sort((a, b) => {
                      const aInt = a.name.replace(/\D/g, '');
                      const bInt = b.name.replace(/\D/g, '');
                      return parseInt(aInt) - parseInt(bInt);
                    })
                    .map((pu) => (
                      <div>- {pu.name}</div>
                    ))}
                </div>
              }
              placement="right"
              overlayClassName="show-units-activity-modal"
              trigger="click">
              <span
                className={`activity ${activity.disable && 'disable'} ${activity.children.length && 'has_childs'}`}>
                {splitText(
                  activity.name,
                  $activityBox.current?.offsetWidth - 50 || 150
                )}
              </span>
            </Popover>
          </div>
        </Col>
      </Row>
    );
  };

  /**
   * This function handles with massive selection tasks
   * @param {*} selection Element to change selecton status by it active attribute
   * @param {*} flag This saves the parent selected status to match with their children
   * @param {*} toAdd Array with tasks to add to state array with massive selection
   * @param {*} toDelete Array with tasks to delete from state array with massive selection
   */
  const massiveSelectionHandler = (
    selection,
    flag = null,
    toAdd = [],
    toDelete = []
  ) => {
    /** Define array of children (activity or task) */
    const childs = selection.children;

    /** Defines first flag value from calling this function without flag */
    if (flag == null) {
      flag = !selection.active;
      selection.active = !selection.active;
    }

    /** Matchs the values to dont change already setted correct value */
    if (flag != selection.active) {
      selection.active = !selection.active;
    }

    /** Defines if the current selection must be deleted or added to state array of massive selection */
    if (selection.active && !selection.disable) {
      toAdd.push(selection);
    } else if (!selection.active && !selection.disable) {
      toDelete.push(selection);
    }

    /** Recursively repetition for children */
    if (childs) {
      if (childs.length) {
        childs.map((el) => {
          massiveSelectionHandler(el, flag, toAdd, toDelete);
        });
      }
    }
  };

  /**
   * This function handles with massive selection tasks
   * @param {*} selection Element to change selecton status by it active attribute
   * @param {*} flag This saves the parent selected status to match with their children
   * @param {*} toAdd Array with tasks to add to state array with massive selection
   * @param {*} toDelete Array with tasks to delete from state array with massive selection
   */
  const massiveSelectionToDeleteHandler = (
    selection,
    flag = null,
    toAdd = [],
    toDelete = []
  ) => {
    /** Define array of children (activity or task) */
    const childs = selection.children;

    /** Defines first flag value from calling this function without flag */
    if (flag == null) {
      flag = !selection.active;
      selection.active = !selection.active;
    }

    /** Matchs the values to dont change already setted correct value */
    if (flag != selection.active) {
      selection.active = !selection.active;
    }

    /** Defines if the current selection must be deleted or added to state array of massive selection */
    if (selection.active && selection.disable) {
      selection.selectedByUser = true;
      toAdd.push(selection);
    } else if (!selection.active && selection.disable) {
      selection.selectedByUser = true;
      toDelete.push(selection);
    }

    /** Recursively repetition for children */
    if (childs) {
      if (childs.length) {
        childs.map((el) => {
          massiveSelectionToDeleteHandler(el, flag, toAdd, toDelete);
        });
      }
    }
  };

  /**
   * This function executes massiveSelectionHandler then sets the selection state to
   * handle massive actions like deleting, changing responsable, etc.
   * @param {*} selection Initial selection
   */
  const recursivelySelection = (selection) => {
    const sele = { ...selection };
    /** Temporal array to save added task */
    const toAdd = [];

    /** Temporal array to save deleted task */
    const toDelete = [];

    /** Execution of recursively selection */
    massiveSelectionHandler(selection, null, toAdd, toDelete);

    /** Setting state of massive actions */
    /* Take off elements of massive actions state */
    const newState = differenceBy(selectedActivities, toDelete, 'id');
    /** Then we check those elements that arent at the state and add them */
    toAdd.map((el) => {
      const doesExist = newState.find((single) => el.id == single.id);
      if (!doesExist) {
        /** Filter if it is a task (avoid adding activities) */
        if ((el.children || el.activityId) && !el.disable) {
          newState.push(el);
        }
      }
    });

    onSelectedChange(newState);

    const toAddDelete = [];
    const toDeleteDelete = [];
    massiveSelectionToDeleteHandler(sele, null, toAddDelete, toDeleteDelete);

    /** Setting state of massive actions */
    /* Take off elements of massive actions state */
    const newStateDelete = differenceBy(
      selectedActivitiesToDelete,
      toDeleteDelete,
      'id'
    );
    /** Then we check those elements that arent at the state and add them */
    toAddDelete.map((el) => {
      const doesExist = newStateDelete.find((single) => el.id == single.id);
      if (!doesExist) {
        /** Filter if it is a task (avoid adding activities) */
        if ((el.children || el.activityId) && el.disable) {
          newStateDelete.push(el);
        }
      }
    });

    onSelectedtoDeleteChange(newStateDelete);
  };

  return createTreeCheckbox();
}
