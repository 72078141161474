export const totalMaterialSvg = `M1035.36,561.21c0-1.13,0-2.26-1.13-3.39l-1.13-1.13c0-1.13-1.13-1.13-2.26-2.26l-48.59-24.86l46.33-23.73
c1.13,0,2.26-1.13,2.26-1.13l1.13-1.13c1.13-1.13,1.13-2.26,1.13-2.26l1.13-1.13c0-1.13,1.13-2.26,1.13-3.39V277.58
c0-1.13,0-3.39-1.13-4.52l-1.13-1.13c-1.13-1.13-1.13-2.26-2.26-2.26L821.79,164.57c-3.39-1.13-6.78-1.13-9.04,0l-59.89,30.51
v-53.11c0-2.26,0-3.39-1.13-4.52l-1.13-1.13c-1.13-1.13-1.13-1.13-2.26-2.26L539.28,30.1c-3.39-1.13-6.78-1.13-9.04,0L322.31,134.06
l-1.13,1.13c-1.13,1.13-1.13,1.13-2.26,2.26l-1.13,1.13c-1.13,1.13-1.13,3.39-1.13,4.52v67.8l-59.89-30.51
c-3.39-1.13-6.78-1.13-9.04,0L38.67,285.49h-1.13c-1.13,1.13-1.13,1.13-2.26,2.26v1.13c-1.13,1.13-1.13,3.39-1.13,4.52v218.1v1.13
c0,1.13,0,2.26,1.13,3.39c0,1.13,1.13,2.26,2.26,3.39c0,0,1.13,1.13,2.26,2.26l46.33,23.73L39.8,569.13l-2.26,0
c-1.13,1.13-1.13,1.13-2.26,2.26l-1.13,1.13c0,1.13-1.13,2.26-1.13,3.39v219.23v1.13c0,1.13,0,2.26,1.13,3.39
c0,1.13,1.13,2.26,2.26,3.39c0,0,1.13,1.13,2.26,2.26L246.6,909.27h1.13c2.26,1.13,4.52,1.13,6.78,0l62.15-30.51v53.11l1.13,3.39
c0,1.13,1.13,1.13,1.13,2.26l1.13,1.13c1.13,1.13,1.13,1.13,1.13,1.13l0,0l209.06,105.09h1.13c1.13,0,2.26,1.13,3.39,1.13
s2.26,0,3.39-1.13l209.06-103.96l1.13-1.13l1.13-1.13l2.26-3.39c0-1.13,0-1.13,0-2.26v-70.06l59.89,30.51l0,0
c2.26,1.13,5.65,1.13,7.91,0l209.06-103.96c1.13,0,2.26-1.13,2.26-1.13l0,0l1.13-1.13c1.13-1.13,1.13-2.26,2.26-3.39
c0-1.13,1.13-2.26,1.13-3.39V563.47l0,0L1035.36,561.21z M1015.02,491.15l-186.46,93.79v-195.5l186.46-93.79V491.15z M818.4,187.17
l184.2,92.66L818.4,372.5l-184.2-92.66L818.4,187.17z M751.73,425.61L751.73,425.61c-1.13-2.26-2.26-2.26-3.39-3.39l-127.69-64.41
v-62.15l186.46,93.79v194.37l-54.24-27.12V430.13C752.86,429,752.86,426.74,751.73,425.61z M337,447.08l186.46,93.79v194.37
L337,641.45V447.08z M322.31,421.09l-1.13,1.13c-1.13,1.13-1.13,1.13-2.26,2.26l-1.13,1.13c-1.13,1.13-1.13,2.26-1.13,3.39v142.38
l-54.24,27.12V404.14l186.46-92.66v47.46L322.31,421.09z M350.56,430.13l184.2-91.53l184.2,92.66l-184.2,91.53L350.56,430.13z
 M544.93,540.87l187.59-93.79v194.37l-186.46,93.79V540.87H544.93z M732.51,207.51L605.95,270.8l-1.13,1.13
c-1.13,1.13-2.26,2.26-3.39,3.39c-1.13,1.13-1.13,3.39-1.13,5.65v67.8l-54.24-27.12v-66.67l186.46-93.79V207.51z M534.76,50.44
l184.2,92.66l-184.2,92.66l-184.2-92.66L534.76,50.44z M337,160.05l186.46,93.79v66.67l-54.24,27.12v-53.11c0-2.26,0-3.39-2.26-5.65
c-1.13-1.13-2.26-2.26-3.39-2.26L335.87,222.2v-62.15H337z M251.12,201.86l184.2,92.66l-184.2,92.66l-184.2-92.66L251.12,201.86z
 M54.49,505.84V311.48l186.46,92.66v194.37L54.49,505.84z M54.49,789.48v-195.5l186.46,93.79v194.37L54.49,789.48z M251.12,669.7
l-184.2-92.66l41.81-21.47L246.6,624.5h1.13c2.26,1.13,4.52,1.13,6.78,0l61.02-30.51v41.81L251.12,669.7z M317.79,709.25
c-1.13,1.13-1.13,3.39-1.13,4.52v141.25l-54.24,27.12V687.78l59.89-30.51L369.77,681l-46.33,22.6l-1.13,1.13l0,0
C320.05,705.86,318.92,706.99,317.79,709.25l5.65,2.26L317.79,709.25z M337,925.09V730.72l186.46,93.79v194.37L337,925.09z
 M534.76,806.43l-184.2-92.66l41.81-21.47l137.86,68.93h1.13c2.26,1.13,4.52,1.13,6.78,0l138.99-68.93l41.81,21.47L534.76,806.43z
 M732.51,925.09l-186.46,93.79V824.51l186.46-93.79V925.09z M752.86,840.33V713.77c0-2.26,0-3.39-1.13-4.52l-1.13-1.13
c-1.13-1.13-1.13-1.13-2.26-2.26l-47.46-23.73l46.33-23.73l3.39-2.26c1.13-1.13,1.13-1.13,1.13-2.26l1.13-1.13c0-1.13,0-1.13,0-2.26
v-4.52l54.24,27.12v194.37L752.86,840.33z M818.4,655.01l-65.54-32.77v-41.81l59.89,30.51h1.13c2.26,1.13,4.52,1.13,7.91,0
L960.78,542l41.81,21.47L818.4,655.01z M1015.02,774.79l-186.46,92.66V673.09l186.46-93.79V774.79z`;

export const materialMassiveSvg = `M972.3,300.3c0-0.6-0.1-1.2-0.2-1.8c-0.3-2.5-1-4.8-2.1-7.1c-0.1-0.1,0-0.3-0.1-0.4l-0.1-0.1
c-1.3-2.3-3-4.3-5-6c-0.5-0.4-0.9-0.8-1.5-1.2s-0.9-0.9-1.5-1.2L551.9,79.8c-1-0.4-2-0.8-3-1.1c-1.4-0.7-2.9-1.3-4.5-1.7
c-2.8-0.2-5.7-0.2-8.5,0c-1.5,0.4-3,0.9-4.4,1.7c-1,0.3-2.1,0.7-3,1.1L118.5,282.6c-0.5,0.3-0.9,0.8-1.4,1.1
c-0.6,0.4-1.1,0.8-1.6,1.3c-2,1.7-3.6,3.7-4.9,6l-0.1,0.1c-0.1,0.2-0.1,0.3-0.2,0.5c-1.1,2.2-1.8,4.5-2.1,7
c-0.1,0.6-0.2,1.2-0.2,1.8c0,0.4-0.2,0.8-0.2,1.3v482.9c0,8.4,4.7,16.1,12.2,19.9l409.9,208.4c0.9,0.4,1.8,0.7,2.8,1
c0.6,0.2,1.1,0.5,1.7,0.6c1.8,0.5,3.7,0.8,5.6,0.8h0h0l0,0c3.2-0.1,6.3-0.8,9.2-2.2c0.3-0.1,0.6-0.1,0.9-0.2l409.9-208.4
c7.5-3.8,12.2-11.5,12.2-19.9V301.6C972.5,301.1,972.3,300.7,972.3,300.3z M540.2,495.1L175,300.1l365.2-175l365.2,175L540.2,495.1
z M152.6,338.8l365.2,195v422.6L152.6,770.8V338.8z M562.5,956.5V533.8l365.2-195v432L562.5,956.5z`;

export const durationMassiveSvg = `M721.77,967.09c3.3,7.6-0.18,16.43-7.78,19.74c-61.32,26.66-126.59,40.18-194,40.18
c-65.74,0-129.52-12.88-189.57-38.28c-57.99-24.53-110.07-59.64-154.79-104.36c-44.72-44.72-79.83-96.8-104.36-154.79
C45.88,669.52,33,605.74,33,540c0-65.74,12.88-129.52,38.28-189.57c24.53-57.99,59.64-110.07,104.36-154.79
c44.72-44.72,96.8-79.83,154.79-104.36C390.48,65.88,454.26,53,520,53c54.33,0,107.68,8.89,158.59,26.41
C875.02,147.03,1007,332.13,1007,540c0,8.28-6.72,15-15,15s-15-6.72-15-15c0-195.07-123.85-368.77-308.18-432.22
C621.07,91.34,571,83,520,83C268.01,83,63,288.01,63,540c0,251.99,205.01,457,457,457c63.27,0,124.51-12.68,182.04-37.69
C709.64,956.01,718.47,959.49,721.77,967.09z M525,247.32V528H307c-8.28,0-15,6.72-15,15s6.72,15,15,15h233c8.28,0,15-6.72,15-15
V247.32c0-8.28-6.72-15-15-15S525,239.03,525,247.32z M1050.3,670.25v183.52c0,6.19-5.01,11.25-11.25,11.25H465.97
c-6.19,0-11.25-5.06-11.25-11.25V670.25c0-6.19,5.06-11.25,11.25-11.25h573.08C1045.24,659,1050.3,664.06,1050.3,670.25z
 M1027.8,681.5H477.22v161.02h550.57V681.5z M494.89,813.66v-103.3c0-6.19,5.06-11.25,11.25-11.25H828.8
c6.19,0,11.25,5.06,11.25,11.25v103.3c0.06,6.24-5.01,11.25-11.2,11.25H506.14C499.95,824.91,494.89,819.85,494.89,813.66z
 M517.39,802.41h300.15v-80.79H517.39V802.41z`;
