import React, { useCallback } from 'react';
import DropdownSystem from '../../../DesignSystem/DropdownSystem/DropdownSystem';
import { splitText } from '../../../../utils/splitText';

const DetailSection = React.memo(
  ({
    t,
    label,
    dropdownData,
    stateField,
    updateField,
    keyProp,
    labelProp,
    translationPrefix
  }) => {
    const handleChange = useCallback(
      (newValue) => {
        updateField(newValue);
      },
      [updateField]
    );

    const getLabel = useCallback(
      (itemProp) => {
        if (!itemProp) return '';
        const translationKey = translationPrefix
          ? `${translationPrefix}.${itemProp}`
          : itemProp;
        return splitText(t(translationKey), 200);
      },
      [t, translationPrefix]
    );

    const currentLabel = stateField
      ? getLabel(
          dropdownData.find((item) => item[keyProp] === stateField)?.[labelProp]
        )
      : '';

    const dropdownItems =
      dropdownData?.map(({ [keyProp]: key, [labelProp]: label }) => ({
        key,
        label: getLabel(label),
        disabled: false,
        isDivider: false,
        active: key === stateField,
        onClick: () => handleChange(key),
        subitems: []
      })) || [];

    return (
      <>
        <label>{label}</label>
        {DropdownSystem({
          theme: 'dark',
          version: 'v2',
          trigger: ['click'],
          width: 180,
          label: currentLabel,
          items: dropdownItems
        })}
      </>
    );
  }
);

export default DetailSection;
