import React, { useState } from 'react';
import './index.css';
import CardLeft from './CardLeft';
import CardRight from './CardRight';

import useConfigurationPermissions from '../../../../hooks/useConfigurationPermissions';

function CardCategoria(props) {
  const { t } = props;
  const [CncTypes, setCncTypes] = useState([]);
  const [ContraintsTypesAll, setContraintsTypesAll] = useState([]);
  const [tagsAll, setTagsAll] = useState([]);
  const permissions = useConfigurationPermissions();
  const [onlyRead, setOnlyRead] = useState(permissions.project == 'V');

  return (
    <div className="grid-categoria">
      <CardLeft
        onlyRead={onlyRead}
        setCncTypes={setCncTypes}
        CncTypes={CncTypes}
        ContraintsTypesAll={ContraintsTypesAll}
        setContraintsTypesAll={setContraintsTypesAll}
        tagsAll={tagsAll}
        setTagsAll={setTagsAll}
        t={t}
      />
      <CardRight
        onlyRead={onlyRead}
        CncTypes={CncTypes}
        setCncTypes={setCncTypes}
        ContraintsTypesAll={ContraintsTypesAll}
        setContraintsTypesAll={setContraintsTypesAll}
        tagsAll={tagsAll}
        setTagsAll={setTagsAll}
        t={t}
      />
    </div>
  );
}

export default CardCategoria;
