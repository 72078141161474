/**
 * Very simple object, which maps custom feature flags created by each dev
 * True means the feature can be deployed
 * False means the feature must be removed and showed only trhough the URL param
 */

const enableFeatureMap = {
  enable_lwp_18: true,
  'gantt-new-columns': true,
  'lwp-15': true,
  'enable-LWP-100': false,
  enable_LWP_206: true,
  enable_new_settings_menu_items: false,
  enable_modification_requests_drawer: true,
  'enable-LWP-103': true,
  enable_refactor_table: false,
  'enable_color-schema': true,
  enable_new_pdf_exporting_modal: true,
  enable_bulk_actions_bar: true
};

export default enableFeatureMap;
