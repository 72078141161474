/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class SectorResourcesService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(sectorResourceId) {
    const res = await this.http
      .get('sectorresources/' + sectorResourceId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async activityresource(sectorResourceId, sectorId) {
    const res = await this.http
      .get(
        'sectorresources/activityresource/' + sectorResourceId + '/' + sectorId
      )
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(sectorResourceId) {
    const res = await this.http
      .delete('sectorresources/' + sectorResourceId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroyAll(sectorId) {
    const res = await this.http
      .delete('sectorresources/deleteall/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async searchBySector(sectorId) {
    const data = { sector_id: sectorId };
    const res = await this.http
      .post('sectorresources/searchbysector/', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http
      .post('sectorresources/', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async assingActivitys(data, dataupdate) {
    const datah = {
      activitysectorresource: data,
      activitysectorresourceupdate: dataupdate
    };
    const res = await this.http
      .post('sectorresources/assign/activity/', datah)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('sectorresources/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showBySectorWithWorkers(sectorId) {
    const res = await this.http
      .get(`sectorresources/relations/${sectorId}`)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const sectorResourcesService = new SectorResourcesService();
