import React, { Fragment } from 'react';
import './hoc.css';
import Loader from '../layout/loader';

const withFilters = (props) => (WrappedComponent) =>
  function withSidenav() {
    return (
      <Fragment>
        <WrappedComponent />
        <Loader />
      </Fragment>
    );
  };
export default withFilters;
