import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Select, Card, Button, Empty, Popconfirm, Icon } from 'antd';
import styled from 'styled-components';
import deleteIcon from '../../icons/delete.svg';
import { tagService } from '../../../../../../services/tag.service';
import { useSelector } from 'react-redux';
import Editable from '../../../../Editable/Editable';
import { openNotification } from '../../../../../../utils';
import { CompactPicker } from 'react-color';

function Etiquetas(props) {
  const { Option } = Select;
  /** props */
  const { tagsAll, setTagsAll, onlyRead, t } = props;

  /** refs */
  const inputRef = useRef();

  /** hooks */
  const [idEdit, setIdEdit] = useState(false);

  // GET ID PROJECT FROM REDUX
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;

  useEffect(() => {
    const abortController = new AbortController();
    tagService
      .showByProject(projectSelectedId)
      .then((res) => {
        setTagsAll(res.tag);
      })
      .catch((err) => console.log(err));
    return function cleanup() {
      abortController.abort();
    };
  }, [projectSelectedId]);

  useEffect(() => {
    const arrTmp = [];
    if (tagsAll.length) {
      tagsAll.map((el) => {
        arrTmp.push({
          ...templateTags,
          hex: el.description
        });
      });
      setState(arrTmp);
    }
  }, [tagsAll]);

  useEffect(() => {
    if (idEdit !== false) {
      updateTag(tagsAll[idEdit]);
    }
  }, [idEdit]);

  const updateTag = async (data) => {
    const resp = await tagService.update(data);
    setIdEdit(false);
  };

  const handleCatsSettings = (index, name, value) => {
    const copyCats = [...tagsAll];
    copyCats[index] = {
      ...copyCats[index],
      [name]: value
    };
    setTagsAll(copyCats);
    setIdEdit(index);
  };

  const deleteTag = (tagId) => {
    tagService
      .destroy(tagId)
      .then((res) => {
        if (res === false) {
          const alertError = {
            title: t('settings.project_categories.tags.title'),
            description: t('settings.project_categories.tags.no_auth'),
            type: 'error'
          };
          openNotification(alertError);
        } else {
          const newAllTags = tagsAll.filter((tag) => tag.id !== tagId);
          setTagsAll(newAllTags);
          const alertSuccess = {
            title: t('settings.project_categories.tags.title'),
            description: t('settings.project_categories.tags.deleted'),
            type: 'success'
          };
          openNotification(alertSuccess);
        }
      })
      .catch((error) => {
        const alertError = {
          title: t('settings.project_categories.tags.title'),
          description: t('settings.error_default'),
          type: 'error'
        };
        openNotification(alertError);
        console.log(error);
      });
  };

  const templateTags = {
    displayColorPicker: false,
    color: {
      r: '241',
      g: '112',
      b: '19',
      a: '1'
    }
  };

  const [state, setState] = useState([]);

  const handleClick = (index) => {
    const copyState = [...state];
    copyState[index] = {
      ...copyState[index],
      displayColorPicker: !copyState[index].displayColorPicker
    };
    setState(copyState);
  };

  const handleClose = (index) => {
    const copyState = [...state];
    copyState[index] = {
      ...copyState[index],
      displayColorPicker: false
    };
    setState(copyState);
    handleCatsSettings(index, 'description', state[index].hex);
  };

  const handleChange = (color, index) => {
    const copyState = [...state];
    copyState[index] = {
      ...copyState[index],
      color: color.rgb,
      hex: color.hex
    };
    setState(copyState);
  };

  return (
    <Fragment>
      <div className="position-btn-add-right tag-right">
        <StyleButton>
          <Button disabled={onlyRead} className="btn-agregar-right" type="text">
            {t('settings.project_categories.tags.template_add')}
          </Button>
          <Button disabled={onlyRead} className="btn-agregar-right" type="text">
            {t('settings.project_categories.tags.template_save')}
          </Button>
        </StyleButton>
      </div>
      {/* CARTAS 3 */}
      <div className="scroll-table">
        {tagsAll.length ? (
          tagsAll.map((value, index) => (
            <Card
              bodyStyle={{ padding: '0px' }}
              className="card-category"
              key={value.id}>
              <div className="grid-cards-category">
                <div className="cat-name">
                  <Editable
                    text={value.name}
                    placeholder={t(
                      'settings.project_categories.tags.name_table'
                    )}
                    childRef={inputRef}
                    type="input"
                    className="input-table-edit">
                    <input
                      disabled={onlyRead}
                      ref={inputRef}
                      type="text"
                      name="task"
                      className="input-inline"
                      placeholder={t(
                        'settings.project_categories.tags.name_table'
                      )}
                      value={value.name}
                      onChange={(e) =>
                        handleCatsSettings(index, 'name', e.target.value)
                      }
                      autoComplete="off"
                    />
                  </Editable>
                </div>
                <div className="cat-square">
                  <div>
                    <div
                      style={{
                        padding: '5px',
                        background: '#fff',
                        borderRadius: '1px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleClick(index)}>
                      <div
                        style={{
                          width: '36px',
                          height: '14px',
                          borderRadius: '2px',
                          background: `${state[index] && state[index].hex}`
                        }}
                      />
                    </div>
                    {state[index] && state[index].displayColorPicker ? (
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: '2'
                        }}>
                        <div
                          style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px'
                          }}
                          onClick={() => handleClose(index)}
                        />
                        {onlyRead ? (
                          <div style={{ background: state[index].color }} />
                        ) : (
                          <CompactPicker
                            className="picker-wrapp"
                            color={state[index].color}
                            onChange={(color) => handleChange(color, index)}
                          />
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div></div>
                <div className="grid-category-delete cat-actions">
                  <Popconfirm
                    placement={'bottom'}
                    onConfirm={() => deleteTag(value.id)}
                    title={t('are_sure_general')}
                    icon={
                      <Icon type="question-circle-o" style={{ color: 'red' }} />
                    }>
                    {onlyRead ? null : (
                      <a>
                        <img src={deleteIcon} alt="" />
                      </a>
                    )}
                  </Popconfirm>
                </div>
              </div>
            </Card>
          ))
        ) : (
          <div style={{ marginTop: 38 }}>
            <Empty
              style={{ margin: 0 }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span>{t('settings.project_categories.tags.not_exists')}</span>
              }
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Etiquetas;

const StyleButton = styled.div`
  .ant-btn {
    border-color: transparent;
    visibility: hidden;
  }
`;

const SelectionCargoStyle = styled.div`
  .ant-select-selection {
    border-radius: 5px !important;
    width: 100px;
  }
  .ant-select-selection {
    background-color: white !important;
    color: #5f5f5f;
  }
  .anticon svg {
    color: transparent;
  }

  .ant-select-selection,
  ant-select-selection--single {
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    border-top: 0px;
  }

  .ant-select-selection:focus,
  ant-select-selection--single:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;
