/**
 * Enum for breadcrumb categories in Sentry tracking.
 * This helps in categorizing the different types of actions or features
 * within the application, making it easier to filter and understand events.
 *
 * @readonly
 * @enum {string}
 */
export const BreadcrumbCategory = Object.freeze({
  STORAGE: 'async localstorage',
  PROCORE: 'procore',
  SCHEDULES: 'project view',
  CALENDARS: 'calendars',
  COMPANY: 'company',
  PROJECTS: 'projects',
  SECTORS: 'sectors',
  USERS: 'users',
  WEEKCOMMITMENTS: 'week commitments',
  ACTIVITIES: 'activities',
  TASKS: 'tasks',
  CONSTRAINTS: 'constraints',
  AUTHENTICATION: 'authentication'
});
/**
 * Enum for breadcrumb levels in Sentry tracking.
 * This helps in categorizing the different types of actions or features
 * within the application, making it easier to filter and understand events.
 *
 * @readonly
 * @enum {string}
 */
export const BreadcrumbLevels = Object.freeze({
  FATAL: 'fatal',
  CRITICAL: 'critical',
  ERROR: 'error',
  WARNING: 'warning',
  LOG: 'log',
  INFO: 'info',
  DEBUG: 'debug'
});
