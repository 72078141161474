export const STEPS = Object.freeze({
  THIRD_CASE_A_SCREEN: 1,
  QUARTER_CASE_A_SCREEN: 2,
  THIRD_CASE_B_SCREEN: 3,
  EXPIRED_SCREEN: 4
});

export const ITEMS_COMPANY_SIZE = [
  {
    key: 1,
    label: 'Less than 20 people'
  },
  {
    key: 2,
    label: '20 - 50 people'
  },
  {
    key: 3,
    label: '50 - 100 people'
  },
  {
    key: 4,
    label: 'More than 100 people'
  }
];

export const ITEMS_COMPANY_TYPE = [
  {
    key: 1,
    label: 'General contractor'
  },
  {
    key: 2,
    label: 'Trade contractor'
  },
  {
    key: 3,
    label: 'Owner'
  },
  {
    key: 4,
    label: 'Other'
  }
];
