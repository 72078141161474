function getAllCheckedTasks(filterParents = false) {
  let gantt = window.to_use_react_gantt || window.ganttVisualization;

  if (filterParents) {
    const allowTypeOfTask = ['task', 'milestone'];

    return gantt
      .getTaskBy(
        (t) =>
          allowTypeOfTask.includes(t.$rendered_type) &&
          (t.visibleChecked || t.checked || t.mustApplyVisibleChecked)
      )
      .map((t) => t.id);
  }

  return gantt
    .getTaskBy(
      (t) => t.visibleChecked || t.checked || t.mustApplyVisibleChecked
    )
    .map((t) => t.id);
}

export { getAllCheckedTasks };
