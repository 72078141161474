/** React components  */
import React, { useState, useEffect } from 'react';

import { Button, Tooltip } from 'antd';
import IconComponent from '../Projects/IconSvg';
import CustomDropdownSelector from '../CustomDropdownSelector';
import { getTypeNotification, notifyMessageCustom } from '../../utils';
import { MassiveTagComp } from '../GanttMassiveToolbar/icons';

export default function GanttMassiveTag(props) {
  const gantt = window.to_use_react_gantt;
  const { tooltipI18nKey, t, selectedActivities } = props;
  const defaultState = {
    mainObject: {},
    options: props?.toSelectTags,
    attrToExtractFromOption: 'id',
    attrToLabelFromOption: 'name',
    hideCreateBtn: false
  };
  const [state, setState] = useState(defaultState);
  const [isHovered, setIsHovered] = useState(false);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [dictionary, setDictionary] = useState([]);

  useEffect(() => {
    setState({
      ...defaultState,
      options: props?.toSelectTags
    });
    const gantt = window?.to_use_react_gantt;
    if (gantt) {
      gantt.optimizedRender();
    }
  }, [props.toSelectTags]);

  useEffect(() => {
    /** unMount component */
    return () => {
      setTagsSelected([]);
    };
  }, []);

  const handleMouseEnter = (e) => {
    setIsHovered(true);
  };

  const handleMouseLeave = (e) => {
    setIsHovered(false);
  };

  const customMainClassName = 'massive-tag-class';
  const isOnlyReadElement = false; // onlyRead || task.readonly || (subtradeRole && subtradeRole.isSubtradeRole)

  const onSelection = (payload, dictionary) => {
    setTagsSelected(payload);
    if (dictionary && !dictionary.length) setDictionary(dictionary);
  };

  const applyChanges = async () => {
    if (selectedActivities.length) {
      gantt.batchUpdate(function () {
        const undoExtension = gantt.ext.undo;
        selectedActivities.forEach((act) => {
          undoExtension.saveState(act.id, 'task');
          const actGet = gantt.getTask(act.id);
          const usersSelectedObj = tagsSelected.map((userId) => {
            return dictionary[userId];
          });
          if (actGet.tags) {
            actGet.tags = usersSelectedObj;
          }
          gantt.updateTask(actGet.id);
        });
      });
      gantt.ext.keyboardNavigation.focus({
        type: 'taskCell',
        id: selectedActivities[0].id,
        column: 'tags'
      });
      let description = t('massive_notif_tag');
      if (tagsSelected.length === 0) {
        description = t('massive_notif_tag_clear');
      }
      notifyMessageCustom({
        type: getTypeNotification(MassiveTagComp),
        description
      });
    }
  };

  /** tag */
  const renderTagToSelect = (option) => {
    const { object } = option;

    const isSelected = tagsSelected.includes(object.id + '-');

    return (
      <div className="item-gantt">
        <div
          className="subcontract-border cut-text-and-dot"
          style={{
            height: '100%',
            color: `${object.description}`,
            padding: 0,
            backgroundColor: isSelected ? '#e6e3e4' : object.description + '4d'
          }}>
          <div
            className="text-subcontract cut-text-and-dot"
            style={{
              alignContent: 'center',
              textAlign: 'center',
              position: 'relative',
              top: 7,
              float: 'left',
              width: 140
            }}>
            <span style={{ marginLeft: 5 }}>
              <IconComponent
                viewBox="0 0 16 16"
                data={
                  'M0.409302 5.87389V1.23607C0.444715 0.527453 1.01572 0.00853333 1.64537 0H6.28318C7.02902 0.0463467 7.91953 0.33876 8.39779 0.878533L15.2626 8.51972C15.6938 9.06561 15.706 9.82104 15.2626 10.2768L9.88925 15.6501C9.3624 16.1267 8.57631 16.1065 8.13219 15.6501L1.28782 7.98849C0.788875 7.39925 0.416182 6.61145 0.409302 5.87389ZM2.42174 3.19744C2.45098 3.88691 2.99486 4.38449 3.60674 4.39265C4.2982 4.36177 4.79378 3.81064 4.80196 3.19744C4.77152 2.50567 4.21757 2.02047 3.60674 2.01244C2.91694 2.04125 2.42973 2.58795 2.42174 3.19744Z'
                }
                width={14}
                fill={object.description}
              />
              <Tooltip title={object.name} placement="leftBottom">
                <span style={{ marginLeft: 3 }}>{object.name}</span>
              </Tooltip>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const callbackOnClickCreate = () => {
    gantt.refreshMassive = false;
    gantt.setVisibleFormTags(true);
  };

  const renderCustomFooter = () => {
    return <Button>{t('apply_label')}</Button>;
  };

  return (
    <span className="gm-tag">
      <CustomDropdownSelector
        {...state}
        renderCustomSelectionOption={renderTagToSelect}
        customMainClassName={`custom-proplanner-tag ${customMainClassName || ''}`}
        renderCustomFooter={renderCustomFooter}
        labelCreateBtn={<div className="create-sub-btn">+ {t('new_tag')}</div>}
        callbackOnSelection={onSelection}
        callbackOnClickCreate={callbackOnClickCreate}
        closeOnclick={false}
        callbackOnClickFooter={applyChanges}
        onlyread={isOnlyReadElement}
        multiple
        calbackOnVisibleChange={() => setTagsSelected([])}>
        <Tooltip placement="top" title={t(tooltipI18nKey)}>
          <span
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}>
            {props.icon && (
              <props.icon color={isHovered ? '#7DFF8A' : '#FFFFFF'} />
            )}
          </span>
        </Tooltip>
      </CustomDropdownSelector>
    </span>
  );
}
