import { isFeatureOn } from '../../../utils/featureUtils';
import roadbloackIcon from '../../../assets/img/newlookahead/roadblock/roadblock.png';
/**
 * This function build templates elements to build visual stuff at chart and grid
 * @param {*} gantt Gantt dhtmlx instance object
 */
export const buildTemplates = (gantt) => {
  gantt.templates.link_class = function (link) {
    if (!gantt.config.show_links) {
      return 'hidden';
    }
    return '';
  };

  gantt.templates.grid_row_class = function (start, end, task) {
    // return `custom-gantt-visualization-grid ${!task.isTask ? 'grouppable' : ''}`;
    const finalGridRowClass = isFeatureOn('enable_lwp_18')
      ? 'custom-gantt-visualization-grid ' + task.type + '-custom-style'
      : 'custom-gantt-visualization-grid';

    return finalGridRowClass;
  };

  gantt.templates.grid_file = (item) => {
    if (!item.isTask) {
      return "<div class='gantt_tree_icon gantt_file'></div>";
    }
  };

  gantt.templates.leftside_text = function (start, end, item) {
    if (!gantt.isTaskExists(item.id)) return '';
    const sizes = gantt.getTaskPosition(item);
    item.barWidth = sizes.width;
    if (item.restricted) {
      return `
                <span class="${item.isInsideWeekly ? 'roadblock-img' : 'roadblock-img-non-border'}">
                    <img width="18" height="18" src="${roadbloackIcon}"/>
                </span>
            `;
    }
  };

  gantt.templates.rightside_text = (start, end, task) => {
    let extraClassname = '';

    if (task.isParentTask) {
      extraClassname += 'fit-vertical-position';
    }

    if (task.isTask) {
      return `
            <span class="name-gantt-visualization ${extraClassname} ${task.isInsideWeekly ? 'name-with-border' : 'name-without-border'}">
                ${task.name}
            </span>
            `;
    }
  };

  /**
   * This function allows gantt, to print calendars through cell class css function
   * @param {*} item Task
   * @param {*} date Date to check if is workable
   */
  gantt.templates.task_cell_class = function (item, date) {
    let calendar;

    if (item.calendar_id) {
      calendar = gantt.getCalendar(item.calendar_id);
    }

    if (calendar) {
      if (
        gantt.config.scales[0].unit === 'day' &&
        gantt.config.scales[1].unit === 'hour'
      ) {
        if (!calendar.isWorkTime({ date: date, task: item, unit: 'hour' })) {
          return 'weekend personalized-weekend-class-visua';
        }
      } else if (
        gantt.config.scales[0].unit === 'month' &&
        gantt.config.scales[1].unit === 'day'
      ) {
        if (!calendar.isWorkTime({ date: date, task: item, unit: 'day' })) {
          return 'weekend personalized-weekend-class-visua';
        }
      } else if (
        gantt.config.scales[0].unit === 'month' &&
        gantt.config.scales[1].unit === 'week'
      ) {
        const copyOfDate = new Date(date);
        const endDate = gantt.date.add(date, 6, 'day');
        let isWorkable = false;
        while (true) {
          // Funcion mal utilizada deberia ser {date, unit: 'day'}
          if (calendar.isWorkTime({ date: copyOfDate, unit: 'day' })) {
            isWorkable = true;
            break;
          } else {
            copyOfDate.setDate(copyOfDate.getDate() + 1);
            if (copyOfDate > endDate) break;
          }
        }

        if (!isWorkable) return 'weekend personalized-weekend-class-visua';
      } else if (
        gantt.config.scales[0].unit === 'year' &&
        gantt.config.scales[1].unit === 'month'
      ) {
        const copyOfDate = new Date(date);
        let isWorkable = false;
        while (true) {
          // Funcion mal utilizada deberia ser {date, unit: 'day'}
          if (calendar.isWorkTime({ date: copyOfDate, unit: 'day' })) {
            isWorkable = true;
            break;
          } else {
            copyOfDate.setDate(copyOfDate.getDate() + 1);
            if (copyOfDate.getDate() == 1) break;
          }
        }

        if (!isWorkable) return 'weekend personalized-weekend-class-visua';
      }
    }
  };

  gantt.templates.task_class = function (start, end, task) {
    let css = 'top-fix-style task-gantt-visualization ';
    if (task.is_milestone) return css;
    css = css + task.type + '-bar-custom-style';
    if (gantt.hasChild(task.id) && task.isTask) {
      task.isParentTask = true;
      css =
        css +
        ' parent-task-style' +
        (task.isInsideWeekly
          ? ' with-inside-border'
          : ' without-inside-border');
    }
    if (
      task.isTask &&
      task.isInsideWeekly &&
      task.lean_status === 'Committed'
    ) {
      css += ' force-border-to-weekly-commited-range-task';
    } else if (gantt.isWeeklyCommited && task.isCommitted && !task.restricted) {
      css += ' force-border-to-weekly-commited-range-task';
    } else if (task.isTask && task.isInsideWeekly && task.restricted) {
      css += ' force-border-to-weekly-restricted-task';
    } else if (task.isTask && task.isInsideWeekly) {
      css += ' force-border-to-weekly-range-task';
    }

    if (!task.isTask) {
      css += ' non-task-view-gantt';
    }
    return css;
  };
};
