import React from 'react';
import moment from 'moment';
import { styles } from './style.js';
import { Text } from '@react-pdf/renderer';
import { formatText } from './utils';

export const colorByStatus = {
  Advancement: '#309FE9',
  Overdue: '#E50101',
  Waiting: '#C8C6C6',
  Doing: '#F59D04',
  Done: '#34AF00'
};

export const MAX_LENGTH_ACTIVITY_ROUTE = 160;

export const COMPANY_IDS_WITH_ROUTENAME_LIMITED = [54, 479];

const formatDate = (date, format) => moment(date).format(format);

export const lookaheadExportablePDFColumns = Object.freeze({
  0: {
    flexHeaderColumnValue: 1.5,
    headerColumnTexts: 'master_plan.tasks',
    getText: (taskProps) => formatText(taskProps.task.name, 29)
  },
  1: {
    flexHeaderColumnValue: 1.1,
    headerColumnTexts: 'master_plan.responsible',
    getText: (taskProps) => formatText(taskProps.responsables, 25)
  },
  2: {
    flexHeaderColumnValue: 0.8,
    headerColumnTexts: 'lookahead_report.subcontract',
    getText: (taskProps) => formatText(taskProps.subcontractName, 10)
  },
  3: {
    flexHeaderColumnValue: 0.65,
    headerColumnTexts: 'tables.lookahead.plan.start_date',
    getText: (taskProps) =>
      formatText(
        formatDate(taskProps.task.start_date, taskProps.sector.dateFormat),
        11
      )
  },
  4: {
    flexHeaderColumnValue: 0.5,
    headerColumnTexts: 'tables.lookahead.plan.duration',
    getText: (taskProps) => taskProps.task.duration || ''
  },
  5: {
    flexHeaderColumnValue: 0.65,
    headerColumnTexts: 'tables.lookahead.plan.end_date',
    getText: (taskProps) =>
      formatText(
        formatDate(taskProps.task.end_date, taskProps.sector.dateFormat),
        11
      )
  },
  6: {
    flexHeaderColumnValue: 0.5,
    headerColumnTexts: 'tables.lookahead.plan.progress',
    getText: (taskProps) => (
      <Text style={styles.table_task_title_name}>
        {Number.isInteger(taskProps.task.progress)
          ? taskProps.task.progress
          : taskProps.task.progress.toFixed(2)}
        %
      </Text>
    )
  },
  7: {
    flexHeaderColumnValue: 0.25,
    headerColumnTexts: null,
    getText: (taskProps) =>
      !taskProps.vaslidate_constrints?.length
        ? '-'
        : taskProps.vaslidate_constrints.length
  }
});
