export const buildCustomDropdown = (gantt, setSelected, disable = false) => {
  /**
   * Get the dropdown nodes
   */
  const getDropdownNode = () => document.querySelector('#gantt_dropdown');

  /**
   * This function hide the dropdown
   */
  gantt.$hideDropdown = function () {
    const dropDown = getDropdownNode();
    dropDown.style.display = 'none';
  };

  const detectClickDropdown = function (event) {
    if (getDropdownNode()) {
      if (!event.target.closest('#gantt_dropdown') && !getDropdownNode().keep) {
        if (gantt.$hideDropdown) {
          gantt.$hideDropdown();
        }
      }
    }
  };

  if (disable) {
    window.removeEventListener('click', detectClickDropdown);
  } else {
    /**
     * This function attached to gantt instance puts the dropdown just where is clicked
     */
    gantt.$showDropdown = function (node, selectedId) {
      setSelected(selectedId);
      const position = node.getBoundingClientRect();
      const dropDown = getDropdownNode();
      dropDown.className = dropDown.className + ' fade-in';
      dropDown.style.top = position.bottom + 'px';
      dropDown.style.left = position.left - 15 + 'px';
      dropDown.style.display = 'block';
      dropDown.keep = true;
      setTimeout(() => {
        dropDown.keep = false;
      });
      setTimeout(() => {
        dropDown.className = '';
      }, 500);
    };

    /**
     * This function detects the event of clicking outside of the dropdown
     */
    window.addEventListener('click', detectClickDropdown);
  }
};
