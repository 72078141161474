import React from 'react';
import ButtonSystem from '../ButtonSystem';

const DEFAULT_SIZE = 'default';

const EmptyStateSystem = ({
  icon,
  sizeIcon = DEFAULT_SIZE,
  title,
  description,
  textButton,
  sizeButton = DEFAULT_SIZE,
  functionButton
}) => {
  const processDescription = (text) =>
    text.split('\n').map((string, index) => <p key={index}>{string}</p>);
  return (
    <div className="empty-state-design-system">
      {icon && (
        <div className={`empty-state-design-system__icon size--${sizeIcon}`}>
          {icon}
        </div>
      )}
      {title && (
        <div className="empty-state-design-system__title">
          <h2>{title}</h2>
        </div>
      )}
      {description && (
        <div className="empty-state-design-system__description">
          {processDescription(description)}
        </div>
      )}
      {textButton && functionButton && (
        <div className="empty-state-design-system__button">
          <ButtonSystem size={sizeButton} onClick={() => functionButton()}>
            {textButton}
          </ButtonSystem>
        </div>
      )}
    </div>
  );
};

export default EmptyStateSystem;
