/** React stuff */
import React from 'react';
import './ResourcesList.css';
import { getTask } from '../../utils/lookahead-common';
import { socket } from '../../services/socket.service';
import { materialMassiveSvg } from '../../utils/svgIcons';
/** import IconComponent for SVG imagen */
import IconComponent from '../Projects/IconSvg';

/** Redux implementation */
import { useSelector } from 'react-redux';

/** import common functions from utils */
import { crane } from '../../utils';

/** Components from ant design css framework */
import { Input, Popover, Tooltip } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import cloneDeep from 'lodash/cloneDeep';
import { capitalize } from 'lodash';
import { taskService } from '../../services';

export const ResourcesList = (props) => {
  const {
    t,
    task,
    setPopsVisibility,
    popsVisibility,
    setResources,
    setActivityResource,
    column,
    activity,
    resources,
    setTypeResource,
    resourceData,
    updateAsyncTask,
    showFilter = false,
    showBtnCreate = false,
    typeResource,
    uniqueId,
    handlePopVisibility,
    setVisibleFormResource,
    lastLevelActivities,
    filterText,
    setFilterText,
    setTaskSelected,
    notifyRedux = () => {}
  } = props;

  /** Redux */
  const projectState = useSelector((state) => state.projectState);

  /** get label */
  let labelResource = lastLevelActivities.resource.find(
    (el) => el.id === task.materialId
  );
  if (typeResource === 'machinery') {
    labelResource = lastLevelActivities.resource.find(
      (el) => el.id === task.machineId
    );
  } else if (typeResource === 'rrhh') {
    labelResource = lastLevelActivities.resource.find(
      (el) => el.id === task.specialtyId
    );
  }

  /** set icon */
  let iconResource;
  let dataInRow;
  let resourceRoute;
  switch (typeResource) {
    case 'material':
      iconResource = (
        <span className="material-icon">
          <IconComponent data={materialMassiveSvg} width={15} fill="#000" />
        </span>
      );
      dataInRow = task.materialId || null;
      resourceRoute = 'materialId';
      break;
    case 'rrhh':
      iconResource = <i className="fas fa-user"></i>;
      dataInRow = task.specialtyId || null;
      resourceRoute = 'specialtyId';
      break;
    case 'machinery':
      iconResource = (
        <span className="machinery-icon">
          <IconComponent data={crane} width={15} fill="#000" />
        </span>
      );
      dataInRow = task.machineId || null;
      resourceRoute = 'machineId';
      break;

    default:
      iconResource = (
        <span className="material-icon">
          <IconComponent data={materialMassiveSvg} width={15} fill="#000" />
        </span>
      );
      dataInRow = task.materialId || null;
      resourceRoute = 'materialId';
      break;
  }

  const handleVisibleChange = (visible, id, type) => {
    const newData = cloneDeep(popsVisibility);
    newData[id] = visible;
    setPopsVisibility({
      ...newData,
      [type + '-' + id]: visible
    });
    if (!visible) {
      setFilterText('');
      setResources(lastLevelActivities?.resource);
    }
  };

  /** this function set to null resource Id */
  const deleteResource = (task, activity, resourceRoute) => {
    if (!activity.id) {
      /** grouped */
      const reference = activity.tasks.find((el) => el.id === task.id);
      if (reference) {
        reference[resourceRoute] = null;
        const copy = cloneDeep(task);
        delete copy.activityObject;
        taskService.removeResource(task.id, resourceRoute);
        socket.emit('task_update', {
          sector: projectState.sectorSelected,
          task: copy
        });
        updateAsyncTask(task);
        handlePopVisibility(false, uniqueId);
        notifyRedux(); /** weekly plan exception */
      }
    } else {
      const doesExistAtReference = getTask(task.id, null, activity);
      if (doesExistAtReference.length) {
        const reference = doesExistAtReference[0];
        reference[resourceRoute] = null;
        const copy = cloneDeep(task);
        taskService.removeResource(task.id, resourceRoute);
        socket.emit('task_update', {
          sector: projectState.sectorSelected,
          task: copy
        });
      }
      updateAsyncTask(task);
      handlePopVisibility(false, uniqueId);
    }
  };

  return (
    <div className="resources-field">
      <Popover
        key={task.id}
        overlayClassName="popover-list"
        className="popover-list"
        visible={popsVisibility[typeResource + '-' + task.id]}
        onVisibleChange={(visible) =>
          handleVisibleChange(visible, task.id, typeResource)
        }
        content={
          <div className="popover-options" key={task.id}>
            <Input
              style={{
                display: showFilter ? 'inline-block' : 'none'
              }}
              autoFocus
              placeholder={t('find_resource')}
              type="text"
              value={filterText}
              onChange={(e) => {
                if (e.target.value === '') {
                  setResources(lastLevelActivities?.resource);
                  setFilterText('');
                } else {
                  const resourcesFiltered = cloneDeep(resources).map((el) => {
                    el.show = false;
                    if (
                      el.name
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    ) {
                      el.show = true;
                    }
                    return el;
                  });
                  setResources(resourcesFiltered);
                  setFilterText(e.target.value);
                }
              }}
            />
            <div className="resources-container">
              {resourceData.length ? (
                resourceData.map((resource, index) => {
                  if (resource.show === false) return null;
                  return (
                    <span
                      className="item"
                      key={index}
                      onClick={() => {
                        task[column.name] = resource.id;
                        updateAsyncTask(task);
                        handlePopVisibility(false, uniqueId);
                      }}>
                      <span>
                        <div className="icon-resources">{iconResource}</div>
                        {capitalize(resource.name)}
                      </span>
                    </span>
                  );
                })
              ) : (
                <div className="no-resources">{t('no_resources')}</div>
              )}
            </div>
            <span
              style={{
                display: showBtnCreate ? 'inline-block' : 'none'
              }}
              className="add-resourse-inline"
              onClick={() => {
                /** search activity reference */
                if (!activity.id) {
                  // grouped
                  const taskRef = activity.tasks.find(
                    (el) => el.id === task.id
                  );
                  if (taskRef) {
                    setActivityResource(taskRef.activityObject);
                  }
                } else {
                  // no grouped
                  setActivityResource(activity);
                }
                setVisibleFormResource(true);
                setTypeResource(typeResource);
                setTaskSelected(task);
              }}>
              {t('create_subcontract_label')}
            </span>
          </div>
        }
        trigger="click"
        placement="bottom">
        <div
          style={{
            height: '100%',
            cursor: 'pointer'
          }}>
          <span className="vertical-center">
            {labelResource ? labelResource.name : t('not_assigned_general')}
          </span>
        </div>
      </Popover>
      {dataInRow ? (
        <Tooltip title={t('remove_resource')}>
          <CloseCircleOutlined
            className="delete-resources"
            onClick={() => deleteResource(task, activity, resourceRoute)}
          />
        </Tooltip>
      ) : null}
    </div>
  );
};
