export default function useLookaheadPermissions() {
  const permissions = JSON.parse(sessionStorage.getItem('permissiontable'));
  if (!permissions) return {};
  const constraints = permissions.lookahead.find(
    (per) => per.module == 'constraints'
  ).permission;
  const resources = permissions.lookahead.find(
    (per) => per.module == 'resources'
  ).permission;
  const plan = permissions.lookahead.find(
    (per) => per.module == 'plan'
  ).permission;
  return { constraints, resources, plan, product: 'lookahead' };
}
