import moment from 'moment';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import MenuSubmodules from '../../components/MenuSubmodules';
import { useParams } from 'react-router-dom';

const WeeklyPlanMenu = (props) => {
  const { project, history, t } = props;
  const { projectIdCnc, sectorIdCnc } = useParams();
  const companyState = useSelector(
    (state) => state.companyState?.currentCompany
  );

  const { current: messageOptions } = useRef({
    DURATION: 'info_criteria_task_duration',
    COST: 'info_criteria_task_cost',
    HH: 'info_criteria_task_hh'
  });

  const publishSubmodules = ({ history, match, t, toggleActiveButtons }) => {
    return [
      {
        id: 1,
        title: t('tabs.weeklyplan.plan_legacy'),
        routeName: `${match.url}/planning`,
        onClick: (_) => {
          toggleActiveButtons(1);
          if (projectIdCnc || sectorIdCnc) {
            history.push('/weeklyplan/planning');
          } else {
            history.push(`${match.url}/planning`);
          }
        },
        hide: true
      },
      {
        id: 2,
        title: t('tabs.weeklyplan.plan'),
        routeName: `${match.url}/commintments`,
        onClick: (_) => {
          toggleActiveButtons(2);
          if (projectIdCnc || sectorIdCnc) {
            history.push('/weeklyplan/commintments');
          } else {
            history.push(`${match.url}/commintments`);
          }
        },
        hide: false
      },
      {
        id: 3,
        title: t('tabs.weeklyplan.cncs'),
        routeName: `${match.url}/cncs`,
        onClick: (_) => {
          toggleActiveButtons(3);
          if (projectIdCnc || sectorIdCnc) {
            history.push('/weeklyplan/cncs');
          } else {
            history.push(`${match.url}/cncs`);
          }
        },
        hide: false
      }
    ];
  };

  const isAfterSeptemberFirst = () => {
    const baseDate = '07-07-2021';
    const formatedDate = moment(companyState.createdAt);
    return moment(formatedDate).isAfter(baseDate);
  };

  return (
    <MenuSubmodules
      publishSubmodules={publishSubmodules}
      history={history}
      messageModule={
        project && project.task_creter
          ? t(messageOptions[project.task_creter.toUpperCase()])
          : ''
      }
    />
  );
};

export default WeeklyPlanMenu;
