import { Row } from 'antd';
import React from 'react';

const WarningLowerEnv = ({ env }) => {
  return (
    <div className={'warning-lower-env'}>
      <Row className="row-normal">
        <p
          style={{
            color: 'orange',
            fontSize: 16,
            fontWeight: 500
          }}>{`Warning : ${env} Version`}</p>
        <span className="suggestion-text">{'Please go to : '}</span>
        <a href="https://app.outbuild.com" className="link-text">
          <u>app.outbuild.com</u>
        </a>
      </Row>
    </div>
  );
};

export default WarningLowerEnv;
