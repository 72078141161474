import React from 'react';
import { splitText } from '../../../../views/taktplanning/assign/index.helper';

/**
 * This component is the rendered element between Y (activity) and X (location) which show the match of selected items
 * @param {*} activities all activities array
 * @param {*} units all locations array
 * @param {*} selectedActivities selected activities array
 * @param {*} selectedUnits selected units array
 * @param {*} locationArray locations with units array
 */
export const GridCellLocationGroupped =
  (activities, _, selectedActivities, selectedUnits, locationArray) =>
  ({ columnIndex, rowIndex, style }) => {
    const grouppedActivity = activities[rowIndex];
    const location = locationArray[columnIndex];

    const foundActivitiesToUnassign = (currentSelection) => (itemSelected) =>
      itemSelected.id === currentSelection.id &&
      currentSelection.disable &&
      currentSelection.active;

    /**
     * To asssign flow
     */
    let isSelectedActivity = false;
    let nActivityToCreate = 0;
    grouppedActivity.activities.forEach((activity) => {
      const isActivityInSelectedArray = selectedActivities.find(
        (act) => act.id == activity.id && activity.active
      );
      if (isActivityInSelectedArray) {
        nActivityToCreate += 1;
        isSelectedActivity = true;
      }
    });

    const customClass = 'separate-secctions';

    let isSomeOfUnitSelected = false;

    let nUnitToCreate = 0;
    location.productionUnits.forEach((unit) => {
      const isUnitInSelectedArray = selectedUnits.find(
        (un) => un.id == unit.id && unit.active
      );
      if (isUnitInSelectedArray) {
        nUnitToCreate += 1;
        isSomeOfUnitSelected = true;
      }
    });

    /**
     * Already assigned flow
     */
    let nAlreadyAssignedActivities = 0;
    for (let i = 0; i < grouppedActivity.activities.length; i++) {
      const ac = grouppedActivity.activities[i];
      location.productionUnits.map((pu) => {
        const isActivityInSelectedArray = pu.activities.find(
          (act) => act.id == ac.id && ac.disable
        );
        if (isActivityInSelectedArray) {
          nAlreadyAssignedActivities += 1;
        }
      });
    }

    const nAlreadyAssigned = nAlreadyAssignedActivities;

    /**
     * ===============
     *  Unassign units
     * ===============
     * */
    let currentActivity = null;
    let activitiesWillUnassign = false;
    const unAssignActivities = (activity) => {
      const isFoundActivitySelected = selectedActivities.find(
        foundActivitiesToUnassign(activity)
      );
      if (isFoundActivitySelected) {
        activitiesWillUnassign = true;
        currentActivity = activity;
      }
    };

    grouppedActivity.activities.forEach(unAssignActivities);

    // Units unassigning
    let isSomeUnitwillUnassign = false;
    let unitWillUnassign = 0;

    const unassigningUnits = (unit) => {
      const isFoundUnitSelected = selectedUnits.find(
        foundActivitiesToUnassign(unit)
      );
      const activityUnitMatches =
        isFoundUnitSelected &&
        currentActivity &&
        isFoundUnitSelected.activities.find(
          (activity) => activity.id === currentActivity.id
        );
      if (isFoundUnitSelected && activityUnitMatches) {
        unitWillUnassign++;
        isSomeUnitwillUnassign = true;
      }
    };

    location.productionUnits.forEach(unassigningUnits);

    const assign = nUnitToCreate * nActivityToCreate;
    let className =
      isSelectedActivity && isSomeOfUnitSelected
        ? `grid-element-with-units ${customClass}`
        : `grid-element-without-units ${customClass} ${nAlreadyAssigned ? 'grid-element-with-units' : ''}`;

    let content =
      isSelectedActivity && isSomeOfUnitSelected
        ? `${assign} new / ${nAlreadyAssigned} assigned`
        : `${nAlreadyAssigned || '-'}`;

    // adding class for unassign
    if (activitiesWillUnassign && isSomeUnitwillUnassign) {
      className = 'grid-element-unassign-units';
      content = `${unitWillUnassign * activitiesWillUnassign} / ${nAlreadyAssigned}`;
    }

    return (
      <div className={className} style={style}>
        {splitText(content, 8)}
      </div>
    );
  };

export const GridCellLocationTree =
  (activities, _units, selectedActivities, selectedUnits, locationArray) =>
  ({ columnIndex, rowIndex, style }) => {
    const activity = activities[rowIndex];
    const location = locationArray[columnIndex];

    const foundActivitiesToUnassign = (currentSelection) => (itemSelected) =>
      itemSelected.id === currentSelection.id &&
      currentSelection.disable &&
      currentSelection.active;

    /**
     * To asssign flow
     */
    const isSelectedActivity = selectedActivities.find(
      (act) => act.id == activity.id && activity.active
    );

    const customClass = 'separate-secctions';

    let isSomeOfUnitSelected = false;

    let nUnitToCreate = 0;
    for (let i = 0; i < location.productionUnits.length; i++) {
      const unit = location.productionUnits[i];
      const isUnitInSelectedArray = selectedUnits.find(
        (un) => un.id == unit.id && unit.active
      );
      if (isUnitInSelectedArray) {
        nUnitToCreate += 1;
        isSomeOfUnitSelected = true;
      }
    }

    /**
     * Already assigned flow
     */
    let nAlreadyAssignedActivities = 0;
    const ac = activity;
    location.productionUnits.map((pu) => {
      const isActivityInSelectedArray = pu.activities.find(
        (act) => act.id == ac.id && ac.disable
      );
      if (isActivityInSelectedArray) {
        nAlreadyAssignedActivities += 1;
      }
    });

    const nAlreadyAssigned = nAlreadyAssignedActivities;

    /**
     * ===============
     *  Unassign units
     * ===============
     * */
    const activitiesWillUnassign = selectedActivities.find(
      foundActivitiesToUnassign(activity)
    );

    // Units unassigning
    let isSomeUnitwillUnassign = false;
    let unitWillUnassign = 0;

    const unassigningUnits = (unit) => {
      const isFoundUnitSelected = selectedUnits.find(
        foundActivitiesToUnassign(unit)
      );
      const currentActivity = ac;
      const activytyUnitMatches =
        isFoundUnitSelected &&
        currentActivity &&
        isFoundUnitSelected.activities.find(
          (activity) => activity.id === currentActivity.id
        );
      if (isFoundUnitSelected && activytyUnitMatches) {
        unitWillUnassign++;
        isSomeUnitwillUnassign = true;
      }
    };

    location.productionUnits.forEach(unassigningUnits);

    const blankText = activity.has_childs ? '' : '-';
    let className =
      isSelectedActivity && isSomeOfUnitSelected && !activity.has_childs
        ? `grid-element-with-units ${customClass}`
        : `grid-element-without-units ${customClass} ${nAlreadyAssigned ? 'grid-element-with-units' : ''}`;

    let content =
      isSelectedActivity && isSomeOfUnitSelected && !activity.has_childs
        ? `${Number(nUnitToCreate)}  ${nAlreadyAssigned ? `${nAlreadyAssigned} assigned` : ''}`
        : `${nAlreadyAssigned || blankText}`;

    // adding class for unassign
    if (activitiesWillUnassign && isSomeUnitwillUnassign) {
      className = 'grid-element-unassign-units';
      content = `${Number(unitWillUnassign)} / ${nAlreadyAssigned}`;
    }

    return (
      <div className={className} style={style}>
        {splitText(content, 4)}
      </div>
    );
  };
