import React from 'react';
import './ResponsibleList.scss';
import noAvatar from '../../assets/img/fake_user.png';

/**
 * @typedef {Object} TaskResponsibles
 * @property {string} name - Responsible name
 * @property {string} lastname - Responsible last name
 * @property {string} image - Responsible image
 */

/**
 * @param {Object} obj - Component props
 * @param {function(label:string)} obj.t - i18n translation instance
 * @param {TaskResponsibles[]} obj.responsibles - Task Responsibles
 */

const ResponsibleList = ({ responsibles, t }) => {
  if (!responsibles.length) {
    return (
      <div className="responsible-list">
        {t('modals.lookahead.activity_modification_request.no_responsible')}
      </div>
    );
  }

  if (responsibles.length === 1) {
    const { image, name, lastname } = responsibles[0];
    return (
      <div className="responsible-list">
        <div className="one-responsible">
          <img src={image ?? noAvatar} />
          <div className="responsible-name">
            {name} {lastname}
          </div>
        </div>
      </div>
    );
  }

  const extraResponsibles = responsibles.length - 3;

  return (
    <div className="responsible-list">
      <div className="many-responsibles">
        {responsibles.slice(0, 3).map(({ image }, i) => (
          <img key={i} src={image ?? noAvatar} className={`image${i}`} />
        ))}
      </div>
      {extraResponsibles > 0 && (
        <div className="responsible-name">+{extraResponsibles}</div>
      )}
    </div>
  );
};

export default ResponsibleList;
