/* eslint-disable no-case-declarations */
import {
  SET_CONSTRAINT_FORM,
  SET_CONSTRAINT_RESPONSABLES,
  SET_CONSTRAINT_LOOKAHEAD,
  SET_CONSTRAINT_ACTIVATED_COLS,
  NOTIFY_LOOKAHEAD_UPDATE_CONSTRAINT,
  NOTIFY_LOOKAHEAD_UPDATE_GROUP_CONSTRAINT,
  NOTIFY_LOOKAHEAD_UPDATE_FILTER_CONSTRAINT,
  NOTIFY_LOOKAHEAD_UPDATE_ORDER_CONSTRAINT,
  SET_COLUMNS_SIZES
} from '../actions/constraintActions';

/**
 * Reducer function means a switch case to filter action data on the platform for general state
 * @param {*} state Real time updated through all components state (general state flux)
 * @param {*} action Action is an object with structyure { type: STRING, payload: FUNCTION }
 */
function constraintReducer(
  state = {
    constraintForm: null,
    constraintResponsables: [],
    constraintLookAhead: [],
    activatedColumns: [],
    sizeColumns: [],
    notifyChange: false,
    notifyChangeGroup: false,
    notifyChangeFilter: false,
    notifyChangeOrder: false
  },
  action
) {
  switch (action.type) {
    case SET_CONSTRAINT_FORM:
      return {
        ...state,
        constraintForm: action.payload
      };
    case SET_CONSTRAINT_LOOKAHEAD:
      return {
        ...state,
        constraintLookAhead: action.payload
      };
    case SET_CONSTRAINT_RESPONSABLES:
      return {
        ...state,
        constraintResponsables: action.payload
      };
    case SET_CONSTRAINT_ACTIVATED_COLS:
      return {
        ...state,
        activatedColumns: action.payload
      };
    case SET_COLUMNS_SIZES:
      return {
        ...state,
        columnsSizes: action.payload
      };
    case NOTIFY_LOOKAHEAD_UPDATE_CONSTRAINT:
      return {
        ...state,
        notifyChange: !state.notifyChange
      };
    case NOTIFY_LOOKAHEAD_UPDATE_GROUP_CONSTRAINT:
      return {
        ...state,
        notifyChangeGroup: !state.notifyChangeGroup
      };
    case NOTIFY_LOOKAHEAD_UPDATE_FILTER_CONSTRAINT:
      return {
        ...state,
        notifyChangeFilter: !state.notifyChangeFilter
      };
    case NOTIFY_LOOKAHEAD_UPDATE_ORDER_CONSTRAINT:
      return {
        ...state,
        notifyChangeOrder: !state.notifyChangeOrder
      };
    default:
      return state;
  }
}

export default constraintReducer;
