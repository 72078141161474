import { getTask } from '../../../utils/lookahead-common';

/**
 * This function build utils to handle multi select API on DHTMLX gantt with Proplanner multiselect
 * @param {*} gantt Dhtmlx gantt instance to add functions
 * @param {*} setMassiveSelectionGannt Setter function from gantt
 * @param {*} setMassiveSelection Setter function from proplanner API
 */
export const buildMultiselectAPI = (
  gantt,
  setMassiveSelectionGannt,
  setMassiveSelection
) => {
  /**
   * Recursive function which check checkbox state and keep it coherent
   * @param {*} id ID from element to checkbox must be checked
   * @param {*} hardcodedState Hardcode state for checkbox (checked or not)
   */
  const update_checkbox = (id, hardcodedState = 'none') => {
    if (!gantt.isTaskExists(id)) return '';
    const task = gantt.getTask(id);
    task.lastVisibleChecked = task.visibleChecked;

    if (hardcodedState != 'none') {
      task.checked = hardcodedState;
    } else {
      task.checked = !task.checked;
    }

    /** Children behaviours comes in checkbox flow not in mulsti select drag n drop (shift) */
    const children = gantt.getChildren(id);
    if (children) {
      const parentChecked = task.checked;
      children.forEach((childId) =>
        gantt.update_checkbox(
          childId,
          hardcodedState != 'none' ? hardcodedState : parentChecked
        )
      );
    }
    if (task.checked) {
      gantt.selectTask(task.id);
    }
    if (!task.checked) {
      gantt.unselectTask(task.id);
    }

    task.visibleChecked = task.checked;
  };

  /**
   * Middleware function to connect checkbox behaviour with massive handler at planification view (Proplanner massive API with DHTMLX massive API)
   * @param {*} id Id from selection to be connected
   * @param {*} activityReferenceId Activity reference if there is task
   * @param {*} avoidRender Boolean to avoid rendering process, if true render will not be executed
   * @param {*} hardcodeState Hardcode state for selection (selected or not)
   */
  const updateCheckboxRecursive = (
    id,
    activityReferenceId,
    avoidRender = false,
    hardcodeState = 'none'
  ) => {
    gantt.update_checkbox(id, hardcodeState);
    setMassiveSelectionGannt({
      taskId: id,
      activityReferenceId
    });
    if (!avoidRender) {
      gantt.optimizedRender();
    }

    /** this function should only exist in MP */
    gantt.getArrMassiveFn && gantt.getArrMassiveFn();
  };

  const unselectTasksDrag = (_) => {
    gantt.eachSelectedTask((item) => {
      gantt.unselectTask(item.id);
    });
  };

  /**
   * This function cleans massive selection, and reset it to initial state
   * @param {*} _
   */
  const detachMultiDragSelect = (_) => {
    gantt.batchUpdate(() => {
      gantt.getTaskByTime().forEach((selected) => {
        const taskRef = selected;
        const activityRefId = taskRef.activityReference?.proplannerId || null;
        const activityReference = window?.activities?.find(
          (ac) => ac.id == activityRefId
        );
        if (activityReference) {
          const doesExistAtReference = getTask(
            taskRef.id,
            null,
            activityReference
          );
          if (doesExistAtReference[0]) {
            doesExistAtReference[0].active = false;
          }
        }
        taskRef.checked = false;
        taskRef.visibleChecked = false;
        delete taskRef.mustApplyVisibleChecked;
        gantt.unselectTask(taskRef.id);
      });

      setMassiveSelection([]);
      gantt.dragMultiActive = false;
      gantt.selectedTasksMultiDrag = [];

      gantt.multiSelectTroughCheckbox = false;
      gantt.multiSelectTroughShift = false;
    });
    gantt.clearSelectedActivities && gantt.clearSelectedActivities();
  };

  /** 18 PROPLANNER CHECKBOX FEATURE */
  const proplannerCustomBlockSelection = (last, target_ev, firstSelected) => {
    // debugger
    let arrayOfIDS = [];
    if (!last) last = target_ev;
    else if (target_ev) {
      const initialFirst = gantt.getGlobalTaskIndex(firstSelected);
      const includeFirst = initialFirst - 1;
      const aux = gantt
        .getTaskBy(
          (t) => t.visibleChecked || t.checked || t.mustApplyVisibleChecked
        )
        .map((t) => t.id);
      let first_indx;
      if (includeFirst < 0 || aux.length) {
        arrayOfIDS = [...aux];
        first_indx = initialFirst;
      } else {
        first_indx = includeFirst;
      }
      const target_indx = gantt.getGlobalTaskIndex(target_ev);
      let tmp = target_ev;
      while (gantt.getGlobalTaskIndex(tmp) !== first_indx) {
        arrayOfIDS.push(tmp);
        tmp =
          first_indx > target_indx ? gantt.getNext(tmp) : gantt.getPrev(tmp);
      }
    }
    arrayOfIDS = arrayOfIDS.reverse();
    arrayOfIDS = [...new Set(arrayOfIDS)];
    gantt.detachMultiDragSelect && gantt.detachMultiDragSelect();
    /** proplanner lookahead timeline view instance */
    setTimeout(() => {
      if (gantt.runCommonSelection) {
        gantt.runCommonSelection(arrayOfIDS);
        window.lastSelectedIds = arrayOfIDS;
        window.selectFromShift = true;
        gantt.setSelectedActivitiesFromBlock &&
          gantt.setSelectedActivitiesFromBlock(arrayOfIDS);
      }
    }, 250);
    return arrayOfIDS;
  };

  /**
   * This functions is being used by lib DHTMLX lib, by it onMultiSelect event and also in their drag scroll API extension callback
   * @param {*} selectedTasks Array with DHTMLX lib task instance to being
   */
  const handleNewDragMultiselect = (selectedTasks) => {
    if (selectedTasks.length) {
      gantt.dragMultiActive = true;
    } else {
      gantt.dragMultiActive = false;
    }

    if (!gantt.multiSelectTroughCheckbox) {
      selectedTasks.forEach((toSelect) => {
        const activityRefId = toSelect.activityReference?.proplannerId || null;
        gantt.handleMultiSelectionWithActivities(toSelect.id, activityRefId);
      });
      return;
    }

    selectedTasks.forEach((taskRef) => {
      const activityRefId = taskRef.activityReference?.proplannerId || null;
      if (!taskRef.isTask) return;
      gantt.updateCheckboxRecursive(taskRef.id, activityRefId, true);
    });
    gantt.selectedTasksMultiDrag = [
      ...gantt.selectedTasksMultiDrag,
      ...selectedTasks
    ];
    gantt.optimizedRender();
  };

  /**
   * This function handles the checkbox task selection
   * @param {*} id ID from selected item through its checkbox
   * @param {*} activityReferenceId Parent activity which contains the element
   * @param {*} avoidRender default false, turn into true if you need to re render the chart
   */
  const updateDOMCheckbox = (
    id,
    activityReferenceId,
    avoidRender = false,
    comesFromCheckbox = false
  ) => {
    gantt.multiSelectTroughCheckbox = true;
    gantt.multiSelectTroughShift = false;
    const taskRef = gantt.getTask(id);

    gantt.handleMultiSelectionWithActivities(id, activityReferenceId);

    const checkIfChildAreSelected = (parent) => {
      if (!parent) return;
      const childs = gantt.getChildren(parent.id);
      if (childs && childs.length) {
        const allChildrenSelected = childs.some((childID) => {
          const childObject = gantt.getTask(childID);
          if (!childObject.checked || !childObject.visibleChecked) return true;
        });
        return allChildrenSelected;
      }
    };
    const recursiveUncheckParents = (child) => {
      const parent = gantt.getTask(child.parent);
      const isChildUnselected = checkIfChildAreSelected(parent);
      if (isChildUnselected) {
        parent.checked = false;
        parent.visibleChecked = false;
        delete parent.mustApplyVisibleChecked;
        recursiveUncheckParents(parent);
      }
    };
    if (!taskRef?.checked || !taskRef.visibleChecked)
      recursiveUncheckParents(taskRef);

    setTimeout(() => {
      if (Boolean(taskRef) && !taskRef.checked && !taskRef.visibleChecked) {
        delete taskRef.mustApplyVisibleChecked;
      }
      gantt.handleMultiselectionScheduler();
    }, 500);
  };

  /**
   * This function check if there is an activity or task and handles their recursive behaviour, also checks the visiblechecked state to keep it coherent
   * @param {*} id ID from el to be selected (activity or task)
   * @param {*} activityReferenceId If there is an task indeed, so then his activity id will be necessary to handle normal behaviour
   */
  const handleMultiSelectionWithActivities = (id, activityReferenceId) => {
    gantt.batchUpdate(() => {
      const ganttRef = gantt.getTask(id);
      ganttRef.checked = !ganttRef.checked;
      if (
        ganttRef.visibleChecked === true &&
        ganttRef.checked === true &&
        ganttRef.mustApplyVisibleChecked
      ) {
        ganttRef.checked = false;
      }
      const children = gantt.getChildren(id);
      if (children && children.length) {
        children.forEach((childTask) => {
          gantt.updateCheckboxRecursive(
            childTask,
            ganttRef.proplannerId,
            false,
            ganttRef.checked
          );
        });
      } else {
        gantt.updateCheckboxRecursive(
          id,
          activityReferenceId,
          false,
          ganttRef.checked
        );
      }

      if (ganttRef.checked === false) {
        ganttRef.visibleChecked = false;
      }
    });
  };

  /**
   * This function handle unselection for elements that must not being moved through the timeline area
   * @param {*} toUnselectRefs Direct object HDTMLX to being unselect
   */
  const runUnSelectTimeout = (toUnselectRefs) => {
    setTimeout(() => {
      /** Handles unselect non task elements */
      if (toUnselectRefs.length) {
        toUnselectRefs.forEach((ref) => {
          if (
            (!ref.lastVisibleChecked && ref.checked) ||
            (ref.lastVisibleChecked && ref.checked)
          ) {
            ref.visibleChecked = true;
          } else {
            ref.visibleChecked = false;
          }
          ref.checked = false;
          gantt.unselectTask(ref.id);
        });
      }
    }, 150);
  };

  /**
   * This function check to select elements
   * @param {*} wholeSelection Array with ID's or Direct dhtmlx task instance to being checked
   * @param {*} arrayWithIds Default true, if array comes with pure ID's, if there is an array with objects inside must be false
   * @returns Filtered array about parents already selected
   */
  const clearParentsToAvoidOverRecursive = (
    wholeSelection,
    arrayWithIds = true
  ) => {
    let toSelectRefs = [];
    const toUnselectRefs = [];

    /** This hash maps will be used only with  handleNewDragMultiselect below */
    const activityToChildHash = {};
    wholeSelection.forEach((id, index) => {
      let ganttRef;
      if (arrayWithIds) {
        ganttRef = gantt.getTask(id);
      } else {
        ganttRef = id;
      }
      const children = gantt.getChildren(id);
      if (
        (children && children.length) ||
        (ganttRef && ganttRef.progress > 99.99)
      ) {
        ganttRef.mustApplyVisibleChecked = true;
        if (!activityToChildHash[ganttRef.id]) {
          activityToChildHash[ganttRef.id] = [];
        }
        toUnselectRefs.push(ganttRef);
      } else {
        toSelectRefs.push(ganttRef);
        if (activityToChildHash[ganttRef.parent])
          activityToChildHash[ganttRef.parent].push(ganttRef.id);
        ganttRef.mustApplyVisibleChecked = false;
      }
    });

    let toRemoveFromSelectableDueToHisParent = [];
    const keysArray = Object.keys(activityToChildHash);
    keysArray.map((key) => {
      const parentRef = gantt.getTask(key);
      if (!keysArray.includes(JSON.stringify(parentRef.parent))) {
        toSelectRefs.push(parentRef);
      }
      const childArrayIds = activityToChildHash[key];
      toRemoveFromSelectableDueToHisParent = [
        ...toRemoveFromSelectableDueToHisParent,
        ...childArrayIds
      ];
    });

    toSelectRefs = toSelectRefs.filter((taskToSelect) => {
      if (!toRemoveFromSelectableDueToHisParent.includes(taskToSelect.id))
        return true;
    });
    return {
      toSelectRefs,
      toUnselectRefs
    };
  };

  /**
   * This function receives an array of activities ID's, and then check if there is already selected their parents
   * @param {*} selectedIds Array with selected ID's
   * @returns Array of activities objects which must be selected
   */
  const cleanerChilds = (selectedIds = []) => {
    const returnArray = [];
    if (selectedIds && selectedIds.length) {
      const completeSelectedRefs = selectedIds.map((selectedId) =>
        gantt.getTask(selectedId)
      );
      completeSelectedRefs.forEach((el) => {
        const parentOfSelectedObjectId = el.parent;
        const isParentIdInSelectedIds = selectedIds.find(
          (id) => id == parentOfSelectedObjectId
        );
        if (!isParentIdInSelectedIds) {
          returnArray.push(el);
        }
      });
    }

    return returnArray;
  };

  /**
   * This function is only called by API through timeline area selection (with shift key)
   * @param {*} startPoint x,y from mouse start
   * @param {*} endPoint x,y from mouse end
   * @param {*} startDate Start date from mouse position
   * @param {*} endDate End date from mouse position
   * @param {*} tasksBetweenDates Tasks in the X axis (Dates)
   * @param {*} tasksInRows Tasks in the Y (bar row)
   */
  const onDragCursorMultiTask = (
    startPoint,
    endPoint,
    startDate,
    endDate,
    tasksBetweenDates,
    tasksInRows
  ) => {
    gantt.multiSelectTroughCheckbox = false;
    gantt.multiSelectTroughShift = true;

    gantt.unselectTasksDrag();
    const selectedTasks = [];
    for (let i = 0; i < tasksBetweenDates.length; i++) {
      for (let j = 0; j < tasksInRows.length; j++) {
        if (tasksBetweenDates[i] === tasksInRows[j]) {
          selectedTasks.push(tasksBetweenDates[i].id);
        }
      }
    }
    runCommonSelection(selectedTasks);
    setTimeout(() => {
      gantt.callEvent('onMultiSelect');
    }, 300);
  };

  /**
   * This function run common multiselect behaviour on proplanner
   * @param {*} selectedTasks array with all selected ID's
   * @returns Null if there is no selected tasks
   */
  const runCommonSelection = (selectedTasks = []) => {
    gantt.batchUpdate(() => {
      if (!selectedTasks) return;
      if (!selectedTasks.length) return;
      /** Here the array of ID's it's transformed into a complete object ref array */
      const toSelect = cleanerChilds(selectedTasks);
      toSelect &&
        toSelect.length &&
        toSelect.forEach((toSelectObject) => {
          const activityRefId =
            toSelectObject.activityReference?.proplannerId || null;
          gantt.updateDOMCheckbox(toSelectObject.id, activityRefId);
        });
    });
  };

  /**
   * This function handles multiselect process, trhough the chechbox comming from grid, multi selection
   * with shift also from grid and finally multi selection with timeline area with mouse click.
   * All of this behaviours comes from different triggers, so then, avoid touching code below unless there was
   * strict necessary.
   * @returns Always false (to avoid native flow for on multi select event)
   */
  const handleMultiselectionScheduler = (_) => {
    gantt.batchUpdate(() => {
      const checkedEls = gantt.getTaskBy((t) => t.checked);
      if (!checkedEls.length) {
        gantt.multiSelectTroughCheckbox = false;
      }

      const wholeSelection = gantt.getSelectedTasks();
      const { toUnselectRefs, toSelectRefs } =
        gantt.clearParentsToAvoidOverRecursive(wholeSelection);
      /** If this multi select event were triggered by only one selected element will abort */
      if (wholeSelection.length === 1) return false;

      if (!gantt.multiSelectTroughShift && !gantt.multiSelectTroughCheckbox) {
        return;
      }

      gantt.runUnSelectTimeout(toUnselectRefs);
    });
    return false;
  };

  gantt.cleanerChilds = cleanerChilds;
  gantt.unselectTasksDrag = unselectTasksDrag;
  gantt.handleNewDragMultiselect = handleNewDragMultiselect;
  gantt.onDragCursorMultiTask = onDragCursorMultiTask;
  gantt.detachMultiDragSelect = detachMultiDragSelect;
  gantt.runUnSelectTimeout = runUnSelectTimeout;
  gantt.clearParentsToAvoidOverRecursive = clearParentsToAvoidOverRecursive;
  gantt.handleMultiselectionScheduler = handleMultiselectionScheduler;
  gantt.handleMultiSelectionWithActivities = handleMultiSelectionWithActivities;
  gantt.updateDOMCheckbox = updateDOMCheckbox;
  gantt.updateCheckboxRecursive = updateCheckboxRecursive;
  gantt.update_checkbox = update_checkbox;
  gantt.runCommonSelection = runCommonSelection;
  gantt.proplannerCustomBlockSelection = proplannerCustomBlockSelection;
};
