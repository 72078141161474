import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import EventEmitter from 'react-native-eventemitter';

import { Button, Row, Col, Tooltip, message } from 'antd';

// assets
import menuSubmodules from './index.module.css';
import infoIcon from '../../assets/img/info.png';
import { withTranslation } from 'react-i18next';

const ButtonSubmodule = ({ buttons, btnStyled }) => {
  return (
    <div>
      {buttons.map((btn) => {
        if (btn.hide) return null;

        return (
          <Button
            key={btn.id}
            className={btn.className || btnStyled}
            type="primary"
            onClick={btn.onClick}>
            {btn.title}
          </Button>
        );
      })}
    </div>
  );
};

const MenuSubmodules = (props) => {
  const { CustomRigthComp, messageModule, publishSubmodules, history } = props;

  let toggleActiveButtons = () => {};
  const match = useRouteMatch();

  const [submoduleBtn, setSubmoduleBtn] = useState(() =>
    publishSubmodules({ ...props, match, toggleActiveButtons })
  );
  const [btnStyle, setBtnStyle] = useState('lookahead-btn-style');

  useEffect(() => {
    EventEmitter.on('changeMainRoute', changeMainRoute);

    const activeButton = submoduleBtn.filter(
      (btn) => btn.routeName == history?.location?.pathname
    );

    if (activeButton.length) {
      toggleActiveButtons(activeButton.pop().id);
    }

    return () =>
      EventEmitter.removeListener('changeMainRoute', changeMainRoute);
  }, [match]);

  // Event Emitter Router
  const changeMainRoute = (data) => data.route(history);

  toggleActiveButtons = (id) => {
    const buttons = submoduleBtn.map((btn) => {
      setBtnStyle('lookahead-btn-style lookahead-disabled-btn-style');
      if (btn.id == id) {
        return { ...btn, className: 'lookahead-btn-style' };
      }

      delete btn.className;
      return { ...btn };
    });
    setSubmoduleBtn(buttons);
  };

  return (
    <Row>
      <Col className={menuSubmodules.containerHeader}>
        <ButtonSubmodule buttons={submoduleBtn} btnStyled={btnStyle} />
        <span className={menuSubmodules.tooltip}>
          {CustomRigthComp.Comp ? (
            <CustomRigthComp.Comp {...CustomRigthComp.props} />
          ) : (
            <Tooltip title={messageModule} placement="left">
              <span>
                <img src={infoIcon} alt="info icon" width={15} />
              </span>
            </Tooltip>
          )}
        </span>
      </Col>
    </Row>
  );
};

MenuSubmodules.propTypes = {
  messageModule: PropTypes.string.isRequired,
  CustomRigthComp: PropTypes.object,
  publishSubmodules: PropTypes.func.isRequired
};

MenuSubmodules.defaultProps = {
  messageModule: 'Some message for module information',
  CustomRigthComp: { props: null, Comp: null }
};

export default withTranslation()(MenuSubmodules);
