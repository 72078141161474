export default function useConfigurationPermissions() {
  const permissions = JSON.parse(sessionStorage.getItem('permissiontable'));
  const company = permissions?.configuration?.find(
    (per) => per.module == 'company'
  ).permission;
  const project = permissions?.configuration?.find(
    (per) => per.module == 'project'
  ).permission;
  const user = permissions?.configuration?.find(
    (per) => per.module == 'user'
  ).permission;
  const integrations = permissions?.configuration?.find(
    (per) => per.module == 'integrations'
  )?.permission;
  return { company, project, user, integrations };
}
