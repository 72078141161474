import React, { useState, useEffect } from 'react';

/** Redux implementation */
import { useSelector } from 'react-redux';

import EventEmitter from 'react-native-eventemitter';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from 'react-router-dom';

import masterPlanIcon from '../../assets/img/modules/planning.svg';
import withRouteToolbar from '../../hocs/withRouteToolbar.hoc';
import MainRouteChild from '../../components/MainRouteChild';

import ReImportView from './reImport';
import NewImportView from './newImport';
import { Spin, Icon } from 'antd';
import { Animated } from 'react-animated-css';
import useMasterplanPermissions from '../../hooks/useMasterplanPermissions';
import { withTranslation } from 'react-i18next';
import {
  totangoEventTracking,
  totangoSetAccountAttributes
} from '../../analytics/implements/totango';
import { getSignedUser } from '../../utils/userUtils';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

function ImportGanttView(props) {
  const { t } = props;
  const projectState = useSelector((state) => state.projectState);
  const stateCompany = useSelector((state) => state.companyState);
  /** hooks */
  const [visibleImporter, setVisibleImporter] = useState(false);
  const masterplanPermissions = useMasterplanPermissions();

  const match = useRouteMatch();
  const menuMetaData = {
    title: t('master_plan.import_title'),
    subTitle: t('master_plan.import_sub_title'),
    goBack: () => {
      props.history.push('/masterplan');
    },
    buttons: [
      {
        btnTitle: t('master_plan.import_btn_label'),
        routeName: `${match.url}/reimport`,
        onClick: (params) => {
          // props.history.push(`${match.url}/newimport`)
          const loggedUser = getSignedUser();
          const currentCompany = stateCompany.currentCompany;
          const project = projectState.allProjects.find(
            (p) => p.id == projectState.projectSelected
          );

          totangoSetAccountAttributes(
            loggedUser,
            projectState.projectSelected,
            currentCompany?.name,
            currentCompany?.id,
            project?.name,
            project?.stage,
            project?.country
          );

          totangoEventTracking(
            `p_${projectState.projectSelected}`,
            loggedUser,
            'Gantt import',
            'Master Plan'
          );
          setVisibleImporter(true);
        }
      }
    ]
  };

  useEffect(() => {
    window.Appcues.page();
  });

  const [FinishPage, setFinishPage] = useState(<Spin indicator={antIcon} />);
  useEffect(() => {
    const FinishPage = withRouteToolbar({
      ...props,
      route: {
        icon: masterPlanIcon,
        title: t('master_plan.import_route_title'),
        breadcrumb: t('master_plan.import_route_breadcrumb')
      },
      menu: menuMetaData
    })(MainRouteChild);
    setFinishPage(<FinishPage />);
  }, []);

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  const propsNewImporter = {
    visibleImporter,
    setVisibleImporter
  };

  const renderFinishPage = () => {
    if (masterplanPermissions.gantt == 'AC') {
      return (
        <Animated
          animationIn="fadeIn"
          animationInDuration={500}
          isVisible={true}>
          {FinishPage}
          <NewImportView {...props} {...propsNewImporter} />
        </Animated>
      );
    }
    return <div>{t('settings.not_permissions')}</div>;
  };

  return renderFinishPage();
}

export default withTranslation()(ImportGanttView);
