import { updateExistingSubmittals } from '../../views/ganttContainer/gantt/gantt.helper';
import {
  LOAD_SUBMITTALS,
  SUBMITTAL_CREATED,
  SUBMITTAL_DELETED,
  SUBMITTAL_UPDATED,
  UPDATE_SUBMITTALS
} from '../actions/submittalsActions';

/**
 * The initial state object for submittals.
 */
const initialState = {
  submittals: {}
};

/**
 * Reducer function to handle actions related to submittals.
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action object containing type and payload.
 * @return {Object} - The new state.
 */
const submittalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMITTAL_CREATED: {
      const { id, ...newSubmittal } = action.payload;
      return {
        ...state,
        submittals: {
          ...state.submittals,
          [id]: newSubmittal
        }
      };
    }
    case SUBMITTAL_UPDATED: {
      const { id, ...updatedSubmittal } = action.payload.submittal;
      return {
        ...state,
        submittals: {
          ...state.submittals,
          [id]: updatedSubmittal
        }
      };
    }
    case LOAD_SUBMITTALS:
      return {
        ...state,
        submittals: Object.fromEntries(action.payload.map((s) => [s.id, s]))
      };
    case SUBMITTAL_DELETED:
      const { [action.payload]: deleted, ...remainingSubmittals } =
        state.submittals;
      return {
        ...state,
        submittals: remainingSubmittals
      };
    case UPDATE_SUBMITTALS:
      const updatedSubmittals = updateExistingSubmittals(
        state.submittals,
        action.payload
      );

      return {
        ...state,
        submittals: updatedSubmittals
      };
    default:
      return state;
  }
};

export default submittalsReducer;
