import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Input, DatePicker, Select, Upload, Avatar, Icon, Spin } from 'antd';
import ModalCropImage from '../../ModalCropImage';
import './index.css';
import Etapas from './Etapas';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../../../redux/slices/projectSlice';
import { companyActions } from '../../../../redux/actions/companyActions';
import moment from 'moment';
import {
  sectorService,
  projectService,
  companyService,
  userService
} from '../../../../services';
import {
  release_constraint_options,
  ObjectNewLogoSvg,
  ObjectNewLogoOwnerSvg,
  Types,
  typesCreaters,
  projectSizesTypes,
  projectCurrency,
  week_days,
  setAllProjectsGlobal
} from '../../../../utils';
import uploadIcon from '../../../../assets/img/upload-icon.png';
/** import library for format numbers */
import NumberFormat from 'react-number-format';
import {
  ConsoleSqlOutlined,
  LoadingOutlined,
  UserOutlined
} from '@ant-design/icons';
/** import library for antd upload crop */
import ImgCrop from 'antd-img-crop';

/** import base contant (setting urls)  */
import { base } from '../../../../services/base';

import useConfigurationPermissions from '../../../../hooks/useConfigurationPermissions';
import { getSessionTokenData } from '../../../../utils/userUtils';
import { useHistory } from 'react-router-dom';
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

function CardGeneral(props) {
  const { t } = props;
  // GET ID PROJECT FROM REDUX
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;
  const user = JSON.parse(localStorage.getItem('user'));
  const sessionTokenData = getSessionTokenData();
  const sectorDateFormat = stateProject.allSectors.find(
    (e) => e.id == stateProject.sectorSelected
  );
  const [ProjectSelected, setProjectSelected] = useState({});
  const [stateUpload, setStateUpload] = useState({ loading: false });
  const [stateUploadProjectOwner, setStateUploadProjectOwner] = useState({
    loading: false
  });
  const [UsersProject, setUsersProject] = useState([]);
  const permissions = useConfigurationPermissions();
  const [onlyRead, setOnlyRead] = useState(permissions.project == 'V');
  const [Sectors, setSectors] = useState([]);
  const [startDateArr, setStartDateArr] = useState([]);
  const [endDateArr, setEndDateArr] = useState([]);
  const [imgSrc, setImgSrc] = useState('');
  const [imgSrcProjectOwner, setImgSrcProjectOwner] = useState('');
  const [formCropVisible, setFormCropVisible] = useState(false);
  const [formCropVisibleProjectOwner, setFormCropVisibleProjectOwner] =
    useState(false);
  let history = useHistory();

  useEffect(() => {
    if (imgSrc) {
      setFormCropVisible(true);
    }
  }, [imgSrc]);
  useEffect(() => {
    if (imgSrcProjectOwner) {
      setFormCropVisibleProjectOwner(true);
    }
  }, [imgSrcProjectOwner]);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgSrc(reader.result.toString() || '');
        setFormCropVisible(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onSelectFileProjectOwner(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgSrcProjectOwner(reader.result.toString() || '');
        setFormCropVisibleProjectOwner(true);
      });
      reader.readAsDataURL(e.target.files[0]);
      setImgSrcProjectOwner(null);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    sectorService
      .getSectorsByProjectWithMasterActivity(projectSelectedId)
      .then(async (response) => {
        if (response.sectors) {
          /** select active sectors  */
          const filterSectors = response.sectors.filter(
            (e) =>
              e.projectId === stateProject.projectSelected && e.status === true
          );
          setSectors(filterSectors);
          const arrStartDate = [];
          const arrEndDate = [];
          filterSectors.map((sec) => {
            if (sec.activities[0] && sec.activities[0].start_date) {
              arrStartDate.push({
                id: sec.id,
                start_date: sec.activities[0].start_date
              });
            }
            if (sec.activities[0] && sec.activities[0].end_date) {
              arrEndDate.push({
                id: sec.id,
                end_date: sec.activities[0].end_date
              });
            }
          });
          setStartDateArr(arrStartDate);
          setEndDateArr(arrEndDate);
        }
      })
      .catch((err) => console.log(err));
    projectService
      .show(projectSelectedId)
      .then((response) => {
        const { project } = response;
        if (!project) history.goBack();

        setProjectSelected(project);
        setStateUpload({
          ...stateUpload,
          imageUrl: project.image
        });
        setStateUploadProjectOwner({
          ...stateUploadProjectOwner,
          imageProjectOwnerUrl: project.imageProjectOwner
        });
      })
      .catch((err) => console.log(err));
    userService
      .getByProject(projectSelectedId)
      .then((response) => {
        if (response.users) {
          setUsersProject(response.users.filter((el) => el.is_active));
        }
      })
      .catch((err) => console.log(err));

    return function cleanup() {
      abortController.abort();
    };
  }, [projectSelectedId]);

  // GET PROJECT GENERAL SELECTED BY USER
  const [stateNewImage, setStateNewImage] = useState({ isLoaded: false });
  const [stateNewOwnerImage, setStateNewOwnerImage] = useState({
    isLoadedProjectOwner: false
  });

  useEffect(() => {
    if (stateUpload.imageUrl) {
      const image = new Image();
      image.onload = () => setStateNewImage({ isLoaded: true });
      image.src = stateUpload.imageUrl;
    }
    if (ProjectSelected?.id) {
      updateProjectSettings();
    }
  }, [stateUpload]);

  useEffect(() => {
    if (stateUploadProjectOwner.imageProjectOwnerUrl) {
      const imageProjectOwner = new Image();
      imageProjectOwner.onload = () =>
        setStateNewOwnerImage({ isLoadedProjectOwner: true });
      imageProjectOwner.src = stateUploadProjectOwner.imageProjectOwnerUrl;
    }
    if (ProjectSelected?.id) {
      updateProjectSettings();
    }
  }, [stateUploadProjectOwner]);

  /** component Logo by default */
  const ProjectIcon = (props) => (
    <Icon component={ObjectNewLogoSvg} {...props} />
  );
  const ProjectOwnerIcon = (props) => (
    <Icon component={ObjectNewLogoOwnerSvg} {...props} />
  );

  /** component upload button */
  const uploadButton = (
    <div>
      {stateUpload && stateUpload.loading ? (
        <LoadingOutlined />
      ) : (
        <ProjectIcon className="FrmCompany" />
      )}
    </div>
  );

  const uploadProjectOwnerButton = (
    <div>
      {stateUploadProjectOwner && stateUploadProjectOwner.loading ? (
        <LoadingOutlined />
      ) : (
        <ProjectOwnerIcon className="FrmCompany" />
      )}
    </div>
  );

  // get new value from user
  const changeInput = (e) => {
    let valueInput = e.target.value;
    if (['budget', 'pcr_goal', 'pcc_goal'].includes(e.target.name)) {
      valueInput = e.target.value.replace(/\./g, '');
    }
    setProjectSelected({
      ...ProjectSelected,
      [e.target.name]: valueInput
    });
  };

  // This method update project in state
  const updateSelect = (name, value) => {
    setProjectSelected({
      ...ProjectSelected,
      [`${name}`]: value
    });
  };

  // DATES PICKERS
  const { Option } = Select;
  const dateFormat =
    sectorDateFormat && sectorDateFormat.dateFormat
      ? sectorDateFormat.dateFormat
      : null;
  const dispatch = useDispatch();

  const updateProjectsList = (arr) => {
    /** Redux event emitting function to set projects load from project actions */
    // dispatch(projectActions.setAllProjects(arr))
    setAllProjectsGlobal(arr, dispatch, projectActions, stateProject);
  };

  async function getCompany(companyId) {
    const resp = await companyService.show(companyId);
    return resp ? resp.company : false;
  }

  // This method update redux and api in backend node
  const updateProjectSettings = (replace = false) => {
    const projectSave = ProjectSelected;
    if (replace) {
      // eslint-disable-next-line no-useless-escape
      projectSave.size = projectSave.size
        ? parseFloat(
            projectSave.size.toString().replace(/\./g, '').replace(/\,/g, '.')
          )
        : null;
    }
    projectService
      .update(projectSave)
      .then(async (response) => {
        const companyId = sessionTokenData.companyId;
        const company = await getCompany(companyId);
        if (company) {
          dispatch(companyActions.setCurrentCompany(company));
        }
        // const getProjects = await userService.getProjectsByUser(user.id)
        const getProjects = await userService.projectsbyuserthrough(user.id);
        const projectsActive = getProjects.projects.filter(
          (e) => e.stage !== 'deleted'
        );
        updateProjectsList(projectsActive);
      })
      .catch((err) => console.log(err));
  };

  /** get min of array of objects (date_end) */
  const getMinMaxDate = (tasks, field = 'end_date', type = 'min') => {
    const min = tasks.reduce((prev, curr) => {
      if (type === 'min') {
        return prev[field] < curr[field] ? prev : curr;
      }
      return prev[field] > curr[field] ? prev : curr;
    }, moment());
    return min[field];
  };

  const minStartDate = getMinMaxDate(startDateArr, 'start_date', 'min');
  const maxEndDate = getMinMaxDate(endDateArr, 'end_date', 'max');
  const { isLoaded } = stateNewImage;
  const { isLoadedProjectOwner } = stateNewOwnerImage;
  const imgToShow = !isLoaded ? (
    <Spin indicator={antIcon} />
  ) : (
    <div
      className="projectImg-resize"
      style={{ backgroundImage: `url(${stateUpload.imageUrl})` }}></div>
  );

  const imgProjectOwnerToShow = !isLoadedProjectOwner ? (
    <Spin indicator={antIcon} />
  ) : (
    <div
      className="proyectOwnerimg-resize"
      style={{
        backgroundImage: `url(${stateUploadProjectOwner.imageProjectOwnerUrl})`
      }}></div>
  );

  return (
    <Fragment>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h1 className="title-config-project-general">
          {t('settings.project_form.info')}
        </h1>
        <div className="grid-general">
          <div className="project-and-owner-images">
            <div className="logo-title-imageContent">
              <p className="project-logos-titles">
                {t('settings.image_project')}
              </p>
              <div className="cont-projectImag-edit">
                {['superadmin', 'admin'].includes(sessionTokenData.role) ? (
                  <div className="Crop-Controls">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={onSelectFile}
                      id="file-input-edit-image"
                    />
                  </div>
                ) : null}
                <div>
                  {stateUpload && stateUpload.imageUrl
                    ? imgToShow
                    : uploadButton}
                </div>
              </div>
            </div>
            {['superadmin', 'admin'].includes(sessionTokenData.role) ? (
              <label htmlFor="file-input-edit-image">
                <img
                  className="img-upload-logo"
                  alt="logo company"
                  src={uploadIcon}
                />
              </label>
            ) : null}
            {['superadmin', 'admin'].includes(sessionTokenData.role) ? (
              <ModalCropImage
                imgSrc={imgSrc}
                setImgSrc={setImgSrc}
                Visible={formCropVisible}
                setVisible={setFormCropVisible}
                setCompanyCurrent={setProjectSelected}
                CompanyCurrent={ProjectSelected}
                projectSelectedId={stateProject.projectSelected}
                setStateUpload={setStateUpload}
                setStateNewImage={setStateNewImage}
                t={t}
              />
            ) : null}
            <div
              className="logo-title-imageContent"
              style={{ marginTop: '7px' }}>
              <p className="project-logos-titles">
                {t('settings.logo_projectOwner')}
              </p>
              <div className="cont-projectOwnerImg-edit">
                {['superadmin', 'admin'].includes(sessionTokenData.role) ? (
                  <div className="Crop-Controls">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={onSelectFileProjectOwner}
                      id="file-input-edit-image2"
                    />
                  </div>
                ) : null}
                <div>
                  {stateUploadProjectOwner &&
                  stateUploadProjectOwner.imageProjectOwnerUrl
                    ? imgProjectOwnerToShow
                    : uploadProjectOwnerButton}
                </div>
              </div>
            </div>
            {['superadmin', 'admin'].includes(sessionTokenData.role) ? (
              <label htmlFor="file-input-edit-image2">
                <img
                  className="img-upload-logo"
                  alt="logo company"
                  src={uploadIcon}
                />
              </label>
            ) : null}
            {['superadmin', 'admin'].includes(sessionTokenData.role) ? (
              <ModalCropImage
                imgSrc={imgSrcProjectOwner}
                setImgSrc={setImgSrcProjectOwner}
                Visible={formCropVisibleProjectOwner}
                setVisible={setFormCropVisibleProjectOwner}
                setCompanyCurrent={setProjectSelected}
                CompanyCurrent={ProjectSelected}
                projectSelectedId={stateProject.projectSelected}
                setStateUpload={setStateUploadProjectOwner}
                setStateNewImage={setStateNewOwnerImage}
                typeImage="projectOwnerImage"
                t={t}
              />
            ) : null}
          </div>
          <div>
            <p className="description-form">
              {t('settings.project_form.name')}
            </p>
            <Input
              disabled={onlyRead}
              name="name"
              className="input-text"
              placeholder="Project Name"
              value={ProjectSelected?.name}
              onChange={(e) => changeInput(e)}
              onBlur={() => updateProjectSettings()}
            />
            <p className="description-form">
              {t('settings.project_form.type')}
            </p>
            <div className="fix-title">
              <SelectionStyle>
                <Select
                  disabled={onlyRead}
                  value={ProjectSelected?.type}
                  style={{
                    width: '95%',
                    fontSize: '12px',
                    border: '1px solid #121212',
                    borderRadius: '5px'
                  }}
                  onChange={(e) => {
                    console.dir(e);
                    updateSelect('type', e);
                  }}
                  name="type"
                  onBlur={() => updateProjectSettings()}>
                  {Types.map((project) => (
                    <Option key={project.value} value={project.value}>
                      {t(`settings.project_form.${project.trad}`)}
                    </Option>
                  ))}
                </Select>
              </SelectionStyle>
            </div>

            <h1 className="title-config-projects">
              {t('settings.project_form.control')}
            </h1>
            <p className="description-form">
              {t('settings.project_form.plan_day')}
            </p>
            <div className="fix-title">
              <SelectionStyle>
                <Select
                  disabled={onlyRead}
                  value={
                    ProjectSelected?.planification_day === null
                      ? 1
                      : ProjectSelected?.planification_day
                  }
                  style={{
                    width: '95%',
                    fontSize: '12px',
                    border: '1px solid #121212',
                    borderRadius: '5px'
                  }}
                  onChange={(e) => {
                    updateSelect('planification_day', e);
                  }}
                  name="planification_day"
                  onBlur={() => updateProjectSettings()}>
                  {week_days.map((day) => (
                    <Option key={day.value} value={day.value}>
                      {t(`settings.project_form.weeks.${day.trad}`)}
                    </Option>
                  ))}
                </Select>
              </SelectionStyle>
            </div>
            <div style={{ marginTop: '20px', marginLeft: '2px' }}>
              <p className="description-form">
                {t('settings.project_form.ponderador')}
              </p>
              <Input
                disabled={onlyRead}
                name="geo_project_id"
                className="input-text"
                placeholder={t('settings.project_form.ponderador')}
                value={ProjectSelected?.geo_project_id}
                onChange={(e) => changeInput(e)}
                onBlur={() => updateProjectSettings()}
              />
            </div>
          </div>

          <div>
            <p className="description-form">
              {t('settings.project_form.address')}
            </p>
            <Input
              disabled={onlyRead}
              name="address"
              className="input-text"
              placeholder={t('settings.project_form.address')}
              value={ProjectSelected?.address}
              onChange={(e) => changeInput(e)}
              onBlur={() => updateProjectSettings()}
            />
            <p className="description-form">
              {t('settings.project_form.admin')}
            </p>
            <div className="fix-title">
              <SelectionStyle>
                <Select
                  disabled={onlyRead}
                  value={ProjectSelected?.managerId || undefined}
                  style={{
                    width: '95%',
                    fontSize: '12px',
                    border: '1px solid #121212',
                    borderRadius: '5px'
                  }}
                  placeholder={t(
                    'settings.project_categories.constraints.select'
                  )}
                  onChange={(e) => {
                    updateSelect('managerId', e);
                  }}
                  name="managerId"
                  onBlur={() => updateProjectSettings()}>
                  {UsersProject &&
                    UsersProject.map((user, index) => (
                      <Option key={user.id} value={user.id}>
                        {(user.name || t('settings.project_form.no_name')) +
                          ' ' +
                          (user.lastname ||
                            t('settings.project_form.no_lastname'))}
                      </Option>
                    ))}
                </Select>
              </SelectionStyle>
            </div>

            <p className="description-form mt-description-manager">
              {t('settings.project_form.ponderator')}
            </p>
            <SelectionStyle>
              <Select
                disabled={onlyRead}
                value={ProjectSelected?.task_creter}
                style={{
                  width: ' 95%',
                  fontSize: '12px',
                  border: '1px solid #121212',
                  borderRadius: '5px',
                  marginBottom: 16
                }}
                name="type"
                onBlur={() => updateProjectSettings()}>
                {typesCreaters.map((type) => (
                  <Option
                    name="task_creter"
                    onClick={(e) => {
                      updateSelect('task_creter', e.key);
                      sectorService.updatePonderatorLookahead(
                        stateProject.sectorSelected,
                        true
                      );
                    }}
                    key={type.value}>
                    {t(`settings.project_form.creaters.${type.trad}`)}
                  </Option>
                ))}
              </Select>
            </SelectionStyle>

            <p className="description-form" style={{ marginTop: '5px' }}>
              {t('settings.project_form.curve_s')}
            </p>
            <SelectionStyle>
              <Select
                disabled={onlyRead}
                value={ProjectSelected?.activity_creter}
                style={{
                  width: '95%',
                  fontSize: '12px',
                  border: '1px solid #121212',
                  borderRadius: '5px'
                }}
                name="type"
                onBlur={() => updateProjectSettings()}>
                {typesCreaters.map((type) => (
                  <Option
                    name="activity_creter"
                    onClick={(e) => {
                      updateSelect('activity_creter', e.key);
                      sectorService.updatePonderatorMaster(
                        stateProject.sectorSelected,
                        true
                      );
                    }}
                    key={type.value}>
                    {t(`settings.project_form.creaters.${type.trad}`)}
                  </Option>
                ))}
              </Select>
            </SelectionStyle>
          </div>

          <div>
            <p className="description-form">
              {t('settings.project_form.budget')}
            </p>
            <SelectStyle>
              <div
                style={{
                  border: '1px solid #121212',
                  width: '65px',
                  borderRadius: '5px',
                  float: 'left'
                }}>
                <Select
                  disabled={onlyRead}
                  dropdownClassName="options-currency"
                  className="select-budget"
                  value={ProjectSelected?.currency}
                  style={{ width: 65 }}
                  bordered={false}
                  onBlur={() => updateProjectSettings()}>
                  {projectCurrency(t).map((currency) => (
                    <Option
                      name="currency"
                      onClick={() => updateSelect('currency', currency.value)}
                      key={currency.label}>
                      {currency.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </SelectStyle>
            <NumberFormat
              disabled={onlyRead}
              name="budget"
              className="input-text numeric-project"
              placeholder={t('settings.project_form.budget')}
              displayType={'input'}
              thousandSeparator={t('ĺang') === 'en' ? ',' : '.'}
              prefix={''}
              decimalSeparator={false}
              value={ProjectSelected?.budget}
              onBlur={() => updateProjectSettings()}
              allowNegative={false}
              onChange={(e) => changeInput(e)}
              required
            />
            <p className="description-form">
              {t('settings.project_form.size')}
            </p>
            <SelectStyle>
              <div
                style={{
                  border: '1px solid #121212',
                  width: '65px',
                  borderRadius: '5px',
                  float: 'left'
                }}>
                <Select
                  disabled={onlyRead}
                  className="select-size"
                  value={ProjectSelected?.sizetype}
                  style={{ width: 65 }}
                  bordered={false}
                  onBlur={() => updateProjectSettings()}
                  onChange={(e) => updateSelect('sizetype', e)}>
                  {projectSizesTypes.map((size) => (
                    <Option value={size.value} key={size.value}>
                      {size.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </SelectStyle>
            <NumberFormat
              disabled={onlyRead}
              id="numberformatProjectSize1"
              name="size"
              className="input-text numeric-project"
              placeholder={t('settings.project_form.size')}
              displayType={'input'}
              thousandSeparator={t('ĺang') === 'en' ? ',' : '.'}
              prefix={''}
              decimalSeparator={','}
              decimalScale={2}
              value={ProjectSelected?.size}
              onBlur={() => updateProjectSettings(true)}
              allowNegative={false}
              onChange={(e) => changeInput(e)}
              required
            />

            <p className="description-form" style={{ marginTop: '32px' }}>
              {t('settings.project_form.pcr')}
            </p>
            <NumberFormat
              disabled={onlyRead}
              name="pcr_goal"
              className="input-text percentage-project"
              placeholder={t('settings.project_form.pcr')}
              displayType={'input'}
              thousandSeparator={t('ĺang') === 'en' ? ',' : '.'}
              prefix={''}
              decimalSeparator={false}
              value={ProjectSelected?.pcr_goal}
              onBlur={() => updateProjectSettings()}
              onChange={(e) => changeInput(e)}
              required
              isAllowed={(values) => {
                if (values.value === '') {
                  return true;
                }
                const x = parseInt(values.value);
                if (x >= 0 && x <= 100) {
                  return true;
                }
                return false;
              }}
            />

            <p className="description-form">{t('settings.project_form.ppc')}</p>
            <NumberFormat
              disabled={onlyRead}
              name="pcc_goal"
              className="input-text percentage-project"
              placeholder={t('settings.project_form.ponderator')}
              displayType={'input'}
              thousandSeparator={t('ĺang') === 'en' ? ',' : '.'}
              prefix={''}
              decimalSeparator={false}
              value={ProjectSelected?.pcc_goal}
              onBlur={() => updateProjectSettings()}
              onChange={(e) => changeInput(e)}
              required
              isAllowed={(values) => {
                if (values.value === '') {
                  return true;
                }
                const x = parseInt(values.value);
                if (x >= 0 && x <= 100) {
                  return true;
                }
                return false;
              }}
            />
          </div>

          <div>
            <p className="description-form">
              {t('settings.project_form.date_start')}
            </p>
            <Input
              disabled={true}
              type="text"
              className="input-text date-input-project"
              value={moment(minStartDate).format(dateFormat)}
            />
            <p className="description-form">
              {t('settings.project_form.date_end')}
            </p>
            <Input
              disabled={true}
              type="text"
              className="input-text date-input-project"
              value={moment(maxEndDate).format(dateFormat)}
            />
          </div>
        </div>
      </div>
      <Etapas onlyRead={onlyRead} t={t} />
    </Fragment>
  );
}

export default CardGeneral;

const SelectionStyle = styled.div`
  .ant-select-selection {
    border-radius: 5px !important;
    width: 90%;
  }
  .ant-select-selection {
    background-color: white !important;
    color: #5f5f5f;
  }
  .anticon svg {
    color: transparent;
  }

  .ant-select-selection,
  ant-select-selection--single {
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    border-top: 0px;
  }

  .ant-select-selection:focus,
  ant-select-selection--single:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

const SelectStyle = styled.div`
  .ant-select-selection {
    border-radius: 5px !important;
    width: 100%;
  }
  .ant-select-selection {
    background-color: transparent !important;
    color: #121212;
    font-size: 12px;
  }
  .anticon svg {
    color: transparent;
  }

  .ant-select-selection,
  ant-select-selection--single {
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    border-top: 0px;
  }

  .ant-select-selection:focus,
  ant-select-selection--single:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;
