import React from 'react';
import { startCase } from 'lodash';
import moment from 'moment';

import * as Icons from '../../../../../../../icons';
import styles from './Calendar.module.scss';

export const WeeklyPlanCalendar = (props) => {
  const { currentWeek, dateRange, t = (_) => {} } = props;
  return (
    <div className={styles.container} id="pos">
      {currentWeek ? (
        <span className={styles.calendar__info}>
          <Icons.CalendarIcon />
          <div className="week-number">
            {t('weekly_week')} {currentWeek} -{' '}
          </div>
          <div className="range-date">
            {startCase(
              moment(dateRange.start).format('DD MMM').replace(/\./g, '')
            )}
            {/*startCase(moment(dateRange.end).format('DD MMM').replace(/\./g, ''))*/}
          </div>
        </span>
      ) : (
        <span className={styles.calendar__info}>
          <div className="week-number">{t('weekly_select')}</div>
          <div className="range-date">{t('weekly_init_end')}</div>
        </span>
      )}
    </div>
  );
};
