import React from 'react';
import { Tooltip } from 'antd';

const TooltipSystemV2 = ({ children, ...props }) => (
  <Tooltip overlayClassName={`tooltip-design-system-v2`} {...props}>
    {children}
  </Tooltip>
);

export default TooltipSystemV2;
