/* eslint-disable max-lines-per-function */
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as projectActions from '../redux/slices/projectSlice';
import { trackingEvent } from '../analytics';
import { AMPLITUDE_SERVICE } from '../analytics/constants';
import { getBasicAmplitudEventProperties } from '../analytics/utils';
import { setAllProjectsGlobal, openNotification } from '../utils';
import {
  asignProjectSubcontract,
  asignProjectUser,
  createProject,
  createSector
} from '../views/project/project.helper';
import {
  defaultCncTypes,
  defaultConstraintTypes,
  defaultSubContracts
} from '../components/Projects/FormProjectsDummy/dummyData';
import {
  calendarService,
  cncTypeService,
  constraintTypeService,
  ganttService,
  subContractService,
  userService,
  workingdayService
} from '../services';
import { removeCurrencySeparators } from '../utils/currency';
import { roleTypes } from '../constants/role.constants';
import { log } from '../monitor/monitor';
import * as Sentry from '@sentry/react';

const useSaveProject = (
  state,
  setField,
  setLoading,
  resetForm,
  setVisible,
  t,
  user,
  currentCompany
) => {
  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.projectState);
  const history = useHistory();

  const saveProject = useCallback(async () => {
    if (!state.projectName.trim()) {
      openNotification(
        {
          title: t('add_project_modal.notification_title_error'),
          description: t('add_project_modal.notification_description_error'),
          type: 'error'
        },
        5
      );
      return;
    }

    setLoading(true);
    log('save_project', `Saving project: ${state.projectName}, ${user.id}`);

    try {
      setLoading(true);
      const isEnglish = t('lang').includes('en');
      const budget = removeCurrencySeparators(isEnglish, state.budget);
      const size = removeCurrencySeparators(isEnglish, state.size);
      let newSector;

      log('save_project', `Saving project: ${state.projectName}, ${user.id}`);

      const projectNew = await createProject({
        name: state.projectName,
        country: state.location,
        companyId: currentCompany.id,
        type: state.projectType,
        currency: state.currency,
        budget: budget,
        sizetype: state.unit,
        size: size,
        stage: 'started',
        planification_day: 1,
        image: state.projectImageURL
      });

      if (projectNew.id) {
        log('save_project', `Project created: ${projectNew.id}, ${user.id}`);
        /** add constraint types */
        const asyncMapDefaultConstraintTypes = defaultConstraintTypes(t).map(
          async (constraintType) => {
            await constraintTypeService.create({
              ...constraintType,
              projectId: projectNew.id,
              description: '' /** is required */
            });
          }
        );

        log(
          'save_project',
          `Adding constraint types: ${projectNew.id}, ${user.id}`
        );
        await Promise.all(asyncMapDefaultConstraintTypes);

        /** add cnc types */
        const asyncMapDefaultCncTypes = defaultCncTypes(t).map(
          async (cncType) => {
            await cncTypeService.create({
              ...cncType,
              projectId: projectNew.id,
              description: '' /** is required */
            });
          }
        );

        log('save_project', `Adding cnc types: ${projectNew.id}, ${user.id}`);
        await Promise.all(asyncMapDefaultCncTypes);
        // add default subcontract

        const response = await subContractService.getByCompany(
          currentCompany.id
        );
        let allSubcontracts;

        if (response) {
          allSubcontracts = response.subcontracts;
        }
        log(
          'save_project',
          `Adding default subcontract: ${projectNew.id}, ${user.id}`
        );

        defaultSubContracts.map(async (sc) => {
          const existName = allSubcontracts.filter(
            (el) =>
              el.type === 'General Contractor' &&
              el.name.toLowerCase().trim() ===
                currentCompany.name.toLowerCase().trim()
          );
          log(
            'save_project',
            `Adding default subcontract: ${projectNew.id}, ${user.id}`
          );
          if (!existName.length) {
            const subcontract = await subContractService.create({
              ...sc,
              companyId: currentCompany.id,
              name: currentCompany.name,
              image: currentCompany.image
            });

            /** assign */
            if (subcontract.id) {
              await asignProjectSubcontract({
                subcontractId: subcontract.id,
                projectId: projectNew.id
              });
            }
          } else {
            await asignProjectSubcontract({
              subcontractId: existName[0].id,
              projectId: projectNew.id
            });
          }
        });

        log(
          'save_project',
          `Assigning project to user: ${projectNew.id}, ${user.id}`
        );
        // assign to user
        await asignProjectUser({
          userId: user.id,
          projectId: projectNew.id,
          isCreator: true
        });

        log('save_project', `Creating sector: ${projectNew.id}, ${user.id}`);
        newSector = await createSector({
          name: 'Main Schedule',
          projectId: projectNew.id,
          set_current: true,
          visible: true
        });
        log('save_project', `Sector created: ${newSector.id}, ${user.id}`);

        /** users */
        /** get users of company */

        const usersCompany = await userService.getByCompany(currentCompany.id);
        // assign users to project
        if (usersCompany.users) {
          usersCompany.users.map(async (us) => {
            if (us.role === roleTypes.SUPERADMIN && user.id !== us.id) {
              log(
                'save_project',
                `Assigning project to user: ${projectNew.id}, ${user.id}`
              );
              await asignProjectUser({
                userId: us.id,
                projectId: projectNew.id,
                isCreator: false
              });
            }
          });
        }
      }

      let status; // waiting aws
      while (state.projectImageURL && status !== 200) {
        const response = await fetch(state.projectImageURL);
        status = response.status;
      }
      const getProjects = await userService.projectsbyuserthrough(user.id);
      const projectsActive = getProjects.projects.filter(
        (e) => e.stage !== 'deleted'
      );
      const updateProjectsList = (arr) => {
        setAllProjectsGlobal(arr, dispatch, projectActions, projectState);
      };
      const ganttObject = {
        activities: 0,
        activities_relation_size: 0,
        is_impoorter: false,
        sectorId: newSector.id
      };

      log('save_project', `Creating gantt: ${projectNew.id}, ${user.id}`);
      const ganttRes = await ganttService.create(ganttObject);

      const gantt = await ganttService.showBySector(newSector.id);

      log('save_project', `Creating calendar: ${projectNew.id}, ${user.id}`);
      const calendar = await calendarService.create({
        name: 'Default',
        is_default: true,
        working_days: '0,1,1,1,1,1,0',
        sectorId: newSector.id,
        ganttId: gantt?.gantt?.id,
        shift_start: 'false,8:00,8:00,8:00,8:00,8:00,false',
        shift_end: 'false,16:00,16:00,16:00,16:00,16:00,false',
        status: true,
        userId: user.id,
        unique_id: new Date().getTime()
      });

      if (calendar.id) {
        const workingDay = {
          shift_string:
            'false,8:00,8:00,8:00,8:00,8:00,false-false,16:00,16:00,16:00,16:00,16:00,false',
          correlative_id: 0,
          calendarId: calendar.id
        };
        await workingdayService.create(workingDay);
      }

      dispatch(projectActions.setProject(projectNew.id));
      sessionStorage.setItem('currentProject', JSON.stringify(projectNew));
      dispatch(projectActions.setSector(newSector.id));
      sessionStorage.setItem('currentSector', JSON.stringify(newSector));

      log(
        'save_project',
        `finished: ${projectNew.id}, ${newSector.id}, ${user.id}`
      );
      openNotification(
        {
          title: t('add_project_modal.notification_title_success'),
          description: t('add_project_modal.notification_description_success'),
          type: 'success'
        },
        5
      );
      dispatch(
        projectActions.setProps({
          ...projectState.props,
          openAddProject: false
        })
      );
      resetForm();
      updateProjectsList(projectsActive);
      setLoading(false);

      trackingEvent(
        'add_project_click',
        {
          ...getBasicAmplitudEventProperties(),
          project_created: true,
          details_expanded: state.moreDetailsExpanded,
          user_id: user.id
        },
        AMPLITUDE_SERVICE
      );

      log('save_project', `Redirecting to gantt: ${projectNew.id}, ${user.id}`);

      history.push({
        pathname: '/gantt/' + ganttRes.sectorId + '/ganttchart',
        state: {}
      });
    } catch (error) {
      Sentry.captureException(error);
      const breadcrumbMessage = `Error creating project: ${error.message} - ${currentCompany.id} - ${state.projectName}, ${user.id}`;
      log('creating_project:', breadcrumbMessage);
      trackingEvent(
        'add_project_click',
        {
          ...getBasicAmplitudEventProperties(),
          project_created: false,
          details_expanded: state.moreDetailsExpanded,
          user_id: user.id
        },
        AMPLITUDE_SERVICE
      );
      openNotification(
        {
          title: 'Error',
          description: error.message,
          type: 'error'
        },
        5
      );
    } finally {
      setLoading(false);
      resetForm();
      dispatch(
        projectActions.setProps({
          ...projectState.props,
          openAddProject: false
        })
      );
    }
  }, [
    state,
    setField,
    setLoading,
    resetForm,
    setVisible,
    t,
    user,
    currentCompany,
    dispatch
  ]);

  return { saveProject };
};

export default useSaveProject;
