/** React components  */
import React, { useState, useEffect } from 'react';

import { Animated } from 'react-animated-css';

/** Import elements from library Antd */
import {
  Select,
  Row,
  Col,
  Modal,
  Button,
  Upload,
  message,
  Progress
} from 'antd';
import { CloseOutlined, CheckCircleOutlined } from '@ant-design/icons';

/** Redux */
import { useSelector } from 'react-redux';

import { withTranslation } from 'react-i18next';
/** import base contant (setting urls)  */
import { importerService, sectorService } from '../../services';

import { socket } from '../../services/socket.service';

import * as projectActions from '../../redux/slices/projectSlice';
import { useDispatch } from 'react-redux';
import { getImportableIconByFileExt } from '../../utils/importGanttUtils';

/** consts */
const colorBar = '#7DFF8A';
const { Option } = Select;

function ImportGanttFinish(props) {
  /** Project state from redux */
  const projectState = useSelector((state) => state.projectState);
  const companyState = useSelector((state) => state.companyState);

  /** Redux */
  const dispatch = useDispatch();

  /** use props */
  const {
    t,
    setStartTimeUpload,
    fileInfo,
    stateUpload,
    visible,
    listOptions,
    setVisibleFinish,
    setStateUpload,
    setListOptions,
    setFileInfo
  } = props;

  /** hooks */
  const [currenTab, setCurrenTab] = useState(1);
  const [selection, setSelection] = useState([
    'tasks',
    'calendars',
    'progress'
  ]);
  const [percentUpload, setPercentUpload] = useState(0);
  const [tasksTotal, setTasksTotal] = useState(0);
  const [tasksProgress, setTasksProgress] = useState(0);

  const handleClose = async () => {
    socket.off(
      'importer_sector' + projectState.sectorSelected,
      realTimeTaskUpdate
    );
    setVisibleFinish(false);
    setStateUpload({ loading: false });
    setFileInfo({ name: null });
    setListOptions(null);
    setStartTimeUpload(null);
    await sectorService.updatePonderatorMaster(
      projectState.sectorSelected,
      true
    );
    await updateDataSector();

    props.history.push('/masterplan');
  };

  const updateDataSector = async () => {
    /** update state sectors */
    const sectorIndex = projectState.allSectors.findIndex(
      (e) => e.id == projectState.sectorSelected
    );
    if (sectorIndex !== -1) {
      const sectorFromBD = await sectorService.show(
        projectState.sectorSelected
      );
      if (sectorFromBD && sectorFromBD.sector) {
        const sector = projectState.allSectors[sectorIndex];
        const hoursPerDay = sectorFromBD?.sector?.hoursPerDay || 0;
        const hoursPerWeek = sectorFromBD?.sector?.hoursPerWeek || 0;
        sector.hoursPerDay = hoursPerDay;
        sector.hoursPerWeek = hoursPerWeek;
        const allSectors = projectState.allSectors;
        allSectors[sectorIndex] = sector;
        dispatch(projectActions.setAllSectors(allSectors));
      }
    }
  };

  const prgUpload = {
    percent: percentUpload,
    strokeColor: {
      '0%': colorBar,
      '100%': colorBar
    },
    strokeWidth: 3,
    format: (percent) => `${parseFloat(percent.toFixed(2))}%`
  };

  const realTimeTaskUpdate = (data) => {
    const newPercent = parseFloat((100 * data.progreso) / data.total);
    setPercentUpload(newPercent);
    setTasksTotal(isNaN(parseInt(data.total)) ? 0 : parseInt(data.total));
    setTasksProgress(
      isNaN(parseInt(data.progreso)) ? 0 : parseInt(data.progreso)
    );
    if (data.tipo === 'Completado') {
      setCurrenTab(3);
    }
  };

  const handleSubmitFinish = () => {
    if (stateUpload) {
      const data = {
        is_reimport: false,
        is_cost: selection.includes('cost'),
        is_hh: selection.includes('hh'),
        is_progress: selection.includes('progress'),
        gantt_url: stateUpload.name,
        sector_id: projectState.sectorSelected,
        company_id: companyState?.currentCompany?.id
      };
      setCurrenTab(2);
      const resp = importerService.save(data);
      socket.on(
        'importer_sector' + projectState.sectorSelected,
        realTimeTaskUpdate
      );
    } else {
      console.dir('error');
    }
  };

  const handleSelection = (sel, index) => {
    if (index > 1) {
      if (selection.includes(sel)) {
        const selFilter = selection.filter((el) => el !== sel);
        setSelection(selFilter);
      } else {
        setSelection([...selection, sel]);
      }
    }
  };

  const handleEndProcess = () => {
    setCurrenTab(3);
  };

  const renderOptions = () => {
    const ret = (
      <div className="list-items">
        {listOptions.map((el, index) => (
          <div
            className={`item-info ${selection.includes(el.name) ? 'selected' : null}`}
            key={index}
            onClick={() => handleSelection(el.name, index)}>
            <div className="item-number">{el.title}</div>
            <div className="item-name">{el.subtitle}</div>
          </div>
        ))}
      </div>
    );
    return ret;
  };

  return (
    <Animated animationIn="fadeIn" animationInDuration={500} isVisible={true}>
      <Modal
        closable={false}
        className="frm-finish"
        title=""
        visible={visible}
        centered
        keyboard={false}
        maskClosable={false}
        onCancel={handleClose}
        width={450}
        footer={null}>
        <div className={`body-modal ${currenTab === 1 ? null : 'hidden'}`}>
          <Row className="row-normal">
            <Col span={24} className="tab-right">
              <Row className="process-title">
                <div className="title">
                  <img
                    className="img-upload"
                    src={getImportableIconByFileExt(fileInfo?.name)}
                  />
                  {fileInfo.name}
                </div>
                <div className="hand-close" onClick={handleClose}>
                  <CloseOutlined />
                </div>
              </Row>
              <div className="title-info">
                {t('import.finish.elements_to_load_label')}
              </div>
              {renderOptions()}
              <div className="buttons">
                <Button
                  className="btn-continue"
                  onClick={() => handleSubmitFinish()}>
                  {t('import.finish.import_label')} {selection.length}{' '}
                  {t('import.finish.elements_label')}
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        <div className={`body-modal ${currenTab === 2 ? null : 'hidden'}`}>
          <Row className="process-title">
            <div className="title">{t('import.gantt_import')}</div>
            <div className="hand-close" onClick={handleClose}>
              <CloseOutlined />
            </div>
          </Row>
          <Row className="row-normal">
            <span className="text-title">
              {t('import.finish.processing_gantt_label')}
            </span>
            <div className="text-remaining">
              {t('import.finish.loading_tasks_label')}
              <span onClick={() => handleEndProcess()}>
                {tasksProgress}/{tasksTotal}
              </span>
            </div>
            <div className="text-filename">
              <img
                className="img-upload"
                src={getImportableIconByFileExt(fileInfo?.name)}
              />
              {fileInfo.name}
            </div>
            <div className="div-progress">
              <Progress {...prgUpload} />
            </div>
          </Row>
        </div>

        <div className={`body-modal ${currenTab === 3 ? null : 'hidden'}`}>
          <Row className="process-title">
            <div className="title">{t('import.gantt_import')}</div>
            <div className="hand-close" onClick={handleClose}>
              <CloseOutlined />
            </div>
          </Row>
          <Row className="row-normal">
            <div className="text-success">
              {t('import.finish.success_import_label')}
            </div>
            <div className="div-circle" onClick={() => handleClose()}>
              <CheckCircleOutlined className="circle-ok" />
            </div>
          </Row>
        </div>
      </Modal>
    </Animated>
  );
}

export default withTranslation()(ImportGanttFinish);
