import { useEffect, useState } from 'react';
import { dynamicSort } from '../../../utils';
import { filterByName } from '../../../utils/projectUtils';

const useProjectFilterByStage = (projectsGroupedByStages, filterText = '') => {
  const [sortedProjects, setSortedProjects] = useState({});

  useEffect(() => {
    if (
      !projectsGroupedByStages ||
      !Object.keys(projectsGroupedByStages).length
    ) {
      setSortedProjects({});
      return;
    }

    const sortedGroupedProjects = Object.keys(projectsGroupedByStages).reduce(
      (acc, stage) => {
        const projectsByStage = projectsGroupedByStages[stage];
        acc[stage] = projectsByStage
          .sort(dynamicSort('name'))
          .filter(filterByName(filterText));
        return acc;
      },
      {}
    );

    setSortedProjects(sortedGroupedProjects);
  }, [projectsGroupedByStages, filterText]);

  return sortedProjects;
};

export default useProjectFilterByStage;
