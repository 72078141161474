import React, { useEffect } from 'react';
import EventEmitter from 'react-native-eventemitter';
import LookAHeadReport, {
  NUMWEEKSPERPAGE
} from '../../../components/Report/LookAHeadReport';
import { useParams } from 'react-router-dom';

export default function ReportLookAhead(props) {
  const { week, numWeeks } = useParams();
  const idsvalidate = JSON.parse(localStorage.getItem('idsPdfLookahead'));

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);
  const formattedWeek = week ? week.replaceAll('-', '/') : '';
  const parsedNumWeeks = numWeeks ? parseInt(numWeeks, 10) : NUMWEEKSPERPAGE;
  return (
    <div
      className="lookahead-report-container"
      style={{ background: '#FFFFFF' }}>
      <LookAHeadReport
        week={formattedWeek}
        numWeeks={parsedNumWeeks}
        idsvalidate={idsvalidate}
      />
    </div>
  );
}
