/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
const baseURL = 'https://respaldobackv2.proplanner.app/api/';
class ProjectServiceNew {
  constructor() {
    this.http = createHttpClient({ baseURL });
  }

  async show(projectId) {
    const res = await this.http.get('projects/' + projectId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async destroy(projectId) {
    const res = await this.http
      .delete('projects/' + projectId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('projects', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('projects/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updateHoursPerDay(data) {
    const res = await this.http
      .put(`projects/${data.id}/hoursperday`, { hoursPerDay: data.hoursPerDay })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async asignProjectUser(data) {
    const res = await this.http
      .post('projects/assign/user', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async asignProjectSubContract(data) {
    const res = await this.http
      .post('projects/assign/subcontract', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async deallocateUserOfProject(data) {
    const res = await this.http
      .post('projects/deallocate/user', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res;
  }

  async deallocateSubcontracOfProject(data) {
    const res = await this.http
      .post('projects/deallocate/subcontract', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async ProjectsByUser(userId) {
    const res = await this.http
      .get('projects/byuser/' + userId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showAnalitic(projectId, ourRequest = null) {
    let res;
    if (ourRequest) {
      res = await this.http
        .get('projects/analitic/' + projectId, {
          cancelToken: ourRequest?.token || null
        })
        .catch((error) => {
          console.log(error.response);
          return false;
        });
    } else {
      res = await this.http
        .get('projects/analitic/' + projectId)
        .catch((error) => {
          console.log(error.response);
          return false;
        });
    }
    // return res ? res.data : res
    return res;
  }

  async showAnaliticByFilter(data) {
    const res = await this.http
      .post('projects/analitic_filter', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const projectServiceNew = new ProjectServiceNew();
