/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
import { base } from './base';

const baseURL = base.apiTakt;

class LocationService {
  constructor() {
    this.http = createHttpClient({ baseURL });
  }

  async show(locationId) {
    const res = await this.http
      .get('locations/' + locationId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(locationId) {
    const res = await this.http
      .delete('locations/' + locationId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('locations', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async createAll(data) {
    const res = await this.http
      .post('locations/create_all', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('locations/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showBySector(sectorId) {
    const res = await this.http
      .post('locations/bysector', { sectorId })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const locationService = new LocationService();
