import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyStateSystem from '../../components/DesignSystem/EmptyStateSystem';
import { trackingEvent } from '../../analytics';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';
import { getCurrentUser } from '../../utils/userUtils';
import {
  INVALID_LINK,
  NO_MODULE_ACCESS,
  NO_ORGANIZATION_ACCESS,
  NO_SCHEDULE_ACCESS,
  NOT_FOUND
} from '../../constants/access';
import Toolbar from '../../components/Toolbar';
import invalidLinkImg from '../../assets/img/invalidLink.png';
import notFoundImg from '../../assets/img/notFound.png';
import unauthorizedImg from '../../assets/img/unauthorized.png';
import './index.scss';

const redirectToHomePage = (link) => () => {
  if (getCurrentUser()) {
    window.location.href = link ?? '/projects';
    return;
  }
  window.location.href = '/login';
};

const selects = {
  projects: true,
  stages: true
};

const layoutTypes = {
  [INVALID_LINK]: {
    backgroundClass: 'not-found-layout',
    title: 'error_views.invalid_link.title',
    description: 'error_views.invalid_link.description',
    textButton: 'error_views.invalid_link.button',
    icon: <img src={invalidLinkImg} width={200} />
  },
  [NOT_FOUND]: {
    backgroundClass: 'not-found-layout',
    title: 'error_views.not_found.title',
    description: 'error_views.not_found.description',
    textButton: 'error_views.not_found.button',
    icon: <img src={notFoundImg} width={200} />
  },
  [NO_ORGANIZATION_ACCESS]: {
    title: 'error_views.no_organization_access.title',
    description: 'error_views.no_organization_access.description',
    textButton: 'error_views.no_organization_access.button',
    icon: <img src={unauthorizedImg} width={200} />,
    link: '/selectCompany'
  },
  [NO_SCHEDULE_ACCESS]: {
    header: true,
    title: 'error_views.no_schedule_access.title',
    description: 'error_views.no_schedule_access.description',
    textButton: 'error_views.no_schedule_access.button',
    icon: <img src={unauthorizedImg} width={200} />,
    link: '/projects'
  },
  [NO_MODULE_ACCESS]: {
    title: 'error_views.no_module_access.title',
    description: 'error_views.no_module_access.description',
    textButton: 'error_views.no_module_access.button',
    icon: <img src={unauthorizedImg} width={200} />,
    link: '/projects'
  }
};

const InvalidLinkLayout = ({ type }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (type !== NOT_FOUND && type !== INVALID_LINK) {
      return;
    }

    const url = window.location.href;
    trackingEvent('404_error_viewed', { url }, AMPLITUDE_SERVICE);
  }, []);

  const {
    header,
    backgroundClass,
    title,
    description,
    textButton,
    icon,
    link
  } = layoutTypes[type];

  return (
    <div className={backgroundClass}>
      {header && (
        <Toolbar selects={selects} fixed={true} autoSetSchedule={false} />
      )}
      <EmptyStateSystem
        icon={icon}
        title={t(title)}
        description={t(description)}
        textButton={t(textButton)}
        functionButton={redirectToHomePage(link)}
      />
    </div>
  );
};

export default InvalidLinkLayout;
