/* eslint-disable prefer-const */

/** React components  */
import React, { useState, useEffect, useRef } from 'react';

/** Import Library for PropTypes */
import PropTypes from 'prop-types';

/** Import elements from library Antd */
import { Row, Input, Col, List, ConfigProvider, Empty, Tooltip } from 'antd';

/** Redux implementation */
import { useSelector, useDispatch } from 'react-redux';
import { constraintActions } from '../../../../../redux/actions/constraintActions';

/** Load Components */
import SelectActivities from '../SelectActivities';
import ConstraintResponsables from '../ConstraintResponsables';
import ConstraintDates from '../ConstraintDates';
import CharacterCounter from './CharacterCounter';

/** config for empty */
const customizeRenderEmpty = () => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description="Sin tareas seleccionadas"
  />
);

const ConstraintSelectTask = (props) => {
  /** Redux */
  const dispatch = useDispatch();
  const constraintState = useSelector((state) => state.constraintState);

  /** use props */
  const {
    nameValid,
    initialTasks,
    visibleForm,
    responsableValid,
    toSelectResponsables,
    t
  } = props;

  /** Hooks */
  const [taskList, setTaskList] = useState(
    constraintState.constraintForm.taskList
  ); /** handle load */
  const [selectedTask, setSelectedTask] = useState(); /** handle select task */
  const [valSelect, setValSelect] = useState({}); /** handle select task */

  /** load initial tasks */
  useEffect(() => {
    if (initialTasks.length) {
      /** get tasks from state (if task id not exists on tasks, remove object of itialTasks) */
      const tmpTasks = initialTasks
        .map((task) => {
          let findTask;
          if (constraintState.constraintLookAhead.tasks) {
            findTask = constraintState.constraintLookAhead.tasks.find(
              (t) => t.id === task.id
            );
          }
          return findTask || null;
        })
        .filter((elem) => elem);
      const initalSelect = tmpTasks.map((el) => 'task-' + el.id);
      setValSelect({
        selected: initalSelect
      });
      forceUpdate(tmpTasks);
    }
  }, [visibleForm, constraintState.constraintLookAhead]);

  useEffect(() => {
    setValSelect({ selected: null });
    if (constraintNameInputLengthRef) {
      constraintNameInputLengthRef.current = 0;
    }
  }, [visibleForm]);

  /** Component Logic */
  /** handle update state async */
  const forceUpdate = (newState) => {
    /** trick for update state because push */
    const a = JSON.stringify(newState);
    setTaskList((prev) => {
      prev = JSON.parse(a);
      return prev;
    });

    /** update state Constraint */
    dispatch(
      constraintActions.setConstraintForm({
        ...constraintState.constraintForm,
        taskList: newState
      })
    );
  };

  useEffect(() => {
    selectedTask && addTaskAct();
  }, [selectedTask]);

  const addTaskAct = () => {
    setTaskList(() => []);
    const newState = [];
    if (selectedTask.length) {
      selectedTask.map((el) => {
        if (el.includes('act-')) {
          const idAct = el.replace(/act-/g, '');
          const findAct = constraintState.constraintLookAhead.lookahead.find(
            (act) => act.id === parseInt(idAct)
          );
          if (findAct) {
            const tasks = findAct.tasks;
            tasks.map((task) => {
              const find = newState.find((e) => e.id === task.id);
              if (!find) {
                newState.push(task);
              }
            });
          }
        } else if (el.includes('task-')) {
          const idTask = el.replace(/task-/g, '');
          const find = newState.find((e) => e.id === parseInt(idTask));
          if (!find) {
            let findTask;
            if (constraintState.constraintLookAhead.tasks) {
              findTask = constraintState.constraintLookAhead.tasks.find(
                (task) => task.id === parseInt(idTask)
              );
            }
            findTask && newState.push(findTask);
          }
        }
      });
    }
    forceUpdate(newState);
  };

  /** remove task from list */
  const handleDeleteTask = (taskId) => {
    const newState = taskList.filter((e) => e.id !== taskId);
    forceUpdate(newState);
    const valSelectTmp = valSelect.selected.filter(
      (el) => el !== 'task-' + taskId
    );
    setValSelect({ selected: valSelectTmp });
  };

  const constraintNameInputLengthRef = useRef(0);
  /** update state for name constraint */
  const handleNameConstraint = (e) => {
    constraintNameInputLengthRef.current = e.target.value.length;
    if (e.target.value !== '\n') {
      dispatch(
        constraintActions.setConstraintForm({
          ...constraintState.constraintForm,
          name: e.target.value
        })
      );
    }
  };

  let old = false;

  /** render */
  return old ? (
    <div className="wrapp-select-task">
      {/* render name constraint */}
      <Row>
        <Col span={12}>
          <div className="div-name-resp resp-align">
            <Input
              className="constraint-select-add-input"
              type="text"
              name="cname"
              placeholder={t('detail_constraint_label')}
              autoComplete="off"
              onChange={(e) => handleNameConstraint(e)}
              value={constraintState.constraintForm.name}
              required
            />
            <ConstraintResponsables
              t={t}
              responsableValid={responsableValid}
              toSelectResponsables={toSelectResponsables}
            />
            <label
              className={`constraint-select-lbl-error ${!nameValid ? 'constraint-select-lbl-error' : 'notVisible'}`}>
              {t('required_detail_label')}
            </label>
          </div>
          <div className="div-select-task">
            <SelectActivities
              visibleForm={visibleForm}
              t={t}
              setSelectedTask={setSelectedTask}
              valSelect={valSelect}
              setValSelect={setValSelect}
            />
          </div>
          <ConfigProvider
            renderEmpty={() => (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('no_selected_tasks_label')}
              />
            )}>
            <List
              className="list-tasks"
              dataSource={taskList}
              renderItem={(item) => (
                <List.Item className="item-list-task">
                  <i className="fas fa-circle circleTasks fa-xs"></i>
                  <div className="item-task">
                    <Tooltip placement="top" title={item.name}>
                      {item.name}
                    </Tooltip>
                  </div>
                  <i
                    className="far fa-trash-alt fa-xs deleteTask"
                    onClick={() => handleDeleteTask(item.id)}></i>
                </List.Item>
              )}
            />
          </ConfigProvider>
        </Col>
        {/* render users to select responsables */}
        <Col span={12}>
          <ConstraintDates
            t={t}
            visibleForm={visibleForm}
            initialTasks={initialTasks}
            nameValid={nameValid}
          />
        </Col>
      </Row>

      {/* render list tasks */}
    </div>
  ) : (
    <div className="constraint-select__container">
      <div className="container__roadblock-name">
        <label
          className={`${constraintState.constraintForm.type === null ? 'disabled' : ''}`}>
          {t('constraint_select_roadblock_name_label')}
        </label>
        <Input.TextArea
          className="roadblock-name__input"
          type="text"
          name="cname"
          autoComplete="off"
          onChange={(e) => handleNameConstraint(e)}
          value={constraintState.constraintForm.name}
          maxLength={4000}
          disabled={constraintState.constraintForm.type === null}
          required
        />
        <CharacterCounter
          currentCharacters={constraintNameInputLengthRef.current}
        />
      </div>
      <div className="container__others-inputs">
        <div className="inputs__responsible">
          <label
            className={`${constraintState.constraintForm.type === null ? 'disabled' : ''}`}>
            {t('constraint_select_responsible_label')}
          </label>
          <ConstraintResponsables
            t={t}
            responsableValid={responsableValid}
            toSelectResponsables={toSelectResponsables}
          />
        </div>
        <div className="inputs__date-committed">
          <label
            className={`${constraintState.constraintForm.type === null ? 'disabled' : ''}`}>
            {t('constraint_select_date_committed_label')}
          </label>
          <ConstraintDates
            t={t}
            visibleForm={visibleForm}
            initialTasks={initialTasks}
            nameValid={nameValid}
          />
        </div>
      </div>
      <div className="container__select-tasks">
        <label
          className={`${constraintState.constraintForm.type === null ? 'disabled' : ''}`}>
          {t('constraint_select_tasks_label')}
        </label>
        <SelectActivities
          visibleForm={visibleForm}
          t={t}
          setSelectedTask={setSelectedTask}
          valSelect={valSelect}
          setValSelect={setValSelect}
        />
      </div>
    </div>
  );
};

/** declaration of types for comnponent */
ConstraintSelectTask.propTypes = {
  nameValid: PropTypes.bool /** handle validation name constraint */,
  initialTasks: PropTypes.array /** handle initial tasks array */,
  visibleForm: PropTypes.bool /** handle side effects dependence  */
};

/** default props for component */
ConstraintSelectTask.defaultProps = {
  nameValid: true,
  initialTasks: [],
  visibleForm: true
};

export default ConstraintSelectTask;
