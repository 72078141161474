import React from 'react';
import { Checkbox } from 'antd';

const CheckboxSystem = ({
  theme = 'light',
  size = 'medium',
  label,
  ...props
}) => (
  <Checkbox
    className={`checkbox-design-system theme--${theme} size--${size}`}
    {...props}>
    {label}
  </Checkbox>
);

export default CheckboxSystem;
