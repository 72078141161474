import React from 'react';
import './index.css';

import ConstraintsTypes from './ConstraintsTypes';
import CNCTypes from './CNCs';
import Etiquetas from './Etiquetas';

function CardRight({
  CncTypes,
  setCncTypes,
  ContraintsTypesAll,
  setContraintsTypesAll,
  tagsAll,
  setTagsAll,
  onlyRead,
  t
}) {
  return (
    <div style={{ marginLeft: 10, marginTop: 15 }}>
      <ConstraintsTypes
        onlyRead={onlyRead}
        ContraintsTypesAll={ContraintsTypesAll}
        setContraintsTypesAll={setContraintsTypesAll}
        t={t}
      />
      <CNCTypes
        onlyRead={onlyRead}
        CncTypes={CncTypes}
        setCncTypes={setCncTypes}
        t={t}
      />
      <Etiquetas
        onlyRead={onlyRead}
        tagsAll={tagsAll}
        setTagsAll={setTagsAll}
        t={t}
      />
    </div>
  );
}

export default CardRight;
