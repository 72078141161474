const commands = {
  CHANGE_COLOR: (ganttInstance, itemType) =>
    ganttInstance.changeVisualizationOption(itemType),
  SET_ZOOM_LEVEL: (ganttInstance, zoomLevel) =>
    ganttInstance.changeScaleVisualization(zoomLevel.toLowerCase()),
  SCROLL_TO_TODAY: (ganttInstance) => ganttInstance.scrollToTodayAtChart(),
  COMMIT_WEEKLY_PLAN: (ganttInstance) => ganttInstance.commitWeeklyPlan(),
  REVIEW_WEEKLY_PLAN: (ganttInstance) => ganttInstance.reviewWeeklyPlan(),
  DATE_FORMAT: (ganttInstance, dateFormat) =>
    ganttInstance.changeCurrentDateFormat(dateFormat)
};

export const executeNewToolbarCommand = (command, value) => {
  const commandHandler = commands[command];
  const globalGanttInstance = window.ganttVisualization;

  commandHandler && commandHandler(globalGanttInstance, value);
};
