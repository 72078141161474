import React, { useState, useEffect } from 'react';
import CustomDropdownSelector from '../../../CustomDropdownSelector';
import fakeAvatar from '../../../../assets/img/fake_user.png';
import { notificationService } from '../../../../services';
import { userActions } from '../../../../redux/actions/userActions';
import { Tooltip } from 'antd';
import { RENDER_RESPONSIBLE } from '../constants';
import { withTranslation } from 'react-i18next';
import {
  findDeepGetTask,
  updateAsyncTaskGanttV2
} from '../../GanttVisualization.helper';
import {
  getSessionTokenData,
  getSignedUser
} from '../../../../utils/userUtils';

const RenderResponsible = ({
  column,
  lastLevelActivities,
  activities,
  gantt,
  t,
  onlyRead,
  task,
  responsables,
  callbackOnClickCreate,
  callbackOnClickRemove,
  callbackOnSelection,
  customMainClassName
}) => {
  const [state, setState] = useState(RENDER_RESPONSIBLE);
  const isOnlyReadElement = onlyRead || task.readonly;
  const taskFromLookahead =
    activities && gantt ? findDeepGetTask(activities, 'id', task.id) : null;
  const img_from = 'image';

  useEffect(() => {
    setState({
      ...state,
      mainObject: task,
      options: responsables
    });
  }, [task, responsables]);

  const assignSubByResponsible = (task) => {
    const sessionTokenData = getSessionTokenData();
    const availableRolesToUseThisFeature = [
      'superadmin',
      'admin',
      'projectleader',
      'planner',
      'superintendent'
    ];

    if (!availableRolesToUseThisFeature.includes(sessionTokenData.role)) return;
    let toAssignSub = null;

    if (task.responsables && task.responsables.length) {
      for (let i = 0; i < task.responsables.length; i++) {
        const currentSubUser = task.responsables[i].subcontractId;
        if (currentSubUser) {
          toAssignSub = currentSubUser;
          break;
        }
      }
    }
    if (toAssignSub) {
      task.subcontractId = toAssignSub;
      taskFromLookahead.subcontractId = task.subcontractId;
    }
  };

  const onSelection = async (payload, dictionary) => {
    const newVal = [];
    payload.map((op) => {
      newVal.push(dictionary[op]);
    });
    setState({
      ...state,
      mainObject: {
        ...state.mainObject,
        responsables: payload.map((id) => dictionary[id])
      }
    });
    const oldResponsables = task.responsables;
    task.responsables = newVal;
    assignSubByResponsible(task);
    callbackOnSelection && callbackOnSelection(payload, dictionary);
    updateAsyncTaskGanttV2(taskFromLookahead, column.name, newVal, gantt, true);
    const user = getSignedUser();
    const sector = JSON.parse(sessionStorage.getItem('currentSector'));
    task.sectorId = sector.id;
    if (column.name === 'responsables') {
      if (newVal.length > oldResponsables.length) {
        await notificationService.createConstraint({
          user,
          constraint: task,
          module: 'tasks',
          type_notification: 'assign_responsible',
          userId: newVal[newVal.length - 1].id
        });
      }
    }
  };

  const callbackRemove = (_) => {
    setState({
      ...state,
      mainObject: {
        ...state.mainObject,
        responsables: []
      }
    });
    callbackOnClickRemove && callbackOnClickRemove();
  };

  const renderSelectedOption = (_) => {
    const width = column.width;

    if (!width) return null;

    /** 16x16 image size per total of friends */
    const totalWidthResponsables = task[column.name]?.length * 16;
    let applyPlusCounter;
    let maxElPerWidth;
    let restElements = 0;
    if (totalWidthResponsables > width) {
      applyPlusCounter = true;
      maxElPerWidth = width / 16;
    }

    const adjustPlus = 2;

    return (
      <div>
        {task[column.name]?.length ? (
          <div>
            {task[column.name].map((responsable, index) => {
              if (
                applyPlusCounter &&
                maxElPerWidth &&
                index > maxElPerWidth - adjustPlus
              ) {
                restElements++;
                return;
              }

              return (
                <span key={index} className="solapate-responsable-gantt">
                  <Tooltip
                    title={`${responsable.name} ${responsable.lastname}`}>
                    {responsable[img_from] ? (
                      <img
                        className="img-responsable-lookahead"
                        src={responsable[img_from]}
                      />
                    ) : (
                      <div className="img-responsable-lookahead no-img ">
                        {responsable.name ? responsable.name[0] : ''}
                        {responsable.lastname ? responsable.lastname[0] : ''}
                      </div>
                    )}
                  </Tooltip>
                </span>
              );
            })}
            {applyPlusCounter ? (
              <span className="plus-counter-responsable-gantt">
                +{restElements}
              </span>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  };

  const renderResponsibleToSelect = (option) => {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10,
      position: 'relative',
      left: 4,
      top: 1
    };

    let toShowName = `${option.object.name} ${option.object.lastname}`;
    if (toShowName) {
      if (toShowName.length > 25) {
        toShowName = toShowName.slice(0, 25) + '...';
      }
    }

    return (
      <span style={{ display: 'flex' }}>
        <img
          alt=""
          style={imgStyle}
          width="16"
          height="16"
          src={option.object.image}
        />
        <div
          className="cut-text-and-dot"
          style={{ fontSize: 12, width: '100%' }}
          title={toShowName}>
          {toShowName}
        </div>
      </span>
    );
  };

  let isOdd = false;
  if (gantt?.oddColsConfig && task.type === 'activitytask') {
    isOdd = gantt.oddColsConfig.responsables;
  }

  if (!task.isTask && gantt) return null;
  return (
    <div
      className={`${isOdd ? 'odd-col' : 'non-odd-col'} ${customMainClassName || ''}`}>
      <CustomDropdownSelector
        {...state}
        customMainClassName={customMainClassName || ''}
        renderCustomSelectionOption={renderResponsibleToSelect}
        renderCustomPlaceholderElement={renderSelectedOption}
        callbackOnSelection={onSelection}
        callbackOnClickCreate={callbackOnClickCreate}
        callbackOnClickRemove={callbackRemove}
        onlyread={isOnlyReadElement}
      />
    </div>
  );
};

export default withTranslation()(RenderResponsible);
