function initializeGanttReadjustmentCounters() {
  const gantt = window.to_use_react_gantt;
  if (gantt.initreadjustment_validate) {
    gantt.initNonSavedTasksCounter = gantt.checkNoSavedActivities().length;
    gantt.initNonSavedDeletedTasksCounter =
      gantt.deletedUnsavedActivities.length;
    gantt.initNonUpdatedTasksCounter = gantt.checkNoUpdatedActivities().length;

    gantt.initNonSavedLinksCounter = gantt.checkNoSavedLinks().length;
    gantt.initNonSavedDeletedLinksCounter = gantt.deletedUnsavedLinks.length;
    gantt.initNonUpdatedLinksCounter = gantt.checkNoUpdatedLinks().length;

    gantt.initreadjustment_validate = false;
  }
}

export { initializeGanttReadjustmentCounters };
