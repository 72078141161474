/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class TagService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(constraintId) {
    const res = await this.http.get('tags/' + constraintId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async destroy(constraintId) {
    const res = await this.http
      .delete('tags/' + constraintId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('tags', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http.put('tags/' + data.id, data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async showByProject(projectId) {
    const res = await this.http
      .get('tags/project/' + projectId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const tagService = new TagService();
