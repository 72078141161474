import project from './project';
import actions from './actions';
import start from './start';
import duration from './duration';
import end from './end';
import progress from './progress';
import variance from './variance';

export default (gantt, options) => [
  project(gantt, options),
  actions(gantt, options),
  start(gantt, options),
  duration(gantt, options),
  end(gantt, options),
  progress(gantt, options),
  variance(gantt, options)
];
