export const PERMISSIONS = {
  MASTERPLAN: {
    GANTT: 'masterplan.gantt',
    RESOURCES: 'masterplan.resources'
  },
  ANALYTICS: {
    CNC: 'analytics.cnc',
    PCR: 'analytics.pcr',
    PPC: 'analytics.ppc',
    SCURVE: 'analytics.scurve',
    SECTORADVANCEMENT: 'analytics.sectoradvancement',
    OVERDUE: 'analytics.overdue'
  },
  CONFIGURATION: {
    COMPANY: 'configuration.company',
    PROJECT: 'configuration.project',
    USER: 'configuration.user'
  },
  LOOKAHEAD: {
    CONSTRAINTS: 'lookahead.constraints',
    RESOURCES: 'lookahead.resources',
    PLAN: 'lookahead.plan'
  },
  WEEKLYPLAN: {
    CNCS: 'weeklyplan.cncs',
    PLAN: 'weeklyplan.plan'
  }
}; //

export const PARTIAL_PERMISSION_PER_MODULE = {
  MASTERPLAN: {
    GANTT: {
      IMPORT_SCHEDULE: {
        SUPERADMIN: true,
        ADMIN: true,
        PROJECTLEADER: true,
        PLANNER: true,
        SUPERINTENDENT: false,
        LASTPLANNER: false,
        CLIENT: false,
        MANAGER: false,
        SUBTRADE: false
      },
      DOWNLOAD_SCHEDULE_TO_XML: {
        SUPERADMIN: true,
        ADMIN: true,
        PROJECTLEADER: true,
        PLANNER: true,
        SUPERINTENDENT: true,
        LASTPLANNER: false,
        CLIENT: false,
        MANAGER: false,
        SUBTRADE: false
      },
      COLUMNS: {
        SUPERADMIN: true,
        ADMIN: true,
        PROJECTLEADER: true,
        PLANNER: true,
        SUPERINTENDENT: true,
        LASTPLANNER: true,
        CLIENT: true,
        MANAGER: true,
        SUBTRADE: false
      }
    }
  }
};
