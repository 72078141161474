/* eslint-disable no-case-declarations */
import { SET_PROPERTIES } from '../actions/propertiesAction';

/**
 * Reducer function means a switch case to filter action data on the platform for general state
 * @param {*} state Real time updated through all components state (general state flux)
 * @param {*} action Action is an object with structyure { type: STRING, payload: FUNCTION }
 */
function propertiesReducer(state = {}, action) {
  switch (action.type) {
    case SET_PROPERTIES:
      return {
        ...state,
        properties: action.payload
      };
    default:
      return state;
  }
}

export default propertiesReducer;
