import React, { useEffect, useRef } from 'react';

import { sectorResourcesService } from '../../../services';

const widgetHOC = (ComponentWrapper) => (props) => {
  const resources = useRef([]);

  // Effects ==>
  useEffect(() => {
    getResources();
  }, [props.sectorId]);

  // End effects ==>

  const getResources = async () => {
    const { sectorResources } =
      await sectorResourcesService.showBySectorWithWorkers(props.sectorId);
    resources.current = sectorResources;
  };

  return (
    <ComponentWrapper
      realWeek={props.widgetreadlweekly}
      expected={props.widgetexpected}
      planning={props.widgetplanned}
      committed={props.widgetcommitment}
      resources={resources.current}
      actualPPC={props.actualPC}
      {...props}
    />
  );
};

export default widgetHOC;
