import React, { useRef, useEffect, useState } from 'react';
/** Components from ant design css framework */
import {
  Button,
  Modal,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Input,
  InputNumber,
  Progress,
  Popover,
  Icon,
  Empty,
  Popconfirm
} from 'antd';

import CustomCheckbox from '../CustomCheckbox';
import useWindowDimensions from '../../hooks/useWindowDimensions';

/** PNG to put on users without image */
import fakeAvatar from '../../assets/img/fake_user.png';

/** import library for format numbers */
import NumberFormat from 'react-number-format';

/** PNG to put on constraints by default */
import constraintIcon from '../../assets/img/iconoConstraint.png';

import { FixedSizeList as List } from 'react-window';

import EditableInput from '../EditableInput';
import { notificationService } from '../../services';
import { taskService } from '../../services';
import { activityService } from '../../services';
import { reversPathTasks } from '../../utils';
import {
  splitText,
  getRecursiveFromParentTask,
  formatMoney
} from '../../utils/lookahead-common';
import { activityModificationService } from '../../services';
import { base } from '../../services/base';
import IconComponent from '../Projects/IconSvg';
import cloneDeep from 'lodash/cloneDeep';
/** Date handler lib */
import moment from 'moment';

/** Redux implementation */
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/actions/userActions';
// import { lookaheadActions } from '../../redux/actions/lookaheadActions'
import {
  getEndDateByGantt,
  defineTitleModification,
  defineDateUnfit,
  hasConstraint,
  hasUnfitProblem,
  renderFriend,
  calculateExpected,
  calculateExpectedCost,
  calculateExpectedForWeek
} from '../../utils/lookahead-common';
import { socket } from '../../services/socket.service';
import { openNotification, helmet } from '../../utils';
import SelectSearch from 'react-select-search';

import calendarModificationIcon from '../../assets/img/activitymodification/calendar.png';
import calendarModificationIconModal from '../../assets/img/activitymodification/calendar-modal.png';
import selectResponsablesIcon from '../../assets/img/select_responsables.png';
import { clone } from '@babel/types';
import { capitalize } from 'lodash';
import { ResourcesList } from '../ResourcesList';
import RenderTag from '../GanttVisualization/dependencies/RenderTag';
import { validateMutationFromSub } from '../LookaheadActivity/LookaheadActivity.helper';
import useSubtradeUser from '../../hooks/useSubtradeUser';
import MoneySymbolString from '../MoneySymbolString';
import { getSignedUser } from '../../utils/userUtils';
const { TextArea } = Input;
const LazyElement =
  (data) =>
  ({ index, style }) => {
    return (
      <div style={style} className="single-row-bottom-bordered">
        {data[index]}
      </div>
    );
  };

export default function LookaheadOtherGroup(props) {
  const {
    groupParent,
    tableMetadata,
    updateAsyncTask,
    showDrawer,
    onClosecard,
    t,
    volatileTaskData,
    groupBy,
    permission,
    ganttAPI,
    lookaheadActions,
    setVisibleFormResource,
    setTypeResource,
    setActivityResource,
    setTaskSelected,
    lastLevelActivities,
    notifyRedux = () => {},
    setVisibleFormTags,
    toSelectTags,
    subtradeRole
  } = props;
  const [editedInput, setEditedInput] = useState(null);
  const projectState = useSelector((state) => state.projectState);
  const [modalModification, setModalModification] = useState({
    visible: false,
    title: '',
    description: '',
    data: { activity: {}, task: {} }
  });
  const [popsVisibility, setPopsVisibility] = useState({});
  const { height, width } = useWindowDimensions();
  const dispatch = useDispatch();
  const virtualizeRef = useRef();
  const [onlyRead, setOnlyRead] = useState(permission == 'V');
  const [resourceMachine, setResourceMachine] = useState([]);
  const [resourceMaterial, setResourceMaterial] = useState([]);
  const [resourceRrhh, setResourceRrhh] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [resources, setResources] = useState(lastLevelActivities?.resource);
  const navigatorLang = navigator.language || navigator.userLanguage;
  const currency_symbol = MoneySymbolString() + ' ';
  const res = props.resources;
  const resAll = props.lastLevelActivities
    ? props.lastLevelActivities.resource
    : [];
  const sectorDateFormat = projectState.allSectors.find(
    (e) => e.id == projectState.sectorSelected
  );
  const formatDate =
    sectorDateFormat && sectorDateFormat.dateFormat
      ? sectorDateFormat.dateFormat
      : null;
  useEffect(() => {
    if (res) {
      const mch = res.filter(function (rs) {
        return rs.type == 'machinery';
      });
      const mtl = res.filter(function (rs) {
        return rs.type == 'material';
      });
      const rrhh = res.filter(function (rs) {
        return rs.type == 'rrhh';
      });
      setResourceMachine(mch);
      setResourceMaterial(mtl);
      setResourceRrhh(rrhh);
    }
  }, []);

  useEffect(() => {
    const id = props.groupParent.value;
    if (props.scrollStates[id]) {
      if (virtualizeRef.current) {
        virtualizeRef.current.scrollTo(props.scrollStates[id]);
      }
    }

    return () => {
      if (virtualizeRef.current) {
        props.scrollStates[id] = virtualizeRef.current.state.scrollOffset;
      }
    };
  }, [virtualizeRef]);

  /**
   * This function allows user to hide childs for a task or activity
   * @param {*} parent Parent activity or task to hide their children (REQUIRED)
   */
  const hideChilds = (parent) => {
    if (parent.tasks) {
      props.virtualizeRef.current.resetAfterRowIndex(props.index);
    }
    parent.hide_childs = true;
    if (props.data) {
      setTimeout(() => {
        props.updateRender(props.data);
      }, 500);
    }
    updateState();
  };

  /**
   * This function allows user to show childs for a task or activity
   * @param {*} parent Parent activity or task to show their children (REQUIRED)
   */
  const showChilds = (parent) => {
    if (parent.tasks) {
      props.virtualizeRef.current.resetAfterRowIndex(props.index);
    }
    parent.hide_childs = false;
    if (props.data) {
      setTimeout(() => {
        props.updateRender(props.data);
      }, 500);
    }
    updateState();
  };

  /**
   * This function handles at virtual dom which icon should be shown (collapse or uncollapse)
   * @param {*} parent Parent activity or task to define what icon to show (REQUIRED)
   */
  const defineCollapseIcon = (parent) => {
    const children = parent.tasks || parent.children;
    if (children) {
      if (children.length) {
        if (parent.hide_childs) {
          return (
            <i
              onClick={() => showChilds(parent)}
              className="fa fa-angle-down show-childs-btn"
              aria-hidden="true"
            />
          );
        } else {
          return (
            <i
              onClick={() => hideChilds(parent)}
              className="fa fa-minus hide-childs-btn"
              aria-hidden="true"
            />
          );
        }
      }
    }
  };

  const defineParentColumnContent = (column, parent) => {
    if (column.name == 'name') {
      if (parent.name || parent.label) {
        return (
          <div>
            {parent.tasks.length && !onlyRead ? (
              <CustomCheckbox
                inverted
                onClick={() => {
                  props.massiveSelectionHandler(parent);
                  // updateState()
                }}
                active={parent.active}
              />
            ) : null}
            {props.groupBy && props.groupBy.criteria === 'responsables' ? (
              <span
                title={parent.value}
                style={{ position: 'relative', top: -2 }}>
                <img
                  width={16}
                  style={{ borderRadius: 20 }}
                  src={parent.img || fakeAvatar}
                />
                &nbsp;
              </span>
            ) : (
              defineCollapseIcon(parent)
            )}
            <span>{parent[column.name] || parent.label}</span>
          </div>
        );
      }
      return parent.label;
    }
  };

  const renderParentColumn = (column, parent, index) => {
    if (column.visible) {
      return (
        <Col
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineParentColumnContent(column, parent)}
        </Col>
      );
    }
  };

  const renderGroupParentHeader = (parent = groupParent) => {
    return (
      <Row className="custom-header-top-list">
        {tableMetadata.map((eachColumn, index) => {
          return renderParentColumn(eachColumn, parent, index);
        })}
      </Row>
    );
  };

  /**
   * This function opens a component to add constraints
   * @param {*} task task to add constraint
   */
  const openAddConstraint = (task) => {
    props.handleAddConstraint(task);
  };
  /**
   * Clone deep of a new state to set and render view
   * @param {*} newState new state value (inmutability is broken)
   */
  const updateState = (newState = popsVisibility) => {
    setPopsVisibility({ ...popsVisibility });
    dispatch(lookaheadActions.notifyLookaheadUpdate());
  };

  const createActivityModificationRequest = async (task, activity) => {
    const loggedUser = getSignedUser();
    const projectId = projectState.projectSelected;
    const sectorId = projectState.sectorSelected;
    const link = base.front + 'masterplan/' + sectorId + '/' + projectId;

    const activityModification = {
      type: task.unfitType,
      state: 'waiting',
      description: modalModification.description,
      activityId: activity.id,
      userRequestId: loggedUser.id,
      startOriginal: activity.start_date,
      endOriginal: activity.end_date,
      user: loggedUser,
      projectId,
      sectorId,
      link
    };

    if (task.unfitType == 'start') {
      activityModification.startNew = task.start_date;
      activityModification.endNew = activity.end_date;
    } else if (task.unfitType == 'end') {
      activityModification.startNew = activity.start_date;
      activityModification.endNew = task.end_date;
    } else if (task.unfitType == 'both') {
      activityModification.startNew = task.start_date;
      activityModification.endNew = task.end_date;
    }

    const res = await activityModificationService.create(activityModification);
    dispatch(userActions.setUserUpdate());
    if (res) {
      openNotification({
        type: 'success',
        title: t('success_request_notify')
      });
      setModalModification({
        ...modalModification,
        visible: false
      });
    }
  };

  /**
   * This function handles the request acitivity modification
   * @param {*} task task which has problems of unfit dates
   * @param {*} activity activity to request modification
   */
  const handleActivityModificationRequest = (task, activity) => {
    setModalModification({
      ...modalModification,
      visible: true,
      title: t('lookahead_planification_modification_request'),
      data: {
        task,
        activity
      }
    });
  };

  /**
   * This function deals with pop visibility hash map
   * @param {boolean} visible Value to set the pop
   * @param {*} id Unique state eval to use at virtual DOM to show popup or hide it (from antd component)
   */
  const handlePopVisibility = (visible, id) => {
    setPopsVisibility({
      ...popsVisibility,
      [id]: visible
    });
    dispatch(lookaheadActions.notifyLookaheadUpdate());
  };

  const filterBufferSaveAndUpdateState = async (task, notif = false) => {
    const copyTask = cloneDeep(task);
    delete copyTask.activityObject;
    updateAsyncTask(copyTask);
    if (notif) {
      const user = getSignedUser();
      copyTask.sectorId = projectState.sectorSelected;
      for (let i = 0; i < copyTask.responsables.length; i++) {
        await notificationService.createConstraint({
          user,
          constraint: copyTask,
          module: 'tasks',
          type_notification: 'assign_responsible',
          userId: copyTask.responsables[i].id
        });
      }
      dispatch(userActions.setUserUpdate());
    }
    updateState();
  };

  const renderResourcesList = (objectData) => {
    // notifyRedux
    let notifyLocal = () => {};
    if (objectData.type === 'material') {
      notifyLocal = notifyRedux;
    }
    return (
      <ResourcesList
        setTaskSelected={setTaskSelected}
        setVisibleFormResource={setVisibleFormResource}
        task={objectData.task}
        setFilterText={setFilterText}
        filterText={filterText}
        activity={objectData.activity}
        setTypeResource={setTypeResource}
        updateAsyncTask={updateAsyncTask}
        uniqueId={objectData.uniqueId}
        handlePopVisibility={handlePopVisibility}
        popsVisibility={popsVisibility}
        setPopsVisibility={setPopsVisibility}
        setResources={setResources}
        resources={resources}
        column={objectData.column}
        lastLevelActivities={props.lastLevelActivities}
        setActivityResource={setActivityResource}
        t={t}
        showBtnCreate={false}
        resourceData={objectData.data}
        typeResource={objectData.type}
        notifyRedux={notifyLocal}
      />
    );
  };

  const defineTaskColumnContent = (column, task, parent, index) => {
    const isOnlyReadElement =
      onlyRead ||
      (task.editablePermissionACP && !task.editablePermissionACP.editable);
    const newCommonObject = {
      column,
      activities: null,
      gantt: null,
      t,
      onlyRead: isOnlyReadElement
    };
    const released = task.constraints.filter(
      (constraint) => constraint.status == 'released'
    );
    const isTaskRestricted =
      task.constraints.length && task.constraints.length != released.length;

    let tagResource = '';
    let materialObject = null;
    let resourceMaterial_taks = null;
    let resourceRhh_taks = null;
    let resourceMachinery_taks = null;
    if (resources) {
      resourceMaterial_taks =
        resources && resources.filter((rs) => rs.type == 'material');
      resourceRhh_taks =
        resources && resources.filter((rs) => rs.type == 'rrhh');
      resourceMachinery_taks =
        resources && resources.filter((rs) => rs.type == 'machinery');
      materialObject = resourceMaterial_taks.filter(
        (el) => el.id == task.materialId
      );
      tagResource = materialObject[0] ? materialObject[0].material_label : null;
    }

    if (column.name === 'tags') {
      return (
        <RenderTag
          {...newCommonObject}
          customMainClassName="render-tag-at-table"
          lastLevelActivities={lastLevelActivities}
          task={task}
          tags={toSelectTags}
          callbackOnSelection={(val, dictionary) => {
            task[column.name] = [];
            val.map((op) => {
              task[column.name].push(dictionary[op]);
            });
            updateAsyncTask(task);
            task.sectorId = projectState.sectorSelected;
            dispatch(userActions.setUserUpdate());
            updateState();
          }}
          callbackOnClickRemove={() => {
            console.log('remove tag from table');
          }}
          callbackOnClickCreate={() => {
            setVisibleFormTags(true);
          }}
        />
      );
    }

    if (column.data_type == 'string') {
      if (column.name == 'name') {
        const released = task.constraints.filter(
          (constraint) => constraint.status == 'released'
        );
        let option = props.tableMetadata.filter(
          (el) => el.name == 'lean_status'
        );
        option = option[0];
        let background;
        if (option) {
          background = option.from_values.filter(
            (el) => el.value == task.lean_status
          );
          background = background[0];
        }
        if (
          task.constraints.length &&
          task.constraints.length != released.length
        ) {
          background = {
            color: '#E50101'
          };
        }

        return (
          <span
            className="vertical-center"
            onClick={() =>
              !isOnlyReadElement && showDrawer(task, props.lastLvlActivity)
            }>
            {isOnlyReadElement ? null : (
              <CustomCheckbox
                onClick={() => {
                  props.massiveSelectionHandler(task);
                }}
                active={task.active}
              />
            )}
            <div
              className="lineStatus lookaheadLineStatus"
              style={{
                background: background ? background.color : null,
                marginRight: 6
              }}>
              &nbsp;
            </div>
            <EditableInput
              t={t}
              activity={props.lastLvlActivity}
              disabled={isOnlyReadElement}
              service={filterBufferSaveAndUpdateState}
              onEdit={setEditedInput}
              isEditing={editedInput}
              renderEditable={(
                column,
                index,
                task,
                value,
                setValue,
                updateParentData = null,
                handleEsc = null
              ) => {
                return (
                  <Input
                    onKeyDown={handleEsc}
                    onPressEnter={updateParentData}
                    key={index}
                    id={column.name + task.id}
                    size="small"
                    value={value}
                    className="custom-input-planification"
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => setValue(e.target.value)}
                  />
                );
              }}
              updateState={updateState}
              index={index}
              column={column}
              task={task}
              showDrawer={showDrawer}
              featureOpenCard={true}
              onClosecard={onClosecard}
              viewCaller={'tableView'}
            />
            {/* <span style={{ float: 'right' }}>
                            { hasConstraint(task, openAddConstraint, isOnlyReadElement, t) }
                        </span> */}
          </span>
        );
      } else if (column.name == 'taskRoute') {
        return (
          <div
            className="vertical-center"
            style={{ display: 'flex', marginLeft: '1rem', paddingTop: '8px' }}>
            {splitText(reversPathTasks(task.taskRoute), {
              ...column,
              view: 'planninggroup'
            })}
          </div>
        );
      } else if (column.name == 'actions') {
        return (
          <span className="vertical-center actions">
            {isOnlyReadElement ? null : (
              <span>
                <span className="constraint-add-icon">
                  {hasConstraint(
                    task,
                    openAddConstraint,
                    isOnlyReadElement,
                    t,
                    false
                  )}
                </span>
              </span>
            )}
          </span>
        );
      } else if (column.name == 'roadblocks') {
        if (task.constraints?.length) {
          return (
            <span className="vertical-center roadblocks">
              <span>
                {hasConstraint(task, openAddConstraint, !isOnlyReadElement, t)}
              </span>
            </span>
          );
        } else {
          return <span className="vertical-center roadblocks">-</span>;
        }
      }
    } else if (column.data_type == 'number') {
      let onChangeFunction;
      let expWeek;
      if (props.dateRange) {
        expWeek = calculateExpectedForWeek(
          task,
          ganttAPI,
          task.activityObject.calendarId,
          null,
          props.dateRange
        );
        task.expectedweek = expWeek;
      }
      if (column.name == 'progress') {
        let progressColor = '#52c41a';
        if (task.progress.toFixed(2) == '100.00') {
          progressColor = '#34AF00';
          task.Advancement = false;
          task.Overdue = false;
        } else if (task.progress > task.expected) {
          progressColor = '#2C3421';
          task.Advancement = true;
          task.Overdue = false;
        } else if (task.progress < task.expected) {
          progressColor = '#E50101';
          task.Overdue = true;
          task.Advancement = false;
        } else {
          progressColor = '#52c41a';
          task.Overdue = false;
          task.Advancement = false;
        }
        const percentVal = task[column.name].toFixed
          ? task[column.name].toFixed(2)
          : task[column.name];
        return (
          <span className="vertical-center">
            <Progress
              strokeColor={{ '0%': progressColor, '100%': progressColor }}
              className="progress-custom-props"
              percent={percentVal}
              format={
                percentVal == 100
                  ? false
                  : (percent) =>
                      t('lang') === 'en'
                        ? percent + '%'
                        : `${percent}`.replace('.', ',') + '%'
              }
            />
          </span>
        );
      } else if (column.name == 'duration') {
        return (
          <span className="vertical-center">
            {t('lang') === 'en'
              ? formatMoney(task[column.name], '', 2, '.', ',')
              : formatMoney(task[column.name], '', 2, ',', '.')}
          </span>
        );
      } else if (column.name == 'spend_hm') {
        task.spend_hm = (task.progress / 100) * task.total_hm;
        return (
          <span className="vertical-center">
            {t('lang') === 'en'
              ? formatMoney(task.spend_hm, '', 2, '.', ',')
              : formatMoney(task.spend_hm, '', 2, ',', '.')}
          </span>
        );
      } else if (column.name == 'remaining_quantity') {
        const remainQuant = task.total_quantity - task.actual_quantity;
        if (task.remaining_quantity != remainQuant) {
          task.remaining_quantity = remainQuant;
          updateAsyncTask(task);
        }
        return (
          <span className="vertical-center">
            {t('lang') === 'en'
              ? formatMoney(remainQuant, '', 2, '.', ',')
              : formatMoney(remainQuant, '', 2, ',', '.')}
          </span>
        );
      } else if (column.name == 'actual_quantity') {
        const actualQuanti = (task.progress / 100) * task.total_quantity;
        if (task.actual_quantity != actualQuanti) {
          task.actual_quantity = actualQuanti;
          updateAsyncTask(task);
        }
        return (
          <span className="vertical-center">
            {t('lang') === 'en'
              ? formatMoney(actualQuanti, '', 2, '.', ',')
              : formatMoney(actualQuanti, '', 2, ',', '.')}
          </span>
        );
      } else if (column.name == 'quantity_parcial') {
        if (task && volatileTaskData && volatileTaskData.length) {
          if (volatileTaskData[task.id]) {
            task.quantity_parcial = volatileTaskData[task.id].quantity_parcial;
            return (
              <span
                className="vertical-center"
                style={{ backgroundColor: 'transparent' }}>
                {Number.isInteger(task[column.name]) ? (
                  <NumberFormat
                    value={task[column.name]}
                    thousandSeparator={t('ĺang') === 'en' ? ',' : '.'}
                    decimalSeparator={false}
                    displayType={'text'}
                    prefix={
                      ['expected_cost'].includes(column.name)
                        ? currency_symbol
                        : null
                    }
                  />
                ) : task[column.name] ? (
                  <NumberFormat
                    value={parseFloat(task[column.name]).toFixed(2)}
                    thousandSeparator={t('ĺang') === 'en' ? ',' : '.'}
                    decimalSeparator={false}
                    displayType={'text'}
                    prefix={
                      ['expected_cost'].includes(column.name)
                        ? currency_symbol
                        : null
                    }
                  />
                ) : (
                  '0.00'
                )}
              </span>
            );
          }
        }
      } else if (column.name == 'commitment_percentaje') {
        if (task && volatileTaskData && volatileTaskData.length) {
          if (volatileTaskData[task.id]) {
            task.commitment_percentaje =
              volatileTaskData[task.id].commitment_percentaje;
            return (
              <span
                className="vertical-center"
                style={{ backgroundColor: 'transparent' }}>
                {Number.isInteger(task[column.name]) ? (
                  <NumberFormat
                    value={task[column.name]}
                    thousandSeparator={t('ĺang') === 'en' ? ',' : '.'}
                    decimalSeparator={t('ĺang') === 'en' ? '.' : ','}
                    decimalScale={2}
                    displayType={'text'}
                    prefix={
                      ['expected_cost'].includes(column.name)
                        ? currency_symbol
                        : null
                    }
                  />
                ) : task[column.name] ? (
                  <NumberFormat
                    value={parseFloat(task[column.name]).toFixed(2)}
                    thousandSeparator={t('ĺang') === 'en' ? ',' : '.'}
                    decimalSeparator={t('ĺang') === 'en' ? '.' : ','}
                    decimalScale={2}
                    displayType={'text'}
                    prefix={
                      ['expected_cost'].includes(column.name)
                        ? currency_symbol
                        : null
                    }
                  />
                ) : (
                  '0.00'
                )}
              </span>
            );
          }
        }
      } else if (column.name == 'expectedweek') {
        return (
          <span className="vertical-center">
            {t('lang') === 'en'
              ? expWeek.toFixed(2) + '%'
              : expWeek.toFixed(2).replace('.', ',') + '%'}
          </span>
        );
      } else if (column.name == 'won_hh') {
        const wonHH = (task.progress / 100) * task.hhWorkTime;
        if (task.won_hh != wonHH) {
          task.won_hh = wonHH;
          updateAsyncTask(task);
        }

        return (
          <span className="vertical-center">
            {t('lang') === 'en'
              ? formatMoney(wonHH, '', 2, '.', ',')
              : formatMoney(wonHH, '', 2, ',', '.')}
          </span>
        );
      } else if (column.name == 'expected') {
        task.expected = calculateExpected(
          task,
          ganttAPI,
          task.activityObject.calendarId
        );
        return (
          <span className="vertical-center">
            {t('lang') === 'en'
              ? task.expected.toFixed(2) + '%'
              : task.expected.toFixed(2).replace('.', ',') + '%'}
          </span>
        );
      } else if (column.name == 'expected_cost') {
        task.expected_cost = calculateExpectedCost(
          task,
          ganttAPI,
          task.activityObject.calendarId
        );
        return (
          <span className="vertical-center">
            <NumberFormat
              value={parseFloat(task.expected_cost)}
              decimalScale={2}
              thousandSeparator={navigatorLang.includes('en') ? ',' : '.'}
              decimalSeparator={navigatorLang.includes('en') ? '.' : ','}
              displayType={'text'}
              prefix={
                ['expected_cost'].includes(column.name) ? currency_symbol : null
              }
            />
          </span>
        );
      } else if (column.name == 'ponderator') {
        return (
          <span className="vertical-center">
            {t('lang') === 'en'
              ? formatMoney(parseFloat(task[column.name]), '', 2, '.', ',')
              : formatMoney(parseFloat(task[column.name]), '', 2, ',', '.')}
          </span>
        );
      } else if (column.name == 'cost') {
        return (
          <span className="vertical-center">
            <NumberFormat
              value={parseFloat(task[column.name])}
              decimalScale={2}
              thousandSeparator={navigatorLang.includes('en') ? ',' : '.'}
              decimalSeparator={navigatorLang.includes('en') ? '.' : ','}
              displayType={'text'}
              prefix={currency_symbol}
            />
          </span>
        );
      } else {
        return (
          <span className="vertical-center">
            {t('lang') === 'en'
              ? formatMoney(
                  parseFloat(task[column.name]),
                  '',
                  column.name === 'real_endowment' ||
                    column.name === 'plan_endowment'
                    ? 0
                    : 2,
                  '.',
                  ','
                )
              : formatMoney(
                  parseFloat(task[column.name]),
                  '',
                  column.name === 'real_endowment' ||
                    column.name === 'plan_endowment'
                    ? 0
                    : 2,
                  ',',
                  '.'
                )}
          </span>
        );
      }
    } else if (column.data_type == 'date') {
      /**
       * This code below deals with end date bug,
       * with gantt plugin end date were adding 1 day.
       * In customGanttPlugin at line 41, software substract 1 day, but at BD stills showing it at 24 hrs
       * Here even when data comes as example saturday 24 at 24 hrs,
       * is interpreted as sunday 25.
       * Whatever, here the text or object is fitted to correct date (JUST VISUALLY)
       */

      if (column.name == 'end_date') {
        let dateObject;
        if (task[column.name].split) {
          dateObject = new Date(task[column.name]);
        } else {
          dateObject = cloneDeep(task[column.name]);
        }

        dateObject.setHours(0);
        return (
          <span className="vertical-center">
            {moment(dateObject.toString()).format(formatDate)}
            {hasUnfitProblem(
              task,
              task.activityObject,
              updateAsyncTask,
              handleActivityModificationRequest,
              isOnlyReadElement,
              t
            )}
          </span>
        );
      } else if (column.name == 'start_date') {
        let classDate = 'custom-date-picker-planification';
        if (task.active) {
          /** #12121210 */
          classDate += ' massive';
        }
        return (
          <span className="vertical-center-dates">
            <DatePicker
              disabled={isOnlyReadElement}
              allowClear={false}
              id={column.name + task.id}
              className={classDate}
              defaultValue={moment(task[column.name])}
              format={formatDate}
              onChange={(date, dateString) => {
                task[column.name] = moment(dateString, formatDate).format(
                  'YYYY/MM/DD'
                );
                getEndDateByGantt(task, task.activityObject);
                /** Apply changes */
                filterBufferSaveAndUpdateState(task);
              }}
            />
          </span>
        );
      }
    } else if (column.data_type == 'array/images') {
      const uniqueId = task.id + column.name;
      const friends = [];
      const selected = [];
      const dictionary = {};

      task[column.name].map((res) => {
        selected.push(res[column.el_to_extract_from]);
      });

      props[column.from_values].map((user) => {
        if (user.is_active) {
          friends.push({
            name:
              user[column.el_to_label_from[0]] +
              ' ' +
              user[column.el_to_label_from[1]],
            value: user[column.el_to_extract_from],
            photo: user[column.img_from] || fakeAvatar,
            object: user
          });
          dictionary[user[column.el_to_extract_from]] = user;
        }
      });

      if (isOnlyReadElement) {
        return (
          <div
            className="custom-multi-select-pop"
            style={{
              width: column.width ? column.width : '100%',
              height: '100%',
              marginTop: 0,
              border: 0
            }}>
            {task[column.name].length ? (
              task[column.name].map((responsable, index) => {
                return (
                  <span className="vertical-center" key={index}>
                    <Tooltip
                      title={`${responsable.name} ${responsable.lastname}`}>
                      {responsable[column.img_from] ? (
                        <img
                          className="img-responsable-lookahead"
                          src={responsable[column.img_from]}
                        />
                      ) : (
                        <div className="img-responsable-lookahead no-img">
                          {responsable.name ? responsable.name[0] : ''}
                          {responsable.lastname ? responsable.lastname[0] : ''}
                        </div>
                      )}
                    </Tooltip>
                  </span>
                );
              })
            ) : (
              <span className="vertical-center">
                <Tooltip
                  title={
                    isOnlyReadElement
                      ? t('non_allow_responsable_tooltip')
                      : t('select_responsable_tooltip')
                  }
                  style={isOnlyReadElement ? {} : {}}>
                  <img src={selectResponsablesIcon} width={15} />
                </Tooltip>
              </span>
            )}
          </div>
        );
      }

      return (
        <div
          className="custom-multi-select-pop"
          style={{
            width: column.width ? column.width : '100%',
            height: '100%',
            marginTop: 0,
            border: 0
          }}>
          <Popover
            overlayClassName="container-image-custom"
            className="popoverPriority"
            content={
              <SelectSearch
                className="select-search select-search--multiple"
                options={friends}
                value={selected}
                renderOption={renderFriend}
                onChange={(val) => {
                  task[column.name] = [];
                  val.map((op) => {
                    task[column.name].push(dictionary[op]);
                  });
                  filterBufferSaveAndUpdateState(task, true);
                }}
                multiple
                search
                placeholder={t('search_responsable_placeholder')}
              />
            }
            trigger="click">
            {task[column.name].length ? (
              task[column.name].map((responsable, index) => {
                return (
                  <span className="vertical-center" key={index}>
                    <Tooltip
                      title={`${responsable.name} ${responsable.lastname}`}>
                      {responsable[column.img_from] ? (
                        <img
                          className="img-responsable-lookahead"
                          src={responsable[column.img_from]}
                        />
                      ) : (
                        <div className="img-responsable-lookahead no-img">
                          {responsable.name ? responsable.name[0] : ''}
                          {responsable.lastname ? responsable.lastname[0] : ''}
                        </div>
                      )}
                    </Tooltip>
                  </span>
                );
              })
            ) : (
              <span className="vertical-center">
                <Tooltip title={t('select_responsable_tooltip')}>
                  <img src={selectResponsablesIcon} width={15} />
                </Tooltip>
              </span>
            )}
          </Popover>
        </div>
      );
    } else if (column.data_type == 'array/string') {
      if (column.name == 'machineId') {
        const uniqueId = task.id + column.name;
        const leanObject = resourceMachine.filter(
          (el) => el.id == task[column.name]
        );
        if (isOnlyReadElement) {
          return (
            <div
              style={{
                // backgroundColor: background,
                height: '100%'
              }}>
              <span className="vertical-center">
                {leanObject.length > 0
                  ? leanObject[0].name
                  : t('not_assigned_general')}
              </span>
            </div>
          );
        }
        return renderResourcesList({
          type: 'machinery',
          data: resourceMachinery_taks,
          task,
          activity: parent,
          uniqueId,
          column
        });
      } else if (column.name == 'materialId') {
        const uniqueId = task.id + column.name;
        const leanObject = resAll.filter((el) => el.id == task[column.name]);
        const background = 'white';

        if (isOnlyReadElement) {
          return (
            <span className="vertical-center">
              {leanObject.length > 0
                ? leanObject[0].name
                : t('not_assigned_general')}
            </span>
          );
        }
        return renderResourcesList({
          type: 'material',
          data: resourceMaterial_taks,
          task,
          activity: parent,
          uniqueId,
          column
        });
      } else if (column.name == 'specialtyId') {
        const uniqueId = task.id + column.name;
        const leanObject = resourceRrhh.filter(
          (el) => el.id == task[column.name]
        );
        const background = 'white';

        if (isOnlyReadElement) {
          return (
            <div
              style={{
                // backgroundColor: background,
                height: '100%'
              }}>
              <span className="vertical-center">
                {leanObject.length > 0
                  ? leanObject[0].name
                  : t('not_assigned_general')}
              </span>
            </div>
          );
        }
        return renderResourcesList({
          type: 'rrhh',
          data: resourceRhh_taks,
          task,
          activity: parent,
          uniqueId,
          column
        });
      } else if (column.name === 'subcontractId') {
        const uniqueId = task.id + column.name;
        const statusObject =
          props.subContracts &&
          props.subContracts.find((e) => e.id === task[column.name]);
        const colorObject = statusObject ? statusObject.color : null;

        if (
          isOnlyReadElement ||
          (subtradeRole && subtradeRole.isSubtradeRole)
        ) {
          return (
            <div style={{ height: '100%' }}>
              <span className="vertical-center">
                {statusObject ? (
                  <span
                    className="subcontract-border"
                    style={{
                      border: '1px solid ' + colorObject,
                      color: colorObject,
                      padding: 5,
                      borderRadius: 5
                    }}>
                    <IconComponent
                      style={{ marginRight: 6, position: 'relative', top: 2 }}
                      data={helmet}
                      width={15}
                      fill={colorObject}
                      className="icon-subcontract"
                    />
                    {statusObject.name}
                  </span>
                ) : (
                  <span className="">-</span>
                )}
              </span>
            </div>
          );
        }

        return (
          <div
            className="customPlanificationBlock priorityFlag"
            style={{ height: '100%', marginTop: 0, border: 0 }}>
            <Tooltip placement="top">
              <Popover
                overlayClassName="popover-subcontract"
                className="popover-subcontract"
                placement="bottom"
                content={
                  <div
                    className="subcontracts-options"
                    style={{ maxHeight: 600, overflow: 'auto' }}>
                    {props.subContracts.map((option, index) => {
                      return (
                        <span
                          className="item"
                          key={index}
                          onClick={() => {
                            task[column.name] = option.id;

                            if (
                              subtradeRole.subtrade &&
                              JSON.stringify(option.id) !==
                                JSON.stringify(subtradeRole.subtrade.id)
                            ) {
                              validateMutationFromSub(subtradeRole, task);
                            }
                            updateAsyncTask(task);
                            handlePopVisibility(false, uniqueId);
                            updateState();
                          }}>
                          <span style={{ marginLeft: 5 }}>
                            <span
                              className="subcontract-options-border"
                              style={{
                                border: '1px solid ' + option.color,
                                color: option.color
                              }}>
                              <IconComponent
                                data={helmet}
                                width={15}
                                fill={option.color}
                                className="icon-options-subcontract"
                              />
                              {capitalize(option.name)}
                            </span>
                          </span>
                        </span>
                      );
                    })}
                    <span
                      className="add-subcontract-inline"
                      onClick={() => props.setVisibleFormSubcontract(true)}>
                      + Crear
                    </span>
                  </div>
                }
                trigger="click">
                <div style={{ height: '100%' }}>
                  <span className="vertical-center">
                    {statusObject ? (
                      <span
                        className="subcontract-border"
                        style={{
                          border: '1px solid ' + colorObject,
                          color: colorObject,
                          padding: 5,
                          borderRadius: 5
                        }}>
                        <IconComponent
                          style={{
                            marginRight: 6,
                            position: 'relative',
                            top: 2
                          }}
                          data={helmet}
                          width={15}
                          fill={colorObject}
                          className="icon-subcontract"
                        />
                        {statusObject.name}
                      </span>
                    ) : (
                      <span className="">-</span>
                    )}
                  </span>
                </div>
              </Popover>
            </Tooltip>
          </div>
        );
      } else if (column.name == 'lean_status') {
        if (isTaskRestricted) {
          task.restricted = true;
          return (
            <div
              style={{
                backgroundColor: '#E50101',
                color: 'white',
                height: '100%'
              }}>
              <span className="vertical-center">{t('restricted_label')}</span>
            </div>
          );
        } else {
          const uniqueId = task.id + column.name;
          const leanObject = column.from_values.filter(
            (el) => el.value == task[column.name]
          );
          const background = leanObject[0].color;
          return (
            <div
              style={{
                backgroundColor: background,
                color: 'white',
                height: '100%'
              }}>
              <span className="vertical-center">{leanObject[0].label}</span>
            </div>
          );
          // return (
          //     <div
          //         className="customPlanificationBlock priorityFlag"
          //         style={{ height: '100%', marginTop: 0, border: 0 }}>
          //         {
          //             task[column.name] !== 'Committed'
          //                 ? (
          //                     <Tooltip
          //                         placement="bottom">
          //                         <Popover
          //                             overlayClassName="popoverPriority"
          //                             className="popoverPriority"
          //                             content={
          //                                 (
          //                                     <div className="priorityOptions">
          //                                         {
          //                                             column.from_values.map((option, index) => {
          //                                                 if (option.value != 'Committed' && option.value != 'Restricted') {
          //                                                     return (
          //                                                         <span className='item' key={index} onClick={() => {
          //                                                             task[column.name] = option.value
          //                                                             filterBufferSaveAndUpdateState(task)
          //                                                         }}>
          //                                                             <i
          //                                                                 className='fas fa-circle'
          //                                                                 style={{ fontSize: 11, color: option.color, position: 'relative', top: -1 }}/>
          //                                                             <span style={{ marginLeft: 5 }}>
          //                                                                 { capitalize(option.label) }
          //                                                             </span>
          //                                                         </span>
          //                                                     )
          //                                                 }
          //                                             })
          //                                         }
          //                                     </div>
          //                                 )
          //                             }
          //                             trigger="click">
          //                             <div style={{ backgroundColor: background, color: 'white', height: '100%' }}>
          //                                 <span className="vertical-center">
          //                                     { leanObject[0].label }
          //                                 </span>
          //                             </div>
          //                         </Popover>
          //                     </Tooltip>
          //                 )
          //                 : (
          //                     <div style={{ backgroundColor: background, color: 'white', height: '100%' }}>
          //                         <span className="vertical-center">
          //                             { leanObject[0].label }
          //                         </span>
          //                     </div>
          //                 )
          //         }
          //     </div>
          // )
        }
      }
    } else if (column.data_type == 'array/icon') {
      const iconToShow = column.from_values.filter(
        (f) => f.value == task[column.name]
      );
      const uniqueId = task.id + column.name;
      const tooltipDescription = column.from_values.filter(
        (option) => option.value === task[column.name]
      );
      const tooltipTitle = tooltipDescription[0].label;
      const defineIcon = (option) => {
        if (column.name == 'priority') {
          return <img src={option.icon} width={12} />;
        } else {
          return (
            <i
              className={option.icon}
              style={{
                fontSize: 11,
                color: option.color,
                position: 'relative',
                top: -2
              }}
            />
          );
        }
      };

      if (isOnlyReadElement) {
        return (
          <span className="vertical-center">{defineIcon(iconToShow[0])}</span>
        );
      }
      return (
        <div
          className="customStr priorityFlag custom-select-planification-position"
          style={{ height: '100%', marginTop: 0, border: 0 }}>
          <Tooltip title={capitalize(tooltipTitle)} placement="bottom">
            <Popover
              overlayClassName="popoverPriority"
              className="popoverPriority"
              content={
                <div className="priorityOptions">
                  {column.from_values.map((option, index) => {
                    return (
                      <span
                        className="item"
                        key={index}
                        onClick={() => {
                          task[column.name] = option.value;
                          filterBufferSaveAndUpdateState(task);
                        }}>
                        {defineIcon(option)}
                        <span style={{ marginLeft: 5 }}>
                          {capitalize(option.label)}
                        </span>
                      </span>
                    );
                  })}
                </div>
              }
              trigger="click">
              <span className="vertical-center">
                {defineIcon(iconToShow[0])}
              </span>
            </Popover>
          </Tooltip>
        </div>
      );
    }
  };

  const renderTaskColumn = (column, task, index, parent) => {
    if (column.visible) {
      return (
        <Col
          className={
            column.name == 'name'
              ? 'fit-name-superposition'
              : 'single-task-column-style'
          }
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            paddingLeft: column.name == 'name' ? 15 : 0,
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineTaskColumnContent(column, task, parent, index)}
        </Col>
      );
    }
  };

  const renderChildTreeTasks = (finalArray, parent) => {
    if (props.fromWeeklyPlan) {
      parent.tasks.map((task) => {
        if (!task.hide && task.showWeeklyPlan) {
          if (props.resizing) {
            finalArray.push(
              <Row key={task.id} className="fit-at-middle-row"></Row>
            );
          } else {
            finalArray.push(
              <Row key={task.id} className="fit-at-middle-row">
                {props.tableMetadata.map((eachColumn, index) => {
                  return renderTaskColumn(eachColumn, task, index, parent);
                })}
              </Row>
            );
          }
        }
      });
    } else {
      parent.tasks.map((task) => {
        if (!task.hide) {
          finalArray.push(
            <Row
              style={{ backgroundColor: task.active ? '#12121210' : null }}
              key={task.id}
              className="fit-at-middle-row">
              {tableMetadata.map((eachColumn, index) => {
                return renderTaskColumn(eachColumn, task, index, parent);
              })}
            </Row>
          );
        }
      });
    }
  };

  const [renderedChildsCounter, setRenderedChildsCounter] = useState(0);
  useEffect(() => {
    props.virtualizeRef.current.resetAfterRowIndex(props.index);
  }, [renderedChildsCounter]);

  /** This function is used for show tasks quantity on virtual dom who renders them and resize height */
  const defineVirtualizedHeight = (taskCounter) => {
    const tempHeight = taskCounter * 40;
    const maxHeight = height < 700 ? height * 0.45 : height * 0.6;
    if (tempHeight < maxHeight) {
      return tempHeight;
    }
    return maxHeight;
  };

  const renderTasks = (parent = groupParent) => {
    const childRended = [];
    renderChildTreeTasks(childRended, parent);

    parent.childRended = childRended.length;

    if (renderedChildsCounter != childRended.length) {
      setRenderedChildsCounter(childRended.length);
    }
    /**
     * To Optimize render of this child list an option is
     * https://react-window.now.sh/#/examples/list/variable-size
     * with childRended, we can create a virtual list to handle performance
     */
    if (parent.hide_childs) {
      return null;
    } else if (childRended.length) {
      return (
        <List
          ref={virtualizeRef}
          height={defineVirtualizedHeight(childRended.length)}
          itemCount={childRended.length}
          itemSize={40}
          width={'100%'}>
          {LazyElement(childRended)}
        </List>
      );
    } else {
      if (props.isFilterdData) {
        return;
      }

      return (
        <div style={{ marginTop: 20 }}>
          <Empty
            style={{ margin: 0 }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span></span>}
          />
        </div>
      );
    }
  };

  /**
   * Render
   */

  if (!formatDate) return <div></div>;

  return (
    <Row key={props.index}>
      <Col
        className="tableConstraints"
        style={{ marginTop: 10, marginBottom: 40 }}>
        {renderGroupParentHeader(groupParent)}
        {renderTasks(groupParent)}
      </Col>

      {/* Modal for dealing with activity modification request */}
      <Modal
        wrapClassName="activity-modification-style"
        title={modalModification.title}
        visible={modalModification.visible}
        onCancel={() =>
          setModalModification({ ...modalModification, visible: false })
        }
        footer={[
          <Button
            onClick={() =>
              createActivityModificationRequest(
                modalModification.data.task,
                modalModification.data.activity
              )
            }
            key="submit"
            style={{
              background: '#7DFF8A',
              color: '#121212',
              borderColor: '#7DFF8A'
            }}>
            {t('modals.lookahead.activity_modification_request.send_request')}
          </Button>
        ]}>
        {modalModification.visible ? (
          <div>
            <div>
              <span className="name-activity-modification">
                {modalModification.data.activity.name}
              </span>
            </div>
            <div className="dates-container-activity-modification">
              <span className="calendar-activity-modification">
                <img src={calendarModificationIconModal} width={12} />
              </span>
              <span className="dates-activity-modification">
                {modalModification.data.activity.start_date.split(' ')[0]}
              </span>
              <span className="calendar-activity-modification">
                <img src={calendarModificationIconModal} width={12} />
              </span>
              <span className="dates-activity-modification">
                {modalModification.data.activity.end_date.split(' ')[0]}
              </span>
            </div>
            <div className="modification-title-activity-modification">
              {defineTitleModification(modalModification.data.task, t)}
            </div>
            {defineDateUnfit(
              modalModification.data.task,
              modalModification.data.activity,
              t
            )}
            <div className="commentary-container-activity-modification">
              {t('modals.lookahead.activity_modification_request.comment')}
            </div>
            <div>
              <TextArea
                onChange={({ target: { value } }) => {
                  modalModification.description = value;
                }}
                placeholder={t(
                  'modals.lookahead.activity_modification_request.description_placeholder'
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </div>
          </div>
        ) : null}
      </Modal>
    </Row>
  );
}
