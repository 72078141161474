export const filterUnits = (locations) => {
  let units = [];
  const locationsWithUnits = locations.filter((location) => {
    if (!location.productionUnits) {
      return true;
    }
  });

  locationsWithUnits.map((locationWithUnit) => {
    units = [...units, locationWithUnit];
  });
  units = units.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  return units;
};

export const filterLastLvlActivities = (activities) =>
  activities.filter((ac) => !ac.has_childs);
