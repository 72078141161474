import React from 'react';
import './styles.scss';
import useConfigurationPermissions from '../../hooks/useConfigurationPermissions';
import { getHeightSchedule } from '../../views/project/project.helper';
import { useSelectProject } from '../../hooks/useSelectProject';
import { projectService } from '../../services';
import { ScheduleFactory } from './builder/ScheduleFactory';
import { useDestroyGanttOnUnmount } from './hooks/useDestroyGanttOnUnmount';
import * as tracking from '../../analytics';
import { useLoadProjectsWithFilters } from './hooks/useLoadProjectsWithFilters';
import { useMountGanttAfterFetchProjects } from './hooks/useMountGanttAfterFetchProjects';
import { useFilterGanttSchedules } from './hooks/useFilterGanttSchedules';

const ganttName = 'ganttScheduleOfSchedule';
const scheduleFactory = ScheduleFactory;
const dependencies = { tracking, projectService, scheduleFactory };

const ScheduleOfSchedules = (props) => {
  const { processedProjects } = props;
  const ganttRef = React.useRef(null);
  const permissions = useConfigurationPermissions();
  const { onClickProject } = useSelectProject();
  const status = useLoadProjectsWithFilters({ ...props, ...dependencies });

  useMountGanttAfterFetchProjects({
    ...props,
    ...status,
    ...dependencies,
    onClickProject,
    ganttRef,
    permissions
  });

  useFilterGanttSchedules({
    ...dependencies,
    ...status,
    ...props,
    gantt: window[ganttName]
  });

  useDestroyGanttOnUnmount(ganttName);

  if (!processedProjects || status.isLoading) {
    return null;
  }

  return (
    <div
      className="schedule-of-schedule"
      ref={ganttRef}
      style={{
        height: getHeightSchedule(),
        paddingTop: '1px'
      }}
    />
  );
};

export default ScheduleOfSchedules;
