import React from 'react';
import moment from 'moment';
import { DeletedTaskEmptyIcon } from '../../../../icons';
import TooltipSystem from '../../../../components/DesignSystem/TooltipSystem';
import './ModalContentTasksCommitmentDeleted.scss';

export const ModalContentTasksCommitmentDeleted = (
  taskscommitmentsDeleted,
  isProgressColumns,
  weekcommitment,
  t
) => {
  const FORMAT_DEFAULT = 'MM/DD/AA';
  const sector = JSON.parse(sessionStorage.getItem('currentSector'));

  const isFloat = (value) => value % 1 != 0;

  const shortText = (text, large = 20) =>
    text.length > large ? `${text.substr(0, large)}...` : text;

  return taskscommitmentsDeleted?.length === 0 ? (
    <div className="modal-tasks-deleted-empty">
      <div className="modal-tasks-deleted-empty__icon">
        <DeletedTaskEmptyIcon color="#FFF" />
      </div>
      <div className="modal-tasks-deleted-empty__text">
        <span>{t('weekcommitment.modal_deleted_tasks_empty_state_text')}</span>
      </div>
    </div>
  ) : (
    <div
      className="modal-tasks-deleted"
      style={{
        overflowY: taskscommitmentsDeleted?.length > 6 ? 'scroll' : 'inherit',
        maxHeight: 220
      }}>
      <div className="modal-tasks-deleted__detail">
        <div className="detail__header">
          <span>{t('weekcommitment.modal_deleted_tasks_header_task')}</span>
          <span>{t('weekcommitment.modal_deleted_tasks_header_progress')}</span>
          <span>
            {t('weekcommitment.modal_deleted_tasks_header_commitment')}
          </span>
        </div>
        <div className="detail__body">
          {taskscommitmentsDeleted?.map((currentTask) => (
            <div className="body__item">
              <div>
                {TooltipSystem({
                  children: shortText(currentTask.name),
                  placement: 'top',
                  title: currentTask.name
                })}
              </div>
              <div>
                {isProgressColumns.progress && weekcommitment?.closed
                  ? currentTask.realized_percentaje
                  : 0}
                %
              </div>
              <div>
                {isProgressColumns.progress &&
                isFloat(currentTask.commitment_percentaje)
                  ? `${currentTask.commitment_percentaje.toFixed(2)}%`
                  : `${currentTask.commitment_percentaje}%`}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="modal-tasks-deleted__date">
        <div className="date__header">
          <span>
            {t('weekcommitment.modal_deleted_tasks_header_initial_date')}
          </span>
          <span>{t('weekcommitment.modal_deleted_tasks_header_end_date')}</span>
        </div>
        <div className="date__body">
          {taskscommitmentsDeleted?.map((currentTask) => (
            <div className="body__item">
              <div>
                {moment(currentTask.start_date).format(
                  sector?.dateFormat || FORMAT_DEFAULT
                )}
              </div>
              <div>
                {moment(currentTask.end_date).format(
                  sector?.dateFormat || FORMAT_DEFAULT
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="modal-tasks-deleted__status">
        <div className="status__header">
          <span>{t('weekcommitment.modal_deleted_tasks_header_status')}</span>
        </div>
        <div className="status__body">
          {taskscommitmentsDeleted?.map((currentTask) => {
            const conditionCompleted = weekcommitment?.closed
              ? currentTask.realized_percentaje >=
                currentTask.commitment_percentaje
              : currentTask.commitment_percentaje <= 0;
            return (
              <div className="body__item">
                <div>
                  {conditionCompleted ? (
                    <i
                      className="far fa-check-circle"
                      style={{ fontSize: 14, color: 'green' }}
                    />
                  ) : (
                    <i
                      className="fas fa-minus-circle"
                      style={{ fontSize: 14, color: 'red' }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
