import React from 'react';
import { Icon, Tooltip, Popconfirm } from 'antd';
import { withTranslation } from 'react-i18next';

export const BaselinesModalActionButton = withTranslation()(({
  onConfirm,
  tooltip,
  children,
  t
}) => {
  const content = (
    <Tooltip placement="bottom" title={tooltip}>
      {children}{' '}
    </Tooltip>
  );

  return onConfirm ? (
    <Popconfirm
      onConfirm={onConfirm}
      title={t('modals.gantt.all_baselines.are_sure')}
      icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}>
      <div>{content}</div>
    </Popconfirm>
  ) : (
    content
  );
});
