import {
  calculateApprovalDatePlanned,
  calculateStartDatePlanned
} from '../assets/js/events/onAfterTaskUpdate/updateRelatedSubmittals';

/**
 * Updates submittal data based on the start date from the Gantt chart.
 *
 * @linkcode https://pp-docs-internal.proplanner.app/docs/Proplanner%20Web/Front/Submittals/Utils/updateSubmittal%20Data
 *
 * @param {Object} data - The submittal data object. It should contain attributes like `leadTime`, `designTeamReviewTime`, and `internalReviewTime`.
 * @param {Date} ganttStartDate - The start date from the Gantt chart.
 *
 * @returns {Object} An updated submittal data object with recalculated `requiredOnSiteDate`, `startDatePlanned`, and `approvalDatePlanned`.
 *
 * @example
 * const data = {
 *   leadTime: 10,
 *   designTeamReviewTime: 5,
 *   internalReviewTime: 3,
 * };
 * const ganttStartDate = new Date('2023-09-01');
 * const updatedData = updateSubmittalData(data, ganttStartDate);
 *
 * console.log(updatedData);  // Output will contain updated values for requiredOnSiteDate, startDatePlanned, and approvalDatePlanned.
 */
export const updateSubmittalData = (data, ganttStartDate) => {
  const { leadTime, designTeamReviewTime, internalReviewTime } = data;

  const newRequiredOnSiteDate = ganttStartDate;
  const newApprovalDatePlanned = calculateApprovalDatePlanned(
    newRequiredOnSiteDate,
    leadTime
  );
  const newStartDatePlanned = calculateStartDatePlanned(
    newRequiredOnSiteDate,
    leadTime,
    designTeamReviewTime,
    internalReviewTime
  );

  return {
    ...data,
    requiredOnSiteDate: newRequiredOnSiteDate,
    startDatePlanned: newStartDatePlanned,
    approvalDatePlanned: newApprovalDatePlanned
  };
};
