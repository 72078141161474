import React, { useState, useEffect } from 'react';

import { Popover, Tooltip, message } from 'antd';

/** PNG to put on users without image */
import fakeAvatar from '../../assets/img/fake_user.png';
import descriptionModificationIcon from '../../assets/img/gantt/activity-modification-description.png';
import acceptModificationIcon from '../../assets/img/gantt/activity-modification-accept.png';
import rejectModificationIcon from '../../assets/img/gantt/activity-modification-reject.png';
import rejectedModificationIcon from '../../assets/img/gantt/activity-modification-rejected.png';
import approvedModificationIcon from '../../assets/img/gantt/activity-modification-approved.png';
import onClickedModificationIcon from '../../assets/img/gantt/on-clicked-modification-calendar.png';
import { activityModificationService } from '../../services';

const ActivityModification = (props) => {
  const {
    gantt,
    t,
    activityModificationSelected,
    saveHandler,
    permission,
    updateModalRender
  } = props;

  const getTaskCalendar = (task) => {
    let calendar;
    if (!task.calendar_id) {
      calendar = gantt.getCalendar(gantt.defaultCalendar);
    } else {
      calendar = gantt.getCalendar(task.calendar_id);
    }

    return calendar;
  };

  const printModificationList = (activityClicked) => {
    const activityObject = gantt.getTask(activityClicked);

    return (
      <div
        style={{
          position: 'relative',
          zIndex: 3,
          maxHeight: 135,
          overflow: 'auto'
        }}>
        {activityObject.activityModifications
          .sort((mrA, mrB) => new Date(mrB.createdAt) - new Date(mrA.createdAt))
          .map((activityModification, index) => {
            return (
              <div key={index}>
                <Tooltip
                  placement="top"
                  title={`${activityModification.userRequest.name} ${activityModification.userRequest.lastname}`}>
                  <span>
                    <img
                      width="12"
                      src={activityModification.userRequest.image || fakeAvatar}
                    />
                  </span>
                </Tooltip>
                <span className="dates-activity-modification-gantt">
                  {activityModification.startNew}
                </span>
                <span className="dates-activity-modification-gantt">
                  {activityModification.endNew}
                </span>
                <span>
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {activityModification.description}
                      </span>
                    }
                    trigger="click">
                    <Tooltip
                      placement="top"
                      title={t(
                        'modals.gantt.activity_modification_request.comment'
                      )}>
                      <img
                        src={descriptionModificationIcon}
                        width={10}
                        style={{ marginLeft: 10, cursor: 'pointer' }}
                      />
                    </Tooltip>
                  </Popover>
                </span>
                {defineActionsModification(activityModification)}
              </div>
            );
          })}
      </div>
    );
  };

  const acceptModification = async (activityModification) => {
    if (permission == 'V') {
      message.error(t('without_permission_to_save'), 2);
      return;
    }

    /** Update modification object */
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    activityModification.userApproved = loggedUser.id;
    activityModification.state = 'approved';
    const resModification =
      await activityModificationService.update(activityModification);

    if (resModification) {
      const { unique_id } = activityModification.activity;
      const { startNew, endNew } = activityModification;

      const startObject = gantt.date.parseDate(startNew, 'xml_date');
      const endObject = gantt.date.parseDate(endNew, 'xml_date');

      const activity = gantt.getTask(unique_id);
      /** Update chart */
      activity.constraint_type = 'asap';
      activity.start_date = startObject;
      activity.end_date = endObject;

      /**
       * Code below deals with calendars and hours feature
       */
      const calendar = getTaskCalendar(activity);
      activity.duration = calendar.calculateDuration(activity);
      activity.for_disable_milestone_duration =
        calendar.calculateDuration(activity);

      const updateTaskTiming = (task) => {
        task.start_date = calendar.getClosestWorkTime({
          dir: 'future',
          date: gantt.date.parseDate(task.start_date, 'xml_date'),
          unit: gantt.config.duration_unit,
          task: task
        });
        task.end_date = calendar.calculateEndDate(task);
      };
      updateTaskTiming(activity);

      await gantt.updateTask(activity.id);
      activity.for_disable_milestone_duration = activity.duration;

      await gantt.modifyLagCustom(activity);
      await gantt.autoSchedule();
      await saveHandler();
      message.success(t('success_modification_activity'), 5);
    }

    gantt.$hideDropdown();
  };

  const rejectModification = async (activityModification) => {
    if (permission == 'V') {
      message.error(t('without_permission_to_save'), 2);
      return;
    }

    /** Update modification object */
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    activityModification.userApproved = loggedUser.id;
    activityModification.state = 'rejected';
    const resModification =
      await activityModificationService.update(activityModification);

    if (resModification) {
      message.success(t('rejected_modification_activity'), 5);
    }

    updateModalRender();

    gantt.$hideDropdown();
  };

  const defineActionsModification = (activityModification) => {
    const { state } = activityModification;

    if (state == 'waiting') {
      return (
        <span key={activityModification.id} style={{ marginRight: 13 }}>
          <Tooltip
            placement="top"
            title={t('modals.gantt.activity_modification_request.accept')}>
            <span>
              <img
                src={acceptModificationIcon}
                width={12}
                style={{ marginLeft: 10, cursor: 'pointer' }}
                onClick={() => acceptModification(activityModification)}
              />
            </span>
          </Tooltip>
          <Tooltip
            placement="top"
            title={t('modals.gantt.activity_modification_request.reject')}>
            <span>
              <img
                src={rejectModificationIcon}
                width={10}
                style={{ marginLeft: 10, cursor: 'pointer' }}
                onClick={() => rejectModification(activityModification)}
              />
            </span>
          </Tooltip>
        </span>
      );
    } else if (state == 'rejected') {
      return (
        <Tooltip
          placement="top"
          title={t('lang') == 'es' ? 'Rechazada' : 'Rejected'}>
          <span style={{ marginRight: 13 }}>
            <img
              src={rejectedModificationIcon}
              style={{ marginLeft: 10 }}
              width={12}
            />
          </span>
        </Tooltip>
      );
    } else if (state == 'approved') {
      return (
        <Tooltip
          placement="top"
          title={t('lang') == 'es' ? 'Aceptada' : 'Accepted'}>
          <span style={{ marginRight: 13 }}>
            <img
              src={approvedModificationIcon}
              style={{ marginLeft: 10 }}
              width={12}
            />
          </span>
        </Tooltip>
      );
    }
  };

  return (
    <div id="gantt_dropdown">
      <div>
        <div className="over-top-dropdown">
          <img src={onClickedModificationIcon} width={12} />
        </div>
      </div>
      {activityModificationSelected
        ? printModificationList(activityModificationSelected)
        : null}
    </div>
  );
};

export default ActivityModification;
