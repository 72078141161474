import React from 'react';

/** massive icons */
import alertIcon from '../../../assets/img/icons/icon--alert--new.svg';
import { SvgIcon } from '../../../utils';

export const ALERT_ICON = (
  <SvgIcon
    path={alertIcon}
    className="massive-notification-icon"
    color="#FDD551"
  />
);
