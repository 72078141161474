/**
 * This function keeps constraint as they should be (BUG SOLVED)
 * @param {*} gantt Instance where user is actually using the gantt lib (dhtmlx) which gives access to their API
 * @returns None return just an void method
 */
export const autoschedule_job =
  (gantt) => (task, new_date, link, predecessor) => {
    let reason = '';
    if (predecessor) {
      reason = predecessor.text;
    } else {
      const constraint = gantt.getConstraintType(task);
      reason = gantt.locale.labels[constraint];
    }
    predecessor = predecessor || { text: task.constraint_type };
  };
