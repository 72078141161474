import React, { useState, useEffect } from 'react';
import { Table, Avatar, Progress, Dropdown, Row, Col, Select } from 'antd';
import fakeAvatar from '../../../../assets/img/fake_user.png';
import './index.css';
import moment from 'moment';
import IconComponent from '../../../../components//Projects/IconSvg';
import { filter, getTodayWeekWithPdayAndCurrentDay } from '../../../../utils';

export default function PccForUser(props) {
  const {
    allUsers = [],
    t = () => {},
    total_pcr_weeksF = [],
    planificationDay,
    constraintsArrayUser = []
  } = props;
  let total_pcr_weeks = total_pcr_weeksF;
  let _allUsers = allUsers;
  if (allUsers === null) {
    _allUsers = [];
  }
  const { Column } = Table;
  const [data, setData] = useState([]);
  const [filterVisibility, setFilterVisibility] = useState(false);
  const [sectorFilter, setSectorFilter] = useState([]);
  const [arraySector, setArraySector] = useState([]);
  const [users, setusers] = useState([]);

  useEffect(() => {
    initPrc();
  }, [constraintsArrayUser]);

  async function initPrc() {
    const push_check_sector = [];
    if (total_pcr_weeks.length) {
      total_pcr_weeks = total_pcr_weeks.sort((a, b) => {
        if (b.year == a.year) return a.week - b.week;
        return a.year - b.year;
      });
    }

    push_check_sector.push(
      <Option key={0} value={['todo', 'todo']}>
        {t('lang') == 'es' ? 'Todo' : 'All'}
      </Option>
    );

    let prevWeeK = 0;
    let prevYear = 0;
    total_pcr_weeks.length &&
      total_pcr_weeks.map((c) => {
        if ((prevWeeK != c.week || prevYear != c.year) && c.closed == true) {
          const weekForFilter = getTodayWeekWithPdayAndCurrentDay(
            null,
            moment(c.start_date, 'YYYY-MM-DD'),
            planificationDay
          );
          push_check_sector.push(
            <Option key={c.id} value={[c.week, c.year]}>
              {moment(weekForFilter[2].end)
                .locale(t('lang'))
                .format('DD MMM YY')}
            </Option>
          );
        }
        prevWeeK = c.week;
        prevYear = c.year;
      });

    setSectorFilter(push_check_sector);
    getUsers();
  }

  const handleFilterDropdown = (val) => {
    setFilterVisibility(val);
  };

  useEffect(() => {
    getUsers(users);
  }, arraySector);

  useEffect(() => {
    getUsers();
  }, _allUsers);

  function handleChangeWeek(value) {
    getUsers(value[0], value[1]);
    setFilterVisibility(!filterVisibility);
  }

  async function getUsers(week = 'todo', year) {
    const pre_data = [];
    const users_active = constraintsArrayUser.filter(
      (user) => user.is_active == true
    );
    users_active.map((users) => {
      let total = 0;
      let realized = 0;
      const diferent_count = 0;
      const reproramin = 0;
      const diferente_total = 0;
      const arrayfilter = [];
      users.tasks.map((task) => {
        task.taskcommitments.map((taskcommitment) => {
          if (week == 'todo') {
            if (
              taskcommitment.commitment_percentaje <=
              taskcommitment.realized_percentaje
            ) {
              realized++;
            }
            total++;
          } else {
            if (
              taskcommitment.weekcommitment.week == week &&
              taskcommitment.weekcommitment.year == year
            ) {
              if (
                taskcommitment.commitment_percentaje <=
                taskcommitment.realized_percentaje
              ) {
                realized++;
              }
              total++;
            }
          }
        });
      });
      let commitment_percentaje = 0;
      if (total > 0) {
        commitment_percentaje = ((realized / total) * 100).toFixed(0);
      }
      const dato = {
        user: users,
        ppc: commitment_percentaje.toString().replace('.', ','),
        reprograming: 0,
        realizes: realized + '/' + total,
        diferent: 0,
        total: total
      };
      pre_data.push(dato);
    });

    pre_data.sort((a, b) => (a.ppc > b.ppc ? -1 : 1));

    setData(pre_data);
  }
  const { Option } = Select;

  return (
    <div className="contenctPcrForUser">
      <br />
      <h1 className="user_h1">{t('analitic.team_commitment')}</h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: 30,
          marginBottom: 32,
          marginTop: -40
        }}>
        <Row className="lookahead-filter-header filer_user">
          <Col span={10}>
            <Col style={{ height: '100%', textAlign: 'center' }} span={3}>
              <Dropdown
                overlay={
                  <div className="sector_check_filter">
                    <Select
                      style={{ width: '150px' }}
                      onChange={handleChangeWeek}
                      placeholder={t('analitic.select_week')}>
                      {sectorFilter}
                    </Select>
                    <br />
                    <br />
                  </div>
                }
                trigger={['click']}
                visible={filterVisibility}
                onVisibleChange={handleFilterDropdown}>
                <div
                  className="filter_buton"
                  style={{ cursor: 'pointer', fontSize: '12px' }}>
                  <IconComponent
                    data={filter}
                    fill="#53C255"
                    className="svg-icon-filter"
                  />
                </div>
              </Dropdown>
            </Col>
          </Col>
        </Row>
      </div>
      <div className="user_hr"></div>
      <Table
        className="tablescroll"
        dataSource={data}
        scroll={{ y: 400 }}
        style={{ width: '100%', height: '400' }}
        pagination={false}>
        <Column
          title={t('master_plan.responsible')}
          dataIndex="user"
          key="user"
          width="60%"
          render={(user) => (
            <div>
              <Avatar
                className="avantar"
                shape="circle"
                src={user.image ? user.image : fakeAvatar}
                size={40}
              />
              <div className="content-title">
                <p className="user-title">{user.name + ' ' + user.lastname}</p>
                <p className="user-position-sub">{user.position}</p>
              </div>
            </div>
          )}
        />
        <Column
          title={'PPC'}
          dataIndex="ppc"
          key="ppc"
          className="other-title"
          render={(ppc, total) => (
            <div>
              {total.total > 0 ? (
                <Progress
                  type="circle"
                  percent={ppc}
                  width={40}
                  strokeColor={ppc >= 50 ? '#2C3421' : 'red'}
                  className="porgressbar"
                  trailColor="#fff"
                />
              ) : (
                '-'
              )}
            </div>
          )}
        />
        <Column
          title={t('analitic.commitments')}
          dataIndex="realizes"
          key="realizes"
          className="other-title"
          render={(realizes) => (
            <div>
              <p className="user-position">{realizes}</p>
            </div>
          )}
        />
      </Table>
    </div>
  );
}
