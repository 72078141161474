import logoMsProject from '../assets/img/importer/msproject.png';
import logoOrPrimavera from '../assets/img/importer/primavera.png';

const fileIcons = {
  mpp: logoMsProject,
  xer: logoOrPrimavera
};

export const getImportableIconByFileExt = (fileName) => {
  const fileExt = fileName?.split('.').slice(-1).pop();
  return fileIcons[fileExt] ?? fileIcons.mpp;
};
