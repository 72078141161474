import React from 'react';
import styles from './styles.module.scss';

const MAX_CHARACTERS = 4000;

const Counter = ({ currentCharacters }) => (
  <div className={styles.counter}>
    <span>
      {currentCharacters}/{MAX_CHARACTERS}
    </span>
  </div>
);

export default Counter;
