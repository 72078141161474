import React, { useEffect, useState, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ViewsCtx } from '../../Context/Planification/ViewsContext';
import { timelineActions } from '../../redux/actions/lookaheadTimelineActions';
import { viewService } from '../../services/views.service';
import { userService } from '../../services/user.service.js';
import { treePaneService } from '../../services/activityTreeSettings.service.js';
import { roleTypes } from '../../constants/role.constants.js';
import { grid, dynamicSort } from '../../utils';
import { log } from '../../monitor/monitor.js';
import * as Sentry from '@sentry/react';
import PopoverUniselectSystem from '../DesignSystem/PopoverUniselectSystem';
import ModalSystem from '../DesignSystem/ModalSystem';
import InputSystem from '../DesignSystem/InputSystem';
import ButtonSystem from '../DesignSystem/ButtonSystem';
import TooltipSystemV2 from '../DesignSystem/TooltipSystemV2';
import RadioSystem from '../DesignSystem/RadioSystem';
import notificationSystemV2 from '../DesignSystem/NotificationSystemV2';
import TruncatedTextSystem from '../DesignSystem/TruncatedTextSystem';
import IconComponent from '../Projects/IconSvg';
import {
  ArrowDropdownIcon,
  TimelineViewIcon,
  AddIcon,
  InformationCircleOutlineIcon,
  PencilOutlineIcon,
  TrashOutlineIcon,
  WarningDiamontIcon
} from '../../icons';
import Colors from '../../assets/styles/variables.scss';
import './LookaheadVisualizationOptions.scss';

const DEFAULT_CREATE_VIEW = 'table';
const THEME_DARK = 'dark';
const MODULES_VIEW = Object.freeze({
  VISIBILITY: 'visibility',
  CREATE_VIEW: 'createView',
  ACTIONS: 'actions'
});

const LookaheadVisualizationOptions = (props) => {
  const {
    changeVisualization,
    groupBy,
    isPlanification,
    listViews,
    queryFilter,
    orderQuery,
    setDateRange,
    t,
    viewType,
    visualizationOp,
    removeFiltersInCurrentView
  } = props;

  const dispatch = useDispatch();

  const projectState = useSelector((state) => state.projectState);

  const {
    viewCtx: [__, setViewCtx]
  } = useContext(ViewsCtx);

  const $listViewItems = useRef(null);

  const { BLACK, DARK_MINT, BED_ROCK, PRIMARY } = Colors;

  const [selectedViewLabel, setSelectedLabel] = useState('');
  const [listViewItems, setListViewItems] = useState(null);
  const [view, setView] = useState({});
  const [defaultView, setDefaultView] = useState(null);
  const [isShowViewNameModal, setIsShowViewNameModal] = useState(false);
  const [isShowRenameViewModal, setIsShowRenameViewModal] = useState(false);
  const [isShowDeleteViewModal, setIsShowDeleteViewModal] = useState(false);
  const [isLoadingCreateNewView, setIsLoadingCreateNewView] = useState(false);
  const [isLoadingRenameView, setIsLoadingRenameView] = useState(false);
  const [isLoadingDeleteView, setIsLoadingDeleteView] = useState(false);
  const [viewNameModal, setViewNameModal] = useState({
    name: '',
    tableType: DEFAULT_CREATE_VIEW,
    isPublic: false
  });
  const [currentRenameView, setCurrentRenameView] = useState({
    viewId: null,
    label: null,
    newLabel: null
  });
  const [inputDeleteView, setInputDeleteView] = useState('');
  const [currentDeleteView, setCurrentDeleteView] = useState();

  const DEFAULT_DELETE_VIEW = t(
    'lookahead_visualizationOpt.modal_delete_view_text_delete'
  );

  useEffect(() => {
    setDefaultView(props.defaultView);
    setSelectedLabel(window.defaultView?.current?.label);
  }, []);

  useEffect(() => {
    if (!isPlanification) return;

    const viewLabel = listViews.current.find(findView).view;

    setViewCtx(viewLabel);
    setView(viewLabel);

    defaultView &&
      setDateRange({
        start: defaultView.lookahead_init,
        end: defaultView.lookahead_end
      });
  }, [isPlanification]);

  useEffect(() => {
    const updateFilterQuery = async () => {
      if (queryFilter && isPlanification) {
        await updateFilters('filter_query', visualizationOp.filter_query);
      }
    };

    updateFilterQuery();
  }, [queryFilter]);

  useEffect(() => {
    const storeOrderQuery = async () => {
      if (orderQuery && isPlanification) {
        await updateFilters('order_query', visualizationOp.order_query);
      }
    };

    storeOrderQuery();
  }, [orderQuery]);

  useEffect(() => {
    const storeGroupQuery = async () => {
      if (isPlanification) {
        await updateFilters('group_query', JSON.stringify(groupBy));
      }
    };

    storeGroupQuery();
  }, [groupBy]);

  useEffect(() => {
    setListViewItems($listViewItems);
  }, [listViewItems]);

  const findView = ({ view }) => {
    const defaultObject = props.defaultView || defaultView;

    if (!defaultObject) return;
    if (defaultObject.id === view.id) return true;
  };

  const forceViewIcon = (selectedView) => {
    for (const view of viewType) {
      if (view.name == selectedView) return view;
    }

    return viewType[0];
  };

  const updateFilters = async (filterKey, value) => {
    const viewConfigIdx = listViews.current.findIndex(findView);
    listViews.current[viewConfigIdx].view[filterKey] = value;

    removeFiltersInCurrentView(filterKey, value);

    await viewService.update({
      projectId: listViews.current[viewConfigIdx].project,
      sectorId: listViews.current[viewConfigIdx].sector,
      ganttChart: listViews.current[viewConfigIdx].ganttChart,
      orderView: listViews.current[viewConfigIdx].order,
      ...listViews.current[viewConfigIdx].view
    });
  };

  const visualizationHandle = async (
    option,
    { created = false, selected = false, duplicated = false } = {}
  ) => {
    if (selected && visualizationOp.id === option.view.id) return;

    if (listViews.current.length) {
      if (created || duplicated) {
        const viewWillCreate = {
          project: option.project,
          sector: option.sector,
          order: option.order,
          view: {
            ...option.view
          }
        };
        listViews.current.push(viewWillCreate);
      }

      if (selected || created) {
        await viewService.updateDefaultView(
          option.project,
          option.sector,
          false
        );
        await viewService.updateDefaultViewByView(option.view.id, true);
      }

      listViews.current.forEach((configView) => {
        configView.view.default = configView.view.id === option.view.id;
        configView.view.id === option.view.id &&
          setDefaultView(configView.view);
      });

      setViewCtx(option.view);

      await updateDefaultUserView(option.view.id);

      changeVisualization(option.view);

      setTimeout(() => {
        dispatch(timelineActions.updateParsedValue(false));
      }, 500);
    }
  };

  const updateViewLabel = async (viewId, label) => {
    try {
      if (!viewId || !label) return;

      setIsLoadingRenameView(true);

      await viewService.updateLabel(viewId, label);

      const dataView = listViews.current.find(
        (viewInfo) => viewInfo.view.id === viewId
      );

      listViews.current.forEach((option) => {
        if (option.view.id == dataView.view.id) {
          option.view.label = label;
        }
      });

      const defaultView = window.defaultView.current;

      if (dataView.view.id === defaultView.id) {
        setSelectedLabel(label);
      }

      notificationSystemV2({
        key: 'success-update-view',
        type: 'success',
        message: t(
          'lookahead_visualizationOpt.modal_rename_view_success_notification'
        )
      });
    } catch (err) {
      log('UPDATE_VIEW_LABEL', `Error in updateViewLabel(), error: ${err}`);

      Sentry.captureException(err);

      notificationSystemV2({
        key: 'error-update-view',
        type: 'error',
        message: t(
          'lookahead_visualizationOpt.modal_rename_view_error_notification'
        )
      });
    } finally {
      handleCloseRenameViewModal();
    }
  };

  const assignNewDefaultUserView = async (viewToDelete) => {
    const localUser = JSON.parse(localStorage.getItem('user'));
    const updatedUser = await userService.show(localUser.id);
    const defaultSectorView =
      updatedUser?.user?.defaultLookaheadView[projectState.sectorSelected];

    if (defaultSectorView == viewToDelete.view.id) {
      let viewIdToReplace;
      let olderView = new Date();
      let viewToReturn;
      listViews.current
        .filter((view) => view.view.userId == localUser.id)
        .forEach((view) => {
          const dateCreateView = new Date(view.view.createdAt);
          const timeStampOlder = olderView.getTime();
          const timeStampView = dateCreateView.getTime();
          if (timeStampView < timeStampOlder) {
            olderView = dateCreateView;
            viewIdToReplace = view.view.id;
            viewToReturn = view;
          }
        });
      if (viewIdToReplace) {
        await updateDefaultUserView(viewIdToReplace);
      }

      return viewToReturn;
    }
  };

  const handleDeleteView = async (view) => {
    try {
      if (!view) return;

      if (
        window.listView?.current.length === 1 &&
        !window.listView?.current[0].view.public
      ) {
        return;
      }

      setIsLoadingDeleteView(true);

      const viewId = view.id;
      const currentView = listViews.current.find(
        (viewInfo) => viewInfo.view.id === viewId
      );

      const findToRemove = ({ view }) => view.id === currentView.view.id;
      const viewIdx = listViews.current.findIndex(findToRemove);
      const viewToDelete = listViews.current.find(findToRemove);

      await viewService.destroy(viewToDelete.view.id);
      listViews.current.splice(viewIdx, 1);
      listViews.current[0].view.default = true;

      const viewToAssign = await assignNewDefaultUserView(viewToDelete);

      if (visualizationOp.id === currentView.view.id) {
        await viewService.updateDefaultViewByView(
          listViews.current[0].view.id,
          true
        );
        visualizationHandle(viewToAssign || listViews.current[0]);
      }

      setListViewItems(listViews.current);

      notificationSystemV2({
        key: 'success-delete-view',
        type: 'success',
        message: t(
          'lookahead_visualizationOpt.modal_delete_view_success_notification'
        )
      });
    } catch (err) {
      log('DELETE_VIEW', `Error in handleDeleteView(), error: ${err}`);

      Sentry.captureException(err);

      notificationSystemV2({
        key: 'error-delete-view',
        type: 'error',
        message: t(
          'lookahead_visualizationOpt.modal_delete_view_error_notification'
        )
      });
    } finally {
      handleCloseDeleteViewModal();
    }
  };

  const isNotValidCreateViewForm = () =>
    !viewNameModal.name ||
    viewNameModal.name === '' ||
    viewNameModal === '' ||
    viewNameModal.name.trim() === '';

  const isNotValidRenameViewForm = () =>
    !currentRenameView.newLabel ||
    currentRenameView.newLabel === '' ||
    currentRenameView.newLabel === '' ||
    currentRenameView.newLabel.trim() === '' ||
    currentRenameView.newLabel === currentRenameView.label;

  const isNotValidDeleteViewForm = () =>
    inputDeleteView !== DEFAULT_DELETE_VIEW;

  const handleCreateViewType = async () => {
    try {
      if (isNotValidCreateViewForm()) return;

      setIsLoadingCreateNewView(true);

      const newViewType = viewNameModal.tableType;
      let configViewModule = viewType.find((view) => view.name == newViewType);
      if (!configViewModule) {
        configViewModule = forceViewIcon(newViewType);
      }

      if (!window.defaultView && !window.defaultView.current) return;
      const userView = { ...window.defaultView.current };
      delete userView.id;

      const lastView = listViews.current[listViews.current.length - 1];
      const newView = {
        project: null,
        sector: projectState.sectorSelected,
        sectorId: projectState.sectorSelected,
        ganttChart: newViewType === 'gant' ? 1054 : null,
        order: lastView.order + 1,
        view: {
          ...userView,
          label: viewNameModal.name,
          public: viewNameModal.isPublic
        }
      };

      await viewService.updateDefaultView(
        lastView.project,
        lastView.sector,
        false
      );

      const currentProject = projectState.projectSelected;
      const currentUser = JSON.parse(localStorage.getItem('user'));
      const viewCreated = await viewService.create({
        projectId: currentProject,
        sectorId: newView.sector,
        order: newView.order,
        ...newView.view,
        userId: currentUser.id
      });
      const activityTreeData = newView.view.activityTreeSettings;
      const newActivityTreeData = {
        ...activityTreeData,
        viewId: viewCreated.view.id
      };

      delete newActivityTreeData.id;

      await treePaneService.create(newActivityTreeData);
      await updateDefaultUserView(viewCreated.view.id);

      visualizationHandle(viewCreated, { created: true });

      setIsLoadingCreateNewView(false);

      notificationSystemV2({
        key: 'success-create-view',
        type: 'success',
        message: t(
          'lookahead_visualizationOpt.modal_create_view_success_notification'
        )
      });
    } catch (err) {
      log('CREATE_VIEW', `Error in handleCreateViewType(), error: ${err}`);

      Sentry.captureException(err);

      setIsShowViewNameModal(false);

      notificationSystemV2({
        key: 'error-create-view',
        type: 'error',
        message: t(
          'lookahead_visualizationOpt.modal_create_view_error_notification'
        )
      });
    }
  };

  const updateDefaultUserView = async (viewId) => {
    const localUser = JSON.parse(localStorage.getItem('user'));
    const updatedUser = await userService.show(localUser.id);
    const allUserDefaultViews = updatedUser?.user?.defaultLookaheadView || {};
    allUserDefaultViews[projectState.sectorSelected] = viewId;
    updatedUser.defaultLookaheadView = allUserDefaultViews;

    const updateUserDefaultView = {
      ...updatedUser.user,
      defaultLookaheadView: allUserDefaultViews,
      updateDefaultLookaheadview: true
    };

    await userService.update(updateUserDefaultView);
  };

  const handleKeyUp = (evt) => {
    if (evt.keyCode === 13) return handleCreateViewType();
  };

  const handleKeyUpRenameModal = (evt) => {
    if (evt.keyCode === 13)
      return updateViewLabel(
        currentRenameView.viewId,
        currentRenameView.newLabel
      );
  };

  const handleKeyUpDeleteModal = (evt) => {
    if (evt.keyCode === 13) return handleDeleteView(currentDeleteView);
  };

  const getItemsPopover = () => {
    const localUser = JSON.parse(localStorage.getItem('user'));

    const myViewsGroup = () =>
      window.listView?.current
        ? window.listView?.current
            .filter(
              (option) =>
                option.view.userId === localUser.id && !option.view.public
            )
            .map((option) => ({
              key: option.view.id,
              name: option.view.label,
              active: window.defaultView?.current?.id === option.view.id,
              icon: null,
              disabled: false,
              onClick: () => visualizationHandle(option, { selected: true }),
              subitems: [
                {
                  key: 11,
                  name: t('lookahead_visualizationOpt.views_group_rename'),
                  active: false,
                  icon: <PencilOutlineIcon color={DARK_MINT} />,
                  disabled: false,
                  onClick: () =>
                    handleOpeRenameViewModal(
                      option.view.id,
                      option.view.label,
                      option.view.label
                    )
                },
                {
                  key: 12,
                  name: t('lookahead_visualizationOpt.views_group_delete'),
                  active: false,
                  icon: <TrashOutlineIcon color={DARK_MINT} />,
                  disabled:
                    window.listView?.current.length === 1 &&
                    !window.listView?.current[0].view.public,
                  onClick: () => handleOpenDeleteViewModal(option?.view),
                  informationIcon:
                    window.listView?.current.length === 1 &&
                    !window.listView?.current[0].view.public,
                  tooltipIformation: t(
                    'lookahead_visualizationOpt.modal_delete_view_tooltip'
                  )
                }
              ]
            }))
        : [];

    const publicViewsGroup = () =>
      window.listView?.current
        ? window.listView?.current
            .filter(
              (option) =>
                (option.view.userId !== localUser.id && option.view.public) ||
                (option.view.userId === localUser.id && option.view.public)
            )
            .map((option) => ({
              key: option.view.id,
              name: option.view.label,
              active: window.defaultView?.current?.id === option.view.id,
              icon: null,
              disabled: false,
              onClick: () => visualizationHandle(option, { selected: true }),
              subitems:
                getPermissionByModule(MODULES_VIEW.ACTIONS) ||
                option.view.userId === localUser.id
                  ? [
                      {
                        key: 21,
                        name: t(
                          'lookahead_visualizationOpt.views_group_rename'
                        ),
                        active: false,
                        icon: <PencilOutlineIcon color={DARK_MINT} />,
                        disabled: false,
                        onClick: () =>
                          handleOpeRenameViewModal(
                            option.view.id,
                            option.view.label,
                            option.view.label
                          )
                      },
                      {
                        key: 22,
                        name: t(
                          'lookahead_visualizationOpt.views_group_delete'
                        ),
                        active: false,
                        icon: <TrashOutlineIcon color={DARK_MINT} />,
                        disabled: false,
                        onClick: () => handleOpenDeleteViewModal(option?.view)
                      }
                    ]
                  : []
            }))
        : [];

    const myViews = [
      {
        key: 1,
        name: t('lookahead_visualizationOpt.views_group_my_views'),
        active: false,
        icon: null,
        disabled: false,
        onClick: null,
        subitems: [],
        group: myViewsGroup().sort(dynamicSort('name'))
      }
    ];

    const publicViews = [
      {
        key: 2,
        name: t('lookahead_visualizationOpt.views_group_public_views'),
        active: false,
        icon: null,
        disabled: false,
        onClick: null,
        subitems: [],
        group: publicViewsGroup().sort(dynamicSort('name'))
      }
    ];

    return publicViewsGroup().length > 0
      ? [...myViews, ...publicViews]
      : myViews;
  };

  const handleChangeVisibility = (event) => {
    setViewNameModal({
      ...viewNameModal,
      isPublic: event.target.value
    });
  };

  const getPermissionByModule = (module) => {
    if (!module) return false;

    const localUser = JSON.parse(localStorage.getItem('user'));
    const currentRole = localUser?.role;

    if (module === MODULES_VIEW.VISIBILITY) {
      if (
        [
          roleTypes.SUPERADMIN,
          roleTypes.ADMIN,
          roleTypes.PROJECTLEADER,
          roleTypes.PLANNER,
          roleTypes.SUPERINTENDENT
        ].includes(currentRole)
      ) {
        return true;
      }

      return false;
    }

    if (module === MODULES_VIEW.CREATE_VIEW) {
      if (
        [roleTypes.LASTPLANNER, roleTypes.CLIENT, roleTypes.MANAGER].includes(
          currentRole
        )
      ) {
        return false;
      }

      return true;
    }

    if (module === MODULES_VIEW.ACTIONS) {
      if ([roleTypes.SUPERADMIN, roleTypes.ADMIN].includes(currentRole)) {
        return true;
      }

      return false;
    }
  };

  const getContentViewNameModal = () => (
    <div className="content-view-name-modal">
      <div className="content-view-name-modal__input">
        <InputSystem
          theme={THEME_DARK}
          label={t('lookahead_visualizationOpt.modal_create_view_input_label')}
          onChange={(evt) =>
            setViewNameModal({ ...viewNameModal, name: evt.target.value })
          }
          onKeyUp={handleKeyUp}
          value={viewNameModal.name}
        />
      </div>
      {getPermissionByModule(MODULES_VIEW.VISIBILITY) && (
        <div className="content-view-name-modal__visibility">
          <div className="visibility__title">
            <span>
              {t(
                'lookahead_visualizationOpt.modal_create_view_visibility_title'
              )}
            </span>
            <div className="title__icon">
              {TooltipSystemV2({
                title: t(
                  'lookahead_visualizationOpt.modal_create_view_visibility_tooltip'
                ),
                children: (
                  <div>
                    <InformationCircleOutlineIcon color={BED_ROCK} />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="visibility__options">
            {RadioSystem({
              theme: THEME_DARK,
              options: [
                {
                  label: t(
                    'lookahead_visualizationOpt.modal_create_view_radio_private_label'
                  ),
                  value: false
                },
                {
                  label: t(
                    'lookahead_visualizationOpt.modal_create_view_radio_public_label'
                  ),
                  value: true
                }
              ],
              value: viewNameModal.isPublic,
              onChange: handleChangeVisibility
            })}
          </div>
        </div>
      )}
      <div className="content-view-name-modal__buttons">
        <ButtonSystem
          type="secondary"
          onClick={() => handleCloseViewNameModal()}>
          {t('lookahead_visualizationOpt.modal_create_view_cancel_button')}
        </ButtonSystem>
        <ButtonSystem
          loading={isLoadingCreateNewView}
          disabled={isNotValidCreateViewForm()}
          onClick={() => handleCreateViewType()}>
          {t('lookahead_visualizationOpt.modal_create_view_save_button')}
        </ButtonSystem>
      </div>
    </div>
  );

  const getContentRenameModal = () => (
    <div className="content-rename-view-modal">
      <div className="content-rename-view-modal__input">
        <InputSystem
          theme={THEME_DARK}
          label={t('lookahead_visualizationOpt.modal_rename_view_input_label')}
          onChange={(evt) =>
            setCurrentRenameView({
              ...currentRenameView,
              newLabel: evt.target.value
            })
          }
          onKeyUp={handleKeyUpRenameModal}
          value={currentRenameView.newLabel}
        />
      </div>
      <div className="content-rename-view-modal__buttons">
        <ButtonSystem
          type="secondary"
          onClick={() => handleCloseRenameViewModal()}>
          {t('lookahead_visualizationOpt.modal_rename_view_cancel_button')}
        </ButtonSystem>
        <ButtonSystem
          loading={isLoadingRenameView}
          disabled={isNotValidRenameViewForm()}
          onClick={() =>
            updateViewLabel(
              currentRenameView.viewId,
              currentRenameView.newLabel
            )
          }>
          {t('lookahead_visualizationOpt.modal_rename_view_save_button')}
        </ButtonSystem>
      </div>
    </div>
  );

  const getContentDeleteModal = () => (
    <div className="content-delete-view-modal">
      <div className="content-delete-view-modal__icon">
        <WarningDiamontIcon color={PRIMARY} />
      </div>
      <div className="content-delete-view-modal__title">
        <h3>{t('lookahead_visualizationOpt.modal_delete_view_title')}</h3>
        <h3>{t('lookahead_visualizationOpt.modal_delete_view_subtitle')}</h3>
      </div>
      <div className="content-delete-view-modal__input">
        <span>
          {t('lookahead_visualizationOpt.modal_delete_view_text')}{' '}
          <b>{t('lookahead_visualizationOpt.modal_delete_view_text_delete')}</b>{' '}
          {t('lookahead_visualizationOpt.modal_delete_view_text_delete_sure')}
        </span>
        <div className="input__form">
          <InputSystem
            theme={THEME_DARK}
            onChange={(evt) => setInputDeleteView(evt.target.value)}
            onKeyUp={handleKeyUpDeleteModal}
            value={inputDeleteView}
          />
        </div>
      </div>
      <div className="content-delete-view-modal__buttons">
        <ButtonSystem
          type="secondary"
          onClick={() => handleCloseDeleteViewModal()}>
          {t('lookahead_visualizationOpt.modal_delete_view_cancel_button')}
        </ButtonSystem>
        <ButtonSystem
          loading={isLoadingDeleteView}
          disabled={isNotValidDeleteViewForm()}
          onClick={() => handleDeleteView(currentDeleteView)}>
          {t('lookahead_visualizationOpt.modal_delete_view_delete_button')}
        </ButtonSystem>
      </div>
    </div>
  );

  const handleCloseViewNameModal = () => {
    setViewNameModal({ ...viewNameModal, name: '', isPublic: false });
    setIsShowViewNameModal(false);
  };

  const handleCloseRenameViewModal = () => {
    setInputDeleteView('');
    setCurrentRenameView({ viewId: null, label: null, newLabel: null });
    setIsLoadingRenameView(false);
    setIsShowRenameViewModal(false);
  };

  const handleOpeRenameViewModal = (viewId, label) => {
    setCurrentRenameView({
      ...currentRenameView,
      viewId: viewId,
      label: label,
      newLabel: label
    });
    setIsShowRenameViewModal(true);
  };

  const handleOpenDeleteViewModal = (view) => {
    setCurrentDeleteView(view);
    setIsShowDeleteViewModal(true);
  };

  const handleCloseDeleteViewModal = () => {
    setInputDeleteView('');
    setCurrentDeleteView();
    setIsLoadingDeleteView(false);
    setIsShowDeleteViewModal(false);
  };

  return isPlanification ? (
    <>
      <PopoverUniselectSystem
        width={218}
        countItemsShow={7}
        trigger="click"
        placement="bottomLeft"
        items={getItemsPopover()}
        maxCharacters={14}
        ctaText={t('lookahead_visualizationOpt.popover_cta_text')}
        ctaFunction={
          getPermissionByModule(MODULES_VIEW.CREATE_VIEW)
            ? () => setIsShowViewNameModal(true)
            : null
        }>
        <div className="select-view-popover">
          <div className="select-view-popover__left">
            <div className="left__icon">
              <TimelineViewIcon color={BLACK} />
            </div>
            <TruncatedTextSystem
              name={selectedViewLabel}
              maxWidth={108}
              color={BLACK}
              placement={'topLeft'}>
              <span>{selectedViewLabel}</span>
            </TruncatedTextSystem>
          </div>
          <div className="select-view-popover__right">
            <ArrowDropdownIcon />
          </div>
        </div>
      </PopoverUniselectSystem>
      {ModalSystem({
        visible: isShowViewNameModal,
        theme: THEME_DARK,
        setVisible: handleCloseViewNameModal,
        showTitle: true,
        title: t('lookahead_visualizationOpt.modal_create_view_title'),
        showIconTitle: true,
        iconTitle: <AddIcon color={PRIMARY} />,
        centered: true,
        width: 380,
        closable: true,
        children: getContentViewNameModal(),
        zIndex: 2000
      })}
      {ModalSystem({
        visible: isShowRenameViewModal,
        theme: THEME_DARK,
        setVisible: handleCloseRenameViewModal,
        showTitle: true,
        title: t('lookahead_visualizationOpt.modal_rename_view_title'),
        showIconTitle: true,
        iconTitle: <PencilOutlineIcon color={PRIMARY} />,
        centered: true,
        width: 380,
        closable: true,
        children: getContentRenameModal(),
        zIndex: 2000
      })}
      {ModalSystem({
        visible: isShowDeleteViewModal,
        theme: THEME_DARK,
        setVisible: handleCloseDeleteViewModal,
        showTitle: false,
        centered: true,
        width: 480,
        closable: true,
        children: getContentDeleteModal(),
        zIndex: 2000
      })}
    </>
  ) : (
    <span>
      <span>
        <IconComponent
          data={grid}
          fill={PRIMARY}
          className="svg-icon-grid-view"
        />
      </span>
      <span>{t('filters_label.table_view')}</span>
    </span>
  );
};

export default withTranslation()(LookaheadVisualizationOptions);
