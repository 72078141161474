import React, { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import './etapas.css';
import TableEtapa from './TableEtapa';
import { sectorService } from '../../../../services/sector.service';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { openNotification, compareValues } from '../../../../utils';
import * as projectActions from '../../../../redux/slices/projectSlice';

function Etapas(props) {
  const dispatch = useDispatch();
  const { onlyRead, t } = props;
  const [Sectors, setSectors] = useState([]);
  useEffect(() => {
    dispatch(
      projectActions.setProps({
        ...stateProject.props,
        loadSectors: false
      })
    );
    if (Sectors.length) dispatch(projectActions.setAllSectors(Sectors));
  }, [Sectors]);

  /** hooks */
  const [stageError, setStageError] =
    useState(null); /** handle validation stage */

  // GET ID SECTOR FROM REDUX
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;
  const [newEtapa, setnewEtapa] = useState({
    description: null,
    name: '',
    order: 64,
    productive: true,
    projectId: projectSelectedId,
    status: true
  });

  const changeNewEtapa = (e) => {
    setStageError(null);
    setnewEtapa({
      ...newEtapa,
      [e.target.name]: e.target.value
    });
  };

  // services
  async function getSectorsByProject(idProject) {
    const resp = await sectorService.getSectorsByProject(idProject);
    return resp ? resp.sectors : false;
  }

  const createNewSector = async (e) => {
    if (newEtapa.name.trim() === '') {
      setStageError(true);
      const alertError = {
        title: t('settings.project_form.sectors.title'),
        description: t('settings.project_form.sectors.valid_stage_name'),
        type: 'error'
      };
      openNotification(alertError);
      return;
    }
    e.preventDefault();
    sectorService.create(newEtapa).then(async (response) => {
      if (response === false) {
        const alertError = {
          title: t('settings.project_form.sectors.title'),
          description: t('settings.error_default'),
          type: 'error'
        };
        openNotification(alertError);
      } else {
        setnewEtapa({
          description: null,
          name: '',
          productive: true,
          projectId: projectSelectedId,
          status: true
        });

        // UPDATE TABLE SECTOR ALL FROM RIGHT
        const sectors = await getSectorsByProject(stateProject.projectSelected);
        if (sectors) {
          /** select active sectors  */
          const filterSectors = sectors.filter((e) => e.status === true);
          filterSectors.sort(compareValues('order'));
          dispatch(
            projectActions.setProps({
              ...stateProject.props,
              loadSectors: true
            })
          );
          setSectors(filterSectors);
        }

        const alertSuccess = {
          title: t('settings.project_form.sectors.title'),
          description: 'Was updated successfully',
          type: 'success'
        };
        openNotification(alertSuccess);
        dispatch(
          projectActions.setProps({
            ...stateProject.props,
            loadSectors: false
          })
        );
      }
    });
  };
  return (
    <div className="grid-etapas">
      <div></div>
      <div>
        <h1 className="title-config-projects">
          {t('settings.project_form.sectors.title')}
        </h1>
        <p className="title-etapas">
          {t('settings.project_form.sectors.name')}
        </p>
        <Form.Item name="group">
          <Input
            autoComplete="off"
            disabled={onlyRead}
            name="name"
            style={{ border: '1px solid #121212' }}
            className={stageError ? 'border-input input-error' : 'border-input'}
            placeholder={t('settings.project_form.sectors.name')}
            value={newEtapa.name}
            onChange={(e) => changeNewEtapa(e)}
          />
        </Form.Item>

        <div className="grid-etapa">
          <div>
            <span className="title-etapas">
              {t('settings.project_form.sectors.name')}
            </span>
            <div className="btn-productivity">
              <button
                className={`btn-etapa ${
                  newEtapa.productive ? 'bg-btn' : 'bg-btn-white underline-btn'
                }`}
                onClick={() =>
                  setnewEtapa({
                    ...newEtapa,
                    productive: true
                  })
                }>
                {t('settings.project_form.sectors.prod_name')}
              </button>
              <button
                className={`btn-etapa ${
                  newEtapa.productive ? 'bg-btn-white underline-btn' : 'bg-btn'
                }`}
                onClick={() =>
                  setnewEtapa({
                    ...newEtapa,
                    productive: false
                  })
                }>
                {t('settings.project_form.sectors.not_prod_name')}
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="center-btn-generals">
          <button
            disabled={onlyRead}
            type="button"
            className="btn-project-stage"
            onClick={(e) => createNewSector(e)}>
            {t('settings.project_form.sectors.add')}
          </button>
        </div>
      </div>
      <div style={{ marginTop: '35px', borderLeft: '1px solid #F1F1F1' }}>
        <TableEtapa
          Sectors={Sectors}
          setSectors={setSectors}
          onlyRead={onlyRead}
          t={t}
        />
      </div>
    </div>
  );
}

export default Etapas;
