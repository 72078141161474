import React, { useState, useEffect, useRef, memo } from 'react';
import PopoverUniselectSystem from '../../../../../DesignSystem/PopoverUniselectSystem';
import TooltipSystemV2 from '../../../../../DesignSystem/TooltipSystemV2';
import { USER_ROLES } from '../../constants';
import {
  ArrowDropdownIcon,
  InformationCircleOutlineIcon
} from '../../../../../../icons';
import Colors from '../../../../../../assets/styles/variables.scss';

const Role = memo(
  ({ data, t = {}, currentUser, handleUpdateUser, isValidRole }) => {
    const roleRef = useRef();

    const [currentData, setCurrentData] = useState(data);

    const { BLACK, DARK_GRAY } = Colors;

    useEffect(() => {
      setCurrentData(data);
    }, [data]);

    const getRoleLevel = (roleValue) => {
      const role = USER_ROLES(t).find(
        (currentRole) => currentRole.value === roleValue
      );
      return role ? role.level : null;
    };

    const isDisabledItem = (level) => {
      const currentUserRoleLevel = getRoleLevel(currentUser.role);
      return currentUserRoleLevel !== null && currentUserRoleLevel > level;
    };

    const isHigherRole = () => {
      const currentRoleLevel = getRoleLevel(data.role);
      const currentUserRoleLevel = getRoleLevel(currentUser.role);
      return (
        currentUserRoleLevel !== null &&
        currentRoleLevel !== null &&
        currentUserRoleLevel > currentRoleLevel
      );
    };

    const handleOnClick = (value) => {
      setCurrentData({ ...data, role: value });
      handleUpdateUser(data, 'role', value);
      handleClosePopover();
    };

    const handleClosePopover = () => {
      if (roleRef.current) {
        roleRef.current.tooltip.state.visible = false;
      }
    };

    const items = USER_ROLES(t).map((currentRole) => {
      return {
        key: currentRole.value,
        name: currentRole.label,
        active: currentRole.value === currentData.role,
        icon: null,
        disabled: isDisabledItem(currentRole.level),
        onClick: () => handleOnClick(currentRole.value),
        subitems: [],
        group: []
      };
    });

    if (!isValidRole)
      return (
        <span className="roles-column">
          {USER_ROLES(t).find((role) => role.value === data.role)?.label}
        </span>
      );

    if (isHigherRole())
      return (
        <div className="roles-column">
          <span>
            {
              USER_ROLES(t).find(
                (currentRole) => currentRole.value === currentData.role
              )?.label
            }
          </span>
          <div className="roles-column__icon">
            {TooltipSystemV2({
              title: t(
                'settings.organization_settings.users_tab.table_role_tooltip_admin'
              ),
              placement: 'top',
              children: (
                <div>
                  <InformationCircleOutlineIcon color={DARK_GRAY} />
                </div>
              )
            })}
          </div>
        </div>
      );

    return (
      <PopoverUniselectSystem
        itemReference={roleRef}
        trigger="click"
        items={items}
        countItemsShow={9}
        placement="bottom"
        withSearch={false}>
        <div className="roles-column-popover">
          <span>
            {
              USER_ROLES(t).find(
                (currentRole) => currentRole.value === currentData.role
              )?.label
            }
          </span>
          <div className="roles-column-popover__icon">
            <ArrowDropdownIcon color={BLACK} />
          </div>
        </div>
      </PopoverUniselectSystem>
    );
  }
);

export default Role;
