import { addDurationToDate } from '../../helpers/index';
import { CONSTRAINT_TYPES } from '../../constants/index';
/**
 * Calculates the restriction dates based on the activity constraint type.
 * @param {Object} activity - The activity object containing constraint date and duration.
 * @param {string} constraint - The type of constraint (e.g., 'snet', 'snlt', 'fnlt', 'fnet', 'mso', 'mfo').
 * @param {Object} activityCalendar - The calendar object associated with the activity.
 * @returns {Object} - An object containing the calculated early start (es) and early finish (ef) dates.
 */

function calculateRestriction(activityCalendar, activity, constraint) {
  let restriction = { ls: null, lf: null };

  if (constraint === CONSTRAINT_TYPES.MSO) {
    restriction.ls = activity.constraint_date;
    restriction.lf = addDurationToDate(
      activityCalendar,
      restriction.ls,
      activity.duration,
      activity
    );
  }

  if (constraint === CONSTRAINT_TYPES.MFO) {
    restriction.lf = activity.constraint_date;
    restriction.ls = addDurationToDate(
      activityCalendar,
      restriction.lf,
      -activity.duration,
      activity
    );
  }

  return restriction;
}

export default calculateRestriction;
