/**
 * An enumeration that maps role types to their corresponding names.
 * This object is frozen to make it immutable, ensuring that the mappings
 * cannot be accidentally modified elsewhere in the code.
 *
 * @readonly
 * @enum {string}
 */
export const roleTypes = Object.freeze({
  SUPERADMIN: 'superadmin',
  ADMIN: 'admin',
  PROJECTLEADER: 'projectleader',
  PLANNER: 'planner',
  SUPERINTENDENT: 'superintendent',
  LASTPLANNER: 'lastplanner',
  CLIENT: 'client',
  MANAGER: 'manager',
  SUBTRADE: 'subtrade'
});
