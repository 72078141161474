import { cloneDeep } from 'lodash';
import { updateTaskTiming } from '../utils';

function setFinalHourDay(task) {
  const gantt = window.to_use_react_gantt;
  const calendar = gantt.getCalendar(task.calendar_id);
  const clonedStartDate = cloneDeep(task.start_date);
  let clonedEndDate = cloneDeep(task.end_date);

  if (clonedEndDate.getHours() !== 0 || clonedEndDate.getMinutes() !== 0) {
    clonedEndDate.setHours(24);
    clonedEndDate.setMinutes(0);
  }

  if (calendar) {
    clonedEndDate = calendar.getClosestWorkTime({
      dir: 'past',
      date: clonedEndDate,
      unit: gantt.config.duration_unit
    });

    const duration = calendar.calculateDuration(clonedStartDate, clonedEndDate);
    task.duration = duration;
    task.for_disable_milestone_duration = duration;
    task.end_date = clonedEndDate;

    updateTaskTiming({ task });
  }
}

export { setFinalHourDay };
