import moment from 'moment';
function keepHoursWhileDrag(task) {
  const startDateCopy = moment(task.start_date).clone();
  const endDateCopy = moment(task.end_date).clone();
  startDateCopy
    .hours(task.calendarWorkingHours.start.hour)
    .minutes(task.calendarWorkingHours.start.minutes);
  endDateCopy
    .hours(task.calendarWorkingHours.end.hour)
    .minutes(task.calendarWorkingHours.end.minutes);

  task.start_date = startDateCopy.toDate();
  task.end_date = endDateCopy.toDate();
}
export { keepHoursWhileDrag };
