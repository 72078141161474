import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import styles from './ResourcesMatrix.module.scss';
import { FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ACTIONS } from '../../scroll.actions';

export const ResourcesMatrix = ({
  data,
  t,
  gridWidth,
  gridHeight,
  gridContentValue,
  resourcesIds,
  onScroll,
  matrixContentRef,
  nowIndexValidate
}) => {
  const { partialValues } = data[0];
  const valdidate = nowIndexValidate;
  const rowCount = resourcesIds.length;
  const countColumns = partialValues.length;
  // let columns = []
  const rows = [];
  // const generateColumns = (data) => {
  //     columns = data[0].accumulatedValues.map(values => {
  //         return ({
  //             title: values.weeks.dateWeek,
  //             dataIndex: values.weeks.dateWeek,
  //             key: values.weeks.dateWeek
  //         })
  //     })
  // }
  const generateHeaderArray = partialValues.map(
    (value) => value.weeks.dateWeek
  );

  const generateRows = () => {
    resourcesIds.forEach((resource) => {
      let values = [];
      partialValues.map((value) => {
        if (value.weeks.data[resource] != undefined) {
          values = [...values, value.weeks.data[resource].toFixed(2)];
        } else {
          values = [...values, ''];
        }
      });
      rows.push({ values });
    });
  };

  // generateColumns(data);
  generateRows();

  const cells =
    (cell) =>
    ({ columnIndex, rowIndex, style }) => {
      return (
        <div
          style={style}
          className={`${cell[rowIndex].values[columnIndex] ? (columnIndex < valdidate ? styles['matrix__cell-green'] : styles['matrix__cell-blue']) : styles['matrix__cell-grey']}`}>
          {cell[rowIndex].values[columnIndex]}
        </div>
      );
    };
  const handleScroll = (scroll) =>
    onScroll({ scrollFrom: ACTIONS.MATRIX })(scroll);

  return (
    <div className={styles.matrix} ref={gridContentValue}>
      <AutoSizer>
        {({ height, width }) => (
          <Grid
            className={styles.gridValues}
            ref={matrixContentRef}
            columnCount={countColumns}
            rowCount={rowCount}
            columnWidth={80}
            rowHeight={25}
            width={width}
            height={height}
            onScroll={handleScroll}>
            {cells(rows)}
          </Grid>
        )}
      </AutoSizer>
    </div>
  );
};
