import React, { useEffect, useState } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Col, Popover } from 'antd';
import { ACTIONS } from '../../scroll.actions';
import styles from './Resources.module.scss';
// => Utilities
import { splitText } from '../../../ActivitiesUnitsGrid/index.helper';

export const GridSectorResources = ({
  resourcesData,
  sectorResourceRef,
  gridWidth,
  girdHeight,
  gridSectorRef,
  onScroll
}) => {
  const rowCount = resourcesData.length;

  const splitTextSize = gridWidth * 0.4;
  const cells =
    (cell) =>
    ({ columnIndex, rowIndex, style }) => {
      return (
        <div style={style}>
          <Col className={styles.gridContainer}>
            <div className={styles.cell__sector}>
              <Popover
                placement="right"
                overlayClassName="show-units-activity-modal"
                trigger="click">
                {splitText(cell[rowIndex].sectorName, splitTextSize)}
              </Popover>
            </div>
            <div className={styles.cell__resource}>
              {cell[rowIndex].resources[0].resourceName}
            </div>
            <div className={styles.cell__unidad}>
              {cell[rowIndex].resources[0].unidad}
            </div>
          </Col>
        </div>
      );
    };

  const handleScroll = (scroll) =>
    onScroll({ scrollFrom: ACTIONS.RESOURCE_LIST })(scroll);

  const calculatedHeight = 25 * rowCount + 20;
  return (
    <div
      className={styles.divGridContainer}
      ref={sectorResourceRef}
      style={{ height: `${calculatedHeight}px` }}>
      <AutoSizer>
        {({ height }) => (
          <Grid
            className={styles.gridContent}
            ref={gridSectorRef}
            columnCount={1}
            rowCount={rowCount}
            columnWidth={gridWidth}
            rowHeight={25}
            width={gridWidth}
            height={height}
            onScroll={handleScroll}>
            {cells(resourcesData)}
          </Grid>
        )}
      </AutoSizer>
    </div>
  );
};
