/**
 * Cleans the data by removing invalid keys from each task in the array.
 * @param {Object[]} tasks - The array of tasks to clean.
 * @returns {Object[]} The cleaned array of tasks.
 */
export const cleanDataXML = (tasks) => {
  if (!tasks || !Array.isArray(tasks)) return tasks;
  tasks.forEach((task) => removeInvalidKeys(task));
  return tasks;
};

/**
 * Removes invalid keys from a task object.
 * Invalid keys are those that start with certain prefixes and have numerical values within a specific range.
 * @param {Object} task - The task object to clean.
 * @returns {boolean} Returns false if an error occurs, otherwise nothing.
 */
function removeInvalidKeys(task) {
  try {
    const prefixes = ['$', '_'];
    const range = { min: 0, max: 36 };

    const keysToRemove = Object.keys(task).filter((attribute) =>
      prefixes.some(
        (prefix) =>
          attribute.startsWith(prefix) &&
          isInRange(attribute, range.min, range.max)
      )
    );
    keysToRemove.forEach((attribute) => delete task[attribute]);
  } catch (error) {
    return false;
  }
}

/**
 * Checks if the numerical part of an attribute is within a specified range.
 * @param {string} attribute - The attribute key to check.
 * @param {number} min - The minimum value of the range.
 * @param {number} max - The maximum value of the range.
 * @returns {boolean} True if the number is in the range, false otherwise.
 */
function isInRange(attribute, min, max) {
  const INDEX = 1;
  const numberAttribute = parseInt(attribute.slice(INDEX));
  return (
    !isNaN(numberAttribute) && numberAttribute >= min && numberAttribute <= max
  );
}
