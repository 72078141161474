import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { submittalActions } from '../redux/actions/submittalsActions';
import { getSectorCompanyAndProject } from '../analytics/utils';
import { procoreService } from '../services/procore.service';
import { socket } from '../services/socket.service';
import { log } from '../monitor/monitor';
import * as Sentry from '@sentry/react';
import { updateSubmittalData } from '../utils/submittals.utils';

export const useSubmittalsEvents = () => {
  const dispatch = useDispatch();
  const latestEvent = useRef(null);
  const { currentSector, currentProject, currentCompany } =
    getSectorCompanyAndProject();

  useEffect(() => {
    if (
      !currentSector ||
      !currentProject ||
      (!currentCompany && !currentCompany?.agave_token)
    )
      return;

    const fetchInitialSubmittals = async () => {
      try {
        const submittals = await procoreService.getSubmittals(
          currentCompany.id,
          currentSector.id,
          currentProject.id
        );
        dispatch(submittalActions.loadSubmittals(submittals));
      } catch (error) {
        Sentry.captureException(error);
        log('Error loading initial submittals:', {
          company: currentCompany.id,
          project: currentProject.id,
          sector: currentSector.id,
          error
        });

        dispatch(submittalActions.loadSubmittals([]));
      }
    };

    fetchInitialSubmittals();

    const roomName = `${currentCompany.id}-${currentProject.id}-${currentSector.id}`;
    socket.emit('joinRoom', {
      companyId: currentCompany.id,
      projectId: currentProject.id,
      sectorId: currentSector.id
    });

    const handleSubmittalCreated = (data) => {
      latestEvent.current = { type: 'created', data: data };
      dispatch(submittalActions.submittalCreated(data));
    };

    const handleSubmittalUpdated = (data) => {
      latestEvent.current = { type: 'updated', data: data };

      if (data.deletedAt) {
        dispatch(submittalActions.submittalDeleted(data.id));
        return;
      }

      if (!window?.to_use_react_gantt) {
        return;
      }

      const ganttTask = window.to_use_react_gantt.getTaskBy(
        (t) => t.proplannerId === data.activityId
      );
      if (!ganttTask || !ganttTask[0]) {
        return;
      }

      const { start_date: ganttStartDate } = ganttTask[0];
      const newSubmittal = updateSubmittalData(data, ganttStartDate);

      dispatch(submittalActions.submittalUpdated({ submittal: newSubmittal }));
    };

    const handleSubmittalDeleted = (data) => {
      window.to_use_react_gantt.iconSubmittalsArray.forEach((layer) => {
        window.to_use_react_gantt.removeTaskLayer(layer);
      });

      window.to_use_react_gantt.iconSubmittalsArray = [];

      latestEvent.current = {
        type: 'deleted',
        data: data.submittalData
      };

      dispatch(submittalActions.submittalDeleted(data.submittalData.id));
    };

    socket.on('submittalCreated', handleSubmittalCreated);
    socket.on('submittalUpdated', handleSubmittalUpdated);
    socket.on('submittalDeleted', handleSubmittalDeleted);

    return () => {
      socket.off('submittalCreated', handleSubmittalCreated);
      socket.off('submittalUpdated', handleSubmittalUpdated);
      socket.off('submittalDeleted', handleSubmittalDeleted);
      socket.emit('leaveRoom', {
        companyId: currentCompany.id,
        projectId: currentProject.id,
        sectorId: currentSector.id
      });
    };
  }, [dispatch, currentCompany?.id, currentProject?.id, currentSector?.id]);

  return latestEvent.current;
};
