/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class PropertiesService {
  constructor() {
    this.http = createHttpClient();
  }

  async index() {
    const res = await this.http.get('/properties').catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }
}
export const propertiesService = new PropertiesService();
