import { createSlice } from '@reduxjs/toolkit';

export const taskSliceKey = 'task';

const initialState = {};

const { actions, reducer } = createSlice({
  name: taskSliceKey,
  initialState,
  reducers: {
    // Dummy actions used in sagas
    taskUpdateRequested() {},
    taskUpdateSucceeded() {},
    taskUpdateFailed() {},
    taskUpdateProgressRequested() {}
  }
});

export const taskReducer = reducer;
export const {
  taskUpdateRequested,
  taskUpdateSucceeded,
  taskUpdateFailed,
  taskUpdateProgressRequested
} = actions;
