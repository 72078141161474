import { getIconByStatus } from './utils';

export const REQUEST_STATUSES = ['WAITING', 'APPROVED', 'REJECTED'];

export const FILTER_BUTTONS_CONFIG = [
  ['ALL', ...REQUEST_STATUSES].map((status, i) => {
    const { icon, color: iconColor } = getIconByStatus(status);

    return {
      command: `SHOW_${status}`,
      isCta: i === 0,
      icon,
      iconColor
    };
  })
];
