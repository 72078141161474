import { cloneDeep } from 'lodash';

const checkUpdatedElementsToCalculateTimingPast = (updatedTasks, gantt) => {
  if (!updatedTasks.length) return;

  const updatedObjects = [];
  updatedTasks = updatedTasks.forEach((id) => {
    if (!gantt.isTaskExists(id)) return;
    const t = gantt.getTask(id);
    updatedObjects.push(t);
  });
};

function updateTaskTimingPast(task, gantt) {
  /**
   * This function check any milestone start, and check if it is pushed to next workable date
   * @param {*} task Task to check if must be corrected to last workable date
   */

  if (task.$target.length || task.$source.length) return;

  let calendar = gantt.getCalendar(task.calendar_id);
  if (!calendar) calendar = gantt.getCalendar('global');

  const copyOfStart = cloneDeep(task.start_date);
  copyOfStart.setHours(copyOfStart.getHours() - 1);
  // Funcion mal utilizada deberia ser {date, unit: 'hour'}
  if (!calendar.isWorkTime({ date: copyOfStart, unit: 'hour' })) {
    task.start_date.setHours(task.start_date.getHours() - 1);
    try {
      task.start_date = calendar.getClosestWorkTime({
        dir: 'past',
        date: task.start_date,
        unit: gantt.config.duration_unit,
        task: task
      });
      task.end_date = calendar.calculateEndDate(task);
    } catch (e) {
      console.log(e);
    }
  }
}
export { checkUpdatedElementsToCalculateTimingPast };
