import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Modal,
  Checkbox,
  Button,
  Collapse,
  Radio,
  Dropdown
} from 'antd';
import styles from './ResourceFilter.module.scss';
import { useSelector } from 'react-redux';
import { dynamicSort } from '../../utils';

const GenerateArraySectorResources = (resources) => {
  const projectState = useSelector((state) => state.projectState);
  const allSectorsIds = projectState.allSectors.map((sector) => sector.id);
  const resourcesFormated = resources.map((resource) => {
    let sectorResource = [];
    if (resource.sectorresource.length > 0) {
      const sector = projectState.allSectors.filter(
        (sector) => sector.id == resource.sectorresource[0].sectorId
      )[0];
      // {label: 'asd', value: 1}
      const recursos = resource.sectorresource.map((recurso) => {
        return { label: recurso.name, value: recurso.id };
      });
      sectorResource = {
        sector_name: sector.name,
        sector_id: sector.id,
        resources: recursos
      };
    }
    return sectorResource;
  });
  return resourcesFormated;
};

export const RenderModalFilter = ({
  t,
  title,
  visible,
  resourcesArray,
  handleCancel,
  handleOk
}) => {
  const { Panel } = Collapse;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedResource, setSelectedResource] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const [arrayOfResourcesIds, setArrayOfResourcesId] = useState([]);
  const [booleanArrayOfResourcesIds, setBooleanArrayOfResourcesId] = useState(
    []
  );
  const [activo, setActivo] = useState(false);

  const populateResourcesIds = () => {
    if (resourcesArray[0]) {
      if (resourcesArray[0].sectorresource.length > 0) {
      }
    }
  };

  const renderCollapse = () => {
    const sectorResource = GenerateArraySectorResources(resourcesArray);
    if (resourcesArray[0]) {
      if (resourcesArray[0].sectorresource.length > 0) {
        const _arrayOfResourcesIds = [];
        const _booleanArray = [];
        if (arrayOfResourcesIds.length == 0) {
          sectorResource.sort(dynamicSort('sector_name')).map((sr) => {
            sr.resources.map((r) => {
              _booleanArray.push(false);
              _arrayOfResourcesIds.push(r.value);
            });
          });
          setArrayOfResourcesId(_arrayOfResourcesIds);
          setBooleanArrayOfResourcesId(_booleanArray);
        }
        let indice = -1;
        const panels = sectorResource
          .sort(dynamicSort('sector_name'))
          .map((sr) => {
            return (
              <Panel
                header={sr.sector_name}
                key={sr.sector_id}
                forceRender={true}>
                {sr.resources.map((r) => {
                  indice++;
                  return (
                    <Row>
                      <Checkbox
                        checked={booleanArrayOfResourcesIds[indice]}
                        value={[sr.sector_id, r.value, indice]}
                        onChange={onChecked}>
                        {r.label}
                      </Checkbox>
                    </Row>
                  );
                })}
              </Panel>
            );
          });
        return panels;
      }
    }
    return null;
  };

  const onChecked = (checkedValues) => {
    setActivo(!activo);
    const indice = checkedValues.target.value[2];
    const _booleanArray = booleanArrayOfResourcesIds;
    _booleanArray[indice] = checkedValues.target.checked;
    setBooleanArrayOfResourcesId(_booleanArray);
    const selected_resource = selectedResource;
    const selected_sector = selectedSector;
    if (checkedValues.target.checked) {
      selected_resource.push(checkedValues.target.value[1]);
      selected_sector.push(checkedValues.target.value[0]);
    } else {
      const indexResource = selected_resource.indexOf(
        checkedValues.target.value[1]
      );
      const indexSector = selected_sector.indexOf(
        checkedValues.target.value[0]
      );
      if (indexResource > -1) {
        selected_resource.splice(indexResource, 1);
      }
      if (indexSector > -1) {
        selected_sector.splice(indexSector, 1);
      }
    }
    setSelectedSector(selected_sector);
    setSelectedResource(selected_resource);
  };
  const clearHandle = () => {
    const _array = booleanArrayOfResourcesIds.map((id) => false);
    setBooleanArrayOfResourcesId(_array);
    setSelectedResource([]);
  };
  const selectAllHandle = () => {
    const _array = booleanArrayOfResourcesIds.map((id) => true);
    setBooleanArrayOfResourcesId(_array);
    setSelectedResource(arrayOfResourcesIds);
  };
  return (
    <div>
      <Modal
        title={t(title)}
        visible={visible}
        onOk={() => handleOk(selectedResource)}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}>
        {populateResourcesIds}
        <Row>
          <Col span={16}></Col>
          <Col span={8}>
            <span className={styles.options__clear} onClick={clearHandle}>
              ({t('analitic.clear')})
            </span>
            <span className={styles.options__all} onClick={selectAllHandle}>
              ({t('analitic.select_all')})
            </span>
          </Col>
        </Row>
        <Collapse className={`${styles.modalcontent}`}>
          {renderCollapse()}
        </Collapse>
      </Modal>
    </div>
  );
};
