import React, { useState, useEffect, useRef } from 'react';
import { taskService } from '../../services/task.service';
import { Tooltip } from 'antd';
import IconComponent from '../../components/Projects/IconSvg';

/** import library for format numbers */
import NumberFormat from 'react-number-format';

/** PNG for task planification edit and save tasks  */
import { SaveOutlined } from '@ant-design/icons';

import { cardEditIcon } from '../../utils';
import { cardSaveIcon } from '../../utils';
import { formatMoney } from '../../utils/lookahead-common';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';

export const testIds = {
  RENDER_EDITABLE_CONTAINER: 'RENDER_EDITABLE_CONTAINER',
  EDITABLE_INPUT_TRIGGER_: 'EDITABLE_INPUT_TRIGGER_'
};

const TestWrapped = ({ children }) => (
  <span data-testid={testIds.RENDER_EDITABLE_CONTAINER}>{children}</span>
);
const emptyWrapped = () => (
  <TestWrapped>
    <div></div>
  </TestWrapped>
);

/**
 * Legacy component to any flow for editing input
 * NOTE: Avoid touching this code under neccesary, be aware with it under your own responsability
 * @param {*} props
 * @returns JSX with common editable UI, which handles given props callbacks
 */
const EditableInput = (props) => {
  /**
   * Var section (Any state, redux state or any variable must be declared here)
   */
  const {
    t,
    activity,
    service = taskService,
    column,
    task,
    index,
    updateState,
    renderEditable,
    isFloat,
    onSave,
    onEdit,
    symbol,
    symbol_suffix = null,
    disabled = false,
    round = false,
    showDrawer = null,
    isCard = false,
    constraint = false,
    nameOnClick = () => {},
    onBlurEnabled = false,
    classNameText = '',
    featureOpenCard = false,
    viewCaller = null,
    saveOnlyIfChangeValue = false,
    ratioString = 2
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const userLang = navigator.language || navigator.userLanguage;
  const valueCalc = task ? task[column.name] : false;
  const [value, setValue] = useState(valueCalc);
  const editableInputRef = useRef(null);

  /**
   * Effects section (any new effect must be written here)
   */
  useOutsideAlerter(editableInputRef, () => {
    if (!onBlurEnabled) {
      setIsEditing(false);
    }
  });
  useEffect(() => {
    if (column.name != 'name' && typeof value == 'string')
      setValue(task[column.name]);
    if (column.name != 'name' && value < 0) setValue(0);
    if (column.name == 'duration' && value > 200) setValue(200);
  }, [value]);

  useEffect(() => {
    if (!task) return emptyWrapped();
    const focusedItem = column.name + task.id;
    const domElement = document.getElementById(focusedItem);
    if (domElement) {
      domElement.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (!task) return emptyWrapped();
    if (props.isEditing == column.name + task.id) {
      setIsEditing(true);
    }
  }, [props.isEditing]);

  /**
   * Function section (Any new function must be written here)
   */
  const updateParentData = (replaceValue = true) => {
    if (onSave) {
      onSave(value);
      setIsEditing(false);
    } else {
      if (saveOnlyIfChangeValue && task[column.name] === value) {
        setIsEditing(false);
        onEdit(null);
        return;
      }
      if (replaceValue && task) {
        task[column.name] = value;
      }
      !onBlurEnabled && setIsEditing(false);
      if (saveOnlyIfChangeValue) {
        if (replaceValue && task) service(task);
      } else {
        service(task);
      }
      onBlurEnabled && setIsEditing(false);
    }
    onEdit(null);
  };

  const handleEsc = (e) => {
    if (e.keyCode === 27) {
      if (saveOnlyIfChangeValue) {
        setIsEditing(false);
      } else {
        updateParentData(false);
      }
    }
  };

  const handleEditing = (e) => {
    setIsEditing(false);
  };

  const splitText = (text, round = false) => {
    if (typeof text == 'string') {
      let showedText = text;
      let tooltip;
      const colAdjustWidth = constraint
        ? Math.trunc(column.width / 5)
        : Math.trunc(column.width / 9);
      const textAdjustWidth = showedText.length * ratioString;

      if (textAdjustWidth > colAdjustWidth) {
        showedText =
          text.slice(0, Math.trunc(colAdjustWidth / ratioString)) + '...';
        tooltip = true;
      }
      const textDrawer = showDrawer ? (
        <span
          onClick={() => showDrawer(task, activity)}
          className={classNameText}>
          {showedText}
        </span>
      ) : (
        <span onClick={(e) => nameOnClick(e, task, activity)}>
          {showedText}
        </span>
      );
      return tooltip ? (
        <Tooltip placement="top" title={text}>
          {textDrawer}
        </Tooltip>
      ) : (
        textDrawer
      );
    } else {
      const ret =
        round && task ? (
          <NumberFormat
            value={parseFloat(text)}
            thousandSeparator={userLang.includes('en') ? ',' : '.'}
            decimalSeparator={userLang.includes('en') ? '.' : ','}
            decimalScale={Number.isInteger(parseFloat(text)) ? 0 : 2}
            displayType={'text'}
          />
        ) : (
          text
        );
      return ret;
    }
  };

  let classText = '';
  if (featureOpenCard) {
    classText = 'open-card-class';
  }

  /** Return section */
  return (
    <TestWrapped>
      <span
        // this line creates a unique testid for a column connected to element ID
        data-testid={
          testIds.EDITABLE_INPUT_TRIGGER_ +
          column?.name?.toUpperCase() +
          '_' +
          (activity?.id || task?.id)
        }
        className={classText}
        onClick={(e) => {
          if (viewCaller && viewCaller === 'tableView') {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
        onDoubleClick={(e) => {
          if (featureOpenCard) {
            onEdit(column.name + task?.id);
            setIsEditing(true);
          }
        }}>
        {isEditing ? (
          <span
            className={
              !featureOpenCard
                ? 'editable-ipsum-custom react-dhtmlx'
                : 'editable-ipsum-custom'
            }
            ref={editableInputRef}>
            {symbol || null}
            {renderEditable(
              column,
              index,
              task,
              value,
              setValue,
              updateParentData,
              handleEsc,
              handleEditing
            )}
            <span
              className="icon-save"
              onClick={updateParentData}
              style={{ marginLeft: 10, cursor: 'pointer' }}>
              <Tooltip placement="top" title={t('save_editable_tooltip')}>
                {isCard ? (
                  <IconComponent data={cardSaveIcon} width={13} fill="#fff" />
                ) : (
                  <SaveOutlined className="save-input-icon" />
                )}
              </Tooltip>
            </span>
          </span>
        ) : (
          <span
            className={
              (!featureOpenCard
                ? 'editable-ipsum-custom react-dhtmlx'
                : 'editable-ipsum-custom') + ' editable-input-not-triggered'
            }
            style={{
              cursor: disabled ? 'not-allowed' : 'pointer'
            }}
            onClick={() => {
              if (!isCard && !disabled) {
                if (featureOpenCard && showDrawer) {
                  showDrawer(task, activity);
                } else {
                  onEdit(column.name + task?.id);
                  setIsEditing(true);
                }
              }
            }}>
            {symbol || null}
            {round ? (
              task && task[column.name] ? (
                <NumberFormat
                  value={parseFloat(task[column.name])}
                  decimalScale={
                    Number.isInteger(parseFloat(task[column.name])) ? 0 : 2
                  }
                  thousandSeparator={userLang.includes('en') ? ',' : '.'}
                  decimalSeparator={userLang.includes('en') ? '.' : ','}
                  displayType={'text'}
                />
              ) : t('lang') === 'en' ? (
                '0'
              ) : (
                '0'
              )
            ) : task && task[column.name] ? (
              isFloat ? (
                t('lang') === 'en' ? (
                  formatMoney(
                    parseFloat(task[column.name]),
                    '',
                    column.name === 'real_endowment' ||
                      column.name === 'plan_endowment'
                      ? 0
                      : 2,
                    '.',
                    ','
                  )
                ) : (
                  formatMoney(
                    parseFloat(task[column.name]),
                    '',
                    column.name === 'plan_endowment' ? 0 : 2,
                    ',',
                    '.'
                  )
                )
              ) : (
                splitText(task[column.name])
              )
            ) : column.name === 'plan_endowment' ||
              column.name === 'real_endowment' ? (
              '0'
            ) : t('lang') === 'en' ? (
              '0'
            ) : (
              '0'
            )}
            {column?.name === 'ponderator' ? '%' : null}
            {symbol_suffix || null}
            {disabled ? null : (
              <span
                onClick={() => {
                  if (isCard) {
                    onEdit(column.name + task?.id);
                    setIsEditing(true);
                  }
                }}
                style={{ marginLeft: 5, cursor: 'pointer' }}>
                <Tooltip placement="top" title={t('edit_editable_tooltip')}>
                  {isCard ? (
                    <IconComponent data={cardEditIcon} width={13} fill="#fff" />
                  ) : null}
                </Tooltip>
              </span>
            )}
          </span>
        )}
      </span>
    </TestWrapped>
  );
};

export default EditableInput;
