import React from 'react';
import LazilyRender from 'react-lazily-render';

const LazyElement =
  (
    data,
    offset = 30,
    placeholder = null,
    className = 'single-row-bottom-bordered'
  ) =>
  ({ index, style }) => (
    <div style={style} className={className}>
      {data[index]}
    </div>
  );

export default LazyElement;
