import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ItemCard from '../ItemCard';
import { dynamicSort } from '../../../../utils';
import { pluginService } from '../../../../services/plugin.service';
import { useSelectProject } from '../../../../hooks/useSelectProject';

const CardsGeneral = () => {
  const stateCompany = useSelector((state) => state.companyState);
  const idCurrentCompany = stateCompany.currentCompany?.id;
  const [allPlugins, setAllPlugins] = useState([]);
  const { onClickProject } = useSelectProject();

  useEffect(() => {
    const fetchPlugins = async () => {
      const getPlugins = await pluginService.index();
      const sortedPlugins = getPlugins.plugins.sort(dynamicSort('name'));
      setAllPlugins(sortedPlugins);
    };
    fetchPlugins();
  }, [idCurrentCompany]);

  return (
    <div>
      {allPlugins.map((plugin, index) => (
        <ItemCard key={index} plugin={plugin} onClickProject={onClickProject} />
      ))}
    </div>
  );
};

export default CardsGeneral;
