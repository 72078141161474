import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';

import {
  activityCardFetchRequested,
  activityCardFetchSucceeded,
  activityCardFetchFailed,
  activityLogFetchRequested,
  activityLogFetchSucceeded,
  activityUpdateRequested,
  activityUpdateSucceeded,
  activityUpdateFailed,
  activityUpdateDatesRequested,
  activityUpdateDatesSucceeded,
  activityUpdateDatesFailed,
  activityUpdateResourceRequested,
  activityUpdateResourceFailed,
  activityUpdateResourceSucceeded
} from '../slices/activitySlice';
import { mapActivityToNewFormat } from '../../components/ActivityCardDrawer/utils';
import { activityService } from '../../services/activity.service';
import { applyChangesToGantt } from './modificationRequestSaga';
import { getSaveMasterplanSelector } from '../slices/nonSerializableSlice';
import { sectorResourcesService } from '../../services/sectorresource.service';
import { getGanttDateFormatSelector } from '../slices/ganttSlice';
import { convertGanttTaskToActivity } from '../../views/ganttContainer/gantt/gantt.helper';

export function* activityWatcherSaga() {
  yield takeLatest(
    activityCardFetchRequested.type,
    activityCardFetchRequestedWorkerSaga
  );
  yield takeLatest(
    activityLogFetchRequested.type,
    activityLogFetchRequestedWorkerSaga
  );
  yield takeLatest(
    activityUpdateRequested.type,
    activityUpdateRequestedWorkerSaga
  );
  yield takeLatest(
    activityUpdateDatesRequested.type,
    activityUpdateDatesRequestedWorkerSaga
  );
  yield takeLatest(
    activityUpdateResourceRequested.type,
    activityUpdateResourceRequestedWorkerSaga
  );
}

export function* activityCardFetchRequestedWorkerSaga({ payload } = {}) {
  const dateFormat = yield select(getGanttDateFormatSelector);
  const globalDhtmlxId = payload?.globalDhtmlxId;

  try {
    /** DO NOT DELETE THIS COMMENT: EXPLANATION ABOUT INTEGRATION REDUX-SAGA WITH LEGACY CODE PROPLANNER V2 */
    /** Sagas works through events, an activityCardFetchRequested is dispatched on ActivityCardDrawer,
     * which is an redux action defined on a slice (our new configuration for reducers and actions),
     * once this events is triggered, saga listen to this with their watchers (by running activityWatcherSaga),
     * and runs their workers (in this case activityCardFetchRequestedWorkerSaga),
     * to finally feed the ActivityCardDrawer through cardData which is a state from redux,
     * given at HoveringPortal, which finally listen to hovering portal events (watchers and workers) to handle which component
     * must be given, and also which props must be given  */
    // const response = yield call([activityService, activityService.getCardData], globalActivityId);
    // const updatedActivityRef = response?.activity;

    let dhtmlxGanttRef = window?.to_use_react_gantt?.getTask(globalDhtmlxId);
    if (!dhtmlxGanttRef) return;

    let activityRoute = [];
    getNameParents(dhtmlxGanttRef.id, activityRoute);

    const userLang = navigator.language || navigator.userLanguage;

    activityRoute.push(userLang.includes('es') ? 'Plan Maestro' : 'Schedule');
    activityRoute = activityRoute.reverse().join(' > ');

    dhtmlxGanttRef = {
      ...dhtmlxGanttRef,
      unique_id: dhtmlxGanttRef.id,
      activityRoute: activityRoute,
      resources: [],
      has_childs: false
    };

    const allSubs = window?.to_use_react_gantt?.subContracts;
    let activity = {};
    if (dhtmlxGanttRef) {
      const adaptedDhtmlxGanttRef = convertGanttTaskToActivity(dhtmlxGanttRef);
      const {
        tags,
        hhWorkTime,
        cost,
        name,
        description,
        constraint_date,
        start_date,
        duration,
        end_date,
        subcontractId,
        responsables,
        progress
      } = adaptedDhtmlxGanttRef;
      activity = {
        ...dhtmlxGanttRef,
        tags,
        hhWorkTime,
        cost,
        name,
        description,
        constraint_date,
        start_date,
        duration,
        end_date,
        subcontractId,
        responsables,
        progress
      };
      if (allSubs) {
        const subObject = allSubs.find(
          (sub) => sub.id === activity.subcontractId
        );
        if (subObject) {
          activity.subcontract = subObject;
        }
      }
    }

    if (!activity) {
      throw new Error('Cannot retrieve the selected activity.');
    }

    const mappedActivity = mapActivityToNewFormat(
      activity,
      dateFormat,
      getGanttInstance()
    );

    yield put({
      type: activityCardFetchSucceeded.type,
      payload: mappedActivity
    });
  } catch (e) {
    yield put({ type: activityCardFetchFailed.type });
  }
}

export function* activityUpdateRequestedWorkerSaga({
  payload: { activity, ganttActivityPatch, onUpdated }
} = {}) {
  try {
    yield call(updateActivity, activity);

    if (ganttActivityPatch) {
      yield call(
        activityUpdateGanttLocally,
        activity.unique_id,
        ganttActivityPatch
      );
    }

    if (onUpdated) yield call(onUpdated, activity);

    yield put({ type: activityUpdateSucceeded.type, payload: activity });
  } catch (e) {
    yield put({ type: activityUpdateFailed.type });
  }
}

export function* activityUpdateGanttLocally(id, changes) {
  const ganttInstance = getGanttInstance();
  const activity = ganttInstance.getTask(id);
  ganttInstance.updateTask(id, { ...activity, ...changes });
}

export function* activityUpdateDatesRequestedWorkerSaga({
  payload: { activityId, startDate, endDate, duration, onFinished }
} = {}) {
  try {
    const projectState = yield select((state) => state.projectState);
    const selectedSector = projectState.allSectors.find(
      (sector) => sector.id === projectState.sectorSelected
    );
    const ganttInstance = getGanttInstance();
    const durationUnit = ganttInstance.config.duration_unit;

    if (duration !== undefined) {
      duration =
        durationUnit === 'hour'
          ? duration * selectedSector.hoursPerDay
          : duration;
    }

    const saveMasterplan = yield select(getSaveMasterplanSelector);
    const changes = yield call(applyChangesToGantt, activityId, {
      start: startDate,
      end: endDate,
      duration
    });

    yield call(saveMasterplan);
    yield put({
      type: activityUpdateDatesSucceeded.type,
      payload: {
        ...changes,
        duration:
          durationUnit === 'hour'
            ? changes.duration / selectedSector.hoursPerDay
            : changes.duration
      }
    });
  } catch (e) {
    yield put({ type: activityUpdateDatesFailed.type });
  } finally {
    onFinished && onFinished();
  }
}

/**
 * this function save the resource's quantity updating the assigned value
 * @param {*} resource resource to update
 */
const saveAssing = async (resource) => {
  const data_push_update = [];
  const assign = {
    sectorresourceId: resource.id,
    activityId: resource.activitysectorresource.activityId,
    quantity: resource.total,
    sectorId: resource.sectorId
  };
  data_push_update.push(assign);
  await sectorResourcesService.assingActivitys([], data_push_update);
};

export function* activityUpdateResourceRequestedWorkerSaga({
  payload: { resource, onFinished }
} = {}) {
  try {
    yield call(
      [sectorResourcesService, sectorResourcesService.update],
      resource
    );

    /** update activity resourse object with the new value of quantity */
    yield call(saveAssing, resource);

    yield put({ type: activityUpdateResourceSucceeded.type });
  } catch (e) {
    yield put({ type: activityUpdateResourceFailed.type });
  } finally {
    onFinished && onFinished();
  }
}

export function* activityLogFetchRequestedWorkerSaga({ payload } = {}) {
  const response = [
    {
      date: '2021-10-23',
      logs: [
        {
          time: '17:20',
          user: 'Franco Giaquinto',
          type: 'CHANGE',
          field: 'status',
          newValue: 'Advanced',
          newValueColor: '#34af01'
        }
      ]
    },
    {
      date: '2021-10-21',
      logs: [
        {
          time: '08:55',
          user: 'Franco Giaquinto',
          type: 'CHANGE',
          field: 'duration',
          newValue: 3,
          newValueUnit: 'DAY'
        }
      ]
    },
    {
      date: '2021-10-21',
      logs: [
        {
          time: '14:03',
          user: 'Hernan Vargas',
          type: 'ADD',
          field: 'assignee',
          newValue: 'Franco Giaquinto'
        },
        {
          time: '13:20',
          user: 'Hernan Vargas',
          type: 'CHANGE',
          field: 'status',
          newValue: 'Delayed',
          newValueColor: '#fa7676'
        },
        {
          time: '10:55',
          user: 'Hernan Vargas',
          type: 'CHANGE',
          field: 'duration',
          newValue: 5,
          newValueUnit: 'DAY'
        }
      ]
    },
    {
      date: '2021-10-18',
      logs: [
        {
          time: '18:35',
          user: 'Felix Pretell',
          type: 'ADD',
          field: 'assignee',
          newValue: 'John Doe'
        },
        {
          time: '15:40',
          user: 'Alcides Queiroz',
          type: 'CHANGE',
          field: 'status',
          newValue: 'Advanced',
          newValueColor: '#34af01'
        },
        {
          time: '09:07',
          user: 'Felix Pretell',
          type: 'CHANGE',
          field: 'duration',
          newValue: 7,
          newValueUnit: 'DAY'
        },
        {
          time: '07:26',
          user: 'Alcides Queiroz',
          type: 'CHANGE',
          field: 'duration',
          newValue: 1,
          newValueUnit: 'DAY'
        }
      ]
    }
  ];

  yield delay(0);
  yield put({ type: activityLogFetchSucceeded.type, payload: response });
}

export function* updateActivity(activity) {
  const activityCopy = yield call(cloneDeep, activity);
  delete activityCopy.activityObject;
  delete activityCopy.activityReference;
  yield call([activityService, activityService.update], activityCopy);

  /** case subcontract null */
  if (!activityCopy.subcontractId) {
    yield call(
      [activityService, activityService.removeSubcontract],
      activityCopy.id
    );
  }
}

const getGanttInstance = () => window.to_use_react_gantt;

function getNameParents(taskId, activityRoute) {
  const currentTask = window.to_use_react_gantt.getTask(taskId);
  if (!currentTask) return;

  activityRoute.push(currentTask.text);

  if (currentTask.$rendered_parent > 0) {
    getNameParents(currentTask.$rendered_parent, activityRoute);
  }
}
