import { message as antdMessages } from 'antd';

export const copyMessages = (t, selectedTasksToCopy) => ({
  noData: {
    message: t('multiselect_copy_paste.content_no_data_to_copy'),
    type: 'warning'
  },
  tooMuchMessages: {
    message: `${t('multiselect_copy_paste.too_much_content_1')} ${selectedTasksToCopy?.length} ${t('multiselect_copy_paste.too_much_content_2')} `,
    type: 'error'
  },
  success: {
    message: `${selectedTasksToCopy?.length} ${t('multiselect_copy_paste.content_copied')}`,
    type: 'success'
  }
});

const pasteMessages = (t, selectedTasks) => ({
  loading: {
    message: t('multiselect_copy_paste.pasting_msj'),
    type: 'loading'
  },
  success: {
    message: `${selectedTasks} ${t('multiselect_copy_paste.content_pasted')}`,
    type: 'success'
  },
  noData: {
    message: t('multiselect_copy_paste.content_no_data'),
    type: 'warning'
  },
  error: { message: t('multiselect_copy_paste.content_error'), type: 'error' }
});

function messages(t, selectedTasks, isCopy) {
  return isCopy
    ? copyMessages(t, selectedTasks)
    : pasteMessages(t, selectedTasks);
}

const messageAPI = (t, selectedTasksProplanner, isCopy) =>
  messages(t, selectedTasksProplanner, isCopy);

export const launchMessage =
  (isCopy) =>
  (t, selectedTasksProplanner, type, timer = 2) => {
    const messageOptions = messageAPI(t, selectedTasksProplanner, isCopy)[type];
    antdMessages[messageOptions.type](messageOptions.message, timer);
  };
