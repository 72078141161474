import React, { useContext, useEffect, useState } from 'react';
import { Assignmentworkerresources } from '../../../../services';
import { ACTIONS } from '../../scroll.actions';

// Components
import { FixedSizeGrid as Grid } from 'react-window';
import Element from './GridCells';

// Context
import { ProductivityAssignCtx } from '../../../../Context/Productivty/Assign';

// Assets
import style from './style.module.css';

const WorkersProductivityGrid = (props) => {
  const {
    className,
    listWorkers,
    listResources,
    matrixContentRef,
    gridWidth,
    gridHeight,
    updateInline,
    onScroll,
    gridListRef,
    crewWorkers
  } = props;

  const productivityAssign = useContext(ProductivityAssignCtx);

  // ==> useStates
  const [rows, setRows] = useState(0);
  const [columns, setColumns] = useState(0);
  const [changeValueCell, setChangeValueCell] = useState(false);
  const [renderInputCell, setRenderInputCell] = useState({
    colIdx: null,
    rowIdx: null
  });
  const [invalidPercentage, setInvalidPercentage] = useState(false);
  const [widthCell, setWidthCell] = useState(100);
  const [cellUpdated, setCellUpdated] = useState([]);

  // => start useEffect
  useEffect(() => {
    if (listResources) {
      setCellUpdated([]);
      setColumns(listResources.length);
    }
    if (listWorkers) {
      setRows(listWorkers.length);
    }
  }, [listResources, listWorkers]);

  useEffect(() => {
    if (changeValueCell) {
      setChangeValueCell(false);
    }
  }, [changeValueCell]);

  useEffect(() => {
    const width = gridListRef.current.offsetWidth;
    if (columns) {
      const widthPerCell = width / columns;
      widthPerCell > 100 ? setWidthCell(widthPerCell) : setWidthCell(100);
    }
  }, [columns, gridListRef]);

  // => end useEffect

  const handleVisibilityInput = ({ column, row }) => {
    setInvalidPercentage(false);
    setRenderInputCell({ colIdx: column, rowIdx: row });
  };

  const handleBlur = () => {
    setInvalidPercentage(true);
    setRenderInputCell({ colIdx: null, rowIdx: null });
  };

  const handleSubmit = (evt) => {
    try {
      evt.preventDefault();
      const targetForm = evt.target;
      const [percentageValue, invalid = ''] =
        targetForm.percentage.value.split('%');
      const resourcesAssignedId = targetForm.getAttribute(
        'data-sectorresourceId'
      );
      const assistworkerId = targetForm.getAttribute('data-assistworkerId');
      const workerData = JSON.parse(targetForm.getAttribute('data-worker'));

      const hash = {};
      const resources = workerData.resourcesAssigned.filter(function (current) {
        var exists = !hash[current.sectorresourceId];
        hash[current.sectorresourceId] = true;
        return exists;
      });

      const factorsSum = resources.reduce((acc, resource) => {
        if (resource.sectorresourceId !== parseInt(resourcesAssignedId)) {
          acc += resource.factor * 100;
        }

        return acc;
      }, 0);

      if (invalid != '' || isNaN(percentageValue)) {
        throw new Error('invalid percentage');
      }

      const number = parseFloat(percentageValue);
      const totalFactor = number + factorsSum;

      if (number > 100 || totalFactor > 100) {
        throw new Error('invalid range');
      }

      const factor = number / 100;

      Assignmentworkerresources.updateFactor({
        sectorresourceId: resourcesAssignedId,
        assistworkerId: assistworkerId,
        data: {
          factor: factor
        }
      });

      const updatedValue = [
        {
          rowIdx: renderInputCell.rowIdx,
          colIdx: renderInputCell.colIdx,
          factor: number
        }
      ];

      listResources[renderInputCell.colIdx].workersAssigment.forEach(
        (worker) => {
          if (worker.name == workerData.name) {
            worker.factor = factor;
          }
        }
      );

      for (const crew of crewWorkers) {
        for (const worker of crew.workers) {
          if (worker.id != workerData.id) {
            continue;
          }

          for (const resource of worker.resourcesAssigned) {
            if (resource.sectorresourceId != resourcesAssignedId) {
              continue;
            }
            resource.factor = factor;
            break;
          }
          continue;
        }
      }

      evt.target.percentage.value = '';
      updateInline(true);
      setRenderInputCell({ colIdx: null, rowIdx: null });
      setChangeValueCell(true);
      setCellUpdated((prev) => {
        let newState;
        const foundIdx = prev.findIndex(
          (cell) =>
            cell.rowIdx == updatedValue[0].rowIdx &&
            cell.colIdx == updatedValue[0].colIdx
        );

        if (foundIdx >= 0) {
          prev[foundIdx].factor = updatedValue[0].factor;
          newState = [...prev];
        } else {
          newState = [...prev, ...updatedValue];
        }
        return newState;
      });
    } catch (error) {
      setInvalidPercentage(true);
    }
  };

  const handleChange = (evt) => {
    if (evt.keyCode != 13) {
      setInvalidPercentage(false);
    }
    if (evt.keyCode == 27) {
      setRenderInputCell({ colIdx: null, rowIdx: null });
    }
  };

  const handleScroll = (scroll) =>
    onScroll({ scrollFrom: ACTIONS.MATRIX })(scroll);

  if (listWorkers && !listWorkers.length) {
    return null;
  }

  return (
    <div className={className}>
      <Grid
        className={style.gridContainer}
        ref={matrixContentRef}
        columnCount={columns}
        rowCount={rows}
        columnWidth={widthCell}
        rowHeight={25}
        height={gridHeight}
        width={gridWidth}
        onScroll={handleScroll}>
        {Element(
          listWorkers,
          listResources,
          productivityAssign.resourceSelected,
          productivityAssign.workerSelected,
          handleSubmit,
          handleVisibilityInput,
          renderInputCell,
          handleChange,
          invalidPercentage,
          cellUpdated,
          handleBlur
        )}
      </Grid>
    </div>
  );
};

export default WorkersProductivityGrid;
