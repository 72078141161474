/* eslint-disable prefer-const */

/** React components  */
import React, { useState, useEffect } from 'react';

/** Import elements from library Antd */
import { TreeSelect } from 'antd';

/** import library for handle dates */
import moment from 'moment';
/** import services */
import { activityService, constraintService } from '../../../../../services';

/** Redux implementation */
import { useSelector, useDispatch } from 'react-redux';
import { constraintActions } from '../../../../../redux/actions/constraintActions';

import { TaskListIcon } from '../../../../../icons';

export default function SelectActivities(props) {
  /** Redux */
  const constraintState = useSelector((state) => state.constraintState);
  const dispatch = useDispatch();

  /** consts */
  const { SHOW_CHILD } = TreeSelect;

  /** use props */
  const {
    setSelectedTask,
    valSelect,
    setValSelect,
    t,
    visibleForm,
    noDispatch
  } = props;

  /** hooks */
  const [dataRoot, setDataRoot] = useState([]); /** handle select value */
  const [treeTasks, setTreeTasks] = useState([]); /** handle select value */

  /** Project state from redux */
  const projectState = useSelector((state) => state.projectState);

  useEffect(() => {
    if (visibleForm) {
      getActs();
    }
  }, [visibleForm]);

  const getActs = async () => {
    const acts = await activityService.showBySector(
      projectState.sectorSelected
    );
    buildActs(acts);
    // getTasks()
  };

  /** this function loads the tasks every time it starts */
  const getTasks = async () => {
    /** get activities/tasks */
    // const lastLevelActivities = await activityService.getLookahead({
    const lastLevelActivities = await constraintService.getLookahead({
      sector_id: projectState.sectorSelected,
      start: moment(),
      end: moment(),
      ignore_dates: true
    });
    const tasks = [];
    let objTasks = null;
    if (lastLevelActivities) {
      /** get tasks from activities */
      lastLevelActivities.lookahead.map((act) =>
        act.tasks.map((task) => tasks.push(task))
      );
      objTasks = {
        ...lastLevelActivities,
        tasks
      };
      setTreeTasks(objTasks);
      /** add lookahead to constraint state */
      if (noDispatch != true) {
        dispatch(constraintActions.setConstraintLookAhead(objTasks));
      }
    }
    return objTasks;
  };

  const buildActs = async (activitys) => {
    let acts = [];
    activitys.activity.map((act) => {
      const ap = {
        id: parseInt(act.unique_id),
        idbd: act.id,
        proplanner_id: act.id,
        parentId: parseInt(act.parent_id),
        title: act?.correlative_id
          ? `${act.correlative_id} | ${act.name}`
          : act.name,
        level: act.parent_id,
        key: 'act' + act.id,
        value: 'act' + act.id,
        nivel: 0,
        position: 0,
        has_childs: act.has_childs,
        cantidad: '',
        update: false,
        children: null,
        correlative_id: act.correlative_id
      };
      acts.push(ap);
    });
    acts.sort(compare);

    // get tasks
    const tasks = await getTasks();

    let treeData = [];
    tasks.activities.map((act) => {
      let it = {
        title: act.name,
        value: 'act-' + act.id,
        key: 'act-' + act.id,
        id: act.id,
        unique_id: act.unique_id
      };
      const childs = renderTasks(act);
      const arrParents = act.activityRoute
        .split('>')
        .map((e) => e.trim())
        .filter((el, i) => i !== 0);

      if (arrParents.length) {
        it = buildParent(act, childs);
      } else {
        if (childs.length) {
          it.children = childs;
        }
      }
      treeData.push(it);
    });
    list_to_tree(acts, treeData);
  };

  const compare = (a, b) => {
    if (a.correlative_id < b.correlative_id) {
      return -1;
    }
    if (a.correlative_id > b.correlative_id) {
      return 1;
    }
    return 0;
  };

  const list_to_tree = (list, treeData) => {
    let push_array = [];
    let map = {};
    let node;
    let roots = [];
    let i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
      list[i].disabled = false; // initialize disabled
    }

    /** this function traverses the children recursively (object type children) */
    const r2 = (key, object, res) => {
      if (object === 'undefined') {
        return false;
      }
      if (object && object.key == key) {
        res.push(object);
      } else if (object.children && !Array.isArray(object.children)) {
        r2(key, object.children, res);
      }
    };

    const recursiveFindTree = (key, array, res) => {
      array.map((el) => {
        r2(key, el, res);
      });
    };

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentId !== 0) {
        node.key = '0-0-' + map[node.parentId] + '-' + i;
        node.nivel = map[node.parentId];
        push_array.push(node);
        const findIndex = treeData.findIndex((el) => el.id === list[i].idbd);
        if (findIndex !== -1) {
          list[map[node.parentId]].children.push(treeData[findIndex]);
        } else {
          list[map[node.parentId]].children.push(node);
        }
      } else {
        roots.push(node);
      }
    }

    /** second iteration after structure builded */
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentId !== 0) {
        const findIndex = treeData.findIndex((el) => el.id === list[i].idbd);
        if (findIndex == -1) {
          if (!node.children.length) {
            const customKey = 'act-' + node.idbd;
            const children = [];
            recursiveFindTree(customKey, treeData, children);
            if (children.length) {
              list[map[node.id]].children = children[0].children;
            }
          }
        }
      }
    }
    recursiveDisabled(roots);
    setDataRoot(roots[0]?.children);
    return true;
  };

  /**
   * Recursive function to disable elements on TreeSelect Component
   * @param {*} array Array of elements to draw on TreeSelect Component
   */
  const recursiveDisabled = (array) => {
    array.map((el) => {
      const childs = el.children;
      recursiveDisabled(childs);
      if (childs) {
        /** all childs are activities && disabled */
        const allActs = childs.every(
          (el) => el.disabled && !el.value.includes('task-')
        );
        el.disabled = allActs;

        /** last level (tasks) */
        if (!childs.length && el.value.includes('task-')) {
          el.disabled = false;
        }
      }
      el.children = childs;
      return el;
    });
  };

  /**
   * Recursive function to display at virtual dom the tree nested table format
   * @param {*} tasks Array of tasks
   * @param {*} finalArray Final array to display JSX at virtual dom
   * @param {*} activity Parent activity
   * @param {*} tabulation Padding left to display tree table format
   * @param {*} parentTask Instance of the parent task if it is not from first lvl
   */
  const renderChildTreeTasks = (tasks, act) => {
    const newData = tasks.map((task) => {
      const childs = task.children;
      const hasChilds = childs.length != 0;
      const newItem = {
        title: task.name,
        value: 'task-' + task.id,
        className: 'task-class',
        key: 'task-' + task.id,
        children: []
      };
      if (hasChilds) {
        newItem.children = renderChildTreeTasks(childs, act);
      }
      return newItem;
    });
    return newData;
  };

  /**
   * This function is used by virtual dom to render tasks associated to activity
   * @param {*} activity Activity object to show his child tasks
   */
  const renderTasks = (activity) => {
    const tasks = renderChildTreeTasks(activity.tasks, activity);
    return tasks;
  };

  const getNodeRecursive = (arr, childs, act) => {
    if (arr.length === 0) {
      const tmp = {
        title: act.name,
        value: 'act-' + act.id,
        key: 'act-' + act.id
      };
      tmp.children = childs;
      return tmp;
    }
    const tmpNode = {
      title: arr[0],
      value: 'lev-' + '00' + arr[0],
      key: 'lev-' + '00' + arr[0]
    };
    const nextChild = getNodeRecursive(
      arr.filter((e, i) => i !== 0),
      childs,
      act
    );
    if (nextChild) {
      tmpNode.children = nextChild;
    }
    return tmpNode;
  };

  const buildParent = (act, childs) => {
    const arrParents = act.activityRoute
      .split('>')
      .map((e) => e.trim())
      .filter((el, i) => i !== 0);
    const objRec = getNodeRecursive(arrParents, childs, act);
    return objRec;
  };

  const onChange = (e) => {
    setSelectedTask(e);
    setValSelect({ selected: e });
  };

  const renderSelectTasksEmpy = () => (
    <div className="select-tasks__container">
      <TaskListIcon color="#FFF" />
      <span>{t('select_activities_label_select')}</span>
    </div>
  );

  const tProps = {
    showSearch: true,
    treeData: dataRoot,
    value: valSelect.selected,
    onChange: onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_CHILD,
    placeholder: renderSelectTasksEmpy(),
    className: `select-tasks ${constraintState.constraintForm.type === null ? 'disabled' : ''}`,
    dropdownClassName: 'select-acts',
    treeDefaultExpandAll: false,
    treeDefaultExpandedKeys: ['0-0-0-0']
  };

  /** render */
  return treeTasks.activities ? (
    <TreeSelect
      {...tProps}
      disabled={false}
      // disabled={constraintState.constraintForm.type === null}
    />
  ) : null;
}
