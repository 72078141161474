import { cloneDeep } from 'lodash';

function updateStacks(setAutoscheduling, setAutoschedulingVisual) {
  const gantt = window.to_use_react_gantt;
  const allLinks = gantt.getLinks();

  setAutoscheduling(false);
  setAutoschedulingVisual(false);
  gantt.clearUndoStack();
  gantt.clearRedoStack();
  gantt.legacy_corrected_activities.map((t) => {
    t.freeSlack = t.non_parsed_freeslack;
    t.ponderator = t.non_parsed_ponderator;
    t.is_critical = t.non_parsed_is_critical ? 'Si' : 'No';
  });
  /** Links same behaviour */
  gantt.legacy_corrected_links = cloneDeep(allLinks);
}

export { updateStacks };
