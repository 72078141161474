import React, { useContext, useEffect, useReducer, useState } from 'react';
import { ACTIONS, initState, reducer } from './reducers';
import { Assignmentworkerresources } from '../../services';
import { Toast } from '../../views/taktplanning/assign/index.helper';
import { notifyMessage } from '../../utils/lookahead-common';

// Components
import { Button, notification, Row } from 'antd';
import Loading from '../Loadign';
import SettingsHours from './SettignsHours';

// Context
import { ProductivityAssignCtx } from '../../Context/Productivty/Assign';

// Assets
import styles from './index.module.css';
import { UserOutlined } from '@ant-design/icons';
import assignedCreated from '../../assets/img/productivity/workerAssigned.svg';

const ProductivtyActions = (props) => {
  const { className, dateInit, hoursPerDay, workersAbsent, workersPresent, t } =
    props;

  const productivityAssign = useContext(ProductivityAssignCtx);
  const { resourceSelected, workerSelected } = productivityAssign;

  const [state, dispatch] = useReducer(reducer, initState);

  const [loadingAssignment, setLoadingAssigment] = useState(false);
  const [assignOption, setAssignOption] = useState('');

  useEffect(() => Toast.init(), []);

  useEffect(() => {
    if (workerSelected.length && resourceSelected.length) {
      return assignment();
    }
    dispatch({ type: ACTIONS.ASSIGN_ACTION, payload: true });
    dispatch({ type: ACTIONS.DISABLE_BTN, payload: true });
    dispatch({ type: ACTIONS.DATA_TO_ASSIGN, payload: null });
  }, [workerSelected, resourceSelected]);

  const addResourcesToWorkers = (toAssign, { fill = false } = {}) => {
    workerSelected.forEach((worker) => {
      worker.active = false;
      toAssign.forEach((assignment, _idx, arrayAssign) => {
        if (fill) {
          const workerFound = worker.resourcesAssigned.find(
            (resource) =>
              assignment.assistworkerId == resource.assistworkerId &&
              assignment.sectorresourceId != resource.sectorresourceId
          );
          if (
            assignment.assistworkerId == workerFound?.assistworkerId &&
            worker.resourcesAssigned.length <= arrayAssign.length
          ) {
            worker.resourcesAssigned.push({
              ...workerFound,
              sectorresourceId: assignment.sectorresourceId,
              factor: assignment.fill
            });
          }
        } else {
          if (worker.resourcesAssigned.length) {
            worker.resourcesAssigned.forEach((resource) => {
              const workerFound = worker.resourcesAssigned.find(
                (src) =>
                  assignment.assistworkerId == src.assistworkerId &&
                  assignment.sectorresourceId != src.sectorresourceId
              );
              if (
                assignment.assistworkerId == workerFound?.assistworkerId &&
                worker.resourcesAssigned.length <= arrayAssign.length
              ) {
                resource.factor = assignment.factor;
                worker.resourcesAssigned.push({
                  ...workerFound,
                  sectorresourceId: assignment.sectorresourceId
                });
              }
            });
          } else {
            worker.resourcesAssigned.push({
              ...assignment
            });
          }
        }
      });
    });

    resourceSelected.forEach((resource) => {
      resource.active = false;
      toAssign.forEach((assignment) => {
        if (fill) {
          if (resource.workersAssigment.length) {
            const resourceFound = resource.workersAssigment.find(
              (resource) => assignment.assistworkerId != resource.assistworkerId
            );
            if (resourceFound && assignment.sectorresourceId == resource.id) {
              resource.workersAssigment.push({
                assistworkerId: assignment.assistworkerId,
                sectorresourceId: assignment.sectorresourceId,
                name: assignment.name,
                factor: assignment.fill,
                date: dateInit.format('YYYY-MM-DD')
              });
            }
          } else if (assignment.sectorresourceId == resource.id) {
            resource.workersAssigment.push({
              ...assignment
            });
          }
        } else {
          if (resource.workersAssigment.length) {
            resource.workersAssigment.forEach((worker) => {
              if (worker.date.includes(dateInit.format('YYYY-MM-DD'))) {
                worker.factor = assignment.factor;
              }
            });

            if (resource.id == assignment.sectorresourceId) {
              resource.workersAssigment.push({
                assistworkerId: assignment.assistworkerId,
                sectorresourceId: assignment.sectorresourceId,
                name: assignment.name,
                factor: assignment.factor,
                date: dateInit.format('YYYY-MM-DD')
              });
            }
          } else {
            resource.workersAssigment.push({
              ...assignment,
              sectorresourceId: resource.id
            });
          }
        }
      });
    });

    setAssignOption('');
  };

  const removeResourcesToWorkers = (toUnassign) => {
    workerSelected.forEach((worker) => {
      worker.active = false;
      toUnassign.forEach((unassign) => {
        const idx = worker.resourcesAssigned.findIndex(
          (resource) =>
            resource.assistworkerId == unassign.assistworkerId &&
            resource.sectorresourceId == unassign.sectorresourceId
        );
        idx >= 0 && worker.resourcesAssigned.splice(idx, 1);
      });
    });

    resourceSelected.forEach((resource) => {
      resource.active = false;
      toUnassign.forEach((unassign) => {
        const idx = resource.workersAssigment.findIndex(
          (assigment) =>
            assigment.assistworkerId == unassign.assistworkerId &&
            assigment.sectorresourceId == unassign.sectorresourceId
        );
        idx >= 0 && resource.workersAssigment.splice(idx, 1);
      });
    });
  };

  const linkedWorker = (acc, resource, _, totalResoucers) => {
    const linked = workerSelected
      .map((worker) => {
        const totalFactor = worker.resourcesAssigned.reduce((acc, resource) => {
          acc += resource.factor;
          return acc;
        }, 0);

        const factor =
          1 / (worker.resourcesAssigned.length + totalResoucers.length);

        const assignInfo = {
          recalculate: totalFactor >= 1,
          name: worker.name,
          factor: factor,
          sectorresourceId: resource.id,
          assistworkerId: worker.assist.id,
          date: dateInit.format('YYYY-MM-DD')
        };

        if (totalFactor < 1 && totalFactor > 0) {
          const missingToHoundred = 1 - totalFactor;
          const factorToFill = missingToHoundred / totalResoucers.length;
          assignInfo.fill = factorToFill;
        }

        return assignInfo;
      })
      .flat();

    acc.push(linked);
    return acc.flat();
  };

  const verifyAssignAction = () => {
    const assignedAction = {
      unassign: []
    };

    const resourcesCurrentDate = resourceSelected.reduce((acc, resource) => {
      const resourcesDate = resource.workersAssigment.filter((assignment) =>
        assignment.date.includes(dateInit.format('YYYY-MM-DD'))
      );
      acc.push(resourcesDate);
      return acc.flat();
    }, []);

    const matchResource = workerSelected.reduce((acc, worker) => {
      const resource = resourcesCurrentDate.filter(
        (resource) => resource.assistworkerId == worker.assist.id
      );
      acc.push(resource);
      return acc.flat();
    }, []);

    workerSelected.forEach((worker) => {
      worker.resourcesAssigned.forEach((assignment) => {
        matchResource.forEach((resource) => {
          if (
            assignment.assistworkerId === resource.assistworkerId &&
            assignment.sectorresourceId === resource.sectorresourceId
          ) {
            assignedAction.unassign.push({
              assistworkerId: resource.assistworkerId,
              sectorresourceId: resource.sectorresourceId
            });
          }
        });
      });
    });

    return assignedAction;
  };

  const willAssign = (resourcesLinked) => {
    dispatch({ type: ACTIONS.ASSIGN_ACTION, payload: true });
    dispatch({ type: ACTIONS.DISABLE_BTN, payload: false });
    dispatch({ type: ACTIONS.DATA_TO_ASSIGN, payload: resourcesLinked });
  };

  const willUnassign = (resourcesUnassigned) => {
    dispatch({ type: ACTIONS.ASSIGN_ACTION, payload: false });
    dispatch({ type: ACTIONS.DISABLE_BTN, payload: false });
    dispatch({ type: ACTIONS.DATA_TO_ASSIGN, payload: resourcesUnassigned });
  };

  const notifications = (resourcesLinked) => {
    const procced = resourcesLinked.filter((resource) => !resource.recalculate);
    if (resourcesLinked.length != procced.length) {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={async () => {
            notification.close(key);
            addResourcesToWorkers(resourcesLinked, { fill: false });
            willAssign(resourcesLinked);
            await sendingData(resourcesLinked);
            productivityAssign.cleanSelection();
          }}>
          {t('productivity.assign.accept')}
        </Button>
      );

      if (resourcesLinked.filter((resource) => resource.fill).length) {
        throw new Error('colapse');
      }

      notification.info({
        message: 'Assignaciones tienen un 100%',
        description: 'Quieres que nosotros recalculemos los porcentajes?',
        btn,
        key
      });

      return true;
    }

    if (resourcesLinked.filter((resource) => resource.fill).length) {
      const key = `open${Date.now()}`;
      const btn = (
        <div>
          <Button
            size="small"
            onClick={async () => {
              notification.close(key);
              resourcesLinked.forEach((resource) => {
                if (resource.fill) {
                  resource.recalculate = true;
                }
              });

              addResourcesToWorkers(resourcesLinked, { fill: false });
              willAssign(resourcesLinked);
              await sendingData(resourcesLinked);
              productivityAssign.cleanSelection();
            }}>
            {t('productivity.assign.accept')}
          </Button>
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: 10 }}
            onClick={async () => {
              notification.close(key);
              addResourcesToWorkers(resourcesLinked, { fill: true });
              willAssign(resourcesLinked);
              await sendingData(resourcesLinked);
              productivityAssign.cleanSelection();
            }}>
            Agregar
          </Button>
        </div>
      );

      notification.info({
        message: 'Assignaciones menos de 100%',
        description:
          'Quieres que nosotros recalculemos los porcentajes o llenamos el restante?',
        btn,
        key
      });
      return true;
    }

    return false;
  };

  const assignment = () => {
    try {
      const { unassign } = verifyAssignAction();
      const resourcesLinked = resourceSelected.reduce(linkedWorker, []);

      if (
        (unassign.length && unassign.length < resourcesLinked.length) ||
        (!unassign.length && !resourcesLinked.length)
      ) {
        throw new Error('Multiple assign actions');
      }

      if (unassign.length) {
        return willUnassign(unassign);
      }

      willAssign(resourcesLinked);
    } catch (e) {
      dispatch({ type: ACTIONS.ASSIGN_ACTION, payload: true });
      dispatch({ type: ACTIONS.DISABLE_BTN, payload: true });
      dispatch({ type: ACTIONS.DATA_TO_ASSIGN });
    }
  };

  const handleClickAssignAction = async () => {
    const { toAssign, assignAction } = state;
    assignAction
      ? createAssignment(toAssign)
      : await unLinkAssignment(toAssign);
  };

  const unLinkAssignment = async (data) => {
    try {
      setLoadingAssigment(true);

      removeResourcesToWorkers(data);

      await Assignmentworkerresources.bulkDelete({
        assigmentIds: JSON.stringify(data)
      });

      const toastMessage = `
            <div class=${styles.toastContianer}>
                <img class="${styles.toastImg}" src="${assignedCreated}" alt="icon assigned" />
                <div>
                    ${t('productivity.assign.willUnassign')}
                    <span style="color: #1890FF"> ${workerSelected.length} ${t('productivity.assign.workers')}</span>
                    ${t('productivity.assign.assigned_to')}
                    <span style="color: #7DFF8A"> ${resourceSelected.length} ${t('productivity.assign.resources')}</span>
                </div>
            </div>
            `;

      Toast.show(toastMessage, 'unassign', 4000);
      productivityAssign.cleanSelection();
    } catch (e) {
      notifyMessage({
        title: t('productivity.assign.errormsg'),
        type: 'error'
      });
    } finally {
      setLoadingAssigment(false);
    }
  };

  const firstAssigments = async (data) => {
    data.forEach((resources) => {
      resources.recalculate = true;
    });
    addResourcesToWorkers(data, { fill: false });
    willAssign(data);
    await sendingData(data);
    productivityAssign.cleanSelection();
  };

  const createAssignment = (data) => {
    try {
      if (!notifications(data)) {
        firstAssigments(data);
      }
    } catch (e) {
      dispatch({ type: ACTIONS.ASSIGN_ACTION, payload: true });
      dispatch({ type: ACTIONS.DISABLE_BTN, payload: true });
      dispatch({ type: ACTIONS.DATA_TO_ASSIGN });
    }
  };

  const sendingData = async (data) => {
    try {
      setLoadingAssigment(true);
      const result = await Assignmentworkerresources.create(data);

      if (!result) {
        throw new Error();
      }

      const toastMessage = `
            <div class=${styles.toastContianer}>
                <img class="${styles.toastImg}" src="${assignedCreated}" alt="icon assigned" />
                <div>
                    ${t('productivity.assign.assigned')}
                    <span style="color: #1890FF"> ${workerSelected.length} ${t('productivity.assign.workers')}</span>
                    ${t('productivity.assign.assigned_to')}
                    <span style="color: #7DFF8A"> ${resourceSelected.length} ${t('productivity.assign.resources')}</span>
                </div>
            </div>
            `;

      productivityAssign.cleanSelection();
      Toast.show(toastMessage, 'assign', 4000);
      // updateData({ buildResourceTree: true })
    } catch (e) {
      notifyMessage({
        title: t('productivity.assign.errormsg'),
        type: 'error'
      });
    } finally {
      setLoadingAssigment(false);
    }
  };

  return (
    <Row className={className}>
      {loadingAssignment && <Loading />}
      <div>
        <UserOutlined style={{ color: '#34AD00' }} />
        <span>
          {workersPresent} {t('productivity.assign.present')}
        </span>
      </div>
      <div>
        <UserOutlined style={{ color: '#E50101' }} />
        <span>
          {workersAbsent} {t('productivity.assign.absent')}
        </span>
      </div>
      <SettingsHours hoursPerDay={hoursPerDay} t={t} />
      <button
        disabled={state.disableBtn}
        className={`${styles.assignBtn} ${!state.assignAction && styles.unassign} ${state.disableBtn && styles.disabled}`}
        onClick={handleClickAssignAction}>
        {state.assignAction
          ? t('productivity.assign.assign')
          : t('productivity.assign.unassign')}
      </button>
    </Row>
  );
};

export default ProductivtyActions;
