import React from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import styles from './SummaryRow.module.scss';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ACTIONS } from '../../scroll.actions';

export const GrindSummaryRow = ({
  data,
  resourcesIds,
  gridHeight,
  totalGridRef,
  onScroll,
  nowIndexValidate
}) => {
  const rowCount = resourcesIds.length;
  const rows = [];
  const { partialValues } = data[0];
  const generateRows = () => {
    resourcesIds.forEach((resource) => {
      let values = 0;
      let count = 0;
      partialValues.map((value) => {
        if (value.weeks.data[resource] != undefined) {
          if (count < nowIndexValidate) {
            values += value.weeks.data[resource];
          }
        }
        count++;
      });
      rows.push(values.toFixed(2));
    });
  };
  generateRows();
  const cells =
    (cell) =>
    ({ rowIndex, style }) => {
      return (
        <div style={style} className={styles.cell}>
          {cell[rowIndex]}
        </div>
      );
    };
  const handleScroll = (scroll) =>
    onScroll({ scrollFrom: ACTIONS.TOTAL })(scroll);

  return (
    <div>
      <AutoSizer>
        {({ height }) => (
          <Grid
            className={styles.summaryGrid}
            ref={totalGridRef}
            columnCount={1}
            rowCount={rowCount}
            columnWidth={100}
            rowHeight={25}
            width={100}
            height={height}
            onScroll={handleScroll}>
            {cells(rows)}
          </Grid>
        )}
      </AutoSizer>
    </div>
  );
};
