import React from 'react';
import styled from 'styled-components';

function NavCategories() {
  return (
    <StyleNavCategories>
      <div style={{ borderRight: '1px solid #DFDFDF', padding: '10px' }}>
        <h1 style={{ fontSize: '16px' }}>Categories</h1>
        <p className="txt-categories-size bg-active-nav-categories">
          <a href="/" className="align-txt-categories-nav">
            All
          </a>
        </p>
        <p className="txt-categories-size">
          <a href="/" className="align-txt-categories-nav">
            Building in height
          </a>
        </p>
        <p className="txt-categories-size">
          <a href="/" className="align-txt-categories-nav">
            Building in extension
          </a>
        </p>
        <p className="txt-categories-size">
          <a href="/" className="align-txt-categories-nav">
            Malls
          </a>
        </p>
        <p className="txt-categories-size">
          <a href="/" className="align-txt-categories-nav">
            Hotels
          </a>
        </p>
      </div>
    </StyleNavCategories>
  );
}

export default NavCategories;

const StyleNavCategories = styled.div`
  .align-txt-categories-nav {
    position: relative;
    left: 15px;
    color: gray;
  }
  .txt-categories-size {
    font-size: 12px;
  }
  .align-txt-categories-nav:hover {
    color: black;
  }
  .bg-active-nav-categories {
    background-color: #f1f1f1;
    padding-top: 2px;
    padding-bottom: 2px;
  }
`;
