/** React components  */
import React, { useState, useEffect, useRef, createRef } from 'react';

import SelectSearch from 'react-select-search';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/** To animating views easely */
import { Animated } from 'react-animated-css';

import CustomPaginator from '../../../components/CustomPaginator';

/** import library for handle dates */
import 'moment/locale/es';
import moment from 'moment';

/** Build components from antd css framework */
import { Row, Col } from 'antd';
import {
  Spin,
  Icon,
  Empty,
  Popover,
  DatePicker,
  Popconfirm,
  message
} from 'antd';
import quitDismissIcon from '../../../assets/img/gantt/quit-header-config.png';
import ConstraintForm from '../../../components/LookAhead/Constraints/ConstraintForm';
import IconComponent from '../../../components/Projects/IconSvg';
import CardTask from '../../../components/CardTask';
import {
  handshake,
  helmet,
  excludeDeep,
  belongsToRange,
  updateResources,
  getTodayWeekWithPday
} from '../../../utils';
import { materialMassiveSvg } from '../../../utils/svgIcons';
import { dynamicSort } from '../../../utils';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
/** Services */
import { activityService } from '../../../services/activity.service';
import { userService } from '../../../services/user.service';
import { taskService } from '../../../services/task.service';
import {
  calendarService,
  subContractService,
  sectorService
} from '../../../services';
import { historicalActivityProgressService } from '../../../services/historicalactivityprogress.service';
/** Plain text CSS file (react pretty features) */
import './index.css';

import AnimatedSortable from '../../../components/AnimatedSortable';
/** Redux */
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { lookaheadActions } from '../../../redux/actions/lookaheadResourceActions';

/** JSON with table distribution */
import { tableMetadata } from './table.layout';

/** Single draggable column component */
import SingleLookaheadOrderCol from '../../../components/SingleLookaheadOrderCol';

/** Single table with activity as header and tasks as childs */
import LookaheadActivityResource from '../../../components/LookAHeadResource';

import LookaheadOtherGroup from '../../../components/LookaheadOtherGroup';

/** Header with filters options component */
import LookaheadFilterHeader from '../../../components/LookaheadFilterHeader';

/** Function to clone objects on JS */
import cloneDeep from 'lodash/cloneDeep';
import differenceBy from 'lodash/differenceBy';
import { capitalize } from 'lodash';

/** Function to keep states replacing specific elements */
import update from 'immutability-helper';
import { firstBy } from 'thenby';

/** Massive actions icons */
import constraintMassive from '../../../assets/img/massive/constraint.png';
import datesMassive from '../../../assets/img/massive/dates.png';
import deleteMassive from '../../../assets/img/massive/delete.png';
import priorityMassive from '../../../assets/img/massive/priority.png';
import progressMassive from '../../../assets/img/massive/progress.png';
import responsableMassive from '../../../assets/img/massive/responsable.png';
import leanMassive from '../../../assets/img/massive/lean.png';
import duplicateMassive from '../../../assets/img/massive/duplicate.png';

/** PNG to put on users without image */
import fakeAvatar from '../../../assets/img/fake_user.png';

import ModalAddSubContract from '../../../components/Settings/ModalAddSubContract';
import {
  calculatePonderators,
  getTask,
  getTasksToDelete,
  deleteTaskHandler,
  notifyMessage,
  calculateProgress,
  getEndDateByGantt
} from '../../../utils/lookahead-common';

import Range from 'react-range-progress';
import { ganttAPI } from '../../../utils/customGanttPlugin';
import { Select, Tooltip } from 'antd';
import { socket } from '../../../services/socket.service';
import { withTranslation } from 'react-i18next';
import ModalAddResources from '../../../components/ModalAddResources';
import { MassiveTotalMaterial } from '../../../components/MassiveTotalMaterial';
/** RangePicker builded components to use datepicker from start to end in just one input :) */
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const { Option } = Select;
const ProgressBar = (props) => {
  const { value, setValue } = props;
  const [progress, setProgress] = useState(value);

  return (
    <div
      onMouseUp={() => {
        setValue(progress);
      }}
      style={{ paddingLeft: 5 }}>
      <span>{progress}%</span>
      <Range
        className="progress-massive-bar"
        style={{ top: -2 }}
        value={progress}
        fillColor={{
          r: 245,
          g: 157,
          b: 4,
          a: 1
        }}
        trackColor={{
          r: 245,
          g: 245,
          b: 245,
          a: 1
        }}
        height={7}
        width="58%"
        onChange={(value) => {
          setProgress(value);
        }}
      />
    </div>
  );
};

/**
 * This view shows activities from last level.
 * Then user can through a filter change range date showed.
 * Then, user can add on eagger data model tasks, associating them with the activity ID as a super parent.
 * @param {*} props
 */
function ResourcesView(props) {
  const { t } = props;

  const [currentPage, setCurrentPage] = useState(0);

  /** hooks card */
  const [cardState, setCardState] = useState({ visible: false });
  const [currentTask, setCurrentTask] = useState(null);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [currentWeekWithPday, setCurrentWeekWithPday] = useState();
  const [allActivities, setAllActivities] = useState(
    {}
  ); /** state for handle lastlevelactivities without reference */

  const [lastLevelActivitiesCard, setLastLevelActivitiesCard] = useState(null);
  const [resourcesMap, setResourcesMap] = useState([]);

  const [popsVisibility, setPopsVisibility] = useState({});
  const { permission } = props;

  /** handle Form Add Constraint */
  const [visibleForm, setVisibleForm] = useState({ value: false, tasks: [] });

  /** Project state from redux */
  const projectState = useSelector((state) => state.projectState);
  const lookaheadResourceState = useSelector(
    (state) => state.lookaheadResourceState
  );
  const dispatch = useDispatch();
  const [isTree, setIsTree] = useState({ value: true });

  /** Activities to handle table loading */
  const [activities, setActivities] = useState([]);
  const [activityResource, setActivityResource] = useState(null);

  /** Calendar to handle table loading */
  const [calendars, setCalendars] = useState([]);

  /** Array Calendar with custon expected lookahead  to handle table loading */
  const [sectorResoruce, setSectorResoruce] = useState([]);
  const [subContracts, setSubcontracts] = useState([]);

  /** Array with users that belongs to the same sector that this master plan */
  const [toSelectResponsables, setToSelectResponsables] = useState([]);

  /** Flag to know when component is on loading step */
  const [isLoading, setLoading] = useState(true);
  const [toActivityDuplicate, setToActivityDuplicate] = useState({
    value: null
  });

  /** Object to handle start and end dates to show last level activities from master plan  */
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  const [groupBy, setGroupBy] = useState({
    criteria: 'activityId',
    sort: 'asc'
  });
  const [existFilter, setExistFilter] = useState(false);

  /** Variable to view and his components to handle table manipulation by user */
  const [tableConfig, setTableConfig] = useState([]);

  const [resizing, setResizing] = useState(false);

  const [massiveSelection, setMassiveSelection] = useState([]);

  const { height, width } = useWindowDimensions();

  const [massiveProgress, setMassiveProgress] = useState(0);

  const [scrollStates, setScrollStates] = useState({});

  const [massiveResponsableState, setMassiveResponsableState] = useState({
    values: []
  });

  const [massiveOnProcess, setMassiveOnProcess] = useState(false);

  const [typeResource, setTypeResource] =
    useState('material'); /** material, machinery, rrhh */

  const [visibleFormResource, setVisibleFormResource] = useState(false);

  /** Full width feature */
  const [totalTablesWidth, setTotalTablesWidth] = useState(0);
  let paginatorRef = useRef();
  const [visibleFormSubcontract, setVisibleFormSubcontract] = useState(false);

  const [ReloadTableUsers, setReloadTableUsers] = useState(true);
  const [tableUpdated, setTableUpdated] = useState(false);
  const [taskSelected, setTaskSelected] = useState();
  const [totalMaterialMassive, setTotalMaterialMassive] = useState(0);

  useEffect(() => {
    if (tableConfig.length) {
      const getData = async () => {
        const response = await subContractService.getByProject(
          projectState.projectSelected
        );
        setSubcontracts(response.subcontracts);
      };
      if (ReloadTableUsers) {
        getData();
      }
    }
  }, [ReloadTableUsers]);

  useEffect(() => {
    /** get week number */
    const todayWeek = getTodayWeekWithPday(projectState);
    setCurrentWeekWithPday(todayWeek);
  }, []);

  /** Effect to load translation to table declaration file */
  useEffect(() => {
    setTimeout(() => {
      const copyOfTableData = [...tableMetadata];
      copyOfTableData.map((column) => {
        if (
          column.data_type.includes('/icon') ||
          column.data_type.includes('/string')
        ) {
          column.label = t(
            'tables.lookahead.resources.' + column.name + '.label'
          );
          column.from_values.map((option) => {
            option.label = t(
              'tables.lookahead.resources.' +
                column.name +
                '.options.' +
                option.value
            );
          });
        } else {
          column.label = t('tables.lookahead.resources.' + column.name);
        }
      });
      setTableConfig(copyOfTableData);
      setTableUpdated(true);
    }, 500);
  }, []);

  /**
   * This function render Form Add Constraint
   */
  const renderFormAddResources = () => (
    <ModalAddResources
      t={t}
      typeResource={typeResource}
      Visible={visibleFormResource}
      setVisible={setVisibleFormResource}
      setReloadTableUsers={setReloadTableUsers}
      activityResource={activityResource}
      setLastLevelActivities={setLastLevelActivitiesCard}
      lastLevelActivities={lastLevelActivitiesCard}
      resourceAdded={(resource) => {
        let resourceRoute;
        switch (typeResource) {
          case 'material':
            resourceRoute = 'materialId';
            break;
          case 'rrhh':
            resourceRoute = 'specialtyId';
            break;
          case 'machinery':
            resourceRoute = 'machineId';
            break;
          default:
            resourceRoute = 'materialId';
            break;
        }
        const doesExistAtReference = getTask(
          taskSelected.id,
          null,
          activityResource
        );
        if (doesExistAtReference[0]) {
          const reference = doesExistAtReference[0];
          reference[resourceRoute] = resource.id;
        }
        const saveObject = {
          ...taskSelected,
          [resourceRoute]: resource.id
        };
        updateAsyncTask(saveObject);
        const copy = cloneDeep(taskSelected);
        socket.emit('task_update', {
          sector: projectState.sectorSelected,
          task: copy
        });
      }}
      assignResourceOnCreate={false}
    />
  );

  /**
   * This function render Form Add Constraint
   */
  const renderFormAddSubContracts = () => (
    <ModalAddSubContract
      t={t}
      Visible={visibleFormSubcontract}
      setVisible={setVisibleFormSubcontract}
      setReloadTableUsers={setReloadTableUsers}
      ReloadTableUsers={ReloadTableUsers}
    />
  );

  useEffect(() => {
    dispatch(lookaheadActions.notifyLookaheadUpdateGroup());

    setTimeout(() => {
      dispatch(lookaheadActions.notifyLookaheadUpdateOrder());
    }, 50);

    setTimeout(() => {
      dispatch(lookaheadActions.notifyLookaheadUpdateFilter());
    }, 100);
  }, [lookaheadResourceState.notifyChange]);

  useEffect(() => {
    if (!resizing) {
      let tablesWidth = 0;
      tableConfig.map((el) => {
        if (el.visible) {
          tablesWidth += el.width;
        }
      });
      setTotalTablesWidth(tablesWidth);
    }
  }, [tableConfig]);

  useEffect(() => {
    if (paginatorRef.current) {
      paginatorRef.current.resetAfterColumnIndex(0);
    }
  }, [totalTablesWidth]);

  /**
   * This function handles with resize of cols inside the table from dragging resize  from header titles
   * @param {*} width Updated width catched from the event
   * @param {*} colMetadata Single column metadata that were resized
   */
  const onDivResizeHandler = (width, colMetadata) => {
    if (colMetadata.doubleClicked) {
      colMetadata.width = width;
      colMetadata.doubleClicked = false;
      updateTableMetadata();
    }
  };

  /**
   * This function handles the click, and the unclick from user when the resize is catched
   * @param {*} col Single column metadata that is going to be resized
   * @param {*} val Boolean, true for start resizing, false for end resizing
   */
  const onDivResizeDoubleClicked = (col, val) => {
    col.doubleClicked = val;
    // setResizing(val)
    // updateTableMetadata()
  };

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);
    props.setContainerHeader('');

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  /** This effect allows to load initial lookahead range, and reacts to change on sectorSelected from redux */
  useEffect(() => {
    /** Set default dates as today with 2 weeks forward */
    let start = new Date();
    let end = new Date();
    end.setDate(end.getDate() + 100);
    start = start.toISOString().split('T')[0].split('-').join('/');
    end = end.toISOString().split('T')[0].split('-').join('/');

    /** Then using set state hook load this vars, to let virtual dom load datepickers with correct dates */
    setDateRange({
      start,
      end
    });

    /** Loads activities with their tasks */
  }, [projectState.sectorSelected]);

  useEffect(() => {
    if (tableConfig.length && tableUpdated) {
      if (dateRange.start != '' || dateRange.end != '') {
        getLookahead();
      }
    }
  }, [dateRange, tableUpdated]);

  const modifyGroupBy = async (newGroupBy, data = activities) => {
    if (newGroupBy.criteria == 'activityId') {
      if (!isTree.value) {
        data = await getLookahead(false);
        isTree.value = true;
      }
      /* data.sort(firstBy('correlative_id', { direction: newGroupBy.sort, ignoreCase: true })) */
      if (newGroupBy.sort.toLowerCase() == 'asc') {
        data.sort(
          (a, b) => parseInt(a.correlative_id) - parseInt(b.correlative_id)
        );
      } else {
        data.sort(
          (a, b) => parseInt(b.correlative_id) - parseInt(a.correlative_id)
        );
      }
      isTree.value = true;
    } else if (newGroupBy.criteria == 'activity') {
      if (!isTree.value) {
        data = await getLookahead(false);
        isTree.value = true;
      }
      data.sort(
        firstBy('name', { direction: newGroupBy.sort, ignoreCase: true })
      );
      isTree.value = true;
    } else {
      let col = tableConfig.filter((el) => el.name == newGroupBy.criteria);
      if (col.length != 0) {
        col = col[0];
        if (
          newGroupBy.criteria == groupBy.criteria &&
          newGroupBy.sort != groupBy.sort
        ) {
          data.sort(
            firstBy('value', { direction: newGroupBy.sort, ignoreCase: true })
          );
        } else {
          if (isTree.value || newGroupBy.criteria != groupBy.criteria) {
            const lastLevelActivities =
              await activityService.getLookaheadesource({
                sector_id: projectState.sectorSelected,
                start: dateRange.start,
                end: dateRange.end,
                groupBy: newGroupBy.criteria
              });
            data = lastLevelActivities.lookahead;
            isTree.value = false;
          }
          if (groupBy.criteria !== newGroupBy.criteria) {
            quitDismissHandler();
          }

          let newData = [];
          if (col.from_values == 'toSelectResponsables') {
            newData = toSelectResponsables.map((el) => ({
              value: el[col.el_to_extract_from],
              name: `${el.name} ${el.lastname}`,
              img: el.image,
              tasks: []
            }));
            /** Bug fix: 278 airtable ID */
            newData.push({
              value: null,
              name: t('filters_label.no_responsible'),
              img: null,
              tasks: []
            });
          } else if (col.from_values.length) {
            let newSpace = col.from_values;
            if (col.mixed) {
              newSpace = col.from_values.filter((from) => {
                if (col.mixed_from.includes(from.value)) {
                  return false;
                }
                return true;
              });
            }

            newData = newSpace.map((el) => ({
              value: el.value,
              tasks: [],
              label: el.label
            }));
          }

          data.map((activity) => {
            activity.tasks.map((task) => {
              newData.map((singleParent) => {
                singleParent.activities = activity.activities;
                /** Save object reference */
                task.activityObject = activity;
                if (col.name == 'responsables') {
                  if (task[col.name].length) {
                    task[col.name].map((responsable) => {
                      if (
                        responsable[col.el_to_extract_from] ==
                        singleParent.value
                      ) {
                        singleParent.tasks.push(task);
                      }
                    });
                  } else if (!singleParent.value) {
                    singleParent.tasks.push(task);
                  }
                } else {
                  if (task[col.name] == singleParent.value) {
                    singleParent.tasks.push(task);
                  }
                }
              });
            });
          });
          data = newData;
          data.sort(
            firstBy('value', { direction: newGroupBy.sort, ignoreCase: true })
          );
        }
      }
    }

    updateRender(data);
    setGroupBy(newGroupBy);
    setIsTree({ ...isTree });
  };

  /**
   * This function get activities and responsables to use them at DOM
   */
  async function getLookahead(reLoad = true) {
    /** set load to true */
    if (reLoad) {
      setLoading(true);
    }
    setMassiveSelection([]);
    setCurrentPage(0);

    const sectorRes = await sectorService.showCalendar(
      projectState.sectorSelected
    );
    const sector = sectorRes.sector;
    setSectorObject(sector);

    /** Bring data from services (activities from master plan that belongs to sectorID which is used at platform, and responsables with the same logic) */
    const lastLevelActivities = await activityService.getLookaheadesource({
      sector_id: projectState.sectorSelected,
      start: dateRange.start,
      end: dateRange.end
    });
    setLastLevelActivitiesCard(lastLevelActivities);
    setAllActivities(cloneDeep(lastLevelActivities));
    const responsables = await userService.getBySector(
      projectState.sectorSelected
    );

    const data = lastLevelActivities.activities.sort(
      firstBy('id', { direction: 'asc', ignoreCase: true })
    );
    const resources = lastLevelActivities.resource || [];

    const calendars = await calendarService.showBySector(
      projectState.sectorSelected
    );

    /** get Subcontracts */
    const subcontractsGet = await subContractService.getByProject(
      projectState.projectSelected
    );

    if (subcontractsGet) {
      const typeFrom = tableConfig.find((col) => col.name == 'subcontractId');
      typeFrom.from_values.splice(0, typeFrom.from_values.length);
      const subContractsOrdered = subcontractsGet.subcontracts.sort(
        dynamicSort('name')
      );
      /** sort by value (order : 1, asc, -1 desc ) */
      // groupedSorted.sort(dynamicSort(`${order}value`));

      subContractsOrdered.map((type, index) => {
        typeFrom.from_values.push({
          value: type.id,
          label: type.name,
          weigth: index + 1
        });
      });
      setTableConfig([...tableConfig]);
      setSubcontracts(subContractsOrdered);
    }

    /** If data were fetch succesfully, we pass this data to component states */
    if (lastLevelActivities) {
      setToSelectResponsables(responsables.users);
      setCalendars(calendars.calendar);
      const materialFrom = tableConfig.find(
        (col) => col.identifier == 'material'
      );
      const machineryFrom = tableConfig.find(
        (col) => col.identifier == 'machinery'
      );
      const specialityFrom = tableConfig.find(
        (col) => col.identifier == 'rrhh'
      );
      materialFrom.from_values.splice(0, materialFrom.from_values.length);
      machineryFrom.from_values.splice(0, machineryFrom.from_values.length);
      specialityFrom.from_values.splice(0, specialityFrom.from_values.length);

      resources.map((resource, index) => {
        const toPush = {
          value: resource.id,
          label: resource.name,
          weigth: index + 1
        };

        switch (resource.type) {
          case 'material':
            materialFrom.from_values.push(toPush);
            break;
          case 'machinery':
            machineryFrom.from_values.push(toPush);
            break;
          case 'rrhh':
            specialityFrom.from_values.push(toPush);
            break;
        }
      });
      setResourcesMap(resources);
      setTableConfig([...tableConfig]);
      setSectorResoruce(resources);
      setActivities(
        data.sort(
          (a, b) => parseInt(a.correlative_id) - parseInt(b.correlative_id)
        )
      );
      if (reLoad) {
        // setLoading(false)
      }
    }

    return data;
  }

  /**
   * This function updates the state of table metadata
   * @param {*} tMetadata Object with same attr than tableConfig to replace at view state
   */
  const updateTableMetadata = (tMetadata = tableConfig) => {
    setTableConfig((prev) => {
      prev = cloneDeep(tMetadata);
      return prev;
    });
  };

  /**
   * This functions update the state of activities at view
   */
  const updateRender = (ac = activities) => {
    setActivities([...ac]);
  };

  /**
   * This function is used by datepickers to save new valeus to component state
   * @param {*} value Moment date object
   * @param {*} dateString String date
   */
  const changeDateState = async (value, dateString) => {
    if (dateString[0] != '' && dateString != '') {
      await setDateRange((prev) => {
        prev.start = dateString[0];
        prev.end = dateString[1];
        return prev;
      });
      await setGroupBy((prev) => {
        prev = { criteria: 'activityId', sort: 'asc' };
        return prev;
      });
      getLookahead();
    } else {
      setActivities([]);
    }
  };

  /**
   * This function executes massiveSelectionHandler then sets the selection state to
   * handle massive actions like deleting, changing responsable, etc.
   * @param {*} selection Initial selection
   */
  const recursivelySelection = (selection) => {
    /** Temporal array to save added task */
    const toAdd = [];

    /** Temporal array to save deleted task */
    const toDelete = [];

    /** Execution of recursively selection */
    massiveSelectionHandler(selection, null, toAdd, toDelete);

    /** Setting state of massive actions */
    setMassiveSelection((prev) => {
      /* Take off elements of massive actions state */
      const newState = differenceBy(prev, toDelete, 'id');

      /** Then we check those elements that arent at the state and add them */
      toAdd.map((el) => {
        const doesExist = newState.find((single) => el.id == single.id);
        if (!doesExist) {
          /** Filter if it is a task (avoid adding activities) */
          if (!el.hide && (el.children || el.activityId)) {
            newState.push(el);
          }
        }
      });

      prev = cloneDeep(newState);
      return prev;
    });
  };

  /**
   * This function handles with massive selection tasks
   * @param {*} selection Element to change selecton status by it active attribute
   * @param {*} flag This saves the parent selected status to match with their children
   * @param {*} toAdd Array with tasks to add to state array with massive selection
   * @param {*} toDelete Array with tasks to delete from state array with massive selection
   */
  const massiveSelectionHandler = (
    selection,
    flag = null,
    toAdd = [],
    toDelete = []
  ) => {
    /** Define array of children (activity or task) */
    const childs = selection.tasks || selection.children;

    /** Defines first flag value from calling this function without flag */
    if (flag == null) {
      flag = !selection.active;
      selection.active = !selection.active;
    }

    /** Matchs the values to dont change already setted correct value */
    if (flag != selection.active) {
      selection.active = !selection.active;
    }

    /** Defines if the current selection must be deleted or added to state array of massive selection */
    if (selection.active) {
      toAdd.push(selection);
    } else if (!selection.active) {
      toDelete.push(selection);
    }

    /** Recursively repetition for children */
    if (childs) {
      if (childs.length) {
        childs.map((el) => {
          massiveSelectionHandler(el, flag, toAdd, toDelete);
        });
      }
    }
  };

  /**
   * This function handles the drag n drop movements for change cols disposition
   * @param {*} oldIndex Index from the array that is dragged
   * @param {*} newIndex Index from the element that the dragged el is going to be moved
   */
  const moveCard = async ({ oldIndex, newIndex }) => {
    const dragCard = tableConfig[oldIndex];

    const a = update(tableConfig, {
      $splice: [
        [oldIndex, 1],
        [newIndex, 0, dragCard]
      ]
    });
    updateTableMetadata(a);
  };

  const [array, setArray] = useState([]);
  useEffect(() => {
    setArray([renderGeneralHeader(), ...activities]);
  }, [activities, isLoading, tableConfig]);

  /**
   * This function render the table with activities and their tasks
   */
  const renderTable = () => {
    if (isLoading) {
      return (
        <Spin className="loader-spinner-lookahead-table" indicator={antIcon} />
      );
    }
    if (activities.length) {
      /* const array = [
                    renderGeneralHeader(),
                    ...activities
                ] */
      if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
        return (
          <CustomPaginator
            totalTablesWidth={totalTablesWidth}
            massiveSelection={massiveSelection}
            resizing={resizing}
            current={currentPage}
            setCurrentPage={setCurrentPage}
            isFilterdData={existFilter}
            data={array}
            t={t}
            itemSize={height < 700 ? height * 0.55 : height * 0.68}
            renderItem={(
              item,
              key,
              virtualizeRef,
              indexToHide,
              setIndexToHide
            ) => {
              if (key == 0) {
                paginatorRef = virtualizeRef;
                return item;
              } else if (!resizing) {
                return (
                  <LookaheadActivityResource
                    t={t}
                    permission={permission}
                    ganttAPI={ganttAPI}
                    updateAsyncTask={updateAsyncTask}
                    updateAsyncActivity={updateAsyncActivity}
                    scrollStates={scrollStates}
                    setScrollStates={setScrollStates}
                    virtualizeRef={virtualizeRef}
                    massiveSelectionHandler={recursivelySelection}
                    setMassiveSelection={setMassiveSelection}
                    resizing={resizing}
                    tableMetadata={tableConfig}
                    isFilterdData={existFilter}
                    key={key}
                    lastLvlActivity={item}
                    index={key}
                    resources={item.activities}
                    toSelectResponsables={toSelectResponsables}
                    handleAddConstraint={handleAddConstraint}
                    subContracts={subContracts}
                    indexToHide={indexToHide}
                    lastLevelActivities={lastLevelActivitiesCard}
                    setIndexToHide={setIndexToHide}
                    setVisibleFormSubcontract={setVisibleFormSubcontract}
                    showDrawer={showDrawer}
                    onCloseCard={onCloseCard}
                    deleteSubcontract={deleteSubcontract}
                    setVisibleFormResource={setVisibleFormResource}
                    setTypeResource={setTypeResource}
                    setActivityResource={setActivityResource}
                    setTaskSelected={setTaskSelected}
                  />
                );
              }
            }}
            perPage={1}
          />
        );
      }
      return (
        <CustomPaginator
          totalTablesWidth={totalTablesWidth}
          massiveSelection={massiveSelection}
          itemSize={height < 700 ? height * 0.55 : height * 0.68}
          current={currentPage}
          isFilterdData={existFilter}
          setCurrentPage={setCurrentPage}
          data={array}
          t={t}
          renderItem={(
            item,
            key,
            virtualizeRef,
            indexToHide,
            setIndexToHide
          ) => {
            if (key == 0) {
              paginatorRef = virtualizeRef;
              return item;
            } else if (!resizing) {
              return (
                <LookaheadOtherGroup
                  t={t}
                  permission={permission}
                  lookaheadActions={lookaheadActions}
                  resources={item.activities}
                  ganttAPI={ganttAPI}
                  updateAsyncTask={updateAsyncTask}
                  updateAsyncActivity={updateAsyncActivity}
                  scrollStates={scrollStates}
                  isFilterdData={existFilter}
                  setScrollStates={setScrollStates}
                  virtualizeRef={virtualizeRef}
                  massiveSelectionHandler={recursivelySelection}
                  setMassiveSelection={setMassiveSelection}
                  tableMetadata={tableConfig}
                  groupParent={item}
                  index={key}
                  lastLvlActivity={item}
                  key={key}
                  groupBy={groupBy}
                  lastLevelActivities={lastLevelActivitiesCard}
                  indexToHide={indexToHide}
                  setIndexToHide={setIndexToHide}
                  toSelectResponsables={toSelectResponsables}
                  handleAddConstraint={handleAddConstraint}
                  showDrawer={showDrawer}
                  onCloseCard={onCloseCard}
                  subContracts={subContracts}
                  setVisibleFormSubcontract={setVisibleFormSubcontract}
                  setVisibleFormResource={setVisibleFormResource}
                  setTypeResource={setTypeResource}
                  setActivityResource={setActivityResource}
                  setTaskSelected={setTaskSelected}
                />
              );
            }
          }}
          perPage={1}
        />
      );
    }
    return (
      <Empty
        style={{ marginTop: 200 }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>{t('no_find_tasks')}</span>}
      />
    );
  };

  /**
   * This function render the header with titles for the printed tables, it allows to drag n drop the position
   * as also allow to resize the width.
   */
  const renderGeneralHeader = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Row className="fixed-tree-table-header">
        <AnimatedSortable
          useDragHandle
          items={tableConfig}
          onSortEnd={moveCard}
          axis="x"
          onDivResizeHandler={onDivResizeHandler}
          onDivResizeDoubleClicked={onDivResizeDoubleClicked}
        />
      </Row>
    );
  };

  /**
   * This function render modal Add Constraint
   */
  const handleAddConstraint = (task) => {
    setVisibleForm({ value: true, tasks: [task] });
  };

  const handleUpdatedTasksResponsables = (t, updatedTask) => {
    activities.map((el) => {
      /** Then get the reference from state */
      const taskToUpdate = getTask(t.id, null, el);

      /** Finally we just update the constraints array from the referenced task */
      if (taskToUpdate.length) {
        taskToUpdate[0].constraints = updatedTask.constraints;
        socket.emit('task_update', {
          sector: projectState.sectorSelected,
          task: updatedTask
        });
      }
    });
  };

  const handleUpdatedTasks = async (updatedTasks = false) => {
    if (updatedTasks) {
      if (updatedTasks.length) {
        const asyncFeature = updatedTasks.map(async (t) => {
          /** First we fetch the updated task */
          let updatedTask = await taskService.show(t.id);
          if (updatedTask) {
            updatedTask = updatedTask.task;
            let activity;
            const { criteria } = groupBy;
            /** Check if the groupping if by activity */
            if (criteria == 'activity' || criteria == 'activityId') {
              /** Get activity */
              activity = activities.find((ac) => ac.id == t.activityId);
            } else {
              /** If is by responsable we iterate each one, and update it list task */
              if (criteria == 'responsables') {
                return handleUpdatedTasksResponsables(t, updatedTask);
              }
              /** We get the attribute value and filter whole array of parents (activity just to not broke the code) */
              activity = activities.find((ac) => ac.value == t[criteria]);
            }

            /** Then get the reference from state */
            const taskToUpdate = getTask(t.id, null, activity);

            /** Finally we just update the constraints array from the referenced task */
            if (taskToUpdate.length) {
              taskToUpdate[0].constraints = updatedTask.constraints;
              socket.emit('task_update', {
                sector: projectState.sectorSelected,
                task: updatedTask
              });
            }
          }
        });
        await Promise.all(asyncFeature);
        updateRender();
      }
      dispatch(lookaheadActions.notifyLookaheadUpdateGroup());
    }
  };

  /**
   * This function render Form Add Constraint
   */
  const renderFormConstraints = () => (
    <ConstraintForm
      t={t}
      initialTasks={visibleForm.tasks}
      visibleForm={visibleForm.value}
      setVisibleForm={(val) => setVisibleForm({ value: val, tasks: [] })}
      toSelectResponsables={toSelectResponsables}
      setConstraintAdded={(updatedTasks) => handleUpdatedTasks(updatedTasks)}
    />
  );

  /**
   * This function render the header with filtering options for printed tables
   */
  const renderFilterHeader = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Row>
        <Col>
          <LookaheadFilterHeader
            defaultOrderOptions={[
              {
                name: 'activityId',
                label: t('activity_id'),
                switch: ['1 → 9', '9 → 1']
              }
            ]}
            isPlanification={false}
            lookaheadActions={lookaheadActions}
            lookaheadState={'lookaheadResourceState'}
            groupBy={groupBy}
            modifyGroupBy={modifyGroupBy}
            data={activities}
            setIsFilter={setExistFilter}
            changeDateState={changeDateState}
            dateRange={dateRange}
            updateTableMetadata={updateTableMetadata}
            tableMetadata={tableConfig}
            toSelectResponsables={toSelectResponsables}
            resources={sectorResoruce}
            subContracts={subContracts}
            updateRender={updateRender}
            filterByActivityId
          />
        </Col>
      </Row>
    );
  };

  const quitDismissHandler = () => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );
        activity.active = false;
        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.active = false;
          // taskService.update(reference)
          // updateAsyncTask(reference)
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            const reference = doesExistAtReference;
            reference.active = false;
            ac.active = false;
          }
        });
      });
    }

    setMassiveSelection([]);
    handlePopVisibility(false, 'priorityMassive');
  };

  const massiveDuplicateHandler = async () => {
    const res = await taskService.duplicateAll(
      massiveSelection,
      projectState.sectorSelected,
      toActivityDuplicate.value
    );
    if (res) {
      const lastLevelActivities = await activityService.getLookahead({
        sector_id: projectState.sectorSelected,
        start: dateRange.start,
        end: dateRange.end
      });
      const data = lastLevelActivities.activities;
      const copiedActivity = data.find(
        (el) => el.id == toActivityDuplicate.value
      );
      const replace = activities.map((ac) => {
        if (ac.id == toActivityDuplicate.value) {
          ac = copiedActivity;
        }
        return ac;
      });

      updateRender(replace);
      notifyMessage({
        title: t('duplicate_succesfully_title'),
        message: t('duplicate_succesfully'),
        type: 'success'
      });
    }
  };

  const renderSelectDuplicateTasks = () => {
    const avoidActivities = [];
    massiveSelection.map((singleTask) => {
      const activityId = singleTask.activityId;
      if (!avoidActivities.includes(activityId)) {
        avoidActivities.push(activityId);
      }
    });

    return (
      <Select
        placeholder="A la actividad"
        style={{ width: 319 }}
        onChange={(e) => {
          toActivityDuplicate.value = e;
        }}>
        {activities.map((ac) => {
          if (!avoidActivities.includes(ac.id)) {
            return (
              <Option value={ac.id}>
                <Tooltip
                  overlayStyle={{ width: 350, maxWidth: 350, minWidth: 350 }}
                  title={
                    <div style={{ textAlign: 'center' }}>
                      {ac.activityRoute}
                    </div>
                  }>
                  <div>{ac.name}</div>
                </Tooltip>
              </Option>
            );
          }
        })}
      </Select>
    );
  };

  /**
   * On click function when pressing delete
   * @param {*} task Top lvl task to start tree deleting (REQUIRED)
   * @param {*} activity Activity parent object (REQUIRED)
   * @param {*} parent If task is from another than first level must be specified the parent task to attack it children array
   */
  const handleDelete = async (task, activity, parent = null) => {
    const tasksToDelete = [];
    getTasksToDelete(task, tasksToDelete);
    const asyncMap = tasksToDelete.map(async (toDelete) => {
      await deleteTaskHandler(
        toDelete,
        activity,
        parent,
        updateAsyncTask,
        updateAsyncActivity
      );
      socket.emit('task_delete', {
        sector: projectState.sectorSelected,
        task: toDelete
      });
    });

    await Promise.all(asyncMap);
  };

  /**
   * This function returns the reference of an activity from state
   * @param {*} id ID of activity to get (REQUIRED)
   */
  const getActivity = (id) => {
    const activity = activities.find((ac) => ac.id == id);
    return activity;
  };

  /**
   * This function return the reference from the state for activity and parent task
   * @param {*} activityId ID of the activity
   * @param {*} parentId ID of the parent
   */
  const getActivityAndParentReference = (activityId, parentId) => {
    /** Get reference of the activity of current iteration */
    const activity = getActivity(activityId);

    let parentTask = null;

    /** If current iteration has a parent task, we load it */
    if (parentId) {
      parentTask = getTask(parentId, null, activity);
      if (parentTask.length) {
        parentTask = parentTask[0];
      }
    }

    return { activity, parentTask };
  };

  /* Real time feature */
  const realTimeTaskAdd = (task) => {
    const lastLvlActivity = getActivity(task.activityId);
    if (lastLvlActivity) {
      const taskToAdd = getTask(task.id, null, lastLvlActivity);
      if (taskToAdd.length) {
        // console.log('La tarea ya esta en esta actividad')
      } else {
        // console.log('La tarea no esta agregada, y pertenece a esta actividad asi que debe agregarse')
        if (task.parent_id) {
          // console.log('la tarea debe ser agregada como sub tarea de otra tarea')
          const parentTask = getTask(task.parent_id, null, lastLvlActivity);
          if (parentTask.length) {
            parentTask[0].children.push(task);
          }
        } else {
          // console.log('la tarea debe ser agregada como hija a la actividad')
          lastLvlActivity.tasks.push(task);
        }
        handlePopVisibility(false, 'progressMassive');
        // updateState()
      }
    }
  };

  const [sectorObject, setSectorObject] = useState(false);
  useEffect(() => {
    if (sectorObject.update_ponderators_lookahead) {
      message.info('Estamos configurando el cambio de criterio...', 3);
      const alreadyCalculatedParents = {};
      let activityAlreadyCalculated = false;
      if (groupBy.criteria == 'activityId') {
        activities.map((activity) => {
          activity.tasks.map((task) => {
            if (task.parent_id) {
              if (!alreadyCalculatedParents[task.parent]) {
                const parent = getTask(task.parent_id, null, activity);
                calculatePonderators(
                  parent,
                  null,
                  updateAsyncTask,
                  projectState
                );
                alreadyCalculatedParents[task.parent_id] = true;
              }
            } else if (!activityAlreadyCalculated) {
              calculatePonderators(
                activity,
                null,
                updateAsyncTask,
                projectState
              );
              activityAlreadyCalculated = true;
            }
          });
        });
      }
      sectorService.updatePonderatorLookahead(sectorObject.id, false);
    }
    setLoading(false);
  }, [activities]);

  /**
   * This function is the only one that is involve in other groupping
   * @param {*} task Task which was reported to update
   */
  const realTimeTaskUpdate = (task) => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      const lastLvlActivity = getActivity(task.activityId);
      if (lastLvlActivity) {
        const taskToUpdate = getTask(task.id, null, lastLvlActivity);
        if (taskToUpdate.length) {
          Object.keys(task).map((attr) => {
            if (
              attr != 'children' &&
              attr != 'createdAt' &&
              attr != 'activityId' &&
              attr != 'id' &&
              attr != 'parent_id' &&
              attr != 'updatedAt' &&
              attr != 'active'
            ) {
              taskToUpdate[0][attr] = task[attr];
            }
          });
        }
      }
    } else {
      activities.map((ac) => {
        /** Reference from state */
        const doesExistAtReference = ac.tasks.find((t) => t.id == task.id);
        if (doesExistAtReference) {
          Object.keys(task).map((attr) => {
            if (
              attr != 'children' &&
              attr != 'createdAt' &&
              attr != 'activityId' &&
              attr != 'id' &&
              attr != 'parent_id' &&
              attr != 'updatedAt' &&
              attr != 'active'
            ) {
              doesExistAtReference[attr] = task[attr];
            }
          });
        }
      });
    }
    handlePopVisibility(false, 'progressMassive');
  };

  const realTimeTaskDelete = (task) => {
    const lastLvlActivity = getActivity(task.activityId);
    if (lastLvlActivity) {
      const taskToDelete = getTask(task.id, null, lastLvlActivity);
      if (taskToDelete.length) {
        if (task.parent_id) {
          /** remove from parent task */
          const parentTask = getTask(task.parent_id, null, lastLvlActivity);
          if (parentTask.length) {
            parentTask[0].children = parentTask[0].children.filter((t) => {
              if (t.id != task.id) return true;
              return false;
            });
          }
        } else {
          /** remove from activity */
          lastLvlActivity.tasks = lastLvlActivity.tasks.filter((t) => {
            if (t.id != task.id) return true;
            return false;
          });
        }
        handlePopVisibility(false, 'progressMassive');
        // updateState()
      }
    }
  };

  const realTimeActivityUpdate = (activity) => {
    const lastLvlActivity = getActivity(activity.id);
    if (lastLvlActivity) {
      lastLvlActivity.progress = activity.progress;
      lastLvlActivity.hasCustomPonderator = activity.hasCustomPonderator;
      handlePopVisibility(false, 'progressMassive');
      // updateState()
    }
  };

  useEffect(
    () =>
      // console.log('Connecting to socket server')
      // socket.on('task_update_' + projectState.sectorSelected, realTimeTaskUpdate)

      // socket.on('task_delete_' + projectState.sectorSelected, realTimeTaskDelete)

      // socket.on('task_add_' + projectState.sectorSelected, realTimeTaskAdd)

      // socket.on('activity_update_' + projectState.sectorSelected, realTimeActivityUpdate)

      () => {
        // console.log('Disconnecting socket server')
        socket.off('task_add_' + projectState.sectorSelected, realTimeTaskAdd);
        socket.off(
          'task_delete_' + projectState.sectorSelected,
          realTimeTaskDelete
        );
        socket.off(
          'task_update_' + projectState.sectorSelected,
          realTimeTaskUpdate
        );
        socket.off(
          'activity_update_' + projectState.sectorSelected,
          realTimeActivityUpdate
        );
      },
    [projectState.sectorSelected, activities, groupBy]
  );

  const updateAsyncTask = (task) => {
    const copy = cloneDeep(task);
    delete copy.activityObject;
    taskService.update(copy);
    socket.emit('task_update', {
      sector: projectState.sectorSelected,
      task: copy
    });
    // console.log('should implement socket for task')
  };

  const updateAsyncActivity = (activity) => {
    activityService.update(activity).then(() => {
      activityService.updateTreeProgress(activity.id);
      /** find activity on object allActivities (state that saves activities without reference) */
      const findAct = allActivities.activities.findIndex(
        (act) => act.id === activity.id
      );
      if (findAct !== -1) {
        /** found */
        /** compare activity modified vs activity previous state */
        if (
          parseFloat(allActivities.activities[findAct].progress) !==
          parseFloat(activity.progress)
        ) {
          const newActivityHistorical = {
            activityId: activity.id,
            progress: activity.progress
          };
          historicalActivityProgressService.create(newActivityHistorical);

          /** update state allActivities (without reference too) */
          const newActivity = cloneDeep(activity);
          const newActivities = cloneDeep(allActivities);
          newActivities.activities[findAct] = newActivity;
          setAllActivities(newActivities);
        }
      }
    });
    socket.emit('activity_update', {
      sector: projectState.sectorSelected,
      activity: activity
    });
    // console.log('should implement socket for activity')
  };

  /**
   * This function opens the constraint creation component with massive selection
   */
  const massiveConstraintHandler = () => {
    setVisibleForm({ value: true, tasks: massiveSelection });
  };

  /**
   * This function handles with massive progress change
   */
  const massiveProgressHandler = async () => {
    const asyncMap = massiveSelection.map(async (selection) => {
      const { activity, parentTask } = getActivityAndParentReference(
        selection.activityId,
        selection.parent_id
      );

      /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
      const doesExistAtReference = getTask(selection.id, null, activity);
      if (doesExistAtReference.length) {
        const reference = doesExistAtReference[0];
        /** VALIDATION: Task with children cant be setted the progress */
        if (!reference.children.length) {
          reference.progress = massiveProgress;
          if (massiveProgress == 0) {
            if (calculatePonderators) {
              calculatePonderators(
                parentTask || activity,
                activity,
                updateAsyncTask
              );
            }
          }
          // taskService.update(reference)
          updateAsyncTask(reference);
          await calculateProgress(
            reference,
            parentTask || activity,
            activity,
            updateAsyncTask,
            updateAsyncActivity
          );
        }
      }
    });

    await Promise.all(asyncMap);
    handlePopVisibility(false, 'progressMassive');
    // updateRender()
  };

  /**
   * This function handle massive change of priority
   * @param {*} option Single object with selected priority
   */
  const massivePriorityHandle = (option) => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.priority = option.value;
          // taskService.update(reference)
          updateAsyncTask(reference);
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            let reference = doesExistAtReference;
            reference.priority = option.value;
            reference = cloneDeep(reference);
            delete reference.activityObject;
            updateAsyncTask(reference);
          }
        });
      });
    }

    handlePopVisibility(false, 'priorityMassive');
  };

  /**
   * This function deletes tasks massively
   */
  const massiveDeleteHandler = async () => {
    /** Declare an async iteration */
    const asyncMap = massiveSelection.map(async (selection) => {
      const { activity, parentTask } = getActivityAndParentReference(
        selection.activityId,
        selection.parent_id
      );

      /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
      const doesExistAtReference = getTask(selection.id, null, activity);
      if (doesExistAtReference.length) {
        await handleDelete(doesExistAtReference[0], activity, parentTask);
        if (!activity.tasks.length) {
          activity.active = false;
        }
      }
    });

    /** We await of async iteration */
    await Promise.all(asyncMap);

    /** Then update the render, notify of process, and clean the massive selection state */
    updateRender();
    notifyMessage({
      title: t('deleted_succesfully_title'),
      message: t('deleted_succesfully'),
      type: 'success'
    });
    setMassiveSelection([]);
  };

  /**
   * This function handles the massive start date change
   * @param {*} date date f rom datepicker at dom
   */
  const massiveStartHandle = (date) => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.start_date = date;
          getEndDateByGantt(reference, activity);
          updateAsyncTask(reference);
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            let reference = doesExistAtReference;
            reference.start_date = date;
            getEndDateByGantt(reference, reference.activityObject);
            reference = cloneDeep(reference);
            delete reference.activityObject;
            updateAsyncTask(reference);
          }
        });
      });
    }

    handlePopVisibility(false, 'datesMassive');
  };

  /**
   * This function handles change massive responsable change
   */
  const massiveResponsableHandler = () => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.responsables = [];
          massiveResponsableState.values.map((res) => {
            reference.responsables.push(res);
          });
          updateAsyncTask(reference);
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            let reference = doesExistAtReference;
            reference.responsables = [];
            massiveResponsableState.values.map((res) => {
              reference.responsables.push(res);
            });
            reference = cloneDeep(reference);
            delete reference.activityObject;
            updateAsyncTask(reference);
          }
        });
      });
    }
    handlePopVisibility(false, 'responsableMassive');
    dispatch(lookaheadActions.notifyLookaheadUpdateGroup());
  };

  const massiveSubcontractHandler = (option) => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.subcontractId = option.value;
          // taskService.update(reference)
          updateAsyncTask(reference);
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            let reference = doesExistAtReference;
            reference.subcontractId = option.value;
            reference = cloneDeep(reference);
            delete reference.activityObject;
            updateAsyncTask(reference);
          }
        });
      });
    }

    handlePopVisibility(false, 'priorityMassive');
    dispatch(lookaheadActions.notifyLookaheadUpdateGroup());
  };

  const massiveMaterialHandler = (option) => {
    if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
      massiveSelection.map(async (selection) => {
        const { activity, parentTask } = getActivityAndParentReference(
          selection.activityId,
          selection.parent_id
        );

        /** Then we check if this iteration does exist at state reference, and if it does, we deal it */
        const doesExistAtReference = getTask(selection.id, null, activity);
        if (doesExistAtReference.length) {
          const reference = doesExistAtReference[0];
          reference.materialId = option.id;
          updateAsyncTask(reference);
        }
      });
      /** Other group by logic massive update */
    } else {
      massiveSelection.map((selection) => {
        activities.map((ac) => {
          /** Reference from state */
          const doesExistAtReference = ac.tasks.find(
            (task) => task.id == selection.id
          );
          if (doesExistAtReference) {
            let reference = doesExistAtReference;
            reference.materialId = option.id;
            reference = cloneDeep(reference);
            delete reference.activityObject;
            updateAsyncTask(reference);
          }
        });
      });
    }

    handlePopVisibility(false, 'materialMassive');
    dispatch(lookaheadActions.notifyLookaheadUpdateGroup());
  };

  /* useEffect(() => {
        if (massiveOnProcess) {
            setMassiveOnProcess(false)
        }
    }, [massiveOnProcess]) */

  const handlePopVisibility = async (visible, id) => {
    // setMassiveOnProcess(true)

    setPopsVisibility({
      ...popsVisibility,
      [id]: visible
    });
  };

  /**
   * This function builds a JSX to select responsables at inline edition
   */
  const renderFriend = (props, option, snapshot, className) => {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10
    };

    let toShowName = `${option.object.name} ${option.object.lastname}`;

    if (toShowName) {
      if (toShowName.length > 17) {
        toShowName = toShowName.slice(0, 17) + '...';
      }
    }

    return (
      <button
        {...props}
        className={className}
        type="button"
        title={option.object.email}>
        <span>
          <img alt="" style={imgStyle} width={20} src={option.photo} />
          <span style={{ fontSize: 15 }}>{toShowName}</span>
        </span>
      </button>
    );
  };

  const renderMassiveResponsable = () => {
    const friends = [];
    const selected = [];
    const dictionary = {};

    massiveResponsableState.values.map((res) => {
      selected.push(res.email);
    });

    toSelectResponsables.map((user) => {
      if (user.is_active) {
        friends.push({
          name: user.email,
          value: user.email,
          photo: user.image || fakeAvatar,
          object: user
        });
        dictionary[user.email] = user;
      }
    });

    return (
      <SelectSearch
        className="select-search-massive select-search-massive--multiple"
        options={friends}
        value={selected}
        renderOption={renderFriend}
        onChange={(val) => {
          massiveResponsableState.values = [];
          val.map((op) => {
            massiveResponsableState.values.push(dictionary[op]);
          });
        }}
        multiple
        search
        placeholder={t('search_responsable_placeholder')}
      />
    );
  };

  /**
   * This function prints the header with actions to deal with massive selection
   */
  const renderMassiveActionHeader = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Animated
        style={{
          height: massiveSelection.length ? 30 : 0,
          overflow: 'hidden'
        }}
        animationIn="fadeIn"
        animationInDuration={250}
        animationOut="fadeOut"
        animationOutDuration={250}
        isVisible={Boolean(massiveSelection.length)}>
        <Row style={{ height: 30, backgroundColor: '#586666' }}>
          <Col span={12} offset={6} style={{ height: '100%' }}>
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              style={{ height: '100%' }}>
              {massiveOnProcess ? null : (
                <Col style={{ textAlign: 'center' }}>
                  {/* Selection text indicator */}
                  <span style={{ color: '#7DFF8A' }}>
                    {massiveSelection.length + ' '}
                  </span>
                  <span style={{ color: '#FFFFFF', marginRight: 23 }}>
                    {t('lookahead_resources.masive_task')}
                    {massiveSelection.length > 1 ? 's' : null}{' '}
                    {t('lookahead_resources.masive_selected')}
                    {massiveSelection.length > 1 ? 's' : null}
                  </span>

                  {/* Massive constraints */}
                  <span
                    className="massive-icon-style"
                    onClick={massiveConstraintHandler}>
                    <Tooltip
                      placement="top"
                      title={t('lookahead_resources.masive_constraint')}>
                      <img width={12} src={constraintMassive} />
                    </Tooltip>
                  </span>

                  {/* Massive Progress */}
                  {groupBy.criteria == 'activity' ||
                  groupBy.criteria == 'activityId' ? (
                    <span className="massive-icon-style">
                      <Popover
                        overlayClassName="massive-selection-pop"
                        placement="bottom"
                        content={
                          <span className="progress-massive-style">
                            <div style={{ textAlign: 'center', width: 111 }}>
                              {t('lookahead_resources.masive_avance')}
                            </div>
                            <ProgressBar
                              value={massiveProgress}
                              setValue={setMassiveProgress}
                            />
                            <div
                              className="progress-massive-btn"
                              onClick={massiveProgressHandler}>
                              {t('lookahead_resources.masive_apply')}
                            </div>
                          </span>
                        }
                        trigger="click">
                        <Tooltip
                          placement="top"
                          title={t('lookahead_resources.masive_avance')}>
                          <img width={12} src={progressMassive} />
                        </Tooltip>
                      </Popover>
                    </span>
                  ) : null}

                  {/* Massive Responsable */}
                  <span className="massive-icon-style">
                    <Popover
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          {renderMassiveResponsable()}
                          <div
                            className="progress-massive-btn"
                            onClick={massiveResponsableHandler}>
                            {t('lookahead_resources.masive_apply')}
                          </div>
                        </span>
                      }
                      trigger="click">
                      <Tooltip
                        placement="top"
                        title={t('responsable_label_only')}>
                        <img width={12} src={responsableMassive} />
                      </Tooltip>
                    </Popover>
                  </span>

                  {/* Subcontract */}
                  <span className="massive-icon-style">
                    <Popover
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          {tableMetadata
                            .find((col) => col.name == 'subcontractId')
                            .from_values.map((option, index) => (
                              <div
                                style={{ cursor: 'pointer' }}
                                key={index}
                                onClick={() =>
                                  massiveSubcontractHandler(option)
                                }>
                                <IconComponent
                                  data={helmet}
                                  width={15}
                                  fill={'white'}
                                  className="icon-options-subcontract"
                                />
                                <span style={{ marginLeft: 5 }}>
                                  {capitalize(option.label)}
                                </span>
                              </div>
                            ))}
                        </span>
                      }
                      trigger="click">
                      <Tooltip
                        placement="top"
                        title={t('lookahead_resources.masive_subcrontract')}>
                        <span>
                          <IconComponent
                            data={helmet}
                            width={15}
                            fill={'white'}
                            className="icon-options-subcontract"
                          />
                        </span>
                      </Tooltip>
                    </Popover>
                  </span>

                  {/* Massive start date */}
                  <span className="massive-icon-style">
                    <Popover
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          <DatePicker
                            allowClear={false}
                            className="custom-date-picker-planification"
                            style={{ left: -4 }}
                            format={'YYYY/MM/DD'}
                            placeholder={t('select_date_label_only')}
                            onChange={(date, dateString) =>
                              massiveStartHandle(dateString)
                            }
                          />
                        </span>
                      }
                      trigger="click">
                      <Tooltip
                        placement="top"
                        title={t('lookahead_resources.masive_init')}>
                        <img width={12} src={datesMassive} />
                      </Tooltip>
                    </Popover>
                  </span>

                  {/* Massive duplicate */}
                  {groupBy.criteria == 'activity' ||
                  groupBy.criteria == 'activityId' ? (
                    <span className="massive-icon-style">
                      <Popover
                        overlayStyle={{ width: 350 }}
                        overlayClassName="massive-selection-pop"
                        placement="bottom"
                        content={
                          <span className="progress-massive-style">
                            <div
                              style={{
                                textAlign: 'center',
                                width: 'auto',
                                paddingBottom: 5
                              }}>
                              {t('lookahead_resources.masive_duplicate_task')}
                            </div>
                            {renderSelectDuplicateTasks()}
                            <div
                              className="progress-massive-btn"
                              style={{ marginTop: 8 }}
                              onClick={massiveDuplicateHandler}>
                              {t('lookahead_resources.masive_apply')}
                            </div>
                          </span>
                        }
                        trigger="click">
                        <Tooltip
                          placement="top"
                          title={t('lookahead_resources.masive_init')}>
                          <img width={12} src={duplicateMassive} />
                        </Tooltip>
                      </Popover>
                    </span>
                  ) : null}

                  {/* Massive material */}
                  <MassiveTotalMaterial
                    t={t}
                    massiveSelection={massiveSelection}
                    activities={activities}
                    getTask={getTask}
                    getActivityAndParentReference={
                      getActivityAndParentReference
                    }
                    updateAsyncTask={updateAsyncTask}
                    handlePopVisibility={handlePopVisibility}
                    groupBy={groupBy}
                  />

                  {/* Massive material */}
                  <span className="massive-icon-style">
                    <Popover
                      overlayClassName="massive-selection-pop"
                      placement="bottom"
                      content={
                        <span className="progress-massive-style">
                          {lastLevelActivitiesCard?.resource.length
                            ? lastLevelActivitiesCard.resource
                                .filter((el) => el.type === 'material')
                                .map((option, index) => (
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    key={index}
                                    onClick={() =>
                                      massiveMaterialHandler(option)
                                    }>
                                    <span className="material-icon-res">
                                      <IconComponent
                                        data={materialMassiveSvg}
                                        width={15}
                                        fill="#fff"
                                      />
                                    </span>
                                    <span style={{ marginLeft: 5 }}>
                                      {capitalize(option.name)}
                                    </span>
                                  </div>
                                ))
                            : null}
                        </span>
                      }
                      trigger="click">
                      <Tooltip
                        placement="top"
                        title={t('lookahead_resources.masive_material')}>
                        <span>
                          <IconComponent
                            data={materialMassiveSvg}
                            width={14}
                            fill="#fff"
                            className="icon-massive-material"
                          />
                        </span>
                      </Tooltip>
                    </Popover>
                  </span>

                  {/* Massive delete */}
                  {groupBy.criteria == 'activity' ||
                  groupBy.criteria == 'activityId' ? (
                    <span className="massive-icon-style">
                      <Popconfirm
                        placement={'bottom'}
                        onConfirm={massiveDeleteHandler}
                        title={t('are_sure_general')}
                        icon={
                          <Icon
                            type="question-circle-o"
                            style={{ color: 'red' }}
                          />
                        }>
                        <Tooltip
                          placement="top"
                          title={t('lookahead_resources.masive_delete')}>
                          <img width={12} src={deleteMassive} />
                        </Tooltip>
                      </Popconfirm>
                    </span>
                  ) : null}

                  {/* Quit Dismiss */}
                  <Tooltip
                    placement="top"
                    title={t('lookahead_resources.masive_cancel')}>
                    <span
                      className="massive-icon-style"
                      onClick={quitDismissHandler}>
                      <img
                        width={12}
                        src={quitDismissIcon}
                        style={{ position: 'relative', top: -1 }}
                      />
                    </span>
                  </Tooltip>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Animated>
    );
  };

  const getWorkingDaysFromCalendar = (legacy_working_time) => {
    const ganttDhtmlxWorktime = legacy_working_time.map((workingtime) => {
      switch (workingtime) {
        case true:
          return 1;
        case false:
          return 0;
        case 1:
          return 1;
        case 0:
          return 0;
      }
    });
    return ganttDhtmlxWorktime;
  };

  const loadCalendars = (calendars) => {
    /** This method load calendars to Gantt API and also creates a custom version to use custom calculate duration */
    const customVersionCalendars = ganttAPI.loadCalendars(calendars);
  };

  useEffect(() => {
    if (calendars.length) {
      loadCalendars(calendars);
    }
  }, [calendars]);

  /** card functions */
  const showDrawer = async (task, activity) => {
    setCurrentTask(task);
    setCurrentActivity(activity);
    setCardState({ visible: true });
  };

  const onCloseCard = () => {
    setPopsVisibility({});
    setCurrentTask(null);
    setCurrentActivity(null);
    setCardState({ visible: false });
  };

  /** this function set to null subcontractId */
  const deleteSubcontract = (task, activity) => {
    const doesExistAtReference = getTask(task.id, null, activity);
    if (doesExistAtReference.length) {
      const reference = doesExistAtReference[0];
      reference.subcontractId = null;
      const copy = cloneDeep(task);
      taskService.removeSubcontract(task.id);
      socket.emit('task_update', {
        sector: projectState.sectorSelected,
        task: copy
      });
    }
    handlePopVisibility(false, 'deleteSubcontract');
  };

  /** this function close all popover  except uniqueIdConstraintDiff */
  const handlePopVisibilityCloseAll = async (uniqueIdConstraintDiff) => {
    const popArr = popsVisibility;
    Object.keys(popArr).forEach((v) => {
      if (uniqueIdConstraintDiff !== v) {
        popArr[v] = false;
      }
    });
    setPopsVisibility({
      ...popArr
    });
  };

  /**
   * Render
   */
  const renderWithPermission = () => {
    if (permission == 'AC' || permission == 'V') {
      return (
        <Animated
          animationIn="fadeIn"
          animationInDuration={500}
          isVisible={true}>
          <Row>
            <Col>
              {renderFilterHeader()}
              {renderMassiveActionHeader()}
              {/* renderGeneralHeader() */}
              {renderTable()}
              {cardState ? (
                <CardTask
                  updateRender={updateRender}
                  activities={activities}
                  currentTask={currentTask}
                  currentActivity={currentActivity}
                  subContracts={subContracts}
                  tableConfig={tableConfig}
                  lastLevelActivities={lastLevelActivitiesCard}
                  onCloseCard={onCloseCard}
                  cardState={cardState}
                  handshake={handshake}
                  toSelectResponsables={toSelectResponsables}
                  resources={resourcesMap}
                  updateAsyncTask={updateAsyncTask}
                  handlePopVisibility={handlePopVisibility}
                  popsVisibility={popsVisibility}
                  handlePopVisibilityCloseAll={handlePopVisibilityCloseAll}
                  t={t}
                  permission={permission}
                  belongsToRange={belongsToRange}
                  dateRange={dateRange}
                  setVisibleFormSubcontract={setVisibleFormSubcontract}
                  excludeDeep={excludeDeep}
                  currentWeekWithPday={currentWeekWithPday}
                  updateAsyncActivity={updateAsyncActivity}
                  updateResources={updateResources}
                  getTask={getTask}
                  deleteSubcontract={deleteSubcontract}
                  groupBy={groupBy}
                />
              ) : null}
            </Col>
          </Row>

          {/* Component Form Constraints */}
          {renderFormConstraints()}
          {renderFormAddSubContracts()}
          {renderFormAddResources()}
        </Animated>
      );
    }
    return <div>{t('settings.not_permissions')}</div>;
  };

  /**
   * Render
   */
  return renderWithPermission();
}

export default withTranslation()(ResourcesView);
