import React from 'react';
import './index.css';
import ContraintsTypes from './ContraintsTypes';
import CncsTypes from './CncsTypes';
import Etiquetas from './Etiquetas';

function CardLeft({
  setCncTypes,
  CncTypes,
  ContraintsTypesAll,
  setContraintsTypesAll,
  tagsAll,
  setTagsAll,
  onlyRead,
  t
}) {
  return (
    <div className="cats-settings">
      <ContraintsTypes
        onlyRead={onlyRead}
        ContraintsTypesAll={ContraintsTypesAll}
        setContraintsTypesAll={setContraintsTypesAll}
        t={t}
      />
      <CncsTypes
        onlyRead={onlyRead}
        setCncTypes={setCncTypes}
        CncTypes={CncTypes}
        t={t}
      />
      <Etiquetas
        onlyRead={onlyRead}
        tagsAll={tagsAll}
        setTagsAll={setTagsAll}
        t={t}
      />
    </div>
  );
}

export default CardLeft;
