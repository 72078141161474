import React from 'react';

import { getActionDescription, getNewValue } from '../utils';
import { isFeatureOn } from '../../../utils/featureUtils';
import { FEATURE_FLAGS } from '../../../constants/featureFlags';
import { LogsIcon } from '../../../icons';
import colors from '../../../stylesheets/variables.scss';
import styles from './ActivityLog.module.scss';

export const ActivityLog = ({ logData, t }) => {
  if (!isFeatureOn(FEATURE_FLAGS.ENABLE_ACTIVITY_LOG)) return null;

  return (
    <div className={styles['activity-log-panel']}>
      <h3 className={styles['activity-log-panel__title']}>
        <LogsIcon color={colors.white} /> {t('activity_card.title')}
      </h3>
      <div className={styles['activity-log-panel__content']}>
        {logData.map((dateEntry, i) => (
          <ol className={styles['activity-log-panel__date-entries']} key={i}>
            <li className={styles['activity-log-panel__date-entry']}>
              <em>{dateEntry.date}</em>
              <ol className={styles['activity-log-panel__time-entries']}>
                {dateEntry.logs.map((timeEntry, i) => (
                  <li
                    className={styles['activity-log-panel__time-entry']}
                    key={i}>
                    <em>{timeEntry.time}</em>
                    <span className={styles['activity-log-panel__user']}>
                      {timeEntry.user}
                    </span>
                    <span className={styles['activity-log-panel__action']}>
                      {getActionDescription(timeEntry)}
                    </span>
                    <span
                      className={styles['activity-log-panel__newValue']}
                      style={{ color: timeEntry.newValueColor || 'inherit' }}>
                      {getNewValue(timeEntry)}
                    </span>
                  </li>
                ))}
              </ol>
            </li>
          </ol>
        ))}
      </div>
    </div>
  );
};
