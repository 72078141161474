import React, { useEffect, useState } from 'react';
import {
  Table,
  Select,
  Popconfirm,
  Icon,
  Dropdown,
  Menu,
  Upload,
  message
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import deleteIcon from './delete.svg';
import userIcon from '../icons/user.svg';
import { useSelector } from 'react-redux';
import { subContractService } from '../../../../services/subcontract.service';
import { projectService } from '../../../../services/project.service';
import { StyleTableUsersProyect } from './style';
import styled from 'styled-components';
import { openNotification, headersSendForm } from '../../../../utils';
import { ChromePicker } from 'react-color';
import ImgCrop from 'antd-img-crop';
import { base } from '../../../../services/base';
import { trackingEvent } from '../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../../analytics/utils';

/** components */
import ModalAddSubContract from '../../ModalAddSubContract';
import ModalAssignSubContract from '../../ModalAssignSubContract';
import useConfigurationPermissions from '../../../../hooks/useConfigurationPermissions';

function CardSubContracts(props) {
  const { t } = props;
  /** hooks */
  const [Visible, setVisible] = useState(false);
  const [visibleAddUser, setVisibleAddUser] = useState(false);
  const [ReloadTableUsers, setReloadTableUsers] = useState(false);
  const [UsersProject, setUsersProject] = useState([]);
  const [usersByCompany, setUsersByCompany] = useState([]);
  const [showProjects, setshowProjects] = useState(false);
  const permissions = useConfigurationPermissions();
  const [onlyRead, setOnlyRead] = useState(permissions.project == 'V');
  const [search, setSearch] = useState('');
  const [copy, setCopy] = useState(null);

  /** consts */
  const { Option } = Select;

  /** redux */
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;
  const stateCompany = useSelector((state) => state.companyState);
  const idCurrentCompany = stateCompany.currentCompany?.id;

  // GET PROJECT GENERAL SELECTED BY USER
  useEffect(() => {
    const getData = async () => {
      const response = await subContractService.getByProject(projectSelectedId);
      setUsersProject(response.subcontracts);
      setScColorsAll(response.subcontracts);
      setshowProjects(!showProjects);
    };
    getData();
  }, [ReloadTableUsers, Visible, projectSelectedId]);

  useEffect(() => {
    loadUsersByCompany();
  }, [projectSelectedId, ReloadTableUsers]);

  useEffect(() => {
    filter();
  }, [UsersProject, search]);

  const loadUsersByCompany = async () => {
    const usersByCompany =
      await subContractService.getByCompany(idCurrentCompany);
    setUsersByCompany(usersByCompany.subcontracts);
  };

  const handleUserSettings = (index, name, value, userId) => {
    const copyUsers = [...UsersProject];
    const findIndex = copyUsers.findIndex((el) => el.id === userId);
    copyUsers[findIndex] = {
      ...copyUsers[findIndex],
      [name]: value
    };
    setUsersProject(copyUsers);
  };

  const updateUserTable = async (user) => {
    await subContractService
      .update(user)
      .then((response) => {})
      .catch(() => {
        const alertError = {
          title: t('settings.project_subcontracts.title'),
          description: t('settings.error_default'),
          type: 'error'
        };
        openNotification(alertError);
      });
  };

  const deallocateSubcontractOfProject = async (data) => {
    const resp = await projectService.deallocateSubcontracOfProject(data);
    return resp;
  };

  const deleteSub = async (subcontract) => {
    const resp = await deallocateSubcontractOfProject({
      subcontractId: subcontract.id,
      projectId: projectSelectedId
    });
    if (resp.msj === 'subcontract deallocated') {
      const alertSucces = {
        title: t('settings.project_subcontracts.assign'),
        description: t('settings.project_subcontracts.remove'),
        type: 'success'
      };
      trackingEvent(
        'company_deletion',
        {
          ...getBasicAmplitudEventProperties(),
          event_source: 'Project Settings',
          company_deleted_name: subcontract.name,
          company_deleted_id: subcontract.id
        },
        AMPLITUDE_SERVICE
      );
      openNotification(alertSucces);
      const newUsersProject = UsersProject.filter(
        (project) => project.id !== subcontract.id
      );
      setUsersProject(newUsersProject);
    }
  };

  const onChangeUpload = async (info, data) => {
    if (info.file.status === 'done') {
      data.image = info.file.response.name;
      await subContractService.update(data);
      let status;
      const hide = message.loading('Action in progress..', 0);
      while (status !== 200) {
        const response = await fetch(data.image);

        status = response.status;
      }
      setTimeout(hide, 100);
      setReloadTableUsers(!ReloadTableUsers);
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'image',
      key: 'image',
      width: 60,
      render: (image, data) => (
        <ImgCrop rotate shape="rect" aspect={5 / 3} modalWidth={850}>
          <Upload
            action={`${base.api}subcontracts/upload`}
            listType="picture-card"
            showUploadList={false}
            headers={headersSendForm}
            onChange={(info) => onChangeUpload(info, data)}>
            <img
              src={image || userIcon}
              className="rounded-img-user"
              height="20px"
              width="20px"
            />
          </Upload>
        </ImgCrop>
      )
    },
    {
      title: t('settings.project_subcontracts.name'),
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.name || '';
        b = b.name || '';
        return b.localeCompare(a);
      },
      render: (name, data, index) => (
        <input
          disabled={onlyRead}
          className="inp-table"
          placeholder={'-'}
          autoComplete="off"
          type="text"
          name="name"
          value={name}
          style={{ width: '90%', fontSize: '12px', border: '0px' }}
          title={name}
          onChange={(e) =>
            handleUserSettings(index, e.target.name, e.target.value, data.id)
          }
          onBlur={() => updateUserTable(data)}
        />
      )
    },
    {
      title: t('settings.project_subcontracts.dni'),
      dataIndex: 'rut',
      key: 'rut',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.rut || '';
        b = b.rut || '';
        return b.localeCompare(a);
      },
      render: (rut, data, index) => (
        <input
          disabled={onlyRead}
          className="inp-table"
          placeholder={'-'}
          autoComplete="off"
          type="text"
          name="rut"
          value={rut}
          style={{ width: '100%', fontSize: '12px', border: '0px' }}
          title={rut}
          onChange={(e) =>
            handleUserSettings(index, e.target.name, e.target.value, data.id)
          }
          onBlur={() => updateUserTable(data)}
        />
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.email || '';
        b = b.email || '';
        return b.localeCompare(a);
      },
      render: (email, data, index) => (
        <input
          disabled={onlyRead}
          className="inp-table"
          placeholder={'-'}
          autoComplete="off"
          type="text"
          name="email"
          defaultValue={email}
          style={{ width: '100%', fontSize: '12px', border: '0px' }}
          title={email}
          onChange={(e) =>
            handleUserSettings(index, e.target.name, e.target.value, data.id)
          }
          onBlur={() => updateUserTable(data)}
        />
      )
    },
    {
      title: t('settings.project_subcontracts.type'),
      width: 100,
      dataIndex: 'type',
      key: 'type',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.type || '';
        b = b.type || '';
        return b.localeCompare(a);
      },
      render: (type, data, index) => (
        <input
          disabled={onlyRead}
          className="inp-table"
          placeholder={'-'}
          autoComplete="off"
          type="text"
          name="type"
          value={type}
          style={{ width: '100%', fontSize: '12px', border: '0px' }}
          title={type}
          onChange={(e) =>
            handleUserSettings(index, e.target.name, e.target.value, data.id)
          }
          onBlur={() => updateUserTable(data)}
        />
      )
    },
    {
      title: 'Color',
      width: 100,
      dataIndex: 'color',
      key: 'color',
      render: (color, data, index) => {
        index = state.findIndex((el) => el.id === data.id);
        return (
          <div>
            <div
              style={{
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                position: 'relative',
                top: 2
              }}
              onClick={() => handleClick(index, data.id)}>
              <div
                style={{
                  width: '36px',
                  height: '14px',
                  borderRadius: '2px',
                  background: `${state[index] && state[index].hex}`
                }}
              />
            </div>
            {state[index] && state[index].displayColorPicker ? (
              <div
                style={{
                  position: 'absolute',
                  zIndex: '2'
                }}>
                <div
                  style={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px'
                  }}
                  onClick={() => handleClose(index, data.id)}
                />
                {onlyRead ? (
                  <div style={{ background: state[index].color }} />
                ) : (
                  <ChromePicker
                    className="picker-wrapp"
                    color={state[index].color}
                    onChange={(color) => handleChange(color, index, data.id)}
                  />
                )}
              </div>
            ) : null}
          </div>
        );
      }
    },
    {
      title: t('settings.project_subcontracts.projects'),
      width: 150,
      dataIndex: 'projects',
      key: 'projects',
      render: (projects) => (
        <span className="no-image-span">
          <Dropdown
            overlay={
              <Menu>
                {projects.map((el, index) => {
                  if (el === null) return;
                  return <Menu.Item key={index}>{el?.name}</Menu.Item>;
                })}
              </Menu>
            }
            trigger={['click']}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}>
              {projects.length === 0
                ? t('settings.project_subcontracts.no_projects')
                : projects.length > 1
                  ? projects.length +
                    ' ' +
                    t('settings.project_subcontracts.projects')
                  : projects.length +
                    ' ' +
                    t('settings.project_subcontracts.projects_single')}{' '}
              <DownOutlined />
            </a>
          </Dropdown>
        </span>
      )
    },
    {
      title: t('settings.project_subcontracts.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status, data, index) => (
        <button
          disabled={onlyRead}
          className={`btn-table-users ${
            status ? 'bg-btn-activo' : 'bg-btn-desactivo'
          }`}
          onClick={() => handleUserSettings(index, 'status', !status, data.id)}
          onBlur={() => updateUserTable(data)}>
          {status
            ? t('settings.project_subcontracts.active')
            : t('settings.project_subcontracts.inactive')}
        </button>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (_, data, index) => {
        if (onlyRead) {
          return null;
        }
        return (
          <Popconfirm
            onConfirm={() => deleteSub(data)}
            title={t('settings.project_subcontracts.confirm')}
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}>
            <img src={deleteIcon} alt="delete" style={{ cursor: 'pointer' }} />
          </Popconfirm>
        );
      }
    }
  ];

  const templateColors = {
    displayColorPicker: false,
    color: {
      r: '241',
      g: '112',
      b: '19',
      a: '1'
    }
  };

  const [state, setState] = useState([]);
  const [scColorsAll, setScColorsAll] = useState([]);
  const [idEdit, setIdEdit] = useState(false);

  useEffect(() => {
    const arrTmp = [];
    if (scColorsAll?.length) {
      scColorsAll.map((el) => {
        arrTmp.push({
          ...templateColors,
          hex: el.color,
          id: el.id
        });
      });
      setState(arrTmp);
    }
  }, [scColorsAll]);

  const handleClick = (index, userId) => {
    const copyState = [...state];
    const findIndex = copyState.findIndex((el) => el.id === userId);
    copyState[findIndex] = {
      ...copyState[findIndex],
      displayColorPicker: !copyState[findIndex].displayColorPicker
    };
    setState(copyState);
  };

  const handleClose = (index, userId) => {
    const copyState = [...state];
    const findIndex = copyState.findIndex((el) => el.id === userId);
    copyState[findIndex] = {
      ...copyState[findIndex],
      displayColorPicker: false
    };
    setState(copyState);
    handleCatsSettings(findIndex, 'color', state[findIndex].hex);
  };

  const handleChange = (color, index, userId) => {
    const copyState = [...state];
    const findIndex = copyState.findIndex((el) => el.id === userId);
    copyState[findIndex] = {
      ...copyState[findIndex],
      color: color.rgb,
      hex: color.hex
    };
    setState(copyState);
  };

  const handleCatsSettings = (index, name, value) => {
    const copyCats = [...scColorsAll];
    copyCats[index] = {
      ...copyCats[index],
      [name]: value
    };
    setScColorsAll(copyCats);
    setIdEdit(index);
  };

  useEffect(() => {
    if (idEdit !== false) {
      updateSubcontract(scColorsAll[idEdit]);
    }
  }, [idEdit]);

  const updateSubcontract = async (data) => {
    await subContractService.update(data);
    setIdEdit(false);
  };

  const filter = () => {
    if (!search) {
      setCopy(UsersProject);
      return;
    }
    const filteredData = UsersProject.filter(
      (entry) =>
        (entry.name &&
          entry.name.toLowerCase().includes(search.toLowerCase())) ||
        (entry.rut && entry.rut.toLowerCase().includes(search.toLowerCase())) ||
        (entry.email &&
          entry.email.toLowerCase().includes(search.toLowerCase())) ||
        (entry.type && entry.type.toLowerCase().includes(search.toLowerCase()))
    );
    setCopy(filteredData);
  };

  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  return (
    <StyleTableUsersProyect>
      <div>
        <div className="grid-options-users">
          <div></div>
          <div style={{ display: 'inline-flex' }}>
            <button
              disabled={onlyRead}
              className="btn-table-add bg-btn-add"
              onClick={() => setVisible(true)}>
              <Icon type="plus" style={{ marginRight: '5px' }} />
              <b style={{ fontSize: '12px' }}>
                {t('settings.project_subcontracts.create')}
              </b>
            </button>
            <button
              disabled={onlyRead}
              className="btn-table-add bg-btn-add"
              onClick={() => setVisibleAddUser(true)}>
              <Icon type="plus" style={{ marginRight: '5px' }} />
              <b style={{ fontSize: '12px' }}>
                {t('settings.project_subcontracts.assign')}
              </b>
            </button>
          </div>
        </div>
        <div style={{ display: 'flex', margin: 2 }}>
          <input
            className="inp-table"
            placeholder={t('settings.project_subcontracts.search') + ' ...'}
            autoComplete="off"
            type="text"
            name="search"
            value={search || ''}
            style={{
              width: '180px',
              fontSize: '14px',
              border: '1px solid',
              borderColor: '#2C3421',
              margin: 2,
              paddingLeft: 10
            }}
            onChange={handleChangeSearch}
          />
        </div>
        <Table
          className="table-users-project"
          columns={columns}
          dataSource={copy}
          pagination={{ pageSize: 15 }}
          rowKey={(obj) => obj.id}
        />
      </div>
      <ModalAddSubContract
        t={t}
        Visible={Visible}
        setVisible={setVisible}
        setReloadTableUsers={setReloadTableUsers}
        ReloadTableUsers={ReloadTableUsers}
      />
      <ModalAssignSubContract
        Visible={visibleAddUser}
        setVisible={setVisibleAddUser}
        setReloadTableUsers={setReloadTableUsers}
        ReloadTableUsers={ReloadTableUsers}
        usersByCompany={usersByCompany}
        UsersProject={UsersProject}
        t={t}
      />
    </StyleTableUsersProyect>
  );
}

export default CardSubContracts;

const SelectionCargoStyle = styled.div`
  .ant-select-selection {
    border-radius: 5px !important;
    width: 100px;
  }
  .ant-select-selection {
    background-color: white !important;
    color: #5f5f5f;
  }
  .anticon svg {
    color: transparent;
  }

  .ant-select-selection,
  ant-select-selection--single {
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    border-top: 0px;
  }

  .ant-select-selection:focus,
  ant-select-selection--single:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;
