/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
import { base } from './base';

const baseURL = base.apiTakt;

class ProductionunitService {
  constructor() {
    this.http = createHttpClient({ baseURL });
  }

  async show(productionunitId) {
    const res = await this.http
      .get('productionunits/' + productionunitId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(productionunitId) {
    const res = await this.http
      .delete('productionunits/' + productionunitId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('productionunits', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async createAll(data) {
    const res = await this.http
      .post('productionunits/create_all', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('productionunits/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showBySector(sectorId) {
    const res = await this.http
      .post('productionunits/bysector', { sectorId })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async assignTaktPlanning(body) {
    const res = await this.http
      .post('productionunits/assign_takt', body)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async unAssignTakt(body) {
    const res = await this.http
      .post('productionunits/unassign_takt', body)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updateBases(body) {
    const res = await this.http
      .put('productionunits/updatebasestakt/' + body.sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const productionunitService = new ProductionunitService();
