import ForwardPath from './forward-path';
import BackwardPath from './backward-path';
import TotalFloat from './total-float';
import FreeFloat from './free-float';

class CriticalPath {
  constructor(ganttInstance) {
    this.gantt = ganttInstance;
    this.forwardPathCalculations = null;
    this.backwardPathCalculations = null;
    this.totalFloat = new Map();
    this.freeFloat = new Map();
  }

  async calculate(gantt) {
    try {
      this.gantt = gantt || this.gantt;
      const forwardPath = new ForwardPath(this.gantt);
      await forwardPath.calculate();
      const forwardPathCalculations = forwardPath.calculations;
      const forwardPathConstraints = forwardPath.constraintsMap;

      const backwardPath = new BackwardPath(
        this.gantt,
        forwardPathCalculations,
        forwardPathConstraints
      );
      await backwardPath.calculate();

      this.forwardPathCalculations = forwardPathCalculations;
      this.backwardPathCalculations = backwardPath.calculations;
      this.calculateTotalFloat(forwardPath, backwardPath);
      this.calculateFreeFloat(forwardPath, backwardPath);
      this.gantt.refreshData();
    } catch (error) {
      throw new Error(`Critical Path error - ${error.message}`);
    }
  }

  calculateTotalFloat(forward, backward) {
    try {
      this.forwardPathCalculations.forEach((_, id) => {
        const activity = this.gantt.getTask(id);
        if (this.gantt.isSummaryTask(activity)) {
          return this.totalFloat.set(activity.id, undefined);
        }

        const paramsForTotalFloatCalculation = {
          es: forward.calculations.get(activity.id)?.es || 0,
          ef: forward.calculations.get(activity.id)?.ef || 0,
          ls: backward.calculations.get(activity.id)?.ls || 0,
          lf: backward.calculations.get(activity.id)?.lf || 0,
          constraintsMap: forward.constraintsMap.get(activity.id),
          totalFloatSnltFnlt: forward.totalFloatSnltFnlt.get(activity.id),
          totalFloatForMsoMfo: forward.totalFloatForMsoMfo.get(activity.id),
          minFromLinks: backward.minFromLinks.get(activity.id),
          activity,
          gantt: this.gantt
        };
        const calculationOfTotalFloat = new TotalFloat(
          paramsForTotalFloatCalculation
        ).getTotalFloat();
        this.totalFloat.set(activity.id, calculationOfTotalFloat);
      });
    } catch (error) {
      throw new Error(`Error in calculateTotalFloat - ${error.message}`);
    }
  }

  calculateFreeFloat(forward, backward) {
    try {
      this.forwardPathCalculations.forEach((_, id) => {
        const activity = this.gantt.getTask(id);
        if (this.gantt.isSummaryTask(activity)) {
          // Es correcto en base a la documentacion que el freefloat modifica el total float
          // Originalmente su valor es totalFloat.set y es cambiado a freeFloat por el contexto de calculateFreeFloat
          return this.freeFloat.set(activity.id, undefined);
        }

        const paramsForFreeFloatCalculation = {
          forwardMap: forward.calculations || new Map(),
          backwardMap: backward.calculations || new Map(),
          activity,
          gantt: this.gantt
        };

        const calculationOfFreeFloat = new FreeFloat(
          paramsForFreeFloatCalculation
        ).getFreeFloat();
        this.freeFloat.set(activity.id, calculationOfFreeFloat);
      });
    } catch (error) {
      throw new Error(`Error in calculateFreeFloat - ${error.message}`);
    }
  }
}
export default CriticalPath;
