import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Modal, Spin, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './index.css';
import { canvasPreview } from '../settingsCompany/CardGeneral/canvasPreview';
import { useDebounceEffect } from '../settingsCompany/CardGeneral/useDebounceEffect';
import {
  EditImageIcon,
  HelpIcon,
  ZoomInIcon,
  ZoomOutIcon,
  RotateLeftIcon,
  RotateRightIcon
} from '../../../icons';
import ModalHelpCrop from '../ModalHelpCrop';
import 'react-image-crop/dist/ReactCrop.css';
/** impost sdk aws */
import AWS from 'aws-sdk';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop
} from 'react-image-crop';

// services
import { projectService } from '../../../services/project.service';
import styled from 'styled-components';

function ModalCropImage({
  typeImage,
  Visible,
  setVisible,
  setStateUpload,
  t,
  imgSrc,
  setCompanyCurrent,
  CompanyCurrent,
  setStateNewImage,
  projectSelectedId,
  setImgSrc
}) {
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1.0);
  const [rotate, setRotate] = useState(0);
  const [visibleHelp, setVisibleHelp] = useState(false);
  const previewCanvasRef = useRef(null);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate, Visible]
  );

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setCrop({
      unit: '%',
      width: 50,
      height: 50,
      x: 25,
      y: 25
    });
  }

  const resetState = (forceCrop = false) => {
    setImgSrc('');
    setScale(1.0);
    setRotate(0);
    const file_picker = document.getElementById('file-input-edit-image');
    const file_picker2 = document.getElementById('file-input-edit-image2');
    if (file_picker) {
      file_picker.value = '';
    }
    if (file_picker2) {
      file_picker2.value = '';
    }
    if (forceCrop) {
      setCompletedCrop();
      setCrop();
    }
    setImgSrc('');
  };

  // Close modal
  const handleCancel = () => {
    setVisible(false);
    resetState(true);
  };

  const [ProjectSelected, setProjectSelected] = useState({});

  // GET PROJECT GENERAL SELECTED BY USER
  useEffect(() => {
    if (Visible) {
      const abortController = new AbortController();
      projectService
        .show(projectSelectedId)
        .then((response) => {
          if (response.project === undefined) {
            setProjectSelected({});
          } else {
            setProjectSelected(response.project);
          }
        })
        .catch(() => setProjectSelected({}));
      return function cleanup() {
        abortController.abort();
      };
    }
    resetState(true);
  }, [Visible]);

  const [Loading, setLoading] = useState(false);

  function dataURItoBlob(dataURI) {
    const binary = window.atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/png' });
  }

  // Bucket Configurations
  const bucketName = 'proplannerv2';
  const bucketRegion = 'us-east-2';
  const IdentityPoolId = 'us-east-2:550291a9-df58-45b4-a461-c8a5c504340e';

  AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IdentityPoolId
    })
  });

  const bucket = new AWS.S3({
    apiVersion: '2006-03-01',
    params: { Bucket: bucketName }
  });

  const cropAndUpload = () => {
    setLoading(true);
    setStateNewImage({ isLoaded: false });
    const canvas = previewCanvasRef.current;
    const dataUrl = canvas.toDataURL('image/png');
    const blobData = dataURItoBlob(dataUrl);

    const fileName = 'logo.png';
    const ts = new Date().getTime();
    const filePath = 'images/companys/' + ts + '-' + fileName;

    bucket.putObject(
      {
        Key: filePath,
        Body: blobData,
        ACL: 'public-read'
      },
      async (err, data) => {
        const s3url = bucket.getSignedUrl('getObject', { Key: filePath });
        const s3urlsplit = s3url.split('?');
        switch (typeImage) {
          case 'projectOwnerImage':
            setCompanyCurrent(() => ({
              ...CompanyCurrent,
              imageProjectOwner: s3urlsplit[0]
            }));
            setStateUpload({
              name: s3urlsplit[0],
              imageProjectOwnerUrl: s3urlsplit[0],
              loading: false
            });
            break;
          default:
            setCompanyCurrent(() => ({
              ...CompanyCurrent,
              image: s3urlsplit[0]
            }));
            setStateUpload({
              name: s3urlsplit[0],
              imageUrl: s3urlsplit[0],
              loading: false
            });
        }
        setLoading(false);
        setVisible(false);

        if (err) {
          console.dir('error uploading image company');
        }
      }
    );
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const renderFooter = () =>
    Loading ? (
      <div style={{ textAlign: 'center' }}>
        <Spin indicator={antIcon} />
      </div>
    ) : (
      <div className="footer-edit-image">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start'
          }}>
          <ButtonEditImage>
            <button
              className="btn-edit-image-left first-button"
              style={{ color: '#121212', fontWeight: 500 }}
              onClick={() => setScale(parseFloat(scale) + 0.1)}>
              <ZoomInIcon color="#121212" />
            </button>
          </ButtonEditImage>
          <ButtonEditImage>
            <button
              className="btn-edit-image-left second-button"
              style={{ color: '#121212', fontWeight: 500 }}
              onClick={() => setScale(parseFloat(scale) - 0.1)}>
              <ZoomOutIcon color="#121212" />
            </button>
          </ButtonEditImage>
          <ButtonEditImage>
            <button
              className="btn-edit-image-left first-button"
              style={{ color: '#121212', fontWeight: 500 }}
              onClick={() => {
                setRotate(Math.min(180, Math.max(-180, Number(rotate + 1))));
              }}>
              <RotateRightIcon color="#121212" />
            </button>
          </ButtonEditImage>
          <ButtonEditImage>
            <button
              className="btn-edit-image-left second-button"
              style={{ color: '#121212', fontWeight: 500 }}
              onClick={() => {
                setRotate(Math.min(180, Math.max(-180, Number(rotate - 1))));
              }}>
              <RotateLeftIcon color="#121212" />
            </button>
          </ButtonEditImage>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          <ButtonEditImage>
            <button
              className="btn-edit-image"
              style={{
                background: '#EFEFEF',
                color: '#121212',
                fontWeight: 500
              }}
              onClick={handleCancel}>
              {t('settings.project_users.add_user_form.cancel')}
            </button>
          </ButtonEditImage>
          <ButtonEditImage>
            <Button
              disabled={!previewCanvasRef.current}
              className="btn-edit-image"
              style={{ padding: '0 10px', width: 'auto', fontWeight: 500 }}
              onClick={cropAndUpload}>
              {t('settings.edit_image_form.save_text')}
            </Button>
          </ButtonEditImage>
        </div>
      </div>
    );

  const renderCrop = () => (
    <div className="grid-crop">
      <div
        className="column-wrap-image-prev"
        style={{ margin: 'auto!important' }}>
        {imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={null}
            style={{ margin: 'auto!important' }}>
            <img
              ref={imgRef}
              className="img-to-crop"
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        <div>
          {completedCrop && (
            <canvas className="canvas-html" ref={previewCanvasRef} />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Modal
        className="crop_image_modal"
        title={
          <span
            style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
            <EditImageIcon color="#7DFF8A" />
            <span style={{ marginLeft: 10 }}>
              {t('settings.edit_image_form.title')}
            </span>
          </span>
        }
        visible={Visible}
        onCancel={handleCancel}
        maskClosable={false}
        width={580}
        footer={renderFooter()}
        centered>
        <div className="grid-crop">
          <span className="column-title">
            {t('settings.edit_image_form.text-first')}
            <span
              style={{ float: 'right', cursor: 'pointer' }}
              onClick={() => {
                setVisibleHelp(true);
              }}>
              <HelpIcon color="#fff" />
            </span>
          </span>
        </div>
        {renderCrop()}
      </Modal>

      <div className="help-modal-crop">
        <ModalHelpCrop
          wrapClassName="help-modal-crop"
          Visible={visibleHelp}
          setVisible={setVisibleHelp}
          centered
          closeIcon={''}
          t={t}
        />
      </div>
    </Fragment>
  );
}

export default ModalCropImage;

const ButtonEditImage = styled.div`
  .btn-edit-image {
    margin-top: 40px;
    text-align: center;
    user-select: none;
    padding: 0.15rem 0.1rem;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
    color: #121212;
    width: 80px;
    font-size: 12px;
    height: 28px;
    border-radius: 5px;
    background-color: #7dff8a;
    transition: 0.3s;
    margin: 10px 5px;
    border: none;
    outline: none;
  }

  .btn-edit-image-left {
    margin-top: 40px;
    text-align: center;
    user-select: none;
    padding: 0.15rem 0.1rem;
    line-height: 1.5;
    display: inline-block;
    border: 0px solid #000000;
    cursor: pointer;
    color: white;
    width: 30px;
    font-size: 12px;
    height: 28px;
    background-color: #7dff8a;
    transition: 0.3s;
    margin: 10px 2px;
    border: none;
    outline: none;
  }

  .btn-edit-image:hover,
  .btn-edit-image-left:hover {
    transition: 0.3s;
    background-color: #ca7534;
  }

  .btn-edit-image:disabled {
    transition: 0.3s;
    background-color: grey;
    color: black;
  }
`;
