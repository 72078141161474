import CriticalPathHelpers from '../base/generic-calculations';
import LinksConstraintCalculator from './calculators/LinksConstraintCalculator';
import calculateRestriction from './helpers/restrictionCalculation';
import { CONSTRAINT_TYPES } from '../constants/index';
import calculateRestrictionAsForward from '../forward-path/helpers/restrictionCalculation';

class BackwardPath extends CriticalPathHelpers {
  constructor(ganttInstance, forwardPathCalculations, forwardConstraintsMap) {
    super('backward');
    this.gantt = ganttInstance;
    this.chainStartActivities = null; // Storage of the activities that start the chains
    this.calculations = new Map(); // Storage of the backward calculations
    this.mapOfRelationsWithActivitiesIds = new Map(); // Storage of the relations of the activities with the ids
    this.mapOfRelationsWithLinks = new Map(); // Storage of the relations of the activities with the links
    this.activitiesPendingToBeCalculated = new Set(); // Storage of the activities pending to be calculated
    this.activitiesLinkedToChainStart = new Set(); // Storage of the activities linked to the chain start activities
    this.forwardPathCalculations = forwardPathCalculations; // Storage of the forward calculations
    this.forwardConstraintsMap = forwardConstraintsMap; // Storage of the forward constraints
    this.constraintMap = new Map(); // Storage of the constraints
    this.minFromLinks = new Map(); // Storage of the minimum values from the links
    this.identifyInitialActivities();
    this.transformInitialActivitiesToObject();
  }

  async calculate() {
    try {
      await this.calculateChains();
      this.getStartAndFinishOfChainOrigin();
      this.calculateTimesForChainStartLinkedActivities();
      this.initializePendingActivities();
      this.deleteActivitiesLinkedToChainStartsFromPendingQueue();
      this.recursiveCalculateLfAndLs();
    } catch (error) {
      throw new Error(`Backward Path - ${error.message}`);
    }
  }
  /**
   * Recursively calculates the latest finish (LF) and latest start (LS) times for a set of activities.
   *
   * This function processes activities that can be calculated based on their predecessors and updates
   * the remaining pending activities until all calculations are complete. It recursively calls itself
   * until there are no more pending activities to be calculated.
   *
   * @param {Set<string|number>} [activitiesPending=this.activitiesPendingToBeCalculated] - A set of activity IDs that are pending calculation.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activitiesPendingToBeCalculated is a Set containing activity IDs [1, 2, 3]
   * recursiveCalculateLfAndLs();
   * // This will recursively calculate LF and LS for all activities that can be calculated
   * // based on their predecessors and update the pending activities set until all are calculated.
   *
   * @throws {Error} If an error occurs during the process.
   */
  recursiveCalculateLfAndLs(
    activitiesPending = this.activitiesPendingToBeCalculated
  ) {
    if (activitiesPending.size === 0) return;

    let { activitiesThatCanBeCalculated, pendingActivities } =
      this.checkActivitiesThatNowCanBeCalculated(activitiesPending);

    this.calculateStartAndFinishTimes(activitiesThatCanBeCalculated);

    activitiesThatCanBeCalculated.forEach((activity) => {
      pendingActivities.delete(activity);
    });

    if (pendingActivities.size > 0) {
      this.recursiveCalculateLfAndLs(pendingActivities);
    }
  }
  /**
   * Calculates the latest start (LS) and latest finish (LF) times for a list of activities.
   *
   * This function processes each activity in the `activitiesToCalculate` list, retrieves the necessary
   * information, and calculates the LS and LF times based on the activity's progress and constraint type.
   * It handles various constraints and updates the calculations accordingly.
   *
   * @param {Array<string|number>} activitiesToCalculate - A list of activity IDs for which to calculate LS and LF times.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activitiesToCalculate is an array containing activity IDs [1, 2, 3]
   * calculateStartAndFinishTimes([1, 2, 3]);
   * // This will calculate the LS and LF times for the activities and update the internal calculations map.
   *
   * @throws {Error} If an error occurs during the process.
   */
  calculateStartAndFinishTimes(activitiesToCalculate) {
    try {
      activitiesToCalculate.forEach((activityId) => {
        let activityReference = this.gantt.getTask(activityId);
        if (!activityReference) {
          throw new Error(`Activity with id ${activityId} not found`);
        }
        let linksWithSucessors = activityReference.$source;
        let constraintType =
          activityReference.constraint_type || CONSTRAINT_TYPES.ASAP;
        const progress = Number(activityReference.progress);
        //Temporary code for parent activities
        if (activityReference.type === 'project') {
          this.calculations.set(activityId, {
            es: activityReference.start_date,
            ef: activityReference.end_date
          });
          return;
        }

        //Temporary code for parent activities
        linksWithSucessors =
          this.cleanAllActivityParentsFromLinks(linksWithSucessors);

        if (progress === 100) {
          this.setWhenActivityHasFullProgress(activityReference);
          return;
        }

        if (
          this.allLinkedActivitiesHasProgress(
            linksWithSucessors,
            activityReference
          )
        ) {
          this.setWhenAllLinkedActivitiesHasProgress(activityReference);
          return;
        }

        if (progress > 0 && progress < 100) {
          if (
            constraintType === CONSTRAINT_TYPES.MSO ||
            constraintType === CONSTRAINT_TYPES.MFO
          ) {
            this.setWhenActivitiesHasProgressButLessThanOneHundred(
              activityReference,
              constraintType
            );
            return;
          }
        }

        if (
          constraintType === CONSTRAINT_TYPES.MSO ||
          constraintType === CONSTRAINT_TYPES.MFO
        ) {
          this.recalculateInBaseRestriction(activityReference, constraintType);

          return;
        }

        let { ls, lf, minFromLinks } = this.calculateLfandLsFromLinks(
          linksWithSucessors,
          activityReference,
          constraintType
        );

        if (
          constraintType === CONSTRAINT_TYPES.SNLT ||
          constraintType === CONSTRAINT_TYPES.FNLT
        ) {
          ({ ls, lf } = this.getTheLessLateInBasEsEfConstraint(
            activityReference.id,
            {
              ls,
              lf
            },
            constraintType
          ));
        }

        this.setCalculations(activityReference, ls, lf);
        this.minFromLinks.set(activityReference.id, {
          ls: minFromLinks.ls,
          lf: minFromLinks.lf
        });
      });
    } catch (error) {
      throw new Error(error.message);
    }
  }

  /**
   * Calculates the latest start (LS) and latest finish (LF) times for activities linked to chain start activities.
   *
   * This function processes activities that are directly linked to the chain start activities by calculating their LS and LF times.
   * It utilizes the `calculateStartAndFinishTimes` method, which is inherited from the superclass, to perform the calculations.
   * The activities to be processed are stored in the `activitiesLinkedToChainStart` property.
   *
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activitiesLinkedToChainStart is an array containing activity IDs [1, 2, 3]
   * calculateTimesForChainStartLinkedActivities();
   * // This will calculate the LS and LF times for the activities linked to chain start activities
   * // and update the internal calculations map.
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  calculateTimesForChainStartLinkedActivities() {
    this.calculateStartAndFinishTimes(this.activitiesLinkedToChainStart);
  }
  /**
   * Initializes the set of activities pending to be calculated.
   *
   * This function initializes the `activitiesPendingToBeCalculated` property by creating a set
   * of all activity IDs that have entries in the `mapOfRelationsWithActivitiesIds` map. This set
   * represents all activities that are pending calculation.
   *
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming mapOfRelationsWithActivitiesIds is a Map with the structure:
   * // Map {
   * //   1: [2],
   * //   2: [3],
   * //   3: [4]
   * // }
   * initializePendingActivities();
   * // This will set activitiesPendingToBeCalculated to a Set containing [1, 2, 3]
   *
   * @throws {Error} If an error occurs during the initialization process.
   */
  initializePendingActivities() {
    const allActivities = this.gantt.getTaskByTime();
    const deleteChainStartActivitiesFromAllActivities = allActivities
      .filter((activity) => !this.chainStartActivities.includes(activity.id))
      .filter((activity) => activity.type !== 'project')
      .filter((activity) => !activity['$rendered_parent'] == '0')
      .filter(
        (activity) => !this.activitiesLinkedToChainStart.has(activity.id)
      );
    deleteChainStartActivitiesFromAllActivities.forEach((activity) => {
      this.activitiesPendingToBeCalculated.add(activity.id);
    });
    // this.activitiesPendingToBeCalculated.add(deleteChainStartActivitiesFromAllActivities.map((activity) => activity.id));
  }
  /**
   * Removes activities linked to chain start activities from the pending queue.
   *
   * This function iterates over the activities in the `activitiesLinkedToChainStart` array and removes each one
   * from the `activitiesPendingToBeCalculated` set. This ensures that these activities are no longer considered
   * pending for calculation.
   *
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activitiesLinkedToChainStart is an array containing activity IDs [1, 2, 3]
   * // and activitiesPendingToBeCalculated is a Set containing activity IDs [1, 2, 3, 4, 5]
   * deleteActivitiesLinkedToChainStartsFromPendingQueue();
   * // This will remove activity IDs [1, 2, 3] from the activitiesPendingToBeCalculated set
   *
   * @throws {Error} If an error occurs during the deletion process.
   */
  deleteActivitiesLinkedToChainStartsFromPendingQueue() {
    this.activitiesLinkedToChainStart.forEach((element) => {
      this.activitiesPendingToBeCalculated.delete(element);
    });
  }
  /**
   * Determines the least late time for an activity based on ES and EF constraints.
   *
   * This function compares the latest finish (LF) time from links with the earliest finish (EF) constraint
   * for the activity. If the LF from links is less than the EF constraint, it returns the LF from links.
   * Otherwise, it returns the ES and EF constraints.
   *
   * @param {string|number} activity - The ID of the activity.
   * @param {Object} lfFromLinks - An object containing LS (latest start) and LF (latest finish) times from links.
   * @param {Date} lfFromLinks.ls - The latest start time from links.
   * @param {Date} lfFromLinks.lf - The latest finish time from links.
   * @returns {Object} An object containing the least late LS and LF times.
   * @property {Date} ls - The least late latest start time.
   * @property {Date} lf - The least late latest finish time.
   *
   * @example
   * // Assuming forwardConstraintsMap contains the ES and EF constraints for the activity
   * const result = getTheLessLateInBasEsEfConstraint(1, { ls: new Date(), lf: new Date() });
   * console.log(result); // { ls: Date, lf: Date }
   *
   * @throws {Error} If an error occurs during the process.
   */
  getTheLessLateInBasEsEfConstraint(activity, lfFromLinks, constraintType) {
    let esEfFromConstraint = this.forwardConstraintsMap.get(activity);
    if (!esEfFromConstraint) {
      const activityReference = this.gantt.getTask(activity);
      const activityCalendar = this.gantt.getCalendar(
        activityReference.calendar_id
      );
      esEfFromConstraint = calculateRestrictionAsForward(
        activityReference,
        constraintType,
        activityCalendar
      );
    }

    if (lfFromLinks.lf < esEfFromConstraint.ef) {
      return lfFromLinks;
    }

    return { ls: esEfFromConstraint.es, lf: esEfFromConstraint.ef };
  }
  /**
   * Checks whether all activities linked to the given set of activities have progress greater than 0.
   *
   * This function iterates over the linked activities, retrieves their data, and checks if all of them have progress greater than 0.
   * If any linked activity has 0 progress, the function returns false. If all linked activities have progress, it returns true.
   *
   * @param {Array<string|number>} linkedActivities - An array of linked activity IDs.
   * @returns {boolean} True if all linked activities have progress greater than 0, otherwise false.
   *
   * @example
   * // Assuming linkedActivities is an array containing linked activity IDs [1, 2, 3]
   * const allHaveProgress = allLinkedActivitiesHasProgress([1, 2, 3]);
   * console.log(allHaveProgress); // true or false based on the progress of the linked activities
   *
   * @throws {Error} If an error occurs during the process, such as missing link data or activity data.
   */
  allLinkedActivitiesHasProgress(linkedActivities) {
    try {
      let allLinkedHasProgress = true;
      linkedActivities.map((link) => {
        const linkData = this.gantt.getLink(link);
        if (!linkData) {
          throw new Error(`Link data not found with id ${link}`);
        }
        const activityData = this.gantt.getTask(linkData.target);
        if (!activityData) {
          throw new Error(`Activity data not found with id ${linkData.target}`);
        }
        if (activityData.progress === 0) {
          allLinkedHasProgress = false;
        }
      });

      return allLinkedHasProgress;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  /**
   * Sets the calculations for an activity that has 100% progress.
   *
   * This function uses the activity's start and end dates to set the calculations for the activity.
   * It is assumed that the activity has 100% progress.
   *
   * @param {Object} activity - The activity object containing the details of the activity.
   * @param {Date} activity.start_date - The start date of the activity.
   * @param {Date} activity.end_date - The end date of the activity.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activity is an object with start_date and end_date properties
   * setWhenActivityHasFullProgress({
   *   start_date: new Date('2023-01-01'),
   *   end_date: new Date('2023-01-10')
   * });
   * // This will set the calculations for the activity using its start and end dates.
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  setWhenActivityHasFullProgress(activity) {
    this.setCalculations(activity, activity.start_date, activity.end_date);
  }
  /**
   * Sets the calculations for an activity when all its linked activities have progress.
   *
   * This function calculates the latest start (LS) and latest finish (LF) times for the activity using a backward direction,
   * and sets these values in the internal calculations.
   *
   * @param {Object} activity - The activity object containing the details of the activity.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activity is an object with necessary properties for calculation
   * setWhenAllLinkedActivitiesHasProgress(activity);
   * // This will calculate LS and LF for the activity and set these values in the internal calculations.
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  setWhenAllLinkedActivitiesHasProgress(activity) {
    const { ls, lf } = this.calculateStartAndFinishOfChainOrigin(
      activity,
      'backward'
    );
    this.setCalculations(activity, ls, lf);
    return;
  }
  /**
   * Sets the calculations for an activity with progress greater than 0% but less than 100%.
   *
   * This function handles activities based on their constraint type when the progress is between 0% and 100%.
   * It sets the calculations accordingly using the activity's start and end dates, or the constraint dates.
   *
   * @param {Object} activity - The activity object containing the details of the activity.
   * @param {string} constraintType - The constraint type of the activity, such as 'mso' or 'mfo'.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activity is an object with start_date, end_date, and constraint_date properties, and constraintType is 'mso'
   * setWhenActivitiesHasProgressButLessThanOneHundred(activity, 'mso');
   * // This will set the calculations for the activity using its start and end dates.
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  setWhenActivitiesHasProgressButLessThanOneHundred(activity, constraintType) {
    if (constraintType === CONSTRAINT_TYPES.MSO) {
      this.setCalculations(activity, activity.start_date, activity.end_date);
      return;
    }

    if (constraintType === CONSTRAINT_TYPES.MFO) {
      const minDateBetween = Math.min(
        activity.constraint_date,
        this.gantt.getSubtaskDates().end_date
      );
      const maxBetween = Math.max(minDateBetween, activity.end_date);
      this.setCalculations(activity, activity.start_date, maxBetween);
      return;
    }
  }
  /**
   * Sets the latest start (LS) and latest finish (LF) times for a given activity in the internal calculations map.
   *
   * This function stores the LS and LF times, along with the activity's text, in the internal `calculations` map
   * using the activity's ID as the key.
   *
   * @param {Object} activity - The activity object containing the details of the activity.
   * @param {Date} ls - The latest start time for the activity.
   * @param {Date} lf - The latest finish time for the activity.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activity is an object with id and text properties, and ls and lf are Date objects
   * setCalculations(activity, new Date('2023-01-01'), new Date('2023-01-10'));
   * // This will store the LS and LF times in the calculations map for the given activity.
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  setCalculations(activity, ls, lf) {
    this.calculations.set(activity.id, {
      ls,
      lf,
      text: activity.text
    });
  }
  /**
   * Calculates the latest start (LS) and latest finish (LF) times for an activity based on its links with successors and the given constraint type.
   *
   * This function creates a new instance of the `LinksConstraintCalculator` class with the provided parameters and calls its `calculate` method
   * to determine the LS and LF times for the activity.
   *
   * @param {Array<string|number>} linksWithSucessors - An array of links to successor activities.
   * @param {Object} activityReference - The activity object for which to calculate LS and LF times.
   * @param {string} constraintType - The constraint type of the activity.
   * @returns {Object} An object containing the calculated latest start (LS) and latest finish (LF) times.
   * @property {Date} ls - The calculated latest start time.
   * @property {Date} lf - The calculated latest finish time.
   *
   * @example
   * // Assuming linksWithSucessors is an array of link IDs, activityReference is an activity object,
   * // and constraintType is a string representing the constraint type
   * const result = calculateLfandLsFromLinks([1, 2, 3], activityReference, 'mso');
   * console.log(result); // { ls: Date, lf: Date }
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  calculateLfandLsFromLinks(
    linksWithSucessors,
    activityReference,
    constraintType
  ) {
    return new LinksConstraintCalculator({
      links: linksWithSucessors,
      activity: activityReference,
      calculatedActivitiesMap: this.calculations,
      calculatedForwardActivitiesMap: this.forwardPathCalculations,
      constraint: constraintType,
      ganttInstance: this.gantt
    }).calculate();
  }
  /**
   * Recalculates the latest start (LS) and latest finish (LF) times for an activity based on its constraint type.
   *
   * This function fetches the activity's calendar, calculates the restriction based on the constraint type,
   * updates the constraint map, and sets the new calculations for the activity.
   *
   * @param {Object} activity - The activity object containing the details of the activity.
   * @param {string} constraintType - The constraint type of the activity.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activity is an object with calendar_id property, and constraintType is 'mso'
   * recalculateInBaseRestriction(activity, 'mso');
   * // This will recalculate the LS and LF times for the activity based on the constraint type and update the internal calculations.
   *
   * @throws {Error} If an error occurs during the calculation process, such as missing calendar data.
   */
  recalculateInBaseRestriction(activity, constraintType) {
    try {
      const activityCalendar = this.gantt.getCalendar(activity.calendar_id);
      if (!activityCalendar) {
        throw new Error(
          `Error trying to get calendar with id: ${activity.calendar_id}`
        );
      }
      let restriction = calculateRestriction(
        activityCalendar,
        activity,
        constraintType
      );
      this.constraintMap.set(activity, restriction);
      this.setCalculations(activity, restriction.ls, restriction.lf);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  cleanAllActivityParentsFromLinks(links) {
    //Temporary code for parent activities
    return links
      .map((link) => {
        let sourceId = this.gantt.getLink(link).source;
        let targetId = this.gantt.getLink(link).target;
        if (
          !this.gantt.getTask(sourceId) ||
          this.gantt.getTask(sourceId)?.type === 'project'
        )
          return;
        if (
          !this.gantt.getTask(targetId) ||
          this.gantt.getTask(targetId)?.type === 'project'
        )
          return;

        return link;
      })
      .filter(Boolean);
  }
}

export default BackwardPath;
