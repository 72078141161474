export const adjustGridWidth = (gantt) => () => {
  if (!gantt) return;
  const currentDOMElement = document.getElementsByClassName(
    'gantt_layout_cell gantt_layout gantt_layout_y  gantt_layout_cell_border_right'
  );
  if (!currentDOMElement[0]) return;
  const originalGridWidth = parseInt(currentDOMElement[0].style.width);
  const totalGridWidth = gantt.config.grid_width;
  const middleScreenWidth = window.innerWidth * 0.5;
  gantt.refreshData();
  if (originalGridWidth < totalGridWidth) {
    gantt.hasCustomResize = true;
  } else if (totalGridWidth > middleScreenWidth) {
    gantt.hasCustomResize = false;
    gantt.$layout.$cells[0].$config.width = totalGridWidth;
    gantt.render();
  } else if (
    gantt.config &&
    gantt.config.layout &&
    gantt.config.layout.cols instanceof Array &&
    gantt.config.layout.cols[0]
  ) {
    gantt.hasCustomResize = false;
    gantt.$layout.$cells[0].$config.width = totalGridWidth;
    gantt.optimizedRender();
  }
};
