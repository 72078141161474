import React, { useState, useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';
import { Spin, Icon } from 'antd';
import moment from 'moment';
import cardImgDefault from '../../../../assets/img/Group-1.png';

export default function CurvePpcMini(props) {
  /** props */
  const { t } = props;
  let planificationDay = props.planificationDay;
  const commints = props.data;

  /** consts  */
  let weeks_number = [];
  const labels = [];
  const commitment_percentajes = [];
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  /** hooks */
  const [ppcs, setPpcs] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      height: '250',
      sparkline: {
        enabled: true
      },
      type: 'line',
      colors: ['#F44336'],
      toolbar: {
        show: false
      }
    },
    stroke: {
      width: [0, 2],
      curve: ['smooth', 'straight', 'stepline']
    },
    title: {
      text: ''
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1]
    },
    labels: [],
    xaxis: {
      type: 'datetime'
    },
    yaxis: [
      {
        title: {
          text: ''
        }
      },
      {
        opposite: true,
        title: {
          text: ''
        }
      }
    ],
    colors: ['#155D77', '#7DFF8A'],
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return <p>series[seriesIndex][dataPointIndex]</p>;
      }
    },
    toolbar: {
      show: false
    }
  });
  const [series, setSeries] = useState([
    {
      name: 'Porcentaje',
      type: 'column',
      data: null
    }
  ]);

  useEffect(() => {
    setTimeout(() => {
      /** get week number */
      if (commints) {
        const ppcs_temp = [];
        commints.map((commint) => {
          const init_date = moment(commint.start_date);
          // se debe arregkar
          const weeknumber = init_date.week();
          const week_object = { week: commint.week, year: init_date.year() };
          const validate = weeks_number.find(
            (object) =>
              object.week == commint.week && object.year == init_date.year()
          );
          if (!validate) {
            weeks_number.push(week_object);
          }
        });
        weeks_number = [...new Set(weeks_number)];
        weeks_number.sort((a, b) =>
          moment()
            .locale(t('lang'))
            .year(a.year)
            .week(a.week)
            .startOf('isoweek')
            .diff(moment().locale(t('lang')).year(b.year).week(b.week))
        );
        weeks_number.map((week_n) => {
          if (planificationDay == 0) {
            planificationDay = 6;
          }
          let week_for_text = week_n.week;
          if (week_n.year == 2021) {
            week_for_text = week_n.week - 1;
          }
          const data_week = moment()
            .locale(t('lang'))
            .year(week_n.year)
            .week(week_for_text)
            .startOf('isoweek')
            .add(planificationDay - 1 + 6, 'days');

          const label = data_week.format('DD MMM YY');

          labels.push(label);
          let total = 0;
          let realized = 0;
          commints.map((commint) => {
            if (commint.week == week_n.week && commint.year == week_n.year) {
              total = total + commint.taskcommitments?.length;
              realized = realized + commint.realized_tasks;
            }
          });

          const commitment_percentaje = (realized / total) * 100;
          commitment_percentajes.push(commitment_percentaje.toFixed(2));
          const ppc = {
            label: label,
            commitment_percentaje: commitment_percentaje,
            total: total,
            realized: realized
          };
          ppcs_temp.push(ppc);
        });
        setPpcs(ppcs_temp);
        const option = {
          chart: {
            height: 250,
            sparkline: {
              enabled: true
            },
            grid: {
              show: false,
              padding: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
              },

              yaxis: {
                lines: {
                  show: false
                }
              }
            },
            type: 'line',
            colors: ['#F44336'],
            toolbar: {
              show: false,
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: true,
                zoomout: true,
                pan: false,
                reset: false,
                customIcons: []
              }
            }
          },
          plotOptions: {
            bar: {
              // endingShape: 'rounded',
              startingShape: 'flat',
              // endingShape: 'flat',
              columnWidth: '50%',
              barHeight: '50%',
              distributed: false,
              rangeBarOverlap: true,
              radius: 30
            }
          },
          stroke: {
            width: [0, 2],
            curve: ['smooth', 'straight', 'stepline']
          },

          dataLabels: {
            enabled: false
          },
          // labels: labels,
          xaxis: {
            type: 'category',
            labels: {},
            categories: labels,
            tickPlacement: 'on'
          },
          yaxis: [
            {
              show: false,
              title: {
                text: ''
              },
              max: 100
            },
            {
              opposite: true,
              title: {
                text: ''
              }
            }
          ],
          colors: ['#155D77', '#7DFF8A'],
          tooltip: {
            shared: true,
            intersect: false,
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return ''; // return '<div>' + `${t('lang') === 'en' ? series[seriesIndex][dataPointIndex] : series[seriesIndex][dataPointIndex].toString().replace('.',',')}` + '%  <br>' + ppcs_temp[dataPointIndex].realized + '/' + ppcs_temp[dataPointIndex].total + ' ' + t('analictic.tasks') + '</div>';
            }
          }
        };
        const seriesUp = [
          {
            name: 'Porcentaje',
            type: 'column',
            data: commitment_percentajes
          }
        ];
        setOptions(option);
        setSeries(seriesUp);
      }
    }, 500);
  }, [commints]);

  return (
    <div style={{ height: '100%' }}>
      {series[0].data === null ? (
        <div className="widget-analitycs">
          <Spin
            className="loader-spinner-lookahead-table"
            indicator={antIcon}
          />
        </div>
      ) : series[0].data?.length ? (
        <Chart
          options={options}
          series={series}
          type="bar"
          width="100%"
          height="100%"
        />
      ) : null}
    </div>
  );
}
