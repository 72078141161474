/* eslint-disable prefer-const */

/** React components  */
import React, { useEffect, useState } from 'react';

/** import component SelectSearch for responsables */
import SelectSearch from 'react-select-search';

/** Import elements from library Antd */
import { Tooltip, Avatar, Popover } from 'antd';

/** Redux implementation */
import { useSelector, useDispatch } from 'react-redux';
import { constraintActions } from '../../../../../redux/actions/constraintActions';

/** Import fakeAvatar png */
import fakeAvatar from '../../../../../assets/img/carbon_user-avatar.svg';

export default function ConstraintResponsables(props) {
  /** Redux */
  const dispatch = useDispatch();
  const constraintState = useSelector((state) => state.constraintState);

  /** use props */
  const { responsableValid, toSelectResponsables, t } = props;

  /* hooks */
  const [visiblePopUsers, setVisiblePopUsers] = useState(false);
  const [responsible, setResponsible] = useState(null);

  useEffect(() => {
    let currentResponsible = toSelectResponsables.find(
      (responsible) =>
        responsible.id === constraintState.constraintForm.responsable
    );
    setResponsible(currentResponsible);
  }, [constraintState?.constraintForm?.responsable]);

  /** Component Logic */
  // const handleClickResponsable = (type) => {
  //     dispatch(constraintActions.setConstraintForm({
  //         ...constraintState.constraintForm,
  //         responsable: type.id
  //     }))
  // }
  /**
   * This function builds a JSX to select responsables at inline edition
   */
  const renderFriend = (props, option, snapshot, className) => {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10
    };

    let toShowName = `${option.object.name} ${option.object.lastname}`;

    if (toShowName) {
      if (toShowName.length > 15) {
        toShowName = toShowName.slice(0, 15) + '...';
      }
    }

    return (
      <button
        {...props}
        className={className}
        type="button"
        title={option.object.email}>
        <span>
          <img
            alt=""
            style={imgStyle}
            width="16"
            height="16"
            src={option.photo}
          />
          <span style={{ fontSize: 12 }}>{toShowName}</span>
        </span>
      </button>
    );
  };

  const getNameResponsible = (responsible) => {
    if (responsible.name) {
      return responsible.lastname
        ? `${responsible.name} ${responsible.lastname}`
        : responsible.name;
    }

    return responsible.email;
  };

  /** Declare valid format to select responsables from user array */

  const friends = [];

  toSelectResponsables &&
    toSelectResponsables.map((user) => {
      if (user.is_active) {
        friends.push({
          name: user.name,
          value: String(user.id),
          photo: user.image || fakeAvatar,
          object: user
        });
      }
    });

  let old = false;

  /** render */
  return friends && old ? (
    <div className="responsable-wrapper">
      <Popover
        overlayClassName="container-image-custom"
        className="popoverPriority"
        content={
          <SelectSearch
            printOptions="always"
            className="select-search select-search--multiple"
            options={friends}
            renderOption={renderFriend}
            onChange={(val) => {
              dispatch(
                constraintActions.setConstraintForm({
                  ...constraintState.constraintForm,
                  responsable: parseInt(val)
                })
              );
              setVisiblePopUsers(false);
            }}
            search
            placeholder={t('search_res_text')}
          />
        }
        trigger="click"
        visible={visiblePopUsers}
        onVisibleChange={(val) => setVisiblePopUsers(val)}>
        <Tooltip
          title={
            constraintState.constraintForm &&
            constraintState.constraintForm.responsable
              ? toSelectResponsables.find(
                  (el) => el.id === constraintState.constraintForm.responsable
                ).email
              : null
          }>
          <Avatar
            className="avatar-constraint-responsable"
            shape="circle"
            src={
              constraintState.constraintForm &&
              constraintState.constraintForm.responsable
                ? toSelectResponsables.find(
                    (el) => el.id === constraintState.constraintForm.responsable
                  ).image
                  ? toSelectResponsables.find(
                      (el) =>
                        el.id === constraintState.constraintForm.responsable
                    ).image
                  : fakeAvatar
                : fakeAvatar
            }
            required
          />
        </Tooltip>
      </Popover>
      <label
        className={`constraint-responsable-lbl-error ${!responsableValid ? 'constraint-responsable-lbl-error' : 'notVisible'}`}>
        {t('select_responsable_tooltip')}
      </label>
    </div>
  ) : (
    <div
      className={`responsible__container ${constraintState.constraintForm.type === null ? 'disabled' : ''}`}>
      <div
        className={`container__avatar ${constraintState.constraintForm.type === null ? 'disabled' : ''}`}>
        <Popover
          overlayClassName="container-image-custom-responsible"
          className="popoverPriority"
          content={
            <SelectSearch
              printOptions="always"
              className="select-search select-search--multiple"
              options={friends}
              renderOption={renderFriend}
              onChange={(val) => {
                dispatch(
                  constraintActions.setConstraintForm({
                    ...constraintState.constraintForm,
                    responsable: parseInt(val)
                  })
                );
                setVisiblePopUsers(false);
              }}
              search
              placeholder={t('constraint_input_search_responsible')}
            />
          }
          trigger={constraintState.constraintForm.type === null ? '' : 'click'}
          visible={visiblePopUsers}
          onVisibleChange={(val) => setVisiblePopUsers(val)}>
          <Tooltip
            title={
              constraintState.constraintForm &&
              constraintState.constraintForm.responsable
                ? toSelectResponsables.find(
                    (el) => el.id === constraintState.constraintForm.responsable
                  ).email
                : null
            }>
            <Avatar
              className="avatar-constraint-responsable"
              shape="circle"
              src={
                constraintState.constraintForm &&
                constraintState.constraintForm.responsable
                  ? toSelectResponsables.find(
                      (el) =>
                        el.id === constraintState.constraintForm.responsable
                    ).image
                    ? toSelectResponsables.find(
                        (el) =>
                          el.id === constraintState.constraintForm.responsable
                      ).image
                    : fakeAvatar
                  : fakeAvatar
              }
              required
            />
            <div className="container__name">
              <span className={`${responsible ? 'name__active' : ''}`}>
                {responsible
                  ? getNameResponsible(responsible)
                  : `${t('constraint_add_responsible')}`}
              </span>
            </div>
          </Tooltip>
        </Popover>
      </div>
    </div>
  );
}
