/**
 * Difference between two dates in days
 * @param {*} dateA date which is going to be substract
 * @param {*} dateB date to subtract
 * @returns {date} Return in difference in days
 */
export const getDiffDates = (dateA, dateB) => {
  const diff = dateB.getTime() - dateA.getTime();

  return diff / (1000 * 60 * 60 * 24);
};

/**
 * This function set hours and minutes to 0 for specific date
 * @param {string} time timedate in string
 * @returns {date} date instanced with 0 minutes and 0 hours
 */
export const getTimeToZero = (time) => {
  const date = time ? new Date(time) : new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
};

/**
 * This function use pointer given to calculate the trending column
 * @param {*} constraint Constraint pointer to be setted the values
 */
export const calculateTrendingForConstraint = (constraint) => {
  let trending;
  if (!constraint) return;
  if (!constraint.commitmentDate) {
    constraint.trending = 0;
    return;
  }
  const commitment = getTimeToZero(constraint.commitmentDate);
  if (constraint.status === 'released') {
    const release = getTimeToZero(constraint.release_date);
    trending = getDiffDates(release, commitment);
  } else {
    const today = getTimeToZero();
    trending = getDiffDates(today, commitment);
  }
  trending = parseInt(trending.toFixed(2));
  constraint.trending = trending;
};

/**
 * This function calculates the trending for all constraints
 * @param {Array} constraints array of constraints to calculate the trending value
 */
export const setTrending = (constraints) => {
  constraints.forEach(calculateTrendingForConstraint);
};
