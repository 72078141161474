import React, { Fragment } from 'react';

function SettingsUser(props) {
  const { t } = props;
  return (
    <Fragment>
      <button className="button-nav-settings border-nav-settings">
        {t('settings.user')}
      </button>
      {/* <button className="button-nav-settings">ProPlanner</button>
            <button className="button-nav-settings">Notificaciones</button> */}
    </Fragment>
  );
}

export default SettingsUser;
