import React, { useState } from 'react';
import Gantt from './Gantt';
import Navegacion from './Navegacion';
import Stage from './Stage';

function CardTemplate() {
  const [Router, setRouter] = useState(1);
  return (
    <div>
      <Navegacion setRouter={setRouter} Router={Router} />
      {Router === 1 && <Gantt />}
      {Router === 2 && <Stage />}
    </div>
  );
}

export default CardTemplate;
