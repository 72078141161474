import Edificio from '../../../assets/img/takt/Edificio.svg';
import Vivienda from '../../../assets/img/takt/Vivienda.svg';
import Vialidad from '../../../assets/img/takt/Vialidad.svg';
import Tunel from '../../../assets/img/takt/Tunel.svg';

export const fakeStructures = [
  {
    name: 'Estructura 1',
    id: 1,
    user: {
      name: 'Alberto Herrera'
    },
    project: {
      name: 'Proyecto prueba'
    },
    sector: {
      name: 'Sector prueba'
    },
    creation_date: '20-10-10'
  },
  {
    name: 'Estructura 2',
    id: 2,
    user: {
      name: 'Alberto Herrera'
    },
    project: {
      name: 'Proyecto prueba'
    },
    sector: {
      name: 'Sector prueba'
    },
    creation_date: '20-10-10'
  },
  {
    name: 'Estructura 3',
    id: 3,
    user: {
      name: 'Alberto Herrera'
    },
    project: {
      name: 'Proyecto prueba'
    },
    sector: {
      name: 'Sector prueba'
    },
    creation_date: '20-10-10'
  },
  {
    name: 'Estructura 4',
    id: 4,
    user: {
      name: 'Alberto Herrera'
    },
    project: {
      name: 'Proyecto prueba'
    },
    sector: {
      name: 'Sector prueba'
    },
    creation_date: '20-10-10'
  },
  {
    name: 'Estructura 5',
    id: 5,
    user: {
      name: 'Alberto Herrera'
    },
    project: {
      name: 'Proyecto prueba'
    },
    sector: {
      name: 'Sector prueba'
    },
    creation_date: '20-10-10'
  },
  {
    name: 'Estructura 6',
    id: 6,
    user: {
      name: 'Alberto Herrera'
    },
    project: {
      name: 'Proyecto prueba'
    },
    sector: {
      name: 'Sector prueba'
    },
    creation_date: '20-10-10'
  }
];

export const prototypeStructure = [
  {
    id: 1,
    name: 'Edificio en altura',
    icon: Edificio,
    description:
      'Los edificios en altura, suelen llamar sus ubicaciones Pisos o Niveles, y a sus unidades de produccion, Departamentos.',
    location_label: 'location_levels',
    location_label_translate: 'quantity_location',
    production_unit_label: 'Unidad(Depto/Oficina)',
    production_unit_label_translate: 'unit_depto',
    hasauto: true,
    translate_label: 'building'
  },
  {
    id: 2,
    name: 'Viviendas en extensión',
    icon: Vivienda,
    description: '',
    location_label: '',
    production_unit_label: '',
    hasauto: false,
    translate_label: 'homes'
  },
  {
    id: 3,
    name: 'Vialidad',
    icon: Vialidad,
    description: '',
    location_label: '',
    production_unit_label: '',
    hasauto: false,
    translate_label: 'road'
  },
  {
    id: 4,
    name: 'Tunel',
    icon: Tunel,
    description: '',
    location_label: '',
    production_unit_label: '',
    hasauto: false,
    translate_label: 'tunnel'
  }
];
