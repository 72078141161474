import { addDurationToDate, getNextWorkingHour } from '../../helpers/index';
import { LINK_TYPES } from '../../constants/index';

class LinksRulesCalculationsMethods {
  constructor(calculationObject) {
    this.referenceActivity = calculationObject.activity;
    this.activityToCalculate = null;
    this.links = calculationObject.links;
    this.calculatedActivities = calculationObject.calculatedActivitiesMap;
    this.alapMap = calculationObject.calculatedAlapsMap;
    this.gantt = calculationObject.ganttInstance;
  }
  /**
   * Retrieves the earliest start (ES) and earliest finish (EF) dates for a given activity from previously calculated activities.
   *
   * This function checks if the activity exists in the `alapMap`. If it does, it returns the data from `alapMap`.
   * If the activity is not found in `alapMap`, it retrieves and returns the data from `calculatedActivities`.
   *
   * @param {string|number} activity - The ID of the activity for which to retrieve ES and EF dates.
   * @returns {Object|null} The ES and EF dates for the activity, or null if not found.
   *
   * @example
   * // Assuming alapMap and calculatedActivities are Maps with activity data
   * const esEf = getEsAndEfFromPreviousCalculatedActivities(1);
   * console.log(esEf); // { es: ..., ef: ..., text: ... } or null
   *
   * @throws {Error} If an error occurs during the retrieval process.
   */
  getEsAndEfFromPreviousCalculatedActivities(activity) {
    const isAlap = this.alapMap.has(activity);

    if (isAlap) {
      return this.alapMap.get(activity);
    }

    return this.calculatedActivities.get(activity);
  }
  /**
   * Calculates the earliest start (ES) and earliest finish (EF) times for a successor activity.
   *
   * This function calculates the ES and EF times for a successor activity based on the provided predecessor time,
   * data calculations for ES and EF, lag time, and the type of link restriction. It adjusts the dates according
   * to the activity's calendar and the specified restrictions.
   *
   * @param {Date} predecessorTime - The time of the predecessor activity.
   * @param {number} esDataCalculation - The amount of time to add to the predecessor time to calculate the earliest start.
   * @param {number} [efDataCalculation=0] - The amount of time to add to calculate the earliest finish.
   * @param {number} lag - The lag time between the predecessor and successor activities.
   * @param {string} [restrictionOfLink='fs'] - The type of link restriction between the activities.
   * @returns {Object} An object containing the calculated earliest start (ES) and earliest finish (EF) times.
   * @property {Date} es - The earliest start time.
   * @property {Date} ef - The earliest finish time.
   *
   * @example
   * // Assuming predecessorTime is a Date object, esDataCalculation is 5, efDataCalculation is 10, lag is 2, and restrictionOfLink is 'fs'
   * const result = calculateSucessorTimes(new Date(), 5, 10, 2, 'fs');
   * console.log(result); // { es: Date, ef: Date }
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  calculateSucessorTimes(
    predecessorTime,
    esDataCalculation,
    efDataCalculation = 0,
    lag,
    restrictionOfLink = LINK_TYPES.FS
  ) {
    try {
      let activityCalendar = this.gantt.getCalendar(
        this.referenceActivity.calendar_id
      );
      if (!activityCalendar) {
        throw new Error(
          `Error trying to get calendar with id: ${this.referenceActivity.calendar_id}`
        );
      }
      let dateBaseToCalculate = predecessorTime;
      let es = null;
      let ef = null;
      let isAMilestone = this.referenceActivity.type === 'milestone';

      if (!isAMilestone) {
        if (restrictionOfLink === LINK_TYPES.FS && lag === 0) {
          dateBaseToCalculate = getNextWorkingHour({
            dateBaseToCalculate,
            activityCalendar,
            direction: 'future',
            activity: this.referenceActivity
          });
        } else {
          dateBaseToCalculate =
            this.mutateTheDateToFutureOrPastInBaseRestriction(
              predecessorTime,
              restrictionOfLink,
              activityCalendar
            );
        }
      }

      if ([LINK_TYPES.FF, LINK_TYPES.SF].includes(restrictionOfLink)) {
        ef = addDurationToDate(
          activityCalendar,
          dateBaseToCalculate,
          efDataCalculation,
          this.referenceActivity
        );

        es = addDurationToDate(
          activityCalendar,
          ef,
          esDataCalculation,
          this.referenceActivity
        );

        if (!isAMilestone && lag > 0) {
          es = this.mutateTheDateToFutureOrPastInBaseRestriction(
            es,
            restrictionOfLink,
            activityCalendar
          );
        }
      } else {
        es = addDurationToDate(
          activityCalendar,
          dateBaseToCalculate,
          esDataCalculation,
          this.referenceActivity
        );

        if (!isAMilestone && lag > 0) {
          es = this.mutateTheDateToFutureOrPastInBaseRestriction(
            es,
            restrictionOfLink,
            activityCalendar
          );
        }

        ef = addDurationToDate(
          activityCalendar,
          es,
          efDataCalculation,
          this.referenceActivity
        );
      }

      return { es, ef };
    } catch (error) {
      throw new Error(error.message);
    }
  }
  /**
   * Calculates the earliest start (ES) and earliest finish (EF) times for a Start-to-Start (SS) relationship.
   *
   * This function calculates the ES and EF times for a successor activity based on a Start-to-Start (SS) relationship
   * with the predecessor activity. It uses the predecessor's ES time and adjusts it based on the link's lag and the
   * activity's duration.
   *
   * @param {Object} link - The link object representing the relationship between the predecessor and successor activities.
   * @param {number} link.source - The ID of the predecessor activity.
   * @param {number} [link.lag=0] - The lag time between the predecessor and successor activities.
   * @returns {Object} An object containing the calculated earliest start (ES) and earliest finish (EF) times.
   * @property {Date} es - The earliest start time.
   * @property {Date} ef - The earliest finish time.
   *
   * @example
   * // Assuming link is an object with a source property (predecessor ID) and an optional lag property
   * const result = calculateSS({ source: 1, lag: 2 });
   * console.log(result); // { es: Date, ef: Date }
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  calculateSS(link) {
    let predecessorCalculatedEsAndEf =
      this.getEsAndEfFromPreviousCalculatedActivities(Number(link.source));
    let esDataCalculation = link.lag || 0;
    let duration = this.referenceActivity.duration;
    let efDataCalculation = duration;
    let lag = link.lag || 0;

    return this.calculateSucessorTimes(
      predecessorCalculatedEsAndEf.es,
      esDataCalculation,
      efDataCalculation,
      lag,
      LINK_TYPES.SS
    );
  }
  /**
   * Calculates the earliest start (ES) and earliest finish (EF) times for a Finish-to-Finish (FF) relationship.
   *
   * This function calculates the ES and EF times for a successor activity based on a Finish-to-Finish (FF) relationship
   * with the predecessor activity. It uses the predecessor's EF time and adjusts it based on the link's lag and the
   * activity's duration.
   *
   * @param {Object} link - The link object representing the relationship between the predecessor and successor activities.
   * @param {number} link.source - The ID of the predecessor activity.
   * @param {number} [link.lag=0] - The lag time between the predecessor and successor activities.
   * @returns {Object} An object containing the calculated earliest start (ES) and earliest finish (EF) times.
   * @property {Date} es - The earliest start time.
   * @property {Date} ef - The earliest finish time.
   *
   * @example
   * // Assuming link is an object with a source property (predecessor ID) and an optional lag property
   * const result = calculateFF({ source: 1, lag: 2 });
   * console.log(result); // { es: Date, ef: Date }
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  calculateFF(link) {
    let predecessorCalculatedEsAndEf =
      this.getEsAndEfFromPreviousCalculatedActivities(Number(link.source));

    let duration = this.referenceActivity.duration;
    let esDataCalculation = -duration;
    let efDataCalculation = link.lag || 0;
    let lag = link.lag || 0;

    return this.calculateSucessorTimes(
      predecessorCalculatedEsAndEf.ef,
      esDataCalculation,
      efDataCalculation,
      lag,
      LINK_TYPES.FF
    );
  }
  /**
   * Calculates the earliest start (ES) and earliest finish (EF) times for a Finish-to-Start (FS) relationship.
   *
   * This function calculates the ES and EF times for a successor activity based on a Finish-to-Start (FS) relationship
   * with the predecessor activity. It uses the predecessor's EF time and adjusts it based on the link's lag and the
   * activity's duration.
   *
   * @param {Object} link - The link object representing the relationship between the predecessor and successor activities.
   * @param {number} link.source - The ID of the predecessor activity.
   * @param {number} [link.lag=0] - The lag time between the predecessor and successor activities.
   * @returns {Object} An object containing the calculated earliest start (ES) and earliest finish (EF) times.
   * @property {Date} es - The earliest start time.
   * @property {Date} ef - The earliest finish time.
   *
   * @example
   * // Assuming link is an object with a source property (predecessor ID) and an optional lag property
   * const result = calculateFS({ source: 1, lag: 2 });
   * console.log(result); // { es: Date, ef: Date }
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  calculateFS(link) {
    let predecessorCalculatedEsAndEf =
      this.getEsAndEfFromPreviousCalculatedActivities(Number(link.source));
    let esDataCalculation = link.lag || 0;
    let duration = this.referenceActivity.duration;
    let efDataCalculation = duration;
    let lag = link.lag || 0;

    return this.calculateSucessorTimes(
      predecessorCalculatedEsAndEf.ef,
      esDataCalculation,
      efDataCalculation,
      lag,
      LINK_TYPES.FS
    );
  }
  /**
   * Calculates the earliest start (ES) and earliest finish (EF) times for a Start-to-Finish (SF) relationship.
   *
   * This function calculates the ES and EF times for a successor activity based on a Start-to-Finish (SF) relationship
   * with the predecessor activity. It uses the predecessor's EF time and adjusts it based on the link's lag and the
   * activity's duration.
   *
   * @param {Object} link - The link object representing the relationship between the predecessor and successor activities.
   * @param {number} link.source - The ID of the predecessor activity.
   * @param {number} [link.lag=0] - The lag time between the predecessor and successor activities.
   * @returns {Object} An object containing the calculated earliest start (ES) and earliest finish (EF) times.
   * @property {Date} es - The earliest start time.
   * @property {Date} ef - The earliest finish time.
   *
   * @example
   * // Assuming link is an object with a source property (predecessor ID) and an optional lag property
   * const result = calculateSF({ source: 1, lag: 2 });
   * console.log(result); // { es: Date, ef: Date }
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  calculateSF(link) {
    let predecessorCalculatedEsAndEf =
      this.getEsAndEfFromPreviousCalculatedActivities(Number(link.source));
    let duration = this.referenceActivity.duration;
    let esDataCalculation = -duration;
    let efDataCalculation = link.lag || 0;
    let lag = link.lag || 0;

    return this.calculateSucessorTimes(
      predecessorCalculatedEsAndEf.es,
      esDataCalculation,
      efDataCalculation,
      lag,
      LINK_TYPES.SF
    );
  }

  /**
   * Adjusts a date to the next working hour based on the type of link restriction and the activity's calendar.
   *
   * This function adjusts the provided date either to a future or past working hour based on the specified link restriction.
   * It uses the activity's calendar to determine the working hours and calculates the appropriate date.
   *
   * @param {Date} dateBaseToCalculate - The initial date that needs to be adjusted.
   * @param {string} restrictionOfLink - The type of link restriction (e.g., 'fs', 'ss', 'ff').
   * @param {Object} activityCalendar - The calendar associated with the activity, used to determine working hours.
   * @returns {Date} The adjusted date based on the link restriction and activity calendar.
   *
   * @example
   * // Assuming dateBaseToCalculate is a Date object, restrictionOfLink is 'fs', and activityCalendar is a valid calendar object
   * const adjustedDate = mutateTheDateToFutureOrPastInBaseRestriction(new Date(), 'fs', activityCalendar);
   * console.log(adjustedDate); // The date adjusted to the next working hour in the future
   *
   * @throws {Error} If an error occurs during the date adjustment process.
   */
  mutateTheDateToFutureOrPastInBaseRestriction(
    dateBaseToCalculate,
    restrictionOfLink,
    activityCalendar
  ) {
    if (
      ![LINK_TYPES.FS, LINK_TYPES.SS, LINK_TYPES.FF].includes(restrictionOfLink)
    ) {
      return dateBaseToCalculate;
    }

    let constraintThatGoToNextWorkingHour = [LINK_TYPES.FS, LINK_TYPES.SS];
    let direction = constraintThatGoToNextWorkingHour.includes(
      restrictionOfLink
    )
      ? 'future'
      : 'past';

    return getNextWorkingHour({
      dateBaseToCalculate,
      activityCalendar,
      direction,
      activity: this.referenceActivity
    });
  }
}

export default LinksRulesCalculationsMethods;
