import React from 'react';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { CAMERA_ICON, DELETE_ICON } from '../constants';
import { headersSendForm } from '../../../../utils';
import { base } from '../../../../services/base';

const DeleteIconButton = ({ onClick }) => (
  <button className="delete-icon-button" onClick={onClick}>
    {DELETE_ICON}
  </button>
);

const CameraIconButton = React.memo(({ alt }) => (
  <button className="camera-icon-button">{CAMERA_ICON}</button>
));

const ProjectImageSection = React.memo(
  ({ t, state: { projectImage }, setField, handleImageChange }) => {
    const handleDeleteClick = (event) => {
      event.stopPropagation();
      setField('projectImage', false);
    };

    return (
      <div className="form__input--more-details__image">
        <label>{t('add_project_modal.project_image_field')}</label>
        <ImgCrop rotate shape="rect" aspect={6 / 4}>
          <Upload
            action={`${base.api}projects/upload`}
            listType="picture-card"
            showUploadList={false}
            headers={headersSendForm}
            onChange={handleImageChange}>
            <div className="project-image-uploader">
              {projectImage ? (
                <>
                  <DeleteIconButton onClick={handleDeleteClick} />
                  <img
                    src={projectImage}
                    alt="Project"
                    style={{ width: '100%' }}
                  />
                </>
              ) : null}
              <CameraIconButton alt={projectImage ? 'Edit' : 'Upload'} />
            </div>
          </Upload>
        </ImgCrop>
      </div>
    );
  }
);

export default ProjectImageSection;
