import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PopoverSystemV2 from '../PopoverSystemV2';
import TooltipSystemV2 from '../TooltipSystemV2';
import SplitTextSystem from '../SplitTextSystem';
import {
  SearchIcon,
  WarningDiamontIcon,
  HorizontalMenuOutlineIcon,
  DeleteTagOutlineIcon,
  InfoIconOutlined,
  CheckboxCheckedIcon,
  CheckboxCheckedAllIcon
} from '../../../icons';

const LIGHT_THEME = 'light';
const HEIGHT_ITEM = 30;
const HEIGHT_ITEM_GROUP = 35;
const WIDTH_DEFAULT = 200;
const DEFAULT_COUNT_ITEMS_SHOW = 5;

const PopoverMultiselectSystem = ({
  theme = LIGHT_THEME,
  width = WIDTH_DEFAULT,
  version,
  children,
  items = [],
  withSearch = true,
  withSelectAll = true,
  countItemsShow = DEFAULT_COUNT_ITEMS_SHOW,
  ctaText,
  ctaIcon,
  ctaFunction,
  onFocusSearchFunction,
  onCleanSearchFunction,
  onUsedSearchFunction,
  onShowEmptyStateFunction,
  onItemsCheckedFunction,
  itemReference,
  ...props
}) => {
  const { t } = useTranslation();

  const [isEmptyState, setIsEmptyState] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentItemsChecked, setCurrentItemsChecked] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setCurrentItems([...items]);
  }, [items]);

  useEffect(() => {
    setCurrentItemsChecked(getCheckedItems(items));
  }, [items]);

  const handleOnChangeSearch = (event) => {
    setSearchInput(event.target.value);

    if (event.target.value === '') {
      setCurrentItems([...items]);
      setIsEmptyState(false);

      return;
    }

    const filteredItems = filterItemsByKeyword(
      currentItems,
      event.target.value
    );

    if (filteredItems.length === 0) {
      setIsEmptyState(true);

      if (isValidFunction(onShowEmptyStateFunction)) {
        onShowEmptyStateFunction(event.target.value);
      }

      return;
    }

    setCurrentItems(filteredItems);
    setIsEmptyState(false);
  };

  const handleCleanSearch = (isExternal = false) => {
    if (isValidFunction(onCleanSearchFunction) && !isExternal) {
      onCleanSearchFunction(searchInput);
    }

    setSearchInput('');
    setCurrentItems([...items]);
    setIsEmptyState(false);
  };

  function filterItemsByKeyword(items, keyword) {
    const lowerKeyword = keyword.toLowerCase();

    function filterGroup(group) {
      return group.filter((subitem) =>
        subitem.name.toLowerCase().includes(lowerKeyword)
      );
    }

    return items
      .map((item) => {
        if (item.group.length === 0) {
          if (item.name.toLowerCase().includes(lowerKeyword)) {
            return item;
          }
        } else {
          const filteredGroup = filterGroup(item.group);
          if (filteredGroup.length > 0) {
            return {
              ...item,
              group: filteredGroup
            };
          }
        }
        return null;
      })
      .filter((item) => item !== null);
  }

  const calculateHeightItems = () => {
    const currentItemsCalculate = [...items];

    let groupItemsCount = 0;
    let itemsCount = 0;
    let itemsTotal = 0;
    let itemsTotalAll = 0;

    currentItemsCalculate.map((currentItem) => {
      if (currentItem.group.length > 0) {
        itemsTotalAll++;

        if (itemsTotal < countItemsShow) {
          groupItemsCount++;
          itemsTotal++;
        }

        currentItem.group.map(() => {
          itemsTotalAll++;

          if (itemsTotal < countItemsShow) {
            itemsCount++;
            itemsTotal++;
          }
        });

        return;
      }

      itemsTotalAll++;

      if (itemsTotal < countItemsShow) {
        itemsCount++;
        itemsTotal++;
      }
    });

    return {
      totalCount:
        groupItemsCount * HEIGHT_ITEM_GROUP + itemsCount * HEIGHT_ITEM + 30,
      itemsTotalAll: itemsTotalAll
    };
  };

  const calculateWidthItems = (
    withIcon = false,
    withAdditionalIcon = false,
    widthSubitems = false
  ) => {
    let base = 64;

    if (withIcon) base += 26;
    if (withAdditionalIcon) base += 26;
    if (widthSubitems) base += 26;

    return width - base;
  };

  const contentItem = (item) => {
    const contentChildren = (
      <div className="item__text">
        <div className="text">
          {!currentItemsChecked.some(
            (currentItem) => currentItem.key === item.key
          ) ? (
            <div className="text__check" />
          ) : (
            <CheckboxCheckedIcon
              color={theme === LIGHT_THEME ? '#53C255' : '#7DFF8A'}
            />
          )}
          {item.icon && <div className="text__icon">{item.icon}</div>}
          <span
            style={{
              maxWidth: calculateWidthItems(
                !!item.icon,
                !!item.additionalIcon,
                item.subitems.length > 0
              )
            }}>
            {item.name}
          </span>
        </div>
        <div className="text__options">
          {item.additionalIcon && (
            <div className="options__additional-icon">
              {item.additionalIcon}
            </div>
          )}
          {item.subitems.length > 0 && (
            <PopoverSystemV2
              theme={theme}
              content={
                <div
                  className={`popover-multiselect-design-system__subitems theme--${theme}`}>
                  {item.subitems.map((subitem) => contentSubItem(subitem))}
                </div>
              }
              trigger="click"
              {...props}>
              <div onClick={(e) => e.stopPropagation()}>
                <HorizontalMenuOutlineIcon
                  color={theme === LIGHT_THEME ? '#121212' : '#FFFFFF'}
                />
              </div>
            </PopoverSystemV2>
          )}
        </div>
      </div>
    );

    return (
      <div
        key={item.key}
        onClick={() => {
          isItemClickable(item);
          isOnUsedSearchFunction();
        }}
        className={`list__item ${item.active ? 'active' : ''} ${item.disabled ? 'disabled' : ''}`}>
        <SplitTextSystem
          name={item.name}
          children={contentChildren}
          placement="top"
        />
      </div>
    );
  };

  const contentSubItem = (subitem) => {
    return (
      <div
        key={subitem.key}
        className={`subitems__content ${subitem.active ? 'active' : ''} ${subitem.disabled ? 'disabled' : ''} ${subitem.divider ? 'divider' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          isSubitemClickable(
            subitem.onClick,
            subitem.disabled,
            subitem.active,
            subitem.divider
          );
        }}>
        {subitem.divider ? (
          <div className="content__divider" />
        ) : (
          <>
            <div className="content__info">
              {subitem.icon && <div className="info__icon">{subitem.icon}</div>}
              <span>{subitem.name}</span>
            </div>
            {subitem.informationIcon &&
              (subitem.tooltipIformation ? (
                TooltipSystemV2({
                  title: subitem.tooltipIformation,
                  placement: 'top',
                  children: (
                    <div className="content__icon">
                      <InfoIconOutlined
                        color={theme === LIGHT_THEME ? '#53C255' : '#7DFF8A'}
                      />
                    </div>
                  )
                })
              ) : (
                <div className="content__icon">
                  <InfoIconOutlined
                    color={theme === LIGHT_THEME ? '#53C255' : '#7DFF8A'}
                  />
                </div>
              ))}
          </>
        )}
      </div>
    );
  };

  const contentGlobalItem = (item) => {
    if (item.group.length > 0) {
      return (
        <>
          <div key={item.key} className="list__item group">
            <div className="item__text">
              <div className="text">
                {item.icon && <div>{item.icon}</div>}
                <span style={{ maxWidth: width }}>{item.name}</span>
              </div>
            </div>
          </div>
          {item.group.map((newItem) => contentItem(newItem))}
        </>
      );
    }

    return contentItem(item);
  };

  const countItemsFunction = (items) =>
    items.reduce(
      (items, { group }) =>
        group.length === 0 ? items + 1 : items + group.length,
      0
    );

  const isItemClickable = (item) => {
    if (item.disabled) return;

    const findIndexItem = currentItemsChecked.findIndex(
      (currentItem) => currentItem.key === item.key
    );

    if (findIndexItem >= 0) {
      const newItems = [...currentItemsChecked];
      newItems.splice(findIndexItem, 1);

      if (isValidFunction(onItemsCheckedFunction)) {
        onItemsCheckedFunction(newItems);
      }

      setCurrentItemsChecked(newItems);
      return;
    }

    if (isValidFunction(onItemsCheckedFunction)) {
      onItemsCheckedFunction([...currentItemsChecked, item]);
    }

    setCurrentItemsChecked([...currentItemsChecked, item]);
  };

  const isSubitemClickable = (onClick, disabled, active, divider) => {
    if (!isValidFunction(onClick) || disabled || active || divider) return;

    onClick();
  };

  const isOnUsedSearchFunction = () => {
    if (!isValidFunction(onUsedSearchFunction) || searchInput === '') return;

    onUsedSearchFunction(searchInput, countItemsFunction(currentItems));
  };

  const isValidFunction = (functionValidation) =>
    typeof functionValidation === 'function';

  const getCheckedItems = (items) => {
    return items.reduce((acc, item) => {
      if (item.group.length > 0) {
        const checkedGroupItems = item.group.filter(
          (groupItem) => groupItem.checked
        );
        acc.push(...checkedGroupItems);
      } else if (item.checked) {
        acc.push(item);
      }
      return acc;
    }, []);
  };

  const allItemsChecked = () =>
    currentItemsChecked.length === countItemsFunction(currentItems);

  const removeAllItemsChecked = () => {
    if (!withSelectAll) return;

    setCurrentItemsChecked([]);

    if (isValidFunction(onItemsCheckedFunction)) {
      onItemsCheckedFunction([]);
    }
  };

  const addAllItemsChecked = () => {
    if (!withSelectAll) return;

    const allItemsChecked = currentItems.reduce((acc, item) => {
      if (item.group.length > 0) {
        acc.push(...item.group);
      } else {
        acc.push(item);
      }
      return acc;
    }, []);

    setCurrentItemsChecked(allItemsChecked);

    if (isValidFunction(onItemsCheckedFunction)) {
      onItemsCheckedFunction(allItemsChecked);
    }
  };

  const handleOnVisibleChange = (visible) => {
    if (!visible) {
      handleCleanSearch(true);
    }
  };

  const contentPopover = () => (
    <div
      className={`popover-multiselect-design-system theme--${theme}`}
      style={{ width: width }}>
      {withSearch && (
        <div className="popover-multiselect-design-system__search">
          <input
            placeholder="Search"
            name="search"
            onChange={handleOnChangeSearch}
            value={searchInput}
            autoComplete="off"
            onFocus={() =>
              isValidFunction(onFocusSearchFunction)
                ? onFocusSearchFunction()
                : null
            }
          />
          <div
            className="search__clean"
            onClick={() =>
              searchInput.length > 0 ? handleCleanSearch() : null
            }>
            {searchInput.length > 0 && (
              <DeleteTagOutlineIcon
                color={theme === LIGHT_THEME ? '#747474' : '#F0F4F5'}
              />
            )}
          </div>
          <div className="search__icon">
            <SearchIcon color={theme === LIGHT_THEME ? '#53C255' : '#7DFF8A'} />
          </div>
        </div>
      )}
      <div
        className="popover-multiselect-design-system__items"
        style={{
          maxHeight: !isEmptyState
            ? calculateHeightItems().totalCount
            : 'inherit',
          overflowY:
            calculateHeightItems().itemsTotalAll > countItemsShow &&
            !isEmptyState
              ? 'scroll'
              : 'initial'
        }}>
        {isEmptyState ? (
          <div className="items__empty">
            <div className="empty__icon">
              <WarningDiamontIcon
                color={theme === LIGHT_THEME ? '#747474' : '#c3cfbf'}
              />
            </div>
            <span>{t('popoverMultiselectSystem.empty_state_message')}</span>
          </div>
        ) : (
          <div className="items__list">
            {withSelectAll && items.length > 0 && searchInput.length === 0 && (
              <div
                className="list__select"
                onClick={() =>
                  allItemsChecked()
                    ? removeAllItemsChecked()
                    : addAllItemsChecked()
                }>
                {!allItemsChecked() ? (
                  <div className="select__checked" />
                ) : (
                  <CheckboxCheckedAllIcon
                    color={theme === LIGHT_THEME ? '#53C255' : '#7DFF8A'}
                  />
                )}
                <span>
                  {allItemsChecked()
                    ? t('popoverMultiselectSystem.unselect_all_items')
                    : t('popoverMultiselectSystem.select_all_items')}
                </span>
              </div>
            )}
            {currentItems.map((item) => contentGlobalItem(item))}
          </div>
        )}
      </div>
      {ctaText && ctaFunction && (
        <div
          className="popover-multiselect-design-system__cta"
          onClick={() => ctaFunction()}>
          {ctaIcon && <div className="cta__icon">{ctaIcon}</div>}
          <span>{ctaText}</span>
        </div>
      )}
    </div>
  );

  return (
    <PopoverSystemV2
      itemReference={itemReference}
      theme={theme}
      content={contentPopover()}
      onVisibleChange={(value) => handleOnVisibleChange(value)}
      {...props}>
      {children}
    </PopoverSystemV2>
  );
};

export default PopoverMultiselectSystem;
