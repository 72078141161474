import React from 'react';
import cn from 'classnames';

import styles from './ToolbarSubmenu.module.scss';
import { ToolbarSubmenuHeaderItem } from './ToolbarSubmenuHeaderItem';

export const TestIds = {
  SUBMENU: 'toolbar_submenu_header_root'
};

export const ToolbarSubmenuHeader = (props) => {
  const { onCommandDispatched, t, className, config } = props;
  const { subitems = [], direction } = config;

  return (
    <ul
      className={cn(styles['toolbar-submenu-header'], className, {
        [styles['toolbar-submenu--right-direction']]: direction === 'RIGHT',
        [styles['toolbar-submenu--left-direction']]: direction === 'LEFT'
      })}
      data-testid={TestIds.SUBMENU}>
      {subitems.map((item) => (
        <ToolbarSubmenuHeaderItem
          key={item.i18nKey}
          item={item}
          onCommandDispatched={onCommandDispatched}
          t={t}
          direction={direction}
          config={config}
          subitems={item?.subitems}
        />
      ))}
    </ul>
  );
};
