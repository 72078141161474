import React from 'react';
import colors from '../../../stylesheets/variables.scss';

/** massive icons */
import checkCurrentStage from '../../../assets/img/icons/icon--check-circle-green.svg';
import { SvgIcon } from '../../../utils';

export const SetCurrentStageComp = (
  <SvgIcon
    path={checkCurrentStage}
    className="massive-notification-icon"
    color={colors.greenIcon}
  />
);
