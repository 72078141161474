import React from 'react';
import { notification } from 'antd';
import {
  InformationCircleOutlineIcon,
  SuccessSolidIcon,
  WarningOutlineIcon,
  ErrorOutlineIcon,
  CloseNotificationIcon
} from '../../../icons';

const INFO_TYPE = 'info';
const SUCCESS_TYPE = 'success';
const WARNING_TYPE = 'warning';
const ERROR_TYPE = 'error';
const DEFAULT_SIZE = 24;
const DEFAULT_DURATION = 4.5;

const notificationSystemV2 = ({
  key,
  type = INFO_TYPE,
  duration = DEFAULT_DURATION,
  description,
  note,
  ...props
}) => {
  const getIconType = (type = INFO_TYPE) => {
    const listTypes = {
      [INFO_TYPE]: (
        <InformationCircleOutlineIcon color="#498E98" size={DEFAULT_SIZE} />
      ),
      [SUCCESS_TYPE]: <SuccessSolidIcon color="#53C255" size={DEFAULT_SIZE} />,
      [WARNING_TYPE]: (
        <WarningOutlineIcon color="#FDD551" size={DEFAULT_SIZE} />
      ),
      [ERROR_TYPE]: <ErrorOutlineIcon color="#E50101" size={DEFAULT_SIZE} />
    };

    return listTypes[type];
  };

  notification[type]({
    key: key,
    closeIcon: <CloseNotificationIcon color="#747474" />,
    className: `notification-design-system-v2 type--${type}`,
    icon: getIconType(type),
    duration: duration,
    description: description && (
      <div>
        <p>{description}</p>
        {note && <span>{note}</span>}
      </div>
    ),
    ...props
  });
};

export default notificationSystemV2;
