import React, { useEffect, useState } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import styles from './ResourceHeader.module.scss';
import { ACTIONS } from '../../scroll.actions';

export const GridHeader = ({
  data,
  gridWidth,
  onScroll,
  resourceHeaderRef
}) => {
  const countColumns = data.length;
  const cells =
    (cell) =>
    ({ columnIndex, rowIndex, style }) => {
      return <div style={style}>{cell[columnIndex]}</div>;
    };

  const handleScroll = (scroll) =>
    onScroll({ scrollFrom: ACTIONS.HEADER })(scroll);

  return (
    <Grid
      ref={resourceHeaderRef}
      className={styles.headergrid}
      columnCount={countColumns}
      rowCount={1}
      columnWidth={80}
      rowHeight={25}
      width={gridWidth}
      height={25}
      onScroll={handleScroll}>
      {cells(data)}
    </Grid>
  );
};
