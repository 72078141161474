import React, { useState, useEffect } from 'react';
import Outbuild from '../../../../assets/img/proplanner.svg';
import { LoadingOutlined } from '@ant-design/icons';
import { STEPS } from '../../constants';
import { trackingEvent } from '../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import './index.scss';

const ThirdScreenA = (props) => {
  const { t, dataForm, setCurrentStep } = props;

  const [isLoadingButton, setIsLoadingButton] = useState(false);

  useEffect(() => {
    trackingEvent('joined_company_viewed', null, AMPLITUDE_SERVICE);
  }, []);

  const handleClickButton = async () => {
    setIsLoadingButton(true);

    try {
      setCurrentStep(STEPS.QUARTER_CASE_A_SCREEN);
    } catch (err) {
      console.log(`Error in SignUpConfirmation - handleClickButton ${err}`);
    }
  };

  const handleClickOther = () => {
    setCurrentStep(STEPS.THIRD_CASE_B_SCREEN);
  };

  return (
    <div className="signup__third-screen-a">
      <div className="third-screen-a__logo">
        <img src={Outbuild} alt="Logo Outbuild" />
      </div>
      <div className="third-screen-a__body">
        <div className="body__title">
          <h2>{t('signup.case_a.second_screen_title')}</h2>
        </div>
        <div className="body__company">
          <div className="company__logo">
            {dataForm.company.image ? (
              <img src={dataForm.company.image} alt={dataForm.company.name} />
            ) : (
              <div className="logo__name">
                <span>{dataForm.company.name.charAt(0).toUpperCase()}</span>
              </div>
            )}
          </div>
          <div className="company__info">
            <h6>{dataForm.company.name}</h6>
          </div>
        </div>
        <div className="body__button">
          <button
            disabled={isLoadingButton}
            onClick={() => handleClickButton()}>
            {isLoadingButton ? (
              <LoadingOutlined />
            ) : (
              t('signup.case_a.second_screen_button_text')
            )}
          </button>
        </div>
        <div className="body__text">
          <span>{t('signup.case_a.second_screen_text')}</span>
          <a href="javascript:void(0);" onClick={() => handleClickOther()}>
            {t('signup.case_a.second_screen_link')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ThirdScreenA;
