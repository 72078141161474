import React from 'react';
import DurationCell from '../../../components/DurationCell';

export default (gantt, { t }) => ({
  name: 'original_duration',
  label: t('scheduleofschedule.columns.original_duration'),
  align: 'center',
  width: 70,
  onrender: (item) => <DurationCell duration={item.original_duration} />
});
