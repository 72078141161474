import { gantt } from '../../gantt/dhtmlxgantt';
export const formatter = (sector) =>
  gantt.ext.formatters.durationFormatter({
    enter: 'day',
    store: 'hour',
    format: 'day',
    hoursPerDay: sector.hoursPerDay,
    hoursPerWeek: sector.hoursPerWeek,
    short: false
  });
export const customFormatter = (hoursPerDay, hoursPerWeek) =>
  gantt.ext.formatters.durationFormatter({
    enter: 'day',
    store: 'hour',
    format: 'day',
    hoursPerDay: hoursPerDay,
    hoursPerWeek: hoursPerWeek,
    short: false
  });
