import React, { useEffect, useState } from 'react';
import EventEmitter from 'react-native-eventemitter';
import WeekReportCommit from '../../../components/Report/WeeReportCommit';
import { useParams } from 'react-router-dom';
import WeekReport from '../../../components/Report/WeekReport';
import moment from 'moment';

import { weekCommitmentService } from '../../../services';

export default function ReportCommint(props) {
  const { week, projectId, sectorId, planificationday } = props;
  const [start, setStart] = useState(false);
  const year = moment().year();

  useEffect(() => {
    const data = {
      week: parseInt(week, 10),
      sectorId: parseInt(sectorId, 10),
      year
    };

    weekCommitmentService.searchbyweek(data).then(({ commitment }) => {
      if (!commitment || !commitment.id) {
        return;
      }

      setStart(commitment.start_date);
    });
  }, []);

  if (!start) {
    return null;
  }

  return (
    <WeekReport
      projectId={projectId}
      sectorId={sectorId}
      year={year}
      week={year == 2021 ? week - 1 : week}
      planificationday={planificationday - 1}
      start={start}
    />
  );
}
