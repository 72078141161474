import React, { useState, useEffect } from 'react';
import CompanyImage from '../../../components/CompanyImage/CompanyImage';
import { useHistory } from 'react-router-dom';
import { getCompaniesByUser, getSessionToken } from './companyOptions.helpers';
import { trackingEvent } from '../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';
import './index.scss';

export default function CompanyOptions(props) {
  const { dark, validateMP } = props;
  const [companies, setCompanies] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const getCompany = async () => {
      const response = await getCompaniesByUser();
      setCompanies(response);
    };

    getCompany();
  }, []);

  const handleClick = async (companyId, companyName) => {
    const excecuteChangeCompany = () => {
      const currentPath = history?.location?.pathname;
      const eventSources = [
        { path: '/projects', source: 'Home' },
        { path: '/selectCompany', source: 'Company Selection page' }
      ];
      trackingEvent(
        'organization_selection',
        {
          ...getBasicAmplitudEventProperties(),
          event_source:
            eventSources.find((src) => currentPath?.includes(src.path))
              ?.source || 'Header',
          company_selected_id: companyId,
          company_selected_name: companyName
        },
        AMPLITUDE_SERVICE
      );
      getSessionToken(companyId, history);
    };
    if (validateMP) {
      validateMP(excecuteChangeCompany);
      return;
    }
    excecuteChangeCompany();
  };

  const renderCompanies = () => {
    if (!companies) return null;

    const countCompanies = companies.length;
    const isCorrectCompanies = countCompanies && countCompanies > 0;

    if (!isCorrectCompanies) return null;

    const MAX_VISIBLE_COMPANIES = 5;
    const isScroll =
      countCompanies >= MAX_VISIBLE_COMPANIES
        ? 'companyOption__layout-scroll'
        : '';
    const renderItems = () => {
      return companies.map((company) => {
        const { company: companyInfo, companyId } = company;
        const { name, image } = companyInfo;
        const item = (
          <div
            className={`companyOption__item ${dark ? 'companyOption__item--dark' : ''}`}
            key={`CompanyOptions__item-${name}`}
            onClick={() => handleClick(companyId, name)}>
            {CompanyImage(image, name)}
            <h2>{name}</h2>
          </div>
        );
        return item;
      });
    };

    return (
      <div className={`companyOption__layout ${isScroll}`}>{renderItems()}</div>
    );
  };
  return renderCompanies();
}
