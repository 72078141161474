import React from 'react';
import { Tooltip } from 'antd';
import cn from 'classnames';

import { CircleEmptyIcon, CircleCheckIcon } from '../../../icons';
import colors from '../../../stylesheets/variables.scss';
import styles from './ToolbarSubmenu.module.scss';
import { commandPermission } from '../../../utils/partialPermissionUtils';

export const TestIds = {
  SUBMENU: 'toolbar_submenu_root'
};

export const ToolbarSubmenu = (props) => {
  const {
    onCommandDispatched,
    t,
    className,
    config,
    subitemsCkeckedBackground,
    type,
    visualizationConfig = {}
  } = props;
  const { subitems, direction } = config;

  const {
    areLinksVisible,
    areSlackVisible,
    areNumtasksVisible,
    areBaselineVisible,
    areSubmittalsVisible,
    isTodaylineVisible
  } = visualizationConfig;

  const handleClick = (command, value) => {
    if (value) {
      command && onCommandDispatched(command, value);
    } else {
      command && onCommandDispatched(command);
    }
  };

  /**
   * This function renders the icon according to whether it is selected or not
   * @param {*} condition condition that needs to be rendered
   * @param {*} iconColorSelected color set in configuration
   * @returns component selection to render
   */
  const renderSelectedOrNot = (condition, iconColorSelected) => {
    return condition ? (
      <CircleCheckIcon color={iconColorSelected} />
    ) : (
      <CircleEmptyIcon color={colors.gray30} />
    );
  };

  /**
   * This function renders the subitems, according to the suitems setting
   * @param {*} command name of the function that is executed when selecting the item
   * @param {*} iconColorSelected icon color set in item configuration
   * @param {*} checked value that indicates if the item is selected or not
   * @returns component to render according to the configuration of the items
   */
  const renderItem = (command, iconColorSelected, checked) => {
    if (command === 'TOGGLE_BASELINE_VISIBILITY') {
      return renderSelectedOrNot(areBaselineVisible, iconColorSelected);
    }
    if (command === 'TOGGLE_TODAYLINE_VISIBILITY') {
      return renderSelectedOrNot(isTodaylineVisible, iconColorSelected);
    }
    if (command === 'TOGGLE_SLACK_VISIBILITY') {
      return renderSelectedOrNot(areSlackVisible, iconColorSelected);
    }
    if (command === 'TOGGLE_NUMTASKS_VISIBILITY') {
      return renderSelectedOrNot(areNumtasksVisible, iconColorSelected);
    }
    if (command === 'TOGGLE_LINKS_VISIBILITY') {
      return renderSelectedOrNot(areLinksVisible, iconColorSelected);
    }
    if (command === 'TOGGLE_SUBMITTALS_VISIBILITY') {
      return renderSelectedOrNot(areSubmittalsVisible, iconColorSelected);
    }

    return checked ? (
      subitemsCkeckedBackground ? (
        <CircleCheckIcon color="#FFFFFF" />
      ) : (
        <CircleCheckIcon color={iconColorSelected} />
      )
    ) : (
      <CircleEmptyIcon color={colors.gray30} />
    );
  };

  const cn_submenu_item = type
    ? styles['toolbar-massive-submenu__item']
    : styles['toolbar-submenu__item'];

  return (
    <ul
      className={cn(styles['toolbar-submenu'], className, {
        [styles['toolbar-submenu--right-direction']]: direction === 'RIGHT',
        [styles['toolbar-submenu--left-direction']]: direction === 'LEFT'
      })}
      data-testid={TestIds.SUBMENU}>
      {subitems?.map(
        (
          {
            icon: Icon,
            iconColor,
            text,
            i18nKey,
            command,
            Comp,
            tooltip,
            tooltipI18nKey,
            subitems,
            checkable,
            checked,
            props: propsComp,
            iconColorSelected,
            value
          },
          i
        ) => {
          const hasPermission = commandPermission(command);
          return (
            <li
              onClick={(e) => {
                if (hasPermission) {
                  handleClick(command, value);
                } else {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              className={cn(cn_submenu_item, {
                [styles['toolbar-submenu__item--checked']]:
                  subitemsCkeckedBackground && checked
              })}
              key={i}
              style={{
                cursor: hasPermission ? 'pointer' : 'not-allowed'
              }}>
              {Comp && <Comp Icon={Icon} {...propsComp} />}
              {!Comp && (
                <Tooltip
                  title={hasPermission ? '' : t('not_permissions_actions')}
                  placement="top">
                  <span>
                    {checkable && (
                      <span
                        className={
                          styles['toolbar-submenu__item-check-circle']
                        }>
                        {renderItem(command, iconColorSelected, checked)}
                      </span>
                    )}
                    <span>
                      {Icon && <Icon color={iconColor} />}
                      <span className={styles['toolbar-submenu__item-text']}>
                        {text || t(i18nKey)}
                      </span>
                    </span>
                  </span>
                </Tooltip>
              )}
              {subitems && (
                <ToolbarSubmenu {...props} config={{ ...config, subitems }} />
              )}
            </li>
          );
        }
      )}
    </ul>
  );
};
