import React from 'react';
import { CardViewIcon, TimelineViewIcon } from '../../../../icons';

export const TYPE_VIEW_CARD = 'CARD';
export const TYPE_VIEW_TIMELINE = 'TIMELINE';
export const TYPE_VIEW_LOADING = 'TYPE_VIEW_LOADING';

export const TYPE_VIEWS = Object.freeze({
  [TYPE_VIEW_CARD]: {
    key: 'card',
    label: 'card_view_label',
    icon: <CardViewIcon color="#121212" />
  },
  [TYPE_VIEW_TIMELINE]: {
    key: 'timeline',
    label: 'timeline_view_label',
    icon: <TimelineViewIcon color="#121212" />
  }
});
