import React, { useState, useEffect, useRef, memo } from 'react';
import PopoverCompanySystem from '../../../../../DesignSystem/PopoverCompanySystem';
import TruncatedTextSystem from '../../../../../DesignSystem/TruncatedTextSystem';
import { AddIcon, ArrowDropdownIcon } from '../../../../../../icons';
import Colors from '../../../../../../assets/styles/variables.scss';

const Company = memo(
  ({
    t,
    width,
    subcontract,
    data,
    handleUpdateUser,
    dataSubcontracts,
    isLoadingSubcontracts,
    setIsShowAddSubcontractModal,
    setCurrentUserIdSubcontracts,
    isValidRole
  }) => {
    const companyRef = useRef();

    const [currentSubcontract, setCurrentSubcontract] = useState(subcontract);

    useEffect(() => {
      setCurrentSubcontract(subcontract);
    }, [subcontract]);

    const { BLACK, WHITE, DARK_MINT } = Colors;

    const isContrastingBlack = (hex) => {
      const r = parseInt(hex.substring(1, 3), 16);
      const g = parseInt(hex.substring(3, 5), 16);
      const b = parseInt(hex.substring(5, 7), 16);
      const luminosity = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      return luminosity > 128;
    };

    const getColorCompany = (currentColor = null) => {
      if (!currentColor) return BLACK;
      return isContrastingBlack(currentColor) ? BLACK : WHITE;
    };

    const handleOpenAddSubcontractModal = () => {
      setCurrentUserIdSubcontracts(data.id);
      setIsShowAddSubcontractModal(true);
      handleClosePopover();
    };

    const handleOnClick = (selected) => {
      handleUpdateUser(data, 'subcontract', selected.key);
      setCurrentSubcontract(selected);
      handleClosePopover();
    };

    const handleClosePopover = () => {
      if (companyRef.current) {
        companyRef.current.tooltip.state.visible = false;
      }
    };

    const truncateText = () => (
      <TruncatedTextSystem
        name={currentSubcontract?.name}
        maxWidth={width > 1600 ? 96 : 61}
        color={getColorCompany(currentSubcontract?.color)}
        placement={'topLeft'}>
        <span style={{ color: getColorCompany(currentSubcontract?.color) }}>
          {currentSubcontract?.name}
        </span>
      </TruncatedTextSystem>
    );

    if (!isValidRole) {
      return (
        <div
          className="company-column-popover__label"
          style={{
            backgroundColor: currentSubcontract?.color || 'transparent'
          }}>
          {truncateText()}
        </div>
      );
    }

    return (
      <PopoverCompanySystem
        itemReference={companyRef}
        theme="light"
        countItemsShow={5}
        trigger="click"
        placement="bottom"
        items={dataSubcontracts}
        defaultChecked={currentSubcontract}
        isLoading={isLoadingSubcontracts}
        onItemCheckedFunction={(selected) => {
          if (selected && selected.key !== data.subcontract) {
            handleOnClick(selected);
          }
        }}
        ctaText={t(
          'settings.project_settings.users_tab.table_company_new_company'
        )}
        ctaIcon={<AddIcon color={DARK_MINT} />}
        ctaFunction={() => handleOpenAddSubcontractModal()}>
        <div
          className="company-column-popover"
          style={{
            backgroundColor: currentSubcontract?.color || 'transparent'
          }}>
          {truncateText()}
          <div className="roles-column-popover__icon">
            <ArrowDropdownIcon
              color={getColorCompany(currentSubcontract?.color)}
            />
          </div>
        </div>
      </PopoverCompanySystem>
    );
  }
);

export default Company;
