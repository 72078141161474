/* eslint-disable no-eval */

/** React components  */
import React, { useState, useEffect, useRef } from 'react';

import SelectSearch from 'react-select-search';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/** To animating views easely */
import { Animated } from 'react-animated-css';

import CustomPaginator from '../../../components/CustomPaginator';

/** Build components from antd css framework */
import { Row, Col } from 'antd';
import {
  Spin,
  Icon,
  Empty,
  Popover,
  DatePicker,
  Popconfirm,
  Tooltip
} from 'antd';

import ConstraintForm from '../../../components/LookAhead/Constraints/ConstraintForm';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
/** Services */
import { activityService } from '../../../services/activity.service';
import { historicalActivityProgressService } from '../../../services/historicalactivityprogress.service';
import { userService } from '../../../services/user.service';
import { taskService } from '../../../services/task.service';
import { calendarService } from '../../../services';

/** Plain text CSS file (react pretty features) */
import './index.css';

/** Redux */
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { lookaheadAssistanceActions } from '../../../redux/actions/assistanceActions';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  RightOutlined,
  LeftOutlined
} from '@ant-design/icons';

/** JSON with table distribution */
import { tableMetadata } from './table.layout';

/** Single draggable column component */
import SingleLookaheadOrderCol from '../../../components/SingleLookaheadOrderCol';

/** Single table with activity as header and tasks as childs */
import LookaheadActivity from '../../../components/LookaheadAssistance';

import LookaheadOtherGroup from '../../../components/LookaheadOtherGroup';

/** Header with filters options component */
import LookaheadFilterHeader from '../../../components/LookaheadFilterHeader';

/** Function to clone objects on JS */
import cloneDeep from 'lodash/cloneDeep';
import differenceBy from 'lodash/differenceBy';
import { capitalize } from 'lodash';

/** Function to keep states replacing specific elements */
import update from 'immutability-helper';
import { firstBy } from 'thenby';

/** Massive actions icons */
import constraintMassive from '../../../assets/img/massive/constraint.png';
import datesMassive from '../../../assets/img/massive/dates.png';
import deleteMassive from '../../../assets/img/massive/delete.png';
import priorityMassive from '../../../assets/img/massive/priority.png';
import progressMassive from '../../../assets/img/massive/progress.png';
import responsableMassive from '../../../assets/img/massive/responsable.png';
import leanMassive from '../../../assets/img/massive/EstadoLean.png';

/** PNG to put on users without image */
import fakeAvatar from '../../../assets/img/fake_user.png';

import {
  calculatePonderators,
  getTask,
  getTasksToDelete,
  deleteTaskHandler,
  notifyMessage,
  calculateProgress,
  getEndDateByGantt
} from '../../../utils/lookahead-common';
import { ganttAPI } from '../../../utils/customGanttPlugin';
import Range from 'react-range-progress';
import { socket } from '../../../services/socket.service';
import { geoVictoriaService } from '../../../services/geovictoria_api.service';
import moment from 'moment';
import { startCase } from 'lodash';
/** Redux */

import { withTranslation } from 'react-i18next';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

function AssiistenceView(props) {
  const { t } = props;
  const [refresh, setRefresh] = useState([true]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentWeek, setCurrentWeek] = useState();
  const [showInitView, setShowInitView] = useState(true);
  const [popsVisibility, setPopsVisibility] = useState({});
  // const projectState = useSelector(state => state.projectState)

  /** handle Form Add Constraint */
  const [visibleForm, setVisibleForm] = useState({ value: false, tasks: [] });

  /** Project state from redux */
  const projectState = useSelector((state) => state.projectState);
  const lookaheadState = useSelector((state) => state.assistanceState);
  const dispatch = useDispatch();

  /** Activities to handle table loading */
  const [activities, setActivities] = useState([]);
  const [isTree, setIsTree] = useState({ value: true });

  /** Calendar to handle table loading */
  const [calendars, setCalendars] = useState([]);

  /** Array with users that belongs to the same sector that this master plan */
  const [toSelectResponsables, setToSelectResponsables] = useState([]);

  /** Flag to know when component is on loading step */
  const [isLoading, setLoading] = useState(true);
  const [isLoadingNotData, setLoadingNotData] = useState(true);

  /** Object to handle start and end dates to show last level activities from master plan  */
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  const [groupBy, setGroupBy] = useState({
    criteria: 'activityId',
    sort: 'asc'
  });

  /** Variable to view and his components to handle table manipulation by user */
  const [month, setMonth] = useState(moment());
  const [tableConfig, setTableConfig] = useState(tableMetadata);

  const [resizing, setResizing] = useState(false);

  const [massiveSelection, setMassiveSelection] = useState([]);

  const { height, width } = useWindowDimensions();

  const [massiveProgress, setMassiveProgress] = useState(0);

  const [scrollStates, setScrollStates] = useState({});

  const [massiveResponsableState, setMassiveResponsableState] = useState({
    values: []
  });

  const [massiveOnProcess, setMassiveOnProcess] = useState(false);

  /** Full width feature */
  const [totalTablesWidth, setTotalTablesWidth] = useState(0);
  const [mount_date_count, setMount_date_count] = useState([]);
  let paginatorRef = useRef();

  useEffect(() => {
    setCurrentWeek(moment().month());
    if (currentWeek) {
      getAssist();
    }
  }, []);

  const getAssist = async (reLoad = true) => {
    setLoading(true);
    const d = {
      month: currentWeek,
      projectState: projectState.projectSelected
    };
    const data = await geoVictoriaService.getGeoData(d);
    const datos = [];
    const mount_date_count = [];
    let i = 1;
    const month_days = month.daysInMonth(); // month.daysInMonth()
    while (i <= month_days) {
      const date = moment().set({
        year: month.year(),
        month: month.month(),
        date: parseInt(i),
        hour: 0,
        minute: 0,
        second: 0
      });
      const date_format = date.format('YYYYMMDD') + '000000';
      const v = {
        date: date_format,
        total: 0
      };
      mount_date_count.push(v);
      i++;
    }

    if (data) {
      const users_info = data.listusers;
      data.array_attendancebook.map((attendancebook) => {
        if (typeof attendancebook === 'boolean') {
          // variable is a boolean
          setLoadingNotData(false);
          return false;
        }
        attendancebook.Users.map((user) => {
          const user_info = users_info.find(
            (u) => u.Identifier === user.Identifier
          );
          if (user_info) {
            user.user_info = user_info;
            datos.push(user);
          }
        });
      });

      datos.map((dato) => {
        dato.PlannedInterval.map((pivl) => {
          const find = mount_date_count.find((u) => u.date == pivl.Date);
          if (find) {
            if (pivl.Worked == 'True') {
              find.total = find.total + 1;
            }
          }
        });
      });
      setMount_date_count(mount_date_count);
      if (datos.length == 0) {
        setLoadingNotData(false);
      } else {
        setLoadingNotData(true);
      }
      setActivities([
        {
          name: '',
          label: '',
          tasks: datos
        }
      ]);

      setLoading(false);
    }
  };

  useEffect(() => {
    createdayIntable();
  }, [month]);

  useEffect(() => {
    if (!resizing) {
      let tablesWidth = 0;
      createdayIntable();

      tableConfig.map((el) => {
        if (el.visible) {
          tablesWidth += el.width;
        }
      });
      setTotalTablesWidth(tablesWidth);
    }
  }, [tableConfig]);

  function createdayIntable() {
    let count_day = 0;
    const month_days = month.daysInMonth();
    const total_column = month_days + 2;
    tableConfig.map((el) => {
      if (count_day <= total_column) {
        el.visible = true;
      } else {
        el.visible = false;
      }
      count_day++;
    });
    setTableConfig(tableConfig);
  }

  useEffect(() => {
    if (paginatorRef.current) {
      paginatorRef.current.resetAfterColumnIndex(0);
    }
  }, [totalTablesWidth]);

  useEffect(() => {
    /** get week number */
    const todayWeek = moment().week();

    /** set range according current week */
    getRangeDateOfWeek(todayWeek);
    // setCurrentWeek(todayWeek);
    if (currentWeek) {
      getAssist();
    }

    /** Loads activities with their tasks */
    // getLookahead(todayWeek);
  }, [projectState.sectorSelected]);

  const renderExtraItemsNavigationWeek = () => (
    <div className="week-navigate">
      <Row className="">
        <div className="div-week" style={{ zIndex: 100, position: 'relative' }}>
          <Col span={8} className="week-left">
            <LeftOutlined onClick={() => changeCurrentWeek(-1)} />
          </Col>
          <Col span={8}>
            {currentWeek ? (
              <span>
                <div className="week-number">
                  {moment().month(currentWeek).format('MMMM').toUpperCase()}
                </div>
                <div className="range-date">
                  {startCase(
                    moment(dateRange.start).format('DD MMM').replace(/\./g, '')
                  )}
                  &nbsp;-&nbsp;
                  {startCase(
                    moment(dateRange.end).format('DD MMM').replace(/\./g, '')
                  )}
                </div>
              </span>
            ) : (
              <span>
                <div className="week-number">Seleccione Semana</div>
                <div className="range-date">Ini - Fin</div>
              </span>
            )}
          </Col>
          <Col span={8} className="week-right">
            <RightOutlined onClick={() => changeCurrentWeek(+1)} />
          </Col>
        </div>
      </Row>
    </div>
  );

  const changeCurrentWeek = (add) => {
    /** get week number */
    const todayWeek = currentWeek + parseInt(add);
    setCurrentWeek(todayWeek);
    setShowInitView(true);
  };

  useEffect(() => {
    /** get week number */
    if (currentWeek) {
      getRangeDateOfWeek(currentWeek);
      // getLookahead(currentWeek);
      setMonth(moment().year(2021).month(currentWeek));
      getAssist();
    }
  }, [currentWeek]);

  const getRangeDateOfWeek = (week) => {
    /** get day 3 of week */

    const month_change = moment().month(week);
    /** get first day of week */
    // const from_date = day3ofWeek.startOf('isoWeek');
    // const to_date = from_date.clone().add(1, 'month');
    const start = month_change.clone().startOf('month');
    const end = month_change.clone().endOf('month');

    /** Then using set state hook load this vars, to let virtual dom load datepickers with correct dates */
    setDateRange((prev) => {
      prev.start = start;
      prev.end = end;
      return prev;
    });
  };

  const updateTableMetadata = (tMetadata = tableConfig) => {
    /* setTableConfig(prev => {
            prev = cloneDeep(tMetadata)
            return prev
        }) */
  };

  /**
   * This functions update the state of activities at view
   */
  const updateRender = (ac = activities) => {
    setActivities([...ac]);
  };

  const renderFilterHeader = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Row>
        <Col>
          <LookaheadFilterHeader
            renderExtraFilterCenter={renderExtraItemsNavigationWeek}
            defaultOrderOptions={[
              {
                name: 'activity',
                label: 'Actividad',
                switch: ['A → Z', 'Z → A']
              },
              {
                name: 'activityId',
                label: t('activity_id'),
                switch: ['1 → 9', '9 → 1']
              }
            ]}
            disableLookahead
            lookaheadActions={lookaheadAssistanceActions}
            lookaheadState={'assistanceState'}
            groupBy={groupBy}
            modifyGroupBy={() => {}}
            data={activities}
            changeDateState={() => {}}
            dateRange={dateRange}
            updateTableMetadata={updateTableMetadata}
            tableMetadata={tableConfig}
            toSelectResponsables={toSelectResponsables}
            updateRender={updateRender}
          />
        </Col>
      </Row>
    );
  };

  const renderTable = () => {
    if (isLoading) {
      return (
        <Spin className="loader-spinner-lookahead-table" indicator={antIcon} />
      );
    }
    if (activities.length) {
      const array = [renderGeneralHeader(), ...activities];
      if (groupBy.criteria == 'activity' || groupBy.criteria == 'activityId') {
        return (
          <CustomPaginator
            scrollStates={scrollStates}
            totalTablesWidth={totalTablesWidth}
            massiveSelection={massiveSelection}
            resizing={resizing}
            current={currentPage}
            setCurrentPage={setCurrentPage}
            data={array}
            itemSize={height < 700 ? height * 0.55 : height * 0.68}
            renderItem={(item, key, virtualizeRef) => {
              if (key == 0) {
                paginatorRef = virtualizeRef;
                return item;
              } else if (!resizing) {
                return (
                  <LookaheadActivity
                    ganttAPI={ganttAPI}
                    updateAsyncTask={() => {}}
                    updateAsyncActivity={() => {}}
                    scrollStates={scrollStates}
                    setScrollStates={setScrollStates}
                    virtualizeRef={virtualizeRef}
                    massiveSelectionHandler={() => {}}
                    setMassiveSelection={setMassiveSelection}
                    resizing={resizing}
                    tableMetadata={tableConfig}
                    key={key}
                    groupParent={item}
                    assist={mount_date_count}
                    index={key}
                    toSelectResponsables={toSelectResponsables}
                    month={month}
                    handleAddConstraint={() => {}}
                  />
                );
              }
            }}
            perPage={1}
          />
        );
      }
      return (
        <CustomPaginator
          scrollStates={scrollStates}
          totalTablesWidth={totalTablesWidth}
          massiveSelection={massiveSelection}
          itemSize={height < 700 ? height * 0.55 : height * 0.68}
          current={currentPage}
          setCurrentPage={setCurrentPage}
          data={array}
          renderItem={(item, key, virtualizeRef) => {
            if (key == 0) {
              paginatorRef = virtualizeRef;
              return item;
            } else if (!resizing) {
              return (
                <LookaheadOtherGroup
                  lookaheadActions={lookaheadAssistanceActions}
                  ganttAPI={ganttAPI}
                  updateAsyncTask={() => {}}
                  updateAsyncActivity={() => {}}
                  scrollStates={scrollStates}
                  setScrollStates={setScrollStates}
                  virtualizeRef={virtualizeRef}
                  massiveSelectionHandler={() => {}}
                  setMassiveSelection={setMassiveSelection}
                  tableMetadata={tableConfig}
                  groupParent={item}
                  index={key}
                  key={key}
                  toSelectResponsables={toSelectResponsables}
                  handleAddConstraint={() => {}}
                />
              );
            }
          }}
          perPage={1}
        />
      );
    }
    return (
      <Empty
        style={{ marginTop: 200 }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <span>
            {t('lang') === 'es'
              ? 'No se han encontrado tareas'
              : 'You haven’t created any Tasks'}
          </span>
        }
      />
    );
  };

  const onDivResizeHandler = (width, colMetadata) => {
    if (colMetadata.doubleClicked) {
      colMetadata.width = width;
      colMetadata.doubleClicked = false;
      updateTableMetadata();
    }
  };

  /**
   * This function handles the click, and the unclick from user when the resize is catched
   * @param {*} col Single column metadata that is going to be resized
   * @param {*} val Boolean, true for start resizing, false for end resizing
   */
  const onDivResizeDoubleClicked = (col, val) => {
    col.doubleClicked = val;
  };

  const moveCard = async (dragIndex, hoverIndex) => {
    const dragCard = tableConfig[dragIndex];

    const a = update(tableConfig, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard]
      ]
    });
    updateTableMetadata(a);
  };

  const renderGeneralHeader = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Row className="fixed-tree-table-header">
        {tableConfig.map((metadata, index) => {
          if (metadata.visible) {
            return (
              <SingleLookaheadOrderCol
                setResizing={setResizing}
                index={index}
                key={index}
                id={index}
                moveCard={moveCard}
                metadata={metadata}
                width={metadata.width}
                onDivResizeHandler={onDivResizeHandler}
                onDivResizeDoubleClicked={onDivResizeDoubleClicked}
              />
            );
          }
        })}
      </Row>
    );
  };

  return (
    <Animated animationIn="fadeIn" animationInDuration={500} isVisible={true}>
      <Row className="assistance-plan">
        <Col>
          {renderFilterHeader()}
          {/* renderGeneralHeader() */}
          {isLoadingNotData ? (
            renderTable()
          ) : (
            <div className="content_alert_not_data">
              <p>
                No hay datos disponible por favor buscar en otro periodo de
                tiempo o verificar tu id en geo victoria tu ide es{' '}
                {projectState.projectSelected}
              </p>
            </div>
          )}
        </Col>
      </Row>
    </Animated>
  );
}
export default withTranslation()(AssiistenceView);
