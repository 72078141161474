import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { findDeepGetActivityOfTask } from '../views/lookahead/planification/index.helper';

const formatDate = (dateStr, dateFormat) =>
  moment(dateStr).format(dateFormat ?? 'MM/DD/YY');

const getVarianceUsingWorkdays = async (
  activityEndDate,
  taskEndDate,
  calendarId,
  hoursPerDay
) => {
  const activityEnd = new Date(activityEndDate);
  const taskEnd = new Date(taskEndDate);

  const calendar = window.ganttVisualization.getCalendar(calendarId);
  const varianceInHours = calendar.calculateDuration(activityEnd, taskEnd);

  const varianceInDays = varianceInHours / hoursPerDay;

  const formattedVariance =
    varianceInDays < 0 ? 0 : Math.round(varianceInDays * 100) / 100;

  return `${formattedVariance >= 0 ? '+' : ''}${formattedVariance}`;
};

const getNewStartDate = (activityDate, taskDate, dateFormat) => {
  const startActivity = moment(activityDate);
  const startTask = moment(taskDate, dateFormat);

  return moment.min([startActivity, startTask]).format(dateFormat);
};

const getNewEndDate = (activityDate, taskDate, dateFormat) => {
  const endActivity = moment(activityDate);
  const endTask = moment(taskDate, dateFormat);

  return moment.max([endActivity, endTask]).format(dateFormat);
};

export const useModificationRequestDates = ({ taskId, modalModification }) => {
  const projectState = useSelector((state) => state.projectState);

  const [taskAndActivityData, setTaskAndActivityData] = useState({});
  const [dates, setDates] = useState({ taskResponsibles: [] });
  const [variance, setVariance] = useState(0);

  useEffect(() => {
    const calculateVariance = async () => {
      if (!projectState || !window.ganttVisualization || !taskId) {
        return;
      }

      const sectorDateFormat = projectState.allSectors.find(
        (e) => parseInt(e.id, 10) === parseInt(projectState.sectorSelected, 10)
      );
      const { dateFormat, hoursPerDay } = sectorDateFormat;
      const taskData = window.ganttVisualization.getTask(taskId);
      const activityData = findDeepGetActivityOfTask(
        window.activities,
        'id',
        taskId
      );
      const subcontract = window.ganttVisualization.subContracts?.find(
        ({ id }) => parseInt(id, 10) === parseInt(taskData.subcontractId, 10)
      );

      setTaskAndActivityData({
        activityId: activityData.correlative_id,
        activityName: activityData.name,
        activityStartDate: activityData.start_date,
        activityEndDate: activityData.end_date,
        taskSubcontract: subcontract,
        taskName: taskData.name,
        taskStartDate: taskData.start_date,
        taskEndDate: taskData.end_date,
        taskResponsibles: taskData.responsables
      });

      const currStartDate = formatDate(activityData.start_date, dateFormat);
      const currEndDate = formatDate(activityData.end_date, dateFormat);

      const taskStartDate = formatDate(taskData.start_date, dateFormat);
      const taskEndDate = formatDate(taskData.end_date, dateFormat);

      const newStartDate = getNewStartDate(
        activityData.start_date,
        taskData.start_date,
        dateFormat
      );

      const newEndDate = getNewEndDate(
        activityData.end_date,
        taskData.end_date,
        dateFormat
      );

      setDates({
        currStartDate,
        currEndDate,
        newStartDate,
        newEndDate,
        taskStartDate,
        taskEndDate
      });

      const variance = await getVarianceUsingWorkdays(
        activityData.end_date,
        taskData.end_date,
        activityData.calendarId,
        hoursPerDay
      );

      setVariance(variance);
    };

    calculateVariance();
  }, [taskId, modalModification, projectState]);

  return {
    ...taskAndActivityData,
    ...dates,
    variance
  };
};
