import React from 'react';

import style from './style.module.css';

const Element =
  (
    worker,
    resource,
    resourceSelected,
    workerSelected,
    handleSubmit,
    visibilityInput,
    cell,
    handleChangePercentage,
    invalidPercentage,
    cellUpdated,
    handleBlur
  ) =>
  ({ columnIndex, rowIndex, style: inlineStyle }) => {
    if (!worker || !resource) {
      return <div style={inlineStyle}></div>;
    }

    const currentResource = resource[columnIndex];
    const currentWorker = worker[rowIndex];
    const existResourcesAssigned = currentWorker.resourcesAssigned?.length;
    const existWorkerAssigned = currentResource.workersAssigment?.length;

    let content = '';
    let resourcesAssigned = [];
    let workersAssigned = [];
    let disabled = false;

    const handleClick = () => {
      visibilityInput({ column: columnIndex, row: rowIndex });
    };

    if (currentWorker.rut) {
      content = '-';
    }

    if (existWorkerAssigned && existResourcesAssigned) {
      resourcesAssigned = currentWorker.resourcesAssigned;
      workersAssigned = currentResource.workersAssigment;

      const matchWorker = workersAssigned.find(
        (worker) => worker.assistworkerId === currentWorker.assist.id
      );
      const matchResource = resourcesAssigned.find(
        (resource) => resource.sectorresourceId === currentResource.id
      );

      if (matchWorker && matchResource) {
        const percentage = matchResource.factor * 100;
        const parsePercentage =
          percentage % 1 === 0 ? `${percentage}%` : `${percentage.toFixed(2)}%`;

        content = (
          <div onClick={handleClick} className={style.factorContent}>
            {parsePercentage}
          </div>
        );

        const isUpdated = cellUpdated.find(
          (cell) => cell.colIdx === columnIndex && cell.rowIdx === rowIndex
        );

        if (isUpdated) {
          content = (
            <div onClick={handleClick} className={style.factorContent}>
              {isUpdated.factor}%
            </div>
          );
        }

        if (cell.colIdx == columnIndex && cell.rowIdx == rowIndex) {
          content = (
            <form
              onSubmit={handleSubmit}
              className={style.inputChangeFactor}
              data-sectorresourceId={matchResource.sectorresourceId}
              data-assistworkerId={matchResource.assistworkerId}
              data-worker={JSON.stringify(currentWorker)}>
              <input
                className={invalidPercentage && style.invalid}
                type="text"
                name="percentage"
                defaultValue={isUpdated ? isUpdated.factor : parsePercentage}
                onKeyUp={handleChangePercentage}
                autoFocus
                onBlur={handleBlur}
              />
            </form>
          );
        }
      }
    }

    if (currentWorker.assist) {
      disabled = !currentWorker.assist.worked;
    }

    const resourceActive = !!resourceSelected.find(
      (resource) => resource.id == currentResource.id
    );
    const workerActive = !!workerSelected.find(
      (worker) => worker.id == currentWorker.id && currentWorker.rut
    );

    const cellActive = !!(resourceActive && workerActive);

    return (
      <div
        className={`${style.cell} ${cellActive && style.active} ${disabled && style.disabled}`}
        style={inlineStyle}>
        {content}
      </div>
    );
  };

export default Element;
