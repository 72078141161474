import React, { useImperativeHandle, useRef } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import closeImg from '../../assets/img/order-esc.png';
import orderImg from '../../assets/img/order-drag.png';
import i18n from '../../i18n';

import { Radio, Row, Col, Icon } from 'antd';
const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move'
};
const Card = React.forwardRef(
  (
    {
      index,
      filter,
      deleteFilter,
      onChangeOrderBy,
      isDragging,
      connectDragSource,
      connectDropTarget
    },
    ref
  ) => {
    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);
    const opacity = isDragging ? 0 : 1;
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current
    }));

    const insideSwitchContent = filter.orderable_switch;

    return (
      <div ref={elementRef}>
        <Col key={index}>
          <Row className="single-filter-row">
            <Col
              onClick={() => deleteFilter(filter)}
              className="delete-filter-style aligned-center"
              span={1}>
              <img
                src={closeImg}
                width={6}
                style={{ position: 'relative', top: -2 }}
              />
            </Col>
            <Col span={6}>{filter.label}</Col>
            <Col span={1} className="aligned-center">
              {i18n.t('lang') === 'es' ? 'de' : 'from'}
            </Col>
            <Col
              span={13}
              offset={1}
              className="aligned-center order-row-radio">
              <Radio.Group
                onChange={(e) => onChangeOrderBy(e, filter)}
                defaultValue={filter.order_by}
                size="small"
                buttonStyle="solid">
                <Radio.Button value="asc" className="btnSwitch width-half">
                  {insideSwitchContent[0]}
                </Radio.Button>
                <Radio.Button value="desc" className="btnSwitch width-half">
                  {insideSwitchContent[1]}
                </Radio.Button>
              </Radio.Group>
            </Col>
            <Col span={1} className="aligned-center" style={{ cursor: 'move' }}>
              <img src={orderImg} width={5} />
            </Col>
          </Row>
        </Col>
      </div>
    );
  }
);
export default DropTarget(
  ItemTypes.CARD,
  {
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    }
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget()
  })
)(
  DragSource(
    ItemTypes.CARD,
    {
      beginDrag: (props) => ({
        id: props.id,
        index: props.index
      })
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    })
  )(Card)
);
