import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { store } from '../../redux/store';

import { link_click_job } from './gantt_lifecycle/lags-flow';
import { autoschedule_job } from './gantt_lifecycle/autoschedule-flow';

/** Formatter for columns deploy */
import {
  table_grid_columns,
  ganttColumnsSettingsVersion
} from './field_config/gantt-columns';

/** Gantt DHTMLX lib lifecycle execution logic  */
import {
  editing_flow,
  type_and_duration_feature_filter,
  check_progress
} from './gantt_lifecycle/editing-flow';

/** This function allows to create an identical object but with a new memory instance */
import cloneDeep from 'lodash/cloneDeep';

import EventEmitter from 'react-native-eventemitter';
import closeIcon from '../img/gantt/close-links-modal.png';
import trashIcon from '../img/gantt/icoTrash.png';

import {
  MSProyectParseData,
  responsibleDataProcore,
  getTaskByGanttFormatProcore
} from './custom_gantt_exportToMSProject';

/** Configuration files for Gantt DHTMLX lib  */
import {
  right_side_gantt,
  adding_slack_to_task_layer,
  baseline_task_layer,
  submittal_icon_task_layer
} from './gantt_styles_config/task-gantt-style';
import { initial_task_load_flow } from './gantt_lifecycle/task-flow';
import { general_layout } from './gantt_layout/general-layout';
import { init_scroll_zoom_config } from './gantt_layout/scroll-zooming-conf';
import {
  on_open_filter_custom_predecessor,
  after_save_filter_custom_predecessor,
  send_warning_message,
  from_links_to_string_predecessor
} from './custom_gantt_fields/custom_predecessor';
import {
  on_open_filter_custom_sucessor,
  after_save_filter_custom_sucessor,
  from_links_to_string_sucessor
} from './custom_gantt_fields/custom_sucessor';

import { isFeatureOn } from '../../utils/featureUtils';
import { FEATURE_FLAGS } from '../../constants/featureFlags';
import { DRAWER_ORIGINS } from '../../constants/drawerOrigins';
import { ActivityCardDrawer } from '../../components/ActivityCardDrawer';
import { ModificationRequestsDrawer } from '../../components/ModificationRequestsDrawer';
import { ganttAPI } from '../../utils/customGanttPlugin';
import {
  calculatePonderators,
  transformHourToDays,
  getPlaneCssToExport,
  removeActionsAdded,
  enableForMassiveSelect,
  getNextUID
} from '../../views/ganttContainer/gantt/gantt.helper';
import * as ganttActions from '../../redux/slices/ganttSlice';
import {
  openDrawer,
  requestCloseDrawer
} from '../../redux/slices/hoveringStackSlice';
import { calculateExpected, notifyMessage } from '../../utils/lookahead-common';
import {
  activityService,
  sectorService,
  activityPdfService
} from '../../services';
import { compareValues, openNotification, areArraysEqual } from '../../utils';
import * as projectActions from '../../redux/slices/projectSlice';
import { message } from 'antd';
import { userActions } from '../../redux/actions/userActions';
import { buildMultiselectAPI } from '../gantt/ext/proplannerMultiselect.api';
import { copyPasteActions } from '../../redux/actions/copyPasteActions';
import { base } from '../../services/base';
import { handleCopy, launchMessage } from '../gantt/ext/copyPasteAPI';
import { trackingEvent } from '../../analytics';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';
import { debug } from 'request';
import { getBasicAmplitudEventProperties } from '../../analytics/utils';
import { isCircularLinkExtended } from '../../utils/circularDependencyDetection';
import { onAfterTaskUpdate } from './events/onAfterTaskUpdate/index';
import { onAfterAutoSchedule } from './events/onAfterAutoSchedule/index';
import { onAfterTaskDrag } from './events/onAfterTaskDrag/index';
import { onTaskDrag } from './events/onTaskDrag';
import { onBeforeTaskChanged } from './events/onBeforeTaskChanged';
import {
  executeFixForConstraints,
  updateTaskTiming,
  getTaskCalendar,
  addHoursToConstraintDate,
  updateExpectedGlobal,
  defineColorByCriticalPath,
  defineColorByStatus,
  defineColorBySubcontract,
  defineColorByTags,
  checkUpdatedElementsToCalculateTimingPast,
  reloadDuration,
  getAllCheckedTasks,
  criticalPathRefresh,
  validateCriticalNeed,
  getStartAndEndHours,
  constraintValidationsForDrag
} from './events/utils/index';

import { modificationForConstraint } from './events/onAfterTaskDrag/modificationForConstraint';
import { fixScaleDateHeader } from './functions/fixScaleDateHeader';
import { fixBlankScreenViewport } from './functions/fixBlankScreenViewport';
import { adjustGridWidth } from './functions/adjustGridWidth';
import { changeSubmittalsVisibility } from './functions/gantt-submittals-config';
import { modifyUndoStack } from '../gantt/ext/UndoRedo/modify_undo_stack';
import { initFunctions } from '../gantt/ext/alapstrategy';
import moduleName from 'module';
import ModalMessage from '../../components/ExportablePDFMessage/ModalMessage';
import { checkCircularLinks } from './functions/checkCircularLinks';
import { calendarService } from '../../../src/services';
import { updateDefaultView } from '../../services/views/viewSync';
import { regexDateFormat } from '../../components/GanttSettings/constants';
import { getCurrentSector } from '../../utils/userUtils';
import { summaryColumnValue } from '../../constants/columnNames';
import NotificationSystemV2 from '../../components/DesignSystem/NotificationSystemV2';
import CriticalPath from './critical_path';
import { earlyAccessCriticalPath } from '../../utils/earlyAccessCriticalPath';
import { getParentLinkedLinks } from './autoschedule';
import { log } from '../../monitor/monitor';
import { updateAllTaskForExportPDF } from '../gantt/ext/Export/ExportPDFGantt/helpers/updateGantt';
import { cleanColumnsIssues } from '../../views/ganttContainer/gantt/viewUtils';

message.config({
  maxCount: 1
});
export const columnNameWBS = 'WBS_INITIAL_COL';
export const preColumnWBS = 'WBS_PRE_COL';
const constantWidthBeforeWBS = 8;
const MIN_ACTIVITY_BAR_WIDTH = 23;
const TASK_COUNT_THRESHOLD = 9;
const $ = window.$;
const exportableServerURL =
  base.exportableUrl || 'https://report.proplanner.app/gantt'; // 'http://localhost:3200/gantt' - base.exportableUrl

/**
 * This functions creates the whole configuration and initialize the gantt component
 * @param {*} gantt gantt instance which is going to be configured
 * @param {*} ganttContainer DOM element which will be injected with the gantt dhtmlx UI
 * @param {*} dataToLoad Links and activtiies to load at initial loading
 * @param {*} deleteCallback Callback function which is going to be called on every delete (links and activities)
 * @param {*} detectChildAndLinks Callback which detects assocciations for child and links
 * @param {*} projectState Redux state about project
 * @param {*} dispatch Dispatch redux function to execute any of redux events
 * @param {*} t Translation object from i18n lib
 * @param {*} sector sector object whit current sector which must be loaded at gantt chart
 * @param {*} userPreferenceTableGantt Redux persistance about user table confdiguration preferences
 * @param {*} ganttVisualizationConfig Redux persistance about if links must be visualized or if critical path must be showed
 * @param {*} setAutoscheduling Setter state from react hook useState to allow communication with gantt chart and react component about autoscheduling process
 * @param {*} setAutoschedulingVisual Setter state from react hook useState to allow communication with gantt chart and react component about showing autoschedule load screen
 * @param {*} subContracts Sub/Trade array from Proplanner DB
 * @param {*} toSelectResponsables Project proplanner users to be selected as responsables of any activity at gantt
 * @param {*} toSelectTags Project proplanner tags to be selected as tags for any activity at gantt
 * @param {*} setVisibleFormSubcontract Setter state from react hook useState to allow communication with gantt chart and react component about showing modal for create subs
 * @param {*} setVisibleFormTags Setter state from react hook useState to allow communication with gantt chart and react component about showing modal for create tags
 * @returns events array to be unplugged when the instance is going to be killed
 */
export function gantt_general_config(
  gantt,
  ganttContainer,
  dataToLoad = { data: [], links: [] },
  deleteCallback = null,
  detectChildAndLinks = null,
  projectState = null,
  dispatch = () => {},
  t,
  sector,
  userPreferenceTableGantt,
  ganttVisualizationConfig,
  lastUniqueCorrelativeIds,
  selectedActivities,
  setAutoscheduling,
  setAutoschedulingVisual,
  subContracts,
  toSelectResponsables,
  toSelectTags,
  setVisibleFormSubcontract,
  setVisibleFormTags,
  setZoomLevel,
  calendarObject,
  masterplanPer,
  companyState,
  companySettingsState,
  modalCircular,
  copyPasteState,
  submittalState
) {
  gantt.plugins({
    auto_scheduling: true,
    critical_path: true,
    click_drag: true,
    multiselect: true,
    drag_timeline: true,
    undo: true,
    keyboard_navigation: true,
    marker: true
  });

  gantt.autoscheduleNumber = 0;
  // ALAP FUNCTION FOR RESET LINKS STATE
  initFunctions(gantt);
  gantt.config.undo_steps = 150;
  gantt.config.undo_actions = {
    update: 'update',
    remove: 'remove',
    add: 'add',
    move: 'move' // move task in grid
  };
  gantt.config.undo_types = {
    link: 'link',
    task: 'task'
  };

  gantt.backup_data_saving_use = [];

  const trackEventBasicProps = (eventName) => {
    trackingEvent(
      eventName,
      { ...getBasicAmplitudEventProperties() },
      AMPLITUDE_SERVICE
    );
  };

  // Redux integration
  const tasksToCopy = copyPasteState?.masterplan?.selectedTasks; // localStorage.getItem('tasksToCopy')
  if (tasksToCopy) {
    gantt.tasksToCopy = tasksToCopy;
  }

  gantt.iscritical_init = false;
  gantt.config.auto_scheduling_use_progress = true;
  /**
   * This function, is connected to Undo class, which is part of the API for undo/redo in DHTMLX.
   * @param {*} action Action to be undone/redone
   * @param {*} methods Methods for each entities
   * @param {*} actions Actions available to undo/redo
   */
  gantt.runProplannerUndoCommands = (action, methods, actions) => {
    let command = null;
    const finalArrayActions = action?.commands;
    if (!finalArrayActions) return;
    try {
      for (let i = 0; i < finalArrayActions.length; i++) {
        command = finalArrayActions[i];
        // console.log('type:', command.type)
        const method = methods[command.entity][command.type];
        const getMethod = methods[command.entity].get;
        const check = methods[command.entity].isExists;

        /** PROPLANNER FIX: This validation must be applyed always, not just in removing */
        if (!gantt[check](command.value.id) && command.type !== actions.add)
          return;
        if (command.type === actions.add) {
          command.oldValue.comesFromUndo = true;
          gantt[method](
            command.oldValue,
            command.oldValue.parent,
            command.oldValue.$local_index
          );
        } else if (command.type === actions.remove) {
          if (gantt[check](command.value.id)) {
            gantt[method](command.value.id);
          }
        } else if (command.type === actions.update) {
          const item = gantt[getMethod](command.value.id);
          for (const prop in command.value) {
            if (!prop.startsWith('$') && !prop.startsWith('_')) {
              item[prop] = command.value[prop];
            }
          }
          if (!gantt[check](command.value.id)) return;
          gantt[method](command.value.id);
        } else if (command.type === actions.move) {
          /** PROPLANNER FIX: missed validation for parent */
          if (!gantt[check](command.value.parent)) return;
          gantt[method](
            command.value.id,
            command.value.$local_index,
            command.value.parent
          );
          // GS-680: We should send the changes to the server after we undo vertical reorder
          gantt.callEvent('onRowDragEnd', [command.value.id]);
        }
      }
    } catch (e) {
      console.log('error while running commands please check....');
    }
  };

  gantt.isMasterplan = true;

  gantt.formatter = gantt.ext.formatters.durationFormatter({
    enter: 'day',
    store: 'hour',
    format: 'day',
    hoursPerDay: sector?.hoursPerDay,
    hoursPerWeek: sector?.hoursPerWeek,
    short: false
  });
  /**
   *This function inject CSS to the DOM through the document window object
   * @param {*} css Plane text with CSS format to be injected
   */
  const addCSS = (css, id = false) => {
    const styleElement = document.createElement('style');
    id && styleElement.setAttribute('id', id);
    document.head.appendChild(styleElement).innerHTML = css;
  };

  /**
   * This function returns the memory ref loaded, from the grid column specified
   * @param {*} colName Name of the column defined in gantt-columns
   * @returns Return a pointer with the mem ref loaded in the gantt for a specific column
   */
  const getGridColumnRef = (colName) =>
    gantt.getGridColumn && gantt.getGridColumn(colName);

  /**
   * This function change the visibility of a specific column
   * @param {*} columnName The column name defined in gantt-columns
   * @param {*} hide Boolean which represent if the column should be hide or not
   * @param {*} forceRender When true we launch a gantt.render function to refresh UI
   */
  const changeColumnVisibility = (columnName, hide, forceRender = false) => {
    const columnMemRefWBS = getGridColumnRef(columnName);
    if (columnMemRefWBS) {
      columnMemRefWBS.hide = hide;
    }
    forceRender && gantt.render();
  };

  /**
   * This function change the width for a specific column
   * @param {*} columnName Column name from gantt-columns
   * @param {*} newWidth new width to be setted
   * @param {*} forceRender Force to launc gantt.render function
   * @returns
   */
  const changeGridColumnWidth = (columnName, newWidth, forceRender) => {
    const refCol = getGridColumnRef(columnName);
    if (!refCol) return;
    refCol.width = newWidth;
    forceRender && gantt.render();
    return true;
  };

  const getNewWidthWBS = () =>
    gantt?.detectedLevelsInGantt?.length * constantWidthBeforeWBS || 0;

  /** This function only update the WBS col width related to detectedLevelsInGantt */
  const updateColWidthWBS = () => {
    if (!changeGridColumnWidth(columnNameWBS, getNewWidthWBS())) {
      console.log('Error onParse for first width to WBS hide col');
    } else {
      gantt.callEvent('onGanttDetectedLevelsChange', [
        gantt.detectedLevelsInGantt
      ]);
    }
  };

  /** Declarative function to enable the WBS coloring */
  gantt.enableColorsWBS = () => {
    trackingEvent(
      'enable_wbs_coloring',
      {
        ...getBasicAmplitudEventProperties()
      },
      AMPLITUDE_SERVICE
    );
    changeColumnVisibility(columnNameWBS, false);
    // changeColumnVisibility(preColumnWBS, false)
    gantt.initializeLevelColorsCSS && gantt.initializeLevelColorsCSS();
    gantt.isActiveColorWBS = true;
    gantt.render();
    updateDefaultView();
    return true;
  };

  /** Declarative function to disable the WBS coloring */
  gantt.disableColorsWBS = () => {
    trackingEvent(
      'disable_wbs_coloring',
      {
        ...getBasicAmplitudEventProperties()
      },
      AMPLITUDE_SERVICE
    );
    if (!gantt.isActiveColorWBS) return;
    document.head.removeChild(document.getElementById('wbs-style-header'));
    changeColumnVisibility(columnNameWBS, true);
    // changeColumnVisibility(preColumnWBS, true)
    gantt.isLevelColorsInitialized = false;
    const enableOddCols = `
        .odd-col {
            background-color: #1212120a !important;;
            height: 100%;
        }
        `;

    addCSS(enableOddCols);
    gantt.isActiveColorWBS = false;
    gantt.cssWBS = '';
    try {
      gantt.render();
    } catch (e) {
      console.log(e);
    }
    return true;
  };

  /** Available themes, with a set of colors/hovers */
  const colorsThemes = {
    softEarthPP: [
      { solidColor: '#24616F', hoverColor: '#DEEDF0' },
      { solidColor: '#56A0B2', hoverColor: '#E8F4F8' },
      { solidColor: '#72BACC', hoverColor: '#EBF8FC' },
      { solidColor: '#92BDCD', hoverColor: '#EDF8FC' },
      { solidColor: '#AFD3DC', hoverColor: '#EEF5F6' },
      { solidColor: '#B8E7F0', hoverColor: '#F2FDFF' },
      { solidColor: '#C7E2E0', hoverColor: '#F7FEFD' },
      { solidColor: '#FFECDA', hoverColor: '#FFFDFC' },
      { solidColor: '#FAE3C4', hoverColor: '#FFFCF8' },
      { solidColor: '#F9D9A9', hoverColor: '#FFF9EF' },
      { solidColor: '#E9C59B', hoverColor: '#F6F3EE' },
      { solidColor: '#D0AC99', hoverColor: '#F5EAE4' },
      { solidColor: '#B89E92', hoverColor: '#F1E0D8' },
      { solidColor: '#B4B2A8', hoverColor: '#ECECEC' }
    ],
    softRainmbow: [
      { solidColor: '#FFAFA9', hoverColor: '#FFF5F4' },
      { solidColor: '#85C6FA', hoverColor: '#E5F4FF' },
      { solidColor: '#BFF8C1', hoverColor: '#EFFFF0' },
      { solidColor: '#FFFF80', hoverColor: '#FFFFE5' },
      { solidColor: '#96CDF9', hoverColor: '#EAF4FD' },
      { solidColor: '#F9CF92', hoverColor: '#FFFCF8' },
      { solidColor: '#DEAEE7', hoverColor: '#FEF5FF' },
      { solidColor: '#8FE1EC', hoverColor: '#DCF7FB' },
      { solidColor: '#DBDBDB', hoverColor: '#F3F1F1' },
      { solidColor: '#EEF4B3', hoverColor: '#FAFCE5' },
      { solidColor: '#DBB9AD', hoverColor: '#FFF6F3' },
      { solidColor: '#FFBEF9', hoverColor: '#FFEEFD' },
      { solidColor: '#AACCDC', hoverColor: '#DAE8EF' },
      { solidColor: '#A6A6A6', hoverColor: '#F0F0F0' }
    ]
  };
  gantt.colorsThemes = colorsThemes;

  // Levels with their color and hover color
  gantt.availableColors = [...colorsThemes.softRainmbow];

  /**
   * This is a declarative function which change current colors theme for WBS coloring
   * @param {*} themeName theme name from colorsTheme var
   * @returns false if there is no active coloring WBS
   */
  gantt.changeThemeWBS = (themeName) => {
    if (!gantt.isActiveColorWBS) return;
    const themeColors = colorsThemes[themeName];
    if (!themeColors) return;
    gantt.availableColors = [...themeColors];
    gantt.isLevelColorsInitialized = false;
    gantt.enableColorsWBS();
  };

  // add custom css file with our WBS colors config with a solid and hover color proplanner-wbs-color-X where X is the level indeed
  gantt.initializeLevelColorsCSS = () => {
    if (!gantt.isLevelColorsInitialized) {
      let finalCssCode = '';
      const createSingleIndent = (level, solidColor, hoverColor) => {
        const forwardLevel = level + 1;
        const totalBeforeWidth = forwardLevel * constantWidthBeforeWBS;

        const buildBeforeBackgroundSize = (level) => {
          let finalString = '';
          for (let index = 0; index < level; index++) {
            finalString += constantWidthBeforeWBS + 'px 100%';
            if (index + 1 === level) {
              finalString += ';';
            } else {
              finalString += ',';
            }
          }
          return finalString;
        };

        const buildBeforeBackgroundPosition = (level) => {
          let finalString = '';
          for (let index = 0; index < level; index++) {
            finalString += constantWidthBeforeWBS * index + 'px 0';
            if (index + 1 === level) {
              finalString += ';';
            } else {
              finalString += ',';
            }
          }
          return finalString;
        };

        const buildBeforeImage = (level) => {
          let finalString = '';
          for (let index = 0; index < level; index++) {
            const levelColors = gantt.availableColors[index];
            if (!levelColors) return;
            finalString +=
              'linear-gradient(to right, ' +
              levelColors.solidColor +
              ', ' +
              levelColors.solidColor +
              ')';
            if (index + 1 === level) {
              finalString += ';';
            } else {
              finalString += ',';
            }
          }
          return finalString;
        };

        return `
            .proplanner-wbs-color-${level}{
                background-color: ${solidColor} !important;
            }
            .proplanner-wbs-color-${level}:hover{
                background-color: ${hoverColor} !important;
            }
            .proplanner-wbs-color-${forwardLevel}-child{
                background-color: ${hoverColor} !important;
            }

            .proplanner-wbs-color-${forwardLevel}-child-before{
                background-color: ${solidColor};
                content:'';
                display: inline-block;
                height: 100%;
                width: ${totalBeforeWidth}px;
                position: absolute;
                background-repeat: no-repeat; /* same as no-repeat, no-repeat, no-repeat */
                background-image: ${buildBeforeImage(forwardLevel)}
                background-position: ${buildBeforeBackgroundPosition(forwardLevel)}
                background-size: ${buildBeforeBackgroundSize(forwardLevel)}
            }

            .proplanner-wbs-color-${forwardLevel}-before{
                background-color: ${solidColor};
                content:'';
                display: inline-block;
                height: 100%;
                width: ${totalBeforeWidth}px;
                position: absolute;
                background-repeat: no-repeat; /* same as no-repeat, no-repeat, no-repeat */
                background-image: ${buildBeforeImage(forwardLevel)}
                background-position: ${buildBeforeBackgroundPosition(forwardLevel)}
                background-size: ${buildBeforeBackgroundSize(forwardLevel)}
            }
            `;
      };
      gantt.availableColors &&
        gantt.availableColors.forEach((item, level) => {
          const levelColors =
            gantt.availableColors && gantt.availableColors[level];
          if (!levelColors) return;
          finalCssCode += createSingleIndent(
            level,
            levelColors.solidColor,
            levelColors.hoverColor
          );
        });

      const disableOddColsColored = `
            .odd-col {
                background-color: transparent !important;
                height: 100%;
            }
            `;
      finalCssCode += disableOddColsColored;
      addCSS(finalCssCode, 'wbs-style-header');
      gantt.cssWBS = finalCssCode;
      gantt.isLevelColorsInitialized = true;
    }
  };

  // Template which inject to full activity row in grid the class
  gantt.templates.grid_row_class = (start, end, task) => {
    if (!gantt.isActiveColorWBS) return;
    let finalClass = '';
    const taskLevel = task?.$level;
    if (!gantt.hasChild(task.id)) {
      finalClass += 'proplanner-wbs-color-' + taskLevel + '-child';
    } else {
      finalClass += 'proplanner-wbs-color-' + taskLevel;
    }
    return finalClass;
  };

  // This by now is only related to WBS, but as soon as there is other option to coloring rows
  const getRowColorSetter = (colorSchemeType) =>
    ({
      wbs: gantt.enableColorsWBS,
      default: gantt.disableColorsWBS
    })[colorSchemeType];

  gantt.changeRowVisualizationOption = (colorSchemeType) => {
    const setColorScheme = getRowColorSetter(colorSchemeType);
    if (!setColorScheme) return;
    setColorScheme();
    gantt.visualizationRowColorActive = colorSchemeType;

    dispatch(
      ganttActions.notifyGanttVisualizationConfigChange({
        colorRowSchemeType: colorSchemeType
      })
    );
  };

  gantt.templates.leftside_text = (start, end, activity) => {
    if (
      !gantt.config.show_numtasks ||
      activity.type !== 'task' ||
      !activity.tasks?.length ||
      !gantt.isTaskExists(activity.id)
    )
      return '';

    const { width } = gantt.getTaskPosition(activity);
    if (width < MIN_ACTIVITY_BAR_WIDTH) return '';

    const taskCount = activity.tasks.length;
    const progressColor = activity.progressSolidColor;
    const cssClass =
      taskCount > TASK_COUNT_THRESHOLD
        ? 'on-lookahead-new-gantt-counter'
        : 'on-lookahead-new-gantt-counter-less';

    return `<span class="${cssClass}" style="color: ${progressColor}; border:1px solid ${progressColor}">${taskCount}</span>`;
  };

  gantt.config.preserve_scroll = false;
  gantt.config.disable_focus_on_create = true;
  gantt.config.link_line_width = 1;

  gantt.config.show_links = ganttVisualizationConfig.areLinksVisible ?? false;
  gantt.config.highlight_critical_path = false; // ganttVisualizationConfig.isCriticalPathVisible ?? false;
  gantt.config.show_slack = ganttVisualizationConfig.areSlackVisible ?? false;
  gantt.config.show_baseline =
    ganttVisualizationConfig.areBaselineVisible ?? false;
  gantt.config.show_numtasks =
    ganttVisualizationConfig.areNumtasksVisible ?? false;
  gantt.config.show_todayline =
    ganttVisualizationConfig.isTodaylineVisible ?? false;
  gantt.config.show_submittal_icon =
    ganttVisualizationConfig.areSubmittalsVisible ?? false;

  // Setting this config to true will switch auto scheduling to the as late as possible mode.
  gantt.config.schedule_from_end = true;

  gantt.visualizationColorOptions = [
    'status',
    'subcontract',
    'tags',
    'criticalpath'
  ];

  resetGanttColumnsSettingsIfChanged();

  gantt.changeCurrentDateFormat = async (dateFormat) => {
    message.loading(t('configurating'), 3);
    const doesExistAtOptions = gantt.dateFormatOptions.find(
      (el) => el == dateFormat
    );

    if (doesExistAtOptions) {
      const copySector = { ...sector };
      copySector.dateFormat = dateFormat;
      delete copySector.calendars;
      const res = await sectorService.update(copySector);

      gantt.currentDateFormat = dateFormat;
      gantt.render();
      dispatch(ganttActions.setDateFormat(dateFormat));

      if (res) {
        const resp = await sectorService.index();
        if (!resp) return;

        const filterSectors = resp.sectors.filter(
          (e) =>
            e.projectId === projectState.projectSelected && e.status === true
        );
        filterSectors.sort(compareValues('order'));
        dispatch(projectActions.setAllSectors(filterSectors));
      }
    }
  };

  const setFinalHourDay = (task) => {
    const calendar = gantt.getCalendar(task.calendar_id);
    const clonedStartDate = cloneDeep(task.start_date);
    let clonedEndDate = cloneDeep(task.end_date);
    clonedEndDate.setHours(24);
    clonedEndDate.setMinutes(0);

    if (calendar) {
      clonedEndDate = calendar.getClosestWorkTime({
        dir: 'past',
        date: clonedEndDate,
        unit: gantt.config.duration_unit
      });
      const duration = calendar.calculateDuration(
        clonedStartDate,
        clonedEndDate
      );
      task.duration = duration;
      task.for_disable_milestone_duration = duration;
      task.end_date = clonedEndDate;
      updateTaskTiming({ task, gantt });
      gantt.updateTask(task.id);
    }
  };

  gantt.setFinalHourDay = setFinalHourDay;

  const setInitialHourDay = (task) => {
    const clonedStartDate = cloneDeep(task.start_date);
    clonedStartDate.setHours(0);
    clonedStartDate.setMinutes(0);
    task.start_date = clonedStartDate;
    updateTaskTiming({ task, gantt });
    gantt.updateTask(task.id);
  };

  gantt.setInitialHourDay = setInitialHourDay;

  /**
   * This function checks the browser's gantt columns settings version and compares
   * it with the app's current one. If they're not the same, the locally persisted
   * settings are erased and the page is reloaded.
   */
  function resetGanttColumnsSettingsIfChanged() {
    const currentStateGanttVersionLSKey = 'gantt_columns_settings_version';
    const ganttStateLSKey = 'persist:ganttState';
    const localVersion = Number(localStorage[currentStateGanttVersionLSKey]);

    const resetValuesFilter = (
      currentStateGanttVersionLSKey,
      ganttStateLSKey
    ) => {
      let currentGanttState;
      try {
        currentGanttState = JSON.parse(localStorage[ganttStateLSKey]);
      } catch (e) {
        currentGanttState = {};
      }
      delete currentGanttState.userPreferenceTableGantt;
      localStorage.setItem(ganttStateLSKey, JSON.stringify(currentGanttState));
      localStorage.setItem(
        currentStateGanttVersionLSKey,
        ganttColumnsSettingsVersion
      );
    };
    if (localVersion === ganttColumnsSettingsVersion) return;
    if (localStorage.firsTimeLogin) {
      const firsTimeLogin = JSON.parse(localStorage.firsTimeLogin);
      if (firsTimeLogin) {
        resetValuesFilter(currentStateGanttVersionLSKey, ganttStateLSKey);
        localStorage.setItem('firsTimeLogin', false);
        return;
      }
    }
    resetValuesFilter(currentStateGanttVersionLSKey, ganttStateLSKey);
    try {
      window.location.reload();
    } catch (e) {}
  }

  /**
   * This function run a callback on a settimeout function, and then unmount it to avoid freeze mem
   * @param {*} callback Function definition to be called inside settimeout
   * @param {*} timer Time to be used at settimeout
   */
  const runAndUnmountTimeout = (callback, timer) => {
    // eslint-disable-next-line prefer-const
    let timeoutRef;
    clearTimeout(timeoutRef);
    timeoutRef = setTimeout(() => {
      callback();
    }, timer);
  };

  const checkNoSavedLinks = () => {
    const nonSavedTasksCounter = [];
    const activities = gantt.serialize().links;

    if (activities.length == 0) {
      // send_warning_message(gantt)(t('flow_gantt_base_error'))
      return nonSavedTasksCounter;
    }

    activities.map((t) => {
      if (!t.proplannerId) {
        nonSavedTasksCounter.push(t);
      }
    });

    return nonSavedTasksCounter;
  };
  gantt.checkNoSavedLinks = checkNoSavedLinks;

  const checkNoUpdatedLinks = () => {
    const originalData = gantt.legacy_corrected_links;
    const nonUpdatedTasksCounter = [];
    const planeActivities = gantt.serialize().links;
    const hashPlaneActivities = {};
    planeActivities.forEach((ac) => {
      hashPlaneActivities[ac.id] = ac;
    });

    originalData &&
      originalData.forEach((ac) => {
        const doesExist = gantt.isLinkExists(ac.id);
        if (!doesExist) return;
        const currentRef = gantt.getLink(ac.id);
        if (!currentRef) return;
        const planeCurrentRef = hashPlaneActivities[ac.id];

        const originalActivityAttr = Object.keys(ac);
        for (let i = 0; i < originalActivityAttr.length; i++) {
          const key = originalActivityAttr[i];
          const toSkipAttributes = {
            baseline_points: true,
            tasks: true,
            baselineObject: true,
            activityModifications: true,
            color: true,
            totalSlack: true,
            custom_predecessors: true,
            custom_sucessors: true,
            start_base: true,
            end_base: true,
            work_base: true,
            cost_base: true,
            duration_base: true
          };

          if (toSkipAttributes[key]) continue;
          const originalValue = ac[key];
          const currentValue = currentRef[key];
          /** Date objects operator */
          if (originalValue instanceof Date) {
            const oldDate =
              originalValue && originalValue.getTime && originalValue.getTime();
            const currentDate =
              currentValue && currentValue.getTime && currentValue.getTime();
            if (oldDate && currentDate) {
              if (!(oldDate === currentDate)) {
                nonUpdatedTasksCounter.push(planeCurrentRef);
                break;
              }
            }
            /** Array object operator */
          } else if (originalValue instanceof Array) {
            if (originalValue.length != currentValue.length) {
              nonUpdatedTasksCounter.push(planeCurrentRef);
              break;
            }

            /** others */
          } else {
            if (!(originalValue == currentValue)) {
              nonUpdatedTasksCounter.push(planeCurrentRef);
              break;
            }
          }
        }
      });

    return nonUpdatedTasksCounter;
  };
  gantt.checkNoUpdatedLinks = checkNoUpdatedLinks;

  const checkNoSavedActivities = () => {
    const nonSavedTasksCounter = [];
    const activities = gantt.serialize().data;

    if (activities.length == 0) {
      // send_warning_message(gantt)(t('flow_gantt_base_error'))
      return nonSavedTasksCounter;
    }

    activities.map((t) => {
      if (!t.proplannerId) {
        nonSavedTasksCounter.push(t);
      }
    });

    return nonSavedTasksCounter;
  };

  gantt.checkNoSavedActivities = checkNoSavedActivities;

  const checkNoUpdatedActivities = () => {
    const originalData = window.backup_data_saving_use;
    const nonUpdatedTasksCounter = [];
    const planeActivities = gantt.serialize().data;
    const hashPlaneActivities = {};
    planeActivities.forEach((ac) => {
      hashPlaneActivities[ac.id] = ac;
    });
    gantt.buggedAlert = [];

    originalData &&
      originalData.forEach((ac) => {
        const doesExist = gantt.isTaskExists(ac.id);
        if (!doesExist) return;
        const currentRef = gantt.getTask(ac.id);
        if (!currentRef) return;
        const planeCurrentRef = hashPlaneActivities[ac.id];

        const originalActivityAttr = Object.keys(ac);
        for (let i = 0; i < originalActivityAttr.length; i++) {
          const key = originalActivityAttr[i];

          const toSkipAttributes = {
            baseline_points: true,
            tasks: true,
            baselineObject: true,
            activityModifications: true,
            color: true,
            freeSlack: true,
            totalSlack: true,
            custom_predecessors: true,
            custom_sucessors: true,
            start_base: true,
            work_base: true,
            cost_base: true,
            duration_base: true,
            $effective_calendar: true
          };

          if (toSkipAttributes[key]) continue;
          let originalValue = ac[key];
          let currentValue = currentRef[key];
          if (originalValue == null || originalValue == undefined)
            originalValue = 0;
          if (currentValue == null || currentValue == undefined)
            currentValue = 0;

          const addNonUpdatedKey = () => {
            gantt.buggedAlert.push({
              correlative_id: ac.correlative_id,
              name: ac.text,
              field: key,
              originalValue,
              currentValue
            });
          };

          /** Date objects operator */
          if (
            key === 'calendar_id' &&
            planeCurrentRef?.assignedDefaultCalendar
          ) {
            nonUpdatedTasksCounter.push(planeCurrentRef);
          }
          if (originalValue instanceof Date) {
            const oldDate =
              originalValue && originalValue.getTime && originalValue.getTime();
            const currentDate =
              currentValue && currentValue.getTime && currentValue.getTime();
            if (oldDate && currentDate) {
              if (!(oldDate === currentDate)) {
                planeCurrentRef && nonUpdatedTasksCounter.push(planeCurrentRef);
                addNonUpdatedKey();
                break;
              }
            }
            /** Array object operator */
          } else if (originalValue instanceof Array) {
            if (!areArraysEqual(originalValue, currentValue)) {
              addNonUpdatedKey();
              planeCurrentRef && nonUpdatedTasksCounter.push(planeCurrentRef);
              break;
            }

            /** others */
          } else {
            if (!(originalValue == currentValue)) {
              planeCurrentRef && nonUpdatedTasksCounter.push(planeCurrentRef);
              addNonUpdatedKey();
              break;
            }
          }
        }
      });

    return nonUpdatedTasksCounter;
  };

  gantt.checkNoUpdatedActivities = checkNoUpdatedActivities;

  gantt.visualizationColorActive =
    ganttVisualizationConfig.colorSchemeType ?? 'status';
  gantt.visualizationRowColorActive =
    ganttVisualizationConfig.colorRowSchemeType ?? 'status';

  gantt.defineColorByStatus = defineColorByStatus;

  gantt.defineColorByTags = defineColorByTags;

  gantt.defineColorBySubcontract = defineColorBySubcontract;

  const defineColorByCriticalPathGlobalElements = () => {
    gantt.config.highlight_critical_path = true;
  };

  gantt.defineColorByCriticalPath = defineColorByCriticalPath;
  const getColorSetter = (colorSchemeType) => {
    trackingEvent(
      'bar_color_option_selection',
      {
        ...getBasicAmplitudEventProperties(),
        bar_color_option_selected: colorSchemeType
      },
      AMPLITUDE_SERVICE
    );
    return {
      status: defineColorByStatus,
      subcontract: defineColorBySubcontract,
      tags: defineColorByTags,
      criticalpath: defineColorByCriticalPath
    }[colorSchemeType];
  };

  /**
   * This function changes the Gantt chart's bar color
   * @param {*} colorSchemeType The color scheme type to be set
   */
  gantt.changeVisualizationOption = (
    colorSchemeType = gantt.visualizationColorActive
  ) => {
    gantt.batchUpdate(() => {
      const setColorScheme = getColorSetter(colorSchemeType);

      if (setColorScheme) {
        gantt.getTaskByTime().forEach((t) => setColorScheme(t));
      } else {
        return;
      }

      gantt.optimizedRender();
      gantt.visualizationColorActive = colorSchemeType;

      dispatch(
        ganttActions.notifyGanttVisualizationConfigChange({ colorSchemeType })
      );
    });
    if (gantt.config.highlight_critical_path) {
      gantt.autoSchedule();
    }
  };

  window.to_use_react_gantt = gantt;
  gantt.subContracts = subContracts;
  gantt.toSelectResponsables = toSelectResponsables;
  gantt.toSelectTags = toSelectTags;
  gantt.setVisibleFormSubcontract = setVisibleFormSubcontract;
  gantt.setVisibleFormTags = setVisibleFormTags;

  gantt.config.external_render = {
    // checks the element is a React element
    isElement: (element) => React.isValidElement(element),
    // renders the React element into the DOM
    renderElement: (element, container) => {
      if (!gantt.completingOnProcess) {
        ReactDOM.render(element, container);
      }
    }
  };

  gantt.config.date_format = '%Y-%m-%d %H:%i';
  const events = [];
  gantt.config.reorder_grid_columns = true;
  /** Calculates duration in working hours and hides non-working time from the chart */
  gantt.config.work_time = true;
  gantt.config.correct_work_time = true;

  /** Autoschedule turned on */
  gantt.config.auto_scheduling = true;
  gantt.config.auto_scheduling_initial = false;
  gantt.config.round_dnd_dates = false;
  gantt.config.time_step = 60;
  gantt.config.duration_unit = 'hour';

  const addActivityAfter = (activityId) => {
    const ZERO = 0;
    const ONE = 1;
    const sector = getCurrentSector();
    if (!sector) {
      return;
    }

    const objectToPaste = {
      selectedTasks: [
        {
          duration: sector.hoursPerDay,
          unique_correlative_id: getNextUID(
            sector.id,
            lastUniqueCorrelativeIds,
            gantt
          )
        }
      ],
      selectedLinks: []
    };

    const activity = gantt.getTask(activityId);
    if (!activity) {
      return;
    }

    if (gantt.hasChild(activityId)) {
      pasteActivities(
        objectToPaste,
        gantt.getCalendars(),
        activityId,
        ZERO,
        false
      );
      return;
    }

    pasteActivities(
      objectToPaste,
      gantt.getCalendars(),
      activity.parent,
      activity.$local_index + ONE,
      false
    );
  };

  const ctrlIHandler = () => {
    const lastTask = gantt.getSelectedTasks()?.pop();
    if (!lastTask) {
      return;
    }

    addActivityAfter(lastTask);
    trackingEvent(
      'activity_insertion',
      {
        ...getBasicAmplitudEventProperties(),
        source: 'shortcut'
      },
      AMPLITUDE_SERVICE
    );
  };

  document.addEventListener('keydown', (e) => {
    // console.log(e.key)
    try {
      if (e.key === 'Escape') {
        gantt.detachMultiDragSelect && gantt.detachMultiDragSelect();
      } else if (e.key === 'z' && (e.ctrlKey || e.metaKey)) {
        gantt.undo && gantt.undo();
      } else if (e.key === 'y' && (e.ctrlKey || e.metaKey)) {
        gantt.redo && gantt.redo();
      } else if (e.key === 'i' && e.ctrlKey) {
        isAddedThroughLineTimeout();
        ctrlIHandler();
      }
    } catch (e) {
      console.log('Error on global keydown listener, please check...');
    }
  });

  buildMultiselectAPI(
    gantt,
    () => {},
    () => {}
  );

  const clearSelectedActivities = () => {
    dispatch(ganttActions.setSelectedActivities([]));
  };
  gantt.clearSelectedActivities = clearSelectedActivities;

  const setSelectedActivitiesFromBlock = (arrIDs) => {
    const isBulkActionsBarFeatureEnabled =
      isFeatureOn(FEATURE_FLAGS.ENABLE_BULK_ACTIONS_BAR) &&
      enableForMassiveSelect();
    let activitiesIds;
    if (isBulkActionsBarFeatureEnabled) {
      if (arrIDs.length) {
        activitiesIds = arrIDs;
        dispatch(
          ganttActions.setSelectedActivities(transformIdsInTasks(activitiesIds))
        );
      } else {
        activitiesIds = checkArrayMassive();
        dispatch(ganttActions.setSelectedActivities(activitiesIds));
      }
    }
  };
  gantt.setSelectedActivitiesFromBlock = setSelectedActivitiesFromBlock;

  gantt.config.multiselect = true;
  gantt.config.multiselect_one_level = false;

  /** Configuration for multi task on drag selecting */
  gantt.config.click_drag = {
    callback: gantt.onDragCursorMultiTask,
    useKey: 'shiftKey'
  };

  /** Custom templates for edit modal fields */
  const control_template = gantt.locale.labels;
  control_template.section_priority =
    'Priority'; /** For custom field priority */

  gantt.config.layout = general_layout;

  gantt.optimizedRender = () => {
    if (!gantt.isRenderingOnProcess && !gantt.completingOnProcess) {
      gantt.render();
    }
  };

  const dateFormat = gantt.currentDateFormat;

  if (dateFormat) {
    dispatch(ganttActions.setDateFormat(dateFormat));
  }

  gantt.adjustGridWidth = adjustGridWidth(gantt);
  gantt.fixScaleDateHeader = fixScaleDateHeader(gantt);
  gantt.fixBlankScreenViewport = fixBlankScreenViewport(gantt);

  const updateGlobalStatus = () => {
    gantt.batchUpdate(() => {
      gantt.getTaskByTime().forEach((task) => {
        const expected_calculated =
          gantt.status_criteria === 'Baseline'
            ? task.expected_progress_base
            : task.expected_progress;
        if (Math.round(task.progress * 100) / 100 > 99.99) {
          task.status = 'Done';
        } else if (
          Math.round(expected_calculated * 100) / 100 >
          Math.round(task.progress * 100) / 100
        ) {
          task.status = 'Overdue';
        } else if (
          task.progress > 0 &&
          Math.round(task.progress * 100) / 100 >
            Math.round(expected_calculated * 100) / 100
        ) {
          task.status = 'Advancement';
        } else if (task.progress == 0 && expected_calculated == 0) {
          task.status = 'Waiting';
        } else if (
          task.progress > 0 &&
          Math.round(task.progress * 100) / 100 <
            Math.round(expected_calculated * 100) / 100
        ) {
          task.status = 'Doing';
        } else {
          task.status = 'Waiting';
        }

        if (gantt.visualizationColorActive == 'status') {
          defineColorByStatus(task);
        } else if (gantt.visualizationColorActive == 'subcontract') {
          defineColorBySubcontract(task);
        } else if (gantt.visualizationColorActive == 'tags') {
          defineColorByTags(task);
        } else if (gantt.visualizationColorActive == 'criticalpath') {
          defineColorByCriticalPathGlobalElements(task);
        }
      });
    });
  };

  /** autoscheduling after action */
  const executeAutoScheduleExternal = () => {
    runAndUnmountTimeout(() => executeAsyncAutoscheduleToAvoidFreeze(), 300);
    if (earlyAccessCriticalPath()) {
      criticalPathRefresh();
    }
  };

  gantt.updateExpectedGlobal = updateExpectedGlobal;
  gantt.executeAutoScheduleExternal = executeAutoScheduleExternal;

  const updateCriteriaStatus = async (value) => {
    if (value) {
      gantt.status_criteria = value;
      dispatch(
        projectActions.setProps({
          ...projectState.props,
          status_criteria: value
        })
      );
      updateExpectedGlobal({ gantt });
    } else return false;
  };
  gantt.updateCriteriaStatus = updateCriteriaStatus;

  const updateActCard = async () => {
    updateGlobalStatus();
    runAndUnmountTimeout(gantt.optimizedRender, 500);
  };
  gantt.updateActCard = updateActCard;

  /** OPTIMIZATION NOTE: Calculation with whiles where removed to avoid infinites loops */
  gantt.templates.task_cell_class = function (item, date) {
    let calendar;

    if (item.calendar_id) {
      calendar = gantt.getCalendar(item.calendar_id);
    }

    if (calendar) {
      if (
        gantt.config.scales[0].unit === 'day' &&
        gantt.config.scales[1].unit === 'hour'
      ) {
        if (!calendar.isWorkTime({ date: date, task: item, unit: 'hour' })) {
          return 'weekend personalized-weekend-class';
        }
      } else if (
        gantt.config.scales[0].unit === 'month' &&
        gantt.config.scales[1].unit === 'day'
      ) {
        if (!calendar.isWorkTime({ date: date, task: item, unit: 'day' })) {
          return 'weekend personalized-weekend-class';
        }
      } else if (
        gantt.config.scales[0].unit === 'month' &&
        gantt.config.scales[1].unit === 'week'
      ) {
        const copyOfDate = new Date(date);
        const endDate = gantt.date.add(date, 6, 'day');
        let isWorkable = false;
        while (true) {
          // Funcion mal utilizada deberia ser {date, unit: 'day'}
          if (calendar.isWorkTime({ date: copyOfDate, unit: 'day' })) {
            isWorkable = true;
            break;
          } else {
            copyOfDate.setDate(copyOfDate.getDate() + 1);
            if (copyOfDate > endDate) break;
          }
        }

        if (!isWorkable) return 'weekend personalized-weekend-class';
      } else if (
        gantt.config.scales[0].unit === 'year' &&
        gantt.config.scales[1].unit === 'month'
      ) {
        const copyOfDate = new Date(date);
        let isWorkable = false;
        while (true) {
          // Funcion mal utilizada deberia ser {date, unit: 'day'}
          if (calendar.isWorkTime({ date: copyOfDate, unit: 'day' })) {
            isWorkable = true;
            break;
          } else {
            copyOfDate.setDate(copyOfDate.getDate() + 1);
            if (copyOfDate.getDate() == 1) break;
          }
        }

        if (!isWorkable) return 'weekend personalized-weekend-class';
      }
    }
  };

  gantt.checkLinkNotCircular = () => {
    let count = 0;
    const arr = [];
    gantt.getLinks().map(async (link) => {
      const link_circulate_validate = gantt.isCircularLink(link);
      if (link_circulate_validate) {
        count++;
        const getSourceActivity = await activityService.showCircular({
          sector_id: parseInt(link.sectorId),
          unique_id: parseInt(link.source)
        });
        if (getSourceActivity) arr.push(getSourceActivity.activity[0]);
      }
    });
    return arr;
  };

  /** Task height */
  gantt.config.task_height = 20;

  gantt.templates.task_class = function (start, end, task) {
    if (task.correlative_id === 0)
      return 'top-fix-style new-project-gantt-style';
    else if (task.type === 'project')
      return 'top-fix-style new-project-gantt-style';
    return 'top-fix-style new-child-gantt-style';
  };

  gantt.templates.link_class = function (link) {
    if (!gantt.config.show_links) {
      return 'hidden';
    }
    return 'top-fix-style new-links-gantt-style';
  };

  const isAddedThroughLineTimeout = () => {
    gantt.isAddedThroughLine = true;
    setTimeout(() => {
      gantt.isAddedThroughLine = false;
    }, 500);
  };

  const lineAddHandler = async (activityId) => {
    isAddedThroughLineTimeout();
    addActivityAfter(activityId);
    trackingEvent(
      'activity_insertion',
      {
        ...getBasicAmplitudEventProperties(),
        source: 'bar'
      },
      AMPLITUDE_SERVICE
    );
  };

  gantt.lineAddHandler = lineAddHandler;

  gantt.templates.grid_card_div = (
    activity
  ) => `<div class="gantt_tree_card ${gantt.isActiveColorWBS && 'gantt_tree_card_adjust_wbs'}" onclick="window.to_use_react_gantt.openActivityCard(${activity.id});">
      <span class="open-card-button-new-gantt"></span>
    </div>`;

  /** Height of the header labels of gantt */
  gantt.config.scale_height = 28;

  /** Height of table columns height */
  gantt.config.row_height = 30;

  /** Min width for grid table  */
  gantt.config.min_grid_column_width = 100;

  /** This prop allows to navigate through mouse at the gantt */
  gantt.config.drag_timeline = {
    ignore: '.gantt_task_line, .gantt_task_link',
    useKey: 'ctrlKey'
  };

  events.push(
    gantt.attachEvent('onBeforeLinkUpdate', (id, new_item) => {
      const shouldAbortLink = checkCircularLinks(
        new_item,
        gantt,
        modalCircular
      );
      if (shouldAbortLink) return false;
      return true;
    })
  );

  events.push(
    gantt.attachEvent('onGridResizeEnd', (_, new_width) => {
      dispatch(
        ganttActions.setUserPreferenceTableGantt({
          table: null,
          ganttChart: new_width
        })
      );
      runAndUnmountTimeout(gantt.adjustGridWidth, 700);
      setTimeout(() => gantt.fixScaleDateHeader(), 1);
      return true;
    })
  );

  events.push(
    gantt.attachEvent(
      'onColumnResizeEnd',
      (_index, configColumn, new_width) => {
        const { ganttState } = store.getState();
        const { userPreferenceTableGantt } = ganttState;
        const { gridTable } = userPreferenceTableGantt;
        const columnIdx = gridTable.findIndex(
          (column) => configColumn.name == column.name
        );

        const newConfig = { ...configColumn, width: new_width };
        delete newConfig.min_grid_column_width;
        const newGridTable = gridTable.toSpliced(columnIdx, 1, {
          ...configColumn,
          width: new_width
        });
        dispatch(
          ganttActions.setUserPreferenceTableGantt({
            table: newGridTable,
            ganttChart: null
          })
        );
        runAndUnmountTimeout(gantt.adjustGridWidth, 700);
        gantt.fixScaleDateHeader();
        updateDefaultView();
        return true;
      }
    )
  );

  /** Configuration execution for mouse scrolling zoom */
  const scale = JSON.parse(localStorage.getItem('scale'));
  gantt.ext.zoom.init(init_scroll_zoom_config(gantt));

  events.push(
    gantt.attachEvent('onTaskOpened', (id) => {
      updateDefaultView();
    })
  );

  events.push(
    gantt.attachEvent('onTaskClosed', (id) => {
      updateDefaultView();
    })
  );

  events.push(
    gantt.ext.zoom.attachEvent('onAfterZoom', (level, config) => {
      /** Disabled auto scroll */
      gantt.config.scroll_on_click = false;
      let current_zoom = 'MONTH';
      switch (level) {
        case 0:
          current_zoom = 'YEARS';
          break;
        case 4:
          current_zoom = 'DAYS';
          break;
        case 3:
          current_zoom = 'WEEKS';
          break;
        case 2:
          current_zoom = 'MONTH';
          break;
        case 1:
          current_zoom = 'QUARTERS';
          break;
      }

      setZoomLevel(current_zoom);
      window.localStorage.setItem(
        'scale',
        JSON.stringify(current_zoom.toLowerCase())
      );
      if (!gantt.ext) return;
      if (!gantt.ext.zoom) return;
      if (!gantt.ext.zoom.getCurrentLevel) return;
      const currentZoomLevel = gantt.ext.zoom.getCurrentLevel();
      const stringValues = ['YEARS', 'QUARTERS', 'MONTH', 'WEEKS', 'DAYS'];
      const stringCurrentValue = stringValues[currentZoomLevel];
      if (!stringCurrentValue) return;
      gantt.stringCurrentValue = stringCurrentValue;
      /* Aqui se inicializa el zoom */
      EventEmitter.emit('changeScaleVisualization', {
        value: gantt.stringCurrentValue
      });
      const { ganttState } = store.getState();
      const { loading } = ganttState;
      updateDefaultView(loading);
    })
  );

  /** Opens lightbox on creating a task */
  gantt.config.details_on_create = false;

  /** disables backward scheduling */
  gantt.config.schedule_from_end = false;

  /** Marker for start project :) */
  if (gantt.addMarker) {
    gantt.addMarker({
      start_date: gantt.config.project_start,
      text: 'project start'
    });
  }

  /** Marker for today line */
  const dateToString = gantt.date.date_to_str(gantt.config.task_date);
  const todayLine = {
    start_date: new Date(),
    css: 'today',
    text: t('today_label_only'),
    title: `${t('today_label_only')}: ${dateToString(new Date())}`
  };
  let todayLineMarker = gantt.addMarker(todayLine);

  /** Map if element is a task, project or milestone  */
  gantt.config.auto_types = true;

  /** Editor task and GRID table configuration */

  const columnsByDefault = table_grid_columns(gantt, t, masterplanPer);

  let flag = false;
  /** check if the order changed */
  if (userPreferenceTableGantt) {
    if (userPreferenceTableGantt.gridTable) {
      flag = userPreferenceTableGantt.gridTable.some(
        (el, idx) => el.name !== columnsByDefault[idx].name
      );
    }
  }

  let copyOfTableData = columnsByDefault;
  const tmp_columns = [];
  if (flag) {
    // WBS is injected forced
    const colWBS = columnsByDefault.find((col) => col.name === columnNameWBS);
    const colPre = columnsByDefault.find((col) => col.name === preColumnWBS);
    if (colWBS) {
      tmp_columns.push(colPre);
      tmp_columns.push(colWBS);
    }
    userPreferenceTableGantt.gridTable.map((el, idx) => {
      const findInCopy = columnsByDefault.find((col) => col.name === el.name);
      if (findInCopy) {
        tmp_columns.push(findInCopy);
      }
    });

    copyOfTableData = tmp_columns;
  }

  copyOfTableData.map((column, idx) => {
    if (
      column.name != 'buttons' &&
      column.name != columnNameWBS &&
      column.name != preColumnWBS
    ) {
      if (
        column.data_type.includes('/icon') ||
        column.data_type.includes('/string')
      ) {
        column.label = t('tables.gantt.' + column.name + '.label');
        if (!column.data_type.includes('array/string')) {
          column.from_values.map((option) => {
            option.label = t(
              'tables.gantt.' + column.name + '.options.' + option.value
            );
          });
        }
      } else {
        column.label = t('tables.gantt.' + column.name);
      }
    }
  });

  // Drop all undefined items in the array
  const columnsActive = cleanColumnsIssues(copyOfTableData);

  gantt.config.columns = columnsActive;
  /** Optimization on the rendering */
  gantt.config.smart_rendering = true;
  gantt.config.static_background = true;
  gantt.config.show_task_cells = true;
  gantt.config.show_progress = true;
  gantt.config.smart_scales = true;
  gantt.config.open_tree_initially = true;
  gantt.config.keyboard_navigation_cells = true;
  gantt.config.order_branch = true;
  gantt.config.order_branch_free = false;

  /** Disable the row to move progress at gantt chart */
  gantt.config.drag_progress = false;

  gantt.completeTask = (task_id) => {
    gantt.completingOnProcess = true;
    gantt.completeTaskRecursive(task_id);
    gantt.completingOnProcess = false;
  };

  gantt.completeTaskRecursive = (task_id) => {
    if (!gantt.isTaskExists(task_id)) return '';

    const task = gantt.getTask(task_id);
    task.progress = 100;
    task.auto_scheduling = false;
    gantt.updateTask(task.id);

    const childs = gantt.getTaskByTime().filter((t) => t.parent == task_id);
    childs.forEach((child) => {
      child.progress = 100;
      child.auto_scheduling = false;
      gantt.updateTask(child.id);
      if (child.type == 'project') {
        gantt.completeTaskRecursive(child.id);
      }
    });
    if (task.type !== 'project') {
      gantt.update_progress(task_id);
    }
  };

  gantt.uncompleteTask = (task_id) => {
    gantt.ext.undo.undoStackOldReverse = true;
    gantt.completingOnProcess = true;
    gantt.uncompleteTaskRecursive(task_id);
    gantt.completingOnProcess = false;
  };

  gantt.uncompleteTaskRecursive = (task_id) => {
    gantt.batchUpdate(() => {
      if (!gantt.isTaskExists(task_id)) return '';
      const task = gantt.getTask(task_id);
      task.auto_scheduling = true;
      task.progress = 0;
      gantt.updateTask(task.id);
      const childs = gantt.getTaskByTime().filter((t) => t.parent == task_id);
      if (childs.length === 0) {
        check_progress(gantt, task);
        return;
      }
      childs.forEach((child) => {
        child.auto_scheduling = true;
        child.progress = 0;
        gantt.updateTask(child.id);
        if (child.type == 'project') {
          gantt.uncompleteTaskRecursive(child.id);
        }
      });
      if (task.type !== 'project') {
        gantt.update_progress(task_id);
      }
    });
  };

  events.push(
    gantt.attachEvent('onTaskRowClick', (id) => {
      // any custom logic here
      if (!gantt.isTaskExists(id)) return '';
    })
  );

  gantt.openActivityCard = (activityId) => {
    gantt.activityCard = activityId;
    const task = gantt.getTask(activityId);
    const proplannerId = task?.proplannerId;

    const params = {
      globalActivityId: proplannerId,
      globalDhtmlxId: activityId
    };
    setTimeout(() => {
      gantt.activityCard = null;
      dispatch(
        openDrawer({
          component: ActivityCardDrawer,
          origin: DRAWER_ORIGINS.BOTTOM,
          params
        })
      );
      trackingEvent(
        'activity_card_open',
        {
          ...getBasicAmplitudEventProperties(),
          activity_name: task?.text,
          activity_id: task?.id,
          activity_index: task?.correlative_id
        },
        AMPLITUDE_SERVICE
      );
    }, 100);
  };

  /**
   * This function gives us a vector with id of tasks selected (through checked value)
   * @returns Vector with ids of taks selected
   */
  const checkArrayMassive = () => {
    const gantt = window.to_use_react_gantt;
    const checked_gems = gantt.getTaskByTime();
    let checkedTasks = checked_gems.filter(
      (t) => t.should_be_showed && (t.visibleChecked || t.checked)
    );

    /** check if all are parents */
    const allAreParents = !checkedTasks.some(
      (t) => gantt.hasChild(t.id) === undefined
    );
    if (allAreParents) {
      checkedTasks = [];
    }
    return checkedTasks;
  };
  gantt.checkArrayMassive = checkArrayMassive;

  gantt.arrayMassive = [];

  /**
   * This function only get data if BaB feature is ON
   */
  const getArrMassiveFn = () => {
    const isBulkActionsBarFeatureEnabled =
      isFeatureOn(FEATURE_FLAGS.ENABLE_BULK_ACTIONS_BAR) &&
      enableForMassiveSelect();
    if (isBulkActionsBarFeatureEnabled) {
      const getArrMassive = checkArrayMassive();
      gantt.arrayMassive = getArrMassive;
    }
  };
  gantt.getArrMassiveFn = getArrMassiveFn;

  gantt.attachEvent('onTaskUnselected', (id) => {
    if (!gantt.isTaskExists(id)) return;
    if (gantt.getTask(id).checked) gantt.selectTask(id);
  });

  gantt.attachEvent('onTaskSelected', (id) => {
    if (window.to_use_react_gantt.avoidCloseDrawer) return;
    dispatch(requestCloseDrawer());
    window.to_use_react_gantt.avoidCloseDrawer = false;
  });

  gantt.unCheckMassive = () => {
    gantt.batchUpdate(() => {
      gantt.getTaskByTime().forEach((ac) => {
        ac.checked = false;
        gantt.unselectTask(ac.id);
      });
    });
  };

  gantt.indentMassive = () => {
    gantt.performAction('indent');
    updateAllCorrelatives();
    // gantt.unCheckMassive() LWP-1224
  };

  gantt.outdentMassive = () => {
    gantt.performAction('outdent');
    updateAllCorrelatives();
    // gantt.unCheckMassive() LWP-1224
  };

  gantt.scrollToTodayAtChart = () => {
    gantt.avoidShowDateOptimization = true;
    gantt.showDate(new Date());
  };

  gantt.deleteMassive = () => {
    gantt.confirm({
      text: '¿ Tasks will be deleted permanently, are you sure ?',
      callback: function (res) {
        if (res) {
          gantt.performAction('del');
        }
      }
    });
  };

  gantt.linkMassive = () => {
    const checkedTasks = [];
    gantt.batchUpdate(() => {
      gantt.getSelectedTasks().forEach((task_id) => {
        const object = gantt.getTask(task_id);
        if (object.checked) {
          checkedTasks.push(object);
        }
      });
      if (checkedTasks && checkedTasks.length) {
        const lastIndex = checkedTasks.length - 1;
        for (let i = 0; i < lastIndex; i++) {
          const currentPointer = checkedTasks[i];
          const nextPointer = checkedTasks[i + 1];

          const newLink = {
            source: currentPointer.id,
            target: nextPointer.id,
            type: gantt.config.links.finish_to_start
          };
          gantt.addLink(newLink);
          trackingEvent(
            'try_add_link',
            { getBasicAmplitudEventProperties, location: 'from linkMassive' },
            AMPLITUDE_SERVICE
          );
        }
      }
    });
  };

  gantt.suspense_delete_update = true;
  gantt.unlinkMassive = () => {
    gantt.suspense_delete_update = false;
    gantt.batchUpdate(() => {
      const checkedTasks = [];
      gantt.getSelectedTasks().forEach((task_id) => {
        const object = gantt.getTask(task_id);
        if (object.checked) {
          checkedTasks.push(object);
        }
      });
      if (checkedTasks && checkedTasks.length) {
        let elToDelete = [];
        const lastIndex = checkedTasks.length;
        for (let i = 0; i < lastIndex; i++) {
          const currentPointer = [
            ...checkedTasks[i].$source,
            ...checkedTasks[i].$target
          ];
          if (!currentPointer) continue;
          const linksToNextPointer = [];
          currentPointer.forEach((linkId) => {
            const linkObject = gantt.getLink(linkId);
            linksToNextPointer.push(linkObject);
          });
          elToDelete = [...elToDelete, ...linksToNextPointer];
        }

        elToDelete.forEach((toDelete) => {
          if (gantt.isLinkExists(toDelete.id)) {
            gantt.deleteLink(toDelete.id);
          }
        });
      }
    });
    gantt.suspense_delete_update = true;
    criticalPathRefresh();
  };

  gantt.changeScaleVisualization = (scale = 'weeks') => {
    let newZoomLvl;
    const currentLevel = gantt.ext.zoom.getCurrentLevel();
    switch (scale) {
      case 'hours':
        newZoomLvl = 3; // for tasks saved in hours, transform to weeks.
        break;
      case 'days':
        newZoomLvl = 4;
        break;
      case 'weeks':
        newZoomLvl = 3;
        break;
      case 'month':
        newZoomLvl = 2;
        break;
      case 'quarters':
        newZoomLvl = 1;
        break;
      case 'years':
        newZoomLvl = 0;
        break;
    }
    if (newZoomLvl !== currentLevel) gantt.ext.zoom.setLevel(newZoomLvl);
  };

  const config = gantt.config;

  const dispatchGanttVisualizationConfigChange = () => {
    dispatch(
      ganttActions.notifyGanttVisualizationConfigChange({
        areLinksVisible: gantt.config.show_links,
        areSlackVisible: gantt.config.show_slack,
        areBaselineVisible: gantt.config.show_baseline,
        areNumtasksVisible: gantt.config.show_numtasks,
        isTodaylineVisible: gantt.config.show_todayline,
        areSubmittalsVisible: gantt.config.show_submittal_icon
      })
    );
  };

  gantt.changeLinksVisibility = (render = true) => {
    config.show_links = !config.show_links;
    if (render) gantt.optimizedRender();
    dispatchGanttVisualizationConfigChange();
  };

  gantt.changeSlackVisibility = (render = true) => {
    gantt.config.show_slack = !gantt.config.show_slack;

    if (gantt.config.show_slack) {
      gantt.idTaskLayerSlack = gantt.addTaskLayer(
        adding_slack_to_task_layer(gantt)
      );
    } else {
      gantt.removeTaskLayer(gantt.idTaskLayerSlack);
    }

    if (render) gantt.optimizedRender();
    dispatchGanttVisualizationConfigChange();
  };

  gantt.changeBaselineVisibility = (render = true) => {
    gantt.config.show_baseline = !gantt.config.show_baseline;

    if (gantt.config.show_baseline) {
      gantt.idTaskLayerBaseline = gantt.addTaskLayer(
        baseline_task_layer(gantt)
      );
      gantt.templates.rightside_text = right_side_gantt(gantt, true);
    } else {
      gantt.removeTaskLayer(gantt.idTaskLayerBaseline);
      gantt.templates.rightside_text = right_side_gantt(gantt, false);
    }

    if (render) gantt.optimizedRender();
    dispatchGanttVisualizationConfigChange();
  };

  gantt.changeNumtasksVisibility = (render = true) => {
    gantt.config.show_numtasks = !gantt.config.show_numtasks;
    if (render) gantt.optimizedRender();
    dispatchGanttVisualizationConfigChange();
  };

  gantt.changeTodaylineVisibility = (render = true) => {
    gantt.config.show_todayline = !gantt.config.show_todayline;
    if (gantt.config.show_todayline) {
      todayLineMarker = gantt.addMarker(todayLine);
      trackEventBasicProps('show_today_ line');
    } else {
      gantt.deleteMarker(todayLineMarker);
      trackEventBasicProps('hide_today_ line');
    }

    if (render) gantt.optimizedRender();
    dispatchGanttVisualizationConfigChange();
  };

  gantt.changeSubmittalsVisibility = () => {
    changeSubmittalsVisibility(
      gantt,
      trackEventBasicProps,
      dispatchGanttVisualizationConfigChange
    );
  };

  gantt.changeLevelVisualization = (lvl) => {
    if (lvl != gantt.detectedLevelsInGantt.length - 1) {
      gantt.isLevelsFiltered = true;
    } else {
      gantt.isLevelsFiltered = false;
    }

    /** We open all tasks */
    gantt.getTaskByTime().filter((task) => {
      task.$open = true;
    });

    /** We close belongs task branch */
    gantt
      .getTaskBy((task) => task.$level == lvl)
      .map((task) => {
        task.$open = false;
      });

    gantt.optimizedRender();
  };

  /**
   * this function return project name
   * @param {*} projectState
   * @returns
   */
  const getProjecName = (projectState) => {
    const project = projectState.allProjects.find(
      (pr) => pr.id === projectState.projectSelected
    );
    let projectName = '';
    if (project) {
      projectName = project.name;
    }
    return projectName;
  };

  /**
   *
   * @returns get today with format defined in master plan
   */
  const getFormatToday = (format) => {
    let today = moment().format(window.to_use_react_gantt.currentDateFormat);
    if (format) {
      today = moment().format(format);
    }
    return today;
  };

  const getFormatDate = (date, format) => {
    const currentDate = moment(date).format(
      format || window.to_use_react_gantt.currentDateFormat
    );

    return currentDate;
  };

  const getHeaderPdf = (page = 1, total = 0, prefixIni = '') => {
    const currentSector = sector;
    const projectName = getProjecName(projectState);
    const imgCompany = companyState.currentCompany.image;
    const imgOwner = projectState.allProjects?.find(
      (p) => p.id === projectState.projectSelected
    )?.imageProjectOwner;
    let imgLogoCompany = '<div style="height:100%; width:50px"></div>';
    let classNoImage = 'position-noimage';
    if (imgCompany) {
      imgLogoCompany = `<img src="${imgCompany}" class="img-company imagen-custom-logo" />`;
      classNoImage = '';
    }

    const headerRight = imgOwner
      ? `<img src="${imgOwner}" class="img-owner imagen-custom-owner" />`
      : '<span class="header-right"><div style="height:5px; width:30px"></div></span>';
    const headerHtml = `<div class="wrapper-pdf">
                                ${imgLogoCompany}
                                <div class="wrapper-pdf-company">
                                    <span class="gantt-header-title-project">${projectName}</span>
                                    <span class="gantt-header-title-sector">${currentSector.name}</span>
                                </div>
                                ${headerRight}
                            </div>`;

    return headerHtml;
  };

  const getFooterPdf = (newDate) => {
    const imgCompany = companyState.currentCompany.image;
    let imgLogoCompany = '';
    let classNoImage = 'position-noimage';
    if (imgCompany) {
      imgLogoCompany = `<img src="${imgCompany}" class="img-company" />`;
      classNoImage = '';
    }

    /** img proplanner */
    const imgproplaner =
      'https://proplannerv2.s3.us-east-2.amazonaws.com/pdf-gantt/outbuild-logo.png';
    const imgPP = `<img src="${imgproplaner}" class="img-pp" alt="logo-proplanner" />`;

    const today = newDate ? getFormatDate(newDate) : getFormatToday();
    const marginElement = '<div style="height:5px; width:30px"></div>';

    let optionStatus = '';
    // getPlaneCssToExport(gantt.config.highlight_critical_path, gantt.config.show_slack, gantt.config.show_links)
    if (gantt.config.highlight_critical_path) {
      optionStatus = `${marginElement}${marginElement}
                            <div class="footer_container_icons_column">
                                <span class="footer_container_icons_column_title">Critical Path</span>${marginElement}${marginElement}
                                <div class="footer_container_items">
                                    <div class="footer_container_icons_column_item">
                                        <span><img class="footer-icon" src="https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146869923-logo.png"/> Critical</span>
                                        ${marginElement}
                                        <span><img class="footer-icon" src="https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146893853-logo.png"/> Non Critical</span>
                                    </div>
                                </div>
                            </div>`;
    } else {
      if (gantt.visualizationColorActive === 'status') {
        optionStatus = `${marginElement}${marginElement}
                                <div class="footer_container_icons_column">
                                    <span class="footer_container_icons_column_title">Status</span>${marginElement}${marginElement}
                                    <div class="footer_container_items">
                                        <div class="footer_container_icons_column_item">
                                        <span>
                                            <div>
                                                <img class="footer-icon" src="https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662521987746-logo.png    "/> 
                                            </div>
                                            <span>Completed</span>
                                            ${marginElement}
                                        </span>
                                        ${marginElement}
                                        <span>
                                            <div>
                                                <img class="footer-icon" src="https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146869923-logo.png"/> 
                                            </div>
                                            <span>Overdue</span>
                                            ${marginElement}
                                        </span>
                                    </div>
                                    <div class="footer_container_icons_column_item">
                                        <span>
                                            <div>
                                                <img class="footer-icon" src="https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662521930394-logo.png"/> 
                                            </div>
                                            <span>Ahead</span>
                                            ${marginElement}
                                        </span>
                                        ${marginElement}
                                        <span>
                                            <div>
                                                <img class="footer-icon" src="https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146893853-logo.png"/> 
                                            </div>
                                            <span>Uninitiated</span>
                                            ${marginElement}
                                        </span>
                                    </div>
                                    </div>
                                </div>`;
      }
    }

    const footerHtml = `<div class="footer_container">
                                <div style="display: flex;flex-direction: row">${marginElement}${imgPP}</div>   
                                <div class="footer_container_icons">
                                    <div class="footer_container_icons_column">
                                        <span class="footer_container_icons_column_title">General</span>${marginElement}${marginElement}
                                        <div class="footer_container_items">
                                            <div class="footer_container_icons_column_item">
                                                <span>
                                                    <div>
                                                        <img class="footer-icon" src="https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146539442-logo.png"/> 
                                                    </div>
                                                    <span>Parent Activity</span>
                                                    ${marginElement}
                                                </span>
                                                ${marginElement}
                                                <span>
                                                    <div>
                                                        <img class="footer-icon" src="https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146694166-logo.png"/> 
                                                    </div>
                                                    <span>Child Activity</span>
                                                    ${marginElement}
                                                </span>
                                            </div>
                                            <div class="footer_container_icons_column_item">
                                                <span>
                                                    <div>
                                                        <img class="footer-icon" src="https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146724169-logo.png"/> 
                                                    </div>
                                                    <span>Baseline</span>
                                                    ${marginElement}
                                                </span>
                                                ${marginElement}
                                                <span>
                                                    <div>
                                                        <span style="text-align: center; font-size: 13px;margin-left:-3px"><p>(+# days)</p> 
                                                    </div>
                                                    <span>Delay</span>
                                                    ${marginElement}
                                                </span>
                                            </div>
                                            <div class="footer_container_icons_column_item">
                                                <span>
                                                    <div style="width:30px !important">
                                                        <img class="footer-icon" src="https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146751776-logo.png" style="height:15px !important"/> 
                                                    </div>
                                                    <span>Milestone</span>
                                                    ${marginElement}
                                                </span>
                                            </div>
                                            <div class="footer_container_icons_column_item">
                                                <span class="footer_container_icons_column_item__links"><img class="footer-icon" src="https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146790862-logo.png"/> Links</span>
                                                ${marginElement}
                                                <span class="footer_container_icons_column_item__float" style="display:none"><img class="footer-icon" src="https://proplannerv2.s3.us-east-2.amazonaws.com/images/companys/1662146843189-logo.png"/> Float</span>
                                            </div>
                                        </div>
                                    </div>
                                    ${optionStatus}
                                    
                                </div>
                                <div class="footer_container_info">
                                    <span class="gantt-footer-info"><p class="gantt-footer-title">${t('master_plan.printed_on')}:  </p><p class="gantt-footer-data">${today} </p>${marginElement}</span>
                                    <span id="gantt-footer-page" class="gantt-footer-info" style="visibility:hidden;"><p class="gantt-footer-title">${t('master_plan.page')}:  </p><p class="gantt-footer-data">{{p}}/{{pt}} </p></span>
                                </div>
                            </div>`;
    return footerHtml;
  };

  const calculateFilteredDaysPercentage = () => {
    const tasks = gantt.getTaskByTime();
    if (tasks.length === 0) return 1;
    let minStartDate = null;
    let maxStartDate = null;
    let taskProject = null;
    let isGanttFiltered = false;
    tasks.forEach((task) => {
      const {
        $level,
        should_be_showed,
        $expanded_branch,
        type,
        start_date,
        end_date
      } = task;
      const isVisible = should_be_showed && $expanded_branch;
      const isProject = type === 'project';
      if ($level === 0) taskProject = task;

      if (!isGanttFiltered && !isVisible) isGanttFiltered = true;
      if (!isVisible || isProject) return;

      const startDate = new Date(start_date);
      const endDate = new Date(end_date);

      if (!minStartDate || minStartDate > startDate) {
        minStartDate = startDate;
      }

      if (maxStartDate < endDate) {
        maxStartDate = endDate;
      }
    });

    if (!isGanttFiltered) return 1;

    const allDaysProject = moment(taskProject.end_date).diff(
      moment(taskProject.start_date),
      'days'
    );
    const countDaysFiltersOrCollapsed = moment(maxStartDate).diff(
      moment(minStartDate),
      'days'
    );
    return countDaysFiltersOrCollapsed / allDaysProject;
  };

  const exportPDFWithConfig = async (
    setLoading,
    handleCloseModal,
    newDate,
    newDateToday,
    isFitOnePage,
    isTrimParent
  ) => {
    updateAllTaskForExportPDF(gantt);
    gantt.unselectTask();
    gantt.detachMultiDragSelect();
    gantt.render();
    const currentZoomLevel = gantt.ext.zoom.getCurrentLevel();
    const formatToExport = gantt.formatToExport;
    const isFormatAllContent = formatToExport === 'ALL_CONTENT';
    let isCorrectPDF = true;
    if (currentZoomLevel >= 4) gantt.ext.zoom.setLevel(3);
    if (gantt.getGridColumn('buttons')) {
      gantt.getGridColumn('buttons').hide = true;
    }
    if (gantt.getGridColumn(preColumnWBS)) {
      gantt.getGridColumn(preColumnWBS).hide = false;
    }

    const DOMTimelineHeader =
      document.getElementsByClassName('gantt_scale_cell');
    const widthColumnTask = DOMTimelineHeader[
      DOMTimelineHeader.length - 1
    ].style.width.replace('px', '');
    let minWidthColumnZoom = 50;

    const zoomLevelConfig = {
      1: 40,
      3: 150,
      4: 20
    };
    minWidthColumnZoom = zoomLevelConfig[currentZoomLevel] || 50;

    if (isFormatAllContent && currentZoomLevel === 3) {
      minWidthColumnZoom = 80;
    }

    const addWidth = isFormatAllContent ? 1.25 : 1;
    let task_bg_width =
      (gantt.$task_bg.scrollWidth / widthColumnTask) * minWidthColumnZoom;

    const percentageWith = isFormatAllContent
      ? 1
      : calculateFilteredDaysPercentage();
    task_bg_width = percentageWith * task_bg_width;
    if (task_bg_width < 200) task_bg_width = 200;
    const total_width = gantt.$grid.scrollWidth * addWidth + task_bg_width;
    const total_heigth = gantt.$task_bg.offsetHeight + gantt.$grid.offsetHeight;

    const additional_settings = {};

    const formatOnPixels = {
      A3: {
        height: 1647,
        width: 2383,
        format_text: 'A3'
      },
      Tabloid: {
        height: 1544.8,
        width: 2448,
        format_text: 'Tabloid'
      },
      ALL_CONTENT: {
        height: total_heigth,
        width: total_width,
        format_text: 'ALL_CONTENT'
      },
      Arch_C: {
        height: 2527.8,
        width: 3370.4,
        format_text: 'Arch_C'
      },
      Arch_D: {
        height: 3370.4,
        width: 5055.6,
        format_text: 'Arch_D'
      }
    };

    /** When we use format export, we need validate that inch values to pixel values (https://www.unitconverters.net/typography/inch-to-pixel-x.htm) with this dhtmlx lib handles better pdf */
    if (gantt.formatToExport && gantt.formatToExport) {
      // additional_settings.format = gantt.formatToExport
      const onPixelSize = formatOnPixels[gantt.formatToExport];
      // const onPixelSize = formatOnPixels['ALL_CONTENT']
      if (!onPixelSize) return;
      additional_settings.width = onPixelSize.width;
      additional_settings.height = onPixelSize.height;
      additional_settings.format_text = onPixelSize.format_text;
    }

    /** Then we count how many width follow by selected format */
    const extraCSS = (left = 0, addWidthToChart) => `<style>
        .gantt_layout_cell.timeline_cell {
            left: ${left}px !important;
            ${addWidthToChart ? 'width: 2000px !important;' : ''}
        }
        </style>`;
    let pageCounter = 1;
    let customAdjust = 0;
    let allTableWidth;
    if (
      document.getElementsByClassName('gantt_grid_data') &&
      document.getElementsByClassName('gantt_grid_data')[0]
    ) {
      allTableWidth =
        document.getElementsByClassName('gantt_grid_data')[0].offsetWidth;
    }

    /** calculate total pages */
    const totalPages =
      isFitOnePage || isFormatAllContent
        ? 1
        : parseInt(total_width / additional_settings.width) + 1;

    const arrayOfFiles = [];

    const userLang = navigator.language || navigator.userLanguage;
    const planeCssToExport = getPlaneCssToExport(gantt)(
      gantt.config.highlight_critical_path,
      gantt.config.show_slack,
      gantt.config.show_links,
      gantt.config.show_baseline,
      gantt.config.show_numtasks,
      gantt.config.show_todayline,
      gantt.isActiveColorWBS
    );

    for (let i = 1; i <= totalPages; i++) {
      const positionPage = additional_settings.width * (i - 1);
      // customAdjust = 0// i * 0.06
      // if (pageCounter === 1) {
      //     customAdjust = 0
      // }

      if (allTableWidth && positionPage >= allTableWidth) {
        // console.log('Starting printing CHART')
        customAdjust = 20;
      }

      /** get project name */
      const projectName = getProjecName(projectState);
      const today = getFormatToday();

      let headerHtml = getHeaderPdf(pageCounter, totalPages);
      const newI = positionPage + additional_settings.width;
      additional_settings.customProplanner = {
        totalPages,
        pageCounter,
        format_text: additional_settings.format_text,
        level: gantt.ext.zoom.getCurrentLevel(),
        lang: userLang.includes('es') ? 'es' : 'en',
        isFitOnePage,
        isTrimParent
      };
      if (newI >= total_width) {
        /** is lastpage */
        headerHtml = getHeaderPdf(pageCounter, totalPages, 'lastpage');
      }

      const customCss = '<style>' + planeCssToExport + '</style>' + headerHtml;
      const adjustCSS = extraCSS(customAdjust, false);
      const cssAdjustToIteration = `${adjustCSS}<style>#gantt_here{left:-${positionPage}px;position: absolute;}</style>`;

      // const DOMTimelineHeader = document.getElementsByClassName('gantt_scale_cell fix_width')
      // const currentWidthTimelineString = DOMTimelineHeader[3].attributes.style.nodeValue
      const currentWidthTimelineString =
        DOMTimelineHeader[DOMTimelineHeader.length - 1].attributes.style
          .nodeValue;
      const posIni = currentWidthTimelineString.indexOf(':');
      const posFin = currentWidthTimelineString.indexOf(';');
      const currentWidthTimelineInteger = currentWidthTimelineString.substring(
        posIni + 1,
        posFin - 2
      );
      if (
        DOMTimelineHeader &&
        Number.isInteger(parseInt(currentWidthTimelineInteger))
      ) {
        gantt.config.min_column_width = currentWidthTimelineInteger;
      }

      arrayOfFiles.push(
        gantt.exportToPDF({
          raw: false,
          skip_circular_links: true,
          name: `${projectName}-${sector.name}-${today}(${pageCounter}).pdf`,
          header: cssAdjustToIteration + customCss,
          footer: getFooterPdf(newDate),
          server: exportableServerURL,
          additional_settings,
          newTodayLine: newDate,
          newDateToday: newDateToday
        })
      );
      pageCounter++;
    }

    const projectName = getProjecName(projectState);
    let today = getFormatToday();
    today = today.replaceAll('/', '-');
    const currentUser = JSON.parse(localStorage.getItem('user'));

    const jsonData = JSON.stringify(arrayOfFiles);
    const res = await activityPdfService.exportToPDF({
      data: jsonData,
      name: `${projectName}_${sector.name}_${today}_${additional_settings.format_text}.pdf`,
      current_user: currentUser,
      lang: userLang.includes('es') ? 'es' : 'en',
      project: projectName,
      sector: sector.name,
      project_id: projectState.projectSelected,
      sector_id: sector.id,
      is_schedule: true
    });

    let timeoutToUpdateToolbar = 3;

    if (res?.url) {
      window.open(res.url, '_blank');
    } else if (res?.message) {
      isCorrectPDF = false;
    } else {
      timeoutToUpdateToolbar = 20;
      const title =
        t('lang') === 'es'
          ? 'Tu exportable está generándose!'
          : 'Your exportable is being processed!';
      const description =
        t('lang') === 'es'
          ? 'Te informaremos vía correo y notificación cuando el exportable esté listo'
          : 'You will be notified by email and notification when the document is ready';
      openNotification(
        {
          title,
          description,
          type: 'info'
        },
        0
      );
    }
    if (setLoading) {
      setLoading(false);
    }
    if (gantt.ext.zoom.getCurrentLevel() !== currentZoomLevel)
      gantt.ext.zoom.setLevel(currentZoomLevel);
    gantt.fixScaleDateHeader();

    if (gantt.getGridColumn('buttons')) {
      gantt.getGridColumn('buttons').hide = false;
    }
    if (gantt.getGridColumn(preColumnWBS)) {
      gantt.getGridColumn(preColumnWBS).hide = true;
    }
    gantt.adjustGridWidth();
    setTimeout(() => {
      dispatch(userActions.setUserUpdate());
    }, 1000 * timeoutToUpdateToolbar);
    handleCloseModal();
    if (!isCorrectPDF) gantt.showModalPDF();
  };

  const getCalendars = async (sectorId) => {
    const calendars = await calendarService.showBySector(sectorId);
    return calendars.calendar ? calendars.calendar : [];
  };

  gantt.exportProcorexml = async () => {
    let html_text = '';
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const currentSector = sector;
    const calendars = await getCalendars(currentSector.id);

    const { responsibles, assignments } = responsibleDataProcore(gantt);
    const tasksByGantt = getTaskByGanttFormatProcore(gantt);

    let propsExportProcore = {
      skip_circular_links: false,
      name: currentSector.name,
      tasks: MSProyectParseData(gantt, true),
      project: {
        Author: currentUser.email,
        MinutesPerDay: function () {
          return currentSector.hoursPerDay * 60;
        }
      },
      server: exportableServerURL,
      isProcore: true,
      calendars,
      tasksByGantt
    };
    const { transferResponsibles } = companySettingsState;
    const isTransferResponsible = transferResponsibles?.transferResponsible;
    if (isTransferResponsible) {
      propsExportProcore = {
        ...propsExportProcore,
        responsibles,
        assignments
      };
    }

    html_text = gantt.exportToMSProjectProcore(propsExportProcore);
    /* console.log('====================================');
        console.log('html_text', html_text);
        console.log('===================================='); */
    return html_text;
  };

  gantt.config.hoursPerDay = sector?.hoursPerDay;

  gantt.exportGantt = async (
    option,
    loadingPdf = () => {},
    handleCloseModal = () => {},
    newDate = null,
    newDateToday = false,
    isFitOnePage = false,
    isTrimParent = false
  ) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const currentSector = sector;

    const calendars = await getCalendars(currentSector.id);
    switch (option) {
      case 'xml':
        gantt.exportToMSProject({
          skip_circular_links: false,
          name: currentSector.name,
          tasks: MSProyectParseData(gantt),
          project: {
            Author: currentUser.email,
            MinutesPerDay: function () {
              return currentSector.hoursPerDay * 60;
            }
          },
          calendars,
          server: exportableServerURL
        });
        break;
      case 'pdf':
        exportPDFWithConfig(
          loadingPdf,
          handleCloseModal,
          newDate,
          newDateToday,
          isFitOnePage,
          isTrimParent
        );
        break;
      case 'excel':
        gantt.exportToExcel({
          name: currentSector.name + '.xlsx',
          server: exportableServerURL
        });
        break;
    }
  };

  gantt.recoveryErrorSaveProcessXml = () => {
    const currentSector = sector;
    const currentUser = JSON.parse(localStorage.getItem('user'));
    gantt.exportToMSProject({
      skip_circular_links: false,
      name: currentSector.name + '-backup',
      tasks: MSProyectParseData(gantt),
      project: {
        Author: currentUser.email,
        MinutesPerDay: function () {
          return currentSector.hoursPerDay * 60;
        }
      },
      server: exportableServerURL
    });
  };

  gantt.addShortcut(
    'shift + l',
    (e) => {
      gantt.linkMassive();
    },
    'gantt'
  );

  gantt.addShortcut(
    'shift + u',
    (e) => {
      gantt.unlinkMassive();
    },
    'gantt'
  );

  gantt.addShortcut(
    'tab',
    (e) => {
      gantt.indentMassive();
    },
    'gantt'
  );

  gantt.addShortcut(
    'shift+tab',
    (e) => {
      gantt.outdentMassive();
    },
    'gantt'
  );

  gantt.addShortcut(
    'shift+d',
    (e) => {
      gantt.deleteMassive();
    },
    'gantt'
  );

  /** Ctrl +c and ctrl + v were native events, that were not able to being added through the addShortcut function given by DHTMLX
   * So then is needed to get into the code base, to add both handlers and link to this both functions
   */
  gantt.handlerCtrlC = () => {
    const selectedTasks = gantt
      ?.serialize()
      ?.data?.filter((t) => t.visibleChecked || t.checked);
    const canCopyTasks = handleCopy(gantt, dispatch, t, selectedTasks);

    if (canCopyTasks) {
      canCopyTasks.masterplan();
      trackEventBasicProps('activities_copying');
    }
  };

  /**
   * This function launch a message with succesfull pasted behaviour
   * @param {*} currentIndex current index of pasted object
   * @param {*} arrayLarge total of elements to paste
   * @returns null if current index is not equals to large of this array
   */
  const handleSuccessPasting = (currentIndex, arrayLarge) => {
    const doesThisLastElement = currentIndex === arrayLarge - 1;
    if (!doesThisLastElement) return;
    !gantt.isAddedThroughLine && launchMessage(false)(t, arrayLarge, 'success');
    gantt.detachMultiDragSelect();
    gantt.$keyboardNavigation.dispatcher.activeNode = null;
    gantt.$keyboardNavigation.dispatcher.isActive = false;
    updateAllCorrelatives();
    gantt.render();
  };

  /**
   * This function handle the pasting of links
   * @param {*} linksToPaste array with links copied
   * @param {*} resultFromPastingActivities { newToOldHash: Object which maps new activities ID to old ID, oldToNewHash: same before but old to new, createdTasksRefArray: array of gantt instance added, toOpenParents: parents that were copied }
   */
  const pasteLinks = (linksToPaste, resultFromPastingActivities = {}) => {
    try {
      const mainActivity = gantt.getTaskByIndex(1);
      const { oldToNewHash } = resultFromPastingActivities;
      if (!mainActivity) return;
      linksToPaste.forEach((link) => {
        const copiedLink = cloneDeep(link);
        const newSource = oldToNewHash[link.source]?.id;
        const newTarget = oldToNewHash[link.target]?.id;
        if (newSource && newTarget) {
          copiedLink.source = newSource;
          copiedLink.target = newTarget;
          delete copiedLink.id;
          delete copiedLink.proplannerId;
          copiedLink.sectorId = sector.id;
          copiedLink.ganttId = mainActivity.ganttId;
          gantt.addLink(copiedLink);
          trackingEvent(
            'try_add_link',
            { getBasicAmplitudEventProperties, location: 'from pasteLinks' },
            AMPLITUDE_SERVICE
          );
        }
      });
    } catch (e) {
      console.log('error on links psting process, please check...');
    }
  };

  /**
   * This function use batchUpdate from DHTMLX gantt to avoid collapse memory, and launch an iteration to create new pasted tasks
   * @param {*} selectedTasksProplanner OBJECT with selectedTasks and selectedLinks
   * @param {*} availableCalendars calendars available
   * @param {*} parent Parent which is going to be used when creating task
   * @param {*} initialIndexRef Where to start the pasting
   */
  const launchBatchUpdate = (
    selectedTasksProplanner,
    availableCalendars,
    parent,
    initialIndexRef = 0,
    doResetData = true
  ) => {
    let indexRef = initialIndexRef;
    /** Leveling integration with two hash maps, one that maps new IDS to old IDs and other that maps old IDS to new ones */
    gantt.batchUpdate(() => {
      const newToOldHash = {};
      const oldToNewHash = {};
      const createdTasksRefArray = [];
      const toOpenParents = [];

      selectedTasksProplanner &&
        selectedTasksProplanner.selectedTasks &&
        selectedTasksProplanner.selectedTasks.forEach((activity, index) => {
          const addedRef = pasteActivity(
            activity,
            parent,
            availableCalendars,
            doResetData
          );
          newToOldHash[addedRef.id] = activity;
          oldToNewHash[activity.id] = addedRef;
          createdTasksRefArray.push(addedRef);
          /** REMEMBER THAT THIS FUNCTION WORKS WITH PARENT CONTEXT INDEX (SO THEN FIRST CHILD START AS 1) */
          gantt.moveTask(addedRef.id, indexRef, parent);
          indexRef++;
        });

      handleSuccessPasting(
        selectedTasksProplanner?.selectedTasks?.length - 1,
        selectedTasksProplanner?.selectedTasks?.length
      );

      createdTasksRefArray.forEach((createdRef) => {
        const originalActivity = newToOldHash[createdRef.id];
        const originalParent = originalActivity.parent;
        const doesExistInHash = oldToNewHash[originalParent];
        if (doesExistInHash) {
          createdRef.parent = doesExistInHash.id;
          /** Trigger this functions will allows to Undo API to detect this indentation */
          doesExistInHash.type = 'project';
          gantt.updateTask(createdRef.id);
          const task = gantt.getTask(createdRef.id);
          task.is_open_lightbox = false;
          task.isNewActivity = true;
          task.old_cost_registered = 0;
          task.old_duration_registered = task.duration;
          task.old_hhWorkTime_registered = 0;
          task.old_progress_registered = 0;
          task.old_used_cost_registered = 0;
          task.auto_scheduling = true;
          if (!toOpenParents.includes(createdRef.parent)) {
            toOpenParents.push(createdRef.parent);
          }
        }
      });

      toOpenParents.forEach((id) => {
        gantt.isTaskExists(id) &&
          (gantt.getTask(id).type = 'project') &&
          gantt.open(id);
      });
      updateExpectedGlobal({ gantt });
      gantt.changeVisualizationOption && gantt.changeVisualizationOption();
      if (!selectedTasksProplanner.selectedLinks) return;
      const params = {
        newToOldHash,
        oldToNewHash,
        createdTasksRefArray,
        toOpenParents
      };
      pasteLinks(selectedTasksProplanner.selectedLinks, params);

      setTimeout(() => {
        // batch update externo pierde contexto por el timeout
        gantt.batchUpdate(() => {
          createdTasksRefArray.forEach((element) => {
            element.auto_scheduling = true;
            gantt.updateTask(element.id);
          });
          gantt.isPasting = false;
          gantt.autoSchedule();
          setAutoschedulingVisual(false);
        });
      }, 1000);
    });
  };
  /**
   * This function run a try/catch block to launch batch update to paste activities
   * @param {*} objectToPaste OBJECT with selectedTasks and selectedLinks
   * @param {*} availableCalendars calendars available
   * @param {*} parent Parent to be added as child activity
   * @param {*} initialIndexRef Index (correlative_id) to start adding
   */
  const pasteActivities = (
    objectToPaste,
    availableCalendars,
    parent,
    initialIndexRef = 0,
    doResetData = true
  ) => {
    try {
      launchBatchUpdate(
        objectToPaste,
        availableCalendars,
        parent,
        initialIndexRef,
        doResetData
      );
    } catch (e) {
      launchMessage(false)(t, [], 'error');
    }
  };

  gantt.handlerCtrlV = () => {
    gantt.handlerCtrlV1();
    trackEventBasicProps('activities_pasting');
  };

  gantt.handlerCtrlV1 = () => {
    if (gantt.config.readonly) return;
    gantt.isPasting = true;
    setAutoschedulingVisual(true);
    setTimeout(() => {
      if (gantt.tasksToCopy) {
        const selectedTasksDHTMLX = gantt.getSelectedTasks();
        /** Validation */
        if (selectedTasksDHTMLX && selectedTasksDHTMLX.length === 1) {
          const selectedTasksProplanner = JSON.parse(gantt.tasksToCopy);
          /** Validation */
          if (
            selectedTasksProplanner &&
            selectedTasksProplanner.selectedTasks
          ) {
            if (!selectedTasksProplanner.selectedTasks.length) return;
            message.loading(t('multiselect_copy_paste.pasting_msj'), 1);
            const toCopyActivityId = selectedTasksDHTMLX[0];
            const toCopyActivity = gantt.getTask(toCopyActivityId);
            /** Validation */
            if (toCopyActivity) {
              const availableCalendars = gantt.getCalendars();
              /** If activity has child, then copied activities will be children */
              if (gantt.hasChild(toCopyActivityId)) {
                pasteActivities(
                  selectedTasksProplanner,
                  availableCalendars,
                  toCopyActivityId
                );
              } else {
                const parentRef = gantt.getTask(toCopyActivity.parent);
                const parentIndex = parentRef && parentRef.$index;
                const toPasteIndex = toCopyActivity.$index;
                const indexRef = toPasteIndex - parentIndex;
                if (indexRef < 0) return;
                pasteActivities(
                  selectedTasksProplanner,
                  availableCalendars,
                  toCopyActivity.parent,
                  indexRef
                );
              }
            }
          } else {
            gantt.isPasting = false;
            setAutoschedulingVisual(false);
            notifyMessage({
              title: t('multiselect_copy_paste.title_no_data'),
              message: t('multiselect_copy_paste.content_no_data'),
              type: 'success'
            });
          }
        } else {
          gantt.isPasting = false;
          setAutoschedulingVisual(false);
        }
      } else {
        gantt.isPasting = false;
        setAutoschedulingVisual(false);
        launchMessage(false)(t, [], 'noData');
      }

      setAutoschedulingVisual(false);
    }, 300);
    // alberto
    setTimeout(() => {
      gantt.refreshDetectedLevelsInGantt &&
        gantt.refreshDetectedLevelsInGantt();
      updateColWidthWBS();
    }, 3000);
    updateAllCorrelatives();
  };

  /**
   * This function handle basic behaviour to can paste an activity without errors
   * @param {*} activity Activity object to paste
   * @param {*} parent Parent ID to assign
   * @param {*} availableCalendars Array with DHTMLX calendar format
   * @returns True if process finish without error
   */
  const pasteActivity = (
    activity,
    parent,
    availableCalendars,
    doResetData = true
  ) => {
    const currentSector = sector;
    const clonedObj = doResetData ? cloneDeep(activity) : activity;
    const doesExistCurrentCalendarId = availableCalendars.find(
      (calendar) => calendar.id == clonedObj.calendar_id
    );
    if (!doesExistCurrentCalendarId) {
      delete clonedObj.calendar_id;
      clonedObj.calendar_id = gantt.defaultCalendar;
    }
    if (doResetData) {
      clonedObj.parent = parent;
      /** calculate the new correlative id */
      const newIID = getNextUID(sector.id, lastUniqueCorrelativeIds, gantt);
      clonedObj.unique_correlative_id = newIID;

      clonedObj.sectorId = currentSector.id;
      clonedObj.constraint_date = gantt.date.parseDate(
        clonedObj.constraint_date,
        'xml_date'
      );
      clonedObj.constraint_type_backup = clonedObj.constraint_type;
      clonedObj.is_lookahead = false;
      clonedObj.should_correct_start_date = false;
      clonedObj.is_critical = 'No';
      delete clonedObj.proplannerId;
      delete clonedObj.id;
      delete clonedObj.baselineObject;
      delete clonedObj.mustApp;
      delete clonedObj.mustApplyVisibleChecked;
      clonedObj.ponderator = 0;
      clonedObj.progress = 0;
      clonedObj.checked = false;
      clonedObj.visibleChecked = false;
      clonedObj.tasks = [];
      clonedObj.baseline_points = [];
      clonedObj.activityModifications = [];
      clonedObj.expected_progress_base = 0;
      clonedObj.hhWorkTime = null;
      clonedObj.used_cost = 0;
      clonedObj.comesFromCopy = true;
    }

    validateSubs(clonedObj);
    validateResponsables(clonedObj);
    validateTags(clonedObj);
    gantt.createTask(clonedObj, parent);
    clonedObj.new_task = false;

    setTimeout(() => {
      // gantt.updateTask(clonedObj.id)
    }, 1000);

    return clonedObj;
  };

  /**
   * This function check, if copied sub, does exist in this context
   * @param {*} activity Activity to be checked
   */
  const validateSubs = (activity) => {
    const doesExist = subContracts.find(
      (subOption) => subOption.id === activity.subcontractId
    );
    if (!doesExist) {
      activity.subcontractId = null;
    }
  };

  /**
   * This function check if copied responsables, does exist in the context of the sector
   * @param {*} activity Activity to be checked
   */
  const validateResponsables = (activity) => {
    validateArrayOption(activity, 'responsables', toSelectResponsables, 'id');
  };

  /**
   * This function validate if copied tags, does exist in the context of the sector
   * @param {*} activity Activity to be checked
   */
  const validateTags = (activity) => {
    validateArrayOption(activity, 'tags', toSelectTags, 'id');
  };

  /**
   * This function validates that an attr from an activity, is available in current context (sector and project)
   * @param {*} activity Activity to check some attribute that is an array of options
   * @param {*} attr Name attribute which is a array of option, as tags, responsables, or future columns added
   * @param {*} optionsToSelect Array with available options for that attribute
   * @param {*} filterBy Attribute name which is going to be compared to check if there is exist the option assigned
   */
  const validateArrayOption = (activity, attr, optionsToSelect, filterBy) => {
    const finalResToAssign = [];
    activity &&
      activity[attr] &&
      activity[attr].forEach((toValidate) => {
        const doesExist = optionsToSelect.find(
          (option) => option[filterBy] === toValidate[filterBy]
        );
        if (doesExist) {
          finalResToAssign.push(toValidate);
        }
      });
    activity[attr] = finalResToAssign;
  };

  /**
   * This function check the new activities array attached to parent tasks, and deletes the activity which user did remove
   * @param {*} parentObject Parent object which has a deleted new activity
   * @param {*} elToRemove Element to be removed
   */
  const checkToRemoveFromArray = (parentObject, elToRemove) => {
    if (!parentObject || !elToRemove) return;
    parentObject.newActivitiesArray = parentObject.newActivitiesArray.filter(
      (el) => el !== elToRemove
    );
  };

  /**
   * This function gets an original array, and add all elements from other array(if it dont exist already)
   * @param {*} parentObject Parent where is going to be added the other array elements
   * @param {*} elementsToAdd Elements to be added to array param
   */
  const checkToAddToArray = (parentObject, elementsToAdd) => {
    elementsToAdd.forEach((toAdd) => {
      const doesExistAlready = parentObject.newActivitiesArray.find(
        (el) => el === toAdd
      );
      if (!doesExistAlready) parentObject.newActivitiesArray.push(toAdd);
    });
  };

  /**
   * This function goes through the parents when a task is added, or deleted, check if all tree still have the same behaviour about new activities created to check it on baseline save
   * @param {*} initialParent First parent affected due to an adding or deleting action
   * @param {*} isAddAction default true, which means a task was added, false when a deleting is performed
   * @param {*} deletedTask If isAddAction is false, then this param must be required to know which elements must be removed from new activities array on the whole tree
   */
  const checkRecursiveFlagParents = (
    initialParent,
    isAddAction = true,
    deletedTaskId = null
  ) => {
    if (!initialParent) return;
    const parentId = gantt.getParent(initialParent.id);
    if (parentId && gantt.isTaskExists(parentId)) {
      const parentObject = gantt.getTask(parentId);
      if (!parentObject.newActivitiesArray) {
        parentObject.newActivitiesArray = [];
      }
      isAddAction && !deletedTaskId
        ? checkToAddToArray(parentObject, initialParent.newActivitiesArray)
        : checkToRemoveFromArray(parentObject, deletedTaskId);
      parentObject.hasNewActivities = Boolean(
        parentObject.newActivitiesArray &&
          parentObject.newActivitiesArray.length
      );
      checkRecursiveFlagParents(parentObject, isAddAction, deletedTaskId);
    }
  };
  gantt.checkRecursiveFlagParents = checkRecursiveFlagParents;

  /** set initial status for lst unique id */
  gantt.lastUniqueCorrelativeIds = lastUniqueCorrelativeIds;

  const setParentAsASAPConstraint = (parent_id) => {
    const parent = gantt.getTask(parent_id);
    if (!parent) return;
    const { constraint_type } = parent;
    const typeAsap = 'asap';
    const isNotAsap = constraint_type !== typeAsap;
    if (isNotAsap) {
      parent.constraint_type = typeAsap;
      parent.constraint_date = null;
    }
  };

  gantt.createTaskWithChild = async (task, parent, parentProplannerId) => {
    window.to_use_react_gantt.creatingActivity = true;
    if (parentProplannerId) {
      try {
        let isIdAlreadyExist = false;
        const currentParent = gantt.getTask(parent);

        window.stackActivitiesMotherSave.forEach((activity) => {
          if (activity === parentProplannerId) {
            isIdAlreadyExist = true;
          }
        });

        if (isIdAlreadyExist && !currentParent?.is_lookahead) {
          gantt.createTaskWithChildAction(task, parent);
          return;
        }

        const tasksActivity =
          await activityService.getTasksActivity(parentProplannerId);

        if (tasksActivity.status !== 200) return;

        if (tasksActivity.tasks.length > 0) {
          gantt.showModalRemoveTaskLookahead(
            t,
            task,
            parent,
            parentProplannerId
          );
          return;
        }
      } catch (err) {
        console.log(
          `Err in activityService.getTasksActivity() in gantt.createTaskWithChild, ${err}`
        );
      }
    }

    gantt.createTaskWithChildAction(task, parent);
    criticalPathRefresh();
  };

  const parentsToUpdate = new Set();

  gantt.createTaskWithChildAction = (task, parent) => {
    const newIID = getNextUID(sector.id, lastUniqueCorrelativeIds, gantt);
    task.unique_correlative_id = newIID;

    const oldObject = gantt.lastUniqueCorrelativeIds;
    const findObjectIndex = oldObject.findIndex(
      (el) => parseInt(el.sectorId) === parseInt(sector.id)
    );

    if (findObjectIndex !== -1) {
      oldObject[findObjectIndex] = {
        sectorId: sector.id.toString(),
        lastUniqueCorrelativeId: parseInt(newIID)
      };
      dispatch(ganttActions.setLastUniqueCorrelativeIds(oldObject));
      gantt.lastUniqueCorrelativeIds = oldObject;
    }

    const currentParent = gantt.getTask(parent);
    if (currentParent) currentParent.is_lookahead = false;

    gantt.createTask(task, parent);

    trackingEvent(
      'activity_creation_from_button',
      {
        ...getBasicAmplitudEventProperties(),
        activity_id: task?.id,
        activity_index: task?.correlative_id
      },
      AMPLITUDE_SERVICE
    );

    task.isNewActivity = true;
    if (!gantt.isTaskExists(parent)) return '';
    const parentObject = gantt.getTask(parent);
    if (parentObject) {
      parentObject.hasNewActivities = true;
      if (!parentObject.newActivitiesArray) {
        parentObject.newActivitiesArray = [];
      }
      parentObject.newActivitiesArray.push(task.id);
      parentsToUpdate.add(parentObject);
    }
  };

  /**
   * This function fix wrong behaviour of milestones
   * @param {*} new_parent Parent which one is now a project, but before modification was a milestone
   */
  const adjustParentMilestone = (new_parent) => {
    if (!new_parent.duration) {
      new_parent.comes_from_indent = true;
      new_parent.is_milestone = false;
      // new_parent.constraint_type = 'asap'
      new_parent.real_constraint_type = new_parent.constraint_type;
      new_parent.type = 'task';
      runAndUnmountTimeout(() => {
        if (new_parent.comes_from_indent) {
          // new_parent.constraint_type = 'asap'
          new_parent.real_constraint_type = new_parent.constraint_type;
          new_parent.for_disable_milestone_duration = new_parent.duration;
          gantt.updateTask(new_parent.id);
          new_parent.comes_from_indent = false;
        }
      }, 200);
    }
  };

  /** This actions are functions to allow user use tab to make child a task */
  const actions = {
    indent: function indent(task_id) {
      let prev_id = gantt.getPrevSibling(task_id);
      let shouldKeepWhiling = true;
      while (shouldKeepWhiling) {
        const prev = gantt.getPrevSibling(prev_id);
        if (!prev) break;
        const t = gantt.getTask(prev_id);
        const isSelectedPrevSibling =
          t.visibleChecked || t.checked || t.mustApplyVisibleChecked; // Legacy: gantt.isSelectedTask(prev_id)
        if (isSelectedPrevSibling) {
          prev_id = prev;
          shouldKeepWhiling = true;
        } else {
          shouldKeepWhiling = false;
        }
      }

      if (!gantt.isTaskExists(prev_id)) return;

      if (prev_id) {
        const new_parent = gantt.getTask(prev_id);
        const moveTask = gantt.moveTask(
          task_id,
          gantt.getChildren(new_parent.id).length,
          new_parent.id
        );
        if (moveTask !== false) setParentAsASAPConstraint(prev_id);
        new_parent.type = gantt.config.types.project;
        new_parent.$open = true;
        gantt.updateTask(task_id);
        /**
         * Fix for wierd behaviour when indenting a task inside other
         */
        adjustParentMilestone(new_parent);
        gantt.updateTask(new_parent.id);
        return task_id;
      }
      return null;
    },
    outdent: function outdent(task_id, initialIndexes, initialSiblings) {
      if (!gantt.isTaskExists(task_id)) return;
      const cur_task = gantt.getTask(task_id);
      const old_parent = cur_task.parent;
      if (
        gantt.isTaskExists(old_parent) &&
        old_parent != gantt.config.root_id
      ) {
        let index = gantt.getTaskIndex(old_parent) + 1;
        const prevSibling = initialSiblings[task_id].first;

        if (!gantt.isTaskExists(prevSibling)) return;
        const t = gantt.getTask(prevSibling);
        const isSelectedTask =
          t.visibleChecked || t.checked || t.mustApplyVisibleChecked; // Legacy: gantt.isSelectedTask(prevSibling)
        if (isSelectedTask) {
          index += initialIndexes[task_id] - initialIndexes[prevSibling];
        }
        gantt.moveTask(task_id, index, gantt.getParent(cur_task.parent));
        if (!gantt.hasChild(old_parent)) {
          gantt.getTask(old_parent).type = gantt.config.types.task;
        }
        gantt.updateTask(task_id);
        gantt.updateTask(old_parent);
        return task_id;
      }
      return null;
    },
    del: function (task_id) {
      if (gantt.isTaskExists(task_id)) gantt.deleteTask(task_id);
      return task_id;
    }
  };

  const cascadeAction = {
    indent: true,
    outdent: true,
    del: true
  };

  gantt.performAction = function (actionName) {
    const action = actions[actionName];
    if (!action) {
      return;
    }

    gantt.batchUpdate(() => {
      // need to preserve order of items on indent/outdent,
      // remember order before changing anything:
      const indexes = {};
      const siblings = {};
      const IdToCorrelativeHash = {};
      const selectedThroughProPlannerAPI = gantt
        ?.serialize()
        ?.data?.filter(
          (t) => t.visibleChecked || t.checked || t.mustApplyVisibleChecked
        );
      selectedThroughProPlannerAPI &&
        selectedThroughProPlannerAPI.forEach(({ id }) => {
          gantt.ext.undo.saveState(id, 'task');
          const correlativeId = gantt?.getTask(id)?.correlative_id;
          IdToCorrelativeHash[id] = correlativeId;
          indexes[id] = gantt.getTaskIndex(id);
          siblings[id] = {
            first: null
          };

          let currentId = id;
          let shouldKeepWhiling = true;
          while (shouldKeepWhiling) {
            const currentSibling = gantt.getPrevSibling(currentId);
            const doesExistSibling = gantt.isTaskExists(currentSibling);
            const doesSelectedSibling = gantt.isSelectedTask(currentSibling);
            /** if sibling is selected and exist we change currentID to go to the next iteration with while */
            if (doesSelectedSibling && doesExistSibling) {
              currentId = gantt.getPrevSibling(currentId);
              shouldKeepWhiling = true;
            } else {
              shouldKeepWhiling = false;
            }
          }
          siblings[id].first = currentId;
        });
      const updated = {};
      selectedThroughProPlannerAPI &&
        selectedThroughProPlannerAPI.forEach(({ id }) => {
          if (!gantt.isTaskExists(id)) return;
          const t = gantt.getTask(id);
          if (!t) return;
          if (cascadeAction[actionName]) {
            if (!updated[gantt.getParent(id)]) {
              const updated_id = action(id, indexes, siblings);

              updated[updated_id] = true;
            } else {
              updated[id] = true;
            }
          } else {
            action(id, indexes);
          }
        });
    });
  };

  events.push(
    gantt.attachEvent('onBeforeTaskMove', (id, parent, tindex) => {
      gantt.iscritical_init = false;
      let parentObject;
      if (!gantt.isTaskExists(id)) return;
      if (parent && parent != '0') {
        parentObject = gantt.getTask(parent);
      }
      if (parent == 0) {
        return false;
      } else if (parentObject?.is_lookahead) {
        return false;
      }
      return true;
    })
  );

  events.push(
    gantt.attachEvent('onBeforeUndo', (action) => {
      // your code here
      if (!action) return false;
      window.loader.show();
      gantt.ext.undo.undoDisabled = false;
      gantt.ext.undo.undoRedoDisabled = false;
      gantt.ext.undo.notDeleteUndoStack = true;
      gantt.isUndo = true;
      return true;
    })
  );

  events.push(
    gantt.attachEvent('onBeforeRedo', (action) => {
      if (!action) return false;
      window.loader.show();
      gantt.ext.undo.undoDisabled = false;
      gantt.ext.undo.undoRedoDisabled = false;
      gantt.ext.undo.redoDisabledJoin = true;
      gantt.ext.undo.notDeleteUndoStack = true;
      // your code here
      gantt.isRedo = true;
      return true;
    })
  );

  events.push(
    gantt.attachEvent('onAfterRedo', (action) => {
      // your code here
      gantt.isRedo = false;
      return true;
    })
  );

  /** This events trigger each time per task so we can here define the 2 arguments that will help to draw the baseline */
  events.push(
    gantt.attachEvent('onTaskLoading', initial_task_load_flow(gantt))
  );

  /** Event ejecute to editing links */
  // events.push(gantt.attachEvent('onLinkDblClick', link_click_job(gantt)))

  events.push(
    gantt.attachEvent('onLinkClick', (id, e) => {
      if (e && e.detail && e.detail === 2) {
        link_click_job(gantt)(id, e);
      }
    })
  );

  gantt.link_click_job = link_click_job;

  /**
   * This function check progress recursive, based on weigth and update cost and HH
   * @param {*} id Of the activity to start the progress calculation
   */
  gantt.update_progress = (id) => {
    const task = gantt.getTask(id);
    editing_flow(gantt)(id, task, updatePonderators, updateCostAndHH);
    updateExpectedGlobal();
    criticalPathRefresh();
  };

  const updatePonderators = (item) => {
    if (item.parent) {
      calculatePonderators(item.parent, gantt, projectState);
    } else {
      item.ponderator = 0;
    }
  };
  gantt.updatePonderators = updatePonderators;
  gantt.getParentLinkedLinks = getParentLinkedLinks;

  events.push(
    gantt.attachEvent('onAfterTaskAdd', (id, item) => {
      const currentSector = sector;
      if (!item.comesFromCopy && !item.comesFromUndo) {
        if (
          gantt.getTaskByTime().length != 0 &&
          gantt.getTaskByTime().length == 1
        ) {
          gantt.getGridColumn('buttons').label = 'Actions';
          item.text = 'New Master Plan';
        } else {
          item.text = 'New Activity';
        }
        item.hhWorkTime = 0;
        item.cost = 0;
        item.used_cost = 0;
        item.should_be_showed = true;
        item.calendar_id = gantt.defaultCalendar;
        item.activityModifications = [];
        item.responsables = [];
        item.tags = [];
        item.subcontractId = null;
        item.real_constraint_type = item.constraint_type;
        item.start_date.setHours(0);
        item.start_date.setMinutes(0);
        item.duration = parseInt(currentSector.hoursPerDay);
        item.new_task = true;
        item.start_date_backup = item.start_date.toString();
        item.type = 'task';
        item.description = '';
        item.correlative_id = '';
      }
      if (['asap', 'alap'].includes(item.constraint_type))
        item.constraint_date = null;

      item.value_start_add = { ...item };
      const setter = getColorSetter(gantt.visualizationColorActive);
      setter && setter(item);
      if (!gantt.isPasting) {
        gantt.updateTask(item.id);
        updateCostAndHH(item, true);
      }
      debouncedActivitiesCalculation();
    })
  );

  const debouncedActivitiesCalculation = debounce(() => {
    if (!gantt.isPasting) {
      updateAllCorrelatives({ avoidRender: true });
      gantt.updateExpectedGlobal({ avoidRender: true });
      gantt.refreshDetectedLevelsInGantt &&
        gantt.refreshDetectedLevelsInGantt();
      updateColWidthWBS();
      window.to_use_react_gantt.creatingActivity = false;

      gantt.batchUpdate(function () {
        parentsToUpdate.forEach((parentObject) => {
          if (
            !parentObject.duration ||
            parentObject.is_milestone ||
            parentObject.old_type === 'milestone'
          ) {
            adjustParentMilestone(parentObject);
          }
          setParentAsASAPConstraint(parentObject.id);
          gantt.updateTask(parentObject.id);
        });

        parentsToUpdate.clear();
      }, true);

      gantt.refreshData();
      gantt.$layout.resize();
    }
  }, 2000);

  const updateAllCorrelatives = ({ avoidRender = false } = {}) => {
    gantt.batchUpdate(() => {
      const tasks = gantt.serialize().data;
      tasks.forEach((task, index) => {
        if (!gantt.isTaskExists(task.id)) return;
        const t = gantt.getTask(task.id);
        t.correlative_id = index;
      });
    }, avoidRender);
  };

  gantt.trashTask = (id) => {
    const scrollState = JSON.parse(JSON.stringify(gantt.getScrollState()));
    const tempModal = gantt.confirm({
      title: `
                <div class="link-gantt-new-title">
                    <span></span>
                    <span class="title-fit-modal-link"></span>
                    <span>
                        <img onclick="window.to_use_react_gantt.modalbox.hide(window.currentOpenModalDelete)" class="fit-right-img-link" width="14" src="${closeIcon}" />
                    </span>
                </div>
            `,
      addClassName: 'gantt-deleted-modal',
      text: `
            <div class="middle-info-trash">
                <div>
                    <img class="arrow-link-description" width="48" height="48" src="${trashIcon}" />
                </div>
                <div class="middle-info-description" style="color: #FFFFFF; font-size: 16px">
                    ${t('master_plan.delete_activity_alert')}
                </div>
            </div>
            `,
      ok: t('lookahead.delete'),
      cancel: t('cancel'),
      callback: function (res) {
        if (res) {
          if (!gantt.isTaskExists(id)) return;
          const isParentTask = gantt.hasChild(id);
          const getTask = gantt.getTask(id);
          if (getTask.correlative_id === 0) {
            openNotification(
              {
                title: 'Warning',
                description:
                  'It is not possible to delete the project summary activity of your schedule',
                type: 'warning'
              },
              2
            );
            return;
          }
          const isNewActivityToLaunchTreeCheck = getTask.isNewActivity;
          const parentId = gantt.getParent(id);
          const prev = gantt.getTaskBy(
            'correlative_id',
            getTask.correlative_id - 1
          );
          const next = gantt.getTaskBy(
            'correlative_id',
            getTask.correlative_id + 1
          );
          gantt.isDeletion = true;

          trackingEvent(
            'activity_delete',
            {
              ...getBasicAmplitudEventProperties(),
              activity_name: getTask.text,
              activity_id: getTask?.id,
              activity_index: getTask?.correlative_id
            },
            AMPLITUDE_SERVICE
          );

          const selectedActivitiesToDelete = gantt.checkArrayMassive();
          const filteredActivities = selectedActivitiesToDelete.filter(
            (activity) =>
              !gantt.isChildOf(activity.id, id) && activity.id !== id
          );
          dispatch(ganttActions.setSelectedActivities(filteredActivities));

          if (prev[0]) {
            if (!gantt.isTaskExists(prev[0].id)) return;
            gantt.deleteTask(id);
          } else if (next[0]) {
            if (!gantt.isTaskExists(next[0].id)) return;
            gantt.deleteTask(id);
          } else {
            gantt.deleteTask(id);
          }

          const parentObject = gantt.getTask(parentId);

          if (parentObject) {
            if (
              parentObject.type === 'task' &&
              parentObject.tasks?.length > 0
            ) {
              parentObject.is_lookahead = true;
            }
          }

          if (
            isNewActivityToLaunchTreeCheck &&
            parentId &&
            gantt.isTaskExists(parentId)
          ) {
            checkToRemoveFromArray(parentObject, id);
            parentObject.hasNewActivities = Boolean(
              parentObject.newActivitiesArray &&
                parentObject.newActivitiesArray.length
            );
            if (!parentObject.newActivitiesToDelete) {
              parentObject.newActivitiesToDelete = [];
            }
            parentObject.newActivitiesToDelete.push(id);
          }
          setTimeout(() => {
            // gantt.scrollTo(scrollState.x, scrollState.y)
          }, 500);
        }
      }
    });
    window.currentOpenModalDelete = tempModal;
  };

  // agregar
  events.push(
    gantt.attachEvent('onRowDragEnd', (id, target) => {
      trackEventBasicProps('activity_reorder');
    })
  );

  events.push(
    gantt.attachEvent('onBeforeRowDragEnd', (id, target) => {
      if (gantt.isDataFiltered || gantt.isDataOrdered) {
        const message =
          t('lang') === 'es'
            ? 'Movimiento no permitido: Hay filtros activos.'
            : 'Movement not allowed: there are active filters.';
        send_warning_message(gantt)(message);
        return false;
      }

      if (gantt.getGlobalTaskIndex(id) == 0) {
        return false;
      }

      gantt.updateTask(id);

      updateAllCorrelatives();

      return true;
    })
  );

  /** This for new task created at PP */
  events.push(
    gantt.attachEvent('onBeforeTaskAdd', (id, item) => {
      gantt.iscritical_init = false;
      // any custom logic here
      const task = item;
      const parent = gantt.getTask(item.parent);
      if (task.type === 'project') task.$open = true;
      task.old_type = task.type;
      task.old_duration = task.duration;
      if (task.comesFromCopy)
        task.constraint_type = task.constraint_type_backup;
      task.on_open_lightbox_type = task.type;
      task.for_disable_milestone_duration = task.duration;
      parent.old_type = parent.type;
      parent.type = 'project';
      return true;
    })
  );

  events.push(
    gantt.attachEvent('onAfterLinkDelete', (id, item) => {
      if (deleteCallback) deleteCallback(item, 'link');
      trackEventBasicProps('link_deletion');
      if (gantt.suspense_delete_update) {
        criticalPathRefresh();
      }
      gantt.autoSchedule();
    })
  );

  events.push(
    gantt.attachEvent('onAfterTaskDelete', (id, item) => {
      updateCostAndHH(item);
      updateAllCorrelatives();
      const childsFromParent = gantt.getChildren(item.parent);
      if (childsFromParent.length) {
        if (!gantt.isTaskExists(childsFromParent[0])) return;
        const childTask = gantt.getTask(childsFromParent[0]);
        gantt.updateTask(childTask.id);
      }

      if (gantt.getTaskByTime().length == 0) {
        gantt.getGridColumn('buttons').label =
          '<div style="font-size: 26px; color: #333333;font-weight: 400; cursor: pointer" onclick="window.to_use_react_gantt.createTask()"> + </div>';
      }
      setTimeout(() => {
        gantt.refreshDetectedLevelsInGantt &&
          gantt.refreshDetectedLevelsInGantt();
        updateColWidthWBS();
      }, 500);
      if (gantt.suspense_delete_update) {
        criticalPathRefresh();
      }
      gantt.autoSchedule();
    })
  );

  //

  events.push(
    gantt.attachEvent('onBeforeTaskDelete', (id, item) => {
      if (gantt.isDataFiltered || gantt.isDataOrdered) {
        const message =
          t('lang') === 'es'
            ? 'Movimiento no permitido: Hay filtros activos.'
            : 'Movement not allowed: there are active filters.';
        send_warning_message(gantt)(message);
        return false;
      }

      if (detectChildAndLinks) detectChildAndLinks(item);
      return true;
    })
  );

  gantt.submittal_icon_task_layer = submittal_icon_task_layer;

  gantt.iconSubmittalsArray = [];

  events.push(
    gantt.attachEvent('onGanttReady', () => {
      /** This methods renders any time a task is movement, it will add baseline */
      /** We add graphically painting of slack at gantt chart */
      const { ganttState } = store.getState();
      const customView = ganttState.views;
      const customViewEmpty = Object.keys(customView).length === 0;

      if (gantt.config.show_slack) {
        gantt.idTaskLayerSlack = gantt.addTaskLayer(
          adding_slack_to_task_layer(gantt)
        );
      }

      if (customViewEmpty) {
        if (gantt.config.show_baseline) {
          gantt.idTaskLayerBaseline = gantt.addTaskLayer(
            baseline_task_layer(gantt)
          );
          gantt.templates.rightside_text = right_side_gantt(gantt, true);
        } else {
          gantt.templates.rightside_text = right_side_gantt(gantt, false);
        }

        if (gantt.config.show_todayline) {
          todayLineMarker = gantt.addMarker(todayLine);
        } else {
          gantt.deleteMarker(todayLineMarker);
        }
      }

      if (gantt.config.show_submittal_icon) {
        gantt.idTaskLayerSubmittal = gantt.addTaskLayer(
          submittal_icon_task_layer(gantt)
        );
        gantt.iconSubmittalsArray.push(gantt.idTaskLayerSubmittal);
      }

      /** reorder columns event */
      const grid = gantt.$ui.getView('grid');

      grid.attachEvent('onBeforeColumnDragStart', (column, index) => {
        if (column.draggedColumn.name === columnNameWBS) {
          return false;
        }
        return true;
      });

      grid.attachEvent(
        'onColumnDragMove',
        ({ dragColumn, targetColumn, dragIndex, targetIndex }) => {
          if (
            (targetColumn && targetColumn.name === columnNameWBS) ||
            !targetColumn
          ) {
            return false;
          }
          return true; // return false will prevent reordering to this position
        }
      );

      grid.attachEvent('onAfterColumnReorder', (reorderData) => {
        if (!reorderData) return;
        const { draggedIndex, targetIndex } = reorderData;

        const { ganttState } = store.getState();
        const { userPreferenceTableGantt } = ganttState;
        const { gridTable } = userPreferenceTableGantt;
        /** Move an array element from one array position to another */
        const element = gridTable[draggedIndex];
        const newGridTable = gridTable
          .toSpliced(draggedIndex, 1)
          .toSpliced(targetIndex, 0, element);

        /** update redux */
        dispatch(
          ganttActions.setUserPreferenceTableGantt({
            table: newGridTable,
            ganttChart: null
          })
        );
        updateDefaultView();
        return true;
      });

      setTimeout(() => {
        const task = gantt.getTaskByIndex(1);
        updateCostAndHH(task);
      }, 600);
    })
  );

  events.push(
    gantt.attachEvent('onTaskDrag', (id, mode, task, original) => {
      onTaskDrag(id, mode, task, original);
    })
  );

  gantt.config.fit_tasks = true;

  /** This events trigger each time per task so we can here define the 2 arguments that will help to draw the baseline */
  events.push(
    gantt.attachEvent('onTaskLoading', initial_task_load_flow(gantt))
  );

  const updateCostAndHH = (activity, avoidRender = false) => {
    gantt.batchUpdate(() => {
      try {
        if (activity?.parent && activity?.parent != '0') {
          if (!gantt.isTaskExists(activity.parent)) return;
          const parent = gantt.getTask(activity.parent);
          const childs = gantt.getChildren(parent.id);
          let hhWorkTime = 0;
          let cost = 0;
          let usedCost = 0;
          let realWork = 0;
          if (childs.length) {
            childs.forEach((id) => {
              if (!gantt.isTaskExists(id)) return;
              const task = gantt.getTask(id);
              cost += parseFloat(task.cost) || 0;
              usedCost += parseFloat(task.used_cost) || 0;
              hhWorkTime += task.hhWorkTime ? parseFloat(task.hhWorkTime) : 0;
              const aux = task.real_work
                ? parseFloat(task.real_work)
                : task.hhWorkTime
                  ? (parseFloat(task.hhWorkTime) * task.progress) / 100
                  : 0;
              realWork += aux;
            });
          }
          parent.cost = cost;
          parent.used_cost = usedCost;
          parent.hhWorkTime = hhWorkTime;
          parent.real_work = realWork;
          /** make the function recursive */
          updateCostAndHH(parent, avoidRender);
        } else {
          if (gantt.Sentry) {
            gantt.Sentry.captureException(
              'Cant calculate updateCostAndHH due to null activity',
              'warning'
            );
          }
        }
        gantt.optimizedRender();
      } catch (e) {
        console.log(e);
        console.log('Error');
      }
    }, avoidRender);
  };
  gantt.updateCostAndHH = updateCostAndHH;

  events.push(
    gantt.attachEvent('onAfterLinkAdd', (_, item) => {
      if (gantt.isPasting) return;
      const shouldAbortLink = checkCircularLinks(item, gantt, modalCircular);
      if (shouldAbortLink) {
        gantt.deleteLink(item.id);
        return false;
      }
      const allTasks = [item.target, item.source];
      checkUpdatedElementsToCalculateTimingPast(allTasks, gantt);
      gantt.added_link = true;
      gantt.countAutoSchedule = [];
      trackEventBasicProps('link_creation');

      if (
        gantt.auto_scheduling_links &&
        (item.type === '0' || item.type === '2')
      ) {
        setTimeout(() => {
          gantt.ext.undo.ignoreAutoSchedule = false;
          gantt.autoSchedule();
        }, 200);
      }
      setTimeout(() => {
        gantt.creatingLink = false;
      }, 1000);

      trackingEvent(
        'link_creation',
        {
          ...getBasicAmplitudEventProperties()
        },
        AMPLITUDE_SERVICE
      );

      gantt.resetLinksStateWithTimeout && gantt.resetLinksStateWithTimeout();
    })
  );

  /**
   * This function receives a date, and check if it goes longer than 4 years forward and backward
   * @param {*} date Date to be checked if is inside this time period
   * @returns True if this date is valid for project period, false if this date is out of the range
   */
  const checkLongTaskDate = (date) => {
    const momentDate = moment(date);
    const masterTask = gantt.getTaskByIndex(0);
    if (!masterTask) return;
    const endProject = masterTask?.end_date;
    const startProject = masterTask?.start_date;
    if (!endProject || !startProject) return;
    const forwardEnd = moment(endProject).add(20, 'years');
    const backwardStart = moment(startProject).subtract(20, 'years');
    if (!forwardEnd || !backwardStart) return;
    if (momentDate.isBefore(backwardStart) || momentDate.isAfter(forwardEnd))
      return;
    return true;
  };
  gantt.checkLongTaskDate = checkLongTaskDate;

  /**
    This function sends a notification for a constraint alert.
        @param {string} constraint_date - The date of the constraint.
        @param {string} constraint_type - The type of the constraint.
        @returns {boolean} Returns the required notification and false.
    */
  const notificationConstraint = (
    constraintDate,
    constraintType,
    id,
    multipleDrag = false
  ) => {
    if (localStorage.getItem('constraintValidation') === null) {
      window.showConstraintValidationModal(
        { constraintDate, constraintType, id },
        multipleDrag
      );
    }
  };

  const CONSTRAINT_TYPES = {
    MFO: 'mfo',
    MSO: 'mso',
    FNLT: 'fnlt',
    FNET: 'fnet',
    SNET: 'snet',
    SNLT: 'snlt'
  };

  gantt.notificationConstraint = notificationConstraint;

  gantt.validateFormatDay = (inputString) => {
    const dateFormat = gantt.currentDateFormat;
    const regex = regexDateFormat[dateFormat];
    if (regex && !regex.test(inputString)) {
      notifyMessage({
        title: t('format_error_title'),
        message: t('format_error_content'),
        type: 'warning'
      });
      return false;
    }
    return true;
  };

  events.push(
    gantt.ext.inlineEditors.attachEvent('onBeforeSave', (state) => {
      let { id, columnName, oldValue, newValue } = state;

      const datesInputs = ['start_date', 'end_date', 'constraint_date'];
      if (datesInputs.includes(columnName)) {
        if (
          gantt.validateFormatDay &&
          !gantt.validateFormatDay(gantt.stringDateInput)
        ) {
          return false;
        }
      }

      const fieldsToValidateDate = ['start_date', 'end_date'];
      if (
        fieldsToValidateDate.includes(columnName) &&
        !checkLongTaskDate(newValue)
      )
        return false;
      if (!gantt.isTaskExists(id)) return;

      /** set initial lenth of undo stack */
      gantt.allLinksBefore = gantt.getLinks();

      after_save_filter_custom_predecessor(gantt)(state, modalCircular);
      after_save_filter_custom_sucessor(gantt)(state, modalCircular);

      const task = gantt.getTask(id);
      gantt.lastUpdatedColumn = columnName;
      /** Avoid inputs to autoschedule */
      if (
        columnName == 'hhWorkTime' ||
        columnName == 'progress' ||
        columnName == 'text' ||
        columnName == 'cost' ||
        columnName == 'used_cost'
      ) {
        gantt.avoidAutoschedule = true;
      }

      const eventMappings = {
        text: 'activity_name_entry',
        description: 'activity_description_entry',
        hhWorkTime: 'activity_labor_hours_budgeted_entry',
        cost: 'activity_cost_budgeted_entry',
        used_cost: 'activity_cost_spent_entry'
      };

      if (eventMappings[columnName] !== undefined) {
        const eventName = eventMappings[columnName];
        trackingEvent(
          eventName,
          {
            ...getBasicAmplitudEventProperties(),
            activity_name: task?.text,
            activity_id: task?.id,
            activity_index: task?.correlative_id
          },
          AMPLITUDE_SERVICE
        );
      }

      if (columnName == 'calendar_id') {
        /** We set this value to false, because is the user which assign this calendar */
        if (newValue == '') {
          return false;
        }
        if (task.calendar_id == newValue) {
          return false;
        }
        task.assignedDefaultCalendar = false;
        task.calendar_id = newValue;
        trackingEvent(
          'activity_calendar_assignment',
          {
            ...getBasicAmplitudEventProperties(),
            activity_name: task?.text,
            activity_id: task?.id,
            activity_index: task?.correlative_id
          },
          AMPLITUDE_SERVICE
        );
      } else if (columnName == 'progress') {
        const n = parseInt(state.newValue);
        if (n > 100 || n < 0) {
          return false;
        } else if (n == 0) {
          task.auto_scheduling = true;
        } else if (n > 0) {
          task.auto_scheduling = false;
          trackingEvent(
            'activity_progress_entry',
            {
              ...getBasicAmplitudEventProperties(),
              activity_name: task?.text,
              activity_id: task?.id,
              activity_index: task?.correlative_id
            },
            AMPLITUDE_SERVICE
          );
        }
      } else if (columnName == 'custom_predecessors') {
        const inputElement = document.getElementsByName(state.columnName);
        if (inputElement.length) {
          const element = inputElement[0];
          element.autocomplete = 'off';
          element.value = task.custom_predecessors;
        }
        task.old_custom_predecessor = task.custom_predecessors;
      } else if (columnName == 'duration') {
        const calendar = gantt.getCalendar(task.calendar_id);
        const new_date_end = calendar.calculateEndDate(
          task.start_date,
          newValue
        );
        if ([CONSTRAINT_TYPES.MFO].includes(task.constraint_type)) {
          return gantt.notificationConstraint(
            task.constraint_date,
            task.constraint_type,
            false
          );
        }
        if (
          [CONSTRAINT_TYPES.FNLT].includes(task.constraint_type) &&
          task.constraint_date < new_date_end
        ) {
          return gantt.notificationConstraint(
            task.constraint_date,
            task.constraint_type,
            false
          );
        }
        if (
          [CONSTRAINT_TYPES.FNET].includes(task.constraint_type) &&
          task.constraint_date > new_date_end
        ) {
          return gantt.notificationConstraint(
            task.constraint_date,
            task.constraint_type,
            false
          );
        }
        if (!newValue) {
          newValue = 0;
          task.is_milestone = true;
        } else {
          task.is_milestone = false;
        }

        if (oldValue == 0 && newValue > 0) {
          task.type = 'task';
        } else if (oldValue === newValue) {
          return false;
        } else {
          task.bug_duration_from_resize_drag = true;
          task.inline_duration_value = newValue;
          trackingEvent(
            'activity_duration_entry',
            {
              ...getBasicAmplitudEventProperties(),
              activity_name: task?.text,
              activity_id: task?.id,
              activity_index: task?.correlative_id
            },
            AMPLITUDE_SERVICE
          );
        }
        gantt.resetLinksStateWithTimeout && gantt.resetLinksStateWithTimeout();
      } else if (columnName == 'constraint_type') {
        const constraint_type_old = task.constraint_type;
        const constraint_date_old = task.constraint_date;
        task.constraint_type = newValue;
        /** This flags allow after update task to correct with correct constraint type */
        /** This flag saves real constraint type */
        task.real_constraint_type = newValue;

        /** variables used for cases of constraint types */
        task.constraint_type_old = constraint_type_old;
        task.constraint_date_old = constraint_date_old;

        gantt.updateTask(task.id);
        gantt.constraint_bug = true;
        gantt.resetLinksStateWithTimeout && gantt.resetLinksStateWithTimeout();
      } else if (columnName == 'constraint_date') {
        const calendar = gantt.getCalendar(task.calendar_id);

        // Funcion mal utilizada deberia ser {date, unit: 'day'}
        if (
          !calendar.isWorkTime({
            date: gantt.date.parseDate(newValue, 'xml_date'),
            unit: 'day'
          })
        ) {
          return false;
        }
        task.constraint_type_last = task.constraint_type;

        if (
          ['asap', 'alap'].includes(task.constraint_type) ||
          !task.constraint_type
        ) {
          task.constraint_type = 'snet';
        }

        task.last_constraint_date = task.constraint_date;
        const clone_constraint_date = calendar.getClosestWorkTime({
          dir: 'fut', // fut past
          date: newValue,
          unit: gantt.config.duration_unit
        });
        task.constraint_date = clone_constraint_date;
        task.real_constraint_type = task.constraint_type;
        task.constraint_type_old = task.constraint_type;

        gantt.constraint_bug = true;
        gantt.resetLinksStateWithTimeout && gantt.resetLinksStateWithTimeout();
      } else if (columnName == 'hhWorkTime') {
        task.hhWorkTime = newValue;
      } else if (columnName == 'start_date') {
        const dateFormat = gantt.currentDateFormat;
        const calendar = gantt.getCalendar(task.calendar_id);
        const new_value_hours = calendar.getClosestWorkTime({
          dir: 'fut',
          date: newValue,
          unit: gantt.config.duration_unit
        });
        window.gantt.lastMoveTaskData = [
          {
            id: task.id,
            data: cloneDeep(task)
          }
        ];
        const clone_new_value =
          dateFormat.split(' ').length > 1 ? newValue : new_value_hours;
        if ([CONSTRAINT_TYPES.MSO].includes(task.constraint_type))
          gantt.notificationConstraint(
            task.constraint_date,
            task.constraint_type,
            task.id,
            false
          );
        if (
          [CONSTRAINT_TYPES.SNLT].includes(task.constraint_type) &&
          task.constraint_date < clone_new_value
        )
          gantt.notificationConstraint(
            task.constraint_date,
            task.constraint_type,
            task.id,
            false
          );
        if (
          [CONSTRAINT_TYPES.SNET].includes(task.constraint_type) &&
          task.constraint_date > clone_new_value
        )
          gantt.notificationConstraint(
            task.constraint_date,
            task.constraint_type,
            task.id,
            false
          );
        if ([CONSTRAINT_TYPES.MFO].includes(task.constraint_type))
          gantt.notificationConstraint(
            task.constraint_date,
            task.constraint_type,
            task.id,
            false
          );

        const new_date_end = calendar.calculateEndDate(
          clone_new_value,
          task.duration
        );
        if (
          [CONSTRAINT_TYPES.FNLT].includes(task.constraint_type) &&
          task.constraint_date < new_date_end
        )
          gantt.notificationConstraint(
            task.constraint_date,
            task.constraint_type,
            task.id,
            false
          );
        if (
          [CONSTRAINT_TYPES.FNET].includes(task.constraint_type) &&
          task.constraint_date > new_date_end
        )
          gantt.notificationConstraint(
            task.constraint_date,
            task.constraint_type,
            task.id,
            false
          );

        /** ctid#13 mp-252 */
        // if (task.duration_new && task.duration_new) {
        //    delete task.duration_new
        //    delete task.end_date_new
        // }

        if (
          task.start_date.toISOString().split('T')[0] ===
          clone_new_value.toISOString().split('T')[0]
        ) {
          return false;
        }
        const constraint_type_old = task.constraint_type;

        task.constraint_date = clone_new_value;
        task.constraint_type = 'snet';

        task.last_constraint_date = clone_new_value;
        task.real_constraint_type = 'snet';
        task.constraint_type_old = 'snet';

        task.start_date = clone_new_value;

        if (task.constraint_type != 'alap' && task.last_constraint != 'alap') {
          gantt.modifyLagCustom(task);
        }
        gantt.updateTask(task.id);
        trackingEvent(
          'activity_start_date_entry',
          {
            ...getBasicAmplitudEventProperties(),
            activity_name: task?.text,
            activity_id: task?.id,
            activity_index: task?.correlative_id
          },
          AMPLITUDE_SERVICE
        );

        /** ctid#01 */
        if (task.constraint_type === 'snet') {
          // checkDeleteLink(task, newValue)
        }

        gantt.resetLinksStateWithTimeout && gantt.resetLinksStateWithTimeout();
      } else if (columnName == 'end_date') {
        const dateFormat = gantt.currentDateFormat;
        const calendar = gantt.getCalendar(task.calendar_id);
        const new_value_hours = calendar.getClosestWorkTime({
          dir: 'past',
          date: new Date(newValue.getTime() + 24 * 60 * 60 * 1000),
          unit: gantt.config.duration_unit
        });

        const clone_new_value =
          dateFormat.split(' ').length > 1 ? newValue : new_value_hours;

        window.gantt.lastMoveTaskData = [
          {
            id: task.id,
            data: cloneDeep(task)
          }
        ];

        if (['mfo'].includes(task.constraint_type))
          gantt.notificationConstraint(
            task.constraint_date,
            task.constraint_type,
            task.id,
            false
          );
        if (
          ['fnlt'].includes(task.constraint_type) &&
          task.constraint_date < clone_new_value
        )
          gantt.notificationConstraint(
            task.constraint_date,
            task.constraint_type,
            task.id,
            false
          );
        if (
          ['fnet'].includes(task.constraint_type) &&
          task.constraint_date > clone_new_value
        )
          gantt.notificationConstraint(
            task.constraint_date,
            task.constraint_type,
            task.id,
            false
          );

        const dateStr = clone_new_value;

        if (Object.keys(calendarObject.customHour).length !== 0) {
          const hours = calendarObject.customHour.endHour.split(':')[0];
          const minutes = calendarObject.customHour.endHour.split(':')[1];
          dateStr.setHours(hours);
          dateStr.setMinutes(minutes);
        }

        const copyOfdateStr = cloneDeep(dateStr);

        if (
          task.end_date.toISOString().split('T')[0] ===
          dateStr.toISOString().split('T')[0]
        ) {
          return false;
        } else if (dateStr < task.date_origin) {
          return false;
        } else if (dateStr < task.start_date) {
          return false;
          // Funcion mal utilizada deberia ser {date, unit: 'day'}
        } else if (!calendar.isWorkTime({ date: copyOfdateStr, unit: 'day' })) {
          return false;
        }
        if (['asap', 'alap'].includes(task.constraint_type)) {
          task.constraint_date = task.start_date;
          task.constraint_type = 'snet';

          task.last_constraint_date = task.start_date;
          task.real_constraint_type = 'snet';
          task.constraint_type_old = 'snet';
        }
        const newDuration = calendar.calculateDuration(
          new Date(task.start_date),
          new Date(dateStr)
        );

        /** ctid#13 mp-252 */
        task.duration_new = newDuration;
        task.for_disable_milestone_duration = newDuration;
        task.end_date_new = dateStr;

        // gantt.updateTask(task.id)
        trackingEvent(
          'activity_end_date_entry',
          {
            ...getBasicAmplitudEventProperties(),
            activity_name: task?.text,
            activity_id: task?.id,
            activity_index: task?.correlative_id
          },
          AMPLITUDE_SERVICE
        );

        gantt.resetLinksStateWithTimeout && gantt.resetLinksStateWithTimeout();
      }
      if (
        columnName == 'end_date' ||
        columnName == 'start_date' ||
        columnName == 'duration' ||
        columnName == 'constraint_date' ||
        columnName == 'calendar_id'
      ) {
        setTimeout(() => {
          gantt.autoSchedule();
        }, 500);
      }
      // gantt.updateTask(task.id) // Undo redo freeze

      return true;
    })
  );

  /**
   * This funciont add 1 day, to a specified date
   * @param {*} dateToConvert
   * @param {*} days
   * @returns
   */
  const addDays = (dateToAdd, days) => {
    const date = new Date(dateToAdd);
    date.setDate(date.getDate() + days);
    return date;
  };

  events.push(
    gantt.ext.inlineEditors.attachEvent('onEditEnd', (state) => {
      if (!gantt.isTaskExists(state.id)) return;
      const task = gantt.getTask(state.id);
      if (
        state.columnName === 'start_date' &&
        task.real_constraint_type &&
        task.real_constraint_type === 'asap' &&
        moment(task.non_parsed_original_start_date).toString() ==
          task.start_date.toString().substring(0, 33)
      ) {
        // task.constraint_type = 'asap'
        gantt.updateTask(state.id);
      }
      if (state.columnName === 'progress') {
        const progress = Number(gantt.getTask(state.id).progress);

        if (progress === 100) {
          window.to_use_react_gantt.completeTask(state.id);
        }

        if (progress === 0) {
          window.to_use_react_gantt.uncompleteTask(state.id);
        }

        updateExpectedGlobal();
        executeAsyncAutoscheduleToAvoidFreeze();
        criticalPathRefresh();
      }
      if (task.real_constraint_type) {
        if (task.constraint_type != task.real_constraint_type) {
          task.constraint_type = task.real_constraint_type;
          gantt.optimizedRender();
        }
      }
      task.cost = parseFloat(task.cost);
      task.real_cost = parseFloat(task.real_cost);
      task.used_cost = parseFloat(task.used_cost);
      gantt.optimizedRender();
      gantt.is_task_moved = false;
      gantt.isEditingInline = false;
      const autoScheduleColumns = [
        'start_date',
        'end_date',
        'duration',
        'constraint_type',
        'calendar_id',
        'constraint_date'
      ];
      gantt.fixScaleDateHeader();
    })
  );

  let dateToStr = null;
  let strToDate = null;
  function initDateFunctions() {
    dateToStr = gantt.date.date_to_str(
      gantt.hashMoment2Date[gantt.currentDateFormat]
    );
    strToDate = gantt.date.str_to_date(
      gantt.hashMoment2Date[gantt.currentDateFormat]
    );
  }
  gantt.config.editor_types.date = {
    show: function (id, column, config, placeholder) {
      initDateFunctions();
      let minValue = null;
      let maxValue = null;

      if (typeof config.min === 'function') {
        minValue = config.min(id, column);
      } else {
        minValue = config.min;
      }

      if (typeof config.max === 'function') {
        maxValue = config.max(id, column);
      } else {
        maxValue = config.max;
      }

      const minAttr = minValue ? " min='" + dateToStr(minValue) + "' " : '';
      const maxAttr = maxValue ? " max='" + dateToStr(maxValue) + "' " : '';
      const html =
        "<div><input type='text' " +
        minAttr +
        maxAttr +
        " name='" +
        column.name +
        "' class='schedule-datepicker'></div>";
      placeholder.innerHTML = html;
      $('.schedule-datepicker').datepicker({
        format: {
          toDisplay: function (date, format, language) {
            /** check if date changed */
            const tempDate = gantt.currenDateSelected || new Date();
            const ifChangeDate = tempDate.getTime() !== date.getTime();
            if (!ifChangeDate) {
              return dateToStr(date);
            }
            if (
              !(
                gantt.currentDateFormat.includes('h') &&
                gantt.currentDateFormat.includes('m')
              )
            ) {
              if (date.getTimezoneOffset() <= 0) {
                date.setHours(0);
                date.setMinutes(0);
              } else {
                date.setMinutes(60);
                date.setHours(23);
              }
            } else {
              /** date format with hour. ex: DD/MM/YYYY hh:mm. fix bug datepicker ui  */
              let dateAddTimeZone = 1;
              if (date.getTimezoneOffset() <= 0) dateAddTimeZone = 0;
              const dateAdd = addDays(
                new Date(date.setHours(0, 0, 0, 0)),
                dateAddTimeZone
              );
              let fieldToModify;
              if (column.name === 'start_date') {
                fieldToModify = 'startHour';
              } else if (column.name === 'end_date') {
                fieldToModify = 'endHour';
              }
              if (Object.keys(calendarObject.customHour).length !== 0) {
                const hours =
                  calendarObject.customHour[fieldToModify]?.split(':')[0] ||
                  '0';
                const minutes =
                  calendarObject.customHour[fieldToModify]?.split(':')[1] ||
                  '0';
                dateAdd.setHours(hours);
                dateAdd.setMinutes(minutes);
              }
              date = dateAdd;
            }
            return dateToStr(date);
          },
          toValue: function (date, format, language) {
            const d = new Date(moment(date, gantt.currentDateFormat).toDate());

            /** set current date of date picker */
            gantt.currenDateSelected = d;
            return d;
          }
        },
        autoclose: true,
        todayBtn: 'linked',
        todayHighlight: true,
        weekStart: 1,
        keyboardNavigation: false
      });
    },
    hide: function () {
      $('.schedule-datepicker').datepicker('destroy');
    },
    set_value: function (value, id, column, node) {
      if (value && value.getFullYear) {
        node.querySelector('input').value = dateToStr(value);
      } else {
        node.querySelector('input').value = value;
      }
      $('.schedule-datepicker').datepicker('update');
      $('.schedule-datepicker').data('datepicker').show();
    },
    is_valid: function (value, id, column, node) {
      if (!value || isNaN(value.getTime())) {
        return false;
      }
      return true;
    },
    get_value: function (id, column, node) {
      let parsed;
      try {
        parsed = strToDate(node.querySelector('input').value || '');
      } catch (e) {
        parsed = null; // return null will cancel changes
      }

      return parsed;
    },
    is_changed: function (value, id, column, node) {
      let new_value = null;
      try {
        new_value = strToDate(node.querySelector('input').value);
        if (Number(new_value) != Number(value)) return true;
      } catch (error) {
        if (column.name === 'constraint_date' && !new_value) {
          setTimeout(() => {
            const task = gantt.getTask(id);
            task.constraint_type = 'asap';
            task.constraint_date = null;
            gantt.updateTask(id);
          }, 500);
        }
      }
    },
    focus: function (node) {
      console.log('focus');
    }
  };

  events.push(
    gantt.ext.inlineEditors.attachEvent('onEditStart', (state) => {
      on_open_filter_custom_predecessor(gantt)(state);
      on_open_filter_custom_sucessor(gantt)(state);
      const inputElement = document.getElementsByName(state.columnName);
      if (inputElement.length) {
        const inputDom = inputElement[0];
        const divParent = inputDom.parentNode;
        divParent.style.width = '100%';
        inputDom.autocomplete = 'off';
      }
      if (state.columnName == 'start_date') {
        gantt.is_task_moved = true;
      }
    })
  );

  const handleColumnClick = (activityId, columnName) => {
    NotificationSystemV2({
      key: `info-notif-${Date.now()}`,
      type: 'info',
      message: t('schedule_notification.calculated_column_value')
    });
    trackingEvent(
      'calculated_summary_activity_edit_attempt',
      {
        ...getBasicAmplitudEventProperties(),
        field_attempted: columnName,
        activity_id: activityId
      },
      AMPLITUDE_SERVICE
    );
  };

  gantt.handleColumnClick = handleColumnClick;

  events.push(
    gantt.ext.inlineEditors.attachEvent('onBeforeEditStart', (state) => {
      if (
        !(
          gantt.isTaskExists(state.id) &&
          document.activeElement.closest('.gantt-container') &&
          document.querySelector('#hovering-root:empty') &&
          !document.querySelector('.filter-lookahead-multi-check')
        ) &&
        !clickedOnActivity
      ) {
        return false;
      }
      if (
        gantt.hasChild(state.id) &&
        summaryColumnValue.includes(state.columnName)
      ) {
        NotificationSystemV2({
          key: `info-notif-${Date.now()}`,
          type: 'info',
          message: t('schedule_notification.summary_column_value')
        });
        trackingEvent(
          'summary_activity_edit_attempt',
          {
            ...getBasicAmplitudEventProperties(),
            field_attempted: state.columnName,
            activity_id: state.id
          },
          AMPLITUDE_SERVICE
        );
      }

      if (clickedOnActivity) return false;

      gantt.maxPerformance = true;
      /** This flag is to avoid autoscheduling in a infinite loop, so with this we can avoid it */
      gantt.copyBehaviourFromInline = true;
      const task = gantt.getTask(state.id);
      gantt.isEditingInline = true;
      task.is_inline = true;

      dispatch(requestCloseDrawer());

      if (task.type == 'project') {
        if (
          state.columnName !== 'text' &&
          state.columnName !== 'description' &&
          state.columnName !== 'calendar_id'
        ) {
          return false;
        }
      }

      if (state.columnName === 'custom_predecessors') {
        task.custom_predecessors =
          from_links_to_string_predecessor(gantt)(task);
      }
      if (state.columnName === 'custom_sucessors') {
        task.custom_sucessors = from_links_to_string_sucessor(gantt)(task);
      }

      if (state.columnName === 'constraint_date' && task.progress === 100)
        return false;

      if (state.columnName === 'constraint_type') return false;

      if (state.columnName == 'progress') {
        if (task.type === 'project' || task.tasks?.length) {
          return false;
        }
      } else if (state.columnName == 'duration') {
        task.last_constraint = task.constraint_type;
        task.last_constraint_date = task.constraint_date;
        task.last_start_date = task.start_date;

        /**
         * Double Fix BUG: Ops said to change this to task with advancement, then change it to just 100%
         * In case to new change check this line which blocks to edit inline the duration
         * Then to drag resize go to line 351 :)
         * NOTE: Intervals go from 0 to 1 where 0 means 0% and 1 means 100%
         */
        if (task.progress == 100) {
          const message =
            t('lang') === 'es'
              ? 'Acción no permitida: Intentas modificar una actividad completada.'
              : 'Action not allowed: You try to modify a completed activity.';
          send_warning_message(gantt)(message);
          return false;
        }
      } else if (state.columnName == 'start_date') {
        if (task.progress > 0) {
          const message =
            t('lang') === 'es'
              ? 'Acción no permitida: Intentas modificar una actividad con avance.'
              : 'Action not allowed: You try to modify an activity with progress.';
          send_warning_message(gantt)(message);
          return false;
        }
        gantt.editDatepicker = true;
      } else if (state.columnName == 'end_date') {
        if (task.progress >= 99.999) {
          const message =
            t('lang') === 'es'
              ? 'Acción no permitida: Intentas modificar una actividad completada.'
              : 'Action not allowed: You try to modify a completed activity.';
          send_warning_message(gantt)(message);
          return false;
        }
        gantt.editDatepicker = true;
      }
      return true;
    })
  );

  events.push(
    gantt.attachEvent(
      'onAfterTaskAutoSchedule',
      (task, start, link, predecessor) => {
        autoschedule_job(gantt)(task, start, link, predecessor);
      }
    )
  );

  events.push(
    gantt.attachEvent('onBeforeTaskUpdate', (id, new_item) => {
      gantt.iscritical_init = false;
      if (gantt.loadedSuccesfully) {
        if (!gantt.isTaskExists(id)) return;

        // The start and end times of the task are validated with the calendar.

        updateTaskWorkingHours();

        if (gantt.constraint_bug) {
          gantt.render();
          gantt.constraint_bug = false;
        }
      }

      function updateTaskWorkingHours() {
        const task = gantt.getTask(id);

        if (!gantt.editDatepicker && task.type !== 'milestone') {
          const calendar = getTaskCalendar(task, gantt);

          let hours_start =
            calendar?._worktime.dates[
              task.start_date.getDay() - 1 < 0
                ? 6
                : task.start_date.getDay() - 1
            ];
          if (hours_start) {
            hours_start = hours_start[0]?.split('-')[0];
          }
          const task_start = new Date(task.start_date);
          task_start.setHours(hours_start);

          let hours_end =
            calendar?._worktime.dates[
              task.end_date.getDay() - 1 < 0 ? 6 : task.end_date.getDay() - 1
            ];
          if (hours_end) {
            hours_end = hours_end[hours_end?.length - 1]?.split('-')[1];
          }
          const task_end = new Date(task.end_date);
          task_end.setHours(hours_end);

          // If the start_date or end_date time differs from the calculated time, the updateTaskTiming function is executed.
          if (
            task_start.getHours() !== task.start_date.getHours() ||
            task_end.getHours() !== task.end_date.getHours()
          ) {
            task.start_date = new Date(task.start_date.toDateString());
            task.end_date = new Date(
              task.end_date.toDateString() + ' 23:59:59'
            );
            updateTaskTiming({ task, gantt });
          }
        }
      }
    })
  );

  events.push(
    gantt.attachEvent('onBeforeTaskDisplay', (id, task) => {
      if (task.should_be_showed) {
        return true;
      }
      return false;
    })
  );

  events.push(gantt.attachEvent('onBeforeLightbox', (id) => false));

  events.push(
    gantt.attachEvent('onAfterTaskUpdate', (id, task) => {
      onAfterTaskUpdate({ id, task, calendarObject, sector, projectState });
    })
  );

  events.push(
    gantt.attachEvent('onBeforeAutoSchedule', () => {
      gantt.iscritical_init = false;
      gantt.is_autoscheduling = true;
      const scale = JSON.parse(window.localStorage.getItem('scale'));
      scale && gantt.changeScaleVisualization(scale);

      if (gantt.clearStacks) {
        const allTasks = gantt.getTaskByTime();
        gantt.legacy_corrected_activities = cloneDeep(allTasks);
      }

      if (
        gantt.dragWithMultipleActivitiesSelected ||
        gantt.avoidAutoScheduleBeforeDrag
      ) {
        return false;
      }
      return true;
    })
  );

  gantt.initNonSavedTasksCounter = 0;
  gantt.initNonSavedDeletedTasksCounter = 0;
  gantt.initNonUpdatedTasksCounter = 0;
  gantt.initreadjustment_validate = true;

  events.push(
    gantt.attachEvent('onAfterAutoSchedule', (taskId, updatedTasks) => {
      onAfterAutoSchedule({
        taskId,
        updatedTasks,
        setAutoscheduling,
        setAutoschedulingVisual
      });
    })
  );

  gantt.validateCriticalNeed = validateCriticalNeed;
  gantt.criticalPathRefresh = criticalPathRefresh;

  events.push(
    gantt.attachEvent('onAfterTaskAdd', () => {
      reloadDuration(gantt);
      gantt.resetLinksStateWithTimeout && gantt.resetLinksStateWithTimeout();
    })
  );

  gantt.modifyUndoStack = modifyUndoStack;

  events.push(
    gantt.attachEvent('onAfterUndo', (action) => {
      gantt.isUndo = false;
      updateAllCorrelatives();
      gantt.changeVisualizationOption && gantt.changeVisualizationOption();
      gantt.detachMultiDragSelect && gantt.detachMultiDragSelect();
      gantt.ext.undo.undoDisabled = true;
      gantt.ext.undo.undoRedoDisabled = true;
      gantt.autoSchedule();
      gantt.isUndo = false;

      gantt.ext.undo.notDeleteUndoStack = false;
      setTimeout(() => {
        gantt.updateActCard();
        gantt.ext.undo.undoDisabled = false;
        gantt.ext.undo.undoRedoDisabled = false;
        window.loader.hide();
      }, 300);
    })
  );

  events.push(
    gantt.attachEvent('onAfterRedo', (action) => {
      gantt.isRedo = false;
      updateAllCorrelatives();
      gantt.changeVisualizationOption && gantt.changeVisualizationOption();
      gantt.detachMultiDragSelect && gantt.detachMultiDragSelect();
      gantt.ext.undo.undoDisabled = true;
      gantt.ext.undo.undoRedoDisabled = true;
      gantt.ext.undo.updateCommandRedo = true;
      gantt.ext.undo.notDeleteUndoStack = true;
      gantt.added_link = false;
      gantt.autoSchedule();
      setTimeout(() => {
        gantt.updateActCard();
        window.loader.hide();
      }, 500);
    })
  );

  gantt.modifyLagCustom = (task) => {
    let new_lag = 0;
    gantt.batchUpdate(() => {
      let link;
      let source_task;
      task.$target.forEach((l) => {
        link = gantt.getLink(l);
        if (!gantt.isTaskExists(link.source)) return;
        source_task = gantt.getTask(link.source);
        // *************************************
        // validar el tipo de link
        let l_source;
        let l_target;
        switch (link.type) {
          case '0':
            l_source = moment(source_task.end_date).format();
            l_target = moment(task.start_date).format();
            break;
          case '1':
            l_source = moment(source_task.start_date).format();
            l_target = moment(task.start_date).format();
            break;
          case '2':
            l_source = moment(source_task.end_date).format();
            l_target = moment(task.end_date).format();
            break;
          case '3':
            l_source = moment(source_task.start_date).format();
            l_target = moment(task.end_date).format();
            break;
          default:
            l_source = moment(source_task.end_date).format();
            l_target = moment(task.start_date).format();
            break;
        }
        // fin validar tipo de link

        const durationWithGanttApi = ganttAPI.calculateDuration(
          l_source,
          l_target,
          task.calendar_id,
          gantt
        );

        new_lag = durationWithGanttApi; // calculateDuration(l_source, l_target, gantt_cal, task.calendar_id);

        if (new_lag > 0) {
          // new_lag = 0
        } else {
          // task.constraint_type = 'asap'
        }

        link.lag = new_lag;
        gantt.updateLink(link.id);
      });
    });
  };

  function checkIfMultipleTasksAreSelected() {
    gantt.getAllCheckedTasks = getAllCheckedTasks(true);

    if (getAllCheckedTasks(true).length > 1) {
      gantt.dragWithMultiple = true;
      gantt.avoidAutoScheduleBeforeDrag = true;
    }
  }

  const isOdd = (num) => num % 2;
  gantt.oddColsConfig = {};
  events.push(
    gantt.attachEvent('onBeforeGanttRender', () => {
      gantt
        .getGridColumns()
        .filter((el) => !el.hide)
        .forEach((el, index) => {
          const columnName = el.name;
          if (isOdd(index)) {
            gantt.oddColsConfig[columnName] = true;
          } else {
            gantt.oddColsConfig[columnName] = false;
          }
        });
    })
  );

  events.push(
    gantt.attachEvent('onBeforeTaskDrag', (id, mode, e) => {
      let tasksToDrag = [];
      const tasksToDeleteFromDrag = [];
      checkIfMultipleTasksAreSelected();
      if (gantt.dragWithMultiple) {
        gantt.draggingMultipleALAP = true;
        tasksToDrag = getAllCheckedTasks(true);
      } else {
        tasksToDrag = [id];
      }

      window.gantt.lastMoveTaskData = [];

      tasksToDrag.forEach((id) => {
        gantt.iscritical_init = false;
        if (!gantt.isTaskExists(id)) return;
        const modes = gantt.config.drag_mode;
        const temp_task_var = gantt.getTask(id);

        if (!gantt.dragWithMultiple) {
          gantt.maxPerformance = true;
          if (temp_task_var.type === 'milestone') {
            gantt.draggingSingleMilestone = true;
            temp_task_var.calendarWorkingHours =
              getStartAndEndHours(temp_task_var);
          }
        }

        window.gantt.lastMoveTaskData = [
          ...window.gantt.lastMoveTaskData,
          {
            id,
            data: { ...temp_task_var }
          }
        ];

        const task_drag = {
          mode: mode,
          start_date: temp_task_var.start_date,
          end_date: temp_task_var.end_date,
          duration: temp_task_var.duration,
          for_disable_milestone_duration:
            temp_task_var.for_disable_milestone_duration,
          constraint_type: 'snet',
          constraint_type_old: temp_task_var.constraint_type,
          constraint_date: temp_task_var.constraint_date,
          baseline_points: temp_task_var.baseline_points,
          task_original: { ...temp_task_var }
        };
        temp_task_var.new_dates_before_drag = task_drag;
        if (temp_task_var.constraint_type == 'alap')
          temp_task_var.abortALAP = true;
        /** ctid#13 mp-252 */
        if (temp_task_var.duration_new && temp_task_var.duration_new) {
          delete temp_task_var.duration_new;
          delete temp_task_var.end_date_new;
        }

        temp_task_var.auto_scheduling = true;

        /** ctid#24 */
        temp_task_var.constraint_type_old = temp_task_var.constraint_type;
        switch (mode) {
          case modes.move:
            /** Verify if task has progress to not allow moving */
            if (temp_task_var.progress > 0) {
              runAndUnmountTimeout(() => {
                gantt.maxPerformance = false;
              }, 1000);
              const message = t(
                'schedule_notification.activity_drag_not_allowed.by_progress'
              );
              send_warning_message(gantt)(message);
              temp_task_var.auto_scheduling = false;
              tasksToDeleteFromDrag.push(id);
              return false;
            }

            gantt.is_task_moved = true;
            temp_task_var.correct_baseline_worktime_bug = true;
            /* temp_task_var.auto_scheduling = false */
            if (temp_task_var.task_added) {
              temp_task_var.duration = temp_task_var.aux_duration;
              // gantt.updateTask(temp_task_var.id);
            }
            temp_task_var.dragged = true;
            break;
          case modes.resize:
            if (temp_task_var.progress == 100) {
              const message =
                t('lang') === 'es'
                  ? 'Movimiento no permitido: Intentas modificar la fecha de inicio o fin de una actividad completada.'
                  : 'Movement not allowed: You try to modify the start or end date of a completed activity.';
              send_warning_message(gantt)(message);
              runAndUnmountTimeout(() => {
                gantt.maxPerformance = false;
              }, 1000);
              return false;
            }
            gantt.copyBehaviourFromInline = true;
            /** We use this flag to copy the behaviour of lightbox editing with resize trough drag */
            temp_task_var.is_open_lightbox = true;
            /** This flag allows to event aftertaskupdate from Gantt DHTMLX to fix bug resize drag */
            temp_task_var.bug_duration_from_resize_drag = true;
            break;
        }

        temp_task_var.last_constraint = temp_task_var.constraint_type;
        temp_task_var.last_constraint_date = temp_task_var.constraint_date;
        temp_task_var.last_start_date = temp_task_var.start_date;
        temp_task_var.last_duration = temp_task_var.duration;
        temp_task_var.last_end_date = temp_task_var.end_date;
      });
      gantt.isDragging = true;
      gantt.isEditingDrag = true;
      removeTaskFromDrag(tasksToDeleteFromDrag);
      return true;
    })
  );

  function removeTaskFromDrag(tasksToRemove) {
    if (!tasksToRemove.length) return;
    const currentCheckedTasks = gantt.getAllCheckedTasks;
    if (currentCheckedTasks)
      gantt.getAllCheckedTasks = currentCheckedTasks.filter(
        (el) => !tasksToRemove.includes(el)
      );
  }

  gantt.updateModifiedSourceLagCustom = (task) => {
    task.$target.forEach((l) => {
      const link = gantt.getLink(l);
      if (!gantt.isTaskExists(link.source)) return;
      // const task = gantt.getTask(link.source)
      // gantt.updateTask(link.source)
      // executeAsyncAutoscheduleToAvoidFreeze(link.source)
    });
  };

  /**
   * This function execute autoschedule directly if there is under 3000 tasks and links
   * @returns Direct autoshcedue execution if there is under 3000 elements at gantt
   */
  const executeAsyncAutoscheduleToAvoidFreeze = () => {
    /** Both flags helps dhtmlx component to freeze memory through their real time autoschedule */
    gantt.maxPerformance = false;
    gantt.shouldAutoscheduleSync = true;
    gantt.copyBehaviourFromInline = false;

    const allTasks = gantt.getTaskByTime();
    const allLinks = gantt.getLinks();
    const sumOfTaskAndLinks = allTasks.length + allLinks.length;

    if (sumOfTaskAndLinks < 3000) {
      return gantt.autoSchedule();
    }

    if (!gantt.avoidAutoschedule) {
      setAutoschedulingVisual(true);
    } else {
      runAndUnmountTimeout(() => {
        gantt.avoidAutoschedule = false;
      }, 500);
    }
  };

  events.push(
    gantt.attachEvent('onAfterTaskDrag', (id) => {
      onAfterTaskDrag(id, t);
      trackEventBasicProps('activity_bar_drag&drop');
    })
  );

  /**
   * This function takes all milestones with progress and set autoscheduling to true
   */
  const disableAutoscheduleForMilestonesWithProgress = () => {
    try {
      log('DISABLE_AUTOSCHEDULE_MILESTONES', 'schedule ID' + sector.id);
      const milestonesWithProgress = gantt.getTaskBy(
        (task) => task.type === 'milestone' && task.progress > 0
      );
      milestonesWithProgress.forEach((milestone) => {
        milestone.auto_scheduling = false;
      });
    } catch (e) {
      if (gantt.Sentry) {
        gantt.Sentry.captureMessage(e, 'warning');
      }
    }
  };

  gantt.disableAutoscheduleForMilestonesWithProgress =
    disableAutoscheduleForMilestonesWithProgress;

  /** Function for fix wierd behaviour of initial unscheduled task (better performance at init) */
  gantt.correctWrongDates = () => {
    gantt.loadedSuccesfully = false;
    const milestoneToCheckPast = [];
    const tasks = gantt.getTaskByTime();
    tasks &&
      tasks.length &&
      tasks.map((task) => {
        task.duration = task.duration_milestone_bugged;

        if (gantt.defaultCalendar) {
          if (!task.calendar_id) {
            task.calendar_id = gantt.defaultCalendar;
            task.assignedDefaultCalendar = true;
          }
        }

        let calendar = gantt.getCalendar(task.calendar_id);
        if (!calendar) calendar = gantt.getCalendar('global');

        task.old_progress_registered = task.progress;
        task.old_hhWorkTime_registered = task.hhWorkTime;
        task.old_cost_registered = task.cost;
        task.old_used_cost_registered = task.used_cost;
        task.old_duration_registered = task.duration;
        task.old_real_cost_registered = task.real_cost;

        const constraintTypeOptions = [
          {
            value: 'asap',
            date: false
          },
          {
            value: 'alap',
            date: false
          },
          {
            value: 'snet',
            date: true
          },
          {
            value: 'snlt',
            date: true
          },
          {
            value: 'fnlt',
            date: true
          },
          {
            value: 'fnet',
            date: true
          },
          {
            value: 'mso',
            date: true
          },
          {
            value: 'mfo',
            date: true
          }
        ];

        task.constraint_type = task.real_constraint_type;
        const typeSelect = constraintTypeOptions?.find(
          (e) => e.value === task.constraint_type
        );
        if (task.should_correct_start_date) {
          task.start_date = gantt.date.parseDate(
            task.non_parsed_original_start_date,
            'xml_date'
          );
          task.for_disable_milestone_duration = task.duration;

          if (task.non_parsed_original_constraint_date) {
            task.constraint_date = gantt.date.parseDate(
              task.non_parsed_original_constraint_date,
              'xml_date'
            );
          }

          if (!typeSelect || !typeSelect?.date) {
            task.constraint_date = null;
            task.last_constraint = task.constraint_type;
            task.last_constraint_date = task.constraint_date;
            task.real_constraint_type = task.constraint_type;
          }

          updateTaskTiming({ task });
        }
        if (gantt.hasChild(task.id)) {
          task.type = gantt.config.types.project;
          task.newActivitiesArray = gantt.getChildren(task.id);
        } else if (
          task.is_milestone ||
          task.duration == 0 ||
          !task.non_parsed_original_duration
        ) {
          if (task.$source.length || task.$target.length) {
            milestoneToCheckPast.push(task.id);
          }
          task.type = 'milestone';
          task.duration = 0;
        }
        executeFixForConstraints(task, calendar);
        addHoursToConstraintDate(task, calendar, gantt);
      });
    checkUpdatedElementsToCalculateTimingPast(milestoneToCheckPast, gantt);
    gantt.adjustGridWidth();

    setTimeout(() => {
      gantt.clearStacks = true;
      gantt.autoSchedule();
    }, 500);
    gantt.resetLinksStateWithTimeout && gantt.resetLinksStateWithTimeout(false);
  };

  /**
   * Native event listener to handle user keyboard bug at inline editors
   */
  document.addEventListener('keydown', (e) => {
    if (
      window.to_use_react_gantt?.isEditingInline &&
      document.activeElement.closest('.gantt-container') &&
      document.querySelector('#hovering-root:empty') &&
      !document.querySelector('.filter-lookahead-multi-check')
    ) {
      const parentInput = document.getElementsByClassName(
        'gantt_grid_editor_placeholder'
      );
      if (parentInput.length) {
        const input = document.getElementsByClassName(
          'gantt_grid_editor_placeholder'
        )[0]?.children[0]?.children[0];
        input && input.focus && input.focus();
      }
    }
  });

  document &&
    document.querySelector &&
    document.querySelector('.gantt-container') &&
    document
      .querySelector('.gantt-container')
      .addEventListener('click', (e) => {
        const isMRDrawerFeatureEnabled = isFeatureOn(
          FEATURE_FLAGS.ENABLE_MODIFICATION_REQUESTS_DRAWER
        );
        const mrButton = e.target.closest('.open-mr-drawer-button');
        if (!mrButton || !isMRDrawerFeatureEnabled) return;

        const activityId = Number(mrButton.getAttribute('data-activity-id'));
        dispatch(
          openDrawer({
            component: ModificationRequestsDrawer,
            origin: DRAWER_ORIGINS.RIGHT,
            params: { activityId }
          })
        );
      });

  let oldTop;
  const isReRendering = false;
  events.push(
    gantt.attachEvent('onGanttScroll', (left, top) => {
      // if (oldTop != top) {
      //     if (oldTop > top) {
      //         runAndUnmountTimeout(() => {
      //             if (!isReRendering) gantt.render()
      //             isReRendering = true
      //             runAndUnmountTimeout(() => {
      //                 isReRendering = false
      //             }, 500)
      //         }, 500)
      //     }
      //     oldTop = top
      // }

      gantt.fixScaleDateHeader();
      gantt.ext.inlineEditors.hide();
    })
  );

  gantt.refreshDetectedLevelsInGantt = () => {
    const ganttLevels = getDetectedGanttLevels();
    gantt.detectedLevelsInGantt = ganttLevels;
    return gantt.detectedLevelsInGantt;
  };

  const getDetectedGanttLevels = () => {
    const ganttLevels = [];
    gantt.getTaskByTime().forEach((task) => {
      if (!ganttLevels.includes(task.$level)) {
        ganttLevels.push(task.$level);
      }
    });
    ganttLevels.sort((a, b) => a - b);
    return ganttLevels;
  };

  const checkDuplicateIndex = (activities) => {
    let isDuplicated = false;
    let errorType = '';

    isDuplicated = activities.some((activity, i) => {
      const correlativeId = activity.correlative_id;
      const nextCorrelativeId = activities[i + 1]?.correlative_id;
      const validateExistsActivities = correlativeId && nextCorrelativeId;

      if (!validateExistsActivities) return false;

      if (correlativeId + 1 !== nextCorrelativeId) {
        errorType =
          correlativeId === nextCorrelativeId
            ? 'ID´s duplication'
            : 'ID´s not correlatives';
        return true;
      }
      return false;
    });

    const statusCorrectIds = { isDuplicated, errorType };
    return statusCorrectIds;
  };

  events.push(
    gantt.attachEvent('onParse', () => {
      gantt.firstScrollToday = true;

      gantt.sort('correlative_id', false);
      gantt.auto_scheduling_links = true;

      setTimeout(() => {
        gantt.refreshDetectedLevelsInGantt();
        // gantt.scrollToTodayAtChart();
        const setterRowColor = getRowColorSetter(
          gantt.visualizationRowColorActive
        );
        setterRowColor && setterRowColor();
        updateColWidthWBS();

        const activities = gantt
          .getTaskByTime()
          .sort((a, b) => a.correlative_id - b.correlative_id);
        const oldLastCorrelativeId =
          activities[activities.length - 1].correlative_id;
        const oldLastCountActivities = activities.length - 1;

        const areDuplicatedRes = checkDuplicateIndex(activities);
        if (areDuplicatedRes.isDuplicated) {
          updateAllCorrelatives();

          const new_activities = gantt
            .getTaskByTime()
            .sort((a, b) => a.correlative_id - b.correlative_id);
          const newLastCorrelativeId =
            new_activities[activities.length - 1].correlative_id;
          const newCountActivities = new_activities.length - 1;

          trackingEvent(
            'correlative_ids_update',
            {
              ...getBasicAmplitudEventProperties(),
              old_last_correlative_id: oldLastCorrelativeId,
              old_activities_count: oldLastCountActivities,
              new_last_correlative_id: newLastCorrelativeId,
              new_activities_count: newCountActivities,
              error_type: areDuplicatedRes.errorType
            },
            AMPLITUDE_SERVICE
          );
        }
        filterRepeatedLinks();
      }, 500);

      log('LOADED_SCHEDULE', 'schedule ID' + sector.id);
    })
  );

  const filterRepeatedLinks = () => {
    gantt.batchUpdate(() => {
      try {
        const allLinks = gantt.getLinks();
        // Función de comparación para ordenar por el campo "id"
        const compareById = (a, b) => b.id - a.id;
        allLinks.sort(compareById);
        const compare_array = [];
        if (allLinks) {
          for (let i = 0; i < allLinks.length; i++) {
            const link = allLinks[i];
            const sameSource = link?.source;
            const sameTarget = link?.target;
            const sameType = link?.type;
            const sameLag = link?.lag;
            const doesRepeat = compare_array.some(
              ({ target, source, type, lag }) =>
                target === sameTarget &&
                source === sameSource &&
                type === sameType
            );
            if (doesRepeat) {
              gantt.deleteLink(link.id);
              if (deleteCallback) deleteCallback(link, 'link');
            }
            if (link) {
              compare_array.push(link);
            }
          }
        }
      } catch (error) {}
      gantt.clearStacks = true;
    });
  };

  /**
   * this function converts an array of activity ids to an array of activities
   * @param {*} activitiesIds id of activities
   * @returns array of activitied
   */
  const transformIdsInTasks = (activitiesIds) => {
    const getTasks = [];
    if (Array.isArray(activitiesIds)) {
      activitiesIds.forEach((el) => {
        const taskFind = gantt.getTask(el);
        if (taskFind && gantt.isTaskExists(taskFind.id)) {
          getTasks.push(taskFind);
        }
      });
    }
    return getTasks;
  };

  /**
   * This keeps track of selected tasks, in order to retrieve their modification requests
   * and display them in the new drawer. However, for some reason, this listener behaves
   * erratically. When you select tasks by checking their checkboxes, it seems to work fine.
   * But when you deselect a task, by unchecking its checkbox, the getSelectedTasks method
   * doesn't reflect this change immediately. I don't know if there is some workaround
   * to circumvent this limitation, but for now, this is how we'll do it.
   */
  let lastSelectedids = [];
  gantt.attachEvent('onMultiSelect', () => {
    const isBulkActionsBarFeatureEnabled =
      isFeatureOn(FEATURE_FLAGS.ENABLE_BULK_ACTIONS_BAR) &&
      enableForMassiveSelect();
    let activitiesIds;
    if (isBulkActionsBarFeatureEnabled) {
      if (window.selectFromShift) {
        activitiesIds = window.lastSelectedIds;
        activitiesIds = activitiesIds.filter((id) => !gantt.hasChild(id));
        delete window.selectFromShift;
        activitiesIds = transformIdsInTasks(activitiesIds);
      } else {
        activitiesIds = checkArrayMassive();
      }
    } else {
      activitiesIds = gantt.getSelectedTasks().map((id) => Number(id));
    }

    function shouldDispatchSelectedActivities() {
      if (activitiesIds.length === 0 && lastSelectedids.length > 1) return true;
      if (activitiesIds.length === 0 && lastSelectedids.length === 0)
        return false;
      if (activitiesIds.length === lastSelectedids.length) return false;
      return true;
    }

    if (shouldDispatchSelectedActivities()) {
      dispatch(ganttActions.setSelectedActivities(activitiesIds));
    }

    lastSelectedids = activitiesIds;

    // This action works only in the event onTaskClick
    return true;
  });

  let clickedOnActivity = false;

  /**
   * This function handle gantt selection with proplanner massive bar
   * @param {*} id
   */
  const runMultiSelectionMiddleware = (id) => {
    gantt.updateDOMCheckbox && gantt.updateDOMCheckbox(id, null);
    /** this function should only exist in MP */
    // This code should run onMultiSelect event
    const selectedActivities = gantt.checkArrayMassive();
    dispatch(ganttActions.setSelectedActivities(selectedActivities));
  };
  gantt.runMultiSelectionMiddleware = runMultiSelectionMiddleware;

  events.push(
    gantt.attachEvent('onTaskClick', (activityId, e) => {
      const id = activityId;
      if (e.ctrlKey || e.metaKey) {
        runMultiSelectionMiddleware(id);
        return false;
      }
      const activityTitleColumnIndex = gantt
        .getGridColumns()
        .findIndex((col) => col.name === 'text'); // REFACTOR TO DYNAMIC FIND TITLE COL POSITION
      const clickedCellIndex = Number(
        e.target.closest('.gantt_cell')?.getAttribute('data-column-index')
      );
      const hasClickedOnModRequestsButton = Boolean(
        e.target.closest('.gantt-dropdown')
      );

      // If the user is trying to select one or more activities through their checkboxes,
      // OR when the click happens on a column other than the title,
      // OR when the click happens on the modification requests button (inside the title cell),
      // the Activity Card isn't displayed

      if (e.target?.className?.includes('gantt_tree_card')) {
        clickedOnActivity = true;
        setTimeout(() => {
          clickedOnActivity = false;
        }, 300);
      }
      if (
        e.target?.className?.includes('gantt_tree_card') ||
        e.target.type === 'checkbox' ||
        clickedCellIndex !== activityTitleColumnIndex ||
        hasClickedOnModRequestsButton
      ) {
        return;
      }

      // As the click event bubbles up towards the document's root, we need to cancel bubbling here.
      // This way, the drawer won't get closed right after being opened, as if the user had clicked outside it.
      e.stopPropagation();

      return true;
    })
  );

  /**
   * This function renders 6 more months to the gantt time scale
   */
  events.push(
    gantt.attachEvent('onBeforeGanttRender', () => {
      const range = gantt.getSubtaskDates();
      let addTime = {
        duration_start: -1,
        duration_end: 6,
        scaleUnit: 'month'
      };

      switch (gantt.ext.zoom.getCurrentLevel()) {
        case 0:
          addTime = {
            duration_start: -1,
            duration_end: 12 * 17,
            scaleUnit: 'month'
          };
          break;
        case 1:
          addTime = {
            duration_start: -1,
            duration_end: 12 * 4,
            scaleUnit: 'month'
          };
          break;
        case 2:
          addTime = {
            duration_start: -1,
            duration_end: 12,
            scaleUnit: 'month'
          };
          break;
        case 3:
          addTime = {
            duration_start: -4,
            duration_end: 6,
            scaleUnit: 'week'
          };
          break;
        case 4:
          addTime = {
            duration_start: -4,
            duration_end: 1,
            scaleUnit: 'week'
          };
          break;
      }
      if (range.start_date && range.end_date) {
        gantt.config.start_date = gantt.calculateEndDate(
          range.start_date,
          addTime.duration_start,
          addTime.scaleUnit
        );
        gantt.config.end_date = gantt.calculateEndDate(
          range.end_date,
          addTime.duration_end,
          addTime.scaleUnit
        );
      }
    })
  );

  const createLinkUniqueID = (link) => {
    if (!link) return;
    const { source, target, type, lag } = link;
    const uniqueID = `${source}-${target}-${type}`;
    return uniqueID;
  };

  function checkAndSetUniqueLink(uniqueID) {
    if (gantt.mappedLinks[uniqueID]) {
      return false;
    }
    gantt.mappedLinks[uniqueID] = true;
    return true;
  }

  gantt.attachEvent('onAfterLinkDelete', (id, link) => {
    if (gantt.mappedLinks) {
      const uniqueID = createLinkUniqueID(link);
      if (!uniqueID) return;
      delete gantt.mappedLinks[uniqueID];
      setTimeout(() => {
        gantt.autoSchedule();
      }, 2000);
    }
  });

  gantt.attachEvent('onBeforeBatchUpdate', () => {
    gantt.mappedLinks = {};
    gantt.getLinks().forEach((link) => {
      gantt.mappedLinks[createLinkUniqueID(link)] = true;
    });
  });

  gantt.attachEvent('onBeforeLinkAdd', (id, link) => {
    gantt.creatingLink = true;
    const uniqueID = createLinkUniqueID(link);
    if (!uniqueID) return false;
    const shouldAddLink = checkAndSetUniqueLink(uniqueID);

    gantt.resetLinksStateWithTimeout && gantt.resetLinksStateWithTimeout();
    return shouldAddLink;
  });

  /**
   * Event handler for onLinkCreated event.
   * Displays a modal if the link creates a circular dependency.
   * @param {object} link - The newly created link.
   * @returns {boolean} - Whether to continue with the default processing.
   */
  gantt.attachEvent('onLinkCreated', (link) => {
    const shouldAbortLink = checkCircularLinks(link, gantt, modalCircular);
    if (shouldAbortLink) {
      return false;
    }
    return true;
  });
  gantt.attachEvent('onBeforeTaskChanged', (id, mode, task) => {
    onBeforeTaskChanged(id, mode, task);
    return true;
  });
  /** handle circular error event. The link is deleted automatically. */
  events.push(
    gantt.attachEvent('onCircularLinkError', (link, group) => {
      /** circular link review process */
      if (group && Object.keys(group).length !== 0) {
        trackingEvent(
          'circular_link_alert_visualization',
          {
            ...getBasicAmplitudEventProperties(),
            link_id: JSON.stringify(group.links),
            type: 'circular_automatic_deleted',
            tag: 'old logic'
          },
          AMPLITUDE_SERVICE
        );
        modalCircular();
      }
    })
  );

  /** handle circular error event. It's necessary delete manually the links included. */
  gantt.attachEvent('onAutoScheduleCircularLink', (groups) => {
    console.log('Circular links: ', groups);
    /** circular link review process */
    if (groups && Array.isArray(groups)) {
      const arr_correlative_id = [];
      groups.forEach((group) => {
        if (group.tasks) {
          group.tasks.forEach((task_id) => {
            if (gantt.isTaskExists(task_id)) {
              const getTask = gantt.getTask(task_id);
              arr_correlative_id.push(getTask.correlative_id);
            }
          });
        }
      });
      trackingEvent(
        'circular_link_alert_visualization',
        {
          ...getBasicAmplitudEventProperties(),
          link_id: JSON.stringify(groups.links),
          type: 'circular_no_deleted',
          tag: 'old logic'
        },
        AMPLITUDE_SERVICE
      );
      modalCircular(arr_correlative_id);
      removeActionsAdded(gantt, groups);
    }
  });
  events.push(
    gantt.attachEvent('onEmptyClick', (e) => {
      gantt.ext.inlineEditors.save();
      gantt.detachMultiDragSelect && gantt.detachMultiDragSelect();
    })
  );

  gantt.constraintValidations = constraintValidationsForDrag;
  events.push(
    gantt.attachEvent('onGanttRender', () => {
      gantt.fixScaleDateHeader();
      gantt.fixBlankScreenViewport();
    })
  );
  gantt.criticalPath = new CriticalPath(gantt);
  /** Through viewchild we have the virtual dom reference, so we can initialize the plugin there */
  gantt.init(ganttContainer);

  /** Events to detach at kanban component or any who use this */
  return events;
}
