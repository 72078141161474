export const SET_LOOKAHEAD_ASSISTANCE_ACTIVATED_COLS =
  'SET_LOOKAHEAD_ASSISTANCE_ACTIVATED_COLS';
export const SET_LOOKAHEAD_ASSISTANCE_RANGE_FILTERED =
  'SET_LOOKAHEAD_ASSISTANCE_RANGE_FILTERED';
export const NOTIFY_LOOKAHEAD_ASSISTANCE_UPDATE =
  'NOTIFY_LOOKAHEAD_ASSISTANCE_UPDATE';
export const NOTIFY_LOOKAHEAD_ASSISTANCE_UPDATE_ORDER =
  'NOTIFY_LOOKAHEAD_ASSISTANCE_UPDATE_ORDER';
export const NOTIFY_LOOKAHEAD_ASSISTANCE_UPDATE_FILTER =
  'NOTIFY_LOOKAHEAD_ASSISTANCE_UPDATE_FILTER';
export const NOTIFY_LOOKAHEAD_ASSISTANCE_UPDATE_GROUP =
  'NOTIFY_LOOKAHEAD_ASSISTANCE_UPDATE_GROUP';

export const lookaheadAssistanceActions = {
  setActivatedColumns: (activatedColumns) => ({
    type: SET_LOOKAHEAD_ASSISTANCE_ACTIVATED_COLS,
    payload: activatedColumns
  }),
  setIsRangeFiltered: (obj) => ({
    type: SET_LOOKAHEAD_ASSISTANCE_RANGE_FILTERED,
    payload: { boolean: obj.boolean, dateRange: obj.range }
  }),
  notifyLookaheadUpdate: () => ({
    type: NOTIFY_LOOKAHEAD_ASSISTANCE_UPDATE,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateOrder: () => ({
    type: NOTIFY_LOOKAHEAD_ASSISTANCE_UPDATE_ORDER,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateFilter: () => ({
    type: NOTIFY_LOOKAHEAD_ASSISTANCE_UPDATE_FILTER,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateGroup: () => ({
    type: NOTIFY_LOOKAHEAD_ASSISTANCE_UPDATE_GROUP,
    payload: { boolean: true }
  })
};
