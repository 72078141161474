import { Colors } from '../../../../constants/colors.constants';

function defineColorByStatus(task, render = false, gantt = false) {
  if (!gantt) {
    gantt = window.to_use_react_gantt;
  }

  gantt.config.highlight_critical_path = false;
  if (task.type === 'milestone') return;

  const color = getBarColorWithProgressByStatus(task.status);
  task.progressSolidColor = color;
  task.color = getBarColorWithoutProgressByStatus(task.status, color);

  if (render) gantt.optimizedRender();
}

function getBarColorWithProgressByStatus(status) {
  switch (status) {
    case 'Advancement':
      return Colors.GANTT_BAR_BLUE;
    case 'Overdue':
      return Colors.GANTT_BAR_RED;
    case 'Waiting':
      return Colors.GANTT_BAR_GRAY_WITH_PROGRESS;
    case 'Doing':
      return Colors.GANTT_BAR_YELLOW;
    case 'Done':
      return Colors.GANTT_BAR_GREEN;
    default:
      return Colors.GANTT_BAR_GRAY_WITH_PROGRESS;
  }
}

function getBarColorWithoutProgressByStatus(status, color) {
  const statusesWithSolidColor = ['Advancement', 'Overdue', 'Doing', 'Done'];
  return statusesWithSolidColor.includes(status)
    ? color + '4d'
    : Colors.GANTT_BAR_GRAY_WITHOUT_PROGRESS;
}

export { defineColorByStatus };
