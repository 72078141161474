import moment from 'moment';
/**
 * Event handler that is called before a task's change is finalized. It allows for custom logic to be executed right after a drag-and-drop operation but before the task is actually updated.
 *
 * @param {string|number} id - The ID of the task being changed.
 * @param {string} mode - The mode of drag-and-drop operation ("r3esize", "progress", "move", "ignore").
 * @param {object} task - A copy of the task object in its original state before the drag-and-drop operation.
 * @returns {boolean} - Returns `true` to proceed with the default action of the event, or `false` to cancel the drag operation.
 *
 * @example
 * gantt.attachEvent("onBeforeTaskChanged", function(id, mode, task){
 *   // Custom logic here
 *   return true;
 * });
 */
function onBeforeTaskChanged(id, mode, task) {
  let gantt = window.ganttVisualization;
  if (!gantt) return;

  if (mode === 'resize') {
    setNewDatesAndHours(id);
  }

  return true;
}
/**
 * Modifies the start and end dates of an task based on the task's calendar working hours.
 *
 * @param {string|number} id - The ID of the task to modify.
 */
function setNewDatesAndHours(id) {
  let gantt = window.ganttVisualization;
  if (!gantt) return;
  let resizedTask = gantt.getTask(id);
  const startDateCopy = moment(resizedTask.start_date).clone();
  const endDateCopy = moment(resizedTask.end_date).clone();
  const calendarWorkingHours = resizedTask.calendarWorkingHours;
  if (!calendarWorkingHours) return;

  const newStartDate = setNewHoursAndMinutes(
    startDateCopy,
    calendarWorkingHours.start
  );

  const newEndDate = setNewHoursAndMinutes(
    endDateCopy,
    calendarWorkingHours.end
  );

  resizedTask.start_date = newStartDate;
  resizedTask.end_date = newEndDate;
}
/**
 * Sets new hours and minutes for a given moment object based on provided working hours.
 *
 * @param {moment} activityDate - The moment object representing the activity's date.
 * @param {Object} calendarWorkingHours - An object containing the start hour and minutes.
 * @returns {Date} - A JavaScript Date object with updated hours and minutes.
 */
function setNewHoursAndMinutes(activityDate, calendarWorkingHours) {
  return activityDate
    .hours(calendarWorkingHours.hour)
    .minutes(calendarWorkingHours.minutes)
    .toDate();
}

export { onBeforeTaskChanged };
