import React from 'react';
import colors from '../../../stylesheets/variables.scss';

/** massive icons */
import deleteStagePath from '../../../assets/img/icons/icon--delete-stage.svg';
import { SvgIcon } from '../../../utils';

export const DeleteStageComp = (
  <SvgIcon
    path={deleteStagePath}
    className="massive-notification-icon"
    color={colors.brandOrange40}
  />
);
