/* eslint-disable no-case-declarations */
import {
  SET_RESOURCE_COLUMN_SECTORS,
  SET_RESOURCE_COLUMN_RESOURCE,
  SET_RESOURCE_COLUMN_PROJECT
} from '../actions/curveResourceActions';

/**
 * Reducer function means a switch case to filter action data on the platform for general state
 * @param {*} state Real time updated through all components state (general state flux)
 * @param {*} action Action is an object with structyure { type: STRING, payload: FUNCTION }
 */
function curveResourceReducer(
  state = { sectors: [], resources: [], projects: [] },
  action
) {
  switch (action.type) {
    case SET_RESOURCE_COLUMN_SECTORS:
      return {
        ...state,
        sectors: action.sectors
      };
    case SET_RESOURCE_COLUMN_RESOURCE:
      return {
        ...state,
        resources: action.resources
      };
    case SET_RESOURCE_COLUMN_PROJECT:
      return {
        ...state,
        projects: action.projects
      };
    default:
      return state;
  }
}

export default curveResourceReducer;
