import React, { useRef, memo, useCallback } from 'react';
import PopoverUniselectSystem from '../../../../../DesignSystem/PopoverUniselectSystem';
import { HorizontalMenuOutlineIcon } from '../../../../../../icons';
import { USER_ROLES } from '../../constants';
import Colors from '../../../../../../assets/styles/variables.scss';

const Action = memo(
  ({
    t,
    action,
    data,
    currentUser,
    isValidRole,
    handleOpenRemoveUserModal,
    handleReInviteUser
  }) => {
    const actionRef = useRef();

    const { BLACK } = Colors;

    const isHigherRole = () => {
      const currentLevel = USER_ROLES(t).find(
        (currentRole) => currentRole.value === data.role
      );

      if (!currentLevel) return false;

      const currentUserRole = USER_ROLES(t).find(
        (currentRole) => currentRole.value === currentUser.role
      );

      if (!currentUserRole) return false;

      return currentUserRole.level > currentLevel.level;
    };

    const handleRemove = useCallback(() => {
      handleOpenRemoveUserModal(data.id);
      handleClosePopover();
    }, [handleOpenRemoveUserModal, data.id]);

    const handleClosePopover = () => {
      if (actionRef.current) {
        actionRef.current.tooltip.state.visible = false;
      }
    };

    const handleReInvite = useCallback(() => {
      handleReInviteUser(data.email);
      handleClosePopover();
    }, [handleReInviteUser, data.emai]);

    const isDisabledItem = useCallback(
      () => isHigherRole() || data.id === currentUser.id,
      [isHigherRole, data.id, currentUser.id]
    );

    const items = [
      {
        key: 1,
        name: t(
          'settings.project_settings.users_tab.table_action_reinvite_text'
        ),
        active: false,
        icon: null,
        disabled: false,
        onClick: () => handleReInvite(),
        subitems: [],
        group: []
      },
      {
        key: 2,
        name: t('settings.project_settings.users_tab.table_action_remove_text'),
        active: false,
        icon: null,
        disabled: isDisabledItem(),
        onClick: () => handleRemove(),
        subitems: [],
        group: []
      }
    ];

    if (!isValidRole) return null;

    return (
      <div className="action-column">
        {action ? (
          <span
            className={`${isDisabledItem() ? 'disabled' : ''}`}
            onClick={() => !isDisabledItem() && handleRemove()}>
            {t('settings.project_settings.users_tab.table_action_remove_text')}
          </span>
        ) : (
          <PopoverUniselectSystem
            itemReference={actionRef}
            trigger="click"
            items={items}
            countItemsShow={2}
            placement="left"
            withSearch={false}>
            <div className="action-column__icon">
              <HorizontalMenuOutlineIcon color={BLACK} />
            </div>
          </PopoverUniselectSystem>
        )}
      </div>
    );
  }
);

export default Action;
