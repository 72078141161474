import { createSlice } from '@reduxjs/toolkit';

export const activitySliceKey = 'activity';

const initialState = {
  card: null,
  log: null
};

const { actions, reducer } = createSlice({
  name: activitySliceKey,
  initialState,
  reducers: {
    activityCardFetchSucceeded(state, { payload }) {
      state.card = payload;
    },
    activityCardFetchFailed(state) {
      state.card = {};
    },
    activityLogFetchSucceeded(state, { payload }) {
      state.log = payload;
    },
    activityLogFetchFailed(state) {
      state.log = [];
    },
    activityCardUpdateTaskLocally(state, { payload: updatedTask }) {
      const taskToBeChanged = state.card.tasks.find(
        (task) => task.id === updatedTask.id
      );
      Object.assign(taskToBeChanged, updatedTask);
    },
    activityCardUpdateLocally(state, { payload: updatedCard }) {
      Object.assign(state.card, { ...updatedCard });
    },
    activityCardResetData(state) {
      state.log = null;
      state.card = null;
    },
    activityCardFetchRequested(state) {
      state.card = null;
    },
    activityLogFetchRequested(state) {
      state.log = null;
    },
    activityUpdateDatesSucceeded(
      state,
      { payload: { startDate, duration, endDate } }
    ) {
      Object.assign(state.card, { startDate, duration, endDate });
    },
    activityUpdateSucceeded(state, { payload }) {
      Object.assign(state.card.originalActivityObject, payload);
    },

    // Dummy actions used in sagas
    activityUpdateRequested() {},
    activityUpdateFailed() {},
    activityUpdateDatesRequested() {},
    activityUpdateDatesFailed() {},
    activityUpdateResourceRequested() {},
    activityUpdateResourceFailed() {},
    activityUpdateResourceSucceeded() {}
  }
});

const baseSelector = (state) => state[activitySliceKey];

export function getActivityCardDataSelector(state) {
  return baseSelector(state).card;
}

export function getActivityLogDataSelector(state) {
  return baseSelector(state).log;
}

export const activityReducer = reducer;
export const {
  activityCardFetchSucceeded,
  activityCardFetchFailed,
  activityLogFetchSucceeded,
  activityLogFetchFailed,
  activityCardFetchRequested,
  activityLogFetchRequested,
  activityUpdateRequested,
  activityUpdateSucceeded,
  activityUpdateFailed,
  activityUpdateDatesRequested,
  activityUpdateDatesSucceeded,
  activityUpdateDatesFailed,
  activityCardUpdateTaskLocally,
  activityCardUpdateLocally,
  activityCardResetData,
  activityUpdateResourceRequested,
  activityUpdateResourceFailed,
  activityUpdateResourceSucceeded
} = actions;
