function setConstraints(task) {
  const gantt = window.to_use_react_gantt;
  if (task.start_date === task.last_start_date) {
    task.constraint_type = task.last_constraint;
    task.constraint_date = task.last_constraint_date;
    gantt.updateTask(task.id);
  }
}

export { setConstraints };
