import { useEffect } from 'react';

const validURLs = [
  'login',
  'confirmation',
  'report',
  'forgot',
  'signup',
  'signupconfirmation',
  'changepassword',
  'register',
  'schedules',
  'invite',
  'sso/login',
  'weekly'
];

const selectCompanyPath = '/selectCompany';

export const useCheckUserAndLocation = () => {
  const user = localStorage.getItem('signed');
  const token = localStorage.getItem('authToken');
  const sessionToken = sessionStorage.getItem('sessionToken');

  useEffect(() => {
    const includeSelectCompanyPath =
      window.location.href.includes(selectCompanyPath);
    const findURL = validURLs.find((url) => window.location.href.includes(url));
    const isValidUser = token && user;

    if (findURL) return;

    if (!isValidUser) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.href = '/login';
      return;
    }

    if (!sessionToken && !includeSelectCompanyPath) {
      window.sessionStorage.clear();
      window.location.href = '/selectCompany';
    }
  }, []);
};
