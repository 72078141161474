import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import { activityUpdateRequested } from '../../../../redux/slices/activitySlice';
import { TextInput } from '../../../TextInput';
import sharedStyles from './shared.module.scss';
import styles from './CostsAndWorkTab.module.scss';
import { updateGanttLocally } from '../../utils';

export const CostsAndWorkTab = ({ cardData, activityUpdateRequested, t }) => {
  const updateCostsAndWork = (changes) => {
    activityUpdateRequested({
      activity: { ...cardData.originalActivityObject, ...changes },
      ganttActivityPatch: { ...changes }
    });
  };

  return (
    <div className={cn(styles['dates-tab'], sharedStyles['three-by-two-tab'])}>
      <label>
        {t('activity_card.cost')}
        <TextInput
          initialValue={cardData.cost}
          inputConfig={{
            type: 'number',
            min: 0,
            maxDecimalPrecision: 2
          }}
          onChange={(cost) => {
            const gantt = window.to_use_react_gantt;
            const currentActivity = updateGanttLocally(
              cardData,
              'cost',
              cost,
              gantt
            );
            gantt.updateCostAndHH(currentActivity);
            gantt.render();
          }}
        />
      </label>
      <label>
        {t('tables.gantt.real_cost')}
        <TextInput
          initialValue={cardData.realCost}
          inputConfig={{
            type: 'number',
            min: 0,
            maxDecimalPrecision: 2
          }}
          onChange={(realCost) => updateCostsAndWork({ real_cost: realCost })}
          disabled
        />
      </label>
      <label>
        {t('activity_card.base_cost')}
        <input type="text" defaultValue={cardData.baseCost} disabled />
      </label>
      <label>
        {t('activity_card.work')}
        <TextInput
          initialValue={cardData.work}
          inputConfig={{
            type: 'number',
            min: 0,
            maxDecimalPrecision: 2
          }}
          onChange={(work) => {
            const gantt = window.to_use_react_gantt;
            const currentActivity = updateGanttLocally(
              cardData,
              'hhWorkTime',
              work,
              gantt
            );
            gantt.updateCostAndHH(currentActivity);
            gantt.render();
          }}
        />
      </label>
      <label>
        {t('activity_card.real_work')}
        <TextInput
          initialValue={cardData.realWork}
          inputConfig={{
            type: 'number',
            min: 0,
            maxDecimalPrecision: 2
          }}
          onChange={(realWork) => {
            // updateCostsAndWork({ real_work: realWork })
          }}
          disabled
        />
      </label>
      <label>
        {t('activity_card.base_work')}
        <input type="text" defaultValue={cardData.baseWork} disabled />
      </label>
    </div>
  );
};

const actionCreators = {
  activityUpdateRequested
};

export default connect(null, actionCreators)(CostsAndWorkTab);
