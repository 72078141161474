import { createHttpClient } from '../utils/httpUtils';

class CompanySettings {
  constructor() {
    this.http = createHttpClient();
  }

  async getTransferResponsibleSetting(companyId) {
    if (!this.http) {
      console.warn('No session token available, request aborted');
      return false;
    }
    const res = await this.http
      .get('companysettings/' + companyId)
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return res ? res.data : res;
  }

  async saveTransferResponsibleSetting(companyId, transferResponsible) {
    const res = await this.http
      .post('companysettings/', {
        companyId: companyId,
        code: 'TRANSFER_RESPONSIBLE',
        metadata: {
          transferResponsible: transferResponsible
        }
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return res ? res.data : res;
  }
}

export default new CompanySettings();
