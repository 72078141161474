/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class SectorBaseLinePointService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(sectorBaselinePointId) {
    const res = await this.http
      .get('sectorbaselinepoints/' + sectorBaselinePointId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(sectorBaselinePointId) {
    const res = await this.http
      .delete('sectorbaselinepoints/' + sectorBaselinePointId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(sectorBaselinePoint) {
    const res = await this.http
      .post('sectorbaselinepoints', sectorBaselinePoint)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(sectorBaselinePoint) {
    const res = await this.http
      .put(
        'sectorbaselinepoints/' + sectorBaselinePoint.id,
        sectorBaselinePoint
      )
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async createAllBaselines(data) {
    const res = await this.http
      .post('sectorbaselinepoints/createallbaselines/', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}
export const sectorBaselinePointService = new SectorBaseLinePointService();
