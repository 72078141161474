import { SET_TRANSFER_RESPONSIBLE } from '../actions/companySettingsAction';

function companySettingsReducer(state = { companySettings: {} }, action) {
  switch (action.type) {
    case SET_TRANSFER_RESPONSIBLE:
      return {
        ...state,
        transferResponsibles: action.payload
      };
    default:
      return state;
  }
}

export default companySettingsReducer;
