export const dummyProject = {
  name: 'Demo Project',
  type: 'commercial',
  country: 'US',
  currency: 'USD',
  budget: '20000',
  sizetype: 'ft2',
  size: 15000,
  stage: 'started',
  task_creter: 'duration',
  activity_creter: 'duration',
  planification_day: 1 /** monday by default */,
  pcr_goal: 80,
  pcc_goal: 80,
  release_constraint: 'editable'
};

export const dummySectors = [
  {
    name: 'Commercial Office Building',
    order: 0,
    hoursPerDay: 8,
    hoursPerWeek: 40
  },
  {
    name: 'Blank Sector',
    order: 1,
    hoursPerDay: 8,
    hoursPerWeek: 40
  }
];

export const dummyConstraintTypes = [
  {
    name: 'Change Order',
    type: 'others',
    idStr: 'change-order'
  },
  {
    name: 'Contract Release',
    type: 'procedure',
    idStr: 'contract-release'
  },
  {
    name: 'Equipment',
    type: 'machinery',
    idStr: 'equipment'
  },
  {
    name: 'Inspection',
    type: 'procedure',
    idStr: 'inspection'
  },
  {
    name: 'Material / Supplies',
    type: 'material',
    idStr: 'material'
  },
  {
    name: 'RFI',
    type: 'procedure',
    idStr: 'rfi'
  },
  {
    name: 'Space Availability',
    type: 'activity',
    idStr: 'space-availability'
  },
  {
    name: 'Submittal',
    type: 'procedure',
    idStr: 'submittal'
  },
  {
    name: 'Weather',
    type: 'ambient',
    idStr: 'weather'
  }
];

export const dummyCncTypes = [
  {
    name: 'Change Order',
    type: 'others',
    idStr: 'change-order'
  },
  {
    name: 'Equipment',
    type: 'machinery',
    idStr: 'equipment'
  },
  {
    name: 'Incorrect Time Estimate',
    type: 'plan',
    idStr: 'incorrect'
  },
  {
    name: 'Material / Supplies',
    type: 'material',
    idStr: 'material'
  },
  {
    name: 'Off Project Demands',
    type: 'others',
    idStr: 'off-project'
  },
  {
    name: 'Prior Work - Others',
    type: 'activity',
    idStr: 'prior-work-others'
  },
  {
    name: 'Prior Work - Self',
    type: 'activity',
    idStr: 'prior-work-self'
  },
  {
    name: 'RFI',
    type: 'others',
    idStr: 'rfi'
  },
  {
    name: 'Schedule / Coordination',
    type: 'plan',
    idStr: 'schedule'
  },
  {
    name: 'Space Availability',
    type: 'plan',
    idStr: 'space'
  },
  {
    name: 'Staff Availability',
    type: 'rrhh',
    idStr: 'staff'
  },
  {
    name: 'Submittal',
    type: 'others',
    idStr: 'submittal'
  },
  {
    name: 'Weather',
    type: 'weather',
    idStr: 'weather'
  }
];

export const dummyTags = [
  {
    name: 'HVAC',
    description: '#000000',
    idStr: 'hvac'
  },
  {
    name: 'Owner',
    description: '#4287f5',
    idStr: 'owner'
  },
  {
    name: 'Phase 1',
    description: '#4287f5',
    idStr: 'phase1'
  },
  {
    name: 'Sector A',
    description: '#4287f5',
    idStr: 'sectora'
  }
];

export const dummySubContracts = [
  {
    name: 'Mechanical Inc.',
    type: 'Mechanical',
    color: '#984BC4',
    idStr: 'mechanical'
  },
  {
    name: 'Concrete Inc.',
    type: 'Concrete',
    color: '#F67411',
    idStr: 'concrete'
  },
  {
    name: 'Electrical Inc.',
    type: 'Electrical',
    color: '#121212',
    idStr: 'electrical'
  },
  {
    name: 'Plumbing Inc.',
    type: 'Plumbing',
    color: '#E50101',
    idStr: 'plumbing'
  },
  {
    name: 'Steel Structure Inc.',
    type: 'Steel',
    color: '#223AB4',
    idStr: 'steel'
  },
  {
    name: 'Drywall Inc.',
    type: 'Drywall',
    color: '#984BC4',
    idStr: 'drywall'
  },
  {
    name: 'Ceilings Inc.',
    type: 'ACT',
    color: '#F67411',
    idStr: 'ceilings'
  },
  {
    name: 'Excavation Inc.',
    type: 'Excavation / Site',
    color: '#121212',
    idStr: 'excavation'
  },
  {
    name: 'Flooring Inc.',
    type: 'Flooring',
    color: '#E50101',
    idStr: 'flooring'
  },
  {
    name: 'Fire Protection Inc.',
    type: 'Fire Sprinkler',
    color: '#223AB4',
    idStr: 'fire'
  },
  {
    name: 'Painting Inc.',
    type: 'Paint',
    color: '#984BC4',
    idStr: 'painting'
  },
  {
    name: 'Roofing Inc.',
    type: 'Roofind',
    color: '#F67411',
    idStr: 'roofing'
  },
  {
    name: 'Glazing Inc.',
    type: 'Curtain Walls',
    color: '#121212',
    idStr: 'glazing'
  },
  {
    name: 'General Contractor',
    type: 'GC',
    color: '#236CF2',
    idStr: 'general'
  }
];

export const dummyUsers = [
  {
    name: 'Alice',
    lastname: 'Sup',
    email: 'superintendent@demo.com',
    role: 'planner',
    is_active: true
  },
  {
    name: 'Mike',
    lastname: 'Elec',
    email: 'electrician1@demo.com',
    role: 'fieldprofessional',
    is_active: true
  },
  {
    name: 'Miles',
    lastname: 'Exca',
    email: 'excavation@demo.com',
    role: 'fieldprofessional',
    is_active: true
  },
  {
    name: 'Joe',
    lastname: 'Plumb',
    email: 'plumber@demo.com',
    role: 'fieldprofessional',
    is_active: true
  },
  {
    name: 'Anna',
    lastname: 'Concret',
    email: 'concret@demo.com',
    role: 'fieldprofessional',
    is_active: true
  }
];

export const dummyCalendar = {
  name: 'Standard Week',
  is_default: true,
  working_days: '0,1,1,1,1,1,0',
  shift_start: 'null-null',
  shift_end: 'null-null'
};

export const dummyExceptions = [
  {
    name: 'New Year',
    unique_id: new Date().getTime(),
    from_date: '2022/01/01',
    to_date: '2022/01/01',
    workable: false
  },
  {
    name: 'Labor Day',
    unique_id: new Date().getTime(),
    from_date: '2021/09/06',
    to_date: '2021/09/06',
    workable: false
  },
  {
    name: 'Christmas',
    unique_id: new Date().getTime(),
    from_date: '2021/12/25',
    to_date: '2021/12/25',
    workable: false
  },
  {
    name: 'Thanksgiving',
    unique_id: new Date().getTime(),
    from_date: '2021/11/25',
    to_date: '2021/11/25',
    workable: false
  }
];

export const dummyActivitys = [
  {
    start_date: '2021/08/30 6:00',
    end_date: '2022/06/28 14:00',
    parent_id: '0',
    unique_id: '1600113954421',
    description: '',
    name: 'Office Building',
    duration: 214,
    cost: 1747100,
    used_cost: 55000,
    real_cost: 162392.72727272718,
    progress: 7.926445497630333,
    constraint: 'As soon As Possible',
    type: 'project',
    correlative_id: 0,
    has_childs: true,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 0,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: null,
    activitymodifications: [],
    responsables: [],
    subcontract: null,
    tags: []
  },
  {
    start_date: '2021/08/30 6:00',
    end_date: '2021/09/24 14:00',
    parent_id: '1600113954421',
    unique_id: '1600113954422',
    description: '',
    name: 'Make Ready',
    duration: 19,
    cost: 348000,
    used_cost: 0,
    real_cost: 152392.72727272718,
    progress: 74.88,
    constraint: 'As soon As Possible',
    type: 'project',
    correlative_id: 1,
    has_childs: true,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 9.95260663507109,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 42,
    reCalculatePonderator: null,
    freeSlack: null,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'general',
    tags: [
      {
        idStr: 'phase1'
      }
    ]
  },
  {
    constraint_date: '2021/08/30 6:00',
    start_date: 'week_1_1',
    // start_date: '2021/08/29 6:00',
    end_date: '2021/09/20 14:00',
    parent_id: '1600113954422',
    unique_id: '1630352765677',
    description: '',
    name: 'Permitting',
    duration: 15,
    cost: 60000,
    used_cost: 0,
    real_cost: 0,
    progress: 50,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 2,
    has_childs: false,
    isOnLookahead: true,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 57.1428571428571,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'general',
    tags: [
      {
        idStr: 'owner'
      }
    ]
  },
  {
    constraint_date: '2021/08/31 7:00',
    start_date: 'week_1_1',
    // start_date: '2021/08/29 6:00',
    end_date: '2021/09/14 14:00',
    parent_id: '1600113954422',
    unique_id: '1630352765769',
    description: '',
    name: 'Utilities',
    duration: 11,
    cost: 150000,
    used_cost: 0,
    real_cost: 81818.18181818175,
    progress: 80,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 3,
    has_childs: false,
    isOnLookahead: true,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 26.1904761904762,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'electrician1@demo.com'
      }
    ],
    subcontract: 'electrical',
    tags: []
  },
  {
    constraint_date: '2021/09/15 8:00',
    start_date: 'week_3_3',
    // start_date: '2021/09/15 6:00',
    end_date: '2021/09/24 14:00',
    parent_id: '1600113954422',
    unique_id: '1630352765676',
    description: '',
    name: 'Mobilization',
    duration: 7,
    cost: 80000,
    used_cost: 0,
    real_cost: 70574.54545454544,
    progress: 27,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 4,
    has_childs: false,
    isOnLookahead: true,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 16.6666666666667,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'general',
    tags: []
  },
  {
    constraint_date: '2021/09/30 8:00',
    start_date: 'week_4_4',
    // start_date: '2021/09/24 14:00',
    end_date: '2021/09/30 8:00',
    parent_id: '1600113954422',
    unique_id: '1630448977511',
    description: '',
    name: 'Construction Start',
    duration: 0,
    cost: 58000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'milestone',
    correlative_id: 5,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 0,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'painting',
    tags: []
  },
  {
    start_date: '2021/09/27 6:00',
    end_date: '2021/11/12 14:00',
    parent_id: '1600113954421',
    unique_id: '1600113954423',
    description: '',
    name: 'Building Foundation / Basement',
    duration: 35,
    cost: 195000,
    used_cost: 30000,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'project',
    correlative_id: 6,
    has_childs: true,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 8.29383886255924,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 35,
    reCalculatePonderator: null,
    freeSlack: 0,
    subcontractId: null,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: null,
    tags: []
  },
  {
    constraint_date: '2021/09/24 6:00',
    start_date: 'week_4_5',
    // start_date: '2021/09/27 6:00',
    end_date: '2021/10/08 14:00',
    parent_id: '1600113954423',
    unique_id: '1630352765771',
    description: '',
    name: 'Shoring',
    duration: 10,
    cost: 65000,
    used_cost: 30000,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 7,
    has_childs: false,
    isOnLookahead: true,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 28.5714285714286,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'excavation@demo.com'
      }
    ],
    subcontract: 'excavation',
    tags: [
      {
        idStr: 'hvac'
      }
    ]
  },
  {
    constraint_date: '2021/10/11 8:00',
    start_date: 'week_6_5',
    // start_date: '2021/10/11 6:00',
    end_date: '2021/10/20 14:00',
    parent_id: '1600113954423',
    unique_id: '1630352765773',
    description: '',
    name: 'Footings',
    duration: 8,
    cost: 50000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 8,
    has_childs: false,
    isOnLookahead: true,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 22.8571428571429,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'concret@demo.com'
      }
    ],
    subcontract: 'concrete',
    tags: [
      {
        idStr: 'sectora'
      }
    ]
  },
  {
    constraint_date: '2021/10/22 8:00',
    start_date: 'week_8_3',
    // start_date: '2021/10/21 6:00',
    end_date: '2021/10/29 14:00',
    parent_id: '1600113954423',
    unique_id: '1630352765775',
    description: '',
    name: 'Slab on Grade',
    duration: 7,
    cost: 30000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 9,
    has_childs: false,
    isOnLookahead: true,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 20,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'concret@demo.com'
      }
    ],
    subcontract: 'concrete',
    tags: [
      {
        idStr: 'sectora'
      }
    ]
  },
  {
    constraint_date: '2021/11/03 7:00',
    start_date: 'week_9_5',
    // start_date: '2021/11/01 6:00',
    end_date: '2021/11/12 14:00',
    parent_id: '1600113954423',
    unique_id: '1630352765776',
    description: '',
    name: 'Foundation Walls',
    duration: 10,
    cost: 50000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 10,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 28.5714285714286,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'concret@demo.com'
      }
    ],
    subcontract: 'concrete',
    tags: []
  },
  {
    start_date: '2021/11/15 6:00',
    end_date: '2022/02/08 14:00',
    parent_id: '1600113954421',
    unique_id: '1630352765679',
    description: '',
    name: 'Core and Shell',
    duration: 60,
    cost: 495700,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'project',
    correlative_id: 11,
    has_childs: true,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 16.5876777251185,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 70,
    reCalculatePonderator: null,
    freeSlack: 20,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: null,
    tags: []
  },
  {
    constraint_date: '2021/11/18 9:00',
    start_date: 'week_11_4',
    // start_date: '2021/11/15 6:00',
    end_date: '2021/11/30 14:00',
    parent_id: '1630352765679',
    unique_id: '1630352765783',
    description: '',
    name: 'Steel Structure',
    duration: 10,
    cost: 100000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 12,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 14.2857142857143,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'steel',
    tags: []
  },
  {
    constraint_date: '2021/12/07 11:00',
    start_date: 'week_14_2',
    // start_date: '2021/12/01 6:00',
    end_date: '2021/12/07 14:00',
    parent_id: '1630352765679',
    unique_id: '1630352765784',
    description: '',
    name: 'Decking',
    duration: 5,
    cost: 65000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 13,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 7.14285714285714,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'steel',
    tags: []
  },
  {
    constraint_date: '2021/12/15 8:00',
    start_date: 'week_15_2',
    // start_date: '2021/12/08 6:00',
    end_date: '2021/12/28 14:00',
    parent_id: '1630352765679',
    unique_id: '1630352765785',
    description: '',
    name: 'Elevated Slabs',
    duration: 15,
    cost: 80000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 14,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 21.4285714285714,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'concret@demo.com'
      }
    ],
    subcontract: 'concrete',
    tags: []
  },
  {
    constraint_date: '2022/01/07 7:00',
    start_date: 'week_17_2',
    // start_date: '2021/12/29 6:00',
    end_date: '2022/01/25 14:00',
    parent_id: '1630352765679',
    unique_id: '1630352765786',
    description: '',
    name: 'Exterior Skin',
    duration: 20,
    cost: 90000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 15,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 28.5714285714286,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'glazing',
    tags: []
  },
  {
    constraint_date: '2022/02/08 11:00',
    start_date: 'week_21_2',
    // start_date: '2022/01/26 6:00',
    end_date: '2022/02/08 14:00',
    parent_id: '1630352765679',
    unique_id: '1630352765787',
    description: '',
    name: 'Roofing',
    duration: 10,
    cost: 75500,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 16,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 14.2857142857143,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'roofing',
    tags: []
  },
  {
    constraint_date: '2022/01/07 7:00',
    start_date: 'week_17_2',
    // start_date: '2021/12/29 6:00',
    end_date: '2022/01/11 14:00',
    parent_id: '1630352765679',
    unique_id: '1630352765905',
    description: '',
    name: 'Risers (Utilities)',
    duration: 10,
    cost: 85200,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 17,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 14.2857142857143,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 963,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'general',
    tags: []
  },
  {
    start_date: '2021/12/29 6:00',
    end_date: '2022/01/25 14:00',
    parent_id: '1600113954421',
    unique_id: '1630426370491',
    description: '',
    name: 'Basement / Garage',
    duration: 20,
    cost: 40200,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'project',
    correlative_id: 18,
    has_childs: true,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 4.739336492891,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 20,
    reCalculatePonderator: null,
    freeSlack: 72,
    subcontractId: null,
    activitymodifications: [],
    responsables: [],
    subcontract: null,
    tags: []
  },
  {
    constraint_date: '2022/01/07 7:00',
    start_date: 'week_17_2',
    // start_date: '2021/12/29 6:00',
    end_date: '2022/01/11 14:00',
    parent_id: '1630426370491',
    unique_id: '1630426370492',
    description: '',
    name: 'Area 1',
    duration: 10,
    cost: 15200,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 19,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 50,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'concret@demo.com'
      }
    ],
    subcontract: 'concrete',
    tags: []
  },
  {
    constraint_date: '2022/01/24 9:00',
    start_date: 'week_19_2',
    // start_date: '2022/01/12 6:00',
    end_date: '2022/01/25 14:00',
    parent_id: '1630426370491',
    unique_id: '1630426370493',
    description: '',
    name: 'Loading Dock',
    duration: 10,
    cost: 25000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 20,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 50,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 883,
    activitymodifications: [],
    responsables: [
      {
        email: 'concret@demo.com'
      }
    ],
    subcontract: 'concrete',
    tags: []
  },
  {
    start_date: '2021/12/29 6:00',
    end_date: '2022/06/28 14:00',
    parent_id: '1600113954421',
    unique_id: '1630448977539',
    description: '',
    name: 'Interior',
    duration: 130,
    cost: 555200,
    used_cost: 25000,
    real_cost: 10000,
    progress: 1,
    constraint: 'As soon As Possible',
    type: 'project',
    correlative_id: 21,
    has_childs: true,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 47.39336492891,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 200,
    reCalculatePonderator: null,
    freeSlack: null,
    subcontractId: null,
    activitymodifications: [],
    responsables: [],
    subcontract: null,
    tags: []
  },

  {
    constraint_date: '2021/08/30 6:00',
    start_date: 'week_17_2',
    // start_date: '2021/12/29 6:00',
    end_date: '2022/02/08 14:00',
    parent_id: '1630448977539',
    unique_id: '1630448977540',
    description: '',
    name: 'Metal Stud Framing',
    duration: 30,
    cost: 52000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 22,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 15,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'drywall',
    tags: []
  },
  {
    constraint_date: '2021/10/15 12:00',
    start_date: 'week_23_2',
    // start_date: '2022/02/09 6:00',
    end_date: '2022/02/22 14:00',
    parent_id: '1630448977539',
    unique_id: '1630448977541',
    description: '',
    name: 'One Side Drywall',
    duration: 10,
    cost: 35000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 23,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 5,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'drywall',
    tags: []
  },
  {
    constraint_date: '2021/08/30 6:00',
    start_date: 'week_24_2',
    // start_date: '2022/02/16 6:00',
    end_date: '2022/03/15 14:00',
    parent_id: '1630448977539',
    unique_id: '1630448977542',
    description: '',
    name: 'MEP Rough In Wall',
    duration: 20,
    cost: 10000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 24,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 10,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'general',
    tags: []
  },
  {
    constraint_date: '2021/11/09 11:00',
    start_date: 'week_27_2',
    // start_date: '2022/03/09 6:00',
    end_date: '2022/03/22 14:00',
    parent_id: '1630448977539',
    unique_id: '1630448977543',
    description: '',
    name: 'Second Side Drywall',
    duration: 10,
    cost: 12000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 25,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 5,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: -16,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'drywall',
    tags: []
  },
  {
    constraint_date: '2022/04/12 11:00',
    start_date: 'week_29_1',
    // start_date: '2022/03/21 6:00',
    end_date: '2022/04/01 14:00',
    parent_id: '1630448977539',
    unique_id: '1630448977549',
    description: '',
    name: 'Restrooms Specialties',
    duration: 10,
    cost: 50000,
    used_cost: 25000,
    real_cost: 10000,
    progress: 20,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 26,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 5,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'general',
    tags: []
  },
  {
    constraint_date: '2021/08/30 6:00',
    start_date: 'week_29_1',
    // start_date: '2022/03/21 6:00',
    end_date: '2022/04/15 14:00',
    parent_id: '1630448977539',
    unique_id: '1630448977550',
    description: '',
    name: 'Control Room Equipment',
    duration: 20,
    cost: 45000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 27,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 10,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 419,
    activitymodifications: [],
    responsables: [
      {
        email: 'electrician1@demo.com'
      }
    ],
    subcontract: 'mechanical',
    tags: []
  },
  {
    constraint_date: '2021/12/06 10:00',
    start_date: 'week_29_2',
    // start_date: '2022/03/23 6:00',
    end_date: '2022/04/12 14:00',
    parent_id: '1630448977539',
    unique_id: '1630448977545',
    description: '',
    name: 'Ceiling Grid',
    duration: 15,
    cost: 75000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 28,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 7.5,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'ceilings',
    tags: []
  },
  {
    constraint_date: '2021/08/30 6:00',
    start_date: 'week_29_2',
    // start_date: '2022/03/23 6:00',
    end_date: '2022/04/19 14:00',
    parent_id: '1630448977539',
    unique_id: '1630448977544',
    description: '',
    name: 'MEP Overhead',
    duration: 20,
    cost: 60000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 29,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 10,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'general',
    tags: []
  },
  {
    constraint_date: '2022/01/06 6:00',
    start_date: 'week_33_2',
    // start_date: '2022/04/20 6:00',
    end_date: '2022/05/10 14:00',
    parent_id: '1630448977539',
    unique_id: '1630448977546',
    description: '',
    name: 'ACT',
    duration: 15,
    cost: 50000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 30,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 7.5,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'ceilings',
    tags: []
  },
  {
    constraint_date: '2021/08/30 6:00',
    start_date: 'week_33_2',
    // start_date: '2022/04/20 6:00',
    end_date: '2022/05/17 14:00',
    parent_id: '1630448977539',
    unique_id: '1630448977547',
    description: '',
    name: 'Flooring',
    duration: 20,
    cost: 32000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 31,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 10,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'flooring',
    tags: []
  },
  {
    constraint_date: '2022/02/07 10:00',
    start_date: 'week_37_2',
    // start_date: '2022/05/18 6:00',
    end_date: '2022/05/31 14:00',
    parent_id: '1630448977539',
    unique_id: '1630448977548',
    description: '',
    name: 'Specialties',
    duration: 10,
    cost: 45200,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 32,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 5,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'drywall',
    tags: []
  },
  {
    constraint_date: '2022/02/22 12:00',
    start_date: 'week_39_2',
    // start_date: '2022/06/01 6:00',
    end_date: '2022/06/07 14:00',
    parent_id: '1630448977539',
    unique_id: '1630448977551',
    description: '',
    name: 'Furnishings',
    duration: 5,
    cost: 74000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 33,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 2.5,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'general',
    tags: []
  },
  {
    constraint_date: '2022/03/02 9:00',
    start_date: 'week_40_2',
    // start_date: '2022/06/08 6:00',
    end_date: '2022/06/28 14:00',
    parent_id: '1630448977539',
    unique_id: '1630448977552',
    description: '',
    name: 'Paint',
    duration: 15,
    cost: 15000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 34,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 7.5,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'painting',
    tags: []
  },
  {
    start_date: '2022/02/09 6:00',
    end_date: '2022/04/26 14:00',
    parent_id: '1600113954421',
    unique_id: '1630448977597',
    description: '',
    name: 'Exterior',
    duration: 55,
    cost: 113000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'project',
    correlative_id: 35,
    has_childs: true,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 13.0331753554502,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 55,
    reCalculatePonderator: null,
    freeSlack: null,
    subcontractId: null,
    activitymodifications: [],
    responsables: [],
    subcontract: null,
    tags: []
  },
  {
    constraint_date: '2022/02/22 12:00',
    start_date: 'week_23_2',
    // start_date: '2022/02/09 6:00',
    end_date: '2022/03/08 14:00',
    parent_id: '1630448977597',
    unique_id: '1630426370487',
    description: '',
    name: 'Hardcape / Access',
    duration: 20,
    cost: 15000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 36,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 36.3636363636364,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'concret@demo.com'
      }
    ],
    subcontract: 'concrete',
    tags: []
  },

  {
    constraint_date: '2022/03/25 8:00',
    start_date: 'week_27_2',
    // start_date: '2022/03/09 6:00',
    end_date: '2022/04/05 14:00',
    parent_id: '1630448977597',
    unique_id: '1630426370488',
    description: '',
    name: 'Parking Area',
    duration: 20,
    cost: 78000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 37,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 36.3636363636364,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'general',
    tags: []
  },
  {
    constraint_date: '2022/04/26 12:00',
    start_date: 'week_31_2',
    // start_date: '2022/04/06 6:00',
    end_date: '2022/04/26 14:00',
    parent_id: '1630448977597',
    unique_id: '1630352765680',
    description: '',
    name: 'Landscaping',
    duration: 15,
    cost: 20000,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'task',
    correlative_id: 38,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 27.2727272727273,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 360,
    activitymodifications: [],
    responsables: [
      {
        email: 'superintendent@demo.com'
      }
    ],
    subcontract: 'excavation',
    tags: []
  },
  {
    constraint_date: '2021/08/30 6:00',
    start_date: 'week_43_1',
    // start_date: '2022/06/28 14:00',
    end_date: '2022/06/28 14:00',
    parent_id: '1600113954421',
    unique_id: '1630448977515',
    description: '',
    name: 'Substantial Completion - Owner Move In',
    duration: 0,
    cost: 0,
    used_cost: 0,
    real_cost: 0,
    progress: 0,
    constraint: 'As soon As Possible',
    type: 'milestone',
    correlative_id: 39,
    has_childs: false,
    isOnLookahead: false,
    hhWorkTime: 0,
    real_work: 0,
    ponderator: 0,
    hasCustomPonderator: false,
    sumOfDurationRecursively: 0,
    reCalculatePonderator: null,
    freeSlack: 0,
    activitymodifications: [],
    responsables: [
      {
        email: 'excavation@demo.com'
      }
    ],
    subcontract: null,
    tags: []
  }
];

export const dummyActivityRelations = [
  {
    unique_id: '1600113954424',
    source_id: '1600113954422',
    target_id: '1600113954423',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630352765830',
    source_id: '1630352765771',
    target_id: '1630352765773',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630352765831',
    source_id: '1630352765773',
    target_id: '1630352765775',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630352765832',
    source_id: '1630352765775',
    target_id: '1630352765776',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630352765833',
    source_id: '1630352765776',
    target_id: '1630352765783',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630352765834',
    source_id: '1630352765783',
    target_id: '1630352765784',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630352765835',
    source_id: '1630352765784',
    target_id: '1630352765785',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630352765836',
    source_id: '1630352765785',
    target_id: '1630352765786',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630352765837',
    source_id: '1630352765786',
    target_id: '1630352765787',
    lag: 0,
    type: 'fs'
  },
  {
    id: 1305100,
    unique_id: '1630352765906',
    source_id: '1630352765785',
    target_id: '1630352765905',
    lag: 0,
    type: 'fs'
  },
  {
    id: 1305428,
    unique_id: '1630426370494',
    source_id: '1630352765785',
    target_id: '1630426370492',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630426370495',
    source_id: '1630426370492',
    target_id: '1630426370493',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630440324259',
    source_id: '1630352765787',
    target_id: '1630426370487',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630440324260',
    source_id: '1630426370487',
    target_id: '1630426370488',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630440324261',
    source_id: '1630426370488',
    target_id: '1630352765680',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630448977513',
    source_id: '1630352765676',
    target_id: '1630448977511',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630448977514',
    source_id: '1630448977511',
    target_id: '1630352765771',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630448977516',
    source_id: '1630352765680',
    target_id: '1630448977515',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630448977553',
    source_id: '1630448977540',
    target_id: '1630448977541',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630448977554',
    source_id: '1630448977541',
    target_id: '1630448977542',
    lag: 5,
    type: 'ss'
  },
  {
    unique_id: '1630448977556',
    source_id: '1630448977542',
    target_id: '1630448977543',
    lag: 5,
    type: 'ff'
  },
  {
    unique_id: '1630448977558',
    source_id: '1630448977543',
    target_id: '1630448977549',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630448977559',
    source_id: '1630448977549',
    target_id: '1630448977550',
    lag: 0,
    type: 'ss'
  },
  {
    unique_id: '1630448977560',
    source_id: '1630448977543',
    target_id: '1630448977545',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630448977561',
    source_id: '1630448977545',
    target_id: '1630448977544',
    lag: 0,
    type: 'ss'
  },
  {
    unique_id: '1630448977562',
    source_id: '1630448977544',
    target_id: '1630448977546',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630448977563',
    source_id: '1630448977546',
    target_id: '1630448977547',
    lag: 0,
    type: 'ss'
  },
  {
    unique_id: '1630448977564',
    source_id: '1630448977547',
    target_id: '1630448977548',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630448977565',
    source_id: '1630448977548',
    target_id: '1630448977551',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630448977566',
    source_id: '1630448977551',
    target_id: '1630448977552',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630448977567',
    source_id: '1630448977552',
    target_id: '1630448977515',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630448977568',
    source_id: '1630352765785',
    target_id: '1630448977540',
    lag: 0,
    type: 'fs'
  },
  {
    unique_id: '1630448977520',
    source_id: '1630352765677',
    target_id: '1630352765769',
    lag: 0,
    type: 'ss'
  },
  {
    unique_id: '1630448977518',
    source_id: '1630352765769',
    target_id: '1630352765676',
    lag: 0,
    type: 'fs'
  }
];

export const dummyTasks = [
  {
    name: 'First Review',
    activityCorrelativeId: 2,
    duration: 13,
    start_date: 'week_1_1',
    ponderator: 50,
    progress: 100,
    idStr: 'first-review'
  },
  {
    name: 'Resubmittal',
    activityCorrelativeId: 2,
    duration: 5,
    start_date: 'week_3_2',
    ponderator: 50,
    progress: 40,
    idStr: 'resubmittal'
  },
  {
    name: 'Excavate',
    activityCorrelativeId: 3,
    duration: 5,
    start_date: 'week_1_1',
    ponderator: 20,
    progress: 100,
    idStr: 'excavate'
  },
  {
    name: 'Install Power Gear',
    activityCorrelativeId: 3,
    duration: 2,
    start_date: 'week_2_2',
    ponderator: 20,
    progress: 100,
    idStr: 'install-power-gear'
  },
  {
    name: 'Tie Into City Power',
    activityCorrelativeId: 3,
    duration: 1,
    start_date: 'week_2_5',
    ponderator: 20,
    progress: 100,
    idStr: 'tie-into-city-power'
  },
  {
    name: 'Tie Into Water and Gas',
    activityCorrelativeId: 3,
    duration: 2,
    start_date: 'week_3_1',
    ponderator: 20,
    progress: 100,
    idStr: 'tie-into-water-and-gas'
  },
  {
    name: 'Energize',
    activityCorrelativeId: 3,
    duration: 1,
    start_date: 'week_3_2',
    ponderator: 20,
    progress: 0,
    idStr: 'energize'
  },
  {
    name: 'Mobilize Trailers',
    activityCorrelativeId: 4,
    duration: 4,
    start_date: 'week_3_3',
    ponderator: 25,
    progress: 75,
    idStr: 'mobilize-trailers'
  },
  {
    name: 'Furniture',
    activityCorrelativeId: 4,
    duration: 2,
    start_date: 'week_3_5',
    ponderator: 25,
    progress: 33.33333,
    idStr: 'furniture'
  },
  {
    name: 'MEPs Hook Up',
    activityCorrelativeId: 4,
    duration: 3,
    start_date: 'week_4_1',
    ponderator: 25,
    idStr: 'meps-hook-up'
  },
  {
    name: 'Team Move In',
    activityCorrelativeId: 4,
    duration: 6,
    start_date: 'week_4_2',
    ponderator: 25
  },
  {
    name: 'Drive Piles North',
    activityCorrelativeId: 7,
    duration: 3,
    start_date: 'week_5_2',
    ponderator: 25,
    idStr: 'drive-piles-north'
  },
  {
    name: 'Drive Piles South',
    activityCorrelativeId: 7,
    duration: 3,
    start_date: 'week_5_5',
    ponderator: 25
  },
  {
    name: 'Excavate and Shore',
    activityCorrelativeId: 7,
    duration: 2,
    start_date: 'week_6_4',
    ponderator: 25
  },
  {
    name: 'Secure Shoring',
    activityCorrelativeId: 7,
    duration: 2,
    start_date: 'week_7_2',
    ponderator: 25
  },
  {
    name: 'Excavate Footings',
    activityCorrelativeId: 8,
    duration: 3,
    start_date: 'week_7_1',
    ponderator: 33.33333333
  },
  {
    name: 'Install Rebar',
    activityCorrelativeId: 8,
    duration: 4,
    start_date: 'week_7_4',
    ponderator: 33.33333333
  },
  {
    name: 'Pour Concrete',
    activityCorrelativeId: 8,
    duration: 1,
    start_date: 'week_8_2',
    ponderator: 33.33333333
  }
];

export const dummyConstraints = [
  {
    name: 'Crane available',
    user: 'superintendent@demo.com',
    type: 'equipment',
    priority: 'urgent',
    status: 'released',
    release_date: '2021/09/17',
    release_user: 'superintendent@demo.com',
    commitmentDate: 'week_3_4'
  },
  {
    name: 'RFI 00135',
    user: 'superintendent@demo.com',
    type: 'rfi',
    priority: 'normal',
    status: 'released',
    release_date: '2021/09/13',
    release_user: 'superintendent@demo.com',
    commitmentDate: 'week_3_1'
  },
  {
    name: 'Submittal Pending - Need approval and material release',
    user: 'superintendent@demo.com',
    type: 'submittal',
    priority: 'normal',
    status: 'released',
    release_date: '2021/09/07',
    release_user: 'superintendent@demo.com',
    commitmentDate: 'week_2_1',
    tasks: ['install-power-gear']
  },
  {
    name: 'Issue with Delivery',
    user: 'plumber@demo.com',
    type: 'material',
    priority: 'normal',
    status: 'released',
    release_date: '2021/09/07',
    release_user: 'superintendent@demo.com',
    commitmentDate: 'week_4_2',
    tasks: ['furniture']
  },
  {
    name: 'Inspection delayed',
    user: 'superintendent@demo.com',
    type: 'inspection',
    priority: 'normal',
    status: 'notreleased',
    release_date: '2021/09/07',
    release_user: 'superintendent@demo.com',
    commitmentDate: 'week_4_2',
    tasks: ['meps-hook-up']
  },
  {
    name: 'Equipment delayed, will not be on site',
    user: 'superintendent@demo.com',
    type: 'equipment',
    priority: 'normal',
    status: 'notreleased',
    release_date: '2021/09/07',
    release_user: 'superintendent@demo.com',
    commitmentDate: 'week_4_4',
    tasks: ['drive-piles-north']
  },
  {
    name: 'RFI 19 - Specify Gas Shutdown Requirement',
    user: 'plumber@demo.com',
    type: 'rfi',
    priority: 'normal',
    status: 'released',
    release_date: '2021/09/07',
    release_user: 'superintendent@demo.com',
    commitmentDate: 'week_1_3',
    tasks: ['tie-into-water-and-gas']
  },
  {
    name: 'Crane available expired',
    user: 'superintendent@demo.com',
    type: 'equipment',
    priority: 'urgent',
    status: 'expired',
    release_date: '2021/09/17',
    release_user: 'superintendent@demo.com',
    commitmentDate: 'week_4_1'
  }
];

export const dummyWeekCommitment = [
  {
    week: 'week_1',
    commitment_tasks: 2,
    realized_tasks: 2,
    tasks: [
      {
        idStr: 'first-review',
        commitment: 50,
        realized: 50
      },
      {
        idStr: 'excavate',
        commitment: 100,
        realized: 100
      }
    ]
  },
  {
    week: 'week_2',
    commitment_tasks: 4,
    realized_tasks: 3,
    tasks: [
      {
        idStr: 'first-review',
        commitment: 90,
        realized: 90
      },
      {
        idStr: 'install-power-gear',
        commitment: 100,
        realized: 100
      },
      {
        idStr: 'tie-into-city-power',
        commitment: 100,
        realized: 100
      },
      {
        idStr: 'tie-into-water-and-gas',
        commitment: 100,
        realized: 90
      }
    ]
  },
  {
    week: 'week_3',
    commitment_tasks: 5,
    realized_tasks: 4,
    tasks: [
      {
        idStr: 'first-review',
        commitment: 100,
        realized: 100
      },
      {
        idStr: 'resubmittal',
        commitment: 40,
        realized: 30
      },
      {
        idStr: 'energize',
        commitment: 100,
        realized: 100
      },
      {
        idStr: 'mobilize-trailers',
        commitment: 75,
        realized: 75
      },
      {
        idStr: 'furniture',
        commitment: 30,
        realized: 30
      }
    ]
  }
];

export const dummyCncs = [
  {
    name: 'No Forklift Available',
    type: 'equipment',
    priority: 'normal',
    status: 'unsolved',
    week: 'week_2',
    tasks: [
      {
        idStr: 'tie-into-water-and-gas'
      }
    ]
  },
  {
    name: 'City closed',
    type: 'submittal',
    priority: 'normal',
    status: 'unsolved',
    week: 'week_3',
    tasks: [
      {
        idStr: 'resubmittal'
      }
    ]
  }
];

export const defaultConstraintTypes = (t) => [
  {
    name: t('lang') === 'es' ? 'Actividad Predecesora' : 'Predecessors',
    type: 'activity'
  },
  {
    name: t('lang') === 'es' ? 'RDI' : 'RFI',
    type: 'design'
  },
  {
    name: t('lang') === 'es' ? 'Planos' : 'Drawings',
    type: 'design'
  },
  {
    name: t('lang') === 'es' ? 'Documentación' : 'Submittal',
    type: 'submittal'
  },
  {
    name: t('lang') === 'es' ? 'Mano de Obra' : 'Labor',
    type: 'rrhh'
  },
  {
    name: t('lang') === 'es' ? 'Equipos / Maquinaria' : 'Equipment',
    type: 'machinery'
  },
  {
    name: t('lang') === 'es' ? 'Materiales' : 'Material',
    type: 'material'
  },
  {
    name: t('lang') === 'es' ? 'Medio Ambiente' : 'Environment',
    type: 'ambient'
  },
  {
    name: t('lang') === 'es' ? 'Clima' : 'Weather',
    type: 'ambient'
  },
  {
    name: t('lang') === 'es' ? 'Permisos' : 'Permit',
    type: 'permissions'
  },
  {
    name: t('lang') === 'es' ? 'Contrato' : 'Contract',
    type: 'contract'
  },
  {
    name: t('lang') === 'es' ? 'Solicitud de Cliente' : 'Owner Request',
    type: 'client'
  },
  {
    name: t('lang') === 'es' ? 'Inspección' : 'Inspection',
    type: 'procedure'
  },
  {
    name: t('lang') === 'es' ? 'Retrabajo' : 'Rework',
    type: 'procedure'
  },
  {
    name: t('lang') === 'es' ? 'Cambio de Alcance' : 'Change Order',
    type: 'change'
  },
  {
    name: t('lang') === 'es' ? 'Proveedores' : 'Supply Chain',
    type: 'provider'
  },
  {
    name: t('lang') === 'es' ? 'Seguridad' : 'Safety',
    type: 'risks'
  }
];

export const defaultCncTypes = (t) => [
  {
    name:
      t('lang') === 'es'
        ? 'Actividad Predecesora / Propia'
        : 'Predecessors / Self',
    type: 'activity'
  },
  {
    name:
      t('lang') === 'es'
        ? 'Actividad Predecesora / Otros'
        : 'Predecessors / Others',
    type: 'activity'
  },
  {
    name: t('lang') === 'es' ? 'RDI' : 'RFI',
    type: 'design'
  },
  {
    name: t('lang') === 'es' ? 'Planos' : 'Drawings',
    type: 'design'
  },
  {
    name: t('lang') === 'es' ? 'Mano de Obra' : 'Labor',
    type: 'rrhh'
  },
  {
    name: t('lang') === 'es' ? 'Equipos / Maquinaria' : 'Equipment',
    type: 'machinery'
  },
  {
    name: t('lang') === 'es' ? 'Materiales' : 'Material',
    type: 'material'
  },
  {
    name: t('lang') === 'es' ? 'Clima' : 'Weather',
    type: 'weather'
  },
  {
    name: t('lang') === 'es' ? 'Permisos' : 'Permit',
    type: 'permissions'
  },
  {
    name: t('lang') === 'es' ? 'Contrato' : 'Contract',
    type: 'contract'
  },
  {
    name: t('lang') === 'es' ? 'Solicitud de Cliente' : 'Owner Decision',
    type: 'client'
  },
  {
    name: t('lang') === 'es' ? 'Inspección' : 'Inspection',
    type: 'quality'
  },
  {
    name: t('lang') === 'es' ? 'Cambio de Alcance' : 'Change Order',
    type: 'change'
  },
  {
    name:
      t('lang') === 'es'
        ? 'Mala Estimación de Tiempo'
        : 'Incorrect Time Estimate',
    type: 'plan'
  },
  {
    name:
      t('lang') === 'es' ? 'Disponibilidad de Espacio' : 'Space Availability',
    type: 'plan'
  },
  {
    name: t('lang') === 'es' ? 'Seguridad' : 'Safety',
    type: 'risks'
  },
  {
    name: t('lang') === 'es' ? 'Paralización de Trabajos' : 'Work Stoppage',
    type: 'others'
  },
  {
    name: t('lang') === 'es' ? 'Condición del Sitio' : 'Site Condition',
    type: 'others'
  }
];

export const defaultSubContracts = [
  {
    name: 'default',
    type: 'General Contractor',
    color: '#236CF2'
  }
];
