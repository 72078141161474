import React, { useCallback, useEffect, useRef, useState } from 'react';
import PopoverSystemV2 from '../../../../DesignSystem/PopoverSystemV2';
import {
  ThreeDotsHorizontal,
  PencilOutlineIcon,
  DuplicateOutlineIcon,
  TrashOutlineIcon
} from '../../../../../icons';
import { withTranslation } from 'react-i18next';

const MoreOptionsPopover = ({ project, t }) => {
  const gantt = window.ganttScheduleOfSchedule || {};
  const [visible, setVisible] = useState(false);
  const popoverRef = useRef(null);
  const treeDotsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        treeDotsRef.current &&
        !treeDotsRef.current.contains(event.target)
      ) {
        setVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popoverRef, treeDotsRef]);

  const handleEditClick = useCallback(() => {
    setVisible(false);
    const originalProject =
      gantt.processedProjects && gantt.processedProjects[project.id];
    if (originalProject)
      gantt.handleEdit && gantt.handleEdit(null, originalProject);
  }, [gantt, project]);

  const handleDeleteClick = useCallback(() => {
    setVisible(false);
    const originalProject =
      gantt.processedProjects && gantt.processedProjects[project.id];
    if (originalProject)
      gantt.handleDelete && gantt.handleDelete(null, originalProject);
  }, [gantt, project]);

  const contentPopover = () => (
    <div className="more-options-popover__content" ref={popoverRef}>
      <div className="content__item" onClick={() => handleEditClick()}>
        <div>
          <PencilOutlineIcon color="#53C255" size={16} />
        </div>
        <span>{t('scheduleofschedule.toolbar.EDIT')}</span>
      </div>

      <div className="content__item" onClick={() => handleDeleteClick()}>
        <div>
          <TrashOutlineIcon color="#53C255" size={16} />
        </div>
        <span>{t('scheduleofschedule.toolbar.DELETE')}</span>
      </div>
    </div>
  );

  return (
    <div className="more-options-popover" ref={treeDotsRef}>
      <PopoverSystemV2
        trigger={'click'}
        visible={visible}
        content={contentPopover()}>
        <div onClick={() => setVisible(true)}>
          <ThreeDotsHorizontal color="#B3B3B3" size={14} />
        </div>
      </PopoverSystemV2>
    </div>
  );
};

export default withTranslation()(MoreOptionsPopover);
