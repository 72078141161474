/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';
class WeekCommitmentService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(weekCommitmentId) {
    const res = await this.http
      .get('weekcommitments/' + weekCommitmentId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(weekCommitmentId) {
    const res = await this.http
      .delete('weekcommitments/' + weekCommitmentId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async searchbyweek(data) {
    const res = await this.http
      .post('weekcommitments/searchbyweek/', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http
      .post('weekcommitments/', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('weekcommitments/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async searchBySector(sectorId) {
    const data = { sector_id: sectorId };
    const res = await this.http
      .post('weekcommitments/searchbysector/', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getByWeekly(data) {
    const res = await this.http
      .post('weekcommitments/getbyweekly', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getByWeeklyWidget(data) {
    const res = await this.http
      .post('weekcommitments/searchbyweekwidgetd', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const weekCommitmentService = new WeekCommitmentService();
