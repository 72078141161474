import React, {
  useEffect,
  useState,
  useRef,
  createContext,
  forwardRef
} from 'react';
import { Select, Spin, Icon } from 'antd';
import PropTypes from 'prop-types';
import './index.css';
import { VariableSizeGrid as Grid } from 'react-window';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import LazilyRender from 'react-lazily-render';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
/** Sticky */

const StickyListContext = createContext();
StickyListContext.displayName = 'StickyListContext';

const ItemWrapper = (data) => {
  const { rowIndex, style } = data;
  const { ItemRenderer, stickyIndices } = data.data;
  if (stickyIndices && stickyIndices.includes(rowIndex)) {
    return null;
  }

  !style.width && (style.width = '100%');
  return <ItemRenderer rowIndex={rowIndex} style={style} />;
};

const innerElementType = (data) =>
  forwardRef(({ children, ...rest }, ref) => (
    <StickyListContext.Consumer>
      {({ stickyIndices }) => (
        <div ref={ref} {...rest}>
          {stickyIndices.map((index) =>
            StickyRow(data)({
              rowIndex: index,
              key: index,
              style: { top: index * 35, left: 0, width: '100%', height: 35 }
            })
          )}
          {children}
        </div>
      )}
    </StickyListContext.Consumer>
  ));

const StickyList = (data) => {
  const {
    children,
    stickyIndices,
    innerElementType,
    toUseRef,
    columnCount,
    columnWidth,
    height,
    rowCount,
    rowHeight,
    width
  } = data;
  return (
    <StickyListContext.Provider
      value={{ ItemRenderer: children, stickyIndices }}>
      <Grid
        ref={(reference) => {
          toUseRef.current = reference;
          window.rowsActivities = reference;
        }}
        columnCount={columnCount}
        columnWidth={columnWidth}
        height={height}
        rowCount={rowCount}
        rowHeight={rowHeight}
        width={width}
        innerElementType={innerElementType}
        itemData={{ ItemRenderer: children, stickyIndices }}>
        {ItemWrapper}
      </Grid>
    </StickyListContext.Provider>
  );
};

const { Option } = Select;

const recursiveWeeklyCommitments = (task, totalTask) => {
  if (task.taskcommitments.length) totalTask.push(task);

  if (task.children.length)
    task.children.forEach((child) =>
      recursiveWeeklyCommitments(child, totalTask)
    );
};

const renderFilterTasks = (
  tasks,
  tasktRendered,
  isWeeklyplan,
  isWeeklyCommitments
) => {
  if (isWeeklyCommitments) return;
  for (const task of tasks) {
    isWeeklyplan
      ? !task.hide && task.showWeeklyPlan && tasktRendered.push(task)
      : !task.hide && tasktRendered.push(task);

    if (task.children?.length) {
      renderFilterTasks(task.children, tasktRendered, isWeeklyplan);
    }
  }
};

/**
 * This function allows virtualization to use dynamic height for virtualized elements
 * @param {*} data Whole object with data { jsx, data }
 * @param {*} itemSize Default item size
 */
const getItemSize =
  (data, itemSize, isFilterdData, lookahead, isWeeklyCommitments) =>
  (index) => {
    if (index == 0) {
      return 30;
    }

    if (isWeeklyCommitments) {
      const taskCommitments = [];
      data[index].data.tasks.forEach((task) =>
        recursiveWeeklyCommitments(task, taskCommitments)
      );
      return 30 * (2 + taskCommitments.length);
    }

    // Itemsize is for handling row size
    if (data[index].data.childRended) {
      let listHeight = data[index].data.childRended * 40 + 300;
      if (data[index].data.hide_childs) {
        return 90;
      } else if (listHeight > itemSize + 200) {
        listHeight = itemSize + 20;
      } else {
        listHeight -= 211;
      }

      return listHeight;
    }
    const childRender = data[index].childRender;
    if (!childRender && isFilterdData) {
      return 180;
    }

    if (!childRender) {
      return lookahead ? 90 : 181;
    }

    const activityHeight = childRender * 40 + 110;
    if (activityHeight > itemSize + 200) return itemSize + 90;
    return activityHeight;
  };

const StickyRow =
  (data) =>
  ({ rowIndex, style }) => {
    if (!data[rowIndex]) {
      return <div key={rowIndex}></div>;
    }
    return (
      <div className="sticky" style={style} key={rowIndex}>
        {data[rowIndex].jsx}
      </div>
    );
  };

const LazyElement =
  (data) =>
  ({ rowIndex, style }) => {
    if (!data[rowIndex]) {
      return <div></div>;
    }
    return <div style={style}>{data[rowIndex].jsx}</div>;
  };

function Paginator(props) {
  const {
    massiveSelection,
    current,
    perPage,
    data,
    renderItem,
    setCurrentPage,
    itemSize,
    virtualize,
    resizing,
    lazy,
    totalTablesWidth,
    adjustHeight,
    adjustWeigth,
    isFilterdData,
    lookahead = false,
    weeklyplan = false,
    weeklyCommitments = false,
    widthParent = false
  } = props;
  const [state, setState] = useState({
    current: null,
    perPage: null,
    data: null,
    fromIndex: null
  });
  const { height, width } = useWindowDimensions();
  const virtualizeRef = useRef();

  useEffect(() => {
    setState({
      ...state,
      current: state.current || current,
      perPage: state.perPage || perPage,
      data: data,
      fromIndex: 0
    });
  }, [data]);

  const printPerPage = () => {
    const jsxArray = [];

    let containerHeight = height - (adjustHeight || 193);
    const containerWidth = width - (adjustWeigth || 75);
    for (let i = 0; i < state.data.length; i++) {
      if (state.data[i]) {
        if (i == 0) {
          jsxArray.push({
            jsx: renderItem(state.data[i], i, virtualizeRef),
            data: state.data[i]
          });
        } else {
          let offsetForLazy = state.data[i].childRended
            ? state.data[i].childRended * 30
            : 0;

          if (offsetForLazy > itemSize) {
            offsetForLazy = itemSize;
          }

          if (data[i]) {
            const childRender = [];
            const { tasks } = data[i];

            renderFilterTasks(
              tasks,
              childRender,
              weeklyplan,
              weeklyCommitments
            );
            if (isFilterdData && !childRender.length) {
              continue;
            }

            jsxArray.push({
              jsx: (
                <LazilyRender offset={offsetForLazy * 0.7} key={i}>
                  {(render) =>
                    render ? (
                      renderItem(state.data[i], i, virtualizeRef)
                    ) : (
                      <div style={{ textAlign: 'center', marginTop: 20 }}>
                        {props.t ? (
                          props.t('resource_curve_loading')
                        ) : (
                          <Spin indicator={antIcon} />
                        )}
                      </div>
                    )
                  }
                </LazilyRender>
              ),
              data: state.data[i],
              childRender: childRender.length
            });
          }
        }
      }
    }

    if (massiveSelection) {
      if (massiveSelection.length) {
        containerHeight -= 30;
      }
    }

    // eslint-disable-next-line no-constant-condition
    return (
      <StickyList
        toUseRef={virtualizeRef}
        innerElementType={innerElementType(jsxArray)}
        stickyIndices={[0]}
        columnCount={1}
        columnWidth={(_) => totalTablesWidth + 120}
        height={containerHeight}
        rowCount={jsxArray.length}
        rowHeight={getItemSize(
          jsxArray,
          itemSize,
          isFilterdData,
          lookahead,
          weeklyCommitments
        )}
        width={widthParent || containerWidth}>
        {LazyElement(jsxArray)}
      </StickyList>
    );
  };

  return <div>{state.data ? printPerPage() : 'loading...'}</div>;
}

/** Props definition */
Paginator.propTypes = {
  current: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired
};

/** Item rendered on pagination */
const Item = (props) => {
  const { name } = props;
  return <div> {name} </div>;
};

/** Usage example */
function App() {
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([
    { name: 'Soy el elemento 1' },
    { name: 'Soy el elemento 2' },
    { name: 'Soy el elemento 3' },
    { name: 'Soy el elemento 4' },
    { name: 'Soy el elemento 5' },
    { name: 'Soy el elemento 6' },
    { name: 'Soy el elemento 7' }
  ]);

  return (
    <div className="App">
      <Paginator
        current={currentPage}
        setCurrentPage={setCurrentPage}
        data={data}
        renderItem={(item, key) => <Item name={item.name} key={key} />}
        perPage={5}
      />
    </div>
  );
}

export default Paginator;
