export const ACTIONS = {
  MATRIX: 'matrix',
  RESOURCE_LIST: 'resource_list',
  HEADER: 'header',
  TOTAL: 'total',
  TOTAL_COL: 'total_col'
};

export default {
  [ACTIONS.MATRIX]: ({
    header,
    list,
    total,
    total_col,
    scrollLeft,
    scrollTop
  }) => {
    if (header.current) {
      header.current.scrollTo({ scrollLeft: scrollLeft, scrollTop: 0 });
    }
    if (list.current) {
      list.current.scrollTo({ scrollTop: scrollTop, scrollLeft: 0 });
    }
    if (total.current) {
      total.current.scrollTo({ scrollTop: scrollTop, scrollLeft: 0 });
    }
    if (total_col.current) {
      total_col.current.scrollTo({ scrollLeft: scrollLeft, scrollTop: 0 });
    }
  },
  [ACTIONS.TOTAL]: ({ list, matrix, scrollLeft, scrollTop }) => {
    if (matrix.current) {
      matrix.current.scrollTo({ scrollTop });
    }
    if (list.current) {
      list.current.scrollTo({ scrollTop: scrollTop, scrollLeft: 0 });
    }
  },
  [ACTIONS.RESOURCE_LIST]: ({ matrix, total, scrollTop }) => {
    if (matrix.current) {
      matrix.current.scrollTo({ scrollTop });
    }
    if (total.current) {
      total.current.scrollTo({ scrollTop: scrollTop, scrollLeft: 0 });
    }
  },
  [ACTIONS.HEADER]: ({ matrix, scrollLeft }) => {
    if (matrix.current) {
      matrix.current.scrollTo({ scrollLeft });
    }
  }
};
