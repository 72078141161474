import { createHttpClient } from '../utils/httpUtils';

class WorkerService {
  constructor() {
    this.http = createHttpClient();
    this.url = 'workers';
  }

  async show() {
    const res = await this.http.get(this.url).catch((error) => {
      console.error(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async destroy(workerId) {
    const res = await this.http
      .delete(`${this.url}/${workerId}}`)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post(this.url, data).catch((error) => {
      console.log(error.response);
      return error.response;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put(`${this.url}/${data.id}}`, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getCrewWorkersByProject(projectId, date) {
    const res = await this.http
      .get(`${this.url}/withcrew?project=${projectId}&date=${date}`)
      .catch((error) => {
        console.error(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}
export const workerService = new WorkerService();
