import React from 'react';
import { ganttAPI } from '../../../../utils/customGanttPlugin';
import { findDeepGetActivityOfTask } from '../../../../views/lookahead/planification/index.helper';
import {
  findDeepGetTask,
  updateAsyncTaskGanttV2,
  isGrouped,
  getColorByStatus,
  updateAsyncActivityGantt,
  updateActivityParent
} from '../../GanttVisualization.helper';
import cloneDeep from 'lodash/cloneDeep';
import { taskService } from '../../../../services';
import {
  calculateExpected,
  calculatePonderators,
  calculateProgress
} from '../../../../utils/lookahead-common';
export const TestIds = {
  RENDER_PROGRESS_CONTAINER: 'RENDER_PROGRESS_CONTAINER'
};

const ReturnTestWrapped = ({ children, other }) =>
  `<div className=${other ? 'new-fix-looakhead-div' : ''} data-testid=${
    TestIds.RENDER_PROGRESS_CONTAINER
  }>${children}</div>
  `;

const RenderProgress = ({
  task,
  node,
  column,
  activities,
  gantt,
  t,
  onlyRead,
  projectState,
  updateAsyncActivity
}) => {
  if (!task) return null;
  let hasChild;

  if (task.is_milestone) return ReturnTestWrapped({ children: '' });

  if (isGrouped()) {
    return ReturnTestWrapped({ children: '' });
  }

  if (!task.isTask) {
    return ReturnTestWrapped({
      children: `
      <span className="vertical-center-dates">
      <span className="column-disabled">${
        t('lang') === 'en'
          ? task[column.name]?.toFixed(
              Number.isInteger(task[column.name]) ? 0 : 2
            ) + '%'
          : task[column.name]
              ?.toFixed(Number.isInteger(task[column.name]) ? 0 : 2)
              .replace('.', ',') + '%'
      }</span>
     </span>
      `
    });
  }

  if (gantt.hasChild) {
    hasChild = gantt.hasChild(task.id);
  }

  let isOdd = false;
  if (gantt.oddColsConfig) {
    isOdd = gantt.oddColsConfig.progress;
  }
  // let color;

  /** get activity reference */
  const findAct = findDeepGetActivityOfTask(window.activities, 'id', task.id);
  let activityReference;
  if (findAct) activityReference = findAct;
  if (!activityReference) return ReturnTestWrapped({ children: '' });

  /** get reference task (taskFromLookahead) from activities */
  const taskFromLookahead = findDeepGetTask(activities, 'id', task.id);
  if (!taskFromLookahead) return ReturnTestWrapped({ children: '' });

  const itemCopy = cloneDeep(taskFromLookahead);
  task.expected = calculateExpected(
    itemCopy,
    ganttAPI,
    activityReference.calendarId
  );

  if (task.progress > 99.99) {
    task.status = 'Done';
  } else if (task.expected > task.progress) {
    task.status = 'Overdue';
  } else if (task.progress > task.expected) {
    task.status = 'Advancement';
  } else if (task.progress == 0 && task.expected == 0) {
    task.status = 'Waiting';
  } else if (task.progress > 0 && task.progress < task.expected) {
    task.status = 'Doing';
  } else {
    task.status = 'Waiting';
  }
  taskFromLookahead.status = task.status;
  /** get Color by status */
  const color = getColorByStatus(task);

  let parentTask = null;
  let disabled = true;
  if (taskFromLookahead) {
    disabled = taskFromLookahead.children.length > 0;
    if (taskFromLookahead.parent_id) {
      const doesExistAtReferenceParent = findDeepGetTask(
        activities,
        'id',
        taskFromLookahead.parent_id
      );
      parentTask = doesExistAtReferenceParent || null;
    }
  }

  let progressColor = '#52c41a';
  if (taskFromLookahead.progress.toFixed(2) == '100.00') {
    progressColor = '#34AF00';
    taskFromLookahead.Advancement = false;
    taskFromLookahead.Overdue = false;
  } else if (taskFromLookahead.progress > taskFromLookahead.expected) {
    progressColor = '#2C3421';
    taskFromLookahead.Advancement = true;
    taskFromLookahead.Overdue = false;
  } else if (taskFromLookahead.progress < taskFromLookahead.expected) {
    progressColor = '#E50101';
    taskFromLookahead.Overdue = true;
    taskFromLookahead.Advancement = false;
  } else {
    progressColor = '#52c41a';
    taskFromLookahead.Overdue = false;
    taskFromLookahead.Advancement = false;
  }
  taskFromLookahead.progressColor = progressColor;

  const getProgressForParent = (parent) => {
    const childs = parent.tasks || parent.children;
    let progress = 0;
    childs.map((toExtractFrom) => {
      progress += (toExtractFrom.ponderator / 100) * toExtractFrom.progress;
    });
    parent.progress = progress;
  };

  /**
   * this function calculate progress recursively to up
   * @param {*} idTask id of the task from which the calculation will be made
   */
  const updateAsyncParentRecursive = (idTask) => {
    const parentAct = gantt.getTask(idTask);
    const getReferenceTask = findDeepGetTask(activities, 'id', idTask);
    if (!getReferenceTask) return;

    getProgressForParent(getReferenceTask);

    if (gantt.isTaskExists(parentAct.id)) {
      parentAct.progress = getReferenceTask.progress;
      gantt.updateTask(parentAct.id);
    }

    if (getReferenceTask.parent_id) {
      updateAsyncParentRecursive(getReferenceTask.parent_id);
    }
  };

  // taskFromLookahead ORIGINAL REACT STRUCTURE DATA (lookahead)
  // ------   ESTRUCTURA DE DATOS DE LA GANTT DHTMLX

  /**
   * This function updates some data from a copy of the original lookahead ref (react)
   * @param {*} taskCallback Clone of lookahead (react) ref
   */
  const updateData = async (taskCallback) => {
    /** check status according progress */
    if (taskCallback.progress == 0) {
      taskCallback.status = 'Waiting';
    } else if (taskCallback.progress > 0 && taskCallback.progress < 100) {
      taskCallback.status = 'Doing';
    } else {
      taskCallback.status = 'Done';
    }
    /** update task */
    const actualQuanti =
      (taskCallback.progress / 100) * taskCallback.total_quantity;
    taskCallback.actual_quantity = actualQuanti;

    // We besides update original lookahead ref (because function context is about the copy one which is called taskCallback)
    taskFromLookahead.actual_quantity = actualQuanti;

    // Se lanza una actualizacion de datos al servidor
    await updateAsyncTaskGanttV2(
      taskCallback,
      [column.name, 'status', 'actual_quantity'],
      null,
      gantt,
      true,
      false,
      false,
      true,
      true
    );

    setTimeout(() => {
      const ganttRef = gantt.getTask(taskFromLookahead.id);
      if (ganttRef) {
        ganttRef[column.name] = taskFromLookahead[column.name];
        ganttRef.status = taskFromLookahead.status;
        ganttRef.actual_quantity = taskFromLookahead.actual_quantity;
        /** update parent */
        if (taskFromLookahead.parent_id) {
          updateAsyncParentRecursive(taskFromLookahead.parent_id);
        }
      }
    }, 500);
  };

  const saveProgress = (
    updateAsyncTask,
    task,
    newProgress,
    parent,
    activity,
    updateAsyncActivity
  ) => {
    if (!onlyRead) {
      task[column.name] = newProgress;
      if (newProgress == 0) {
        if (calculatePonderators) {
          calculatePonderators(
            parent || activity,
            activity,
            updateAsyncTask,
            projectState
          );
        }
      }

      if (updateAsyncTask) {
        updateAsyncTask(task);
      } else {
        taskService.update(task);
      }
      calculateProgress(
        task,
        parent,
        activity,
        updateAsyncTask,
        updateAsyncActivity
      );
      // updateTask(task)
    }
  };

  const onChangeProgress = (progress) => {
    saveProgress(
      updateData,
      taskFromLookahead,
      parseFloat(progress),
      parentTask || activityReference,
      activityReference,
      updateAsyncActivityGantt(
        gantt,
        task,
        updateAsyncActivity,
        updateActivityParent,
        projectState.sectorSelected
      )
    );
  };

  task.onChangeProgress = onChangeProgress;

  return ReturnTestWrapped({
    children: `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" 
            style="font-weight: ${hasChild ? '600' : '100'}; color: ${color}; 
            cursor: ${onlyRead ? 'not-allowed' : 'pointer'};">
                ${
                  t('lang') === 'en'
                    ? parseFloat(task.progress).toFixed(
                        Number.isInteger(task.progress) ? 0 : 2
                      ) + '%'
                    : parseFloat(task.progress)
                        .toFixed(Number.isInteger(task.progress) ? 0 : 2)
                        .replace('.', ',') + '%'
                }
            </div>
            `,
    other: true
  });
};

export default RenderProgress;
