function checkIfActivityViolateConstraint(activity, multipleDrag = false) {
  if (!activity.new_dates_drag) return;

  const constraint = activity.new_dates_drag.task_original.constraint_type_old;
  const actualConstraintDate = activity.last_constraint_date;
  const actualConstraintType = activity.last_constraint;

  const gantt = window.to_use_react_gantt;

  const CONSTRAINT_TYPES = {
    MFO: 'mfo',
    MSO: 'mso',
    FNLT: 'fnlt',
    FNET: 'fnet',
    SNET: 'snet',
    SNLT: 'snlt'
  };

  if (
    [CONSTRAINT_TYPES.MSO].includes(constraint) &&
    Number(actualConstraintDate) !== Number(activity.new_dates_drag.start_date)
  ) {
    gantt.notificationConstraint(
      actualConstraintDate,
      actualConstraintType,
      activity.id,
      multipleDrag
    );
    return resetActivityToLastState(activity);
  }
  if (
    [CONSTRAINT_TYPES.MFO].includes(constraint) &&
    Number(actualConstraintDate) !== Number(activity.new_dates_drag.end_date)
  ) {
    gantt.notificationConstraint(
      actualConstraintDate,
      actualConstraintType,
      activity.id,
      multipleDrag
    );
    return resetActivityToLastState(activity);
  }
  if (
    [CONSTRAINT_TYPES.SNET].includes(constraint) &&
    Number(actualConstraintDate) > Number(activity.new_dates_drag.start_date)
  ) {
    gantt.notificationConstraint(
      actualConstraintDate,
      actualConstraintType,
      activity.id,
      multipleDrag
    );
    return resetActivityToLastState(activity);
  }
  if (
    [CONSTRAINT_TYPES.SNLT].includes(constraint) &&
    Number(actualConstraintDate) < Number(activity.new_dates_drag.start_date)
  ) {
    gantt.notificationConstraint(
      actualConstraintDate,
      actualConstraintType,
      activity.id,
      multipleDrag
    );
    return resetActivityToLastState(activity);
  }
  if (
    [CONSTRAINT_TYPES.FNLT].includes(constraint) &&
    Number(actualConstraintDate) < Number(activity.new_dates_drag.end_date)
  ) {
    gantt.notificationConstraint(
      actualConstraintDate,
      actualConstraintType,
      activity.id,
      multipleDrag
    );
    return resetActivityToLastState(activity);
  }
  if (
    [CONSTRAINT_TYPES.FNET].includes(constraint) &&
    Number(actualConstraintDate) > Number(activity.new_dates_drag.end_date)
  ) {
    gantt.notificationConstraint(
      actualConstraintDate,
      actualConstraintType,
      activity.id,
      multipleDrag
    );
    return resetActivityToLastState(activity);
  }

  return resetActivityToLastState(activity, false);
}

const resetActivityToLastState = (activity, violateConstraint = true) => {
  const constraintValidation = localStorage.getItem('constraintValidation');

  if (constraintValidation === 'false' || constraintValidation === null) {
    if (activity.new_dates_drag.mode === 'resize') {
      allowDragToNewDates(activity);
    }
    return false;
  }

  if (activity.new_dates_drag.mode === 'resize') {
    if (violateConstraint) {
      resetDragToOldDates(activity);
      return true;
    }
    allowDragToNewDates(activity);
    return false;
  }

  if (!violateConstraint) return false;

  resetData();
  return true;
};

function allowDragToNewDates(activity) {
  activity.constraint_type = 'asap';
  activity.real_constraint_type = 'asap';
  activity.constraint_type_old = 'asap';
  activity.last_constraint = activity.new_dates_drag.start_date;
  activity.constraint_date = activity.new_dates_drag.start_date;
  activity.last_constraint_date = activity.last_constraint_date;
}

function resetDragToOldDates(activity) {
  activity.start_date = activity.last_start_date;
  activity.end_date = activity.last_end_date;
  activity.duration = activity.last_duration;
  activity.for_disable_milestone_duration = activity.last_duration;
  resetData();
}

function resetData() {
  const gantt = window.to_use_react_gantt;
  window.gantt.lastMoveTaskData.forEach((task) => {
    gantt.updateTask(task.id, task.data);
  });
  gantt.autoSchedule();
  window.gantt.lastMoveTaskData = [];
}

export { checkIfActivityViolateConstraint };
