import React from 'react';
import colors from '../../../stylesheets/variables.scss';

/** massive icons */
import duplicateStagePath from '../../../assets/img/icons/icon--duplicate-stage.svg';
import editPartyPath from '../../../assets/img/icons/icon--party.svg';
import { SvgIcon } from '../../../utils';

export const DuplicateStageComp = (
  <SvgIcon
    path={duplicateStagePath}
    className="massive-notification-icon"
    color={colors.brandOrange40}
  />
);
export const EditPartyComp = (
  <SvgIcon
    path={editPartyPath}
    className="massive-notification-icon"
    color={colors.brandOrange40}
  />
);
