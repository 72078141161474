export const SET_CALENDARS_BY_SECTOR = 'SET_CALENDARS_BY_SECTOR';
export const SET_CALENDAR_FORM = 'SET_CALENDAR_FORM';
export const SELECT_CALENDAR = 'SELECT_CALENDAR';

export const calendarActions = {
  setCalendar: (selectedCalendar) => ({
    type: SELECT_CALENDAR,
    payload: selectedCalendar
  }),
  setCalendarsbySector: (calendarsBySector) => ({
    type: SET_CALENDARS_BY_SECTOR,
    payload: calendarsBySector
  }),
  setCalendarForm: (calendarForm) => ({
    type: SET_CALENDAR_FORM,
    payload: calendarForm
  })
};
