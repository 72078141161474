import * as Sentry from '@sentry/react';
export const fixBlankScreenViewport = (gantt) => () => {
  const ganttBackground = document.getElementsByClassName('gantt_task_bg');
  if (!ganttBackground.length) return;
  const chartNode = ganttBackground[0];
  if (!chartNode) return;
  const ganttBackgroundViewport =
    document.getElementsByClassName('gantt_data_area');
  if (!ganttBackgroundViewport.length) return;
  const usedBackgroundHeight = chartNode.offsetHeight;
  const viewportBackgroundHeight = ganttBackgroundViewport[0].offsetHeight;
  if (usedBackgroundHeight < viewportBackgroundHeight) {
    const scrollState = gantt.getScrollState();
    if (!scrollState) return;
    const xScroll = scrollState.x;
    try {
      gantt.scrollTo(xScroll, 0);
    } catch (e) {
      Sentry.captureMessage(e, 'warning');
    }
  }
};
