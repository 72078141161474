import React, { useMemo } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useNavigateFromNavbar } from '../../../hooks/useNavigateFromNavbar';
import ReportCommint from '../weekAllReportCommit';
import WeekReportCommitClosed from '../../../components/Report/WeeReportCommitClose';

const WeeklyCommitReport = () => {
  const history = useHistory();
  const { projectId, scheduleId } = useParams();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  useNavigateFromNavbar(history);

  const type = queryParams.get('type');
  const planificationDay = queryParams.get('planificationDay');
  const week = queryParams.get('week');

  if (type === 'commitment') {
    return (
      <ReportCommint
        week={week}
        projectId={projectId}
        sectorId={scheduleId}
        planificationday={planificationDay}
      />
    );
  }

  if (type !== 'closure') {
    return null;
  }

  return (
    <WeekReportCommitClosed
      week={week}
      sectorId={scheduleId}
      projectId={projectId}
      planificationday={planificationDay - 1}
    />
  );
};

export default WeeklyCommitReport;
