export const USER_ROLES = (t) => [
  {
    value: 'superadmin',
    label: t('positions.superadmin'),
    level: 1
  },
  {
    value: 'admin',
    label: t('positions.admin'),
    level: 2
  },
  {
    value: 'projectleader',
    label: t('positions.projectleader'),
    level: 3
  },
  {
    value: 'planner',
    label: t('positions.planner'),
    level: 4
  },
  {
    value: 'superintendent',
    label: t('positions.fieldprofessional'),
    level: 5
  },
  {
    value: 'subtrade',
    label: t('positions.subtrade'),
    level: 6
  },
  {
    value: 'lastplanner',
    label: t('positions.lastplanner'),
    level: 7
  },
  {
    value: 'client',
    label: t('positions.client'),
    level: 8
  },
  {
    value: 'manager',
    label: t('positions.manager'),
    level: 9
  }
];
