import React from 'react';
import { Layout } from 'antd';
import { Animated } from 'react-animated-css';
import forgotViewImg from '../assets/img/imgForgot.jpg';
import './hoc.css';
const { Sider, Content } = Layout;

const withoutSidenavUp = (props) => (WrappedComponent) => () => (
  <Animated
    animationIn="slideInUp"
    animationInDuration={750}
    isVisible={true}
    style={{ height: '100%' }}>
    <Layout id="layoutWide">
      <Layout>
        <Content>
          <WrappedComponent />
        </Content>
      </Layout>
      <Sider>
        <img
          style={{ height: '100%', width: '100%' }}
          src={forgotViewImg}
          alt=""
        />
      </Sider>
    </Layout>
  </Animated>
);

export default withoutSidenavUp;
