import React from 'react';
import { Layout } from 'antd';
import withFooter from './withFooter.hoc';

const { Header, Content } = Layout;

const withoutWide = (props) => (WrappedComponent) => () => {
  const Footer = withFooter({
    ...props,
    options: {
      visible: true
    }
  });
  return (
    <Layout id="layoutWide">
      <Header>
        <div id="titleHeader" className="titleWide"></div>
      </Header>
      <Layout>
        <Content>
          <WrappedComponent />
        </Content>
      </Layout>
      {Footer}
    </Layout>
  );
};

export default withoutWide;
