/** React components  */
import React, { useState, useEffect, Fragment } from 'react';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/**  import library for animations effects */
import { Animated } from 'react-animated-css';

/** import elements from library Antd Framework */
import { Avatar, Icon, Input, Row, Col, Button, Select, Upload } from 'antd';

/** import common functions from utils */
import {
  checkAllFieldsValid,
  reduceFormValues,
  generateFormObjectSelect,
  codeCountries,
  companySizes,
  ObjectLogoSvg,
  getBase64,
  headersSendForm,
  setTitleWide
} from '../../utils';

/** import common functions from helper */
import {
  stateTemplate,
  updateUser,
  createCompany,
  getUser
} from './company.helper';

/** import hook for handle params */
import { useParams } from 'react-router-dom';

/** import icons from antd framework */
import { LoadingOutlined } from '@ant-design/icons';

/** import component for validation */
import ErrorValidationLabel from '../../components/Login/ValidationLabel';

/** import base contant (setting urls)  */
import { base } from '../../services/base';

/** import library for antd upload crop */
import ImgCrop from 'antd-img-crop';

import './company.css';

/** constants */
const { Option } = Select;

/** state for component */
/** Structure:
    {
        cname: object,
        size: object,
        country: object,
        allFieldsValid: false, // handle all field form is valid
        isLoading: false // handle loading
    }
*/
// state Object : stateTemplate

export default function CompanyView(props) {
  // hooks
  const [state, setState] = useState(stateTemplate); // state
  const { token } = useParams(); // handle params
  const [stateUpload, setStateUpload] = useState({ loading: false });

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };
    setTitleWide('company');
    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);
  useEffect(() => {
    window.Appcues.page();
  });

  // logic
  const onSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formValues = reduceFormValues(form.elements, state);

    // add selects to validation
    formValues.size = generateFormObjectSelect(
      'size',
      'Number of projects',
      state.size.value
    );
    formValues.country = generateFormObjectSelect(
      'country',
      'Country',
      state.country.value
    );
    const allFieldsValid = checkAllFieldsValid(formValues);
    setState({ ...formValues, allFieldsValid });

    if (allFieldsValid) {
      const user = await getUser(token);
      setState({
        ...state,
        isLoading: true
      });
      if (user) {
        // create Company
        const data = {
          name: state.cname.value,
          size: state.size.value,
          country: state.country.value
        };
        /** save image uploaded */
        data.image = stateUpload.name ? stateUpload.name : null;

        const company = await createCompany(data);
        // sig in
        localStorage.setItem('signed', true);
        localStorage.setItem('firsTimeLogin', true);

        // Update User companyId
        const dataUser = {
          id: user.id,
          email: user.email,
          companyId: company.id
        };
        await updateUser(dataUser);
        const userUpdate = await getUser(token);
        localStorage.setItem('user', JSON.stringify(userUpdate));
        setState({
          ...state,
          isLoading: false
        });
        props.history.push(`/confirmationproject/${token}`);
      }
    }
  };

  const CompanyIcon = (props) => <Icon component={ObjectLogoSvg} {...props} />;

  /** this function is triggered when upload image */
  const onChangeUpload = (info) => {
    if (info.file.status === 'uploading') {
      setStateUpload({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response
      getBase64(info.file.originFileObj, (imageUrl) =>
        setStateUpload({
          imageUrl,
          name: info.file.response.name,
          loading: false
        })
      );
    }
  };

  /** Component Button */
  const uploadButton = (
    <div>
      {stateUpload.loading ? (
        <LoadingOutlined />
      ) : (
        <CompanyIcon className="FrmCompany" />
      )}
    </div>
  );

  // validation
  const { cname, size, country } = state;

  const renderCnameValidationError =
    cname.valid || cname.value ? (
      ''
    ) : (
      <ErrorValidationLabel
        classError="lblerror"
        txtLbl={cname.typeMismatch ? cname.formatErrorTxt : cname.requiredTxt}
      />
    );

  const renderSizeValidationError =
    size.valid || size.value ? (
      ''
    ) : (
      <ErrorValidationLabel
        classError="lblerror ta-left"
        txtLbl={size.typeMismatch ? size.formatErrorTxt : size.requiredTxt}
      />
    );

  const renderCountryValidationError =
    country.valid || country.value ? (
      ''
    ) : (
      <ErrorValidationLabel
        classError="lblerror ta-left"
        txtLbl={
          country.typeMismatch ? country.formatErrorTxt : country.requiredTxt
        }
      />
    );

  // render
  return (
    <Animated
      className="animatedWide"
      animationIn="fadeIn"
      animationInDuration={750}
      isVisible={true}>
      <div className="divWide">
        <div className="divSection">
          <Fragment>
            <form
              className="frmWide mt-50"
              onSubmit={(e) => onSubmit(e)}
              noValidate>
              <Row>
                <Col span={24}>
                  <ImgCrop rotate shape="round" modalWidth={850}>
                    <Upload
                      action={`${base.api}companys/upload`}
                      listType="picture-card"
                      showUploadList={false}
                      headers={headersSendForm}
                      onChange={onChangeUpload}>
                      {stateUpload.imageUrl ? (
                        <Avatar
                          style={{ backgroundColor: 'black' }}
                          size={140}
                          src={stateUpload.imageUrl}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </ImgCrop>
                </Col>
              </Row>
              <Row className="mt-30">
                <Col span={24}>
                  <Input
                    className="w100 centerTxt"
                    type="text"
                    name="cname"
                    placeholder="Company Name"
                    autoComplete="off"
                    onChange={(e) => {
                      /** update state with value selected */
                      setState({
                        ...state,
                        cname: {
                          ...state.cname,
                          value: e.target.value
                        }
                      });
                    }}
                    required
                  />
                  {renderCnameValidationError}
                </Col>
              </Row>
              <Row className="mt-20">
                <Col span={11}>
                  <Select
                    className="w100"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Number of Projects"
                    onChange={(e) => {
                      /** update state with value selected */
                      setState({
                        ...state,
                        size: {
                          ...state.size,
                          value: e
                        }
                      });
                    }}>
                    {companySizes.map(({ value, label }) => (
                      <Option key={value} value={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                  {renderSizeValidationError}
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <Select
                    className="w100"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Country"
                    onChange={(e) => {
                      /** update state with value selected */
                      setState({
                        ...state,
                        country: {
                          ...state.country,
                          value: e
                        }
                      });
                    }}>
                    {codeCountries.map(({ code, name }) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                  {renderCountryValidationError}
                </Col>
              </Row>
              <Button htmlType="submit" loading={state.isLoading}>
                Continue
              </Button>
            </form>
          </Fragment>
        </div>
      </div>
    </Animated>
  );
}
