/** React components  */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { constraintActions } from '../../../../../../redux/actions/constraintActions';
import { Tooltip } from 'antd';
import {
  categoriesConstraintType,
  getIconCategories
} from '../../../../../../utils';
import { CircleCheckIcon, CircleEmptyIcon } from '../../../../../../icons';

export default function ConstraintTypesList(props) {
  const { types } = props;

  const dispatch = useDispatch();
  const { constraintState } = useSelector((state) => state);

  const handleClickTypes = (type) => {
    dispatch(
      constraintActions.setConstraintForm({
        ...constraintState.constraintForm,
        type
      })
    );
  };

  return (
    <div className="constraint-list__container">
      {types.length &&
        types.map((type, key) => {
          const findItem = categoriesConstraintType.find(
            (category) => category.value === type.type
          );
          return (
            <div
              key={key}
              className={`container__type ${constraintState.constraintForm.type === type.id ? 'type--selected' : ''}`}
              onClick={() => handleClickTypes(type.id)}>
              <div className="type__name">
                <div className="name__select">
                  {constraintState.constraintForm.type === type.id ? (
                    <CircleCheckIcon color="#000000" />
                  ) : (
                    <CircleEmptyIcon color="#ffffff" />
                  )}
                </div>
                <p>{type.name}</p>
              </div>
              <div className="type__icon">
                <Tooltip title={type.name}>
                  <div className="icon">
                    {getIconCategories(
                      findItem.icon,
                      constraintState.constraintForm.type === type.id
                    )}
                  </div>
                </Tooltip>
              </div>
            </div>
          );
        })}
    </div>
  );
}
