/** React components  */
import React, { useState, useEffect } from 'react';

import { Animated } from 'react-animated-css';

import axios from 'axios';

/** Redux */
import { useSelector } from 'react-redux';

/** Import elements from library Antd */
import { Select, Row, Col, Modal, Button, Spin, Tooltip } from 'antd';
import { LoadingOutlined, CloseOutlined } from '@ant-design/icons';

/** import base contant (setting urls)  */
import { importerService, sectorResourcesService } from '../../services';

/** import common functions from utils */
import { resourcesType } from '../../utils';

import { withTranslation } from 'react-i18next';
import { AlertIcon } from '../../../src/icons';
import { getImportableIconByFileExt } from '../../utils/importGanttUtils';
/** consts */
const colorBar = '#7DFF8A';
const { Option } = Select;

function ImportGanttProcess(props) {
  /** Project state from redux */
  const projectState = useSelector((state) => state.projectState);
  const companyState = useSelector((state) => state.companyState);
  const requireResources = companyState?.currentCompany?.require_resources;

  /** use props */
  const {
    t,
    setVisible,
    visible,
    fileInfo,
    setVisibleFinish,
    stateUpload,
    listOptions,
    setListOptions
  } = props;

  /** hooks */
  const [currentOption, setCurrentOption] = useState(0);
  const [selectResource, setSelectResource] = useState([]);

  const handleClose = () => {
    setVisible(false);
  };

  const timeout = (delay) =>
    new Promise((resolve) => setTimeout(resolve, delay));

  /** request validate */
  useEffect(() => {
    if (stateUpload.name) {
      validateGantt(stateUpload.name);
    }
  }, [stateUpload.name]);

  const checkUrl = async (url) => {
    const http = axios.create({
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    });
    const res = await http.get(url).catch((error) => {
      console.log(error);
      return false;
    });
    if (res.status === 200) {
      return true;
    }
    return false;
  };

  const validateGantt = async (url) => {
    let check = false;
    do {
      await timeout(3000);
      check = await checkUrl(url);
      console.dir('checking url');
      console.dir(url);
    } while (!check);
    const data = {
      is_reimport: true,
      gantt_url: url
    };
    const dataImport = await importerService.validate(data);

    if (dataImport) {
      const options = [
        {
          name: 'tasks',
          title: t('import.process.tasks_op_label'),
          subtitle:
            dataImport.activity_count + dataImport.activity_milestone || 0,
          data: [
            {
              label: t('import.process.tasks_data_one_label'),
              value: dataImport.activity_count
            },
            {
              label: t('import.process.tasks_data_two_label'),
              value: dataImport.activity_milestone
            }
          ]
        },
        {
          name: 'calendars',
          title: t('import.process.calendars_op_label'),
          subtitle: dataImport.calendarList
            ? dataImport.calendarList.length
            : 0,
          data: dataImport.calendarList
        },
        {
          name: 'progress',
          title: t('import.process.progress_op_label'),
          subtitle: dataImport.progress_total
            ? dataImport.progress_total
            : t('import.process.not_assigned_label'),
          data: [
            {
              label: t('import.process.progress_data_one_label'),
              value: dataImport.activity_progres_complete + '%'
            },
            {
              label: t('import.process.progress_data_two_label'),
              value: dataImport.progress_total
            }
          ]
        },
        {
          name: 'hh',
          title: t('import.process.work_op_label'),
          subtitle:
            dataImport.activity_count_hh > 0
              ? t('import.process.assigned_label')
              : t('import.process.not_assigned_label'),
          reviewed: false,
          data: [
            {
              label: t('import.process.work_data_one_label'),
              value: dataImport.activity_count_hh
            }
          ]
        },
        {
          name: 'cost',
          title: t('import.process.cost_op_label'),
          subtitle:
            dataImport.activity_count_cost > 0
              ? dataImport.activity_count_cost
              : t('import.process.not_assigned_label'),
          reviewed: false,
          data: [
            {
              label: t('import.process.cost_data_one_label'),
              value: dataImport.activity_count_cost
            }
          ]
        },
        {
          name: 'resources',
          title: t('import.process.resources_op_label'),
          subtitle: dataImport.resourceList
            ? dataImport.resourceList.filter((el) => el.name !== null).length
            : 0,
          reviewed: false,
          data: dataImport.resourceList
        },
        {
          alert_exception: dataImport.alert_exception
            ? dataImport.alert_exception
            : false
        }
      ].filter((item) => item.name !== 'resources' || requireResources);
      setListOptions(options);

      /** set state for select of resources */
      const arrRes = [];
      dataImport.resourceList.map((el) => {
        arrRes.push('material');
      });
      setSelectResource(arrRes);
    }
  };

  /**
   * This function creates a new resource
   * @param {*} name Name to show to the new task
   * @param {*} activity Activity superior parent object
   */
  const createResource = async (data, index) => {
    if (data.name) {
      const toPush = {
        name: data.name,
        type: selectResource[index],
        material_label: data.materialLavel || 'Un',
        total: 0,
        used: 0,
        sectorId: projectState.sectorSelected,
        unique_id: data.unique_id
      };
      await sectorResourcesService.destroyAll(projectState.sectorSelected);
      await sectorResourcesService.create(toPush);
    }
  };

  const handleChangeResource = (e, index) => {
    const arrTmp = selectResource;
    arrTmp[index] = e.target.value;
    const a = JSON.stringify(arrTmp);
    setSelectResource((prev) => {
      prev = JSON.parse(a);
      return prev;
    });
  };

  const handleSkip = () => {
    setVisible(false);
    setVisibleFinish(true);
  };

  const handleNext = async () => {
    if (currentOption < listOptions.length - 1) {
      setCurrentOption(currentOption + 1);
    } else {
      if (
        listOptions &&
        listOptions[currentOption].name === 'resources' &&
        requireResources
      ) {
        const asyncMap =
          listOptions[currentOption].data &&
          listOptions[currentOption].data.map(async (res, index) => {
            await createResource(res, index);
          });
        await Promise.all(asyncMap);
      }
      setVisible(false);
      setVisibleFinish(true);
    }
  };

  const handlePrevious = () => {
    if (currentOption > 0) {
      setCurrentOption(currentOption - 1);
    }
  };

  const renderOptions = () => {
    const alert_exception =
      listOptions[[listOptions.length - 1]].alert_exception;
    let ret = (
      <div className="list-items">
        <div className="item-info">
          <div className="item-number"></div>
          <div className="item-name"></div>
        </div>
      </div>
    );

    if (currentOption === 1) {
      ret = (
        <>
          {alert_exception && (
            <div className="alert-msg">
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500
                }}>
                <AlertIcon color="#7DFF8A" className="icon-alert" />
              </span>
              <span className="alert-text">
                {t('import.process.alert_exception_msg')}
              </span>
            </div>
          )}

          <div
            className={`list-items ${alert_exception ? 'calendars-with-msg' : 'calendars'}`}>
            {listOptions[currentOption].data.map((el, index) => (
              <div className="item-info" key={index}>
                <div className="item-number">{el}</div>
              </div>
            ))}
          </div>
        </>
      );
    }

    if ([0, 2, 3, 4].includes(currentOption)) {
      ret = (
        <div className="list-items">
          {listOptions[currentOption].data.map((el, index) => (
            <div className="item-info" key={index}>
              <div className="item-number">{el.value}</div>
              <div className="item-name">{el.label}</div>
            </div>
          ))}
        </div>
      );
    }

    if (currentOption === 5 && requireResources) {
      ret = (
        <div className="list-items">
          {listOptions[currentOption].data.map((el, index) =>
            el.unique_id && el.name ? (
              <div className="item-info" key={index}>
                <Tooltip title={el.name}>
                  <div className="item-name-overflow">{el.name}</div>
                </Tooltip>
                <div className="">{el.type}</div>
                <div className="">
                  <select
                    className="select-resource"
                    value={selectResource[index]}
                    onChange={(e) => handleChangeResource(e, index)}>
                    {resourcesType(t).map((el, index) => (
                      <option key={index} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : null
          )}
        </div>
      );
    }
    if (currentOption === listOptions.length - 1) {
      ret = (
        <div className="list-items">
          {listOptions.map((el, index) => (
            <div className="item-info" key={index}>
              <div className="item-number">{el.title}</div>
              <div className="item-name">{el.subtitle}</div>
            </div>
          ))}
        </div>
      );
    }
    return ret;
  };

  return (
    <Animated animationIn="fadeIn" animationInDuration={500} isVisible={true}>
      <Modal
        closable={false}
        className="frm-process"
        title=""
        visible={visible}
        centered
        keyboard={false}
        maskClosable={false}
        onCancel={handleClose}
        width={640}
        footer={null}>
        {!listOptions ? (
          <div className="imp-loading">
            <Spin indicator={<LoadingOutlined />} className={'loading'} />
            &nbsp;
            {t('import.process.loading_data_label')}
          </div>
        ) : (
          <Row className="">
            <Col span={8} className="tab-left">
              {listOptions &&
                listOptions.map((el, index) => (
                  <div className="list-left" key={index}>
                    <div
                      className={
                        index < currentOption
                          ? 'reviewed'
                          : index === currentOption
                            ? 'current'
                            : null
                      }>
                      {index < currentOption ? (
                        <div className="back-white">
                          <i className="fas fa-check-circle option-icon"></i>
                        </div>
                      ) : (
                        <div>
                          <i className="fas fa-circle option-icon"></i>
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="option-text">{el.title}</div>
                      <div className="option-subtitle">{el.subtitle}</div>
                    </div>
                  </div>
                ))}
            </Col>
            <Col span={16} className="tab-right">
              <Row className="process-title">
                <div className="title">
                  <img
                    className="img-upload"
                    src={getImportableIconByFileExt(fileInfo?.name)}
                  />
                  {fileInfo?.name || null}
                </div>
                <div className="hand-close" onClick={handleClose}>
                  <CloseOutlined />
                </div>
              </Row>
              <div className="title-info">
                {listOptions[currentOption].title}
              </div>
              {renderOptions()}
              <div className="buttons">
                {currentOption === 5 ? (
                  <div className="skip-link" onClick={handleSkip}>
                    Skip
                  </div>
                ) : null}
                {currentOption ? (
                  <Button className="btn-previous" onClick={handlePrevious}>
                    {t('import.process.back_label')}
                  </Button>
                ) : null}
                <Button className="btn-continue" onClick={handleNext}>
                  {t('import.process.next_label')}
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Modal>
    </Animated>
  );
}

export default withTranslation()(ImportGanttProcess);
