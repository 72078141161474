import React, { useState, useEffect, useRef } from 'react';
import { Spin, Icon } from 'antd';
import './index.css';
import './cnc.scss';

import Chart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';
function Cnc(props) {
  const { t } = props;

  /** consts */
  const cnc = props.data;
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  /** hooks */
  const [loading_filter, setLoading_filter] = useState(false);

  const [options, setOptions] = useState({
    chart: {
      height: 250,
      colors: ['#F44336']
    },
    title: {
      text: ''
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1]
    },
    labels: [],
    xaxis: {
      type: 'datetime'
    },
    yaxis: [
      {
        title: {
          text: ''
        }
      },
      {
        opposite: true,
        title: {
          text: 'PCR'
        }
      }
    ],
    tooltip: {}
  });

  const [series, setSeries] = useState();
  const [state_total_cnc_all, setState_total_cnc_all] = useState();

  useEffect(() => {
    setLoading_filter(false);
    if (cnc) {
      const cncs = cnc.cncs;
      const cnctypes = cnc.cnctypes;

      const { datay, datax, totalCnc } = cnctypes.reduce(
        (acc, cnctype) => {
          acc.datay.push(cnctype.name);
          const totalcnc = cncs.filter(
            (cnc) => cnc.cncTypeId == cnctype.id
          ).length;
          acc.totalCnc += totalcnc;
          acc.datax.push(totalcnc);
          return acc;
        },
        { datay: [], datax: [], totalCnc: 0 }
      );

      const option = {
        chart: {
          type: 'pie'
        },
        labels: datay,
        dataLabels: {
          name: {
            show: false
          }
        },
        legend: {
          show: false
        },
        /* stroke: {
                    width: [0, 2],
                    curve: ['smooth', 'straight', 'stepline'],
                    colors: ['#F44336', '#E91E63', '#9C27B0']
                }, */
        fill: {
          opacity: 0.8
        },
        title: {
          text: t('analitic.cnc'),
          style: {
            fontSize: '13px',
            fontWeight: '500',
            fontFamily: `
              Roboto,
              -apple-system,
              BlinkMacSystemFont,
              'Segoe UI',
              Oxygen,
              Ubuntu,
              Cantarell,
              'Fira Sans',
              'Droid Sans',
              'Helvetica Neue',
              sans-serif !important
            `
          }
        },
        // eslint-disable-next-line no-dupe-keys
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return t('lang') === 'en'
              ? val.toFixed(1) + '%'
              : val.toFixed(1).replace('.', ',') + '%';
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      };

      const seriesUp = datax;
      setOptions(option);
      setSeries(seriesUp);
      setState_total_cnc_all(totalCnc);

      if (seriesUp.length) {
        setLoading_filter(true);
      }
    }
  }, [cnc]);

  return (
    <div
      style={{ height: '100%' }}
      className={state_total_cnc_all ? null : 'no-data-cnc'}>
      {state_total_cnc_all ? (
        series !== null || series?.length ? (
          <Chart
            options={options}
            series={series || []}
            type="pie"
            width="100%"
            height="100%"
          />
        ) : (
          <div className="widget-analitycs">
            <Spin
              className="loader-spinner-lookahead-table"
              indicator={antIcon}
            />
          </div>
        )
      ) : (
        <div>No data</div>
      )}
    </div>
  );
}
export default withTranslation()(Cnc);
