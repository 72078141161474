import { checkLastEditedColumn } from './checkLastEditedColumn';

function updateActivityDuration(activity) {
  if (
    !checkLastEditedColumn([
      'duration',
      'end_date',
      'start_date',
      'calendar_id',
      'custom_predecessors',
      'custom_successors'
    ])
  ) {
    return;
  }

  if (activity.comes_from_indent) {
    activity.for_disable_milestone_duration = activity.duration;
  } else {
    activity.duration = activity.for_disable_milestone_duration;
  }

  if (activity.is_milestone) {
    activity.duration = 0;
    activity.for_disable_milestone_duration = 0;
  }
}

export { updateActivityDuration };
