import React from 'react';
import './styles.scss';
import { Icon, Row, Spin } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import ItemCard from '../Projects/ListProjects/ItemCard';
import {
  getLabel,
  getHeightSchedule
} from '../../views/project/project.helper';
import useProjectFilterByStage from './hooks/useProjectFilterByStage';
import { useTranslation } from 'react-i18next';

const ProjectCardsList = ({
  isLoadingProjects,
  projectsGroupedByStages,
  projectStages,
  filterText,
  filter,
  onClickProject,
  setFormProjectVisible,
  formProjectVisible,
  setFormDeleteVisible,
  formDeleteVisible,
  filterResultsCount
}) => {
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  const sortedProjects = useProjectFilterByStage(
    projectsGroupedByStages,
    filterText
  );
  const { t } = useTranslation();

  if (isLoadingProjects) {
    return (
      <div
        className="wrappView wrapLoading"
        style={{
          height: getHeightSchedule()
        }}>
        <Spin className="loader-spinner-projects" indicator={antIcon} />
      </div>
    );
  }

  if (!sortedProjects || !Object.keys(sortedProjects).length) {
    return null;
  }

  return Object.values(projectStages).map((stage) => (
    <div className="sectionProjects" key={stage.value}>
      <Row gutter={24} className="section--project">
        <CaretRightOutlined />
        <span>{getLabel(t)(stage.value, filter)}</span>
      </Row>
      <Row gutter={24} className="divProjects">
        {sortedProjects[stage.value].map((project) => (
          <ItemCard
            onClickProject={onClickProject}
            key={project.id}
            t={t}
            project={project}
            setFormProjectVisible={setFormProjectVisible}
            setFormDeleteVisible={setFormDeleteVisible}
            formProjectVisible={formProjectVisible}
            formDeleteVisible={formDeleteVisible}
            analytics={{
              search_text: filterText,
              results_count: filterResultsCount
            }}
          />
        ))}
      </Row>
    </div>
  ));
};

export default ProjectCardsList;
