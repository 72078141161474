import React, { useRef, useEffect, useState } from 'react';
/** Components from ant design css framework */
import {
  Button,
  Modal,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Input,
  InputNumber,
  Progress,
  Popover,
  Icon,
  Empty,
  Popconfirm
} from 'antd';

import CustomCheckbox from '../CustomCheckbox';
import useWindowDimensions from '../../hooks/useWindowDimensions';

/** PNG to put on users without image */
import fakeAvatar from '../../assets/img/fake_user.png';

/** PNG to put on constraints by default */
import checkIcon from '../../assets/img/assistance/check.png';
import xIcon from '../../assets/img/assistance/x.png';

import { FixedSizeList as List } from 'react-window';

import EditableInput from '../EditableInput';

import { taskService } from '../../services';
import { activityService } from '../../services';
import { activityModificationService } from '../../services';

import cloneDeep from 'lodash/cloneDeep';
/** Date handler lib */
import moment from 'moment';

/** Redux implementation */
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
// import { lookaheadActions } from '../../redux/actions/lookaheadActions'
import {
  getEndDateByGantt,
  defineTitleModification,
  defineDateUnfit,
  hasConstraint,
  hasUnfitProblem,
  renderFriend,
  calculateExpected,
  calculateExpectedCost,
  splitText
} from '../../utils/lookahead-common';
import { socket } from '../../services/socket.service';
import { openNotification } from '../../utils';
import SelectSearch from 'react-select-search';

import calendarModificationIcon from '../../assets/img/activitymodification/calendar.png';
import calendarModificationIconModal from '../../assets/img/activitymodification/calendar-modal.png';
import selectResponsablesIcon from '../../assets/img/select_responsables.png';
import { clone } from '@babel/types';
import { capitalize } from 'lodash';
/** Styles on pure css */
import './index.css';

const { TextArea } = Input;
const LazyElement =
  (data) =>
  ({ index, style }) => (
    <div style={style} className="single-row-bottom-bordered">
      {data[index]}
    </div>
  );

const AssistanceCell = ({ assist, icon, cellEmpty = false }) => {
  if (assist && cellEmpty) {
    return (
      <Tooltip placement="top" title={`${assist.total} Asistentes`}>
        <div className="vertical-center-griss"></div>
      </Tooltip>
    );
  }

  if (!assist && cellEmpty) {
    return <div className="vertical-center-griss"></div>;
  }

  if (!assist) {
    return (
      <span className="vertical-center">
        <img src={icon} width="20px" height="20px"></img>
      </span>
    );
  }

  return (
    <Tooltip placement="top" title={`${assist.total} Asistentes`}>
      <span className="vertical-center">
        <img src={icon} width="20px" height="20px"></img>
      </span>
    </Tooltip>
  );
};

export default function LookaheadOtherGroup(props) {
  const { groupParent, tableMetadata, updateAsyncTask, updateAsyncActivity } =
    props;
  const [editedInput, setEditedInput] = useState(null);
  const projectState = useSelector((state) => state.projectState);
  const [modalModification, setModalModification] = useState({
    visible: false,
    title: '',
    description: '',
    data: { activity: {}, task: {} }
  });
  const [popsVisibility, setPopsVisibility] = useState({});
  const { height, width } = useWindowDimensions();
  const dispatch = useDispatch();
  const virtualizeRef = useRef();
  const { ganttAPI, lookaheadActions, month, assist } = props;

  useEffect(() => {
    const id = props.groupParent.value;
    if (props.scrollStates[id]) {
      if (virtualizeRef.current) {
        virtualizeRef.current.scrollTo(props.scrollStates[id]);
      }
    }

    return () => {
      if (virtualizeRef.current) {
        props.scrollStates[id] = virtualizeRef.current.state.scrollOffset;
      }
    };
  }, [virtualizeRef]);

  const defineParentColumnContent = (column, parent) => {
    if (column.name == 'name') {
      if (parent.name) {
        return (
          <span>
            <span
              title={parent.value}
              style={{ position: 'relative', top: -2 }}>
              <img
                width={16}
                style={{ borderRadius: 20 }}
                src={parent.img || fakeAvatar}
              />
            </span>
            <span style={{ marginLeft: 5 }}>{parent.name}</span>
          </span>
        );
      }
      return parent.label;
    }
  };

  const renderParentColumn = (column, parent, index) => {
    if (column.visible) {
      return (
        <Col
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineParentColumnContent(column, parent)}
        </Col>
      );
    }
  };

  //
  const renderGroupParentHeader = (parent = groupParent) => (
    <Tooltip placement="top" title="bottomcc">
      <Row className="custom-header-top-list">
        {tableMetadata.map((eachColumn, index) =>
          renderParentColumn(eachColumn, parent, index)
        )}
      </Row>
    </Tooltip>
  );

  /**
   * Clone deep of a new state to set and render view
   * @param {*} newState new state value (inmutability is broken)
   */
  const updateState = (newState = popsVisibility) => {
    setPopsVisibility({ ...popsVisibility });
    dispatch(lookaheadActions.notifyLookaheadUpdate());
  };

  /**
   * This function deals with pop visibility hash map
   * @param {boolean} visible Value to set the pop
   * @param {*} id Unique state eval to use at virtual DOM to show popup or hide it (from antd component)
   */
  const handlePopVisibility = (visible, id) => {
    setPopsVisibility({
      ...popsVisibility,
      [id]: visible
    });
    dispatch(lookaheadActions.notifyLookaheadUpdate());
  };

  const filterBufferSaveAndUpdateState = (task) => {
    const copyTask = cloneDeep(task);
    delete copyTask.activityObject;
    updateAsyncTask(copyTask);
    updateState();
  };

  const defineTaskColumnContent = (column, task, parent, index) => {
    if (column.data_type == 'string') {
      if (column.name == 'Name') {
        return (
          <span className="vertical-center">
            <span style={{ marginLeft: 15 }}>
              {splitText(
                task.user_info.Name + ' ' + task.user_info.LastName,
                column
              )}
            </span>
          </span>
        );
      }
      if (column.name == 'PositionDescription') {
        return (
          <span className="vertical-center">
            <span style={{ marginLeft: 15 }}>
              {splitText(task.PositionDescription, column)}
            </span>
          </span>
        );
      }
      // subcontratos
      if (column.name == 'GroupDescription') {
        return (
          <span className="vertical-center">
            <span style={{ marginLeft: 15 }}>
              {splitText(task.GroupDescription, column)}
            </span>
          </span>
        );
      }
    } else if (column.data_type == 'number') {
    } else if (column.data_type == 'date') {
    } else if (column.data_type == 'array/images') {
    } else if (column.data_type == 'array/string') {
      const actual = moment();
      const date = moment().set({
        year: month.year(),
        month: month.month(),
        date: parseInt(column.name),
        hour: 0,
        minute: 0,
        second: 0
      });
      // month(month.month()).day.add(parseInt(column.name), 'month')
      const date_format = date.format('YYYYMMDD');
      const day_of_work = task.PlannedInterval.find(
        (u) => u.Date == date_format + '000000'
      );

      const assist_day = assist.find((u) => u.date == date_format + '000000');
      if (day_of_work) {
        if (day_of_work.Worked == 'True') {
          if (day_of_work.Absent == 'False') {
            return <AssistanceCell assist={assist_day} icon={checkIcon} />;
          }
          return <AssistanceCell assist={assist_day} icon={xIcon} />;
        }
        return <AssistanceCell assist={assist_day} cellEmpty />;
      }
      if (date < actual) {
        return <AssistanceCell icon={xIcon} />;
      }
      return <AssistanceCell assist={assist_day} cellEmpty />;

      /* return(
                <span className="vertical-center">
                    {date.format('DD')}
                </span>
            ) */
    } else if (column.data_type == 'array/icon') {
    }
  };

  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    return string;
  }

  const renderTaskColumn = (column, task, index, parent) => {
    if (column.visible) {
      return (
        <Col
          className="single-task-column-style-table"
          key={index}
          span={column.span}
          offset={column.offset}
          style={{
            paddingLeft: column.name == 'name' ? 15 : 0,
            textAlign: column.align,
            width: column.width ? column.width : null
          }}>
          {defineTaskColumnContent(column, task, parent, index)}
        </Col>
      );
    }
  };

  const renderChildTreeTasks = (finalArray, parent) => {
    parent.tasks.map((task) => {
      if (!task.hide) {
        finalArray.push(
          <Row
            style={{ backgroundColor: task.active ? '#12121210' : null }}
            key={task.id}
            className="fit-at-middle-row">
            {tableMetadata.map((eachColumn, index) =>
              renderTaskColumn(eachColumn, task, index, parent)
            )}
          </Row>
        );
      }
    });
  };

  /** This function is used for show tasks quantity on virtual dom who renders them and resize height */
  const defineVirtualizedHeight = (taskCounter) => {
    const tempHeight = taskCounter * 40;
    const maxHeight = height < 700 ? height * 0.45 : height * 0.6;
    if (tempHeight < maxHeight) {
      return tempHeight;
    }
    return maxHeight;
  };

  const renderTasks = (parent = groupParent) => {
    const childRended = [];
    renderChildTreeTasks(childRended, parent);

    parent.childRended = childRended.length;

    props.virtualizeRef.current.resetAfterRowIndex(props.index);
    /**
     * To Optimize render of this child list an option is
     * https://react-window.now.sh/#/examples/list/variable-size
     * with childRended, we can create a virtual list to handle performance
     */

    if (childRended.length) {
      return (
        <List
          ref={virtualizeRef}
          height={defineVirtualizedHeight(childRended.length)}
          itemCount={childRended.length}
          itemSize={40}
          width={'100%'}>
          {LazyElement(childRended)}
        </List>
      );
    }
    return (
      <div style={{ marginTop: 20 }}>
        <Empty
          style={{ margin: 0 }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>No tasks for this groupping parameter.</span>}
        />
      </div>
    );
  };

  /**
   * Render
   */
  return (
    <Row key={props.index}>
      <Col
        className="tableConstraints"
        style={{ marginTop: 10, marginBottom: 40 }}>
        {renderGroupParentHeader(groupParent)}
        {renderTasks(groupParent)}
      </Col>

      {/* Modal for dealing with activity modification request */}
    </Row>
  );
}
