import EventEmitter from 'react-native-eventemitter';
const TWO_SECONDS = 2000;

export const runAndUnmountTimeout = (callback, timer) =>
  setTimeout(() => {
    callback();
  }, timer);

export const fixScaleDateHeader = (gantt) => () => {};

const zoomLevels = {
  days: 4,
  weeks: 3,
  month: 2,
  quarters: 1,
  years: 0
};

export const changeScaleVisualization = (gantt) => (newZoomLvl) => {
  const currentLevel = gantt.ext.zoom.getCurrentLevel();
  if (newZoomLvl !== currentLevel) {
    gantt.ext.zoom.setLevel(newZoomLvl);
  }
};
