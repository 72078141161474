import React from 'react';
import styles from './styles.scss';
import { addSign } from './utils';

const varianceClassMapper = {
  'on schedule': 'black',
  overdue: 'red',
  advancement: 'green'
};

const VarianceCell = ({ variance }) => {
  if (!variance) {
    return <div className="variance-cell"></div>;
  }

  const { type, days } = variance;
  return (
    <div className="variance-cell">
      <span className={varianceClassMapper[type]}>{addSign(days, type)}</span>
    </div>
  );
};

export default VarianceCell;
