function setDuration(task) {
  if (!task.modeDrag || task.lig) {
    task.duration = task.for_disable_milestone_duration;
  }

  if (task.task_added) {
    task.duration = task.aux_duration;
  }
}

export { setDuration };
