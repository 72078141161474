import React from 'react';
import EndCell from '../../../components/EndCell';

export default (gantt, { t }) => ({
  name: 'end_date',
  label: t('scheduleofschedule.columns.end_date'),
  align: 'center',
  width: 80,
  onrender: (item) => <EndCell parent={item.parent} date={item.end_date} />
});
