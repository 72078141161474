import React from 'react';

export const useProgressIndicatorProject = (project_s) => {
  let total_unid = 0;
  let total_progress = 0;

  if (project_s) {
    project_s.sector.map((s) => {
      if (s.activities.length > 0) {
        switch (project_s.projects.activity_creter.toUpperCase()) {
          case 'COST':
            total_unid = total_unid + s.activities[0].cost;
            break;
          case 'HH':
            total_unid = total_unid + s.activities[0].hhWorkTime;
            break;
          case 'DURATION':
            total_unid = total_unid + s.activities[0].duration;
            break;
          default:
          // code block
        }
      } else {
      }
    });

    project_s.sector.map((s) => {
      if (s.activities.length > 0) {
        switch (project_s.projects.activity_creter.toUpperCase()) {
          case 'COST':
            total_progress =
              total_progress +
              (s.activities[0].cost / total_unid) * s.activities[0].progress;
            break;
          case 'HH':
            total_progress =
              total_progress +
              (s.activities[0].hhWorkTime / total_unid) *
                s.activities[0].progress;
            break;
          case 'DURATION':
            total_progress =
              total_progress +
              (s.activities[0].duration / total_unid) *
                s.activities[0].progress;
            break;
          default:
        }
      } else {
      }
    });
  }

  let progressFormat = '0%';
  if (total_unid !== 0) {
    progressFormat = total_progress.toFixed(0) + '%';
  }

  return {
    total_progress,
    total_unid,
    progressLabel: progressFormat
  };
};
