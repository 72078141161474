import React, { useEffect, useCallback, useRef, useState } from 'react'
import { Spin, Icon, Popover, Checkbox, Tooltip, Modal, Button, Radio } from 'antd';
import { FixedSizeGrid as Grid } from 'react-window';
import { VariableSizeGrid } from 'react-window';
import './index.css'
import { Row, Col } from 'antd';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import differenceBy from 'lodash/differenceBy'
import XlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';

/** Component for multiselect elements on checkbox display */
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import IconComponent from '../Projects/IconSvg';
import { export_file } from '../../utils'
import filterIcon from '../../assets/img/takt/filter.png'
import configurationIcon from '../../assets/img/takt/config.png'
import moveIcon from '../../assets/img/takt/move.png'
import leftIcon from '../../assets/img/takt/left.png'

/** Grid element when location visualization (static header) is enable with tree and grouppedForm */
import { GridCellStructureGroupped, GridCellStructureTree } from './modules/structureCell'

/** Grid element when location visualization (static header) is enable with tree and grouppedForm */
import { GridCellLocationGroupped, GridCellLocationTree } from './modules/locationCell'

/** Grid element when unit visualization (static header) is enable with tree and grouppedForm */
import { GridCellUnitGroupped, GridCellUnitTree } from './modules/unitCell'

/** Each row for fixed activities column, that will be rendered when using groupped or tree structure */
import { FixedColActivitiesRowGroupped, FixedColActivitiesRowTree } from './modules/fixedActivitiesColumn'

/** Each column for Statics headers (unit, location and structure) that will be rendered */
import { HeaderUnitCol, HeaderLocationCol, HeaderStructureCol } from './modules/fixedHeaders'

import { splitText } from './index.helper'

import { projectService } from '../../services/project.service'
import { useSelector } from 'react-redux'
import moment from 'moment'

export const TestIds = {
    RENDER_TAKT_MATRIX: 'RENDER_TAKT_MATRIX'
}

const ReturnTestWrapped = ({ children }) => <div data-testid={TestIds.RENDER_TAKT_MATRIX}>{children}</div>

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

export default function ProgressMatrixTakt (props) {
    const {
        activities = [],
        calculateExpected = () => {},
        containerRef = {},
        ganttAPI = {},
        selectedActivities = [],
        selectedUnits = [],
        t = () => {},
        units = []
    } = props
    const [isLoading, setIsLoading] = useState(true)
    const [gridRows, setGridRows] = useState([])
    const [treeActivitiesVisualization, setTreeActivitiesVisualization] = useState(false)
    const [radioValue, setRadioValue] = useState('tracing')
    const [finalData, setFinalData] = useState([])
    const [extraHeaderStructure, setExtraHeaderStructure] = useState([])
    const [extraHeaderLocation, setExtraHeaderLocation] = useState([])
    const [headerData, setHeaderData] = useState([])
    const [backgroundColorData, setBackgroundColorData] = useState([])
    const projectState = useSelector((state) => state.projectState);
    const [state, setState] = useState(false)

    const statusColors = {
        generalStatus: [
            {
                color: 'rgba(52, 175, 0, 0.7)',
                label: 'complete_op'
            },
            {
                color: '#F59D04',
                label: 'develope_op'
            },
            {
                color: '#FFFFFF',
                label: 'unstart_op'
            }
        ],
        expectedStatus: [
            {
                color: '#2C3421',
                label: 'advancement_op'
            },
            {
                color: 'rgba(52, 175, 0, 0.7)',
                label: 'complete_op'
            },
            {
                color: '#F59D04',
                label: 'equals_op'
            },
            {
                color: 'rgba(229, 1, 1, 0.7)',
                label: 'overdue_op'
            }
        ],
        progressStatus: [
            {
                color: 'rgba(52, 175, 0, 0.7)',
                label: '100%'
            },
            {
                color: '#89D43D',
                label: '75%'
            },
            {
                color: '#F59D04',
                label: '50%'
            },
            {
                color: '#FAE954',
                label: '25%'
            },
            {
                color: '#FFFFFF',
                label: '0%'
            }
        ]
    }
    const statusColorsExportable = {
        generalStatus: [
            {
                color: '73c768',
                label: 'complete_op'
            },
            {
                color: 'f49d2c',
                label: 'develope_op'
            },
            {
                color: 'FFFFFF',
                label: 'unstart_op'
            }
        ],
        expectedStatus: [
            {
                color: '329fe5',
                label: 'advancement_op'
            },
            {
                color: '73c768',
                label: 'complete_op'
            },
            {
                color: 'f49d2c',
                label: 'equals_op'
            },
            {
                color: 'ec555a',
                label: 'overdue_op'
            }
        ],
        progressStatus: [
            {
                color: '73c768',
                label: '100%'
            },
            {
                color: '8ad44f',
                label: '75%'
            },
            {
                color: 'f49d2c',
                label: '50%'
            },
            {
                color: 'fae965',
                label: '25%'
            },
            {
                color: 'FFFFFF',
                label: '0%'
            }
        ]
    }
    const [configuration, setConfiguration] = useState({
        groupping: {
            structure: true,
            location: true,
            unit: false
        },
        visualization: {
            values: {
                units: true,
                percentaje: false
            },
            expected: {
                total: true,
                expected: false,
                tracing: true,
                base: false
            }
        },
        statusVisualization: {
            generalStatus: false,
            expectedStatus: true,
            progressStatus: false
        }
    })

    const [modalData, setModalData] = useState({
        groupping: {
            structure: true,
            location: true,
            unit: true
        },
        visualization: {
            values: {
                units: true,
                percentaje: false
            },
            expected: {
                total: true,
                expected: false,
                tracing: true,
                base: false
            }
        },
        statusVisualization: {
            generalStatus: true,
            expectedStatus: false,
            progressStatus: false
        }
    })

    const tableUnitRef = useRef(null)
    const tableLocationRef = useRef(null)
    const tableStructureRef = useRef(null)

    const [gridCols, setGridCols] = useState([])
    const [grouppedRows, setGrouppedRows] = useState([])
    const [locationArray, setLocationArray] = useState([])
    const [showModal, setShowModal] = useState(false)
    const { height, width } = useWindowDimensions()
    const staticCol = useRef(null);
    const staticHeader = useRef(null)
    const staticLocationHeader = useRef(null)
    const staticStructureHeader = useRef(null)
    const headerCol = useRef(null)

    /** Headers (Unit, Location and Structure height) */
    const gridHeaderHeight = 25

    /** Height for fixed col activities and grid rows */
    const activitiesColHeight = 35

    /** Grid container header */
    const [gridHeight, setGridHeight] = useState(0)

    useEffect(() => {
        if (gridHeight) {
            if (tableUnitRef.current && tableUnitRef.current.resetAfterColumnIndex) {
                tableUnitRef.current.resetAfterColumnIndex(0)
                tableUnitRef.current.resetAfterRowIndex(0)
            } else if (tableLocationRef.current && tableLocationRef.current.resetAfterColumnIndex) {
                tableLocationRef.current.resetAfterColumnIndex(0)
                tableLocationRef.current.resetAfterRowIndex(0)
            } else if (tableStructureRef.current && tableStructureRef.current.resetAfterColumnIndex) {
                tableStructureRef.current.resetAfterColumnIndex(0)
                tableStructureRef.current.resetAfterRowIndex(0)
            }
        }
    }, [gridHeight])

    /** Complete container width */
    // const gridWidth = width * 0.7
    const originalWidth = width > 1700 ? width * 0.81 : width * 0.78
    const [gridWidth, setGridWidth] = useState(originalWidth)

    /** Width of each col rendered in the grid */
    const gridColWidth = originalWidth

    /** Width of each col rendered in fixed activities */
    const [fixedColWidth, setFixedColWidth] = useState(150)

    useEffect(() => {
        if (staticLocationHeader.current) {
            staticLocationHeader.current.resetAfterColumnIndex(0)
        }

        if (staticStructureHeader.current) {
            staticStructureHeader.current.resetAfterColumnIndex(0)
        }
    }, [configuration])

    const [structuresArray, setStructuresArray] = useState([])

    useEffect(() => {
        updateVirtualizationWidth()
    }, [configuration])

    useEffect(() => {
        updateVirtualizationWidth()
    }, [structuresArray])

    let _containerRef = containerRef
    if (_containerRef === null) {
        _containerRef = {}
    }
    useEffect(() => {
        if (_containerRef.current) {
            // setGridHeight(containerRef.current.offsetHeight - 126)
            // setGridHeight(containerRef.current.offsetHeight * 0.78)
        }
        setGridHeight(700 * 0.78)
        setFixedColWidth(width * 0.1)
        updateVirtualizationWidth()
    }, [width, _containerRef.current])

    /**
   * This effect loads in an lineal array the tree structure of locations, only getting which ones that has at last one unit production
   * Also creates the the flag to get last index of their unit at grid
   */
    useEffect(() => {
    /** Finally for last the second fixed header row, shows the units which at now is the minimum level  */
        const linealUnitsArray = getAllUnits()

        setGridCols(linealUnitsArray)
    }, [locationArray])

    useEffect(() => {
    /** Finally for last the second fixed header row, shows the units which at now is the minimum level  */
        let acumIndex = 0
        structuresArray.map(location => {
            location.locationsWithWidth.map((unit, index) => {
                acumIndex += 1
                if ((location.locationsWithWidth.length - 1) == index) {
                    location.lastIndex = acumIndex
                }
            })
        })
    }, [structuresArray])

    const [optionsForFilter, setOptionsForFilter] = useState([])
    const [selectedActivitiesFilter, setSelectedActivitiesFilter] = useState([])
    const [loading, setLoading] = useState(true)

    /**
   * This effect creates basic structure data for this component work
   */
    useEffect(() => {
        setLoading(true)
        /** Code below sets an lineal tree data structure to show at fixed first col of the grid */
        const linealActivitiesArray = []
        treeToLinealArray(activities, linealActivitiesArray, 0)
        const grouppedActivities = groupActivitiesByName(linealActivitiesArray)
        const options = []
        grouppedActivities.map(ac => {
            options.push({ label: splitText(ac.name, 150), value: ac.name })
        })
        /** Created options array for filter */
        setOptionsForFilter(options)

        /** Populate the selected with all current activities */
        setSelectedActivitiesFilter(options)

        setGrouppedRows(grouppedActivities)
        setGridRows(linealActivitiesArray)

        /** Code below creates an array with locations that have units to show as a upper level o visualization for first fixed row header */
        getLocationsWithUnits(units)
    }, [selectedActivities, selectedUnits, activities, units])

    useEffect(() => {
        setLoading(true)
    }, [grouppedRows, gridRows, selectedActivitiesFilter, optionsForFilter])

    useEffect(() => {
        setTimeout(() => {
            if (!tableUnitRef.current) {
                setLoading(false)
            }
        }, 500)
    }, [loading])

    /**
   * This function updates the scroll reference on all static virtualized elements, taking the grid movements
   */
    const onScroll = useCallback(
        (all) => {
            const { scrollLeft, scrollTop, scrollUpdateWasRequested } = all
            if (!scrollUpdateWasRequested) {
                staticCol.current.scrollTo({ scrollLeft: 0, scrollTop });
                if (staticHeader.current) {
                    staticHeader.current.scrollTo({ scrollTop: 0, scrollLeft })
                }
                if (staticLocationHeader.current) {
                    staticLocationHeader.current.scrollTo({ scrollTop: 0, scrollLeft })
                }
                if (staticStructureHeader.current) {
                    staticStructureHeader.current.scrollTo({ scrollTop: 0, scrollLeft })
                }
            }
        }
    );

    const updateVirtualizationWidth = () => {
        let sumWidth = 0
        if (configuration.groupping.structure && !configuration.groupping.unit && !configuration.groupping.location) {
            structuresArray.map(structure => {
                sumWidth += structure.widthForHeader
            })
        } else {
            locationArray.map(structure => {
                sumWidth += structure.widthForHeader
            })
        }

        if (sumWidth < originalWidth && sumWidth != 0) {
            setGridWidth(sumWidth - 10)
        } else if (sumWidth > originalWidth) {
            const { current: widthTable } = headerCol
            if (widthTable) {
                return setGridWidth((prev) => {
                    let multiply = prev > widthTable.clientWidth ? (546 / widthTable.clientHeight) : (widthTable.clientWidth / prev)
                    multiply = multiply < 2 && multiply > 1.15 ? 1.5 : multiply // cases of proportion between widthTable width and prev width
                    // case of proportion betwwen prev fixedcol width  and new colwidth
                    multiply = widthTable.clientWidth / fixedColWidth > 15
                        ? multiply
                        : widthTable.clientWidth / fixedColWidth > 11.5
                            ? multiply * 3
                            : widthTable.clientWidth / fixedColWidth > 9
                                ? multiply * 2.2
                                : multiply * 1.1
                    return widthTable.clientWidth - (fixedColWidth * multiply) - 10
                })
            }

            setGridWidth(originalWidth)
        }
    }

    const getAllUnits = (locations = locationArray) => {
        const linealUnitsArray = []
        let acumIndex = 0
        locations.forEach(location => {
            location.productionUnits.sort((a, b) => a.correlative_id - b.correlative_id)
            location.productionUnits.map((unit, index) => {
                acumIndex += 1
                if ((location.productionUnits.length - 1) == index) {
                    location.lastIndex = acumIndex
                }
                linealUnitsArray.push(unit)
            })
        })

        return linealUnitsArray
    }

    /**
   * This function goes deeply on a tree data structure and transforms it to an lineal array in the same structure
   * @param {*} tree Array with tree format
   * @param {*} finalJsxArray empty array to push deeply data
   * @param {*} lvl 0 on start to deeply get level for elements
   */
    const treeToLinealArray = (tree, finalJsxArray, lvl) => {
        if (activities === null || activities === []) return <ReturnTestWrapped />
        if (units === null) return <ReturnTestWrapped />
        tree.map(element => {
            const hasChilds = element.children
            element.lvl = lvl
            finalJsxArray.push(element)
            if (hasChilds) {
                if (hasChilds.length) {
                    treeToLinealArray(hasChilds, finalJsxArray, (lvl + 1))
                }
            }
        })
    }

    /**
   * This function gets units (all structure with locations) and transforms to only locatiosn with production unit
   * @param {*} units All locations with units array
   */
    const getLocationsWithUnits = (units) => {
        setIsLoading(true)
        const linealArray = []
        treeToLinealArray(units, linealArray, 0)
        const locationsWithUnits = linealArray.filter(location => {
            if (!location.productionUnits) {
                return false
            } else if (location.productionUnits.length) {
                return true
            }
        })

        locationsWithUnits.map(location => {
            getLocationWidth(location)
        })

        const structureIds = locationsWithUnits.map(loc => loc.structureId)
        const structures = []
        structureIds.map(s => {
            const str = linealArray.find(l => (l.id == s && !l.structureId))
            const doesExist = structures.find(el => el.id == str.id)
            if (!doesExist) {
                const mapping = []
                linealArray.forEach((location, _idx, locationArray) => {
                    if (location.productionUnits) {
                        if (location.productionUnits.length && location.structureId == str.id) {
                            mapping.push({
                                id: location.id,
                                width: gridColWidth
                            })
                        }
                    }
                })

                str.locationsWithWidth = mapping
                structures.push(str)
            }
        })

        structures.map(structure => {
            getStructureWidth(structure)
        })

        setStructuresArray(structures)
        setLocationArray(locationsWithUnits)
    }
    /**
     * This function gets tasks grouped by activitieName and calculate Expexted
     * @param {*} linealActivitiesArray
     */
    const groupActivitiesByName = (linealActivitiesArray) => {
        const grouppedActivitiesArray = []
        const activitiesWithChild = linealActivitiesArray.filter(ac => !ac.has_childs)
        activitiesWithChild.map(ac => {
            ac.name = ac.name.trim()
            const doesExistAtMap = grouppedActivitiesArray.find(act => act.name.trim() == ac.name)
            if (ac.productionUnits.length) {
                if (doesExistAtMap) {
                    doesExistAtMap.activities.push(ac)
                } else {
                    grouppedActivitiesArray.push({
                        name: ac.name,
                        activities: [
                            ac
                        ]
                    })
                }
            }
        })
        grouppedActivitiesArray.filter(activitieWithLocat => activitieWithLocat.activities.length)
            .forEach(activitie => {
                activitie.activities.filter(locatWithUnits => locatWithUnits.productionUnits.length)
                    .forEach(locat => {
                        locat.productionUnits.forEach(task => {
                            const grouppedActivity = activitie
                            const parentActivity = grouppedActivity.activities.find(ac => ac.id == task.tasks[0].activityId)
                            task.tasks[0].expected = calculateExpected(task.tasks[0], ganttAPI, parentActivity.calendarId, null, configuration.visualization.expected.base);
                        })
                    })
            })
        return grouppedActivitiesArray
    }

    /**
   * This function calculates how width should have in the grid a location based on their units
   * @param {*} location location object
   */
    const getLocationWidth = (location) => {
        location.widthForHeader = gridColWidth
    }

    const getStructureWidth = (structure) => {
        let allLocationsWidth = 0
        structure.locationsWithWidth.map(loc => {
            allLocationsWidth += loc.width
        })
        structure.widthForHeader = allLocationsWidth
    }

    const changeTreeVisualization = () => {
        setTreeActivitiesVisualization(!treeActivitiesVisualization)
    }

    const applyConfiguration = () => {
        setConfiguration({ ...modalData })
        setShowModal(false)
    }

    const handleChange = (els) => {
        setSelectedActivitiesFilter(els)

        const toDisable = differenceBy(optionsForFilter, els)
        toDisable.map(({ value }) => {
            const grouppedActivity = grouppedRows.find(ac => ac.name == value)
            grouppedActivity.hide = true
        })

        const toEnable = els
        toEnable.map(({ value }) => {
            const grouppedActivity = grouppedRows.find(ac => ac.name == value)
            grouppedActivity.hide = false
        })

        setGrouppedRows([...grouppedRows])
    }

    // exportable MatrixTakt
    useEffect(() => {
        function defineBackgroundColorCell (statusColor, acumProgress, acumProgressExpected, inProgressTasks, doneTasks, expectedTasks, tasksArray) {
            let color = '#FFFFFF'
            if (statusColor.generalStatus) {
                if (doneTasks.length == tasksArray.length) {
                    return statusColorsExportable.generalStatus[0].color
                } else if (inProgressTasks.length) {
                    return statusColorsExportable.generalStatus[1].color
                } else {
                    color = '#121212'
                    return statusColorsExportable.generalStatus[2].color
                }
            } else if (statusColor.expectedStatus) {
                if (configuration.visualization.values.percentaje) {
                    if (acumProgress >= 99.98) {
                        return statusColorsExportable.expectedStatus[1].color
                    } else if (acumProgress > acumProgressExpected) {
                        return statusColorsExportable.expectedStatus[0].color
                    } else if (acumProgress == acumProgressExpected) {
                        return statusColorsExportable.expectedStatus[2].color
                    } else if (acumProgress < acumProgressExpected) {
                        return statusColorsExportable.expectedStatus[3].color
                    }
                } else {
                    if (doneTasks.length == tasksArray.length) {
                        return statusColorsExportable.expectedStatus[1].color
                    } else if (doneTasks.length > expectedTasks.length) {
                        return statusColorsExportable.expectedStatus[0].color
                    } else if (doneTasks.length == expectedTasks.length) {
                        return statusColorsExportable.expectedStatus[2].color
                    } else if (doneTasks.length < expectedTasks.length) {
                        return statusColorsExportable.expectedStatus[3].color
                    }
                }
            } else if (statusColor.progressStatus) {
                if (acumProgress >= 99.98) {
                    return statusColorsExportable.progressStatus[0].color
                } else if (acumProgress >= 75 && acumProgress < 99.99) {
                    return statusColorsExportable.progressStatus[1].color
                } else if (acumProgress >= 50 && acumProgress < 75) {
                    return statusColorsExportable.progressStatus[2].color
                } else if (acumProgress >= 25 && acumProgress < 50) {
                    return statusColorsExportable.progressStatus[3].color
                } else if (acumProgress > 0 && acumProgress < 25) {
                    return statusColorsExportable.progressStatus[4].color
                }
            }
        }
        setTimeout(() => {
            const data_export = []
            const data_header = []
            const data_extraHeaderStructure = []
            const data_extraHeaderLocations = []
            const data_backgroundColorCells = []
            if (grouppedRows.length) {
                // 3 CASES OF STRUCTURE LEVELS
                if (configuration.groupping.unit == true) {
                    // FINAL DATA
                    let i = -1
                    grouppedRows.map(activity => {
                        i += 1
                        if (activity.activities.length) {
                            data_export.push({
                                name: activity.name
                            })
                            data_backgroundColorCells.push({
                                name: activity.name
                            })
                            activity.activities.map(locat => {
                                if (locat.productionUnits.length) {
                                    locat.productionUnits.map(unid => {
                                        let progress = unid.tasks[0].progress
                                        let num = 0
                                        let denom = 0
                                        const tasksArray = [1]
                                        const doneTasks = progress >= 99.99 && progress > 0 ? [1] : []
                                        const inProgressTasks = progress > 0 && progress < 99.98 ? [1] : []
                                        // 2 CASES OF VALUES: TRACING & BASE
                                        let expected = unid.tasks[0].expected
                                        const expectedTasks = expected >= 99.98 ? [1] : []
                                        expected = typeof expected == 'number' ? (Math.round(expected * 10) / 10) : expected
                                        progress = typeof progress == 'number' ? (Math.round(progress * 10) / 10) : progress
                                        expected = expected === null ? '0.0' : expected
                                        // 2 CASES OF VISUALIZATION VALUES
                                        if (configuration.visualization.expected.total) {
                                            // 2 CASES OF SHOW VALUES
                                            progress >= 99.98 ? num = 1 : num = 0
                                            if (configuration.visualization.values.units) {
                                                data_export[i][unid.id] = [`${num}/1`, unid.name]
                                            } else {
                                                data_export[i][unid.id] = [`${progress}%`, unid.name]
                                            }
                                            // COLOR STATUS
                                            const color = defineBackgroundColorCell(configuration.statusVisualization, progress, expected, inProgressTasks, doneTasks, expectedTasks, tasksArray)
                                            data_backgroundColorCells[i][unid.id] = color
                                        } else if (configuration.visualization.expected.expected) {
                                            // 2 CASES OF SHOW VALUES
                                            progress >= 99.98 ? num = 1 : num = 0
                                            expected >= 99.98 ? denom = 1 : denom = 0
                                            if (configuration.visualization.values.units) {
                                                // CASE OF BASE EXPECTED
                                                if (!unid.tasks[0].base_duration && configuration.visualization.expected.base) {
                                                    data_export[i][unid.id] = [`${num}/-`, unid.name]
                                                } else {
                                                    data_export[i][unid.id] = [`${num}/${denom}`, unid.name]
                                                }
                                            } else if (configuration.visualization.values.percentaje) {
                                                // CASE OF BASE EXPECTED
                                                if (!unid.tasks[0].base_duration && configuration.visualization.expected.base) {
                                                    data_export[i][unid.id] = ['-', unid.name]
                                                } else {
                                                    data_export[i][unid.id] = [`${expected}%`, unid.name]
                                                }
                                            }
                                            // COLOR STATUS
                                            const color = defineBackgroundColorCell(configuration.statusVisualization, progress, expected, inProgressTasks, doneTasks, expectedTasks, tasksArray)
                                            data_backgroundColorCells[i][unid.id] = color
                                        }
                                    })
                                }
                            })
                        }
                    })
                    // DATA HEADER
                    if (configuration.groupping.unit) {
                        locationArray.map(locat => {
                            locat.productionUnits.map(unit => {
                                data_header.push([unit.id, unit.name])
                            })
                        })
                    }
                    // OPTIONAL HEADERS
                    if (configuration.groupping.structure) {
                        units.map(structure => {
                            let numCeldas = 0
                            structure.children.map(locat => {
                                numCeldas += locat.productionUnits.length
                            })
                            data_extraHeaderStructure.push({
                                name: structure.name,
                                numCeldas: numCeldas
                            })
                        })
                    }
                    if (configuration.groupping.location) {
                        locationArray.map(locat => {
                            data_extraHeaderLocations.push({
                                name: locat.name,
                                numCeldas: locat.productionUnits.length
                            })
                        })
                    }
                } else if (configuration.groupping.unit == false && configuration.groupping.location == true) {
                    // FINAL DATA
                    let i = -1
                    grouppedRows.map(activity => {
                        i += 1
                        if (activity.activities.length) {
                            data_export.push({
                                name: activity.name
                            })
                            data_backgroundColorCells.push({
                                name: activity.name
                            })
                            if (activity.activities.length > 1 && activity.activities[0].productionUnits.length <= 1) {
                                let control = 0
                                const keysDiff = []
                                while (activity.activities.length > control) {
                                    let key = ''
                                    const units_actualProgress = []
                                    const units_expectedProgress = []
                                    const units_ponderator = []
                                    for (var same = control; same < activity.activities.length; same++) {
                                        key = activity.activities[same].productionUnits[0].locationId
                                        if (!keysDiff.length) {
                                            keysDiff.push(key)
                                        }
                                        if (!keysDiff.includes(key) && same != 0) {
                                            keysDiff.push(key)
                                            control = same
                                            key = activity.activities[same - 1].productionUnits[0].locationId
                                            break
                                        }
                                        units_actualProgress.push(activity.activities[same].productionUnits[0].tasks[0].progress)
                                        units_expectedProgress.push(activity.activities[same].productionUnits[0].tasks[0].expected === null ? '0.0' : activity.activities[same].productionUnits[0].tasks[0].expected)
                                        units_ponderator.push(activity.activities[same].productionUnits[0].tasks[0].ponderator)
                                        if (same == activity.activities.length - 1) {
                                            control = activity.activities.length
                                        }
                                    }
                                    let num = 0
                                    let j = 0
                                    let denomActual = 0
                                    let denomExpected = 0
                                    let progress = 0
                                    let acumPonderators = 0
                                    units_actualProgress.map(unit_progress => {
                                        unit_progress == 100 ? num += 1 : num += 0
                                        denomActual += 1
                                        j += 1
                                        acumPonderators += units_ponderator[j - 1]
                                        progress += unit_progress * units_ponderator[j - 1]
                                    })
                                    const doneTasks = units_actualProgress.filter(progressTask => progressTask >= 99.99 && progressTask > 0)
                                    const inProgressTasks = units_actualProgress.filter(progressTask => progressTask > 0 && progressTask < 99.99)
                                    let k = 0
                                    let progressExpected = 0
                                    units_expectedProgress.map(n_progress => {
                                        k += 1
                                        progressExpected += n_progress * units_ponderator[k - 1]
                                        n_progress == 100 ? denomExpected += 1 : denomExpected += 0
                                    })
                                    const expectedTasks = units_expectedProgress.filter(t => t >= 99.99)
                                    progressExpected = progressExpected / acumPonderators
                                    progress = progress / acumPonderators
                                    progress = isNaN(progress) ? 0 : (Math.round(progress * 10) / 10)
                                    progressExpected = isNaN(progressExpected) ? 0 : (Math.round(progressExpected * 10) / 10)
                                    // 2 CASES OF VISUALIZATION VALUES
                                    if (configuration.visualization.expected.total) {
                                        // 2 CASES OF SHOW VALUES
                                        if (configuration.visualization.values.units) {
                                            data_export[i][key] = [`${num}/${denomActual}`]
                                        } else {
                                            data_export[i][key] = [`${progress}%`]
                                        }
                                        // COLOR STATUS
                                        const color = defineBackgroundColorCell(configuration.statusVisualization, progress, progressExpected, inProgressTasks, doneTasks, expectedTasks, units_actualProgress)
                                        data_backgroundColorCells[i][key] = color
                                    } else if (configuration.visualization.expected.expected) {
                                        // 2 CASES OF SHOW VALUES
                                        if (configuration.visualization.values.units) {
                                            // CASE OF BASE EXPECTED
                                            if (!activity.activities[control - 1].tasks[0].base_duration && configuration.visualization.expected.base) {
                                                data_export[i][key] = [`${num}/-`]
                                            } else {
                                                data_export[i][key] = [`${num}/${denomExpected}`]
                                            }
                                        } else {
                                            // CASE OF BASE EXPECTED
                                            if (!activity.activities[control - 1].tasks[0].base_duration && configuration.visualization.expected.base) {
                                                data_export[i][key] = ['-']
                                            } else {
                                                const progressExpectedd = isNaN(progressExpected) ? '0.0' : progressExpected
                                                data_export[i][key] = [`${progressExpectedd}%`]
                                            }
                                        }
                                        // COLOR STATUS
                                        const color = defineBackgroundColorCell(configuration.statusVisualization, progress, progressExpected, inProgressTasks, doneTasks, expectedTasks, units_actualProgress)
                                        data_backgroundColorCells[i][key] = color
                                    }
                                }
                            } else {
                                activity.activities.map(locat => {
                                    let control = 0
                                    const keysDiff = []
                                    while (locat.productionUnits.length > control) {
                                        let key = ''
                                        const units_actualProgress = []
                                        const units_expectedProgress = []
                                        const units_ponderator = []
                                        for (var same = control; same < locat.productionUnits.length; same++) {
                                            key = locat.productionUnits[same].locationId
                                            if (!keysDiff.length) {
                                                keysDiff.push(key)
                                            }
                                            if (!keysDiff.includes(key) && same != 0) {
                                                keysDiff.push(key)
                                                control = same
                                                key = locat.productionUnits[same - 1].locationId
                                                break
                                            }
                                            units_actualProgress.push(locat.productionUnits[same].tasks[0].progress)
                                            units_expectedProgress.push(locat.productionUnits[same].tasks[0].expected === null ? '0.0' : locat.productionUnits[same].tasks[0].expected)
                                            units_ponderator.push(locat.productionUnits[same].tasks[0].ponderator)
                                            if (same == locat.productionUnits.length - 1) {
                                                control = locat.productionUnits.length
                                            }
                                        }
                                        let num = 0
                                        let j = 0
                                        let denomActual = 0
                                        let denomExpected = 0
                                        let progress = 0
                                        let acumPonderators = 0
                                        units_actualProgress.map(unit_progress => {
                                            unit_progress == 100 ? num += 1 : num += 0
                                            denomActual += 1
                                            j += 1
                                            progress += unit_progress * units_ponderator[j - 1]
                                            acumPonderators += units_ponderator[j - 1]
                                        })
                                        const doneTasks = units_actualProgress.filter(progressTask => progressTask >= 99.99 && progressTask > 0)
                                        const inProgressTasks = units_actualProgress.filter(progressTask => progressTask > 0 && progressTask < 99.99)
                                        let k = 0
                                        let progressExpected = 0
                                        units_expectedProgress.map(n_progress => {
                                            k += 1
                                            progressExpected += n_progress * units_ponderator[k - 1]
                                            n_progress == 100 ? denomExpected += 1 : denomExpected += 0
                                        })
                                        const expectedTasks = units_expectedProgress.filter(t => t >= 99.99)
                                        progress = progress / acumPonderators
                                        progressExpected = progressExpected / acumPonderators
                                        progress = isNaN(progress) ? 0 : (Math.round(progress * 10) / 10)
                                        progressExpected = isNaN(progressExpected) ? 0 : (Math.round(progressExpected * 10) / 10)
                                        // 2 CASES OF VISUALIZATION VALUES
                                        if (configuration.visualization.expected.total) {
                                            // 2 CASES OF SHOW VALUES
                                            if (configuration.visualization.values.units) {
                                                data_export[i][key] = [`${num}/${denomActual}`]
                                            } else {
                                                data_export[i][key] = [`${progress}%`]
                                            }
                                            // COLOR STATUS
                                            const color = defineBackgroundColorCell(configuration.statusVisualization, progress, progressExpected, inProgressTasks, doneTasks, expectedTasks, units_actualProgress)
                                            data_backgroundColorCells[i][key] = color
                                        } else if (configuration.visualization.expected.expected) {
                                            // 2 CASES OF SHOW VALUES
                                            if (configuration.visualization.values.units) {
                                                // CASE OF BASE EXPECTED
                                                if (!locat.productionUnits[control - 1].tasks[0].base_duration && configuration.visualization.expected.base) {
                                                    data_export[i][key] = [`${num}/-`]
                                                } else {
                                                    data_export[i][key] = [`${num}/${denomExpected}`]
                                                }
                                            } else {
                                                // CASE OF BASE EXPECTED
                                                if (!locat.productionUnits[control - 1].tasks[0].base_duration && configuration.visualization.expected.base) {
                                                    data_export[i][key] = ['-']
                                                } else {
                                                    const progressExpectedd = isNaN(progressExpected) ? '0.0' : progressExpected
                                                    data_export[i][key] = [`${progressExpectedd}%`]
                                                }
                                            }
                                            // COLOR STATUS
                                            const color = defineBackgroundColorCell(configuration.statusVisualization, progress, progressExpected, inProgressTasks, doneTasks, expectedTasks, units_actualProgress)
                                            data_backgroundColorCells[i][key] = color
                                        }
                                    }
                                })
                            }
                        }
                    })
                    // DATA HEADER
                    if (configuration.groupping.location) {
                        locationArray.map(locat => {
                            data_header.push([locat.id, locat.name])
                        })
                    }
                    // OPTIONAL HEADER
                    if (configuration.groupping.structure) {
                        units.map(structure => {
                            if (structure.locationsWithWidth) {
                                data_extraHeaderStructure.push({
                                    name: structure.name,
                                    numCeldas: structure.locationsWithWidth.length
                                })
                            }
                        })
                    }
                } else if (configuration.groupping.unit == false && configuration.groupping.location == false && configuration.groupping.structure == true) {
                    // FINAL DATA
                    let i = -1
                    const array_structures = []
                    units.map(structure => {
                        array_structures.push([structure.id, structure.locationsWithWidth.map(a => a.id)])
                    })
                    grouppedRows.map(activity => {
                        i += 1
                        if (activity.activities.length) {
                            data_export.push({
                                name: activity.name
                            })
                            data_backgroundColorCells.push({
                                name: activity.name
                            })
                            let control = 0
                            const keysDiff = []
                            while (activity.activities.length > control) {
                                let key = ''
                                const units_actualProgress = []
                                const units_expectedProgress = []
                                const units_ponderator = []
                                let base_duration = ''
                                for (var same = control; same < activity.activities.length; same++) {
                                    let locationID = activity.activities[same].productionUnits[0].locationId
                                    array_structures.map(structure => {
                                        if (structure[1].includes(locationID)) {
                                            key = structure[0]
                                        }
                                    })
                                    if (!keysDiff.length) {
                                        keysDiff.push(key)
                                    }
                                    if (!keysDiff.includes(key) && same != 0) {
                                        keysDiff.push(key)
                                        control = same
                                        locationID = activity.activities[same - 1].productionUnits[0].locationId
                                        array_structures.map(structure => {
                                            if (structure[1].includes(locationID)) {
                                                key = structure[0]
                                            }
                                        })

                                        break
                                    }
                                    activity.activities[same].productionUnits.map(unid => {
                                        units_actualProgress.push(unid.tasks[0].progress)
                                        units_expectedProgress.push(unid.tasks[0].expected === null ? '0.0' : unid.tasks[0].expected)
                                        units_ponderator.push(unid.tasks[0].ponderator)
                                        base_duration = unid.tasks[0].base_duration
                                    })
                                    if (same == activity.activities.length - 1) {
                                        control = activity.activities.length
                                    }
                                }
                                let num = 0
                                let denomActual = 0
                                const num_locat = activity.activities.length
                                let k = 0
                                let progress = 0
                                let acumPonderators = 0
                                units_actualProgress.map(unit_progress => {
                                    unit_progress >= 99.98 ? num += 1 : num += 0
                                    denomActual += 1
                                    progress += unit_progress * units_ponderator[k]
                                    acumPonderators += units_ponderator[k]
                                    k += 1
                                })
                                progress = progress / acumPonderators
                                const doneTasks = units_actualProgress.filter(progressTask => progressTask >= 99.99 && progressTask > 0)
                                const inProgressTasks = units_actualProgress.filter(progressTask => progressTask > 0 && progressTask < 99.99)
                                let denomExpected = 0
                                let j = 0
                                let progressExpected = 0
                                units_expectedProgress.map(unit_progress => {
                                    unit_progress >= 99.98 ? denomExpected += 1 : denomExpected += 0
                                    progressExpected += unit_progress * units_ponderator[j]
                                    j += 1
                                })
                                progressExpected = progressExpected / acumPonderators
                                progress = typeof progress == 'number' ? (Math.round(progress * 10) / 10) : progress
                                progressExpected = typeof progressExpected == 'number' ? (Math.round(progressExpected * 10) / 10) : progressExpected
                                const expectedTasks = units_expectedProgress.filter(t => t >= 99.99)
                                // 2 CASES OF VISUALIZATION VALUES
                                if (configuration.visualization.expected.total) {
                                    // 2 CASES OF SHOW VALUES
                                    if (configuration.visualization.values.units) {
                                        data_export[i][key] = `${num}/${denomActual}`
                                    } else {
                                        data_export[i][key] = `${progress}%`
                                    }
                                    // COLOR STATUS
                                    const color = defineBackgroundColorCell(configuration.statusVisualization, progress, progressExpected, inProgressTasks, doneTasks, expectedTasks, units_actualProgress)
                                    data_backgroundColorCells[i][key] = color
                                } else if (configuration.visualization.expected.expected) {
                                    // 2 CASES OF SHOW VALUES
                                    if (configuration.visualization.values.units) {
                                        // CASE OF BASE EXPECTED
                                        if (!base_duration && configuration.visualization.expected.base) {
                                            data_export[i][key] = `${num}/-`
                                        } else {
                                            data_export[i][key] = `${num}/${denomExpected}`
                                        }
                                    } else {
                                        // CASE OF BASE EXPECTED
                                        if (!base_duration && configuration.visualization.expected.base) {
                                            data_export[i][key] = '-'
                                        } else {
                                            data_export[i][key] = `${progressExpected}%`
                                        }
                                    }
                                    // COLOR STATUS
                                    const color = defineBackgroundColorCell(configuration.statusVisualization, progress, progressExpected, inProgressTasks, doneTasks, expectedTasks, units_actualProgress)
                                    data_backgroundColorCells[i][key] = color
                                }
                            }
                        }
                    })
                    // DATA HEADER
                    units.map(structure => {
                        let numCeldas = 0
                        // REVISAR AQUI
                        structure.children.map(locat => {
                            numCeldas += locat.productionUnits.length
                        })
                        data_header.push([structure.id, structure.name])
                    })
                }
            }
            setFinalData(data_export)
            setHeaderData(data_header)
            setExtraHeaderStructure(data_extraHeaderStructure)
            setExtraHeaderLocation(data_extraHeaderLocations)
            setBackgroundColorData(data_backgroundColorCells)
        }, 1000);
    }, [grouppedRows, configuration, state])

    async function matrixTaktReport () {
        setState(!state)
        const project = await projectService.show(projectState.projectSelected);
        const sector = await projectState.allSectors.find(e => e.id == projectState.sectorSelected)
        const projectName = project.project.name
        const sectorName = sector.name

        function getRow (data) {
            const keys = []
            data.map(row => {
                (Object.keys(row)).map(key => {
                    keys.push(key)
                })
            })
            let fields = [...new Set(keys)]
            fields = fields.filter(e => e !== 'name')
            fields.unshift('name')
            return fields
        }
        function getRowHeader (data) {
            const keys = []
            data.map(row => {
                (Object.keys(row)).map(key => {
                    keys.push(key)
                })
            })
            let fields = [...new Set(keys)]
            fields = fields.filter(e => e !== 'name')
            fields.sort()

            data.map(row => {
                (Object.keys(row)).map(key => {
                    if (fields.includes(key)) {
                        fields[fields.indexOf(key)] = row[key][1]
                    }
                })
            })
            return fields
        }
        function getRowLocHeader (data) {
            const keys = []
            data.map(row => {
                keys.push(row.name)
            })
            return keys
        }
        function getColorData (data) {
            data.shift()
            data.map(row => {
                row.shift()
            })
            return data
        }
        function getSheetData (data, header) {
            const keys = []
            data.map(row => {
                (Object.keys(row)).map(key => {
                    keys.push(key)
                })
            }
            )
            let fields = [...new Set(keys)]
            fields = fields.filter(e => e !== 'name')
            fields.sort()
            fields.unshift('name')
            var sheetData = data.map(function (row) {
                return fields.map(function (fieldName) {
                    return row[fieldName] ? row[fieldName] : '';
                });
            });
            sheetData.unshift(header);
            return sheetData;
        }
        function getSheetDataX (data, header, headerID) {
            const keys = []
            data.map(row => {
                (Object.keys(row)).map(key => {
                    keys.push(key)
                })
            }
            )
            let fields = [...new Set(keys)]
            fields = fields.filter(e => e !== 'name')
            fields.sort()
            fields.unshift('name')
            headerID.unshift('name')

            var sheetData = data.map(function (row) {
                return headerID.map(function (fieldName) {
                    return row[fieldName] ? row[fieldName] : '';
                });
            });
            sheetData.unshift(header);
            return sheetData;
        }
        function getSheetDataFD (data) {
            const keys = []
            let fields = []
            data.map(row => {
                (Object.keys(row)).map(key => {
                    keys.push(key)
                })
            }
            )
            fields = [...new Set(keys)]
            fields = fields.filter(e => e == 'name')
            fields.sort()
            var sheetData = data.map(function (row) {
                return fields.map(function (fieldName) {
                    return row[fieldName]
                        ? typeof row[fieldName] == 'string' ? row[fieldName] : row[fieldName][0]
                        : '';
                });
            });
            sheetData.unshift(['name'])
            return sheetData;
        }
        function getSheetActivitiesDataFD (data, headerID) {
            const keys = []
            headerID = headerID.length == 0 ? ['total'] : headerID
            var sheetData = data.map(function (row) {
                return headerID.map(function (fieldName) {
                    return row[fieldName]
                        ? typeof row[fieldName] == 'string' ? row[fieldName] : row[fieldName][0]
                        : '';
                });
            });
            sheetData.unshift(headerID.filter(e => e !== 'name'))
            return sheetData;
        }
        function letterColumnExcel (totalColumns) {
            if (totalColumns <= 26) {
                return String.fromCharCode(64 + totalColumns)
            } else {
                let FirstLetter = ''
                let SecondLetter = ''
                if (totalColumns % 26 == 0) {
                    FirstLetter = String.fromCharCode(64 + Math.floor(totalColumns / 26) - 1)
                    SecondLetter = 'Z'
                } else {
                    FirstLetter = String.fromCharCode(64 + Math.floor(totalColumns / 26))
                    SecondLetter = String.fromCharCode(64 + totalColumns % 26)
                }
                return FirstLetter + SecondLetter
            }
        }

        const fieldHeader = getRow(finalData)
        const fieldLocationHeader = getRowLocHeader(extraHeaderLocation)
        const fieldStructureHeader = getRowLocHeader(extraHeaderStructure)

        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            // INITIAL POPULATING EXCEL
            const sheet1 = workbook.sheet(0);
            const sheetData1 = getSheetDataFD(finalData);
            let header = []
            const headerID = []
            if (headerData.length > 0) {
                headerData.map((element) => {
                    header.push(element[1])
                    headerID.push(element[0])
                })
            } else {
                header = getRowHeader(finalData)
            }
            const sheetData2 = getSheetActivitiesDataFD(finalData, headerID);
            if (finalData.length == 0) {
                return workbook.outputAsync().then((res) => {
                    saveAs(res, `${'Matriz_' + projectName + '_' + sectorName + '_' + moment().format('YYYYMMDD-HHmmss') + '.xlsx'}`);
                });
            } else {
            // HEADER DATA SETUP
                let totalColumns = sheetData1[0].length;
                const headerLocationData = getSheetData(extraHeaderLocation, fieldLocationHeader, headerID)
                headerLocationData.shift()
                const headerStructureData = getSheetData(extraHeaderStructure, fieldStructureHeader, headerID)
                headerStructureData.shift()

                let sheetBackgroundColorData = []
                if (typeof backgroundColorData[0] !== 'object') {
                    sheetBackgroundColorData = backgroundColorData
                } else {
                    sheetBackgroundColorData = getSheetDataX(backgroundColorData, fieldHeader, headerID)
                    getColorData(sheetBackgroundColorData)
                }
                // POPULATING EXCEL
                // 1. General data
                let rowInit = 0
                rowInit += extraHeaderStructure && extraHeaderStructure.length ? 1 : 0
                rowInit += extraHeaderLocation && extraHeaderLocation.length ? 1 : 0
                sheet1.cell('A' + (rowInit + 1).toString()).value(sheetData1);
                sheet1.cell('B' + (rowInit + 1).toString()).value(sheetData2);
                const range = sheet1.usedRange();
                const totalRows = sheetData1.length + rowInit
                totalColumns = header ? header.length + 1 : totalColumns
                const endColumn = letterColumnExcel(totalColumns)
                // 2. Optional headers
                rowInit = 0
                if (extraHeaderStructure && extraHeaderStructure.length) {
                    rowInit += 1
                    let columnInit = 2
                    headerStructureData.map(structure => {
                        const startColumn = letterColumnExcel(columnInit)
                        const endColumn = letterColumnExcel(structure[1] + columnInit - 1)
                        sheet1.range(startColumn + rowInit.toString() + ':' + endColumn + rowInit.toString()).merged(true).value(structure[0]).style("border", true).style("borderColor", '828282').style('fontColor', '535353')
                        columnInit += structure[1]
                    })
                    sheet1.range('B' + rowInit.toString() + ':' + endColumn + rowInit.toString()).style('fill', 'E3E3E3');
                }
                if (extraHeaderLocation && (extraHeaderLocation.length)) {
                    rowInit += 1
                    let columnInit = 2
                    headerLocationData.map(locat => {
                        const startColumn = letterColumnExcel(columnInit)
                        const endColumn = letterColumnExcel(locat[1] + columnInit - 1)
                        sheet1.range(startColumn + rowInit.toString() + ':' + endColumn + rowInit.toString()).merged(true).value(locat[0]).style("border", true).style("borderColor", '828282').style('fontColor', '535353')
                        columnInit += locat[1]
                    })
                    sheet1.range('B' + rowInit.toString() + ':' + endColumn + rowInit.toString()).style('fill', 'E3E3E3');
                }
                rowInit += 1
                // 3. Main header modification
                sheet1.cell('A' + rowInit.toString()).value('')
                let columnInit = 2
                header.map(header => {
                    const column = letterColumnExcel(columnInit)
                    sheet1.cell(column + rowInit.toString()).value(header)
                    columnInit += 1
                })
                // 4. BackgroundColor
                if (sheetData1.length > 1) {
                    sheet1.range('B' + rowInit.toString() + ':' + endColumn + rowInit.toString()).style('fill', 'E3E3E3');
                    sheet1.range('A' + (rowInit + 1).toString() + ':A' + totalRows).style('fill', 'E3E3E3');
                }
                let i = 0
                if (typeof sheetBackgroundColorData[0] === 'object') {
                    sheetBackgroundColorData.map(row => {
                        let j = 0
                        row.map(colorCell => {
                            const column = letterColumnExcel(j + 2)
                            colorCell = colorCell == '' ? 'FFFFFF' : colorCell
                            sheet1.range(column + (i + rowInit + 1).toString() + ':' + column + (i + rowInit + 1).toString()).style('fill', colorCell)
                            j += 1
                        })
                        i += 1
                    })
                } else {
                    sheetBackgroundColorData.map(cell => {
                        cell = cell == '' ? 'FFFFFF' : cell
                        sheet1.cell('B' + (i + rowInit + 1).toString()).style('fill', cell)
                        i += 1
                    })
                }
                // 5. Format and cell styles
                const maxLengthColumnName = sheet1.range('A' + rowInit.toString() + ':A' + totalRows).reduce((max, cell) => {
                    const value = cell.value();
                    if (value === undefined) return max;
                    return Math.max(max, value.toString().length);
                }, 0);

                sheet1.column('A').width(maxLengthColumnName)
                for (var initial = 1; initial < totalColumns; initial++) {
                    const endColumn = letterColumnExcel(1 + initial)
                    const maxLengthColumns = sheet1.range(endColumn + (rowInit).toString() + ':' + endColumn + totalRows).reduce((max, cell) => {
                        const value = cell.value();
                        if (value === undefined) return max;
                        return Math.max(max, value.toString().length);
                    }, 0);
                    sheet1.column(endColumn).width(maxLengthColumns + 3)
                }
                sheet1.range('B1:' + endColumn + totalRows).style({ horizontalAlignment: 'center', verticalAlignment: 'center' })
                sheet1.range('B1:' + endColumn + totalRows).style('border', true).style('borderColor', '828282');
                range.style('border', true).style('borderColor', '828282');
                sheet1.range('B1:' + endColumn + totalRows).style('fontColor', '535353')
                range.style('fontColor', '535353')
                return workbook.outputAsync().then((res) => {
                    saveAs(res, `${'Matriz_' + projectName + '_' + sectorName + '_' + moment().format('YYYYMMDD-HHmmss') + '.xlsx'}`);
                });
            }
        });
    }
    //

    const renderHeader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: 17, paddingBottom: 0 }}>
                <span style={{ color: '#121212', fontSize: 20 }}>
                    {t('takt_units.progress_matrix')}
                </span>
                <span style={{ position: 'relative', top: -2 }} className="matrix-activities-filter">
                    <div style={{ marginRight: 10 }}>
                        <ReactMultiSelectCheckboxes
                            placeholder={t('takt_units.search')}
                            className="filter-lookahead-multi-check"
                            getDropdownButtonLabel={() =>
                                <span
                                    style={{ color: '#121212', fontSize: '12px' }}
                                    onClick={() => {
                                        setTimeout(() => {
                                            const a = document.getElementsByClassName('css-1pcexqc-container')
                                            if (a[0]) {
                                                a[0].parentNode.style.marginTop = '0px'
                                                a[0].parentNode.style.left = '-132px'
                                                a[0].parentNode.style.top = '32px'
                                            }
                                        }, 50)
                                    }}>
                                    <img src={filterIcon} width={15} className="filter-adjust-matrix" />
                                </span>}
                            placeholderButtonLabel="Columns"
                            value={selectedActivitiesFilter}
                            onChange={handleChange}
                            options={optionsForFilter} />
                    </div>
                    <div style={{ cursor: 'pointer', marginRight: 10, padding: '5px 10px 5px 10px' }} onClick={() => {
                        setShowModal(true)
                        setModalData(JSON.parse(JSON.stringify(configuration)))
                    }}>
                        <img src={configurationIcon} width={15} />
                    </div>
                    <div style={{ cursor: 'pointer', fontSize: '12px', marginLeft: '-10px', top: '3px', position: 'relative' }} onClick={matrixTaktReport}>
                        <IconComponent data={export_file} width={15} fill="#53C255" className="svg-icon-export-file" />
                    </div>
                    <img src={moveIcon} width={15} style={{ cursor: 'pointer', marginRight: 5 }} />
                </span>
            </div>
        )
    }

    const renderColorsPerVisualization = () => {
        let def
        Object.keys(modalData.statusVisualization).map(key => {
            if (modalData.statusVisualization[key]) def = key
        })
        const toRender = statusColors[def]
        return (
            <Row>
                {
                    toRender.map(el => {
                        return (
                            <Row>
                                <Col span={3} className="bordered-matrix-conf" style={{ backgroundColor: el.color, height: 23 }}>
                                    <span>

                                    </span>
                                </Col>
                                <Col span={15} className="bordered-matrix-conf">
                                    {t('takt_units.' + el.label)}
                                </Col>
                            </Row>
                        )
                    })
                }
            </Row>
        )
    }

    const renderModalConfiguration = () => {
        return (
            <Modal
                wrapClassName="activity-modification-style matrix-config"
                title={t('takt_units.settings_label')}
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        onClick={applyConfiguration}
                        style={{
                            background: '#7DFF8A',
                            color: '#121212',
                            borderColor: '#7DFF8A'
                        }}>
                        {t('takt_units.save_label')}
                    </Button>
                ]}>
                <Row>
                    <Col>
                        <Row>
                            <div className="title-levels-takt">
                                <span>
                                    <img src={leftIcon} width={5} style={{ marginRight: 10 }} />
                                </span>
                                <span>
                                    {t('takt_units.levels')}
                                </span>
                            </div>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                            <span style={{ marginRight: 10 }}>
                                <Checkbox defaultChecked={modalData.groupping.structure} onChange={e => {
                                    modalData.groupping.structure = !modalData.groupping.structure
                                }} />
                            </span>
                            <span>
                                {t('takt_units.structures_only')}
                            </span>
                        </Row>
                        <Row style={{ marginLeft: 10, marginTop: 10 }}>
                            <span style={{ marginRight: 10 }}>
                                <Checkbox defaultChecked={modalData.groupping.location} onChange={e => {
                                    modalData.groupping.location = !modalData.groupping.location
                                }} />
                            </span>
                            <span>
                                {t('takt_units.location_only')}
                            </span>
                        </Row>
                        <Row style={{ marginLeft: 20, marginTop: 10 }}>
                            <span style={{ marginRight: 10 }}>
                                <Checkbox defaultChecked={modalData.groupping.unit} onChange={e => {
                                    modalData.groupping.unit = !modalData.groupping.unit
                                }} />
                            </span>
                            <span>
                                {t('takt_units.unit_only')}
                            </span>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <div className="title-levels-takt">
                                <span>
                                    <img src={leftIcon} width={5} style={{ marginRight: 10 }} />
                                </span>
                                <span>
                                    {t('takt_units.visualization')}
                                </span>
                            </div>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col span={10} offset={1}>
                                {t('takt_units.values_label')}
                            </Col>
                            <Col offset={1} span={12}>
                                <Radio.Group defaultValue={modalData.visualization.values.units ? 'units' : 'percentaje'} buttonStyle="solid" size="small" onChange={e => {
                                    const { target: { value } } = e
                                    if (value == 'percentaje') {
                                        modalData.visualization.values.percentaje = true
                                        modalData.visualization.values.units = false
                                    } else {
                                        modalData.visualization.values.percentaje = false
                                        modalData.visualization.values.units = true
                                    }
                                }} className="button-switch-groupping">
                                    <Radio.Button value="units" className="btnSwitch width-half">
                                        {t('takt_units.units_op')}
                                    </Radio.Button>
                                    <Radio.Button value="percentaje" className="btnSwitch width-half">
                        %
                                    </Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col span={10} offset={1}>
                                {t('takt_units.total_expected_label')}
                            </Col>
                            <Col offset={1} span={12}>
                                <Radio.Group defaultValue={modalData.visualization.expected.total ? 'total' : 'expected'} buttonStyle="solid" size="small" onChange={e => {
                                    const { target: { value } } = e
                                    if (value == 'total') {
                                        modalData.visualization.expected.total = true
                                        modalData.visualization.expected.expected = false
                                    } else {
                                        modalData.visualization.expected.total = false
                                        modalData.visualization.expected.expected = true
                                    }
                                }} className="button-switch-groupping">
                                    <Radio.Button value="total" className="btnSwitch width-half">
                                        {t('takt_units.total_op')}
                                    </Radio.Button>
                                    <Radio.Button value="expected" className="btnSwitch width-half">
                                        {t('takt_units.expected_op')}
                                    </Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: '10px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingRight: '4.5rem'
                            }}
                        >
                            <Radio.Group
                                className="button-switch-groupping"
                                value = {radioValue}
                                onChange={e => {
                                    const { target: { value } } = e
                                    if (value == 'tracing') {
                                        modalData.visualization.expected.tracing = true
                                        modalData.visualization.expected.base = false
                                        setRadioValue('tracing')
                                    } else {
                                        modalData.visualization.expected.tracing = false
                                        modalData.visualization.expected.base = true
                                        setRadioValue('base')
                                    }
                                }}
                            >
                                <Radio value="tracing" className="radioModalTakt" style={{fontSize: '12px'}}>{t('weekly_plan_widget.following')}</Radio>
                                <Radio value="base" className="radioModalTakt" style={{fontSize: '12px'}}>{t('weekly_plan_widget.base')}</Radio>
                            </Radio.Group>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                            <div className="title-levels-takt">
                                <span>
                                    <img src={leftIcon} width={5} style={{ marginRight: 10 }} />
                                </span>
                                <span>
                                    {t('takt_units.status')}
                                </span>
                            </div>
                        </Row>
                        <Row style={{ marginTop: 20, marginBottom: 10 }}>
                            <Col span={10} offset={1}>
                                <Row>
                                    <Checkbox checked={modalData.statusVisualization.generalStatus} onChange={e => {
                                        if (!modalData.statusVisualization.generalStatus) {
                                            modalData.statusVisualization.generalStatus = true
                                            modalData.statusVisualization.expectedStatus = false
                                            modalData.statusVisualization.progressStatus = false
                                        }
                                        setModalData({ ...modalData })
                                    }} />
                                    <span style={{ marginLeft: 10 }}>
                                        {t('takt_units.general_status')}
                                    </span>
                                </Row>
                                <Row>
                                    <Checkbox checked={modalData.statusVisualization.expectedStatus} onChange={e => {
                                        if (!modalData.statusVisualization.expectedStatus) {
                                            modalData.statusVisualization.expectedStatus = true
                                            modalData.statusVisualization.generalStatus = false
                                            modalData.statusVisualization.progressStatus = false
                                        }
                                        setModalData({ ...modalData })
                                    }} />
                                    <span style={{ marginLeft: 10 }}>
                                        {t('takt_units.expected_status')}
                                    </span>
                                </Row>
                                <Row>
                                    <Checkbox checked={modalData.statusVisualization.progressStatus} onChange={e => {
                                        if (!modalData.statusVisualization.progressStatus) {
                                            modalData.statusVisualization.progressStatus = true
                                            modalData.statusVisualization.expectedStatus = false
                                            modalData.statusVisualization.generalStatus = false
                                        }
                                        setModalData({ ...modalData })
                                    }} />
                                    <span style={{ marginLeft: 10 }}>
                                        {t('takt_units.progress_status')}
                                    </span>
                                </Row>
                            </Col>
                            <Col offset={1} span={12}>
                                {renderColorsPerVisualization()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>

        )
    }

    const calculateFixedStructureHeader = (index) => {
        // for locations units and structure
        if ((configuration.groupping.location && configuration.groupping.unit) || (!configuration.groupping.location && configuration.groupping.unit)) {
            if (configuration.groupping.unit) {
                const totalColumns = getAllUnits(locationArray)
                const structureIds = locationArray.map(loc => loc.structureId)
                const structures = []
                structureIds.forEach(structure => {
                    const doesExist = structures.find(el => el.id == structure)
                    if (!doesExist) {
                        const mapping = []
                        locationArray.forEach((location) => {
                            if (location.productionUnits) {
                                if (location.productionUnits.length && location.structureId == structure) {
                                    mapping.push({
                                        id: location.id,
                                        width: (gridColWidth / totalColumns.length) > 100
                                            ? location.productionUnits.length * (gridColWidth / totalColumns.length)
                                            : location.productionUnits.length * 100
                                    })
                                }
                            }
                        })

                        const newStructure = {
                            id: structure,
                            locationsWithWidth: mapping
                        }
                        structures.push(newStructure)
                    }
                })

                structures.forEach(structure => getStructureWidth(structure))
                return structures[index].widthForHeader
            }
            return structuresArray[index].widthForHeader
        }

        // for locations and structure
        if (configuration.groupping.location && !configuration.groupping.unit) {
            const locations = structuresArray[index].locationsWithWidth
            let width = calculateFixedLocationHeader(index)

            if (locations.length) {
                width = locations.reduce((acc, next) => {
                    acc += width
                    return acc
                }, 0)
            }

            return width
        }

        // just structures
        return (gridColWidth / structuresArray.length) > 100
            ? gridColWidth / structuresArray.length
            : 100
    }

    const calculateFixedLocationHeader = (index) => {
        const totalColumns = getAllUnits()
        if (configuration.groupping.unit) {
            if ((gridColWidth / totalColumns.length) > 100) {
                return locationArray[index].productionUnits.length * (gridColWidth / totalColumns.length)
            }

            return locationArray[index].productionUnits.length * 100
        }

        return (locationArray[index].widthForHeader / locationArray.length) > 100
            ? locationArray[index].widthForHeader / locationArray.length
            : 100
    }

    const renderTableVirtualized = () => {
        if (configuration.groupping.unit) {
            const minWithCol = gridColWidth / gridCols.length
            return (
                <Grid
                    ref={tableUnitRef}
                    onScroll={onScroll}
                    className="GridMatrix"
                    columnCount={gridCols.length} /** Units (not locations!!) */
                    columnWidth={minWithCol > 100 ? minWithCol : 100}
                    height={gridHeight}
                    rowCount={treeActivitiesVisualization ? gridRows.length : grouppedRows.filter(ac => !ac.hide).length} /** Activities */
                    rowHeight={activitiesColHeight}
                    width={gridWidth} >
                    {treeActivitiesVisualization
                        ? GridCellUnitTree(gridRows, gridCols, selectedActivities, selectedUnits, locationArray, configuration, statusColors, calculateExpected, ganttAPI)
                        : GridCellUnitGroupped(grouppedRows.filter(ac => !ac.hide), gridCols, selectedActivities, selectedUnits, locationArray, configuration, statusColors, calculateExpected, ganttAPI)
                    }
                </Grid>
            )
        }
        if (configuration.groupping.location) {
            return (
                <VariableSizeGrid
                    ref={tableLocationRef}
                    onScroll={onScroll}
                    className="GridMatrix"
                    columnCount={locationArray.length} /** Units (not locations!!) */
                    columnWidth={calculateFixedLocationHeader}
                    height={gridHeight}
                    rowCount={treeActivitiesVisualization ? gridRows.length : grouppedRows.filter(ac => !ac.hide).length} /** Activities */
                    rowHeight={(index) => activitiesColHeight}
                    width={gridWidth} >
                    {treeActivitiesVisualization
                        ? GridCellLocationTree(gridRows, gridCols, selectedActivities, selectedUnits, locationArray, configuration, statusColors, calculateExpected, ganttAPI)
                        : GridCellLocationGroupped(grouppedRows.filter(ac => !ac.hide), gridCols, selectedActivities, selectedUnits, locationArray, configuration, statusColors, calculateExpected, ganttAPI)
                    }
                </VariableSizeGrid>
            )
        }
        if (configuration.groupping.structure) {
            const colWidth = calculateFixedStructureHeader()
            return (
                <Grid
                    ref={tableStructureRef}
                    onScroll={onScroll}
                    className="GridMatrix"
                    columnCount={structuresArray.length} /** Units (not locations!!) */
                    columnWidth={colWidth}
                    height={gridHeight}
                    rowCount={treeActivitiesVisualization ? gridRows.length : grouppedRows.filter(ac => !ac.hide).length} /** Activities */
                    rowHeight={activitiesColHeight}
                    width={gridWidth} >
                    {treeActivitiesVisualization
                        ? GridCellStructureTree(gridRows, gridCols, selectedActivities, selectedUnits, structuresArray, configuration, statusColors, calculateExpected, ganttAPI)
                        : GridCellStructureGroupped(grouppedRows.filter(ac => !ac.hide), gridCols, selectedActivities, selectedUnits, structuresArray, locationArray, configuration, statusColors, calculateExpected, ganttAPI)
                    }
                </Grid>
            )
        }
    }

    const renderContent = () => {
        if (loading) {
            return <Spin className="loader-spinner-lookahead-table" indicator={antIcon} />
        } else {
            return (
                <Row style={{ overflow: 'hidden' }}>
                    <Col>
                        {/** Title and buttons header row */}
                        {renderHeader()}
                        {/** Fixed headers, col and grid row */}
                        <Row style={{ padding: 17 }} >
                            <div ref={headerCol} style={{ flexDirection: 'column' }}>
                                {/** Fixed structure row  */}
                                {
                                    configuration.groupping.structure ? (
                                        <Row style={{ marginLeft: fixedColWidth }}>
                                            <VariableSizeGrid
                                                ref={staticStructureHeader}
                                                style={{ overflowX: 'hidden', overflowY: 'hidden' }}
                                                className="GridStaticHeaderLocations structure"
                                                columnCount={structuresArray.length}
                                                columnWidth={calculateFixedStructureHeader}
                                                height={gridHeaderHeight}
                                                rowCount={1}
                                                rowHeight={(index) => gridHeaderHeight}
                                                width={gridWidth} >
                                                {HeaderStructureCol(structuresArray)}
                                            </VariableSizeGrid>
                                        </Row>
                                    ) : null
                                }

                                {/** Fixed location row  */}
                                {
                                    configuration.groupping.location ? (
                                        <Row style={{ marginLeft: fixedColWidth }}>
                                            <VariableSizeGrid
                                                ref={staticLocationHeader}
                                                style={{ overflowX: 'hidden', overflowY: 'hidden' }}
                                                className="GridStaticHeaderLocations"
                                                columnCount={locationArray.length}
                                                columnWidth={calculateFixedLocationHeader}
                                                height={gridHeaderHeight}
                                                rowCount={1}
                                                rowHeight={(index) => gridHeaderHeight}
                                                width={gridWidth} >
                                                {HeaderLocationCol(locationArray)}
                                            </VariableSizeGrid>
                                        </Row>
                                    ) : null
                                }

                                {/** Fixed units row */}
                                {
                                    configuration.groupping.unit ? (

                                        <Row style={{ marginLeft: fixedColWidth }}>
                                            <Grid
                                                ref={staticHeader}
                                                style={{ overflowX: 'hidden', overflowY: 'hidden' }}
                                                className="GridStaticHeaderMatrix"
                                                columnCount={gridCols.length}
                                                columnWidth={
                                                    (gridColWidth / gridCols.length) > 100 ? gridColWidth / gridCols.length : 100
                                                }
                                                height={gridHeaderHeight}
                                                rowCount={1}
                                                rowHeight={gridHeaderHeight}
                                                width={gridWidth} >
                                                {HeaderUnitCol(gridCols, (gridColWidth / gridCols.length) > 100 ? gridColWidth / gridCols.length : 100, selectedActivities, selectedUnits, locationArray)}
                                            </Grid>
                                        </Row>
                                    ) : null
                                }
                                <Row style={{ display: 'inline-flex' }}>
                                    {/** Fixed Col */}
                                    <Grid
                                        ref={staticCol}
                                        style={{ overflowX: 'hidden', overflowY: 'hidden' }}
                                        className="GridStaticColMatrix"
                                        columnCount={1}
                                        columnWidth={fixedColWidth}
                                        height={gridHeight}
                                        rowCount={treeActivitiesVisualization ? gridRows.length : grouppedRows.filter(ac => !ac.hide).length}
                                        rowHeight={activitiesColHeight}
                                        width={fixedColWidth} >
                                        {
                                            treeActivitiesVisualization
                                                ? FixedColActivitiesRowTree(gridRows, fixedColWidth, selectedActivities, selectedUnits)
                                                : FixedColActivitiesRowGroupped(grouppedRows.filter(ac => !ac.hide), fixedColWidth, selectedActivities, selectedUnits)
                                        }
                                    </Grid>
                                    {/** Table indeed */}
                                    {
                                        renderTableVirtualized()
                                    }
                                </Row>
                            </div>
                        </Row>
                    </Col>
                    {/* Modal for cofiguration */}
                    { renderModalConfiguration()}
                </Row>
            )
        }
    }

    return renderContent()
}
