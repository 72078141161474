export const colors = [
  '#39C6D9',
  '#EF934E',
  '#4E8D00',
  '#78909C',
  '#EF5350',
  '#5C6BC0',
  '#BA68C8',
  '#DE4411',
  '#BF9931',
  '#8D6E63',
  '#42A5F5',
  '#FF9800',
  '#81C784',
  '#E57373',
  '#AB47BC',
  '#CBB02E',
  '#AB5412',
  '#AEB287',
  '#3DCADE',
  '#7EE200',
  '#FF86C4',
  '#1E64AC',
  '#DFDA09',
  '#1BB358',
  '#59DEF4',
  '#FF9100',
  '#8956D8',
  '#39C6D9',
  '#EF934E',
  '#4E8D00',
  '#78909C',
  '#EF5350',
  '#5C6BC0',
  '#BA68C8',
  '#DE4411',
  '#BF9931',
  '#8D6E63',
  '#42A5F5',
  '#FF9800',
  '#81C784',
  '#E57373',
  '#AB47BC',
  '#CBB02E',
  '#AB5412',
  '#AEB287',
  '#3DCADE',
  '#7EE200',
  '#FF86C4',
  '#1E64AC',
  '#DFDA09',
  '#1BB358',
  '#59DEF4',
  '#FF9100',
  '#8956D8'
];
