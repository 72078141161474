import { debounce } from 'lodash';
import * as Sentry from '@sentry/react';
const requestRender = debounce(() => {
  const gantt = window.to_use_react_gantt || window.ganttVisualization;
  if (!gantt) return;
  try {
    gantt.render();
  } catch (error) {
    console.error(error);
    Sentry.captureException('error on requestRender');
  }
}, 500);

export { requestRender };
