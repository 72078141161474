/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class TreePaneViewService {
  constructor() {
    this.http = createHttpClient();
  }

  async create(setting) {
    const res = await this.http.post('treePaneView', setting).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(settingId, setting) {
    const res = await this.http
      .put('treePaneView/' + settingId, setting)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const treePaneService = new TreePaneViewService();
