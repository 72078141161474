import React from 'react';
import { findDeepGetTask, isGrouped } from '../../GanttVisualization.helper';
import { findDeepGetActivityOfTask } from '../../../../views/lookahead/planification/index.helper';
import { hasConstraintHtml } from '../../../../utils/lookahead-common';

export const TestIds = {
  RENDER_ROADBLOCKS_CONTAINER: 'RENDER_ROADBLOCKS_CONTAINER'
};

const TestWrapped = ({ children }) => (
  <span data-testid={TestIds.RENDER_ROADBLOCKS_CONTAINER}>{children}</span>
);

const RenderRoadblocks = ({
  task = {},
  activities = [],
  gantt = {},
  onlyRead = true
}) => {
  const SHOW_INDICATOR_CONSTRAINT = true;
  const taskId = task?.id ? task.id : 0;
  const columnName = 'ROADBLOCKS';
  const dataTestId =
    'data-testid="' +
    TestIds.RENDER_ROADBLOCKS_CONTAINER +
    '_' +
    taskId +
    '_' +
    columnName +
    '"';
  if (!activities || !task)
    return (
      <TestWrapped>
        <div></div>
      </TestWrapped>
    );
  const isOnlyReadElement = onlyRead || task.readonly;
  /** Check if data is defined. Activity don't have this data */
  const item = task;
  /** get reference task (taskFromLookahead) from activities */
  let taskFromLookahead = null;
  if (item?.isTask) {
    /** get reference task (taskFromLookahead) from activities */
    taskFromLookahead = findDeepGetTask(activities, 'id', task.id);
  }

  let activityReference = null;
  const findAct = findDeepGetActivityOfTask(window.activities, 'id', task?.id);
  if (findAct) activityReference = findAct;

  /** disabled value for parent tasks, get parent task  */
  let parentTask = null;
  if (taskFromLookahead) {
    if (taskFromLookahead.parent_id) {
      parentTask = findDeepGetTask(
        activities,
        'id',
        taskFromLookahead.parent_id
      );
    }
  }

  if (!gantt) return `<div ${dataTestId}><div></div></div>`;

  const renderRoadblock = (constraint = null) => {
    return `<div ${dataTestId}>
                <span class="task-actions"
                    style="display: inline;visibility: visible">
                    ${isGrouped() ? null : ` <span>${constraint} </span>`}
                </span>
            </div>`;
  };

  const renderActivityTask = () => {
    const hasConstraintJsx = hasConstraintHtml(
      item,
      () => window.ganttVisualization.addConstraint(item?.id),
      true,
      gantt.t,
      SHOW_INDICATOR_CONSTRAINT,
      item?.id
    );

    const html_ponderator_constraint = `<span class="roadblock-span">
                ${item.constraints?.length ? hasConstraintJsx : '-'}
            </span>`;

    const taskName = renderRoadblock(html_ponderator_constraint);
    return `<div ${dataTestId}>
                <div>
                    <span style="position: relative;width: 100%;text-align: center;display: block">
                        ${taskName}
                    </span>
                </div>
            </div>`;
  };

  const RenderRoadblocksColumn = () => {
    let isOdd = false;
    if (gantt.oddColsConfig && task.type === 'activitytask') {
      isOdd = gantt.oddColsConfig.name;
    }
    if (!item?.type) return `<div ${dataTestId}><div></div></div>`;
    return `<div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="cursor: ${onlyRead ? 'not-allowed' : 'pointer'}; overflow: visible">
                ${item.type === 'activitytask' ? renderActivityTask() : ''}
            </div>`;
  };
  return RenderRoadblocksColumn();
};

export default RenderRoadblocks;
