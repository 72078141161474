import React, { useState, useRef, useEffect } from 'react';
import Range from 'react-range-progress';
import { taskService } from '../../services/task.service';
import { hexToRgb } from '../../utils';
import { InputNumber } from 'antd';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import './index.css';

const ProgressBarCard = (props) => {
  const {
    t,
    updateTask = () => {},
    fillcolor,
    task,
    updateRender,
    column,
    calculateProgress,
    calculatePonderators,
    parent,
    activity,
    disable,
    updateAsyncTask,
    updateAsyncActivity,
    projectState,
    progress,
    setProgress,
    type,
    blocked = false,
    updateRenderView,
    updateLogs
  } = props;
  // const [progress, setProgress] = useState({ value: task[column.name] })
  const [isEditingInput, setIsEditingInput] = useState(false);
  const wrapperRef = useRef(null);

  const handleEsc = (e) => {
    if (e.keyCode === 27) {
      setIsEditingInput(false);
    }
  };

  useOutsideAlerter(wrapperRef, () => saveProgress());

  const saveProgress = () => {
    if (!disable) {
      task[column.name] = progress.value;
      if (progress.value == 0) {
        if (calculatePonderators) {
          calculatePonderators(
            parent || activity,
            activity,
            updateAsyncTask,
            projectState
          );
        }
      }

      if (updateAsyncTask) {
        updateAsyncTask(task);
      } else {
        taskService.update(task);
      }
      calculateProgress(
        task,
        parent,
        activity,
        updateAsyncTask,
        updateAsyncActivity
      );
      updateRender();
      updateTask(task);
      setIsEditingInput(false);
      updateRenderView();
      updateLogs();
    }
  };

  useEffect(() => {
    const focusedItem = column.name + task.id;
    const domElement = document.getElementById(focusedItem);
    if (domElement) {
      domElement.focus();
    }
  }, [isEditingInput]);

  return progress !== undefined ? (
    <div className="range-fixed-card card-progress">
      <div onMouseUp={() => saveProgress()}>
        {type === 'bar' ? (
          <Range
            readOnly={disable}
            hideThumb={disable}
            value={progress.value}
            fillColor={
              fillcolor
                ? hexToRgb(fillcolor)
                : {
                    r: 92,
                    g: 196,
                    b: 26,
                    a: 1
                  }
            }
            trackColor={{
              r: 245,
              g: 245,
              b: 245,
              a: 1
            }}
            height={8}
            width={'auto'}
            onChange={(value) => {
              setProgress({ value });
            }}
          />
        ) : null}
      </div>
      {type === 'input' ? (
        <div style={{ display: 'inline-block', margin: '0 10px' }}>
          {t('card.progreso_real')}:&nbsp;
          {isEditingInput ? (
            <span ref={wrapperRef}>
              <InputNumber
                onKeyDown={handleEsc}
                onPressEnter={saveProgress}
                className="custom-input-number-card ant-input-number-focused progress-card-input"
                min={0}
                max={100}
                name={column.name + task.id}
                id={column.name + task.id}
                precision={Number.isInteger(task[column.name]) ? 0 : 2}
                step={0.1}
                defaultValue={task[column.name]}
                onChange={(e) => {
                  let valuePrg;
                  if (e >= 0 && e <= 100) {
                    valuePrg = e;
                    setProgress({
                      value: valuePrg
                    });
                  } else if (e > 100) {
                    valuePrg = 100;
                    setProgress({
                      value: valuePrg
                    });
                  } else if (e < 0) {
                    valuePrg = 0;
                    setProgress({
                      value: valuePrg
                    });
                  }
                  if (e === '') {
                    valuePrg = 0;
                    setProgress({
                      value: valuePrg
                    });
                  }
                }}
              />
            </span>
          ) : (
            <span
              onClick={blocked ? null : () => setIsEditingInput(true)}
              // onClick={() => setIsEditingInput(true)}
              style={{ cursor: 'pointer' }}>
              {t('lang') === 'en'
                ? progress.value.toFixed(2) + '%'
                : progress.value.toFixed(2).replace('.', ',') + '%'}
            </span>
          )}
        </div>
      ) : null}
    </div>
  ) : null;
};

export default ProgressBarCard;
