import React from 'react';
import { Spin, Layout, Menu, Tooltip } from 'antd';

export default function Report() {
  return (
    <Layout>
      <div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>div sdhfkdshjfhksdfkjdlsfhds sjfdsijfisijfois
      </div>
    </Layout>
  );
}
