import { createSlice } from '@reduxjs/toolkit';

export const hoveringStackSliceKey = 'hoveringStack';

const initialState = {
  drawer: {}
};

const { actions, reducer } = createSlice({
  name: hoveringStackSliceKey,
  initialState,
  reducers: {
    openDrawer(state, { payload }) {
      state.drawer = {
        component: payload?.component,
        params: payload?.params,
        origin: payload?.origin,
        status: 'OPEN'
      };
    },
    requestCloseDrawer(state) {
      state.drawer.status = 'REQUESTED_CLOSING';
    },
    cancelCloseDrawer(state) {
      state.drawer.status = 'OPEN';
    },
    closeDrawer(state) {
      state.drawer = {};
    },
    updateDrawer(state, { payload }) {
      state.drawer = {
        component: state.drawer.component,
        params: {
          ...payload?.params,
          dataToLoad: state.drawer.params.dataToLoad
        },
        origin: state.drawer.origin,
        status: 'OPEN'
      };
    }
  }
});

export function getDrawerSelector(state) {
  return state[hoveringStackSliceKey].drawer;
}

export const hoveringStackReducer = reducer;
export const {
  openDrawer,
  requestCloseDrawer,
  cancelCloseDrawer,
  closeDrawer,
  updateDrawer
} = actions;
