import {
  calculatePonderators,
  formatMoney,
  getEndDateByGantt,
  getRecursiveFromParentTask
} from '../../../../utils/lookahead-common';
import { findDeepGetActivityOfTask } from '../../../../views/lookahead/planification/index.helper';
import {
  findDeepGetTask,
  getRecursiveDurationForParent,
  isGrouped,
  updateAsyncTaskGanttV2,
  updateParentDuration,
  updateTaskMassive
} from '../../GanttVisualization.helper';
import { ganttAPI } from '../../../../utils/customGanttPlugin';

export const testIds = {
  RENDER_DURATION_CONTAINER: 'RENDER_DURATION_CONTAINER'
};

const testWrapped = (children = '') => {
  return `<span data-testid=${testIds.RENDER_DURATION_CONTAINER}>${children}</span>`;
};
const RenderDuration = ({
  task = {},
  column = {},
  activities = [],
  gantt = {},
  t = (text) => 'fake translation',
  projectState = {}
}) => {
  const navigatorLang = navigator.language || navigator.userLanguage;
  if (task.is_milestone) return testWrapped();
  /** No Render if parent is Abstract */
  if (isGrouped()) {
    return testWrapped();
  }

  /** Check if data is defined. Activity don't have this data */
  if (!task.isTask) {
    return testWrapped(`<div>
            <span class="vertical-center-dates">
                <span class="column-disabled">${
                  t('lang') === 'en'
                    ? formatMoney(
                        gantt?.formatter
                          ?.format(task[column.name])
                          ?.split('day')[0],
                        '',
                        Number.isInteger(
                          parseFloat(
                            gantt?.formatter?.format(task[column.name])
                          )
                        )
                          ? 0
                          : 2,
                        '.',
                        ','
                      )
                    : formatMoney(
                        gantt?.formatter
                          ?.format(task[column.name])
                          ?.split('day')[0],
                        '',
                        Number.isInteger(
                          parseFloat(
                            gantt?.formatter?.format(task[column.name])
                          )
                        )
                          ? 0
                          : 2,
                        ',',
                        '.'
                      )
                }
                </span>
            </span>
        </div>`);
  }

  /** get activity reference */
  const findAct = findDeepGetActivityOfTask(window.activities, 'id', task.id);
  let activityReference;
  if (findAct) activityReference = findAct;
  if (!activityReference) return testWrapped();

  /** get reference task (taskFromLookahead) from activities */
  const taskFromLookahead = findDeepGetTask(window.activities, 'id', task.id);
  if (!taskFromLookahead) return testWrapped();

  // let valueData = taskFromLookahead[column.name] || 0
  let valueData = taskFromLookahead[column?.name]
    ? parseFloat(taskFromLookahead[column?.name]).toLocaleString('en-US')
    : 0;

  if (valueData !== 0 && !navigatorLang.includes('en')) {
    const numbers = valueData.split('.');
    if (numbers.length > 1)
      valueData = numbers[0].replaceAll(',', '.') + ',' + numbers[1];
    else valueData = numbers[0].replaceAll(',', '.');
  }

  /** disabled value for parent tasks, get parent task  */
  let parentTask = null;
  let disabled = true;
  if (taskFromLookahead) {
    disabled = taskFromLookahead.children.length > 0;
    if (taskFromLookahead.parent_id) {
      const doesExistAtReferenceParent = findDeepGetTask(
        activities,
        'id',
        taskFromLookahead.parent_id
      );
      parentTask = doesExistAtReferenceParent || null;
    }
  }

  let isOdd = false;
  if (gantt?.oddColsConfig && task.type === 'activitytask') {
    isOdd = gantt.oddColsConfig[column.name];
  }
  const child = ` <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
        ${
          disabled
            ? taskFromLookahead
              ? `<span class="vertical-center 11">
                   ${
                     t('lang') === 'en'
                       ? taskFromLookahead[column.name].toFixed(
                           Number.isInteger(
                             parseFloat(taskFromLookahead[column.name])
                           )
                             ? 0
                             : 2
                         )
                       : taskFromLookahead[column.name]
                           .toFixed(
                             Number.isInteger(
                               parseFloat(taskFromLookahead[column.name])
                             )
                               ? 0
                               : 2
                           )
                           .replace('.', ',')
                   }
                  </span>`
              : ''
            : `<span class="vertical-center cost-fix-gantt 22" style="padding-left: 19;">
              ${valueData}
          </span>`
        }
        </div>`;
  return testWrapped(child);
};

export default RenderDuration;
