import { useEffect, useState } from 'react';
import { filterByName } from '../../../utils/projectUtils';
import { trackFilterEvent } from '../utils';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

const defaultSchedules = {
  total: 0,
  filtered: 0,
  projects: []
};

const baseErrorKey = 'scheduleofschedule.errors.load_projects';
const filterErrorKey = 'scheduleofschedule.errors.filter';

const setGanttBarBorderColor = (projects) => {
  const color = projects.find(({ color }) => color)?.color;

  if (!color) {
    return;
  }

  document.documentElement.style.setProperty(
    '--schedule-of-schedules-bar-border-color',
    color
  );
};

export const useLoadProjectsWithFilters = ({
  projectService,
  tracking,
  showNotification,
  filterText,
  filterCompany,
  processedProjects
}) => {
  const { t } = useTranslation();
  const [schedules, setSchedules] = useState(defaultSchedules);
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.loader.show();
    projectService
      .getScheduleOfScheduleProjects(filterCompany)
      .then(({ data }) => {
        setSchedules(data);
        setFilteredSchedules(data.projects.filter(filterByName(filterText)));
        trackFilterEvent(tracking, filterCompany, data.filtered);
        setGanttBarBorderColor(data.projects);
      })
      .catch((error) => {
        Sentry.captureException(error);
        const message = filterCompany ? t(filterErrorKey) : t(baseErrorKey);
        showNotification({ message, type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
        window.loader.hide();
      });
  }, [filterCompany, processedProjects]);

  return { schedules, filteredSchedules, setFilteredSchedules, isLoading };
};
