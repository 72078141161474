import { updateSector } from '../views/project/project.helper';

/**
 * This function get the current sector from the localstorage
 * @returns {object} the sector object getted from the localstorage
 */
export const getSectorFromLocalStorage = () => {
  const currentSectorJSON = sessionStorage.getItem('currentSector');
  if (!currentSectorJSON) return;
  return JSON.parse(currentSectorJSON);
};

/**
 * This function takes a sector updated object and put data into db and localstorage
 * @param {*} sector Sector to be updated
 * @returns {boolean}
 */
export const updateSectorInfo = (sector) => {
  if (!sector) return;
  sessionStorage.setItem('currentSector', JSON.stringify(sector));
  updateSector(sector);
};

/**
 * This function set a flag into the current sector object and save local storage and database value
 * @param {*} flag Key to add in the sector object
 * @param {*} value Value which is going to have this key
 * @returns {boolean}
 */
export const activeSectorFlag = (flag, value) => {
  const sector = getSectorFromLocalStorage();
  if (!sector) return;
  sector[flag] = value;
  updateSectorInfo(sector);
};
