/** React components  */
import React, { useState, useEffect, Fragment } from 'react';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/**  import library for animations effects */
import { Animated } from 'react-animated-css';

/** import elements from library Antd Framework */
import { Icon, Input, Row, Col, Button, Avatar } from 'antd';

/** import hook for handle params */
import { useParams } from 'react-router-dom';

/** import services */
import { sectorService, projectService } from '../../services';

/** import common functions from utils */
import {
  openNotification,
  ListElement,
  ObjectLogoSvg,
  setTitleWide
} from '../../utils';

/** import icons from antd framework */
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

/** Drag and Drop */
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

export default function StageView(props) {
  // hooks
  const { token } = useParams(); // handle params

  /** State */
  const [state, setState] = useState({
    isLoading: false /** handle loading */,
    currentProject: null /** handle project Created in Project view */,
    formName: null /** handle name of schedule (form) */,
    stages: [] /** Handle schedules */
  }); // handle state

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);

    setTitleWide('stage');
    getProject();

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  const getProject = async () => {
    const projectCreated = JSON.parse(localStorage.getItem('projectCreated'));
    // fetch project
    const project = await fetchProject(projectCreated.id);

    setState({
      ...state,
      currentProject: projectCreated,
      stages: project.project.sectors.filter(
        (e) => e.status !== false
      ) /** init schedules */
    });

    /** reset schedules */
    project.project.sectors &&
      project.project.sectors.map(async (sector) => {
        await updateSector({
          id: sector.id,
          name: sector.name,
          projectId: sector.projectId,
          status: false
        });
      });
  };

  /** Constant for notifications */
  const alertSectorAddSuccess = {
    title: 'Schedule',
    description: 'Schedule Added',
    type: 'success'
  };

  /** Functions for Drag and drop  */
  /**
   *  This function create a Element of a List Sortable
   * * @param {*} props props params
   */
  const SortableItem = SortableElement((props) => {
    const value = props.value;
    const index = props.i;
    return (
      <Fragment>
        <ListElement>
          <Row className="listElement" key={value.id}>
            <Col span={24}>
              <div className="element">{value.name}</div>
            </Col>
          </Row>
        </ListElement>
        <DeleteOutlined
          className="deleteElement"
          onClick={(e) => deleteSector(value, index)}
        />
      </Fragment>
    );
  });

  /**
   *  This function create a List Sortable
   * * @param {*} items list of items to render
   */
  const SortableList = SortableContainer(({ items }) => (
    <div>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          i={index}
          value={value}
        />
      ))}
    </div>
  ));

  /**
   *  This function handle Sort End Event
   * * @param {*} oldIndex prev index
   * * @param {*} newIndex move to new index
   */
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setState({
      ...state,
      stages: arrayMove(state.stages, oldIndex, newIndex)
    });
  };

  /** logic of component */
  /** this function submit form schedules  */
  const onSubmit = async (e) => {
    e.preventDefault();
    if (state.stages.length) {
      const projectCreated = JSON.parse(localStorage.getItem('projectCreated'));
      setState({
        ...state,
        isLoading: true
      });
      /** create sectors */
      state.stages.map(async (e) => {
        const data = {
          name: e.name,
          projectId: projectCreated.id
        };
        await createSector(data);
        openNotification(alertSectorAddSuccess);
      });
      setState({
        ...state,
        stages: [],
        isLoading: false
      });
      // redirect invite
      props.history.push(`/invite/${token}`);
    } else {
      /** validate */
      setState({
        ...state,
        stages: [],
        formName: ''
      });
    }
  };

  /** this function add schedule to list  */
  const addSector = () => {
    setState({
      ...state,
      isLoading: true
    });
    if (state.formName) {
      const tmp = state.stages;
      tmp.push({ name: state.formName });
      /** reset form */
      setState({
        ...state,
        formName: null,
        stages: tmp
      });
    } else {
      /** validate */
      setState({
        ...state,
        formName: ''
      });
    }
  };

  /** this function delete a sector of list */
  const deleteSector = (e, i) => {
    const filteredItems =
      e.id !== undefined
        ? state.stages.filter((el) => el.id !== e.id)
        : state.stages.filter((e, index) => index !== i);
    setState({
      ...state,
      stages: filteredItems
    });
  };

  // services
  async function createSector(data) {
    const resp = await sectorService.create(data);
    return resp;
  }

  async function updateSector(data) {
    const resp = await sectorService.update(data);
    return resp;
  }

  async function fetchProject(idProject) {
    const resp = await projectService.show(idProject);
    return resp;
  }

  /** Avatar  */
  const ProjectIcon = (props) => <Icon component={ObjectLogoSvg} {...props} />;

  // render
  return (
    <Animated
      className="animatedWide"
      animationIn="fadeIn"
      animationInDuration={750}
      isVisible={true}>
      <div className="divWide">
        <div className="divSection">
          <Fragment>
            <form
              className="frmWide mt-10"
              onSubmit={(e) => onSubmit(e)}
              noValidate
              id="frmStages">
              <Row>
                <Col span={4}>
                  {state.currentProject && state.currentProject.image ? (
                    <Avatar
                      className="FrmLogoStages"
                      size={140}
                      src={`${state.currentProject.image}`}
                    />
                  ) : (
                    <ProjectIcon className="FrmLogoStages" />
                  )}
                </Col>
                <Col span={18}>
                  <span className="titleFrmElements">
                    {state.currentProject ? state.currentProject.name : ''}
                  </span>
                </Col>
                <Col span={2}></Col>
              </Row>

              <Row>
                <Col span={24}>
                  <div>
                    <Input
                      className="w50 inline"
                      type="text"
                      name="sname"
                      autoComplete="off"
                      placeholder="Schedule Name"
                      value={state.formName}
                      onChange={(e) =>
                        setState({ ...state, formName: e.target.value })
                      }
                      required
                    />
                    <PlusOutlined onClick={addSector} className="addElement" />
                    {state.formName !== null && state.formName === '' ? (
                      <label className="lblErrorElement">
                        Schedule Name is required
                      </label>
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
              </Row>

              <SortableList
                items={state.stages}
                helperClass="dragging-helper-class"
                onSortEnd={onSortEnd}
              />

              <Button htmlType="submit" loading={state.isLoading}>
                Continue
              </Button>
            </form>
          </Fragment>
        </div>
      </div>
    </Animated>
  );
}
