import React, { Fragment } from 'react';

function SettingsCompany(props) {
  const { setRouter, Router, t } = props;
  return (
    <Fragment>
      <button
        className={
          'button-nav-settings ' + (Router === 1 && 'border-nav-settings')
        }
        onClick={() => setRouter(1)}>
        {t('settings.general')}
      </button>
      <button
        className={
          'button-nav-settings ' + (Router === 2 && 'border-nav-settings')
        }
        onClick={() => setRouter(2)}>
        {t('settings.users')}
      </button>
      <button
        className={
          'button-nav-settings ' + (Router === 3 && 'border-nav-settings')
        }
        onClick={() => setRouter(3)}>
        {t('settings.subcontracts')}
      </button>
      {/* <button className={'button-nav-settings ' + (Router === 4 && 'border-nav-settings')} onClick={() => setRouter(4)}>Templates</button>
            <button className="button-nav-settings">Integraciones</button>
            <button className="button-nav-settings">Facturación</button> */}
    </Fragment>
  );
}

export default SettingsCompany;
