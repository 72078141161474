/* eslint-disable no-case-declarations */
import {
  SET_FORCE_LOADING_SECTOR,
  SET_LOOKAHEAD_ACTIVATED_COLS,
  SET_LOOKAHEAD_RANGE_FILTERED,
  SET_LOOKAHEAD_VIEWS,
  NOTIFY_LOOKAHEAD_UPDATE,
  NOTIFY_LOOKAHEAD_UPDATE_FILTER,
  NOTIFY_LOOKAHEAD_UPDATE_GROUP,
  NOTIFY_LOOKAHEAD_UPDATE_ORDER
} from '../actions/lookaheadActions';

/**
 * Reducer function means a switch case to filter action data on the platform for general state
 * @param {*} state Real time updated through all components state (general state flux)
 * @param {*} action Action is an object with structyure { type: STRING, payload: FUNCTION }
 */
function lookaheadReducer(
  state = {
    forceLoadingSector: false,
    activatedColumns: [],
    configViews: [],
    dateRangeToFilter: [],
    isRangeDateFiltered: false,
    notifyChange: false,
    notifyChangeGroup: false,
    notifyChangeFilter: false,
    notifyChangeOrder: false,
    userPreferenceTable: []
  },
  action
) {
  switch (action.type) {
    case SET_LOOKAHEAD_ACTIVATED_COLS:
      return {
        ...state,
        activatedColumns: action.payload
      };
    case SET_LOOKAHEAD_RANGE_FILTERED:
      return {
        ...state,
        isRangeDateFiltered: action.payload.boolean,
        dateRangeToFilter: action.payload.dateRange
      };
    case SET_LOOKAHEAD_VIEWS:
      return {
        ...state,
        configViews: action.payload
      };
    case NOTIFY_LOOKAHEAD_UPDATE:
      return {
        ...state,
        notifyChange: !state.notifyChange
      };
    case NOTIFY_LOOKAHEAD_UPDATE_GROUP:
      return {
        ...state,
        notifyChangeGroup: !state.notifyChangeGroup
      };
    case NOTIFY_LOOKAHEAD_UPDATE_FILTER:
      return {
        ...state,
        notifyChangeFilter: !state.notifyChangeFilter
      };
    case NOTIFY_LOOKAHEAD_UPDATE_ORDER:
      return {
        ...state,
        notifyChangeOrder: !state.notifyChangeOrder
      };
    case SET_FORCE_LOADING_SECTOR:
      return {
        ...state,
        forceLoadingSector: action.payload
      };
    default:
      return state;
  }
}

export default lookaheadReducer;
