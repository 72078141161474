/** React components  */
import React from 'react';
import { WarningIcon } from '../../../../../../icons';

export default function ConstraintTypesEmpty(props) {
  const { t } = props;

  return (
    <div className="constraint-empty__container">
      <WarningIcon color="#C4C4C4" />
      <h5>{t('constraint_list_empty')}</h5>
    </div>
  );
}
