import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import GridLayout, { Responsive, WidthProvider } from 'react-grid-layout';
import { Spin, Icon } from 'antd';

/** Components */
import ActivitiesRythmTable from '../../../../components/ActivitiesRythmTable';
import ProgressMatrixTakt from '../../../../components/ProgressMatrixTakt';
import SpeedCurveTakt from '../../../../components/SpeedCurveTakt';
import BalanceCurveTakt from '../../../../components/BalanceCurveTakt';
import RythmMatrixTakt from '../../../../components/RythmMatrixTakt';
import { calendarService, sectorService } from '../../../../services';
import { structureService } from '../../../../services/structure.service';
import { ganttAPI } from '../../../../utils/customGanttPlugin';
import { calculateExpected } from '../../../../utils/lookahead-common';
import VelocityTasksCurve from '../../../../components/VelocityCurveTakt';

const ResponsiveGridLayout = WidthProvider(Responsive);
const layout = [
  { i: 'headerTitle', x: 0, y: 0, w: 12, h: 3, static: true },
  /* { i: 'activitiesRythm', x: 0, y: 3, w: 3, h: 10 }, */
  { i: 'progressMatrix', x: 0, y: 3, w: 12, h: 17, isResizable: false },
  { i: 'velocityTakt', x: 0, y: 16, w: 12, h: 10, isResizable: false }
  /* { i: 'balanceCurve', x: 0, y: 8, w: 12, h: 5 },
    { i: 'rythmMatrix', x: 0, y: 9, w: 10, h: 5 } */
];
const layouts = { lg: layout };

const TaktAnalyticsView = (props) => {
  const { header, t } = props;
  const [loaded, setLoaded] = useState(true);
  const [activities, setActivities] = useState([]);
  const [units, setUnits] = useState([]);
  const [relations, setRelations] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const projectState = useSelector((state) => state.projectState);
  const [calendars, setCalendars] = useState([]);

  const loadCalendars = (calendars) => {
    /** This method load calendars to Gantt API and also creates a custom version to use custom calculate duration */
    ganttAPI.loadCalendars(calendars);
  };

  useEffect(() => {
    if (calendars) {
      if (calendars.length) {
        loadCalendars(calendars);
      }
    }
  }, [calendars]);

  const getCalendars = async () => {
    const calendars = await calendarService.showBySector(
      projectState.sectorSelected
    );

    return calendars.calendar;
  };

  const getActivities = async () => {
    const sectorResActivity = await sectorService.createActivitiesTree(
      projectState.sectorSelected
    );
    return sectorResActivity.sector;
  };

  const getStructures = async () => {
    const currentSector = projectState.allSectors.find(
      (e) => e.id == projectState.sectorSelected
    );
    const structures = await structureService.showBySector(currentSector.id);
    return structures.structure;
  };

  const getRelations = async (ac, relations) => {
    if (ac.productionUnits.length) {
      ac.productionUnits.map((pu) => {
        const productionunitactivity = pu.productionunitactivity;
        const doesExist = relations.find(
          (rel) => rel.activityId == ac.id && rel.productionunitId == pu.id
        );
        if (!doesExist) {
          relations.push(productionunitactivity);
        }
      });
    }
    if (ac.children.length) {
      ac.children.map((child) => {
        getRelations(child, relations);
      });
    }
  };

  /**
   * This function gets units (all structure with locations) and transforms to only locatiosn with production unit
   * @param {*} units All locations with units array
   */
  const getLocationsWithUnits = (units) => {
    const linealArray = [];
    treeToLinealArray(units, linealArray, 0);
    const locationsWithUnits = linealArray.filter((location) => {
      if (!location.productionUnits) {
        return false;
      } else if (location.productionUnits.length) {
        return true;
      }
    });

    return locationsWithUnits;
    // setLocationArray(locationsWithUnits)
  };

  /**
   * This function goes deeply on a tree data structure and transforms it to an lineal array in the same structure
   * @param {*} tree Array with tree format
   * @param {*} finalJsxArray empty array to push deeply data
   * @param {*} lvl 0 on start to deeply get level for elements
   */
  const treeToLinealArray = (tree, finalJsxArray, lvl) => {
    tree.map((element) => {
      const hasChilds = element.children;
      element.lvl = lvl;
      finalJsxArray.push(element);
      if (hasChilds) {
        if (hasChilds.length) {
          treeToLinealArray(hasChilds, finalJsxArray, lvl + 1);
        }
      }
    });
  };

  const updateStates = async () => {
    const ac = await getActivities();
    const structures = await getStructures();
    const cals = await getCalendars();

    setCalendars(cals);

    const relations = [];
    const alreadySelectedActivities = [];
    if (ac[0]) {
      ac[0].children.map((a) => {
        getRelations(a, relations);
      });

      setRelations(relations);
      const linealActivitiesArray = [];
      treeToLinealArray(ac, linealActivitiesArray, 0);

      relations.map((rel) => {
        const activity = linealActivitiesArray.find(
          (ac) => ac.id == rel.activityId
        );
        const doesAlreadyAdded = alreadySelectedActivities.find(
          (a) => a.id == activity.id
        );
        if (!doesAlreadyAdded) {
          alreadySelectedActivities.push(activity);
        }
      });
    }

    const alreadySelectedUnits = [];
    setSelectedActivities(alreadySelectedActivities);

    if (structures) {
      const structuresFormated = structures.map((structure) => {
        structure.children = JSON.parse(JSON.stringify(structure.locations));
        structure.isStructure = true;
        delete structure.locations;
        return structure;
      });

      if (structuresFormated) {
        relations.map((rel) => {
          const locationArray = getLocationsWithUnits(structuresFormated);
          let unit;
          for (let i = 0; i < locationArray.length; i++) {
            const doesExist = locationArray[i].productionUnits.find(
              (pu) => pu.id == rel.productionunitId
            );
            if (doesExist) {
              unit = doesExist;
              break;
            }
          }

          if (unit) {
            const doesAlreadyAdded = alreadySelectedUnits.find(
              (a) => a.id == unit.id
            );
            if (!doesAlreadyAdded) {
              alreadySelectedUnits.push(unit);
            }
          }
        });
      }
      setSelectedUnits(alreadySelectedUnits);

      /** Hardcoded to first structure JUST TO KEEP DEVELOPInG WITHOUT LOOSING TIME */
      setActivities(ac);
      setUnits(structuresFormated.length ? structuresFormated : []);
    }
    setLoaded(false);
  };

  useEffect(() => {
    updateStates();
  }, [projectState.sectorSelected]);

  const container = useRef(null);

  return (
    <div>
      <ResponsiveGridLayout
        layouts={layouts}
        breakpoints={{ lg: 1920, md: 1250, sm: 1000, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 6, xs: 3, xxs: 3 }}
        rowHeight={37}
        width={1820}
        className="layout">
        {header ? header() : null}
        {/**
                * <div className="car" key="activitiesRythm">
                    <ActivitiesRythmTable />
                </div>
                */}
        <div
          className="car fill-height"
          key="progressMatrix"
          ref={container}
          style={{ minHeight: '700px' }}>
          {!loaded ? (
            <ProgressMatrixTakt
              t={t}
              containerRef={container}
              calculateExpected={calculateExpected}
              ganttAPI={ganttAPI}
              activities={activities}
              units={units}
              selectedActivities={selectedActivities}
              selectedUnits={selectedUnits}
            />
          ) : (
            <LoadingMatrix t={t} />
          )}
        </div>
        <div
          className="car fill-height"
          key="velocityTakt"
          ref={container}
          style={{ minHeight: '700px' }}>
          <VelocityTasksCurve activities={selectedActivities} t={t} />
        </div>
        {/**
                     <div className="car" key="speedCurve">
                    <SpeedCurveTakt />
                </div>
                <div className="car" key="balanceCurve">
                    <BalanceCurveTakt />
                </div>
                <div className="car" key="rythmMatrix">
                    <RythmMatrixTakt />
                </div>
                    */}
      </ResponsiveGridLayout>
      {/**
            <ResponsiveGridLayout className="layout" layouts={layouts}
                breakpoints={{ lg: 1920, md: 1250, sm: 1000, xs: 480, xxs: 0 }}
                cols={{ lg: 12, md: 12, sm: 6, xs: 3, xxs: 3 }}
                rowHeight={37}
                width={1820}
                className="layout">
                <div className="car fill-height" key="velocityTakt" ref={container}>
                    <VelocityTasksCurve
                        activities={selectedActivities}
                        t={t} />
                </div>

                     <div className="car" key="speedCurve">
                    <SpeedCurveTakt />
                </div>
                <div className="car" key="balanceCurve">
                    <BalanceCurveTakt />
                </div>
                <div className="car" key="rythmMatrix">
                    <RythmMatrixTakt />
                </div>

                }

            </ResponsiveGridLayout> */}
    </div>
  );
};

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const LoadingMatrix = ({ t }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      padding: 17,
      paddingBottom: 0,
      position: 'relative'
    }}>
    <span
      style={{
        color: '#121212',
        fontSize: 20,
        position: 'absolute',
        left: 14
      }}>
      {t('takt_units.progress_matrix')}
    </span>
    <Spin className="loader-spinner-lookahead-table" indicator={antIcon} />
  </div>
);

export default TaktAnalyticsView;
