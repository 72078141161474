import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

// ==> Components
import { Col, Row } from 'antd';
import List, { ActivityRow } from './RowActivity';

// ==> Context
import { ProductivityAssignCtx } from '../../Context/Productivty/Assign';

const ProductivityResources = forwardRef((props, ref) => {
  const {
    resources,
    dateInit,
    resouceStyle,
    loadingStyle,
    totalResoucerSelected,
    t
  } = props;

  // => Refs
  const $resourcesContainer = useRef(null);

  const productivityAssign = useContext(ProductivityAssignCtx);

  // => States
  const [hideResourceChilds, setHideResourceChilds] = useState(false);
  const [loadingResource, setLoadingResource] = useState(true);
  const [resourcesRows, setResourcesRows] = useState([]);
  const [widthList, setWidthList] = useState(0);
  const [heightList, setHeightList] = useState(0);

  useImperativeHandle(ref, () =>
    totalResoucerSelected(productivityAssign.resourceSelected.length)
  );

  useEffect(() => {
    loadingResource ?? setLoadingResource(true);

    if (resources) {
      const resourceChekes = resources.map((resource) =>
        buildTreeActivitiesUI(resource)
      );
      setResourcesRows(resourceChekes);
      setLoadingResource(false);
    }
  }, [resources, hideResourceChilds]);

  useEffect(() => {
    setWidthList($resourcesContainer.current.clientWidth);
    setHeightList($resourcesContainer.current.clientHeight);
  }, [$resourcesContainer]);

  // ==> Effects end

  const buildTreeActivitiesUI = (resource, padding = 2) => (
    <ActivityRow
      resource={resource}
      hideResourceChilds={hideResourceChilds}
      padding={padding}
      dateInit={dateInit}
      setHideResourceChilds={setHideResourceChilds}
      t={t}
    />
  );

  return (
    <Row style={{ height: 'inherit' }}>
      <Col style={{ height: 'inherit' }}>
        <div ref={$resourcesContainer} style={{ height: '90%' }}>
          <List
            element={resourcesRows}
            tableWidth={widthList - 20}
            tableHeight={heightList}
            itemSize={30}
            className={`${resouceStyle} ${loadingResource && loadingStyle}`}
            hideResourceChilds={hideResourceChilds}
          />
        </div>
      </Col>
    </Row>
  );
});

ProductivityResources.displayName = 'ProductivityResources';

export default ProductivityResources;
