import * as icons from '../../icons';
import colors from '../../stylesheets/variables.scss';

const DATE_FORMATS = [
  'DD/MM/YY',
  'MM/DD/YY',
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'DD/MM/YY hh:mm',
  'MM/DD/YY hh:mm'
];

export const TIMELINE_CONFIG = [
  {
    i18nKey: 'responsable',
    command: 'SHOW_RESPONSABLE',
    swichable: true
  },
  {
    i18nKey: 'task_name',
    command: 'SHOW_TASK',
    swichable: true
  },
  {
    i18nKey: 'material',
    command: 'SHOW_MATERIAL',
    swichable: true
  },
  {
    i18nKey: 'work',
    command: 'SHOW_WORK',
    swichable: true,
    line: true
  }
];

export const MORE_INFO_CONFIG = {
  icon: icons.InfoIcon,
  iconColor: colors.brandBlue40,
  i18nKey: 'more_info',
  command: 'MORE_INFO'
};

export const DATE_FORMAT_CONFIG = {
  icon: icons.DateFormatIcon,
  iconColor: colors.brandBlue40,
  i18nKey: 'date_format',
  line: true,
  subitems: DATE_FORMATS.map((format) => ({ text: format, checkable: true }))
};

export const TABLE_SETTINGS_TITLE = {
  title: true,
  i18nKey: 'table_settings',
  line: false
};

export const TIMELINE_SETTINGS_TITLE = {
  title: true,
  i18nKey: 'timeline_settings',
  line: false
};

export const GANTT_SETTINGS_ITEMS = [
  {
    icon: icons.CogsIcon,
    iconColor: colors.gray80,
    direction: 'LEFT',
    tooltipI18nKey: 'settings.settings',
    submenuTableSection: true,
    subitemsSettings: [
      TABLE_SETTINGS_TITLE,
      DATE_FORMAT_CONFIG,
      /* TIMELINE_SETTINGS_TITLE,
            ...TIMELINE_CONFIG, */
      MORE_INFO_CONFIG
    ]
  }
];
