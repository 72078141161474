export const SET_RESOURCE_COLUMN_SECTORS = 'SET_RESOURCE_COLUMN_SECTORS';
export const SET_RESOURCE_COLUMN_RESOURCE = 'SET_RESOURCE_COLUMN_RESOURCE';
export const SET_RESOURCE_COLUMN_PROJECT = 'SET_RESOURCE_COLUMN_PROJECT';

export const productivityChartActions = {
  setSectors: (sectors) => ({
    type: SET_RESOURCE_COLUMN_SECTORS,
    sectors: sectors
  }),
  setReourses: (resources) => ({
    type: SET_RESOURCE_COLUMN_RESOURCE,
    resources: resources
  }),

  setProjects: (projects) => ({
    type: SET_RESOURCE_COLUMN_PROJECT,
    projects: projects
  })
};
