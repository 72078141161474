import { createSlice } from '@reduxjs/toolkit';

export const companySliceKey = 'selectCompanyState';

const initialState = {
  props: {}
};

const { actions, reducer } = createSlice({
  name: companySliceKey,
  initialState,
  reducers: {
    setProps(state, { payload }) {
      state.props = payload;
    }
  }
});

reducer.persist = false;

export const selectCompanyReducer = reducer;
export const { setProps } = actions;
