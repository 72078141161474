/* eslint-disable prefer-const */
import React from 'react';
export default function IconSvg({
  style = {},
  fill = '#000',
  width = '100%',
  className = '',
  viewBox = '0 0 1024 1024',
  data = '',
  dataArr = []
}) {
  /** render */
  return (
    <svg
      style={style}
      viewBox={viewBox}
      className={`svg-icon ${className || ''}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}>
      {dataArr.length ? (
        dataArr.map((path, i) => <path key={i} d={path} fill={fill} />)
      ) : (
        <path d={data} fill={fill} />
      )}
    </svg>
  );
}
