import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Button, Table } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './index.css';
import { openNotification } from '../../../utils';
import { useSelector } from 'react-redux';

/** import services */
import { projectService } from '../../../services/project.service';

function ModalAssignSubContract({
  Visible,
  setVisible,
  usersByCompany,
  UsersProject,
  setReloadTableUsers,
  ReloadTableUsers,
  t
}) {
  // GET ID PROJECT FROM REDUX
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;

  // GET CURRENT COMPANY FOR REDUX
  const stateCompany = useSelector((state) => state.companyState);
  const currentCompany = stateCompany.currentCompany;

  const defaultState = {
    email: '',
    role: '',
    companyId: currentCompany.id,
    projectId: projectSelectedId
  };
  const [rowsUsers, setRowsUsers] = useState([defaultState]);
  const [search, setSearch] = useState('');
  const [copy, setCopy] = useState(null);

  // Close modal
  const handleCancel = () => {
    setVisible(false);
    setRowsUsers([defaultState]);
    setSearch('');
  };

  const [ProjectSelected, setProjectSelected] = useState({});

  useEffect(() => {
    filter();
  }, [usersByCompany, search]);

  // GET PROJECT GENERAL SELECTED BY USER
  useEffect(() => {
    if (Visible) {
      const abortController = new AbortController();
      projectService
        .show(projectSelectedId)
        .then((response) => {
          if (response.project === undefined) {
            setProjectSelected({});
          } else {
            setProjectSelected(response.project);
          }
        })
        .catch(() => setProjectSelected({}));
      return function cleanup() {
        abortController.abort();
      };
    }
  }, [Visible]);

  const asignProjectSubcontract = async (data) => {
    const resp = await projectService.asignProjectSubContract(data);
    return resp;
  };

  const deallocateSubcontractOfProject = async (data) => {
    const resp = await projectService.deallocateSubcontracOfProject(data);
    return resp;
  };

  const handleClose = () => {
    setVisible(false);
    setSearch('');
  };

  const onChange = async (e, subcontract) => {
    const check = e.target.checked;
    if (check) {
      /** assign */
      await asignProjectSubcontract({
        subcontractId: subcontract.id,
        projectId: projectSelectedId
      });
      const alertSucces = {
        title: t('settings.project_subcontracts.assign'),
        description: t('settings.project_subcontracts.assign_sub'),
        type: 'success'
      };
      openNotification(alertSucces);
    } else {
      /** deallocate */
      const resp = await deallocateSubcontractOfProject({
        subcontractId: subcontract.id,
        projectId: projectSelectedId
      });
      if (resp.msj === 'subcontract deallocated') {
        const alertSucces = {
          title: t('settings.project_subcontracts.assign'),
          description: t('settings.project_subcontracts.remove'),
          type: 'success'
        };
        openNotification(alertSucces);
      }
    }
    setReloadTableUsers(!ReloadTableUsers);
  };

  const columns = [
    {
      title: t('settings.project_users.name'),
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.name || '';
        b = b.name || '';
        return b.localeCompare(a);
      },
      render: (name, data, index) => (
        <input
          className="inp-table"
          placeholder={t('settings.project_users.name')}
          autoComplete="off"
          type="text"
          name="name"
          value={name || ''}
          style={{ width: '90%', fontSize: '12px', border: '0px' }}
          title={name}
          disabled
        />
      )
    },
    {
      title: t('settings.organization_form.subcontracts.rut'),
      dataIndex: 'rut',
      key: 'rut',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.rut || '';
        b = b.rut || '';
        return b.localeCompare(a);
      },
      render: (rut, data, index) => (
        <input
          className="inp-table"
          placeholder={t('settings.organization_form.subcontracts.rut')}
          autoComplete="off"
          type="text"
          name="rut"
          value={rut || ''}
          style={{ width: '90%', fontSize: '12px', border: '0px' }}
          title={rut}
          disabled
        />
      )
    },
    {
      title: t('settings.project_users.email'),
      dataIndex: 'email',
      key: 'email',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.email || '';
        b = b.email || '';
        return b.localeCompare(a);
      },
      render: (email, data, index) => (
        <input
          className="inp-table"
          placeholder={t('settings.project_users.email')}
          type="text"
          name="email"
          defaultValue={email}
          style={{ width: '100%', fontSize: '12px', border: '0px' }}
          title={email}
          disabled
        />
      )
    },
    {
      title: t('settings.project_users.assign_user_form.add_remove'),
      dataIndex: '',
      key: '',
      render: (_, data, index) => (
        <Checkbox
          onChange={(e) => onChange(e, data)}
          checked={UsersProject.some((el) => el.id === data.id)}>
          <span className="add-to-project">
            {t('settings.project_users.assign_user_form.add_to_project')}
          </span>
        </Checkbox>
      )
    }
  ];

  const filter = () => {
    if (!search) {
      setCopy(usersByCompany);
      return;
    }
    const filteredData = usersByCompany.filter(
      (entry) =>
        (entry.name &&
          entry.name.toLowerCase().includes(search.toLowerCase())) ||
        (entry.rut && entry.rut.toLowerCase().includes(search.toLowerCase())) ||
        (entry.email &&
          entry.email.toLowerCase().includes(search.toLowerCase()))
    );
    setCopy(filteredData);
  };

  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  return (
    <Modal
      visible={Visible}
      onCancel={handleCancel}
      maskClosable={false}
      closable={false}
      width={850}
      className="modal-assign"
      footer={
        <div className="center-btn-modal-adduser" key="btns">
          <Button onClick={handleCancel} className="btn-continue-user-company">
            {t('settings.project_users.assign_user_form.close')}
          </Button>
        </div>
      }>
      <div className="title-frm-add">
        {t('settings.project_subcontracts.assign')}
        <div className="title-close" onClick={handleClose}>
          <CloseOutlined />
        </div>
      </div>
      <div style={{ display: 'flex', margin: 2 }}>
        <input
          className="inp-table"
          placeholder={
            t('settings.project_users.assign_user_form.search') + ' ...'
          }
          autoComplete="off"
          type="text"
          name="search"
          value={search || ''}
          style={{
            width: '180px',
            fontSize: '12px',
            border: '1px solid',
            borderColor: '#2C3421',
            margin: 2,
            paddingLeft: 10
          }}
          onChange={handleChangeSearch}
        />
      </div>
      {usersByCompany?.length && (
        <Table
          className="table-users-project"
          columns={columns}
          dataSource={copy}
          rowKey={(obj) => obj.id}
          pagination={{ pageSize: 20 }}
        />
      )}
    </Modal>
  );
}

export default ModalAssignSubContract;
