import { createHttpClient } from '../utils/httpUtils';

class UserSettings {
  constructor() {
    this.http = createHttpClient();
  }

  async getConstraintValidation() {
    if (!this.http) {
      console.warn('No session token available, request aborted');
      return false;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const res = await this.http
      .get('usersettings/' + user.id)
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return res ? res.data : res;
  }

  async saveConstraintValidation(constraintValidation) {
    const user = JSON.parse(localStorage.getItem('user'));
    const res = await this.http
      .post('usersettings/', {
        userId: user.id,
        code: 'CONSTRAINT_VALIDATION',
        metadata: {
          constraintValidation: constraintValidation
        }
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return res ? res.data : res;
  }
}

export default new UserSettings();
