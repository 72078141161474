/* eslint-disable no-case-declarations */
import {
  SET_INITIAL_LOAD_FLAG_DATA,
  UPDATE_PARSED_VALUE
} from '../actions/lookaheadTimelineActions';

/**
 * Reducer function means a switch case to filter action data on the platform for general state
 * @param {*} state Real time updated through all components state (general state flux)
 * @param {*} action Action is an object with structyure { type: STRING, payload: FUNCTION }
 */
function timelineReducer(
  state = { value: false, lastProjectSelected: null, sectorSelected: null },
  action
) {
  switch (action.type) {
    case SET_INITIAL_LOAD_FLAG_DATA:
      return {
        ...state,
        value: action.payload.value,
        lastProjectSelected: action.payload.lastProjectSelected,
        sectorSelected: action.payload.sectorSelected
      };
    case UPDATE_PARSED_VALUE:
      return {
        ...state,
        value: action.payload
      };
    default:
      return state;
  }
}

export default timelineReducer;
