import React, { useEffect, useState } from 'react';

import { ToolbarGroup } from '../../ToolbarGroup';
import { FILTER_BUTTONS_CONFIG } from '../constants';
import styles from './FilterButtons.module.scss';

export const FilterButtons = ({
  quantities,
  selectedStatus,
  onCommandDispatched,
  t
}) => {
  const defaultButtonCommand = 'SHOW_ALL';
  const defaultButtonLabel = t('modification_requests_drawer.filter_all');
  const [filterButtons, setFilterButtons] = useState(FILTER_BUTTONS_CONFIG);

  useEffect(() => {
    const status = quantities[selectedStatus] === 0 ? 'ALL' : selectedStatus;
    handleCommandDispatched(`SHOW_${status}`);
  }, [quantities]);

  const getQuantity = (command) => quantities[command.split('_').pop()];

  const getButtonLabel = (quantity, command) =>
    `${command === defaultButtonCommand ? defaultButtonLabel : ''} (${quantity})`;

  const handleCommandDispatched = (command, value) => {
    setFilterButtons((filterButtons) => [
      [...filterButtons[0]].map((btn) => ({
        ...btn,
        isCta: btn.command === command,
        disabled: getQuantity(btn.command) === 0,
        text: getButtonLabel(getQuantity(btn.command), btn.command)
      }))
    ]);

    onCommandDispatched(command, value);
  };

  return (
    <ToolbarGroup
      className={styles['filter-buttons']}
      items={filterButtons}
      onCommandDispatched={handleCommandDispatched}
      t={t}
    />
  );
};
