import TooltipSystem from '../components/DesignSystem/TooltipSystem';
import { PLACEMENT_TOP } from '../components/Settings/settingsPlugin/Authorizers/Procore/AddProject/constants';

const AVERAGE_CHARACTER_PIXEL_WIDTH = 8.5;
const ELLIPSIS = '...';

/**
 * Splits a text string based on a specified width. If the text exceeds the width,
 * it truncates the text and shows a tooltip.
 *
 * @param {string} [text=''] - The text to split
 * @param {number} [textWidth=0] - The width to split the text at
 * @returns {string|Object} - The truncated text, or a TooltipSystem object containing the full text
 */
export const splitText = (text = '', textWidth = 0) => {
  const maxCharacters = Math.floor(textWidth / AVERAGE_CHARACTER_PIXEL_WIDTH);

  let showedText = text;
  let tooltip;

  if (text.length > maxCharacters) {
    showedText =
      text.slice(0, Math.max(0, maxCharacters - ELLIPSIS.length)) + ELLIPSIS;
    tooltip = true;
  }

  return tooltip
    ? TooltipSystem({
        children: showedText,
        placement: PLACEMENT_TOP,
        title: text
      })
    : showedText;
};
