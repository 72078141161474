import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../../redux/slices/projectSlice';
import { sectorService } from '../../../services';
import { WarningIcon } from '../../../icons';
import ModalSystem from '../../DesignSystem/ModalSystem';
import notificationSystemV2 from '../../DesignSystem/NotificationSystemV2';
import * as Sentry from '@sentry/react';
import { trackingEvent } from '../../../analytics';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getSignedUser } from '../../../utils/userUtils';
import { Colors } from '../../../constants/colors.constants';

const SetVisibilityOfSchedule = ({ show, setShow, t }) => {
  const { PRIMARY } = Colors;
  const dispatch = useDispatch();
  const { projectState } = useSelector((state) => state);
  const {
    projectState: { sectorSelectedRename, allSectors }
  } = useSelector((state) => state);

  const getSectorsByProject = useCallback(async (idProject) => {
    const resp = await sectorService.getSectorsByProject(idProject);
    return resp ? resp.sectors : false;
  }, []);

  const callGetSectors = useCallback(async () => {
    const sectors = await getSectorsByProject(projectState.projectSelected);
    if (sectors) {
      const filterSectors = sectors.filter((e) => e.status === true);
      dispatch(projectActions.setAllSectors(filterSectors));
    }
  }, [projectState.projectSelected]);

  useEffect(() => {
    callGetSectors();
  }, [callGetSectors]);

  const updateSectorFieldsBySectorId = async (sectorId, visibility) => {
    const fields = [{ field: 'visible', value: !visibility }];
    await sectorService.updateFieldsBySectorId(sectorId, fields);
  };

  const updateCurrentSchedule = async (selectedSectorId, visible) => {
    await Promise.all([
      updateSectorFieldsBySectorId(selectedSectorId, visible)
    ]);
  };

  const handleCloseModal = () => {
    trackVisibilityOption('cancel', sectorSelectedRename);
    setShow(false);
  };

  const notifyScheduleVisibility = (type, visible) => {
    const visibilityStatus = visible ? 'show' : 'hide';
    notificationSystemV2({
      type: type,
      key: `handle-schedule-visibility-${type}`,
      message: t(
        `notifications.set_visibility_schedule_success_msg.title.${visibilityStatus}`
      ),
      description: t(
        `notifications.set_visibility_schedule_success_msg.${type}`
      )
    });
  };
  const trackVisibilityOption = (buttonOption, sector) => {
    const loggedUser = getSignedUser();

    trackingEvent(
      'schedule_visibility_option_used',
      {
        ...getBasicAmplitudEventProperties(),
        user_role: loggedUser?.role,
        schedule_id: sector.id,
        modal_result: buttonOption,
        visibility_status: !sectorSelectedRename?.visible
      },
      AMPLITUDE_SERVICE
    );
  };
  const handleScheduleVisibility = async () => {
    try {
      const newAllSectors = [...allSectors];
      await updateCurrentSchedule(
        sectorSelectedRename?.id,
        sectorSelectedRename?.visible
      );

      dispatch(projectActions.setAllSectors(newAllSectors));
      setShow(false);
      await callGetSectors();

      trackVisibilityOption('confirm', sectorSelectedRename);
      notifyScheduleVisibility('success', sectorSelectedRename?.visible);
    } catch (error) {
      notifyScheduleVisibility('error', sectorSelectedRename?.visible);
      Sentry.captureException(error);
    }
  };

  const contentChildren = () => (
    <div className="set-visibility__content">
      <div className="content__form">
        <div className="form__icon">
          <WarningIcon color={PRIMARY} />
        </div>
        <div className="form__title">
          <h5>
            {sectorSelectedRename?.visible
              ? t('visibility_of_schedule.description.hide')
              : t('visibility_of_schedule.description.show')}
          </h5>
        </div>
      </div>
      <div className="content__buttons">
        <button className="buttons__cancel" onClick={() => handleCloseModal()}>
          {t('visibility_of_schedule.button_cancel_text')}
        </button>
        <button
          className="buttons__confirm"
          onClick={() => handleScheduleVisibility()}>
          {t('visibility_of_schedule.button_confirm_text')}
        </button>
      </div>
    </div>
  );

  return ModalSystem({
    visible: show,
    theme: 'dark',
    setVisible: setShow,
    showTitle: false,
    centered: true,
    width: 400,
    children: contentChildren(),
    zIndex: 2000
  });
};

export default SetVisibilityOfSchedule;
