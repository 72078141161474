import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const useShowNotificationOnUnauthorized = (notificationSystem) => {
  const { search } = useLocation();
  const { t, ready } = useTranslation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (!ready || query.get('error') !== 'unauthorized') {
      return;
    }

    notificationSystem({
      key: 'error_views.no_project_access.toast',
      type: 'error',
      message: t('error_views.no_project_access.toast')
    });
  }, [ready, query, t, notificationSystem]);
};
