import React from 'react';
import { withTranslation } from 'react-i18next';
import DropdownSystemV2 from '../../../../DesignSystem/DropdownSystemV2';
import TooltipSystem from '../../../../DesignSystem/TooltipSystem';
import { PinToolbarProjectsIcon } from '../../../../../icons';
import { zoomLevels } from '../../../../GanttVisualization/constants';

function ToolbarRight({ t, zoomLevel }) {
  return (
    <div className="toolbar-right">
      <div
        className="toolbar-right__button"
        onClick={() => {
          if (!window.ganttScheduleOfSchedule) {
            return;
          }

          window.ganttScheduleOfSchedule.scrollToTodayAtChart();
        }}>
        {TooltipSystem({
          title: t('scheduleofschedule.toolbar.TODAY'),
          children: (
            <div className="button__icon">
              <PinToolbarProjectsIcon />
            </div>
          )
        })}
      </div>
      <div className="toolbar-right__dropdown">
        {DropdownSystemV2({
          theme: 'light',
          version: 'v2',
          trigger: ['click'],
          height: 32,
          label: t('scheduleofschedule.toolbar.' + zoomLevels[zoomLevel]),
          items: zoomLevels.map((zoom, index) => ({
            key: zoom,
            label: t(`scheduleofschedule.toolbar.${zoom}`),
            onClick: () => {
              if (!window.ganttScheduleOfSchedule) {
                return;
              }
              const newZoomLevel = zoomLevels.length - 1 - index;
              window.ganttScheduleOfSchedule.changeScaleVisualization(
                newZoomLevel
              );
            },
            isDisabled: false,
            isDivider: false,
            isActive: zoomLevel === index,
            isGroup: false,
            subitems: []
          }))
        })}
      </div>
    </div>
  );
}

export default withTranslation()(ToolbarRight);
