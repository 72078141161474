/* eslint-disable prefer-const */

/** React components  */
import React, { useEffect, useState } from 'react';

/** Import elements from library Antd */
import { Empty, Tooltip } from 'antd';

/** Redux implementation */
import { useSelector, useDispatch } from 'react-redux';
import { constraintActions } from '../../../../../redux/actions/constraintActions';

/** Services */
import { constraintTypeService } from '../../../../../services';

import { categoriesConstraintType, renderIconCat } from '../../../../../utils';

/** import components */
import ConstraintTypesEmpty from './ConstraintTypesEmpty';
import ConstraintTypesList from './ConstraintTypesList';

export default function ConstraintTypes(props) {
  /** use props */
  const { typeValid, handleContinue, t, types, setTypes, setLoadingTypes } =
    props;

  /** Redux */
  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.projectState);
  const projectSelected = projectState.projectSelected;
  const constraintState = useSelector((state) => state.constraintState);

  /** Hooks */
  // const [types, setTypes] = useState([]) /** handle load */

  /** load data */
  useEffect(() => {
    loadTypes();
  }, []);

  const loadTypes = async () => {
    const ctypes = await getConstraintsTypes();
    if (ctypes) {
      setTypes(ctypes.constrainttypes);
      setLoadingTypes(false);
    }
  };

  /** services */
  const getConstraintsTypes = async () => {
    const types = await constraintTypeService.showByProject(projectSelected);
    return types;
  };

  /** Component Logic */
  const handleClickTypes = (type) => {
    dispatch(
      constraintActions.setConstraintForm({
        ...constraintState.constraintForm,
        type: type.id
      })
    );
    handleContinue();
  };

  let old = false;

  /** render */
  return old ? (
    <div className="constraint-wrapp-types">
      <div className={typeValid ? 'subTitle' : 'subTitle error'}>
        {t('constraint_type_label')}
      </div>
      <div className="constraint-types">
        {types.length ? (
          <div className="constraint-list-types">
            {types.map((type, index) => {
              const findItem = categoriesConstraintType.find(
                (el) => el.value === type.type
              );
              return (
                <div
                  key={index}
                  onClick={() => handleClickTypes(type)}
                  className={
                    constraintState.constraintForm.type === type.id
                      ? 'active'
                      : null
                  }>
                  <Tooltip title={findItem.label}>
                    <img
                      className="cat-icon"
                      src={renderIconCat(findItem.icon)}
                      alt=""
                    />
                  </Tooltip>
                  <span>{type.name}</span>
                </div>
              );
            })}
          </div>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No hay tipos</span>}
          />
        )}
      </div>
    </div>
  ) : (
    <div className="constraint__container">
      <div className="container__title">
        <h6>{t('constraint_type_label')}</h6>
        <div
          className={`container__list ${types.length ? 'list--full' : 'list--empty'} ${types.length > 5 ? 'list--scroll' : ''}`}>
          {types.length ? (
            <ConstraintTypesList types={types} />
          ) : (
            <ConstraintTypesEmpty t={t} />
          )}
        </div>
      </div>
    </div>
  );
}
