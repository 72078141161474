/** Showing calendar feature
 * on Save
 * BUG FIX: MUST BE IMPLEMENTED ON MOVEMENTS THAT ARE NOT DROPPED, INLINE EDITIONS FOR EXAMPLE
 */
import { getTaskCalendar } from './getTaskCalendar';

const updateTaskTiming = ({ task, dir = 'future', gantt }) => {
  if (!gantt) {
    gantt = window.to_use_react_gantt;
  }
  const calendar = getTaskCalendar(task, gantt);
  task.start_date = calendar.getClosestWorkTime({
    dir: dir,
    date: gantt.date.parseDate(task.start_date, 'xml_date'),
    unit: gantt.config.duration_unit,
    task: task
  });
  task.end_date = calendar.calculateEndDate(task);
};

export { updateTaskTiming };
