import { debounce } from 'lodash';
import { updateStacks } from './updateStacks';
import { initializeGanttReadjustmentCounters } from './initializeGanttReadjustmentCounters';
import { refreshCriticalPathIsNeeded } from './refreshCriticalPathIsNeeded';
import { scrollToTodayIfNeeded } from './scrollToTodayIfNeeded';
import { setGanttLoading } from '../../../../redux/slices/ganttSlice';
import { store } from '../../../../redux/store';

import {
  checkUpdatedElementsToCalculateTimingPast,
  reloadDuration,
  updateExpectedGlobal,
  criticalPathRefresh,
  validateCriticalNeed,
  requestRender
} from '../utils/index';
import { earlyAccessCriticalPath } from '../../../../utils/earlyAccessCriticalPath';

function onAfterAutoSchedule({
  taskId,
  updatedTasks,
  setAutoscheduling,
  setAutoschedulingVisual
}) {
  const gantt = window.to_use_react_gantt;
  function executeAutoSchedule() {
    gantt.autoscheduleNumber++;
    if (gantt.autoscheduleNumber == 2) {
      gantt.disableAutoscheduleForMilestonesWithProgress();
    }
    if (gantt.isPasting) return;
    gantt.is_autoscheduling = false;
    reloadDuration(gantt);
    gantt.fixScaleDateHeader();

    if (gantt.clearStacks) {
      gantt.loadedSuccesfully = true;
      updateStacks(setAutoscheduling, setAutoschedulingVisual);
      gantt.clearStacks = false;
    }

    updateExpectedGlobal();
    initializeGanttReadjustmentCounters();
    scrollToTodayIfNeeded();

    refreshCriticalPath();
    requestRender();
    if (!store.getState().ganttState.ganttLoading) {
      store.dispatch(setGanttLoading(false));
    }
  }

  executeAutoSchedule();
}
const debouncedCriticalPath = debounce(() => {
  refreshCriticalPathIsNeeded(criticalPathRefresh, validateCriticalNeed);
}, 1000);

function refreshCriticalPath() {
  if (earlyAccessCriticalPath()) {
    return debouncedCriticalPath();
  }
  refreshCriticalPathIsNeeded(criticalPathRefresh, validateCriticalNeed);
}

export { onAfterAutoSchedule };
