import React from 'react';
import { Layout } from 'antd';
import Toolbar from '../components/Toolbar';
import './hoc.css';

const withToolbar = (props) => (WrappedComponent) => () => (
  <Layout>
    <Toolbar fixed={props.position !== undefined} />
    <WrappedComponent />
  </Layout>
);

export default withToolbar;
