import React, { useState, useEffect } from 'react';
import './index.css';
import './react-layout.css';
import './react-resizable.css';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useSelector } from 'react-redux';
import Card from './card';
import Sector from './Sector';
import CurveS from './curve_s';
import ResourceCurve from './resource_curve';
import VelocityCurve from './velocity_curve';
import DaysBehindSchedule from './curve_days_behind_schedule';
import CurvePpc from './curve_ppc';
import CurvePCR from './curve_pcr';
import Cnc from './cnc';
import { projectService } from '../../../services/project.service';
import { formatMoney } from '../../../utils/lookahead-common';
import { analyticService } from '../../../services';
import { analyticServiceResourse } from '../../../services/analytic_resource.service';

import moment from 'moment';
import {
  dollar,
  columns,
  filter,
  helmet,
  analitycPanels,
  getTodayWeekWithPdayAndCurrentDay,
  dynamicSort
} from '../../../utils';
import IconComponent from '../../../components//Projects/IconSvg';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { withTranslation } from 'react-i18next';
import PcrForUser from './pcr_for_user';
import PccForUser from './ppc_for_user';
import {
  Dropdown,
  Menu,
  Row,
  message,
  Col,
  Checkbox,
  Popover,
  DatePicker,
  Tag,
  Button,
  Spin,
  Icon
} from 'antd';
import TaktAnalytics from './taktAnalytics';
import ProductivityAnalytics from './productivityAnalytics';
import { getTodayWeekWithPday } from '../../../utils';
import ProductivityChart from '../../../components/ProductivityChart';
import useDebounce from '../../../hooks/useFilterAnalitycDebounce';
import { MatrixOfResources } from '../../../components/ResourcesMatrix';
import { getSignedUser } from '../../../utils/userUtils';
import { roleTypes } from '../../../constants/role.constants';
import { usersAccessOnlyToVisibleSchedules } from '../../../components/Toolbar/Toolbar';

const ResponsiveGridLayoutGen = WidthProvider(Responsive);
const ResponsiveGridLayoutPlan = WidthProvider(Responsive);
const ResponsiveGridLayoutConstraint = WidthProvider(Responsive);
const ResponsiveGridLayoutContinuos = WidthProvider(Responsive);
const { RangePicker } = DatePicker;

export const TestIds = {
  DROPDOWN_ANALYTIC_SELECTOR: 'DROPDOWN_ANALYTIC_SELECTOR',
  ANALYTIC_OPTION: 'ANALYTIC_OPTION'
};
function General(props) {
  /** props */
  const { permissions, analitycsRq, isTaktAnalyticsDashboard } = props;
  const { t } = props;
  const navigatorLang = navigator.language || navigator.userLanguage;
  /** redux */
  const projectState = useSelector((state) => state.projectState);
  const projectSelected = projectState.allProjects.find(
    (e) => e.id == projectState.projectSelected
  );

  const stateCompany = useSelector((state) => state.companyState);

  /** hooks */
  const [project, setProject] = useState(null);
  const [weekcommitments, setWeekcommitments] = useState([]);
  const [constraints, setConstraints] = useState('No Data');
  const [constraintsArray, setConstraintsArray] = useState([]);
  const [constraintsArrayUser, setConstraintsArrayUser] = useState([]);
  const [weekcommitmentsArrayUser, setWeekcommitmentsArrayUser] = useState([]);
  const [constraintscount, setConstraintscount] = useState('No Data');
  const [initdate, setInitdate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endBaseDate, setEndBaseDate] = useState(null);
  const [lastweekcommitments, setLastweekcommitments] = useState('No Data');
  const [lastweekcommitmentstask, setLastweekcommitmentstask] =
    useState('No Data');
  const [planificationDay, setPlanificationday] = useState(0);
  const [cnc, setCnc] = useState(null);
  const [textDaysOfLatter, setTextDaysOfLatter] = useState('');
  const [sectorFilter, setSectorFilter] = useState([]); /** filter sector */
  const [allUsers, setAllUsers] = useState([]); /** filter users */
  const [arraySector, setArraySector] = useState();
  const [arrayActivityMaster, setArrayActivityMaster] = useState([]);
  const [arrayUser, setArrayUser] = useState();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [dataRangeFilter, setDataRangeFilter] = useState([]);
  const [dataRangeFilter2, setDataRangeFilter2] = useState([]);
  const [dataWeekFilter, setDataWeekFilter] = useState([]);
  const [dataWeekFilter2, setDataWeekFilter2] = useState([]);
  const [responseCurve, setResponseCurve] = useState([]);
  const [responseResourceCurve, setResponseResourceCurve] = useState([]);
  const [panelSelected, setPanelSelected] = useState('gen');
  const [firstLoad, setFirstLoad] = useState(true);
  const [constraintsArrayNew, setConstraintsArrayNew] = useState(null);
  let curve_recursos_refresh = true;

  const debouncer = useDebounce(arraySector, 800);

  const basid_iamge =
    'https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-6.png';
  const layout = [
    { i: 'headerTitle', x: 0, y: 0, w: 9, h: 3, static: true },
    { i: 'ppc', x: 0, y: 1, w: 3, h: 3 },
    { i: 'pcr', x: 3, y: 1, w: 3, h: 3 },
    { i: 'days_late', x: 6, y: 2, w: 3, h: 3 },
    { i: 'c', x: 9, y: 2, w: 3, h: 6 },
    { i: 'sector', x: 0, y: 3, w: 3, h: 9 },
    { i: 'curve_s', x: 3, y: 3, w: 9, h: 9 },
    { i: 'resource_matrix', x: 3, y: 3, w: 9, h: 9 },
    { i: 'resource_curve', x: 3, y: 3, w: 9, h: 9 },
    { i: 'productivity_chart', x: 3, y: 3, w: 9, h: 9 },
    { i: 'curve_prc', x: 0, y: 4, w: 5, h: 9 },
    { i: 'curve_cnc', x: 5, y: 3, w: 3, h: 9 },
    { i: 'curve_activity_line', x: 8, y: 4, w: 4, h: 9 },
    { i: 'velocity_curve', x: 0, y: 5, w: 6, h: 9 },
    { i: 'days_behind', x: 6, y: 5, w: 6, h: 9 }
  ];
  const layoutPanelPlan = [
    { i: 'headerTitle', x: 0, y: 0, w: 12, h: 3, static: true },
    { i: 'sector', x: 0, y: 3, w: 3, h: 9 },
    { i: 'days_behind', x: 3, y: 3, w: 9, h: 9 },
    { i: 'velocity_curve', x: 0, y: 5, w: 6, h: 9 },
    { i: 'curve_pcc_user', x: 6, y: 6, w: 6, h: 9 },
    { i: 'resource_matrix', x: 0, y: 3, w: 12, h: 12 },
    { i: 'resource_curve', x: 0, y: 3, w: 12, h: 12 },
    { i: 'productivity_chart', x: 0, y: 3, w: 12, h: 12 }
  ];

  const layoutPanelConstraint = [
    { i: 'headerTitle', x: 0, y: 0, w: 12, h: 3, static: true },
    { i: 'curve_activity_line', x: 0, y: 3, w: 6, h: 9 },
    { i: 'curve_pcr_user', x: 6, y: 6, w: 6, h: 9 }
  ];

  const layoutPanelContinuos = [
    { i: 'headerTitle', x: 0, y: 0, w: 12, h: 3, static: true },
    { i: 'curve_cnc', x: 0, y: 3, w: 6, h: 9 }
  ];

  const arrOptionsGeneral = [
    { label: t('analitic.wid_ppc'), value: 1 },
    { label: t('analitic.wid_pcr'), value: 2 },
    { label: t('analitic.Wid_day'), value: 3 },
    { label: t('analitic.wid_sector'), value: 4 },
    { label: t('analitic.curve_s'), value: 5 },
    { label: t('analitic.hystory_ppc'), value: 6 },
    { label: t('analitic.hystory_pcr'), value: 7 },
    { label: t('analitic.cnc'), value: 8 }
    // { label: t('analitic.curve_velocity'), value: 9 },
    // { label: t('analitic.hystory_ppc'), value: 10 }
  ];

  const optionsChart = {
    gen: [
      { label: t('analitic.wid_ppc'), value: 1 },
      { label: t('analitic.wid_pcr'), value: 2 },
      { label: t('analitic.Wid_day'), value: 3 },
      { label: t('analitic.wid_sector'), value: 4 },
      { label: t('analitic.curve_s'), value: 5 },
      { label: t('analitic.hystory_ppc'), value: 6 },
      { label: t('analitic.cnc'), value: 8 },
      { label: t('analitic.hystory_pcr'), value: 7 }
    ],
    plan: [
      { label: t('analitic.wid_sector'), value: 11 },
      { label: t('analitic.Wid_gap'), value: 12 },
      { label: t('analitic.curve_velocity'), value: 13 },
      { label: t('analitic.hystory_pcr'), value: 14 }
    ],
    constraint: [
      { label: t('analitic.hystory_pcr'), value: 7 },
      { label: t('analitic.team_constraints'), value: 10 }
    ],
    continuous: [{ label: t('analitic.cnc'), value: 8 }],
    takt: [{ label: t('analitic.takt'), value: 1 }]
  };

  /** hooks */
  const [options, setOptions] = useState(arrOptionsGeneral);
  const [selectedOption, setSelectedOption] = useState(arrOptionsGeneral);
  const [loading, setLoading] = useState(false);
  const [resourceLoading, setResourceLoading] = useState(false);
  const [ppccar, setPpccar] = useState(true);
  const [pcrcar, setPcrcar] = useState(true);
  const [lastdaycar, setLastdaycar] = useState(true);
  const [secorcar, setSectorcar] = useState(true);
  const [plansecorcar, setPlanSectorcar] = useState(true);
  const [ppcchartcar, setPpcchart] = useState(true);
  const [schartcar, setSchartcar] = useState(true);
  const [planschartcar, setPlanSchartcar] = useState(true);
  const [schartvelocity, setSchartvelocity] = useState(true);
  const [planschartvelocity, setPlanSchartvelocity] = useState(true);
  const [pcrusers, setPcrUsers] = useState(true);
  const [cncchartcar, setCncchartcar] = useState(true);
  const [pcrchartcar, setPcrchart] = useState(true);
  const [planpcrchartcar, setPlanPcrchart] = useState(true);
  const [filterVisibility, setFilterVisibility] = useState(false);
  const [filterSectorVisibility, setFilterSectorVisibility] = useState(false);
  const [filterUserVisibility, setFilterUserVisibility] = useState(false);
  const [filterTabsSector, setFilterTabsSector] = useState([]);
  const [filterTabsUser, setFilterTabsUser] = useState([]);
  const [total_pcr_weeksF, setTotal_pcr_weeksF] = useState([]);

  /** Permissions */
  const { role } = getSignedUser() || {};
  const haveAccessOnlyVisibleSchedules =
    usersAccessOnlyToVisibleSchedules.includes(role);

  const [backgroundColorFilter, setBackgroundColorFilter] = useState({
    show: false,
    style: {},
    counter: 0
  });

  const newGetPcr = (pDay, constraint) => {
    let ppc_exist = false;
    const pcrs = [];
    const labels = [];
    const commitment_percentajes = [];
    const offsetTZ = new Date().getTimezoneOffset() / -60;
    if (Object.keys(constraint).length !== 0) {
      const array_constrint = [];
      Object.keys(constraint).forEach((key, index) => {
        const result = constraint[key].reduce((r, a) => {
          r[a.week] = r[a.week] || [];
          r[a.week].push(a);
          return r;
        }, Object.create(null));
        const constraint_push = [];
        Object.keys(result).forEach((key_, index_) => {
          constraint_push.push({ week: key_, constraint: result[key_] });
        });
        constraint_push.sort((x, y) =>
          parseInt(x.week) < parseInt(y.week) ? -1 : 1
        );
        array_constrint.push({ year: key, data: constraint_push });

        // array_constrint.push({week:parseInt(array_data[0]) , year : parseInt(array_data[1]) , group : key , constraint : constraint[key]})
      });
      array_constrint.map((year_data) => {
        year_data.data.sort();
        year_data.data.map((contraint_object) => {
          // const data_week = moment().locale(t('lang')).year(year_data.year).week(parseInt(contraint_object.week)).startOf('isoweek').add((planificationDay - 1 + 6), 'days');
          /** find commitment date valid */
          const findElemDateCommitment = contraint_object.constraint.find(
            (el) => el.commitmentDate
          );
          if (findElemDateCommitment) {
            const getDateInRange =
              offsetTZ > 0
                ? moment(
                    findElemDateCommitment.commitmentDate,
                    'YYYY-MM-DD HH:mm'
                  ).utc(offsetTZ)
                : moment(
                    findElemDateCommitment.commitmentDate,
                    'YYYY-MM-DD HH:mm'
                  );
            // const todayData = getTodayWeekWithPdayAndCurrentDay(projectState, getDateInRange, planifDay)
            const todayData = getTodayWeekWithPdayAndCurrentDay(
              projectState,
              getDateInRange
            );
            const label =
              offsetTZ > 0
                ? moment(todayData[2]?.end, 'YYYY-MM-DD')
                    .utc(offsetTZ)
                    .format('DD MMM YY HH:mm')
                : moment(todayData[2]?.end, 'YYYY-MM-DD').format(
                    'DD MMM YY HH:mm'
                  );
            const labelToShow = `${label.split(' ')[0]} ${label.split(' ')[1]} ${label.split(' ')[2]}`;
            labels.push(labelToShow);
          }
          let total = 0;
          let realized = 0;
          let reproramin = 0;
          contraint_object.constraint.map((c) => {
            if (c.commitmentDate) {
              if (c.status == 'released') {
                if (c.release_date) {
                  const commitmentDateFormat = moment(
                    c.commitmentDate,
                    'YYYY-MM-DD'
                  );
                  const releaseDateFormat = moment(c.release_date).format(
                    'YYYY-MM-DD'
                  );
                  if (
                    commitmentDateFormat.isSameOrAfter(releaseDateFormat, 'day')
                  ) {
                    realized++;
                  }
                }
              }
              if (c.reprogramin) {
                reproramin++;
              }
              total++;
            }
          });
          const commitment_percentaje = (realized / total) * 100;
          if (total > 0) {
            commitment_percentajes.push(commitment_percentaje.toFixed(2));
            const pcr = {
              realized: realized,
              total: total,
              reproramin: reproramin,
              week: contraint_object.week,
              year: year_data.year
            };
            pcrs.push(pcr);
          }
        });

        /** current pcr */
        const todayData = getTodayWeekWithPdayAndCurrentDay(
          projectState,
          moment(new Date(), 'YYYY-MM-DD')
        );
        let dataOfcurrentWeek = null;
        if (todayData[0]) {
          dataOfcurrentWeek = pcrs.find(
            (el) =>
              parseInt(el.week) === parseInt(todayData[0]) &&
              parseInt(el.year) == moment().year()
          );
          if (dataOfcurrentWeek) {
            const total = dataOfcurrentWeek.total;
            const realized = dataOfcurrentWeek.realized;
            ppc_exist = true;
            if (total) {
              setConstraints(((realized / total) * 100).toFixed(2) + '%');
            } else {
              if (ppc_exist) {
                setConstraints('0' + '%');
              } else {
                setConstraints('No Data');
              }
            }
            setConstraintscount(
              realized + '/' + total + ' ' + t('analictic.constraint')
            );
          }
        }
      });
    }
    if (offsetTZ > 0) {
      labels.pop();
      pcrs.pop();
      commitment_percentajes.pop();
    }
    setConstraintsArrayNew({
      labels: labels,
      pcrs: pcrs,
      commitment_percentajes: commitment_percentajes
    });
    if (ppc_exist) {
    } else {
      setConstraintscount('0' + '/' + '0' + ' ' + t('analictic.constraint'));
      setConstraints('No Data');
    }
  };

  const buildUsersForFilter = () => {
    const tmpUsers = projectSelected.users.map((el) => {
      const label =
        el.name && el.lastname ? `${el.name} ${el.lastname}` : 'no name';
      return {
        ...el,
        label: label,
        value: el.id
        // disabled: true
      };
    });
    const tmpUsersOrdered = tmpUsers.sort(dynamicSort('label'));
    return tmpUsersOrdered;
  };

  const buildScheduleArray = () => {
    const sectorArray = [];
    let filteredSectors;
    const allSectors = projectState.allSectors;

    if (haveAccessOnlyVisibleSchedules) {
      filteredSectors = allSectors.filter((se) => se.visible);
    } else {
      filteredSectors = allSectors;
    }

    filteredSectors.forEach((se) => {
      sectorArray.push({
        label: se.name,
        value: se.id
      });
    });

    return sectorArray;
  };

  const loadDataForFilters = () => {
    const schedulesInFilter = buildScheduleArray();
    setSectorFilter(schedulesInFilter);
    const usersForFilters = buildUsersForFilter();
    setWeekcommitmentsArrayUser(projectSelected.users);
    setAllUsers(usersForFilters);
  };

  /** Effect to load translation to table declaration file */
  useEffect(() => {
    loadDataForFilters();
  }, []);

  useEffect(() => {
    window.Appcues.page();
  });

  function handleChange(value) {
    setSelectedOption(value);
    let ppccar_ = false;
    let pcrcar_ = false;
    let lastdaycar_ = false;
    let secorcar_ = false;
    let ppcchartcar_ = false;
    let schartcar_ = false;
    let schartvelocity_ = false;
    let pcrusers_ = false;
    let cncchartcar_ = false;
    let pcrchartcar_ = false;
    let plansecorcar_ = false;
    let planschartcar_ = false;
    let planschartvelocity_ = false;
    let planpcrchartcar_ = false;
    value.map((v) => {
      if (v.value == 1) {
        ppccar_ = true;
      }
      if (v.value == 2) {
        pcrcar_ = true;
      }
      if (v.value == 3) {
        lastdaycar_ = true;
      }
      if (v.value == 4) {
        secorcar_ = true;
      }
      if (v.value == 5) {
        schartcar_ = true;
      }
      if (v.value == 6) {
        ppcchartcar_ = true;
      }
      if (v.value == 7) {
        pcrchartcar_ = true;
      }
      if (v.value == 8) {
        cncchartcar_ = true;
      }
      if (v.value == 9) {
        schartvelocity_ = true;
      }
      if (v.value == 10) {
        pcrusers_ = true;
      }
      if (v.value == 11) {
        plansecorcar_ = true;
      }
      if (v.value == 12) {
        planschartcar_ = true;
      }
      if (v.value == 13) {
        planschartvelocity_ = true;
      }
      if (v.value == 14) {
        planpcrchartcar_ = true;
      }
    });

    setPpccar(ppccar_);
    setPcrcar(pcrcar_);
    setLastdaycar(lastdaycar_);
    setSectorcar(secorcar_);
    setPpcchart(ppcchartcar_);
    setSchartcar(schartcar_);
    setSchartvelocity(schartvelocity_);
    setPcrUsers(pcrusers_);
    setCncchartcar(cncchartcar_);
    setPcrchart(pcrchartcar_);

    setPlanSectorcar(plansecorcar_);
    setPlanSchartcar(planschartcar_);

    setPlanSchartvelocity(planschartvelocity_);
    setPlanPcrchart(planpcrchartcar_);
  }

  const layouts = { lg: layout };
  const layoutsPanels = { lg: layoutPanelPlan };
  const layoutsPanelsConstraints = { lg: layoutPanelConstraint };
  const layoutsPanelsContinuos = { lg: layoutPanelContinuos };
  useEffect(() => {
    /** get week number */
    curve_recursos_refresh = false;
    setPanelSelected('gen');
    getProyect();
  }, [projectState]);

  /** multi checkbox tree mode */
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
    marginLeft: '5px'
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  /** effect for loading users  */
  useEffect(() => {
    let disabled = false;
    if (loadingUsers) {
      disabled = true;
    }
    const usersForFilters = buildUsersForFilter();
    const newAllUsers = usersForFilters.map((el) => ({
      ...el,
      disabled: disabled
    }));
    setAllUsers(newAllUsers);
  }, [loadingUsers]);

  useEffect(() => {
    if (isTaktAnalyticsDashboard) {
      setPanelSelected('takt');
    }
  }, []);

  async function getProyect() {
    loadDataForFilters();

    const { data: { data: projectData } = {} } = analitycsRq;

    if (!projectData) {
      return;
    }

    const {
      weekcommitments,
      projects: { planification_day },
      allSectors,
      sector,
      master_activitys,
      users,
      cns,
      constraints
    } = projectData;

    const weekcommitmentData = weekcommitments.filter(
      (weekcommitment) => weekcommitment.closed === true
    );

    setPlanificationday(planification_day);

    const productiveSchedules = allSectors?.filter(
      (elem) => elem.productive === true
    );

    setProject({ ...projectData, sectors: productiveSchedules });
    getInitAndLastDay(sector);
    setArrayActivityMaster(master_activitys);
    setWeekcommitments(weekcommitmentData);
    setWeekcommitmentsArrayUser(users);
    getPpcLastWeek(weekcommitments);
    setCnc(cns);
    getPRCArray(constraints, planification_day);
    getCurveData();
    setOptions(optionsChart.gen);
    setSelectedOption(arrOptionsGeneral);

    const schedulesInFilter = buildScheduleArray();
    setSectorFilter(schedulesInFilter);
  }

  const getProyectFilter = async () => {
    if (loading) {
      getCurveData();
    }
    setLoadingFilters(true);
    message.loading(t('applying_filters'), 0);
    const ids = [];
    const idusers = [];
    const weeks = [];
    // let sector_filter = []
    arraySector &&
      arraySector.map((se) => {
        const id = {
          id: se
        };
        ids.push(id);
        // const sector = sectorFilter.find((s)=> s.value == se);
        // sector_filter.push(sector)
      });
    arrayUser &&
      arrayUser.map((us) => {
        const id = {
          id: us
        };
        idusers.push(id);
      });
    dataWeekFilter.map((se) => {
      const week = {
        week: se
      };
      weeks.push(week);
    });

    const rage = dataRangeFilter.map((drf) => ({ date: drf }));

    const data = {
      projectId: projectState.projectSelected,
      ids: ids,
      weeks: weeks,
      range: rage,
      idusers: idusers
    };

    setLoadingUsers(true);
    const project_s = await projectService.showAnaliticByFilter(data);

    setLoadingUsers(false);
    if (project_s) {
      const temp_weekcommitments = project_s.weekcommitments.filter(
        (weekcommitmen) => weekcommitmen.closed == true
      );
      setPlanificationday(project_s.projects.planification_day);
      setProject(project_s);
      getPpcLastWeek(temp_weekcommitments);
      getInitAndLastDay(project_s.sector);
      setArrayActivityMaster(project_s.master_activitys);
      setWeekcommitments(temp_weekcommitments);
      const users = project_s.users.length
        ? project_s.users
        : project_s.usersall;
      const tmpUsers = users.map((el) => {
        const label =
          el.name && el.lastname ? `${el.name} ${el.lastname}` : 'no name';
        return {
          ...el,
          label: label,
          value: el.id
        };
      });
      setWeekcommitmentsArrayUser(tmpUsers);
      setCnc(project_s.cns);
      getPRCArray(project_s.constraints, project_s.projects.planification_day);
    }
    if (arrayUser) {
      const user_filter = [];
      arrayUser &&
        arrayUser.map((us) => {
          const user = allUsers.find((s) => s.value == us);
          user_filter.push(user);
        });
      setFilterTabsUser(user_filter);
    }
    if (arraySector) {
      const sector_filter = [];
      arraySector &&
        arraySector.map((se) => {
          const sector = sectorFilter.find((s) => s.value == se);
          sector_filter.push(sector);
        });
      setFilterTabsSector(sector_filter);
    }
    setDataWeekFilter2(dataWeekFilter);
    setDataRangeFilter2(dataRangeFilter);
    setLoadingFilters(false);
    if (panelSelected != 'gen' || no_gantt_no_baseline == false) {
      message.success(t('filters_applied'), 4);
    }
  };

  function getPRCArray(constraints, pDay) {
    const total_pcr_weeks = [];
    const array_constraint = [];
    // actual_week = moment.week();
    constraints = constraints.filter((c) => c.status != 'notcompromises');
    const constraint_temp = [...constraints];
    constraint_temp.map((constraint_tem) => {
      const control = 0;
      constraint_tem.schedules.map((shdl) => {
        const c = { ...constraint_tem };
        const a = moment(c.commitmentDate.substr(0, 10))
          .format('YYYY-MM-DD')
          .toString();
        const b = moment(shdl.deadline.substr(0, 10))
          .format('YYYY-MM-DD')
          .toString();
        if (a !== b) {
          c.commitmentDate = moment(shdl.deadline.substr(0, 10)).format(
            'YYYY/MM/DD H:MM'
          );
          c.status = 'expired';
          c.reprogramin = true;
          constraints.push(c);
        }
      });
    });
    let planification_day;
    if (pDay === 0) {
      planification_day = -1;
    } else {
      planification_day = pDay - 1;
    }

    constraints.map((constraint) => {
      if (constraint.commitmentDate) {
        const todayData = getTodayWeekWithPdayAndCurrentDay(
          projectState,
          moment(constraint.commitmentDate, 'YYYY-MM-DD')
        );
        if (todayData[0] && todayData[1]) {
          constraint.week = todayData[0];
          constraint.year = todayData[1];
          constraint.group = todayData[0] + '/' + todayData[1];
          if (!total_pcr_weeks.includes(todayData[0])) {
            total_pcr_weeks.push(constraint);
          }
        }
      }
    });
    setTotal_pcr_weeksF(total_pcr_weeks);
    const result_user = constraints.reduce((r, a) => {
      r[a.userId] = r[a.userId] || [];
      r[a.userId].push(a);
      return r;
    }, Object.create(null));
    setConstraintsArrayUser(result_user);
    // reducir por usuarios
    const result = constraints.reduce((r, a) => {
      r[a.year] = r[a.year] || [];
      r[a.year].push(a);
      return r;
    }, Object.create(null));
    try {
      setConstraintsArray(result);
      newGetPcr(planification_day, result);
    } catch (error) {
      console.error(error);
    }
  }

  function getInitAndLastDay(sectors) {
    let end = '';
    let start = '';
    let end_base = '';
    const criteria = projectSelected.activity_creter;
    setTextDaysOfLatter(t('analictic.late'));
    sectors.map((sector) => {
      if (sector.activities.length > 0) {
        if (end != '') {
          if (sector.activities[0].start_date < start) {
            start = sector.activities[0].start_date;
          }
          if (sector.activities[0].end_date > end) {
            end = sector.activities[0].end_date;
          }
          if (sector.activities[0].base_end_date > end_base) {
            // end_base = sector.activities[0].base_end_date
          }
          //
        } else {
          end = sector.activities[0].end_date;
          start = sector.activities[0].start_date;
          // end_base = sector.activities[0].base_end_date;
        }
        if (sector.baseline_versions.length > 0) {
          sector.baseline_versions.map((baseline_version) => {
            if (baseline_version.active) {
              baseline_version.points.map((point) => {
                if (criteria == 'cost') {
                  if (point.cost > 0) {
                    if (end_base == '') {
                      end_base = point.end_date;
                    } else {
                      if (point.end_date > end_base) {
                        end_base = point.end_date;
                      }
                    }
                  }
                } else {
                  if (end_base == '') {
                    end_base = point.end_date;
                  } else {
                    if (point.end_date > end_base) {
                      end_base = point.end_date;
                    }
                  }
                }
              });
            }
          });
        }
      }
    });
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang.indexOf('en') !== -1) {
      moment.locale('en');
    } else {
      moment.locale('es');
    }
    setInitdate(moment(start).format('MMMM DD YYYY'));
    setEndDate(moment(end).format('MMMM DD YYYY'));
    const a = moment(end); // now
    const b = moment(end_base);
    const diff = b.diff(a, 'days');
    if (isNaN(diff)) {
      setEndBaseDate('-');
    } else {
      if (diff > 0) {
        setTextDaysOfLatter(t('analictic.advance'));
      }
      setEndBaseDate(Math.abs(diff));
    }
  }

  function getPpcLastWeek(weekcommitments) {
    let total = 0;
    let realized = 0;
    let ppc_exist = false;
    const todayWeek_ = getTodayWeekWithPday(projectState);
    const year_now = moment().year();
    const todayWeek = todayWeek_ - 1;

    /** weekcommitments come only with last level tasks */
    weekcommitments.map((weekcommitment) => {
      if (
        weekcommitment?.week == todayWeek &&
        weekcommitment?.year == year_now
      ) {
        ppc_exist = true;
        total = total + weekcommitment.taskcommitments?.length;
        /** calculate realized from taskcommitments */
        weekcommitment.taskcommitments.map((el) => {
          if (el.realized_percentaje >= el.commitment_percentaje) {
            realized++;
          }
        });
      }

      if (todayWeek_ == 1) {
        if (weekcommitment.week == 53) {
          ppc_exist = true;
          total = total + weekcommitment.taskcommitments.length;
          weekcommitment.taskcommitments.map((el) => {
            if (el.realized_percentaje >= el.commitment_percentaje) {
              realized++;
            }
          });
        }
      }
    });

    setLastweekcommitmentstask(
      realized + '/' + total + ' ' + t('analictic.tasks')
    );
    const commitment_percentaje = (realized / total) * 100;
    if (total > 0) {
      setLastweekcommitments(commitment_percentaje.toFixed(2) + '%');
    } else {
      if (ppc_exist) {
        setLastweekcommitments('0' + '%');
      } else {
        setLastweekcommitments('No Data');
      }
    }
  }

  useEffect(() => {
    setFirstLoad(true);
    if (loading) {
      getCurveData();
    }
  }, [projectState.projectSelected]);

  async function getResourceCurve() {
    const allSectorsIds = projectState.allSectors.map((sector) => sector.id);
    let dataCurve;
    if (arraySector.length > 0) {
      dataCurve = await analyticServiceResourse.resource_curve({
        sector_id: arraySector,
        date_of_filter: dataRangeFilter,
        resource_id: []
      });
    } else {
      dataCurve = await analyticServiceResourse.resource_curve({
        sector_id: allSectorsIds,
        date_of_filter: dataRangeFilter,
        resource_id: []
      });
    }
    if (dataCurve) {
      setResponseResourceCurve(dataCurve);
    }
    setResourceLoading(true);
  }

  let no_gantt_no_baseline = true;
  const getCurveData = async () => {
    if (isTaktAnalyticsDashboard) return;

    const allSectorsIds = [];
    projectState.allSectors.map((sector) => {
      if (sector.productive) allSectorsIds.push(sector.id);
    });
    let dataCurve;
    if (arraySector?.length > 0) {
      dataCurve = await analyticService.s_curve({
        sector_id: arraySector,
        date_of_filter: [],
        resource_id: []
      });
    } else {
      dataCurve = await analyticService.s_curve({
        sector_id: allSectorsIds,
        date_of_filter: [],
        resource_id: []
      });
    }
    if (dataCurve) {
      setResponseCurve(dataCurve);
    } else {
      no_gantt_no_baseline = false;
    }
    setLoading(true);
  };

  useEffect(() => {
    /** get week number */
    if (!filterVisibility) {
      setFilterSectorVisibility(false);
    }
  }, [filterVisibility]);

  useEffect(() => {
    /** get week number */
    if (!filterVisibility) {
      setFilterUserVisibility(false);
    }
  }, [filterVisibility]);

  const handleFilterDropdown = (val) => {
    setFilterVisibility(val);
  };
  const handleFilterPopOver = (val) => {
    setFilterSectorVisibility(val);
  };

  const handleFilterPopOverUser = (val) => {
    setFilterUserVisibility(val);
  };

  const handleSelectSector = () => {};

  const handleSelectUser = () => {};

  function onChangeSectorCheck(checkedValues) {
    setArraySector(checkedValues);
  }

  function onChangeUserCheck(checkedValues) {
    setArrayUser(checkedValues);
  }

  const middleDatesChange = (a, b) => {
    !b[0] ? setDataRangeFilter([]) : setDataRangeFilter(b);
    const array_week_push = [];
    const init_date = moment(b[0]);
    const end_date = moment(b[1]);

    let validate = true;
    while (validate) {
      if (init_date < end_date) {
        const todayData = getTodayWeekWithPdayAndCurrentDay(
          projectState,
          init_date
        );
        array_week_push.push(todayData[0]);
      } else {
        validate = false;
      }
      init_date.add(7, 'days');
    }
    const rangeIni = moment(b[0]);
    const rangeEnd = moment(b[1]);
    if (rangeIni.year() == 2020 && rangeEnd.year() == 2021) {
      array_week_push.push(53);
    }
    setDataWeekFilter(array_week_push);
  };

  const handleChangeItem = (value) => {
    setPanelSelected(value);

    switch (value) {
      case 'gen':
        // setOptions(optionsChart.gen)
        setOptions(arrOptionsGeneral);
        setSelectedOption(arrOptionsGeneral);

        setPpccar(true);
        setPcrcar(true);
        setLastdaycar(true);
        setSectorcar(true);
        setSchartcar(true);
        setPpcchart(true);
        setCncchartcar(true);
        setPcrchart(true);

        break;
      case 'plan':
        setOptions(optionsChart.plan);
        setSelectedOption(optionsChart.plan);

        setPlanSectorcar(true);
        setPlanSchartcar(true);
        setPlanSchartvelocity(true);
        setPlanPcrchart(true);

        break;
      case 'constraint':
        setOptions(optionsChart.constraint);
        setSelectedOption(optionsChart.constraint);

        setPcrchart(true);
        setPcrUsers(true);

        break;
      case 'continuous':
        setOptions(optionsChart.continuous);
        setSelectedOption(optionsChart.continuous);

        setCncchartcar(true);

        break;
      default:
        console.log('');
    }
  };

  const analyticCustomPanel = analitycPanels(t).filter((f) => {
    const isLangEn = navigatorLang.includes('en');
    const noRequireResources = !stateCompany?.currentCompany?.require_resources;
    const isNotCompany29 = stateCompany?.currentCompany?.id !== 29;

    return (
      !(isLangEn && f.value === 'takt') &&
      !(noRequireResources && f.value === 'resource') &&
      !(isNotCompany29 && f.value === 'productivity')
    );
  });

  const [typeImgState, settypeImgState] = useState('');

  const onImgLoad = ({ target: img }) => {
    const { offsetHeight, offsetWidth } = img;
    let typeImg = 'height-css';
    if (offsetWidth > offsetHeight) {
      typeImg = 'width-css';
    }
    settypeImgState(typeImg);
  };

  const renderHeader = () => (
    <div className="car react-grid-item-for-over" key="headerTitle">
      <div className="cont-img-analitycs">
        <img
          onLoad={onImgLoad}
          className={'img_project_general ' + typeImgState}
          src={
            stateCompany
              ? stateCompany.currentCompany.image
                ? stateCompany.currentCompany.image
                : basid_iamge
              : basid_iamge
          }
          alt="Logo"
        />
      </div>
      <div className="text_title_container">
        <h1 className="text_title_container_h1">
          {project ? project.projects.name : ''}
        </h1>
        <div className="data_proyect">
          <div className="data_proyect_container_first">
            <IconComponent
              data={helmet}
              width={15}
              fill="#2C3421"
              className="iconCompany"
            />
            <p1 className="text_title_container_place">
              {project ? project.user_count.count : ''}
            </p1>
          </div>
          <div className="data_proyect_container">
            <IconComponent
              data={dollar}
              width={15}
              fill="#2C3421"
              className="iconCompany"
            />
            <p1 className="text_title_container_place">
              {project
                ? navigatorLang.includes('en')
                  ? formatMoney(
                      project.projects.budget,
                      project.projects.currency,
                      2,
                      '.',
                      ','
                    )
                  : formatMoney(
                      project.projects.budget,
                      project.projects.currency
                    )
                : ''}
            </p1>
          </div>
          <div className="data_proyect_container">
            {/* <IconComponent className="icon-img" data={dollar} width={15} fill="#2C3421" className="iconCompany" /> */}
            <p1 className="text_title_container_place">
              {project
                ? navigatorLang.includes('en')
                  ? formatMoney(project.projects.size, '', 2, '.', ',') +
                    ' ' +
                    project.projects.sizetype
                  : formatMoney(project.projects.size, '') +
                    ' ' +
                    project.projects.sizetype
                : ''}
            </p1>
          </div>
          <div className="data_proyect_container">
            <ReactMultiSelectCheckboxes
              getDropdownButtonLabel={() => (
                <div
                  style={{ color: '#121212', fontSize: '12px' }}
                  onClick={() => {
                    setTimeout(() => {
                      const a = document.getElementsByClassName(
                        'css-1pcexqc-container'
                      );
                      a[0].parentNode.style.marginTop = '0px';
                    }, 20);
                  }}>
                  <IconComponent
                    data={columns}
                    fill="#53C255"
                    className="svg-icon-columns"
                  />
                  <span>{t('charts_label')}</span>
                </div>
              )}
              options={options}
              value={selectedOption}
              placeholderButtonLabel="Columns"
              onChange={handleChange}
              formatGroupLabel={formatGroupLabel}
            />
          </div>
        </div>
      </div>

      <div className="filter_contect">
        <Row className="lookahead-filter-header">
          <Col span={5} style={{ width: '60%' }}>
            <Col style={{ height: '100%', textAlign: 'center' }} span={3}>
              <Dropdown
                placement="topLeft"
                overlay={
                  <Menu className="filter_menu">
                    <Menu.Item className="filter_menu_item">
                      <Popover
                        placement="rightBottom"
                        id="sector"
                        content={
                          <div className="sector_check_filter filter_analitycs panel-filters-container">
                            <Checkbox.Group
                              options={sectorFilter}
                              onChange={onChangeSectorCheck}
                              value={arraySector}
                              className="scroll-content"></Checkbox.Group>
                            <Button
                              type="primary"
                              className="boton_filter"
                              size="small"
                              onClick={() => getProyectFilter()}
                              key="submit"
                              disabled={loadingFilters}>
                              {t('analitic.apply_filter')}
                            </Button>
                          </div>
                        }
                        trigger={['click']}
                        visible={filterSectorVisibility}
                        onVisibleChange={handleFilterPopOver}>
                        <p
                          onClick={handleSelectSector}
                          className="filter_menu_text">
                          {t('settings.project_form.sectors.title')}
                        </p>
                      </Popover>
                    </Menu.Item>
                    <Menu.Item className="filter_menu_item">
                      <Popover
                        content={
                          <div
                            style={{ zIndex: 3, position: 'relative' }}
                            className="filter_date_analitycs panel-filters-container">
                            <RangePicker
                              onChange={middleDatesChange}
                              defaultValue={
                                !dataRangeFilter[0]
                                  ? ''
                                  : [
                                      moment(dataRangeFilter[0], 'YYYY/MM/DD'),
                                      moment(dataRangeFilter[1], 'YYYY/MM/DD')
                                    ]
                              }
                            />
                            <Button
                              type="primary"
                              size="small"
                              className="boton_filter"
                              onClick={() => getProyectFilter()}
                              key="submit"
                              disabled={loadingFilters}>
                              {t('analitic.apply_filter')}
                            </Button>
                          </div>
                        }
                        placement="right"
                        trigger="click">
                        <p className="filter_menu_text">
                          {t('lookahead_plan.dates')}
                        </p>
                      </Popover>
                    </Menu.Item>
                    <Menu.Item className="filter_menu_item">
                      <Popover
                        placement="rightBottom"
                        id="responsible"
                        content={
                          <div className="sector_check_filter filter_analitycs panel-filters-container">
                            <Checkbox.Group
                              options={allUsers}
                              onChange={onChangeUserCheck}
                              value={arrayUser}
                              className="scroll-content"></Checkbox.Group>
                            <Button
                              type="primary"
                              size="small"
                              className="boton_filter"
                              onClick={() => getProyectFilter()}
                              key="submit"
                              disabled={loadingFilters}>
                              {t('analitic.apply_filter')}
                            </Button>
                          </div>
                        }
                        trigger={['click']}
                        visible={filterUserVisibility}
                        onVisibleChange={handleFilterPopOverUser}>
                        <p
                          onClick={handleSelectUser}
                          className="filter_menu_text">
                          {t('tables.lookahead.plan.responsables')}
                        </p>
                      </Popover>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                visible={filterVisibility}
                onVisibleChange={handleFilterDropdown}>
                <div
                  className="filter_buton"
                  style={{ cursor: 'pointer', fontSize: '12px' }}>
                  {backgroundColorFilter.show ? (
                    <span style={backgroundColorFilter.style}></span>
                  ) : null}
                  {backgroundColorFilter.show ? (
                    <span className="counter-selected-filters">
                      {backgroundColorFilter.counter}
                    </span>
                  ) : null}
                  <IconComponent
                    data={filter}
                    fill="#53C255"
                    className="svg-icon-filter"
                  />
                  <span>{t('filters_label.filters_label')}</span>
                </div>
              </Dropdown>
            </Col>
            <Col
              style={{
                height: '30px',
                marginLeft: '100px',
                overflow: 'auto',
                borderRadius: '2pt'
              }}
              className={
                dataWeekFilter2.length > 0 ||
                filterTabsSector > [] ||
                filterTabsUser > []
                  ? 'filter_tags'
                  : 'filter_0tags'
              }>
              {filterTabsSector?.map((ft) => (
                <Tag key={ft.value}>{ft.label}</Tag>
              ))}
              {dataWeekFilter2.length > 0 &&
              dataRangeFilter2[0] &&
              dataRangeFilter2[1] ? (
                <Tag>
                  {moment(dataRangeFilter2[0]).format('DD/MM/YYYY') +
                    '-' +
                    moment(dataRangeFilter2[1]).format('DD/MM/YYYY')}
                </Tag>
              ) : null}
              {filterTabsUser?.map((ft) => (
                <Tag key={ft.value}>{ft.label}</Tag>
              ))}
            </Col>
          </Col>
          {!isTaktAnalyticsDashboard && (
            <Col>
              <div className="panel-button-container">
                <Dropdown
                  type="primary"
                  overlayClassName="general-style"
                  overlay={
                    <Menu
                    // overlayClassName="general-style"
                    >
                      {analyticCustomPanel.map((el, i) => (
                        <Menu.Item key={i}>
                          <a
                            data-testid={TestIds.ANALYTIC_OPTION + '_' + i}
                            target=""
                            rel="noopener noreferrer"
                            onClick={() => {
                              handleChangeItem(el.value);
                            }}>
                            {el.label}
                          </a>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  placement="bottomCenter">
                  <Button
                    data-testid={TestIds.DROPDOWN_ANALYTIC_SELECTOR}
                    type="primary"
                    className="boton">
                    {
                      analitycPanels(t).find((el) => el.value === panelSelected)
                        .label
                    }
                  </Button>
                </Dropdown>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );

  const renderTakt = () => (
    <div className="wrapper-component">
      <TaktAnalytics header={renderHeader} t={t} />
    </div>
  );

  const renderGeneral = () => (
    <div className="wrapper-component">
      <ResponsiveGridLayoutGen
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1920, md: 1250, sm: 1000, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 6, xs: 3, xxs: 3 }}
        rowHeight={37}
        width={1820}
        autoSize="true">
        {renderHeader()}
        <div
          className="car"
          key="ppc"
          style={{
            visibility: ppccar && panelSelected === 'gen' ? 'visible' : 'hidden'
          }}>
          {permissions.ppc == 'AC' || permissions.ppc == 'V' ? (
            <Card
              t={t}
              position={0}
              title={'PPC'}
              data={lastweekcommitments}
              planificationDay={planificationDay}
              subtitle={lastweekcommitmentstask}
              chart={weekcommitments}
            />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>
        <div
          className="car"
          key="pcr"
          style={{
            visibility: pcrcar && panelSelected === 'gen' ? 'visible' : 'hidden'
          }}>
          {permissions.pcr == 'AC' || permissions.pcr == 'V' ? (
            <Card
              t={t}
              position={1}
              title={t('lang') === 'es' ? 'PCR' : 'PRR'}
              data={constraints}
              planificationDay={planificationDay}
              subtitle={constraintscount}
              chart={constraintsArray}
              chartNew={constraintsArrayNew}
            />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>
        <div
          className="car"
          key="days_late"
          style={{
            visibility:
              lastdaycar && panelSelected === 'gen' ? 'visible' : 'hidden'
          }}>
          {permissions.overdue == 'AC' || permissions.overdue == 'V' ? (
            <Card
              t={t}
              position={2}
              title={textDaysOfLatter}
              data={endBaseDate}
              subtitle={' ' + endDate}
            />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>
        <div className="car" key="c" style={{ visibility: 'visible' }}>
          <div className="img_project_general_galary_contenct">
            <img
              className="img_project_general_galary"
              src={
                project
                  ? project.projects.image
                    ? project.projects.image
                    : basid_iamge
                  : basid_iamge
              }
              alt="Logo"
            />
          </div>
        </div>

        <div
          className="car"
          key="sector"
          style={{
            visibility:
              secorcar && panelSelected === 'gen' ? 'visible' : 'hidden'
          }}>
          {permissions.sectoradvancement == 'AC' ||
          permissions.sectoradvancement == 'V' ? (
            <Sector
              t={t}
              arrayActivityMaster={arrayActivityMaster}
              sectors={project ? project.sector : []}
            />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>
        <div
          className="car"
          key="curve_s"
          style={{
            visibility:
              schartcar && panelSelected === 'gen' ? 'visible' : 'hidden'
          }}>
          {permissions.scurve == 'AC' || permissions.scurve == 'V' ? (
            <CurveS
              t={t}
              dataCurve={responseCurve}
              filterDates={dataRangeFilter}
            />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>
        <div
          className="car"
          key="curve_prc"
          style={{
            visibility:
              ppcchartcar && panelSelected === 'gen' ? 'visible' : 'hidden'
          }}>
          {permissions.ppc == 'AC' || permissions.ppc == 'V' ? (
            <CurvePpc
              t={t}
              planificationDay={planificationDay}
              data={weekcommitments}
              stateCompany={stateCompany}
              projectState={projectState}
              sectorFilter={arraySector}
            />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>
        <div
          className="car"
          key="curve_cnc"
          style={{
            visibility:
              cncchartcar && panelSelected === 'gen' ? 'visible' : 'hidden'
          }}>
          {permissions.cnc == 'AC' || permissions.cnc == 'V' ? (
            <Cnc t={t} data={cnc} />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>
        <div
          className="car"
          key="curve_activity_line"
          style={{
            visibility:
              pcrchartcar && panelSelected === 'gen' ? 'visible' : 'hidden'
          }}>
          {permissions.pcr == 'AC' || permissions.pcr == 'V' ? (
            <CurvePCR
              t={t}
              planificationDay={planificationDay}
              data={constraintsArray}
              dataConstraints={constraintsArrayNew}
            />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>
        <br />
      </ResponsiveGridLayoutGen>
    </div>
  );

  const renderPlan = () => (
    <div className="wrapper-component">
      <ResponsiveGridLayoutPlan
        layouts={layoutsPanels}
        breakpoints={{ lg: 1920, md: 1250, sm: 1000, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 6, xs: 3, xxs: 3 }}
        rowHeight={46}
        width={1000}
        className="layout">
        {renderHeader()}

        <div
          className="car"
          key="sector"
          style={{
            visibility:
              plansecorcar && panelSelected === 'plan' ? 'visible' : 'hidden'
          }}>
          {permissions.sectoradvancement == 'AC' ||
          permissions.sectoradvancement == 'V' ? (
            <Sector
              arrayActivityMaster={arrayActivityMaster}
              t={t}
              sectors={project ? project.sector : []}
            />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>

        <div
          className="car"
          key="days_behind"
          style={{
            visibility:
              planschartcar && panelSelected === 'plan' ? 'visible' : 'hidden'
          }}>
          {permissions.scurve == 'AC' || permissions.scurve == 'V' ? (
            <DaysBehindSchedule
              t={t}
              dataCurve={responseCurve}
              filterDates={dataRangeFilter}
            />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>

        <div
          className="car"
          key="velocity_curve"
          style={{
            visibility:
              planschartvelocity && panelSelected === 'plan'
                ? 'visible'
                : 'hidden'
          }}>
          {permissions.scurve == 'AC' || permissions.scurve == 'V' ? (
            <VelocityCurve t={t} dataCurve={responseCurve} />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>

        <div
          className="car"
          key="curve_pcc_user"
          style={{
            visibility:
              planpcrchartcar && panelSelected === 'plan' ? 'visible' : 'hidden'
          }}>
          {permissions.pcr == 'AC' || permissions.pcr == 'V' ? (
            <PccForUser
              t={t}
              planificationDay={planificationDay}
              constraintsArrayUser={weekcommitmentsArrayUser}
              allUsers={allUsers}
              projectSelected={projectState.projectSelected}
              total_pcr_weeksF={weekcommitments}
            />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>
        <br />
      </ResponsiveGridLayoutPlan>
    </div>
  );

  const renderConstraint = () => (
    <div className="wrapper-component">
      <ResponsiveGridLayoutConstraint
        className="layout"
        layouts={layoutsPanelsConstraints}
        breakpoints={{ lg: 1920, md: 1250, sm: 1000, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 6, xs: 3, xxs: 3 }}
        rowHeight={46}
        width={1820}>
        {renderHeader()}

        <div
          className="car"
          key="curve_activity_line"
          style={{
            visibility:
              pcrchartcar && panelSelected === 'constraint'
                ? 'visible'
                : 'hidden'
          }}>
          {permissions.pcr == 'AC' || permissions.pcr == 'V' ? (
            <CurvePCR
              t={t}
              planificationDay={planificationDay}
              data={constraintsArray}
              dataConstraints={constraintsArrayNew}
            />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>

        <div
          className="car"
          key="curve_pcr_user"
          style={{
            visibility:
              pcrusers && panelSelected === 'constraint' ? 'visible' : 'hidden'
          }}>
          {permissions.pcr == 'AC' || permissions.pcr == 'V' ? (
            <PcrForUser
              t={t}
              planificationDay={planificationDay}
              constraintsArrayUser={constraintsArrayUser}
              projectSelected={projectState.projectSelected}
              total_pcr_weeksF={total_pcr_weeksF}
              users={weekcommitmentsArrayUser}
            />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>
      </ResponsiveGridLayoutConstraint>
    </div>
  );

  const renderContinuos = () => (
    <div className="wrapper-component">
      <ResponsiveGridLayoutContinuos
        className="layout"
        layouts={layoutsPanelsContinuos}
        breakpoints={{ lg: 1920, md: 1250, sm: 1000, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 6, xs: 3, xxs: 3 }}
        rowHeight={46}
        width={1820}>
        {renderHeader()}
        <div
          className="car"
          key="curve_cnc"
          style={{
            visibility:
              cncchartcar && panelSelected === 'continuous'
                ? 'visible'
                : 'hidden'
          }}>
          {permissions.cnc == 'AC' || permissions.cnc == 'V' ? (
            <Cnc t={t} data={cnc} />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>
      </ResponsiveGridLayoutContinuos>
    </div>
  );

  const renderResource = () => (
    <div className="wrapper-component">
      <ResponsiveGridLayoutContinuos
        className="layout"
        layouts={layoutsPanels}
        breakpoints={{ lg: 1920, md: 1250, sm: 1000, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 6, xs: 3, xxs: 3 }}
        rowHeight={46}
        width={1000}>
        {renderHeader()}
        <div
          className="car"
          key="resource_matrix"
          style={{
            visibility:
              schartcar && panelSelected === 'resource' ? 'visible' : 'hidden'
          }}>
          {permissions.scurve == 'AC' || permissions.scurve == 'V' ? (
            <MatrixOfResources t={t} />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>
        <div
          className="car"
          key="resource_curve"
          style={{
            visibility:
              schartcar && panelSelected === 'resource' ? 'visible' : 'hidden'
          }}>
          {permissions.scurve == 'AC' || permissions.scurve == 'V' ? (
            <ResourceCurve firstLoad={firstLoad} t={t} />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>
        <div
          className="car"
          key="productivity_chart"
          style={{
            visibility:
              schartcar && panelSelected === 'resource' ? 'visible' : 'hidden'
          }}>
          {permissions.scurve == 'AC' || permissions.scurve == 'V' ? (
            <ProductivityChart firstLoad={firstLoad} t={t} />
          ) : t('lang') === 'es' ? (
            <div> No tienes acceso a este gráfico. </div>
          ) : (
            <div> You don't have access to this graph. </div>
          )}
        </div>
      </ResponsiveGridLayoutContinuos>
    </div>
  );

  const renderProductivity = () => (
    <div className="wrapper-component">
      <ProductivityAnalytics header={renderHeader} t={t} />
    </div>
  );

  const renderPanel = () => {
    switch (panelSelected) {
      case 'gen':
        return renderGeneral();
      case 'plan':
        return renderPlan();
      case 'constraint':
        return renderConstraint();
      case 'continuous':
        return renderContinuos();
      case 'takt':
        return renderTakt();
      case 'resource':
        return renderResource();
      case 'productivity':
        return renderProductivity();
      default:
        return null;
    }
  };

  return renderPanel();
}

export default withTranslation()(General);
