import setGeneralFilter from './generalFilter';
import setColumnsFilter from './columnsFilter';
import setOrderFilter from './orderFilter';
import setRangeFilter from './rangeFilter';
import setScaleVisualizaTionFilter from './scaleVisualization';
import setVisualizationAndColorsFilter from './visualizationAndColorsFilter';
import setCollapsedFilter from './collapsedFilter';

export {
  setGeneralFilter,
  setColumnsFilter,
  setOrderFilter,
  setRangeFilter,
  setScaleVisualizaTionFilter,
  setVisualizationAndColorsFilter,
  setCollapsedFilter
};
