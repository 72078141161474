/* eslint-disable prefer-const */

/** React components  */
import React from 'react';

/** import elements from library Antd Framework */
import { Layout } from 'antd';

/** import icons from antd framework */
import { LeftOutlined } from '@ant-design/icons';

import { useHistory } from 'react-router-dom';
import './Footer.css';
const { Footer } = Layout;

export default function FooterRet(props) {
  let history = useHistory();
  // render
  return props.options.visible ? (
    <Footer>
      <div
        className="backArrowWide"
        style={{ height: '20px', backgroundColor: 'white' }}>
        <LeftOutlined
          style={{ fontSize: 25, fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => {
            history.goBack();
          }}
        />
        <label
          className="label-btn-navigator"
          onClick={() => {
            history.goBack();
          }}>
          Back
        </label>
      </div>
    </Footer>
  ) : null;
}
