export const SET_LOOKAHEAD_ACTIVATED_RESOURCE_COLS =
  'SET_LOOKAHEAD_ACTIVATED_RESOURCE_COLS';
export const SET_LOOKAHEAD_RESOURCE_RANGE_FILTERED =
  'SET_LOOKAHEAD_RESOURCE_RANGE_FILTERED';
export const NOTIFY_LOOKAHEAD_UPDATE_RESOURCE =
  'NOTIFY_LOOKAHEAD_UPDATE_RESOURCE';
export const NOTIFY_LOOKAHEAD_UPDATE_ORDER_RESOURCE =
  'NOTIFY_LOOKAHEAD_UPDATE_ORDER_RESOURCE';
export const NOTIFY_LOOKAHEAD_UPDATE_FILTER_RESOURCE =
  'NOTIFY_LOOKAHEAD_UPDATE_FILTER_RESOURCE';
export const NOTIFY_LOOKAHEAD_UPDATE_GROUP_RESOURCE =
  'NOTIFY_LOOKAHEAD_UPDATE_GROUP_RESOURCE';

export const lookaheadActions = {
  setActivatedColumns: (activatedColumns) => ({
    type: SET_LOOKAHEAD_ACTIVATED_RESOURCE_COLS,
    payload: activatedColumns
  }),
  setIsRangeFiltered: (obj) => ({
    type: SET_LOOKAHEAD_RESOURCE_RANGE_FILTERED,
    payload: { boolean: obj.boolean, dateRange: obj.range }
  }),
  notifyLookaheadUpdate: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_RESOURCE,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateOrder: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_ORDER_RESOURCE,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateFilter: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_FILTER_RESOURCE,
    payload: { boolean: true }
  }),
  notifyLookaheadUpdateGroup: () => ({
    type: NOTIFY_LOOKAHEAD_UPDATE_GROUP_RESOURCE,
    payload: { boolean: true }
  })
};
