/* eslint-disable no-useless-escape */

/** React components  */
import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/**  import library for animations effects */
import { Animated } from 'react-animated-css';

/** import elements from library Antd */
import { Icon, Button, Select, Input, Row, Col, Upload } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

/** import library for format numbers */
import NumberFormat from 'react-number-format';

/** import components */
import ErrorValidationLabel from '../../../components/Login/ValidationLabel';
import { withTranslation } from 'react-i18next';

/** import common functions from utils */
import {
  checkAllFieldsValid,
  reduceFormValues,
  Types,
  setTitleWide,
  generateFormObjectSelect,
  codeCountries,
  projectSizesTypes,
  projectCurrency,
  ObjectLogoSvg,
  getBase64,
  headersSendForm
} from '../../../utils';
import {
  defaultConstraintTypes,
  defaultCncTypes,
  defaultSubContracts
} from '../../../../src/components/Projects/FormProjectsDummy/dummyData';
/** import common functions from helper */
import {
  stateTemplate,
  createProject,
  getUser,
  asignProjectUser,
  asignProjectSubcontract
} from '../project.helper';

/** import library for antd upload crop */
import ImgCrop from 'antd-img-crop';

/** import base contant (setting urls)  */
import { base } from '../../../services/base';
import {
  cncTypeService,
  companyService,
  constraintTypeService,
  subContractService
} from '../../../services';
/** constants */
const { Option } = Select;

function ProjectConfirmationView(props) {
  /** hooks */
  const [state, setState] = useState(stateTemplate); // validation
  const { token } = useParams(); // handle params
  const { t } = props; // handle props

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    setTitleWide('project');
    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  /** component logic */
  const onSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formValues = reduceFormValues(form.elements, state);

    // add selects to validation
    formValues.ptype = generateFormObjectSelect(
      'ptype',
      'Type',
      state.ptype.value
    );
    formValues.country = generateFormObjectSelect(
      'country',
      'Country',
      state.country.value
    );
    formValues.currency = generateFormObjectSelect(
      'currency',
      'Currency',
      state.pcurrency.value
    );
    formValues.psizetype = generateFormObjectSelect(
      'psizetype',
      'Type Size',
      state.psizetype.value
    );

    const allFieldsValid = checkAllFieldsValid(formValues);
    setState({ ...state, ...formValues, allFieldsValid });
    if (allFieldsValid) {
      setState({
        ...state,
        isLoading: true
      });
      const user = await getUser(token);
      if (user.id) {
        const budget = formValues.pbudget.value.replace(/\./g, '');
        const size = formValues.psize.value
          .replace(/\./g, '')
          .replace(/\,/g, '.');
        const companyId = user.companyId;
        const data = {
          name: state.pname.value,
          country: state.country.value,
          companyId: companyId,
          type: state.ptype.value,
          currency: state.pcurrency.value,
          budget: budget,
          sizetype: state.psizetype.value,
          size: size,
          stage: 'started',
          planification_day: 1 /** monday by default */
        };
        /** save image uploaded */
        data.image = state.stateUpload.name ? state.stateUpload.name : null;
        const projectCreated = await createProject(data);
        const companyCreated = await companyService.show(companyId);
        // asign user to project
        if (projectCreated.id) {
          const asyncMapDefaultConstraintTypes = defaultConstraintTypes(
            props.t
          ).map(async (constraintType) => {
            await constraintTypeService.create({
              ...constraintType,
              projectId: projectCreated.id,
              description: '' /** is required */
            });
          });
          await Promise.all(asyncMapDefaultConstraintTypes);
          /** add cnc types */
          const asyncMapDefaultCncTypes = defaultCncTypes(props.t).map(
            async (cncType) => {
              await cncTypeService.create({
                ...cncType,
                projectId: projectCreated.id,
                description: '' /** is required */
              });
            }
          );
          await Promise.all(asyncMapDefaultCncTypes);
          defaultSubContracts.map(async (sc) => {
            const subcontract = await subContractService.create({
              ...sc,
              companyId: companyId,
              name: companyCreated.company.name,
              image: companyCreated.company.image
            });
            /** assign */
            if (subcontract.id) {
              await asignProjectSubcontract({
                subcontractId: subcontract.id,
                projectId: projectCreated.id
              });
            }
          });
          await asignProjectUser({
            userId: user.id,
            projectId: projectCreated.id,
            isCreator: true
          });
          setState({
            ...state,
            isLoading: false
          });
          localStorage.setItem(
            'projectCreated',
            JSON.stringify(projectCreated)
          );
          props.history.push(`/schedules/${token}`);
          return false;
        }
        setState({
          ...state,
          isLoading: false
        });
      }
    }
  };

  /** validation */
  const { pname, ptype, psizetype, psize, country, pbudget } = state;

  const renderPnameValidationError = pname.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={pname.typeMismatch ? pname.formatErrorTxt : pname.requiredTxt}
    />
  );

  const renderPtypeValidationError = ptype.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={ptype.requiredTxt}
    />
  );

  const renderCountryValidationError = country.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={
        country.typeMismatch ? country.formatErrorTxt : country.requiredTxt
      }
    />
  );

  const renderPsizetypeValidationError = psizetype.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left"
      txtLbl={psizetype.requiredTxt}
    />
  );

  const renderPsizeValidationError = psize.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left ta-lblErrorFixNumber"
      txtLbl={psize.requiredTxt}
    />
  );

  const renderPbudgetValidationError = pbudget.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left ta-lblErrorFixNumber fixLblErrorSplitCol"
      txtLbl={pbudget.requiredTxt}
    />
  );

  /** component Logo by default */
  const ProjectIcon = (props) => <Icon component={ObjectLogoSvg} {...props} />;

  /** this function is triggered when upload image */
  const onChangeUpload = (info) => {
    if (info.file.status === 'uploading') {
      setState({
        ...state,
        stateUpload: { loading: true }
      });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response
      getBase64(info.file.originFileObj, (imageUrl) =>
        setState({
          ...state,
          stateUpload: {
            imageUrl,
            name: info.file.response.name,
            loading: false
          }
        })
      );
    }
  };

  /** component upload button  */
  const uploadButton = (
    <div>
      {state.stateUpload && state.stateUpload.loading ? (
        <LoadingOutlined />
      ) : (
        <ProjectIcon className="FrmCompany" />
      )}
    </div>
  );

  /** render */
  return (
    <Animated
      className="animatedWide"
      animationIn="fadeIn"
      animationInDuration={500}
      isVisible={true}>
      <div className="divWide">
        <div className="divSection">
          <Fragment>
            <form
              className="frmWide"
              onSubmit={onSubmit}
              noValidate
              id="frmProjectConfirmation">
              <Row>
                <Col span={24}>
                  <ImgCrop rotate shape="rect" aspect={5 / 3} modalWidth={850}>
                    <Upload
                      action={`${base.api}projects/upload`}
                      listType="picture-card"
                      showUploadList={false}
                      headers={headersSendForm}
                      onChange={onChangeUpload}>
                      {state.stateUpload && state.stateUpload.imageUrl ? (
                        <img
                          className="imgLogoProject"
                          src={state.stateUpload.imageUrl}
                          alt="logo project"
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </ImgCrop>
                </Col>
              </Row>

              <Row className="">
                <Col span={11}>
                  <Input
                    className="w100"
                    type="text"
                    name="pname"
                    placeholder="Project Name"
                    autoComplete="off"
                    onChange={(e) => {
                      /** update state with value selected */
                      setState({
                        ...state,
                        pname: {
                          ...state.pname,
                          value: e.target.value,
                          valid: true
                        }
                      });
                    }}
                    required
                  />
                  {renderPnameValidationError}
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <Select
                    type="select"
                    name="ptype"
                    placeholder="Project Type"
                    value={state.ptype.value}
                    onChange={(e) => {
                      /** update state with value selected */
                      setState({
                        ...state,
                        ptype: {
                          ...state.ptype,
                          value: e,
                          valid: true
                        }
                      });
                    }}>
                    {Types.map(({ value, label, trad }) => (
                      <Option key={value} value={value}>
                        {t(`settings.project_form.${trad}`)}
                      </Option>
                    ))}
                  </Select>
                  {renderPtypeValidationError}
                </Col>
              </Row>

              <Row className="mt-10">
                <Col span={11}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    name="country"
                    placeholder="Country"
                    value={state.country.value}
                    onChange={(e) =>
                      setState({
                        ...state,
                        country: {
                          ...state.country,
                          value: e,
                          valid: true
                        }
                      })
                    }>
                    {codeCountries.map(({ code, name }, index) => (
                      <Option key={code} value={code}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                  {renderCountryValidationError}
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <Select
                    className="w30 fixCurrency"
                    placeholder="USS"
                    name="currency"
                    value={state.pcurrency.value}
                    onChange={(e) =>
                      setState({
                        ...state,
                        pcurrency: {
                          ...state.pcurrency,
                          value: e,
                          valid: true
                        }
                      })
                    }
                    dropdownClassName="currencySelect">
                    {projectCurrency(t).map(({ value, label }, index) => (
                      <Option key={value} value={value}>
                        {`${value} ${label} `}
                      </Option>
                    ))}
                  </Select>
                  <NumberFormat
                    id="numberformatBudget"
                    name="pbudget"
                    className="fixOnlyNumber"
                    placeholder="Budget"
                    maxLength="12"
                    displayType={'input'}
                    thousandSeparator={t('ĺang') === 'en' ? ',' : '.'}
                    prefix={''}
                    decimalSeparator={false}
                    onChange={(e) =>
                      setState({
                        ...state,
                        pbudget: {
                          ...state.pbudget,
                          value: e,
                          valid: true
                        }
                      })
                    }
                    required
                  />
                  {renderPbudgetValidationError}
                </Col>
              </Row>

              <Row className="mt-10">
                <Col span={11}>
                  <Select
                    className="w100"
                    type="select"
                    name="psizetype"
                    placeholder="Size Type"
                    value={state.psizetype.value}
                    onChange={(e) =>
                      setState({
                        ...state,
                        psizetype: {
                          ...state.psizetype,
                          value: e,
                          valid: true
                        }
                      })
                    }>
                    {projectSizesTypes.map(({ value, label }) => (
                      <Option key={value} value={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                  {renderPsizetypeValidationError}
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <NumberFormat
                    id="numberformatProjectSize"
                    name="psize"
                    className="fixOnlyNumber"
                    placeholder="Size"
                    displayType={'input'}
                    thousandSeparator={t('ĺang') === 'en' ? ',' : '.'}
                    decimalSeparator={t('ĺang') === 'en' ? '.' : ','}
                    prefix={''}
                    decimalScale={2}
                    onChange={(e) =>
                      setState({
                        ...state,
                        psize: {
                          ...state.psize,
                          value: e.target.value,
                          valid: true
                        }
                      })
                    }
                    required
                  />
                  {renderPsizeValidationError}
                </Col>
              </Row>
              <Button htmlType="submit" loading={state.isLoading}>
                {t('new_profile.continue_btn')}
              </Button>
            </form>
          </Fragment>
        </div>
      </div>
    </Animated>
  );
}

export default withTranslation()(ProjectConfirmationView);
