import React from 'react';

import { Animated } from 'react-animated-css';
import { withTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

// Components
import MenuSubmodules from '../../components/MenuSubmodules';
import ProductivityAssign from './assign';
import AssistenceView from './assistance';

const publishSubmodules = ({ history, match, t, toggleActiveButtons }) => [
  {
    id: 1,
    title: t('takt_units.assign_label'),
    routeName: `${match.url}/assign`,
    onClick: (_) => {
      toggleActiveButtons(1);
      history.push(`${match.url}/assign`);
    },
    hide: false
  },
  {
    id: 2,
    title: t('tabs.lookahead.assit'),
    routeName: `${match.url}/assists`,
    onClick: (_) => {
      toggleActiveButtons(2);
      history.push(`${match.url}/assists`);
    },
    hide: false
  }
];

const Productivity = (props) => {
  const match = useRouteMatch();

  return (
    <Animated
      animationIn="fadeIn"
      animationOut="fadeOut"
      animationInDuration={500}
      animationOutDuration={500}
      isVisible={true}>
      <MenuSubmodules
        publishSubmodules={publishSubmodules}
        history={props.history}
      />
      <Switch>
        <Route path={`${match.path}/assign`}>
          <ProductivityAssign />
        </Route>
        <Route path={`${match.path}/assists`}>
          <AssistenceView {...props} />
        </Route>
      </Switch>
    </Animated>
  );
};

export default withTranslation()(Productivity);
