import { addDurationToDate } from '../../helpers';
import { CONSTRAINT_TYPES } from '../../constants/index';
/**
 * Calculates the restriction dates based on the activity constraint type.
 * @param {Object} activity - The activity object containing constraint date and duration.
 * @param {string} constraint - The type of constraint (e.g., 'snet', 'snlt', 'fnlt', 'fnet', 'mso', 'mfo').
 * @param {Object} activityCalendar - The calendar object associated with the activity.
 * @returns {Object} - An object containing the calculated early start (es) and early finish (ef) dates.
 */
function calculateRestriction(activity, constraint, activityCalendar) {
  let restriction = { es: null, ef: null };

  if (!activity) return restriction;

  if ([CONSTRAINT_TYPES.SNET, CONSTRAINT_TYPES.SNLT].includes(constraint)) {
    let constraintDate = new Date(activity.constraint_date);
    restriction.es = constraintDate;
    restriction.ef = addDurationToDate(
      activityCalendar,
      constraintDate,
      activity.duration,
      activity
    );
  } else if (
    [CONSTRAINT_TYPES.FNLT, CONSTRAINT_TYPES.FNET].includes(constraint)
  ) {
    let constraintDate = new Date(activity.constraint_date);

    restriction.es = addDurationToDate(
      activityCalendar,
      constraintDate,
      -activity.duration,
      activity
    );

    restriction.ef = constraintDate;
  } else if (constraint === CONSTRAINT_TYPES.MSO) {
    let constraintDate = new Date(activity.constraint_date);
    restriction.es = constraintDate;
    restriction.ef = addDurationToDate(
      activityCalendar,
      constraintDate,
      activity.duration,
      activity
    );
  } else if (constraint === CONSTRAINT_TYPES.MFO) {
    let constraintDate = new Date(activity.constraint_date);
    restriction.es = addDurationToDate(
      activityCalendar,
      constraintDate,
      -activity.duration,
      activity
    );
    restriction.ef = constraintDate;
  }

  return restriction;
}

export default calculateRestriction;
