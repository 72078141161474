import React, { useEffect, useState } from 'react';
import CustomDropdownSelector from '../../../CustomDropdownSelector';
import RemoveIcon from '../../../../assets/img/gantt/X.png';

import { RENDER_SUB } from '../constants';
import { withTranslation } from 'react-i18next';
import {
  findDeepGetTask,
  updateAsyncTaskGanttV2
} from '../../GanttVisualization.helper';
import { getCompanyTextColor } from '../../../../utils';

const RenderSub = ({
  column,
  lastLevelActivities,
  activities,
  gantt,
  t,
  onlyRead,
  task,
  subs,
  callbackOnClickCreate,
  callbackOnClickRemove,
  callbackOnSelection,
  customMainClassName
}) => {
  const [state, setState] = useState(RENDER_SUB);
  const taskFromLookahead =
    activities && gantt ? findDeepGetTask(activities, 'id', task.id) : null;
  const { subtradeRole } = gantt;
  const isOnlyReadElement =
    onlyRead || task.readonly || (subtradeRole && subtradeRole.isSubtradeRole);
  useEffect(() => {
    setState({
      ...state,
      mainObject: task,
      options: subs
    });
  }, [task, subs]);

  const onSelection = (payload) => {
    if (
      subtradeRole &&
      subtradeRole.subtrade &&
      JSON.stringify(payload.split('-')[0]) !==
        JSON.stringify(subtradeRole.subtrade.id)
    ) {
      validateMutationFromSub();
    }

    setState({
      ...state,
      mainObject: {
        ...state.mainObject,
        subcontractId: payload.split('-')[0]
      }
    });
    callbackOnSelection && callbackOnSelection(payload);
    taskFromLookahead &&
      updateAsyncTaskGanttV2(
        taskFromLookahead,
        column.name,
        payload.split('-')[0],
        gantt
      );
  };

  const callbackRemove = (_) => {
    validateMutationFromSub();
    setState({
      ...state,
      mainObject: {
        ...state.mainObject,
        subcontractId: null
      }
    });
    callbackOnClickRemove && callbackOnClickRemove();
  };

  let isOdd = false;
  if (gantt?.oddColsConfig && task.type === 'activitytask') {
    isOdd = gantt.oddColsConfig.subcontractId;
  }

  const renderSubToSelect = (option) => {
    const { object } = option;
    return (
      <div className="item-gantt">
        <div
          className="subcontract-border cut-text-and-dot"
          style={{
            height: '100%',
            color: `${getCompanyTextColor(object?.color)}`,
            padding: 0,
            backgroundColor: object?.color
          }}>
          <div
            className="text-subcontract cut-text-and-dot"
            style={{
              alignContent: 'center',
              textAlign: 'center',
              position: 'relative',
              top: 7
            }}>
            {object?.name}
          </div>
        </div>
      </div>
    );
  };

  const renderRemoveBtn = () => {
    return (
      <div className="remove-option-sub-custom">
        <div className="remove-sub-x">
          <img width="10" src={RemoveIcon} />
        </div>
        <div className="remove-sub-text">{t('remove_subcontract_label')}</div>
      </div>
    );
  };

  const renderSelectedOption = (selected) => {
    const object = selected;
    return (
      <div
        className="item-gantt item-custom-selected-sub"
        style={{ cursor: isOnlyReadElement ? 'not-allowed' : 'pointer' }}>
        <div
          className="subcontract-border cut-text-and-dot"
          style={{
            height: '100%',
            color: `${getCompanyTextColor(object?.color)}`,
            padding: 0,
            backgroundColor: object?.color
          }}>
          <div
            className="text-subcontract cut-text-and-dot"
            style={{
              alignContent: 'center',
              textAlign: 'center',
              position: 'relative',
              top: 1
            }}>
            {object?.name}
          </div>
        </div>
      </div>
    );
  };

  const validateMutationFromSub = (_) => {
    if (subtradeRole && subtradeRole.subtrade && taskFromLookahead && task) {
      taskFromLookahead.editablePermissionACP.editable = false;
      task.readonly = true;
    }
  };

  if (!task.isTask && gantt) return null;
  return (
    <div
      className={`${isOdd ? 'odd-col' : 'non-odd-col'} ${customMainClassName || ''}`}>
      <CustomDropdownSelector
        customMainClassName={`custom-gantt-subcontract custom-sub-dropdown ${customMainClassName || ''}`}
        renderCustomPlaceholderElement={renderSelectedOption}
        renderCustomSelectionOption={renderSubToSelect}
        renderCustomRemoveOption={renderRemoveBtn}
        labelCreateBtn={
          <div className="create-sub-btn">
            + {t('user_config.new_subcontract')}
          </div>
        }
        {...state}
        callbackOnSelection={onSelection}
        callbackOnClickCreate={() => {
          validateMutationFromSub();
          callbackOnClickCreate();
        }}
        callbackOnClickRemove={callbackRemove}
        onlyread={isOnlyReadElement}
      />
    </div>
  );
};

export default withTranslation()(RenderSub);
