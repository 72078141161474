import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Row, Col, Checkbox, Button, Tooltip, Icon } from 'antd';
import './index.css';
import { analyticService } from '../../services/analytic.service';
import { List, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { openNotification } from '../../utils';
import ApexCharts from 'apexcharts';
import lineaIcon from '../../assets/img/velocity_takt/linea.png';
import descontinuoIcon from '../../assets/img/velocity_takt/descontinuo.png';
import lineapuntoIcon from '../../assets/img/velocity_takt/lineapunto.png';

const _ = require('lodash');

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

export default function VelocityTasksCurve({ t, activities = [] }) {
  // const [loading, setLoading] = useState(false);
  if (activities == null) activities = [];
  const options_check_list = [
    { value: 'base', label: 'Base' },
    { value: 'programada', label: 'Programada' },
    { value: 'real', label: 'Real' }
  ];
  // const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [loading, setLoadView] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [xaxis, setXaxis] = useState([]);
  const [options, setOptions] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isFormated, setIsFormated] = useState(false);
  const [activitys, setActivitys] = useState([]);
  const [activitysgruop, setActivitysgruop] = useState([]);
  const [activityCheckCount, setActivityCheckCount] = useState(0);
  const [countSeries, setCountSeries] = useState(0);
  const projectState = useSelector((state) => state.projectState);
  const [checkoutBase, setCheckoutBase] = useState(true);
  const [checkoutProgramada, setCheckoutProgramada] = useState(true);
  const [checkoutReal, setCheckoutReal] = useState(true);
  const [arrayNameActivity, setArrayNameActivity] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [disableCheck, setDisableCheck] = useState(true);

  // const [checkoutBase, setCheckoutBase] = useState(true);

  const groupBy = function (xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  useEffect(() => {
    const array_name = [];
    if (activities.length > 0) {
      const result = activities.reduce((r, a) => {
        r[a.name.trim()] = r[a.name.trim()] || [];
        r[a.name.trim()].push(a);
        return r;
      }, Object.create(null));
      setActivitysgruop(result);
      const names = Object.keys(result);
      const simbolos = '0123456789ABCDEF';

      names.map((n) => {
        let color;
        color = '#';

        for (let i = 0; i < 6; i++) {
          color = color + simbolos[Math.floor(Math.random() * 16)];
        }
        array_name.push({
          name: n,
          selected: false,
          color: color
        });
      });

      setActivitys(array_name);
      setLoadView(true);
    }
  }, [activities]);

  useEffect(() => {
    formatRawData();
  }, [rawData]);

  async function getData() {
    if (activityCheckCount <= 7) {
      const activity_id = [];
      activitys.map((a) => {
        if (a.selected) {
          activitysgruop[a.name].map((act) => {
            activity_id.push(act.id);
          });
        }
      });
      setDisableButton(true);
      const data = await analyticService.velocity_takt({
        sector_id: projectState.sectorSelected,
        activities: activity_id
      });
      if (data) {
        setRawData(data);
      }
    } else {
      const alertErrorMailExists = {
        title: t('takt_velocity.error_title'),
        description: t('takt_velocity.error_description'),
        type: 'error'
      };
      openNotification(alertErrorMailExists);
    }
  }

  const formatRawData = () => {
    const x_axis = [];
    const series = [];
    const dynamic_name = [];
    const array_type = [];
    const dynamic_dashArray = [];
    const dynamic_markers = [];
    let count_ = 1;
    if (rawData) {
      rawData.forEach((data) => {
        const formated_base_data = data.base.map((item) => ({
          x: item.fecha,
          y: item.valor ? item.valor.toFixed(2) : item.valor
        }));

        const formated_data = data.programada.map((item) => ({
          x: item.fecha,
          y: item.valor ? item.valor.toFixed(2) : item.valor
        }));

        const formated_real_data = data.real.map((item) => ({
          x: item.fecha,
          y: item.valor ? item.valor.toFixed(2) : item.valor
        }));

        const acti = activitys.find((a) => a.name == data.name);
        const simbolos = '0123456789ABCDEF';
        let color;
        // eslint-disable-next-line prefer-const
        color = acti.color;
        series.push({
          name: 'base' + count_,
          data: formated_base_data,
          activity: data.name,
          color: color,
          tipo: 'base'
        });
        series.push({
          name: 'programada' + count_,
          data: formated_data,
          activity: data.name,
          color: color,
          tipo: 'followUp'
        });
        series.push({
          name: 'real' + count_,
          data: formated_real_data,
          activity: data.name,
          color: color,
          tipo: 'real'
        });
        count_++;
        x_axis.push(data.date);
      });
      let seriesOrderer = [];
      seriesOrderer = series.sort(
        (a, b) =>
          // ASC  -> a.length - b.length
          // DESC -> b.length - a.length
          b?.data?.length - a?.data?.length
      );

      seriesOrderer.map((serie) => {
        if (serie.tipo === 'followUp') {
          dynamic_name.push(serie.activity);
          array_type.push(t('takt_velocity.follow'));
          dynamic_dashArray.push(0);
          dynamic_markers.push(5);
        }
        if (serie.tipo === 'base') {
          dynamic_name.push(serie.activity);
          array_type.push(t('takt_velocity.follow'));
          dynamic_dashArray.push(8);
          dynamic_markers.push(0);
        }
        if (serie.tipo === 'real') {
          dynamic_name.push(serie.activity);
          array_type.push(t('takt_velocity.real'));
          dynamic_dashArray.push(0);
          dynamic_markers.push(0);
        }
      });
      setCountSeries(count_);
      setArrayNameActivity(dynamic_name);
      const tmp_options = {
        series: series,
        options: {
          chart: {
            id: 32422,
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
            animations: {
              enabled: false
            }
          },
          stroke: {
            curve: 'straight',
            dashArray: dynamic_dashArray,
            width: 3
          },
          markers: {
            size: dynamic_markers
          },
          title: {
            text: ''
          },
          xaxis: {},
          yaxis: {
            labels: {
              formatter: function (value) {
                return t('lang') === 'en'
                  ? parseFloat(value).toFixed(1)
                  : parseFloat(value).toFixed(1).replace('.', ',');
              }
            }
          },
          legend: {
            show: false
          },
          tooltip: {
            shared: true,
            intersect: false,
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return (
                '<div>' +
                dynamic_name[seriesIndex] +
                '-' +
                array_type[seriesIndex] +
                ':' +
                series[seriesIndex][dataPointIndex] +
                'Un</div>'
              );
            }
          }
        }
      };

      setOptions(tmp_options);
      setIsFormated(true);
      setLoading(false);
    }
  };

  /**
   * This function goes deeply on a tree data structure and transforms it to an lineal array in the same structure
   * @param {*} tree Array with tree format
   * @param {*} finalJsxArray empty array to push deeply data
   * @param {*} lvl 0 on start to deeply get level for elements
   */
  const treeToLinealArray = (tree, finalJsxArray, lvl) => {
    tree.map((element) => {
      const hasChilds = element.children;
      element.lvl = lvl;
      finalJsxArray.push(element);
      if (hasChilds) {
        if (hasChilds.length) {
          treeToLinealArray(hasChilds, finalJsxArray, lvl + 1);
        }
      }
    });
  };

  const clickActivityText = (name) => {
    setDisableButton(false);
    setDisableCheck(false);
    const active = activitys.find((a) => a.name == name);
    active.selected = !active.selected;

    setActivitys([...activitys]);
  };

  useEffect(() => {
    let count = 0;
    activitys.map((a) => {
      if (a.selected) {
        count = count + 1;
      }
    });
    setActivityCheckCount(count);
  }, [activitys]);

  const list_activitys = () => (
    <List
      dataSource={activitys}
      renderItem={(item) => (
        <List.Item className="item_list">
          {item.selected ? (
            <Tooltip title={item.name} className="item">
              <p
                className="text_click text_cut"
                onClick={() => clickActivityText(item.name)}>
                {item.name}
              </p>
              <span style={{ backgroundColor: item.color }} class="dot"></span>
            </Tooltip>
          ) : (
            <Tooltip title={item.name} className="item">
              <p
                className="text_cut"
                onClick={() => clickActivityText(item.name)}>
                {item.name}
              </p>
              <span style={{ backgroundColor: item.color }} class="dot"></span>
            </Tooltip>
          )}
        </List.Item>
      )}></List>
  );

  const clickBase = () => {
    let i = 1;
    while (i < countSeries) {
      ApexCharts.exec(32422, 'toggleSeries', 'base' + i);
      i++;
    }
  };

  const clickProgramada = () => {
    let i = 1;
    while (i < countSeries) {
      ApexCharts.exec(32422, 'toggleSeries', 'programada' + i);
      i++;
    }
  };

  const clickReal = () => {
    let i = 1;
    while (i < countSeries) {
      ApexCharts.exec(32422, 'toggleSeries', 'real' + i);
      i++;
    }
  };

  function onChangeBase(e) {
    clickBase();
    setCheckoutBase(e.target.checked);
  }

  function onChangeProgramada(e) {
    clickProgramada();
    setCheckoutProgramada(e.target.checked);
  }

  function onChangeReal(e) {
    clickReal();
    setCheckoutReal(e.target.checked);
  }

  const renderChart = () => {
    if (isFormated) {
      return (
        <view>
          <Row span={24}>
            <Col span={5}>
              <h1 onClick={() => clickBase()} className="title_velocity_chart">
                {t('takt_velocity.title')}
              </h1>
            </Col>
            <Col span={19}>
              <p className="count_activitys">
                {activityCheckCount + '/7'} {t('takt_velocity.count_text')}
              </p>
            </Col>
          </Row>
          <Row offset={1} span={24} style={{ height: '100%' }}>
            <Row span={24} style={{ height: '100%' }}>
              <Col
                offset={0}
                span={5}
                style={{ height: '100%', padding: '10px' }}>
                <Row span={5} style={{ height: '420px', overflow: 'auto' }}>
                  {list_activitys()}
                </Row>
                <Row span={5}>
                  <Button
                    type="primary"
                    className="common-btn-style bottom-btns-style"
                    disabled={disableButton}
                    onClick={() => activityCheckCount > 0 && getData()}>
                    {t('takt_velocity.load')}
                  </Button>
                </Row>
              </Col>
              <Col offset={0} span={19} style={{ height: '100%' }}>
                <Chart
                  className="chart"
                  options={options.options}
                  series={options.series}
                  type="line"
                  width="100%"
                  height="450px"
                />
                <div style={{ width: '100%' }}>
                  <div style={{ width: '350px' }} className="filter_curve">
                    <Checkbox
                      disabled={disableCheck}
                      onChange={onChangeBase}
                      checked={checkoutBase}>
                      {t('takt_velocity.base')}
                      <img
                        className="img_icon_curve_takt"
                        src={descontinuoIcon}></img>
                    </Checkbox>
                    <Checkbox
                      disabled={disableCheck}
                      onChange={onChangeProgramada}
                      checked={checkoutProgramada}>
                      {t('takt_velocity.follow')}
                      <img
                        className="img_icon_curve_takt"
                        src={lineapuntoIcon}></img>
                    </Checkbox>
                    <Checkbox
                      disabled={disableCheck}
                      onChange={onChangeReal}
                      checked={checkoutReal}>
                      {t('takt_velocity.real')}
                      <img
                        className="img_icon_curve_takt"
                        src={lineaIcon}></img>
                    </Checkbox>
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
        </view>
      );
    }
    return null;
  };

  return (
    <div style={{ height: '100%', minHeight: '700px' }}>
      {loading ? (
        renderChart()
      ) : (
        <Spin className="loader-spinner-lookahead-table" indicator={antIcon} />
      )}
    </div>
  );
}
