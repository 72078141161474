const addTimeToConstraintDate = ({
  task = {},
  start = false,
  field = 'end_date',
  calendarObject = {}
}) => {
  /**
   * This function calculate time to add to constraint date (start day / finish day)
   * @param {*} task task to modify
   * @param {*} start flag to know if you need the beginning of the day or the end of the day
   */

  if (!task) return;
  let dateStr = task[field];
  if (start) {
    dateStr = task.constraint_date;
    const hours = calendarObject.customHour.startHour.split(':')[0];
    const minutes = calendarObject.customHour.startHour.split(':')[1];
    dateStr.setHours(hours);
    dateStr.setMinutes(minutes);
  } else {
    if (Object.keys(calendarObject.customHour).length !== 0) {
      const hours = calendarObject.customHour.endHour.split(':')[0];
      const minutes = calendarObject.customHour.endHour.split(':')[1];
      dateStr.setHours(hours);
      dateStr.setMinutes(minutes);
    }
  }
  task.constraint_date = dateStr;
};

export { addTimeToConstraintDate };
