import React, { useEffect, useState } from 'react';

import styles from './GeneralInfoPanel.module.scss';
import { RENDER_SUB } from '../../GanttVisualization/dependencies/constants';
import CustomDropdownSelector from '../../CustomDropdownSelector/CustomDropdownSelector';
import RemoveIcon from '../../../assets/img/gantt/X.png';
import { getCompanyTextColor } from '../../../utils';

export const SubContractDropdown = (props) => {
  /** get props */
  const {
    cardData,
    customMainClassName,
    t,
    activityCardUpdateLocally,
    activityUpdateRequested
  } = props;

  /** constansts */
  const gantt = window.to_use_react_gantt;

  /** states */
  const [state, setState] = useState(RENDER_SUB);

  /** effects */
  useEffect(() => {
    const sub = {
      ...state,
      mainObject: {
        subcontractId: cardData?.subcontract?.id
      },
      options: gantt?.subContracts?.map((el) => {
        return {
          name: el.name,
          id: el.id,
          color: el.color
        };
      })
    };
    setState(sub);
  }, [cardData]);

  /** validations */
  if (!cardData) return null;

  /** inside functions */

  /**
   * this function is executed when adding a new item
   */
  const callbackOnClickCreate = () => {
    gantt.setVisibleFormSubcontract(true);
    gantt.toHardAssignSubcontract = cardData?.subcontract?.id;
  };

  /**
   * this function is executed when rendering the item of the entity
   * @param {*} selected item of the entity (in this case the assigned subcontract)
   * @returns
   */
  const renderSelectedOption = (selected) => {
    const { color, name } = selected;
    return (
      <div className="item-gantt item-custom-selected-sub">
        <div>
          <button
            style={{
              color: `${getCompanyTextColor(color)}`,
              backgroundColor: color
            }}
            className={styles['general-info-panel__subtrade-button']}>
            {name}
          </button>
        </div>
      </div>
    );
  };

  /**
   * this function renders all the options of the entity
   * @param {*} option item to be rendered
   * @returns
   */
  const renderSubToSelect = (option) => {
    const { object } = option;
    return (
      <div className="item-gantt">
        <div
          className="subcontract-border cut-text-and-dot"
          style={{
            height: '100%',
            color: `${getCompanyTextColor(object?.color)}`,
            padding: 0,
            backgroundColor: object?.color
          }}>
          <div
            className="text-subcontract cut-text-and-dot"
            style={{
              alignContent: 'center',
              textAlign: 'center',
              position: 'relative',
              top: 7
            }}>
            {object?.name}
          </div>
        </div>
      </div>
    );
  };

  /**
   * this function renders the remove item button
   * @returns jsx to render
   */
  const renderRemoveBtn = () => {
    return (
      <div className="remove-option-sub-custom">
        <div className="remove-sub-x">
          <img width="10" src={RemoveIcon} />
        </div>
        <div className="remove-sub-text">{t('remove_subcontract_label')}</div>
      </div>
    );
  };

  /**
   * this function is a callback of the update saga call
   * @param {*} originalActivityObject activity to update
   */
  const onUpdated = (originalActivityObject) => {
    /** update gantt */
    const gantt = window.to_use_react_gantt;
    if (gantt.isTaskExists(originalActivityObject.unique_id)) {
      const taskGantt = gantt.getTask(originalActivityObject.unique_id);
      taskGantt.subcontractId = originalActivityObject.subcontractId;
      gantt.updateTask(taskGantt.id);
    }

    /** update reference */
    activityCardUpdateLocally({
      ...cardData,
      subcontractId: originalActivityObject.subcontractId,
      subcontract: originalActivityObject.subcontract
    });
  };

  /**
   * this function updates the subcontract of an activity
   * @param {*} entity activity to update
   * @param {*} subcontract subcontract to assign
   */
  const assignSubcontractToActivity = (entity, subcontract = null) => {
    let newDataActivity;
    if (subcontract === null) {
      newDataActivity = {
        ...entity,
        subcontractId: null,
        subcontract: null
      };
    } else {
      newDataActivity = {
        ...entity,
        subcontractId: subcontract.id,
        subcontract: subcontract
      };
    }
    onUpdated(newDataActivity);
  };

  /**
   * this function fires every time we select an item from the dropdown
   * @param {*} payload selected option information
   */
  const onSelection = (payload) => {
    const optionId = parseInt(payload.split('-')[0]);
    setState({
      ...state,
      mainObject: {
        ...state.mainObject,
        subcontractId: optionId
      }
    });

    /** update subcontract */
    const subcontractFind = gantt.subContracts.filter(
      (el) => el.id === optionId
    );
    if (subcontractFind[0]) {
      assignSubcontractToActivity(
        cardData.originalActivityObject,
        subcontractFind[0]
      );
    }
  };

  /**
   * this function fires every time we remove the selected item
   * @param {*} _
   */
  const callbackRemove = (_) => {
    setState({
      ...state,
      mainObject: {
        ...state.mainObject,
        subcontractId: null
      }
    });
    /** set null to subcontract */
    assignSubcontractToActivity(cardData.originalActivityObject, null);
  };

  /** render */
  return (
    <CustomDropdownSelector
      customMainClassName={`custom-gantt-subcontract custom-sub-dropdown ${customMainClassName || ''}`}
      renderCustomPlaceholderElement={renderSelectedOption}
      renderCustomSelectionOption={renderSubToSelect}
      renderCustomRemoveOption={renderRemoveBtn}
      width={20}
      options={[]}
      {...state}
      callbackOnSelection={onSelection}
      hideCreateBtn={true}
      callbackOnClickRemove={callbackRemove}
      withTimeout={true}
    />
  );
};
