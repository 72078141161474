/** React components  */
import React, { useState, useEffect, Fragment } from 'react';

/** library to redirect  */
import { Redirect } from 'react-router-dom';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/** Services */
import { userService } from '../../services';

/** import elements from library Antd Framework */
import { Modal, Checkbox, Button, Input, Row, Col } from 'antd';

/** import component for validation */
import ErrorValidationLabel from '../../components/Login/ValidationLabel';

/** import common functions from utils */
import {
  checkAllFieldsValid,
  reduceFormValues,
  buildPassword,
  txtFieldState,
  MsgErrorDB,
  openNotification
} from '../../utils';

/** import base contant (setting urls)  */
import { base } from '../../services/base';
import { withTranslation } from 'react-i18next';

/** state for component */
/** Structure:
    {
        email,
        agree,
        allFieldsValid: false, // handle all field form is valid
        showAgree: false, // handle agree label
        visibleTerms: false, // handle dialog Terms modal
        isLoading: false, // handle loading
        switchViewForm: false // handle view form or view success
    }
*/
/** state Object */
const stateTemplate = {
  email: {
    ...txtFieldState,
    fieldName: 'email',
    required: true,
    requiredTxt: 'login.user_required',
    formatErrorTxt: 'login.user_error_format'
  },
  agree: {
    ...txtFieldState,
    fieldName: 'agree',
    required: true,
    requiredTxt: 'register.term_error_format',
    formatErrorTxt: ''
  },
  allFieldsValid: false,
  showAgree: false,
  visibleTerms: false,
  isLoading: false,
  switchViewForm: false
};

function RegisterView(props) {
  /** hooks */
  const [state, setState] = useState(stateTemplate); // handle state
  const { t } = props;

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);
    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  /** redirects */
  const signed = localStorage.getItem('signed');
  if (signed) {
    return <Redirect to="/projects" />;
  }

  /** navigation history */
  const goToLogin = (e) => {
    e.preventDefault();
    props.history.push('/login');
  };

  /** handle Modal Terms */
  const showTerms = (e) => {
    e.preventDefault();
    setState({
      ...state,
      showAgree: false,
      visibleTerms: true
    });
  };

  /** handle Modal check terms */
  const handleOkTerms = (e) => {
    setState({
      ...state,
      showAgree: true,
      visibleTerms: false
    });
  };

  /** handle Modal cancel terms */
  const handleCancelTerms = (e) => {
    setState({
      ...state,
      showAgree: false,
      visibleTerms: false
    });
  };

  /** handle checkbox check event */
  const chkAgree = () => {
    setState({
      ...state,
      showAgree: !state.showAgree
    });
  };

  /** handle change checked */
  const onChangeAgree = (e) => {
    setState({
      ...state,
      showAgree: e.target.checked
    });
  };

  /** Services */
  async function createUser(data) {
    const resp = await userService.create(data);
    return resp;
  }

  /** logic component */
  const onSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formValues = reduceFormValues(form.elements, state);
    const allFieldsValid = checkAllFieldsValid(formValues);
    setState({ ...state, ...formValues, allFieldsValid });

    if (allFieldsValid) {
      setState({
        ...state,
        isLoading: true
      });
      /** get lang */
      const userLang = navigator.language || navigator.userLanguage;
      const data = {
        email: state.email.value,
        link: base.front + 'confirmation/',
        password: buildPassword(),
        lang: userLang.includes('es') ? 'es' : 'en'
      };
      const resp = await createUser(data);
      if (!resp.id) {
        const msgError = MsgErrorDB(resp.msj.original.constraint);
        const alertErrorMailExists = {
          title: t('register.register'),
          description: t(`register.${msgError}`),
          type: 'error'
        };
        setState({
          ...state,
          isLoading: false
        });
        openNotification(alertErrorMailExists);
      } else {
        setState({
          ...state,
          isLoading: false,
          switchViewForm: true
        });
      }
    }
  };

  /** validation */
  const { email, agree } = state;
  const swAgree = state.showAgree ? 'block' : 'none';

  const renderEmailValidationError = email.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      txtLbl={
        email.typeMismatch ? t(email.formatErrorTxt) : t(email.requiredTxt)
      }
    />
  );

  const renderAgreeValidationError =
    agree.valid || !swAgree ? (
      ''
    ) : (
      <ErrorValidationLabel txtLbl={t(agree.requiredTxt)} />
    );

  /** render */
  return (
    <div className="divSection">
      {state.switchViewForm ? (
        <Fragment>
          <div className="imgDegrade">&nbsp;</div>
          <h1 className="headerMain">{t('register.complete_title')}</h1>
          <h3 style={{ textAlign: 'center' }}>
            {t('register.complete_title_1')}
          </h3>
          <h3 style={{ textAlign: 'center' }}>
            {t('register.complete_title_2')}
          </h3>
          <h3 style={{ textAlign: 'center' }}>
            {t('register.complete_title_3')}
          </h3>
          <Button type="primary" className="btnSubmit" onClick={goToLogin}>
            {t('register.sign_in')}
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <div className="imgDegrade">&nbsp;</div>
          <h1 className="headerMain">{t('register.new_account')}</h1>
          <form className="frmWide" onSubmit={(e) => onSubmit(e)} noValidate>
            <Row>
              <Col span={24}>
                <Input
                  className="centerTxt"
                  type="email"
                  name="email"
                  placeholder={t('register.email_register')}
                  autoComplete="off"
                  onChange={(e) => {
                    /** update state with value selected */
                    setState({
                      ...state,
                      email: {
                        ...state.email,
                        value: e.target.value,
                        valid: true /** clear validation */
                      }
                    });
                  }}
                  required
                />
                {renderEmailValidationError}
              </Col>
            </Row>

            <Row className="mt-20">
              <Col span={24}>
                <Checkbox
                  className="ant-ckeckbox"
                  type="checkbox"
                  name="agree"
                  onClick={chkAgree}
                  checked={state.showAgree}
                  onChange={onChangeAgree}>
                  {t('register.continue_terms')}
                </Checkbox>
                <a href="#/" onClick={showTerms} className="refLink">
                  {t('register.terms_and_conditions_1')}
                </a>
                <label
                  style={{ display: state.showAgree ? 'block' : 'none' }}
                  className="lblAgree">
                  {t('register.accept')}
                </label>
                {!state.showAgree ? renderAgreeValidationError : ''}
              </Col>
            </Row>

            <Button htmlType="submit" loading={state.isLoading}>
              {t('register.register_email')}
            </Button>
            <span className="labelFrm">
              {t('register.already_account_1')}&nbsp;
              <a href="#/" onClick={goToLogin} className="refLink">
                {t('register.already_account_2')}
              </a>
            </span>
          </form>

          <Modal
            title={`Proplanner ${t('register.terms_and_conditions_1')}`}
            visible={state.visibleTerms}
            onOk={handleOkTerms}
            onCancel={handleCancelTerms}
            width={750}
            okText={t('register.accept')}
            cancelText={t('register.cancel')}>
            <p>
              "But I must explain to you how all this mistaken idea of
              denouncing pleasure and praising pain was born and I will give you
              a complete account of the system, and expound the actual teachings
              of the great explorer of the truth, the master-builder of human
              happiness. No one rejects, dislikes, or avoids pleasure itself,
              because it is pleasure, but because those who do not know how to
              pursue pleasure rationally encounter consequences that are
              extremely painful. Nor again is there anyone who loves or pursues
              or desires to obtain pain of itself, because it is pain, but
              because occasionally circumstances occur in which toil and pain
              can procure him some great pleasure. To take a trivial example,
              which of us ever undertakes laborious physical exercise, except to
              obtain some advantage from it? But who has any right to find fault
              with a man who chooses to enjoy a pleasure that has no annoying
              consequences, or one who avoids a pain that produces no resultant
              pleasure?
            </p>
            <p>
              "But I must explain to you how all this mistaken idea of
              denouncing pleasure and praising pain was born and I will give you
              a complete account of the system, and expound the actual teachings
              of the great explorer of the truth, the master-builder of human
              happiness. No one rejects, dislikes, or avoids pleasure itself,
              because it is pleasure, but because those who do not know how to
              pursue pleasure rationally encounter consequences that are
              extremely painful. Nor again is there anyone who loves or pursues
              or desires to obtain pain of itself, because it is pain, but
              because occasionally circumstances occur in which toil and pain
              can procure him some great pleasure. To take a trivial example,
              which of us ever undertakes laborious physical exercise, except to
              obtain some advantage from it? But who has any right to find fault
              with a man who chooses to enjoy a pleasure that has no annoying
              consequences, or one who avoids a pain that produces no resultant
              pleasure?
            </p>
          </Modal>
        </Fragment>
      )}
    </div>
  );
}

export default withTranslation()(RegisterView);
