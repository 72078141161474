/* eslint no-label-var: "off" */
import React, { useState, useEffect } from 'react';

/** Components */
import AppRouter from '../../router';

/** import elements from library Antd Framework */
import { Spin } from 'antd';

/** import icons from antd framework */
import { LoadingOutlined } from '@ant-design/icons';

/**
 * High order components (HOC)
 */
import withoutSidenav from '../../hocs/withoutSidenav.hoc';
import { useCheckUserAndLocation } from '../../hooks/useCheckUserAndLocation';

/** render */
export default function LayoutAuth(props) {
  useCheckUserAndLocation();
  const [FinishPage, setFinishPage] = useState(
    <Spin className="loader-layout-style" indicator={<LoadingOutlined />} />
  );

  useEffect(() => {
    const Final = withoutSidenav({ ...props })(AppRouter);
    setFinishPage(<Final />);
  }, []);

  /** render */
  return <div>{FinishPage}</div>;
}
