/** React components  */
import React, { useState, useEffect, Fragment } from 'react';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/** Services */
import { passwordResetService } from '../../services';

/** import hook for handle params */
import { useParams } from 'react-router-dom';

/**  import library for animations effects */
import { Animated } from 'react-animated-css';

/** import elements from library Antd Framework */
import { Spin, Input, Button, Row, Col } from 'antd';

/** import component for validation */
import ErrorValidationLabel from '../../components/Login/ValidationLabel';
import TokenNoValid from '../../components/Login/tokenNoValid';

/** import common functions from utils */
import {
  checkAllFieldsValid,
  reduceFormValues,
  txtFieldState,
  setTitleWide
} from '../../utils';
import { trackingEvent } from '../../analytics';
import { getBasicAmplitudEventProperties } from '../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';

/** state for component */
/** Structure:
    {
        password,
        repassword,
        allFieldsValid: false,
        isLoading: false, // handle loading button
        switchViewForm: false // handle view form
        loadingForm: true, // show loading until fetch is ok
        tokenValid: false, // handle resp if token is valid
    }
*/
// state Object
const stateTemplate = {
  password: {
    ...txtFieldState,
    fieldName: 'password',
    required: true,
    requiredTxt: 'Password is not valid',
    formatErrorTxt: ''
  },
  repassword: {
    ...txtFieldState,
    fieldName: 'repassword',
    required: true,
    requiredTxt: 'Password is not valid',
    formatErrorTxt: "Passwords don't match"
  },
  allFieldsValid: false,
  isLoading: false,
  switchViewForm: false,
  loadingForm: true,
  tokenValid: false
};

export default function ForgotView(props) {
  /** hooks */
  const [state, setState] = useState(stateTemplate); /** handle state */
  const { token } = useParams(); /** handle params */

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    setTitleWide('password');
    EventEmitter.on('changeMainRoute', callback);
    verifyToken(token);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  const verifyToken = async (token) => {
    const passwordReset = await getTokenData(token);
    if (passwordReset) {
      /** all fine */
      setState({
        ...state,
        loadingForm: false,
        tokenValid: true
      });
    } else {
      /** resetpassword not exists  */
      setState({
        ...state,
        loadingForm: false,
        tokenValid: false
      });

      trackingEvent(
        'forgot_password',
        {
          ...getBasicAmplitudEventProperties(),
          token,
          response_passwordReset: passwordReset,
          event_source: 'ChangePassword View'
        },
        AMPLITUDE_SERVICE
      );
    }
  };

  /** services */
  async function updatePasswordReset(data) {
    // TODO: review valid token
    await passwordResetService.update(data);
  }

  async function getTokenData(token) {
    const resp = await passwordResetService.show(token);
    return resp;
  }

  /** component logic */
  const onSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formValues = reduceFormValues(form.elements, state);
    const allFieldsValid = checkAllFieldsValid(formValues);
    setState({ ...state, ...formValues, allFieldsValid });

    if (allFieldsValid) {
      setState({ ...state, isLoading: true });
      const data = {
        token: token,
        password: state.password.value
      };
      await updatePasswordReset(data);
      setState({ ...state, isLoading: false, switchViewForm: true });
    }
  };

  /** validation */
  const { password, repassword } = state;

  const renderNotEqualValidationError = repassword.valid ? (
    ''
  ) : (
    <ErrorValidationLabel
      classError="lblerror ta-left-widecenter"
      txtLbl={
        password.value !== repassword.value
          ? repassword.formatErrorTxt
          : repassword.requiredTxt
      }
    />
  );

  /** render */
  return (
    <div className="divSection">
      <Animated
        animationIn="fadeIn"
        animationInDuration={750}
        isVisible={true}
        style={{ width: '100%' }}>
        {state.loadingForm ? (
          <Spin spinning={true} />
        ) : state.tokenValid ? (
          state.switchViewForm ? (
            <Fragment>
              <div className="imgDegrade">&nbsp;</div>
              <h1 className="headerMain">Check your inbox</h1>
              <h3 style={{ textAlign: 'center' }}>
                Your password was successfully changed.
              </h3>
              <Button type="primary" className="btnSubmit" href="/login">
                Go to Sign In
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <a href="/">
                <div className="imgDegrade">&nbsp;</div>
              </a>
              <h1 className="headerMain">Change your Password</h1>
              <form className="frmWide" onSubmit={onSubmit} noValidate>
                <Row className="mt-15">
                  <Col span={24}>
                    <Input
                      type="password"
                      name="password"
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      placeholder="Password"
                      onChange={(e) => {
                        /** update state with value selected */
                        setState({
                          ...state,
                          password: {
                            ...state.password,
                            value: e.target.value
                          },
                          repassword: {
                            ...state.repassword,
                            valid: true /** clear validation */
                          }
                        });
                      }}
                      required
                    />
                    <label className="lblInfoElement widecenter">
                      Min Length 8. Must include Caps Letters & Numbers
                    </label>
                  </Col>
                </Row>

                <Row className="mt-15">
                  <Col span={24}>
                    <Input
                      type="password"
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      name="repassword"
                      placeholder="Retype Password"
                      onChange={(e) => {
                        /** update state with value selected */
                        setState({
                          ...state,
                          repassword: {
                            ...state.repassword,
                            value: e.target.value,
                            valid: true /** clear validation */
                          }
                        });
                      }}
                      required
                    />
                    {renderNotEqualValidationError}
                  </Col>
                </Row>
                <Button htmlType="submit" loading={state.isLoading}>
                  Reset password
                </Button>
              </form>
            </Fragment>
          )
        ) : (
          <TokenNoValid />
        )}
      </Animated>
    </div>
  );
}
