import * as icons from '../../icons';
import colors from '../../stylesheets/variables.scss';
import UncheckComponent from '../UncheckComponent';
import GanttMassiveDuration from '../GanttMassiveDuration';
import GanttMassiveProgress from '../GanttMassiveProgress';
import GanttMassiveCompany from '../GanttMassiveCompany';
import GanttMassiveResponsable from '../GanttMassiveResponsable';
import GanttMassiveTag from '../GanttMassiveTag';
import styles from './GanttMassiveToolbar.module.scss';

const commonMassiveItem = {
  mainColor: '#314045',
  backgroundColor: '#314045',
  iconColor: colors.white,
  invertTextColor: colors.green
};

export const MASSIVE_BTN_UNCHECK_CONFIG = {
  i18nKey: 'master_plan.unselect',
  command: 'MASSIVE',
  extraDatainProps: 'selectedActivities',
  comp: UncheckComponent
};

export const MASSIVE_DURATION = {
  icon: icons.MassiveClockIcon,
  tooltipI18nKey: 'master_plan.duration',
  command: 'MASSIVE',
  extraDatainProps: 'selectedActivities',
  comp: GanttMassiveDuration
};

export const MASSIVE_PROGRESS = {
  icon: icons.MassiveCheckIcon,
  tooltipI18nKey: 'master_plan.progress',
  command: 'MASSIVE',
  extraDatainProps: 'selectedActivities',
  comp: GanttMassiveProgress
};

export const MASSIVE_COMPANY = {
  icon: icons.MassiveHelmetIcon,
  tooltipI18nKey: 'subcontract_only_label',
  command: 'MASSIVE',
  extraDatainProps: 'selectedActivities',
  comp: GanttMassiveCompany
};

export const MASSIVE_RESPONSABLE = {
  icon: icons.MassiveAvatarIcon,
  tooltipI18nKey: 'master_plan.responsible',
  command: 'MASSIVE',
  extraDatainProps: 'selectedActivities',
  comp: GanttMassiveResponsable
};

export const MASSIVE_TAG = {
  icon: icons.MassiveTagIcon,
  tooltipI18nKey: 'tag_only_label',
  command: 'MASSIVE',
  extraDatainProps: 'selectedActivities',
  comp: GanttMassiveTag
};

export const MASSIVE_DUPLICATE = {
  icon: icons.MassiveDuplicateIcon,
  tooltipI18nKey: 'duplicate_activity_lookahead',
  command: 'MASSIVE_DUPLICATE',
  ...commonMassiveItem
};

const commonConstraintTypesObject = {
  checkable: true,
  iconColorSelected: colors.white
};

export const CONSTRAINT_TYPES_SUBMENU_ITEMS = [
  {
    i18nKey: 'master_plan.asap',
    command: 'MASSIVE_CONSTRAINT_TYPE',
    value: 'asap',
    ...commonConstraintTypesObject
  },
  {
    i18nKey: 'master_plan.alap',
    command: 'MASSIVE_CONSTRAINT_TYPE',
    value: 'alap',
    ...commonConstraintTypesObject
  },
  {
    i18nKey: 'master_plan.dont_start_before',
    command: 'MASSIVE_CONSTRAINT_TYPE',
    value: 'snet',
    ...commonConstraintTypesObject
  },
  {
    i18nKey: 'master_plan.dont_start_after',
    command: 'MASSIVE_CONSTRAINT_TYPE',
    value: 'snlt',
    ...commonConstraintTypesObject
  },
  {
    i18nKey: 'master_plan.dont_end_before',
    command: 'MASSIVE_CONSTRAINT_TYPE',
    value: 'fnet',
    ...commonConstraintTypesObject
  },
  {
    i18nKey: 'master_plan.dont_end_after',
    command: 'MASSIVE_CONSTRAINT_TYPE',
    value: 'fnlt',
    ...commonConstraintTypesObject
  },
  {
    i18nKey: 'master_plan.must_start',
    command: 'MASSIVE_CONSTRAINT_TYPE',
    value: 'mso',
    ...commonConstraintTypesObject
  },
  {
    i18nKey: 'master_plan.must_end',
    command: 'MASSIVE_CONSTRAINT_TYPE',
    value: 'mfo',
    ...commonConstraintTypesObject
  }
];

export const MASSIVE_DELETE = {
  icon: icons.TrashIcon,
  command: 'MASSIVE_DELETE',
  tooltipI18nKey: 'delete_only_label',
  ...commonMassiveItem,
  iconColor: '#7DFF8A'
};

export const MASSIVE_SETTINGS_CONFIG = [
  MASSIVE_DURATION,
  MASSIVE_PROGRESS,
  MASSIVE_RESPONSABLE,
  MASSIVE_COMPANY,
  MASSIVE_TAG,
  MASSIVE_DUPLICATE,
  MASSIVE_DELETE
];
