import React from 'react';
import ProjectCell from '../../../components/ProjectCell';

export default (gantt, { t }) => ({
  name: 'name',
  label: t('scheduleofschedule.columns.name'),
  disableActCard: true,
  width: 330,
  onrender: (item) => <ProjectCell gantt={gantt} project={item} />
});
