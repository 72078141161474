import React, { useState, useEffect, useRef } from 'react';
import { Spin, Icon } from 'antd';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { getTodayWeekWithPdayAndCurrentDay } from '../../../../utils';
import { weekCommitmentService } from '../../../../services';

export default function CurvePpc(props) {
  /** props */
  const planificationDay = props.planificationDay;
  let commints = props.data;
  const t = props.t;

  /** consts  */
  const noData = {
    text: 'No data',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: '14px',
      fontFamily: undefined
    }
  };
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  let weeks_number = [];
  const labels = [];
  const commitment_percentajes = [];

  /** hooks */
  const [ppcs, setPpcs] = useState([]);
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([
    {
      name: 'Porcentaje',
      type: 'column',
      data: null
    }
  ]);

  useEffect(() => {
    setTimeout(() => {
      getdata();
    }, 800);
  }, [commints]);

  async function getdata() {
    /** get week number */
    if (
      props.stateCompany.currentCompany.id == 28 ||
      props.stateCompany.currentCompany.id == 306
    ) {
      let partial_week_commitment = [];
      // const sectors_active = props.sectorFilter.length ? props.sectorFilter : props.projectState.allSectors
      const sectors_active = props.projectState.allSectors;
      for (let i = 0; i < sectors_active.length; i++) {
        const sector = sectors_active[i];
        let validate_sector;
        if (props.sectorFilter?.length) {
          validate_sector = props.sectorFilter.find((s) => s === sector.id);
        } else {
          validate_sector = true;
        }

        if (validate_sector) {
          const wsapi = await weekCommitmentService.searchBySector(sector.id);
          partial_week_commitment = partial_week_commitment.concat(
            wsapi.weekcommitments
          );
        }
      }
      commints = partial_week_commitment;
    }
    if (commints) {
      const ppcs_temp = [];
      commints.map((commint) => {
        const init_date = moment(commint.start_date);
        const week_object = {
          week: commint.week,
          year: init_date.year(),
          end: commint.end_date
        };
        const validate = weeks_number.find(
          (object) =>
            object.week == commint.week && object.year == init_date.year()
        );
        if (!validate) {
          weeks_number.push(week_object);
        }
      });
      weeks_number = [...new Set(weeks_number)];
      try {
        weeks_number = weeks_number.sort(
          (a, b) => new Date(a.end) - new Date(b.end)
        );
      } catch (e) {
        // sentencias para manejar cualquier excepción
        weeks_number.sort((a, b) =>
          moment()
            .locale(t('lang'))
            .year(a.year)
            .week(a.week)
            .startOf('isoweek')
            .diff(moment().locale(t('lang')).year(b.year).week(b.week))
        );
      }

      const offsetTZ = new Date().getTimezoneOffset() / -60;
      /**/
      weeks_number.map((week_n) => {
        const getDateInRange =
          offsetTZ > 0
            ? moment(week_n.end, 'YYYY-MM-DD HH:mm').utc(offsetTZ)
            : moment(week_n.end, 'YYYY-MM-DD HH:mm');

        const todayData = getTodayWeekWithPdayAndCurrentDay(
          null,
          getDateInRange,
          planificationDay
        );
        if (todayData[2]) {
        }
        const label =
          offsetTZ > 0
            ? moment(todayData[2]?.end, 'YYYY-MM-DD')
                .utc(offsetTZ)
                .format('DD MMM YY HH:mm')
            : moment(todayData[2]?.end, 'YYYY-MM-DD').format('DD MMM YY HH:mm');
        const labelToShow = `${label.split(' ')[0]} ${label.split(' ')[1]} ${label.split(' ')[2]}`;

        // const label = data_week.format('DD MMM YY');
        labels.push(labelToShow);
        let total = 0;
        let realized = 0;

        commints.map((commint) => {
          if (commint.week == week_n.week && commint.year == week_n.year) {
            if (commint.week == 10) {
            }
            if (commint.taskcommitments.length) {
              commint.taskcommitments.map((tastcommitment) => {
                if (
                  tastcommitment.realized_percentaje >=
                  tastcommitment.commitment_percentaje
                ) {
                  realized = realized + 1;
                }
                total = total + 1;
              });
            } else {
              total = total + commint.commitment_tasks;
              realized = realized + commint.realized_tasks;
            }
          }
        });
        const commitment_percentaje = (realized / total) * 100;
        commitment_percentajes.push(commitment_percentaje.toFixed(2));
        const ppc = {
          label: label,
          commitment_percentaje: commitment_percentaje,
          total: total,
          realized: realized,
          week: week_n.week
        };
        ppcs_temp.push(ppc);
      });
      setPpcs(ppcs_temp);
      const option = {
        chart: {
          height: 250,
          sparkline: {
            enabled: false
          },
          grid: {
            show: false,
            padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            },

            yaxis: {
              lines: {
                show: false
              }
            }
          },
          type: 'line',
          colors: ['#F44336'],
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: false,
              customIcons: []
            }
          }
        },
        stroke: {
          width: [0, 2],
          curve: ['smooth', 'straight', 'stepline']
        },
        title: {
          text: t('analitic.ppc'),
          style: {
            fontSize: '13px',
            fontWeight: '500',
            fontFamily: `
              Roboto,
              -apple-system,
              BlinkMacSystemFont,
              'Segoe UI',
              Oxygen,
              Ubuntu,
              Cantarell,
              'Fira Sans',
              'Droid Sans',
              'Helvetica Neue',
              sans-serif !important
            `
          }
        },
        dataLabels: {
          enabled: false
        },
        // labels: labels,
        xaxis: {
          type: 'category',
          labels: {},
          categories: labels,
          tickPlacement: 'on'
        },
        yaxis: [
          {
            title: {
              text: ''
            },
            max: 100,
            labels: {
              formatter: (val) =>
                t('lang') === 'en'
                  ? val + '%'
                  : val.toString().replace('.', ',') + '%'
            }
          },
          {
            opposite: true,
            title: {
              text: ''
            },
            labels: {
              formatter: (val) =>
                t('lang') === 'en' ? val : val.toString().replace('.', ',')
            }
          }
        ],
        colors: ['#155D77', '#7DFF8A'],
        tooltip: {
          shared: true,
          intersect: false,
          enabled: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              '<div>' +
              `${t('lang') === 'en' ? series[seriesIndex][dataPointIndex] : series[seriesIndex][dataPointIndex].toString().replace('.', ',')}` +
              '%  <br>' +
              ppcs_temp[dataPointIndex].realized +
              '/' +
              ppcs_temp[dataPointIndex].total +
              ' ' +
              t('analictic.tasks') +
              '</div>'
            );
          }
        }
      };
      const seriesUp = [
        {
          name: 'Porcentaje',
          type: 'column',
          data: commitment_percentajes
        }
      ];
      setOptions(
        commitment_percentajes.length
          ? option
          : {
              ...option,
              noData
            }
      );
      setSeries(seriesUp);
    }
  }

  return (
    <div style={{ height: '100%' }}>
      {series[0].data !== null || series[0].data?.length ? (
        <Chart
          options={options}
          series={series}
          type="bar"
          width="100%"
          height="100%"
        />
      ) : (
        <div className="widget-analitycs">
          <Spin
            className="loader-spinner-lookahead-table"
            indicator={antIcon}
          />
        </div>
      )}
    </div>
  );
}
