import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../../redux/slices/projectSlice';
import { sectorService, userService } from '../../../services';
import { WarningIcon } from '../../../icons';
import ModalSystem from '../../DesignSystem/ModalSystem';
import { DeleteStageComp } from './icons';
import {
  getTypeNotification,
  notifyMessageCustom,
  dynamicSort
} from '../../../utils';
import { trackingEvent } from '../../../analytics';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';

const DeleteStage = ({ show, setShow, t }) => {
  const dispatch = useDispatch();

  const [nameStage, setNameStage] = useState('');
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const keyActiveButton = t('delete_stage.select_label_key');

  const {
    projectState: { sectorSelectedRename, allSectors }
  } = useSelector((state) => state);

  const handleOnChange = (event) => {
    setNameStage(event.target.value);
  };

  const handleCloseModal = () => {
    setShow(false);
    setNameStage('');
  };

  const handleDeleteModal = async () => {
    if (disabledButton()) return;

    setIsLoadingButton(true);

    try {
      await sectorService.updateFieldsBySectorId(sectorSelectedRename?.id, [
        {
          field: 'status',
          value: false
        }
      ]);

      trackingEvent(
        'schedule_deletion',
        {
          ...getBasicAmplitudEventProperties(),
          stage_id: sectorSelectedRename?.id,
          stage_name: sectorSelectedRename?.name,
          event_result: true,
          event_source: 'Schedule selector'
        },
        AMPLITUDE_SERVICE
      );

      const newAllSectors = [...allSectors];
      const currentSectorId = newAllSectors.findIndex(
        (sector) => sector.id === sectorSelectedRename?.id
      );

      if (currentSectorId > -1) {
        newAllSectors.splice(currentSectorId, 1);
        dispatch(projectActions.setAllSectors(newAllSectors));

        setShow(false);
        setIsLoadingButton(false);
        setNameStage('');

        notifyMessageCustom({
          type: getTypeNotification(DeleteStageComp),
          description: t('notifications.delete_sector_finished')
        });

        const currentUser = JSON.parse(localStorage.getItem('user'));
        const getProjects = await userService.projectsbyuserthrough(
          currentUser?.id
        );

        if (!getProjects) return;

        const projectsActive = getProjects.projects.filter(
          (project) => project.stage !== 'deleted'
        );
        const sortProjects = projectsActive.sort(dynamicSort('name'));
        dispatch(projectActions.setAllProjects(sortProjects));
      }
    } catch (err) {
      trackingEvent(
        'schedule_deletion',
        {
          ...getBasicAmplitudEventProperties(),
          stage_id: sectorSelectedRename?.id,
          stage_name: sectorSelectedRename?.name,
          event_result: false,
          event_source: 'Schedule selector'
        },
        AMPLITUDE_SERVICE
      );
      console.log(err);
    }
  };

  const disabledButton = () => nameStage !== keyActiveButton || isLoadingButton;

  const contentChildren = () => (
    <div className="delete-stage__content">
      <div className="content__form">
        <div className="form__icon">
          <WarningIcon color="#7DFF8A" />
        </div>
        <div className="form__title">
          <h5>{t('delete_stage.title')}</h5>
        </div>
        <div className="form__input">
          <label>
            {t('delete_stage.select_label_one')}{' '}
            <b>{t('delete_stage.select_label_key')}</b>{' '}
            {t('delete_stage.select_label_two')}
          </label>
          <input type="text" value={nameStage} onChange={handleOnChange} />
        </div>
      </div>
      <div className="content__buttons">
        <button className="buttons__cancel" onClick={() => handleCloseModal()}>
          {t('delete_stage.button_cancel_text')}
        </button>
        <button
          className="buttons__delete"
          disabled={disabledButton()}
          onClick={() => handleDeleteModal()}>
          {t('delete_stage.button_delete_text')}
        </button>
      </div>
    </div>
  );

  return ModalSystem({
    visible: show,
    theme: 'dark',
    setVisible: setShow,
    showTitle: false,
    centered: true,
    width: 520,
    children: contentChildren(),
    zIndex: 2000
  });
};

export default DeleteStage;
