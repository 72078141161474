import moment from 'moment';

/**
 * Checks whether the input's raw value is valid according to its data type
 *
 * @param {string} value - The input's raw value
 * @param {Object} inputConfig - An object containing the input config options
 * @return {boolean} - A boolean value indicating whether the value is valid or not
 */
export const validate = (value, { min, max, type, dateFormat }) =>
  type === 'text' ||
  (type === 'number' &&
    !(
      !isNumeric(value) ||
      (min !== undefined && Number(value) < min) ||
      (max !== undefined && Number(value) > max)
    )) ||
  (type === 'date' && moment(value.substr(0, 10), dateFormat).isValid());

/**
 * Takes the input's raw value and cast it to its proper type
 *
 * @param {string} value - The raw value to be cast
 * @param {Object} inputConfig - An object containing the input config options
 * @return {*} - The cast value
 */
export const castValue = (value, defaultValue, { type, dateFormat }) =>
  ({
    text: (_) => value,
    number: (_) => Number(value),
    date: (_) => {
      const momentDate = moment(value, dateFormat);

      if (!dateFormat.includes(':')) {
        momentDate
          .set('h', defaultValue.getHours())
          .set('m', defaultValue.getMinutes());
      }

      return momentDate.toDate();
    }
  })[type]();

/**
 * Formats a value according to its data type
 *
 * @param {string} value - The value to be formatted
 * @param {Object} inputConfig - An object containing the input config options
 * @return {*} - The formatted value
 */
export const formatValue = (
  value,
  { type, dateFormat, maxDecimalPrecision = 2 }
) => {
  if (type === 'date') {
    const date = moment(value);
    return date.isValid() ? date.format(dateFormat) : '';
  } else if (type === 'number') {
    if (maxDecimalPrecision === undefined || !isNumeric(value)) return '';
    const strNumber = Number(
      (parseFloat(value) || 0).toFixed(maxDecimalPrecision)
    );
    return Number(strNumber);
  }

  return value;
};

function isNumeric(value) {
  return !(isNaN(value) || isNaN(parseFloat(value)));
}
