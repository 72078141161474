import React, { Fragment, useState, useEffect } from 'react';
import { Form, Input, Select, Upload, Avatar } from 'antd';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import './index.css';
import CardsGeneral from './CardsGeneral';
import ModalPassword from '../ModalPassword';
import { useSelector, useDispatch } from 'react-redux';
import { userService } from '../../../../services/user.service';
import styled from 'styled-components';

/** import base contant (setting urls)  */
import { base } from '../../../../services/base';

/** import common functions from utils */
import { getBase64, headersSendForm } from '../../../../utils';
import ImgCrop from 'antd-img-crop';
import * as projectActions from '../../../../redux/slices/projectSlice';
import useConfigurationPermissions from '../../../../hooks/useConfigurationPermissions';
import { withTranslation } from 'react-i18next';

function SettinsUser(props) {
  const dispatch = useDispatch();
  const stateProject = useSelector((state) => state.projectState);
  const { InfoUser, setInfoUser, stateUpload, setStateUpload, t } = props;
  const [Visible, setVisible] = useState(false);
  const permissions = useConfigurationPermissions();
  const onlyRead = permissions.user !== 'AC';

  // Execute modal
  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (InfoUser.id) {
      dispatch(
        projectActions.setProps({
          ...stateProject.props,
          changeAvatar: false
        })
      );
      updateUser();
    }
  }, [stateUpload]);

  // get new value from user
  const changeInput = (name, value) => {
    setInfoUser({
      ...InfoUser,
      [name]: value
    });
  };

  const updateUser = async () => {
    const newUser = {
      activation_token: InfoUser.activation_token,
      companyId: InfoUser.companyId,
      country: InfoUser.country,
      createdAt: InfoUser.createdAt,
      dni: InfoUser.dni,
      email: InfoUser.email,
      id: InfoUser.id,
      image: InfoUser.image,
      is_active: InfoUser.is_active,
      name: InfoUser.name,
      lastname: InfoUser.lastname,
      position: InfoUser.position,
      role: InfoUser.role,
      updatedAt: InfoUser.updatedAt
    };
    localStorage.setItem('user', JSON.stringify(newUser));
    await userService.update(newUser);
    dispatch(
      projectActions.setProps({
        ...stateProject.props,
        changeAvatar: false
      })
    );
  };

  /** this function is triggered when upload image */
  const onChangeUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setStateUpload({
        loading: true
      });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response
      getBase64(info.file.originFileObj, (imageUrl) =>
        setStateUpload({
          imageUrl,
          name: info.file.response.name,
          loading: false
        })
      );
      setInfoUser(() => ({
        ...InfoUser,
        image: info.file.response.name
      }));
      dispatch(
        projectActions.setProps({
          ...stateProject.props,
          changeAvatar: true
        })
      );
    }
  };

  /** component button */
  const uploadButton = (
    <div>
      {stateUpload.loading ? (
        <LoadingOutlined />
      ) : InfoUser.image ? (
        <Avatar size={100} src={`${InfoUser.image}`} />
      ) : (
        <Avatar
          className="FrmAvatar avatar-user-settings"
          size={100}
          icon={<UserOutlined />}
        />
      )}
    </div>
  );

  return (
    <Fragment>
      <div className="grid-settings-user-general">
        <div>
          <ImgCrop rotate shape="round" modalWidth={850}>
            <Upload
              action={`${base.api}users/upload`}
              listType="picture-card"
              showUploadList={false}
              headers={headersSendForm}
              onChange={onChangeUpload}>
              {stateUpload.imageUrl ? (
                <Avatar size={100} src={stateUpload.imageUrl} />
              ) : (
                uploadButton
              )}
            </Upload>
          </ImgCrop>
        </div>
        <div>
          <h1 className="projects-title">{t('settings.user_form.info')}</h1>
          <p className="description-form">{t('settings.user_form.name')}</p>
          <Form.Item name="group">
            <Input
              name="name"
              placeholder="Name"
              title={InfoUser ? InfoUser.name : ''}
              style={{ border: '1px solid #121212' }}
              onChange={(e) => changeInput(e.target.name, e.target.value)}
              onBlur={() => updateUser()}
              value={InfoUser ? InfoUser.name : ''}
            />
          </Form.Item>
          <p className="description-form" style={{ marginTop: '28px' }}>
            {t('settings.user_form.email')}
          </p>
          <Form.Item name="group">
            <Input
              disabled
              name="email"
              placeholder="E-mail"
              style={{ border: '1px solid #121212' }}
              onChange={(e) => changeInput(e.target.name, e.target.value)}
              onBlur={() => updateUser()}
              value={InfoUser ? InfoUser.email : ''}
            />
          </Form.Item>
        </div>

        <div style={{ marginTop: '32px' }}>
          <p className="description-form">{t('settings.user_form.lastname')}</p>
          <Form.Item name="group">
            <Input
              name="lastname"
              style={{ border: '1px solid #121212' }}
              placeholder="Last Name"
              onChange={(e) => changeInput(e.target.name, e.target.value)}
              onBlur={() => updateUser()}
              value={InfoUser ? InfoUser.lastname : ''}
            />
          </Form.Item>
          <p className="description-form" style={{ marginTop: '28px' }}>
            {t('settings.user_form.position')}
          </p>
          <Form.Item name="group">
            <Input
              disabled={onlyRead}
              name="position"
              style={{ border: '1px solid #121212' }}
              placeholder="Cargo"
              onChange={(e) => changeInput(e.target.name, e.target.value)}
              onBlur={() => updateUser()}
              value={InfoUser ? InfoUser.position : ''}
            />
          </Form.Item>
        </div>

        <div style={{ marginTop: '32px' }}>
          <p className="description-form">{t('settings.user_form.dni')}</p>
          <Form.Item name="group">
            <Input
              disabled={onlyRead}
              name="dni"
              style={{ border: '1px solid #121212' }}
              placeholder="xxxxxxxx-x"
              value={InfoUser ? InfoUser.dni : ''}
              onChange={(e) => changeInput(e.target.name, e.target.value)}
              onBlur={() => updateUser()}
            />
          </Form.Item>
          <button
            disabled={false}
            className="btn-handle-password"
            onClick={() => showModal()}>
            <b>{t('settings.user_form.change_password')}</b>
          </button>
        </div>
      </div>

      <h1 className="projects-title div-projects">
        {t('settings.user_form.projects')}
      </h1>
      <CardsGeneral {...props} />

      <ModalPassword
        Visible={Visible}
        setVisible={setVisible}
        InfoUserProp={InfoUser}
        setInfoUser={setInfoUser}
      />
    </Fragment>
  );
}

export default withTranslation()(SettinsUser);

const SelectionStyle = styled.div`
  .ant-select-selection {
    border-radius: 5px !important;
    width: 90%;
  }
  .ant-select-selection {
    background-color: white !important;
    color: #5f5f5f;
  }
  .anticon svg {
    color: transparent;
  }

  .ant-select-selection,
  ant-select-selection--single {
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    border-top: 0px;
  }

  .ant-select-selection:focus,
  ant-select-selection--single:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;
