import React, { useRef } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import {
  parseActivityDateTime,
  formatActivityDateTime,
  formatDate,
  updateGanttLocally
} from '../../utils';
import { TextInput } from '../../../TextInput';
import { activityUpdateDatesRequested } from '../../../../redux/slices/activitySlice';
import sharedStyles from './shared.module.scss';
import styles from './DatesTab.module.scss';
import { getGanttDateFormatSelector } from '../../../../redux/slices/ganttSlice';
import {
  transformDaysToHours,
  transformHourToDays
} from '../../../../views/ganttContainer/gantt/gantt.helper';
/** This function allows to create an identical object but with a new memory instance */
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

export const DatesTab = ({
  cardData,
  activityUpdateDatesRequested,
  setIsLoading,
  dateFormat,
  t,
  setCurrentCardData,
  calendarObject
}) => {
  const startDateRef = useRef();
  const restartCardData = () => {
    setCurrentCardData({
      ...cardData
    });
  };

  return (
    <div className={cn(styles['dates-tab'], sharedStyles['three-by-two-tab'])}>
      <label>
        {t('activity_card.start')}
        <TextInput
          inputRef={startDateRef}
          initialValue={parseActivityDateTime(cardData.startDate)}
          inputConfig={{ type: 'date', dateFormat }}
          onChange={(startDate) => {
            const gantt = window.to_use_react_gantt;
            const activityUpdate = updateGanttLocally(
              cardData,
              'start_date',
              startDate,
              gantt
            );
            const newDurationInDays = transformHourToDays(
              activityUpdate.duration
            );
            setCurrentCardData({
              ...cardData,
              startDate: activityUpdate.start_date,
              endDate: activityUpdate.end_date,
              duration: newDurationInDays
            });
          }}
          disabled={cardData.completion > 0}
        />
      </label>
      <label>
        {t('activity_card.duration')}
        <TextInput
          initialValue={cardData.duration}
          inputConfig={{
            type: 'number',
            min: 0,
            maxDecimalPrecision: 2
          }}
          onChange={(duration) => {
            const gantt = window.to_use_react_gantt;
            const newDuration = transformDaysToHours(duration);
            const newDurationInDays = transformHourToDays(newDuration);
            const activityUpdate = updateGanttLocally(
              cardData,
              ['duration', 'for_disable_milestone_duration'],
              [newDuration, newDuration],
              gantt
            );
            setCurrentCardData({
              ...cardData,
              startDate: activityUpdate.start_date,
              endDate: activityUpdate.end_date,
              duration: newDurationInDays
            });
          }}
          disabled={cardData.completion > 0}
        />
      </label>
      <label>
        {t('activity_card.end')}
        <TextInput
          initialValue={parseActivityDateTime(cardData.endDate)}
          inputConfig={{ type: 'date', dateFormat }}
          onChange={(endDate) => {
            const gantt = window.to_use_react_gantt;

            /** fix bug on end_date */
            const calendar = gantt.getCalendar(
              cardData.originalActivityObject.calendarId
            );
            let dateStr = endDate;

            if (Object.keys(calendarObject.customHour).length !== 0) {
              const hours = calendarObject.customHour.endHour.split(':')[0];
              const minutes = calendarObject.customHour.endHour.split(':')[1];
              dateStr.setHours(hours);
              dateStr.setMinutes(minutes);
            }

            const copyOfdateStr = cloneDeep(dateStr);
            if (dateStr < moment(cardData.startDate)) {
              restartCardData();
              return false;
            }

            if (
              moment(cardData.end_date).toISOString().split('T')[0] ===
              dateStr.toISOString().split('T')[0]
            ) {
              restartCardData();
              return false;
            } else if (dateStr < moment(cardData.startDate)) {
              restartCardData();
              return false;
              // Funcion mal utilizada deberia ser {date, unit: 'day'}
            } else if (
              !calendar.isWorkTime({ date: copyOfdateStr, unit: 'day' })
            ) {
              restartCardData();
              return false;
            } else {
              const newDuration = calendar.calculateDuration(
                new Date(cardData.startDate),
                new Date(endDate)
              );
              const newDurationInDays = transformHourToDays(newDuration);
              const activityUpdate = updateGanttLocally(
                cardData,
                ['duration', 'for_disable_milestone_duration', 'end_date'],
                [newDuration, newDuration, endDate],
                gantt
              );
              setCurrentCardData({
                ...cardData,
                duration: newDurationInDays,
                startDate: activityUpdate.start_date,
                endDate: endDate
              });
            }
          }}
          disabled={cardData.completion === 100}
        />
      </label>
      <label>
        {t('activity_card.base_start')}
        <input
          type="text"
          defaultValue={formatDate(cardData.baseStartDate, dateFormat)}
          disabled
        />
      </label>
      <label>
        {t('activity_card.base_duration')}
        <input type="text" defaultValue={cardData.baseDuration} disabled />
      </label>
      <label>
        {t('activity_card.base_end')}
        <input
          type="text"
          defaultValue={formatDate(cardData.baseEndDate, dateFormat)}
          disabled
        />
      </label>
    </div>
  );
};

const actionCreators = {
  activityUpdateDatesRequested
};

const mapStateToProps = (state) => ({
  dateFormat: getGanttDateFormatSelector(state)
});

export default connect(mapStateToProps, actionCreators)(DatesTab);
