/** React components  */
import React from 'react';

import { useDispatch } from 'react-redux';
import { MassiveUncheckIcon } from '../../icons';
import * as ganttActions from '../../redux/slices/ganttSlice';
import styles from '../ToolbarGroup/components/ToolbarItem.module.scss';
import cn from 'classnames';
export const testIds = {
  RENDER_UNCHECK_CONTAINER: 'RENDER_UNCHECK_CONTAINER'
};
const TestWrapped = ({ children }) => (
  <span
    style={{ display: 'contents' }}
    data-testid={testIds.RENDER_UNCHECK_CONTAINER}>
    {children}
  </span>
);
const defaultColor = '#121212';

export default function UncheckComponent(props) {
  const { i18nKey, t, selectedActivities } = props;
  const dispatch = useDispatch();

  /**
   * This funcion clean the selected activities vector
   */
  const handleUnselect = () => {
    dispatch(ganttActions.setSelectedActivities([]));
    const gantt = window.to_use_react_gantt;
    if (gantt) {
      gantt.detachMultiDragSelect && gantt.detachMultiDragSelect();
    }
  };

  /** get the counter lennghth dinamically */
  const counterSelectedActivities = selectedActivities.length || 0;
  const counterTextActivities =
    counterSelectedActivities > 1
      ? `${t('master_plan.selected')} ${t('master_plan.activities')}`
      : `${t('tables.gantt.text')} ${t('master_plan.selected')}`;

  return (
    <div className="wrapper-unselect">
      <TestWrapped>
        <div className="info-text">
          <span className="label-info">
            <span>{counterSelectedActivities}</span> {counterTextActivities}
          </span>
        </div>
        <button
          onClick={handleUnselect}
          className={cn(
            styles['toolbar-item--button'],
            styles['toolbar-item--uncheck'],
            styles['toolbar-item--text-color-invert'],
            styles['toolbar-item--text-color-active'],
            styles['toolbar-item--cta']
          )}>
          {<MassiveUncheckIcon color={defaultColor} />} &nbsp;{t(i18nKey)}
        </button>
      </TestWrapped>
    </div>
  );
}
