export const SET_INITIAL_LOAD_FLAG_DATA = 'SET_INITIAL_LOAD_FLAG_DATA';
export const UPDATE_PARSED_VALUE = 'UPDATE_PARSED_VALUE';
export const timelineActions = {
  setInitialFlagData: (newFlagObject) => ({
    type: SET_INITIAL_LOAD_FLAG_DATA,
    payload: newFlagObject
  }),
  updateParsedValue: (loaded) => ({
    type: UPDATE_PARSED_VALUE,
    payload: loaded
  })
};
