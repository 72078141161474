import { earlyAccessCriticalPath } from '../../../../utils/earlyAccessCriticalPath';

const criticalPathRefresh = () => {
  const gantt = window.to_use_react_gantt;
  const activity = gantt.getTaskByIndex(0);
  if (!activity) return;
  if (earlyAccessCriticalPath()) {
    gantt.calculateAsyncCriticalPath().then(() => {
      gantt.refreshData();
    });
    return;
  }

  activity.freeSlack = gantt.getFreeSlack(activity);
  activity.totalSlack = gantt.getTotalSlack(activity);
};

export { criticalPathRefresh };
