/* eslint-disable prefer-const */
import React, { useState } from 'react';

/** Import elements from library Antd */
import { Dropdown } from 'antd';

/** import icons from antd framework */
import {
  EditOutlined,
  CopyOutlined,
  DeleteFilled,
  MoreOutlined
} from '@ant-design/icons';

import { withTranslation } from 'react-i18next';
/** Redux */
import { useDispatch, useSelector } from 'react-redux';
import * as projectActions from '../../../redux/slices/projectSlice';
import useConfigurationPermissions from '../../../hooks/useConfigurationPermissions';

function ActionsMenu(props) {
  /** redux */
  const projectState = useSelector((state) => state.projectState);
  const permissions = useConfigurationPermissions();

  /** get project from props */
  const { project, t, setFormProjectVisible, setFormDeleteVisible } = props;
  const [visibleDropdown, setVisibleDropdown] = useState({ visible: false });

  /** redux */
  const dispatch = useDispatch();

  const handleMenuClick = (e, obj, type) => {
    e.stopPropagation();
    setVisibleDropdown({ visible: false });
    if (type === 'edit') {
      setFormProjectVisible(true);
      dispatch(
        projectActions.setProps({
          ...projectState.props,
          currentProjectId: project.id,
          openFormProject: true
        })
      );
    }
    if (type === 'delete') {
      setFormDeleteVisible(true);
      dispatch(projectActions.setProps(obj));
    }
  };

  const handleVisibleChange = (flag) => {
    setVisibleDropdown({ visible: flag });
  };

  if (permissions.project != 'AC') {
    return null;
  }

  /** render */
  return (
    <Dropdown
      overlayClassName="overlay-menu"
      visible={visibleDropdown.visible}
      onVisibleChange={handleVisibleChange}
      overlay={
        <span className="actionsMenu">
          <div onClick={(e) => handleMenuClick(e, {}, 'edit')}>
            <EditOutlined className="menuitem" />
            {t('edit_label')}
          </div>
          {/* <div
                        onClick={(e) => {
                            e.stopPropagation()
                            console.dir('Copy')
                        }
                        }
                    >
                        <CopyOutlined className="menuitem"/>
                        {t('copy_label')}
                    </div> */}
          <div
            onClick={(e) =>
              handleMenuClick(
                e,
                {
                  ...projectState.props,
                  modalDeleteVisible: true,
                  currentProject: project
                },
                'delete'
              )
            }>
            <DeleteFilled className="menuitem" />
            {t('delete_lable')}
          </div>
        </span>
      }>
      <MoreOutlined
        className="moreOptions"
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Dropdown>
  );
}

export default withTranslation()(ActionsMenu);
