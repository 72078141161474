import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import GridLayout, { Responsive, WidthProvider } from 'react-grid-layout';
import ProductivityChart from '../../../../components/ProductivityChart';

const ResponsiveGridLayout = WidthProvider(Responsive);
const layout = [
  { i: 'headerTitle', x: 0, y: 0, w: 12, h: 3, static: true },
  { i: 'productivityChart', x: 0, y: 16, w: 12, h: 5, isResizable: true }
];

const layouts = { lg: layout };

const ProductivityAnalytics = (props) => {
  const { header, t } = props;

  return (
    <div>
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1920, md: 1250, sm: 1000, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 6, xs: 3, xxs: 3 }}
        rowHeight={37}
        width={1820}
        className="layout">
        {header ? header() : null}

        <div
          className="car fill-height"
          key="productivityChart"
          style={{ minHeight: '500px' }}>
          <ProductivityChart t={t} />
        </div>
      </ResponsiveGridLayout>
    </div>
  );
};

export default ProductivityAnalytics;
