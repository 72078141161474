/** React components  */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Select } from 'antd';
import { constraintTypeService } from '../../../../../services/constrainttype.service';
import { constraintActions } from '../../../../../redux/actions/constraintActions';
import {
  categoriesConstraintType,
  compareValues,
  openNotification
} from '../../../../../utils';
import { CreateRoadBlockIcon, CloseIcon } from '../../../../../icons';

export default function ConstraintNew(props) {
  const { Option } = Select;

  const dispatch = useDispatch();

  const { t, visibleModal, setVisibleModal, types, setTypes } = props;

  const {
    constraintState,
    projectState: { projectSelected }
  } = useSelector((state) => state);

  const templateNewConstraints = {
    name: '',
    description: '',
    type: undefined,
    projectId: projectSelected
  };

  const alertErrorDefault = {
    title: t('settings.project_categories.constraints.title'),
    description: t('settings.error_default'),
    type: 'error'
  };

  const alertError = {
    title: t('settings.project_categories.constraints.title'),
    description: t('settings.error_default'),
    type: 'error'
  };

  const alertSuccess = {
    title: t('settings.project_categories.constraints.title'),
    description: t('settings.project_categories.constraints.success'),
    type: 'success'
  };

  const [contraintsTypes, setcontraintsTypes] = useState(
    templateNewConstraints
  );

  const handleClose = () => {
    setcontraintsTypes(templateNewConstraints);
    setVisibleModal(false);
  };

  const changeContraints = (name, value) => {
    setcontraintsTypes({
      ...contraintsTypes,
      [name]: value
    });
  };

  const handleAddContraints = async () => {
    if (!validateForm()) {
      openNotification(alertError);
      return false;
    }

    try {
      const response = await constraintTypeService.create(contraintsTypes);

      if (!response) {
        openNotification(alertErrorDefault);
        return false;
      }

      await setTypes([response, ...types]);

      const { id, name } = response;
      dispatch(
        constraintActions.setConstraintForm({
          ...constraintState.constraintForm,
          type: id,
          name
        })
      );

      openNotification(alertSuccess);
    } catch (err) {
      openNotification(alertErrorDefault);
    } finally {
      handleClose();
    }
  };

  const validateForm = () =>
    contraintsTypes.name.length && contraintsTypes.type !== undefined;

  return (
    <Modal
      closable={false}
      className="constraint__new-modal"
      visible={visibleModal}
      centered
      keyboard={false}
      maskClosable={false}
      onCancel={handleClose}
      width={320}
      footer={null}>
      <div className="new-modal__container">
        <div className="container__header">
          <div className="header__title">
            <CreateRoadBlockIcon color="#7DFF8A" />
            <h6>{t('constraint_create_type_modal_title')}</h6>
          </div>
          <div className="header__close" onClick={handleClose}>
            <CloseIcon color="#FFF" />
          </div>
        </div>
        <div className="container__body">
          <div className="body__inputs">
            <div className="inputs__area">
              <label>{t('constraint_create_type_modal_area_label')}</label>
              <Select
                className="area__select"
                dropdownClassName="area__dropdown"
                value={contraintsTypes.type}
                onChange={(value) => changeContraints('type', value)}>
                {categoriesConstraintType
                  .sort(compareValues(t('lang') === 'es' ? 'labelEs' : 'label'))
                  .map((contraint) => (
                    <Option name="task_creter" key={contraint.value}>
                      {t(`settings.project_categories.${contraint.trad}`)}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="inpust__name">
              <label>{t('constraint_create_type_modal_name_label')}</label>
              <input
                name="name"
                value={contraintsTypes.name}
                autoComplete="off"
                onChange={(e) =>
                  changeContraints(e.target.name, e.target.value)
                }
              />
            </div>
          </div>
          <div className="body__actions">
            <button className="actions__cancel" onClick={() => handleClose()}>
              {t('constraint_create_type_modal_cancel_button')}
            </button>
            <button
              className="actions__add"
              onClick={() => handleAddContraints()}
              disabled={!validateForm()}>
              {t('constraint_create_type_modal_add_button')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
