const resetMultiSelectTaskPosition = (toExecuteTasks) => {
  const gantt = window.to_use_react_gantt;
  toExecuteTasks.forEach((taskId) => {
    const task = gantt.getTask(taskId);
    task.constraint_type = 'snet';
    task.constraint_date = task.start_date;
  });
};

export { resetMultiSelectTaskPosition };
