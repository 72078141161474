import moment from 'moment';
import { getTaskCalendar } from '../utils/getTaskCalendar';

const getStartAndEndHours = (task) => {
  const gantt = window.to_use_react_gantt;
  if (!gantt) return;

  const calendar = getTaskCalendar(task, gantt);
  const startDate = task.start_date;
  startDate.setHours(0);
  startDate.setMinutes(0);

  const startHour = getClosesWorkTime({
    task,
    gantt,
    startDate,
    calendar,
    dir: 'future'
  });

  const endHour = getClosesWorkTime({
    task,
    gantt,
    startDate,
    calendar,
    dir: 'past'
  });

  return {
    start: {
      hour: moment(startHour).format('HH'),
      minutes: moment(startHour).format('mm')
    },
    end: {
      hour: moment(endHour).format('HH'),
      minutes: moment(endHour).format('mm')
    }
  };
};

function getClosesWorkTime({
  task,
  gantt,
  startDate,
  calendar,
  dir = 'future'
}) {
  return calendar.getClosestWorkTime({
    dir: dir,
    date: gantt.date.parseDate(startDate, 'xml_date'),
    unit: gantt.config.duration_unit,
    task: task
  });
}

export { getStartAndEndHours };
