import { Trans } from 'react-i18next';

const getLocales = (t, constraint = {}, isMultipleDrag) => {
  let title = null;
  let dontMoveOption = null;
  let continueOption = null;

  const texts = {
    subtitle: t('modals.gantt.constraint_validation.subtitle'),
    dontMove: t('modals.gantt.constraint_validation.dont_move'),
    saveConfiguration: t(
      'modals.gantt.constraint_validation.save_this_configuration'
    ),
    cancel: t('modals.gantt.constraint_validation.cancel'),
    accept: t('modals.gantt.constraint_validation.accept')
  };
  if (isMultipleDrag) {
    title = t('modals.gantt.constraint_validation.multiple_drag.title');
    dontMoveOption = t(
      'modals.gantt.constraint_validation.multiple_drag.dont_move'
    );
    continueOption = t(
      'modals.gantt.constraint_validation.multiple_drag.continue'
    );
  } else {
    title = t('modals.gantt.constraint_validation.title', {
      constraintType: constraint.type,
      constraintDate: constraint.date
    });
    dontMoveOption = t('modals.gantt.constraint_validation.dont_move');
    continueOption = t('modals.gantt.constraint_validation.continue', {
      constraintType: constraint.type
    });
  }

  texts.title = title;
  texts.dontMove = dontMoveOption;
  texts.continue = continueOption;
  return texts;
};

export { getLocales };
