import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { activityService } from '../../../services/activity.service';
import { calendarService } from '../../../services/calendar.service';
import { projectService } from '../../../services/project.service';
import { subContractService } from '../../../services/subcontract.service';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { Avatar } from 'antd';
import Pdf from 'react-to-pdf';
// import { Table, Tag, Space } from 'antd';
// import PDF, { Text, AddPage, Line, Image, Table, Html } from 'jspdf-react'
import logoOrPrimavera from '../../../assets/img/importer/primavera.png';
import { BorderLeftOutlined } from '@ant-design/icons';
import priority_normal from '../../../assets/img/PR-Blue-50x50.png';
import priority_baja from '../../../assets/img/PR-Gray-50x50.png';
import priority_alta from '../../../assets/img/PR-Yellow-50x50.png';
import priority_maxima from '../../../assets/img/PR-Red-50x50.png';
import aspa from '../../../assets/img/vaidate/image (10).png';
import cruz from '../../../assets/img/vaidate/image (9).png';
import logo from '../../../assets/img/iso.png';
import child_arrow from '../../../assets/img/child-arrow.png';
import { ganttAPI } from '../../../utils/customGanttPlugin';

import { styles } from './style.js';
const ref = React.createRef();

export default function WeekReportCommitClosed(props) {
  const week_state = props.week;
  const planificationDay = props.planificationday;
  const sector_url = parseInt(props.sectorId);
  const project_url = parseInt(props.projectId);
  const projectState = useSelector((state) => state.projectState);
  const [activities, setActivities] = useState([]);
  const [resource, setResource] = useState([]);
  const [project, setProject] = useState(null);
  const [sector, setSector] = useState(null);
  const [subContract, setSubContract] = useState([]);
  const [commitmentstate, setCommitmentstate] = useState('Comprometida');
  const [data, setData] = useState([]);
  let init_date = moment()
    .lang('es')
    .year('2020')
    .week(week_state)
    .startOf('isoweek')
    .add('days', planificationDay);
  let end_date = init_date.clone().add(6, 'day');
  const [loadpdf, setLoadpdf] = useState(false);
  const [calendars, setCalendars] = useState([]);
  let pagina = 0;
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Age',
      dataIndex: 'age'
    },
    {
      title: 'Address',
      dataIndex: 'address'
    }
  ];

  const loadCalendars = (calendars) => {
    /** This method load calendars to Gantt API and also creates a custom version to use custom calculate duration */
    const customVersionCalendars = ganttAPI.loadCalendars(calendars);
  };

  useEffect(() => {
    if (calendars.length) {
      loadCalendars(calendars);
    }
  }, [calendars]);

  const head = [
    [
      'ID',
      'Name',
      'Country',
      'Country',
      'Country',
      'Country',
      'Country',
      'Country',
      'Country',
      'Country'
    ]
  ];
  const body = [
    [1, 'Shaw', 'Tanzania'],
    [2, 'Nelson', 'Kazakhstan'],
    [3, 'Garcia', 'Madagascar']
  ];

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf'
  });

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf'
  });

  useEffect(() => {
    getData();
    setTimeout(() => {}, 1000);
  }, []);

  async function getData() {
    const project = await projectService.show(project_url);
    const sectorFind = projectState.allSectors.find(
      (e) => e.id == projectState.sectorSelected
    );
    const init_planification = moment()
      .lang('es')
      .year('2020')
      .week(parseInt(week_state))
      .startOf('isoweek')
      .add('days', project.project.planification_day - 1);
    const config = {
      end: end_date.format('YYYY/MM/DD'),
      ignore_dates: false,
      sector_id: sector_url,
      start: init_date.format('YYYY/MM/DD'),
      week: parseInt(week_state),
      year: parseInt(init_date.format('YYYY'))
    };
    const data = await activityService.getLookaheadweekcommitWeek(config);
    const calendars = await calendarService.showBySector(sector_url);

    const subcontractsGet = await subContractService.getByProject(project_url);
    setSubContract(subcontractsGet.subcontracts);
    setCalendars(calendars.calendar);
    setProject(project.project);
    setSector(sectorFind);
    const activitys_array = [];
    const activitys = [];
    let task_total = 0;
    data.activities
      .sort((a, b) => parseInt(a.correlative_id) - parseInt(b.correlative_id))
      .map((atis) => {
        let validate_push = false;
        const temp_actis = { ...atis };
        // let activity = atis;
        const tasks = [];
        // let newArray = atis.tasks.filter(a => a.lean_status == "Will" || a.lean_status == "Committed");
        const calendar = ganttAPI.getTaskCalendar(atis.calendarId);
        atis.tasks.map((task) => {
          if (task.lean_status == 'Will' || task.lean_status == 'Committed') {
            task_total++;
            init_date = moment()
              .lang('es')
              .year('2020')
              .week(week_state)
              .startOf('isoweek');
            end_date = moment()
              .lang('es')
              .year('2020')
              .week(week_state)
              .endOf('isoweek');
            const init_date_v = moment()
              .lang('es')
              .year('2020')
              .week(week_state)
              .startOf('isoweek');
            const end_date_v = moment()
              .lang('es')
              .year('2020')
              .week(week_state)
              .endOf('isoweek');
            const start_validate = init_date.isBetween(
              moment(task.start_date),
              moment(task.end_date),
              'days',
              false
            );
            const end_validate = end_date.isBetween(
              moment(task.start_date),
              moment(task.end_date),
              'days',
              false
            );

            let validate_week_range_count = 1;
            let validate_week_range = false;

            while (validate_week_range_count < 8) {
              if (!validate_week_range) {
                validate_week_range = init_date_v
                  .day(validate_week_range_count)
                  .isBetween(
                    moment(task.start_date).subtract(1, 'd'),
                    moment(task.end_date).add(1, 'd'),
                    'days',
                    false
                  );
              }
              validate_week_range_count++;
            }
            let validate_week_calendar_count = 1;
            let validate_week_calendar = false;
            while (validate_week_calendar_count < 8) {
              const copyOfDate = new Date(
                init_date
                  .day(validate_week_calendar_count)
                  .format('YYYY/MM/DD H:mm')
              );
              // Funcion mal utilizada deberia ser {date, unit: 'day'}
              if (calendar.isWorkTime({ date: copyOfDate, unit: 'day' })) {
                validate_week_calendar = true;
              }
              validate_week_calendar_count++;
            }
            if (validate_week_range && validate_week_calendar) {
              validate_push = true;
              tasks.push(task);
            }
          }
          if (task.lean_status == 'Committed') {
            setCommitmentstate('Comprometida');
          }
        });
        temp_actis.tasks = tasks;
        if (validate_push) {
          activitys.push(temp_actis);
        }
      });
    let task_order = 0;
    let activitys_respal = [];
    activitys.map((atis) => {
      const validate_paginatos = task_order + atis.tasks.length + 1;
      if (validate_paginatos < 26) {
        activitys_respal.push(atis);
        task_order = validate_paginatos;
      } else {
        const sumables = 26 - task_order;
        const temporal_atis = { ...atis };
        const temporal_task = atis.tasks;
        atis.tasks = temporal_task.slice(0, sumables - 1);

        activitys_respal.push(atis);
        activitys_array.push(activitys_respal);
        activitys_respal = [];
        task_order = 0;
        temporal_atis.tasks = temporal_task.slice(
          sumables - 1,
          temporal_task.length - 1
        );
        task_order = temporal_atis.tasks.length + 1;
        activitys_respal.push(temporal_atis);
      }
    });
    activitys_array.push(activitys_respal);
    setActivities(activitys_array);
    setResource(data.sectorReourse);
    setLoadpdf(true);
  }

  if (loadpdf) {
    return (
      <div style={{ width: '100%' }}>
        <PDFViewer style={{ width: '100%', height: '800px' }}>
          <Document>
            {activities.map((activitys) => (
              <Page size="letter" style={styles.page} orientation="landscape">
                <View style={styles.section_header}>
                  <Image
                    style={styles.image_logo}
                    src={project.image ? project.image : logo}
                    allowDangerousPaths={true}
                  />
                  <View style={styles.section}>
                    <Text style={styles.title}>
                      Plan Semanal{' '}
                      <Text
                        style={
                          commitmentstate == 'Comprometida'
                            ? styles.title_lean_state_com
                            : styles.title_lean_state
                        }>
                        {' '}
                        {commitmentstate}
                      </Text>
                    </Text>
                    <Text style={styles.title_name}>
                      {project ? project.name : ''}
                    </Text>
                  </View>
                  {/* <View style={styles.section_PCC}>
                                        <Text style={styles.title_head_week}>PPC</Text>
                                        <Text style={styles.subtitle_head_week}>{init_date.format("DD MMM")+" - "+end_date.format("DD MMM")}</Text>
                        </View> */}
                  <View style={styles.section_more}>
                    <Text style={styles.title_head_week}>
                      Semana {init_date.week()}
                    </Text>
                    <Text style={styles.subtitle_head_week}>
                      {init_date.format('DD MMM') +
                        ' - ' +
                        end_date.format('DD MMM')}
                    </Text>
                  </View>
                </View>
                <View style={styles.table}>
                  {getHeaderTablePdf()}
                  {activitys.map((activity) => (
                    <View>
                      <View style={styles.section_activity}>
                        <Text style={styles.section_activity_title_name}>
                          {activity.activityRoute + '->' + activity.name}
                        </Text>
                      </View>
                      {activity.tasks.map((task) => {
                        if (task.children.length > 0) {
                          return recursiveChild(task, activity, 0);
                          // return
                          // return recursiveChild(task,activity);
                        }
                        return getTaskTable(task, activity, 0);
                      })}
                    </View>
                  ))}
                </View>
                <View style={styles.pagination}>
                  <Text style={styles.pagination_text}>Powered by IPSUM</Text>
                  <Text style={styles.pagination_text_count}>
                    Pagina {++pagina}
                  </Text>
                </View>
              </Page>
            ))}
          </Document>
        </PDFViewer>
      </div>
    );
  }
  return null;

  function recursiveChild(task, activity, padding) {
    if (task.children.length == 0) {
      return getTaskTable(task, activity, padding);
    }
    const tem_padding = padding + 4;
    return [
      getTaskTableParent(task, activity, padding),
      task.children.map((chdm) => recursiveChild(chdm, activity, tem_padding))
    ];
  }

  function getHeaderTablePdf() {
    return (
      <View style={styles.section_header}>
        <View style={styles.table_header_texto}>
          <Text style={styles.table_heade_title_name}>Tareas</Text>
        </View>
        <View style={styles.table_header}>
          <Text style={styles.table_heade_title_name}>Prioridad</Text>
        </View>
        <View style={styles.table_header}>
          <Text style={styles.table_heade_title_name}>Responsable</Text>
        </View>
        <View style={styles.table_header}>
          <Text style={styles.table_heade_title_name}>Subcontratos</Text>
        </View>
        <View style={styles.table_header}>
          <Text style={styles.table_heade_title_name}>Avance</Text>
        </View>
        <View style={styles.table_header}>
          <Text style={styles.table_heade_title_name}>Compromiso</Text>
        </View>
        <View style={styles.table_header}>
          <Text style={styles.table_heade_title_name}>{'PPC           '}</Text>
        </View>
        <View style={styles.table_header}>
          <Text style={styles.table_heade_title_name}>Material</Text>
        </View>
        <View style={styles.table_header}>
          <Text style={styles.table_heade_title_name}>Real</Text>
        </View>

        <View style={styles.table_header_days}>
          <Text style={styles.table_heade_title_name}>
            L{init_date.day(1).format('DD')}
          </Text>
        </View>
        <View style={styles.table_header_days}>
          <Text style={styles.table_heade_title_name}>
            M{init_date.day(2).format('DD')}
          </Text>
        </View>
        <View style={styles.table_header_days}>
          <Text style={styles.table_heade_title_name}>
            M{init_date.day(3).format('DD')}
          </Text>
        </View>
        <View style={styles.table_header_days}>
          <Text style={styles.table_heade_title_name}>
            J{init_date.day(4).format('DD')}
          </Text>
        </View>
        <View style={styles.table_header_days}>
          <Text style={styles.table_heade_title_name}>
            V{init_date.day(5).format('DD')}
          </Text>
        </View>
        <View style={styles.table_header_days}>
          <Text style={styles.table_heade_title_name}>
            S{init_date.day(6).format('DD')}
          </Text>
        </View>
        <View style={styles.table_header_days}>
          <Text style={styles.table_heade_title_name}>
            D{init_date.day(7).format('DD')}
          </Text>
        </View>
      </View>
    );
  }

  function activity_layaout() {
    return activities.map((activity) => {
      return getActivityTable(activity);
      activity.tasks.map((task) => getTaskTable(task));
    });
  }

  function getActivityTable(activity) {
    return (
      <View style={styles.section_activity}>
        <Text style={styles.section_activity_title_name}>
          {activity.activityRoute + '->' + activity.name}
        </Text>
      </View>
    );
  }

  function getTaskTableParent(task, activity, parent) {
    const image = '';
    let newArray = [];
    if (task.materialId) {
      newArray = resource.filter((r) => r.id == task.materialId);
    }
    return (
      <View style={styles.section_task_parent}>
        <View style={styles.table_task_texto_parent}>
          <Image
            style={{
              width: '6px',
              height: '6px',
              // backgroundColor: "#61C74F",
              // marginTop:"18px",
              marginLeft: parent + 'px',
              // marginLeft:"15px",
              display: parent != 0 ? '' : 'none'
            }}
            source={child_arrow}></Image>
          <Text
            style={{
              height: 'auto',
              fontSize: 7,
              fontFamily: 'Roboto',
              // marginLeft: parent+"px",
              fontStyle: 'normal',
              fontWeight: '1200',
              color: '#000000'
            }}>
            {task.name}
          </Text>
        </View>
        <View style={styles.table_task_priority}>
          <Image style={styles.image} source={getPriorityimg(task.priority)} />
        </View>
        <View style={styles.table_task_subcontract}>
          <Text style={styles.table_task_title_name}>
            {task.responsables.map((responsables) => responsables.name + ' ')}
          </Text>
        </View>
        <View style={styles.table_task_subcontract}></View>
        <View style={styles.table_task_priority}>
          {task.progress >= task.taskcommitments[0].commitment_percentaje ? (
            <Text style={styles.table_task_title_name_acepted}>
              {task.progress}%
            </Text>
          ) : (
            <Text style={styles.table_task_title_name_rechaced}>
              {task.progress}%
            </Text>
          )}
        </View>
        <View style={styles.table_task_commitment}>
          <Text style={styles.table_task_title_name}>
            {task.taskcommitments.length > 0
              ? task.taskcommitments[0].commitment_percentaje + '%'
              : ' -'}
          </Text>
        </View>
        <View style={styles.table_task_resource}>
          <Text style={styles.table_task_title_name}>
            {newArray.length > 0 ? newArray[0].name : ' -'}
          </Text>
        </View>
        <View style={styles.table_task_real}>
          <Text style={styles.table_task_title_name}>
            {(task.progress * task.total_quantity) / 100}
          </Text>
        </View>
        <View style={styles.table_task_resource}></View>

        <View style={styles.table_task_days}></View>
        <View style={styles.table_task_days}></View>
        <View style={styles.table_task_days}></View>
        <View style={styles.table_task_days}></View>
        <View style={styles.table_task_days}></View>
        <View style={styles.table_task_days}></View>
        <View style={styles.table_task_days_parent}></View>
      </View>
    );
  }

  function getTaskTable(task, activity, parent) {
    const image = '';
    let newArray = [];
    if (task.materialId) {
      newArray = resource.filter((r) => r.id == task.materialId);
    }

    let sub = '';
    let sub_ = false;
    if (subContract) sub_ = subContract.find((e) => e.id == task.subcontractId);
    if (sub_) {
      sub = sub_.name;
    }
    return (
      <View style={styles.section_task}>
        <View style={styles.table_task_texto}>
          <Image
            style={{
              width: '6px',
              height: '6px',
              // backgroundColor: "#61C74F",
              // marginTop:"18px",
              marginLeft: parent + 'px',
              // marginLeft:"15px",
              display: parent != 0 ? '' : 'none'
            }}
            source={child_arrow}></Image>
          <Text
            style={{
              height: 'auto',
              width: '100%',
              fontSize: 7,
              fontFamily: 'Roboto',
              // marginLeft: parent+"px",
              fontStyle: 'normal',
              color: '#121212'
            }}>
            {task.name}
          </Text>
        </View>
        <View style={styles.table_task_priority}>
          <Image style={styles.image} source={getPriorityimg(task.priority)} />
        </View>
        <View style={styles.table_task_subcontract}>
          <Text style={styles.table_task_title_name}>
            {task.responsables.map((responsables) => responsables.name + ' ')}
          </Text>
        </View>
        <View style={styles.table_task_subcontract}>
          <Text style={styles.table_task_title_name}>{sub}</Text>
        </View>
        <View style={styles.table_task_priority}>
          <Text style={styles.table_task_title_name}>
            {task.taskcommitments[0].realized_percentaje.toFixed(2)}%
          </Text>
        </View>
        <View style={styles.table_task_commitment}>
          {task.taskcommitments[0].realized_percentaje >=
          task.taskcommitments[0].commitment_percentaje ? (
            <Text style={styles.table_task_title_name_acepted}>
              {task.taskcommitments.length > 0
                ? task.taskcommitments[0].commitment_percentaje.toFixed(2) + '%'
                : ' -'}
            </Text>
          ) : (
            <Text style={styles.table_task_title_name_rechaced}>
              {task.taskcommitments.length > 0
                ? task.taskcommitments[0].commitment_percentaje.toFixed(2) + '%'
                : ' -'}
            </Text>
          )}
        </View>
        <View style={styles.table_task_resource}>
          {task.taskcommitments[0].realized_percentaje >=
          task.taskcommitments[0].commitment_percentaje ? (
            <Image style={styles.image_dos} source={aspa} />
          ) : (
            <Image style={styles.image_dos} source={cruz} />
          )}
        </View>
        <View style={styles.table_task_resource}>
          <Text style={styles.table_task_title_name}>
            {newArray.length > 0 ? newArray[0].name : ' -'}
          </Text>
        </View>
        <View style={styles.table_task_real}>
          <Text style={styles.table_task_title_name}>
            {(task.progress * task.total_quantity) / 100}
          </Text>
        </View>

        <View
          style={
            validateDay(task, activity, 1)
              ? styles.table_task_days_work
              : styles.table_task_days
          }></View>
        <View
          style={
            validateDay(task, activity, 2)
              ? styles.table_task_days_work
              : styles.table_task_days
          }></View>
        <View
          style={
            validateDay(task, activity, 3)
              ? styles.table_task_days_work
              : styles.table_task_days
          }></View>
        <View
          style={
            validateDay(task, activity, 4)
              ? styles.table_task_days_work
              : styles.table_task_days
          }></View>
        <View
          style={
            validateDay(task, activity, 5)
              ? styles.table_task_days_work
              : styles.table_task_days
          }></View>
        <View
          style={
            validateDay(task, activity, 6)
              ? styles.table_task_days_work
              : styles.table_task_days
          }></View>
        <View
          style={
            validateDay(task, activity, 7)
              ? styles.table_task_days_work
              : styles.table_task_days_end
          }></View>
      </View>
    );
  }

  function validateDay(task, activity, day) {
    const calendar = ganttAPI.getTaskCalendar(activity.calendarId);
    const validate_date = moment()
      .lang('es')
      .year('2020')
      .week(week_state)
      .startOf('isoweek')
      .set('hour', '9');
    const copyOfDate = new Date(
      validate_date.day(day).format('YYYY/MM/DD H:mm')
    );
    const validate_day = validate_date.isBetween(
      moment(task.start_date).subtract(1, 'd'),
      moment(task.end_date).add(1, 'd'),
      'days',
      false
    );
    if (validate_day) {
      // Funcion mal utilizada deberia ser {date, unit: 'day'}
      return calendar.isWorkTime({ date: copyOfDate, unit: 'day' });
    }
    return false;
  }

  function getPriorityimg(name) {
    let image = '';
    if (name == 'Urgent') {
      image = priority_maxima;
    }
    if (name == 'High') {
      image = priority_alta;
    }
    if (name == 'Normal') {
      image = priority_normal;
    }
    if (name == 'High') {
      image = priority_maxima;
    }
    if (name == 'Low') {
      image = priority_baja;
    }
    return image;
  }

  function calculeRango() {}
}
