import XlsxPopulate from 'xlsx-populate';
import { Constants } from '../constants/components';
import { Colors } from '../constants/colors.constants';
import moment from 'moment';

export const processTask = (activity, task, dateFormat) => {
  if (task.taskcommitments.length === Constants.EMPTY_NUMBER) return null;

  const responsableNames =
    task.responsables.length > Constants.EMPTY_NUMBER
      ? task.responsables
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((responsable) => responsable.name + ' ' + responsable.lastname)
          .join(', ')
      : Constants.EMPTY_TEXT;
  const commitment = task.taskcommitments[Constants.FIRST_ELEMENT_INDEX] || {};

  return {
    wbs: activity.activityRoute,
    start_date: moment(task.start_date).format(dateFormat.replace('hh', 'HH')),
    end_date: moment(task.end_date).format(dateFormat.replace('hh', 'HH')),
    task: task.name,
    responsable: responsableNames,
    commitment: commitment.commitment_percentaje || Constants.EMPTY_NUMBER,
    progress: commitment.weekcommitment?.closed
      ? commitment.realized_percentaje
      : task.progress,
    company: task.subcontract?.name || Constants.EMPTY_TEXT,
    crew_size: task.plan_endowment || Constants.EMPTY_NUMBER
  };
};

export const processActivity = (activity, dateFormat) => {
  const processedTasks = [];
  const stack = [...activity.tasks];

  while (stack.length) {
    const task = stack.pop();
    const processedTask = processTask(activity, task, dateFormat);
    if (processedTask) processedTasks.push(processedTask);
    stack.push(...task.children);
  }

  return processedTasks;
};

export const getSheetData = (finalData, t) => {
  const headers = Object.keys(finalData[Constants.FIRST_SHEET_INDEX]).map(
    (key) => t(`weekly_plan_excel_export.${key}`)
  );
  return [headers, ...finalData.map((row) => Object.values(row))];
};

export const createExcelFile = async (sheetData) => {
  const workbook = await XlsxPopulate.fromBlankAsync();
  const resultSheet = workbook.sheet(Constants.FIRST_SHEET_INDEX);
  const lastColumn = String.fromCharCode(
    Constants.ASCII_BEFORE_A + sheetData[Constants.FIRST_ELEMENT_INDEX].length
  );

  resultSheet.cell(Constants.FIRST_CELL).value(sheetData);
  resultSheet.range(`${Constants.FIRST_CELL}:${lastColumn}1`).style({
    bold: true,
    fill: Colors.GRAY_3.slice(1)
  });
  resultSheet.usedRange().style('border', true);

  return workbook.outputAsync();
};
