import React from 'react';
export const GridCellLocationTree =
  (
    activities,
    units,
    selectedActivities,
    selectedUnits,
    locationArray,
    configuration,
    calculateExpected,
    ganttAPI
  ) =>
  ({ columnIndex, rowIndex, style }) => {
    const activity = activities[rowIndex];
    const location = locationArray[columnIndex];

    /**
     * To asssign flow
     */
    const isSelectedActivity = selectedActivities.find(
      (act) => act.id == activity.id && !activity.disable
    );

    const customClass = ' border-rigth-gray-matrix';

    let isSomeOfUnitSelected = false;

    let nUnitToCreate = 0;
    for (let i = 0; i < location.productionUnits.length; i++) {
      const unit = location.productionUnits[i];
      const isUnitInSelectedArray = selectedUnits.find(
        (un) => un.id == unit.id && !unit.disable
      );
      if (isUnitInSelectedArray) {
        nUnitToCreate += 1;
        isSomeOfUnitSelected = true;
      }
    }

    /**
     * Already assigned flow
     */
    let nAlreadyAssignedActivities = 0;
    const ac = activity;
    location.productionUnits.map((pu) => {
      const isActivityInSelectedArray = pu.activities.find(
        (act) => act.id == ac.id && ac.disable
      );
      if (isActivityInSelectedArray) {
        nAlreadyAssignedActivities += 1;
      }
    });

    // let nAlreadyAssignedUnits = 0
    // for (let i = 0; i < location.productionUnits.length; i++) {
    //     const unit = location.productionUnits[i]
    //     const isUnitInSelectedArray = selectedUnits.find(un => ((un.id == unit.id) && unit.disable))
    //     if (isUnitInSelectedArray) {
    //         nAlreadyAssignedUnits += 1
    //     }
    // }

    const nAlreadyAssigned = nAlreadyAssignedActivities;

    if (isSelectedActivity && isSomeOfUnitSelected) {
      return (
        <div
          className={'grid-element-with-units-matrix' + customClass}
          style={style}>
          {Number(nUnitToCreate)}{' '}
          {nAlreadyAssigned ? `${nAlreadyAssigned}` : null}
        </div>
      );
    }
    return (
      <div
        className={
          'grid-element-without-units-matrix' +
          customClass +
          (nAlreadyAssigned ? ' grid-element-with-units-matrix' : '')
        }
        style={style}>
        {nAlreadyAssigned ? `${nAlreadyAssigned}` : null}
      </div>
    );
  };

/**
 * This component is the rendered element between Y (activity) and X (location) which show the match of selected items
 * @param {*} activities all activities array
 * @param {*} units all locations array
 * @param {*} selectedActivities selected activities array
 * @param {*} selectedUnits selected units array
 * @param {*} locationArray locations with units array
 */
export const GridCellLocationGroupped =
  (
    activities,
    units,
    selectedActivities,
    selectedUnits,
    locationArray,
    configuration,
    statusColors,
    calculateExpected,
    ganttAPI
  ) =>
  ({ columnIndex, rowIndex, style }) => {
    const grouppedActivity = activities[rowIndex];
    const location = locationArray[columnIndex];
    let totalTasks = 0;
    const navigatorLang = navigator.language || navigator.userLanguage;
    let hasBase = false;
    /**
     * To asssign flow
     */
    let isSelectedActivity = false;
    const activitiesMatched = [];

    /** Goes trhough each groupped activity */
    grouppedActivity.activities.forEach((activity) => {
      /** Find if some of groupped activities is in selected activities array */
      const isActivityInSelectedArray = selectedActivities.find(
        (act) => act.id == activity.id && !activity.disable
      );
      if (isActivityInSelectedArray) {
        isSelectedActivity = true;
        activitiesMatched.push(isActivityInSelectedArray);
      }
    });

    const dataToMatch = [];

    /** Then map all location units, and find if some of these locations are selected */
    const locationUnitsSelected = [];
    location.productionUnits.forEach((unit) => {
      activitiesMatched.map((ac) => {
        const hasActivityThisUnit = ac.productionUnits.find(
          (u) => u.id == unit.id
        );
        if (hasActivityThisUnit) {
          dataToMatch.push(hasActivityThisUnit);
        }
      });
    });

    const tasksArray = [];

    dataToMatch.map((el) => {
      if (el.tasks) {
        const taskObject = el.tasks.find(
          (t) => t.id == el.productionunitactivity.taskId
        );
        if (taskObject) tasksArray.push(taskObject);
      }
    });

    if (grouppedActivity.activities[columnIndex]) {
      totalTasks = tasksArray.length;
      if (
        tasksArray.length >
        grouppedActivity.activities[columnIndex].productionUnits.length
      ) {
        if (
          tasksArray.length / 2 ==
          grouppedActivity.activities[columnIndex].productionUnits.length
        ) {
          totalTasks =
            grouppedActivity.activities[columnIndex].productionUnits.length * 2;
        } else {
          totalTasks =
            grouppedActivity.activities[columnIndex].productionUnits.length;
        }
      }
    }
    /** Defines expected */
    tasksArray.map((task) => {
      // const parentActivity = grouppedActivity.activities.find(ac => ac.id == task.activityId)
      if (task.base_start_date !== null) {
        // task.expected = calculateExpected(task, ganttAPI, parentActivity.calendarId, null, configuration.visualization.expected.base)
        hasBase = true;
      }
      // else {
      //     task.expected = calculateExpected(task, ganttAPI, parentActivity.calendarId, null, false)
      // }
    });

    const doneTasks = tasksArray.filter(
      (singleTask) => singleTask.progress >= 99.99 && singleTask.ponderator > 0
    );
    const inProgressTasks = tasksArray.filter(
      (singleTask) => singleTask.progress > 0 && singleTask.progress < 99.99
    );
    const expectedTasks = tasksArray.filter((t) => t.expected >= 99.99);
    const withProgressTasks = tasksArray.filter(
      (singleTask) => singleTask.progress > 0
    );

    /** Then get location last index to match if current columnIndex match with last location container */
    let customClass = '';
    if (location) {
      const lastIndex = location.lastIndex;
      if (columnIndex == lastIndex - 1) {
        customClass += ' border-rigth-gray-matrix';
      }
    }

    if (columnIndex == activities.length - 1) customClass = '';

    const {
      statusVisualization: { generalStatus, expectedStatus, progressStatus }
    } = configuration;

    let progressAcumulator = 0;
    let acumPonderators = 0;
    tasksArray.map((t) => {
      progressAcumulator += t.progress * t.ponderator;
      acumPonderators += t.ponderator;
    });

    let acumProgress = progressAcumulator / acumPonderators;
    if (!acumProgress) {
      acumProgress = 0;
    }

    let progressExpectedAcumulator = 0;
    tasksArray.map((t) => {
      progressExpectedAcumulator += t.expected * t.ponderator;
    });

    let acumProgressExpected = progressExpectedAcumulator / acumPonderators;
    if (!acumProgressExpected) {
      acumProgressExpected = 0;
    }

    let color = '#FFFFFF';
    let backgroundColor;
    if (generalStatus) {
      if (doneTasks.length == tasksArray.length) {
        backgroundColor = statusColors.generalStatus[0].color;
      } else if (inProgressTasks.length) {
        backgroundColor = statusColors.generalStatus[1].color;
      } else {
        color = '#121212';
        backgroundColor = statusColors.generalStatus[2].color;
      }
    } else if (expectedStatus) {
      if (configuration.visualization.values.percentaje) {
        if (acumProgress >= 99.98) {
          backgroundColor = statusColors.expectedStatus[1].color;
        } else if (acumProgress > acumProgressExpected) {
          backgroundColor = statusColors.expectedStatus[0].color;
        } else if (acumProgress == acumProgressExpected) {
          backgroundColor = statusColors.expectedStatus[2].color;
        } else if (acumProgress < acumProgressExpected) {
          backgroundColor = statusColors.expectedStatus[3].color;
        }
      } else {
        if (doneTasks.length == tasksArray.length) {
          backgroundColor = statusColors.expectedStatus[1].color;
        } else if (doneTasks.length > expectedTasks.length) {
          backgroundColor = statusColors.expectedStatus[0].color;
        } else if (doneTasks.length == expectedTasks.length) {
          backgroundColor = statusColors.expectedStatus[2].color;
        } else if (doneTasks.length < expectedTasks.length) {
          backgroundColor = statusColors.expectedStatus[3].color;
        }
      }
    } else if (progressStatus) {
      if (acumProgress >= 99.98) {
        backgroundColor = statusColors.progressStatus[0].color;
      } else if (acumProgress >= 75 && acumProgress < 99.99) {
        backgroundColor = statusColors.progressStatus[1].color;
      } else if (acumProgress >= 50 && acumProgress < 75) {
        backgroundColor = statusColors.progressStatus[2].color;
      } else if (acumProgress >= 25 && acumProgress < 50) {
        backgroundColor = statusColors.progressStatus[3].color;
      } else if (acumProgress > 0 && acumProgress < 25) {
        backgroundColor = statusColors.progressStatus[4].color;
      }
    }

    if (dataToMatch.length) {
      /** Mode UNIT / ACTUAL */
      if (
        configuration.visualization.values.units &&
        configuration.visualization.expected.total
      ) {
        return (
          <div
            className={'grid-element-with-units-matrix' + customClass}
            style={{ ...style, backgroundColor, color }}>
            {doneTasks.length} / {tasksArray.length}
          </div>
        );

        /** Mode PERCENTAJE / ACTUAL */
      } else if (
        configuration.visualization.values.percentaje &&
        configuration.visualization.expected.total
      ) {
        return (
          <div
            className={'grid-element-with-units-matrix' + customClass}
            style={{ ...style, backgroundColor, color }}>
            {navigatorLang.includes('en')
              ? acumProgress.toFixed(1)
              : acumProgress.toFixed(1).replace('.', ',')}
            %
          </div>
        );
        /** Mode UNIT / EXPECTED */
      } else if (
        configuration.visualization.values.units &&
        configuration.visualization.expected.expected
      ) {
        if (!configuration.visualization.expected.base) {
          return (
            <div
              className={'grid-element-with-units-matrix' + customClass}
              style={{ ...style, backgroundColor, color }}>
              {doneTasks.length} / {expectedTasks.length}
            </div>
          );
        }
        return (
          <div
            className={'grid-element-with-units-matrix' + customClass}
            style={{ ...style, backgroundColor, color }}>
            {doneTasks.length} / {hasBase ? expectedTasks.length : '-'}
          </div>
        );

        /** Mode PERCENTAJE / EXPECTED */
      } else if (
        configuration.visualization.values.percentaje &&
        configuration.visualization.expected.expected
      ) {
        if (!configuration.visualization.expected.base) {
          return (
            <div
              className={'grid-element-with-units-matrix' + customClass}
              style={{ ...style, backgroundColor, color }}>
              {navigatorLang.includes('en')
                ? acumProgressExpected.toFixed(1)
                : acumProgressExpected.toFixed(1).replace('.', ',')}
              %
            </div>
          );
        }
        return (
          <div
            className={'grid-element-with-units-matrix' + customClass}
            style={{ ...style, backgroundColor, color }}>
            {hasBase
              ? navigatorLang.includes('en')
                ? acumProgressExpected.toFixed(1)
                : acumProgressExpected.toFixed(1).replace('.', ',') + '%'
              : '-'}
          </div>
        );
      }
      return null;
    }
    return (
      <div
        className={'grid-element-without-units-matrix' + customClass}
        style={style}></div>
    );
  };
