export const ACCESS_DENIED_EVENT = 'access_denied';

export const LOADING_ACCESS = 'LOADING_ACCESS';
export const ACCESS_GRANTED = 'ACCESS_GRANTED';
export const NO_ORGANIZATION_ACCESS = 'NO_ORGANIZATION_ACCESS';
export const NO_PROJECT_ACCESS = 'NO_PROJECT_ACCESS';
export const NO_SCHEDULE_ACCESS = 'NO_SCHEDULE_ACCESS';
export const NO_MODULE_ACCESS = 'NO_MODULE_ACCESS';
export const NOT_FOUND = 'NOT_FOUND';
export const INVALID_LINK = 'INVALID_LINK';
export const NO_ORGANIZATION_ACCESS_ERROR_MSG = 'No organization access';
export const PROJECT_ACCESS_ERROR_MSG =
  'User have access to project, but the project was not found in the projectsbyuserthrough';
export const SCHEDULE_ACCESS_ERROR_MSG =
  'User have access to schedule, but the schedule was not found in the projectsbyuserthrough';
