import { checkIfActivityViolateConstraint } from './checkIfActivityViolateConstraint';

function modificationForConstraint(task, t) {
  const gantt = window.to_use_react_gantt;
  if (task.new_dates_drag) {
    if (checkIfActivityViolateConstraint(task)) return;

    const updateTaskConstraints = (task, constraintDate) => {
      task.constraint_type = 'snet';
      task.constraint_date = constraintDate;
      task.last_constraint_date = constraintDate;
      task.real_constraint_type = 'snet';
      task.constraint_type_old = 'snet';
      task.last_constraint = constraintDate;
    };

    const calendar = gantt.getCalendar(task.calendar_id);
    const clonedStartDate = task.new_dates_drag.start_date;
    clonedStartDate.setHours(0);
    clonedStartDate.setMinutes(0);

    const start_date_correct_hours = calendar.getClosestWorkTime({
      dir: 'fut',
      date: clonedStartDate,
      unit: gantt.config.duration_unit
    });
    updateTaskConstraints(task, start_date_correct_hours);

    delete task.new_dates_drag;
  }
}

export { modificationForConstraint };
