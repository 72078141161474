import { store } from '../../../../../../redux/store';
import {
  getCurrentCompany,
  getCurrentProject,
  getCurrentSector
} from '../../../../../../utils/userUtils';
/**
 * Generates the HTML for displaying the company logo image.
 *
 * @param {string} imgCompany - The URL of the company logo image.
 * @returns {string} The HTML string for the company logo image or a placeholder div if no image is provided.
 */
const getImageCompanyHTML = (imgCompany) => {
  if (imgCompany) {
    return `<img src="${imgCompany}" class="img-company imagen-custom-logo" />`;
  }
  return '<div style="height:100%; width:50px"></div>';
};

/**
 * Generates the HTML for displaying the project owner's image.
 *
 * @param {string} imageProjectOwner - The URL of the project owner's image.
 * @returns {string} The HTML string for the project owner's image or a placeholder span if no image is provided.
 */
const getImageProjectOwnerHTML = (imageProjectOwner) => {
  if (imageProjectOwner) {
    return `<img src="${imageProjectOwner}" class="img-owner imagen-custom-owner" />`;
  }
  return '<span class="header-right"><div style="height:5px; width:30px"></div></span>';
};

/**
 * Retrieves the image owner of the current project.
 *
 * @returns {string | undefined} The image owner of the current project, or `undefined` if not found.
 */
const getImageOwner = () => {
  const projects = store.getState().projectState?.allProjects;
  const { id: projectId } = getCurrentProject();

  const project = projects.find(({ id }) => id === projectId);
  if (!project) return;
  const { imageProjectOwner } = project;
  return imageProjectOwner;
};

/**
 * Generates the HTML for the header section of the PDF export.
 *
 * @returns {string} The HTML string representing the header, including the company logo, project name, sector name, and project owner's image.
 */
export const getHeaderPdf = () => {
  const { name: projectName } = getCurrentProject();
  const { name: sectorName } = getCurrentSector();
  const { image: imgCompany } = getCurrentCompany();
  const imageOwner = getImageOwner();
  console.log('imageOwner', imageOwner);

  const imageCompanyHTML = getImageCompanyHTML(imgCompany);
  const imageProjectOwnerHTML = getImageProjectOwnerHTML(imageOwner);

  return `
      <div class="wrapper-pdf">
          ${imageCompanyHTML}
          <div class="wrapper-pdf-company">
              <span class="gantt-header-title-project">${projectName}</span>
              <span class="gantt-header-title-sector">${sectorName}</span>
          </div>
          ${imageProjectOwnerHTML}
      </div>
    `;
};
