/* eslint-disable prefer-const */
import React, { useState, useEffect } from 'react';
import './TooltipHTML.css';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

export const TestIds = {
  TOOLBAR_CONTAINER: 'TOOLTIP_CONTAINER'
};

/**
 * This component is to display a tootlip in the lookahead.
 * @param {*} text Text is the value to be displayed in the tooltip
 * @param {*} container Container is the HTML object that will display the tooltip with onmouseover
 * @param {*} task_id Task_id is the id of the task assigned to the tooltip
 * @param {*} gantt Gantt is the instance of the gantt that is being displayed on the screen, by default it will be ganttVisualization
 * @param {*} gantt_string Gantt_string is the name of the instance of the gantt.
 * @param {*} name Name is the name that the tooltip will have in its id
 */
export default function TooltipHTML({
  text = '',
  container = '<div></div>',
  task_id = 0,
  gantt = window.ganttVisualization,
  gantt_string = 'window.ganttVisualization',
  name = ''
}) {
  const idTooltip = 'TooltipHTML-' + task_id + '-' + name;
  if (!gantt.tooltip_create) {
    gantt.tooltip_create = (task_id, name, text) => {
      if (task_id === 0) return;
      const idTooltip = 'TooltipHTML-' + task_id + '-' + name;
      const tooltipDiv = document.getElementById(idTooltip);

      const container = tooltipDiv.children[0].outerHTML;
      const tooltipPositionElement =
        tooltipDiv.children[0].getBoundingClientRect();
      const tooltipPosition = tooltipDiv.getBoundingClientRect();

      if (tooltipDiv.children.length > 1) {
        const tooltipCreate = tooltipDiv.children[1];
        tooltipCreate.style.top = tooltipPositionElement.top + 'px';
        const positionLeft =
          tooltipPositionElement.left -
          (tooltipCreate.getBoundingClientRect().width -
            tooltipPositionElement.width) /
            2;
        tooltipCreate.style.left = positionLeft + 'px';
        return;
      }
      const content = `
                            ${container}
                            <span class="TooltipHTMLtext" style="top: ${tooltipPosition.top}px"><p>${text}</p></span>
                            `;
      tooltipDiv.innerHTML = content;

      const tooltipCreate = document.getElementById(idTooltip).children[1];
      const positionLeft =
        tooltipPositionElement.left -
        (tooltipCreate.getBoundingClientRect().width -
          tooltipPositionElement.width) /
          2;
      tooltipCreate.style.left = positionLeft + 'px';
    };
  }

  return `
        <div class="TooltipHTML" id="${idTooltip}" onmouseover="${gantt_string}.tooltip_create(${task_id},'${name}','${text}')">
            <div class="TooltipHTMLElement">
                ${container}
            </div>
        </div>`;
}
