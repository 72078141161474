import React, { useState } from 'react';
import { Input, Button, Select } from 'antd';
import styled from 'styled-components';
import { cncTypeService } from '../../../../../../services/cnctype.service';
import {
  openNotification,
  categoriesCncType,
  compareValues
} from '../../../../../../utils';
import { useSelector } from 'react-redux';

function CncsTypes({ setCncTypes, CncTypes, onlyRead, t }) {
  const { Option } = Select;

  // GET ID PROJECT FROM REDUX
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;

  const templateCats = {
    name: '',
    description: '',
    type: undefined,
    projectId: projectSelectedId
  };

  const [newCncType, setnewCncType] = useState(templateCats);
  const changeCncType = (name, value) => {
    setnewCncType({
      ...newCncType,
      [name]: value
    });
  };

  const saveCncType = () => {
    /** validate */
    if (!newCncType.name.length || newCncType.type === undefined) {
      const alertError = {
        title: t('settings.project_categories.cncs.title'),
        description: t('settings.project_categories.cncs.required'),
        type: 'error'
      };
      openNotification(alertError);
      return false;
    }
    cncTypeService
      .create(newCncType)
      .then((res) => {
        if (res === false) {
          const alertError = {
            title: t('settings.project_categories.cncs.title'),
            description: t('settings.error_default'),
            type: 'error'
          };
          openNotification(alertError);
        } else {
          setCncTypes([...CncTypes, res]);
          setnewCncType(templateCats);
          const alertSuccess = {
            title: t('settings.project_categories.cncs.title'),
            description: t('settings.project_categories.cncs.success'),
            type: 'success'
          };
          openNotification(alertSuccess);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="wrapp-categories fix-cncs">
      <h1 style={{ color: '#2C3421', marginTop: 35 }}>
        {t('settings.project_categories.cncs.title')}
      </h1>
      <p style={{ fontSize: '12px' }}>
        {t('settings.project_categories.cncs.name')}
      </p>
      <Input
        disabled={onlyRead}
        type="text"
        name="name"
        placeholder={t('settings.project_categories.cncs.name')}
        autoComplete="off"
        style={{
          fontSize: '13px',
          border: '1px solid #121212',
          borderRadius: '5px'
        }}
        value={newCncType.name}
        onChange={(e) => changeCncType(e.target.name, e.target.value)}
      />
      <div style={{ marginTop: '10px' }}>
        <p style={{ fontSize: '12px' }}>
          {t('settings.project_categories.cncs.area')}
        </p>
        <SelectionStyle>
          <Select
            disabled={onlyRead}
            placeholder={t('settings.project_categories.cncs.select')}
            value={newCncType.type}
            style={{
              width: ' 100%',
              fontSize: '13px',
              border: '1px solid #121212',
              borderRadius: '5px'
            }}
            name="type"
            onChange={(value) => changeCncType('type', value)}>
            {categoriesCncType
              .sort(compareValues(t('lang') === 'es' ? 'labelEs' : 'label'))
              .map((type) => (
                <Option
                  style={{
                    fontSize: '12px'
                  }}
                  name="task_creter"
                  key={type.value}>
                  {t(`settings.project_categories.${type.trad}`)}
                </Option>
              ))}
          </Select>
        </SelectionStyle>
      </div>
      <div className="btns-cats">
        <Button
          disabled={onlyRead}
          className="btn-tipo"
          style={{ marginTop: '10px' }}
          onClick={saveCncType}>
          {t('settings.project_categories.cncs.add')}
        </Button>
      </div>
    </div>
  );
}

export default CncsTypes;

const SelectionStyle = styled.div`
  .ant-select-selection {
    border-radius: 5px !important;
    width: 100%;
  }
  .ant-select-selection {
    background-color: white !important;
    color: #5f5f5f;
  }
  .anticon svg {
    color: #7dff8a;
    float: right;
  }

  .ant-select-selection,
  ant-select-selection--single {
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    border-top: 0px;
  }

  .ant-select-selection:focus,
  ant-select-selection--single:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;
