import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import Range from 'react-range-progress';
import { taskService } from '../../services/task.service';
import { hexToRgb } from '../../utils';
import { InputNumber } from 'antd';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import './index.css';
import { trakerServiceAppCues } from '../../utils/appcues-util';
import useWeeklyplanPermissions from '../../hooks/useWeeklyplanPermissions';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const ProgressBar = (props) => {
  const {
    updateTask = () => {},
    fillcolor,
    task = {},
    updateRender,
    column,
    calculateProgress,
    calculatePonderators,
    parent,
    activity,
    disable,
    updateAsyncTask,
    updateAsyncActivity,
    projectState,
    progress_traker,
    className = false,
    textClassName = false,
    hideBar
  } = props;

  const { t } = useTranslation();
  const onlyRead = useWeeklyplanPermissions().plan === 'V';
  const [progress, setProgress] = useState({ value: task[column?.name] });
  const [isEditingInput, setIsEditingInput] = useState(false);
  const wrapperRef = useRef(null);
  const navigatorLang = navigator.language || navigator.userLanguage;
  const handleEsc = (e) => {
    if (e.keyCode === 27 || e.keyCode === 13) {
      setIsEditingInput(false);
    }
  };

  useOutsideAlerter(wrapperRef, () => saveProgress());

  const saveProgress = () => {
    setTimeout(() => {
      trakerServiceAppCues('Progress Input Lookahead or Weekly Plan');
    }, 5000);
    if (progress_traker) {
      progress_traker();
    }
    if (!onlyRead) {
      task[column.name] = progress.value;
      if (progress.value == 0) {
        if (calculatePonderators) {
          calculatePonderators(
            parent || activity,
            activity,
            updateAsyncTask,
            projectState
          );
        }
      }

      if (updateAsyncTask) {
        updateAsyncTask(task);
      } else {
        taskService.update(task);
      }
      calculateProgress(
        task,
        parent,
        activity,
        updateAsyncTask,
        updateAsyncActivity
      );
      updateRender();
      updateTask(task);
    }
  };

  useEffect(() => {
    const focusedItem = column?.name + task?.id;
    const domElement = document.getElementById(focusedItem);
    if (domElement) {
      domElement.focus();
    }
  }, [isEditingInput]);

  if (disable) {
    const formattedProgress = navigatorLang.includes('en')
      ? progress.value
        ? progress.value.toFixed(Number.isInteger(progress.value) ? 0 : 2)
        : 0 + '%'
      : progress.value
        ? progress.value
            .toFixed(Number.isInteger(progress.value) ? 0 : 2)
            .replace('.', ',')
        : 0 + '%';

    const content =
      task.children.length !== 0 ? (
        <span style={{ cursor: 'not-allowed' }}>{formattedProgress}</span>
      ) : (
        <Tooltip
          title={
            <div style={{ textAlign: 'center' }}>
              {t('not_permissions_actions')}
            </div>
          }>
          <span style={{ cursor: 'not-allowed' }}>{formattedProgress}</span>
        </Tooltip>
      );

    return <div>{content}</div>;
  }

  return (
    <div
      style={{ left: '0 !important' }}
      className={cn(
        'range-fixed-lookahead',
        { [className]: className },
        { 'range-fixed-lookahead-left': !className }
      )}>
      <span
        className={cn('percentage-label-custom', {
          [textClassName]: textClassName
        })}>
        {onlyRead ? (
          <Tooltip
            title={
              <div style={{ textAlign: 'center' }}>
                {t('not_permissions_actions')}
              </div>
            }>
            <span style={{ cursor: 'not-allowed' }}>
              {navigatorLang.includes('en')
                ? progress.value
                  ? progress.value.toFixed(
                      Number.isInteger(progress.value) ? 0 : 2
                    )
                  : 0 + '%'
                : progress.value
                  ? progress.value
                      .toFixed(Number.isInteger(progress.value) ? 0 : 2)
                      .replace('.', ',')
                  : 0 + '%'}
            </span>
          </Tooltip>
        ) : isEditingInput ? (
          <span ref={wrapperRef}>
            <InputNumber
              onKeyDown={handleEsc}
              onPressEnter={saveProgress}
              className="custom-input-number-planification ant-input-number-focused progress-bar-custom-input"
              min={0}
              max={100}
              name={column.name + task.id}
              id={column.name + task.id}
              precision={2}
              defaultValue={task[column.name]}
              onChange={(e) => {
                if (e >= 0 && e <= 100) {
                  progress.value = e;
                } else if (e > 100) {
                  progress.value = 100;
                } else if (e < 0) {
                  progress.value = 0;
                }
              }}
            />
          </span>
        ) : (
          <span onClick={() => setIsEditingInput(true)}>
            {navigatorLang.includes('en')
              ? progress.value
                ? progress.value.toFixed(
                    Number.isInteger(progress.value) ? 0 : 2
                  )
                : 0 + '%'
              : progress.value
                ? progress.value
                    .toFixed(Number.isInteger(progress.value) ? 0 : 2)
                    .replace('.', ',')
                : 0 + '%'}
          </span>
        )}
      </span>
    </div>
  );
};

export default ProgressBar;
