import React from 'react';
/**
 * This component is the rendered element between Y (activity) and X (unit) which show the match of selected items
 * @param {*} activities all activities in lineal tree structure
 * @param {*} units all units in lineal tree structure
 * @param {*} selectedActivities Selected activities at checkbox
 * @param {*} selectedUnits Selected units at checkbox
 */
export const GridCellUnitTree =
  (
    activities,
    units,
    selectedActivities,
    selectedUnits,
    locationArray,
    configuration,
    calculateExpected,
    ganttAPI
  ) =>
  ({ columnIndex, rowIndex, style }) => {
    const activity = activities[rowIndex];
    const unit = units[columnIndex];

    const isSelectedActivity = selectedActivities.find(
      (act) => act.id == activity.id && !activity.disable
    );

    const isSelectedUnit = selectedUnits.find(
      (un) => un.id == unit.id && !unit.disable
    );
    const customClass = ' border-rigth-gray-matrix';

    let alreadyAssignedUnits = 0;

    const ac = activity;
    const isActivityInSelectedArray = unit.activities.find(
      (act) => act.id == ac.id && ac.disable
    );
    if (isActivityInSelectedArray) {
      alreadyAssignedUnits += 1;
    }

    const alreadyAssignedCounter = alreadyAssignedUnits;

    if (isSelectedActivity && isSelectedUnit) {
      return (
        <div
          className={'grid-element-with-units-matrix' + customClass}
          style={style}>
          1 {alreadyAssignedCounter ? `${alreadyAssignedCounter}` : null}
        </div>
      );
    }
    return (
      <div
        className={
          'grid-element-without-units-matrix' +
          customClass +
          (alreadyAssignedCounter ? ' grid-element-with-units-matrix' : '')
        }
        style={style}>
        {alreadyAssignedCounter ? `${alreadyAssignedCounter}` : null}
      </div>
    );
  };

/**
 * This component is the rendered element between Y (activity) and X (unit) which show the match of selected items
 * @param {*} activities all activities in lineal tree structure
 * @param {*} units all units in lineal tree structure
 * @param {*} selectedActivities Selected activities at checkbox
 * @param {*} selectedUnits Selected units at checkbox
 * @param {*} locationArray locations with units array
 */
export const GridCellUnitGroupped =
  (
    activities,
    units,
    selectedActivities,
    selectedUnits,
    locationArray,
    configuration,
    statusColors,
    calculateExpected,
    ganttAPI
  ) =>
  ({ columnIndex, rowIndex, style }) => {
    const grouppedActivity = activities[rowIndex];
    const unit = units[columnIndex];
    const navigatorLang = navigator.language || navigator.userLanguage;
    let isSelectedActivity = false;
    let nToCreate = 0;
    const activitiesMatched = [];
    let hasBase = false;
    /** Goes trhough each groupped activity */
    for (let i = 0; i < grouppedActivity.activities.length; i++) {
      const ac = grouppedActivity.activities[i];
      /** Find if some of groupped activities is in selected activities array */
      const isActivityInSelectedArray = selectedActivities.find(
        (act) => act.id == ac.id && !ac.disable
      );
      if (isActivityInSelectedArray) {
        nToCreate += 1;
        isSelectedActivity = true;
        activitiesMatched.push(isActivityInSelectedArray);
      }
    }

    const dataToMatch = [];

    /** Filter all of that activities that are already selected to match with current unit */
    activitiesMatched.map((ac) => {
      const hasActivityThisUnit = ac.productionUnits.find(
        (u) => u.id == unit.id
      );
      if (hasActivityThisUnit) {
        dataToMatch.push(hasActivityThisUnit);
      }
    });

    const tasksArray = [];
    dataToMatch.map((el) => {
      const taskObject = el.tasks.find(
        (t) => t.id == el.productionunitactivity.taskId
      );
      if (taskObject) tasksArray.push(taskObject);
    });

    /** Defines expected */
    tasksArray.map((task) => {
      // const parentActivity = grouppedActivity.activities.find(ac => ac.id == task.activityId)
      if (task.base_start_date !== null) {
        // task.expected = calculateExpected(task, ganttAPI, parentActivity.calendarId, null, configuration.visualization.expected.base)
        hasBase = true;
      }
      // else {
      //     task.expected = calculateExpected(task, ganttAPI, parentActivity.calendarId, null, false)
      // }
    });

    const doneTasks = tasksArray.filter(
      (singleTask) => singleTask.progress >= 99.99 && singleTask.ponderator > 0
    );
    const inProgressTasks = tasksArray.filter(
      (singleTask) => singleTask.progress > 0 && singleTask.progress < 99.99
    );
    const expectedTasks = tasksArray.filter((t) => t.expected >= 99.99);
    const withProgressTasks = tasksArray.filter(
      (singleTask) => singleTask.progress > 0
    );

    let customClass = '';
    const location = locationArray.find((loc) => loc.id == unit.locationId);
    if (location) {
      const lastIndex = location.lastIndex;
      if (columnIndex == lastIndex - 1) {
        customClass += ' border-rigth-gray-matrix';
      }
    }

    if (columnIndex == activities.length - 1) customClass = '';

    const {
      statusVisualization: { generalStatus, expectedStatus, progressStatus }
    } = configuration;

    let progressAcumulator = 0;
    let acumPonderators = 0;
    tasksArray.map((t) => {
      progressAcumulator += t.progress * t.ponderator;
      acumPonderators += t.ponderator;
    });

    let acumProgress = progressAcumulator / acumPonderators;
    if (!acumProgress) {
      acumProgress = 0;
    }

    let progressExpectedAcumulator = 0;
    tasksArray.map((t) => {
      progressExpectedAcumulator += t.expected * t.ponderator;
    });

    let acumProgressExpected = progressExpectedAcumulator / acumPonderators;
    if (!acumProgressExpected) {
      acumProgressExpected = 0;
    }
    let color = '#FFFFFF';
    let backgroundColor;
    if (generalStatus) {
      if (doneTasks.length == tasksArray.length) {
        backgroundColor = statusColors.generalStatus[0].color;
      } else if (inProgressTasks.length) {
        backgroundColor = statusColors.generalStatus[1].color;
      } else {
        color = '#121212';
        backgroundColor = statusColors.generalStatus[2].color;
      }
    } else if (expectedStatus) {
      if (configuration.visualization.values.percentaje) {
        if (acumProgress >= 99.98) {
          backgroundColor = statusColors.expectedStatus[1].color;
        } else if (acumProgress > acumProgressExpected) {
          backgroundColor = statusColors.expectedStatus[0].color;
        } else if (acumProgress == acumProgressExpected) {
          backgroundColor = statusColors.expectedStatus[2].color;
        } else if (acumProgress < acumProgressExpected) {
          backgroundColor = statusColors.expectedStatus[3].color;
        }
      } else {
        if (doneTasks.length == tasksArray.length) {
          backgroundColor = statusColors.expectedStatus[1].color;
        } else if (doneTasks.length > expectedTasks.length) {
          backgroundColor = statusColors.expectedStatus[0].color;
        } else if (doneTasks.length == expectedTasks.length) {
          backgroundColor = statusColors.expectedStatus[2].color;
        } else if (doneTasks.length < expectedTasks.length) {
          backgroundColor = statusColors.expectedStatus[3].color;
        }
      }
    } else if (progressStatus) {
      if (acumProgress >= 99.98) {
        backgroundColor = statusColors.progressStatus[0].color;
      } else if (acumProgress >= 75 && acumProgress < 99.99) {
        backgroundColor = statusColors.progressStatus[1].color;
      } else if (acumProgress >= 50 && acumProgress < 75) {
        backgroundColor = statusColors.progressStatus[2].color;
      } else if (acumProgress >= 25 && acumProgress < 50) {
        backgroundColor = statusColors.progressStatus[3].color;
      } else if (acumProgress > 0 && acumProgress < 25) {
        backgroundColor = statusColors.progressStatus[4].color;
      }
    }

    if (dataToMatch.length) {
      /** Mode UNIT / ACTUAL */
      if (
        configuration.visualization.values.units &&
        configuration.visualization.expected.total
      ) {
        return (
          <div
            className={'grid-element-with-units-matrix' + customClass}
            style={{ ...style, backgroundColor, color }}>
            {doneTasks.length} / {tasksArray.length}
          </div>
        );
        /** Mode PERCENTAJE / ACTUAL */
      } else if (
        configuration.visualization.values.percentaje &&
        configuration.visualization.expected.total
      ) {
        return (
          <div
            className={'grid-element-with-units-matrix' + customClass}
            style={{ ...style, backgroundColor, color }}>
            {navigatorLang.includes('en')
              ? acumProgress.toFixed(1)
              : acumProgress.toFixed(1).replace('.', ',')}
            %
          </div>
        );

        /** Mode UNIT / EXPECTED */
      } else if (
        configuration.visualization.values.units &&
        configuration.visualization.expected.expected
      ) {
        if (!configuration.visualization.expected.base) {
          return (
            <div
              className={'grid-element-with-units-matrix' + customClass}
              style={{ ...style, backgroundColor, color }}>
              {doneTasks.length} / {expectedTasks.length}
            </div>
          );
        }
        return (
          <div
            className={'grid-element-with-units-matrix' + customClass}
            style={{ ...style, backgroundColor, color }}>
            {doneTasks.length} / {hasBase ? expectedTasks.length : '-'}
          </div>
        );

        /** Mode PERCENTAJE / EXPECTED */
      } else if (
        configuration.visualization.values.percentaje &&
        configuration.visualization.expected.expected
      ) {
        if (!configuration.visualization.expected.base) {
          return (
            <div
              className={'grid-element-with-units-matrix' + customClass}
              style={{ ...style, backgroundColor, color }}>
              {navigatorLang.includes('en')
                ? acumProgressExpected.toFixed(1)
                : acumProgressExpected.toFixed(1).replace('.', ',')}
              %
            </div>
          );
        }
        return (
          <div
            className={'grid-element-with-units-matrix' + customClass}
            style={{ ...style, backgroundColor, color }}>
            {hasBase
              ? navigatorLang.includes('en')
                ? acumProgressExpected.toFixed(1)
                : acumProgressExpected.toFixed(1).replace('.', ',') + '%'
              : '-'}
          </div>
        );
      }
      return (
        <div
          className={'grid-element-with-units-matrix' + customClass}
          style={{ ...style, backgroundColor, color }}>
          {doneTasks.length} / {tasksArray.length}
        </div>
      );
    }
    return (
      <div
        className={`grid-element-without-units-matrix ${customClass}`}
        style={style}></div>
    );
  };
