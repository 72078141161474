/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class ConstraintService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(constraintId) {
    const res = await this.http
      .get('constraints/' + constraintId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showBySector(sectorId) {
    const res = await this.http
      .get('constraints/sector/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(constraintId) {
    const res = await this.http
      .delete('constraints/' + constraintId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('constraints', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('constraints/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async asignConstraintTask(data) {
    const res = await this.http
      .post('constraints/assign/tasks', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async bulkAsignConstraintTask(id, data) {
    const res = await this.http
      .post(`constraints/${id}/assign_tasks`, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getByTaskThrough(taskId) {
    const res = await this.http
      .get('constraints/bytaskthrough/' + taskId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getByConstraintThrough(taskId) {
    const res = await this.http
      .get('constraints/byconstraintthrough/' + taskId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getLookahead(config) {
    const res = await this.http
      .post('constraints/lookaheadconstraints', config)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async attachDocuments(constraintId, docs) {
    const res = await this.http
      .post(`constraints/${constraintId}/attachdocuments`, { documents: docs })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const constraintService = new ConstraintService();
