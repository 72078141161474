import moment from 'moment';
import { addDurationToDate } from '../helpers';
import { LINK_TYPES, CONSTRAINT_TYPES } from '../constants/index';

class CriticalPathHelpers {
  constructor(direction, ganttInstace) {
    this.direction = direction;
    this.gantt = ganttInstace;
  }
  /**
   * Identifies and sets the initial activities for the chain based on the specified direction.
   *
   * This function determines the starting activities of a chain by filtering activities from
   * the Gantt chart. The filtering is based on the specified direction (`forward` or `backward`).
   * - For `forward` direction, activities with an empty `$target` property are selected.
   * - For `backward` direction, activities with an empty `$source` property are selected.
   *
   * The function excludes activities of type `project` from the results.
   * The resulting activity IDs are stored in the `chainStartActivities` property.
   *
   * If an error occurs during the process, `chainStartActivities` is set to an empty array,
   * and the error is rethrown.
   *
   * @throws {Error} If an error occurs during the identification process.
   */
  identifyInitialActivities() {
    try {
      const filterProperty =
        this.direction === 'forward' ? '$target' : '$source';
      let chainStartActivities = this.gantt
        .getTaskByTime()
        .filter((activity) => Boolean(!activity[filterProperty].length))
        .filter((activity) => !activity['$rendered_parent'] == '0')
        .map((activity) => activity.id);
      if (chainStartActivities.length === 0)
        throw new Error('No initial activities found');
      const newChain = this.ignoreParentActivitiesAndTraspassInitialToNext(
        chainStartActivities,
        this.gantt
      );

      this.chainStartActivities = newChain;
    } catch (error) {
      this.chainStartActivities = [];
      throw new Error(error.message);
    }
  }

  //Temporary code for parent activities
  ignoreParentActivitiesAndTraspassInitialToNext(chain, gantt) {
    const directionForParent =
      this.direction === 'forward' ? '$source' : '$target';
    const directionForLink = this.direction === 'forward' ? 'target' : 'source';
    function recursiveSeekAndDestroyParentActivites(activityId) {
      const activity = gantt.getTask(activityId);
      if (!activity) {
        return null;
      }

      if (activity.type !== 'project') return activity.id;

      for (let linkId of activity[directionForParent]) {
        const link = gantt.getLink(linkId);
        if (!link) {
          continue;
        }

        const connectedActivityId = link[directionForLink];
        const result =
          recursiveSeekAndDestroyParentActivites(connectedActivityId);
        if (result) {
          return result;
        }
      }
      return null;
    }

    return chain
      .map((activity) => recursiveSeekAndDestroyParentActivites(activity))
      .filter(Boolean);
  }

  /**
   * Transforms the initial activities array into an object.
   *
   * This function converts the array of initial activities (`chainStartActivities`) into
   * an object where each activity ID is a key, and the value is an empty object. The resulting
   * object is then assigned to the `chains` property.
   *
   * Example:
   * If `chainStartActivities` is [1, 2, 3], the resulting `chains` object will be:
   * {
   *   1: {},
   *   2: {},
   *   3: {}
   * }
   *
   * This transformation is useful for organizing the initial activities in a way that
   * facilitates further processing or manipulation.
   */
  transformInitialActivitiesToObject() {
    let chains = this.chainStartActivities.reduce((acc, curr) => {
      acc[curr] = {};
      return acc;
    }, {});

    this.chains = chains;
  }
  /**
   * Recursively calculates and maps the chains of activities, updating the `chains` property and handling the relationships between activities.
   *
   * This function processes the chains of activities concurrently using `Promise.all`, starting from the initial set of activities.
   * For each activity in the chain, it retrieves the related links, updates the current level of chains, generates a map of relations,
   * and recursively processes the linked activities.
   *
   * @param {Array<string|number>} [mainChains=this.chainStartActivities] - The initial set of activity IDs to process.
   * @param {Object} [currLevel=null] - The current level of the chains to update, used for recursive calls.
   * @returns {Promise<void>} A promise that resolves when all chains have been processed.
   *
   * @example
   * // Assuming chainStartActivities = [1, 2]
   * // and getLinks(1) returns a Map with {3: {}, 4: {}}
   * // and getLinks(2) returns a Map with {5: {}}
   *
   * await calculateChains();
   * // This will update the `chains` property to:
   * // {
   * //   1: { 3: {}, 4: {} },
   * //   2: { 5: {} },
   * //   3: {}, 4: {}, 5: {}
   * // }
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  async calculateChains(mainChains = null, currLevel = null, cycleCount = 0) {
    try {
      if (cycleCount > 100000) {
        console.error('Maximum cycle count exceeded');
        return;
      }

      mainChains = mainChains || this.chainStartActivities;
      if (mainChains.length === 0) return;

      await Promise.all(
        mainChains.map(async (chain) => {
          let activityId = chain;
          let newLinks = this.getLinks(activityId);
          let linksToArray = Array.from(newLinks.keys());

          let objectOfLinks = {};

          linksToArray.forEach((link) => {
            objectOfLinks[link] = {};
          });
          if (linksToArray.length == 0) return;
          this.generateMapOfRelations(newLinks, activityId);
        })
      );
    } catch (error) {
      throw new Error(error.message);
    }
  }
  /**
   * Retrieves and maps the links for a given activity ID.
   *
   * This function fetches an activity by its ID and determines its links based on the direction (`forward` or `backward`).
   * It then maps each link to its corresponding Gantt activity link and builds a Map object with these links.
   * If any activity or link is not found, it throws an error.
   *
   * @param {string|number} activityId - The ID of the activity for which to retrieve links.
   * @returns {Map<number, string>} A Map object where the keys are link connection IDs and the values are link IDs.
   *
   * @example
   * // Assuming direction is 'forward' and getTask(1) returns an activity with $source links [2, 3]
   * // and getLink(2) and getLink(3) return valid link objects
   * const links = getLinks(1);
   * console.log(links); // Map { 2: '2', 3: '3' }
   *
   * @throws {Error} If the activity or any link is not found.
   */
  getLinks(activityId) {
    try {
      let activity = this.gantt.getTask(activityId);
      if (!activity)
        throw new Error(`Error trying to get activity  ${activityId}`);
      let links =
        this.direction === 'forward' ? activity.$source : activity.$target;

      let linkConnection = this.direction === 'forward' ? 'target' : 'source';

      let chains = links.map((link) => {
        const ganttActivityLink = this.gantt.getLink(link);
        if (!ganttActivityLink) {
          throw new Error(`Error trying to get link id: ${link}`);
        }
        return ganttActivityLink;
      });

      let target = new Map();

      chains.forEach((link) => {
        target.set(Number(link[linkConnection]), link.id);
      });
      return target;
    } catch (error) {
      throw new Error(error.message);
    }
  }
  /**
   * Generates and updates the maps of relationships for activities and links.
   *
   * This function processes the given `mapOfRelations` to update two maps:
   * - `mapOfRelationsWithActivitiesIds`: Maps activity IDs to their predecessors.
   * - `mapOfRelationsWithLinks`: Maps chain IDs to their associated links.
   *
   * If an activity already exists in the `mapOfRelationsWithActivitiesIds`, it adds a new predecessor
   * and a new link to the array of associations. If not, it creates a new array with the predecessor
   * and the link and stores it in the respective maps.
   *
   * @param {Map<number, Array<number>>} mapOfRelations - A map where keys are activity IDs and values are arrays of link IDs.
   * @param {string|number} chainId - The ID of the chain being processed.
   *
   * @example
   * // Assuming mapOfRelations is a Map with the structure:
   * // Map {
   * //   1: [2, 3],
   * //   2: [4]
   * // }
   * // and chainId is 5
   * generateMapOfRelations(mapOfRelations, 5);
   * // This will update the `mapOfRelationsWithActivitiesIds` and `mapOfRelationsWithLinks` maps accordingly.
   *
   * @throws {Error} If an error occurs during the process.
   */
  generateMapOfRelations(mapOfRelations, chainId) {
    const updateMap = (map, key, value) => {
      if (map.has(key)) {
        const existingValue = map.get(key);
        if (!existingValue.includes(value)) {
          map.set(key, [...existingValue, value]);
        }
      } else {
        map.set(key, [value]);
      }
    };

    [...mapOfRelations.keys()].forEach((activityKey) => {
      const parsedActivityId = Number(activityKey);
      const propertyToCheck =
        this.direction === 'forward' ? '$target' : '$source';
      if (this.gantt.getTask(parsedActivityId)[propertyToCheck].length > 1) {
        const propertyToCheckLink =
          this.direction === 'forward' ? 'source' : 'target';
        const allConnectedActivities = this.gantt
          .getTask(parsedActivityId)
          [propertyToCheck].map((link) => {
            if (!this.gantt.getLink(link)) return;
            const linkData = this.gantt.getLink(link);

            if (!linkData) return;
            return Number(linkData[propertyToCheckLink]);
          });
        if (
          allConnectedActivities.every((act) =>
            this.chainStartActivities.includes(act)
          )
        ) {
          this.activitiesLinkedToChainStart.add(parsedActivityId);
        } else {
          return;
        }
      } else {
        this.activitiesLinkedToChainStart.add(parsedActivityId);
      }
    });
  }
  /**
   * Calculates and stores the start and finish dates for the chain origin activities.
   *
   * This function iterates over the `chainStartActivities`, retrieves each activity, calculates
   * its start and finish dates based on the direction (`forward` or `backward`), and stores these
   * calculations in the `calculations` map along with the activity's text.
   *
   * @example
   * // Assuming chainStartActivities contains [1, 2]
   * // and direction is 'forward'
   * getStartAndFinishOfChainOrigin();
   * // This will update the `calculations` map with the earliest start (es) and earliest finish (ef)
   * // dates for each activity in the chain.
   *
   * @throws {Error} If an error occurs during the retrieval or calculation process.
   */
  getStartAndFinishOfChainOrigin() {
    this.chainStartActivities.forEach((chain) => {
      const activity = this.gantt.getTask(chain);
      if (!activity) return;
      if (activity.progress > 100) return;
      if (activity.type === 'project') return;
      const dates = this.calculateStartAndFinishOfChainOrigin(
        activity,
        this.direction
      );

      if (this.direction === 'forward') {
        let { es, ef } = dates;
        this.calculations.set(chain, { es, ef, text: activity.text });
      } else {
        let { ls, lf } = dates;
        this.calculations.set(chain, { ls, lf, text: activity.text });
      }
    });
  }
  /**
   * Calculates the start and finish dates for the origin of a chain activity.
   *
   * This function calculates the start and finish dates for an activity based on the provided direction
   * (`forward` or `backward`). For forward direction, it calculates the earliest start (ES) and earliest
   * finish (EF). For backward direction, it calculates the latest start (LS) and latest finish (LF),
   * handling different progress states (0%, 100%, and between 0% and 100%).
   *
   * @param {Object} [activityFromLink=null] - The activity object to calculate dates for.
   * @param {string} [customDirection=null] - The direction for the calculation (`forward` or `backward`).
   * @returns {Object} An object containing the calculated dates (ES, EF, LS, LF) based on the direction and progress.
   *
   * @example
   * // Assuming activity is an object with start_date, end_date, progress, and calendar_id properties
   * // and direction is 'forward'
   * const dates = calculateStartAndFinishOfChainOrigin(activity, 'forward');
   * console.log(dates); // { es: activity.start_date, ef: activity.end_date }
   *
   * @throws {Error} If the activity's calendar cannot be retrieved or other errors occur during calculation.
   */
  calculateStartAndFinishOfChainOrigin(
    activityFromLink = null,
    customDirection = null
  ) {
    try {
      let activity = activityFromLink;
      let progress = Number(activity.progress);
      if (progress === '0.00') {
        progress = 0;
      }
      let activityCalendar = this.gantt.getCalendar(activity.calendar_id);
      if (!activityCalendar) {
        throw new Error(
          `Error trying to get calendar with id: ${activity.calendar_id}`
        );
      }
      let direction = customDirection || this.direction;

      if (direction === 'forward') {
        let es = activity.start_date;
        let ef = activity.end_date;
        return {
          es,
          ef
        };
      } else {
        if (progress == 100) {
          return {
            ls: moment(activity.start_date).clone(),
            lf: moment(activity.end_date).clone()
          };
        }

        if (progress === 0) {
          return this.calculateBackwardWhenZeroProgress(
            activity,
            activityCalendar
          );
        }

        if (progress < 100 && progress > 0) {
          return this.calculateWhenProgressIsBetweenZeroAndOneHundred(
            activity,
            activityCalendar
          );
        }
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
  /**
   * Calculates the latest start (LS) and latest finish (LF) dates for an activity with zero progress.
   *
   * This function determines the LS and LF dates based on the activity's constraint type and duration,
   * using the provided calendar. It handles different types of constraints, adjusting the start and finish
   * dates accordingly.
   *
   * @param {Object} activity - The activity object to calculate dates for, which should include start_date, end_date, duration, and constraint_type.
   * @param {Object} calendar - The calendar object used to adjust dates based on working days and hours.
   * @returns {Object} An object containing the calculated latest start (LS) and latest finish (LF) dates.
   *
   * @example
   * // Assuming activity is an object with the required properties and a calendar object is provided
   * const dates = calculateBackwardWhenZeroProgress(activity, calendar);
   * console.log(dates); // { ls: moment(...), lf: moment(...) }
   *
   * @throws {Error} If an error occurs during the calculation.
   */
  calculateBackwardWhenZeroProgress(activity, calendar) {
    let lateStart = moment(activity.start_date).clone();
    let lateFinish = moment(activity.end_date).clone();
    let duration = activity.duration;
    let endDateProjectConstraints = [
      CONSTRAINT_TYPES.ASAP,
      CONSTRAINT_TYPES.ALAP,
      CONSTRAINT_TYPES.SNET,
      CONSTRAINT_TYPES.FNET
    ];
    let constraint = activity.constraint_type || CONSTRAINT_TYPES.ASAP;

    if (endDateProjectConstraints.includes(constraint)) {
      lateFinish = this.gantt.getSubtaskDates().end_date;
      lateStart = addDurationToDate(calendar, lateFinish, -duration, activity);
    } else {
      let plusDuration = [CONSTRAINT_TYPES.SNLT, CONSTRAINT_TYPES.MSO];
      let minusDuration = [CONSTRAINT_TYPES.FNLT, CONSTRAINT_TYPES.MFO];
      if (plusDuration.includes(constraint)) {
        lateStart = activity.constraint_date;
        lateFinish = addDurationToDate(calendar, lateStart, duration, activity);
      }

      if (minusDuration.includes(constraint)) {
        lateFinish = activity.constraint_date;
        lateStart = addDurationToDate(
          calendar,
          lateFinish,
          -duration,
          activity
        );
      }
    }

    return {
      ls: lateStart,
      lf: lateFinish
    };
  }
  /**
   * Calculates the latest start (LS) and latest finish (LF) dates for an activity with progress between 0% and 100%.
   *
   * This function determines the LS and LF dates based on the activity's constraint type and progress,
   * considering the end date of the project and various constraints.
   *
   * @param {Object} activity - The activity object to calculate dates for, which should include start_date, end_date, and constraint_type.
   * @returns {Object} An object containing the calculated latest start (LS) and latest finish (LF) dates.
   *
   * @example
   * // Assuming activity is an object with the required properties
   * const dates = calculateWhenProgressIsBetweenZeroAndOneHundred(activity);
   * console.log(dates); // { ls: activity.start_date, lf: calculatedLateFinish }
   *
   * @throws {Error} If an error occurs during the calculation.
   */
  calculateWhenProgressIsBetweenZeroAndOneHundred(activity) {
    let lateStart = activity.start_date;
    let lateFinish = null;
    let constraint = activity.constraint_type || CONSTRAINT_TYPES.ASAP;
    let endDateOfTheProject = this.gantt.getSubtaskDates().end_date;
    let endDateActivityConstraints = [
      CONSTRAINT_TYPES.ASAP,
      CONSTRAINT_TYPES.ALAP,
      CONSTRAINT_TYPES.SNET,
      CONSTRAINT_TYPES.FNET
    ];
    let endDateActivityWithLagConstraints = [
      CONSTRAINT_TYPES.SNLT,
      CONSTRAINT_TYPES.FNLT
    ];

    if (endDateActivityConstraints.includes(constraint)) {
      lateFinish = Math.max(activity.end_date, endDateOfTheProject);
    } else if (constraint === CONSTRAINT_TYPES.MSO) {
      lateFinish = activity.end_date;
    } else if (
      constraint === CONSTRAINT_TYPES.MFO ||
      endDateActivityWithLagConstraints.includes(constraint)
    ) {
      const minDateBetween = Math.min(
        endDateOfTheProject,
        activity.constraint_date
      );

      lateFinish = Math.max(minDateBetween, activity.end_date);
    }

    return {
      ls: lateStart,
      lf: new Date(lateFinish)
    };
  }
  /**
   * Identifies activities that are directly linked to chain start activities.
   *
   * This function creates an array of activities that can be calculated initially because they have only
   * one predecessor, and this predecessor is an activity that starts a chain. It processes the map of
   * relations (`mapOfRelationsWithActivitiesIds`) and filters activities based on their predecessors.
   *
   * @example
   * // Assuming mapOfRelationsWithActivitiesIds is a Map with the structure:
   * // Map {
   * //   1: [2],
   * //   2: [3, 4],
   * //   3: [1],
   * //   4: [1]
   * // }
   * // and chainStartActivities is [1]
   * getActivitiesThatAreDirectlyLinkedToChainStarts();
   * // This will set activitiesLinkedToChainStart to [3, 4]
   *
   * @throws {Error} If an error occurs during the process.
   */
  getActivitiesThatAreDirectlyLinkedToChainStarts() {
    //Aqui creamos un array con todas aquellas actividades que pueden ser calculadas en un inicio ya que solo tienen un predecesor
    // y este predecesor es una actividad que inicia una cadena

    this.activitiesLinkedToChainStart = [
      ...this.mapOfRelationsWithActivitiesIds
    ]
      .map(([key, value]) => {
        if (value.length === 1) {
          if (value.some((act) => this.chainStartActivities.includes(act))) {
            return key;
          }
          return;
        }

        if (value.every((act) => this.chainStartActivities.includes(act)))
          return key;
      })
      .filter(Boolean);
  }
  /**
   * Checks which activities from a given list can now be calculated based on their predecessors.
   *
   * This function iterates over the `pendingToCalculate` list and determines which activities can now be calculated.
   * An activity can be calculated if all its predecessors have already been calculated. It returns an object containing
   * two sets: one for activities that can be calculated and another for the remaining pending activities.
   *
   * @param {Array<string|number>} pendingToCalculate - An array of activity IDs that are pending calculation.
   * @returns {Object} An object containing two sets:
   * - `activitiesThatCanBeCalculated`: A set of activity IDs that can now be calculated.
   * - `pendingActivities`: A set of remaining activity IDs that are still pending calculation.
   *
   * @example
   * // Assuming mapOfRelationsWithActivitiesIds is a Map with the structure:
   * // Map {
   * //   1: [2, 3],
   * //   2: [4],
   * //   3: [],
   * //   4: []
   * // }
   * // and calculations contains [3, 4]
   * const result = checkActivitiesThatNowCanBeCalculated([1, 2, 3, 4]);
   * console.log(result);
   * // {
   * //   activitiesThatCanBeCalculated: Set { 2, 3, 4 },
   * //   pendingActivities: Set { 1 }
   * // }
   *
   * @throws {Error} If an error occurs during the process.
   */
  checkActivitiesThatNowCanBeCalculated(pendingToCalculate) {
    const activitiesThatCanBeCalculated = new Set();
    const pendingActivities = new Set(pendingToCalculate);
    const propertyToCheck =
      this.direction === 'forward' ? '$target' : '$source';

    pendingToCalculate.forEach((activity) => {
      const activityData = this.gantt.getTask(activity);
      const predecessors = activityData[propertyToCheck];
      const activitiesFromLinks = predecessors
        .map((link) => {
          const linkData = this.gantt.getLink(link);
          if (!linkData) {
            pendingActivities.delete(activity);
            return;
          }
          const propertyForLink =
            this.direction === 'forward' ? 'source' : 'target';
          if (this.gantt.getTask(linkData[propertyForLink])) {
            return this.gantt.getTask(linkData[propertyForLink]).id;
          }
        })
        .filter(Boolean);
      const doPredecessorsAreAlreadyCalculated = activitiesFromLinks.every(
        (predecessor) => {
          if (this.calculations.has(predecessor)) {
            return true;
          }

          if (this.gantt.getTask(predecessor).type === 'project') {
            return true;
          }
        }
      );
      if (doPredecessorsAreAlreadyCalculated) {
        activitiesThatCanBeCalculated.add(activity);
        pendingActivities.delete(activity);
      }
    });

    return {
      activitiesThatCanBeCalculated,
      pendingActivities
    };
  }
}

export default CriticalPathHelpers;
