import { updateDefaultView } from '../../services/views/viewSync';
import * as Sentry from '@sentry/react';
// All of this glue logic is temporary and should be replaced with a Redux-based approach ASAP

export function executeNewToolbarCommand(command, value) {
  const commandHandler = commands[command];
  const globalGanttInstance = window.to_use_react_gantt;
  if (!globalGanttInstance) return;
  try {
    commandHandler && commandHandler(globalGanttInstance, value);
  } catch (e) {
    console.log(e);
  }
}

const commands = {
  SET_ZOOM_LEVEL: (ganttInstance, zoomLevel) => {
    updateDefaultView();
    return ganttInstance.changeScaleVisualization(zoomLevel.toLowerCase());
  },
  UNDO: (ganttInstance) => {
    try {
      ganttInstance.undo();
    } catch (e) {
      Sentry.captureMessage(e, 'warning');
    }
  },
  REDO: (ganttInstance) => {
    try {
      ganttInstance.redo();
    } catch (e) {
      Sentry.captureMessage(e, 'warning');
    }
  },
  MASSIVE: (ganttInstance) => console.log('massive action'),
  OUTDENT: (ganttInstance) => ganttInstance.outdentMassive(),
  INDENT: (ganttInstance) => ganttInstance.indentMassive(),
  LINK: (ganttInstance) => ganttInstance.linkMassive(),
  UNLINK: (ganttInstance) => ganttInstance.unlinkMassive(),
  LEVELS: (ganttInstance, level) => {
    updateDefaultView();
    return ganttInstance.changeLevelVisualization(level);
  },
  TOGGLE_LINKS_VISIBILITY: (ganttInstance) => {
    updateDefaultView();
    return ganttInstance.changeLinksVisibility();
  },
  TOGGLE_SLACK_VISIBILITY: (ganttInstance) => {
    updateDefaultView();
    return ganttInstance.changeSlackVisibility();
  },
  TOGGLE_NUMTASKS_VISIBILITY: (ganttInstance) => {
    updateDefaultView();
    return ganttInstance.changeNumtasksVisibility();
  },
  TOGGLE_BASELINE_VISIBILITY: (ganttInstance) => {
    updateDefaultView();
    return ganttInstance.changeBaselineVisibility();
  },
  TOGGLE_TODAYLINE_VISIBILITY: (ganttInstance) => {
    updateDefaultView();
    return ganttInstance.changeTodaylineVisibility();
  },
  TOGGLE_SUBMITTALS_VISIBILITY: (ganttInstance) => {
    updateDefaultView();
    return ganttInstance.changeSubmittalsVisibility();
  },
  DOWNLOAD_SCHEDULE: (ganttInstance, format) =>
    ganttInstance.exportGantt(format),
  CHANGE_COLOR: (ganttInstance, itemType) => {
    updateDefaultView();
    return ganttInstance.changeVisualizationOption(itemType);
  },
  SCROLL_TO_TODAY: (ganttInstance) => ganttInstance.scrollToTodayAtChart(),
  DATE_FORMAT: (ganttInstance, dateFormat) =>
    ganttInstance.changeCurrentDateFormat(dateFormat),
  CRITERIA_STATUS: (ganttInstance, value) =>
    ganttInstance.updateCriteriaStatus(value),
  CHANGE_COLOR_ROW: (ganttInstance, itemType) => {
    // Code below belongs to remove default option due to non required product feature, this code must be removed as soon as other option
    // to colorize row is created
    const updateToolbarCheck = () => {
      updateDefaultView();
      return ganttInstance?.callEvent('updateCheckedWBS');
    };
    const wbsCheckCallback = ganttInstance?.isActiveColorWBS
      ? ganttInstance.disableColorsWBS
      : ganttInstance?.enableColorsWBS;
    if (wbsCheckCallback instanceof Function)
      wbsCheckCallback() && updateToolbarCheck();
    // Commented due to non other options to colorize rows ganttInstance.changeRowVisualizationOption(itemType)
  }
};
