import { useCallback, useEffect, useRef, useState } from 'react';

const useDebounce = (value, delay = 0) => {
  const [dataDebouncer, setData] = useState(value);
  const ref = useRef({});

  useEffect(() => {
    ref.current.timeout = setTimeout(handler, delay);

    return () => clearTimeout(ref.current.timeout);
  }, [value]);

  const handler = () => setData(value);

  return dataDebouncer;
};

export default useDebounce;
