import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import backgroundTutorials from '../../assets/img/helpcenter/tutorialsback.png';
import headerIcon from '../../assets/img/helpcenter/header_center.png';
import searchIcon from '../../assets/img/helpcenter/search_question.png';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import useWindowDimensions from '../../hooks/useWindowDimensions';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

function TutorialsView(props) {
  const { t } = props;
  const [visibilitySearchIcon, setVisibilitySearchIcon] = useState(true);
  const [videos, setVideos] = useState([]);
  const [popularVideos, setPopularVideos] = useState([]);
  const { height, width } = useWindowDimensions();

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);
    // loadCalendars()

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  useEffect(() => {
    getVideosFromYoutube();
  }, []);

  const getVideosFromYoutube = async () => {
    const res = await axios.get(
      'https://www.googleapis.com/youtube/v3/playlistItems?order=date&part=snippet&playlistId=PL7evoBAv5vaamy2PlzocVyhSJ3yObJBuB&maxResults=50&key=AIzaSyD6OXIlA5b6bJBKr9BKfOPgJ-vuD6OAHtQ'
    );
    if (res.data) {
      const finalArray = [];
      const asyncMapping = res.data.items.map(async (video) => {
        const videoObject = {
          id: video.snippet.resourceId.videoId,
          url:
            'http://www.youtube.com/watch?v=' +
            video.snippet.resourceId.videoId,
          title: video.snippet.title,
          description: video.snippet.description,
          img: video.snippet.thumbnails.high.url,
          creation: video.snippet.publishTime
        };

        if (!videoObject.id) {
          return;
        }

        const analytics = await axios.get(
          'https://www.googleapis.com/youtube/v3/videos?part=statistics&id=' +
            videoObject.id +
            '&key=AIzaSyD6OXIlA5b6bJBKr9BKfOPgJ-vuD6OAHtQ'
        );
        if (analytics) {
          if (analytics.data.items[0]) {
            videoObject.views = parseInt(
              analytics.data.items[0].statistics.viewCount
            );
          }
        }

        finalArray.push(videoObject);
      });
      await Promise.all(asyncMapping);
      let mostPopulars = JSON.parse(JSON.stringify(finalArray));
      mostPopulars.sort((a, b) => b.views - a.views);
      mostPopulars = mostPopulars.slice(0, 10);
      finalArray.sort((a, b) => {
        const c = new Date(a.creation);
        const d = new Date(b.creation);
        return d - c;
      });
      setVideos(finalArray);
      setPopularVideos(mostPopulars);
    }
  };

  const onSearchQuestions = (e) => {
    if (e.target.value.length) {
      setVisibilitySearchIcon(false);
      videos.map((video) => {
        if (e.target.value.toLowerCase().split(' ').length <= 1) {
          if (
            !video.title.toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            video.hide = true;
          } else {
            video.hide = false;
          }
        } else if (
          !video.title
            .toLowerCase()
            .split(' ')
            .some((r) => e.target.value.toLowerCase().split(' ').includes(r))
        ) {
          video.hide = true;
        } else {
          video.hide = false;
        }
      });
      popularVideos.map((video) => {
        if (e.target.value.toLowerCase().split(' ').length <= 1) {
          if (
            !video.title.toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            video.hide = true;
          } else {
            video.hide = false;
          }
        } else if (
          !video.title
            .toLowerCase()
            .split(' ')
            .some((r) => e.target.value.toLowerCase().split(' ').includes(r))
        ) {
          video.hide = true;
        } else {
          video.hide = false;
        }
      });
    } else {
      setVisibilitySearchIcon(true);
      videos.map((video) => {
        video.hide = false;
      });
      popularVideos.map((video) => {
        video.hide = false;
      });
    }
    setVideos([...videos]);
    setPopularVideos([...popularVideos]);
  };

  return (
    <Row>
      <Col>
        <Row>
          <img src={backgroundTutorials} width={'100%'} />
        </Row>
        <div className="rows-downside-tutorials-content">
          <Row>
            <Col offset={1} span={1}>
              <img src={headerIcon} width={35} />
            </Col>
            <Col span={5}>
              <span className="title-tutorials-style">
                {t('tutorials_title')}
              </span>
            </Col>
            <Col offset={11} span={5}>
              <div style={{ textAlign: 'center' }}>
                <img
                  src={searchIcon}
                  width={16}
                  style={{
                    position: 'relative',
                    left: 37,
                    visibility: visibilitySearchIcon ? null : 'hidden'
                  }}
                />
                <input
                  onChange={onSearchQuestions}
                  className="search-help-center"
                  placeholder="Buscar..."
                />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <Col span={11} offset={1}>
              <Row>
                <Col>
                  <span className="tutorials-titles">
                    {t('all_videos_tutos')}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col style={{ overflow: 'auto', height: height - 215 }}>
                  {videos.map((video) => {
                    if (video.hide) return null;
                    return (
                      <div
                        className="single-vid-container"
                        onClick={() => window.open(video.url, '_blank')}>
                        <Row>
                          <Col span={11}>
                            <img
                              src={video.img}
                              width={width < 1400 ? 250 : 350}
                              height={200}
                            />
                          </Col>
                          <Col span={12}>
                            <Row>
                              <Col
                                style={{
                                  color: '#121212',
                                  fontWeight: 'bold',
                                  fontSize: 19
                                }}>
                                {video.title}
                              </Col>
                            </Row>
                            <Row>
                              <Col style={{ fontSize: 15 }}>
                                {video.description}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Col>
            <Col span={11} offset={1}>
              <Row>
                <Col>
                  <span className="tutorials-titles">
                    {t('most_popular_videos')}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col style={{ overflow: 'auto', height: height - 215 }}>
                  {popularVideos.map((video) => {
                    if (video.hide) return null;
                    return (
                      <div
                        className="single-vid-container"
                        onClick={() => window.open(video.url, '_blank')}>
                        <Row>
                          <Col span={12}>
                            <img
                              src={video.img}
                              width={width < 1400 ? 250 : 350}
                              height={200}
                            />
                          </Col>
                          <Col span={12}>
                            <Row>
                              <Col
                                style={{
                                  color: '#121212',
                                  fontWeight: 'bold',
                                  fontSize: 19
                                }}>
                                {video.title}
                              </Col>
                            </Row>
                            <Row>
                              <Col style={{ fontSize: 15 }}>
                                {video.description}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default withTranslation()(TutorialsView);
