import React, { useEffect, useState } from 'react';

import { ToolbarGroup } from '../ToolbarGroup';
import { DATE_FORMAT_CONFIG, GANTT_SETTINGS_ITEMS } from './constants';
import styles from '../GanttSettings/GanttSettings.module.scss';
import { useSelector, useDispatch } from 'react-redux';

export const TimelineSettings = ({
  messageModule,
  t,
  onCommandDispatched,
  timelineObject
}) => {
  const projectState = useSelector((state) => state.projectState);

  useEffect(() => {
    GANTT_SETTINGS_ITEMS[0].subitemsSettings[2].tooltip = messageModule;
  }, []);

  useEffect(() => {
    if (
      !timelineObject?.dateFormatOptions ||
      !timelineObject?.currentDateFormat
    ) {
      return;
    }

    DATE_FORMAT_CONFIG.subitems = timelineObject.dateFormatOptions?.map(
      (dateFormat) => ({
        text: dateFormat,
        command: `SET_DATE_FORMAT_${dateFormat}`,
        checkable: true,
        checked: dateFormat === timelineObject.currentDateFormat
      })
    );
  }, [timelineObject.currentDateFormat, projectState]);

  return (
    <div className={styles['gantt-settings']}>
      <ToolbarGroup
        items={GANTT_SETTINGS_ITEMS}
        onCommandDispatched={onCommandDispatched}
        t={t}
      />
    </div>
  );
};
