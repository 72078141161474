import { Colors } from '../../../../constants/colors.constants';

function defineColorByTags(task, render = false, gantt = false) {
  if (!gantt) {
    gantt = window.to_use_react_gantt;
  }

  gantt.config.highlight_critical_path = false;
  if (task.type === 'milestone') return;

  const color = getBarColorWithProgressByTag(task.tags);
  task.progressSolidColor = color;
  task.color = getBarColorWithoutProgressByTag(color);

  if (render) gantt.optimizedRender();
}

function getBarColorWithProgressByTag(tags) {
  if (tags && tags.length) {
    const firstTag = tags[0];
    return firstTag
      ? firstTag.description
      : Colors.GANTT_BAR_GRAY_WITH_PROGRESS;
  }
  return Colors.GANTT_BAR_GRAY_WITH_PROGRESS;
}

function getBarColorWithoutProgressByTag(color) {
  const COLOR_DEFAULT = Colors.GANTT_BAR_GRAY_WITH_PROGRESS;
  return color !== COLOR_DEFAULT
    ? color + '4d'
    : Colors.GANTT_BAR_GRAY_WITHOUT_PROGRESS;
}

export { defineColorByTags };
