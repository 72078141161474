import React from 'react';

import { splitText } from '../../../ActivitiesUnitsGrid/index.helper';
/**
 * This component is the rendered fixed header col
 * @param {*} data all units
 * @param {*} width width for this space
 */
export const HeaderUnitCol =
  (data, width, selectedActivities, selectedUnits, locationArray) =>
  ({ columnIndex, rowIndex, style }) => {
    const unit = data[columnIndex];
    const isUnitSelected = selectedUnits.find(
      (u) => u.id == unit.id && !unit.disable
    );
    const parentLocation = locationArray.find(
      (loc) => loc.id == unit.locationId
    );
    let customClass = '';

    let hasAtLeastOneAvailableActivity = false;
    const onlyChilds = selectedActivities.filter((ac) => !ac.has_childs);
    for (let i = 0; i < onlyChilds.length; i++) {
      const ac = onlyChilds[i];
      if (!ac.disable) {
        hasAtLeastOneAvailableActivity = true;
        break;
      }
    }

    if (parentLocation) {
      const lastIndex = parentLocation.lastIndex;
      if (columnIndex == lastIndex - 1) {
        customClass += ' border-rigth-gray-matrix';
      }
    }

    if (columnIndex == data.length - 1) customClass = '';

    if (isUnitSelected && hasAtLeastOneAvailableActivity) {
      return (
        <div
          className={`fixed-unit-header-with-activities-matrix ${customClass}`}
          style={style}>
          <span>{splitText(unit.name, width)}</span>
        </div>
      );
    }
    return (
      <div
        className={`fixed-unit-header-without-activities-matrix ${customClass}`}
        style={style}>
        <span>{splitText(unit.name, width)}</span>
      </div>
    );
  };

/**
 * This component is the rendered fixed header location col
 * @param {*} data all locations
 */
export const HeaderLocationCol =
  (data) =>
  ({ columnIndex, rowIndex, style }) => {
    const location = data[columnIndex];
    let noBorder = '';

    if (columnIndex == data.length - 1) noBorder = 'no-border';

    return (
      <div style={style}>
        <div className={`location-header-fix-matrix locations ${noBorder}`}>
          {splitText(location.name, location.widthForHeader || 100)}
        </div>
      </div>
    );
  };

export const HeaderStructureCol =
  (data) =>
  ({ columnIndex, rowIndex, style }) => {
    const structure = data[columnIndex];
    let firstBorderRound = '';
    let lastBorderRound = '';

    if (columnIndex == 0) firstBorderRound = 'first-round';

    if (columnIndex == data.length - 1) lastBorderRound = 'last-round';

    return (
      <div style={style}>
        <div
          className={`location-header-fix-matrix matrix-structure ${firstBorderRound} ${lastBorderRound}`}>
          {splitText(structure.name, structure.widthForHeader || 100)}
        </div>
      </div>
    );
  };
