import * as Sentry from '@sentry/react';
import { log } from '../../../monitor/monitor';

/**
 * This function search all linked parents, finds the latest children, and connect this children with all linked parent tasks
 * @param {*} relations Relations comming from the autoschedule
 * @returns Array of relations of autoschedule
 */
export const getParentLinkedLinks = (relations) => {
  try {
    if (!window.to_use_react_gantt) return relations;
    const gantt = window.to_use_react_gantt;
    if (!gantt) return relations;
    const parentsRef = gantt.getTaskBy((task) => task.type == 'project');
    if (!parentsRef) return relations;

    const parentWithLinks = parentsRef.filter(
      (task) => task.$source && task.$source.length != 0
    );
    if (!parentWithLinks) return relations;

    for (let i = 0, n = parentWithLinks.length; i < n; i++) {
      const linkedParent = parentWithLinks[i];
      if (!linkedParent.$source) continue;
      const childrenLinkedParent = gantt.getChildren(linkedParent.id);
      const latestActivityChildren = childrenLinkedParent.find((children) => {
        const childrenGanttRef = gantt.getTask(children);
        const isSameEndDate =
          childrenGanttRef.end_date.getTime() ==
          linkedParent.end_date.getTime();
        if (isSameEndDate) return true;
      });
      for (let j = 0, m = linkedParent.$source.length; j < m; j++) {
        const parentLink = linkedParent.$source[j];
        const parentLinkRef = gantt.getLink(parentLink);
        if (!parentLinkRef) continue;
        if (parentLinkRef.type != 0) continue;
        const parentLinkLag = parentLinkRef.lag || 0;
        if (latestActivityChildren == parentLinkRef.target) continue;
        const hashSum = `${parentLinkLag}_0_${latestActivityChildren}_${parentLinkRef.target}`;
        const latestActivityChildrenRef = gantt.getTask(latestActivityChildren);
        if (!latestActivityChildrenRef) continue;
        const parentLinked = gantt.getTask(parentLinkRef.target);
        if (!parentLinked) continue;
        if (parentLinked.type != 'task') continue;

        const newLink = {
          target: parentLinkRef.target,
          link: parentLinkRef.type,
          id: parentLinkRef.id,
          sourceLag: 0,
          targetLag: 0,
          lag: parentLinkLag,
          trueLag: parentLinkLag,
          source: latestActivityChildren,
          preferredStart: null,
          sourceParent: latestActivityChildrenRef.parent,
          targetParent: parentLinked.parent,
          hashSum,
          subtaskLink: true
        };
        const alreadyExistInRelations = relations.some((relation) => {
          if (relation.hashSum == newLink.hashSum) return true;
        });
        if (alreadyExistInRelations) continue;
        log('CREATE_AUTOSCHEDULE_LINK', 'link id' + parentLinkRef.id);
        log('CREATE_AUTOSCHEDULE_LINK', 'hashSum' + hashSum);
        relations.push(newLink);
      }
    }
  } catch (error) {
    Sentry.captureMessage(error, 'warning');
  }

  return relations;
};
