import { submittalActions } from '../../../../redux/actions/submittalsActions';
import { store } from '../../../../redux/store';

const HOURS_IN_A_DAY = 24;
const MINUTES_IN_AN_HOUR = 60;
const SECONDS_IN_A_MINUTE = 60;
const MILLISECONDS_IN_A_SECOND = 1000;

/**
 * Subtract a number of days from a given date.
 *
 * @param {Date} date - The original date.
 * @param {number} days - The number of days to subtract.
 * @returns {Date} - The date after subtracting the given number of days.
 */
const subtractDaysFromDate = (date, days) => {
  const millisecondsToSubtract =
    days *
    HOURS_IN_A_DAY *
    MINUTES_IN_AN_HOUR *
    SECONDS_IN_A_MINUTE *
    MILLISECONDS_IN_A_SECOND;

  return new Date(date.getTime() - millisecondsToSubtract);
};

/**
 * Calculates the planned approval date based on the Required On-Site Date and lead time.
 */
export const calculateApprovalDatePlanned = (requiredOnSiteDate, leadTime) =>
  subtractDaysFromDate(requiredOnSiteDate, leadTime);

/**
 * Calculates the planned start date based on various times.
 */
export const calculateStartDatePlanned = (
  requiredOnSiteDate,
  leadTime,
  designTeamReviewTime,
  internalReviewTime
) => {
  const totalReviewTime = leadTime + designTeamReviewTime + internalReviewTime;
  return subtractDaysFromDate(requiredOnSiteDate, totalReviewTime);
};

/**
 * Check if the submittal state is valid.
 *
 * @param {Object} submittalState - The state of the submittal.
 * @returns {boolean} - True if the state is valid, otherwise false.
 */
const isValidSubmittalState = (submittalState) =>
  submittalState && Object.keys(submittalState).length > 0;

/**
 * Check if the task is valid.
 *
 * @param {Object} task - The task object.
 * @returns {boolean} - True if the task is valid, otherwise false.
 */
const isValidTask = (task) =>
  task && task.start_date && !isNaN(new Date(task.start_date).getTime());

/**
 * Check if both the task and submittal state are valid.
 *
 * @param {Object} task - The task object.
 * @param {Object} submittalState - The state of the submittal.
 * @returns {boolean} - True if both are valid, otherwise false.
 */
const isValidStateAndTask = (task, submittalState) =>
  isValidSubmittalState(submittalState) && isValidTask(task);

/**
 * Find submittals related to a specific task.
 *
 * @param {Object} task - The task object.
 * @param {Object} submittalState - The state of the submittal.
 * @returns {Array} - An array of related submittals.
 */
const findRelatedSubmittals = (task, submittalState) =>
  Object.values(submittalState).filter(
    (submittal) => submittal.activityId === task.proplannerId
  );

/**
 * Update a single submittal based on the task's start date.
 *
 * @param {Object} submittal - The original submittal.
 * @param {Date} taskStartDate - The start date of the task.
 * @returns {Object} - The updated submittal.
 */
const updateSubmittal = (submittal, taskStartDate) => {
  const leadTime = Number(submittal.leadTime);
  const designTeamReviewTime = Number(submittal.designTeamReviewTime);
  const internalReviewTime = Number(submittal.internalReviewTime);

  if (
    isNaN(leadTime) ||
    isNaN(designTeamReviewTime) ||
    isNaN(internalReviewTime)
  ) {
    console.warn(
      'Invalid time values in submittal. Skipping this submittal update.'
    );
    return;
  }

  const newStartDatePlanned = calculateStartDatePlanned(
    new Date(taskStartDate),
    leadTime,
    designTeamReviewTime,
    internalReviewTime
  );

  const newApprovalDatePlanned = calculateApprovalDatePlanned(
    new Date(taskStartDate),
    leadTime
  );

  return {
    ...submittal,
    requiredOnSiteDate: new Date(taskStartDate),
    startDatePlanned: newStartDatePlanned,
    approvalDatePlanned: newApprovalDatePlanned
  };
};

/**
 * Update related submittals based on a task.
 *
 * @param {Object} task - The task object.
 * @param {Object} currentSubmittalState - The current state of submittals.
 */
export const updateRelatedSubmittals = (task, currentSubmittalState) => {
  if (!isValidStateAndTask(task, currentSubmittalState)) {
    return;
  }

  const relatedSubmittals = findRelatedSubmittals(task, currentSubmittalState);
  if (relatedSubmittals.length === 0) {
    console.warn('No related submittals found for this task.');
    return;
  }

  const updatedSubmittals = relatedSubmittals
    .map((submittal) => updateSubmittal(submittal, task.start_date))
    .filter(Boolean);

  if (updatedSubmittals.length > 0) {
    store.dispatch(submittalActions.updateSubmittals(updatedSubmittals));
  }
};
