/* eslint-disable no-case-declarations */
import { SET_RESOURCE_ACTIVATED_COLS } from '../actions/resourceActions';

/**
 * Reducer function means a switch case to filter action data on the platform for general state
 * @param {*} state Real time updated through all components state (general state flux)
 * @param {*} action Action is an object with structyure { type: STRING, payload: FUNCTION }
 */
function resourceReducer(state = { activatedColumns: [] }, action) {
  switch (action.type) {
    case SET_RESOURCE_ACTIVATED_COLS:
      return {
        ...state,
        activatedColumns: action.payload
      };
    default:
      return state;
  }
}

export default resourceReducer;
