import { isCircularLinkExtended } from '../../../utils/circularDependencyDetection';
import { trackingEvent } from '../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';
import * as Sentry from '@sentry/react';
/**
 * This function check if predecessor is integer, and transform it to the link format
 * @param {*} predecessor value to check
 * @returns return predecessor in link format (fs)
 */
const transformPredecesors = (predecessor) => {
  const predecessors_list = predecessor.replaceAll(' ', '').split(';');
  const new_list = predecessors_list.map((elem) => {
    let ret = elem;
    const varInt = parseInt(elem);
    const varString = varInt.toString();

    if (varString === elem) {
      ret = `${elem}fs`;
    }
    return ret;
  });
  return new_list.join(';');
};

/**
 * @name after_save_filter_custom_predecessor
 * @example This method is executed after user press enter at the inline input to save the changes
 * @param state Virtual DOM element from Gantt to Angular
 */
export const after_save_filter_custom_predecessor =
  (gantt) => (state, modalCircular) => {
    gantt.abortAutoscheduleLinks = true;
    if (state.columnName == 'custom_predecessors') {
      const new_state_value = transformPredecesors(state.newValue).replaceAll(
        ' ',
        ''
      );
      if (new_state_value === state.oldValue) return;
      /** String in pure state catched from the user inline input editor (example: 5fs+0d;5ff+2d;1ff+10d) */
      state.newValue = new_state_value;
      gantt.getTask(state.id).custom_predecessors = state.newValue;

      get_predecessors_to_create_or_update(gantt)(state.newValue, state.id);
      filter_predecessor_string(gantt)(state.newValue, state.id, modalCircular);
    }
    gantt.abortAutoscheduleLinks = false;
    return true;
  };

/**
 * @name on_open_filter_custom_predecessor
 * @example This method is executed just before user double click at inline editor to get into the inline editor GRID table
 * @param state Virtual DOM element from Gantt to Angular
 */
export const on_open_filter_custom_predecessor = (gantt) => (state) => {
  if (state.columnName == 'custom_predecessors') {
    const task = gantt.getTask(state.id);
    const inputElement = document.getElementsByName(state.columnName);
    if (inputElement.length) {
      const element = inputElement[0];
      element.autocomplete = 'off';
      element.value = task.custom_predecessors;
      task.old_custom_predecessor = task.custom_predecessors;
    }
  }
};

/**
 * @name from_number_to_code
 * @example Transforms a Gantt DHMLX lib type link to words
 * @param code example FS catched from inline editing nomenclature
 */
export function from_number_to_code(code) {
  switch (code) {
    case '0':
      return 'fs';
    case '1':
      return 'ss';
    case '2':
      return 'ff';
    case '3':
      return 'sf';
  }
}

/**
 * @name from_code_to_number
 * @example  Transforms a type word into a Code of Gantt DHTMLX lib
 * @param relation_type Receives a code of relation extracted from nomenclature (Example: FS)
 */
export function from_code_to_number(relation_type) {
  switch (relation_type) {
    case 'fs':
      return '0';
    case 'ss':
      return '1';
    case 'ff':
      return '2';
    case 'sf':
      return '3';
  }
}

/**
 * @name from_links_to_string_predecessor
 * @example This method takes a task, get it's links and transforms into lag nomenclature (Example: 2fs+3d)
 * @param task TASK object created by loading data to Gantt DHTMLX lib (check gantt data lifecycle)
 */
export const from_links_to_string_predecessor =
  (gantt) =>
  (task, { isReturnPromise = false } = {}) => {
    const links = task.$target;
    const string_links_predecessors = links
      .map((link) => {
        link = gantt.getLink(link);
        // const from_connector_id = link.source
        const from_connector_id = gantt.getTask(link.source)?.correlative_id;
        if (!from_connector_id) {
          gantt.deleteLink(link.id);
          return;
        }
        const type = from_number_to_code(link.type);
        let operator = link.lag >= 0 ? '+' : '';
        if (!link.lag && operator == '') {
          operator = '+';
        }

        const lag = link.lag ? link.lag : 0;

        const formattedHourParse = gantt.formatter.format(lag);
        let lagString = '';
        /** Hour integration flow */
        if (formattedHourParse.includes('day')) {
          lagString = gantt.formatter.format(lag).split(' day')[0];
        } else {
          lagString = gantt.formatter.format(lag).split(' days')[0];
        }

        return from_connector_id + type + operator + lagString + 'd';
      })
      .filter((link) => Boolean(link));
    const finalString = string_links_predecessors.join(';');
    if (isReturnPromise) {
      return new Promise((resolve) => resolve(finalString));
    }
    return finalString;
  };

/**
 * @name validate_regex
 * @example chechks 2FS+3d example where 2 is the ID task from the link begins, FS means that finish from it task, to start to double clicked task through inline GRID table. 3 means LAG, and d means days.
 * @param string receives a string from the user after enter inline editing predeccessors
 */
export function validate_regex(string) {
  const regex =
    /(([A-Z]:|\\)\\([^\\]+\\)+)?\d+([fs|FS|ss|SS|ff|FF|sf|SF]{2}(([+-]\d+(\.\d)?\d*)+d)?)?(?=,|$)/;
  return regex.test(string);
}

/**
 * @name validate_regex_error_symbol
 * @example chechks 2FS- example where 2 is the task ID from the link starts, FS means the finish of the same task, minus is the symbol to be deleted.
 * @param string receives a string from the user after enter inline editing sucessor
 */
export function validate_regex_error_symbol(string) {
  const regex =
    /^(([A-Z]:|\\)\\([^\\]+\\)+)?\d+([fs|FS|ss|SS|ff|FF|sf|SF]{2}[+-])$/;
  return regex.test(string);
}

/**
 * @name send_error_message
 * @example Shows a message with ERROR layout with a dinamic text
 * @param text Text to show in a Gantt message
 */
export const send_error_message = (gantt) => (text) => {
  gantt.message({
    type: 'error',
    text: text
  });
};

/**
 * @name send_warning_message
 * @example Shows a message with ERROR layout with a dinamic text
 * @param text Text to show in a Gantt message
 */
export const send_warning_message = (gantt) => (text) => {
  gantt.message({
    type: 'warning',
    text: text
  });
};

/**
 * @name check_links_deleted
 * @example This method analize a string from inline editing from user, and returns links to delete (user deletes from the string nomenclature)
 * @param diffMe a string to split by a other string
 * @param diffBy a string to use to split param diffME
 */
export function check_links_deleted(diffMe, diffBy) {
  const delete_links = diffMe.replace(diffBy, '');
  return validate_regex(delete_links) ? delete_links : diffMe;
}

/**
 * @name get_task_and_links
 * @example This method use the id task, to transorm it into an object of it self and it links
 * @param task_id task id to get it Object and also it links
 */
export const get_task_and_links = (gantt) => (task_id) => {
  const task_object = gantt.getTask(task_id);
  const task_links = task_object.$target;
  const task_links_objects = task_links.map((link_id) =>
    gantt.getLink(link_id)
  );
  return { task_object, task_links_objects };
};

/**
 * @example Checks those predecessors that were at the old string, but there are not anymore at the new predecessors string, so those links must be deleted. This function return the predecessor string with links deleted at inline editor GRID table
 * @param predecessor_string receives an nomenclature predecessor string (example: 5fs+0d;5ff+2d;1ff+10d )
 * @param to_task_id double clicked task at inline editing
 */
export const get_predecessors_to_create_or_update =
  (gantt) => (predecessor_string, to_task_id) => {
    const data_container = get_task_and_links(gantt)(to_task_id);
    const old = data_container.task_object.old_custom_predecessor;
    const current = data_container.task_object.custom_predecessors;
    const deleted_links_predecessors_string = check_links_deleted(old, current);
    const predecessor = deleted_links_predecessors_string;

    /** We break the method if current string is longer */
    if (current == old) {
      return false;
    }

    /** This will return an array of each predecessors Example: [ '2FS+2', 'FS+5' ]  */
    const predecessors_list = predecessor.split(';');
    const links_deleted = [];

    /** This mapping each array example before e */
    predecessors_list.map((predecessor) => {
      if (!validate_regex(predecessor)) {
        if (predecessor == '') return false;
        send_error_message(gantt)(`Predecessor ${predecessor} not valid.`);
        return false;
      }
      const link_container = from_predecessor_string_to_link_object(gantt)(
        predecessor,
        to_task_id
      );
      const does_exist = get_link_if_exist(
        data_container.task_links_objects,
        link_container.from_connector_id,
        link_container.to_connector_id,
        link_container.relation_type
      );

      const links_not_removed = does_exist.filter((link) => {
        // It is searched that the does_exist element is not found in links_deleted
        const isLinkDeleted = links_deleted.find(
          (remove) => remove === link.id
        );
        // Only the not found elements are stored in links_not_removed.
        return !isLinkDeleted;
      });

      if (links_not_removed.length > 0) {
        const link_id = links_not_removed[0].id;
        gantt.deleteLink(link_id);
        links_deleted.push(link_id);
        return true;
      }

      if (!link_container.from_connector_id) {
        send_error_message(gantt)('No ID from task to connect link.');
        return false;
      } else if (!link_container.relation_type) {
        send_error_message(gantt)('No relation assigned (FF|FS|SF|SS).');
        return false;
      } else if (
        link_container.from_connector_id == link_container.to_connector_id
      ) {
        send_error_message(gantt)('Cant create link to self task.');
        return false;
      }
    });
  };

/**
 * @name from_predecessor_string_to_link_object
 * @example This methdos returns an object in custom mode link ( example: { from_connector_id: 2, to_connector_id: 3, ..} instead of { source: 2, target: 3, ... } as in Gantt mode link)
 * @param predecessor_string receives a SINGLE predecessor string (example: 2fs+3d is correct, 2fs+3d;5ff+3d is wrong)
 * @param to_task_id double clcked task inline editor by user
 */
export const from_predecessor_string_to_link_object =
  (gantt) => (predecessor_string, to_task_id) => {
    predecessor_string = predecessor_string.toLowerCase();
    const structure = predecessor_string.split(
      /((?:(?:\\\\|[A-Z]:).*\\)?\d+(?:(?:FS|FF|SS|SF)(?:\+|-)?(?:\d+(\.\d)?\d+d)?)?)/
    );
    const from_connector_id = gantt.getTaskBy(
      (task) => task.correlative_id == structure[1]
    ).length
      ? gantt.getTaskBy((task) => task.correlative_id == structure[1])[0].id
      : null;
    const to_connector_id = to_task_id;
    const relation_type = structure[3]
      ? structure[3].split('+').join('').split('-').join('')
      : false;
    const operation = structure[3]
      ? structure[3].includes('+')
        ? '+'
        : '-'
      : false;
    let lag;
    /** Decimal */
    if (structure.length == 10) {
      lag = parseFloat(
        structure[4] && structure[7] && structure[6] == '.'
          ? operation + structure[4] + '.' + structure[7]
          : '0'
      );
    } else if (structure.length == 7) {
      lag = parseInt(structure[4] ? operation + structure[4] : '0');
    } else {
      lag = parseInt('0');
    }
    return { from_connector_id, to_connector_id, relation_type, lag };
  };

/**
 *
 * @param links list of links in OBJECT format
 * @param from_connector_id id extracted from predecessor nomenclature (Example ID belong to 5 in: 5FS+3d)
 * @param to_connector_id id from task that was double click at predecessors column in inline GRID table
 * @param relation_type relation extracted from predecessor nomenclature  (example: FS relation)
 */
export function get_link_if_exist(
  links,
  from_connector_id,
  to_connector_id,
  relation_type
) {
  return links.filter(
    (link) =>
      link.source == from_connector_id &&
      link.target == to_connector_id &&
      link.type == from_code_to_number(relation_type)
  );
}

/**
 * @example This methods takes the lag nomenclature and transforms it into new links for data at Gantt DHTMLX lib.
 * @param predecessor_string receives a string previously filtered (deleted links user deletes)
 * @param to_task_id task id catched from inline editor double click
 * @param modalCircular is a function to show a modal when a circular dependency is detected
 */
export const filter_predecessor_string =
  (gantt) => (predecessor_string, to_task_id, modalCircular) => {
    const data_container = get_task_and_links(gantt)(to_task_id);

    const predecessors_list =
      predecessor_string.split(
        ';'
      ); /** This will return an array of each predecessors Example: [ '2FS+2', 'FS+5' ] */

    return predecessors_list.map((predecessor) => {
      if (validate_regex_error_symbol(predecessor))
        predecessor = predecessor.replace(/[+-]$/, '');
      if (!validate_regex(predecessor)) {
        if (predecessor == '') return false;
        send_error_message(gantt)(`Predecessor ${predecessor} not valid.`);
        return false;
      }
      const link_container = from_predecessor_string_to_link_object(gantt)(
        predecessor,
        to_task_id
      );
      const hourFormatToParse = link_container.lag + ' days';

      const new_link = {
        source: link_container.from_connector_id,
        target: link_container.to_connector_id,
        type: from_code_to_number(link_container.relation_type),
        lag: gantt.formatter.parse(hourFormatToParse) // link_container.lag
      };

      if (isCircularLinkExtended(new_link, gantt)) {
        modalCircular();
        return false;
      }

      const does_exist = get_link_if_exist(
        data_container.task_links_objects,
        link_container.from_connector_id,
        link_container.to_connector_id,
        link_container.relation_type
      );

      if (does_exist.length > 0) {
        if (link_container.lag !== does_exist[0].lag) {
          // send_warning_message(gantt)(`Updating link ID ${does_exist[0].id}`)
          /** Update for hour handling */
          const hourFormatToParse = link_container.lag + ' days';
          does_exist[0].lag = gantt.formatter.parse(hourFormatToParse); // link_container.lag
          return true;
        }
        return false;
      }

      if (!link_container.from_connector_id) {
        send_error_message(gantt)('No ID from task to connect link.');
        return false;
      } else if (!link_container.relation_type) {
        send_error_message(gantt)('No relation assigned (FF|FS|SF|SS).');
        return false;
      } else if (
        link_container.from_connector_id == link_container.to_connector_id
      ) {
        send_error_message(gantt)('Cant create link to self task.');
        return false;
      }

      try {
        gantt.addLink(new_link);
      } catch (e) {
        Sentry.captureException(e);
      }

      trackingEvent(
        'try_add_link',
        {
          getBasicAmplitudEventProperties,
          location: 'from filter_predecessor_string'
        },
        AMPLITUDE_SERVICE
      );

      const getTask = gantt.getTask(to_task_id);
      trackingEvent(
        'activity_predecessor_entry',
        {
          ...getBasicAmplitudEventProperties(),
          activity_name: getTask?.text,
          activity_id: getTask?.id,
          activity_index: getTask?.correlative_id
        },
        AMPLITUDE_SERVICE
      );
      return true;
    });
  };
