import React from 'react';
import ModalSystem from '../../../../../../DesignSystem/ModalSystem';
import { WarningIcon } from '../../../../../../../icons';

const CleanFiltersModal = ({ isVisible, setShowModal, clearFilters, t }) => {
  const contentChildren = () => (
    <div className="delete-filters-modal delete-stage__content">
      <div className="content__form">
        <div className="form__icon">
          <WarningIcon color="#ED8536" />
        </div>
        <div className="form__title">
          <h5>{t('activity_card.activity_hide_clear_filters')}</h5>
        </div>
      </div>
      <div className="content__buttons">
        <button className="buttons__cancel" onClick={() => setShowModal(false)}>
          {t('delete_stage.button_cancel_text')}
        </button>
        <button className="buttons__delete" onClick={clearFilters}>
          {t('activity_card.clear_filters')}
        </button>
      </div>
    </div>
  );

  return ModalSystem({
    visible: isVisible,
    theme: 'dark',
    setVisible: setShowModal,
    showTitle: false,
    centered: true,
    width: 520,
    children: contentChildren()
  });
};

export default CleanFiltersModal;
