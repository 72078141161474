import React, { useState, useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

function VelocityCurve(props) {
  const { t } = props;
  let series;
  const [isLoading, setLoading] = useState(true);
  const [Options, setOptions] = useState({});
  const userLang = navigator.language || navigator.userLanguage;
  useEffect(() => {
    velocity();
  }, [props.dataCurve]);

  const velocity = async () => {
    let title;
    let point;
    if (userLang.includes('es')) {
      title = 'Curva de velocidad';
      point = 'Velocidad';
    } else {
      title = 'Speed curve';
      point = 'Speed';
    }
    const data = props.dataCurve;
    if (data.options) {
      const dates = props.dataCurve.options.xaxis.full_date;
      series = [
        {
          data: [],
          name: point
        }
      ];
      let stateReal = true;
      dates.forEach((element, index) => {
        let rt1;
        let bt1;
        if (index == 0) {
          rt1 = 0;
          bt1 = 0;
        } else {
          bt1 = data.raw_data[0].base[index - 1];
          rt1 = data.raw_data[2].real[index - 1];
        }
        const bt2 = data.raw_data[0].base[index];
        const rt2 = data.raw_data[2].real[index];

        let spi = (rt2 - rt1) / (bt2 - bt1);

        if (isNaN(spi) || !isFinite(spi)) {
          spi = 0;
        }

        // esto para validar el primer real sea mayor a 0 y no se pase del ultimo real
        if (stateReal) {
          if (data.raw_data[2].real[index] > 0) {
            series[0].data.push({ x: element, y: spi.toFixed(3) });
          }
        }
        if (
          data.raw_data[2].real[index] ==
          data.raw_data[2].real[data.raw_data[2].real.length - 1]
        ) {
          // d == res.series[2].data[res.series[2].data.length-1]
          stateReal = false;
        }
      });

      const options = {
        series: series,
        options: {
          chart: {
            height: 500,
            type: 'line'
          },
          dataLabels: { enabled: false },
          stroke: {
            show: true,
            width: 1.9
          },
          markers: {
            size: [6, 0]
          },
          tooltip: {
            shared: false,
            intersect: true
          },
          legend: {
            show: false
          },
          xaxis: {
            type: 'date'
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return t('lang') === 'en'
                  ? parseFloat(value).toFixed(2)
                  : parseFloat(value).toFixed(2).replace('.', ',');
              }
            }
          },
          title: {
            text: title
          },
          annotations: {
            yaxis: [
              {
                y: 1,
                width: '100%',
                height: '25px',
                strokeDashArray: 0,
                borderColor: '#00E396',
                label: {
                  borderColor: '#00E396',
                  text: '',
                  style: {
                    color: '#fff',
                    background: '#00E396',
                    fontSize: '14px'
                  }
                }
              }
            ]
          }
        }
      };
      setOptions(options);
      setLoading(false);
    }
  };
  const renderChart = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Chart
        options={Options.options}
        series={Options.series}
        width="100%"
        height="100%"
      />
    );
  };
  return <div style={{ height: '100%' }}>{renderChart()}</div>;
}

export default withTranslation()(VelocityCurve);
