/** React components  */
import React, { useState, useEffect } from 'react';

/** Import elements from library Antd */
import { Row, message } from 'antd';

/** import components */
import ImportGanttUpload from '../../../components/ImportGanttUpload';
import ImportGanttProcess from '../../../components/ImportGanttProcess';
import ImportGanttFinish from '../../../components/ImportGanttFinish';

import './newImport.css';

import { Animated } from 'react-animated-css';

export default function NewImportView(props) {
  /** use props */
  const { visibleImporter, setVisibleImporter } = props;
  /** hooks */
  const [visibleProcess, setVisibleProcess] = useState(false);
  const [visibleFinish, setVisibleFinish] = useState(false);
  const [currenTab, setCurrenTab] = useState(1); /** handle tabs (1, 2, 3, 4) */
  const [stateUpload, setStateUpload] = useState({ loading: false });
  const [fileInfo, setFileInfo] = useState({ name: null });
  const [listOptions, setListOptions] = useState(null);
  const [startTimeUpload, setStartTimeUpload] = useState(null);

  useEffect(() => {
    if (visibleImporter) {
      setCurrenTab(1);
    }
  }, [visibleImporter]);

  const propsUpload = {
    setCurrenTab: setCurrenTab,
    currenTab: currenTab,
    setVisibleImporter: setVisibleImporter,
    visibleImporter: visibleImporter,
    setStateUpload: setStateUpload,
    setFileInfo: setFileInfo,
    fileInfo: fileInfo,
    setVisibleProcess: setVisibleProcess,
    startTimeUpload: startTimeUpload,
    setStartTimeUpload: setStartTimeUpload
  };

  const propsProcess = {
    setVisible: setVisibleProcess,
    visible: visibleProcess,
    listOptions: listOptions,
    setListOptions: setListOptions,
    stateUpload: stateUpload,
    fileInfo: fileInfo,
    setVisibleFinish: setVisibleFinish
  };

  const propsFinish = {
    visible: visibleFinish,
    listOptions: listOptions,
    setVisibleFinish: setVisibleFinish,
    fileInfo: fileInfo,
    stateUpload: stateUpload,
    setStateUpload: setStateUpload,
    setFileInfo: setFileInfo,
    setListOptions: setListOptions,
    startTimeUpload: startTimeUpload,
    setStartTimeUpload: setStartTimeUpload
  };

  return (
    <Animated animationIn="fadeIn" animationInDuration={500} isVisible={true}>
      <ImportGanttUpload {...propsUpload} />
      {stateUpload.name ? <ImportGanttProcess {...propsProcess} /> : null}
      {listOptions ? <ImportGanttFinish {...props} {...propsFinish} /> : null}
    </Animated>
  );
}
