import React, { memo } from 'react';
import TruncatedTextSystem from '../../../../../DesignSystem/TruncatedTextSystem';

const Surname = memo(({ surname, width }) => {
  return (
    <TruncatedTextSystem
      name={surname}
      maxWidth={width > 1600 ? 130 : 80}
      placement={'topLeft'}>
      <span className="surname-column">{surname}</span>
    </TruncatedTextSystem>
  );
});

export default Surname;
