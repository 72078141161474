import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Card, Button, Empty, Select, Popconfirm, Icon } from 'antd';
import styled from 'styled-components';
import deleteIcon from '../../icons/delete.svg';
import { cncTypeService, cncService } from '../../../../../../services';
import {
  openNotification,
  categoriesCncType,
  renderIconCat
} from '../../../../../../utils';
import { useSelector } from 'react-redux';
import Editable from '../../../../Editable/Editable';

function CNCTypes({ CncTypes, setCncTypes, onlyRead, t }) {
  const { Option } = Select;
  /** refs */
  const inputRef = useRef();

  /** hooks */
  const [idEdit, setIdEdit] = useState(false); /** handle load */
  const [allCncsProject, setAllCncsProject] = useState([]);

  // GET ID PROJECT FROM REDUX
  const stateProject = useSelector((state) => state.projectState);
  const projectSelectedId = stateProject.projectSelected;

  useEffect(() => {
    getCncAll();
  }, [projectSelectedId]);

  const getCncAll = async () => {
    const allCncs = [];
    const syncMap = stateProject.allSectors.map(async (el) => {
      const list = await cncService.showBySector(el.id);
      allCncs.push(...list.cncs);
    });
    await Promise.all(syncMap);
    setAllCncsProject(allCncs);
  };

  useEffect(() => {
    const abortController = new AbortController();
    cncTypeService
      .showByProject(projectSelectedId)
      .then((res) => {
        setCncTypes(res.cnctypes);
      })
      .catch((err) => console.log(err));
    return function cleanup() {
      abortController.abort();
    };
  }, [projectSelectedId]);

  useEffect(() => {
    if (idEdit !== false) {
      updateCat(CncTypes[idEdit]);
    }
  }, [idEdit]);

  const updateCat = async (data) => {
    const resp = await cncTypeService.update(data);
    setIdEdit(false);
  };

  const handleCatsSettings = (index, name, value) => {
    const copyCats = [...CncTypes];
    copyCats[index] = {
      ...copyCats[index],
      [name]: value
    };
    setCncTypes(copyCats);
    setIdEdit(index);
  };

  const deleteCncType = (constraintId) => {
    const some = allCncsProject.some((el) => el.cncTypeId === constraintId);
    if (some) {
      const alertError = {
        title: t('settings.project_categories.cncs.title'),
        description: t('settings.project_categories.cncs.cannot_delete'),
        type: 'error'
      };
      openNotification(alertError);
      return false;
    }
    cncTypeService
      .destroy(constraintId)
      .then((res) => {
        if (res === false) {
          console.log(t('settings.error_default'));
        } else {
          const newCncTypes = CncTypes.filter(
            (type) => type.id !== constraintId
          );
          setCncTypes(newCncTypes);
          const alertSuccess = {
            title: t('settings.project_categories.cncs.title'),
            description: t('settings.project_categories.cncs.deleted'),
            type: 'success'
          };
          openNotification(alertSuccess);
        }
      })
      .catch(() => {
        const alertError = {
          title: t('settings.project_categories.cncs.title'),
          description: t('settings.error_default'),
          type: 'error'
        };
        openNotification(alertError);
      });
  };
  return (
    <Fragment>
      <div className="position-btn-add-right cnc-right">
        <StyleButton>
          <Button disabled={onlyRead} className="btn-agregar-right" type="text">
            {t('settings.project_categories.cncs.template_add')}
          </Button>
          <Button disabled={onlyRead} className="btn-agregar-right" type="text">
            {t('settings.project_categories.cncs.template_save')}
          </Button>
        </StyleButton>
      </div>
      {/* CARTAS 2 */}
      <div className="scroll-table">
        {CncTypes.length ? (
          CncTypes.map((value, index) => (
            <Card
              bodyStyle={{ padding: '0px' }}
              className="card-category"
              key={value.id}>
              <div className="grid-cards-category">
                <div className="cat-name">
                  <Editable
                    text={value.name}
                    placeholder={t(
                      'settings.project_categories.cncs.name_table'
                    )}
                    childRef={inputRef}
                    type="input"
                    className="input-table-edit">
                    <input
                      disabled={onlyRead}
                      ref={inputRef}
                      type="text"
                      name="task"
                      className="input-inline"
                      placeholder={t(
                        'settings.project_categories.cncs.name_table'
                      )}
                      value={value.name}
                      onChange={(e) =>
                        handleCatsSettings(index, 'name', e.target.value)
                      }
                      autoComplete="off"
                    />
                  </Editable>
                </div>

                <div className="cat-icon">
                  <img
                    src={renderIconCat(
                      categoriesCncType.find((el) => el.value === value.type)
                        .icon
                    )}
                    alt=""
                  />
                </div>

                <div className="mt-category-txt cat-type">
                  <SelectionCargoStyle>
                    <Select
                      disabled={onlyRead}
                      value={value.type}
                      style={{
                        width: ' 100%',
                        fontSize: '12px'
                      }}
                      name="type"
                      onChange={(value) =>
                        handleCatsSettings(index, 'type', value)
                      }>
                      {categoriesCncType.map((cat) => (
                        <Option
                          name="position"
                          key={cat.value}
                          title={cat.label}
                          style={{ fontSize: '12px' }}>
                          {/* {cat.label} */}
                          {t(`settings.project_categories.${cat.trad}`)}
                        </Option>
                      ))}
                    </Select>
                  </SelectionCargoStyle>
                </div>
                <div className="grid-category-delete cat-actions">
                  <div className="mt-category-txt">
                    <Popconfirm
                      placement={'bottom'}
                      onConfirm={() => deleteCncType(value.id)}
                      title={t('are_sure_general')}
                      icon={
                        <Icon
                          type="question-circle-o"
                          style={{ color: 'red' }}
                        />
                      }>
                      {onlyRead ? null : (
                        <a>
                          <img src={deleteIcon} alt="" />
                        </a>
                      )}
                    </Popconfirm>
                  </div>
                </div>
              </div>
            </Card>
          ))
        ) : (
          <div style={{ marginTop: 38 }}>
            <Empty
              style={{ margin: 0 }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span>{t('settings.project_categories.cncs.name_table')}</span>
              }
            />
          </div>
        )}

        {/* {} */}
      </div>
    </Fragment>
  );
}

export default CNCTypes;

const StyleButton = styled.div`
  .ant-btn {
    border-color: transparent;
    visibility: hidden;
  }
`;

const SelectionCargoStyle = styled.div`
  .ant-select-selection {
    border-radius: 5px !important;
    width: 100px;
  }
  .ant-select-selection {
    background-color: white !important;
    color: #5f5f5f;
  }
  .anticon svg {
    color: transparent;
  }

  .ant-select-selection,
  ant-select-selection--single {
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    border-top: 0px;
  }

  .ant-select-selection:focus,
  ant-select-selection--single:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;
