import React, { useContext, useEffect, useState } from 'react';
import { ACTIONS } from '../../scroll.actions';

// Components
import { FixedSizeGrid as Grid } from 'react-window';

// Context
import { ProductivityAssignCtx } from '../../../../Context/Productivty/Assign';

// Assets
import styles from './index.module.css';

// Utilities
import { splitText } from '../../../ActivitiesUnitsGrid/index.helper';

const Column =
  (data, resources, widthCell) =>
  ({ columnIndex, style }) => {
    if (!data) {
      return <div style={style}></div>;
    }

    const currentResource = data[columnIndex];
    const resourceSelected = !!resources.find(
      (resource) => resource.id == currentResource.id
    );

    return (
      <div
        style={style}
        className={`${styles.columnsHeaders} ${resourceSelected && styles.selected}`}>
        {splitText(currentResource.name, widthCell)}
      </div>
    );
  };

const HeaderResourcesGrid = (props) => {
  const {
    className,
    resourceHeaderRef,
    gridListRef,
    gridWidth,
    listResources,
    onScroll
  } = props;

  const productivityAssign = useContext(ProductivityAssignCtx);

  // ==> States
  const [columns, setColumns] = useState(0);
  const [widthCell, setWidthCell] = useState(100);

  // ==> start  hooks

  useEffect(() => {
    if (listResources) {
      setColumns(listResources.length);
    }
  }, [listResources]);

  useEffect(() => {
    const width = gridListRef.current.offsetWidth;
    if (columns) {
      const widthPerCell = width / columns;
      widthPerCell > 100 ? setWidthCell(widthPerCell) : setWidthCell(100);
    }
  }, [columns, gridListRef]);

  // ==> end hooks

  const handleScroll = (scroll) =>
    onScroll({ scrollFrom: ACTIONS.HEADER })(scroll);

  return (
    <div className={className} ref={gridListRef}>
      <Grid
        style={{ overflowX: 'hidden !important', width: '100%' }}
        ref={resourceHeaderRef}
        columnCount={columns}
        rowCount={1}
        columnWidth={widthCell}
        rowHeight={35}
        height={35}
        width={gridWidth}
        onScroll={handleScroll}>
        {Column(listResources, productivityAssign.resourceSelected, widthCell)}
      </Grid>
    </div>
  );
};

export default React.memo(HeaderResourcesGrid);
