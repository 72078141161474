import React, { useState, useEffect } from 'react';
import { RENDER_MACHINERY } from '../constants';
import { withTranslation } from 'react-i18next';
import {
  findDeepGetTask,
  updateAsyncTaskGanttV2
} from '../../GanttVisualization.helper';
import { crane } from '../../../../utils';

import IconComponent from '../../../Projects/IconSvg';
import CustomDropdownSelector from '../../../CustomDropdownSelector';
import RemoveIcon from '../../../../assets/img/gantt/X.png';

const RenderMachinery = ({
  column,
  lastLevelActivities,
  activities,
  gantt,
  t,
  onlyRead,
  task,
  callbackOnClickCreate,
  callbackOnClickRemove,
  options
}) => {
  const isOnlyReadElement = onlyRead || task.readonly;
  const [state, setState] = useState(RENDER_MACHINERY);
  const [resources, setResources] = useState([]);
  const [resourceMachine, setResourceMachine] = useState([]);
  const taskFromLookahead = findDeepGetTask(activities, 'id', task.id);

  useEffect(() => {
    options && setResources(options);
  }, [options]);

  useEffect(() => {
    if (resources) {
      setResourceMachine(resources);
    }
  }, [resources]);

  useEffect(() => {
    setState({
      ...state,
      mainObject: task,
      options: resourceMachine.map((r) => r.plain)
    });
  }, [task, resourceMachine]);

  const callbackOnSelection = (payload) => {
    setState({
      ...state,
      mainObject: {
        ...state.mainObject,
        machineId: payload.split('-')[0]
      }
    });
    updateAsyncTaskGanttV2(
      taskFromLookahead,
      column.name,
      payload.split('-')[0],
      gantt
    );
  };

  const callbackRemove = (_) => {
    setState({
      ...state,
      mainObject: {
        ...state.mainObject,
        machineId: null
      }
    });
    callbackOnClickRemove && callbackOnClickRemove();
  };

  const renderMachineryToSelect = (option) => {
    const { object } = option;
    return (
      <div style={{ paddingLeft: 10 }}>
        <span>
          <IconComponent data={crane} width={16} fill="#000" />
        </span>
        <span style={{ marginLeft: 10 }}>{option.name}</span>
      </div>
    );
  };
  const renderRemoveBtn = () => {
    return (
      <div className="remove-option-sub-custom">
        <div className="remove-sub-x">
          <img width="10" src={RemoveIcon} />
        </div>
        <div className="remove-sub-text">{t('remove_subcontract_label')}</div>
      </div>
    );
  };

  let isOdd = false;
  if (gantt?.oddColsConfig && task.type === 'activitytask') {
    isOdd = gantt.oddColsConfig.machineId;
  }

  if (!task.isTask) return null;
  return (
    <div className={isOdd ? 'odd-col' : 'non-odd-col'}>
      <CustomDropdownSelector
        {...state}
        customMainClassName="custom-material-gantt"
        renderCustomSelectionOption={renderMachineryToSelect}
        renderCustomRemoveOption={renderRemoveBtn}
        labelCreateBtn={
          <div className="create-sub-btn">+ {t('new_machinery_dropdown')}</div>
        }
        callbackOnSelection={callbackOnSelection}
        callbackOnClickCreate={callbackOnClickCreate}
        callbackOnClickRemove={callbackRemove}
        onlyread={isOnlyReadElement}
      />
    </div>
  );
};

export default withTranslation()(RenderMachinery);
