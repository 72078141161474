import React from 'react';

import Icon from '../../../../assets/img/takt/iconCompleted.svg';
import IconCorner from '../../../../assets/img/takt/check-esquina.svg';

/**
 * This component is the rendered element between Y (activity) and X (unit) which show the match of selected items
 * @param {*} activities all activities in lineal tree structure
 * @param {*} units all units in lineal tree structure
 * @param {*} selectedActivities Selected activities at checkbox
 * @param {*} selectedUnits Selected units at checkbox
 * @param {*} locationArray locations with units array
 */
export const GridCell =
  (
    activities,
    locationColorsHash,
    groupByActivity,
    onClick = () => {},
    viewFontSize = 10,
    gridCols,
    ganttAPI
  ) =>
  ({ columnIndex, rowIndex, style }) => {
    const currentDate = gridCols[columnIndex];
    const color = currentDate.workable ? 'white' : '#8080804a';

    const grouppedActivity = activities[rowIndex];
    if (!grouppedActivity.Xaxis) return <div></div>;
    const fitTasks = grouppedActivity.Xaxis[columnIndex];
    let singleHeight = '100%';
    if (grouppedActivity.maxNumberOfTaskPerRow) {
      singleHeight =
        grouppedActivity.maxHeight / grouppedActivity.maxNumberOfTaskPerRow;
    }

    const handleDoubleClick = (t) => {
      t.isTask = true;
      onClick(t);
    };
    return (
      <div
        className={'grid-element-without-units-matrix'}
        style={{
          ...style,
          paddingTop: 0,
          fontSize: viewFontSize,
          backgroundColor: color
        }}>
        {fitTasks.map((t) => {
          let isTaskWorkable = currentDate.workable;
          if (t.calendarId) {
            isTaskWorkable = ganttAPI.isWorkable(
              currentDate.value,
              t.calendarId
            );
          }

          const locationId = t.productionUnitRelation
            ? t.productionUnitRelation.productionunit.locationId
            : null;
          let locationHash = null;
          if (locationId) {
            locationHash = locationColorsHash.find(
              (el) =>
                el.id == t.productionUnitRelation.productionunit.locationId
            );
          }

          if (t.hide || !isTaskWorkable) {
            return <div></div>;
          }
          return (
            <div
              onDoubleClick={() => handleDoubleClick(t)}
              style={{
                backgroundColor: locationHash ? locationHash.color : 'white',
                cursor: 'pointer',
                height: singleHeight,
                color: 'white',
                paddingTop: 7,
                position: 'relative'
              }}>
              {t.name}
              <span style={{ position: 'absolute', left: '0px', top: '0px' }}>
                {t.progress == 100 && (
                  <img
                    src={IconCorner}
                    style={{
                      position: 'absolute',
                      left: '0',
                      top: '0',
                      width: '15px'
                    }}
                  />
                )}
              </span>
            </div>
          );
        })}
      </div>
    );
  };
