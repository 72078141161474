import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const projectCurrencySimbol = [
  { value: 'CLP', label: '$' },
  { value: 'UF', label: 'UF' },
  { value: 'USD', label: '$' },
  { value: 'EUR', label: 'Є' },
  { value: 'PEN', label: 'S/' },
  { value: 'ARS', label: '$' },
  { value: 'UYU', label: '$' },
  { value: 'BRL', label: 'R$' },
  { value: 'COP', label: '$' },
  { value: 'MXN', label: '$' },
  { value: 'BOB', label: 'BS' },
  { value: 'VES', label: 'Bs' },
  { value: 'GYD', label: '$' },
  { value: 'SRD', label: '$' },
  { value: 'TTD', label: '$' },
  { value: 'RP', label: 'Rp' },
  { value: 'CAD', label: 'C$' },
  { value: 'GBP', label: '£' }
];

const MoneySymbolString = (props) => {
  const currentProject = JSON.parse(sessionStorage.getItem('currentProject'));
  const symbol = projectCurrencySimbol.find(
    (pc) => pc.value == currentProject.currency
  );
  return symbol.label;
};
export default MoneySymbolString;
