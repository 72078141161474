import React, { useState, useEffect } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import './index.css';

import { companyService } from '../../../../../services/company.service';
import { projectService } from '../../../../../services/project.service';
import { userService } from '../../../../../services/user.service';
// REDUX
import * as projectActions from '../../../../../redux/slices/projectSlice';
import { useSelector, useDispatch } from 'react-redux';

/** components */
import ItemCard from '../../../../../components/Projects/ListProjects/ItemCard';
import { dynamicSort, setAllProjectsGlobal } from '../../../../../utils';
import { useSelectProject } from '../../../../../hooks/useSelectProject';

function CardsGeneral(props) {
  const stateCompany = useSelector((state) => state.companyState);
  const projectState = useSelector((state) => state.projectState);
  const idCurrentCompany = stateCompany.currentCompany?.id;
  const { onClickProject } = useSelectProject();
  const user = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();

  const [allProjects, setallProjects] = useState([]);
  useEffect(() => {
    const abortController = new AbortController();
    companyService
      .show(idCurrentCompany)
      .then((response) => {
        getProjectsLoad();
      })
      .catch((err) => console.log(err));

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  const getProjectsLoad = async () => {
    try {
      const getProjects = await userService.projectsbyuserthrough(user.id);
      const projectsActive = getProjects.projects.filter(
        (e) => e.stage !== 'deleted'
      );
      setallProjects(projectsActive);
      setAllProjectsGlobal(
        projectsActive,
        dispatch,
        projectActions,
        projectState
      );
    } catch (error) {
      setallProjects([]);
      console.log('Error loading projects:', error);
    }
  };

  const updateProject = (data) => {
    projectService
      .update(data)
      .then((res) => {
        // updating allprojects from redux after updating in the backend
        dispatch(projectActions.setAllProjects(allProjects));
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="cards-setting-user">
      {allProjects.sort(dynamicSort('name')).map((project, index) => (
        <ItemCard
          key={index}
          {...props}
          project={project}
          showActionsMenu={false}
          caller="settings"
          onClickProject={onClickProject}
        />
      ))}
    </div>
  );
}

export default CardsGeneral;
