import React from 'react';
import { Tooltip, Popover } from 'antd';
import { capitalize } from 'lodash';
import {
  defineIcon,
  findDeepGetTask,
  updateAsyncTaskGanttV2
} from '../../GanttVisualization.helper';

const RenderPriority = ({
  task,
  node,
  column,
  activities,
  gantt,
  t,
  onlyRead
}) => {
  /** Check if data is defined. Activity don't have this data */
  if (!task.isTask) return null;
  if (task.is_milestone) return null;

  const isOnlyReadElement = onlyRead || task.readonly;
  /** get reference task (taskFromLookahead) from activities */
  const taskFromLookahead = findDeepGetTask(activities, 'id', task.id);
  if (!taskFromLookahead) return null;

  let tooltipTitle = '';
  const optionFind = column.from_values.find(
    (option) => option.value === task[column.name]
  );
  tooltipTitle = optionFind?.label || tooltipTitle;

  let isOdd = false;
  if (gantt?.oddColsConfig && task.type === 'activitytask') {
    isOdd = gantt.oddColsConfig.priority;
  }

  if (isOnlyReadElement) {
    return (
      <span className="vertical-center">{defineIcon(optionFind, column)}</span>
    );
  }

  return (
    <div className={'react-dhtmlx ' + (isOdd ? 'odd-col' : 'non-odd-col')}>
      <Tooltip title={capitalize(tooltipTitle)} placement="bottom">
        <Popover
          overlayClassName="popoverPriority"
          className="popoverPriority"
          content={
            <div className="priorityOptions">
              {column.from_values.map((option, index) => {
                return (
                  <span
                    className="item"
                    key={index}
                    onClick={() => {
                      updateAsyncTaskGanttV2(
                        taskFromLookahead,
                        column.name,
                        option.value,
                        gantt
                      );
                    }}>
                    {defineIcon(option, column)}
                    <span style={{ marginLeft: 5 }}>
                      {capitalize(option.label)}
                    </span>
                  </span>
                );
              })}
            </div>
          }
          trigger="click">
          <span className="vertical-center">
            {defineIcon(optionFind, column)}
          </span>
        </Popover>
      </Tooltip>
    </div>
  );
};

export default RenderPriority;
