/** import library for handle dates */
import moment from 'moment';

/** import common functions from utils */
import { cncStatus, groupBy as groupByFn } from '../../../utils';

/** Function order by multiple fileds */
import { firstBy } from 'thenby';

/** Check if data is Grouped */
export const isGroupedFn = (arr) => {
  /** check if the object key is only one level deep */
  const isGrouped = arr.some((e) => Object.keys(e).length === 1);
  return isGrouped;
};

/** this function get all task from activities of lookahead */
export const getActivitiesTasks = async (
  lastLevelActivities,
  sectorSelected
) => {
  const tasks = [];
  /** get tasks from activities */
  lastLevelActivities &&
    lastLevelActivities.lookahead.map((act) =>
      act.tasks.map((task) => tasks.push(task))
    );
  return tasks;
};

/** this function get the list of cncs */
export const getCncList = (cncList) => {
  const cncFilter = cncList?.data?.cncs;
  let cncTasksArr = [];
  let listAddedField = [];
  if (cncFilter) {
    /** add status name */
    listAddedField = cncFilter.map((e) => {
      // e.cnctasks
      const tmpTasks = e.cnctasks.map((el) => ({
        week: e.week,
        ...el
      }));

      cncTasksArr = [...cncTasksArr, ...tmpTasks];
      const statusFind = cncStatus.find((cons) => cons.value == e.status);
      return {
        ...e,
        statusName: statusFind.label
      };
    });
  }
  return {
    cncs: listAddedField,
    cncTasksArr: cncTasksArr
  };
};

/** this function filters only the tasks that belong to the sector */
export const filterTasksBySector = (listConstraints, tasksSector) => {
  const filterConstraints = listConstraints.filter((constraint) => {
    /* search in task array, if constraint task exists */
    const find = constraint.tasks.some((taskConstraint) => {
      const findId =
        tasksSector &&
        tasksSector.some((task) => task.id === taskConstraint.id);
      return findId;
    });
    return find ? constraint : false;
  });
  return filterConstraints;
};

/** this function chains the criteria for the ordering */
export const getSortCriteria = (newOrderby) => {
  let arr_sort = null;
  const order = newOrderby;
  if (order.value && order.value.length) {
    /** chain filter criteria */
    order.value.map((or, index) => {
      if (index === 0) {
        arr_sort = firstBy(or.name, {
          ignoreCase: true,
          direction: or.order_by
        });
      } else {
        arr_sort = arr_sort.thenBy(or.name, {
          ignoreCase: true,
          direction: or.order_by
        });
      }
    });
  }
  return arr_sort;
};

/** this function handle logic for group Constraints */
export const groupedAndSort = (data, groupBy) => {
  /** group projects from state */
  const grouped = groupByFn(data, groupBy.criteria);

  /** create array for sort by key (value is the group name) */
  const groupedKeys = Object.keys(grouped).map((el, i) => ({ value: el }));

  /** sort keys */
  const groupedSortedKeys = groupedKeys.sort(
    firstBy('value', {
      direction: groupBy.sort,
      ignoreCase: true
    })
  );

  /** group constraints */
  const constraintsGrouped = groupedSortedKeys.map((e) => ({
    [e.value]: grouped[e.value]
  }));
  return constraintsGrouped;
};

/** logic for filter */
export const filterList = (list, filters) => {
  /** filter */
  const searchTmp = [];
  let tmpSearch = list; /** initial list */
  let searchListFilter;

  if (filters && filters.value.length) {
    /** logic filter according filters selected */
    filters.value.map((el, i) => {
      const search = el.filter_by;
      /** evaluate type of column included in filter */
      if (search) {
        tmpSearch = list.filter((e) => {
          let ev, find;
          const isNot = el.equals; /** equals === true =>  */
          if (el.data_type === 'date') {
            const dataSearch = moment(search).format('DD-MM-YYYY');
            const dataDate = moment(e[el.name]).format('DD-MM-YYYY');
            find = dataDate === dataSearch;
            ev = isNot ? !find : find;
          } else {
            let name = el.name;
            if (el.ref !== undefined) {
              name = el.ref;
              find = e[name] === search;
            } else {
              if (e[name] instanceof Array) {
                find = e[name].find((task) =>
                  task.name.toLowerCase().includes(search.toLowerCase())
                );
              } else {
                find = e[name].toLowerCase().includes(search.toLowerCase());
              }
            }
            ev = isNot ? !find : find;
          }
          return ev;
        });
      }
      searchTmp.push(tmpSearch);
    });

    /** And Or Logic */
    let resp = [];
    if (filters.andOr === 'or') {
      /** cumulative union of filtered elements */
      searchTmp.map((e) => {
        if (resp.length === 0) {
          resp = e;
        } else {
          const union = [...resp, ...e];
          const set = new Set();
          resp = union.filter((item) => {
            if (!set.has(item.id)) {
              set.add(item.id);
              return true;
            }
            return false;
          }, set);
        }
      });
    } else {
      /** cumulative intersection of filtered element */
      searchTmp.map((e, index) => {
        if (index === 0) {
          resp = e;
        } else {
          resp = resp.filter((n) => e.some((n2) => n.id === n2.id));
        }
      });
    }
    searchListFilter = resp;
  } else {
    searchListFilter = list;
  }
  return searchListFilter;
};

export const getTasksWithRoute = (arr_tasks, sector, taskService) => {
  /** avoid call */
  if (!arr_tasks.length) {
    return {
      tasks: []
    };
  }

  /** get ids array */
  const arr_ids_tasks = [];
  arr_tasks.map((el) => {
    if (el.taskId) {
      arr_ids_tasks.push(el.taskId);
    }
  });

  /** call service task */
  const res = taskService.showWithRouteArray(arr_ids_tasks, sector);
  return res;
};
