/**
 * This object is the layout configuration for gantt chart, it has 1 col for grid table, a col with resize bar for grid width,
 *  a col with the chart gantt, and finally an scroll bar for moving vertically the gantt chart
 */
export const general_layout = {
  css: 'gantt_container',
  cols: [
    {
      width: 900,
      rows: [
        {
          view: 'grid',
          scrollX: 'gridScroll',
          scrollable: true,
          scrollY: 'scrollVer'
        },
        { view: 'scrollbar', id: 'gridScroll', group: 'horizontal' }
      ]
    },
    { resizer: true, width: 1 },
    {
      rows: [
        { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
        { view: 'scrollbar', id: 'scrollHor', group: 'horizontal' }
      ]
    },
    { view: 'scrollbar', id: 'scrollVer' }
  ]
};
