import React, { useState, useEffect } from 'react';
import ModalSystem from '../../components/DesignSystem/ModalSystem';
import { withTranslation } from 'react-i18next';
import { WarningDiamontIcon } from '../../icons';
import './ModalRemoveTaskLookahead.scss';

export const ModalRemoveTaskLookahead = (params) => {
  const { t, task, parent, parentProplannerId, isShow, setIsShow } = params;

  const handleClose = () => {
    setIsShow(false);
  };

  const handleContinue = () => {
    window.to_use_react_gantt.createTaskWithChildAction(
      { duration: parseInt(task.duration) },
      parent,
      parentProplannerId
    );

    window.stackActivitiesMotherSave.add(parentProplannerId);

    handleClose();
  };

  const contentChildren = () => (
    <div className="modal-remove-task-lookahead">
      <div className="modal-remove-task-lookahead__icon">
        <WarningDiamontIcon color="#7dff8a" />
      </div>
      <div className="modal-remove-task-lookahead__text">
        <p>{t('modals.gantt.remove_task_lookahead.description')}</p>
      </div>
      <div className="modal-remove-task-lookahead__buttons">
        <button className="buttons__cancel" onClick={() => handleClose()}>
          {t('modals.gantt.remove_task_lookahead.button_cancel_text')}
        </button>
        <button className="buttons__continue" onClick={() => handleContinue()}>
          {t('modals.gantt.remove_task_lookahead.button_continue_text')}
        </button>
      </div>
    </div>
  );

  return ModalSystem({
    visible: isShow,
    theme: 'dark',
    setVisible: handleClose,
    showTitle: false,
    centered: true,
    width: 430,
    closable: true,
    children: contentChildren()
  });
};

export default withTranslation()(ModalRemoveTaskLookahead);
