import React from 'react';
import Outbuild from '../../../assets/img/proplanner.svg';
import ExpiredTokenIcon from '../../../assets/img/icon-expired-token.svg';
import './index.scss';

const ExpiredScreen = (props) => {
  const { t } = props;

  const goToSignIn = () => {
    props.history.push('/signup');
  };

  return (
    <div className="signupconfirmation__expired-screen">
      <div className="signupconfirmation__expired-screen__logo">
        <img src={Outbuild} alt="Logo Outbuild" />
      </div>
      <div className="signupconfirmation__expired-screen__body">
        <div className="body__title">
          <h6>{t('signup.case_a.quarter_screen_expired_title')}</h6>
          <img src={ExpiredTokenIcon} alt="Expired Token Icon" />
        </div>
        <div className="body__button">
          <button onClick={() => goToSignIn()}>
            {t('signup.case_a.quarter_screen_expired_button_text')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExpiredScreen;
