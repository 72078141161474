import React from 'react';
import {
  getRecursiveFromParentTask,
  getTask
} from '../../../../utils/lookahead-common';
import {
  checkPonderatorIcon,
  updateAsyncTaskGanttV2,
  getPonderatorSum,
  updateDataColumn
} from '../../GanttVisualization.helper';
import cloneDeep from 'lodash/cloneDeep';
import { Tooltip, InputNumber } from 'antd';
import EditableInput from '../../../EditableInput';
import MoneySymbolString from '../../../MoneySymbolString';

export const testIds = {
  RENDER_PONDERATOR_CONTAINER: 'RENDER_PONDERATOR_CONTAINER'
};

const TestWrapped = (children) => {
  return `<span data-testid="${testIds.RENDER_PONDERATOR_CONTAINER}"> ${children}</span>`;
};

const RenderPonderator = ({ task, column, activities, gantt, t, onlyRead }) => {
  if (task?.is_milestone) return TestWrapped('<div></div>');

  // const isOnlyReadElement = onlyRead || task.readonly
  //* * Check if data is defined. Activity don't have this data */
  //* * this function runs after updating the value */
  // const updateAsyncTaskPond = (taskLocal) => {
  //     updateAsyncTaskGanttV2(taskFromLookahead, column.name, taskLocal[column.name], gantt)
  // }
  // const paramsCommon = [t, onlyRead, updateAsyncTaskPond, updateAsyncActivity, projectState]
  // if (!task.isTask) {
  //     /** get reference from activities */
  //     const activityFind = activities.find(ac => ac.unique_id == task.id)
  //     return (
  //         <TestWrapped>
  //             {activityFind ? checkPonderatorIcon(activityFind, ...paramsCommon, gantt) : null}
  //         </TestWrapped>
  //     )
  // }

  // const valueData = task ? task[column && column.name] : 0;

  /** symbol currency by default  */
  // const currency_symbol = ['cost', 'expected_cost'].includes(column.name) ? MoneySymbolString() : null

  /** get reference task (taskFromLookahead) from activities */
  const activityReference = window.activities?.find(
    (ac) =>
      ac.id == task?.activityReference?.proplannerId ||
      ac.id == task?.activityReference?.id
  );
  if (!activityReference) return TestWrapped('<div></div>');

  const doesExistAtReference = getTask(task.id, null, activityReference);
  const taskFromLookahead = doesExistAtReference[0] || null;
  if (!taskFromLookahead) return TestWrapped('<div></div>');

  let parentTask = null;
  if (taskFromLookahead) {
    // disabled = (taskFromLookahead.children.length > 0)
    if (taskFromLookahead.parent_id) {
      const doesExistAtReferenceParent = getTask(
        taskFromLookahead.parent_id,
        null,
        activityReference
      );
      parentTask = doesExistAtReferenceParent[0] || null;
    }
  }
  let taskWithoutProgress = true;

  // const taskDoesntHaveCustomPonderator = !(parentTask || activityReference)
  //   .hasCustomPonderator;

  if (taskFromLookahead.progress != 0) {
    taskWithoutProgress = false;
  }

  //     /**
  //    * This function receives a parent to check if a modal must be showed when he try to modify a auto ponderator
  //    * If user needs modify a ponderator, then it will be turned into custom ponderator system
  //    * @param {*} parent parent activity or task
  //    */
  //     const verifyCustomPonderator = (parent) => {
  //         if (!parent.hasCustomPonderator) {
  //             setShowModal(true);
  //             setSelectedParent(parent);
  //         }
  //     }

  //     /** this function runs after updating the value */
  //     const updateAsyncTask = (taskLocal) => {
  //         updateAsyncTaskGanttV2(taskFromLookahead, column.name, taskLocal[column.name], gantt)

  //         /** update parent, only gantt, no backend */
  //         if (taskLocal.parent) {
  //             gantt.updateTask(taskLocal.parent)
  //         }
  //     }

  // /** this function update column.name recursively */
  // const updateAsyncParent = (idTask) => {
  //     const getReferenceTask = getTask(idTask, null, activityReference)
  //     const taskRef = getReferenceTask[0] || null
  //     getRecursiveFromParentTask(taskRef, column.name, () => {}, updateData)
  //     if (taskRef.parent_id) {
  //         updateAsyncParent(taskRef.parent_id)
  //     }
  // }

  // /** this function intercepts the save call, and adds data */
  // const updateData = (taskCallback) => {
  //     updateDataColumn(taskCallback, column, gantt)
  // }

  // const onClick = () => {
  //     if (!isOnlyReadElement) verifyCustomPonderator(parentTask || activityReference)
  // }

  // const onChangeFunction = (e) => {
  //     if (e != undefined) {
  //         /** update reference before of the update */
  //         const inputBackup = cloneDeep(task[column.name]);
  //         task[column.name] = e;
  //         taskFromLookahead[column.name] = e
  //         const { showCustomPonderator, sumPonderators } = getPonderatorSum(
  //             parentTask || activityReference
  //         )
  //         if (sumPonderators >= 100.00545454545457) {
  //             task[column.name] = inputBackup
  //             taskFromLookahead[column.name] = inputBackup
  //         }
  //         /** Apply changes */
  //         updateAsyncTask(task)
  //         /** update Activity */
  //         if (activityReference) {
  //             /** update activity gantt */
  //             const taskToUpdate = gantt.getTask(Number(activityReference.unique_id))
  //             gantt.updateTask(taskToUpdate.id)
  //         }
  //     }
  // }

  /**
   * Formats a given value as a percentage
   *
   * @param {string} lang - The language to use for formatting. Should be "en" or "other"
   * @param {number} value - The value to format as a percentage
   * @returns {string} - The formatted percentage
   */
  function formatPercentage(lang, value) {
    // DISCLAIMER: This is just to keep sync the gantt ref ponderator value from react ref which is always updated.
    task.ponderator = value;
    if (!value.toFixed && !value.replace) {
      return '';
    }
    if (lang === 'en') {
      return value.toFixed(Number.isInteger(parseFloat(value)) ? 0 : 2) + '%';
    } else {
      return (
        value
          .toFixed(Number.isInteger(parseFloat(value)) ? 0 : 2)
          .replace('.', ',') + '%'
      );
    }
  }

  const renderPonderator = (_) => {
    if (taskWithoutProgress) {
      return `<span class="vertical-center cost-fix-gantt" style="padding-left: 19;">
                    ${formatPercentage(t('lang'), taskFromLookahead[column.name])}
            </span>`;
    } else {
      /** Ponderator with progress */
      return formatPercentage(t('lang'), taskFromLookahead[column.name]);
    }
  };
  let isOdd = false;
  if (gantt?.oddColsConfig && task.type === 'activitytask') {
    isOdd = gantt.oddColsConfig.ponderator;
  }
  return TestWrapped(
    `<div class=${isOdd ? 'odd-col' : 'non-odd-col'}>
        ${renderPonderator()}
    </div>`
  );
};

export default RenderPonderator;
