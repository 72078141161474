import React from 'react';
import { Layout } from 'antd';
import RouteToolbar from '../components/RouteToolbar';

const withRouteToolbar = (props) => (WrappedComponent) => () => (
  <Layout>
    <RouteToolbar {...props} />
    <WrappedComponent {...props} />
  </Layout>
);

export default withRouteToolbar;
