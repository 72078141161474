export default function useWeeklyplanPermissions() {
  const permissions = JSON.parse(sessionStorage.getItem('permissiontable'));
  if (!permissions) return {};
  const cncs = permissions.weeklyplan.find(
    (per) => per.module == 'cncs'
  ).permission;
  const plan = permissions.weeklyplan.find(
    (per) => per.module == 'plan'
  ).permission;
  return { cncs, plan, product: 'weeklyplan' };
}
