import React from 'react';
import { Input } from 'antd';

const InputSystem = ({
  theme = 'light',
  label,
  hint,
  isError = false,
  ...props
}) => (
  <div
    className={`input-design-system theme--${theme} ${isError ? 'error' : ''}`}>
    {label && <label className="input-design-system__label">{label}</label>}
    <Input {...props} />
    <span className="input-design-system__hint">{hint}</span>
  </div>
);

export default InputSystem;
