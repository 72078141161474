import React from 'react';
import { connect, useSelector } from 'react-redux';

import { TabPanel } from '../../TabPanel';
import TasksTab from './tabs/TasksTab';
import DatesTab from './tabs/DatesTab';
import CostsAndWorkTab from './tabs/CostsAndWorkTab';
import ResourcesTab from './tabs/ResourcesTab';
import DependenciesTab from './tabs/DependenciesTab/DependenciesTab';
import SubmittalsTab from './tabs/SubmittalsTab/SubmittalsTab';
import styles from './Tabs.module.scss';

export const Tabs = ({
  cardData,
  setIsLoading,
  setCurrentCardData,
  t,
  calendarObject,
  currentTasks,
  procoreBaseUrl
}) => {
  const stateCompany = useSelector((state) => state.companyState);
  const requireResources = stateCompany?.currentCompany?.require_resources;
  const tabs = requireResources
    ? [
        {
          key: 'dates',
          title: t('activity_card.dates'),
          component: (
            <DatesTab
              cardData={cardData}
              t={t}
              setIsLoading={setIsLoading}
              setCurrentCardData={setCurrentCardData}
              calendarObject={calendarObject}
            />
          )
        },
        {
          key: 'dependencies',
          title: t('activity_card.dependencies'),
          component: <DependenciesTab cardData={cardData} t={t} />
        },
        {
          key: 'submittals',
          title: t('activity_card.submittals'),
          component: (
            <SubmittalsTab
              cardData={cardData}
              t={t}
              procoreBaseUrl={procoreBaseUrl}
            />
          )
        },
        {
          key: 'costs_and_work',
          title: t('activity_card.costs_and_work'),
          component: <CostsAndWorkTab cardData={cardData} t={t} />
        },
        {
          key: 'resources',
          title: t('activity_card.resources'),
          component: <ResourcesTab cardData={cardData} t={t} />
        },
        {
          key: 'tasks',
          title: t('activity_card.tasks'),
          component: (
            <TasksTab
              cardData={cardData}
              t={t}
              setCurrentCardData={setCurrentCardData}
              currentTasks={currentTasks}
            />
          )
        }
      ]
    : [
        {
          key: 'dates',
          title: t('activity_card.dates'),
          component: (
            <DatesTab
              cardData={cardData}
              t={t}
              setIsLoading={setIsLoading}
              setCurrentCardData={setCurrentCardData}
              calendarObject={calendarObject}
            />
          )
        },
        {
          key: 'dependencies',
          title: t('activity_card.dependencies'),
          component: <DependenciesTab cardData={cardData} t={t} />
        },
        {
          key: 'submittals',
          title: t('activity_card.submittals'),
          component: (
            <SubmittalsTab
              cardData={cardData}
              t={t}
              procoreBaseUrl={procoreBaseUrl}
            />
          )
        },
        {
          key: 'costs_and_work',
          title: t('activity_card.costs_and_work'),
          component: <CostsAndWorkTab cardData={cardData} t={t} />
        },
        {
          key: 'tasks',
          title: t('activity_card.tasks'),
          component: (
            <TasksTab
              cardData={cardData}
              t={t}
              setCurrentCardData={setCurrentCardData}
              currentTasks={currentTasks}
            />
          )
        }
      ];
  return (
    <div className={styles.tabs}>
      <TabPanel tabs={tabs} />
    </div>
  );
};

export default connect(null, null)(Tabs);
