import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  getModificationRequestsSelector,
  getSelectedActivitiesSelector,
  getGanttDateFormatSelector,
  modificationRequestsFetchRequested,
  modificationRequestsApproveRequested,
  modificationRequestsRejectRequested,
  modificationRequestsEnablePreviewRequested
} from '../../redux/slices/ganttSlice';
import { getSelectedSectorSelector } from '../../redux/slices/projectSlice';
import { Drawer } from '../Drawer';
import { ModificationRequestIcon } from '../../icons/Icons';
import { FilterButtons } from './components/FilterButtons';
import { RequestsAccordion } from './components/RequestsAccordion';
import {
  getQuantitiesByStatus,
  mapRequestToNewFormat,
  mapDatesToSagaFormat
} from './utils';
import styles from './ModificationRequestsDrawer.module.scss';

export const ModificationRequestsDrawer = ({
  allRequests,
  selectedActivities,
  drawerConfig,
  selectedSector,
  params = {},
  t,
  dateFormat,
  ...actions
}) => {
  const {
    modificationRequestsFetchRequested,
    modificationRequestsApproveRequested,
    modificationRequestsRejectRequested,
    modificationRequestsEnablePreviewRequested
  } = actions;
  const [isInPreviewMode, setIsInPreviewMode] = useState(false);
  const [mappedRequests, setMappedRequests] = useState([]);
  const [visibleRequests, setVisibleRequests] = useState([]);
  const [quantities, setQuantities] = useState(getQuantitiesByStatus([]));
  const [isApproved, setIsApproved] = useState(false);
  const [status, setStatus] = useState('ALL');
  const { activityId } = params;
  const activityIdGet = activityId;

  const modificationRequestsDisablePreviewRequested = () => {
    const ganttInstance = window.to_use_react_gantt;
    if (!ganttInstance) return;
    const activityId = ganttInstance.activityPreview;
    if (!activityId) return;
    const activity = ganttInstance.getTask(activityId);
    if (!activity.previewObject) return;
    const { start, end, duration } = activity.previewObject.oldDates;
    activity.start_date = start;
    activity.end_date = end;
    activity.duration = duration;
    activity.newDuration = activity.duration;
    activity.for_disable_milestone_duration = activity.duration;
    ganttInstance.updateTask(activity.id);
    ganttInstance.modifyLagCustom(activity);
    ganttInstance.autoSchedule();
  };

  const handleCommandDispatched = (command) => {
    const newStatus = command.split('_').pop();
    setStatus(newStatus);
    setVisibleRequests(
      mappedRequests.filter((req) => ['ALL', req.status].includes(newStatus))
    );
  };

  const handleApproveRequest = ({
    activityId,
    id: modificationRequestId,
    newStartDate,
    newEndDate
  }) => {
    modificationRequestsApproveRequested({
      activityId,
      modificationRequestId,
      newDates: mapDatesToSagaFormat(newStartDate, newEndDate, dateFormat),
      activityIdGet
    });
    setIsApproved(true);
  };

  const handleRejectRequest = ({ activityId, id: modificationRequestId }) => {
    modificationRequestsRejectRequested({
      activityId,
      modificationRequestId,
      activityIdGet
    });
  };

  const handlePreviewRequest = (
    { activityId, newStartDate, newEndDate },
    isEnablingPreview
  ) => {
    setIsInPreviewMode(isEnablingPreview);

    if (isInPreviewMode) {
      modificationRequestsDisablePreviewRequested();
    } else {
      modificationRequestsEnablePreviewRequested({
        activityId,
        newDates: mapDatesToSagaFormat(newStartDate, newEndDate, dateFormat)
      });
    }
  };

  useEffect(() => {
    modificationRequestsFetchRequested({ activityId });

    if (isInPreviewMode) {
      setIsInPreviewMode(false);
      modificationRequestsDisablePreviewRequested();
    }
  }, [activityId]);

  useEffect(() => {
    setMappedRequests(
      allRequests.map((request) =>
        mapRequestToNewFormat(request, dateFormat, selectedSector)
      )
    );
  }, [allRequests, dateFormat]);

  useEffect(() => {
    setVisibleRequests(mappedRequests);
    setQuantities(getQuantitiesByStatus(mappedRequests));
  }, [mappedRequests]);

  const onDrawerClose = () => {
    if (isInPreviewMode && !isApproved) {
      modificationRequestsDisablePreviewRequested();
    }
  };

  return (
    <Drawer
      theme="dark"
      config={drawerConfig}
      title={t('modification_requests_drawer.title')}
      icon={ModificationRequestIcon}
      onClose={onDrawerClose}
      className={cn(styles['modification-requests-drawer'], {
        [styles['modification-requests-drawer--preview-mode']]: isInPreviewMode
      })}>
      <FilterButtons
        onCommandDispatched={handleCommandDispatched}
        quantities={quantities}
        selectedStatus={status}
        t={t}
      />

      {allRequests.length ? (
        <RequestsAccordion
          onApproveRequest={handleApproveRequest}
          onRejectRequest={handleRejectRequest}
          onPreviewRequest={handlePreviewRequest}
          className={styles['modification-requests-drawer__requests-accordion']}
          requests={visibleRequests}
          t={t}
        />
      ) : (
        <span
          className={styles['modification-requests-drawer__no-data-message']}>
          {selectedActivities.length
            ? t('modification_requests_drawer.no_requests_message')
            : t('no_selected_tasks_label')}
        </span>
      )}
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  allRequests: getModificationRequestsSelector(state),
  selectedActivities: getSelectedActivitiesSelector(state),
  selectedSector: getSelectedSectorSelector(state),
  dateFormat: getGanttDateFormatSelector(state)
});

const actionCreators = {
  modificationRequestsFetchRequested,
  modificationRequestsApproveRequested,
  modificationRequestsRejectRequested,
  modificationRequestsEnablePreviewRequested
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(ModificationRequestsDrawer));
