import React, { useEffect, useState } from 'react';
import EventEmitter from 'react-native-eventemitter';
import { Table, Input, Button, Layout } from 'antd';
import { useParams } from 'react-router-dom';
import { userService } from '../../../services/user.service';

export default function UserInactive(props) {
  // const { week, planificationday, year } = useParams();
  const [value, setValue] = useState('');
  const [valueSearch, setValueSearch] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);

  const getData = async () => {
    const data = await userService.operationValidation(value);
    setDataSource(data.users);
    setData(data.users);
  };

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  const FilterByNameInput = (
    <Input
      placeholder="Search Email"
      value={valueSearch}
      onChange={(e) => {
        const currValue = e.target.value;
        setValueSearch(currValue);
        const filteredData = data.filter((entry) =>
          entry.email.includes(currValue)
        );
        setDataSource(filteredData);
      }}
    />
  );

  const columns = [
    {
      title: FilterByNameInput,
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url'
    }
  ];
  // <General/>
  return (
    <div style={{ background: '#FFFFFF' }}>
      <div style={{ margin: '40px' }}>
        <Input
          size="small"
          value={value}
          placeholder="ingresa codigo"
          className="custom-input-planification"
          onChange={(e) => setValue(e.target.value)}
        />
        <Button
          onClick={() => {
            getData();
          }}
          style={{
            background: '#7DFF8A',
            color: '#121212',
            borderColor: '#7DFF8A',
            width: 107
          }}>
          Buscar
        </Button>
      </div>
      <Table
        style={{ margin: '40px' }}
        dataSource={dataSource}
        columns={columns}
        pagination={{ defaultPageSize: 30 }}
      />
      ;
    </div>
  );
}
