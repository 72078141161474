function isDate(obj) {
  if (obj && obj.constructor === Date) {
    return !!(obj.getFullYear && obj.getMonth && obj.getDate);
  } else {
    return false;
  }
}

function isValidDate(obj) {
  return isDate(obj) && !isNaN(obj.getTime());
}

export { isValidDate };
