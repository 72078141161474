import React from 'react';
import { Switch } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import * as ganttActions from '../../../../../../../redux/slices/ganttSlice';
import './styles.scss';

const DependenciesFilterSwitch = ({ predecesors, successors, cardData, t }) => {
  const ganttState = useSelector((state) => state.ganttState);
  const dispatch = useDispatch();
  const gantt = window.to_use_react_gantt;

  const getPredecessorsAndSuccessors = () => {
    const cleanPredecessors = predecesors
      .filter(Boolean)
      .map((predecesor) => predecesor.id);
    const cleanSuccessors = successors
      .filter(Boolean)
      .map((successor) => successor.id);
    return [...cleanPredecessors, ...cleanSuccessors];
  };

  const filterByActivityWithDependencies = () => {
    const tasks = gantt.getTaskByTime();
    const dependenciesTasks = getPredecessorsAndSuccessors();
    tasks.forEach((task) => {
      if (
        task.id === cardData.id ||
        dependenciesTasks.includes(task.correlative_id)
      ) {
        task.should_be_showed = true;
      } else {
        task.should_be_showed = false;
      }
    });
  };

  const clearFilter = () => {
    const tasks = gantt.getTaskByTime();
    tasks.forEach((task) => {
      task.should_be_showed = true;
    });
  };

  const dispatchSetFilteredByActivityWithDependencies = (filtered) => {
    dispatch(ganttActions.setFilteredByActivityWithDependencies({ filtered }));
  };

  const handleFilterByActivityWithDependencies = (checked) => {
    if (checked) {
      filterByActivityWithDependencies();
      dispatchSetFilteredByActivityWithDependencies([
        {
          id: cardData.id,
          filtered: true
        }
      ]);
    } else {
      clearFilter();
      dispatchSetFilteredByActivityWithDependencies([]);
    }
    gantt.refreshData();
    gantt.render();
    gantt.scrollTo(null, 0);
  };

  const isFilterActive = () => {
    if (!ganttState || !ganttState.filteredByActivityWithDependencies)
      return false;
    const areActiveFilters =
      ganttState.filteredByActivityWithDependencies.length;

    if (!Boolean(areActiveFilters)) {
      return false;
    }

    const activeFilterId = ganttState.filteredByActivityWithDependencies[0].id;
    const isCurrentActivityFiltered = activeFilterId === cardData.id;

    if (isCurrentActivityFiltered) {
      return true;
    }

    return false;
  };

  const FilterSwitchRender = () => {
    if (getPredecessorsAndSuccessors().length === 0) {
      return '';
    }

    return (
      <div className="dependencies-filter-container">
        <span>{t('activity_card.view_dependencies')}</span>
        <Switch
          checked={isFilterActive()}
          onChange={handleFilterByActivityWithDependencies}
          className="dependencies-filter-switch"
        />
      </div>
    );
  };

  return FilterSwitchRender();
};

export { DependenciesFilterSwitch };
