import React, { useEffect } from 'react';

import { ToolbarGroup } from '../ToolbarGroup';
import {
  GANTT_SETTINGS_ITEMS,
  MORE_INFO_CONFIG,
  DATE_FORMAT_CONFIG,
  CRITERIA_STATUS_CONFIG
} from './constants';
import styles from './GanttSettings.module.scss';

export const GanttSettings = ({
  t,
  onCommandDispatched,
  criteriaTooltip,
  ganttObject,
  projectState
}) => {
  useEffect(() => {
    MORE_INFO_CONFIG.tooltip = criteriaTooltip;
  }, []);

  useEffect(() => {
    if (!ganttObject?.dateFormatOptions || !ganttObject?.currentDateFormat) {
      return;
    }
    CRITERIA_STATUS_CONFIG.subitems = CRITERIA_STATUS_CONFIG.subitems.map(
      (el) => {
        const valueToCompare = el.i18nKey.split('_')[2];
        return {
          ...el,
          checked: valueToCompare === ganttObject.status_criteria
        };
      }
    );

    DATE_FORMAT_CONFIG.subitems = ganttObject.dateFormatOptions?.map(
      (dateFormat) => ({
        text: dateFormat,
        command: `SET_DATE_FORMAT_${dateFormat}`,
        checkable: true,
        checked: dateFormat === ganttObject.currentDateFormat
      })
    );
  }, [ganttObject, projectState]);

  return (
    <div className={styles['gantt-settings']}>
      {!window.location.href.includes('resources') && (
        <ToolbarGroup
          items={GANTT_SETTINGS_ITEMS}
          onCommandDispatched={onCommandDispatched}
          t={t}
        />
      )}
    </div>
  );
};
