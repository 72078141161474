/* eslint-disable no-case-declarations */
/** import ACTIONS */
import { SET_CURRENT_COMPANY } from '../actions/companyActions';

/**
 * Reducer function means a switch case to filter action data on the platform for general state
 * @param {*} state Real time updated through all components state (general state flux)
 * @param {*} action Action is an object with structyure { type: STRING, payload: FUNCTION }
 */
function companyReducer(state = { currentCompany: {} }, action) {
  switch (action.type) {
    case SET_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: action.payload
      };
    default:
      return state;
  }
}

export default companyReducer;
