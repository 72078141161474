import React from 'react';
import StartCell from '../../../components/StartCell';

export default (gantt, { t }) => ({
  name: 'start_date',
  label: t('scheduleofschedule.columns.start_date'),
  align: 'center',
  width: 80,
  onrender: (item) => <StartCell parent={item.parent} date={item.start_date} />
});
