const getTaskCalendar = (task, gantt) => {
  let calendar;
  if (!task.calendar_id) {
    calendar = gantt.getCalendar(gantt.defaultCalendar);
  } else {
    calendar = gantt.getCalendar(task.calendar_id);
  }

  return calendar;
};

export { getTaskCalendar };
