import React, { useRef, useState } from 'react';
import { CloseTagIcon, SearchIcon } from '../../icons';
import { Colors } from '../../constants/colors.constants';
import './Search.scss';
import { trackingEvent } from '../../analytics';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';
import {
  HOME_CARD_VIEW,
  HOME_TIMELINE_VIEW
} from '../../hooks/useSelectProject';

const EMPTY = '';
const LIGHT_MODE = 'light';
const typeViewsMapper = {
  CARD: HOME_CARD_VIEW,
  TIMELINE: HOME_TIMELINE_VIEW
};

const Search = ({
  t = () => {},
  theme = LIGHT_MODE,
  callback = () => {},
  typeView
}) => {
  const { DARK_GRAY, BLACK, WHITE } = Colors;
  const [inputValue, setInputValue] = useState(EMPTY);
  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    callback && callback(value);
  };

  const focusInput = () => {
    const { current } = inputRef;
    trackingEvent(
      'search_box_focused',
      {
        event_source: typeViewsMapper[typeView]
      },
      AMPLITUDE_SERVICE
    );
    current && current.focus();
  };

  const clearInput = () => {
    trackingEvent(
      'search_box_cleared',
      {
        search_text: inputValue,
        event_source: typeViewsMapper[typeView]
      },
      AMPLITUDE_SERVICE
    );
    setInputValue(EMPTY);
    callback && callback(EMPTY);
  };

  const renderInput = () => (
    <input
      ref={inputRef}
      placeholder={t('project_header.search_box.search')}
      value={inputValue}
      onChange={handleInputChange}
    />
  );

  const renderCloseIcon = () =>
    inputValue && (
      <div onClick={clearInput}>
        <CloseTagIcon
          className={'icon'}
          color={theme === LIGHT_MODE ? DARK_GRAY : WHITE}
        />
      </div>
    );

  const renderSearchIcon = () => (
    <SearchIcon
      className={'icon'}
      color={theme === LIGHT_MODE ? BLACK : WHITE}
    />
  );

  return (
    <div
      className={`search-container theme--${theme}`}
      onClick={() => focusInput()}>
      {renderInput()}
      {renderCloseIcon()}
      {renderSearchIcon()}
    </div>
  );
};

export default Search;
