import React from 'react';
import {
  DangerousNotificationIcon,
  WarningNotificationIcon
} from '../../../icons';

const TYPE_WARNING = 'warning';
const TYPE_ERROR = 'error';

const NotificationSystem = ({
  type = 'warning',
  withIcon = true,
  icon,
  label
}) => (
  <div className={`notification-system type--${type}`}>
    <div className="notification-system__content">
      {withIcon && (
        <div className="content__icon">
          {icon ||
            (type === TYPE_WARNING ? (
              <WarningNotificationIcon color="#F59D04" />
            ) : TYPE_ERROR ? (
              <DangerousNotificationIcon color="#E50101" />
            ) : (
              ''
            ))}
        </div>
      )}
      {label && <span>{label}</span>}
    </div>
  </div>
);

export default NotificationSystem;
