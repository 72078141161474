/* eslint-disable prefer-const */

/** React components  */
import React, { useState } from 'react';

/** Import elements from library Antd */
import { Row, Col, Radio, Popover } from 'antd';

/** Redux implementation */
import { useSelector, useDispatch } from 'react-redux';
import { constraintActions } from '../../../../../redux/actions/constraintActions';

/** import components */
import ConstraintCalendar from '../ConstraintCalendar';
import { CalendarNewIcon } from '../../../../../icons';

const defaultFormatDate = 'MMMM, DD YYYY';

export default function ConstraintDates(props) {
  /** Redux */
  const dispatch = useDispatch();
  const constraintState = useSelector((state) => state.constraintState);
  const {
    projectState: { allSectors, sectorSelected }
  } = useSelector((state) => state);
  const { t } = props;

  const [showPopoverCalendar, setShowPopoverCalendar] = useState(false);

  let currentDateFormat = allSectors.find(
    (sector) => sector.id === sectorSelected
  )?.dateFormat;

  /** update state for changes in calendar */
  const handleOptionsDate = (e) => {
    dispatch(
      constraintActions.setConstraintForm({
        ...constraintState.constraintForm,
        typeDate: e.target.value
      })
    );
  };

  let old = false;

  /** render */
  return old ? (
    <div className="constraint-dates-wrapp-dates">
      <Row>
        {/* render options for dates of constraints */}
        <Col span={24}>
          <Col className="constraints-switch">
            <Radio.Group
              defaultValue="com"
              className="constraint-dates-options-date"
              onChange={(e) => handleOptionsDate(e)}
              buttonStyle="solid"
              size="small">
              <Radio.Button className="constraint-date-btn-switch" value="req">
                {t('required_constraint_label')}
              </Radio.Button>
              <Radio.Button className="constraint-date-btn-switch" value="com">
                {t('compromise_constraint_label')}
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Col>
        {/* render calendar */}
        <Col span={12}>
          <ConstraintCalendar />
        </Col>
      </Row>
    </div>
  ) : (
    <div
      className={`constraint-dates__container ${constraintState.constraintForm.type === null ? 'disabled' : ''}`}>
      <div className="container__popover">
        <Popover
          overlayClassName="container__overlay-popover"
          className="popover__content"
          content={
            <ConstraintCalendar
              setShowPopoverCalendar={(value) => setShowPopoverCalendar(value)}
            />
          }
          trigger={constraintState.constraintForm.type === null ? '' : 'click'}
          visible={showPopoverCalendar}
          onVisibleChange={(value) => setShowPopoverCalendar(value)}>
          <div className="content__icon">
            <CalendarNewIcon color="#FFF" />
          </div>
          <div className="container__label">
            <span>
              {constraintState.constraintForm.calendarDate
                ? constraintState.constraintForm.calendarDate.format(
                    currentDateFormat || defaultFormatDate
                  )
                : t('select_date_label')}
            </span>
          </div>
        </Popover>
      </div>
    </div>
  );
}
