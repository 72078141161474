import { isValidDate } from './isValidDate';
/**
 * Gets the next working hour based on the given parameters.
 * @param {Object} activityParams - The parameters for the activity.
 * @param {Date} activityParams.dateBaseToCalculate - The base date to start the calculation from.
 * @param {Object} activityParams.activityCalendar - The calendar object associated with the activity.
 * @param {string} activityParams.direction - The direction of the calculation ('forward' or 'backward').
 * @param {Object} activityParams.activity - The activity object associated with the calculation.
 * @returns {Date} - The next working hour based on the calendar and direction.
 */

function getNextWorkingHour(activityParams) {
  if (!isValidDate(new Date(activityParams.dateBaseToCalculate))) {
    throw new Error('Invalid date argument for getClosestWorkTime method');
  }

  const { dateBaseToCalculate, activityCalendar, direction, activity } =
    activityParams;
  let newDate = activityCalendar.$gantt.getClosestWorkTime({
    date: new Date(dateBaseToCalculate),
    dir: direction,
    unit: 'hour',
    task: activity
  });

  return newDate;
}

export { getNextWorkingHour };
