import React, { useState, useEffect, Fragment } from 'react';
import EventEmitter from 'react-native-eventemitter';
import { userService, companyService } from '../../services';
import { Animated } from 'react-animated-css';
import { Input, Row, Col, Button, Select } from 'antd';
import { useParams } from 'react-router-dom';
import ErrorValidationLabel from '../../components/Login/ValidationLabel';
import { openNotification, userType, setTitleWide } from '../../utils';
import {
  checkAllSelectsValid,
  guestsTemplate,
  asignProjectUser
} from './invite.helpers';
import { base } from '../../services/base';
import { withTranslation } from 'react-i18next';
const { Option } = Select;

// messages
const msgFrmEmpty =
  'Existe un error en el formulario. Revise el mail/rol de los invitados';
const msgInvited =
  'Se te ha enviado una invitacion para participar en Outbuild';
const msgError =
  'Hubo un error con la invitación. Contacte al administrador del sistema.';
const msgErrorUser = 'Token y/o usuario inválido.';

function InviteSView(props) {
  const { t } = props;
  // hooks
  const [formValid, setFormValid] = useState(true); // validation
  const [isLoading, setIsLoading] = useState(false); // Btn Loading
  const [arrInvite, setArrInvite] = useState(guestsTemplate); // Arr invite
  const [currentCompany, setCurrentCompany] = useState(); // Arr invite
  const [currentUser, setCurrentUser] = useState(); // Arr invite
  const { token } = useParams(); // handle params

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    setTitleWide('invite');
    EventEmitter.on('changeMainRoute', callback);
    loadData();

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  const loadData = async () => {
    const user = await getUser(token);
    if (user) {
      setCurrentUser(user);
    }

    const company = await getCompany(user.companyId);
    if (company) {
      setCurrentCompany(company);
    }
  };

  // navigation history
  const goToProjects = (e) => {
    if (e) {
      e.preventDefault();
    }
    window.location = '/projects';
  };

  /** services  */
  async function getCompany(companyId) {
    const resp = await companyService.show(companyId);
    return resp ? resp.company : false;
  }

  async function inviteUsers(data, projectId) {
    const resp = await userService.inviteUsers(data);
    // assign users to project
    if (resp.users) {
      resp.users.map(async (user) => {
        await asignProjectUser({
          userId: user.id,
          projectId: projectId,
          isCreator: false
        });
      });
    }
    return resp;
  }

  async function getUser(token) {
    const resp = await userService.getbytoken(token);
    return resp;
  }

  // logic
  const onSubmit = async (e) => {
    e.preventDefault();

    const { allFieldsValid, guests } = checkAllSelectsValid(
      arrInvite,
      msgInvited
    );
    setFormValid(allFieldsValid);

    if (allFieldsValid) {
      setIsLoading(true);
      const user = currentUser;
      if (user.email) {
        if (guests.length) {
          // get project created
          const projectCreated = JSON.parse(
            localStorage.getItem('projectCreated')
          );
          const dataSend = guests.map((el) => ({
            ...el,
            companyId: user.companyId,
            projectId: projectCreated.id
          }));

          const data = {
            sender_user: {
              name: user.name,
              image: user.image,
              name_company: currentCompany.name,
              image_company: currentCompany.image,
              name_project: projectCreated.name
            },
            link: base.front + 'confirmation/',
            users: dataSend
          };

          const inviteds = await inviteUsers(data, projectCreated.id);

          if (inviteds.msj) {
            const alertSuccesMailExists = {
              title: 'Invite',
              description: inviteds.msj,
              type: 'success'
            };
            openNotification(alertSuccesMailExists);
            setTimeout(() => {
              goToProjects();
            }, 1000);
          } else {
            const alertSuccesMailExists = {
              title: 'Invite',
              description: msgError,
              type: 'error'
            };
            openNotification(alertSuccesMailExists);
            setIsLoading(false);
          }
        }
      } else {
        // user not found / token invalid
        const alertErrorUser = {
          title: 'Invite',
          description: msgErrorUser,
          type: 'error'
        };
        openNotification(alertErrorUser);
        setIsLoading(false);
      }
    }
  };

  const handleChange = (type, index, email) => {
    // item to replace
    const itemReplace = { id: index, email: email, type: type };
    // update arrInvite
    const items = arrInvite;
    const foundIndex = items.findIndex((x) => x.id === index);
    items[foundIndex] = itemReplace;
    setArrInvite(items);
  };

  // validation
  const allFieldsValid = formValid;
  const renderGuestsValidationError = allFieldsValid ? (
    ''
  ) : (
    <ErrorValidationLabel txtLbl={msgFrmEmpty} classError="lblerrorfrm" />
  );

  // build form
  const formInvite = arrInvite.map(({ email, type }, i) => (
    <Row key={i} className="p10">
      <Col span={11}>
        <Input
          className="w100 txtFix"
          type="email"
          placeholder="Email"
          defaultValue={email}
          onChange={(e) => handleChange(arrInvite[i].type, i, e.target.value)}
        />
      </Col>
      <Col span={2}></Col>
      <Col span={11}>
        <Select
          className="select-frm"
          placeholder="Select User Type"
          defaultValue={type}
          onChange={(e) => handleChange(e, i, arrInvite[i].email)}>
          {userType(t).map(({ label, value }, index) => (
            <Option key={index} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  ));

  // render
  return (
    <Animated
      className="animatedWide"
      animationIn="fadeIn"
      animationInDuration={500}
      isVisible={true}>
      <div className="divWide">
        <div className="divSection">
          <Fragment>
            <form className="frmWide" onSubmit={onSubmit} noValidate>
              {formInvite}
              {renderGuestsValidationError}
              <Button htmlType="submit" loading={isLoading}>
                Continue
              </Button>
              <a href="#/" onClick={goToProjects} className="refLink skipLink">
                Skip
              </a>
            </form>
          </Fragment>
        </div>
      </div>
    </Animated>
  );
}
export default withTranslation()(InviteSView);
