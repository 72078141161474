import React from 'react';
import { FixedSizeList as List } from 'react-window';

const renderRow =
  (templateData, data) =>
  ({ index, style }) => {
    const currentStyle = {
      ...style,
      gridTemplateColumns:
        templateData.templateColumns ||
        `repeat(${templateData.columns?.length}, auto)`
    };
    return (
      <div style={currentStyle} className="list__content">
        {templateData.columns?.map((row, key) => (
          <div
            className="content__row"
            key={`${index}-${key}`}
            style={{
              justifyContent: row.justifyContentColumn || 'flex-start'
            }}>
            {row.renderColumn && row.renderColumn(data[index], index)}
          </div>
        ))}
      </div>
    );
  };

const VirtualDynamicTable = ({
  templateData,
  data,
  itemCount,
  itemSize,
  heightRow
}) => (
  <div className="virtual-dynamic-table">
    <div
      className="virtual-dynamic-table__header"
      style={{
        height: templateData.headerHeight || 24,
        gridTemplateColumns:
          templateData.templateColumns ||
          `repeat(${templateData.columns?.length}, auto)`
      }}>
      {templateData.columns?.map((column) => (
        <div
          key={column.key}
          className="header__content"
          style={{
            justifyContent: column.justifyContentHeader || 'flex-start'
          }}>
          <span
            onClick={() =>
              column.onClickHeader ? column.onClickHeader() : null
            }
            style={{
              cursor: column.onClickHeader ? 'pointer' : 'inherit'
            }}>
            {column.renderHeader ? column.renderHeader() : column.label}
          </span>
        </div>
      ))}
    </div>
    <div className="virtual-dynamic-table__body">
      <List
        width="100%"
        height={heightRow}
        itemCount={itemCount}
        itemSize={itemSize}
        className="body__list">
        {renderRow(templateData, data)}
      </List>
    </div>
  </div>
);

export default VirtualDynamicTable;
