import { createHttpClient } from '../utils/httpUtils';

class ActivityResourceService {
  constructor() {
    this.http = createHttpClient();
    this.url = 'activityresources';
  }

  async show() {
    const res = await this.http.get(this.url).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async destroy(id) {
    const res = await this.http.delete(`${this.url}/${id}}`).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post(this.url, data).catch((error) => {
      console.log(error.response);
      return error.response;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put(`${this.url}/${data.id}}`, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const activityresourcesService = new ActivityResourceService();
