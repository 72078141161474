/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class CncTypeService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(constraintId) {
    const res = await this.http
      .get('cnctypes/' + constraintId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(constraintId) {
    const res = await this.http
      .delete('cnctypes/' + constraintId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('cnctypes', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('cnctypes/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showByProject(projectId) {
    const res = await this.http
      .get('cnctypes/project/' + projectId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const cncTypeService = new CncTypeService();
