/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class ConstraintTypeService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(typeId) {
    const res = await this.http
      .get('constrainttypes/' + typeId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(typeId) {
    const res = await this.http
      .delete('constrainttypes/' + typeId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('constrainttypes', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('constrainttypes/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showByProject(projectId) {
    const res = await this.http
      .get('constrainttypes/project/' + projectId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const constraintTypeService = new ConstraintTypeService();
