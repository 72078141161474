import React, { useState, useEffect, useRef, useForceUpdate } from 'react';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import { sectorResourcesService } from '../../services/sectorresource.service';
import { analyticService } from '../../services/analytic.service';
import { useDispatch } from 'react-redux';
import { productivityChartActions } from '../../redux/actions/productivityChartActions';
import {
  Row,
  Col,
  Modal,
  Checkbox,
  Button,
  Collapse,
  Radio,
  Dropdown
} from 'antd';
import filterIcon from '../../assets/img/takt/filter.png';
import { dynamicSort } from '../../../src/utils';

export default function ProductivityChart(props) {
  const { t } = props;
  const [firstLoad, setFirstLoad] = useState(true);
  const [firstLoadView, setFirstLoadView] = useState(false);
  const [resources, setResources] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [curveData, setCurveData] = useState([]);
  const [loading_filter, setLoading_filter] = useState(false);
  const [holderChart, setHolderChart] = useState(true);
  const [Options, setOptions] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [unidad, setUnidad] = useState([]);

  const { Panel } = Collapse;
  const dispatch = useDispatch();

  const [selectedResource, setSelectedResource] = useState([]);
  const projectState = useSelector((state) => state.projectState);

  const productivityChartState = useSelector(
    (state) => state.productivityChartState
  );
  const allSectorsIds = projectState.allSectors.map((sector) => sector.id);

  useEffect(() => {
    if (firstLoad) {
      const projects = productivityChartState.projects;
      let project;
      if (projects) {
        project = projects.find(
          (p) => p.projecti_id == projectState.projectSelected
        );
      }
      if (project) {
        setSelectedSector(project.sectors);
        setSelectedResource(project.resources);
        setUnidad(project.unidad);
      } else {
        const arrayResources = [];
        allSectorsIds.map(async (sector) => {
          const response = await sectorResourcesService.searchBySector(sector);
          if (response.sectorresource.length > 0) {
            arrayResources.push(response);
          }
        });
        setResources(arrayResources);
        setFirstLoad(false);
      }
    }
  }, []);

  useEffect(() => {
    if (firstLoad) {
      getResources();
    }
  }, [selectedResource]);

  const getResources = async () => {
    if (selectedResource.length > 0) {
      const response = await analyticService.productivity_chart({
        sector_id: selectedSector,
        date_of_filter: [],
        resource_id: selectedResource
      });
      setFirstLoadView(false);
      setCurveData(response);
      setConfirmLoading(true);
      setTimeout(() => {
        setVisible(false);
        setConfirmLoading(false);
      }, 500);

      const arrayResources = [];
      allSectorsIds.map(async (sector) => {
        const response = await sectorResourcesService.searchBySector(sector);
        if (response.sectorresource.length > 0) {
          arrayResources.push(response);
        }
      });

      setResources(arrayResources);
      setFirstLoad(false);
    }
  };

  useEffect(() => {
    formatRawData();
  }, [curveData]);

  const formatRawData = () => {
    const series = [];
    if (curveData instanceof Array) {
      const serie = curveData.map((item) => ({
        x: item.date,
        y: item.real ? item.real.toFixed(2) : item.real
      }));
      series.push({
        name: 'serie',
        data: serie
      });
      const temp_options = {
        series: series,
        options: {
          chart: {
            height: 350,
            type: 'bar'
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top' // top, center, bottom
              }
            },
            columnWidth: '20%'
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return String(val);
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#304758']
            }
          },
          xaxis: {},
          yaxis: {
            labels: {
              show: true,
              formatter: function (val) {
                return `${val} ${unidad[0]}/HD`;
              }
            }
          },
          title: {
            text: '',
            floating: true,
            offsetY: 330,
            align: 'center',
            style: {
              color: '#444'
            }
          }
        }
      };

      setOptions(temp_options);
      setLoading(false);
      if (serie.length > 0) {
        setLoading_filter(true);
        setHolderChart(false);
      }
    }
  };

  const renderModalFilter = () => (
    <Modal
      title={t('resource_curve_model')}
      visible={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}>
      <Collapse>{renderCollapse()}</Collapse>
    </Modal>
  );
  const handleOk = async () => {
    const response = await analyticService.productivity_chart({
      sector_id: selectedSector,
      date_of_filter: [],
      resource_id: selectedResource
    });
    setCurveData(response);
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 500);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const renderCollapse = () => {
    const sectorResource = generateArraySectorResources;
    if (resources[0]) {
      if (resources[0].sectorresource.length > 0) {
        const panels = sectorResource
          .sort(dynamicSort('sector_name'))
          .map((sr) => (
            <Panel header={sr.sector_name} key={sr.sector_id}>
              {sr.resources.map((r) => (
                <Row>
                  <Checkbox
                    defaultChecked={selectedResource.includes(r.value)}
                    value={[sr.sector_id, r.value, r.un]}
                    onChange={onChecked}>
                    {r.label}
                  </Checkbox>
                </Row>
              ))}
            </Panel>
          ));
        return panels;
      }
    }
    return null;
  };
  const generateArraySectorResources = resources.map((resource) => {
    let sectorResource = [];
    if (resource.sectorresource.length > 0) {
      const sector = projectState.allSectors.filter(
        (sector) => sector.id == resource.sectorresource[0].sectorId
      )[0];
      const recursos = resource.sectorresource.map((recurso) => ({
        label: recurso.name,
        value: recurso.id,
        un: recurso.material_label
      }));
      const sectorAndResource = [];
      sectorResource = {
        sector_name: sector.name,
        sector_id: sector.id,
        resources: recursos
      };
    }
    return sectorResource;
  });

  const onChecked = (checkedValues) => {
    const selected_resource = selectedResource;
    const selected_sector = selectedSector;
    const selected_unidad = unidad;
    if (checkedValues.target.checked) {
      selected_resource.push(checkedValues.target.value[1]);
      selected_sector.push(checkedValues.target.value[0]);
      selected_unidad.push(checkedValues.target.value[2]);
    } else {
      const indexResource = selected_resource.indexOf(
        checkedValues.target.value[1]
      );
      const indexSector = selected_sector.indexOf(
        checkedValues.target.value[0]
      );
      const indexUn = selected_unidad.indexOf(checkedValues.target.value[2]);
      if (indexResource > -1) {
        selected_resource.splice(indexResource, 1);
      }
      if (indexSector > -1) {
        selected_sector.splice(indexSector, 1);
      }
      if (indexUn > -1) {
        selected_unidad.splice(indexUn, 1);
      }
    }

    setSelectedSector(selected_sector);
    setSelectedResource(selected_resource);
    setUnidad(selected_unidad);
    let projects = productivityChartState.projects;
    if (projects) {
      const project = projects.find(
        (p) => p.projecti_id == projectState.projectSelected
      );
      if (project) {
        project.sectors = selected_sector;
        project.resources = selected_resource;
        project.unidad = selected_unidad;
      } else {
        projects.push({
          sectors: selected_sector,
          resources: selected_resource,
          projecti_id: projectState.projectSelected,
          unidad: selected_unidad
        });
      }
    } else {
      projects = [];
      projects.push({
        sectors: selected_sector,
        resources: selected_resource,
        projecti_id: projectState.projectSelected,
        unidad: selected_unidad
      });
    }

    dispatch(productivityChartActions.setProjects(projects));
  };

  const renderChart = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Chart
        options={Options.options}
        series={Options.series}
        type="bar"
        width="98%"
        height="100%"
      />
    );
  };

  return (
    <div style={{ height: '100%' }}>
      <div
        className="content_place_holder"
        style={{ visibility: holderChart ? 'visible' : 'hidden' }}>
        {firstLoadView ? (
          <h2 className="content_place_holder_text">Loadding</h2>
        ) : (
          <h2 className="content_place_holder_text">
            {t('resource_cuve_text_place_holder')}{' '}
            <img src={filterIcon} width={15} />
          </h2>
        )}
      </div>
      <Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 17,
            paddingBottom: 0
          }}>
          <span style={{ color: '#121212', fontSize: 20 }}>
            {t('productivity_chart')}
          </span>

          <span
            style={{ position: 'relative', top: -2 }}
            className="matrix-activities-filter">
            <div
              style={{ cursor: 'pointer', marginRight: 10 }}
              onClick={() => {
                setVisible(true);
              }}>
              <div>
                <img src={filterIcon} width={15} />
              </div>
            </div>
          </span>
        </div>
      </Row>
      <Row style={{ height: '90%' }}>
        {loading_filter ? renderChart() : <div></div>}

        {renderModalFilter()}
      </Row>
    </div>
  );
}
